import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSortable } from '@angular/material/sort';
import { Track } from '../shared/models/track.model';
import { DialogService } from '../shared/services/dialog.service';
import {
  TrackService,
  Filters,
  MinMax,
  GetTracksOptions,
  filtersToParams,
  areFiltersEqual,
} from '../shared/services/track.service';
import { TrackVersion } from '../shared/models/trackversion.model';
import { TrackVersionService } from '../shared/services/trackversion.service';
import { PlayerService } from '../shared/services/player.service';
import { UserMenuService } from '../shared/services/user-menu.service';
import { environment } from '../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { UserService } from '../shared/services/user.service';

const tracksPageSize = 30;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})

export class HomeComponent implements OnInit, OnDestroy {
  loadingTracks = true;
  searchFilterFocused = false;

  playingTrack: Track = null;
  playingTrackVersion: TrackVersion = null;
  musicPlayerCollapsed = false;
  dateChoices: string[] = ['All Tracks', 'Date Created', 'Date Updated', 'Date Approved'];
  selectedDateType: string = "All Tracks";
  pagination: any = {
    next: null,
    previous: null
  };
  isFilter = false;
  get startDate() {
    return this._startDate;
  }
  set startDate(d: Date) {
    this._startDate = d
    this._currentTracksPage = 1;
    //this.loadTracks();
    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: this._params,
    });
  }
  get endDate() {
    return this._endDate;
  }
  set endDate(d: Date) {
    this._endDate = d
    this._currentTracksPage = 1;
    // if(!this._startDate && !this._endDate){
    //   this.loadTracks();
    // }
    //this.loadTracks();
    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: this._params,
    });
  }

  @Input()
  set baseTrackOptions(o: GetTracksOptions) {
    this._baseTrackOptions = o;
    this._currentTracksPage = 1;
    //this.loadTracks();
    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: this._params,
    });
  }
  get baseTrackOptions(): GetTracksOptions {
    return this._baseTrackOptions;
  }
  totalTracks = 0;
  set currentTracksPage(p: number) {
    if (!isNaN(p) && p != this._currentTracksPage) {
      this._currentTracksPage = p;
      //this.loadTracks();
      this._router.navigate([], {
        relativeTo: this._activatedRoute,
        queryParams: this._params,
      });
    }
  }
  get currentTracksPage(): number {
    return this._currentTracksPage;
  }
  get tracksNumberOfPages(): number {
    return Math.ceil(this.totalTracks / tracksPageSize);
  }
  tracks: Observable<ReadonlyArray<Track>>;
  get filters(): Filters {
    return this._filters;
  }
  set filters(f: Filters) {
    if (!areFiltersEqual(this._filters, f)) {
      this._filters = f;
      this._currentTracksPage = 1;
      //this.loadTracks();
      this._router.navigate([], {
        relativeTo: this._activatedRoute,
        queryParams: this._params,
      });
    }
  }
  currFilt: Filters;
  filterChange(e) {
    this.filters = e
    this.currFilt = e;
    this._filters = e;
    this.currentTracksPage = 1
    this.currPage = 1
    console.log(e)
    if (this._filters?.clear == true && this.EDate == null && this.sDate == null) {
      this.isFilter = false;
      
      this.loadTracks()
    }
    else {
      this.isFilter = true;
      this.loadFilter();
    }
  }
  get ordering(): string {
    return this._ordering;
  }
  get limit() {
    return this.currentTracksPage;
  }
  set ordering(o: string) {
    if (o != this._ordering) {
      this._ordering = o;
      this._currentTracksPage = 1;
      if(this.isFilter){
        this.loadFilter()
      }
      else{
       
        if (this.EDate!== null && this.sDate !== null) {
          this.loadFilter()
        }
        else{
          
        this.loadTracks();
        }
      }
      this._router.navigate([], {
        relativeTo: this._activatedRoute,
        queryParams: this._params,
      });
    }
  }

  get searchString(): string {
    return this._searchString;
  }
  set searchString(s: string) {
    if (this._searchString != s) {
      this._searchString = s;
    }
  }
  private get _params(): { [param: string]: string | string[] } {
    let params = filtersToParams(this.filters);
    if (this.ordering) {
      params['ordering_by'] = this.ordering;
    }
    if (this.currentTracksPage && this.currentTracksPage != 1) {
      params['page'] = `${this.currentTracksPage}`;
    }
    if (this.searchString) {
      params['search'] = this.searchString;
    }
    if (this.startDate) {
      params['created_at__gte'] = this.startDate.toISOString();
    }
    if (this.endDate) {
      params['created_at__lte'] = this.endDate.toISOString();
    }
    if (this.limit) {
      params['limit'] = '20';
    }
    return params;
  }
  private _filters: Filters = {
    versionStatus: null,
    artistId: null,
    composerId: null,
    genreId: null,
    moodId: null,
    duration: null,
    tempo: null,
    bpm: null,
    version: null,
    is_instrumental: null,
    isExclusive: null,
    sub_genre: null,
    is_vocals: null,
    page: 1,
    artistType:'None'
  };
  private _ordering: string;
  private _currentTracksPage = 1;
  private _searchString = '';
  private _startDate:any= null;
  private _endDate: any=null;
  private _subscriptions: Subscription[] = [];
  private _getTracksSubscription: Subscription;
  private _tracksSubject: BehaviorSubject<
    ReadonlyArray<Track>
  > = new BehaviorSubject<ReadonlyArray<Track>>([]);
  private _baseTrackOptions: GetTracksOptions = {};
  term = true
  constructor(
    public _userService: UserService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _trackService: TrackService,
    private _dialogService: DialogService,
    private _playerService: PlayerService,
    private api: UserMenuService, private _cdr: ChangeDetectorRef,
  ) {
    if (this._userService.currentUser.is_artist === true) {
      this.term = false;
    }
    else {
      this.term = true;
    }
    this.tracks = this._tracksSubject.asObservable();
  }

  ngOnInit(): void {

    //Robert: 5/16/24 - Prevent last saved track from playing on init
    localStorage.setItem('editData','no')

    this._subscriptions.push(
      this._activatedRoute.queryParamMap.subscribe((params) => {
        let created_at__gte = params.get('created_at__gte');
        let created_at__lte = params.get('created_at__lte');
        if (created_at__gte) {
          this._startDate = new Date(created_at__gte);
        }
        if (created_at__lte) {
          this._endDate = new Date(created_at__lte);
        }

        let bpm_lte = params.get('bpm__lte');
        let bpm_gte = params.get('bpm__gte');
        // let tempo: MinMax = null;
        // if (bpm_lte !== null || bpm_gte !== null) {
        //   tempo = {};
        //   if (bpm_lte !== null) {
        //     tempo['max'] = parseFloat(bpm_lte);
        //   }
        //   if (bpm_gte !== null) {
        //     tempo['min'] = parseFloat(bpm_gte);
        //   }
        // }
        let version = params.get('version')
        let bpm = params.get('bpm');
        let duration_lte = params.get('duration__lte');
        let duration_gte = params.get('duration__gte');
        let duration: MinMax = null;
        if (duration_lte !== null || duration_gte !== null) {
          duration = {};
          if (duration_lte !== null) {
            duration['max'] = parseFloat(duration_lte);
          }
          if (duration_gte !== null) {
            duration['min'] = parseFloat(duration_gte);
          }
        }
        let is_instrumental = params.get('instrumental');
        let instrumental: boolean = null;
        if (is_instrumental !== null) {
          instrumental = is_instrumental !== 'false';
        }
        else {
          instrumental = false
        }
        let is_vocals = params.get('is_vocals');
        let vocal: boolean = null;
        if (is_vocals !== null) {
          vocal = is_vocals !== 'false';
        }
        else {
          vocal = false
        }
        let is_exclusive = params.get('is_exclusive');
        let isExclusive: boolean = null;
        if (is_exclusive !== null) {
          isExclusive = is_exclusive !== 'false';
        }
        let versionStatus = params.get('versionStatus');
        let status: any = null;
        if (versionStatus !== null && versionStatus.length > 0) {
          status = versionStatus
        }
        let artist = params.get('artist');
        let artistId: number = null;
        if (artist !== null) {
          artistId = parseInt(artist);
        }
        let artistName = params.get('artistType');
        let artistType :string ='None'
        if (artistName !== null) {
          artistType = (artistName);
        }
        let composer = params.get('composer');
        let composerId: number = null;
        if (composer !== null) {
          composerId = parseInt(composer);
        }
        let genre = params.get('genre');
        let genreId: number = null;
        if (genre !== null) {
          genreId = parseInt(genre);
        }
        let sub_genre = params.get('sub_genre');
        let sub_genreId: number = null;
        if (sub_genre !== null) {
          sub_genreId = parseInt(sub_genre);
        }
        let mood = params.getAll('mood');
        let moodId: any[] = null;
        if (mood !== null && mood[0] !== '') {
          moodId = (mood)
        }
        let tempo = params.get('tempo');
        let tempoId: number = null;
        if (tempo !== null) {
          tempoId = parseInt(tempo)
        }
        let filters: Filters = {
          versionStatus: status,
          artistId: artistId,
          composerId: composerId,
          genreId: genreId,
          moodId: moodId,
          duration: duration,
          tempo: tempoId,
          bpm: bpm,
          version: version,
          is_instrumental: true,
          isExclusive: true,
          sub_genre: sub_genreId,
          is_vocals: true,
          page: 1,
          artistType:artistType
        };
        this._filters = filters;

        let ordering = params.get('ordering_by');
        this._ordering = ordering;

        let page = params.get('page');
        if (page !== null) {
          this._currentTracksPage = parseInt(page);
        } else {
          this._currentTracksPage = 1;
        }

        let searchString = params.get('search');
        if (searchString !== null) {
          this._searchString = searchString;
        } else {
          this._searchString = '';
        }
      }),
      this._playerService.currentlyPlaying.subscribe(playingItem => {
        if (!playingItem) {
          this.playingTrack = null;
          this.playingTrackVersion = null;
          return;
        }
        this.playingTrack = playingItem?.track;
        this.playingTrackVersion = playingItem?.version;
      }),
      this._playerService.showMusicPlayer.subscribe(show => {
        this.musicPlayerCollapsed = !show;
      })
    );
    if(this._searchString !=='' || this._filters !== undefined ){
      if(this.currFilt !== undefined){
        this.loadFilter(this.currFilt)
      }
     else{
      this.loadFilter(this._filters)
     }
    }
    else{
      this.loadTracks();
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((s) => s.unsubscribe());
    this._subscriptions = [];
  }
  // ngAfterViewChecked() {
   
  //   if (this.musicPlayerCollapsed != this.musicPlayerCollapsed) { 
  //     this.musicPlayerCollapsed = this.musicPlayerCollapsed;
  //     this._cdr.detectChanges();
  //   }
  // }

  clear() {
    this.searchString = '';
    this.startDate = null;
    this.endDate = null;
    this._playerService.play(null, null);
  }

  loadTracks(page:any='') {

    if (!this.baseTrackOptions) {
      return;
    }
    if (!this.filters) {
      return;
    }

    if(page !== ''){
      this.currentTracksPage =page
    }

    //Beat change detections
    this.loadingTracks = true;
    if (this._getTracksSubscription && !this._getTracksSubscription.closed) {
      this._getTracksSubscription.unsubscribe();
    }

    let params = {
      filters: this.filters,
      startDate: this.startDate,
      endDate: this.endDate,
      ordering_by: this.ordering,
      searchString: this.searchString,
      limit: tracksPageSize,
      page: this.currentTracksPage,
      offset: (this.currentTracksPage, - 1) * tracksPageSize,
    };
    let headers = new HttpHeaders();
    let data = {}
    // if (this.filters.versionStatus) {
    //   data['versionStatus'] = this.filters.versionStatus
    // }
    // if (this.filters.artistId) {
    //   data['artistId'] = this.filters.artistId
    // }
    // if (this.filters.artistType) {
    //   data['artistType'] = this.filters.artistType
    // }
    // if (this.filters.genreId) {
    //   data['genreId'] = this.filters.genreId
    // }
    // if (this.filters.sub_genre) {
    //   data['sub_genre'] = this.filters.sub_genre
    // }
    // if (this.filters.moodId) {
    //   data['moodId'] = (this.filters.moodId)
    // }
    if (this.filters.isExclusive || this.filters.isExclusive === false) {
      data['isExclusive'] = this.filters.isExclusive
    }
    if (this.filters.is_instrumental || this.filters.is_instrumental === false) {
      data['instrumental'] = this.filters.is_instrumental
    }
    if (this.filters.is_vocals === true || this.filters.is_vocals === false) {
      data['is_vocal'] = this.filters.is_vocals
    }
    // if (this.filters.tempo) {
    //   data['tempo'] = this.filters.tempo
    // }
    // if (this.filters.bpm) {
    //   data['bpm'] = this.filters.bpm
    // }
    // if (this.filters.version) {
    //   data['versions'] = this.filters.version
    // }

    // if (this.EDate) {
    //   data['end_date'] = this.EDate
    // }

    // if (this.sDate) {
    //   data['start_date'] = this.sDate
    // }
    // if (this.searchString) {
    //   data['search'] = this.searchString
    // }
    // dateChoices: string[] = ['All Tracks', 'Date Created', 'Date Approved'];
    // if (this.DType == 'Date Created') {
    //   data['created_at'] = true
    // }
    // if (this.DType == 'Date Approved') {
    //   data['approved_at'] = true;
    // }
    // if (!this.searchString) {
    //   data['page'] = this.currentTracksPage
    // }
  
    if (this.ordering) {
      if (this.ordering == 'approved_at') {
        data['ordering_by'] = 'approved_at'
      }
      else if (this.ordering == '-approved_at') {
        data['ordering_by'] = '-approved_at'
      }
      if (this.ordering == 'updated_at') {
        data['ordering_by'] = 'updated_at'
      }
      else if (this.ordering == '-updated_at') {
        data['ordering_by'] = '-updated_at'
      }
      else if (this.ordering === "id") {
        data['ordering_by'] = '-created_at'
      } else {
        data["ordering_by"] = this.ordering;
      }
    } else {
      data['ordering_by'] = '-created_at'
    }
    if(this.typeSelected == 'Date Updated'){
      data['ordering_by'] = '-updated_at'
    }

console.log(this.selectedDateType)
    this._trackService.currentFilter = data;
    this.api.doGET(environment.apiURL + '/api/v1/track/track_list/',
      {
        headers: headers,
        params: data
      }
    ).subscribe(t => {
      this.loadingTracks = false;
      this.totalTracks = t['count'];
      if (t['results']) {
        this._tracksSubject.next(t['results']);
        this.tracks = t['results'];
      }

      this.pagination = {
        next: t['next'],
        previous: t['previous'],
      }

      setTimeout(()=>{
        this.loadingTracks = false;
      }, 6000);

      this._cdr.detectChanges();
    })
    // this._getTracksSubscription = this._trackService.getTracks(Object.assign(Object.assign({}, this.baseTrackOptions), params))
    //   .subscribe((t) => {
    //     console.log(params)
    //     this.totalTracks = t.count;
    //     this._tracksSubject.next(t.results);
    //     this.loadingTracks = false;
    //   }, (error) => {
    //     console.log(error);
    //     //alert("An error ocurred while getting tracks. Check your connection and try again")
    //   });
  }

  loadFilter(filter:any='', page:any=""){
    this.loadingTracks = true
    if(filter !==''){
      this.filters = filter
    }
    else{
      if(this.currFilt !== undefined)
      this.filters = this.currFilt
    }
    if(page !== ""){
      this.currentTracksPage = page
    }
    let params = {
      filters: this.filters,
      startDate: this.startDate,
      endDate: this.endDate,
      ordering_by: this.ordering,
      searchString: this.searchString,
      limit: tracksPageSize,
      page: this.currentTracksPage,
      offset: (this.currentTracksPage, - 1) * tracksPageSize,
    };
    let headers = new HttpHeaders();
    let data = {}
    if (this.filters?.versionStatus) {
      data['versionStatus'] = this.filters.versionStatus
    }
    if (this.filters.artistId) {
      data['artistId'] = this.filters.artistId
    }
    if (this.filters.artistType) {
      data['artistType'] = this.filters.artistType
    }
    if (this.filters.genreId) {
      data['genreId'] = this.filters.genreId
    }
    if (this.filters.sub_genre) {
      data['sub_genre'] = this.filters.sub_genre
    }
    if (this.filters.moodId) {
      data['moodId'] = (this.filters.moodId)
    }
    if (this.filters.isExclusive || this.filters.isExclusive === false) {
      data['isExclusive'] = this.filters.isExclusive
    }
    if (this.filters.is_instrumental || this.filters.is_instrumental === false) {
      data['instrumental'] = this.filters.is_instrumental
    }
    if (this.filters.is_vocals === true || this.filters.is_vocals === false) {
      data['is_vocal'] = this.filters.is_vocals
    }
    if(this.filters.is_mibe_owned === true || this.filters.is_mibe_owned === false){
      data['is_mibe_owned'] = this.filters.is_mibe_owned;
    }
    if (this.filters.tempo) {
      data['tempo'] = this.filters.tempo
    }
    if (this.filters.bpm) {
      data['bpm'] = this.filters.bpm
    }
    if (this.filters.version) {
      data['versions'] = this.filters.version
    }

    if (this.EDate) {
      data['end_date'] = this.EDate
    }

    if (this.sDate) {
      data['start_date'] = this.sDate
    }
    if (this.searchString) {
      data['search'] = this.searchString
    }
    if (this.DType == 'Date Created') {
      data['created_at'] = true
    }
    if (this.DType == 'Date Updated') {
      data['updated_at'] = true
    }
    if (this.DType == 'Date Approved') {
      data['approved_at'] = true;
    }
    if (this.DType == 'Date Updated') {
      data['updated_at'] = true;
    }
    
    if (this.ordering) {
      if (this.ordering == 'approved_at') {
        data['ordering_by'] = 'approved_at'
      }
      else if (this.ordering == '-approved_at') {
        data['ordering_by'] = '-approved_at'
      }
      if (this.ordering == 'updated_at') {
        data['ordering_by'] = 'updated_at'
      }
      else if (this.ordering == '-updated_at') {
        data['ordering_by'] = '-updated_at'
      }

      else if (this.ordering === "id") {
        data['ordering_by'] = '-created_at'
      } else {
        data["ordering_by"] = this.ordering;
      }
    } else {
      data['ordering_by'] = '-created_at'
    }
    
   
    data['page'] = this.currentTracksPage
    this._trackService.currentFilter = data;
    this.api.doGET(environment.apiURL + '/api/v1/track/filter/',
      {
        headers: headers,
        params: data
      }
    ).subscribe(t => {
      this.totalTracks = t['count'];
      this._tracksSubject.next(t['results']);
      this.tracks = t['results'];
      this.pagination = {
        next: t['next'],
        previous: t['previous'],
      };
      this.loadingTracks = false;
      this._cdr.detectChanges();
    })
  }

  sortChange(data: MatSortable) {
    this.ordering = (data.start === 'desc' ? '-' : '') + data.id;
  }
  filternew(){
    if (this._filters?.clear == true && this.EDate == null && this.sDate == null) {
      this.isFilter = false;
      
      this.loadTracks()
    }
    else {
      this.isFilter = true;
      this.loadFilter();
    } 
  }

  submitSearch() {

    //Robert: 5/16/24 - Prevent last saved track from playing 
    localStorage.setItem('editData','no')

    this.pagination = {
      next: null,
      previous: null
    };
    this.currentTracksPage = 1;
    this.currPage = 1;
    this.searchFilterFocused = false;
    if (this.searchString == null && !this.isFilter && this.searchString == '') {
      
      this.loadTracks()
    }
    else {
      this.loadFilter(this.currFilt);
    }

    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: this._params,
    });
  }

  displayExportOptionsDialog() {
    this._dialogService.displayExportOptionsDialog();
  }

  displayDownloadResultsDialog() {
    this._dialogService.displayDownloadResultsDialog(this.filters, this.totalTracks);
  }

  toggleMusicPlayerCollapsed() {
    this.musicPlayerCollapsed = !this.musicPlayerCollapsed;
    //this.collapse.emit();
   // this._playerService.updateIsPlaying(false);
   // this._playerService.updateCollapse(!this.musicPlayerCollapsed);
    //this._playerService.updateIsPlaying(true);
  }

  collapseMusicPlayer() {
    this.musicPlayerCollapsed = true;
  }

  setPlayingTrack(track: Track) {
    this.playingTrack = track;
  }

  setPlayingTrackVersion(trackVersion: TrackVersion) {
    this.playingTrackVersion = trackVersion;
  }

  sDate: any;
  EDate: any;
  changedate(e) {
    if (e) {
      var date = new Date(e);

      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      this.sDate = year + "-" + month + "-" + day
    }
    else {
      this.sDate = null
    }


    //this.loadTracks() 
   //  this.loadTracks() 
  }
  changedateend(e) {
    if (e) {
      var date = new Date(e);

      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      this.EDate = year + "-" + month + "-" + day
    }
    else {
      this.EDate = null
    }
    
    if (this.sDate && this.EDate) {
      this.currentTracksPage = 1
      this.loadFilter(this.currFilt)
    }
    else if (this.sDate == null && this.EDate == null && ( this.currFilt && this.currFilt['clear'] === true)) {
      this.DType ='All Tracks'
      
      this.loadTracks()
    }
    else if (this.sDate == null && this.EDate == null && ( this.currFilt && this.currFilt['clear'] === false)) {
      this.loadFilter(this.currFilt)
    }
    else if(this.sDate == null && this.EDate == null && this.currFilt === undefined){
      this.DType ='All Tracks'

      
      this.loadTracks()
    }
  }
  dateTypeSelectd: any;
  dateTypeChange(e) {

    // this.sDate = null
    // this.EDate = null
    console.log(e)
    if (e) {
      this.dateTypeSelectd = e

    }
    else {
      this.dateTypeSelectd = null;
    }
  }
  typeSelected: any = ''
  DType: string = 'All Tracks'
  dateType(e) {
    this.typeSelected = e;
    
    if (e == 'All Tracks') {
      // this.DType = null
      this.DType ='All Tracks'
    }
    if (e == 'Date Created') {
      this.DType = 'Date Created'
    }
    else if (e == 'Date Approved') {
      this.DType = "Date Approved";
    }
    else if (e == 'Date Updated') {
      this.DType = "Date Updated";
    }
    
  this.loadTracks()
  }

  currPage: number = 1
  pageTrigger1(e) {
    let perm = {}, samepage = 0

  //   if (this.EDate) {
  //     perm['end_date'] = this.EDate
    
  //  //   perm['date_filter_type'] = this.dateTypeSelectd
  //   }
  //   else {

  //   }
  //   if (this.sDate) {
  //     perm['start_date'] = this.sDate;
  //   //  perm['date_filter_type'] = this.dateTypeSelectd

  //   }
  //   if(this.dateTypeSelectd){
  //     perm['date_filter_type'] = this.dateTypeSelectd
  //   }
  //   if (this.searchString) {
  //     perm['search'] = this.searchString

  //   }
    // if (this.ordering) {
    //   // if (this.ordering == 'approved_at') {
    //   //   perm['ordering_by'] = 'status'
    //   // }
    //   // else if (this.ordering == '-approved_at') {
    //   //   perm['ordering_by'] = '-status'
    //   // }
    //   // else if (this.ordering === "id") {
    //   //   perm['ordering_by'] = '-created_at'
    //   // } else {
    //   //   perm["ordering_by"] = this.ordering;
    //   // }
    //   perm["ordering_by"] = this.ordering;
    // } else {
    //   perm['ordering_by'] = '-created_at'
    // }

    // if (this.currFilt !== undefined) {
    //   for (const key of Object.keys(this.currFilt)) {
    //     const val = this.currFilt[key];

    //     if (this.currFilt[key] && val !== undefined) {
    //       perm[key] = val;
    //     }
    //     else {
    //       if (key == 'is_instrumental' || key == 'isExclusive' || key == 'is_vocals')
    //         perm[key] = val;
    //     }
    //   }


    // }

    let headers = new HttpHeaders();
    //perm['page'] = e;

    // console.log(perm)

    let requestURL = environment.apiURL + '/api/v1/track/track_list/';

    if (this.currPage > e) {
      samepage = 1
      if (this.pagination.previous) {
        let url
        requestURL = this.pagination.previous;
        if (this.pagination.previous.split('&page=')[1]?.split('&')[1]) {
          url = this.pagination.previous.split('&page=')[0] + '&' + this.pagination.previous.split('&page=')[1].split('&')[1]
          requestURL = url + '&page=' + e;
        }
        else if (this.pagination.previous.split('&page=')[0]) {
          url = this.pagination.previous.split('&page=')[0]
          requestURL = url + '&page=' + e;
        }
        // requestURL = requestURL.split('&page=')[0];

      }
    }
    else if (this.currPage < e) {
      samepage = 1
      if (this.pagination.next) {
        let url
        requestURL = this.pagination.next;
        if (this.pagination.next.split('&page=')[1]?.split('&')[1]) {
          url = this.pagination.next.split('&page=')[0] + '&' + this.pagination.next.split('&page=')[1].split('&')[1]
          requestURL = url + '&page=' + e;
        }
        else if (this.pagination.next.split('&page=')[0]) {
          url = this.pagination.next.split('&page=')[0]
          requestURL = url + '&page=' + e;
        }
        // requestURL = requestURL.split('&page=')[0];
        // requestURL = requestURL + '&page=' + e;
      }
    }

   //   if (samepage == 1) {
        this.loadingTracks = true;
        this.currPage = e;
        this.currentTracksPage = e;
        samepage = 0
        this.api.doGET(requestURL,
          {
            headers: headers,
            params: perm
          }
        ).subscribe(t => {
          this.totalTracks = t['count'];
          this._tracksSubject.next(t['results']);
          this.tracks = t['results'];
          this.pagination = {
            next: t['next'],
            previous: t['previous'],
          };
          this.loadingTracks = false;
          this._cdr.detectChanges();

        }), (error) => {
          //alert("An error ocurred while getting tracks. Check your connection and try again")
        }
      //}
    }

    pageTrigger(e){
    
      if (this._filters?.clear == true && this.EDate == null && this.sDate == null) {
        this.isFilter = false;
        
        this.loadTracks(e)
      }
      else {
        this.isFilter = true;
        this.loadFilter('',e);
      } 
    }
}
