import { Injectable } from '@angular/core';

export const darkTheme = {
  'black': '#000000',
  'white': '#ffffff',
  'red': '#ff0000',
  'hl-green': '#50e3c2',
'hl-blue': '#47cdff',
'txt-grey': '#8a8a8d',
'txt-light-grey': '#bebebe',
'txt-color': '#15151C',
'bg-dark': '#0c0c12',
'bg-dark-grey': '#15151c',
'bg-grey': '#202027',
'bg-light-grey':'#F8F8F8',
'bg-card':'#FFFFFF',
'border-grey': '#D8D8D8',
'border-light-grey':'#D6D6D6',
};

export const lightTheme = {
  'primary-color': '#fff',
  'background-color': '#e5e5e5',
  'text-color': '#2d2d2d'
};

@Injectable({ providedIn: 'root' })
export class ThemeService {
  toggleDark() {
    this.setTheme(darkTheme);
  }

  toggleLight() {
    this.setTheme(lightTheme);
  }

  private setTheme(theme: {}) {
    // //alert('ji')
    Object.keys(theme).forEach(k =>
      document.documentElement.style.setProperty(`$${k}`, theme[k])
    );
  }
}