<div class="home" >
    <div class="main">
        <div class="track-list" [ngClass]="{'music-player-visible': playingTrack && playingTrackVersion, 'music-player-collapsed': musicPlayerCollapsed}">
            <!-- <app-loader *ngIf="loadingTracks"></app-loader> -->
            <app-my-tracks
                class="tracks"
                (collapseMusicPlayer)="collapseMusicPlayer()"
            ></app-my-tracks>

            <div class="music-player-wrapper"
            [ngClass]="{hide: !playingTrack || !playingTrackVersion, collapsed: musicPlayerCollapsed}">
                <button type="button"
                class="music-player-expand-button"
                (click)="toggleMusicPlayerCollapsed()">
                    <svg width="18px" height="9px" viewBox="0 0 18 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="player_close" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="closed-player" transform="translate(-730.000000, -169.000000)" class="inner-stroke" stroke="#50E3C2">
                                <polyline id="Path-2" points="730.682129 177.754395 738.843146 169.593378 747.004163 177.754395"></polyline>
                            </g>
                        </g>
                    </svg>
                </button>
                <app-new-musicplayer [track]="playingTrack"
                [version]="playingTrackVersion"
                [ngClass]="{hide: !playingTrack || !playingTrackVersion, collapsed: musicPlayerCollapsed}"
                (collapse)="toggleMusicPlayerCollapsed()"
            class="music-player"></app-new-musicplayer>
            </div>
        </div>
    </div>
</div>

