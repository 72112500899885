import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  ViewChild,
  ElementRef,
  NgZone,
  Renderer2,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { Track } from '../models/track.model';
import { TrackVersion } from '../models/trackversion.model';
import { PlayerService, PlayingItem } from '../services/player.service';
import { UserService } from '../services/user.service';
import { TrackVersionService } from '../services/trackversion.service';
import { User } from '../models/user.model';
import { DialogService } from '../services/dialog.service';
import { Subscription, fromEvent } from 'rxjs';
import { WaveService } from 'angular-wavesurfer-service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

declare var MediaMetadata: any;
@Component({
  selector: 'app-new-musicplayer',
  templateUrl: './new-musicplayer.component.html',
  styleUrls: ['./new-musicplayer.component.scss'],
  providers: [WaveService]
})
export class NewMusicplayerComponent implements OnInit, OnDestroy {
  show = false;
  wave: WaveSurfer;
  hide: any;
  countTime:any;
  togglesvg() {
  this.show = !this.show


}
  @Input()
  set track(t: Track) {
    this._track = t;
    this.updateMediaSession();
  }
  get track(): Track {
    return this._track;
  }
  @Input()
  version: TrackVersion;
  @Input()
  set playbackProgress(p: number) {
    this._playbackProgress = p;
    let duration = this.version.duration;
    if (!this._platform.isBrowser) { return; }
    if (!isNaN(this.audioEl.nativeElement.duration) && this.audioEl.nativeElement.duration != Infinity) {
     // duration = this.audioEl.nativeElement.duration;
    }
    let newTime = duration * p;
    this.audioEl.nativeElement.currentTime = newTime;
    this.currentTime = this.audioEl.nativeElement.currentTime;
    this._playerService.updatePlaybackProgress(this._playbackProgress);
  }
  get playbackProgress(): number {
    return this._playbackProgress;
  }

  @Output()
  collapse = new EventEmitter();

  @ViewChild('audioEl', {static: true})
  audioEl: ElementRef<HTMLAudioElement>;

  set isPlaying(p: boolean) {
    this._isPlaying = p;
    if (!this._platform.isBrowser) { return; }
    if (p && this.audioEl.nativeElement.paused) {
      setTimeout(() => {
        this.audioEl.nativeElement.play();
        this._cancelAnimationFrame = this._ngZone.runOutsideAngular(() => requestAnimationFrame(() => {
          this.animationFrameCb()
        }))
      });
    } else if (!p && !this.audioEl.nativeElement.paused) {
      this.audioEl.nativeElement.pause();
      this.cancelAnimationFrame();
    }
  }
  get isPlaying(): boolean {
    return this._isPlaying;
  }

  get previousItem(): PlayingItem {
    return this._playerService.previousItem;
  }
  get nextItem(): PlayingItem {
    return this._playerService.nextItem;
  }
  get isFavorited(): boolean {
    return this.currentUser && this.version && this.version.favorited_by_me;
  }

  currentTime: number = 0;
  currentUser: User;
  loadingPercentage:any;
  loadingTracks: boolean = true;
  duration:number =0 
  url :string =''

  private _cancelAnimationFrame: number;
  private _track: Track;
  private _isPlaying = false;
  private _playbackProgress = 0;
  private _subscriptions: Subscription[] = [];

  constructor(
    public _playerService: PlayerService,
    private _ngZone: NgZone,
    private _platform: Platform,
    private _renderer: Renderer2,
    private _userService: UserService,
    private _trackVersionService: TrackVersionService,
    private _dialogService: DialogService,
    public waveService: WaveService,public cdr: ChangeDetectorRef,
    private http:HttpClient
  ) {   this._playerService.updateLoader(true); }
  waveStatus:boolean = false;
  nextTab = false;
  wavePlay = false;
  preview = false

  ngOnInit() {

    // this._subscriptions.push(
    //   this._playerService.isPlaying.subscribe((p) => {
    //     this.isPlaying = p;
       
    //   })
    // );
    this._subscriptions.push(
      this._playerService.isNextTab.subscribe((p) => {
        this.nextTab = p;
      })
    );
    this._playerService.isWavePlay.subscribe((p) => {
      this.wavePlay = p;
      this.isPlaying =   this.wavePlay
      //console.log(this.wavePlay)
    })

    this._subscriptions.push(
      this._playerService.isViewType.subscribe((p) => {
        this.preview = p;
      })
    );
   
    this._subscriptions.push(
      this._playerService.playbackProgress.subscribe((p) => {
        if (this._playbackProgress != p) {
          this.playbackProgress = p;
        }
      })
    );
    this._subscriptions.push(this._userService.currentUserStream.subscribe(u => {
      this.currentUser = u;
    }));
    if (this._platform.isBrowser) {
      this._subscriptions.push(fromEvent(window.document, 'keydown').subscribe((e: KeyboardEvent) => {
        if (e.keyCode === 32 || e.code === 'Space') {
          let activeNodeName = window.document.activeElement.nodeName.toLowerCase();
          if (activeNodeName == 'input' || activeNodeName == 'textarea' || activeNodeName == 'button' || activeNodeName == 'select') {
            return;
          }
          if (!this.track || !this.version) { return; }
          e.preventDefault();
          // if (this.isPlaying) {
          //   this.pause();
          // } else {
          //   this.play();
          // }
        }
      }));
    }
    let aud = document.getElementById('aud')
    aud['muted'] = true;
    if (this.nextTab == true && this.preview == true) {
      this.wave.stop()
    }
    if (this.nextTab == true && this.wave !== null && this.preview == true) {
      this.wave.stop()
    }
    // if(this.preview === true && this.nextTab === false) {
    //   this.wave =null
    // }
    else {
      this.wave = this.waveService.create({
        container: '#basetemplate',
      hideScrollbar: true,
      barGap: 3,
      barWidth: 1,
      waveColor: 'white',
      progressColor:'black',
      cursorColor: '#50e3c2',
      height: 65,
      backend: 'MediaElement'
      });
      this._playerService.currentlyPlaying.subscribe(x => {
     
       // this.wave.stop();
        //console.log(x)
        if (x) {
          if (this.nextTab == false) {
           
            let url:any = `${environment.apiURL}/api/v1/trackversions/get-version-waveform/?version_id=${x.version.id}`
      this.http.get(url).subscribe((y:any)=>{
        this.currentTime =0
        this.duration =0
        this.url = x.version.mp3_file;
        // this.wave.stop()
        let hostUrl = environment.awsHost + y.mp3_file
        let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
       if(isSafari){
      let  result = hostUrl.replace(/.mp4/g, ".mp3");
        y['waveform_file_data'] ? this.wave.load(result,y.waveform_file_data) : this.wave.load(y.mp3_file);
       }
       else{
        y['waveform_file_data'] ? this.wave.load(hostUrl,y.waveform_file_data) : this.wave.load(y.mp3_file);
       }

        this.wave.on('ready', () => {
          this._playerService.updateLoader(false);
         // this.isPlaying = true
          this.duration = this.wave.getDuration()
          this.currentTime = this.wave.getCurrentTime();
          this.loadingTracks = false
          this.cdr.detectChanges();
          this.play();
         // this.wave.setMute(false)
          this.updateTimer()
          this.wave.play();
          this.waveStatus = true
          
        })
      })
         
            this.loadingTracks = true
          }
          else{
            if(this.wavePlay=== false){
              if(this.wave !== null)
              this.wave.stop()
              this.isPlaying = false;
              this.cdr.detectChanges();
               }
               else{
                 if(this.wave !== null && this.preview == false){
                this._playerService.updateLoader(false);
                this.wave.playPause()
                this.isPlaying = true;
                this.cdr.detectChanges();
                this.wave.on('audioprocess', () => {
                this.currentTime = this.wave.getCurrentTime();
                this.duration = this.wave.getDuration()
                this.cdr.detectChanges();
                })
              }
              else{
                if(this.wave !== null)
                this.wave.stop()
                this.cdr.detectChanges();
              }
               }
          }
        }
        else{
          if(this.wave !== null){
            this.wave.stop();
            
          }
        }
      })
      if (this.preview === false) {
        this.wave.on('ready', () => {
          this._playerService.updateLoader(false);
         // this.isPlaying = true
          this.duration = this.wave.getDuration()
          this.currentTime = this.wave.getCurrentTime();
          this.loadingTracks = false
          this.cdr.detectChanges();
          this.play();
         // this.wave.setMute(false)
          this.updateTimer()
          this.wave.play();
          this.waveStatus = true
          
        })
        this.wave.on('finish', () => {
          this.wave.stop();
       //   this.wave.setMute(true)
          this.updateTimer()
          this.ended();
          this.waveStatus = false;
        })
        this.wave.on('loading', (x) => {
          this.wave.stop();
          this.loadingPercentage = x;
          this.updateTimer();
        })

        this.wave.on('audioprocess', () => {
          this.currentTime = this.wave.getCurrentTime();
          this.duration = this.wave.getDuration()
          this.cdr.detectChanges();
          this.updateTimer()
        })
      }
      else {
        if(this.wave !== null){
        this.wave.stop();
        }
      }
    }

  }
  updateTimer() {
   
    var formattedTime = this.secondsToTimestamp(this.wave.getCurrentTime());
   let x =  (document.querySelector('.current-time') as HTMLSpanElement)
   x.innerText = this.waveStatus === true ?formattedTime : '00:00'
   this.currentTime = this.wave.getCurrentTime()
   this.cdr.detectChanges();
  }
   secondsToTimestamp(seconds) {
    seconds = Math.floor(seconds);
    var h = Math.floor(seconds / 3600);
    var m = Math.floor((seconds - (h * 3600)) / 60);
    var s = seconds - (h * 3600) - (m * 60);
  
    var hh = h < 10 ? '0' + h : h;
    var mm = m < 10 ? '0' + m : m;
    var ss = s < 10 ? '0' + s : s;
    return  mm + ':' + ss;
    
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => {
      s.unsubscribe();
    });
    this.cancelAnimationFrame();
    this.wave= null
    this._playerService.nextTab(true);
    this._playerService.updateWavePlay(false);
    this._playerService.updateIsPlaying(false);
    this._playerService.updateShowMusicPlayer(false);
    this._playerService.play(null, null);
  }
  getTime(){
    return this.wave.getCurrentTime() || 0
  }
  play() {
    if (this.isPlaying) { return; }
    if(this.loadingTracks === false){
    this.wave.play()
    // this.wave.on('audioprocess', () => {
    //   //console.log(this.wave.getCurrentTime())
    //   this.currentTime = this.wave.getCurrentTime();
    //   this.duration = this.wave.getDuration()
    //   this.cdr.detectChanges();
   // })
    this._playerService.updateIsPlaying(true);
    this._playerService.playTrackSubject.next(true);
    this._playerService.updateWavePlay(true)

    }
   
  }
  pause(eve) {
    if (eve.srcElement.currentTime && eve.srcElement.duration && eve.srcElement.currentTime === eve.srcElement.duration) return;
    this.wave.pause()
    if (!this.isPlaying) { return; }
    if(this.wave !== null){
   
    this._playerService.clearPlayingSubject.next(true)
    // this.wave.on('audioprocess', () => {
    //   //console.log(this.wave.getCurrentTime())
    //   this.currentTime = this.wave.getCurrentTime();
    //   this.duration = this.wave.getDuration();
    //   this.cdr.detectChanges();
    // })
    this._playerService.updateIsPlaying(false);
    this._playerService.updateWavePlay(false)
    }
  }
  ended() {
    if (!this.nextItem) { return; }
    this.currentTime =0
    this.duration =0
    this.wave.stop();
    this._playerService.updateWavePlay(true);
    this._playerService.updateLoader(true);
   // this._playerService.updateIsPlaying(true);
    this._playerService.next();
  }
  audioTimeUpdate() {
    if (!this._platform.isBrowser) { return; }
  //  let duration = this.version.duration;
    // if (!isNaN(this.audioEl.nativeElement.duration) && this.audioEl.nativeElement.duration != Infinity) {
    //   duration = this.audioEl.nativeElement.duration;
    // }
    // this.currentTime = this.audioEl.nativeElement.currentTime;
    // this._playbackProgress = this.currentTime / duration;
    this._playerService.updatePlaybackProgress(this._playbackProgress);
  }
  audioCanPlay() {
    if (!this._platform.isBrowser) { return; }
    if (this.isPlaying && this.audioEl.nativeElement.paused) {
      this.audioEl.nativeElement.play();
    }
  }
  skipForward() {
    if (!this.nextItem) { return; }
    this.currentTime =0
    this.duration =0
    this._playerService.updateLoader(true);
    this._playerService.updateIsPlaying(true);
    this._playerService.updateWavePlay(true);
    this._playerService.next();
    this.cdr.detectChanges();
  }
  skipBack() {
    // if (this.currentTime > 2) {
    //   this.playbackProgress = 0;
    //   return;
    // }
    if (!this.previousItem) { return; }
    this.wave.stop();
    this.currentTime =0
    this.duration =0
    this._playerService.updateLoader(true);
    this._playerService.updateIsPlaying(true);
    this._playerService.previous();
    this.cdr.detectChanges();
  }

  collapsePlayer() {
    this.collapse.emit();
  }

  amOwner(track: Track): boolean {
    if (!this.currentUser || !track) return false;
    return track.owned_by == this.currentUser.id;
  }

  artistFeatured(track: Track): boolean {
    if (!track) return false;
    return track.artist && track.artist.is_featured;
  }

  displayTrackInfo(track: Track) {
    if (!track) return;
    // this._dialogService.displayTrackInfo(track);
  }

  editTrackDetail(track: Track) {
    if (!track) return;
    // this._dialogService.displayCreateTrack(track);
  }

  addTrackVersion(track: Track) {
    if (!track) return;
    // this._dialogService.displayUploadVersion(track);
  }

  editVersionDetail(track: Track, version: TrackVersion) {
    if (!track || !version) return;
    // this._dialogService.displayUploadVersion(track, version);
  }

  private animationFrameCb() {
  if (!this._platform.isBrowser) { return; }
    if (this.audioEl.nativeElement.currentTime != this.currentTime) {
      this._ngZone.run(() => this.audioTimeUpdate());
    }
    this._cancelAnimationFrame = requestAnimationFrame(() => {
      this.animationFrameCb()
    })
  }
  private cancelAnimationFrame() {
    if (this._platform.isBrowser && this._cancelAnimationFrame) {
      cancelAnimationFrame(this._cancelAnimationFrame);
    }
  }

  private updateMediaSession() {
    if (!this._platform.isBrowser) { return; }
    if (!('mediaSession' in navigator)) { return; }
    if (!this.track) { return; }
    (<any>navigator).mediaSession.metadata = new MediaMetadata({
      title: this.track.title,
      artist: this.track.artist ? this.track.artist.name : '',
      // album: "Podcast Name",
      // artwork: [{src: "podcast.jpg"}]
    });
    (<any>navigator).mediaSession.setActionHandler('play', () => {
      this.play();
    });
    (<any>navigator).mediaSession.setActionHandler('pause', () => {
      this.pause("");
    });
    (<any>navigator).mediaSession.setActionHandler('previoustrack', () => {
      this.skipBack();
    });
    (<any>navigator).mediaSession.setActionHandler('nexttrack', () => {
      this.skipForward();
    });
  }
  getCurrentPreview() {
    this.currentTime = this.wave.getCurrentTime()
    this.cdr.detectChanges();
    setTimeout(()=>{  
      this.currentTime = this.wave.getCurrentTime()
      this.cdr.detectChanges();
    }, 100);
  }
}

