import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  AfterViewInit,
  ViewChild,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { MatSort, MatSortable } from '@angular/material/sort';
import {
  trigger,
  style,
  animate,
  transition,
  state,
} from '@angular/animations';
import { Observable, Subscription, BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { Track } from '../models/track.model';
import { TrackVersion } from '../models/trackversion.model';
import { User } from '../models/user.model';
import { PlayerService, PlayingItem } from '../services/player.service';
import { DialogService } from '../services/dialog.service';
import { SliderDialogService } from '../services/slider-dialog.service';
import { TrackStatusValue } from '../models/trackstatus-value.model';
import { TrackStatusPipe } from '../pipes/trackstatus.pipe';
import { EditTrackComponent } from '../edit-track/edit-track.component';
import { EditTrackVersionComponent } from '../edit-track-version/edit-track-version.component';
import { PlaylistTracks } from '../models/playlisttracks.model';
import { ManageClientAccountComponent } from '../manage-client-account/manage-client-account.component';
import { UserUploadPopupComponent } from '../user-upload-popup/user-upload-popup.component';


declare type UserClientListDataSource = Observable<ReadonlyArray<User>>;

export class UserClientListRow {
  constructor(
    public user: User,
  ) {}
}

@Component({
  selector: 'app-user-client-list',
  templateUrl: './user-client-list.component.html',
  styleUrls: ['./user-client-list.component.scss'],
  animations: [
    trigger('rowInOut', [
      transition(':enter', [
        style({
          opacity: '0',
          height: '0',
          paddingTop: '0',
          overflow: 'hidden',
          paddingBottom: '0',
        }),
        animate(
          '0.7s cubic-bezier(0.19, 1, 0.22, 1)',
          style({
            opacity: '1',
            height: '*',
          })
        ),
      ]),
      transition(':leave', [
        style({
          opacity: '1',
          height: '*',
          overflow: 'hidden',
        }),
        animate(
          '0.7s cubic-bezier(0.19, 1, 0.22, 1)',
          style({
            opacity: '0',
            height: '0',
            paddingTop: '0',
            paddingBottom: '0',
          })
        ),
      ]),
    ]),
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', overflow: 'hidden' })
      ),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
  providers: [TrackStatusPipe],
})
export class UserClientListComponent implements OnInit, OnDestroy, AfterViewInit {

  selectedTrack = null;
  selectedVersion = null;

  isOpen = false;
  loadingOverlay = false;

  triggerOrigin: any;

  toggle(trigger: any, track: any) {
    this.selectedTrack = track;

    this.triggerOrigin = trigger;
    this.isOpen = !this.isOpen
  }

  @Input()
  set dataSource(data: UserClientListDataSource) {
    this._dataSource = data
      .pipe(
        map((users) => {
          let retval: UserClientListRow[] = [];
          users.forEach((u) => {
            let row = new UserClientListRow(u);
            retval.push(row);
          });
          return retval;
        })
      );
  }

  @Input()
  set checkedClients(clients: User[]) {
    this._checkedClients = clients;
    this.checkedClientsChanged.emit(this._checkedClients)
  }
  get checkedClients() {
    return this._checkedClients;
  }

  @Output()
  checkedClientsChanged = new EventEmitter<User[]>()

  @Output()
  playingTrack = new EventEmitter<PlaylistTracks>();

  @Output()
  collapseMusicPlayer = new EventEmitter<null>();

  @Output()
  sortChange: EventEmitter<MatSortable> = new EventEmitter<MatSortable>();

  @Output()
  listChange: EventEmitter<null> = new EventEmitter<null>();

  @Input()
  set sortValue(data: string) {
    const start = data && data[0] === '-' ? 'desc' : 'asc';
    const id = data && data[0] === '-' ? data.substring(1) : data || 'id';
    this._sortValue = { id, start } as MatSortable;
  }

  get listDataSource() {
    return this._dataSource;
  }

  private _checkedClients: User[] = [];

  private _dataSource: Observable<ReadonlyArray<UserClientListRow>>;
  private _subscriptions: Subscription[] = [];
  private _dataSourceSubscription: Subscription = null;
  private _sortValue: MatSortable = null;

  displayedColumns: string[] = [
    'name',
    'company_name',
    'project_name',
    'discount_rate',
    'date_joined',
    'last_login',
    'is_disable',
    'can_download',
    'action',
  ];

  @ViewChild(MatSort) sort: MatSort;
  private _sortSubscription: Subscription = null;

  constructor(
    private _userService: UserService,
    private _playerService: PlayerService,
    private _dialogService: DialogService,
    public _DialogService:DialogService,
    private _sliderDialogService: SliderDialogService,
    private _trackStatusPipe: TrackStatusPipe,private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this._sortSubscription = this.sort.sortChange.subscribe(() => {
      let data =''
      if(this.sort.active === "is_active"){
        data = "is_disable"
      }
      else{
        data = this.sort.active
      }
      this.sortChange.emit({
        id: data,
        start: this.sort.direction,
      } as MatSortable);
    });
    if (!this._sortValue) return;
    this.sort.sort(this._sortValue);
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => {
      s.unsubscribe();
    });
    if (this._dataSourceSubscription) {
      this._dataSourceSubscription.unsubscribe();
    }
    if (this._sortSubscription) {
      this._sortSubscription.unsubscribe();
    }
  }

  listTrackBy(_, item: UserClientListRow) {
    return item.user.id;
  }

  userIsChecked(user:User) {
    return this.checkedClients.some(u=>u.id == user.id);
  }

  userChecked(checked:boolean, user:User) {
    if (checked) {
      this._checkedClients.push(user);
      this.checkedClientsChanged.emit(this.checkedClients)
      return;
    }
    let i = this._checkedClients.findIndex(u=>u.id==user.id);
    if (i >= 0) {
      this._checkedClients.splice(i,1);
      this.checkedClientsChanged.emit(this.checkedClients)
    }
  }

  deselectAll() {
    this._checkedClients.length = 0;
    this.checkedClientsChanged.emit(this.checkedClients);
  }

  addToGroupClick(user:User) {
    var from_page = "user_component";
    this._dialogService.displayAddToEmailGroupDialog([user], from_page).subscribe(changed=>{

    });
  }

  activityClick(user:User) {
    this._dialogService.displayClientActivityDialog(user).subscribe(changed=> {
      
    })
  }

  playlistsClick(user:User) {
    this._dialogService.displayManageUserPlaylistsDialog(user).subscribe(changed=>{

    });
  }


  editClick(user:User) {
    user.password = user.password ? user.password : '12345678';
    this._sliderDialogService.open(ManageClientAccountComponent, {
      width: "600px",
      title: "MANAGE CLIENT ACCOUNT",
      panelClass: "edit-track-dialog",
      data: {user:user}
    }, (val)=> {
      if (val != null) {
        this.listChange.emit();
      }
    });
  }

  deleteClick(user:User) {
    this._dialogService.displayDeleteClientDialog(user).subscribe(changed=> {
      if (changed) this.listChange.emit();
    })
  }

  changeCanDownload(user:User) {
 
    this.loadingOverlay = true;
    user['pro'] = user?.pro && user?.pro['id'] ? user.pro['id']:'';
    if(!user.birth_date){
      user.birth_date = new Date();
    }
    this._userService.updateUser(user).subscribe(u=>{
      user = u;
      this.loadingOverlay = false;
    }, (error) => {
      console.log(error);
      this.loadingOverlay = false;
      if(error.error.detail){
      // this._dialogService.UserUploadPopupConfirmPopup(UserUploadPopupComponent);
      let data = error.error?.error ? error.error?.error : (error.error?.detail ?error.error?.detail : (error.error?.details ? error.error?.details:``) )
      this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
      });
      }
      else{
        // alert("An error ocurred while updating the \"Can Upload\" status. Check your connections and try again");
        let data = error.error?.error ? error.error?.error : (error.error?.detail ?error.error?.detail : (error.error?.details ? error.error?.details:``) )
        this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
        });
      }
      user.can_download = !user.can_download;
      //alert("An error ocurred while updating the \"Can Download\" status. Check your connections and try again");
    });
  }
  getShows(parm){
    let show = ''
   let index = 0
    for(let pr of parm){
      if(index === 0){
        show = pr.name
      }
      else{
        show = show + ',' + pr.name
      }
     
      index++
    }
    return show
  }
}
