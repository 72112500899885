<form [formGroup]="profileForm">
<div class="profile-info">
<div class="menu-user-info">
<div class="section general">
        <div class="title">
            <h3>User DETAILS<span class="allfields"><small>*All fields required </small></span></h3>
        </div>
        
        <div class="grid-container">

            <div class="grid-item">
                <mat-form-field appearance="fill">
                    <mat-label>First Name</mat-label>
                    <input matInput formControlName="first_name">
                </mat-form-field>
            </div>
            <div class="grid-item">
                <mat-form-field appearance="fill">
                    <mat-label>Last Name</mat-label>
                    <input matInput formControlName="last_name">
                </mat-form-field>
            </div>
        </div>
        <div class="grid-card">
            <div class="grid-item">
                <mat-form-field appearance="fill">
                    <mat-label>Email Address</mat-label>
                    <input matInput formControlName="email">
                </mat-form-field>
            </div>
        </div>
        <div class="grid-container">

                <div class="grid-item">
                    <mat-form-field appearance="fill">
                        <mat-label>Nick Name</mat-label>
                        <input matInput formControlName="nick_name">
                    </mat-form-field>
                </div>
                <div class="grid-item">
                   <!--  <mat-form-field appearance="fill">
                        <mat-label>Birthday</mat-label>
                        <input type="date" class="vvvvv" matInput formControlName="birth_date">
                    </mat-form-field> -->
                    <mat-form-field appearance="fill">
                        <mat-label>Birthday</mat-label>
                        <input matInput [matDatepicker]="picker" formControlName="birth_date" [max]="maxDate">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
    </div>
    <hr>


    <div class="section contact">
        <div class="title">
            <h3>Contact<span class="allfields"><small>*All fields required </small></span></h3>
        </div>
        <div class="grid-card">
            <div class="grid-item">
                <mat-form-field appearance="fill">
                    <mat-label>Address </mat-label>
                    <input matInput formControlName="address1">
                </mat-form-field>
            </div>
        </div>
        <div class="grid-card">
            <div class="grid-item">
                <mat-form-field appearance="fill">
                    <mat-label>Address Line 2</mat-label>
                    <input matInput formControlName="address2">
                </mat-form-field>
            </div>
        </div>
        <div class="grid-container">

            <div class="grid-item">
                <!-- <mat-form-field appearance="fill">
                    <mat-label>Country</mat-label>
                    <input matInput formControlName="country">
                </mat-form-field> -->
                <app-country-select [(currentSelection)]="user.country"></app-country-select>
            </div>

            <div class="grid-item">
                <mat-form-field appearance="fill">
                    <mat-label>City</mat-label>
                    <input matInput [patterns]="customPatterns" formControlName="city">
                </mat-form-field>
            </div>
            <div class="grid-item">
                    <app-state-select [(currentSelection)]="user.state"></app-state-select>    
            </div>
            <div class="grid-item">
                <mat-form-field appearance="fill">
                    <mat-label>Zip</mat-label>
                    <input matInput [patterns]="numberPattern" mask="00000" minlength = "5" formControlName="postal_code">
                </mat-form-field>
            </div>
        </div>
        <div class="grid-card">
            <div class="grid-item">
            <h6 style="margin:0; margin-bottom:4%;font-size: 14px;">PHONE</h6>
                <div class="rows">
                    <div class="phone" *ngFor="let phones of phoneNumberArray; let idx = index">
                        <mat-form-field class="type" appearance="fill">
                            <mat-select [(ngModel)]="phones.number_type"  [ngModelOptions]="{standalone: true}">
                                <mat-option [value]="'mobile'">
                                    Mobile
                                </mat-option>
                                <mat-option [value]="'Office'">
                                    Office
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="number" appearance="fill">
                            <input type="tel" id={{idx}} (keydown)="validateNumber($event)" [value]="currentValue == idx?phoneNumber:phones.phone_number" [(ngModel)]="phones.phone_number" [ngModelOptions]="{standalone: true}" matInput class="form-control" maxlength="12" (input)="getPhoneCode($event.target, idx)">
                        </mat-form-field>
                        <button class="remove" (click)="removeNumber(idx)">
                            <svg width="13px" height="12px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                                stroke-linejoin="round">
                                <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                                    <g id="Genre-Options">
                                    <g id="Close" transform="translate(1439.000000, 32.000000)">
                                        <path d="M24,0 L0,24" id="Path"></path>
                                        <path d="M0,0 L24,24" id="Path"></path>
                                    </g>
                                    </g>
                                </g>
                                </g>
                            </svg>
                        </button>
                    </div>
                    
                    <div class="add-row"><button class="btn-row" (click)="addPhoneNumber()">+ <u>Add</u></button></div>
                </div>
                
            </div>
        </div>
        
</div><hr>
<div class="section contact">
    <div class="title">
        <h3>SOCIAL<span class="allfields"><small>*Optional </small></span></h3>
    </div>
    
    <div class="grid-card" *ngFor="let media of socialArray; let i = index;">
        <div class="grid-item">
            <mat-form-field appearance="fill">
                <mat-label>{{media.social_type}}</mat-label>
                
                <input matInput type="url"  pattern="https?:\/\/*" placeholder="http://www.example.com" [(ngModel)]="media.value" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
            <div *ngIf="media.social_type !== 'FACEBOOK' && media.social_type !== 'INSTAGRAM' && media.social_type !== 'TWITTER' && media.social_type !== 'TIKTOK' && media.social_type !== 'YOUTUBE' && media.social_type !== 'CLUBHOUSE' && media.social_type !== 'SNAPCHAT' && media.social_type !== 'SOUNDCLOUD' && media.social_type !== 'TWITCH'">
                <button class="remove-link"  (click)="removeLink(i); dataRemove=true">
                    <svg width="13px" height="12px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                        stroke-linejoin="round">
                        <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                            <g id="Genre-Options">
                            <g id="Close" transform="translate(1439.000000, 32.000000)">
                                <path d="M24,0 L0,24" id="Path"></path>
                                <path d="M0,0 L24,24" id="Path"></path>
                            </g>
                            </g>
                        </g>
                        </g>
                    </svg>
                </button>
            </div>
        </div>
    </div>
    
            <div class="add-row"><button class="btn-row" (click)="socialModal=true">+ <u>Add</u></button></div>
            
</div><hr>
<div class="section contact">
    <div class="title">
        <h3>UPDATE PASSWORD</h3>
    </div>
    
    <div class="grid-card">
        <div class="grid-item">
            <mat-form-field appearance="fill">
                <mat-label>CURRENT PASSWORD</mat-label>
                <input type="password" matInput formControlName="password"  maxlength="16" minlength="4" >
            </mat-form-field>
        </div>
    </div>
    <div class="grid-card">
            <div class="grid-item">
                <mat-form-field appearance="fill">
                    <mat-label>NEW PASSWORD</mat-label>
                    <input type="password" matInput formControlName="newPassword"  maxlength="16" minlength="4" >
                </mat-form-field>
            </div>
        </div>
        <div class="grid-card">
                <div class="grid-item">
                    <mat-form-field appearance="fill">
                        <mat-label>CONFIRM NEW PASSWORD</mat-label>
                        <input type="password" matInput formControlName="confirmPassword"  maxlength="16" minlength="4" >
                    </mat-form-field>
                </div>
            </div>
            <p *ngIf="errorMessage1 == 1" style="
                color: red;
    font-size: small;
    margin-left: 2px;
    margin-top: -4%;
    margin-bottom: -8%;">{{error}}</p>
<button class="btn save-btn" (click)="updateUserProfile()" mat-button>Save</button>
</div>
</div>
</div>
</form>

<div id="myModal" class="modal" *ngIf="socialModal">
    <!-- Modal content -->
    <div class="modal-content">
        <span class="close" (click)="socialModal=false">&times;</span>
        <span class="sclSpan dialog-header">Add Social Channel</span>
        <div class="inputSocial">
            <div class="grid-card">
                <div class="grid-item">
                    <mat-form-field appearance="fill">
                        <mat-label> Social Channel Name</mat-label>
                        <input matInput (input)="getSocialName($event.target.value)" />
                    </mat-form-field>
                </div>
            </div>
            <div class="grid-card">
                <div class="grid-item">
                    <mat-form-field appearance="fill">
                        <mat-label>Social Channel Url </mat-label>
                        <input matInput type= "url" (input)="getSocialUrl($event.target.value)" />
                    </mat-form-field>
                </div>
            </div>
        </div>
      <button class="btnAdd form-button" style="margin-left:0;" [disabled]="socialData === false" mat-button  (click)="addsociallinks()">Add</button>
    </div>  
  </div>
  <div id="myModal" class="modal" *ngIf="dataRemove">
    <!-- Modal content -->
    <div class="modal-content-remove">
        <span class="close" (click)="dataRemove=false">&times;</span>
        <span class="sclSpan dialog-header">REMOVE SOCIAL CHANNEL</span>
        <div class="inputSocial-info">
            <p>Are you sure you want to remove the following social channel ?</p>
            <p><strong>{{deletedName |titlecase}}</strong></p>
            <div class="form-col">
              <button class="form-button" (click)="removeUser(deletedName)">Delete</button>
              <button class="form-button form-button-cancel" (click)="dataRemove=false">Cancel</button>
            </div>
        </div>
    </div>  
  </div>
<div class="loading-overlay" *ngIf="loadingUserUpdate">
    <app-loader></app-loader>
</div>
