<div [@.disabled]="disableAnimation" class="manage-co-writers">
  <mat-expansion-panel #mep="matExpansionPanel"  hideToggle (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header style="background-color: #fff !important;">
      <mat-panel-title class="titleFlexContainer">
        <div class="card-title titleFlexItem">
          <b>MANAGE CO-WRITERS - {{this.track?.cowritten_by?.length || '0'}}</b>
        </div>
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
      <div *ngIf="panelOpenState">
        <div class="btn btn-link headerFlexItem" style="margin-left:auto;">
          <u>Edit</u>
        </div>
      </div>
      <div *ngIf="!panelOpenState">
        <div class="btn btn-link headerFlexItem" style="margin-left:auto;">
          <u *ngIf="this.track?.cowritten_by?.length ==undefined || this.track?.cowritten_by?.length==0">Add</u>
          <u *ngIf="this.track?.cowritten_by?.length>0">Review</u>
          <u *ngIf="this.track?.cowritten_by?.length>0" class="svg-text"><img class="uploadwidth" src="assets/img/check-circle.png"></u>
        </div>
      </div>
    </mat-expansion-panel-header>
    <!--
    <ag-grid-angular
    class="ag-theme-balham grid-style"
    [rowData]="rowData"
    domLayout='autoHeight'

    [frameworkComponents]="frameworkComponents"
    (gridReady)="onGridReady($event)"
    [columnDefs]="columnDefs">
</ag-grid-angular>

<button mat-button (click)="addRow()">Add</button> -->
<div class="grid-card">
  <div class="grid-item">
  <mat-form-field appearance="fill" style="width:100%;" floatLabel="always">
      <mat-label>CO-WRITERS</mat-label>
      <mat-select class="" [multiple]="true" #coWriter panelClass="card-select card-select1-cowriter"
        [disableOptionCentering]="true" [(ngModel)]="cowriterData" placeholder="Select" (selectionChange)="selectOption($event,track.cowritten_by)">
        <!-- <span class="select-title">Select a Status</span> -->
        <!-- <mat-option [value]="false">All</mat-option> -->
        <mat-option  *ngFor="let status of coWriters" [disabled]="(status.disable == true)"  [value]="status">
          {{status.name}}
        </mat-option>
      </mat-select>
  </mat-form-field>
  <!-- <mat-form-field *ngIf="!unlimit && (track.cowritten_by.length) !== limit" appearance="fill" style="width:100%;" floatLabel="always">
    <mat-label>CO-WRITERS</mat-label>
    <mat-select  class="" [multiple]="true" #coWriter panelClass="card-select card-select1-cowriter"
      [disableOptionCentering]="true" [(ngModel)]="cowriterData" placeholder="Select" (selectionChange)="selectOption($event,track.cowritten_by)">
      <mat-option  *ngFor="let status of coWriters" [disabled]="(status.disable == true)" [value]="status">
      {{status.name}} 
      </mat-option>
    </mat-select>
</mat-form-field> -->
</div></div>
<div class="grid-card card-data1" *ngFor="let co of track.cowritten_by; let i = index">
  <div class="grid-item"  [ngClass]="{'disabledElement': (co.disable === true  )}"  >
    <p>{{co.name}}</p> 
    <!-- <p *ngIf="!co.name"> {{co.first_name}} {{co.last_name}}</p> -->
   <p class="percent-data"><span>   <input class="data" step="00.1" matInput [(ngModel)]="co.percent" (keyup)="$event.preventDefault();$event.stopPropagation();value($event.target.value, i)" type="text" matInput  maxlength="4" 
   (blur)="$event.preventDefault();$event.stopPropagation();onBlur($event.target.value, i)" ><p class="new-per">%</p></span></p>
   <p class="percent-data1"(click)="co.percent = minus(co.percent,i)"><span>-</span></p> 
   <p class="percent-data2" (click)="co.percent = plus(co.percent,i)"><span>+</span></p> 
   <p class="percent-data3" (click)="remove(co);co.percent = 0;selectOption($event,track.cowritten_by)"><span>Remove</span></p>
   
  </div>
</div>
<button class="btn save-btn" (click)="mep.toggle()"  [disabled]="track.cowritten_by.length ===  0" mat-button (opened)="panelOpenState = true" (closed)="panelOpenState = false" (click)="closethisPanel()">continue & save</button>

<p class="card-portion"> The co-writer’s share is based on a portion of your 100% of a song.</p>
    <!-- <mat-table *ngIf="dataSource" [dataSource]="dataSource">
      Name Column
      <ng-container class="table-row1" matColumnDef="user">
        <mat-header-cell *matHeaderCellDef> USER </mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index">
          <mat-form-field appearance="fill">
            <mat-select panelClass="filter-select"
              [ngModel]="element" name="CoWriterFilter"
              [compareWith]="coWriterCompareWith"
              (selectionChange)="coWriterChangedAt($event, i)"
              (openedChange)="openChangedAt($event, i)"
              placeholder="CoWriter"
              #coWriterSelect>
              <mat-option>
                <ngx-mat-select-search
                  [searching]="searchingCoWriter"
                  [formControl]="coWriterFilterCtrl"
                  placeholderLabel="Select an CoWriter">
                  <svg ngxMatSelectSearchClear class="artist-filter-clear-icon filter-clear-icon" width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                          <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                              <g id="Genre-Options">
                                  <g id="Close" transform="translate(1439.000000, 32.000000)">
                                      <path d="M24,0 L0,24" id="Path"></path>
                                      <path d="M0,0 L24,24" id="Path"></path>
                                  </g>
                              </g>
                          </g>
                      </g>
                  </svg>
                </ngx-mat-select-search>
              </mat-option>          
              <mat-option *ngFor="let coWriter of filteredCoWriterOptionsAt(i) | async; trackBy: coWriterTrackBy" [value]="coWriter">
                {{ coWriter.composer_name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-cell>
      </ng-container>

      Name Column
      <ng-container matColumnDef="percent">
        <mat-header-cell *matHeaderCellDef> PERCENT </mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index">
          <input class="numberInput" type="number" [ngModel]="element.composer_percentage" (ngModelChange)="percentageChangedAt($event, i)" placeholder="">
         </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Delete? </mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index;">
          <button class="remove" mat-icon-button (click)="removeAt(i)">
            <svg width="13px" height="12px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
              stroke-linejoin="round">
              <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                  <g id="Genre-Options">
                  <g id="Close" transform="translate(1439.000000, 32.000000)">
                      <path d="M24,0 L0,24" id="Path"></path>
                      <path d="M0,0 L24,24" id="Path"></path>
                  </g>
                  </g>
              </g>
              </g>
            </svg>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table> -->
    <!-- <button mat-button (click)="addElement()">Add</button> -->
  </mat-expansion-panel>
</div>
