import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LoaderComponent } from './loader/loader.component';
import { PaginationComponent } from './pagination/pagination.component';
import { SliderDialogContainerComponent } from './slider-dialog-container/slider-dialog-container.component';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';
import { SliderDialogService } from './services/slider-dialog.service';
import { TrackDetailsService } from './services/track-details.service';
import { SessionStorageService } from './services/session-storage.service';
import { BpmService } from './services/bpm.service';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TrackPreviewComponent } from './track-preview/track-preview.component';
import { EditTrackComponent } from './edit-track/edit-track.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSliderModule } from '@angular/material/slider';
import { TrackStatusComponent } from './track-status/track-status.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatRadioModule } from '@angular/material/radio';
import { TrackDetailsComponent } from './track-details/track-details.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PlayerService } from './services/player.service';
import { MatExpansionModule } from '@angular/material/expansion';
import { ChipsComponentComponent } from './chips-component/chips-component.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { ManageCowritersComponent } from './manage-cowriters/manage-cowriters.component';
import { TrackDescriptionComponent } from './track-description/track-description.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { EditTrackVersionComponent } from './edit-track-version/edit-track-version.component';
import { TrackDetailsForVersionComponent } from './track-details-for-version/track-details-for-version.component';
import { TrackListComponent } from './track-list/track-list.component';
import { PlaylistTrackListComponent } from './playlist-track-list/playlist-track-list.component';
import { MusicplayerComponent } from './musicplayer/musicplayer.component';
import { WaveformComponent } from './waveform/waveform.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkTableModule } from '@angular/cdk/table';
import { DurationPipe } from './pipes/duration.pipe';
import { TrackStatusPipe } from './pipes/trackstatus.pipe';
import { FiltersComponent } from './filters/filters.component';
import { TrackInfoComponent } from './trackinfo/trackinfo.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { RangeDatepickerComponent } from './range-datepicker/range-datepicker.component';
import { ExportOptionsDialogComponent } from './export-options-dialog/export-options-dialog.component';
import { DownloadResultsDialogComponent } from './download-results-dialog/download-results-dialog.component';
import { CSRFInterceptor } from './interceptors/csrf.interceptor';
import { NewPlaylistFolderDialogComponent } from './new-playlist-folder-dialog/new-playlist-folder-dialog.component';
import { DuplicatePlaylistDialogComponent } from './duplicate-playlist-dialog/duplicate-playlist-dialog.component';
import { AddRemovePlaylistDialogComponent } from './add-remove-playlist-dialog/add-remove-playlist-dialog.component';
import { DeletePlaylistDialogComponent } from './delete-playlist-dialog/delete-playlist-dialog.component';
import { RemoveTrackDialogComponent } from './remove-track-dialog/remove-track-dialog.component';
import { ManageTracksClientsDialogComponent } from './manage-tracks-clients-dialog/manage-tracks-clients-dialog.component';
import { AddSongPlaylistDialogComponent } from './add-song-playlist-dialog/add-song-playlist-dialog.component';
import { SelectBackdropClassDirective } from '../select-backdrop-class.directive';
import { UserClientListComponent } from './user-client-list/user-client-list.component';
import { UserArtistListComponent } from './user-artist-list/user-artist-list.component';
import { AddToEmailGroupComponent } from './add-to-email-group/add-to-email-group.component';
import { ManageUserPlaylistsComponent } from './manage-user-playlists/manage-user-playlists.component';
import { ClientActivityDialogComponent } from './client-activity-dialog/client-activity-dialog.component';
import { ManageClientAccountComponent } from './manage-client-account/manage-client-account.component';
import { DeleteClientDialogComponent } from './delete-client-dialog/delete-client-dialog.component';
import { ArtistActivityDialogComponent } from './artist-activity-dialog/artist-activity-dialog.component';
import { ManageArtistAccountComponent } from './manage-artist-account/manage-artist-account.component';
import { CountrySelectComponent } from './country-select/country-select.component';
import { StateSelectComponent } from './state-select/state-select.component';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { DeleteTrackVersionDialogComponent } from './delete-track-version-dialog/delete-track-version-dialog.component';
import { BpmFieldComponent } from './bpm-field/bpm-field.component';
import { UserEmailGroupListComponent } from './user-email-group-list/user-email-group-list.component';
import { NewUserEmailGroupComponent } from './new-user-email-group/new-user-email-group.component';
import { DeleteUserEmailGroupComponent } from './delete-user-email-group/delete-user-email-group.component';
import { RemoveUsersFromEmailGroupComponent } from './remove-users-from-email-group/remove-users-from-email-group.component';
import { NewEmailTemplateDialogComponent } from './new-email-template-dialog/new-email-template-dialog.component';
import { DeleteEmailTemplateDialogComponent } from './delete-email-template-dialog/delete-email-template-dialog.component';
import { DeletePlaylistFoldersDialogComponent } from './delete-playlist-folders-dialog/delete-playlist-folders-dialog.component';
import { UserDialogueFormComponent } from './user-dialogue-form/user-dialogue-form.component';
import { MenuConfigService } from './services/menu-config.service';
import { UserMenuService } from './services/user-menu.service';
import { MenuListService } from './services/menu-list.service';
import { UserService } from './services/user.service';
import { ComposerListService } from './services/composer-list.service';
import { CreateTrackComponent } from './create-track/create-track.component';
import { ComposerTrackDescriptionComponent } from './composer-track-description/composer-track-description.component';
import { CreateTrackVersionComponent } from './create-track-version/create-track-version.component';
import { NgWaveformModule } from 'ng-waveform';
import { WaveformWaveComponent } from './waveform-wave/waveform-wave.component';
import { MinuteSecondsPipe, PreviewWaveformComponent } from './preview-waveform/preview-waveform.component';
import { ComposerManageCowritersComponent } from './composer-manage-cowriters/composer-manage-cowriters.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { AddTrackPlaylistDialogComponent } from './add-track-playlist-dialog/add-track-playlist-dialog.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DuplicatetemplatePopupComponent } from './duplicatetemplate-popup/duplicatetemplate-popup.component';
import { UserUploadPopupComponent } from './user-upload-popup/user-upload-popup.component';
import { EditNewTrackComponent } from './edit-new-track/edit-new-track.component';
import { NewTrackDescriptionComponent } from './new-track-description/new-track-description.component';
import { TrackNewDetailComponent } from './track-new-detail/track-new-detail.component';
import { EditNewTrackVersionComponent } from './edit-new-track-version/edit-new-track-version.component';
import { TrackVersionNewDetailComponent } from './track-version-new-detail/track-version-new-detail.component';
import { TrackNewListComponent } from './track-new-list/track-new-list.component';
import { SupportComponent } from './support/support.component';
import { NewMusicplayerComponent } from './new-musicplayer/new-musicplayer.component';
import { AngularWavesurferServiceModule } from 'angular-wavesurfer-service';
import { ComposerNewVersionDescriptionComponent } from './composer-new-version-description/composer-new-version-description.component';
import { MatSelectFilterModule } from 'mat-select-filter';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { LyButtonModule } from '@alyle/ui/button';
import { LyIconModule } from '@alyle/ui/icon';
import { LySliderModule } from '@alyle/ui/slider';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    SliderDialogContainerComponent,
    TrackPreviewComponent,
    EditTrackComponent,
    TrackStatusComponent,
    TrackDetailsComponent,
    ChipsComponentComponent,
    ManageCowritersComponent,
    TrackDescriptionComponent,
    MinuteSecondsPipe,
    EditTrackVersionComponent,
    TrackDetailsForVersionComponent,
    PaginationComponent,
    TrackListComponent, 
    PlaylistTrackListComponent,
    TrackNewListComponent,
    MusicplayerComponent,
    NewMusicplayerComponent,
    WaveformComponent,
    DurationPipe,
    TrackStatusPipe,
    FiltersComponent,
    TrackInfoComponent,
    RangeDatepickerComponent,
    ExportOptionsDialogComponent,
    DownloadResultsDialogComponent,
    NewPlaylistFolderDialogComponent,
    DuplicatePlaylistDialogComponent,
    AddRemovePlaylistDialogComponent,
    DeletePlaylistDialogComponent,
    RemoveTrackDialogComponent,
    ManageTracksClientsDialogComponent,
    AddSongPlaylistDialogComponent,
    SelectBackdropClassDirective,
    UserClientListComponent,
    UserArtistListComponent,
    AddToEmailGroupComponent,
    ManageUserPlaylistsComponent,
    ClientActivityDialogComponent,
    ManageClientAccountComponent,
    DeleteClientDialogComponent,
    ArtistActivityDialogComponent,
    ManageArtistAccountComponent,
    CountrySelectComponent,
    StateSelectComponent,
    PhoneMaskDirective,
    DeleteTrackVersionDialogComponent,
    BpmFieldComponent,
    UserEmailGroupListComponent,
    NewUserEmailGroupComponent,
    DeleteUserEmailGroupComponent,
    RemoveUsersFromEmailGroupComponent,
    NewEmailTemplateDialogComponent,
    DeleteEmailTemplateDialogComponent,
    DeletePlaylistFoldersDialogComponent,
    UserDialogueFormComponent,
    CreateTrackComponent,
    ComposerTrackDescriptionComponent,
    CreateTrackVersionComponent,
    WaveformWaveComponent,
    PreviewWaveformComponent,
    ComposerManageCowritersComponent,
    AddTrackPlaylistDialogComponent,
    DuplicatetemplatePopupComponent,
    UserUploadPopupComponent,
    EditNewTrackComponent,
    NewTrackDescriptionComponent,
    TrackNewDetailComponent,
    EditNewTrackVersionComponent,
    TrackVersionNewDetailComponent,
    SupportComponent,
    ComposerNewVersionDescriptionComponent,
   
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    MatMenuModule,
    PaginationComponent,
    TrackListComponent,
    PlaylistTrackListComponent,
    TrackNewListComponent,
    MusicplayerComponent,
    NewMusicplayerComponent,
    WaveformComponent,
    WaveformWaveComponent,
    FiltersComponent,
    TrackInfoComponent,
    RangeDatepickerComponent,
    UserClientListComponent,
    UserArtistListComponent,
    UserEmailGroupListComponent,
    ManageClientAccountComponent,
    UserDialogueFormComponent,
    PhoneMaskDirective,
    StateSelectComponent,
    CountrySelectComponent,
    ClipboardModule,
    MatSnackBarModule,
    DurationPipe,
    AngularWavesurferServiceModule

  ],
  imports: [
    CommonModule,
    PortalModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSliderModule,
    MatCardModule,
    MatOptionModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDividerModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatExpansionModule,
    MatButtonModule,
    RouterModule,
    MatMenuModule,
    HttpClientModule,
    NgxMatSelectSearchModule,
    MatProgressBarModule,
    MatTableModule,
    MatSortModule,
    MatTooltipModule,
    DragDropModule,
    CdkTableModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    OverlayModule,
    MatDialogModule,
    AngularWavesurferServiceModule,
    NgxMaskModule.forRoot(maskConfig),
    NgWaveformModule,
    ClipboardModule,
    ImageCropperModule,
    MatSelectFilterModule,
    CKEditorModule,
    LyImageCropperModule,
    LySliderModule,
    LyButtonModule,
    LyIconModule
  ],
  providers: [
    SliderDialogService,
    PlayerService,
    MenuConfigService,
    UserMenuService,
    MenuListService,
    UserService,
    ComposerListService,
    SessionStorageService,
    BpmService,
    TrackDetailsService

    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: CSRFInterceptor,
    //   multi: true
    // }
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ,NO_ERRORS_SCHEMA]
})
export class SharedModule {}
