<div class="slider-dialog green-theme"
     [@slideContent]="animationState"
     (@slideContent.start)="onAnimationStart($event)"
     (@slideContent.done)="onAnimationDone($event)">
  <div class="header">
    <span class="title">{{ dialogTitle}}</span>
    <button class="close_button" (click)="$event.preventDefault();closeDialog()" *ngIf="trackCreationType===null">
      <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
          stroke-linejoin="round">
          <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
            <g id="Genre-Options">
              <g id="Close" transform="translate(1439.000000, 32.000000)">
                <path d="M24,0 L0,24" id="Path"></path>
                <path d="M0,0 L24,24" id="Path"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </button>
    <button class="close_button" (click)="$event.preventDefault();closeDialog()" *ngIf="trackCreationType=='create' || trackCreationType=='edit'">
      <a *ngIf="trackCreationType=='create'" class="create-track-close-btn">Cancel</a>
      <svg *ngIf="trackCreationType=='edit'" width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
          stroke-linejoin="round">
          <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
            <g id="Genre-Options">
              <g id="Close" transform="translate(1439.000000, 32.000000)">
                <path d="M24,0 L0,24" id="Path"></path>
                <path d="M0,0 L24,24" id="Path"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </button>
  </div>
  <ng-template cdkPortalOutlet #portal> </ng-template>
</div>

