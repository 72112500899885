import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-puplishcms',
  templateUrl: './puplishcms.component.html',
  styleUrls: ['./puplishcms.component.scss']
})
export class PuplishcmsComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<PuplishcmsComponent>) { }

  ngOnInit() {
  }
  close() {
    this.dialogRef.close(true);
  }
}
