import { Component, ElementRef, Inject, OnDestroy, OnInit,QueryList,ViewChildren,Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { of, ReplaySubject, Subscription } from 'rxjs';
import { startWith, debounceTime, distinctUntilChanged, tap, switchMap, map } from 'rxjs/operators';
import { Playlist } from '../models/playlist.model';
import { PlaylistFolder } from '../models/playlistfolder.model';
import { PlaylistService } from '../services/playlist.service';
import { PlaylistFolderService } from '../services/playlistfolder.service';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../services/user.service';
import { environment } from 'src/environments/environment';
import { UserMenuService } from '../services/user-menu.service';
import { DialogService } from '../services/dialog.service';
//----------------------Image Cropper --------------------------------------------------//
// import { StyleRenderer, lyl, WithStyles, ThemeRef, ThemeVariables } from '@alyle/ui';
// import {
//   ImgCropperConfig,
//   ImgCropperEvent,
//   LyImageCropper,
//   ImgCropperErrorEvent,
//   ImgCropperLoaderConfig,
//   STYLES as CROPPER_STYLES
// } from '@alyle/ui/image-cropper';
// import { Platform } from '@angular/cdk/platform';
// import { LySliderChange } from '@alyle/ui/slider';
//-------------------------------------------------------------------------------------//
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { SessionStorageService } from '../services/session-storage.service';
//----------------------Image Cropper --------------------------------------------------//
// const STYLES = (_theme: ThemeVariables, ref: ThemeRef) => {
//   ref.renderStyleSheet(CROPPER_STYLES);
//   return {
//     cropper: lyl `{
//       max-width: 400px
//       height: 300px
//     }`,
//     cropperResult: lyl `{
//       position: relative
//       width: 150px
//       height: 150px
//     }`,
//     sliderContainer: lyl `{
//       text-align: center
//       max-width: 400px
//       margin: 14px
//     }`
//   };
// };
//------------------------------------------------------------------------------------------//
const folderLimit = 30;

export interface DialogData {
  isDuplicate: boolean;
  isEdit: boolean;
  playlist?: Playlist;
}

@Component({
  selector: 'app-duplicate-playlist-dialog',
  templateUrl: './duplicate-playlist-dialog.component.html',
  styleUrls: ['./duplicate-playlist-dialog.component.scss']
})
export class DuplicatePlaylistDialogComponent implements OnInit, OnDestroy {
  //----------------------Image Cropper --------------------------------------------------//
// export class DuplicatePlaylistDialogComponent implements OnInit, OnDestroy, WithStyles {
  // classes = this.sRenderer.renderSheet(STYLES);
  // croppedImage?: string | null = null;
  // scale: number;
  // ready = false;
  // minScale: number;
  // @ViewChild(LyImageCropper) readonly cropper!: LyImageCropper;
  // myConfig: ImgCropperConfig = {
  //   // autoCrop: true,
  //   width: 352, // Default `250`
  //   height: 198, // Default `200`
  //   fill: '#ff2997', // Default transparent if type == png else #000
  //   type: 'image/png', // Or you can also use `image/jpeg`
  //   responsiveArea: true
  // };
  //----------------------------------------------------------------------------------------//

  @Input() baseWidth = 1;
  @Input() baseHeight = 1;
  maximumError: boolean = false;
  maximumDimention: boolean = false;
  imgsrc = '/assets/img/placeholder-image.png';
  playlistImg : File | string = '';
  image=''
  imageLink: any = '/assets/img/placeholder-image.png'
  newFinalImg:any ='';
  //----------------------Image Cropper --------------------------------------------------//
  // fileToUpload: any;
  // imageUrl: any;
  // handleFileInput(file: FileList) {
  //   this.fileToUpload = file.item(0);

  //   //Show image preview
  //   let reader = new FileReader();
  //   reader.onload = (event: any) => {
  //     this.imageUrl = event.target.result;
  //   }
  //   reader.readAsDataURL(this.fileToUpload);
  // }
  //-----------------------------------------------------------------------------------------//
  imageUrl = '/assets/img/placeholder-image.png';
  croppedImage = '/assets/img/placeholder-image.png';
  finalImg = '/assets/img/placeholder-image.png';
  show = false;
  oldMainImage ='/assets/img/placeholder-image.png';
  oldThumbImage = '/assets/img/placeholder-image.png';
  currentCropImage = '/assets/img/placeholder-image.png';
  backUpMainImg: any = "";
  backUpThumbImg: any = "";
  originalFileLink: any = ""
  isThumb_img: boolean = false;
  imageChangedEvent:any = "";

  crop() {
    this.show = false;
    this.showCropper = false;
    this.finalImg = this.croppedImage; //old cropper
    //----------------------Image Cropper --------------------------------------------------//
    // if (this.isThumb_img) {
    //   this.disableSave = false;
    //   this.show = false;
    //   this.showCropper = false;
    //   this.isThumb_img = false;
    //   this.finalImg = this.croppedImage; // new cropper
    // } else {
    //   this.showCropper = false;
    //   this.show = true;
    //   this.imageLink = this.croppedImage;
    //   this.isThumb_img = true;
    //   this.myConfig.width = 200;
    //   this.myConfig.height = 200;
    //   this.myConfig = Object.assign({}, this.myConfig);
      
    //   const config: ImgCropperLoaderConfig = {
    //     scale: 0,
    //     name: this.image,
    //     rotation: 0,
    //     originalDataURL: this.originalFileLink.changingThisBreaksApplicationSecurity
    //   };
    //   setTimeout(() => {
    //     this.cropper.loadImage(config);
    //   }, 0);
    // }
  }
//  toggle(){
// this.show = !this.show;
//   }
//---------------------------------------------------------------------------------------//
toggleclose(){
  // this.disableSave = false;
  this.show = !this.show;
  this.imageUrl = this.oldMainImage;
  this.imageLink = this.oldMainImage;
  this.finalImg = this.oldThumbImage;
}
@ViewChild('myFileInput') myFileInput;

onFileChange(event) { 
  this.myFileInput.nativeElement.value = '';
}

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.currentCropImage = this.croppedImage
    this.newFinalImg = event;
    const fileToReturn = this.base64ToFile(
      event.base64,
      this.image,
    )
    console.log(fileToReturn)
    this.newFinalImg = fileToReturn;
    // return fileToReturn;
  }

  base64ToFile(data, filename) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  imageLoaded(eve) {
      // show cropper
      console.log("Image load event triggered", eve)
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }

  // onCropped(e: ImgCropperEvent) {
  //   this.croppedImage = e.dataURL;
  //   console.log("event in cropped image", e)
  //   const file = this.base64ToFile(e.dataURL, e.name);
  //   if (this.isThumb_img) {
  //     this.newFinalImg = file;
  //   } else {
  //     this.playlistImg = file;
  //   }
  //   console.log(this.newFinalImg);
  //   console.log('cropped img: ', e);
  // }
  // onLoaded(e: ImgCropperEvent) {
  //   console.log('img loaded', e);
  // }
  // onError(e: ImgCropperErrorEvent) {
  //   console.warn(`'${e.name}' is not a valid image`, e);
  // }
  // onSliderInput(event: LySliderChange) {
  //   this.scale = event.value as number;
  // }
  // onReady(e: ImgCropperEvent) {
  //   this.ready = true;
  //   console.log('Img ready for cropper', e);
  // }

  fileChange(e) {
    // const URL = window.URL || window['webkitURL'];
    // const Img = new Image();

    // const filesToUpload = (e.target.files);
  //   Img.src = URL.createObjectURL(filesToUpload[0]);

  //   Img.onload = (e: any) => {
  //     const height = e.path[0].height;
  //     const width = e.path[0].width;
  //     if(width < 900 || height < 540){
  //       this.maximumDimention = true;
  //       this.show = false;
  //       this.playlistImg = '';
  //       this.image = '';
  //       this.imageLink = '';
  //       this.imgsrc = ''
  //       this.newValue = false;
  //       return
  //     }
  // }
    if(e.srcElement.files[0].size  > 1 * 1000000){
     this.maximumError = true;
     this.show = false;
     this.playlistImg = '';
     this.image = '';
     this.imageLink = '';
     this.originalFileLink = '';
     this.imgsrc = ''
     this.newValue = false;
     return
    }
    
    this.show = true;
    this.maximumError = false
    this.maximumDimention = false
    this.imageChangedEvent = e;
    // this.isThumb_img = false;
    // this.disableSave = true;
    this.finalImg = '/assets/img/placeholder-image.png';
    // this.imageLink = '/assets/img/placeholder-image.png';
    const file = e.srcElement.files[0];
    // this.myConfig.width = 352;
    // this.myConfig.height = 198;
    // this.myConfig = Object.assign({}, this.myConfig);
    // setTimeout(() => {
    //   this.scale = 0;
    //   this.cropper.selectInputEvent(e);
    // }, 0);
    this.imgsrc = window.URL.createObjectURL(file); 
    this.playlistImg = file;
    this.image = file.name
    // this.originalFileLink = this._d.bypassSecurityTrustUrl( this.imgsrc);
    this.imageLink = this._d.bypassSecurityTrustUrl( this.imgsrc);
    this.newValue = true;
  }
  get aspectRatio() {
    return (this.baseHeight / this.baseWidth) * 100;
  }
  constructor(
    public _d: DomSanitizer,
    private dialogRef: MatDialogRef<DuplicatePlaylistDialogComponent>,
    private _playlistService: PlaylistService,
    private _playlistFolderService: PlaylistFolderService,
    private sessionStorageService: SessionStorageService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,public _userService: UserService, public api: UserMenuService ,public dialog: DialogService,
    // readonly sRenderer: StyleRenderer
  ) {

  }

  publicPlaylist = true;
  newPlaylistFolder = false;

  loading = true;
  @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef>;

  folders: PlaylistFolder[] = [];
  checkedFolders: PlaylistFolder[] = [];
  foldersIDS: any[] = []

  title:string;
  folderName: string='';
  note:string = '';
  cms:boolean=true
  newValue = false;

  error: string;
  folderOptionsAll: any = [];
  currentUser:any =[]

  searchControl = new FormControl();
  folderOptions: ReplaySubject<PlaylistFolder[]> = new ReplaySubject<PlaylistFolder[]>(1);
  searchingFolders = false;
  showCropper = false;
  // disableSave: boolean = false;

  private _subscriptions: Subscription[] = [];

  ngOnInit(): void {
    if (this.data.playlist) {
      if(this.data.isDuplicate){
        this.title = this.data.playlist.name + ' Copy'
      }
      else{
        this.title = this.data.playlist.name 
      }
      this.note= this.data.playlist.description  
      this.cms = this.data.playlist.is_cms;
      this.publicPlaylist = this.data.playlist.is_public
      if( this.data.playlist.marquee_image !== null &&  this.data.playlist.marquee_image !== ''){
        this.imageLink = this.data.playlist.marquee_image;
        if (this.data.playlist.marquee_image && this.data.playlist.thumb_image && this.data.playlist.marquee_image.includes("s3.amazonaws.com/mibe-subscribe-dev") && this.data.playlist.thumb_image.includes("s3.amazonaws.com/mibe-subscribe-dev")) {
          this.backUpMainImg = this.data.playlist.marquee_image.replace("s3.amazonaws.com/mibe-subscribe-dev", "d141icalva6e0e.cloudfront.net");
          this.backUpThumbImg = this.data.playlist.thumb_image.replace("s3.amazonaws.com/mibe-subscribe-dev", "d141icalva6e0e.cloudfront.net");
        }
        var splitUrl = this.data.playlist.marquee_image.split('/');
        this.image = splitUrl[splitUrl.length-1]
        this.playlistImg = this.imageLink = this.data.playlist.marquee_image;
        this.finalImg = this.data.playlist.thumb_image;
        var splitUrl = this.data.playlist.marquee_image.split('/');
        this.image = splitUrl[splitUrl.length-1]
        this.oldThumbImage = this.finalImg;
        this.oldMainImage = this.playlistImg;
        this.newValue = false;
      }
      else{
        this.imageLink = this._d.bypassSecurityTrustUrl(this.imgsrc)
        // this.finalImg = this.imgsrc
        this.newValue = true;
      }

      // this._d.bypassSecurityTrustUrl(objectURL)
      // console.log(this._d.bypassSecurityTrustUrl(objectURL))
    }
    this._playlistFolderService.getAllPlaylistFolders({limit: folderLimit}).subscribe(res => {
      this.folders = res.results;
      this.folderOptions.next(res.results);
      if(this.data.playlist && this.data.playlist.assigned_folders.length > 0){
        for(let i=0;i< this.data.playlist.assigned_folders.length ;i++){
          for(let i1=0;i1< this.folders.length ;i1++){
            if(this.data.playlist.assigned_folders[i].id === this.folders[i1].id ){
              this.checkedFolders.push( this.folders[i1]);
              this.foldersIDS.push(this.folders[i1].id);
            }
          }
         
        }
      }
      this.loading = false;
    }, (error) => {
      console.log(error);
      //alert("An error ocurred while getting the folders. Check your connections and try again");
    })
 
    this._subscriptions.push(this.searchControl.valueChanges.pipe(
      startWith(''),
      debounceTime(150),
      distinctUntilChanged(),
      tap(() => {
        this.searchingFolders = true;
      }),
      switchMap((v) => {
        if (!v || v.length <= 2) {
          return of(this.folders);
        }
        return this._playlistFolderService.getAllPlaylistFolders({search:v, limit:folderLimit}).pipe(
          map(res => {
            let ret = res.results;
            for(let folder of this.checkedFolders) {
              if(!ret.some(f=>f.id == folder.id)) {
                ret.push(folder);
              }
            }
            return ret;
          })
        );
      }),
      tap(() => {
        this.searchingFolders = false;
      })
    ).subscribe(f => {
      this.folderOptions.next(f);
    }));
    this._userService.currentUserStream.subscribe((u) => {
      console.log(u)
      this.currentUser = u;
    })
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s=>s.unsubscribe())
    this._subscriptions = [];
  }

  onInvalidImg(eve, imgType) {
    eve.target.src = imgType === 'main' ?this.backUpMainImg: this.backUpThumbImg;
  }
  cropData() {
    this.showCropper = true;
    this.show = false;
    this.currentCropImage = this.currentCropImage
    // this.cropper.crop();
    // this.ready = false;
  }
  resetCrop() {
    this.showCropper = false;
    this.show = true;
    // const config: ImgCropperLoaderConfig = {
    //   scale: 0.745864772531767,
    //   // xOrigin: 642.380608078103,
    //   // yOrigin: 236.26357452128866,
    //   name: this.image,
    //   rotation: 0,
    //   originalDataURL: this.originalFileLink.changingThisBreaksApplicationSecurity
    // };
    // setTimeout(() => {
    //   this.cropper.loadImage(config);
    // }, 0);
  }
  close() {
    this.dialogRef.close(null);
  }
  checkCms(value){
    this.cms= value;
  }

  isFolderChecked(folder:PlaylistFolder) {
    return this.checkedFolders.some(f=>f.id == folder.id);
  }
 
  folderChecked(checked:boolean, folder:PlaylistFolder) {
    if (checked) {
      this.checkedFolders.push(folder);
      this.checkedFolders.forEach((id:any)=>{
        if(!this.foldersIDS.includes(id.id)){
          this.foldersIDS.push(id.id);
        }else{
          this.foldersIDS.splice(id.id,1);
        }
      })
      console.log(this.checkedFolders)
      if (!this.folders.some(f=>f.id == folder.id)) {
        this.folders.push(folder);
      }
      return;
    }
    this.checkedFolders.forEach((id:any)=>{
      if(!this.foldersIDS.includes(id.id)){
        this.foldersIDS.push(id.id);
      }else{
        this.foldersIDS.splice(id.id,1);
      }
    })
    let data =[]
   for(let i1=0;i1<this.foldersIDS.length;i1++){
     if(this.foldersIDS[i1] !==  folder.id){
       data.push(this.foldersIDS[i1])
     }
   }
    let i = this.checkedFolders.findIndex(f=>f.id == folder.id);
    if (i >= 0) {
      this.checkedFolders.splice(i, 1);
      //
    }
    this.foldersIDS = data

  }

  folderTrackBy(i:number, folder:PlaylistFolder) {
    return folder.id;
  }

  canSubmit():boolean {
    if(this.title && this.title.trim()=== ''){
      return false
    }
    return !!this.title && ((this.newPlaylistFolder && !!this.folderName.trim()) || !this.newPlaylistFolder);
  }

  duplicate() {
    console.log(this.data)
    console.log(this.data.playlist)
    if (!this.canSubmit()) return;
    if (this.loading) return;
    this.error = null
    this.loading = true;
    this.folderCreated = false;
  //  const formData = new FormData();

    const formData:any = new FormData();

    formData.append("name", this.title);
    formData.append("is_cms", this.cms);
    formData.append("description", this.note ? this.note :'');
    formData.append("created_by", this.currentUser.id);
    formData.append("folder_ids",  (this.foldersIDS));
    formData.append("folder_name", this.folderName.trim());
    formData.append("is_public",  this.publicPlaylist);
    formData.append("track_to_be_mapped",[]);
    let newdata =0
    if(this.data.playlist && (this.data.isDuplicate || this.data.isEdit)){
      if( this.data.playlist.marquee_image !== null &&  this.data.playlist.marquee_image !== ''){
        if(this.data.playlist.marquee_image !== this.playlistImg  && this.newValue === true ){
          formData.append("file", this.playlistImg);
          typeof this.playlistImg !== 'string' && formData.append("marquee_image",  this.playlistImg);
          typeof this.newFinalImg !== 'string' && formData.append("thumb_image", this.newFinalImg);
        }
      }
      else{
        formData.append("file", this.playlistImg);
        typeof this.playlistImg !== 'string' && formData.append("marquee_image",  this.playlistImg);
        typeof this.newFinalImg !== 'string' && formData.append("thumb_image", this.newFinalImg);
      }
      
    }
    else{
      formData.append("file", this.playlistImg);
      typeof this.playlistImg !== 'string' && formData.append("marquee_image",  this.playlistImg);
      typeof this.newFinalImg !== 'string' && formData.append("thumb_image", this.newFinalImg);
    }

    this.playlistAddedTo = [];

    // if (this.data.isDuplicate) {
    //   this._playlistService
    //     .createPlaylist(
    //       `${this.title}`,
    //       "",
    //       34,
    //       this.data.playlist.id,
    //       'duplicate',
    //       this.foldersIDS,
    //       "",
    //       this.publicPlaylist
    //     )
    //     .subscribe((p) => {
    //       this.playlistCreated(p);
    //     }, (error) =>{
    //       alert(error.error.error)
    //       this.loading = false
    //     });
    //   return;
    // }
      if (this.data.isDuplicate) {
        formData.append("playlist_id", this.data.playlist.id);
      //  formData.append("created_by", this.currentUser.id);
        this._playlistService
        .createPlaylist1(formData)
        .subscribe((p) => {
        //  this.playlistCreated(p);
        this.dialogRef.close(true)
        }, (error) =>{
          let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
          this.dialog.UserUploadPopupConfirmPopup(data).subscribe(changed => {
          });
          this.dialogRef.close(true);
          this.loading = false
        });
      return;
    }
    if (this.data.isEdit) {
      // this.data.playlist.name = this.title;
      // let playlist = new Playlist(this.data.playlist.toJSON());
      // playlist.name = this.title;
      let url = environment.apiURL + '/api/v1/admin/playlists/' + this.data.playlist.id + '/'
      this.api.doPUT(url, formData).subscribe(x => {
        this.dialogRef.close(true)
      // })
      // this._playlistService.updatePlaylist(formData, this.data.playlist.id).subscribe(p=> {
      //  // this.playlistCreated(p);
      //  this.dialogRef.close(p)
      }, (error) => {
        // if(error.error.details){
        //   alert(error.error.details)
        // }
        // if(error.error.name){
        //   alert(error.error.name)
        // }
        // else{
        //   alert(error.error.error)
        // }
        let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details :(error?.error.name ? error?.error?.name : '') ))
        this.dialog.UserUploadPopupConfirmPopup(data).subscribe(changed => {
        });
        console.log(error);
        this.loading = false
        //alert("An error ocurred while editing the playlist. Check your input for errors and then check your internet connections and try again");
      })
      return;
    }
    this._playlistService.createPlaylist1(formData).subscribe( playlistRes => {
      // console.log(playlistRes._original_data);
     // formData.append("created_by", this.currentUser.id);
      // this.playlistCreated(p);
      if (playlistRes._original_data.body) {
        this.setSessionActivePlaylist(playlistRes._original_data.body.id, playlistRes._original_data.body.name);
      }
      this.dialogRef.close(true)
    }, (error) => {
      console.log(error);
      let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details :(error?.error.name ? error?.error?.name : '') ))
      this.dialog.UserUploadPopupConfirmPopup(data).subscribe(changed => {
      });
      this.loading = false
      //alert("An error ocurred while creating the playlist. Check your input for errors and then check your internet connections and try again");
    })
    console.log(formData);
  }

  private playlistCreated(p: Playlist) {
    if (this.newPlaylistFolder) {
      this._playlistFolderService.createPlaylistFolder(this.folderName).subscribe(folder => {
        if (!folder.playlists) {
          folder.playlists = []
        }
        folder.playlists.push(p);
        this._playlistFolderService.updatePlaylistFolder(folder).subscribe(updatedFolder => {
          this.folderCreated = true;
          this.closeIfDone(p);
        }, (error) => {
          console.log(error);
          let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details :(error?.error.name ? error?.error?.name : '') ))
        this.dialog.UserUploadPopupConfirmPopup(data).subscribe(changed => {
        });
          this.loading = false
          //alert("An error ocurred while updating the folder. The folder was created, please check the folder to make sure everything is as expected.");
        })
      }, (error) => {
        console.log(error);
        let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details :(error?.error.name ? error?.error?.name : '') ))
        this.dialog.UserUploadPopupConfirmPopup(data).subscribe(changed => {
        });
        this.loading = false
        //alert("An error ocurred while creating the folder. Check your input for errors and then check your internet connections and try again");
      })
    }
    if (this.checkedFolders && this.checkedFolders.length > 0) {
      this.checkedFolders.forEach(checkedFolder => {
        if (!checkedFolder.playlists) {
          checkedFolder.playlists = []
        }
        checkedFolder.playlists.push(p);
        this._playlistFolderService.updatePlaylistFolder(checkedFolder).subscribe(updatedFolder => {
          this.playlistAddedTo.push(updatedFolder.id);
          this.closeIfDone(p);
        }, (error) => {
          console.log(error);
          let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details :(error?.error.name ? error?.error?.name : '') ))
          this.dialog.UserUploadPopupConfirmPopup(data).subscribe(changed => {
          });
          this.loading = false
          //alert(`An error ocurred while updating the folder ${checkedFolder.getDisplayName()}. Check your input for errors and then check your internet connections and try again`);
        })
      })
    }
    this.closeIfDone(p);
  }


  private folderCreated = false;
  private playlistAddedTo = [];
  private closeIfDone(duplicatedPlaylist: Playlist) {
    if (this.newPlaylistFolder && !this.folderCreated) return;
    if (this.checkedFolders && this.checkedFolders.length > 0) {
      if (this.playlistAddedTo.length != this.checkedFolders.length) return;
    }
    this.dialogRef.close(duplicatedPlaylist);
  }
  deselectPlaylist(event){   
    if(event){
      this.checkedFolders=[];  
    } 
  }

  setSessionActivePlaylist(playlistId: any, playlistName: any) {
    let playlist = {id : playlistId, value: playlistName}
    this.sessionStorageService.activePlaylistSessionKey = playlistId;
    this.sessionStorageService.activePlaylistSessionName = playlistName;
    this.sessionStorageService.setItem('activePlaylist', playlist);
  }

  // Get this without key?
  getActivePlaylistFromSession() {
    return this.sessionStorageService.getItem('activePlaylist');
  }

  removeActivePlaylistFromSession() {
    this.sessionStorageService.removeItem('activePlaylist');
  }

  // handleToggle() {
  //   this.isThumb_img = !this.isThumb_img;
  //   this.myConfig.width = this.isThumb_img? 200: 352;
  //   this.myConfig.height = this.isThumb_img? 200: 198;
  //   this.myConfig = Object.assign({}, this.myConfig);
  // }
}
