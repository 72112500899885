import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { User } from '../../shared/models/user.model';
import { SliderDialogService, DialogConfig, CONTAINER_DATA } from '../../shared/services/slider-dialog.service';
import { UserFormComponent } from '../user-form/user-form.component';
import { UserEditFormComponent } from '../user-edit-form/user-edit-form.component';
import { PhoneNumber, PhoneNumberTypes } from '../../shared/models/phonenumber.model';
import { MenuUser } from '../../shared/models/menu-user.model';
import { environment } from '../../../environments/environment';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { DialogService } from '../../shared/services/dialog.service';
import { DeleteUserDialogueComponent } from '../delete-user-dialogue/delete-user-dialogue.component';
import { MenuListService } from '../../shared/services/menu-list.service';
import { MatDialogRef } from '@angular/material/dialog';




@Component({
  selector: 'app-menu-user',
  templateUrl: './menu-user.component.html',
  styleUrls: ['./menu-user.component.scss']
})
export class MenuUserComponent implements OnInit {
  public _searchString = '';
  currUser:any;
  constructor(
    private _sliderDialogService:SliderDialogService,
    private dialog: DialogService,
    public list: MenuListService,
    private api: UserMenuService,
    private config : MenuConfigService,
    
    
  ) { 
    this.list.getAllMenuUser();
    this.list.getAllUser();
    this.list.getAllGroups(1, 'edit');
    this.list.getAllPermission();
    this.list.getEditAvailableUserPermission();
  }
  primary:any;
  toppings = new FormControl();
  searchFilterFocused:any;
  primaryPhoneControl:FormControl = new FormControl();
  toppingList: string[] = ['Sub Admin Groups', 'Test1', 'Test2', 'Test3', 'Test4'];
  allMenuUser: any[] = []
  loading:boolean = false;
  currentSelect:any;

  ngOnInit(): void {
    this.list.getAdminUser().subscribe(x=>{
  this.currentSelect = x;
    })
    
  }
 
  newUser() {
    
      this._sliderDialogService.open(UserFormComponent, {
        width: "600px",
        title: "NEW ADMIN USER",
        panelClass: "edit-track-dialog",
        data: {user: new User({is_client:true}), isNewUser: true}
      }, (user) => {
        
      });
    
  }
  
  deleteUser(){
    this.dialog.displayMenuDialog(DeleteUserDialogueComponent)
  }
 
  getFindUser(){
   
    if(this._searchString.length){
      this.list.loadingUser = true;
       let url = environment.apiURL + this.config.menuUser + '?search=' + this._searchString;
    this.api.doGET(url).subscribe(x=>{
      this.list.loadingUser = false;
      this.list.allMenuUser = x['results'] || [];
      this.list.currentAdminUserSubject.next(x['results'][0])
    },
    (error)=>{
      this.list.loadingUser = false;
      //alert(error.message)
    }
  )
    }
    else{
      this.list.getAllMenuUser();
    }
   
  }
}
