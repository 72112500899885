import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MenuConfigService {

  constructor() { }
  doMenuUser: any = "/api/v1/admin/users/";
  doGroups: any = "/api/v1/admin/groups/";
  listOfPermission: any = "/api/v1/admin/groups/dropdown/";
  doProfile:any = "/api/v1/user/current/";
  availableUser: any = "/api/v1/admin/groups/available-users/";
  availablePermission: any = "/api/v1/admin/groups/all-available-permission/";
  createUserGroup: any = "/api/v1/admin/groups/create-group-with-users/";  
  removeUserGroup: any = "/api/v1/admin/groups/{group_id}/";
  manageUser: any = '/api/v1/admin/groups/';
  managePermission: any = '/api/v1/admin/groups/{group_id}/update-group-permission/'
  doGenres:any = '/api/v1/admin/genres/';
  publisherList: any = '/api/v1/admin/user/publisher/';
  createPROS: any = '/api/v1/admin/user/pros/';
  getAllPROSlist: any = '/api/v1/admin/user/pros/'
  getAllMOODSlist: any = '/api/v1/admin/moods/';
  getAlbhaticalMoods: any = '/api/v1/admin/moods/get-starts-with-moods/';
  doTos: any = '/api/v1/admin/tos/get-artist-tos/'
  doTosClient: any = '/api/v1/admin/tos/get-client-tos/'
  doTosSearch : any ='/api/v1/admin/tos/'
  doTosUpload: any = '/api/v1/admin/tos/'
  doThesaurus: any = '/api/v1/admin/thesaurus/'
  getAllReports: any = '/api/v1/admin/report/'
  downloadReports: any = '/api/v1/admin/report/download-reports/?from={start}&to={end}'
  downloadTracks: any = '/api/v1/track/download-tracks/'
  downloadTracksDirect: any = '/api/v1/track/download-tracks-direct/'
  doLogs: any = '/api/v1/admin/logs/';
  menuUser: any = '/api/v1/admin/users/get-staffs/'
  userPro: any = '/api/v1/admin/users/user-pro/'
  uploadedTracksapi = '/api/v1/upload/'
  searchUploadedTrack = '/api/v1/upload/?search='
  sortuploadedTrack = '/api/v1/upload/?ordering='
  getTracksGenres= '/api/v1/track/genre-dropdown/'
  getTracksMoods= '/api/v1/track/moods-dropdown/'
  getTracksInstrument = '/api/v1/track/instrument-dropdown/'
  getTracksVocals = '/api/v1/track/vocals-dropdown/'
  createTrackAPI = '/api/v1/track/'
  doDashboard = '/api/v1/admin/users/dashboard/'
  doAudioLevel = '/api/v1/admin/audiolevel/recent-record/'
  doPublisher = `/api/v1/admin/users/get-publisher-percentage/?name=MIBE PUBLISHING`
  postAudioLevel = `/api/v1/admin/audiolevel/`
  postPublisher = `/api/v1/admin/users/update-publisher-percentage/`
  createTrackVersionAPI = '/api/v1/trackversion/'
  getThesaurusDropdown =`/api/v1/admin/moods/get-thesaurus-dropdown/` 
  //activeGenre=`/api/v1/genres/get-all-genre-for-clients-wo-pagination/`
  activeGenre = `/api/v1/admin/genres/get-cms-genres/?page_size=6`
  activeGenre1 =`/api/v1/admin/genres/get-cms-genres/`
  allGenre = `/api/v1/admin/genres/get-is_featured-genres/`
  updateCmsGenre = `/api/v1/admin/genres/update-cms-genres/`
  allPlaylist = `/api/v1/admin/playlists/get-featured-playlist/`
  addPlaylist = `/api/v1/admin/playlists/get-playlist-for-cms/?page_size=6`
  addPlaylist1 = `/api/v1/admin/playlists/get-playlist-for-cms/`
  updateActivePlaylist = `/api/v1/admin/playlists/update-cms-playlist/`
  addPlaylistDropdown = `/api/v1/admin/playlists/cms-playlist-dropdown/`
  addPlaylistTrackDropdown = `/api/v1/admin/playlists/cms-track-playlist-dropdown/`
  allTrack = `/api/v1/admin/playlists/`
  addPlaylistTrack = `/api/v1/admin/playlists/get-playlist-for-track-cms/`
  addPlaylistTrack1 = `/api/v1/admin/playlists/get-playlist-for-track-cms/`
  updateActiveTarck = `/api/v1/track/update-featured-track/`
  addGenreDropdown = `/api/v1/admin/homepageslides/get-genre-dropdown/`;
  addGenreTable = `/api/v1/admin/homepageslides/get-added-features/`;
  addGenreTable1 = `/api/v1/admin/homepageslides/get-added-features/`;
  addFeatureSearch = `/api/v1/admin/homepageslides/get-added-features/?search=`;
  addGenreTableup = `/api/v1/admin/homepageslides/get-active-features/`;
  addFeatureDropdown = `/api/v1/admin/homepageslides/`;
  deleteFeatureItem = `/api/v1/admin/homepageslides/`;
  updateActiveFeatureDown = `/api/v1/admin/homepageslides/update-active-features/`;
  addPlaylistFeatureDropdown = `/api/v1/admin/homepageslides/get-playlist-dropdown/`;
  addFeaturePlaylist = `/api/v1/admin/homepageslides/`;

}
