<div class="heading">
  <strong> {{ totalTracks }} Tracks</strong>
  <button *ngIf="clearBtnFlag"
    type="button"
    (click)="clearFilter()" class="clear"
  >
    Clear all
  </button>
</div>


<mat-form-field appearance="fill">
  
  <mat-label>Status</mat-label>
  <span *ngIf="statusnumber" class="status-clear" (click)="statusnumber = false;exclusiveChanged($event)">Clear </span>
  <mat-select class="custom-backdrop-class" panelClass="filter-select filter-status" [disableOptionCentering]="true"
    (selectionChange)="exclusiveChanged($event);clearBtnFlag = true" [(ngModel)]="statusnumber" placeholder="All">
    <mat-select-trigger *ngIf="statusnumber">
      <div *ngFor="let status of statuses">
        <span class="status-bullet" *ngIf="status.value === statusnumber"
          [ngStyle]="{'background-color': status.color}"></span>
        <span *ngIf="status.value === statusnumber"> {{status.name}}</span>
      </div>
  
    </mat-select-trigger>
    <span class="select-title">Select a Status</span>
    <mat-option [value]="false" style="display: none;">All</mat-option>
    <mat-option *ngFor="let status of statuses" [value]="status.value">
      <span
        class="status-bullet"
        *ngIf="status.color.length > 0"
        [ngStyle]="{'background-color': status.color}"
      ></span>
      {{status.name}}
    </mat-option>
    
  </mat-select>
</mat-form-field>

<mat-form-field appearance="fill">
  <mat-label>Artist</mat-label>
  <span *ngIf="artistNumber" class="status-clear" (click)="artistNumber = false; selectedArtsit='None';exclusiveChanged($event)">Clear </span>
  <!-- <mat-select class="custom-backdrop-class" [(ngModel)]="artistNumber" [disableOptionCentering]="true" (selectionChange)="artistChange($event);clearBtnFlag = true;" panelClass="filter-select filter-artist"  name="artistFilter"  placeholder="All" #artistSelect>
    <mat-option>
      <ngx-mat-select-search (selectionChange)="artistChange($event);clearBtnFlag = true" 
        [searching]="searchingArtist"
        [formControl]="artistFilterCtrl"
        placeholderLabel="All">
        <svg ngxMatSelectSearchClear class="artist-filter-clear-icon filter-clear-icon" width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                    <g id="Genre-Options">
                        <g id="Close" transform="translate(1439.000000, 32.000000)">
                            <path d="M24,0 L0,24" id="Path"></path>
                            <path d="M0,0 L24,24" id="Path"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </ngx-mat-select-search>
    </mat-option>
    <mat-option [value]="false">All</mat-option>

    <mat-option *ngFor="let artist of filteredArtistOptions | async;" [value]="artist.id">
      {{ artist.name }}
    </mat-option>
  </mat-select> -->

  <mat-select class="custom-backdrop-class" panelClass="filter-select filter-status" [disableOptionCentering]="true" (selectionChange)="artistChanged($event);clearBtnFlag = true" [(ngModel)]="artistNumber" placeholder="All">
    <!-- <div class="bpm-box"><input class="max-level-2" type="text" placeholder="Search Artists" [formControl]="artistFilterCtrl" [(ngModel)]="searchArtist"></div> -->
      <mat-option [value]="false" style="display: none;">All</mat-option>
      <mat-option>
        <ngx-mat-select-search
        [searching]="searchingArtist"
        [formControl]="artistFilterCtrl"
          placeholderLabel="Select Artist"
          noEntriesFoundLabel="No Options Found">
          <svg ngxMatSelectSearchClear class="filter-clear-icon" width="26px" height="24px" viewBox="0 0 26 24"
            version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
              stroke-linejoin="round">
              <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                <g id="Genre-Options">
                  <g id="Close" transform="translate(1439.000000, 32.000000)">
                    <path d="M24,0 L0,24" id="Path"></path>
                    <path d="M0,0 L24,24" id="Path"></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let status of filteredArtistOptions | async" [value]="status?.id">
        {{status?.name}}
      </mat-option>
      
    </mat-select>
</mat-form-field>

<mat-form-field appearance="fill" floatLabel ='always' *ngIf ="artistNumber">
  <mat-label>ArtistType</mat-label>
  <mat-select class="custom-backdrop-class" panelClass="filter-select filter-status" [disableOptionCentering]="true" (selectionChange)="typeChanged($event);clearBtnFlag = true" [(ngModel)]="selectedArtsit" placeholder="None">
      <mat-option [value]="false" style="display: none;">None</mat-option>
      <mat-option *ngFor="let status of selectArtistType" [value]="status.value">
        {{status.label}}
      </mat-option>
      
    </mat-select>
</mat-form-field>

<mat-form-field appearance="fill">
  <mat-label>Genre</mat-label>
  <span *ngIf="gnereNumber" class="status-clear" (click)="gnereNumber = false;exclusiveChanged($event);onSearchGenre('');">Clear </span>
  <mat-select class="custom-backdrop-class" panelClass="filter-select filter-genre" [disableOptionCentering]="true" [(ngModel)]="gnereNumber" (selectionChange)="exclusiveChanged($event);clearBtnFlag = true;getSubgnere($event.value)" name="genreFilter"  placeholder="All" #genreSelect>
    <!-- <span class="select-title">Select a Genre</span> -->

    <mat-option [value]="false" style="display: none;">All</mat-option>
    <mat-option>
      <ngx-mat-select-search
      [searching]="searchingGenre"
      [formControl]="genreFilterCtrl"
        placeholderLabel="Select a Genre"
        noEntriesFoundLabel="No Options Found">
        <svg ngxMatSelectSearchClear class="filter-clear-icon" width="26px" height="24px" viewBox="0 0 26 24"
          version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
            stroke-linejoin="round">
            <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
              <g id="Genre-Options">
                <g id="Close" transform="translate(1439.000000, 32.000000)">
                  <path d="M24,0 L0,24" id="Path"></path>
                  <path d="M0,0 L24,24" id="Path"></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </ngx-mat-select-search>
    </mat-option>
    <!-- <div class="bpm-box"><input class="max-level-2" type="text" placeholder="Search Genre" [formControl]="genreFilterCtrl" [(ngModel)]="searchGenre" (keyup)="onSearchGenre($event.target.value)"></div> -->

    <mat-option *ngFor="let option of filteredGenreOptions | async; trackBy: genreTrackBy" [value]="option.id">
      {{ option.name }}
    </mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field appearance="fill" floatLabel="always">
  <mat-label>Subgenre</mat-label>
  <span *ngIf="subgnereNumber" class="status-clear" (click)="subgnereNumber = false;exclusiveChanged($event);onSearchSubGenre('');">Clear
  </span>
  <mat-select class="custom-backdrop-class"  panelClass="filter-select filter-subgenre" 
  [disableOptionCentering]="true" [(ngModel)]="subgnereNumber" (selectionChange)="exclusiveChanged($event);clearBtnFlag = true" 
  name="subGenreFilter"  placeholder="All" #subGenreSelect>
  <mat-option [value]="false" style="display: none;">All</mat-option>
  <!-- <div class="bpm-box"><input class="max-level-2" type="text" placeholder="Search Subgnere" [(ngModel)]="searchSubgnere" (keyup)="onSearchSubGenre($event.target.value)"></div> -->
  <mat-option>
    <ngx-mat-select-search
    [searching]="searchingSubgenre"
    [formControl]="subGenreFilterCtrl"
      placeholderLabel="Select a Sub Genre"
      noEntriesFoundLabel="No Options Found">
      <svg ngxMatSelectSearchClear class="filter-clear-icon" width="26px" height="24px" viewBox="0 0 26 24"
        version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
          stroke-linejoin="round">
          <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
            <g id="Genre-Options">
              <g id="Close" transform="translate(1439.000000, 32.000000)">
                <path d="M24,0 L0,24" id="Path"></path>
                <path d="M0,0 L24,24" id="Path"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </ngx-mat-select-search>
  </mat-option>
    <!-- <span class="select-title">Select a Subgenre</span> -->

   

    <mat-option *ngFor="let option of filteredSubGenreOptions | async; trackBy: genreTrackBy" [value]="option.id">
      {{ option.name }}
    </mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field appearance="fill" floatLabel="always">
  <mat-label>Mood</mat-label>
  <span *ngIf="moodNumber" class="status-clear" (click)="moodNumber = false;currentMoods =[] ;moodChanged($event);onSearchMood('');" class="status-clear">Clear </span>
  <mat-select class="custom-backdrop-class"  panelClass="musicMoodspanel" 
  [disableOptionCentering]="true" [(ngModel)]="currentMoods" 
  (selectionChange)="moodChanged($event);clearBtnFlag = true "  
  name="moodFilter" placeholder="All" #moodSelect multiple>
    <!-- <span class="select-title">Select a Mood</span> -->
      <!-- <mat-option [value]="false"  style="display: none;" >All</mat-option> -->
      <mat-option>
      <ngx-mat-select-search
        [searching]="searchingMoods"
        [formControl]="moodFilterCtrl"
        placeholderLabel="Select Moods"
        noEntriesFoundLabel="No Options Found">
        <svg ngxMatSelectSearchClear class="filter-clear-icon" width="26px" height="24px" viewBox="0 0 26 24"
          version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
            stroke-linejoin="round">
            <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
              <g id="Genre-Options">
                <g id="Close" transform="translate(1439.000000, 32.000000)">
                  <path d="M24,0 L0,24" id="Path"></path>
                  <path d="M0,0 L24,24" id="Path"></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </ngx-mat-select-search>
    </mat-option>

      <!-- <div class="bpm-box"><input class="max-level-2" type="text" placeholder="Search Mood" [formControl]="moodFilterCtrl" [(ngModel)]="searchMood" (keyup)="onSearchMood($event.target.value)"></div> -->
    <mat-option *ngFor="let option of filteredMoodOptions | async; trackBy: moodTrackBy" [value]="option">
      {{ option.name }}
    </mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field appearance="fill">
  <mat-label>Tempo</mat-label>
  <span *ngIf="tempoNumber || bpm" class="status-clear" (click)="tempoNumber = false;bpm = '';exclusiveChanged($event)" class="status-clear">Clear </span>
  <mat-select class="custom-backdrop-class" panelClass="filter-select filter-tempo" [disableOptionCentering]="true" [(ngModel)]="tempoNumber" (selectionChange)="tempoSearch($event);clearBtnFlag = true"  placeholder="All" #tempoSelect>
   <div class="bpm-box"><strong>ENTER BPM</strong><input class="max-level" type="text" value="bpm" [(ngModel)]="bpm"  maxlength="3" (keyup) ="data($event.target.value)" pattern ='^[0-9]*$' (change)="exclusiveChanged($event)"></div>
    <mat-option>
      <ngx-mat-select-search [formControl]="tempoFilterCtrl" placeholderLabel="Search a Tempo" (keypress)="omitSpclChar($event, 'tempo')">
        <svg ngxMatSelectSearchClear class="tempo-filter-clear-icon filter-clear-icon" width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                    <g id="Genre-Options">
                        <g id="Close" transform="translate(1439.000000, 32.000000)">
                            <path d="M24,0 L0,24" id="Path"></path>
                            <path d="M0,0 L24,24" id="Path"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </ngx-mat-select-search>
    </mat-option>

    <mat-option [value]="false" style="display: none;">{{ tempoNumber ? tempoNumber : bpm ? bpm : 'All' }}</mat-option>

    <mat-option *ngFor="let option of filteredTempoOptions | async; trackBy: selectStringTrackBy" [value]="option.id">
      {{ option.tempo_name }}
    </mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field appearance="fill">
  <mat-label>Version</mat-label>
  <span *ngIf="versionNumber" class="status-clear" (click)="versionNumber = false;exclusiveChanged($event)" class="status-clear">Clear </span>
  <mat-select class="custom-backdrop-class" panelClass="filter-select filter-version" [disableOptionCentering]="true" (selectionChange)="versionSearch($event);clearBtnFlag = true;" [(ngModel)]="versionNumber" placeholder="Search">
    <mat-option>
      <ngx-mat-select-search (selectionChange)="versionSearch($event);clearBtnFlag = true"
        [formControl]="versionFilterCtrl"
        placeholderLabel="Search " (keypress)="omitSpclChar($event, 'version')">
        <svg ngxMatSelectSearchClear class="tempo-filter-clear-icon filter-clear-icon" width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                    <g id="Genre-Options">
                        <g id="Close" transform="translate(1439.000000, 32.000000)">
                            <path d="M24,0 L0,24" id="Path"></path>
                            <path d="M0,0 L24,24" id="Path"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </ngx-mat-select-search>
    </mat-option>

    <mat-option [value]="false" style="display: none;">All</mat-option>

    <mat-option *ngFor="let option of filteredVersionOptions | async; trackBy: selectStringTrackBy" [value]="option.value">
      {{ option.label }}
    </mat-option>
  </mat-select>
</mat-form-field>
<div class="additional-filter">
  <mat-checkbox [(ngModel)]="Instrumentalsflag" (ngModelChange)="exclusiveChanged($event);clearBtnFlag = true">
    Instrumentals
  </mat-checkbox>
</div>

<div class="additional-filter">
  <mat-checkbox [(ngModel)]="Vocalsflag" (ngModelChange)="exclusiveChanged($event);clearBtnFlag = true">
    Vocals
  </mat-checkbox>
</div>

<div class="additional-filter">
  <mat-checkbox [(ngModel)]="ExclusiveTracksflag" (click)="changeValue($event)" >
    Exclusive Tracks
  </mat-checkbox>
</div>

<div class="additional-filter">
  <mat-checkbox [(ngModel)]="MibeOwnedflag" (ngModelChange)="exclusiveChanged($event);clearBtnFlag = true" >
    MIBE Owned Tracks
  </mat-checkbox>
</div>


 