import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { Track } from '../models/track.model';
import { TrackService } from '../services/track.service';
import { TrackComposer } from '../models/trackcomposer.model';
import { TrackVersion } from '../models/trackversion.model';
import { CONTAINER_DATA, DialogConfig, SliderDialogService } from '../services/slider-dialog.service';
import { TrackVersionService } from '../services/trackversion.service';
import { forkJoin } from 'rxjs';
import { MenuListService } from '../services/menu-list.service';
import { environment } from '../../../environments/environment';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { UserService } from '../services/user.service';
import { type } from 'os';
import { Router } from '@angular/router';
import { DialogService } from '../services/dialog.service';
import { PlayerService } from '../services/player.service';
import { TrackDetailsService } from '../services/track-details.service';

@Component({
  selector: 'app-create-track',
  templateUrl: './create-track.component.html',
  styleUrls: ['./create-track.component.scss']
})
export class CreateTrackComponent implements OnInit {

  track: Track;
  set version(v: TrackVersion) {
    this._version = v;
  }
  get version(): TrackVersion {
    return this._version;
  }
  bpmValue: number = 0;
  @Output()
  versionChange = new EventEmitter<TrackVersion>();
  @Output() getNewTrack: EventEmitter<any> = new EventEmitter();
  _version: TrackVersion;
  composers: TrackComposer[];
  loading: boolean = false;
  currentUser: any;
  title: any;
  artistName: any;
  trackInfo: any;
  autoBpmValue: number;
  autoBpmData: boolean = false;
  confirmpopup: boolean = false;
  confirmData: boolean = false
  autoBpmValuepopup:boolean = false;
  moodsArray:any=[];
  constructor(
    private _sliderDialogService: SliderDialogService,
    private trackService: TrackService,
    private trackVersionService: TrackVersionService, private _router: Router,
    @Inject(CONTAINER_DATA) public componentData: DialogConfig,
    public list: MenuListService,
    public api: UserMenuService,
    public _DialogService:DialogService,
    public config: MenuConfigService,
    private _userService: UserService,
    private _dialogService: DialogService,  private _playerService: PlayerService,
    private trackDetailsService: TrackDetailsService
  ) {
    if (componentData.data?.mytrack.file) {
      this.getTrackInfo();
    }
   }
  newTrack: any = {
    "title": "",
    "description": null,
    "is_exclusive": true,
    "is_featured": false,
    "artist": null,
    "genre": "",
    "sub_genre": "",
    "lyrics": {
      "verse": "",
      "pre_chorus": "",
      "chorus": "",
      "bridge": ""
    },
    "owned_by": "",
    "moods": [],
    "albums": [],
    "metadata": [],
    "instruments": [],
    "vocal": [],
    "themes": [],
    "cowritten_by": [],
    "network_references": [],
    "tv_references": [],
    "artist_references": [],
    "band_references": [],
    "sonic_references": [],
    "vocal_desc": [],
    "instruments_desc": [],
    "versions": [],
    "Instrumentnew_suggestion":[],
    'isValid': true
  }
  trackVersion: any = {
    "calculated_bpm": "",
    "calculated_key": "",
    "duration": 0,
    "tempo": "",
    "is_instrumental": true,
    "is_vocals": false,
    "is_full_version": true,
    "is_main_track": false,
    "mp3_file":'',
    "title":"",
  }
  ngOnInit(): void {
    console.log(this.componentData.data, "CD")
    // this.track = this.trackService.trackPopData
    // this.version = this.trackService.versionPopData
    this.track = new Track(this.newTrack);
    console.log(this.track, "TT");
    this.version = this.trackVersion;
    console.log(this.version, "TV")
    this.composers = this.track.composers;
    this._userService.currentUserStream.subscribe((u) => {
      this.currentUser = u;
      this.artistName = this.currentUser.first_name + ' ' + this.currentUser.last_name;
    });
    this.version.mp3_file =  this.componentData.data.mytrack.file;
    this.version.title = this.componentData.data?.mytrack.file_name ;
    this.versionChange.emit(this._version);
  }

  bpmChange(val: any) {
    this.bpmValue = Math.round(val);
    this.version.calculated_bpm = Math.round(val);
    this.versionChange.emit(this._version);
  }

  onSave() {
console.log(this.track, "OnS")
console.log(this.version, "OnSV")
if(this.track.moods1 && this.track.moods1!=undefined){
  this.moodsArray.push(this.track.moods1)
}
if(this.track.moods2 && this.track.moods2!=undefined){
  this.moodsArray.push(this.track.moods2)
}
if(this.track.moods3 && this.track.moods3!=undefined){
  this.moodsArray.push(this.track.moods3)
}

    let id:any=[]
    id = this.track.Instrumentsub_category;
    if (id) {
      this.track.instruments = this.track.instruments.filter(function (e) {
        if (e.id !== id) {
          return e;
        }
      })
      if(this.track &&this.track.Instrumentdescriptions){
      this.track.Instrumentdescriptions = this.track.Instrumentdescriptions.filter(function (e) {
        if (e !== id) {
          return e;
        }
      })
    }
    }
       
    if(this._version.calculated_bpm === this.autoBpmValue && this.confirmData === false){
      this.autoBpmValuepopup = true;
    }
    else{
      if(this.confirmData === false){
      this.autoBpmData = true;
      this.confirmPopupData();
      }
    }
    if (this.autoBpmData) {
      this.confirmpopup = true;

      if(this.confirmData) {
        if (this.trackService.cowritten_by.length > 0) {
          let newValues = [];
          for (let i = 0; i < this.trackService.cowritten_by.length; i++) {
            if (this.trackService.cowritten_by[i]?.disable!== true && this.trackService.cowritten_by[i]?.disable!==  true && this.trackService.cowritten_by[i]?.percent > 0) {
              newValues.push(this.trackService.cowritten_by[i])
            }
          }
          this.trackService.cowritten_by = newValues
        }
        let tv;
        let network, sonic, band;
       // <!-- Add Newsuggestion -->
        if (this.track.tv_references) {
          tv = this.track.tv_references.filter(function (e) {
            if (e.id !== '') {
              return e;
            }
          })
        }
        if (this.track.network_references) {
          network = this.track.network_references.filter(function (e) {
            if (e.id !== '') {
              return e;
            }
          })
        }

        if (this.track.sonic_references) {
          sonic = this.track.sonic_references.filter(function (e) {
            if (e.id !== '') {
              return e;
            }
          })
        }
        if (this.track.band_references) {
          band = this.track.band_references.filter(function (e) {
            if (e.id !== '') {
              return e;
            }
          })
        }

        let data = {
          "title": this.track.title,
          "artist": this.currentUser.id,
          "genre": this.track.genre.id,
          "sub_genre": (this.track.sub_genre && this.track.sub_genre.id) ? this.track.sub_genre.id : "",
          "moods": this.moodsArray.map(x => x.id),
          "track_ending": this.version.track_ending,
          "is_instrumental": (this.version.is_instrumental === false && this.version.is_vocals === false) ? true : this.version.is_instrumental,
          "versions": this.version,
          "is_exclusive": true,
          "composers": [],
          "instruments": {
            "sub_category": this.track.Instrumentsub_category || [],
            "descriptions": this.track.Instrumentdescriptions || [],
            // "suggesstion": this.track.instruments.map(x => x.id) || [],
            "suggesstion": [],
            "new_suggestion": this.track.newAddedSuggestion || [],
            'new_ins':this.track.new_ins || [],
            'rm_ins': this.track.rm_ins || [],
            'new_ins_decs': this.track.Instrumentdescriptions || []
          },
          // "tv_references": [this.track.tv_references] || [],
          // "network_references": [this.track.network_references] || [],
          // "network_references": this.track.network_references.map(x => x.id),
          // "artist_references": [this.track.artist_references] || [],
          // "band_references": [this.track.band_references] || [],
        //  "sonic_references": this.track.sonic_references.map(x => x.id),
          //"sonic_references":[],
         //"tv_references": this.track.tv_references.map(x => x.id),
         // "network_references": this.track.network_references.map(x => x.id),

         "sonic_references": sonic.map(x => x.id),
         
        //  "sonic_references": {
        //   "sub_category": sonic.map(x => x.id),
        //   "new_suggestion": this.track.sonic_referencesNew_suggestion || []
        // },
           // <!-- Add Newsuggestion -->

          "tv_references": {
            "sub_category": tv.map(x => x.id),
            //"descriptions": this.track.tv_referencesDescriptions || [],
          //"suggesstion": [],
            "new_suggestion":  []
          },
          "network_references": {
            "sub_category": network.map(x => x.id),
           // "descriptions": this.track.network_referencesDescriptions || [],
           // "suggesstion": [],
            "new_suggestion": []
          },
          //"artist_references": this.track.artist_references.map(x => x.id),
          "artist_references": [],
          "band_references": band.map(x => x.id),
          "lyrics_data": {
            "verse": this.track.lyrics.verse?this.track.lyrics.verse:[],
            "pre_chorus": this.track.lyrics.pre_chorus?this.track.lyrics.pre_chorus:[],
            "chorus": this.track.lyrics.chorus?this.track.lyrics.chorus:[],
            "bridge": this.track.lyrics.bridge?this.track.lyrics.bridge:[]
          },
          "vocal": {
            "sub_category": this.track.Vocalssub_category || "",
            "descriptions": this.track.Vocalsdescriptions || [],
            // "suggesstion": this.track.Vocalssuggesstion || [],
            "suggesstion": [],
            "new_suggestion": []
          },
          "albums": "",
          "cowritten_by": this.filter(this.trackService.cowritten_by) || [],
          // "calculated_bpm": this.version.calculated_bpm,
          // "calculated_key": this.version.calculated_key,
          // "tempo": this.version.tempo,
          "owned_by": this.currentUser.id,
          "upload_file_id": this.componentData.data.mytrack.id,
          "support_nw_ref":{
            "new_sug":this.track.network_referencesNew_suggestion || [],
            "rm_sug":this.track.newNetwork_ref_rm_sug || [],
        },
        "support_tv_ref":{
       
          "new_sug":this.track.tv_referencesNew_suggestion || [],
          "rm_sug":this.track.newTv_ref_rm_sug || [],
      },
      "support_sonic_ref":{
        "new_sug":this.track.sonic_referencesNew_suggestion || [],
        "rm_sug":this.track.newSonic_ref_rm_sug || [],
    },
    "support_band_ref":{
      "new_sug":this.track.band_referencesNew_suggestion || [],
      "rm_sug":this.track.newBand_ref_rm_sug || [],
  },
        }
        this.track.composers = this.composers;
        console.log(data, "Data");
        this.loading = true;
        let url = environment.apiURL + this.config.createTrackAPI;
        this.api.doPOST(url, data).subscribe(x => {
          this.loading = false
          this.getNewTrack.emit(true)
          this._playerService.nextTab(true)
          this._playerService.updateWavePlay(false);
          this._playerService.updateIsPlaying(false);
          this._playerService.updateShowMusicPlayer(false);
          this._playerService.play(null, null);
          this._playerService.updatePreviewPlaying(false);
          this._sliderDialogService.close(true);
        location.reload()
        },
          (error) => {
            this.loading = false;
             if (error.error && error.error?.is_logout) {
              // alert(error.error.error)
              let data = [];
              data.push(error.error.error)
              data.push(false);
              this._playerService.nextTab(true)
              this._playerService.updateWavePlay(false);
              this._playerService.updateIsPlaying(false);
              this._playerService.updateShowMusicPlayer(false);
              this._playerService.play(null, null);
              this._playerService.updatePreviewPlaying(false);
              this._sliderDialogService.close(data);

            }
            else if (error.error && error.error.error) {
              // alert(error.error.error)
              let data = error.error?.error ? error.error?.error : (error.error?.detail ?error.error?.detail : (error.error?.details ? error.error?.details:``) )
              this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
              });
             
            }
            
          })
      }
    }
  }

  filter(e) {
    let filData = []
    for (let x of e) {
      console.log(e)
      let data = {}
      data['user_id'] = x.id
      data['percent'] = x.percent ? x.percent : 0
      filData.push(data)
    }
    return filData
  }

  getTrackInfo() {
    let trackData = {
      "file_path": this.componentData.data.mytrack.file
    }
    let requestUrl = environment.apiURL + `/api/v1/track/process-files/`
    this.api.doPOST(requestUrl, trackData).subscribe(x => {
      let arr;
      arr = x;
      this.trackInfo = arr;
      if(this.trackInfo){
        this.autoBpmValue = Math.round(arr.bpm)
        this.version.duration = this.trackInfo.duration.toFixed(2);

        // //Robert: 5/17/24 - assigning key value here
        this.version.calculated_key = this.trackDetailsService.getKeyFromList(this.trackInfo.key_with_scale);
        this.versionChange.emit(this._version);
      }
    })
  }
  saveData(){
    this.autoBpmData = true;
    this.autoBpmValuepopup = false;
    this.confirmPopupData();
  }
  confirmPopupData(){
    this._dialogService.displayConfirmPopup(this.track.title,"create").subscribe(changed => {
      if (changed) {
        this.confirmData= true
      }
      if(this.confirmData){
        this.onSave()
      }
    })
  }
}
