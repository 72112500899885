import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSortable } from '@angular/material/sort';
import { User } from '../../shared/models/user.model';
import { DialogService } from '../../shared/services/dialog.service';

import { environment } from '../../../environments/environment';


import { EmailGroup } from '../../shared/models/email-group.model';
import { GetUsersOptions, UserService } from '../../shared/services/user.service';
import { EmailGroupService, GetEmailGroupOptions } from '../../shared/services/email-group.service';
import { MenuListService } from '../../shared/services/menu-list.service';

const usersPageSize = 30;
const groupPageSize = 25;

@Component({
  selector: 'app-user-mgmt-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit, OnDestroy {

  loadingUsers = true;
  loadingGroups = false;
  loadingFolders = false;
  searchFilterFocused = false;
  musicPlayerCollapsed = false;

  get apiURL(): string {
    return environment.apiURL;
  }

  set activeTab(t:'clients'|'artists') {
    this._currentTab = t;
    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: this._params,
    });
  }
  get activeTab() {
    return this._currentTab;
  }

  set currentGroup(p:EmailGroup) {
    if (this._currentGroup && p && this._currentGroup.id == p.id) {
      return;
    }
    this.checkedUsers = [];
    this._currentGroup = p;
    this.loadUsers();
    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: this._params,
    });
  }
  get currentGroup(): EmailGroup {
    return this._currentGroup;
  }

  get startDate() {
    return this._startDate;
  }
  set startDate(d: Date) {
    this._startDate = d
    this._currentUsersPage = 1;
    this.loadGroups();
    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: this._params,
    });
  }
  get endDate() {
    return this._endDate;
  }
  set endDate(d: Date) {
    this._endDate = d
    this._currentUsersPage = 1;
    this.loadGroups();
    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: this._params,
    });
  }

  get totalGroups():number {
    if (this.activeTab == 'clients') {
      return this._totalClientGroups;
    }
    return this._totalArtistGroups;
  }
  set totalGroups(total:number) {
    if (this.activeTab == 'clients') {
      this._totalClientGroups = total;
    } else {
      this._totalArtistGroups = total;
    }
  }

  set groups(p: EmailGroup[]) {
    this._groups = p;
    // if (!this.currentGroup && p && p.length > 0) {
    if (p && p.length > 0) {
      this.currentGroup = p[0];
    }
  }
  get groups(): EmailGroup[] {
    return this._groups;
  }
  set currentGroupsPage(p: number) {
    if (!isNaN(p) && p != this._currentGroupsPage) {
      this._currentGroupsPage = p;
      this.loadGroups();
      this._router.navigate([], {
        relativeTo: this._activatedRoute,
        queryParams: this._params,
      });
    }
  }
  get currentGroupsPage(): number {
    return this._currentGroupsPage;
  }
  get groupsNumberOfPages(): number {
    return Math.ceil(this.totalGroups / groupPageSize);
  }

  totalUsers = 0;
  set currentUsersPage(p: number) {
    if (!isNaN(p) && p != this._currentUsersPage) {
      this._currentUsersPage = p;
      this.loadUsers();
      this._router.navigate([], {
        relativeTo: this._activatedRoute,
        queryParams: this._params,
      });
    }
  }
  get currentUsersPage(): number {
    return this._currentUsersPage;
  }
  get usersNumberOfPages(): number {
    return Math.ceil(this.totalUsers / usersPageSize);
  }
  users: Observable<ReadonlyArray<User>>;

  get ordering(): string {
    return this._ordering;
  }
  set ordering(o: string) {
    if (o != this._ordering) {
      this._ordering = o;
      this._currentUsersPage = 1;
      this.loadUsers();
      this._router.navigate([], {
        relativeTo: this._activatedRoute,
        queryParams: this._params,
      });
    }
  }

  get searchString(): string {
    return this._searchString;
  }
  set searchString(s: string) {
    if (this._searchString != s) {
      this._searchString = s;
      this.loadGroups();
    }
    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: this._params,
    });
  }

  private get _params(): GetUsersOptions {
      let params: GetUsersOptions = {};
    if (this.ordering && this.ordering !== 'id') {
      params['ordering'] = this.ordering;
    }
    if (this.activeTab && this.activeTab !== 'clients') {
      params['tab'] = this.activeTab;
    }
    if (this.currentGroup) {
      params['group'] = this.currentGroup.id;
    }
    if (this.ordering) {
      params['ordering_by'] = this.ordering;
    }
    if (this.currentGroupsPage && this.currentGroupsPage != 1) {
      params['grouppage'] = `${this.currentGroupsPage}`;
    }
    if (this.currentUsersPage && this.currentUsersPage != 1) {
      params['userspage'] = `${this.currentUsersPage}`;
    }
    if (this.searchString) {
      params['search'] = this.searchString;
    }
    return params;
  }

  checkedUsers: User[] = [];
  private _ordering: string;
  private _totalClientGroups = 0;
  private _totalArtistGroups = 0;
  private _currentUsersPage = 1;
  private _currentGroupsPage = 1;
  private _currentTab:'clients'|'artists' = 'clients';
  private _currentGroup: EmailGroup = null;
  private _searchString = '';
  private _startDate: Date;
  private _endDate: Date;
  private _groups: EmailGroup[];
  private _getUsersSubscription: Subscription;
  private _usersSubject: BehaviorSubject<
    ReadonlyArray<User>
  > = new BehaviorSubject<ReadonlyArray<User>>([]);

  private _subscriptions: Subscription[] = [];

  currentClientPage: number = 1;
  currentArtistPage: number = 1;
  
  selectedDateType: string = "Created At";
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    public _emailGroupService: EmailGroupService,
    private _dialogService: DialogService,
    public list: MenuListService
  ) {
    this.users = this._usersSubject.asObservable();
  }

  ngOnInit(): void {
    this._subscriptions.push(
      this._activatedRoute.queryParamMap.subscribe((params) => {
        let tab = params.get('tab');
        if (tab) {
          this.activeTab = tab as ('clients'|'artists');
        }
        let group = params.get('group');
        let groupId: number = null;
        if (group !== null) {
          groupId = parseInt(group);
          if (!isNaN(groupId)) {
            this._emailGroupService.getGroup(groupId).subscribe(p => {
              // this.currentGroup = p;
            }, (error) => {
              console.log(error);
              //alert("An error ocurred while getting the group. Check your connection and try again")
            })
          }
        }

        let ordering = params.get('ordering');
        this.ordering = ordering;

        let grouppage = params.get('grouppage');
        if (grouppage !== null) {
          this.currentGroupsPage = parseInt(grouppage);
        } else {
          this.currentGroupsPage = 1;
        }

        let userspage = params.get('userspage');
        if (userspage !== null) {
          this.currentUsersPage = parseInt(userspage);
        } else {
          this.currentUsersPage = 1;
        }

        let searchString = params.get('search');
        if (searchString !== null) {
          this.searchString = searchString;
        } else {
          this.searchString = '';
        }
      })
    );
    this.switchTab(this.activeTab);
    this.loadUsers();
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((s) => s.unsubscribe());
    this._subscriptions = [];
  }

  loadGroups() {
    this.loadingGroups = true;
    let params:GetEmailGroupOptions = {
      type: this.activeTab == 'clients' ? 'CLIENT':'ARTIST',
      limit: groupPageSize,
      offset: (this.currentGroupsPage - 1) * groupPageSize,
      page: this._currentGroupsPage
    };
    if (this.searchString && this.searchString != '') {
      params.searchString = this.searchString;
    }

    this._emailGroupService
      .getAllGroups(params, null)
      .subscribe((p) => {
        this.loadingGroups = false;
        this.totalGroups = p.count;
        this.groups = p.results;
        if (this.totalGroups > 0) {
          this.loadUsers();
        }
      }, (error) => {
        console.log(error);
        //alert("An error ocurred while getting the groups. Check your connection and try again");
      });
  }

  loadUsers() {
    if (this.totalGroups > 0) {
    if (!this.currentGroup || !this.currentGroup.id) {
      return;
    }
    this.loadingUsers = true;
    if (this._getUsersSubscription && !this._getUsersSubscription.closed) {
      this._getUsersSubscription.unsubscribe();
    }
    let params;
    if(this.activeTab=='clients'){
      params = {
        isClient:true,
        groupId: this.currentGroup.id,
        limit: usersPageSize,
        offset: (this.currentUsersPage - 1) * usersPageSize,
        type: 'group'
      };
    }else{
      params = {
        isArtist:true,
        groupId: this.currentGroup.id,
        limit: usersPageSize,
        offset: (this.currentUsersPage - 1) * usersPageSize,
        type: 'group'
      };
    }
    this._getUsersSubscription = this._userService
      .getAllUsers({
        isClient:true,
        groupId: this.currentGroup.id,
        limit: usersPageSize,
        offset: (this.currentUsersPage - 1) * usersPageSize,
        type: 'group',
        ...this._params
      })
      .subscribe((t) => {
        this.totalUsers = t.count;
        this._usersSubject.next(t.results);
        this.loadingUsers = false;
      }, (error) => {
        console.log(error);
        //alert("An error ocurred while getting the users for the group. Check your connection and try again");
      });
    }
  }
  get currentPage():number {
    return this.activeTab == 'clients' ? this.currentClientPage:this.currentArtistPage;
  }

  set currentPage(page) {
    if (this.currentPage != page) {
      if (this.activeTab == 'clients') {
        this.currentClientPage = page;
      } else {
        this.currentArtistPage = page;
      }
    }
  }
  private get currentQueryParams() {
    return {
      'page': this.currentPage > 1 ? this.currentPage:null,
      'start': this.startDate ? this.startDate.toISOString():null,
      'end': this.endDate ? this.endDate.toISOString():null,
      'datetype': this.selectedDateType == "Created At" ? null:this.selectedDateType,
      'search': this.searchString
    }
  }
  private updateQueryParams() {
    this._router.navigate(['.'], {
      relativeTo: this._activatedRoute,
      queryParamsHandling: 'merge',
      queryParams: this.currentQueryParams
    })
  }
  sortChange(data: MatSortable) {
    this.ordering = (data.start === 'desc' ? '-' : '') + data.id;
  }
  submitSearch() {
    this.searchFilterFocused = false;
    this.loadGroups();
    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: this._params,
    });
  }

  displayDuplicateGroupDialog(data) {
    this._dialogService.displayNewUserEmailGroupDialog(`${this.currentGroup.name} copy`, true, data.id).subscribe(changed => {
      if (changed) {
        this.loadGroups();
      }
    })
  }

  displayNewGroupDialog() {
    this._dialogService.displayNewUserEmailGroupDialog().subscribe(changed => {
      if (changed) {
        this.loadGroups();
      }
    })
  }

  displayRemoveUsersFromGroupDialog() {
    this._dialogService.displayRemoveUsersFromEmailGroupDialog(this.checkedUsers, this.currentGroup.id).subscribe(changed => {
      if (changed) {
        this.loadUsers();
      }
    })
  }

  displayDeleteGroupDialog() {
    this._dialogService.displayDeleteUserEmailGroupDialog(this.currentGroup).subscribe(changed => {
      if (changed) {
        this.currentGroup = null;
        this.searchString = ""
        this.loadGroups();
        this.loadUsers();
      }
    });
    
  }

  switchTab(val){
    this.searchString =""
    this.activeTab = val;
    this.currentGroupsPage = 1;
    this.totalUsers = 0;
    this._usersSubject.next([]);
    this.loadGroups();
    this.loadUsers();
  }

  groupDisplayName(p:EmailGroup): string {
    if (!p || !p.id) return '';
    return `${this.formatDate(p.created_at)} - ${p.name}`;
  }



  formatDate(d:Date): string {
    return `${d.getMonth()+1}/${d.getDate()}/${d.getFullYear().toString().substr(-2)}`;
  }

  groupTrackBy(i: number, group: EmailGroup) {
    return group.id + group.name;
  }
  getSingleGroup(){
    
  }

}
