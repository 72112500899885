import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { TrackVersionService } from '../services/trackversion.service';
import { TrackVersion } from '../models/trackversion.model';
import { Waveform } from '../models/waveform.model';

const svgViewBoxWidth = 553;
const svgViewBoxHeight = 92;

var nextId = 0;

@Component({
  selector: 'app-waveform',
  templateUrl: './waveform.component.html',
  styleUrls: ['./waveform.component.scss']
})
export class WaveformComponent implements OnInit, OnDestroy {
  @Input()
  set version(v: TrackVersion) {
    this._version = v;
    this.waveformData = null;
    if (!v) { return; }
    this.loading = true;
    this._trackVersionService.getVersionWaveformData(v).subscribe(w => {
      this.waveformData = w;
      this.loading = false;
    });
  }
  get version(): TrackVersion {
    return this._version;
  }

  @Input()
  trackProgress: number;
  @Output()
  trackProgressChange = new EventEmitter();

  @Input()
  fillColor: string = 'black';
  @Input()
  completeColor: string = 'black';
  @Input()
  interactionEnabled = true;

  @ViewChild('waveformEl', {static: true})
  waveformEl: ElementRef;

  get hoveringPercent(): string {
    return (this.hoveringProgress * 100) + '%';
  }
  get hoveringTimestamp(): number {
    return this.hoveringProgress * this.version.duration;
  }

  set waveformData(w: Waveform) {
    this._waveformData = w;
    this.points = '0,' + svgViewBoxHeight/2;
    if (!w) { return; }
    let tot = w.data.length;
    // Find the largest value in the waveform
    let largest = 1;
    /*for (let i=0; i < tot; i++) {
      largest = Math.max(Math.abs(w.data[i]), largest);
    }*/
    // Draw Top Of Waveform
    for (let i=0; i < tot; i++) {
      this.points += ' ' + ((svgViewBoxWidth / tot) * i) + ',';
      this.points += ((w.data[i][0] / largest) * (svgViewBoxHeight / 2) + ((svgViewBoxHeight / 2) + 0.25));
    }
    // Draw bottom of waveform
    for (let i=w.data.length-1; i > -1; i--) {
      this.points += ' ' + ((svgViewBoxWidth / tot) * i) + ',';
      this.points += ((w.data[i][1] / largest) * (svgViewBoxHeight / 2) + ((svgViewBoxHeight / 2) - 0.25));
    }
  }
  get waveformData(): Waveform {
    return this._waveformData;
  }

  get viewBox(): string {
    return '0 0 ' + svgViewBoxWidth + ' ' + svgViewBoxHeight;
  }

  get baseURL(): string {
    return this._baseURL;
  }

  points: string;

  loading = false;

  hovering = false;
  panning = false;
  hoveringProgress = 0;

  filterId = `fill-complete-${nextId++}`;

  private _version: TrackVersion;
  private _waveformData: Waveform;
  private _subscriptions: Subscription[] = [];
  private _baseURL = '';

  constructor(
    private _trackVersionService: TrackVersionService,
    private _router: Router
  ) { }

  ngOnInit() {
    // This is a lame hack that's required because SVG filters have an issue with
    // a <base href="/"> in HTML so the filter must be referenced via an absolut URL
    this._baseURL = this._router.url;
    this._subscriptions.push(
      this._router.events.subscribe(e => {
        if (this._baseURL != this._router.url) {
          this._baseURL = this._router.url;
        }
      })
    );
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._subscriptions = [];
  }

  mouseenter(e) {
    this.hovering = true;
    let bb = this.waveformEl.nativeElement.getBoundingClientRect();
    this.hoveringProgress = Math.max(Math.min((e.clientX - bb.left) / bb.width, 1), 0);
  }
  mousemove(e) {
    let bb = this.waveformEl.nativeElement.getBoundingClientRect();
    this.hoveringProgress = Math.max(Math.min((e.clientX - bb.left) / bb.width, 1), 0);
  }
  mouseleave(e) {
    this.hovering = false;
  }
  mousedown(e) {
    e.preventDefault();
  }
  tapped(e) {
    if (!this.interactionEnabled) { return; }
    let bb = this.waveformEl.nativeElement.getBoundingClientRect();
    this.trackProgress = (e.center.x - bb.left) / bb.width;
    this.trackProgressChange.emit(this.trackProgress);
  }
  panstart(e) {
    if (!this.interactionEnabled) { return; }
    e.preventDefault();
    if (e.srcEvent.stopPropagation) { e.srcEvent.stopPropagation(); }
    this.panning = true;
    let bb = this.waveformEl.nativeElement.getBoundingClientRect();
    this.hoveringProgress = Math.max(Math.min((e.center.x - bb.left) / bb.width, 1), 0);
    this.trackProgress = this.hoveringProgress;
    this.trackProgressChange.emit(this.trackProgress);
  }
  pan(e) {
    if (!this.interactionEnabled) { return; }
    e.preventDefault();
    if (e.srcEvent.stopPropagation) { e.srcEvent.stopPropagation(); }
    let bb = this.waveformEl.nativeElement.getBoundingClientRect();
    this.hoveringProgress = Math.max(Math.min((e.center.x - bb.left) / bb.width, 1), 0);
    this.trackProgress = this.hoveringProgress;
    this.trackProgressChange.emit(this.trackProgress);
  }
  panend(e) {
    if (!this.interactionEnabled) { return; }
    e.preventDefault();
    if (e.srcEvent.stopPropagation) { e.srcEvent.stopPropagation(); }
    this.panning = false;
  }
}
