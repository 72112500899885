import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { of, ReplaySubject, Subscription } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { Artist } from '../models/artist.model';
import { BandReference } from '../models/bandreference.model';
import { Instrument } from '../models/instrument.model';
import { NetworkReference } from '../models/networkreference.model';
import { SonicReference } from '../models/sonicreference.model';
import { Track } from '../models/track.model';
import { TrackVersion } from '../models/trackversion.model';
import { TvReference } from '../models/tvreference.model';
import { Vocals } from '../models/vocals.model';
import { MenuListService } from '../services/menu-list.service';
import { SliderDialogService } from '../services/slider-dialog.service';
import { SonicReferenceService } from '../services/sonicreference.service';
import { UserMenuService } from '../services/user-menu.service';
import { UserService } from '../services/user.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { environment } from '../../../environments/environment';
import { timeStamp } from 'console';


@Component({
  selector: 'app-new-track-description',
  templateUrl: './new-track-description.component.html',
  styleUrls: ['./new-track-description.component.scss']
})
export class NewTrackDescriptionComponent implements OnInit {

  @Input()
  set version(v: TrackVersion) {
    this._version = v;
    this.versionChange.emit(this._version);
  }
  get version(): TrackVersion {
    return this._version;
  }
  @Output()
  versionChange = new EventEmitter<TrackVersion>();

  _version: TrackVersion;
  showMetaFor: number = -1;
  instrumentationPanelOpen: boolean;
  tvNetworkPanelOpen: boolean;
  sonicPanelOpen: boolean;
  lyricsPanelOpen: boolean;
  vocalsPanelOpen: boolean;
  sonicUpdate: boolean = false;
  instDesc: any;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  networkSuggestion: any = [];
  tvSuggestion: any[] = []
  newAddedSuggestion: any[] = []
  InstruDesc: any = []
  bandRef: any = []
  sonicRef: any = []
  newSonic = []
  newband = []

  instrumentCtrl = new FormControl();
  filteredInstruments: ReplaySubject<Instrument[]> = new ReplaySubject<Instrument[]>(1);;
  allInstruments: Instrument[] = [];

  network1Ctrl = new FormControl();
  filteredNetworks: ReplaySubject<NetworkReference[]> = new ReplaySubject<NetworkReference[]>(1);;
  allNetworks: NetworkReference[] = [];
  networkNewCtrl = new FormControl();

  sonicCtrl = new FormControl();
  filteredSonics: ReplaySubject<SonicReference[]> = new ReplaySubject<SonicReference[]>(1);;
  allSonics: SonicReference[] = [];

  vocalCtrl = new FormControl();
  filteredVocals: ReplaySubject<Vocals[]> = new ReplaySubject<Vocals[]>(1);;
  allVocal: Vocals[] = [];

  tvCtrl = new FormControl();
  filteredTV: ReplaySubject<TvReference[]> = new ReplaySubject<TvReference[]>(1);;
  allTV: TvReference[] = [];
  tvNewCtrl = new FormControl();

  bandCtrl = new FormControl();
  filteredBands: ReplaySubject<BandReference[]> = new ReplaySubject<BandReference[]>(1);;
  allBands: BandReference[] = [];

  artistCtrl = new FormControl();
  filteredArtists: ReplaySubject<Artist[]> = new ReplaySubject<Artist[]>(1);;
  allArtists: Artist[] = [];

  showVerseField: Boolean = false;
  showPreChorusField: Boolean = false;
  showChorusField: Boolean = false;
  showBridgeField: Boolean = false;

  @ViewChild('instrumentInput') instrumentInput: ElementRef<HTMLInputElement>;
  @ViewChild('networkInput') networkInput: ElementRef<HTMLInputElement>;
  @ViewChild('sonicInput') sonicInput: ElementRef<HTMLInputElement>;
  @ViewChild('vocalInput') vocalInput: ElementRef<HTMLInputElement>;
  @ViewChild('tvInput') tvInput: ElementRef<HTMLInputElement>;
  @ViewChild('artistInput') artistInput: ElementRef<HTMLInputElement>;
  @ViewChild('bandInput') bandInput: ElementRef<HTMLInputElement>;
  @ViewChild("instrumentsPanel") instrumentsPanel: any;
  @ViewChild("networkPanel") networkPanel: any;
  @ViewChild("sonicPanel") sonicPanel: any;
  @ViewChild("lyricsPanel") lyricsPanel: any;
  @ViewChild("vocalsPanel") vocalsPanel: any;
  @ViewChild('instrumentInput', { read: MatAutocompleteTrigger })
  autoComplete: MatAutocompleteTrigger;
  @ViewChild('networkInput', { read: MatAutocompleteTrigger })
  autoComplete1: any;
  @ViewChild('sonicInput', { read: MatAutocompleteTrigger })
  autoComplete2: MatAutocompleteTrigger;
  @ViewChild('vocalInput', { read: MatAutocompleteTrigger })
  autoComplete3: MatAutocompleteTrigger;
  @ViewChild('tvInput', { read: MatAutocompleteTrigger })
  autoComplete4: MatAutocompleteTrigger;
  @ViewChild('artistInput', { read: MatAutocompleteTrigger })
  autoComplete5: MatAutocompleteTrigger;
  @ViewChild('bandInput', { read: MatAutocompleteTrigger })
  autoComplete6: MatAutocompleteTrigger;

  allInstrumentationSubCategory: any = [];
  allVolDescList: any = [];
  artistReferences: any = [];
  bandReferences: any = [];
  allnetworkReferences: any = [];
  tvReferences: any = [];
  newValue: any = [];
  networkDesc: any = [];
  tvDesc: any = []
  @Input()
  set track(t: Track) {
    this._track = t;
    this.trackChange.emit(this._track);
  }
  get track(): Track {
    return this._track;
  }

  @Output()
  trackChange = new EventEmitter<Track>();

  newInsSuggestion: any = []
  private _track: Track;
  private _subscriptions: Subscription[] = []
  isInstrumental: Boolean;
  trackType: any;
  replaceTrackVersion: Boolean = false;
  inDescription: any = [];
  nameSuggestion: any = [];
  vocalDescription: any = [];
  vocalSuggestion: any = [];
  VocalDesc: any = [];
  instrumentData = [];
  oldInstDescription = [];

  values = [];
  data = [];
  preChrousValues = [];
  preChrousData = [];
  chrousValues = [];
  chrousData = [];
  bridgeValues = [];
  bridgeData = [];
  currentUser: any = []
  tvRef: any = []
  netRef: any = []

  constructor(
    private api: UserMenuService, public _userService: UserService,
    public list: MenuListService, private _sliderDialogService: SliderDialogService
  ) {
    this.getSubCategory();
    this.getAllvocalstype();
    this.getTVReferences();
    this.getnetworkReferences();
    // this.getartistReferences();
    // this.getbandReferences();
    this.gecontextReferences();
    this.getSonicReferences();
  }

  ngOnInit() {
    this.track.Instrumentrm_desc_sug = []
    this.track.Instrumentnew_desc_sug = []
    this.track.new_ins = []
    this.track.rm_ins = []
    this.track.new_ins_decs = []

    this.track.sonic_referencesNew = []
    this.track.version_band_referencesNew = []

    this.track.sonicRemove = [];
    this.track.bandRemove = [];

    this.track.Vocalnew_desc_sug = []
    this.track.Vocalrm_desc_sug = []
    this.track.tv_ref_new_sug = []
    this.track.tv_ref_rm_sug = []
    this.track.newTv_ref_rm_sug = []
    this.track.newNetwork_ref_rm_sug = []
    this.track.network_ref_new_sug = []
    this.track.network_ref_rm_sug = []
    this.track.vers_new_sonic_band = [], this.track.vers_new_sonic_ref = [], this.track.vers_rm_sonic_band = [];
    this.track.vers_rm_sonic_ref = [],
      this.track.newAddedSuggestion = []
    if (!this._track['version_lyrics']) {

      this._track['version_lyrics'] = this._track['lyrics']
    }
    this._track['version_lyrics'] = (this._track['version_lyrics']) ? this._track['version_lyrics'] : this._track['lyrics']
    if (!this._track['version_lyrics']) {
      var dat = ''
      var lyrics = {
        verse: dat,
        pre_chorus: dat,
        chorus: dat,
        bridge: dat
      }
      this._track['version_lyrics'] = lyrics
    }

    this._userService.currentUserStream.subscribe((u) => {
      console.log(u)
      this.currentUser = u;
    })
    this._sliderDialogService.getReplaceTrackVersion().subscribe((val) => {
      if (val && val == 'replace') {
        console.log(val);
        this.replaceTrackVersion = true;

      }
    });
    this.list.getInstrumentalTyep().subscribe((type) => {
      console.log(type)
      this.isInstrumental = type;
      if (this.isInstrumental !== false) {
        if (this._track.version_lyrics) {
          if (typeof (this._track.version_lyrics?.verse) !== 'string') {
            if (this._track.version_lyrics.verse?.length > 0) {
              for (let i = 0; i < this._track.version_lyrics.verse.length; i++) {
                this.showVerseField = true
                if (this._track.version_lyrics.verse[i] !== '')
                  this.values.push({ value: this._track.version_lyrics.verse[i] });
              }
            }
          }
          if (typeof (this._track.version_lyrics.pre_chorus) !== 'string') {
            if (this._track.version_lyrics.pre_chorus?.length > 0) {
              for (let i = 0; i < this._track.version_lyrics.pre_chorus.length; i++) {
                this.showPreChorusField = true
                if (this._track.version_lyrics.pre_chorus[i] !== '')
                  this.preChrousValues.push({ value: this._track.version_lyrics.pre_chorus[i] });
              }
            }
          }
          if (typeof (this._track.version_lyrics?.chorus) !== 'string') {
            if (this._track.version_lyrics.chorus?.length > 0) {
              for (let i = 0; i < this._track.version_lyrics.chorus.length; i++) {
                this.showChorusField = true
                if (this._track.version_lyrics.chorus[i] !== '')
                  this.chrousValues.push({ value: this._track.version_lyrics.chorus[i] });
              }
            }
          }
          if (typeof (this._track.version_lyrics?.bridge) !== 'string') {
            if (this._track.version_lyrics.bridge?.length > 0) {
              for (let i = 0; i < this._track.version_lyrics.bridge.length; i++) {
                this.showBridgeField = true
                if (this._track.version_lyrics.bridge[i] !== '')
                  this.bridgeValues.push({ value: this._track.version_lyrics.bridge[i] });
              }
            }
          }
        }
        else {
          let dat = ''
          let lyrics = {
            verse: dat,
            pre_chorus: dat,
            chorus: dat,
            bridge: dat
          }
          this._track['version_lyrics'] = lyrics
        }

      }

    }, (error) => {
      console.log(error);
    })
    this.track.version_sonic_reference = (this.track.version_sonic_reference?.length > 0) ? this.track.version_sonic_reference : this.track.track_sonic_reference
    this.track.version_band_references = (this.track.version_band_references?.length > 0) ? this.track.version_band_references : this.track.band_references

    this.showMetaFor = this._track && this._track?.added_by_role ? this._track?.added_by_role : -1;
    this._track.version_instruments = (this._track.version_instruments?.length > 0) ? this._track.version_instruments : this._track.instruments
    if (this._track.version_instruments?.length > 0) {
      for (let i of this._track.version_instruments) {
        this.instrumentData.push(i.id)
      }
      this.track.Instrumentsub_category = this.instrumentData
      this.getArtist(this.instrumentData, 'ini')
    }
    if (this._track.Instrumentsub_category?.length > 0) {
      this.track.Instrumentsub_category = (this._track.Instrumentsub_category)
      this.getArtist(this._track.Instrumentsub_category, 'ini')
    }
    console.log(this._track['version_instruments_desc'].length)
    this._track['version_instruments_desc'] = (this._track['version_instruments_desc']?.length > 0) ? this._track['version_instruments_desc'] : this._track['instruments_desc']

    this.InstruDesc = this._track['version_instruments_desc']
    if (this._track['version_instruments_desc']?.length > 0) {
      let filData = []
      for (let x of this._track['version_instruments_desc']) {
        console.log(x)
        let data = {}
        if (x.id !== null && x.name !== null) {
          data['id'] = x.id
          filData.push(x.id)
          this.inDescription.push(x.id)
          this.oldInstDescription.push(x.id)
          this.nameSuggestion.push(x.name);
        }
      }
      this.track.Instrumentdescriptions = this.inDescription
      this.track.Instrumentnew_suggestion = this.nameSuggestion
      this.newValue = this.inDescription
      if (this.inDescription.length == 0) {
        this.track.version_instruments = []
      }
      console.log("desc", filData)

    }
    // <!-- Add Newsuggestion -->

    let newData = [], tvData = []
    if (this._track['tv_desc']?.length > 0) {
      for (let x of this._track['tv_desc']) {
        if (x.name !== null) {
          let data = {
            id: '',
            name: x.name
          }
          tvData.push(data)
          this.networkDesc.push(x.name)
          newData.push(x)
        }
      }
      for (let val of tvData) {
        this.track.version_tv_references.push(val)
      }

      this.track.version_tv_references.push(tvData)
      this.track.tv_referencesDescriptions = newData

    }
    this.track.version_tv_references = (this.track.version_tv_references?.length > 0) ? this.track.version_tv_references : this.track.tv_references
    this.track.version_network_references = (this.track.version_network_references?.length > 0) ? this.track.version_network_references : this.track.network_references

    this.tvRef = this.track.version_tv_references;
    this.netRef = this.track.version_network_references;

    let networkData = [], valData = []
    if (this._track['network_desc']?.length > 0) {
      for (let x of this._track['network_desc']) {
        if (x.name !== null) {
          let data = {
            id: '',
            name: x.name
          }
          valData.push(data)
          this.networkDesc.push(x.name)
          networkData.push(x)
        }
      }
      for (let val of valData) {
        this.track.version_network_references.push(val)
      }

      this.track.network_referencesDescriptions = networkData
    }
    this._track['version_vocal'] = (this._track['version_vocal']?.length > 0) ? this._track['version_vocal'] : this._track['vocal']

    if (this._track['version_vocal']?.length > 0) {
      this.track.Vocalssub_category = this._track['version_vocal'][0].id
      this.getVocDesc(this.track.Vocalssub_category)
    }
    let filDataVoc = []
    this._track['version_vocal_desc'] = (this._track['version_vocal_desc']?.length > 0) ? this._track['version_vocal_desc'] : this._track['vocal_desc']

    this.VocalDesc = this._track['version_vocal_desc'];
    if (this._track['version_vocal_desc']?.length > 0) {
      for (let x of this._track['version_vocal_desc']) {
        let data = {}
        if (x.thesaurus_name !== null) {
          data['id'] = x.thesaurus_id
          filDataVoc.push(x.thesaurus_id)
          this.vocalDescription.push(x.thesaurus_id)
          this.vocalSuggestion.push(x.thesaurus_name);
        }
      }
      this.track.Vocalsdescriptions = this.vocalDescription;
      this.track.Vocalsnew_suggestion = this.vocalSuggestion;

    }

    this.list.getTrackType().subscribe((type) => {
      console.log(type)
      this.trackType = type;
    }, (error) => {
      console.log(error);
    })
    // if(this.isInstrumental!==false){
    //   if(this._track.version_lyrics){
    //     if(typeof (this._track.version_lyrics?.verse) !== 'string'){
    //     if(this._track.version_lyrics.verse?.length > 0){
    //       for(let i=0;i<this._track.version_lyrics.verse.length;i++){
    //         this.showVerseField=true
    //         if(this._track.version_lyrics.verse[i] !== '')
    //         this.values.push({value:this._track.version_lyrics.verse[i]});
    //       }
    //     }
    //   }
    //   if(typeof (this._track.version_lyrics.pre_chorus) !== 'string'){
    //     if(this._track.version_lyrics.pre_chorus?.length > 0){
    //       for(let i=0;i<this._track.version_lyrics.pre_chorus.length;i++){
    //         this.showPreChorusField=true
    //         if(this._track.version_lyrics.pre_chorus[i] !== '')
    //         this.preChrousValues.push({value:this._track.version_lyrics.pre_chorus[i]});
    //       }
    //     }
    //   }
    //   if(typeof (this._track.version_lyrics?.chorus) !== 'string'){
    //     if(this._track.version_lyrics.chorus?.length > 0){
    //       for(let i=0;i<this._track.version_lyrics.chorus.length;i++){
    //         this.showChorusField=true
    //         if(this._track.version_lyrics.chorus[i] !== '')
    //         this.chrousValues.push({value:this._track.version_lyrics.chorus[i]});
    //       }
    //     }
    //   }
    //   if(typeof (this._track.version_lyrics?.bridge) !== 'string'){
    //     if(this._track.version_lyrics.bridge?.length > 0){
    //       for(let i=0;i<this._track.version_lyrics.bridge.length;i++){
    //         this.showBridgeField=true
    //         if(this._track.version_lyrics.bridge[i] !== '')
    //         this.bridgeValues.push({value:this._track.version_lyrics.bridge[i]});
    //       }
    //     }
    //   }
    //   }
    //   else{
    //     let dat=''
    //     let lyrics = {
    //       verse:dat,
    //       pre_chorus:dat,
    //       chorus:dat,
    //       bridge:dat
    //     }
    //     this._track['version_lyrics'] = lyrics
    //   }

    // }

    if (this.track.support_version_ins_desc?.length > 0) {
      this.newInsSuggestion = []
      for (let x of this.track.support_version_ins_desc) {
        if (x.name !== '') {
          this.newInsSuggestion.push(x.name)
        }
      }
    }

    // this._subscriptions.push(

    //   this.network1Ctrl.valueChanges.pipe(
    //     startWith(''),
    //     switchMap((v) => {
    //       let iIds = this.track.version_network_references.map(i => i.id);
    //       if (!v || typeof v != 'string') {
    //         return of(this.allnetworkReferences.filter(i => !iIds.includes(i.id)));
    //       }
    //       return of(this.allnetworkReferences.filter(i => i.name.toLowerCase().includes(v.toLowerCase()) && !iIds.includes(i.id)));
    //     })
    //   ).subscribe(i => {
    //     this.filteredNetworks.next(i);
    //   }),

    //   // this.sonicCtrl.valueChanges.pipe(
    //   //   startWith(''),
    //   //   switchMap((v) => {
    //   //     let iIds = this.track.version_sonic_reference.map(i => i.id);
    //   //     if (!v || typeof v != 'string') {
    //   //       return of(this.allSonics.filter(i => !iIds.includes(i.id)));
    //   //     }
    //   //     return of(this.allSonics.filter(i => i.name.toLowerCase().includes(v.toLowerCase()) && !iIds.includes(i.id)));
    //   //   })
    //   // ).subscribe(i => {
    //   //   this.filteredSonics.next(i);
    //   // })
    // )
    window.addEventListener('scroll', this.scrollEvent, true);
    this.setValidation()
  }


  ngAfterViewInit() {
    this.networkInput.nativeElement.addEventListener('focus', () => {
      console.log('Input focused');
    });

    this.networkInput.nativeElement.addEventListener('blur', () => {
      console.log('Input blurred');
    });
  }

  scrollEvent = (event: any): void => {


    if (this.autoComplete1 && this.autoComplete1.panelOpen) {
      this.autoComplete1.updatePosition();
    }
    if (this.autoComplete2 && this.autoComplete2.panelOpen) {
      this.autoComplete2.updatePosition();
    }
    if (this.autoComplete3 && this.autoComplete3.panelOpen) {
      this.autoComplete3.updatePosition();
    }
    if (this.autoComplete4 && this.autoComplete4.panelOpen) {
      this.autoComplete4.updatePosition();
    }
    if (this.autoComplete5 && this.autoComplete5.panelOpen) {
      this.autoComplete5.updatePosition();
    }
    if (this.autoComplete6 && this.autoComplete6.panelOpen) {
      this.autoComplete6.updatePosition();
    }

  };
  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._subscriptions = [];
  }

  updateFilteredInstruments(type: any = '') {
    let iIds = this.track.version_instruments.map(i => i.id);
    this.filteredInstruments.next(this.allInstrumentationSubCategory.filter(i => !iIds.includes(i.id)));
    if (type !== '') {
      if (this.allInstrumentationSubCategory.length == 0) {
        this.track.Instrumentnew_suggestion = [];
        this.track.Instrumentnew_suggestion = this.track.newAddedSuggestion.concat(this.track.support_version_ins_desc);
        this.track.Instrumentdescriptions = [];
        this.inDescription = [];
        this.track.Instrumentnew_suggestion = this.newInsSuggestion;
      }
      if (this.allInstrumentationSubCategory.length !== 0) {
        let dataInst: any = []
        // this.inDescription =[];
        for (let i = 0; i < this.allInstrumentationSubCategory.length; i++) {
          const index1 = this.nameSuggestion.findIndex(ind => ind == this.allInstrumentationSubCategory[i].name);
          if (index1 >= 0) {
            dataInst.push(this.allInstrumentationSubCategory[i].name)
            this.inDescription.push(this.allInstrumentationSubCategory[i].id)
          }
        }
        this.track.Instrumentdescriptions = this.inDescription
        this.track.Instrumentnew_suggestion = [...dataInst, ...this.track.newAddedSuggestion]
        let data = [...this.track.Instrumentnew_suggestion, ...this.newInsSuggestion]
        this.track.Instrumentnew_suggestion = data;
      }
    }
    this.instrumenrtationChange2(this.inDescription, 'add');
  }


  updateFilteredNetworks() {
    let iIds = this.track.version_network_references.map(i => i.id);
    this.filteredNetworks.next(this.allnetworkReferences.filter(i => !iIds.includes(i.id)));
  }

  updateFilteredTVReferences() {
    let iIds = this.track && this.track['version_tv_references'].map(i => i.id);
    this.filteredTV.next(this.tvReferences.filter(i => !iIds.includes(i.id)));
  }

  updateFilteredArtists() {
    let iIds = this.track && this.track['artist_references'].map(i => i.id);
    this.filteredArtists.next(this.artistReferences.filter(i => !iIds.includes(i.id)));
  }

  updateFilteredBands() {
    let iIds = this.track && this.track['version_band_references'].map(i => i.id);
    this.filteredBands.next(this.bandReferences.filter(i => !iIds.includes(i.id)));
  }

  updateFilteredSonics() {
    let iIds = this.track.version_sonic_reference.map(i => i.id);
    this.filteredSonics.next(this.allSonics.filter(i => !iIds.includes(i.id)));
  }

  updateFilteredVocals() {
    let iIds = this.track.version_vocal.map(i => i.id);
    this.filteredVocals.next(this.allVolDescList.filter(i => !iIds.includes(i.id)));
  }

  addInstrument(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      let instrument = this.allInstruments.find(i => i.name.toLowerCase() == value.trim().toLowerCase());
      if (instrument) {
        this.track.version_instruments.push(instrument);
        this.trackChange.emit(this.track);
        this.updateFilteredInstruments();
      }
    }

    if (input) {
      input.value = '';
    }

    this.instrumentCtrl.setValue('');
  }

  removeInstrument(instrument: Instrument): void {
    const index = this.track.version_instruments.findIndex(i => i.id == instrument.id);

    if (index >= 0) {
      this.track.version_instruments.splice(index, 1);
      this.trackChange.emit(this.track);
      this.updateFilteredInstruments();
    }
    this.setValidation()
  }

  selectedInstrument(event: MatAutocompleteSelectedEvent): void {
    this.track.version_instruments.push(event.option.value);
    this.track.Instrumentnew_suggestion = this.track.Instrumentnew_suggestion.filter(function (e) {
      if (e !== event.option.value.name) {
        return e;
      }
    })
    this.track.Instrumentnew_suggestion.push(event.option.value.name)
    this.trackChange.emit(this.track);
    this.updateFilteredInstruments();
    this.instrumentInput.nativeElement.value = '';
    this.instrumentCtrl.setValue('');
  }
  addVocal(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      let vocal = this.allVocal.find(i => i.name.toLowerCase() == value.trim().toLowerCase());
      if (vocal) {
        this.track.version_vocal.push(vocal);
        this.trackChange.emit(this.track);
        this.updateFilteredVocals();
      }
    }

    if (input) {
      input.value = '';
    }

    this.vocalCtrl.setValue('');
  }

  removeVocal(vocal: Instrument): void {
    const index = this.track.version_vocal.findIndex(i => i.id == vocal.id);

    const val = this.track.Vocalsnew_suggestion.findIndex(i => i == vocal);
    var valueDa = []
    for (let x of this.allVolDescList) {
      for (let y of this.track.Vocalsnew_suggestion) {
        if (x.name === y) {
          valueDa.push(x.id)
        }
      }
    }
    this.track.Vocalsdescriptions = valueDa

    if (val >= 0) {
      this.track.Vocalsnew_suggestion.splice(val, 1);
      this.trackChange.emit(this.track);
      this.updateFilteredVocals();
    }
    if (index >= 0) {
      this.track.version_vocal.splice(index, 1);
      this.trackChange.emit(this.track);
      this.updateFilteredVocals();
    }
    this.setValidation()
  }


  selectedVocal(event: MatAutocompleteSelectedEvent): void {
    this.track.version_vocal.push(event.option.value);
    this.trackChange.emit(this.track);
    this.updateFilteredVocals();
    this.vocalInput.nativeElement.value = '';
    this.vocalCtrl.setValue('');
  }

  addNetwork(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      let network = this.allNetworks.find(i => i.name.toLowerCase() == value.trim().toLowerCase());
      if (network) {
        this.track.version_network_references.push(network);
        this.trackChange.emit(this.track);
        this.updateFilteredNetworks();
      }
    }

    if (input) {
      input.value = '';
    }

    this.network1Ctrl.setValue('');
  }

  removeNetwork(network: NetworkReference): void {
    const index = this.track.version_network_references.findIndex(i => i.name == network.name);
    const index2 = this.track['support_network_references'].findIndex(i => i.name == network.name);
    let data = []
    if (index >= 0) {
      this.track.network_ref_rm_sug.push(this.track.version_network_references[index].id)
      this.track.version_network_references.splice(index, 1);
      this.trackChange.emit(this.track);
      this.updateFilteredNetworks();
    }

    if (index2 >= 0) {
      this.track.newNetwork_ref_rm_sug.push(network.id)
      this.track['support_network_references'].splice(index2, 1);
      this.trackChange.emit(this.track);
      this.updateFilteredNetworks();
    }
    // <!-- Add Newsuggestion -->

    if (this.track.network_referencesNew_suggestion) {
      data = this.track.network_referencesNew_suggestion.filter(function (e) {
        if (e !== network.name) {
          return e;
        }
      })
    }
    if (this.track.network_referencesDescriptions) {
      this._track['version_network_desc'] = this._track['version_network_desc'].filter(function (e) {
        if (e.name !== network.name && e.name !== null) {
          return e;
        }
      })

    }
    this.track.network_referencesNew_suggestion = data
    this.track.network_referencesDescriptions = this._track['version_network_desc']
    this.trackChange.emit(this.track);
    if (network)
      this.setValidation()

  }

  selectedNetwork(event: MatAutocompleteSelectedEvent): void {
    this.track.version_network_references.push(event.option.value);
    this.trackChange.emit(this.track);
    this.updateFilteredNetworks();
    this.networkInput.nativeElement.value = '';
    this.network1Ctrl.setValue('');
    this.networkNewCtrl.setValue('');
  }
  // Add TV Reference
  addTV(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      let tv = this.allTV.find(i => i.name.toLowerCase() == value.trim().toLowerCase());
      if (tv) {
        this.track.version_tv_references.push(tv);
        this.trackChange.emit(this.track);
        this.updateFilteredTVReferences();
      }
    }

    if (input) {
      input.value = '';
    }

    this.tvCtrl.setValue('');
  }
  // Remove TV Reference
  removeTV(tv: TvReference): void {
    const index = this.track.version_tv_references.findIndex(i => i.name == tv.name);
    const index2 = this.track['support_tv_references'].findIndex(i => i.name == tv.name);
    const tvIndex = this.track.tv_referencesDescriptions && this.track?.tv_referencesDescriptions.findIndex(i => i.name == tv.name);
    let data = []


    if (index >= 0) {
      this.track.tv_ref_rm_sug.push(this.track.version_tv_references[index].id)

      this.track.version_tv_references.splice(index, 1);
      this.trackChange.emit(this.track);
      this.updateFilteredTVReferences();

    }
    if (index2 >= 0) {
      this.track.newTv_ref_rm_sug.push(tv.id)

      this.track['support_tv_references'].splice(index2, 1);
      this.trackChange.emit(this.track);
      this.updateFilteredTVReferences();


    }

    // <!-- Add Newsuggestion -->

    if (this.track.tv_referencesNew_suggestion) {
      data = this.track.tv_referencesNew_suggestion.filter(function (e) {
        if (e !== tv.name) {
          return e;
        }
      })
    }
    if (this.track.tv_referencesDescriptions) {
      this._track['version_tv_desc'] = this._track['version_tv_desc'].filter(function (e) {
        if (e.name !== tv.name && e.name !== null) {
          return e;
        }
      })
    }
    this.track.tv_referencesNew_suggestion = data
    this.track.tv_referencesDescriptions = this._track['version_tv_desc']
    this.trackChange.emit(this.track);
    this.setValidation()

  }
  // Selected TV Reference
  selectedTV(event: MatAutocompleteSelectedEvent): void {
    this.track.version_tv_references.push(event.option.value);
    this.trackChange.emit(this.track);
    this.updateFilteredTVReferences();
    this.tvInput.nativeElement.value = '';
    this.tvCtrl.setValue('');
    this.tvNewCtrl.setValue('')
  }
  // Add Artist Reference
  addArtist(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      let artist = this.allTV.find(i => i.name.toLowerCase() == value.trim().toLowerCase());
      if (artist) {
        this.track.artist_references.push(artist);
        this.trackChange.emit(this.track);
        this.updateFilteredArtists();
      }
    }

    if (input) {
      input.value = '';
    }

    this.artistCtrl.setValue('');
  }
  // Remove Artist Reference
  removeArtist(artist: Artist): void {
    const index = this.track.artist_references.findIndex(i => i.id == artist.id);

    if (index >= 0) {
      this.track.artist_references.splice(index, 1);
      this.trackChange.emit(this.track);
      this.updateFilteredArtists();
    }
    this.setValidation()

  }
  // Selected Artist Reference
  selectedArtist(event: MatAutocompleteSelectedEvent): void {
    this.track.artist_references.push(event.option.value);
    this.trackChange.emit(this.track);
    this.updateFilteredArtists();
    this.artistInput.nativeElement.value = '';
    this.artistCtrl.setValue('');
  }
  // Add Band Reference
  addBand(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      let band = this.allBands.find(i => i.name.toLowerCase() == value.trim().toLowerCase());
      if (band) {
        this.track.version_band_references.push(band);
        this.trackChange.emit(this.track);
        this.updateFilteredBands();
      }
    }

    if (input) {
      input.value = '';
    }

    this.bandCtrl.setValue('');
  }
  // Remove Band Reference
  removeBand(artist: Artist): void {
    const index = this.track.version_band_references.findIndex(i => i.name == artist.name);
    const index2 = this.track['support_band_references'].findIndex(i => i.name == artist.name);

    if (index >= 0) {
      this.track.vers_rm_sonic_band.push(this.track.version_band_references[index].id)
      this.track.version_band_references.splice(index, 1);
      this.trackChange.emit(this.track);
      this.updateFilteredBands();
    }
    if (index2 >= 0) {
      this.track.bandRemove.push(this.track['support_band_references'][index2].id)
      this.track['support_band_references'].splice(index2, 1);
      this.trackChange.emit(this.track);
      this.updateFilteredBands();
    }
    this.setValidation()
  }
  // Selected Band Reference
  selectedBand(event: MatAutocompleteSelectedEvent): void {
    this.track.version_band_references.push(event.option.value);
    this.trackChange.emit(this.track);
    this.updateFilteredBands();
    this.bandInput.nativeElement.value = '';
    this.bandCtrl.setValue('');
  }

  addSonic(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      let sonic = this.allSonics.find(i => i.name.toLowerCase() == value.trim().toLowerCase());
      if (sonic) {
        this.track.version_sonic_reference.push(sonic);
        this.trackChange.emit(this.track);
        this.updateFilteredSonics();
      }
    }

    if (input) {
      input.value = '';
    }

    this.sonicCtrl.setValue('');
  }

  removeSonic(sonic: SonicReference): void {
    const index = this.track.version_sonic_reference.findIndex(i => i.name == sonic.name);
    const index2 = this.track['support_sonic_references'].findIndex(i => i.name == sonic.name);

    if (index >= 0) {
      this.track.vers_rm_sonic_ref.push(this.track.version_sonic_reference[index].id)
      this.track.version_sonic_reference.splice(index, 1);
      this.trackChange.emit(this.track);
      this.updateFilteredSonics();
    }
    if (index2 >= 0) {
      this.track.sonicRemove.push(this.track['support_sonic_references'][index2].id)
      this.track['support_sonic_references'].splice(index, 1);
      this.trackChange.emit(this.track);
      this.updateFilteredSonics();
    }
    this.setValidation()
  }

  selectedSonic(event: MatAutocompleteSelectedEvent): void {
    this.track.version_sonic_reference.push(event.option.value);
    this.trackChange.emit(this.track);
    this.updateFilteredSonics();
    this.sonicInput.nativeElement.value = '';
    this.sonicCtrl.setValue('');
  }

  onLyricsChangeverse(data, i) {
    console.log(this.values)
    this.values[i].value = data;
    for (let index = 0; index < this.values.length; index++) {
      this.data[index] = this.values[index].value;
    }
    console.log(this.data)
    this.track.version_lyrics.verse = this.data;
    this.trackChange.emit(this.track);
  }
  onLyricsChangepre_chorus(data, i) {
    console.log(this.values)
    this.preChrousValues[i].value = data;
    for (let index = 0; index < this.preChrousValues.length; index++) {
      this.preChrousData[index] = this.preChrousValues[index].value;
    }
    console.log(this.preChrousData)
    this.track.version_lyrics.pre_chorus = this.preChrousData;
    this.trackChange.emit(this.track);
  }
  onLyricsChangechorus(data, i) {
    console.log(this.chrousValues)
    this.chrousValues[i].value = data;
    for (let index = 0; index < this.chrousValues.length; index++) {
      this.chrousData[index] = this.chrousValues[index].value;
    }
    console.log(this.chrousData)
    this.track.version_lyrics.chorus = this.chrousData;
    this.trackChange.emit(this.track);
  }
  onLyricsChangebridge(data, i) {
    this.bridgeValues[i].value = data;
    for (let index = 0; index < this.bridgeValues.length; index++) {
      this.bridgeData[index] = this.bridgeValues[index].value;
    }
    console.log(this.bridgeData)
    this.track.version_lyrics.bridge = this.bridgeData;
    this.trackChange.emit(this.track);
  }

  onVocalsChange(data) {
    this.track.vocals = data;
    this.trackChange.emit(this.track);
  }

  instrumentTrackBy(instrument: Instrument, index) {
    return instrument.id;
  }

  networkTrackBy(network: NetworkReference, index) {
    return network.id;
  }

  sonicTrackBy(sonic: SonicReference, index) {
    return sonic.id;
  }

  vocalTrackBy(vocal: Vocals, index) {
    return vocal.id;
  }

  getInstrumentAddedByClass(instrument: Instrument): string {
    // let it = this.track.instruments.find(i=>i.id == instrument.id);
    // if (it) {
    //   return this.getClassForRole(it.added_by_role);
    // }
    return ''
  }

  getNetworkAddedByClass(network: NetworkReference): string {
    // let it = this.track.networkreferencetracks_set.find(i=>i.networkreference_id == network.id);
    // if (it) {
    //   return this.getClassForRole(it.added_by_role);
    // }
    return ''
  }

  getSonicAddedByClass(sonic: SonicReference): string {
    // let it = this.track.sonicreferencetracks_set.find(i=>i.sonicreference_id == sonic.id);
    // if (it) {
    //   return this.getClassForRole(it.added_by_role);
    // }
    return ''
  }

  getVocalAddedByClass(vocal: Vocals): string {
    // let it = this.track.instruments.find(i=>i.id == vocal.id);
    // if (it) {
    //   return this.getClassForRole(it.added_by_role);
    // }
    return ''
  }

  getClassForRole(role: number) {
    switch (role) {
      case -1:
        return 'all';
      case 0:
        return 'composer';
      case 1:
        return 'approver';
      case 2:
        return 'meta-tagger';
    }
  }
  // allInstrumentationSubCategory: any;
  allSubCategory: any;
  allVocals: any;
  instsub: any;
  vocalstype: any;
  vocalsDesc: any;
  // allVolDescList: any;
  getArtist(e, type: any = '') {

    let requestUrl = environment.apiURL + `/api/v1/track/ins-description-dropdown?ins_ids=` + e
    this.api.doGET(requestUrl).subscribe(x => {
      this.allInstrumentationSubCategory = x;
      if (type == '') {
        this.updateFilteredInstruments('val')
      }
      else {
        this.updateFilteredInstruments()
      }
    })
    this.instrumentCtrl.valueChanges.pipe(
      startWith(''),
      switchMap((v) => {
        let iIds = this.track.version_instruments.map(i => i.id);
        if (!v) {
          return of(this.allInstrumentationSubCategory.filter(i => !iIds.includes(i.id)));
        }
        if (this.allInstrumentationSubCategory && this.allInstrumentationSubCategory?.length > 0) {
          return of(this.allInstrumentationSubCategory.filter(i => i.name.toLowerCase().includes(v) && !iIds.includes(i.id)));
        }
      })
    ).subscribe(i => {
      this.filteredInstruments.next(i);
    })

  }

  setNewInstrument(e, id) {
    if (e.isUserInput) {
      let instrument = this.instrumentData.findIndex(i => i == id);
      if (e.source.selected) {
        if (instrument < 0) {
          this.track.new_ins.push(id)
        }
      }
      else {
        if (instrument >= 0) {
          this.track.rm_ins.push(id)
        }
      }
    }

  }
  getSubCategory() {
    let requestUrl = environment.apiURL + `/api/v1/track/instrument-dropdown/`
    this.api.doGET(requestUrl).subscribe(x => {
      this.allSubCategory = x;
    })
  }
  getAllvocalstype() {
    let requestUrl = environment.apiURL + `/api/v1/track/vocals-dropdown/`
    this.api.doGET(requestUrl).subscribe(x => {
      this.allVocals = x;
    })
  }
  getVocDesc(e) {
    let requestUrl = environment.apiURL + `/api/v1/track/${e}/vocal-description-dropdown/`
    this.api.doGET(requestUrl).subscribe(x => {
      this.allVolDescList = x;
      this.updateFilteredVocals();
    })
    this.vocalCtrl.valueChanges.pipe(
      startWith(''),
      switchMap((v) => {
        let iIds = this.track.version_vocal.map(i => i.id);
        if (!v) {
          return of(this.allVolDescList.filter(i => !iIds.includes(i.id)));
        }
        if (this.allVolDescList && this.allVolDescList?.length > 0) {
          return of(this.allVolDescList.filter(i => i.name.toLowerCase().includes(v) && !iIds.includes(i.id)));
        }
      })
    ).subscribe(i => {
      this.filteredVocals.next(i);
    })
  }
  Changeddesc(e) {

  }
  instrumenrtationChange(e) {
    this.list.instDesc = this.instDesc
    this.list.instSubCategory = this.instsub
    this.track.Vocalsdescriptions = e.value

    var valueDa = []
    for (let x of this.allVolDescList) {
      for (let y of e.value) {
        if (x.id === y) {
          valueDa.push(x.name)
        }
      }
    }
    this.track.Vocalsnew_suggestion = valueDa
  }
  instrumenrtationChange1(e) {
    this.track.Instrumentsub_category = e.value
  }
  instrumenrtationChange2(e, name) {
    if (name === 'add') {
      let data: any = [];
      this.newValue = [];
      for (let index = 0; index < e.length; index++) {
        for (let i = 0; i < this.allInstrumentationSubCategory.length; i++) {
          if (this.allInstrumentationSubCategory[i].id === e[index]) {
            data.push(this.allInstrumentationSubCategory[i].name)
            this.newValue.push(this.allInstrumentationSubCategory[i].id)
          }

        }
      }
      for (let i = 0; i < this.allInstrumentationSubCategory.length; i++) {
        const index1 = this.nameSuggestion.findIndex(ind => ind == this.allInstrumentationSubCategory[i].name);
        if (index1 >= 0) {
          this.nameSuggestion.splice(index1, 1);
        }
      }

      for (let j = 0; j < this.nameSuggestion.length; j++) {
        data.push(this.nameSuggestion[j])
      }

      for (let k = 0; k < this.newAddedSuggestion.length; k++) {
        data.push(this.newAddedSuggestion[k])
      }
      const dataInstrument = data => data.filter((item, index) => data.indexOf(item) === index)
      console.log(dataInstrument(data))
      this.track.Instrumentnew_suggestion = dataInstrument(data);
      this.track.Instrumentdescriptions = (e)
    }
    else {
      this.track.Instrumentdescriptions = e
    }
    this.setValidation()

  }
  vocalsChange(e) {
    this.list.vocalsType = this.vocalstype
    this.list.vocalsdesc = this.vocalsDesc
  }
  setInstrument(e, id, name) {
    if (e.isUserInput) {
      let instrument = this.InstruDesc.findIndex(i => i.id == id);
      if (e.source.selected) {
        if (instrument < 0) {
          this.track.Instrumentnew_desc_sug.push(id)
          this.track.new_ins_decs.push(id)
          this.track.Instrumentnew_suggestion.push(name)
        }
      }
      else {
        if (instrument >= 0) {
          this.track.Instrumentrm_desc_sug.push(id)
          const index1 = this.track.Instrumentnew_suggestion.findIndex(ind => ind == name);
          if (index1 >= 0) {
            this.track.Instrumentnew_suggestion.splice(index1, 1);
          }
        }

      }
    }
  }
  setVocal(e, id) {
    if (e.isUserInput) {

      let instrument = this.VocalDesc.findIndex(i => i.thesaurus_id == id);
      if (e.source.selected) {
        if (instrument < 0) {
          this.track.Vocalnew_desc_sug.push(id)
        }
      }
      else {
        if (instrument >= 0) {
          this.track.Vocalrm_desc_sug.push(id)
        }
      }
    }
  }
  setTv(e, id) {
    if (e.isUserInput) {

      let instrument = this.tvRef.findIndex(i => i.id == id);
      if (e.source.selected) {
        if (instrument < 0) {
          this.track.tv_ref_new_sug.push(id)
        }
      }
      else {
        if (instrument >= 0) {
          this.track.tv_ref_rm_sug.push(id)
        }
      }
    }
  }
  setNetwork(e, id) {
    if (e.isUserInput) {

      let instrument = this.netRef.findIndex(i => i.id == id);
      if (e.source.selected) {
        if (instrument < 0) {
          this.track.network_ref_new_sug.push(id)
        }
      }
      else {
        if (instrument >= 0) {
          this.track.network_ref_rm_sug.push(id)
        }
      }
    }
  }
  setSonic(e, id) {
    if (e.isUserInput) {

      let instrument = this.sonicRef.findIndex(i => i.id == id);
      if (e.source.selected) {
        if (instrument < 0) {
          this.track.vers_new_sonic_ref.push(id)
        }
      }
      else {
        if (instrument >= 0) {
          this.track.vers_rm_sonic_ref.push(id)
        }
      }
    }
  }
  setBand(e, id) {
    if (e.isUserInput) {

      let instrument = this.bandRef.findIndex(i => i.id == id);
      if (e.source.selected) {
        if (instrument < 0) {
          this.track.vers_new_sonic_band.push(id)
        }
      }
      else {
        if (instrument >= 0) {
          this.track.vers_rm_sonic_band.push(id)
        }
      }
    }
  }

  closethisPanel(panel) {
    if (panel == 'instruments') {
      this.instrumentationPanelOpen = false;
      this.instrumentsPanel.close();
      this.instrumentInput.nativeElement.value = '';
      this.instrumentCtrl.setValue('');
    }
    if (panel == 'network') {
      this.tvNetworkPanelOpen = false;
      this.networkPanel.close();
    }
    if (panel == 'sonic') {
      this.sonicPanelOpen = false;
      this.sonicPanel.close();
      this.sonicUpdate = true;
    }
    if (panel == 'lyrics') {
      this.lyricsPanelOpen = false;
      this.lyricsPanel.close();
    }
    if (panel == 'vocals') {
      this.vocalsPanelOpen = false;
      this.vocalsPanel.close();
    }
    this.setValidation()
  }
  clearInstrumentInput() {
    this.instrumentInput.nativeElement.value = '';
    this.instrumentCtrl.setValue('');
  }
  clearTVNetworkRefInput() {
    this.tvInput.nativeElement.value = '';
    this.tvCtrl.setValue('');
    this.networkInput.nativeElement.value = '';
    this.network1Ctrl.setValue('');
    this.tvNewCtrl.setValue('');
    this.networkNewCtrl.setValue('');
  }
  clearSonicRefInput() {
    if (this.artistInput && this.artistInput.nativeElement) {
      this.artistInput.nativeElement.value = ''
    };
    this.artistCtrl?.setValue('');
    if (this.bandInput && this.bandInput.nativeElement) {
      this.bandInput.nativeElement.value = ''
    };
    this.bandCtrl?.setValue('');
  }
  // Add custom instruments
  addCustomInstruments(): void {
    console.log(this.track)
    if (this.instrumentInput.nativeElement.value != undefined && this.instrumentInput.nativeElement.value.trim() != '') {
      if (this.track && this.track?.Instrumentnew_suggestion) {
        this.track.Instrumentnew_suggestion = this.track.Instrumentnew_suggestion
      }
      else {
        this.track['Instrumentnew_suggestion'] = []
      }

      this.track.Instrumentnew_suggestion.push(this.instrumentInput.nativeElement.value);
      this.track?.newAddedSuggestion.push(this.instrumentInput.nativeElement.value)
      let data = this.track.Instrumentnew_suggestion, data1 = this.track?.newAddedSuggestion
      const dataInstrument = data => data.filter((item, index) => data.indexOf(item) === index)
      console.log(dataInstrument(data))
      this.track.Instrumentnew_suggestion = dataInstrument(data)
      const dataInstrument1 = data1 => data1.filter((item, index) => data1.indexOf(item) === index)
      console.log(dataInstrument1(data1))
      this.track.newAddedSuggestion = dataInstrument1(data1)
      this.trackChange.emit(this.track);
      this.instrumentInput.nativeElement.value = '';
      this.instrumentCtrl.setValue('');
    }
  }

  addCustomTv(event: any): void {

    if (event.value.trim() !== '') {
      const index = this.tvReferences.findIndex(i => i.name == event.value);
      const tvIndex = this.track.version_tv_references.findIndex(i => i.name == event.value)
      const sugIndex = this.tvSuggestion.findIndex(i => i == event.value)
      console.log(sugIndex)
      if (sugIndex < 0) {
        if (tvIndex > 0 && index > 0) {
          this.tvNewCtrl.setValue('');
        }
        else {
          if (index < 0) {
            let data = {
              id: '',
              name: event.value
            }
            this.track.version_tv_references.push(data);
            this.tvSuggestion.push(event.value)
            this.track.tv_referencesNew_suggestion = []
            this.track.tv_referencesNew_suggestion = this.tvSuggestion
            this.trackChange.emit(this.track);
            this.tvNewCtrl.setValue('');
          }
          if (tvIndex < 0 && index > 0) {
            if (event.value?.name) {
              this.track.version_tv_references.push(event.value);
              this.tvSuggestion.push(event.value.name)
            }
            else {
              let data = {
                id: '',
                name: event?.value
              }
              this.tvSuggestion.push(event?.value)
              this.track.version_tv_references.push(data);
            }

            this.track.tv_referencesNew_suggestion = []
            this.track.tv_referencesNew_suggestion = this.tvSuggestion
            this.trackChange.emit(this.track);
            this.tvNewCtrl.setValue('');
          }
        }
      }
      else {
        this.tvNewCtrl.setValue('');
      }
    }
  }
  addCustomNetwork(event: any): void {
    if (event.value.trim() !== '') {
      const index = this.allnetworkReferences.findIndex(i => i.name == event.value);
      const indexNetwork = this.track.version_network_references.findIndex(i => i.name == event.value)
      if (indexNetwork > 0 && index > 0) {
        this.networkNewCtrl.setValue('');
      }
      else {
        if (index < 0) {
          let data = {
            id: '',
            name: event.value
          }
          this.track.version_network_references.push(data);
          this.networkSuggestion.push(event.value)
          this.track.network_referencesNew_suggestion = []
          this.track.network_referencesNew_suggestion = this.networkSuggestion
          this.trackChange.emit(this.track);
          this.networkNewCtrl.setValue('');
        }
        if (indexNetwork < 0 && index > 0) {

          if (event.value?.name) {
            this.networkSuggestion.push(event.value.name)
            this.track.version_network_references.push(event.value);
          }
          else {
            let data = {
              id: '',
              name: event?.value
            }
            this.networkSuggestion.push(event?.value)
            this.track.version_network_references.push(data);
          }
          this.track.network_referencesNew_suggestion = []
          this.track.network_referencesNew_suggestion = this.networkSuggestion
          this.trackChange.emit(this.track);
          this.networkNewCtrl.setValue('');
        }
      }

    }
  }

  radioChange(event) {
    this.track.added_by_role = Number(event.value);
    this.trackChange.emit(this.track);
  }
  // Remove custom instruments
  removeCustomInstruments(instrument?: any) {
    let removeInst = []
    const index = this.track.Instrumentnew_suggestion.findIndex(i => i == instrument);
    const index1 = this.track?.newAddedSuggestion?.findIndex(i => i == instrument);
    let valu = this.InstruDesc.findIndex(i => i.name.toLowerCase() == instrument.toLowerCase());

    if (valu >= 0) {
      this.track.Instrumentrm_desc_sug.push(this.InstruDesc[valu].id)
    }
    // for(let va of removeInst){
    //   this.track.Instrumentrm_desc_sug.push(va)
    // }

    // if(index1 >= 0){
    //   this.newAddedSuggestion.splice(index1, 1);
    // }
    // const index2 = this.nameSuggestion.findIndex(i => i == instrument);
    // if(index2 >= 0){
    //   this.nameSuggestion.splice(index2, 1);
    // }
    this.track.version_instruments = this.track.version_instruments.filter(function (e) {
      if (e.name !== instrument) {
        return e;
      }
    })
    this._track['version_instruments_desc'] = this._track['version_instruments_desc'].filter(function (e) {
      if (e.name !== instrument && e.name !== null) {
        return e;
      }
    })


    let data = [];
    for (let x of this._track['version_instruments_desc']) {
      data.push(x.id)
    }

    let dataId = 0
    const indexData = this.allInstrumentationSubCategory.filter(function (e) {
      if (e.name === instrument) {
        dataId = e.id;
        return e.id;
      }
    })
    let valIndex = this.track.Instrumentnew_desc_sug.findIndex(i => i == dataId);
    if (valIndex >= 0) {
      this.track.Instrumentnew_desc_sug.splice(valIndex, 1)
    }
    console.log(indexData);
    for (let val = 0; val < indexData.length; val++) {
      this.newValue = this.newValue.filter(function (e) {
        if (e !== indexData[val].id) {
          return e;
        }
      })
    }
    this.inDescription = this.newValue
    this.instrumenrtationChange2(this.newValue, "remove")
    //  this.track.Instrumentdescriptions = this.newValue
    //   console.log(this.track.Instrumentdescriptions,this.newValue )
    this.track.Instrumentdescriptions = data
    if (index != -1) {
      this.track.Instrumentnew_suggestion.splice(index, 1);
      this.trackChange.emit(this.track);
      console.log(this.track)
    }
    this.setValidation()
  }
  // Get artist references
  getartistReferences() {
    let requestUrl = environment.apiURL + `/api/v1/track/all-artists/`
    this.api.doGET(requestUrl).subscribe(x => {
      this.artistReferences = x;
      this.updateFilteredArtists()
    })
    this.artistCtrl.valueChanges.pipe(
      startWith(''),
      switchMap((v) => {
        let iIds = this.track && this.track['artist_references'].map(i => i.id);
        if (!v || typeof v != 'string') {
          return of(this.artistReferences.filter(i => !iIds.includes(i.id)));
        }
        return of(this.artistReferences.filter(i => i.name.toLowerCase().includes(v.toLowerCase()) && !iIds.includes(i.id)));
      })
    ).subscribe(i => {
      this.filteredArtists.next(i);
    })
  }
  // Get band references
  // getbandReferences(){
  //   let requestUrl = environment.apiURL + `/api/v1/track/all-bands/`
  //   this.api.doGET(requestUrl).subscribe(x => {
  //     this.bandReferences = x;
  //     this.updateFilteredBands();
  //   })
  //   this.bandCtrl.valueChanges.pipe(
  //     startWith(''),
  //     switchMap((v) => {
  //       let iIds = this.track && this.track['version_band_references'].map(i => i.id);
  //       if (!v || typeof v != 'string') {
  //         return of(this.bandReferences.filter(i => !iIds.includes(i.id)));
  //       }
  //       return of(this.bandReferences.filter(i => i.name.toLowerCase().includes(v.toLowerCase()) && !iIds.includes(i.id)));
  //     })
  //   ).subscribe(i => {
  //     this.filteredBands.next(i);
  //   })
  // }

  //   Get context references
  gecontextReferences(event: any = '') {

    let requestUrl
    if (event.trim() !== '') {
      requestUrl = environment.apiURL + `/api/v1/sonicreferences/band-reference-dropdown/?search=` + event
    }
    else {
      requestUrl = environment.apiURL + `/api/v1/sonicreferences/band-reference-dropdown/`
    }
    this.api.doGET(requestUrl).subscribe(x => {
      this.bandReferences = x['results'];
      this.updateFilteredBands();
    })
    this.bandCtrl.valueChanges.pipe(
      startWith(''),
      switchMap((v) => {
        let iIds = this.track && this.track['version_band_references'].map(i => i.id);
        if (!v || typeof v != 'string') {
          return of(this.bandReferences.filter(i => !iIds.includes(i.id)));
        }
        return of(this.bandReferences.filter(i => i.name.toLowerCase().includes(v.toLowerCase()) && !iIds.includes(i.id)));
      })
    ).subscribe(i => {
      this.filteredBands.next(i);
    })
  }


  //Get sonic REferences
  dataSonic: any = []
  getSonicReferences(event: string = '') {
    let requestUrl
    if (event.trim() !== '') {
      requestUrl = environment.apiURL + `/api/v1/sonicreferences/sonic-reference-dropdown/?search=` + event
    }
    else {
      requestUrl = environment.apiURL + `/api/v1/sonicreferences/sonic-reference-dropdown/`
    }
    this.api.doGET(requestUrl).subscribe(x => {
      this.dataSonic = x['results']
      this.allSonics = this.dataSonic;
      this.updateFilteredSonics();
    })
    this.sonicCtrl.valueChanges.pipe(
      startWith(''),
      switchMap((v) => {
        let iIds = this.track && this.track['version_sonic_reference'].map(i => i.id);
        if (!v || typeof v != 'string') {
          return of(this.allSonics.filter(i => !iIds.includes(i.id)));
        }
        return of(this.allSonics.filter(i => i.name.toLowerCase().includes(v.toLowerCase()) && !iIds.includes(i.id)));
      })
    ).subscribe(i => {
      this.filteredSonics.next(i);
    })
  }
  // Get network references
  getnetworkReferences() {
    let requestUrl = environment.apiURL + `/api/v1/track/all-network-ref/`
    this.api.doGET(requestUrl).subscribe(x => {
      this.allnetworkReferences = x;
      this.updateFilteredNetworks();
    })
    this.network1Ctrl.valueChanges.pipe(
      startWith(''),
      switchMap((v) => {
        let iIds = this.track && this.track['version_network_references'].map(i => i.id);
        if (!v || typeof v != 'string') {
          return of(this.allnetworkReferences.filter(i => !iIds.includes(i.id)));
        }
        return of(this.allnetworkReferences.filter(i => i.name.toLowerCase().includes(v.toLowerCase()) && !iIds.includes(i.id)));
      })
    ).subscribe(i => {
      this.filteredNetworks.next(i);
    })
  }
  // Get TV references
  getTVReferences() {
    let requestUrl = environment.apiURL + `/api/v1/track/all-tv-ref/`
    this.api.doGET(requestUrl).subscribe(x => {
      this.tvReferences = x;
      this.updateFilteredTVReferences();
    })
    this.tvCtrl.valueChanges.pipe(
      startWith(''),
      switchMap((v) => {
        let iIds = this.track && this.track['version_tv_references'].map(i => i.id);
        if (!v || typeof v != 'string') {
          return of(this.tvReferences.filter(i => !iIds.includes(i.id)));
        }
        return of(this.tvReferences.filter(i => i.name.toLowerCase().includes(v.toLowerCase()) && !iIds.includes(i.id)));
      })
    ).subscribe(i => {
      this.filteredTV.next(i);
    })
  }
  setValidation() {
    if (this.version?.is_full_version) {
      if (this.track.title && this.track.genre && (this.track.moods[0] || this.track.moods[1] || this.track.moods[2]) && this.version?.tempo && this.version?.track_ending && this.version?.calculated_key) {
        this.track.isValid = false;
      }
      else {
        this.track.isValid = true;
      }
    }
    if (!this.version?.is_full_version) {
      if (this.version?.title && this.version?.track_ending && this.track.title) {
        this.track.isValid = false;
      }
      else {
        this.track.isValid = true;
      }
    }

    if (this.isInstrumental !== false) {
      if (([...this.track.version_network_references, ...this.track['support_network_references']])?.length >= 2 && ([...this.track.version_tv_references, ...this.track['support_tv_references']])?.length >= 2
        && ([...this.track.version_sonic_reference, ...this.track['support_sonic_references']])?.length >= 2 &&
        ([...this.track.version_band_references, ...this.track['support_band_references']])?.length >= 2 &&
        (this.track.Vocalssub_category && (this.track.Vocalsdescriptions && this.track.Vocalsdescriptions?.length > 0))
        && (this.track.version_lyrics.verse != '' || this.track.version_lyrics.pre_chorus != '' || this.track.version_lyrics.chorus != '' || this.track.version_lyrics.bridge != '')
        && ((this.track?.Instrumentsub_category && this.track?.Instrumentsub_category?.length > 0) && (
          (this.track.Instrumentdescriptions
            && this.track.Instrumentdescriptions?.length > 0) ||
          (this.track.Instrumentnew_suggestion && this.track.Instrumentnew_suggestion?.length > 0))
        )) {
        this.track.isValid = false;
      }
      else {
        this.track.isValid = true;
      }
    }
    else {
      if (([...this.track.version_network_references, ...this.track['support_network_references']])?.length >= 2 && ([...this.track.version_tv_references, ...this.track['support_tv_references']])?.length >= 2
        && ([...this.track.version_sonic_reference, ...this.track['support_sonic_references']])?.length >= 2 &&
        ([...this.track.version_band_references, ...this.track['support_band_references']])?.length >= 2 &&
        ((this.track?.Instrumentsub_category && this.track.Instrumentsub_category?.length > 0) && ((this.track.Instrumentdescriptions
          && this.track.Instrumentdescriptions?.length > 0) ||
          (this.track.Instrumentnew_suggestion && this.track.Instrumentnew_suggestion?.length > 0)))) {
        this.track.isValid = false;
      }
      else {
        this.track.isValid = true;
      }
    }
    this.versionChange.emit(this.version);
  }
  remove(i) {
    this.data = [];
    let val1 = this.values[i].value
    const index = this.track.version_lyrics?.verse?.findIndex(i => i == val1);
    if (index >= 0) {
      this.values.splice(i, 1);
      for (let index = 0; index < this.values.length; index++) {
        this.data[index] = this.values[index].value;
      }
      this.track.version_lyrics.verse = this.data;
      this.trackChange.emit(this.track);
    }
  }

  addVerse() {
    this.values.push({ value: "" });
  }
  movedownVerse(current, next) {
    let data = this.values[next];
    let oldDate = this.values[current];
    this.values[current] = data;
    this.values[next] = oldDate
    for (let index = 0; index < this.values.length; index++) {
      this.data[index] = this.values[index].value;
    }
    console.log(this.data)
    this.track.version_lyrics.verse = this.data;
    this.trackChange.emit(this.track);
  }
  moveupVerse(current, next) {
    let data = this.values[next];
    let oldDate = this.values[current];
    this.values[current] = data;
    this.values[next] = oldDate
    for (let index = 0; index < this.values.length; index++) {
      this.data[index] = this.values[index].value;
    }
    console.log(this.data)
    this.track.version_lyrics.verse = this.data;
    this.trackChange.emit(this.track);
  }

  removePreChrous(i) {
    this.preChrousData = []
    this.preChrousValues.splice(i, 1);
    for (let index = 0; index < this.preChrousValues.length; index++) {
      this.preChrousData[index] = this.preChrousValues[index].value;
    }
    console.log(this.preChrousValues, this.preChrousData)
    this.track.version_lyrics.pre_chorus = this.preChrousData;
    this.trackChange.emit(this.track);
  }

  addPreChrous() {
    this.preChrousValues.push({ value: "" });
  }
  movedownPreChrous(current, next) {
    let data = this.preChrousValues[next];
    let oldDate = this.preChrousValues[current];
    this.preChrousValues[current] = data;
    this.preChrousValues[next] = oldDate
    for (let index = 0; index < this.preChrousValues.length; index++) {
      this.preChrousData[index] = this.values[index].value;
    }
    console.log(this.preChrousData)
    this.track.version_lyrics.pre_chorus = this.preChrousData;
    this.trackChange.emit(this.track);
  }
  moveupPreChrous(current, next) {
    let data = this.preChrousValues[next];
    let oldDate = this.preChrousValues[current];
    this.preChrousValues[current] = data;
    this.preChrousValues[next] = oldDate
    for (let index = 0; index < this.preChrousValues.length; index++) {
      this.preChrousData[index] = this.preChrousValues[index].value;
    }
    console.log(this.preChrousData)
    this.track.version_lyrics.pre_chorus = this.preChrousData;
    this.trackChange.emit(this.track);
  }

  removeChrous(i) {
    this.chrousData = []
    this.chrousValues.splice(i, 1);
    for (let index = 0; index < this.chrousValues.length; index++) {
      this.chrousData[index] = this.chrousValues[index].value;
    }
    console.log(this.chrousValues, this.chrousData)
    this.track.version_lyrics.chorus = this.chrousData;
    this.trackChange.emit(this.track);
  }

  addChrous() {
    this.chrousValues.push({ value: "" });
  }
  movedownChrous(current, next) {
    let data = this.chrousValues[next];
    let oldDate = this.chrousValues[current];
    this.chrousValues[current] = data;
    this.chrousValues[next] = oldDate
    for (let index = 0; index < this.chrousValues.length; index++) {
      this.chrousData[index] = this.chrousValues[index].value;
    }
    console.log(this.chrousData)
    this.track.version_lyrics.chorus = this.chrousData;
    this.trackChange.emit(this.track);
  }
  moveupChrous(current, next) {
    let data = this.chrousValues[next];
    let oldDate = this.chrousValues[current];
    this.chrousValues[current] = data;
    this.chrousValues[next] = oldDate
    for (let index = 0; index < this.chrousValues.length; index++) {
      this.chrousData[index] = this.chrousValues[index].value;
    }
    console.log(this.chrousData)
    this.track.version_lyrics.chorus = this.chrousData;
    this.trackChange.emit(this.track);
  }

  removeBridge(i) {
    this.bridgeData = []
    this.bridgeValues.splice(i, 1);
    for (let index = 0; index < this.bridgeValues.length; index++) {
      this.bridgeData[index] = this.bridgeValues[index].value;
    }
    console.log(this.bridgeValues, this.bridgeData)
    this.track.version_lyrics.bridge = this.bridgeData;
    this.trackChange.emit(this.track);
  }

  addBridge() {
    this.bridgeValues.push({ value: "" });
  }
  movedownBridge(current, next) {
    let data = this.bridgeValues[next];
    let oldDate = this.bridgeValues[current];
    this.bridgeValues[current] = data;
    this.bridgeValues[next] = oldDate
    for (let index = 0; index < this.bridgeValues.length; index++) {
      this.bridgeData[index] = this.bridgeValues[index].value;
    }
    console.log(this.bridgeData)
    this.track.version_lyrics.bridge = this.bridgeData;
    this.trackChange.emit(this.track);
  }
  moveupBridge(current, next) {
    let data = this.bridgeValues[next];
    let oldDate = this.bridgeValues[current];
    this.bridgeValues[current] = data;
    this.bridgeValues[next] = oldDate
    for (let index = 0; index < this.bridgeValues.length; index++) {
      this.bridgeData[index] = this.bridgeValues[index].value;
    }
    console.log(this.bridgeData)
    this.track.version_lyrics.bridge = this.bridgeData;
    this.trackChange.emit(this.track);
  }

  networkRef(track) {
    let data = [...track.version_tv_references, ...track['support_tv_references']]
    return data
  }
  getTvData(track) {
    let data = [...track.version_network_references, ...track['support_network_references']]
    return data
  }
  sonicSugg: string = ''
  clearsonic() {
    this.sonicSugg = ''
  }
  clearBand() {
    this.bandSugg = ''
  }
  getSonic(track) {
    let data = [...track.version_sonic_reference, ...track['support_sonic_references']]
    return data
  }
  getSonicBand(track) {
    let data = [...track.version_band_references, ...track['support_band_references']]
    return data
  }
  addsonicSugg() {

    if (this.sonicSugg == '') {
      return
    }
    for (let sonic of this.getSonic(this.track)) {
      if (sonic.name === this.sonicSugg) {
        return
      }
    }
    let data = {
      id: '',
      name: this.sonicSugg
    }
    this.track.version_sonic_reference.push(data);
    this.newSonic.push(data)
    this.track.sonic_referencesNew = this.newSonic
    this.trackChange.emit(this.track);
    this.clearsonic()
  }
  bandSugg: string = ''
  addBandSugg() {

    if (this.bandSugg == '') {
      return
    }
    for (let band of this.getSonic(this.track)) {
      if (band.name === this.bandSugg) {
        return
      }
    }
    let data = {
      id: '',
      name: this.bandSugg
    }
    this.track.version_band_references.push(data);
    this.newband.push(data)
    this.track.version_band_referencesNew = this.newband
    this.trackChange.emit(this.track);
    this.clearBand()
  }

  networkRefClick() {
    if (!this.autoComplete1.panelOpen) {
      this.autoComplete1.openPanel()
    }
  }
  programRefClick() {
    if (!this.autoComplete4.panelOpen) {
      this.autoComplete4.openPanel()
    }
  }

  sonicRefClick() {
    if (!this.autoComplete2.panelOpen) {
      this.autoComplete2.openPanel()
    }
  }
  contextRefClick() {
    if (!this.autoComplete6.panelOpen) {
      this.autoComplete6.openPanel()
    }
  }
}
