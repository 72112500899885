<div class="edit-track">

  <app-track-preview
    [track]="track"
    [version]="version"
    [ngClass]="{hide: !track || !version || replaceTrackVersion}"
    (collapse)="toggleMusicPlayerCollapsed()"
    class="music-player"
  ></app-track-preview>

  <app-track-details-for-version
    [(track)]="track"
    [(version)]="version"
    *ngIf="track && version"
  ></app-track-details-for-version>

  <app-manage-cowriters [(composers)]="composers" [ngClass]="{hide: replaceTrackVersion}"></app-manage-cowriters>

  <app-track-status [ngClass]="{hide: replaceTrackVersion}"
    [(version)]="version"
    *ngIf="version" [(track)]="track"
  ></app-track-status>

   <div class="separator-line"></div>

  <!-- <app-track-description
    [track]="track"
    (onValueChanged)="toggleTrackDescriptionCollapsed($event)"
  ></app-track-description>  -->
  <app-track-description
  [(track)]="track"  [(version)]="version"></app-track-description>

  <div *ngIf="!replaceTrackVersion">
    <button class="save-version-btn" mat-button  [disabled]="track.isValid  === true"
    (click)="saveVersion()">Save Track Version</button>
  </div>
 <div *ngIf="replaceTrackVersion" >
  <button class="save-version-btn"   [disabled]="uploadFile === false || track.isValid  === true"  mat-button (click)="replaceTrack()">Replace Track Version</button>
 </div>

</div>
<div class="loading-overlay" *ngIf="loading">
  <app-loader></app-loader>
</div>