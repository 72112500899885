import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { WaveService } from 'angular-wavesurfer-service';
import { Subscription } from 'rxjs';
import { TrackVersion } from '../models/trackversion.model';
import { PlayerService, PlayingItem } from '../services/player.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-waveform-wave',
  templateUrl: './waveform-wave.component.html',
  styleUrls: ['./waveform-wave.component.scss'],
  providers: [WaveService]
})

export class WaveformWaveComponent implements OnInit, OnDestroy {
  @Input()
  set version(v: TrackVersion) {
    this._version = v;
    this.url = this._version.mp3_file
    if (!v) { return; }
  }
  get version(): TrackVersion {
    return this._version;
  }

  @Input()
  trackProgress: number;
  // @Input()
  url: string;
  nexturl: string;
  @Input()
  wave: WaveSurfer = null;
  isPlaying = false;
  loadingTracks= true;
  get previousItem(): PlayingItem {
    return this._playerService.previousItem;
  }
  get nextItem(): PlayingItem {
    return this._playerService.nextItem;
  }

  set isViewType(p: boolean) {
    this.preview = p;
  }
  get isViewType(): boolean {
    return this.preview;
  }

  playPause = false

  @Output()
  trackProgressChange = new EventEmitter();
  private _version: TrackVersion;

  @Output() waveCurrent = new EventEmitter();
  @Output() waveDuration = new EventEmitter();


  current = 0;
  end = 0;
  //wave: WaveSurfer = null;
  private _subscriptions: Subscription[] = [];
  preview = false;
  collapse = true;
  oldUrl : string;
  wavePlay = false;
  nextTab = false;

  constructor(private cdr: ChangeDetectorRef, public _playerService: PlayerService, public waveService: WaveService, public http: HttpClient) { }
  ngOnInit() {
    if (this.wave === null) {
      this.wave = this.waveService.create({
        container: '#waveform',
        height: 50,
        hideScrollbar: true,
        barGap: 3,
        barWidth: 1,
        waveColor: 'white',
        progressColor: 'black',
        backend: 'MediaElement',
        responsive: true,
      });
    }

    this._subscriptions.push(
      this._playerService.isViewType.subscribe((p) => {
        this.preview = p;
      })
    );
    
    this._subscriptions.push(
      this._playerService.isNextTab.subscribe((p) => {
        this.nextTab = p;
      })
    );

    this._subscriptions.push(
      this._playerService.isPlaying.subscribe((p) => {
      
        this._subscriptions.push(
          this._playerService.isCollapse.subscribe((play) => {
            this._playerService.isWavePlay.subscribe((p) => {
              this.wavePlay = p;
              this.isPlaying =   this.wavePlay
              console.log(this.wavePlay)
            })
            this.collapse = play;
          })
        )

       
       if(this.nextTab === true) {
          this._playerService.currentPlaylistItems.isPlay=false;
          this._playerService.currentPlaylistItems.isPause=true; 
          this.isPlaying = false;
           this.wave.stop();
           this.cdr.detectChanges();
         // this.pause();
        }
        // if (p === false && this.nextTab === true) {
        //   this._playerService.currentPlaylistItems.isPlay=false;
        //   this._playerService.currentPlaylistItems.isPause=true; 
        //   this.isPlaying = false;
        //    this.wave.stop();
        //  // this.pause();
        // }
        else if (p === false && this.nextTab === false) {
          this._playerService.currentPlaylistItems.isPlay=false;
          this._playerService.currentPlaylistItems.isPause=true; 
          this.isPlaying = false;
           this.wave.playPause();
           this.cdr.detectChanges();
         // this.pause();
        }
        // if (p === false && this.preview === false && this.collapse === true) {
        //   this.wave.stop()
         
        // }
        // else if (p === false && this.preview === true && this.collapse === false) {
        //   this.wave.stop()
        // }
        // else if (this.preview === false && this.collapse === false) {
        //   this.wave.stop()
        // }
        // else if (this.wavePlay=== false && this.preview === false && this.collapse === false) {
        //   this.wave.stop()
        // }
        // else if (this.wavePlay=== true && this.preview === false && this.collapse === false) {
        //   this.wave.play()
        // }
        else {
           if(this.oldUrl === this.url){
             if(this.wavePlay=== false){
            this.wave.stop()
            this.isPlaying = false;
            this.cdr.detectChanges();
             }
             else{
              this.wave.play()
              this.isPlaying = true;
              this.cdr.detectChanges();
             }
          }
          else if (this.preview === false && this.oldUrl !== this.url) {
            this.generateWaveform();
            this.cdr.detectChanges();

            Promise.resolve().then(() => {
              let url:any = `${environment.apiURL}/api/v1/trackversions/get-version-waveform/?version_id=${this.version.id}`
      this.http.get(url).subscribe((y:any)=>{
        let hostUrl = environment.awsHost + y.mp3_file
        let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
       if(isSafari){
      let  result = hostUrl.replace(/.mp4/g, ".mp3");
        y['waveform_file_data'] ? this.wave.load(result,y.waveform_file_data) : this.wave.load(y.mp3_file);
       }
       else{
        y['waveform_file_data'] ? this.wave.load(hostUrl,y.waveform_file_data) : this.wave.load(y.mp3_file);
       }
      })
            });
            this.wave.on('finish', () => {
              if (this.preview === false) {
                if (this.wave.isPlaying() === false) {
                  // this.wave === null
                  this.isPlaying = false;
                 // this.wave.stop()
                  this.oldUrl = this.url;
                  this._playerService.next();
                  this.cdr.detectChanges();
                }
              }
              else {
                this.wave.stop()
                this.isPlaying = false;
                this.cdr.detectChanges();
              }
            });
          }
          else if (p === true && this.collapse === true) {
            this.wave.playPause()
            this.isPlaying = true;
            this.cdr.detectChanges();
            
          }
          // else if (p === false && this.collapse === true) {
          //   this.wave.stop()
          //   this.isPlaying = false;
          //   this.cdr.detectChanges();
          // }
        }
      })
    )

  }
  generateWaveform(): void {
    Promise.resolve(null).then(() => {
      this.oldUrl = this.url;
      this.wave.on('ready', () => {
        this.isPlaying = true;
        if (this.preview === false) {
          this.wave.play();
          this._playerService.updateWavePlay(true);
          this.loadingTracks = false
          this.isPlaying = true;
          this.cdr.detectChanges();
        }
        // this.wave.setMute(true)
        this.end = this.wave.getDuration();
        this.waveDuration.next(this.wave.getDuration())
        this.isPlaying = true;
        this.cdr.detectChanges();
        this._playerService.updateWavePlay(true);
        this.wave.isPlaying()
      });
      if (this.preview === false) {
        this.wave.on('audioprocess', () => {
          this.waveCurrent.next(this.wave.getCurrentTime());
          this.current = this.wave.getCurrentTime();
          this.cdr.detectChanges();
        });
          this.wave.on('finish', () => {
            if (this.preview === false) {
              if (this.wave.isPlaying() === false) {
                //this.wave = null 
                this.waveCurrent.next(0);
                this.waveDuration.next(0)
                 this.oldUrl = this.url;
                this.isPlaying = false;
                this.cdr.detectChanges();
                //this.wave.stop()
                this._playerService.updateWavePlay(false);
                this._playerService.next();
              }
            }
            else {
              this.wave.stop()
              this.isPlaying = false;
              this.cdr.detectChanges();
              // this._playerService.updateIsPlaying(false);
            }
          });
       
      }
      else {
        this.wave.on('audioprocess', () => {
          this.waveCurrent.next(this.wave.getCurrentTime());
          this.wave.on('finish', () => {
            this.wave.stop()
            this.isPlaying = false;
            this.cdr.detectChanges();

          })
        })
      }

    });
  }
  ngOnDestroy() {
    this._subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }
  getcurrentTime() {
    setTimeout(()=>{  
      this.waveCurrent.next(this.wave.getCurrentTime());  
    }, 100);
  }

  play() {
    this.isPlaying = true;
    this.wave.play();
     this._playerService.updateWavePlay(true)
     this._playerService.updateIsPlaying(true);

    this._playerService.currentPlaylistItems.isPlay=true;
    this._playerService.currentPlaylistItems.isPause=false;
    // this.isPlaying = true;
    // this.wave.play();
  }
  pause() {
    this.isPlaying = false;
   // this.wave.playPause();
    this._playerService.updateWavePlay(false);
    this._playerService.updateIsPlaying(false);
    this._playerService.nextTab(false);
    this._playerService.currentPlaylistItems.isPlay=false;
    this._playerService.currentPlaylistItems.isPause=true; 
    // this.isPlaying = false;
    // this.wave.playPause();

  }

  skipForward() {
    if (!this.nextItem) { return; }
    this.wave.stop();
    this.waveCurrent.next(0);
    this.waveDuration.next(0)
    this._playerService.next();
 
  }
  skipBack() {
    if (this.current > 2) {
      return;
    }
    if (!this.previousItem) { return; }
    this.wave.stop();
    this.waveCurrent.next(0);
    this.waveDuration.next(0)
    this._playerService.previous();
  }
}
