<div class="dialog-container green-theme">
    <div class="dialog-header">
      <h4 class="dialog-heading">Add To Playlist</h4>
      <button class="close-button" type="button" (click)="close()">
        <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
            <g class="inner-stroke" id="Genre" transform="translate(-1438.000000, -32.000000)" stroke="#FFFFFF">
              <g id="Genre-Options">
                <g id="Close" transform="translate(1439.000000, 32.000000)">
                  <path id="Path" d="M24,0 L0,24"></path>
                  <path id="Path" d="M0,0 L24,24"></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </button>
    </div>
  
    <mat-dialog-content>
    
     
      <!-- NEW UI FOR ADD TO PLAYLIST -->
      <div class="dialog-body playlist-container">
        <app-track-preview
        [track]="data.track"
        [version]="data.version"
        [ngClass]="{hide: !data.track || !data.version}"
        class="music-player"
      ></app-track-preview>
      <div class="playlist-body">
        <h4>playlists</h4>
        <p>Select a playlist(s) to add tracks</p>
        <div class="playbox-container">
          
        <div class="grid-card">
            <div class="grid-item">
                <mat-form-field class="trackend-group" appearance="fill"   floatLabel="always">
                    <mat-label>Available Playlists</mat-label>
                    <mat-select class="custom-backdrop-class" name="playlistFilter" panelClass="filter-select filter-rejection-playlist" [disableOptionCentering]="true"  placeholder="All" [disableOptionCentering]="true" [(ngModel)]="playlistcheckbox" #playlistSelect multiple>
                      <!-- <span class="select-title">Select</span> -->
                      <mat-option>
                        <ngx-mat-select-search [formControl]="tempoFilterCtrl" placeholderLabel="Search a playlist" (keyup)="$event.preventDefault();$event.stopPropagation();
                        getPlaylist($event.target.value)">
                          <svg ngxMatSelectSearchClear class="tempo-filter-clear-icon filter-clear-icon" width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                              <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                                  <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                                      <g id="Genre-Options">
                                          <g id="Close" transform="translate(1439.000000, 32.000000)">
                                              <path d="M24,0 L0,24" id="Path"></path>
                                              <path d="M0,0 L24,24" id="Path"></path>
                                          </g>
                                      </g>
                                  </g>
                              </g>
                          </svg>
                        </ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let option of playlistList" #allSelected   (onSelectionChange)="playlistAllSelection($event, option.id,option.name)"  [value]="option.id" >
                         
                        {{ option.name }}
                      </mat-option>
                      <mat-option style="text-align: center; background-color: #50E3C2;" id="loadMore" (click)="loadMore()" >Load More</mat-option>
                    </mat-select>
                  </mat-form-field>
                  
            </div>
        </div>
        <div class="grid-card">
          <div class="grid-item">
             
              <mat-form-field  appearance="fill"  class="trackend-group">
                  <input matInput placeholder="New playlist name" [formControl]="playlistCtrl">
                  <span class="close-btn1" (click)="clearPlaylistInput()" style="float: right;">&times;<strong style="padding-left: 28px;cursor: pointer;text-decoration: underline;" (click)="addCustomPlaylist()">ADD</strong></span>
                  <p *ngIf="duplicate" style="color: red; font-size: small; margin-left: -10px; margin-top: 4%; position: absolute;">Playlist with this name already exists</p>
                </mat-form-field>
                <!-- <mat-form-field  appearance="fill" class="trackend-group">
                  <input placeholder="New playlist name" [formControl]="playlistCtrl" >
                <span class="add-data"><span (click)="clearPlaylistInput()"><strong style="right: 49px;
                    position: absolute;">&times;</strong></span><span (click)="addCustomPlaylist()">ADD</span></span>
                  </mat-form-field> -->
             
          </div>
      </div> 
        <div>
            <mat-chip-list #chipList="">
                <mat-chip [ngClass]="{disabledElement :(custom.isNew === true), disabledElement1 : (custom.newPlay === true)}" style="margin: 13px 12px 7px 0;" *ngFor="let custom of playlistsContainingTrack;" [selectable]="false" [removable]="true" (removed)="removeCustom(custom.value)">
                  <span *ngIf="custom">{{custom.value}}</span>
                  <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
                </mat-chip>
              </mat-chip-list>
        </div>
       <!-- 
      <div class="tagnames">
        <div class="tagnames-data">
            <p>Tag name 2 goes here <span class="close-btn2">&times;</span></p>
        </div>
        <div class="tagnames-data">
            <p>Tag name 2 <span class="close-btn2">&times;</span></p>
        </div>
    </div> -->
        </div>
       
        <h4>Add Versions</h4>
        <p *ngIf="versionList.length > 0" >Select all track versions you’d like to add to the playlist</p>
            <ul class="list" *ngIf="versionList.length > 0">
                <div class="playbox-container add-versionbox">
                 <div class="sub-checkbox meta-data" style="position: relative;">
                  <mat-checkbox (change)="trackChecked($event.checked, versionList ,'All')" [(ngModel)]="allVersion">ADD ALL VERSIONS</mat-checkbox>
                    <!-- <mat-checkbox *ngIf="topTrackValue === 0" [ngClass]="{'disabled-btn': ( topTrackValue == 0 )}" [(ngModel)]="topTrackList" style="position: absolute;left:46%;">
                      <span >Set as <strong>Top Track</strong></span>
                    </mat-checkbox>
                    <mat-checkbox *ngIf="topTrackValue !== 0" [ngClass]="{'disabled-btn': ( topTrackValue !== 0 )}" [(ngModel)]="!topTrackList" style="position: absolute;left:46%;">
                      <span >Set as <strong>Top Track</strong></span>
                    </mat-checkbox> -->
                </div> 
                </div>
                  <div *ngFor="let playlist of versionList">
                    <div class="playbox-container add-versionbox">
                        
                    <div class="container-check">
                      <div style="width:70%;">
                        <mat-checkbox  [(ngModel)]="playlist.checked" (change)="trackChecked($event.checked, playlist,'')">
                          <span >{{playlist.title}}</span>
                        </mat-checkbox>
                      </div>
                   <!-- <div>
                    <mat-checkbox *ngIf="!onetrack" [ngClass]="{'disabled-btn': ( enableTop == true && playlist.topTrack === false )}"  [(ngModel)]="playlist.topTrack" (change)="topTrackChecked($event.checked, playlist)">
                      <span ><strong>Top Track</strong></span> 
                    </mat-checkbox>

                    <mat-checkbox *ngIf="onetrack" [ngClass]="{'disabled-btn': ( enableTop == true && playlist.topTrack === true )}"  [(ngModel)]="playlist.topTrack" (change)="topTrackChecked($event.checked, playlist)">
                      <span ><strong>Top Track</strong></span> 
                    </mat-checkbox>
                   </div> -->
                      <div>
                        <span class="status-bullet" *ngIf="playlist.status == 'PENDING'" style="background-color: #E3B950;margin-right: 8px;" [matTooltip]="pendingBtn" matTooltipPosition="left" matTooltipClass="tooltip-white"></span>
                        <span class="status-bullet" *ngIf="playlist.status == 'PENDING_APPROVAL'" style="background-color: #47CDEF; margin-right: 8px;" [matTooltip]="pendingApproveBtn" matTooltipPosition="left" matTooltipClass="tooltip-white"></span>
                        <span class="status-bullet" *ngIf="playlist.status == 'APPROVED'" style="background-color: #50E3C2;margin-right: 8px;" [matTooltip]="approveBtn" matTooltipPosition="left" matTooltipClass="tooltip-white"></span>
                        <span class="status-bullet" *ngIf="playlist.status == 'DENIED'" style="background-color: #D73939;margin-right: 8px;" [matTooltip]="denieBtn" matTooltipPosition="left" matTooltipClass="tooltip-white"></span>
                        <span class="status-bullet" *ngIf="playlist.status == 'DISABLE'" style="background-color: #8A8A8D;margin-right: 8px;" [matTooltip]="disableBtn" matTooltipPosition="left" matTooltipClass="tooltip-white"></span>
                        <span *ngIf="playlist.status == 'PENDING'">Pending</span>
                        <span *ngIf="playlist.status == 'PENDING_APPROVAL'">Pending Approval</span>
                        <span *ngIf="playlist.status == 'APPROVED'">APPROVED</span>
                        <span *ngIf="playlist.status == 'DENIED'">Denied</span>
                        <span *ngIf="playlist.status == 'DISABLE'">Disable</span>
                      </div>
                    </div>
                  </div>
                </div>
             
               
        
        </ul>
        <p class="version-list" *ngIf="versionList.length === 0">
          There are no approved versions to be added to the playlist.
        </p>
       
      </div>
      <button class="btn save-btn1" *ngIf="(removePlaylistList.length !== 0 &&  activePlaylistsIds.length === 0 )" [disabled]="(playlistsContainingTrack.length === 0 )"  (click)="save()"  mat-button>Save to playlist</button>
      <button class="btn save-btn1" *ngIf="removePlaylistList.length !== 0 && activePlaylistsIds.length !== 0" (click)="save()" [disabled]="(((playlistsContainingTrack.length === 0  ||  activePlaylistsIds.length === 0 ) || trackCheckBox.length === 0 ) )" mat-button>Save to playlist</button>

      <button class="btn save-btn1" *ngIf="removePlaylistList.length === 0" (click)="save()" [disabled]="(((playlistsContainingTrack.length === 0  || enableButton == false ))  || trackCheckBox.length === 0  )" mat-button>Save to playlist</button>
    </div>
    </mat-dialog-content>
    <!-- <button class="btn save-btn1" (click)="save()" [disabled]="playlistcheckbox.length === 0 || trackCheckBox.length === 0" mat-button>Save to playlist</button> -->
  </div>
  