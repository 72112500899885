import { Component, OnInit } from '@angular/core';
import { MenuListService } from '../../shared/services/menu-list.service';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { MenuConfigService } from '../../shared/services/menu-config.service';
// import { environment } from '../../../environments/environment.prod';
import { environment } from '../../../environments/environment';
import { MatDialogRef } from '@angular/material/dialog';
import { RemoveGenreDialogueComponent } from '../remove-genre-dialogue/remove-genre-dialogue.component';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { UserUploadPopupComponent } from 'src/app/shared/user-upload-popup/user-upload-popup.component';

@Component({
  selector: 'app-thesaurus-delete-dialogue',
  templateUrl: './thesaurus-delete-dialogue.component.html',
  styleUrls: ['./thesaurus-delete-dialogue.component.scss']
})
export class ThesaurusDeleteDialogueComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ThesaurusDeleteDialogueComponent>,
    public list: MenuListService,
    public api: UserMenuService,
    public config: MenuConfigService,
    public _DialogService:DialogService
  ) { }

  ngOnInit(): void {
  }
  close() {
    this.dialogRef.close();
  }
  remove(){
      let requestUrl = environment.apiURL + this.config.doThesaurus + this.list.currThesaurus.id
      this.api.doDelete(requestUrl).subscribe(x=>{
        this.close()
        this.list.getAllthesaurus();
      },
      error =>{
        // alert(error.message)
        // alert(`This Thesaurus is associated with a track(s), hence can't be deleted.`)
        let data = error.error?.error ? error.error?.error : (error.error?.detail ?error.error?.detail : (error.error?.details ? error.error?.details:`This Thesaurus is associated with a track(s), hence can't be deleted.`) )
        this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed=>{
        });
        this.close()
      }
    )
  }
}
