import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Track } from '../models/track.model';
import { TrackVersion } from '../models/trackversion.model';
import { Waveform } from '../models/waveform.model';
import { APIListResponse } from '../models/apiresponse.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TrackService } from './track.service';

export interface GetTrackVersionsOptions {
  track?: number;
  ownedBy?: number;
  offset?: number;
  limit?: number;
  activity_type?:string;
}

@Injectable({
  providedIn: 'root'
})
export class TrackVersionService {

  constructor(private http: HttpClient, public trackService: TrackService) { }

  getTrackVersions(track: Track): Observable<TrackVersion[]> {
    let headers = new HttpHeaders();
    return this.http.get<APIListResponse>(
      environment.apiURL + '/api/v1/admin/trackversions/',
      {
        headers: headers,
        params: {
          'track': `${track.id}`
        }
      }
    ).pipe(map((res) => res.results.map((s) => new TrackVersion(s))));
  }

  getAllTrackVersions(options:GetTrackVersionsOptions): Observable<APIListResponse<TrackVersion>> {
    let p = new HttpParams({fromObject:this._getTrackVersionsOptionsToParams(options)});
    let endpoint = `/api/v1/admin/trackversions/`;
    let headers = new HttpHeaders();
    return this.http.get<APIListResponse>(
      environment.apiURL + `${endpoint}`,
      {
        headers: headers,
        params: p
      }
    ).pipe(map((res) => {
      return {
        count: res.count,
        next: res.next,
        previous: res.previous,
        results: res.results.map((s) => new TrackVersion(s))
      }
    }));
  }

  updateTrackVersion(version: any, file: File=null): Observable<TrackVersion> {
    // let formData = this._tvToFormData(version, file);
    let headers = new HttpHeaders();
    return this.http.patch<any>(
      environment.apiURL + '/api/v1/trackversion/' + version.id + '/' ,
      version,
      {
        headers: headers
      }
    ).pipe(map((res) => {
      return new TrackVersion(res)
    }));
  }
  updateReplaceTrackVersion(version: any, file: File=null): Observable<TrackVersion> {
    // let formData = this._tvToFormData(version, file);
    let headers = new HttpHeaders();
    return this.http.put<any>(
      environment.apiURL + '/api/v1/trackversion/' + version.versionId + '/replace-track-version/' ,
      version,
      {
        headers: headers
      }
    ).pipe(map((res) => {
      return new TrackVersion(res)
    }));
  }

  deleteTrackVersion(version: TrackVersion): Observable<void> {
    let headers = new HttpHeaders();
    return this.http.delete<any>(
      environment.apiURL + `/api/v1/admin/trackversions/${version.id}/`,
      {
        headers: headers
      }
    );
  }

  createTrackVersion(trackId: number, version: TrackVersion, file: File=null): Observable<TrackVersion> {
    let formData = this._tvToFormData(version, file);
    formData.append('track_id', trackId.toString());
    let headers = new HttpHeaders();
    return this.http.post<Object>(
      environment.apiURL + '/api/v1/admin/trackversions/',
      formData,
      {
        headers: headers
      }
    ).pipe(map(res=>new TrackVersion(res)));
  }

  getVersionWaveformData(v: TrackVersion): Observable<Waveform> {
    let headers = new HttpHeaders();
    return this.http.get<Object>(
      v.wav_file,
      {
        headers: headers
      }
    ).pipe(map((res) => new Waveform(res)));
  }

  favoriteTrackVersion(version: TrackVersion): Observable<TrackVersion> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<Object>(
      environment.apiURL + '/api/v1/admin/trackversions/' + version.id + '/favorite/',
      {},
      {
        headers: headers
      }
    ).pipe(map((res) => {
      version.loadWithJSON(res);
      return version;
    }));
  }

  unfavoriteTrackVersion(version: TrackVersion): Observable<TrackVersion> {
    let headers = new HttpHeaders();
    return this.http.delete<Object>(
      environment.apiURL + '/api/v1/admin/trackversions/' + version.id + '/unfavorite/',
      {
        headers: headers
      }
    ).pipe(map((res) => {
      version.loadWithJSON(res);
      return version;
    }));
  }

  downloadTrackVersion(version: TrackVersion): Observable<{download_url:string}> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.get<{download_url:string}>(
      environment.apiURL + '/api/v1/admin/trackversions/' + version.id + '/download/',
      {
        headers: headers
      }
    ).pipe(map((res) => {
      return res;
    }));
  }

  purchaseTrackVersion(version: TrackVersion): Observable<{download_url:string}> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<{download_url:string}>(
      environment.apiURL + '/api/v1/admin/trackversions/' + version.id + '/purchase/',
      {
        headers: headers
      }
    ).pipe(map((res) => {
      return res;
    }));
  }

  private _tvToFormData(version:TrackVersion, file:File=null):FormData {
    let form = new FormData();
    if (file) form.append('file', file);
    if (version) {
      if (version.title) form.append('title', version.title);
      form.append('is_instrumental', version.is_instrumental ? 'true':'false');
    }
    return form;
  }

  private _getTrackVersionsOptionsToParams(options: GetTrackVersionsOptions) {
    let params: { [param: string]: string | string[]; } = {};
    if (options) {
      if (options.ownedBy) {
        params['owned_by'] = `${options.ownedBy}`;
      }
      if (options.track) {
        params['track'] = `${options.track}`;
      }
      if (options.offset) {
        params['offset'] = `${options.offset}`;
      }
      if (options.limit) {
        params['limit'] = `${options.limit}`;
      }
     
    }
    return params;
  }
}
