<div class="main">
    <div class="sidebar">
      <div class="top-holder">
        <div class="tabs">
          <span
            class="tab"
            [ngClass]="{'active': activeTab === 'clients'}"
            (click)="switchTab('clients'); _emailGroupService.checkedtype='CLIENT'"
          >Clients</span>
          <span
            class="tab"
            (click)="switchTab('artists'); _emailGroupService.checkedtype='ARTIST'"
            [ngClass]="{'active': activeTab === 'artists'}"
          >Artists</span>
        </div>
        <form
          action="#"
          class="search-filter"
          #searchForm="ngForm"
          (ngSubmit)="$event.preventDefault();submitSearch()"
          [ngClass]="{'search-filter-focused': searchFilterFocused}">
          <label for="search-filter-field" class="icon" (click)="submitSearch()">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"><path fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="20" d="M6 1a5 5 0 110 10A5 5 0 016 1zM13.002 13.002L9.535 9.535"/></svg>
          </label>
          <input
            [(ngModel)]="searchString"
            name="searchString"
            type="text"
            class="txt"
            id="search-filter-field"
            (focus)="searchFilterFocused = true"
            (blur)="submitSearch()"
            (keyup.enter)="$event.target.blur();"
            placeholder="Search"/>
        </form>
        <div class="groups-info">
          <strong>{{totalGroups}} Groups</strong>
          <button class="button" (click)="displayNewGroupDialog()">+ New Group</button>
        </div>
      </div>
      <div class="groups-holder" >
        <app-loader *ngIf="loadingGroups"></app-loader>
        <ul class="groups" *ngIf="!loadingGroups">
          <li class="group" [ngClass]="{'active': currentGroup && group && currentGroup.id == group.id}" *ngFor="let group of groups; trackBy:groupTrackBy" (click)="currentGroup = group;list.currentAssignedGroupSubject.next(currentGroup.id)">
            {{group.getDisplayName()}}
          </li>
        </ul>
      </div>
      <div class="groups-pagination" *ngIf="!loadingGroups && groupsNumberOfPages > 1">
        <button class="button" (click)="currentGroupsPage = currentGroupsPage-1" [disabled]="currentGroupsPage <= 1">
          <svg width="10px" height="16px" viewBox="0 0 10 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
              <g id="Number-Pagination" transform="translate(1.000000, -9.000000)" class="inner-stroke" stroke="#FFFFFF">
                <g id="Stroke-1">
                  <polyline points="8 25 0 17 8 9"></polyline>
                </g>
              </g>
            </g>
          </svg>
          Prev
        </button>
        <input type="text" class="txt" readonly [(ngModel)]="currentGroupsPage">
        <button class="button btn-next" (click)="currentGroupsPage = currentGroupsPage+1" [disabled]="groupsNumberOfPages <= currentGroupsPage">
          Next
          <svg width="10px" height="16px" viewBox="0 0 10 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
              <g id="Number-Pagination" transform="translate(-343.000000, -9.000000)" class="inner-stroke" stroke="#FFFFFF">
                <g id="Stroke-1">
                  <polyline transform="translate(348.000000, 17.000000) scale(-1, 1) translate(-348.000000, -17.000000) " points="352 25 344 17 352 9"></polyline>
                </g>
              </g>
            </g>
          </svg>
        </button>
      </div>
    </div>
  
    <div class="user-list">
      <div class="header" *ngIf="totalGroups !==0">
        <h3>{{this.currentGroup ? this.currentGroup.getDisplayName():null}}</h3>
        <div class="btns">
          <button class="button" (click)="displayDuplicateGroupDialog(this.currentGroup)">Duplicate Group</button>
          <button class="button" (click)="displayDeleteGroupDialog()">Delete Group</button>
          <button class="button" (click)="displayRemoveUsersFromGroupDialog()" [disabled]="!checkedUsers || checkedUsers.length <= 0">Remove Selected Users</button>
        </div>
      </div>
      <!-- <app-loader *ngIf="loadingUsers"></app-loader> -->
      <div class="user-list">
        <div *ngIf="totalGroups === 0" class="no-Data"> No Data Found</div>
        <app-user-email-group-list *ngIf="totalGroups !== 0"
            class="users"
            [dataSource]="users"
            [sortValue]="ordering"
            (listChange)="loadUsers()"
            (sortChange)="sortChange($event)"
            [checkedUsers]="checkedUsers"
            [groupId]="currentGroup ? currentGroup.id:null"
        ></app-user-email-group-list>
  
        <app-pagination
            *ngIf="usersNumberOfPages > 1"
            [page]="currentUsersPage"
            (pageChange)="currentUsersPage = $event"
            [numberOfPages]="usersNumberOfPages"
        ></app-pagination>
      </div>
    </div>
  </div>
  