<div [@.disabled]="disableAnimation" class="manage-co-writers" >
    <mat-expansion-panel  hideToggle #coWriterPanel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header >
        <mat-panel-title class="titleFlexContainer panel-title">
          <div class="card-title titleFlexItem">
            <b *ngIf="!isVersion">CO-WRITERS: MAIN TRACK <span *ngIf="this.track.cowritten_by.length"> - {{this.track.cowritten_by.length || '0'}}</span></b>
            <b *ngIf="isVersion">CO-WRITERS: TRACK VERSION <span *ngIf="this.track.cowritten_by.length"> - {{this.track.cowritten_by.length || '0'}}</span></b>
          </div>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
        <div *ngIf="panelOpenState">
          <div class="btn btn-link headerFlexItem" style="margin-left:auto; color: white;">
            <u>Edit</u>
          </div>
        </div>
        <div *ngIf="!panelOpenState">
          <div class="btn btn-link headerFlexItem" style="margin-left:auto;">
            <u *ngIf="lengthOfCo==undefined">Add</u>
            <u *ngIf="lengthOfCo>0">Review</u>
            <u *ngIf="lengthOfCo>0" class="svg-text"><img class="uploadwidth" src="assets/img/check-circle.png"></u>
          </div>
        </div>
      </mat-expansion-panel-header>
  <div class="grid-card card-data">
    <div class="grid-item" >
    <mat-form-field appearance="fill" style="width:100%;" floatLabel="always" [ngClass]="{'disabledElement': ((newCowriter == false))}">
        <mat-label>CO-WRITERS</mat-label>
        <mat-select class="co-writer-list"  [multiple]="true" #coWriter  (selectionChange)="selectOption($event,track.cowritten_by)" panelClass="card-select card-select2-cowriter" [disableOptionCentering]="true"  [(ngModel)]="track.cowritten_by" placeholder="Select">
          <mat-option *ngFor="let status of coWriters"  [disabled]="(status.disable == true)" [value]="status">
           
            {{status.first_name}} {{status.last_name}}
          </mat-option>
          </mat-select>
    </mat-form-field>
  </div></div>
  <div class="grid-card card-data1" *ngFor="let co of track.cowritten_by; let i = index">
    <div class="grid-item"  [ngClass]="{'disabledElement': ((co.disable == true))}">
      <p>{{co.first_name}} {{co.last_name}}</p>
      <p class="percent-data"><span>   <input class="data" step="00.1" matInput [(ngModel)]="co.percent" (keyup)="$event.preventDefault();$event.stopPropagation();value($event.target.value, i)" type="text" matInput  maxlength="4" 
        (blur)="$event.preventDefault();$event.stopPropagation();onBlur($event.target.value, i)" ><p class="new-per">%</p></span></p>
     <p class="percent-data1"(click)="co.percent = minus(co.percent,i)"><span>-</span></p> 
     <p class="percent-data2" (click)="co.percent = plus(co.percent,i)"><span>+</span></p> 
     <p class="percent-data3" (click)="remove(co.id);co.percent = 0;selectOption($event,track.cowritten_by)"><span>Remove</span></p>
     
    </div>
  </div>
   <button class="btn save-btn" [disabled]="track.cowritten_by.length ===  0" mat-button (click)="closethisPanel()">continue & save</button>
  
  <p class="card-portion">The co-writer’s share is based on a portion of your 100% of a song.</p>
    </mat-expansion-panel>
  </div>
  