<div class="dialog-container green-theme">
    <div class="dialog-header">
      <h4 class="dialog-heading">Artist Activity - {{activeTab}}</h4>
      <div class="actions">
        <div class="tabs">
            <span
              class="tab"
              (click)="changeTab('tracks')"
              [ngClass]="{'active': activeTab === 'tracks'}">Tracks</span>
            <span
              class="tab"
              [ngClass]="{'active': activeTab === 'uploads'}"
              (click)="changeTab('uploads')">Uploads</span>
        </div>
        <button type="button" class="close-button" (click)="close()">
            <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                stroke-linejoin="round">
                <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                <g id="Genre-Options">
                    <g id="Close" transform="translate(1439.000000, 32.000000)">
                    <path d="M24,0 L0,24" id="Path"></path>
                    <path d="M0,0 L24,24" id="Path"></path>
                    </g>
                </g>
                </g>
            </g>
            </svg>
        </button>
      </div>
    </div>
  
    <mat-dialog-content>
      <div class="dialog-body track-list-sort">
        <h3 class="title" style="font-size: 12px;text-transform: capitalize;">{{data.user.getFullName()}}</h3>
        <app-loader *ngIf="loading" color="#50e3c2"></app-loader>
        <mat-table *ngIf="!loading && activeTab=='tracks'&& notShowTrack === false" [dataSource]="listDataSource" matSort class=" green-theme" multiTemplateDataRows >          
            <ng-container matColumnDef="title" style="font-size: 12px;">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="headerCell">TRACK TITLE</mat-header-cell>
              <mat-cell *matCellDef="let row">
                  <ng-container *ngIf="activeTab == 'tracks'">
                    {{row.title}}
                  </ng-container>
                
              </mat-cell>
            </ng-container>
          
            <ng-container matColumnDef="number" >
              <mat-header-cell *matHeaderCellDef mat-sort-header class="headerCell">RATING</mat-header-cell>
              <mat-cell *matCellDef="let row" style="font-size: 12px;">
                <ng-container *ngIf="activeTab == 'tracks'">
                  N/A
                </ng-container>
             
              </mat-cell>
            </ng-container>
          
            <ng-container matColumnDef="created_at">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="headerCell">DATE CREATED</mat-header-cell>
              <mat-cell *matCellDef="let row" style="font-size: 12px;">
                <ng-container *ngIf="activeTab == 'tracks'">
                  {{row.created_at | date: 'shortDate'}}
                </ng-container>
               
              </mat-cell>
            </ng-container>
          
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns" class="main-row" [@rowInOut]></mat-row>
        </mat-table>
        <div  class="new-upload" *ngIf="!loading && activeTab=='tracks' && notShowTrack === true">
          There are no tracks.
        </div>
          <mat-table *ngIf="!loading && activeTab=='uploads' && !notShowUpload" [dataSource]="listDataSource" matSort class=" green-theme" multiTemplateDataRows >          
            <ng-container matColumnDef="file_name" style="font-size: 12px;">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="headerCell">FILE NAME</mat-header-cell>
              <mat-cell *matCellDef="let row">
                  <ng-container *ngIf="activeTab == 'uploads'">
                    {{row.file_name}}
                  </ng-container>
                
              </mat-cell>
            </ng-container>
          
            <ng-container matColumnDef="file_size" >
              <mat-header-cell *matHeaderCellDef mat-sort-header class="headerCell">FILE SIZE</mat-header-cell>
              <mat-cell *matCellDef="let row" style="font-size: 12px;">
                <ng-container *ngIf="activeTab == 'uploads'">
                  {{row.file_size}}
                </ng-container>
             
              </mat-cell>
            </ng-container>
          
            <ng-container matColumnDef="created_at">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="headerCell">DATE UPLOADED</mat-header-cell>
              <mat-cell *matCellDef="let row" style="font-size: 12px;">
                <ng-container *ngIf="activeTab == 'uploads'">
                  {{row.created_at | date: 'shortDate'}}
                </ng-container>
               
              </mat-cell>
            </ng-container>
          
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns" class="main-row" [@rowInOut]></mat-row>
        </mat-table>
        <div class="new-upload" *ngIf="!loading && activeTab=='uploads' && notShowUpload">
          There are no uploads.
        </div>
        <app-pagination
          *ngIf="numberOfPages > 1"
          [page]="currentPage"
          (pageChange)="pageChange($event)"
          [numberOfPages]="numberOfPages"
          [darkText]="true"
          ></app-pagination>
      </div>
    </mat-dialog-content>
</div>
  