import { MibeModel } from './mibemodel.model';

export enum PhoneNumberTypes {
  MOBILE = 'mobile',
  HOME = 'home',
  OFFICE = 'office',
  OTHER = 'other'
}

export class PhoneNumber implements MibeModel {
  id: number;
  number_type: PhoneNumberTypes;
  phone_number: string;

  private _original_data: any;

  /**
   * Create a Playlist.
   * @param {any} json - The JSON object with which to construct our Playlist.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a Playlist.
   * @param {any} json - The JSON object with which to load our Playlist.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this._original_data = json;
    this.id = json.id;
    this.number_type = json.number_type;
    this.phone_number = json.phone_number;
  }
  /**
   * Returns a JSON representation of our Playlist
   * @return {any} - The JSON representation of our Playlist
   */
  toJSON() {
    var json = this._original_data;
    json['id'] = this.id;
    json['number_type'] = this.number_type;
    json['phone_number'] = this.phone_number;
    return json;
  }

}
