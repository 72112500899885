<div class="edit-track">

  <app-track-preview
    [track]="track"
    [version]="version"
    [ngClass]="{hide: !track || !version}"
    class="music-player"
  ></app-track-preview>

  <app-track-details
    [(track)]="track"
    [(version)]="version"
  ></app-track-details>

  <app-manage-cowriters [(composers)]="composers"></app-manage-cowriters>

  <app-track-status [(track)]="track" [(version)]="version"></app-track-status>

  <div class="separator-line"></div>

  <app-track-description
    [(track)]="track"  [(version)]="version"></app-track-description>

  <button  [disabled]="track.isValid === true" class ="save-version-btn" mat-button (click)="onSave()"> SAVE TRACK</button>
</div>
<div class="loading-overlay" *ngIf="loading">
  <app-loader></app-loader>
</div>


