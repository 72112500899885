<div class="edit-track">

<app-track-preview [track]="track" [version]="version" [ngClass]="{hide: !track || !version}"
  (collapse)="toggleMusicPlayerCollapsed()" class="music-player"></app-track-preview>

  <app-track-details-for-version [(track)]="track" [(version)]="version" *ngIf="track && version">
  </app-track-details-for-version>

  <app-composer-manage-cowriters [(composers)]="composers" [(version)]="version" [(isVersion)]="is_version"></app-composer-manage-cowriters>

  <div class="separator-line"></div>
  <!-- <app-composer-track-description [(track)]="track" [(version)]="version" [(isVersion)]="is_version"></app-composer-track-description> -->
  <app-composer-new-version-description [(track)]="track" [(version)]="version" [(isVersion)]="is_version"></app-composer-new-version-description>
  <button  [disabled]="track.isValid === true"  class="save-version-btn" mat-button (click)="saveVersion()">Save Track Version</button>

</div>
<div class="loading-overlay" *ngIf="loading">
  <app-loader></app-loader>
</div>