import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSortable } from '@angular/material/sort';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ManageArtistAccountComponent } from 'src/app/shared/manage-artist-account/manage-artist-account.component';
import { ManageClientAccountComponent } from 'src/app/shared/manage-client-account/manage-client-account.component';
import { UserPRO } from 'src/app/shared/models/user-pro.model';
import { User } from 'src/app/shared/models/user.model';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SliderDialogService } from 'src/app/shared/services/slider-dialog.service';
import { GetUsersOptions, UserService } from 'src/app/shared/services/user.service';

const pageSize = 20;

@Component({
  selector: "app-user-mgmt-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
})
export class UsersComponent implements OnInit, OnDestroy {
  get activeTab(): "clients" | "artists" {
    return this._router.url.includes("/clients") ? "clients" : "artists";
  }
  clients: Observable<ReadonlyArray<User>>;
  artists: Observable<ReadonlyArray<{ user: User; userPRO: UserPRO }>>;
  loadingClients: boolean = true;
  loadingArtists: boolean = true;
  totalClients: number = 0;
  totalArtists: number = 0;
  currentClientPage: number = 1;
  currentArtistPage: number = 1;

  checkedClients: User[] = [];
  checkedArtists: User[] = [];

  searchString = '';
  searchFormControl: FormControl = new FormControl();

  startDate: Date;
  endDate: Date;
  dateChoices: string[] ;
  selectedDateType: string ;


  get loading(): boolean {
    return this.activeTab == "clients"
      ? this.loadingClients
      : this.loadingArtists;
  }

  get numberOfPages(): number {
    return Math.ceil(
      this.activeTab == "clients"
        ? this.totalClients / pageSize
        : this.totalArtists / pageSize
    );
  }

  get currentPage(): number {
    return this.activeTab == "clients"
      ? this.currentClientPage
      : this.currentArtistPage;
  }

  set currentPage(page) {
    if (this.currentPage != page) {
      if (this.activeTab == "clients") {
        this.currentClientPage = page;
      } else {
        this.currentArtistPage = page;
      }
    }
  }
  get ordering(): string {
    return this._ordering;
  }
  set ordering(o: string) {
    if(this.activeTab == "clients"){
         if (o != this._ordering) {
           this._ordering = o;
          // this.currentClientPage = 1;
           //this.loadUsersForList();
           this._router.navigate([], {
             relativeTo: this._activatedRoute,
             queryParams: this.params,
           });
         }
    }else{
           if (o != this._ordering) {
             this._ordering = o;
            // this.currentArtistPage = 1;
           //  this.loadUsersForList();
             this._router.navigate([], {
               relativeTo: this._activatedRoute,
               queryParams: this.params,
             });
           }
    }
  
  }

  private get currentQueryParams() {
    return {
      page: this.currentPage > 1 ? this.currentPage : null,
      start: this.startDate ? this.startDate.toISOString() : null,
      end: this.endDate ? this.endDate.toISOString() : null,
      datetype:
        this.selectedDateType == "Created At" ? null : this.selectedDateType,
      search: this.searchString,
    };
  }

  private get params(): GetUsersOptions {
    let params: GetUsersOptions = {};
      if (this.ordering) {
        params["ordering_by"] = this.ordering;
      }
    // if (this.selectedDateType == "Last Visited") params.dateType = "last_login";
    if (this.selectedDateType == "Last Visited") params.dateType = "last_visited";
    if(this.selectedDateType === "with Downloads") params.dateType = "with_download";
    if (this.startDate) params.startDate = this.startDate;
    if (this.endDate) params.endDate = this.endDate;
    if (this.searchString) params.searchString = this.searchString;

    return params;
  }
  private _ordering: string ='id';
  private _clientsSubject: BehaviorSubject<ReadonlyArray<User>> =
    new BehaviorSubject<ReadonlyArray<User>>([]);
  private _artistsSubject: BehaviorSubject<
    ReadonlyArray<{ user: User; userPRO: UserPRO }>
  > = new BehaviorSubject<ReadonlyArray<{ user: User; userPRO: UserPRO }>>([]);
  private _subscriptions: Subscription[] = [];

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _userService: UserService,
    private _dialogService: DialogService,
    private _sliderDialogService: SliderDialogService
  ) {
    this.clients = this._clientsSubject.asObservable();
    this.artists = this._artistsSubject.asObservable();
    if(this.activeTab == "clients"){
      this.selectedDateType = "with Downloads"
      this.dateChoices = ["with Downloads", "Last Visited"];
    }
    else{
      this.selectedDateType = "with uploads";
      this.dateChoices = ["with uploads", "Last Visited"];
    }
  }

  ngOnInit(): void {
    this._activatedRoute.queryParamMap.subscribe((queryParams) => {
      let page = parseInt(queryParams.get("page"));
      let startDate = queryParams.get("start");
      let endDate = queryParams.get("end");
      let dateType = queryParams.get("datetype");
      let search = queryParams.get("search");
      this.searchString = search
      if (page && !isNaN(page) && page > 1) {
        this.currentPage = page;
      }
      if (startDate) {
        this.startDate = new Date(startDate);
      }
      if (endDate) {
        this.endDate = new Date(endDate);
      }
      if (dateType && this.dateChoices.includes(dateType)) {
   
          this.selectedDateType = dateType;
      }
      if (search) {
        this.searchString = search;
      }

      this.loadUsersForList();
    });

    this.searchFormControl.valueChanges
      .pipe(debounceTime(250), distinctUntilChanged())
      .subscribe((val: string) => {
        if (val != this.searchString) {
          this.searchString = val;
          this.currentPage = 1;
          this.updateQueryParams();
        }
      });
    // this.loadUsersForList();
    this._activatedRoute.queryParamMap.subscribe(params => {
       console.log(params)
      }
    );
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
    this._subscriptions = [];
  }

  createAccount() {
    if (this.activeTab == "clients") {
      this._sliderDialogService.open(
        ManageClientAccountComponent,
        {
          width: "600px",
          title: "CREATE CLIENT ACCOUNT",
          panelClass: "edit-track-dialog",
          data: { user: new User({ is_client: true }), isNewUser: true },
        },
        (user) => {
          if (user != null) {
            this.loadUsersForList();
          }
        }
      );
      this.loadUsersForList();
    } else {
      this._sliderDialogService.open(
        ManageArtistAccountComponent,
        {
          width: "600px",
          title: "CREATE ARTIST ACCOUNT",
          panelClass: "edit-track-dialog",
          data: {
            user: new User({ is_artist: true }),
            userPRO: new UserPRO(null),
            isNewUser: true,
          },
        },
        (user) => {
          if (user != null) {
            this.loadUsersForList();
          }
        }
      );
      this.loadUsersForList();
    }
  }

  tabChange(tab: string) {
    if (tab != this.activeTab) {
      this.searchString = ""
      this._router.navigate(["/user-mgmt", "users", tab]).then(() => {
        this.updateQueryParams();
      });
    }
  }

  pageChange(page: number) {
    if (this.currentPage != page) {
      this.currentPage = page;
      this.updateQueryParams();
    }
  }
  sortChange(data: MatSortable) {
    this.ordering = (data.start === "desc" ? "-" : "") + data.id;
  }
  addSelectedToGroup() {
    var from_page = "user_component"
    this._dialogService
      .displayAddToEmailGroupDialog(
        this.activeTab == "clients" ? this.checkedClients : this.checkedArtists, from_page
      )
      .subscribe((changed) => {});
  }

  canAddSelectedToGroup() {
    return this.activeTab == "clients"
      ? this.checkedClients.length > 0
      : this.checkedArtists.length > 0;
  }

  reloadList() {
    //this.loadUsersForList();
  }

  updateDateType(type: string) {
    if (this.selectedDateType == type) return;
    this.selectedDateType = type;
    this.updateQueryParams();
  }

  updateStartDate(startDate: Date) {
    if (this.startDate == startDate) return;
    this.startDate = startDate;
  //  this.updateQueryParams();
  }

  updateEndDate(endDate: Date) {
    if (this.endDate == endDate) return;
    this.endDate = endDate;
    this.updateQueryParams();
  }

  private updateQueryParams() {
    this._router.navigate(["."], {
      relativeTo: this._activatedRoute,
      queryParamsHandling: "merge",
      queryParams: this.currentQueryParams,
    });
  }

  private loadUsersForList() {
    if (this.activeTab == "clients") {
      this.loadingClients = true;
      this._userService
        .getAllUsers({
          isClient: true,
          // limit: pageSize,
          // offset: pageSize * (this.currentPage - 1),
          page: this.searchString ? this.currentPage : this.currentPage,
          ...this.params,
        })
        .subscribe(
          (res) => {
            this.totalClients = res.count;
            this._clientsSubject.next(res.results);
            this.loadingClients = false;
          },
          (error) => {
            console.log(error);
            //alert("An error ocurred while getting clients. Check your connections and try again");
          }
        );
    } else {
      this.loadingArtists = true;
      this._userService
        .getAllUsers({
          isArtist: true,
          // limit: pageSize,
          // offset: pageSize * (this.currentPage - 1),
          page: this.searchString ? this.currentPage : this.currentPage,
          ...this.params,
        })
        .subscribe(
          (res) => {
            this.totalArtists = res.count;
            this._userService
              .getUserPROs(res.results.map((user) => user.id))
              .subscribe(
                (proRes) => {
                  let artistList: { user: User; userPRO: UserPRO }[] = [];
                  for (let user of res.results) {
                    let pro = proRes.results.find((p) => p.id == user.id);
                    artistList.push({ user: user, userPRO: pro });
                  }
                  this._artistsSubject.next(artistList);
                  this.loadingArtists = false;
                },
                (error) => {
                  console.log(error);
                  //alert("An error ocurred while getting artists' PRO data. Check your connections and try again");
                }
              );
          },
          (error) => {
            console.log(error);
            //alert("An error ocurred while getting artists. Check your connections and try again");
          }
        );
    }
  }
}
