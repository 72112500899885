import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { Track } from '../models/track.model';
import { TrackComposer } from '../models/trackcomposer.model';
import { TrackVersion } from '../models/trackversion.model';
import { DialogService } from '../services/dialog.service';
import { MenuListService } from '../services/menu-list.service';
import { PlayerService } from '../services/player.service';
import { CONTAINER_DATA, DialogConfig, SliderDialogService } from '../services/slider-dialog.service';
import { Filters, TrackService } from '../services/track.service';
import { TrackVersionService } from '../services/trackversion.service';
import { UserMenuService } from '../services/user-menu.service';
import { forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TrackDetailsService } from '../services/track-details.service';


@Component({
  selector: 'app-edit-new-track-version',
  templateUrl: './edit-new-track-version.component.html',
  styleUrls: ['./edit-new-track-version.component.scss']
})

export class EditNewTrackVersionComponent implements OnInit {
  uploadedUrl = this._sliderDialogService.currentFile;
  loading: boolean = false;
  composers: TrackComposer[];
  bpmValue: number = 0;
  replaceTrackVersion: Boolean = false;
  uploadFile: boolean = false;
  confirmData: boolean = false;
  detail: any = [];
  userDetail: any = [];
  track: Track;
  version: TrackVersion;
  trackId: number;
  bpm: any
  duration: any
  key: any
  tempo: any
  @Output()
  filtersChange = new EventEmitter<Filters>();
  constructor(
    private _sliderDialogService: SliderDialogService,
    private _trackService: TrackService, public api: UserMenuService,
    public _DialogService: DialogService,
    private _trackVersionService: TrackVersionService, private _dialogService: DialogService,
    @Inject(CONTAINER_DATA) public componentData: DialogConfig, private _playerService: PlayerService,
    public list: MenuListService, private trackDetailsService: TrackDetailsService
  ) {
    this.track = componentData.data.track;
    this.trackId = componentData.data.track.id;
    this.version = componentData.data.version;
  }


  ngOnInit(): void {
    this.loading = true;
    let trackUrl = environment.apiURL + `/api/v1/track/${this.version.id}/get-track-info/`;
    this.api.doGET(trackUrl).subscribe(x => {
      this.list.trackDetail = x;
      this.list.trackDetail.title = x['version_title']
      this.track = this.list.trackDetail;
      this.track.genre = this.list.trackDetail.track.genre
      this.track.sub_genre = this.list.trackDetail.track.sub_genre
      this.list.trackDetail.is_full_version = false
      this.version = this.list.trackDetail;
      var keys = Object.keys(this.list.trackDetail).length
      this.detail = keys;
      let id = this.track.artist.user_id
      let url = environment.apiURL + `/api/v1/admin/users/${id}/`;
      this.api.doGET(url).subscribe(x => {
        this.list.userDetail = x
        var keys = Object.keys(this.list.userDetail).length
        this.userDetail = keys;
      })
      this._sliderDialogService.getReplaceTrackVersion().subscribe((val) => {
        if (val && val == 'replace') {

          console.log(val);

          this.replaceTrackVersion = true;
        }
      });
      if (this.replaceTrackVersion) {
        this._sliderDialogService.getReplaceTrackFile().subscribe((val) => {
          if (val) {
            this.uploadFile = true;
          }
        });
      }
      if (this.track) {
        if (this.version.title && this.version.track_ending && this.track.title) {
          this.track.isValid = false;
        }
        else {
          this.track.isValid = true;
        }
      }
      if (this.version.is_instrumental) {
        if (this.track.version_instruments === undefined || this.track['version_instruments_desc'].length === 0 ||
          this.track.version_tv_references.length < 2 || this.track.version_network_references.length < 2 || this.track.version_band_references.length < 2 ||
          this.track.version_sonic_reference.length < 2) {
          this.track.isValid = true;
        }
        else {
          this.track.isValid = false;
        }
      }
      if (this.version.is_vocals) {
        if (this.track.version_instruments === undefined || this.track['version_instruments_desc'].length === 0 ||
          this.track.version_tv_references.length < 2 || this.track.version_network_references.length < 2 || this.track.version_band_references.length < 2 ||
          this.track.version_sonic_reference.length < 2 || (this.track?.version_lyrics?.verse == '' || this.track.version_lyrics?.bridge == '' || this.track.version_lyrics?.chorus == '' || this.track.version_lyrics?.pre_chorus == '') || this.track.version_vocal === undefined || this.track['version_vocal_desc'].length === 0) {
          this.track.isValid = true;
        }
        else {
          this.track.isValid = false;
        }
      }
      this.loading = false;
    })
  }

  saveVersion(arg) {
    if (this._trackService.cowritten_by.length > 0) {
      let newValues = [];
      for (let i = 0; i < this._trackService.cowritten_by.length; i++) {
        if (this._trackService.cowritten_by[i]?.disable !== true) {
          newValues.push(this._trackService.cowritten_by[i])
        }
      }
      this._trackService.cowritten_by = newValues
    }
    console.log(this.track, this.track.Instrumentsub_category)
    let id: any = []
    id = this.track.Instrumentsub_category;
    if (id) {
      if (this.track && this.track.Instrumentdescriptions) {
        this.track.Instrumentdescriptions = this.track.Instrumentdescriptions.filter(function (e) {
          if (e !== id) {
            return e;
          }
        })

      }
      this.track.instruments = this.track.instruments.filter(function (e) {
        if (e.id !== id) {
          return e;
        }
      })
    }
    // <!-- Add Newsuggestion -->
    if (this.track.version_tv_references) {
      this.track.version_tv_references = this.track.version_tv_references.filter(function (e) {
        if (e.id !== '') {
          return e;
        }
      })
    }
    if (this.track.version_network_references) {
      this.track.version_network_references = this.track.version_network_references.filter(function (e) {
        if (e.id !== '') {
          return e;
        }
      })
    }

    if (this.track['version_instruments_desc'].length > 0) {
      for (let x of this.track['version_instruments_desc']) {
        this.track.Instrumentdescriptions = this.track.Instrumentdescriptions.filter(function (e) {
          if (e !== x.id) {
            return e;
          }
        })
        this.track.Instrumentnew_suggestion = this.track.Instrumentnew_suggestion.filter(function (e) {
          if (e !== x.name) {
            return e;
          }
        })
      }
    }
    for (let i = 0; i < this.track.Instrumentsub_category['length']; i++) {
      const index1 = this.track.rm_ins.findIndex(ind => ind == this.track.Instrumentsub_category[i]);
      if (index1 >= 0) {
        this.track.rm_ins.splice(index1, 1);
      }
    }
    if (this.version.status === 'DENIED') {
      this.version.rejected_status_reason = this.version.rejected_status_reason
    }
    else {
      this.version.rejected_status_reason = null
    }
    let sonic = this.track.version_sonic_reference.map(x => x.id)
    let band = this.track.version_band_references.map(x => x.id)

    let data = {
      "track": this.track.track.id,
      "title": this.version.title,
      "is_instrumental": this.version.is_instrumental,
      "is_full_version": this.version.is_full_version,
      "is_main_track": this.version.is_main_track,
      "status": this.version.status,
      "cowritten_by": this.filter(this._trackService.cowritten_by) || [],
      "id": this.track.id,
      "instruments": {
        "sub_category": this.track.Instrumentsub_category || [],
        "descriptions": this.track.Instrumentdescriptions || [],
        "suggesstion": [],
        "new_suggestion": this.track.newAddedSuggestion || [],
        "rm_desc_sug": this.track.Instrumentrm_desc_sug || [],
        "new_desc_sug": this.track.Instrumentnew_desc_sug || [],
        'new_ins': this.track.new_ins || [],
        'rm_ins': this.track.rm_ins || [],
        'new_ins_decs': this.track.new_ins_decs || []
      },
      // <!-- Add Newsuggestion -->

      "tv_references": {
        "sub_category": this.track.version_tv_references.map(x => x.id),
        "new_suggestion": [],
        "tv_ref_new_sug": this.track.tv_ref_new_sug || [],
        "tv_ref_rm_sug": this.track.tv_ref_rm_sug || [],
      },
      "network_references": {
        "sub_category": this.track.version_network_references.map(x => x.id),
        "new_suggestion": [],
        "network_ref_new_sug": this.track.network_ref_new_sug || [],
        "network_ref_rm_sug": this.track.network_ref_rm_sug || [],
      },
      "artist_references": [],
      "sonic_references": sonic.filter(x => x !== ''),
      "band_references": band.filter(x => x !== ''),
      "vers_new_sonic_band": this.track.vers_new_sonic_band,
      "vers_rm_sonic_band": this.track.vers_rm_sonic_band,
      "vers_new_sonic_ref": this.track.vers_new_sonic_ref,
      "vers_rm_sonic_ref": this.track.vers_rm_sonic_ref,
      "vocal": {
        "sub_category": this.track.Vocalssub_category || "",
        "descriptions": this.track.Vocalsdescriptions || [],
        "suggesstion": this.track.vocals || [],
        "new_suggestion": [],
        "rm_desc_sug": this.track.Vocalrm_desc_sug || [],
        "new_desc_sug": this.track.Vocalnew_desc_sug || [],
      },
      "lyrics_data": {
        "verse": this.track.version_lyrics.verse ? this.track.version_lyrics.verse : [''],
        "pre_chorus": this.track.version_lyrics.pre_chorus ? this.track.version_lyrics.pre_chorus : [''],
        "chorus": this.track.version_lyrics.chorus ? this.track.version_lyrics.chorus : [''],
        "bridge": this.track.version_lyrics.bridge ? this.track.version_lyrics.bridge : ['']
      },
      "is_vocals": this.version.is_vocals,
      "is_mibe_owned": this.version.is_mibe_owned,
      "is_five_star": this.version.is_five_star,
      "versionId": this.version.id,
      "calculated_bpm": this.version.calculated_bpm,
      "track_ending": this.version.track_ending,
      "calculated_key": this.version.calculated_key,
      "tempo": this.version.tempo,
      "rejected_status_reason": this.version.rejected_status_reason,
      'added_by_role': this.track.added_by_role,
      'notify_composer': this.track.notify_composer,
      'internal_status_notes': this.version.internal_status_notes,
      'partial': this.track.partial,
      "support_tv_ref": {
        "new_sug": this.track.tv_referencesNew_suggestion || [],
        "rm_sug": this.track.newTv_ref_rm_sug || [],
      },
      "support_nw_ref": {
        "new_sug": this.track.network_referencesNew_suggestion || [],
        "rm_sug": this.track.newNetwork_ref_rm_sug || [],
      },
      "support_band_ref": {
        "new_sug": this.track.version_band_referencesNew.map(x => x.name),
        "rm_sug": this.track.bandRemove,
      },
      "support_sonic_ref": {
        "new_sug": this.track.sonic_referencesNew.map(x => x.name),
        "rm_sug": this.track.sonicRemove,
      },
    }

    if (arg == '2') {
      data['bpm'] = this.bpm
      data['tempo'] = this.tempo.id
      data['key'] = this.key
      data['duration'] = this.duration
    }
    if (this.replaceTrackVersion) {
      this._sliderDialogService.getReplaceTrackFile().subscribe((val) => {
        data['upload_file_id'] = val
        console.log(val);
        data['status'] = 'PENDING'
      });
    }
    this.track.composers = this.composers;
    this.loading = true;
    if (this.replaceTrackVersion && this.confirmData) {
      forkJoin([
        this._trackVersionService.updateReplaceTrackVersion(data)
      ]).subscribe(([version]) => {
        this.componentData.data.version = version;
        this.loading = false;
        this._playerService.nextTab(true)
        this._sliderDialogService.close(true);
        this._playerService.updateWavePlay(false);
        this._playerService.updateIsPlaying(false);
        this._playerService.updateShowMusicPlayer(false);
        this._playerService.play(null, null);
        this._sliderDialogService.close(true);
        this.filtersChange.emit();
        localStorage.setItem('editData', 'yes')
        location.reload();
      }, (error) => {
        console.log(error);
        let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
        this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
        });
        this.loading = false;
      });
    }
    else {
      forkJoin([
        this._trackVersionService.updateTrackVersion(data)
      ]).subscribe(([version]) => {
        this.componentData.data.version = version;
        this.loading = false;
        this._playerService.nextTab(true)
        this._playerService.updateWavePlay(false);
        this._playerService.updateIsPlaying(false);
        this._playerService.updateShowMusicPlayer(false);
        this._playerService.play(null, null);
        this._sliderDialogService.close(true);
        this.filtersChange.emit();
        localStorage.setItem('editData', 'yes')
        // location.reload();
      }, (error) => {
        console.log(error);
        let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
        this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
        });
        this.loading = false;
      });
    }
  }

  toggleTrackDescriptionCollapsed(event: any): void {
    this.track[event.type] = event.data;
  }
  toggleMusicPlayerCollapsed() {

  }
  filter(e) {
    let filData = []
    for (let x of e) {
      console.log(e)
      let data = {}
      if (x.user_id) {
        data['user_id'] = x.user_id
        data['percent'] = x.percent
      }
      else {
        data['user_id'] = x.id
        data['percent'] = x.percent
      }

      filData.push(data)
    }
    return filData
  }
  replaceTrack() {
    this.loading = true
    let trackData = {
      "file_path": this._sliderDialogService.currentFile
    }
    let requestUrl = environment.apiURL + `/api/v1/track/process-files/`
    this.api.doPOST(requestUrl, trackData).subscribe(x => {
      this.loading = false
      let arr;
      arr = x;

      this.bpm = arr.bpm;
      this.duration = arr.duration;
      this.tempo = arr.tempo

      this.tempoNumber = arr.tempo.id
      this.bpmValue = Math.round(arr.bpm)
      this.key = arr.key_with_scale

      this.version.tempo = (this.version.tempo != '' || this.version.tempo != undefined || this.version.tempo != null) ? this.tempoNumber : this.version.tempo
      this.version.calculated_key = (this.version.calculated_key != '' || this.version.calculated_key != undefined || this.version.calculated_key != null) ? this.trackDetailsService.getKeyFromList(this.key) : this.version.calculated_key
      this.version.calculated_bpm = (this.version.calculated_bpm != undefined || this.version.calculated_bpm != null) ? Math.round(arr.bpm) : this.version.calculated_bpm

      this.version.tempo = this.tempoNumber;
      this.version.calculated_key = this.trackDetailsService.getKeyFromList(this.key)
      this.version.calculated_bpm = (Math.round(arr.bpm))
      this._sliderDialogService.currentFile = null
      this._dialogService.displayConfirmPopup(this.track.title, "replace").subscribe(changed => {
        if (changed) {
          this.confirmData = true
        }
        if (this.confirmData) {

          this.saveVersion(2)
        }
      })
    })
    this.loading = false
  }

  ngOnDestroy() {
    this._playerService.nextTab(false);
    this._playerService.updateViewType(false);
    this._playerService.updateShowMusicPlayer(false);
    this._playerService.play(null, null);
  }

  tempoNumber: any;
  getTrackInfo() {
    this.loading = true;
    let trackData = {
      "file_path": this.version.mp3_file
    }
    let requestUrl = environment.apiURL + `/api/v1/track/process-files/`
    this.api.doPOST(requestUrl, trackData).subscribe(x => {
      let arr;
      arr = x;
      this.tempoNumber = arr.tempo.id
      this.bpmValue = Math.round(arr.bpm)
      this.key = arr.key_with_scale

      this.version.tempo = (this.version.tempo != '' || this.version.tempo != undefined || this.version.tempo != null) ? this.tempoNumber : this.version.tempo
      this.version.calculated_key = (this.version.calculated_key != '' || this.version.calculated_key != undefined || this.version.calculated_key != null) ? this.trackDetailsService.getKeyFromList(this.key) : this.version.calculated_key
      this.version.calculated_bpm = (this.version.calculated_bpm != undefined || this.version.calculated_bpm != null) ? Math.round(arr.bpm) : this.version.calculated_bpm

      this.version.tempo = this.tempoNumber;
      this.version.calculated_key = this.trackDetailsService.getKeyFromList(this.key);
      this.version.calculated_bpm = (Math.round(arr.bpm))
      this.loading = false
      console.log(arr)
    })
  }
}
