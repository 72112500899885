import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MenuListService } from '../../shared/services/menu-list.service';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import { environment } from 'src/environments/environment';
import { DialogService } from 'src/app/shared/services/dialog.service';

const TosPageSize = 25;
@Component({
  selector: 'app-tos',
  templateUrl: './tos.component.html',
  styleUrls: ['./tos.component.scss']
})
export class TosComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  displayedColumns = ['file_name', 'version', 'created_at', 'shared_on', 'View'];
  loading:boolean = false;
  dataSource = ELEMENT_DATA;
  _searchString = ''
  constructor(public list: MenuListService,public api: UserMenuService, public _DialogService:DialogService, public config: MenuConfigService,private sortTb: ChangeDetectorRef) {
    this.list.getAllTos();
   }
  primary:any;
  tbactive: boolean = false
  searchFilterFocused:any;
  searchString:any;
  ordering: any = 'asc';
  type: string
  evt = 1;
 // @ViewChild(MatSort, { static: false })
  // set sort(tos: MatSort) {
  //   this.list.dataSourceForTOS.sort = tos;
  // }
  @ViewChild(MatSort) sort:MatSort;
  // set sortClient(tos: MatSort) {
  //   this.list.dataClientSourceForTOS.sort = tos;
  // }
  public _currentTosPage = 1;
  set currentLogsPage(p: number) {
    if (!isNaN(p) && p != this._currentTosPage) {
      this._currentTosPage = p;
      this.list.getAllTos();
    }
  }
  get currentLogsPage(): number {
    return this._currentTosPage;
  }
  get tosNumberOfPages(): number {
    return Math.ceil(this.list.tosCount / TosPageSize);
  }
  ngOnInit(): void {
  }
  onChangeFile(e: File) {
    let requestURL = environment.apiURL + this.config.doTosUpload ;
    let formInputs = new FormData()
    formInputs.append('tos', e);
    if (this.tbactive) {
      formInputs.append('user_type', 'CLIENT');
    }
    else {
      formInputs.append('user_type', 'ARTISTS');
    }
    formInputs.append('version', '3.1');
    this.loading = true;
    this.api.doPOST(requestURL, formInputs).subscribe(x => {
      this.loading = true;
      this.list.getAllTos();
    },
      (error) => {
        if(error.error?.detail){
          // alert(error.error.detail)
          let data = error.error?.error ? error.error?.error : (error.error?.detail ?error.error?.detail : (error.error?.details ? error.error?.details:``) )
          this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
          });
        
        }
        console.log(error.error) 
        this.loading = false

      }
    )
  }
  ngAfterContentChecked(): void{
    this.list.dataSourceForTOS = new MatTableDataSource(this.list.allTos);
    this.sortTb.detectChanges();
    if (this.tbactive) {
      this.list.dataClientSourceForTOS.sort = this.sort;

    }
    else {
      this.list.dataSourceForTOS.sort = this.sort;  
    }
  }

  pageTosTrigger(evt, searchString){
    this.list.tosLoader = false;
    this._searchString = searchString;
   
  if (evt) {
    this.evt = evt;
  }
  let url;
  if (this.tbactive) {
    this.type = 'CLIENT'
  }
  else {
    this.type = 'ARTISTS'
  }
  if(this._searchString && evt ){
    url = environment.apiURL + this.config.doTos +'?page=' + this.evt + '&search=' + this._searchString;
  }
  else if(this._searchString){
    url = environment.apiURL + this.config.doTos + '?search=' + this._searchString;
  }else{
    url = environment.apiURL + this.config.doTos +'?page=' + this.evt + '&search=' + this._searchString;
  }
  
    this.api.doGET(url).subscribe(x=>{
      //this.list.logLoader = false;
      this.list.tosLoader = false;
    this.list.allTos = x['results'] || [];
    this.list.tosCount = x['count'];
    if (this.tbactive) {
      this.list.dataClientSourceForTOS = new MatTableDataSource(this.list.allClientTos);
    }
    else {
      this.list.dataSourceForTOS = new MatTableDataSource(this.list.allTos);
    }
      },
      (error)=>{
        this.list.tosLoader = false;     
      })
  
  }

getFindUser(val){
  this._searchString = val;
  console.log(this._searchString)
  if (this.tbactive) {
    this.type = 'CLIENT'
  }
  else {
    this.type = 'ARTISTS'
  }
  if(this._searchString!=''){
    this.list.tosLoader = true;
     let url = environment.apiURL + this.config.doTosSearch  + '?type=' + this.type + '&search=' + this._searchString;
    this.api.doGET(url).subscribe(x=>{
    this.list.tosLoader = false;
    this.list.allTos = x['results'] || [];
    this.list.tosCount = x['count'];
      if (this.tbactive) {
        this.list.dataClientSourceForTOS = new MatTableDataSource(this.list.allClientTos);
      }
      else {
        this.list.dataSourceForTOS = new MatTableDataSource(this.list.allTos);
      }
  },
  (error)=>{
    this.list.tosLoader = false;
    //alert(error.message)
  }
  )
  } else{
    this.list.tosLoader = true;
    let url = environment.apiURL + this.config.doTosSearch  + '?type=' + this.type;
   this.api.doGET(url).subscribe(x=>{
   this.list.tosLoader = false;
   this.list.allTos = x['results'] || [];
     if (this.tbactive) {
       this.list.dataClientSourceForTOS = new MatTableDataSource(this.list.allClientTos);
     }
     else {
       this.list.dataSourceForTOS = new MatTableDataSource(this.list.allTos);
     }
 },
 (error)=>{
   this.list.tosLoader = false;
   //alert(error.message)
 }
 )
  }
 
}
sortData(data) {
  console.log(data)
  if(data.active === 'file_name' && data._direction === 'asc' || data._direction === ''){
    this.ordering = 'user__first_name'
  }else if(data.active === 'file_name' && data._direction === 'desc'){
    this.ordering = '-user__first_name'
  }
  if(data.active === 'version' && data._direction === 'asc' || (data.active === 'version'&& data._direction === '')){
    this.ordering = 'tos__file_name'
  }else if(data.active === 'version' && data._direction === 'desc'){
    this.ordering = '-tos__file_name'
  }
  if(data.active === 'created_at' && data._direction === 'asc' || (data.active === 'created_at'&& data._direction === '')){
    this.ordering = 'tos__created_at'
  }else if(data.active === 'created_at' && data._direction === 'desc'){
    this.ordering = '-tos__created_at'
  }
  this.loading = true;
    let requestUrl = environment.apiURL +  this.config.doTos+'?ordering='+this.ordering+'&page='+this.evt;
    this.api.doGET(requestUrl).subscribe(x =>{
      this.list.tosLoader = false;
      this.list.allTos = x['results'] || [];
      this.list.tosCount = x['count'];
      this.list.dataSourceForTOS = new MatTableDataSource(this.list.allTos);

    })
}
}
export interface PeriodicElement {
  Size: string;
  position: string;
  weight: string;
  symbol: string;
  
}

const ELEMENT_DATA: PeriodicElement[] = [
  {position: 'Fox Network', Size: 'TERMS OF SERVICE #', weight: '12/11/20', symbol: 'Pending'},
  {position: 'Fox Network', Size: 'TERMS OF SERVICE #', weight: '12/11/20', symbol: '3/24/14'},
  {position: 'Fox Network', Size: 'TERMS OF SERVICE #', weight: '12/11/20', symbol: '3/24/14'},
  
 
];
