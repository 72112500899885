<form [formGroup]="userGroupForm">
<div class="dialog-container">
    <div class="dialog-header">
        <h4 class="dialog-heading">Duplicate New Group</h4>
        <button type="button" class="close-button" (click)="close()">
        <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                    <g id="Genre-Options">
                        <g id="Close" transform="translate(1439.000000, 32.000000)">
                            <path d="M24,0 L0,24" id="Path"></path>
                            <path d="M0,0 L24,24" id="Path"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        </button>
    </div>
<mat-dialog-content>
   
    <div class="dialog-body">
         <div class="">
                <mat-form-field appearance="fill" class="group">
                    <mat-label>Group Name</mat-label>
                    <input matInput formControlName="group_name" [(ngModel)]="list.currentUserGroupName">
                </mat-form-field>
                <p class="note cretenote">Please make title as short and descriptive as possible</p>
        </div> 
        <div  class="form-col">
                <app-loader *ngIf="loader" color="#50e3c2"></app-loader>
            <button *ngIf="!loader" class="form-button" (click)="duplicateUserGroup()">Duplicate Group</button>
            <button  *ngIf="!loader"class="form-button form-button-cancel" (click)="close()">Cancel</button>
        </div>
    </div>
</mat-dialog-content>
</div>
</form>
