import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmailTemplateService } from '../services/email-template.service';
import { EmailTemplate } from '../models/email-template.model';
import { DialogService } from '../services/dialog.service';

export interface DialogData {
  template: EmailTemplate
}
@Component({
  selector: 'app-delete-email-template-dialog',
  templateUrl: './delete-email-template-dialog.component.html',
  styleUrls: ['./delete-email-template-dialog.component.scss']
})
export class DeleteEmailTemplateDialogComponent implements OnInit {

  loading: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<DeleteEmailTemplateDialogComponent>,
    private _emailTemplateService: EmailTemplateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,public dialog: DialogService,
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close(false);
  }

  deleteTemplate() {
    console.log(this.data)
    console.log(this.data.template.id)
    this.loading = true;
    this._emailTemplateService.deleteTemplate(this.data.template.id).subscribe(()=> {
      this.loading = false;
      this.dialogRef.close(true);
    }, (error) => {
      console.log(error);
      let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
      this.dialog.UserUploadPopupConfirmPopup(data).subscribe(changed => {
      });
      this.dialogRef.close(true);
      //alert("An error ocurred while deleting the template. Check your connections and try again");
    });
  }

}
