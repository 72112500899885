import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MenuListService } from '../../shared/services/menu-list.service';
import { environment } from '../../../environments/environment';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { ThesaurusComponent } from '../thesaurus/thesaurus.component';
import { MatSelect } from '@angular/material/select';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
  selector: 'app-add-edit-moods-dialogue',
  templateUrl: './add-edit-moods-dialogue.component.html',
  styleUrls: ['./add-edit-moods-dialogue.component.scss']
})
export class AddEditMoodsDialogueComponent implements OnInit {
  addMoodForm: FormGroup;
  loaderContent: boolean = true;
  loader: boolean = false;
  thesaurusData: any;
  // thesaurus:any;
  moodNumber:any =false
  thesaurus:any[] =[]
  constructor(private dialogRef: MatDialogRef<AddEditMoodsDialogueComponent>, public newMoodForm: FormBuilder, public list: MenuListService, public api: UserMenuService, public config: MenuConfigService,public _DialogService:DialogService) { 

  }

  ngOnInit(): void {
    this.thesaurusList()
    setTimeout(()=>{                         
      this.loaderContent = false;
    }, 500);
    this.addMoodForm = this.newMoodForm.group({
      mood_name: new FormControl('', [Validators.required]),
      thesaurusData: new FormControl([])
    })
    if (this.list && this.list.moodsType === 'Edit') {
      let data: any = [];
      let url = environment.apiURL + this.config.getAllMOODSlist + this.list.moodId + '/';
      this.api.doGET(url, this.list.moodId).subscribe(value => {
        data = value
        for (let i = 0; i < data.thesaurus_terms.length; i++) {
          this.thesaurus.push(data.thesaurus_terms[i].id)
        }
        this.moodNumber = this.thesaurus
        // this.thesaurus= [132]
        console.log(this.thesaurus)
      },
        (error) => {
          this.loader = false;
        })
    }
     console.log( this.thesaurus , this.list)
  }
  close() {
    this.dialogRef.close();
  }
  moodChanged(exclusive: any){
   
    // if( this.thesaurus.length > 0 ){
    //   exclusive.value =  this.thesaurus
    //   }
    // let array ;
    // if(exclusive.value.length === 0){
    //   array =[];
    // }
    // else {
    //   if (exclusive.value.length > 0) {
    //     array  =[]
    //     for (let i = 0; i <exclusive.value.length; i++) {
    //       array.push(exclusive.value[i].id)
    //     }
    //     this.thesaurus = exclusive.value
    //   }
    // }
    // if(exclusive?.value&& exclusive.value.length > 0){
    //   this.moodNumber = exclusive.value
    // }
    // else{
    //   this.moodNumber = false
    // }

    this.thesaurus = exclusive.value
  }

  change(event) {
    console.log(event);
  }


  createMoods(){
    /* if(this.addMoodForm.value.mood_name === ''){
      //alert('Kindly fill the required details')
      return
    } */
    let data:any  =[];
    console.log(this.thesaurus)
    // for( let i=0;i<  this.toppingList.length;i++){
    //   for( let j=0;j<  this.addMoodForm.value.thesaurus.value.length;j++)
    //   if(this.toppingList[i].name === this.addMoodForm.value.thesaurus.value[j]){
    //     data.push(this.toppingList[i].id)
    //   }
    // }
    if(this.thesaurus.length === 0){
      data =[];
    }
    else {
      if (this.thesaurus.length > 0) {
        data  =[]
        for (let i = 0; i <this.thesaurus.length; i++) {
          data.push(this.thesaurus[i].id)
        }
      }
    }
    let postUserBody = {
      "name": this.addMoodForm.value.mood_name,
      "thesaurus": this.thesaurus
    }
 
    if(this.list.moodsType === 'Add'){
      let url = environment.apiURL + this.config.getAllMOODSlist;
      this.api.doPOST(url,postUserBody).subscribe(x=>{
        this.loader = false;      
        this.dialogRef.close();
        this.list.getAllMOODS();
      },
      (error) => {
        this.loader = false;
        let data = error.error?.error ? error.error?.error : (error.error?.detail ?error.error?.detail : (error.error?.details ? error.error?.details:``) )
          this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
          });
          this.dialogRef.close();
        //alert(error.message)
      })
    }else if(this.list.moodsType === 'Edit'){      
    let url = environment.apiURL + this.config.getAllMOODSlist + this.list.moodId + '/';
    this.api.doPUT(url,postUserBody).subscribe(x=>{
      this.loader = false;      
      this.dialogRef.close();
      this.list.getAllMOODS();
    },
    (error) => {
      this.loader = false;
      let data = error.error?.error ? error.error?.error : (error.error?.detail ?error.error?.detail : (error.error?.details ? error.error?.details:``) )
        this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
        });
        this.dialogRef.close();
        //alert(error.message)
    })
    }
  }
  toppingList: any = [];
  toppingListName : any =[];
  thesaurusList() {
    let url = environment.apiURL + this.config.getThesaurusDropdown;   
    this.api.doGET(url).subscribe(x=>{
      this.toppingList = x;
      },
      (error)=>{
      })
    }
  onToppingRemoved(data: string) {
    const thesaurusDetail = this.addMoodForm.value.thesaurus.value as string[];
    this.removeFirst(thesaurusDetail, data);
    this.addMoodForm.value.thesaurus.setValue(thesaurusDetail); 
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }
  
}
