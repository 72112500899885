import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../environments/environment';
import { APIListResponse } from '../models/apiresponse.model';
import { TrackComposer } from '../models/trackcomposer.model';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CoWriterService {

    constructor(
        private http: HttpClient,
        private _transferState: TransferState,
        private _platform: Platform,
    ) {}

    getCoWriters(): Observable<TrackComposer[]> {
        let headers = new HttpHeaders();
        const COWRITER_KEY = makeStateKey<any[]>('cowriters-all');
        if (this._transferState.hasKey(COWRITER_KEY)) {
        const coWriters = this._transferState.get<any[]>(COWRITER_KEY, null);
        this._transferState.remove(COWRITER_KEY);
        return of(coWriters.map((s) => new TrackComposer(s)));
        }

        return this.http.get<any[]>(
          environment.apiURL + '/api/v1/cowriters/',
          {
            headers: headers
          }
        ).pipe(
          tap((res) => {
              if (!this._platform.isBrowser) {
                this._transferState.set(COWRITER_KEY, res);
              }
          }),
          map((res) => {
              return res.map((c) => new TrackComposer(c));
          }));
      }
}