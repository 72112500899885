import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MenuListService } from '../../shared/services/menu-list.service';
import { environment } from '../../../environments/environment';
// import { environment } from '../../../environments/environment.prod';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { UserMenuService } from '../../shared/services/user-menu.service';
import {MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
const LogsPageSize = 25;
@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})
export class LogComponent implements OnInit {
  displayedColumns = ['_log' ,'level', 'logged_at'];
  dataSource = ELEMENT_DATA;
  constructor(public list: MenuListService, public config: MenuConfigService, public api: UserMenuService, private datePipe: DatePipe, private sortTb: ChangeDetectorRef) { 
    this.list.getAllLogs();
  }
  primary:any;
  searchString: string = '';
  searchFormControl: FormControl = new FormControl();
  type = "Log Type"
  startDate: Date;
  endDate: Date;
  logStartDate: any = '';
  logEndDate: any = '';
  dateChoices: string[] = ["Log Type","Info", "Error"];
  selectedDateType: string = "";
  log_type: string = "Error";
/*   @ViewChild(MatSort, { static: false })
  set sort(log: MatSort) {
    this.list.dataSourceForLogs.sort = log;
    console.log(this.list.dataSourceForLogs)
    console.log(this.list.dataSourceForLogs.sort)
  } */
  
  private _currentLogsPage = 1;
  public logsSubject = new BehaviorSubject(null);
  set currentLogsPage(p: number) {
    if (!isNaN(p) && p != this._currentLogsPage) {
      this._currentLogsPage = p;
      this.list.getAllLogs();
    }
  }
  get currentLogsPage(): number {
    return this._currentLogsPage;
  }
  get logsNumberOfPages(): number {
    return Math.ceil(this.list.logCount / LogsPageSize);
  }
  @ViewChild(MatSort) sort:MatSort;
  ngOnInit(): void {
  
  }
  
  ngAfterContentChecked(): void{
    this.list.dataSourceForLogs = new MatTableDataSource(this.list.allLogs);
    this.sortTb.detectChanges();
    this.list.dataSourceForLogs.sort = this.sort;
  }
  getStartDate(evt){
    this.logStartDate = this.datePipe.transform(evt,'yyyy-MM-dd');
  }
  getEndDate(evt){
    if(this.type==='Log Type'){
      this.type = ''
    }
    this.logEndDate = this.datePipe.transform(evt,'yyyy-MM-dd');
    if(evt!==null){
      let url = environment.apiURL + this.config.doLogs + '?start_date=' + this.logStartDate + '&end_date=' + this.logEndDate +'&log_type=' + this.type +'&search=' + this.searchString;
         
      this.api.doGET(url).subscribe(x=>{
        this.list.loadingUser = false;
        this.list.allLogs = x['results'] || []
        this.list.dataSourceForLogs = new MatTableDataSource(this.list.allLogs); 
        this.list.logCount = x['count'];
      },
      (error)=>{
        this.list.loadingUser = false;
        //alert(error.message)
      }
      )
    }
  }
  getFindUserLogs(){
    if(this.type==='Log Type'){
      this.type = ''
    }
      this.list.loadingUser = true;
       let url = environment.apiURL + this.config.doLogs + '?search=' + this.searchString + '&start_date=' + this.logStartDate + '&end_date=' + this.logEndDate + '&log_type=' + this.type;
       /* if(this.logStartDate!=undefined || this.logEndDate!=undefined){
          url = environment.apiURL + this.config.doLogs + '?search=' + this.searchString + '&start_date=' + this.logStartDate + '&end_date=' + this.logEndDate + '&log_type=' + this.type;
       }else{
        url = environment.apiURL + this.config.doLogs + '?search=' + this.searchString + '&log_type=' + this.type
       } */
       
    this.api.doGET(url).subscribe(x=>{
      this.list.loadingUser = false;
      this.list.allLogs = x['results'] || []
      this.list.dataSourceForLogs = new MatTableDataSource(this.list.allLogs); 
      this.list.logCount = x['count'];
    },
    (error)=>{
      this.list.loadingUser = false;
      //alert(error.message)
    }
  )
  } 
  getfilterType(evt){
  this.type = evt;
  if(this.type==='Log Type'){
    this.type = ''
  }
    if(this.logStartDate===null){
      this.logStartDate = '';
    }
    if(this.logEndDate===null){
      this.logEndDate = '';
    }
      this.list.loadingUser = true;
      let url = environment.apiURL + this.config.doLogs + '?search=' + this.searchString + '&start_date=' + this.logStartDate + '&end_date=' + this.logEndDate + '&log_type=' + this.type;
       
      this.api.doGET(url).subscribe(x=>{
        this.list.loadingUser = false;
        this.list.allLogs = x['results'] || []
        this.list.dataSourceForLogs = new MatTableDataSource(this.list.allLogs); 
        this.list.logCount = x['count'];
      },
      (error)=>{
        this.list.loadingUser = false;
        //alert(error.message)
      }
      )
  }
  pageLogsTrigger(evt){
    if(this.type==='Log Type'){
      this.type = ''
    }
  let url;
  if(evt!=1){
    /* url = environment.apiURL + this.config.doLogs + '&start_date=' + this.logStartDate===undefined?'':this.logStartDate + '&end_date=' + this.logEndDate===undefined?'':this.logEndDate + '&log_type=' + this.type===undefined?'':this.type; */
    url = environment.apiURL + this.config.doLogs +'?page=' + evt + '&log_type=' + this.type + '&start_date=' + this.logStartDate + '&end_date=' + this.logEndDate + '&search=' + this.searchString
  }else{
    url = environment.apiURL + this.config.doLogs+ '?log_type=' + this.type + '&start_date=' + this.logStartDate + '&end_date=' + this.logEndDate + '&search=' + this.searchString;
  }
  
    this.api.doGET(url).subscribe(x=>{
      //this.list.logLoader = false;
      this.list.allLogs = x['results'] || [];      
      this.list.dataSourceForLogs = new MatTableDataSource(this.list.allLogs);
      this.list.logCount = x['count'];
      },
      (error)=>{
        //this.list.logLoader = false;     
        //alert(error.message)
      })
  
  }
}
export interface PeriodicElement {
  Size: string;
  position: string;
  weight: string;
  
}

const ELEMENT_DATA: PeriodicElement[] = [
  {position: ' Throwback Fusion Stinger.wav Throwback Fusion Stinger.wavThrowback Fusion Stinger.wavThrowback Fusion Stinger.wavThrowback Fusion Stinger.wavThrowback Fusion Stinger.wavThrowback Fusion Stinger.wavThrowback Fusion Stinger.wavThrowback Fusion Stinger.wavThrowback Fusion Stinger.wavThrowback Fusion Stinger.wavThrowback Fusion Stinger.wavThrowback Fusion Stinger.wavThrowback Fusion Stinger.wavThrowback Fusion Stinger.wavThrowback Fusion Stinger.wavThrowback Fusion Stinger.wavThrowback Fusion Stinger.wavThrowback Fusion Stinger.wavThrowback Fusion Stinger.wavThrowback Fusion Stinger.wavThrowback Fusion Stinger.wavThrowback Fusion Stinger.wavThrowback Fusion Stinger.wav', Size: 'error', weight: '8/6/2016 3:24:43', },
  {position: ' Throwback Fusion Stinger.wav Throwback Fusion Stinger.wavThrowback Fusion Stinger.wavThrowback Fusion Stinger.wavThrowback Fusion Stinger.wavThrowback Fusion Stinger.wavThrowback Fusion Stinger.wavThrowback Fusion Stinger.wavThrowback Fusion Stinger.wavThrowback Fusion Stinger.wavThrowback Fusion Stinger.wavThrowback Fusion Stinger.wavThrowback Fusion Stinger.wavThrowback Fusion Stingern Stin', Size: 'error', weight: '8/6/2016 3:24:43', },
  
 
];