<div class="dialog-container">
    <div class="dialog-header">
        <h4 class="dialog-heading">Edit user Permissions</h4>
        <button type="button" class="close-button" (click)="close()">
        <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                    <g id="Genre-Options">
                        <g id="Close" transform="translate(1439.000000, 32.000000)">
                            <path d="M24,0 L0,24" id="Path"></path>
                            <path d="M0,0 L24,24" id="Path"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        </button>
    </div>
<mat-dialog-content>
    <div class="dialog-body form-container ">
        
        <p><strong>{{list.currentUserGroupEditDelete?.first_name}} {{list.currentUserGroupEditDelete?.last_name}}</strong></p>
        <div class="add-to-folder">
            <h6 class="label">Available Permissions<button type="button" class="action" (click)="deselectAll()"><small>Deselect all ({{selectedPermissionCount}})</small></button></h6>
            <span></span>
            <input id="myInput" class="search" (keyup)="getFindPermissionList()" placeholder="Find a permission">
            <ul id="checkBoxUl" class="list">
                <li *ngFor="let client of list.availableUserPermission; let idx = index">       
                    <!-- <mat-checkbox  [checked]= "client.checked" [disabled]="!groupEdit || client.disabled"  (change)="mySelectOpt(client.id, $event, idx)"> -->
                    <mat-checkbox  [checked]= "client.checked" [disabled]="!groupEdit || client.disabled" [ngClass]="{'permission': (!groupEdit || client.disabled)}"   (change)="mySelectOpt(client.id, $event, idx)">
                        <span class="check-text">{{client.name}}</span>
                    </mat-checkbox>
                </li>
            </ul>
        </div>
        <p class="note">Select or deselect or applicable permissions</p>
        <mat-checkbox (change)="selectAll($event)" [checked]="allselectPermission">
            <span class="check-text">Select All</span>
        </mat-checkbox>
        <div class="form-col">
            <button class="form-button" [disabled]="selectedPermissionCount==0" (click)="updatePermission(userDetails)">Update</button>
            <button class="form-button form-button-cancel" (click)="close()">Cancel</button>
        </div>
    </div>
</mat-dialog-content> 
</div>
<div class="loading-overlay" *ngIf="loadingPermissionUpdate">
    <app-loader></app-loader>
</div>