<div class="dialog-container">
    <div class="dialog-header">
        <h4 class="dialog-heading">please confirm</h4>
        <button type="button" class="close-button" (click)="close()">
            <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"
                    stroke-linecap="round" stroke-linejoin="round">
                    <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                        <g id="Genre-Options">
                            <g id="Close" transform="translate(1439.000000, 32.000000)">
                                <path d="M24,0 L0,24" id="Path"></path>
                                <path d="M0,0 L24,24" id="Path"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </button>
    </div>

    <mat-dialog-content>

        <div class="dialog-body">
            <p>Email Template With this name already exists</p>

            <div class="form-col">
                <button class="form-button" (click)="close()">Ok</button>
            </div>
        </div>
    </mat-dialog-content>

</div>