import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild, Output, EventEmitter} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {Observable, of} from 'rxjs';
import {filter, map, startWith} from 'rxjs/operators';
import {MatSelectChange} from '@angular/material/select';
import {Mood} from '../models/mood.model';


@Component({
  selector: 'app-chips-component',
  templateUrl: './chips-component.component.html',
  styleUrls: ['./chips-component.component.scss']
})
export class ChipsComponentComponent implements OnInit, OnChanges {

  @Input() inputList: Observable<any[]>;
  @Input() initialList: Mood[];
  @Input() title: string;

  @Output()
  onValueChanged: EventEmitter<any> = new EventEmitter<any>();

  selectable = true;
  removable = true;
  moodCtrl = new FormControl();
  filteredMoods: Observable<string[]>;
  moods: any[] = [];
  allMoods: any[] = new Array<any[]>();

  @ViewChild('moodInput') moodInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor() {
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our mood
    if ((value || '').trim()) {
      this.moods.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.moodCtrl.setValue(null);
  }

  remove(mood: string): void {
    const index = this.moods.indexOf(mood);

    if (index >= 0) {
      this.moods.splice(index, 1);
    }
    this.filteredMoods = of(this.allMoods.filter(mood => !this.moods.includes(mood)));

  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.moods.push(event.option.value);
    this.moodInput.nativeElement.value = '';
    this.moodCtrl.setValue(null);

    this.filteredMoods = of(this.allMoods.filter(mood => !this.moods.includes(mood)));
  }

  private _filter(value: any): any[] {
    return this.allMoods.filter(mood => mood.id !== value.id || mood.name !== value.name);
  }

  ngOnInit(): void {
    this.moods = this.initialList;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.inputList) {
      this.inputList.subscribe((val) => {
        this.allMoods = val;
        this.filteredMoods = this.moodCtrl.valueChanges.pipe(
          startWith(''),
          map((mood: any) => mood ? this._filter(mood) : this.allMoods.slice())
        );
      });
    }
  }

}
