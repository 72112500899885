<div class="waveform-container">
  <div class="waveform"
    [ngClass]="{panning: panning}"
    (tap)="tapped($event)"
    (panstart)="panstart($event)"
    (pan)="pan($event)"
    (panend)="panend($event)"
    (mousedown)="mousedown($event)"
    (mouseenter)="mouseenter($event)"
    (mousemove)="mousemove($event)"
    (mouseleave)="mouseleave($event)"
    #waveformEl>
    <svg [attr.viewBox]="viewBox">
      <svg:defs>
        <svg:filter [attr.id]="filterId"
          primitiveUnits="objectBoundingBox"
          x="0"
          y="0"
          width="1" height="1">
          <svg:feFlood x="0" y="0"
            [attr.width]="trackProgress" height="1"
            [attr.flood-color]="completeColor" />
          <svg:feComposite operator="atop" in2="SourceGraphic" />
        </svg:filter>
      </svg:defs>
      <svg:g>
        <svg:polygon [attr.points]="points"
          [attr.fill]="fillColor"
          [attr.filter]="trackProgress > 0 ? 'url(' + baseURL + '#' + filterId + ')' : ''"></svg:polygon>
      </svg:g>
    </svg>
    <div class="hover-timestamp"
      *ngIf="hovering || panning"
      [ngStyle]="{left: hoveringPercent, transform: (hoveringProgress > 0.5 ? 'translateX(-100%)' : '')}">
      {{ hoveringTimestamp | duration }}
    </div>
    <div class="hover-bar"
      *ngIf="hovering || panning"
      [ngStyle]="{left: hoveringPercent}"></div>
  </div>
</div>
