<div class="controls" [ngClass]="{'disabledElement': (loadingTracks === true )}">
	<div class="button-controls">
		<button type="button" (click)="skipBack()" [disabled]="!previousItem && current  < 2" class="previous-button">
			<svg width="13px" height="16px" viewBox="0 0 13 16" version="1.1" xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink">
				<g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
					stroke-linejoin="round">
					<g id="Play-Bar_Green" transform="translate(-389.000000, -48.000000)" stroke="#15151C"
						class="inner-path">
						<g id="Skip-Back">
							<g transform="translate(389.000000, 49.000000)">
								<polygon id="Path" points="11.75 14 3 7 11.75 0"></polygon>
								<path d="M1,14 L1,0" id="Path"></path>
							</g>
						</g>
					</g>
				</g>
			</svg>
		</button>
		<button type="button" class="play-button" *ngIf="!isPlaying" (click)="play()" style="margin-left: -37px;">
			<svg width="13px" height="17px" viewBox="0 0 13 17" version="1.1" xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink">
				<g id="Style-Guide" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
					<g id="Components_Track" transform="translate(-426.000000, -388.000000)" class="inner-path"
						stroke="#000000" stroke-width="0.95">
						<g id="Inactive-Track" transform="translate(392.000000, 351.000000)">
							<path
								d="M45.5758457,45.9952239 C45.6280256,45.9604373 45.6728005,45.9156624 45.707587,45.8634826 C45.8531045,45.6452065 45.7941219,45.3502935 45.5758457,45.2047761 L35.4134826,38.4298673 C35.3354555,38.3778493 35.2437769,38.3500912 35.15,38.3500912 C34.8876647,38.3500912 34.675,38.562756 34.675,38.8250912 L34.675,52.3749088 C34.675,52.4686856 34.7027581,52.5603643 34.7547761,52.6383914 C34.9002935,52.8566675 35.1952065,52.9156501 35.4134826,52.7701327 L45.5758457,45.9952239 Z"
								id="Play"></path>
						</g>
					</g>
				</g>
			</svg>
		</button>
		<button type="button" class="pause-button" (click)="pause()" *ngIf="isPlaying" style="margin-left: -37px;">
			<svg width="10px" height="14px" viewBox="0 0 10 14" version="1.1" xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink">
				<g id="Style-Guide" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
					<g id="Components_Track" transform="translate(-426.000000, -746.000000)" fill="#15151C"
						class="inner-path">
						<g id="Track-Playing" transform="translate(393.000000, 704.000000)">
							<path
								d="M33.2499996,42.9499995 L36.4166662,42.9499995 L36.4166662,55.616666 L33.2499996,55.616666 L33.2499996,42.9499995 Z M39.5833328,42.9499995 L42.7499995,42.9499995 L42.7499995,55.616666 L39.5833328,55.616666 L39.5833328,42.9499995 Z"
								id="Pause"></path>
						</g>
					</g>
				</g>
			</svg>
		</button>
		<button type="button" class="next-button" (click)="skipForward()" [disabled]="!nextItem"
			style="margin-left: -37px;">
			<svg width="13px" height="16px" viewBox="0 0 13 16" version="1.1" xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink">
				<g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
					stroke-linejoin="round">
					<g id="Play-Bar_Green" transform="translate(-476.000000, -48.000000)" stroke="#15151C"
						class="inner-path">
						<g id="Skip-Forward">
							<g transform="translate(477.000000, 49.000000)">
								<polygon id="Path" points="0 0 8.75 7 0 14"></polygon>
								<path d="M10.8333333,0.333333333 L10.8333333,14.3333333" id="Path"></path>
							</g>
						</g>
					</g>
				</g>
			</svg>
		</button>
	</div>
</div>


<div style="width: 100%;
top: 28%;
position: inherit;" (click)="getcurrentTime()">
	<div id="waveform"></div>
	<div id="wave-timeline"></div>
	<div style="overflow:hidden" *ngIf="wave" class="controls">
	</div>