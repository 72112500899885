import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Pipe, PipeTransform } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { WaveService } from 'angular-wavesurfer-service';
import { TrackVersion } from '../shared/models/trackversion.model';
import { PlayerService, PlayingItem, PlaylistTrack } from '../shared/services/player.service';
// import { environment } from '../../environments/environment.prod';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Pipe({
  name: 'minuteSeconds'
})
export class MinuteSecondsPipe implements PipeTransform {
  transform(value: number): string {
    let numhours = Math.floor(value / 3600);
    let numminutes = Math.floor((value % 3600) / 60);
    let numseconds = Math.round((value % 3600) % 60);
    let str = '';
    if (numhours) {
      str = this.zeroPad(numhours, 2) + ':' + this.zeroPad(numminutes, 2) + ':' + this.zeroPad(numseconds, 2);
    } else {
      str = this.zeroPad(numminutes, 2) + ':' + this.zeroPad(numseconds, 2);
    }
    return str;
  }
  public zeroPad(num, minLength): string {
    let numStr = '' + num;
    while (numStr.length < minLength) {
      numStr = '0' + numStr;
    }
    return numStr;
  }
}
@Component({
  selector: 'app-playlistwaveform-wave',
  templateUrl: './playlistwaveform-wave.component.html',
  styleUrls: ['./playlistwaveform-wave.component.scss'],
  providers: [WaveService]
})
export class PlaylistwaveformWaveComponent implements OnInit,OnDestroy {

  // @Input()
  // set url(v: string) {
  //   this.nexturl = v;
  // }
  // get url(): string {
  //   return this.nexturl;
  // }

  @Input()
  set name(v: string) {
    this.trackName = v;
  }
  get name(): string {
    return this.trackName;
  }
  @Input()
  trackProgress: number;
  nexturl: string;
  tracks: any;
  wave: WaveSurfer = null;

  set isPlayType(p: boolean) {
    this.preview = p;
  }
  get isPlayType(): boolean {
    return this.preview;
  }
  get previousItem(): PlaylistTrack {
    return this._playerService.playPreviousItem;
  }
  get nextItem(): PlaylistTrack {

    return this._playerService.playNextItem;
  }
  currentTime = 0;
  duration = 0;
  playPause = false
  loadingTracks = true
  @Output()
  collapsePlay = new EventEmitter();


  @Output()
  trackProgressChange = new EventEmitter();
  public _version: TrackVersion;

  @Output() Current = new EventEmitter();
  @Output() Duration = new EventEmitter();


  current = 0;
  end = 0;
  //wave: WaveSurfer = null;
  public _subscriptions: Subscription[] = [];
  preview = false;
  show = false;
  isPlaying = false
  trackName: string = '';
  nextTab:boolean
  trackId: any = "";
url:string=''

  currentPlayingItem: any = {};

  constructor(public cdr: ChangeDetectorRef, public _playerService: PlayerService, public waveService: WaveService, public http:HttpClient) {

  }

  ngOnInit() {

    // console.log(this._playerService.currentPlaylistItems);
    this._subscriptions.push(
      this._playerService.isNextTab.subscribe((p) => {
        this.nextTab = p;
      })
    );
    if(this.nextTab == true && this.wave !== null){
      this.wave.stop()
    }
    else{

    this._playerService.isPlayType.subscribe((p) => {
      if (p === false) {
        if (this.wave !== null) {
          // this.wave.stop();
          // this.isPlaying = false;
          this.pause()
        }
      }
      else {
        this.isPlaying = true;
        // this.currentPlayingItem = this._playerService.currentPlaylistItems.currentItem.track.versions[0];
       // this.currentPlayingItem = this._playerService.currentPlaylistItems.currentItem?.mp3_file ? this._playerService.currentPlaylistItems.currentItem : this._playerService.currentPlaylistItems.currentItem?.versions
       this.currentPlayingItem = this._playerService.currentPlaylistItems.currentItem
       this.currentPlayingItem = this.currentPlayingItem ? this.currentPlayingItem : this.currentPlayingItem[0]
        this._playerService.updateIsPlaying(true);
        if (this.wave == null) {
          this.wave = this.waveService.create({
            container: '#playlistWaveform',
      hideScrollbar: true,
      barGap: 3,
      barWidth: 1,
      waveColor: 'white',
      progressColor:'black',
      cursorColor: '#50e3c2',
      height: 65,
      backend: 'MediaElement'
          });
        }
        // if (this.url !== this.currentPlayingItem.mp3_file) {
        if (this.trackId !== this.currentPlayingItem.id) {
        
          this.wave.stop()
          let url:any = `${environment.apiURL}/api/v1/trackversions/get-version-waveform/?version_id=${this.currentPlayingItem.id}`
          this.http.get(url).subscribe((y:any)=>{
            this.currentTime =0
            this.duration =0
           // this.url = this.currentPlayingItem.mp3_file;
            // this.wave.stop()
            let hostUrl = environment.awsHost + y.mp3_file
          
            let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
       if(isSafari){
      let  result = hostUrl.replace(/.mp4/g, ".mp3");
        y['waveform_file_data'] ? this.wave.load(result,y.waveform_file_data) : this.wave.load(y.mp3_file);
       }
       else{
        y['waveform_file_data'] ? this.wave.load(hostUrl,y.waveform_file_data) : this.wave.load(y.mp3_file);
       }
            // this.wave.load(hostUrl,y.waveform_file_data)
            this.wave.play();
          })
          this.wave.on('ready', () => {
            this._playerService.updateLoader(false);
           // this.isPlaying = true
            this.duration = this.wave.getDuration()
            this.currentTime = this.wave.getCurrentTime();
            this.generateWaveform();
            this.loadingTracks = true
            this.current = 0
            this.trackId = this.currentPlayingItem.id;
            this.cdr.detectChanges();
           // this.wave.setMute(false)
            this.wave.play();
            
          }) 
          //       this.loadingTracks = true
          // this.generateWaveform();
          // this.url = this.currentPlayingItem.mp3_file
          // this.loadingTracks = true
          // this.current = 0
          // this.currentPlayingItem.waveform_file_data ? this.wave.load( environment.awsHost + this.currentPlayingItem.mp3_file,this.currentPlayingItem.waveform_file_data) : this.wave.load(environment.awsHost + this.currentPlayingItem.mp3_file)
         
         
        }
        else{
          // this.wave.play();
          // this.isPlaying = true;
          this.play()
        }
        this.cdr.detectChanges();

        this.wave.on('finish', () => {
          this._playerService.updateEnablePlay(false);
          this.isPlaying = false;
          if (this.wave.isPlaying() === false) {
            this.wave.stop()
            this.isPlaying = false;

            // this.skipForward();
          }
        });
      }
    
    })
  
  }
 
  }

  generateWaveform(): void {
  
      this.wave.on('ready', () => {
        this.loadingTracks = false
        this.url = this.currentPlayingItem.mp3_file === undefined? "":  this.currentPlayingItem.mp3_file;
        this.trackId = this.currentPlayingItem.id;
        if (this.preview === false) {
          this.wave.play();
          this.isPlaying = true;
        }
        // this.wave.setMute(true)
        this.duration = this.wave.getDuration()
        this.Duration.next(this.wave.getDuration())
      });

      this.wave.on('audioprocess', () => {
        this.current = this.wave.getCurrentTime()
        this.Current.next(this.wave.getCurrentTime());
        this.cdr.detectChanges();
        if (this.current >= this.wave.getDuration()) {
          this.skipForward();
        }
      })
      this.wave.on('finish', () => {
        this._playerService.updateEnablePlay(false);
        this.isPlaying = false;
        this.wave.stop()
        this.cdr.detectChanges();
        // console.log(this.isPlaying)
        //if (!this.isPlaying) { return; }

      })
  }


  ngOnDestroy() {
    // this._subscriptions.forEach((s) => {
    //   s.unsubscribe();
    // });

    this._playerService.updateWavePlay(false);
    this._playerService.currentPlaylistItems.isPlay=false;
    this._playerService.currentPlaylistItems.isPause=false;
    this._playerService.play(null, null);
    this._playerService.updatePlayListType(false);
  }

  getcurrentTime() {
    this.current = this.wave.getCurrentTime()
    this.Current.next(this.wave.getCurrentTime());
    this.cdr.detectChanges();
  }

  play() {
    this._playerService.currentPlaylistItems.isPlay = true;
    this._playerService.currentPlaylistItems.isPause = false;
    this.isPlaying = true;
    this.wave.play();
  }

  pause() {
    this._playerService.currentPlaylistItems.isPlay = false;
    this._playerService.currentPlaylistItems.isPause = true;
    this.isPlaying = false;
    this.wave.playPause();
  }

  collapsePlayer() {
    this.wave.stop();
    this.collapsePlay.emit();
    this._playerService.updateEnablePlay(false);
  }

  skipForward() {

    if (this._playerService.currentPlaylistItems.next == -1) {
      return true;
    }

    let setCurrentPlayItem = this._playerService.currentPlaylistItems.playlists[this._playerService.currentPlaylistItems.next];

    this._playerService.setPlayerObjects(this._playerService.currentPlaylistItems.playlists, setCurrentPlayItem);

    this._playerService.updatePlayListType(true);

  }

  skipBack() {

    if (this._playerService.currentPlaylistItems.prev == -1) {
      return true;
    }

    let setCurrentPlayItem = this._playerService.currentPlaylistItems.playlists[this._playerService.currentPlaylistItems.prev];

    this._playerService.setPlayerObjects(this._playerService.currentPlaylistItems.playlists, setCurrentPlayItem);

    this._playerService.updatePlayListType(true);

  }
}
