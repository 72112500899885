import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { MenuConfigService } from 'src/app/shared/services/menu-config.service';
import { UserMenuService } from 'src/app/shared/services/user-menu.service';
import { environment } from 'src/environments/environment';
export interface DialogData {
 type:string
}
@Component({
  selector: 'app-addplaylistcms',
  templateUrl: './addplaylistcms.component.html',
  styleUrls: ['./addplaylistcms.component.scss']
})
export class AddplaylistcmsComponent implements OnInit {

  //Local varaiable
  public allPlaylist: any = [];
  public selectedPlaylist: any = [];
  public selectedPlaylistName:  any = [];
  public loadingUser: boolean = true;
  public listSelectedPlaylist: any =[];

  constructor(private dialogRef: MatDialogRef<AddplaylistcmsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public config: MenuConfigService, public api: UserMenuService,public _DialogService:DialogService) { }

  ngOnInit() {
    this.getPlaylistDropDown();
  }
  getPlaylistDropDown() {
    let newPlaylist = [];
    if (this.data.type === 'playlist') {
      let url = environment.apiURL + this.config.addPlaylistDropdown
      this.api.doGET(url).subscribe(x => {
        this.allPlaylist = x || []
        this.allPlaylist.forEach(x => {
          if (x.is_alive_cms) {
            x['checked'] = true;
            newPlaylist.push(x)
            let data = {
              "playlist_id": x.id,
              "is_alive_cms": true,
              "list": true,
            }
            this.selectedPlaylist.push(data)
            this.listSelectedPlaylist.push(data)
            this.selectedPlaylistName.push({ "id": x.id, "list": true, "name": x.name })
          }
          else {
            x['checked'] = false;
            newPlaylist.push(x)
          }
        })
        this.loadingUser = false;
        this.allPlaylist = newPlaylist
      },
      (error) => {
        if (error) {
          console.log(error)
          let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
          this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
          });
          this.dialogRef.close(true);
        //  alert(error.error.error)
        };
  
      })
    }
    else{
      let url = environment.apiURL + this.config.addPlaylistTrackDropdown
      this.api.doGET(url).subscribe(x => {
        this.allPlaylist = x || []
        this.allPlaylist.forEach(x => {
          if (x.is_playlist_enable) {
            x['checked'] = true;
            newPlaylist.push(x)
            let data = {
              "playlist_id": x.id,
              "is_playlist_enable": true,
              "list": true,
            }
            this.selectedPlaylist.push(data)
            this.listSelectedPlaylist.push(data)
            this.selectedPlaylistName.push({ "id": x.id, "list": true, "name": x.name })
          }
          else {
            x['checked'] = false;
            newPlaylist.push(x)
          }
        })
        this.loadingUser = false;
        this.allPlaylist = newPlaylist
      },
      (error) => {
        if (error) {
          console.log(error)
          let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
          this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
          });
          this.dialogRef.close(true);
        //  alert(error.error.error)
        };
  
      })
    }
  }

  mySelectOpt(id, evt, index) {
    let data
    if (evt.checked) {
      if (this.data.type === 'playlist') {
       data = {
        "playlist_id": id,
        "is_alive_cms": true,
      }
    }
    else{
      data = {
        "playlist_id": id,
        "is_playlist_enable": true,
      }
    }
      this.selectedPlaylist.push(data)
      this.listSelectedPlaylist.push(data)
      this.allPlaylist[index].checked = true;
      this.selectedPlaylistName.push({ "id": id, "name": this.allPlaylist[index].name })
    }
    else {
      this.allPlaylist[index].checked = false;
      const index1 =  this.listSelectedPlaylist.findIndex(i => i.playlist_id == id);
      if( this.listSelectedPlaylist[index1]?.list){
        if (this.data.type === 'playlist') {
      this.listSelectedPlaylist[index1] =  {
        "playlist_id": id,
        "is_alive_cms": false,
      }
    }
    else{
      this.listSelectedPlaylist[index1] =  {
        "playlist_id": id,
        "is_playlist_enable": false,
      }
    }
      }
      else{
        this.listSelectedPlaylist = this.listSelectedPlaylist.filter((x) => x.playlist_id !== id);
      }
        this.selectedPlaylist = this.selectedPlaylist.filter((x) => x.playlist_id !== id);
        this.selectedPlaylistName = this.selectedPlaylistName.filter((x) => x.id !== id);    
    }
  }

  getFindPlaylistList() {
    var input, filter, ul, li, span, i, txtValue;
    input = document.getElementById('myInput');
    filter = input.value.toUpperCase();
    ul = document.getElementById("checkBoxUl");
    li = ul.getElementsByTagName('li');

    for (i = 0; i < li.length; i++) {
      span = li[i].getElementsByTagName("span")[0];
      txtValue = span.textContent || span.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  }
  deselectAll() {
    for (let x of this.allPlaylist) {
      x.checked = false;
    }
    for(let y of this.listSelectedPlaylist){
      y.is_playlist_enable= false;
    }
    this.selectedPlaylist = [];
    this.selectedPlaylistName =[];
  }
  removeSelectPlaylist(playlist) {
    const index = this.allPlaylist.findIndex(i => i.id == playlist.id);
    this.allPlaylist[index].checked = false;
    this.selectedPlaylist = this.selectedPlaylist.filter((x) => x.playlist_id !== playlist.id);
    this.selectedPlaylistName = this.selectedPlaylistName.filter((x) => x.id !== playlist.id);
    const index1 =  this.listSelectedPlaylist.findIndex(i => i.playlist_id == playlist.id);
    if( this.listSelectedPlaylist[index1]?.list){
    if (this.data.type === 'playlist') {
      this.listSelectedPlaylist[index1] =  {
        "playlist_id": playlist.id,
        "is_alive_cms": false,
      }
    }
    else{
      this.listSelectedPlaylist[index1] =  {
        "playlist_id": playlist.id,
        "is_playlist_enable": false,
      }
    }
    }
    else{
      this.listSelectedPlaylist = this.listSelectedPlaylist.filter((x) => x.playlist_id !== playlist.id);
    }
  }
  addSelectPlaylist(){
    let value ={"playlist_info": this.listSelectedPlaylist}, url
    if (this.data.type === 'playlist') {
     url = environment.apiURL + this.config.addPlaylistDropdown
    }
    else{
      url = environment.apiURL + this.config.addPlaylistTrackDropdown
    }
    this.api.doPUT(url, value ).subscribe(x => {
      this.dialogRef.close(true);
      this.selectedPlaylist = [];
      this.selectedPlaylistName =[];
    },
    (error) => {
      if (error) {
        console.log(error)
        let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
        this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
        });
        this.dialogRef.close(true);
      //  alert(error.error.error)
      };

    });
  }
  close() {
    this.dialogRef.close();
    this.selectedPlaylist = [];
    this.selectedPlaylistName =[];
  }
  loadMore(){
    
  }
}
