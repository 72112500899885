import { Artist } from './artist.model';
import { Genre } from './genre.model';
import { MibeModel } from './mibemodel.model';
import { Track } from './track.model';
import { TrackVersion } from "./trackversion.model";
export class PlaylistTracks implements MibeModel {
  position:number;
  id: number;
  track_name: string;
  wav_file:any;
  mp3_file:any;
  playlist_id: number;
  
  created_at: Date;
  approved_at: Date;
  status: string;
  track: Track;
  order: number;
  versions: TrackVersion[]; 
  title:string;
  artist: Artist;
  genre: Genre;
  sub_genre: any;
  top_version:number;
  admin_top_version:number;

  /**
   * Create a PlaylistTracks.
   * @param {any} json - The JSON object with which to construct our PlaylistTracks.
   */
  constructor(json: any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a PlaylistTracks.
   * @param {any} json - The JSON object with which to load our PlaylistTracks.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this.id = json.id;
    this.track_name = json.track_name;
    this.mp3_file=json.mp3_file;
    this.wav_file=json.wav_file;
    this.playlist_id = json.playlist_id;
    this.created_at = json.created_at;
    this.approved_at = json.approved_at;
    this.status = json.status;
    this.track = new Track(json.track);
    this.order = json.order;
    this.versions = json.versions;
    this.artist = json.artist;
    this.genre = json.genre;
    this.sub_genre = json.sub_genre;
    this.title = json.title
    this.top_version = json.top_version;
    this.admin_top_version = json.admin_top_version;
  }
  /**
   * Returns a JSON representation of our PlaylistTracks
   * @return {any} - The JSON representation of our PlaylistTracks
   */
  toJSON() {
    var json: any = {
      id: this.id,
      playlist_id: this.playlist_id,
      track: this.track.toJSON(),
      order: this.order,
    };
    return json;
  }
}
