<div class="main">
  <div class="sidebar">
    <div class="top-holder">
      <div class="tabs">
        <span
          class="tab"
          [ngClass]="{'active': activeTab === 'clients'}"
          (click)="switchTab('clients'); _emailTemplateService.usertype='CLIENT'"
        >Clients</span>
        <span
          class="tab"
          (click)="switchTab('artists'); _emailTemplateService.usertype='ARTIST'"
          [ngClass]="{'active': activeTab === 'artists'}"
        >Artists</span>
      </div>
      <form
        action="#"
        class="search-filter"
        #searchForm="ngForm"
        (ngSubmit)="$event.preventDefault();submitSearch()"
        [ngClass]="{'search-filter-focused': searchFilterFocused}">
        <label for="search-filter-field" class="icon" (click)="submitSearch()">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"><path fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="20" d="M6 1a5 5 0 110 10A5 5 0 016 1zM13.002 13.002L9.535 9.535"/></svg>
        </label>
        <input
          [(ngModel)]="searchString"
          name="searchString"
          type="text"
          class="txt"
          id="search-filter-field"
          (focus)="searchFilterFocused = true"
          placeholder="Find a template"/>
      </form>
      <div class="templates-info">
        <strong>{{totalTemplates}} Templates</strong>
        <button class="button" (click)="_emailTemplateService.templatetype = 'new';displayNewTemplateDialog()">+ New Template</button>
      </div>
    </div>
    <div class="templates-holder" >
      <app-loader *ngIf="loadingTemplates"></app-loader>
      <ul class="templates" *ngIf="!loadingTemplates">
        <li class="template" [ngClass]="{'active': currentTemplate && template && newCurrenttemplate == template.id}" *ngFor="let template of templates; trackBy:templateTrackBy" (click)="_emailTemplateService.templatetype = 'edit';currentTemplate = template; displayNewTemplateDialog(); newCurrenttemplate = template.id">
          {{template.getDisplayName()}}
        </li>
      </ul>
    </div>
    <div class="templates-pagination" *ngIf="!loadingTemplates && templatesNumberOfPages > 1">
      <button class="button" (click)="currentTemplatesPage = currentTemplatesPage-1" [disabled]="currentTemplatesPage <= 1">
        <svg width="10px" height="16px" viewBox="0 0 10 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
            <g id="Number-Pagination" transform="translate(1.000000, -9.000000)" class="inner-stroke" stroke="#FFFFFF">
              <g id="Stroke-1">
                <polyline points="8 25 0 17 8 9"></polyline>
              </g>
            </g>
          </g>
        </svg>
        Prev
      </button>
      <input type="text" class="txt" [(ngModel)]="currentTemplatesPage">
      <button class="button btn-next" (click)="currentTemplatesPage = currentTemplatesPage+1" [disabled]="templatesNumberOfPages <= currentTemplatesPage">
        Next
        <svg width="10px" height="16px" viewBox="0 0 10 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
            <g id="Number-Pagination" transform="translate(-343.000000, -9.000000)" class="inner-stroke" stroke="#FFFFFF">
              <g id="Stroke-1">
                <polyline transform="translate(348.000000, 17.000000) scale(-1, 1) translate(-348.000000, -17.000000) " points="352 25 344 17 352 9"></polyline>
              </g>
            </g>
          </g>
        </svg>
      </button>
    </div>
  </div>

  <div class="user-list">
    <div class="header">
      <h3>{{this.currentTemplate ? this.currentTemplate.getDisplayName():null}}</h3>
      <div class="btns">
        <button class="button" (click)="_emailTemplateService.templatetype = 'duplicate';displayDuplicateTemplateDialog()">Duplicate Template</button>
        <button class="button" (click)="displayDeleteTemplateDialog()">Delete Template</button>
      </div>
    </div>
    <!-- <app-loader *ngIf="loadingUsers"></app-loader> -->
    <!-- <div class="user-list">
      <app-user-email-template-list
          class="users"
          *ngIf="!loadingUsers"
          [dataSource]="users"
          [sortValue]="ordering"
          (listChange)="loadUsers();loadTemplates()"
          (sortChange)="sortChange($event)"
          [(checkedUsers)]="checkedUsers"
          [templateId]="currentTemplate ? currentTemplate.id:null"
      ></app-user-email-template-list>

      <app-pagination
          *ngIf="usersNumberOfPages > 1"
          [page]="currentUsersPage"
          (pageChange)="currentUsersPage = $event"
          [numberOfPages]="usersNumberOfPages"
      ></app-pagination>
    </div> -->
  </div>
</div>
