import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../environments/environment';
import { Mood } from '../models/mood.model';
import { APIListResponse } from '../models/apiresponse.model';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { EmailGroup } from '../models/email-group.model';
import { User } from '../models/user.model';


export interface GetEmailGroupOptions {
  type?: 'CLIENT'|'ARTIST'|'BOTH'|string;
  limit?: number;
  offset?: number;
  searchString?: string;
  page?: number;
}

@Injectable({
  providedIn: 'root'
})
export class EmailGroupService {

  checkedtype = 'CLIENT';
  constructor(
    private http: HttpClient,
    private _platform: Platform,
    private _transferState: TransferState
  ) {
  }


  getAllGroups(options:GetEmailGroupOptions, from_page:any): Observable<APIListResponse<EmailGroup>> {
    let params = {};
    let grpParam = {};
    if (options.type != null) {
      params['type'] = options.type;
    }
    if (options.limit) {
      params['limit'] = options.limit;
    }
    if (options.offset) {
      params['offset'] = options.offset;
    }
    if (options.searchString) {
      params['search'] = options.searchString;
    }
    if (options.page) {
      params['page'] = options.page;
    }
    if (from_page == "user_component") {
      if (options.type != null) {
        grpParam['type'] = options.type;
      }
      return this.listAllGroups(grpParam);
    } else {
      return this.getGroups(params);
    }
  }

  listAllGroups(params): Observable<any>  {
    let p = new HttpParams({ fromObject: params });
    return this.http.get<Object>(
      environment.apiURL + '/api/v1/admin/users/get-emailgroup-dropdown/',
      {
        params: p
      }
    ).pipe(
      map((res) => {
        return {
          results: res,
          // count: res
        }
      })
    );
  }

  getGroup(groupId: number): Observable<EmailGroup> {
    // Check if moods are cached from server
    const GROUP_KEY = makeStateKey<Object>('group-' + groupId);
    if (this._transferState.hasKey(GROUP_KEY)) {
      const group = this._transferState.get<Object>(GROUP_KEY, null);
      this._transferState.remove(GROUP_KEY);
      return of(new EmailGroup(group));
    }
    let headers = new HttpHeaders();
    return this.http.get<Object>(
      environment.apiURL + '/api/v1/admin/emailgroup/' + groupId + '/',
      {
        headers: headers
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the mood
        if (!this._platform.isBrowser) {
          this._transferState.set(GROUP_KEY, res);
        }
      }),
      map((res) => new EmailGroup(res))
    );
  }

  createGroup(name: string, type: string): Observable<EmailGroup> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiURL + '/api/v1/admin/emailgroup/',
      {
        name: name,
        type: type
      },
      {
        headers: headers
      }
    ).pipe(map((res) => new EmailGroup(res)));
  }

  duplicateGroup(name: string, type:string,group:number): Observable<EmailGroup> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiURL + '/api/v1/admin/emailgroup/'+ group + '/duplicate/ ',
      {
        name: name,
        type: type
      },
      {
        headers: headers
      }
    ).pipe(map((res) => new EmailGroup(res)));
  }

  deleteGroup(group:number): Observable<void> {
    let headers = new HttpHeaders();
    return this.http.delete<any>(
      environment.apiURL + '/api/v1/admin/emailgroup/' + group + '/',
      {
        headers: headers
      }
    );
  }

  removeUsersFromGroup(users:any, groupId:number): Observable<EmailGroup> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<any>(
      `${environment.apiURL}/api/v1/admin/emailgroup/${groupId}/removeusers/`,
      {
        users: users
      },
      {
        headers: headers
      }
    ).pipe(map((res) => new EmailGroup(res)));
  }

  private getGroups(params: {[param: string]: string | string[]}): Observable<APIListResponse<EmailGroup>> {
    let p = new HttpParams({ fromObject: params });
    // Check if moods are cached from server
    const GROUPS_KEY = makeStateKey<APIListResponse<Object>>('groups-' + p.toString());
    if (this._transferState.hasKey(GROUPS_KEY)) {
      const groups = this._transferState.get<APIListResponse<Object>>(GROUPS_KEY, null);
      this._transferState.remove(GROUPS_KEY);
      return of({
        next: groups.next,
        previous: groups.previous,
        count: groups.count,
        results: groups.results.map((g) => new EmailGroup(g))
      });
    }
    let headers = new HttpHeaders();
    return this.http.get<APIListResponse>(
      environment.apiURL + '/api/v1/admin/emailgroup/',
      {
        headers: headers,
        params: p
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the moods
        if (!this._platform.isBrowser) {
          this._transferState.set(GROUPS_KEY, res);
        }
      }),
      map((res) => {
        return {
          next: res.next,
          previous: res.previous,
          count: res.count,
          results: res.results.map((g) => new EmailGroup(g))
        }
      })
    );
  }
}
