<div class="edit-track" style="width: 100%;" *ngIf="!loading">
    
    <app-track-preview class="music-player" *ngIf="userDetail > 0 && detail > 0" [track]="track" [version]="version" [ngClass]="{hide: !track || !version || replaceTrackVersion}" (collapse)="toggleMusicPlayerCollapsed()"></app-track-preview>
  
    <!--Track detail-->
    <app-track-version-new-detail *ngIf="userDetail > 0 && detail > 0 && track && version" [(track)]="track" [(version)]="version"></app-track-version-new-detail>
 
    <!--Manage co writer-->
    <app-manage-cowriters *ngIf="userDetail > 0 && detail > 0" [(composers)]="composers" [ngClass]="{hide: replaceTrackVersion}"></app-manage-cowriters>

    <!--Track status-->
    <app-track-status *ngIf="(userDetail > 0 && detail > 0) && version" [ngClass]="{hide: replaceTrackVersion}" [(version)]="version" [(track)]="track"></app-track-status>
  
     <div class="separator-line"></div>

    <!--Track description-->
     <app-new-track-description *ngIf="userDetail > 0 && detail > 0" [(track)]="track" [(version)]="version"></app-new-track-description>
  
    <div *ngIf="!replaceTrackVersion">
      <button class="save-version-btn" *ngIf="userDetail > 0 && detail > 0" mat-button  [disabled]="track.isValid" (click)="saveVersion(1)">Save Track Version</button>
    </div>
   <div *ngIf="replaceTrackVersion">
    <button class="save-version-btn" *ngIf="userDetail > 0 && detail > 0" [disabled]="uploadFile === false || track.isValid || loading  === true" mat-button (click)="replaceTrack()">Replace Track Version</button>
   </div>
  
  </div>
  <div class="loading-overlay" *ngIf="loading">
    <app-loader></app-loader>
  </div>