import { Component, OnInit, ViewChild, ChangeDetectorRef  } from '@angular/core';
import { DialogService } from '../../shared/services/dialog.service';
import { ReportsDialogueComponent } from '../reports-dialogue/reports-dialogue.component';
import { MenuListService } from '../../shared/services/menu-list.service';
import { environment } from '../../../environments/environment';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { UserMenuService } from '../../shared/services/user-menu.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
const reportsPageSize = 20;

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  displayedColumns = ['file_name', 'file_size', 'file_format', 'created_at', 'artist'];
  constructor(public dialog: DialogService, public list: MenuListService, private cdr: ChangeDetectorRef, public config: MenuConfigService, public api: UserMenuService) {
    
   }
   private _currentReportsPage = 1;
   set currentReportsPage(p: number) {
     if (!isNaN(p) && p != this._currentReportsPage) {
       this._currentReportsPage = p;
       this.list.getAllReports();
     }
   }
   get currentReportsPage(): number {
     return this._currentReportsPage;
   }
   get reportsNumberOfPages(): number {
     return Math.ceil(this.list.allReportsDetails.count / reportsPageSize);
   }
   @ViewChild(MatSort) sort:MatSort;
  /* set sort(report: MatSort) {
    this.list.dataSourceForReports = new MatTableDataSource(this.list.allReports);
    this.list.dataSourceForReports.sort = report; 
    console.log(this.list.dataSourceForReports) 
  } */
  ngOnInit(): void {
    this.list.getAllReports();
  }
  ngAfterContentChecked(): void{
    // this.list.dataSourceForReports = new MatTableDataSource(this.list.newReports);
    this.cdr.detectChanges();
    this.list.dataSourceForReports.sort = this.sort;
  }
  downloadreports(){
    this.dialog.displayMenuDialog(ReportsDialogueComponent)
  }
  pageReportsTrigger(evt){
    //this.list.logLoader = true;
    let url;
    if(evt!=1){
      url = environment.apiURL + this.config.getAllReports+'?page='+evt;
    }else{
      url = environment.apiURL + this.config.getAllReports;
    }
  
    this.api.doGET(url).subscribe(x=>{
      this.list.allReportsDetails = x;
      this.list.allReports = x['results'] || [];   
      for(var i=0;i<this.list.allReports.length;i++){
        this.list.newReports.push(this.list.allReports[i].uploads)
        /* this.newReports.push(this.allReports[i].track.artist) */
      }   
      this.list.dataSourceForReports = new MatTableDataSource(this.list.allReports);
      },
      (error)=>{
      })
  
  }
}
export interface PeriodicElement {
  Size: string;
  position: string;
  weight: string;
  symbol: string;
  Artistname: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {position: 'Throwback Fusion Stinger.wav', Size: '3124kb', weight: 'wav', symbol: 'H', Artistname: 'Johnny Longname'},
  {position: 'Throwback Fusion Stinger.wav', Size: '3124kb', weight: 'aabbcc', symbol: 'H', Artistname: 'abc'},
  {position: 'Throwback Fusion Stinger.wav', Size: '3124kb', weight: 'aabbcc', symbol: 'H', Artistname: 'abc'},
 
];