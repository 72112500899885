<div class="users">
    <div class="header-wrapper">
        <div class="header">
            <div class="filters top-filters">
                <div class="tabs">
                    <span
                      class="tab"
                      (click)="tabChange('clients')"
                      [ngClass]="{'active': activeTab === 'clients'}">Clients</span>
                    <span
                      class="tab"
                      [ngClass]="{'active': activeTab === 'artists'}"
                      (click)="tabChange('artists')">Artists</span>
                </div>
                <div class="search">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"><path fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="20" d="M6 1a5 5 0 110 10A5 5 0 016 1zM13.002 13.002L9.535 9.535"/></svg>
                    <input [ngModel]="searchString" [formControl]="searchFormControl" placeholder="Search"/>
                </div>
                <div class="date">
                    <app-range-datepicker
                        [enableSelect]="true"
                        [rangeFilterList]="dateChoices"
                        [selectedRangeFilter]="selectedDateType"
                        (selectedRangeFilterChange)="updateDateType($event)"
                        [startDate]="startDate"
                        (startDateChange)="updateStartDate($event)"
                        [endDate]="endDate"
                        (endDateChange)="updateEndDate($event)"
                    ></app-range-datepicker>
                </div>
            </div>
            <div class="actions">
                <button class="button" (click)="createAccount()">Create Account</button>
                <button class="button" (click)="addSelectedToGroup()" [disabled]="!canAddSelectedToGroup()">Add Selected To Group</button>
            </div>
        </div>
    </div>
    <div class="body">
        <div class="list" *ngIf="!loading">
            <app-user-client-list
                [dataSource]="clients"
                class="list"
                [sortValue]="ordering"
                (sortChange)="sortChange($event)"
                [(checkedClients)]="checkedClients"
                (listChange)="reloadList()"
                *ngIf="activeTab == 'clients'"></app-user-client-list>
            <app-user-artist-list
                [dataSource]="artists"
                class="list"
                [sortValue]="ordering"
                (sortChange)="sortChange($event)"
                [(checkedArtists)]="checkedArtists"
                (listChange)="reloadList()"
                *ngIf="activeTab == 'artists'"></app-user-artist-list>
                <div *ngIf="(activeTab == 'clients' && totalClients == 0) || (activeTab == 'artists' && totalArtists == 0)" class="no-data">No data available</div>
        </div>
        <app-loader *ngIf="loading"></app-loader>
        <app-pagination
          *ngIf="numberOfPages > 1"
          [page]="currentPage"
          (pageChange)="pageChange($event)"
          [numberOfPages]="numberOfPages"
          ></app-pagination>
    </div>
</div>