
<div class="profile-info">
    <div class="menu-user-info">
    <div class="section general">
            <div class="title">
                <h3>Payment methods</h3>
            </div>
            <div class="payment-method">
                <div class="payment-inline">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 5.69l5 4.5V18h-2v-6H9v6H7v-7.81l5-4.5M12 3L2 12h3v8h6v-6h2v6h6v-8h3L12 3z"/></svg>
                    <span>Check </span>
                    <span class="pay-type">Payment</span>
                </div>
                <div class="payment-inline">
                    <img class="paypal-icon" src="assets/img/paypal.png">
                </div>
                <div class="payment-inline">
                    <img class="paypal-icon" src="assets/img/venmo.jpg">
                </div>
            </div>
    </div><div class="sep-line"></div>
    <div class="section general">
        <div class="title">
            <h3>Mailing Information</h3>
        </div>
        <div class="grid-container">
      
            <div class="grid-item">
                <mat-form-field appearance="fill">
                    <mat-label>First name</mat-label>
                    <input matInput >
                </mat-form-field>
            </div>
            <div class="grid-item">
                <mat-form-field appearance="fill">
                    <mat-label>Last name</mat-label>
                    <input  matInput >
                </mat-form-field>
            </div>
        </div>
        <div class="grid-card">
            <div class="grid-item">
                <mat-form-field appearance="fill">
                    <mat-label>Address</mat-label>
                    <input matInput >
                </mat-form-field>
            </div>
        </div>
        <div class="grid-card">
            <div class="grid-item">
                <mat-form-field appearance="fill">
                    <mat-label>Address Line 2</mat-label>
                    <input matInput >
                </mat-form-field>
            </div>
        </div>
        <div class="grid-card">
            <div class="grid-item">
                <mat-form-field appearance="fill">
                    <mat-label>city</mat-label>
                    <input matInput >
                </mat-form-field>
            </div>
        </div>
        <div class="grid-container">
      
            <div class="grid-item">
                <mat-form-field appearance="fill">
                    <mat-label>state</mat-label>
                    <mat-select  >
                        <mat-option ></mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="grid-item">
                <mat-form-field appearance="fill">
                    <mat-label>zip</mat-label>
                    <input  matInput >
                </mat-form-field>
            </div>
        </div>
        <button class="btn save-btn"  mat-button >Save</button>
    </div><div class="sep-line"></div>
    <div class="section general">
        <div class="title">
            <h3>Saved Addresses</h3>
        </div>
        <div class="saved-address">
            <h5> {{userDetails.first_name}} {{userDetails.last_name}}<span class="del-reqire"><small>Delete</small></span></h5>
            <p> {{userDetails.address1}}<br>
               {{userDetails.country}}, {{userDetails.city}}, {{userDetails.postal_code}}</p>
                <div class="meta-data edit-track">
                    <mat-checkbox class="checkbox-margin" >Make default</mat-checkbox>
                </div>
        </div>
    </div>
    </div>
</div>
         