import { version } from 'process';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { of, ReplaySubject, Subscription } from 'rxjs';
import { startWith, debounceTime, distinctUntilChanged, tap, switchMap, map } from 'rxjs/operators';
import { Playlist } from '../models/playlist.model';
import { Track } from '../models/track.model';
import { TrackVersion } from '../models/trackversion.model';
import { PlaylistService } from '../services/playlist.service';
import { DialogService } from '../services/dialog.service';

const playlistLimit = 30;

export interface DialogData {
  track: Track;
  version: TrackVersion;
  trackid:any;
}

@Component({
  selector: "app-add-song-playlist-dialog",
  templateUrl: "./add-song-playlist-dialog.component.html",
  styleUrls: ["./add-song-playlist-dialog.component.scss"],
})
export class AddSongPlaylistDialogComponent implements OnInit, OnDestroy {
  constructor(
    private dialogRef: MatDialogRef<AddSongPlaylistDialogComponent>,
    private _playlistService: PlaylistService,public _DialogService:DialogService,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}
  newPlaylistFolder = true;

  loading = true;

  playlists: Playlist[] = [];
  modifiedPlaylists: Playlist[] = [];
  tempRemovedPlaylist:Playlist[]=[];
  checkedPlaylists: Playlist[] = [];
  playlistList:any;
  searchPlaylist = '';
  searchControl = new FormControl();
  // playlistOptions: ReplaySubject<Playlist[]> = new ReplaySubject<Playlist[]>(1);
  searchingPlaylists = false;
  playlistOptions: ReplaySubject<any> = new ReplaySubject<any>(1);;
  playlistcheckbox:any = [];
  editedval:any;
  private _subscriptions: Subscription[] = [];
trackId:any;
  ngOnInit(): void {
    if (!this.data) return;
    this._playlistService.getAddsongtoplaylist().subscribe(res => {
      this.playlistList=res;
      this.playlistOptions.next(res);
      this.loading = false;

      if(this.data.trackid){
       this.trackId=this.data.trackid;
      }else{
        this.trackId=this.data.version.id;
      }
      this._playlistService.getassignedplaylistfortrack(this.trackId).subscribe(res => {
        this.playlistcheckbox=res;
      })
    })
    // this._playlistService
    //   .getAllPlaylists({ containsTrackVersionId: this.data })
    //   .subscribe(
    //     (res) => {
    //       this.playlists = res.results;
    //       console.log("playlistsss---", res);
    //       this.checkedPlaylists = res.results;
    //       this.modifiedPlaylists = res.results
    //       // this.playlistOptions.next(res.results);
    //         this._playlistService
    //           .getAllPlaylists({ limit: playlistLimit })
    //           .subscribe(
    //             (response) => {
    //               this.playlists = response.results;
    //               this.playlistOptions.next(response.results);
    //               this.loading = false;
    //             },
    //             (error) => {
    //               console.log(error);
    //               //alert("An error ocurred while getting the playlists. Check your connections and try again");
    //             }
    //           );
    //       if (res.results && res.results.length > 0) {
    //         this.loading = false;
    //       } 
          
          
    //     },
    //     (error) => {
    //       console.log(error);
    //       //alert("An error ocurred while getting the playlists for the folder. Check your connections and try again");
    //     }
    //   );
    this._subscriptions.push(
      this.searchControl.valueChanges
        .pipe(
          startWith(""),
          debounceTime(150),
          distinctUntilChanged(),
          tap(() => {
            this.searchingPlaylists = true;
          }),
          switchMap((v) => {
            if (!v || v.length <= 2) {
              return of(this.playlists);
            }
            return this._playlistService
              .getAllPlaylists({ search: v, limit: playlistLimit })
              .pipe(
                map((res) => {
                  let ret = res.results;
                  for (let playlist of this.modifiedPlaylists) {
                    if (!ret.some((p) => p.id == playlist.id)) {
                      ret.push(playlist);
                    }
                  }
                  return ret;
                })
              );
          }),
          tap(() => {
            this.searchingPlaylists = false;
          })
        )
        .subscribe((f) => {
          this.playlistOptions.next(f);
          if (this.searchPlaylist.length == 0) {
            this.playlistOptions.next(this.playlistList)
          }
        })
    );
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
    this._subscriptions = [];
  }

  close() {
    this.dialogRef.close(false);
  }

  isPlaylistChecked(playlist: Playlist) {
    return this.playlistcheckbox.some((p) => p.id == playlist.id);
  }
  isModifiedChecked(playlist: Playlist) {
    return this.modifiedPlaylists.some((p) => p.id == playlist.id);
  }
  playlistChecked(checked: boolean, playlist: Playlist) {
    // this.toggleModified(playlist);
    if (checked) {
      // if (this.isPlaylistChecked(playlist)) {
      //   return;
      // } else {
      //   this.checkedPlaylists.push(playlist);
      // }
      this.playlistcheckbox.push(playlist);
      // if (!this.playlists.some(c=>c.id == playlist.id)) {
      //   this.playlists.push(playlist);
      // }
      // return;
    } else {
      let i = this.playlistcheckbox.findIndex((p) => p.id == playlist.id);
      if (i >= 0) {
        this.playlistcheckbox.splice(i, 1); 
      }
    }
    
}
 
  toggleModified(playlist: Playlist) {
   
    if (this.isModifiedChecked(playlist)) {
       this.tempRemovedPlaylist.push(playlist)
    
    } else {
      this.modifiedPlaylists.push(playlist);
    }
    console.log('mod-playlst',this.modifiedPlaylists)
  }

  // toggleModified(playlist: Playlist) {
  //   let i = this.modifiedPlaylists.findIndex((p) => p.id == playlist.id);
  //   if (i >= 0) {
  //     this.modifiedPlaylists.splice(i, 1);
  //   } else {
  //     this.modifiedPlaylists.push(playlist);
  //   }
  // }

  playlistTrackBy(i: number, playlist: Playlist) {
    return playlist.id;
  }

  canSubmit(): boolean {
    return this.modifiedPlaylists && this.modifiedPlaylists.length >= 0;
  }
  save() {
    if (!this.canSubmit()) return;
    this.loading = true;
    
    let playlists = [];
    if (this.playlistcheckbox && this.playlistcheckbox.length > 0) {
      let added:number[] = [];
      let removed:number[] = [];
      this.playlistcheckbox.forEach(playlist => {
        if (this.isPlaylistChecked(playlist)) {
          if(playlist.id){
            added.push(playlist.id)
          }
          added.push(playlist.id);
        } else {
          console.log("playlist.id"+playlist.id)
          if (playlist.id) {
            removed.push(playlist.id)
          }
          removed.push(playlist.id);
        }
      });
      if (removed.length > 0 || added.length > 0) {

        if(removed.length > 0){
          this.editedval=removed;
        }else{
          this.editedval=added;  
        }
      
        this._playlistService.addTrackVersionsToPlaylist(this.trackId, this.editedval).subscribe(f=> {
          // this.foldersHandled.push(f.id);
              this.closeIfDone();

        }, (error) => {
          console.log(error);
          // alert(`An error ocurred while removing versions ${removed.toString()} from the playlist. Check connection and try again`);
          let data = error.error?.error ? error.error?.error : (error.error?.detail ?error.error?.detail : (error.error?.details ? error.error?.details:``) )
          this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
          });
          this.close();
        })
      }
    }
  }
  // save() {
  //   if (!this.canSubmit()) return;
  //   this.loading = true;
  //   if(this.tempRemovedPlaylist.length>=1){
  //     this.tempRemovedPlaylist.forEach((elem,index)=>{
  //       let i = this.modifiedPlaylists.findIndex((p) => p.id == elem.id);
  //       this.modifiedPlaylists.splice(i,1)
  //     })
  //   }
  //   let playlists = [];
  //   this.modifiedPlaylists.forEach((element) => {
  //     playlists.push(element.id);
  //   });
  //   if (
  //     playlists.length >= 0 &&
  //     playlists.length == this.modifiedPlaylists.length
  //   ) {
  //     this._playlistService
  //       .addTrackVersionsToPlaylist(this.data.version.id, playlists)
  //       .subscribe(
  //         (p) => {
  //           this.loading = false;
  //           this.dialogRef.close(true);
  //         },
  //         (error) => {
  //           console.log(error);
  //           //alert(`An error ocurred while adding the track version ${this.data.version.id} to the playlist ${playlist.id}. Check your connections and try again`);
  //         }
  //       );
  //   }
  // }

  // save() {
  //   if (!this.canSubmit()) return;
  //   this.loading = true;
  //   this.playlistHandled = [];
  //   this.modifiedPlaylists.forEach((playlist) => {
  //     if (this.isPlaylistChecked(playlist)) {
  //       this._playlistService
  //         .addTrackVersionsToPlaylist(this.data.version.id, [playlist.id])
  //         .subscribe(
  //           (p) => {
  //             this.playlistHandled.push(p.id);
  //             this.closeIfDone();
  //           },
  //           (error) => {
  //             console.log(error);
  //             //alert(`An error ocurred while adding the track version ${this.data.version.id} to the playlist ${playlist.id}. Check your connections and try again`);
  //           }
  //         );
  //     } else {
  //       this._playlistService
  //         .addTrackVersionsToPlaylist(this.data.version.id, [playlist.id])
  //         .subscribe(
  //           (p) => {
  //             this.playlistHandled.push(p.id);
  //             this.closeIfDone();
  //           },
  //           (error) => {
  //             console.log(error);
  //             //alert(`An error ocurred while removing the track version ${this.data.version.id} from the playlist ${playlist.id}. Check your connections and try again`);
  //           }
  //         );
  //     }
  //   });
  // }

  private playlistHandled = [];
  private closeIfDone() {
    // if (this.playlistHandled.length != this.modifiedPlaylists.length) return;
    this.dialogRef.close(true);
  }
}
