<div class="user-mgmt">
    <div class="header">
        <ul class="navigation">
            <li class="nav-item" [ngClass]="{'nav-item-active': isActive('users')}">
                <a class="nav-link" [routerLink]="['/user-mgmt', 'users']">Users</a></li>
            <li class="nav-item" [ngClass]="{'nav-item-active': isActive('groups')}">
                <a class="nav-link" [routerLink]="['/user-mgmt', 'groups']">Groups</a></li>
            <li class="nav-item" [ngClass]="{'nav-item-active': isActive('templates')}">
                <a class="nav-link" [routerLink]="['/user-mgmt', 'templates']">Templates</a>
            </li>
        </ul>
    </div>
    <div class="body">
        <app-user-mgmt-users *ngIf="isActive('users')"></app-user-mgmt-users>
        <app-user-mgmt-groups *ngIf="isActive('groups')"></app-user-mgmt-groups>
        <app-user-mgmt-templates *ngIf="isActive('templates')"></app-user-mgmt-templates>
    </div>
</div>