import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MenuListService } from '../services/menu-list.service';
import { CONTAINER_DATA, DialogConfig, SliderDialogService } from '../services/slider-dialog.service';
import { Filters, TrackService } from '../services/track.service';
import { UserMenuService } from '../services/user-menu.service';
import { environment } from 'src/environments/environment';
import { Track } from '../models/track.model';
import { TrackVersion } from '../models/trackversion.model';
import { TrackComposer } from '../models/trackcomposer.model';
import { PlayerService } from '../services/player.service';
import { DialogService } from '../services/dialog.service';
import { forkJoin } from 'rxjs';



@Component({
  selector: 'app-edit-new-track',
  templateUrl: './edit-new-track.component.html',
  styleUrls: ['./edit-new-track.component.scss']
})
export class EditNewTrackComponent implements OnInit {

  track: Track;
  version: TrackVersion;
  composers: TrackComposer[];
  detail: any = [];
  loading: boolean = false;
  userDetail: any = [];
  trackId: number;
  @Output()
  filtersChange = new EventEmitter<Filters>();

  constructor(
    public api: UserMenuService,
    public trackServ: TrackService, public list: MenuListService, private _sliderDialogService: SliderDialogService,
    @Inject(CONTAINER_DATA) public componentData: DialogConfig, private trackService: TrackService,
    public _DialogService: DialogService,
    private _playerService: PlayerService,) {
    this.track = componentData.data.track;
    this.trackId = componentData.data.track.id;
    this.version = componentData.data.version;
  }

  ngOnInit() {
   
    this.loading = true;
    let trackUrl = environment.apiURL + `/api/v1/track/${this.version.id}/get-track-info/`;
    this.api.doGET(trackUrl).subscribe(x => {
      this.list.trackDetail = x;
      this.track = this.list.trackDetail;
      this.track.genre = this.list.trackDetail.track.genre
      this.track.sub_genre = this.list.trackDetail.track.sub_genre
      this.version = this.list.trackDetail;
      var keys = Object.keys(this.list.trackDetail).length
      this.detail = keys;
      let id = this.track.artist.user_id
      let url = environment.apiURL + `/api/v1/admin/users/${id}/`;
      this.api.doGET(url).subscribe(x => {
        this.list.userDetail = x
        var keys = Object.keys(this.list.userDetail).length
        this.userDetail = keys;
      })
      if (this.track) {
        if ((this.track.genre) && (this.track.moods[0] || this.track.moods[1] || this.track.moods[2]) && this.version.tempo && this.version.track_ending && this.version.calculated_key) {
          this.track.isValid = false;
        }
        else {
          this.track.isValid = true;
        }
      }
      if (this.version.is_instrumental) {
        if (this.track.version_instruments === undefined || this.track['version_instruments_desc'].length === 0 ||
        this.track.version_band_references.length < 2 ||
          this.track.version_sonic_reference.length < 2) {
          this.track.isValid = true;
        }
        else {
          this.track.isValid = false;
        }
      }
      if (this.version.is_vocals) {
        if (this.track.version_instruments === undefined || this.track['version_instruments_desc'].length === 0 ||
        this.track.version_band_references.length < 2 ||
          this.track.version_sonic_reference.length < 2 || (this.track.version_lyrics?.verse == '' || this.track.version_lyrics?.bridge == '' || this.track.version_lyrics?.chorus == '' || this.track.version_lyrics?.pre_chorus == '') || this.track.version_vocal === undefined || this.track['version_vocal_desc'].length === 0) {
          this.track.isValid = true;
        }
        else {
          this.track.isValid = false;
        }
      }
      this.loading = false;
    })

  }

  onSave() {
   // localStorage.setItem('editData','yes')
    if (this.trackService.cowritten_by.length > 0) {
      let newValues = [];
      for (let i = 0; i < this.trackService.cowritten_by.length; i++) {
        if (this.trackService.cowritten_by[i]?.disable!== true) {
          newValues.push(this.trackService.cowritten_by[i])
        }
      }
      this.trackService.cowritten_by = newValues
    }

    console.log(this.track, this.track.Instrumentsub_category)
    let id :any
    id = this.track.Instrumentsub_category;
    if (id) {
      this.track.instruments = this.track.instruments.filter(function (e) {
        if (e.id !== id) {
          return e;
        }
      })
      if (this.track && this.track.Instrumentdescriptions) {
        this.track.Instrumentdescriptions = this.track.Instrumentdescriptions.filter(function (e) {
          console.log(e)

          if (e !== id) {
            return e;
          }
        })
      }
    }
    // <!-- Add Newsuggestion -->

    if (this.track.version_tv_references) {
      this.track.version_tv_references = this.track.version_tv_references.filter(function (e) {
        if (e.id !== '') {
          return e;
        }
      })
    }
    if (this.track.version_network_references) {
      this.track.version_network_references = this.track.version_network_references.filter(function (e) {
        if (e.id !== '') {
          return e;
        }
      })
    }

    if(this.track['version_instruments_desc'].length > 0){
      for(let x of this.track['version_instruments_desc']){
        this.track.Instrumentdescriptions = this.track.Instrumentdescriptions.filter(function (e) {
          if (e !== x.id) {
            return e;
          }
        })
        this.track.Instrumentnew_suggestion = this.track.Instrumentnew_suggestion.filter(function (e) {
          if (e !== x.name) {
            return e;
          }
        })
      }
    }
    for (let i = 0; i < this.track.Instrumentsub_category.length; i++) {
      const index1 = this.track.rm_ins.findIndex(ind => ind == this.track.Instrumentsub_category[i]);
      if (index1 >= 0) {
        this.track.rm_ins.splice(index1, 1);
      }
    }
   
    if(this.version.status === 'DENIED'){
      this.version.rejected_status_reason = this.version.rejected_status_reason
    }
    else{
      this.version.rejected_status_reason = null
    }
    let sonic = this.track.version_sonic_reference.map(x => x.id)
    let band = this.track.version_band_references.map(x => x.id)
   
    let data = {
      "title": this.track.title,
      "artist": this.track.artist.id,
      "id": this.track.track.id,
      "genre": this.track.genre.id,
      "sub_genre": (this.track.sub_genre && this.track.sub_genre.id) ? this.track.sub_genre.id : "",
      "moods": this.track.moods.map(x => x.id),
      "is_exclusive": true,
      "composers": [],
      "instruments": {
        "sub_category": this.track.Instrumentsub_category || [],
        "descriptions": this.track.Instrumentdescriptions || [],
        "suggesstion": [],
        "new_suggestion": this.track.newAddedSuggestion || [],
        "rm_desc_sug": this.track.Instrumentrm_desc_sug || [],
        "new_desc_sug": this.track.Instrumentnew_desc_sug || [],
        'new_ins':this.track.new_ins || [],
        'rm_ins': this.track.rm_ins || [],
        'new_ins_decs': this.track.new_ins_decs || []
      },
      "lyrics_data": {
        "verse": this.track.version_lyrics.verse ? this.track.version_lyrics.verse : [''],
        "pre_chorus": this.track.version_lyrics.pre_chorus ? this.track.version_lyrics.pre_chorus : [''],
        "chorus": this.track.version_lyrics.chorus ? this.track.version_lyrics.chorus : [''],
        "bridge": this.track.version_lyrics.bridge ? this.track.version_lyrics.bridge : ['']
      },
      // <!-- Add Newsuggestion -->

   
      "tv_references": {
        "sub_category": this.track.version_tv_references.map(x => x.id),
        "new_suggestion":  [],
        "tv_ref_new_sug": this.track.tv_ref_new_sug || [],
        "tv_ref_rm_sug": this.track.tv_ref_rm_sug || [],
      },
      "network_references": {
        "sub_category": this.track.version_network_references.map(x => x.id),
        "new_suggestion": [],
        "network_ref_new_sug": this.track.network_ref_new_sug || [],
        "network_ref_rm_sug": this.track.network_ref_rm_sug || [],
      },
      "artist_references": [],
      "sonic_references": sonic.filter(x => x != ""),
      "band_references": band.filter(x => x != ""),
      "vers_new_sonic_band": this.track.vers_new_sonic_band,
      "vers_rm_sonic_band": this.track.vers_rm_sonic_band,
      "vers_new_sonic_ref": this.track.vers_new_sonic_ref,
      "vers_rm_sonic_ref": this.track.vers_rm_sonic_ref,
      "vocal": {
        "sub_category": this.track.Vocalssub_category || "",
        "descriptions": this.track.Vocalsdescriptions || [],
        "suggesstion": this.track.vocals || [],
        "new_suggestion": [],
        "rm_desc_sug": this.track.Vocalrm_desc_sug || [],
        "new_desc_sug": this.track.Vocalnew_desc_sug || [],
      },
      "albums": "",
      "cowritten_by": this.filter(this.trackService.cowritten_by) || [],
      'added_by_role': this.track.added_by_role,
      'notify_composer': this.track.notify_composer,
      'partial': true,
      "calculated_bpm": this.version.calculated_bpm,
      "calculated_key": this.version.calculated_key,
      "track": this.trackId,
      "tempo": this.version.tempo,
      "versions": {
        "is_vocals": this.version.is_vocals,
        "is_mibe_owned": this.version.is_mibe_owned,
        "is_five_star": this.version.is_five_star,
        "versionId": this.version.id,
        "calculated_bpm": this.version.calculated_bpm,
        "track_ending": this.version.track_ending,
        "calculated_key": this.version.calculated_key,
        "tempo": this.version.tempo,
        "rejected_status_reason": this.version.rejected_status_reason,
        'added_by_role': this.track.added_by_role,
        'notify_composer': this.track.notify_composer,
        'internal_status_notes': this.version.internal_status_notes,
        'partial': this.track.partial,
        "title": this.version.title,
        "is_instrumental": this.version.is_instrumental,
        "is_full_version": this.version.is_full_version,
        "is_main_track": this.version.is_main_track,
        "status": this.version.status,
        "id": this.track.id,
      },
      
      "support_tv_ref":{
        "new_sug":this.track.tv_referencesNew_suggestion || [],
        "rm_sug":this.track.newTv_ref_rm_sug || [],
      },
    "support_nw_ref":{
      "new_sug":this.track.network_referencesNew_suggestion || [],
      "rm_sug":this.track.newNetwork_ref_rm_sug || [],
  },"support_band_ref":{
    "new_sug": this.track.version_band_referencesNew.map(x=> x.name),
    "rm_sug":this.track.bandRemove,
},
"support_sonic_ref":{
    "new_sug": this.track.sonic_referencesNew.map(x=> x.name),
    "rm_sug":this.track.sonicRemove,
},


    }

    console.log(data)
    this.track.composers = this.composers;
    this.loading = true;
    forkJoin([
      this.trackService.updateTrack(data,'track'),
    ]).subscribe(([track]) => {
      this.componentData.data.track = track;
      this.loading = false;
      this._playerService.nextTab(true)
      this._playerService.updateWavePlay(false);
      this._playerService.updateIsPlaying(false);
      this._playerService.updateShowMusicPlayer(false);
      this._playerService.play(null, null);
      this._sliderDialogService.close(true);
     //  location.reload();
      this.filtersChange.emit();
      localStorage.setItem('editData','yes')

    }, (error) => {
      console.log(error);
      if (error.error.error) {
        let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
        this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
        });
      }
      else {
        let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
        this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
        });

      }
      this.loading = false;
    });

  }
  filter(e) {
    let filData = []
    for (let x of e) {
      console.log(e)
      let data = {}
      if (x.user_id) {
        data['user_id'] = x.user_id
        data['percent'] = x.percent
      }
      else {
        data['user_id'] = x.id
        data['percent'] = x.percent
      }

      filData.push(data)
    }
    return filData
  }
   
  networkRef(track){
    let data = [...track.version_tv_references,...track.support_network_references]
    return data
  }
  getTvData(track){
    let data = [...track.version_network_references,...track.support_tv_references]
    return data
  }
  ngOnDestroy() {
    this._playerService.nextTab(false);
    this._playerService.updateViewType(false);
    this._playerService.updateShowMusicPlayer(false);
    this._playerService.play(null, null);
  }
}
