import { MibeModel } from './mibemodel.model';
import { PhoneNumber } from './phonenumber.model';
import { AllowedCoWriters } from './allowedcowriters.model';
import { Genre } from './genre.model';
import { Injectable } from '@angular/core';

export class MenuUser implements MibeModel {
    id: number;
  email: string;
  first_name: string;
  last_name: string;
  nick_name: string;
  company_name: string;
  project_name: string;
  discount_rate: number;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string = "US";
  postal_code: string;
  phone_number: string;
  additional_numbers: PhoneNumber[] = [];
  fax: string;
  birth_date: Date;
  cae_ipi: string;
  ssn: string;
  pro: number;
  unread: number;
  role: string;
  can_download: boolean;
  can_upload: boolean;
  is_client: boolean;
  is_artist: boolean;
  is_active: boolean;
  is_staff: boolean;
  publisher_percentage: number = 0;
  cowriter_quanity_limit: number;
  unlimited_cowriters: boolean;
  allowed_cowriters: AllowedCoWriters[] = [];
  visible_genres: Genre[] = [];
  date_joined: Date;
  last_login: Date;

  password:string;
  fax_number:string;
  shows:string;
  message:string;
  portfolio_link:string;
  subscribe:string;

  constructor(json: any) {
    this.loadWithJSON(json);
  }
 loadWithJSON(json: any) {
    this.country = "US";
    this.publisher_percentage = 0;
    if (!json) {
      return;
    }
    this.id = json.id;
    this.email = json.email;
    this.first_name = json.first_name;
    this.last_name = json.last_name;
    this.nick_name = json.nick_name;
    this.company_name = json.company_name;
    this.project_name = json.project_name;
    this.discount_rate = json.discount_rate;
    this.address1 = json.address1;
    this.address2 = json.address2;
    this.city = json.city;
    this.state = json.state;
    this.country = json.country && json.country != '' ? json.country:'US';
    this.postal_code = json.postal_code;
    this.phone_number = json.phone_number;
    if (json.additional_numbers && json.additional_numbers.length > 0) {
      this.additional_numbers = json.additional_numbers.map(num=>new PhoneNumber(num));
    }
    this.fax = json.fax;
    if (json.birth_date) {
      this.birth_date = new Date(json.birth_date);
    }
    this.cae_ipi = json.cae_ipi;
    this.ssn = json.ssn;
    this.pro = json.pro;
    this.unread = json.unread;
    this.role = json.role;
    this.can_download = json.can_download;
    this.can_upload = json.can_upload;
    this.is_client = json.is_client;
    this.is_artist = json.is_artist;
    this.is_active = json.is_active;
    this.is_staff = json.is_staff;
    this.publisher_percentage = json.publisher_percentage ? json.publisher_percentage:0;
    this.cowriter_quanity_limit = json.cowriter_quanity_limit;
    this.unlimited_cowriters = json.unlimited_cowriters;
    if (json.allowed_cowriters && json.allowed_cowriters.length > 0) {
      this.allowed_cowriters = json.allowed_cowriters.map(cowriter=>new AllowedCoWriters(cowriter));
    }
    if (json.visible_genres && json.visible_genres.length > 0) {
      this.visible_genres = json.visible_genres.map(genre => new Genre(genre));
    }
    if (json.date_joined) {
      this.date_joined = new Date(json.date_joined);
    }
    if (json.last_login) {
      this.last_login = new Date (json.last_login);
    }
  }

  toJSON() {
    var json: any = {
      id: this.id,
      email: this.email,
      first_name: this.first_name,
      last_name: this.last_name,
      nick_name: this.nick_name,
      company_name: this.company_name,
      project_name: this.project_name,
      discount_rate: this.discount_rate,
      address1: this.address1,
      address2: this.address2,
      city: this.city,
      state: this.state,
      country: this.country,
      postal_code: this.postal_code,
      phone_number: this.phone_number,
      additional_numbers: this.additional_numbers.map(num=>num.toJSON()),
      fax: this.fax,
      cae_ipi: this.cae_ipi,
      ssn: this.ssn,
      pro: this.pro,
      unread: this.unread,
      role: this.role,
      can_download: this.can_download,
      can_upload: this.can_upload,
      is_client: this.is_client,
      is_artist: this.is_artist,
      is_active: this.is_active,
      is_staff: this.is_staff,
      publisher_percentage: this.publisher_percentage,
      cowriter_quanity_limit: this.cowriter_quanity_limit,
      unlimited_cowriters: this.unlimited_cowriters,
      allowed_cowriters: this.allowed_cowriters.map(cowriter=>cowriter.toJSON()),
      visible_genres: this.visible_genres.map(genre=>genre.toJSON())
    };
    if (this.birth_date) {
      json['birth_date'] = this.birth_date.toJSON();
    }
    if (this.date_joined) {
      json['created_at'] = this.date_joined.toJSON();
    }
    if (json.last_login) {
      json['last_login'] = this.last_login.toJSON();
    }
    return json;
  }

  getFullName(): string {
    return `${this.first_name} ${this.last_name}`;
  }
}
