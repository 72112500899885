<div [ngClass]="{'half-size-loader': isHalfSize}">
    <div class="loader">
        <div class="loader-inner line-scale-pulse-out">
            <div [ngStyle]="{ 'background-color': color }"></div>
            <div [ngStyle]="{ 'background-color': color }"></div>
            <div [ngStyle]="{ 'background-color': color }"></div>
            <div [ngStyle]="{ 'background-color': color }"></div>
            <div [ngStyle]="{ 'background-color': color }"></div>
        </div>
        <div *ngIf="textDisplay" class="loading-text">{{textDisplay}}</div>
    </div>
</div>