import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MenuListService } from '../../shared/services/menu-list.service';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { environment } from '../../../environments/environment';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { UserUploadPopupComponent } from 'src/app/shared/user-upload-popup/user-upload-popup.component';


@Component({
  selector: 'app-remove-genre-dialogue',
  templateUrl: './remove-genre-dialogue.component.html',
  styleUrls: ['./remove-genre-dialogue.component.scss']
})
export class RemoveGenreDialogueComponent implements OnInit {

  loader: boolean = true;
  
  constructor(private dialogRef: MatDialogRef<RemoveGenreDialogueComponent>,
    public list: MenuListService,
    public api: UserMenuService,
    public config: MenuConfigService,
    public _DialogService:DialogService
  ) { }

  ngOnInit(): void {
    setTimeout(()=>{                          
      this.loader = false;
    }, 500);
  }
  close() {
    this.dialogRef.close();
  }
  remove(){
      let requestUrl = environment.apiURL + this.config.doGenres + this.list.currGnere.id
      this.api.doDelete(requestUrl).subscribe(x=>{
        this.close()
        this.list.getAllGenres();
      },
      error =>{
        // alert(error.error.detail)
        // alert("This Genre is associated with a track(s), hence can't be deleted.")
        // this.close()
        let data = error.error?.error ? error.error?.error : (error.error?.detail ?error.error?.detail : (error.error?.details ? error.error?.details:`This Genre is associated with a track(s), hence can't be deleted.`) )
        this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
        });
        this.close()
      }
    )
  }
}
