import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { MenuListService } from '../../shared/services/menu-list.service';
import { SliderDialogService } from '../../shared/services/slider-dialog.service';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {
 
  @Input('currentuser') currentUser:any;
  primary:any;
  toppings = new FormControl();
  searchFilterFocused:any;
  loading: boolean = false;
  searchString:any;
  groupVal:any = [];
  editPermissions:any;
  primaryPhoneControl:FormControl = new FormControl();
  toppingList: string[] = ['Sub Admin Groups', 'Test1', 'Test2', 'Test3', 'Test4'];
  managePermissionArray:any[] = [];
  permission:any[] = [];
  userDetails:any;
  userForm: FormGroup;
  password: string = "";
  isNewUser: boolean = false;
  errorMessage: string;
  searchPermission:string =""
  user = {
    country: '',
    state:''
  }
  username:string = "";
  phoneControllers:FormControl[] = [];
  phoneNumberArray:any = [ {
    number_type:"mobile",
    phone_number:""
    
}];
groupInitial: any = '';
groupEdit: boolean = false;
selectedCount: any = 0;
selectedCountAdd: any = 0;
phoneNumber: any = '';
currentValue: any;
currentphone: any = '';
  allselectUser:boolean = false;
  saveGroup: boolean = false;
  public customPatterns = {'0': { pattern: new RegExp('\[a-zA-Z\]')}};  
  public numberPattern = {'0': { pattern: new RegExp('^[0-9]')}};
  constructor(public fb: FormBuilder,
     public api: UserMenuService, 
     public config: MenuConfigService, 
     public list: MenuListService,
     private _sliderDialogService:SliderDialogService,public dialog: DialogService,
    ) { 
      this.list.getAllUser();
      this.list.getAllGroupsList();
      this.list.getAllPermission();
      this.list.getAvailableUserPermission();
    }

  ngOnInit(): void {
    console.log
   
    this.userForm = this.fb.group({
      first_name: new FormControl('',[Validators.required]),
      last_name: new FormControl('',[Validators.required]),
      email: new FormControl('',[Validators.required,Validators.email]),
      address1: new FormControl(''),
      address2: new FormControl(''),
      city: new FormControl(''),
      state: new FormControl(''),
      country: new FormControl(''),
      postal_code: new FormControl(''),
      phone_number: new FormControl(''),
      additional_numbers: new FormControl([]),
      shows: new FormControl([]),
      allowed_cowriters: new FormControl([]),
      visible_genres: new FormControl([]),
      username: new FormControl(''),
      groups : new FormControl('')
    })
   // if(this.currentUser == 'edit'){
     this.list.getAdminUser().subscribe(x=>{
      if(x){
        this.userDetails = x;
        //this.setformValue(x)
      }
     
     })
    //}
  
  }
/* setformValue(user){
  let data =  {
    first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      address1: user.address1,
      address2: user.address2,
      city: user.city,
      state: user.state,
      country: user.country,
      postal_code: user.postal_code,
      phone_number: user.phone_number,
      additional_numbers: user.additional_numbers,
      shows: user.shows,
      allowed_cowriters: user.allowed_cowriters,
      visible_genres: user.visible_genres,
      username: user.email,
      group : user.groups
  }
  this.user.country = user.country
  this.user.state = user.state
  this.phoneNumberArray = user.additional_numbers;
  if(this.phoneNumberArray){
    this.phoneNumberArray.push({ 
      number_type:"mobile",
    phone_number:user.phone_number,
    id : ''})
  }
  this.editPermissions = user.user_permissions;
  this.editPermissionSet();
  this.userForm.patchValue(data)
} */
formSave(){
  console.log(this.currentphone)
  let data = this.userForm.value;
  //let group = []
  //group.push(this.groupVal)
  let phone = this.phoneNumberArray.filter(x => x.number_type == 'mobile')
  this.phoneNumberArray.forEach(element => {
    element.phone_number = this.phoneNumber;
  });
  data['phone_number'] = this.currentphone
  data['additional_numbers'] = this.phoneNumberArray
  data['state'] = this.user.state;
  data['country'] = this.user.country;
  data['groups'] = this.groupVal;
  // data['user_permissions'] = this.permission.map(x => x.id);
  data['user_permissions'] = this.managePermissionArray
  data['is_staff'] = true;

 /*  if(this.userForm.invalid || this.phoneNumberArray.length === 0 || this.user.state == '' || this.user.country == ''){

    //alert('Enter required fields')
    return;
  } */
  
    this.loading = true;
    let url = environment.apiURL + this.config.doMenuUser;
    this.api.doPOST(url,data).subscribe(x=>{
      this.loading = false
      this.list.getAllMenuUser();
      this._sliderDialogService.close(true);
      location.reload();
    },
    (error) => {
      this.loading = false;
      let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
      this.dialog.UserUploadPopupConfirmPopup(data).subscribe(changed => {
      });
      //alert(error.message)
    })

}
removeNumber(index: number) {
  this.phoneNumberArray.splice(index, 1);
  this.phoneControllers.splice(index, 1);
}
addPhoneNumber(){
    this.phoneNumberArray.push({
      number_type:"",
      phone_number:""
    })
}
validateNumber(event, i?:number) {
  const keyCode = event.keyCode;
  const excludedKeys = [8, 37, 39, 46];
  if ((event.key === ' ' || isNaN(Number(event.key)))&&!((
    (keyCode >= 96 && keyCode <= 105) ||
    (excludedKeys.includes(keyCode))))) {
    event.preventDefault();
  }
}
getPhoneCode(e, i?:number){
  let l = e.value.length;
  this.currentValue = e.id;
  //this.phoneNumber = '';
  if(l==1){
    if(e.value !== "+"){
      this.phoneNumber = '+1' + e.value;
      if(i!==undefined){
      this.phoneNumberArray[i].phone_number = this.phoneNumber
      }
    }
  }else{
    this.phoneNumber = e.value;
  }
}
mySelectOpt(id, evt, index){  
  if(evt.checked){
    this.list.availableUserPermission[index].checked = true;   
  }else{
    this.list.availableUserPermission[index].checked = false;  
  }  
  for (var i = 0; i < this.list.availableUserPermission.length; i++) {
    if (this.list.availableUserPermission[index].checked) {
      this.managePermissionArray.push(id);
      this.selectedCount = this.managePermissionArray.length;
      if(this.managePermissionArray.length==this.list.availableUserPermission.length){
        this.allselectUser = true;
      } 
      break
    }else{
      this.managePermissionArray = this.managePermissionArray.filter(x => x !== id);
      this.selectedCount = this.managePermissionArray.length;
      this.allselectUser = false;
      break
    }
  }
}
deselectPermission(){ 
  this.selectedCount=this.selectedCountAdd;
  this.selectedCountAdd = this.selectedCountAdd;
  for(let x of this.list.availableUserPermission){
     if(!x.disabled){
        x.checked = false;
      } 
    this.allselectUser = false;
    this.managePermissionArray=[];
  }
}
selectAllPermission(evt){
  if(evt.checked){
    for(let x of this.list.availableUserPermission){
      x.checked = true;        
      this.allselectUser = true;
      if(!this.managePermissionArray.includes(x.id)){
        this.managePermissionArray.push(x.id);
        this.selectedCount = this.managePermissionArray.length;
      }
    }
  }else{
    for(let x of this.list.availableUserPermission){
      if(!x.disabled){
        x.checked = false;
      } 
      
      this.allselectUser = false;
      this.managePermissionArray=[];
      this.selectedCount = this.selectedCountAdd;
    }
  }
}
get selectallStatus(){
var status = true;
  for(let x of this.permission){
   if(!x.checked){
    status = false
   }
  }
  return status
}

groupChange(e){
  this.groupEdit = true;
  this.groupVal = [];
  this.groupVal.push(e.value)
  if(e.value){
    this.saveGroup = true;

  }
  else{
    this.saveGroup = false;

  }
  for(let x of this.list.AllGroups){
    if(x.id === e.value){
      for (var i = 0; i < this.list.availableUserPermission.length; i++) {
        this.list.availableUserPermission[i].checked = false;
        this.list.availableUserPermission[i].disabled = false;
        for(var j = 0; j < x.permissions.length; j++){
          if (this.list.availableUserPermission[i].id == x.permissions[j].id) {              
            this.selectedCount = x.permissions.length;
            this.selectedCountAdd = this.selectedCount;
            this.list.availableUserPermission[i].checked = true;
            this.list.availableUserPermission[i].disabled = true;
            if(this.list.availableUserPermission[i].checked){
              if(!this.managePermissionArray.includes(this.list.availableUserPermission[i].id)){
                this.managePermissionArray.push(this.list.availableUserPermission[i].id)
                console.log(this.managePermissionArray)
              }
            }
          }
        }
      }
    }
}

}
}


