import { MibeModel } from './mibemodel.model';
import { Playlist } from './playlist.model';
import {Uploads} from './uploads.model'

export class TrackVersion implements MibeModel {
  id: number;
  title: string;
  mp3_file: string;
  uploads:Uploads;
  waveform_file: string;
  wav_file: string;
  calculated_bpm: number;
  calculated_key: string;
  // Length of track in seconds
  duration: number;
  is_full_version: boolean;
  is_main_track: boolean;
  is_instrumental: boolean;
  favorited_by_me: boolean;
  playlists: Playlist[];
  status: any;
  rejected_status_reason: string;
  internal_status_notes: string;
  created_at: Date;
  updated_at: Date;
  approved_at: Date;
  is_vocals:boolean;
  is_mibe_owned:boolean;
  notify_composer: boolean;
  is_five_star:boolean;
  tempo?:any;
  tempo_value?:any;
  track_ending:string;
  vtype: string;
  waveform_file_data:any;
  versionName:any;
  track: any;
  
  /**
   * Create a TrackVersion.
   * @param {any} json - The JSON object with which to construct our TrackVersion.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a TrackVersion.
   * @param {any} json - The JSON object with which to load our TrackVersion.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this.id = json.id;
    this.title = json.title;
    this.uploads = json.uploads
    this.mp3_file = json.mp3_file;
    this.waveform_file = json.waveform_file;
    this.wav_file = json.wav_file;
    this.calculated_bpm = json.calculated_bpm;
    this.calculated_key = json.calculated_key;
    this.duration = json.duration;
    this.is_full_version = !!json.is_full_version;
    this.is_main_track = !!json.is_main_track;
    this.is_instrumental = !!json.is_instrumental;
    this.favorited_by_me = !!json.favorited_by_me;
    this.is_vocals =  !!json.is_vocals
    this.is_five_star = !!json.is_five_star;
    this.is_mibe_owned = !!json.is_mibe_owned;
    this.notify_composer = !!json.notify_composer;
    this.waveform_file_data = json.waveform_file_data
    this.versionName = json.versionName
    this.tempo = json.tempo;
    if (json.playlists) {
      this.playlists = json.playlists.map((p) => new Playlist(p));
    } else {
      this.playlists = [];
    }
    this.status = json.status;
    this.rejected_status_reason = json.rejected_status_reason;
    this.internal_status_notes = json.internal_status_notes;
    this.track_ending = json.track_ending;
    if (json.created_at) {
      this.created_at = new Date(json.created_at);
    } else {
      this.created_at = null;
    }
    if (json.updated_at) {
      this.updated_at = new Date(json.updated_at);
    } else {
      this.updated_at = null;
    }
    if (json.approved_at) {
      this.approved_at = new Date(json.approved_at);
    } else {
      this.approved_at = null;
    }
  }
  /**
   * Returns a JSON representation of our TrackVersion
   * @return {any} - The JSON representation of our TrackVersion
   */
  toJSON() {
    var json: any = {
      'id': this.id,
      'title': this.title,
      'uploads':this.uploads,
      'mp3_file': this.mp3_file,
      'waveform_file': this.waveform_file,
      'wav_file': this.wav_file,
      'calculated_bpm': this.calculated_bpm,
      'calculated_key': this.calculated_key,
      'duration': this.duration,
      'is_full_version': this.is_full_version,
      'is_main_track': this.is_main_track,
      'is_instrumental': this.is_instrumental,
      'favorited_by_me': this.favorited_by_me,
      'status': this.status,
      'rejected_status_reason': this.rejected_status_reason,
      'internal_status_notes': this.internal_status_notes,
      'tempo': this.tempo,
      'track_ending': this.track_ending,
      'is_vocals': this.is_vocals,
      'is_five_star' : this.is_five_star,
      'is_mibe_owned' : this.is_mibe_owned,
      'notify_composer': this.notify_composer,
      'waveform_file_data': this.waveform_file_data,
      'versionName': this.versionName
    };
    if (this.playlists) {
      json['playlists'] = this.playlists.map((p) => p.toJSON())
    }
    if (this.created_at) {
      json['created_at'] = this.created_at.toJSON();
    }
    if (this.updated_at) {
      json['updated_at'] = this.updated_at.toJSON();
    }
    if (this.approved_at) {
      json['approved_at'] = this.approved_at.toJSON();
    }
    return json;
  }

  isEqualTo(v2: TrackVersion) {
    if (this.id != v2.id) return false;
    if (this.title != v2.title) return false;
    if (this.mp3_file != v2.mp3_file) return false;
    if (this.waveform_file != v2.waveform_file) return false;
    if (this.wav_file != v2.wav_file) return false;
    if (this.calculated_bpm != v2.calculated_bpm) return false;
    if (this.calculated_key != v2.calculated_key) return false;
    if (this.duration != v2.duration) return false;
    if (this.is_full_version !== v2.is_full_version) return false;
    if (this.is_main_track !== v2.is_main_track) return false; 
    if (this.is_instrumental !== v2.is_instrumental) return false;
    if (this.is_five_star  !== v2.is_five_star) return false;
    if (this.is_mibe_owned !== v2.is_mibe_owned) return false;
    if (this.notify_composer !== v2.notify_composer) return false;
    if (this.is_vocals !== v2.is_vocals) return false;
    if (this.favorited_by_me !== v2.favorited_by_me) return false;
    if (this.status !== v2.status) return false;
    if (this.rejected_status_reason !== v2.rejected_status_reason) return false;
    if (this.internal_status_notes !== v2.internal_status_notes) return false;
    if ((!this.playlists && v2.playlists) || (this.playlists && !v2.playlists) ) return false;
    if (this.playlists) {
      if (this.playlists.length != v2.playlists.length) return false;
      for (let i = 0; i < this.playlists.length; i++) {
        if (this.playlists[i].id != v2.playlists[i].id) return false;
      }
    }
    return true;
  }

  getFileName(): string {
    if (!this.wav_file) return '';
    let file = this.wav_file.split('/');
    return file[file.length-1];
  }
}
