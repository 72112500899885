import { Component, OnInit } from '@angular/core';
import { MenuConfigService } from 'src/app/shared/services/menu-config.service';
import { UserMenuService } from 'src/app/shared/services/user-menu.service';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  constructor(public user: UserService, public api: UserMenuService,
    public config: MenuConfigService,) { }
  userDetails: any;
  ngOnInit(): void {
    let url = environment.apiURL + this.config.doProfile;
    this.api.doGET(url).subscribe(x => {
      this.userDetails = x || [];

    },
      (error) => {
      })
  }

}
