import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlTree,
} from '@angular/router';
import { Observable, AsyncSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class GuestGuard implements CanActivate {
  constructor(private _userService: UserService, private _router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const canActivateSubject = new AsyncSubject<boolean | UrlTree>();
    this._userService.currentUserStream.pipe(first()).subscribe((u) => {
      if (!u) {
        canActivateSubject.next(true);
        canActivateSubject.complete();
      } else {
        canActivateSubject.next(this._router.createUrlTree(['/login']));
        canActivateSubject.complete();
      }
    });
    return canActivateSubject.asObservable();
  }
}
