import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { of, ReplaySubject, Subscription } from 'rxjs';
import { startWith, debounceTime, distinctUntilChanged, tap, switchMap, map } from 'rxjs/operators';
import { Playlist } from '../models/playlist.model';
import { PlaylistFolder } from '../models/playlistfolder.model';
import { PlaylistFolderService } from '../services/playlistfolder.service';
import { PlaylistService } from '../services/playlist.service';
import { DialogService } from '../services/dialog.service';

const folderLimit = 30;

export interface DialogData {
  playlist: Playlist,
  folderId:any ,
  playListfolder:any,
  tabval:any;
}

@Component({
  selector: 'app-add-remove-playlist-dialog',
  templateUrl: './add-remove-playlist-dialog.component.html',
  styleUrls: ['./add-remove-playlist-dialog.component.scss']
})
export class AddRemovePlaylistDialogComponent implements OnInit, OnDestroy {

  constructor(
    private dialogRef: MatDialogRef<AddRemovePlaylistDialogComponent>,
    private _playlistFolderService: PlaylistFolderService,
    private _playlistService: PlaylistService,
    public _DialogService:DialogService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  newPlaylistFolder = false;

  loading = true;
  confirm =0
  folders: PlaylistFolder[] = [];
  modifiedFolders: PlaylistFolder[] = [];
  checkedFolders: PlaylistFolder[] = [];

  title:string;
  folderName: string='';
  folderList:any;
  error: string;
  folderListcheckbox:any;
  searchControl = new FormControl();
  folderOptions: ReplaySubject<any> = new ReplaySubject<any>(1);
  searchingFolders = false;
  editedval:any;
  showDataPlaylist=false;
  currentPage = 1;
  isNext = false;
  private _subscriptions: Subscription[] = [];

  ngOnInit(): void {
    if(this.data.tabval == 'playlists'){
      this._playlistFolderService.getAllPlaylistFolderspopup().subscribe(res => {
        this.showDataPlaylist=true;
        console.log("on onInit", res);
        this.folderList=res;
        this.folderOptions.next(res);
        this.loading = false;
        this._playlistFolderService.getAllPlaylistFolderspopupcheckbox( this.data.playlist.id).subscribe(res => {
          this.folderListcheckbox=res;
        })
      })
     
    }else{

  this._playlistService.getAddsongtoplaylist().subscribe(res => {
    this.showDataPlaylist=false;
    this.isNext = res?.next ? true: false;
      this.folderList=res.results;
      this.folderOptions.next(res.results);
      this.loading = false;
      this._playlistFolderService.getAllFoldersPlaylistpopupcheckbox( this.data.folderId[0]).subscribe(res => {
        this.folderListcheckbox=res;
      })
        
      })
     
    }
    
//     this._playlistFolderService.getAllPlaylistFolders({playlistId: this.data.playlist.id}).subscribe(res => {
//       this.folders = res.results;
//       this.checkedFolders = res.results;
//       this.folderOptions.next(res.results);
// console.log("this.folderOptions"+JSON.stringify(this.folderOptions))
//       if (res.results && res.results.length > 0) {
//         this.loading = false;
//       } else {
//         this._playlistFolderService.getAllPlaylistFolders({limit: folderLimit}).subscribe(response => {
//           this.folders = response.results;
//           this.folderOptions.next(response.results);
//           this.loading = false;
//         }, (error) => {
//           console.log(error);
//           //alert("An error ocurred while getting the playlists for the folder. Check your connection and try again");
//         })
//       }
//     })
    this._subscriptions.push(this.searchControl.valueChanges.pipe(
      startWith(''),
      debounceTime(150),
      distinctUntilChanged(),
      tap(() => {
        this.searchingFolders = true;
      }),
      switchMap((v) => {
        if (!v || v.length <= 2) {
          return of(this.folderList);
        }
        if(this.data.tabval !== 'playlists'){
          return this._playlistService.getAddsongtoplaylist(v,folderLimit).pipe(
              map(res => {
                let ret = res;
                for(let folder of this.modifiedFolders) {
                  if(!ret.some(f=>f.id == folder.id)) {
                    ret.push(folder);
                  }
                }
                return ret;
              })
            )
          }
            else{
        return this._playlistFolderService.getAllPlaylistFolders({search:v, limit:folderLimit}).pipe(
          map(res => {
            let ret = res.results;
            for(let folder of this.modifiedFolders) {
              if(!ret.some(f=>f.id == folder.id)) {
                ret.push(folder);
              }
            }
            return ret;
          })
        );

            }
      }),
      tap(() => {
        this.searchingFolders = false;
      })
    ).subscribe(f => {
      this.folderOptions.next(f);
    }));
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s=>s.unsubscribe())
    this._subscriptions = [];
  }

  close() {
    this.dialogRef.close(false);
  }

  isFolderChecked(folder:PlaylistFolder) {
    return this.folderListcheckbox? this.folderListcheckbox.some(f=>f.id == folder.id): false;
  }

  folderChecked(checked:boolean, folder:PlaylistFolder) {
    // this.toggleModified(folder);
    if (checked) {
     this.folderListcheckbox.push(folder);
      // this.folderListcheckbox.forEach((id:any)=>{
      //   if(!this.folderList.includes(id.id)){
      //     this.folderList.push(id.id);
      //   }else{
      //     this.folderListcheckbox.splice(id.id,1);
      //   }
      // })
      // if (!this.folderList.some(f=>f.id == folder.id)) {
      //   this.folderList.push(folder);
      // }
      // return;
    }else{
      let i = this.folderListcheckbox.findIndex(f=>f.id == folder.id);
      if (i >= 0) {
        this.folderListcheckbox.splice(i, 1);
      }
      // let j = this.folders.findIndex(f=>f.id == folder.id);
      // if (j >= 0) {
      //   this.folders.splice(i, 1);
      // }
    }
    
  }

  toggleModified(folder:PlaylistFolder) {
    let i = this.modifiedFolders.findIndex(f=>f.id == folder.id)
    if (i >= 0) {
      this.modifiedFolders.splice(i, 1);
    } else {
      this.modifiedFolders.push(folder);
    }
  }

  folderTrackBy(i:number, folder:PlaylistFolder) {
    return folder.id;
  }

  canSubmit():boolean {
    return ((this.folderListcheckbox && this.folderListcheckbox.length > 0));
  }

  save() {
    if (!this.canSubmit()) return;
    this.loading = true;
    this.foldersHandled = [];
     if (this.confirm === 1) {
      if (this.folderListcheckbox && this.folderListcheckbox.length > 0) {
        let added: number[] = [];
        let removed: number[] = [];
        this.folderListcheckbox.forEach(folder => {
          if (this.isFolderChecked(folder)) {
            if (folder.id) {
              added.push(folder.id)
            }
            added.push(folder.id);
          } else {
            console.log("folder.id" + folder.id)
            if (folder.id) {
              removed.push(folder.id)
            }
            removed.push(folder.id);
          }
        });
        if (removed.length > 0 || added.length > 0) {

          if (removed.length > 0) {
            this.editedval = removed;
          } else {
            this.editedval = added;
          }

          // alert(this.editedval)
          if (this.data.tabval == 'playlists') {
            this._playlistFolderService.updatePlaylisttoFolder(this.data.playlist.id, this.editedval, this.
              folderName).subscribe(f => {
                // this.foldersHandled.push(f.id);
                this.closeIfDone();

              }, (error) => {
                console.log(error);
                //  alert (`An error ocurred while removing versions ${removed.toString()} from the playlist. Check connection and try again`);
                let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
                this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
                });
                this.close();
              })
          } else {
            this._playlistFolderService.updateFoldertoPlaylist(this.data.folderId, this.editedval).subscribe(f => {
              // this.foldersHandled.push(f.id);
              this.closeIfDone();

            }, (error) => {
              console.log(error);
              // alert(`An error ocurred while removing versions ${removed.toString()} from the playlist. Check connection and try again`);
              let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
              this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
              });
              this.close();
            })
          }

        }
      }
  }
    // this.modifiedFolders.forEach(folder => {
    //   if (this.isFolderChecked(folder)) {
    //     folder.playlists.push(this.data.playlist);
    //   } else {
    //     let i = folder.playlists.findIndex(p=>p.id == this.data.playlist.id);
    //     if (i >= 0) {
    //       folder.playlists.splice(i, 1);
    //     }
    //   }
    //   this._playlistFolderService.updatePlaylisttoFolder(folder).subscribe(f => {
    //     this.foldersHandled.push(f.id);
    //     this.closeIfDone();
    //   }, (error) => {
    //     console.log(error);
    //     //alert("An error ocurred while updating the folder. Check your input for errors, and then check connection and try again");
    //   });
    // });
    // if (this.newPlaylistFolder) {
    //   this._playlistFolderService.createPlaylistFolder(this.folderName).subscribe(folder => {
    //     if (!folder.playlists) {
    //       folder.playlists = []
    //     }
    //     folder.playlists.push(this.data.playlist);
    //     this._playlistFolderService.updatePlaylistFolder(folder).subscribe(updatedFolder => {
    //       this.folderCreated = true;
    //       this.closeIfDone();
    //     }, (error) => {
    //       console.log(error);
    //       //alert("An error ocurred while updating the folder. The folder was created. Please check the folder to ensure data looks correct, and edit it if necessary");
    //     });
    //   }, (error) => {
    //     console.log(error);
    //     //alert("An error ocurred while creating the folder. Check your input for errors, and then check connection and try again");
    //   });
    // }

  }

  private folderCreated = false;
  private foldersHandled = [];
  private closeIfDone() {
    // if (this.newPlaylistFolder && !this.folderCreated) return;
    // if (this.foldersHandled.length != this.modifiedFolders.length) return;
    this.dialogRef.close(true);
  }
getConfirmData(){
  let data = '', value ='playlist'
  if(this.showDataPlaylist){
   data = 'Do you want to add this "' + this.data.playlist.name +'" to the selected folder(s)?'
  }
  else{
     data = 'Do you want to add this "' + this.data.playListfolder[0].name +'" to the selected playlist(s)?'
  }
  this._DialogService.UserUploadPopupConfirmPopup(data, value).subscribe(changed => {
    if(changed){
      this.confirm =1
      this.save()
    }
  });
}

loadMore() {
  this.currentPage++;
  this._playlistService.getAddsongtoplaylist("", this.currentPage).subscribe(res => {
  this.isNext = res?.next ? true: false;
  this.folderList.push(...res.results);
  this.folderOptions.next(this.folderList);
  })
}

}
