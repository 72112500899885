import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
}) 

export class SessionStorageService {

  activePlaylistSessionKey: any;
  activePlaylistSessionName: any;

  constructor() { }

  setItem(key: string, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  getItem(key: string): any {
    const item = sessionStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  }

  removeItem(key: string) {
    sessionStorage.removeItem(key);
  }

  clear() {
    sessionStorage.clear();
  }
}
