<div class="dialog-container green-theme">
    <div class="dialog-header">
      <h4 class="dialog-heading">Client Activity</h4>
      <button type="button" class="close-button" (click)="close()">
        <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
            stroke-linejoin="round">
            <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
              <g id="Genre-Options">
                <g id="Close" transform="translate(1439.000000, 32.000000)">
                  <path d="M24,0 L0,24" id="Path"></path>
                  <path d="M0,0 L24,24" id="Path"></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </button>
    </div>
  
    <mat-dialog-content>
      <div class="dialog-body track-list-sort">
        <h3 class="title" style="font-size: 12px;text-transform: capitalize;">{{data.user.getFullName()}}</h3>
        <app-loader *ngIf="loading" color="#50e3c2"></app-loader>
        <mat-table *ngIf="!loading" [dataSource]="listDataSource" matSort class="green-theme" multiTemplateDataRows >          
            <ng-container matColumnDef="title" style="font-size: 12px;">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="headerCell">TRACK TITLE</mat-header-cell>
              <mat-cell *matCellDef="let row">{{row.additional_data.track_name}}</mat-cell>
            </ng-container>
          
            <ng-container matColumnDef="artist" style="font-size: 12px;">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="headerCell">ARTIST NAME</mat-header-cell>
              <mat-cell *matCellDef="let row">{{row.additional_data.artist}}</mat-cell>
            </ng-container>
          
            <ng-container matColumnDef="downloaded" style="font-size: 12px;"> 
              <mat-header-cell *matHeaderCellDef mat-sort-header class="headerCell">DOWNLOADED</mat-header-cell>
              <mat-cell *matCellDef="let row">{{row.updated_at | date: 'shortDate'}}</mat-cell>
            </ng-container>
          
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns" class="main-row" [@rowInOut]></mat-row>
        </mat-table>
        <app-pagination
          *ngIf="numberOfPages > 1"
          [page]="currentPage"
          (pageChange)="pageChange($event)"
          [numberOfPages]="numberOfPages"
          [darkText]="true"
          ></app-pagination>
      </div>
    </mat-dialog-content>
</div>
  