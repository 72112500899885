<form [formGroup]="coWriterForm">
<div class="profile-info">
    <div class="menu-user-info">
    <div class="section general">
            <div class="title">
                <h3>Add Co-writers</h3>
            </div>
            
            <div class="grid-container">
    
                <div class="grid-item">
                    <mat-form-field appearance="fill"  [ngClass]="{'disabledElement': (allCoWriter?.length === userDetails?.cowriter_quanity_limit ) && (userDetails?.unlimited_cowriters === false)}">
                        <mat-label>First Name</mat-label>
                        <input matInput formControlName="first_name">
                    </mat-form-field>
                </div>
                <div class="grid-item">
                    <mat-form-field appearance="fill" [ngClass]="{'disabledElement': (allCoWriter?.length === userDetails?.cowriter_quanity_limit ) && (userDetails?.unlimited_cowriters === false)}">
                        <mat-label>Last Name</mat-label>
                        <input matInput formControlName="last_name">
                    </mat-form-field>
                </div>
            </div> 
            <div class="grid-card">
                <div class="grid-item">
                    <mat-form-field appearance="fill"  (blur)="$event.preventDefault();$event.stopPropagation();checkEmail($event.target.value)" [ngClass]="{'disabledElement': (allCoWriter?.length === userDetails?.cowriter_quanity_limit ) && (userDetails?.unlimited_cowriters === false)}">
                        <mat-label>Email Address</mat-label>
                        <input matInput formControlName="email">
                    </mat-form-field>
                    <p *ngIf="error != ''" style="
                       color: red;
    font-size: small;
    margin-left: 2px;
    margin-top: -4%;
    margin-bottom: 3%;">{{error}}</p>
                </div>
            </div>
            <button class="btn save-btn"  [disabled]="!canAddCoWriter()" mat-button (click)="addCoWriter()">Add Co-writer</button> 

            <div class="co-writersnoti" *ngIf="(allCoWriter?.length === userDetails?.cowriter_quanity_limit) && (userDetails?.unlimited_cowriters === false)">
                <h3><span class="allfields-co"><small>* To make changes to your account, contact your Mibe representative</small></span></h3>
            </div>
        </div>
        <hr>
        <div *ngIf="loading">
            <app-loader color="#50e3c2"></app-loader>
        </div>
        <div *ngIf="!loading" class="section general">
            <div class="title" *ngIf="(allCoWriter?.length === userDetails?.cowriter_quanity_limit) && (userDetails?.unlimited_cowriters === false)">
                <h3>Existing Co-writers</h3>
            </div>
            <div class="title" *ngIf="(allCoWriter?.length !== userDetails?.cowriter_quanity_limit) && (userDetails?.unlimited_cowriters === false)">
                <h3>Existing Co-writers<span class="allfields"><strong>{{allCoWriter?.length === 0 ? '0' : allCoWriter?.length}} OF {{userDetails?.cowriter_quanity_limit}} </strong></span></h3>
            </div>
            <div class="title" *ngIf="userDetails?.unlimited_cowriters === true">
                <h3>Existing Co-writers<span class="allfields-cow"><small>Unlimited</small></span></h3>
            </div>
           
            <div  class="existingcowriter" *ngFor="let coWriter of allCoWriter">
                <p>{{coWriter?.first_name}} {{coWriter?.last_name}} - {{coWriter.is_active === true ? 'Active': 'Pending'}}
                    <!-- <button type="button" class="close-button" (click)="deleteCoWriter(coWriter.id)">
                        <svg width="10px" height="10px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                                <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                                    <g id="Genre-Options">
                                        <g id="Close" transform="translate(1439.000000, 32.000000)">
                                            <path d="M24,0 L0,24" id="Path"></path>
                                            <path d="M0,0 L24,24" id="Path"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </button> -->
                        <span class="coWriter-email"> {{coWriter?.email}} </span>
                </p>
            </div>
           
                <div class="co-writersnoti"  *ngIf="(userDetails?.unlimited_cowriters === false ) && (allCoWriter?.length !== userDetails?.cowriter_quanity_limit) ">
                    <h3><span class="allfields-co"><small>*You can add a total of {{userDetails?.cowriter_quanity_limit}} co-writers </small></span></h3>
                </div>
           
            
            
        </div>
        </div>
</div>
</form>
    

