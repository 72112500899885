import { MibeModel } from './mibemodel.model';
import { User } from './user.model';

export class EmailGroup implements MibeModel {
  id: number;
  name: string;
  type: string;
  created_at: Date;
  users = [];

  /**
   * Create a EmailGroup.
   * @param {any} json - The JSON object with which to construct our EmailGroup.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a EmailGroup.
   * @param {any} json - The JSON object with which to load our EmailGroup.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this.id = json.id;
    this.name = json.name;
    this.type = json.type;
    this.created_at = json.created_at ? new Date(json.created_at):null;
    this.users = json.users;
  }
  /**
   * Returns a JSON representation of our EmailGroup
   * @return {any} - The JSON representation of our EmailGroup
   */
  toJSON() {
    var json: any = {
      'id': this.id,
      'name': this.name,
      'type': this.type,
      'users': this.users
    };
    if (this.created_at) {
      json['created_at'] = this.created_at.toJSON();
    }
    return json;
  }

  getDisplayName(): string {
    if (!this || !this.id) return '';
    return `${this.formatDate(this.created_at)} - ${this.name}`;
  }

  formatDate(d:Date): string {
    return `${d.getMonth()+1}/${d.getDate()}/${d.getFullYear().toString().substr(-2)}`;
  }
}
