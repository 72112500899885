import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpParams, HttpEventType } from '@angular/common/http';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../environments/environment';
import { User } from '../models/user.model'
import { Vocals } from '../models/vocals.model';
import { APIListResponse } from '../models/apiresponse.model';
import { UserService } from './user.service';
import { Observable, BehaviorSubject, ReplaySubject, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VocalsService {

  constructor(
    private http: HttpClient,
    private _platform: Platform,
    private _transferState: TransferState
) {
}

getAllReferences(): Observable<Vocals[]> {
    return this.getReferences({});
}

private getReferences(params: {[param: string]: string | string[]}): Observable<Vocals[]> {
    let p = new HttpParams({ fromObject: params });
    // Check if genres are cached from server
    const VOCALS_KEY = makeStateKey<APIListResponse<Object>>('vocals');
    if (this._transferState.hasKey(VOCALS_KEY)) {
        const references = this._transferState.get<APIListResponse<Object>>(VOCALS_KEY, null);
        this._transferState.remove(VOCALS_KEY);
        return of(references.results.map((s) => new Vocals(s)));
    }

    let headers = new HttpHeaders();
    return this.http.get<APIListResponse>(
        environment.apiURL + '/api/v1/instruments/',
        {
            headers: headers
        }
        ).pipe(
        tap(res => {
            // If we're on the server cache the genres
            if (!this._platform.isBrowser) {
            this._transferState.set(VOCALS_KEY, res);
            }
        }),
        map((res) => res.results.map((s) => new Vocals(s)))
        );
}
}
