import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlaylistFolder } from '../models/playlistfolder.model';
import { DialogService } from '../services/dialog.service';
import { PlaylistFolderService } from '../services/playlistfolder.service';

export interface DialogData {
  isEdit: boolean;
  folder?: PlaylistFolder;
}

@Component({
  selector: 'app-new-playlist-folder-dialog',
  templateUrl: './new-playlist-folder-dialog.component.html',
  styleUrls: ['./new-playlist-folder-dialog.component.scss']
})
export class NewPlaylistFolderDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<NewPlaylistFolderDialogComponent>,
    private _playlistFolderService: PlaylistFolderService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData ,public dialog: DialogService,
  ) {}

  loading = false;
  name: string;
  error: string;


  ngOnInit(): void {
    this.error = null;
    if (this.data.folder) {
      this.name = this.data.folder.name;
    }
  }

  create() {
    if (!this.name) {
      this.error = "Name is required";
      return;
    }
    this.error = null;
    this.loading = true;
    if (this.data.isEdit && this.data.folder) {
      let folder = new PlaylistFolder(this.data.folder.toJSON());
      folder.name = this.name;
      this._playlistFolderService.updatePlaylistFolder(folder).subscribe(folder => {
        this.loading = false;
        this.dialogRef.close(folder);
      }, error => {
        this.loading = false;
        let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details :(error?.error.name ? error?.error?.name : '') ))
        this.dialog.UserUploadPopupConfirmPopup(data).subscribe(changed => {
        });
        this.dialogRef.close();

       // this.error = "There was an error trying to edit your playlist folder. Please check your internet connection and try again";
      });
      return;
    }
    this._playlistFolderService.createPlaylistFolder(this.name).subscribe(folder => {
      localStorage.setItem("newFoldername",this.name)
     this.loading = false;
     this.dialogRef.close(folder);
    }, error => {
      this.loading = false;
      let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details :(error?.error.name ? error?.error?.name : '') ))
      this.dialog.UserUploadPopupConfirmPopup(data).subscribe(changed => {
      });
      this.dialogRef.close();
      // this.error = "There was an error trying to create your playlist folder. Please check your internet connection and try again";
    })
  }

  close() {
    this.dialogRef.close(null);
  }

}
