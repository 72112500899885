<div class="track-list">
<mat-table *ngIf="listDataSource['length'] !== 0" [dataSource]="listDataSource" matSort class="track-list green-theme" multiTemplateDataRows [trackBy]="listTrackBy">
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef mat-sort-header>User Name</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <mat-checkbox class="dark-theme" [checked]="userIsChecked(row.user)" (change)="userChecked($event.checked, row.user)"></mat-checkbox>
      <span class="email">{{ row.user.getFullName() }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="company_name">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Client</mat-header-cell>
    <mat-cell *matCellDef="let row"><span class="email">{{row.user.company_name}}</span></mat-cell>
  </ng-container>

  <ng-container matColumnDef="project_name">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Show</mat-header-cell>
    <mat-cell *matCellDef="let row"><span class="email">{{getShows(row.user.shows)}}</span></mat-cell>
  </ng-container>

  <ng-container matColumnDef="discount_rate">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Rate</mat-header-cell>
    <mat-cell *matCellDef="let row"><span class="email">{{row.user.discount_rate}}%</span></mat-cell>
  </ng-container>

  <ng-container matColumnDef="date_joined">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Created</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.user.date_joined | date: 'shortDate'}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="last_login">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Visited</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.user.last_login | date: 'shortDate'}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="is_disable">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.user.is_disable ? 'Inactive' : 'Active'}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="can_download">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Download</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <mat-slide-toggle class="dark-theme" [(ngModel)]="row.user.can_download" (change)="changeCanDownload(row.user)"></mat-slide-toggle>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="action">
    <mat-header-cell *matHeaderCellDef>
      <div class="header-action">
        <button class="action" *ngIf="checkedClients && checkedClients.length > 0" (click)="deselectAll()">Deselect all ({{checkedClients.length}})</button>
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let row">
      <div class="actions">
        <button class="action" (click)="addToGroupClick(row.user)">Add To Group</button>
        <button class="action" (click)="activityClick(row.user)">Activity</button>
        <button class="action" (click)="playlistsClick(row.user)">Playlists</button>
        <div class="separated-button">
          <button type="button" matTooltip="Edit" matTooltipClass="tooltip-white" class="action-button action" (click)="editClick(row.user)">
            <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                stroke-linejoin="round">
                <g transform="translate(1.000000, 1.000000)" class="inner-stroke">
                  <path
                    d="M14.4,10.128 L14.4,14.4 C14.4,15.2836556 13.6836556,16 12.8,16 L1.6,16 C0.7163444,16 0,15.2836556 0,14.4 L0,3.2 C0,2.3163444 0.7163444,1.6 1.6,1.6 L5.872,1.6">
                  </path>
                  <polygon points="12.8 0 16 3.2 8 11.2 4.8 11.2 4.8 8"></polygon>
                </g>
              </g>
            </svg>
          </button>
        </div>
        <div class="separated-button">
          <button type="button" matTooltip="Delete" matTooltipClass="tooltip-white" class="action-button action" (click)="deleteClick(row.user)">
            <svg width="15px" height="20px" viewBox="0 0 12 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>Delete</title>
              <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                  <g id="Email-Mgmt-Desk-User-Client-Card" class="inner-stroke" transform="translate(-1288.000000, -16.000000)">
                      <g id="Actions" transform="translate(1016.000000, 0.000000)">
                          <g id="Group" transform="translate(270.000000, 16.000000)">
                              <g id="trash-2" transform="translate(2.000000, 1.000000)">
                                  <polyline id="Path" points="0 2.8 1.33333333 2.8 12 2.8"></polyline>
                                  <path d="M10.6666667,2.8 L10.6666667,12.6 C10.6666667,13.3731986 10.069713,14 9.33333333,14 L2.66666667,14 C1.930287,14 1.33333333,13.3731986 1.33333333,12.6 L1.33333333,2.8 M3.33333333,2.8 L3.33333333,1.4 C3.33333333,0.62680135 3.930287,0 4.66666667,0 L7.33333333,0 C8.069713,0 8.66666667,0.62680135 8.66666667,1.4 L8.66666667,2.8" id="Shape"></path>
                                  <line x1="4.66666667" y1="6.3" x2="4.66666667" y2="10.5" id="Path"></line>
                                  <line x1="7.33333333" y1="6.3" x2="7.33333333" y2="10.5" id="Path"></line>
                              </g>
                          </g>
                      </g>
                  </g>
              </g>
          </svg>
          </button>
        </div>
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns" class="main-row" [@rowInOut]></mat-row>
</mat-table>
</div>
<div class="text-center" *ngIf="listDataSource['length'] === 0">No data is available</div>
<div class="overlay" *ngIf="loadingOverlay">
  <app-loader></app-loader>
</div>