import {
  Component, OnInit, OnDestroy, Output, Input, ViewChild,
  ElementRef, NgZone, EventEmitter, Pipe, PipeTransform
} from '@angular/core';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSortable } from '@angular/material/sort';
import { Track } from '../shared/models/track.model';
import { DialogService } from '../shared/services/dialog.service';
import { GetPlaylistsOptions, PlaylistService } from '../shared/services/playlist.service';
import { PlaylistTracksService, GetPlaylistTracksOptions } from '../shared/services/playlisttracks.service';
import { GetPlaylistFoldersOptions, PlaylistFolderService } from '../shared/services/playlistfolder.service';
import { environment } from '../../environments/environment';
import { UserMenuService } from '../shared/services/user-menu.service';
const tracksLimit = 30;

import {
  TrackService,
  Filters,
  MinMax,
  GetTracksOptions,
  filtersToParams,
  areFiltersEqual,
} from '../shared/services/track.service';
import { Playlist } from '../shared/models/playlist.model';
import { PlaylistTracks } from '../shared/models/playlisttracks.model';
import { PlaylistFolder } from '../shared/models/playlistfolder.model';
import { TrackVersion } from '../shared/models/trackversion.model';
import { P } from '@angular/cdk/keycodes';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { FormControl } from '@angular/forms';
import { PlayerService, PlayingItem } from '../shared/services/player.service';
import { Platform } from '@angular/cdk/platform';
import { UserService } from '../shared/services/user.service';
import { User } from '../shared/models/user.model';
import { saveAs } from 'file-saver';


const tracksPageSize = 30;
// const playListPageSize = 20; // change as 25 for pagination
// const folderPageSize = 20; // change as 25 for pagination
const playListPageSize = 25; // change as 25 for pagination
const folderPageSize = 25; // change as 25 for pagination

const clientLimit = 30;

declare var MediaMetadata: any;
@Pipe({
  name: 'minuteSeconds'
})
export class MinuteSecondsPipe implements PipeTransform {
  transform(value: number): string {
    let numhours = Math.floor(value / 3600);
    let numminutes = Math.floor((value % 3600) / 60);
    let numseconds = Math.round((value % 3600) % 60);
    let str = '';
    if (numhours) {
      str = this.zeroPad(numhours, 2) + ':' + this.zeroPad(numminutes, 2) + ':' + this.zeroPad(numseconds, 2);
    } else {
      str = this.zeroPad(numminutes, 2) + ':' + this.zeroPad(numseconds, 2);
    }
    return str;
  }
  private zeroPad(num, minLength): string {
    let numStr = '' + num;
    while (numStr.length < minLength) {
      numStr = '0' + numStr;
    }
    return numStr;
  }
}
@Component({
  selector: 'app-playlist',
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.scss']
})


export class PlaylistComponent implements OnInit, OnDestroy {
  maxDate = new Date();
  loadingTracks = true;
  loadingPlaylists = false;
  showNoData = false;
  loadingCount = true;
  loadingFolders = false;
  searchFilterFocused = false;
  musicPlayerCollapsed = false;
  disabled = false;
  trackCount: any = 0;
  currentValueItem:any
  composerCount: any = 0;
  activeclientCount: any = 0;
  allData: any;
  newfolderval: any;
  mp3_file: any;
  trackName: string
  enableplay: boolean = false;
  isplay = false;
  private _isPlaying = false;
  private _cancelAnimationFrame: number;
  private _playbackProgress = 0;
  switchCase = false;
  playTrack = false;

  @Input()
  set getCurrent(p: number) {
    this.currentTime = p;
  }
  @Input()
  set getDuration(p: number) {
    this.duration = p;
  }

  set enablePlay(p: boolean) {
    this.enableplay = p;
  }
  get enablePlay(): boolean {
    return this.enableplay;
  }

  @Input()
  set playbackProgress(p: number) {
    this._playbackProgress = p;
    //  let duration = this.version.duration;
    if (!this._platform.isBrowser) { return; }
    if (!isNaN(this.audioEl.nativeElement.duration) && this.audioEl.nativeElement.duration != Infinity) {
      //duration = this.audioEl.nativeElement.duration;
    }
    // let newTime = duration * p;
    // this.audioEl.nativeElement.currentTime = newTime;
    //this.currentTime = this.audioEl.nativeElement.currentTime;
    this._playerService.updatePlaybackProgress(this._playbackProgress);
  }
  get playbackProgress(): number {
    return this._playbackProgress;
  }
  currentTime: number = 0
  duration: number = 0;
  @Output()
  collapse = new EventEmitter();


  @ViewChild('audioEl', { static: true })
  audioEl: ElementRef<HTMLAudioElement>;


  @Output() demo = new EventEmitter<string>();
  //  @Output()
  //  update:EventEmitter<string> = new EventEmitter<string>();
  get apiURL(): string {
    return environment.apiURL;
  }

  set activeTab(t: 'folders' | 'playlists') {
    this._currentTab = t;
    // this._router.navigate([], {
    //   relativeTo: this._activatedRoute,
    //   queryParams: this._params,
    // });
  }
  get activeTab() {
    return this._currentTab;
  }

  set currentPlaylist(p: Playlist) {
    if(p === null){
      this.loadingFolders = false;
      this.totalPlaylists = 0;
      this.showNoData = true;
       this.trackCount = 0;
       this.composerCount = 0;
       this.activeclientCount.length = 0;
    //   this.currentPlaylist = null;
       this.currentValueItem = null;
       this._currentPlaylist = null
      return
    }
    if (this._currentPlaylist && this._currentPlaylist.id == p.id && ( this.currentPlaylist !== null || this.currentValueItem !== null)) {
      return;
    }
    if( this._activatedRoute.snapshot.queryParams.playlist &&  this.currentUser === null && ( this.currentPlaylist !== null || this.currentValueItem !== null)){
      return;
    }
    if( this._activatedRoute.snapshot.queryParams.playlist  &&  this.currentUser !== null && ( this.currentPlaylist !== null || this.currentValueItem !== null)){
      this.getAllplaylist( this._activatedRoute.snapshot.queryParams.playlist)
     
    //  this._currentPlaylist = p;
      //this.loadTracks();
      // this._router.navigate([], {
      //   relativeTo: this._activatedRoute,
      //   queryParams: this._params,
      // });
    //  return
    }
    // this.currentValueItem =  this._activatedRoute.snapshot.queryParams.playlist
    // console.log(this._activatedRoute.snapshot.queryParams.playlist)
    if( ( this.currentPlaylist !== null || this.currentValueItem !== null)){
    this._currentPlaylist = p;
    this.loadTracks();
    }
    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: this._params,
    });
  }
  get currentPlaylist(): Playlist {
    return this._currentPlaylist;
  }
  // get previousItem(): PlayingItem {
  //   return this._playerService.previousItem;
  // }
  // get nextItem(): PlayingItem {
  //   return this._playerService.nextItem;
  // }
  // set currentPlaylistFolder(p:PlaylistFolder[]) {
  //   if (this._currentPlaylist && this._currentPlaylist.id == p.id) {
  //     return;
  //   }
  //   this._currentPlaylist = p;
  //   this.loadTracks();
  //   this._router.navigate([], {
  //     relativeTo: this._activatedRoute,
  //     queryParams: this._params,
  //   });
  // }
  // get currentPlaylistFolder(): PlaylistFolder {
  //   return this._currentPlaylist;
  // }
  get startDate() {
    return this._startDate;
  }
  set startDate(d: Date) {
    this._startDate = d
    this._currentTracksPage = 1;
    // this.loadPlaylistOrFolder();
    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: this._params,
    });
  }
  get endDate() {
    return this._endDate;
  }
  set endDate(d: Date) {

    this._endDate = d
    this._currentTracksPage = 1;
    // this.loadPlaylistOrFolder();
    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: this._params,
    });
  }

  totalPlaylists = 0;
  playlistCount = 0;
  currentPlaylistsPageval = 0;
  set playlists(p: Playlist[]) {
    this._playlists = p;
    if (!this.currentPlaylist && p && p.length > 0) {
      // if( this._activatedRoute.snapshot.queryParams.playlist  &&  this.currentUser == null){
      //   for(let i= 0;i<p.length;i++){
      //     if( Number(this._activatedRoute.snapshot.queryParams.playlist) === p[i].id){
      //       this.currentPlaylist = p[i];
      //       this.currentValueItem =  p[i].id
      //       console.log(this._activatedRoute.snapshot.queryParams.playlist)
      //     }
      //   }
      // }
      // else{
      //   this.currentPlaylist = p[0];
      //   this.currentValueItem = p[0].id
      // }
      this.currentPlaylist = p[0];
      this.currentValueItem = p[0].id
    }
    else{
      if( this._activatedRoute.snapshot.queryParams.playlist  &&  this.currentUser !== null){
        for(let i= 0;i<p.length;i++){
          if( Number(this._activatedRoute.snapshot.queryParams.playlist) === p[i].id){
            this.currentPlaylist = p[i];
            this.currentValueItem =  p[i].id
            console.log(this._activatedRoute.snapshot.queryParams.playlist)
          }
        }
      }
      // else{
      //   this.currentPlaylist = p[0];

      // }
    }
   
  }
  get playlists(): Playlist[] {
    return this._playlists;
  }
  totalFolders = 0;
  temp_totalFolderscount = 0;
  expandedFolders: number[] = [];
  hoveringFolder: number;
  set folders(f: PlaylistFolder[]) {
    this._folders = f;
  }
  get folders(): PlaylistFolder[] {
    return this._folders;
  }
  set currentPlaylistsPage(p: number) {
    if (!isNaN(p) && p != this._currentPlaylistsPage) {
      this._currentPlaylistsPage = p;
      // this.loadTracks();
      this.loadPlaylistOrFolder('pagination');
      this._router.navigate([], {
        relativeTo: this._activatedRoute,
        queryParams: this._params,
      });
    }
  }
  get currentPlaylistsPage(): number {
    return this._currentPlaylistsPage;
  }
  get playlistsNumberOfPages(): number {
    if (this.activeTab === 'playlists') {
      return Math.ceil(this.playlistCount / playListPageSize);
    } else {
      return Math.ceil(this.totalFolders / folderPageSize);
    }
  }

  totalTracks = 0;
  set currentTracksPage(p: number) {
    if (!isNaN(p) && p != this._currentTracksPage) {
      this._currentTracksPage = p;
      this.loadTracks();
      this._router.navigate([], {
        relativeTo: this._activatedRoute,
        queryParams: this._params,
      });
    }
  }
  get currentTracksPage(): number {
    return this._currentTracksPage;
  }
  get tracksNumberOfPages(): number {
    return Math.ceil(this.totalTracks / tracksPageSize);
  }
  tracks: Observable<ReadonlyArray<PlaylistTracks>>;

  get totalComposers() {
    return
  }

  get ordering(): string {

    return this._ordering;
  }
  set ordering(o: string) {
    if (o != this._ordering) {
      this._ordering = o;
      this._currentTracksPage = 1;
      this.loadTracks();
      this._router.navigate([], {
        relativeTo: this._activatedRoute,
        queryParams: this._params,
      });
    }
  }

  get searchString(): string {
    return this._searchString;
  }
  set searchString(s: string) {
    if (this._searchString != s) {
      this._searchString = s;
    }
  }

  get playingTrack(): Track {
    if (!this._playingTrack || !this._playingTrack.track) return null
    //console.log(this._playingTrack.track)
    this.playlist = true
    this.newData = this._playingTrack;
    return this._playingTrack.track;
  }

  get playingTrackVersion(): TrackVersion {
    if (!this.playingTrack || !this._playingTrack.track.versions || this._playingTrack.track.versions.length <= 0) return null;
    // console.log(this._playingTrack.track)
    return this._playingTrack.track.versions[0];
  }

  private get _params() {
    let params = {};
    if (this.ordering && this.ordering !== 'id') {
      params['ordering_by'] = this.ordering;
    }
    if (this.activeTab && this.activeTab !== 'playlists') {
      params['tab'] = this.activeTab;
    }
    if (this.currentPlaylist) {
      params['playlist'] = this.currentPlaylist.id;
    }
    if (this.ordering) {
      params['ordering_by'] = this.ordering;
    }
    if (
      this.currentPlaylistsPage &&
      this.currentPlaylistsPage != 1 &&
      this.activeTab == "playlists"
    ) {
      params["page"] = `${this.currentPlaylistsPage}`;
    }
    if (this.currentTracksPage && this.currentTracksPage != 1 && this.activeTab != 'playlists') {
      params['page'] = `${this.currentTracksPage}`;
    }
    if (this.searchString) {
      params['search'] = this.searchString;
    }
    if (this.startDate) {
      params['start_date'] = this.startDate.toISOString();
    }
    if (this.endDate) {
      params['end_date'] = this.endDate.toISOString();
    }

    return params;

  }
  private _ordering: string;
  private _currentTracksPage = 1;
  private _currentPlaylistsPage = 1;
  private _currentTab: 'playlists' | 'folders' = 'playlists';
  private _currentPlaylist: Playlist = null;
  private _searchString = '';
  private _startDate: Date;
  private _endDate: Date;
  public playlist: boolean = false;
  private _playlists: Playlist[];
  private _folders: PlaylistFolder[];
  public _playingTrack: PlaylistTracks;
  public newData: any;
  private _getTracksSubscription: Subscription;
  trackresult: any;
  isPlaying: boolean = false;
  currentUser: User;
  private _tracksSubject: BehaviorSubject<
    ReadonlyArray<PlaylistTracks>
  > = new BehaviorSubject<ReadonlyArray<PlaylistTracks>>([]);

  private _subscriptions: Subscription[] = [];

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router, private _platform: Platform,
    private _ngZone: NgZone,
    public _userService: UserService,
    private _trackService: TrackService,
    private _playlistService: PlaylistService,
    private _playlistTracksService: PlaylistTracksService,
    private _playlistFolderService: PlaylistFolderService,
    private _dialogService: DialogService,
    public http: HttpClient,
    public api: UserMenuService, public _playerService: PlayerService,

  ) {

    this._userService.currentUserStream.subscribe((u) => {
      console.log(u)
      this.currentUser = u;
    }),
    this.tracks = this._tracksSubject.asObservable();
    // if( this._activatedRoute.snapshot.queryParams.playlist){
    //   this.getAllplaylist( this._activatedRoute.snapshot.queryParams.playlist)
    //   }
    //   this.currentValueItem =  this._activatedRoute.snapshot.queryParams.playlist
    // console.log(this._activatedRoute.snapshot.queryParams.playlist)
  }

  ngOnInit(): void {
    this._subscriptions.push(
      this._activatedRoute.queryParamMap.subscribe((params) => {
        let start_date = params.get('start_date');
        let end_date = params.get('end_date');
        if (start_date) {
          this.startDate = new Date(start_date);
        }
        if (end_date) {
          this.endDate = new Date(end_date);
        }
        let tab = params.get('tab');
        if (tab) {
          this.activeTab = tab as ('playlists' | 'folders');
        }
        let playlist = params.get('playlist');
        let playlistId: number = null;
        if (playlist !== null) {
          playlistId = parseInt(playlist);
          // this.getAllplaylist(playlistId);
          if (!isNaN(playlistId)) {
            this._playlistService.getPlaylist(playlistId, true).subscribe(p => {
              this.temp_totalFolderscount = this.totalFolders;
              this.currentPlaylist = p;
              this.trackCount = p.track_count;
              this.composerCount = p.composer_count;
              this.activeclientCount = p.active_clients;
              //   localStorage.setItem('newFoldername','')
              // alert(p.track_count)
              this.loadingCount = true;
            }, (error) => {
              console.log(error);
              //alert("An error ocurred while getting the playlist. Check your connection and try again")
            })
          }
        }

        let ordering = params.get('ordering_by');
        this.ordering = ordering;

        let playlistpage = params.get('page');
        if (playlistpage !== null) {
          this.currentPlaylistsPage = parseInt(playlistpage);
        } else {
          this.currentPlaylistsPage = 1;
        }

        let trackspage = params.get('page');
        if (trackspage !== null) {
          this.currentTracksPage = parseInt(trackspage);
        } else {
          this.currentTracksPage = 1;
        }

        let searchString = params.get('search');
        if (searchString !== null) {
          this.searchString = searchString;
        } else {
          this.searchString = '';
        }
      })
    );
    this._subscriptions.push(
      this._playerService.isPlayType.subscribe((p) => {
        this.isPlaying = p;
      }))
    this._subscriptions.push(
      this._playerService.enablePlay.subscribe((p) => {
        this.enableplay = p;
      }))
    this.switchTab(this.activeTab,'start');
   if( this._activatedRoute.snapshot.queryParams.playlist){
    this.getAllplaylist( this._activatedRoute.snapshot.queryParams.playlist)
    }
    else{
      this.loadTracks();
    }
    
  }

  iscurrentPlaylist(count) {
    return this.currentPlaylist.track_count;
  }
  clear() {
    this.searchString = '';
    this.startDate = null;
    this.endDate = null;
    this.submitSearch();
    // this._playerService.play(null, null);
  }
  ngOnDestroy(): void {
    this._subscriptions.forEach((s) => s.unsubscribe());
    this._subscriptions = [];
  }
  setcurrentplaylist(playlist) {
    this.currentPlaylist = playlist;
  }


  loadPlaylists(pagination?, val:any='') {
    let params: GetPlaylistsOptions = {
      // ordering: '-created_at',
      // limit: playListPageSize,
      offset: (this.currentPlaylistsPage - 1) * playListPageSize,
      includeComposerCount: true
    };

    if (this.searchString && this.searchString != '') {
      params.search = this.searchString;
      this.currentPlaylistsPageval = 1;
        params.page = this.currentPlaylistsPage as any
    }
    if (this.startDate) {
      params.startDate = this.startDate;
    }
    if (this.endDate) {
      params.endDate = this.endDate;
    }
    if (!this.searchString) {
      if (this.currentPlaylistsPage && (!this.searchString || this.searchString == '')) {
        this.currentPlaylistsPageval = this.currentPlaylistsPage;
        params.page = this.currentPlaylistsPage as any
      }
    }
    if (this.ordering) {
      params.ordering_by = this.ordering
    }
    this._playlistService.getAllPlaylists(params).subscribe(
      (p) => {
        if (p.results && p.results.length > 0) {
          this.loadingPlaylists = true;
          if (pagination == 'pagination' && p.results.length > 0) {
            this._playlistService.getAllPlaylists(params).subscribe(
              (p) => {
                this.totalPlaylists = p.count;
                this.playlistCount = p.count;
                this.playlists = p.results;

                this.loadingPlaylists = false;
                if(this.switchCase === true){
                this.currentValueItem = p.results[0].id
                }
                if(val == 'new'){
                this.currentValueItem = p.results[0].id
                }
                this.getAllplaylist(p.results[0].id);
              });
          } else {


            if (this.loadingPlaylists && p.results.length > 0 && (!pagination || pagination != 'pagination')) {
              // alert(';if')
              this.totalPlaylists = p.count;
              this.playlistCount = p.count;

              this.playlists = p.results;

              this.loadingPlaylists = false;
              if(this.switchCase === true){
                this.currentValueItem = p.results[0].id
                }
              this.getAllplaylist(p.results[0].id);
            } else {
              this.totalPlaylists = p.count;
              this.playlistCount = p.count;

              this.playlists = p.results;
              // if(this.currentPlaylistsPageval > 1){
              // //  alert(';elde if')
              //   this.loadingPlaylists = false;

              //   this.getAllplaylist(p.results[0].id);

              // }
              // else{
              // alert(';els')

              this.loadingPlaylists = false;
              this.getAllplaylist('');
              //  }

            }
          }
        }
        else {
          this.totalPlaylists = 0;
          this.playlistCount = 0;
          this.playlists = [];
          this.trackCount = 0;
          this.composerCount = 0;
          this.activeclientCount.length = 0;
          this.loadingPlaylists = false;
          this.currentPlaylist.composer_count = 0
          this.getAllplaylist('');
          this.musicPlayerCollapsed = false;
          this.enableplay = false;
          this._playerService.updateShowMusicPlayer(false);
          this._playerService.playList(null);
        }
      },
      (error) => {
        console.log(error);
        //alert("An error ocurred while getting the playlists. Check your connection and try again");
      }
    );
  }

  loadFolders(val?: any) {
    // this.loadingFolders = true;
    let params: GetPlaylistFoldersOptions = {
      // ordering: '-created_at',
      // limit: folderPageSize,
      offset: (this.currentPlaylistsPage - 1) * folderPageSize,
      // page: this.currentPlaylistsPage, //pagination for playlist folder
      includeComposerCount: true
    };
    if (this.searchString && this.searchString != '') {
      params.search = this.searchString;
    }
    if (this.startDate) {
      params.startDate = this.startDate;
    }
    if (!this.searchString) {
      if (this.currentPlaylistsPage) {
        this.currentPlaylistsPageval = this.currentPlaylistsPage;
        params.page = this.currentPlaylistsPage as any
      }
    }
    if (this.endDate) {
      params.endDate = this.endDate;
    }
    this._playlistFolderService
      .getAllPlaylistFolders(params)
      .subscribe((f) => {
       this.totalFolders = f.count;
      //  this.totalPlaylists = f.count;
        this.folders = f.results;
//---new
        if (!this.totalFolders) {
          this.loadingFolders = false;
          this.totalPlaylists = 0;
          this.showNoData = false;
           this.trackCount = 0;
           this.composerCount = 0;
           this.activeclientCount.length = 0;
           this.currentPlaylist = null;
           this.currentValueItem = null;

          //  this.playlists = []; 
          //  this.loadingPlaylists = false;
          //  this.currentPlaylist.composer_count = 0
          //  this.getAllplaylist('');
          //  this.musicPlayerCollapsed = false;
          //  this.enableplay= false;
         
          return
        }
        if (val == 'delete') {
          this._playlistFolderService
            .getAllPlaylistFolders(params)
            .subscribe((f) => {
              this.totalFolders = f.count;
              this.folders = f.results;
            });
          this.expandedFolders = [];
          this.expandedFolders.push(f.results[0].id);
          this.totalFolders = f.count;
          this.folders = f.results;
          if (f.results[0].playlists.length > 1) {
            this.loadingFolders = false;

            this.getAllplaylist(f.results[0].playlists[0].id);
            location.reload()

          } else {
            this.showNoData = true;
            this.loadingFolders = false;

          }
          return;
        } else if (val == 'add') {
          this.showNoData = true;
          this.expandedFolders = [];
          this.expandedFolders.push(this.newfolderval.id);
          this.loadingFolders = false;
        } else if (val == 'pagination') {
          this._playlistFolderService
            .getAllPlaylistFolders(params)
            .subscribe((f) => {
              this.totalFolders = f.count;
              this.folders = f.results;
            });
          this.expandedFolders = [];
          this.expandedFolders.push(f.results[0].id);
          this.totalFolders = f.count;
          this.folders = f.results;
          if (f.results[0].playlists.length > 0) {
            this.showNoData = false;

            this.loadingFolders = false;
            this.getAllplaylist(f.results[0].playlists[0].id);
          } else {
            this.showNoData = true;
            this.loadingFolders = false;
          }

          return;
        } else {
          if (this.loadingFolders && f.results.length > 0) {
            if (this.activeTab == 'playlists') {
              //first and refresh data
              if (this.loadingPlaylists) {
                // if( this._activatedRoute.snapshot.queryParams.playlist){
                //   this.getAllplaylist( this._activatedRoute.snapshot.queryParams.playlist);
                // }
                // else{
                //   this.getAllplaylist(f.results[0].playlists[0].id);
                // }
                this.getAllplaylist(f.results[0].playlists[0].id);
                this.loadingPlaylists = false;

              } else {
                // if( this._activatedRoute.snapshot.queryParams.playlist){
                //   this.currentPlaylist.id = this._activatedRoute.snapshot.queryParams.playlist
                // }
                this.getAllplaylist(this.currentPlaylist.id);
                this.loadingPlaylists = false;
              }

            } else {
              if (this.expandedFolders.length == 0) {
                this.expandedFolders.push(f.results[0].id);
                if (f.results[0].playlists.length > 0) {
                  this.showNoData = false;
                  this.loadingFolders = false;
                  this.currentValueItem = f.results[0].playlists[0].id
                  this.getAllplaylist(f.results[0].playlists[0].id);
                } else {
                  this.showNoData = true;
                  this.loadingFolders = false;

                }
              } else {
                this.showNoData = false;
                this.loadingFolders = false;
                this.getAllplaylist(this.currentPlaylist.id);
                this.totalPlaylists = 0;
              }
            }
          }






          //------old

          // if (!this.loadingPlaylists && f.results.length > 0 ) {
          //   if(this.expandedFolders.length == 0){
          //     this.expandedFolders.push(f.results[0].id);
          //     this.loadingFolders = false;  
          //     this.getAllplaylist(f.results[0].playlists[0].id);  
          //   }else{

          //     // let val=this.expandedFolders.map(id => f.results.find(f => f.id == id)).filter(f => !!f)
          //     var folderIDs =f.results.map(f => f.id); 
          //     var folderval=this.getFilterdata(folderIDs,this.currentPlaylist.id)
          //     console.log("val=========="+JSON.stringify(folderIDs)+"folderval==="+folderval)
          //     // let i = f.results.indexOf(this.currentPlaylist.id);
          //     // if(f.results.includes(this.currentPlaylist.id))
          //     //Delete folder handle
          //     if(folderval == null){
          //       this.totalFolders = f.count;
          //       this.folders = f.results;
          //       console.log("f.results==="+JSON.stringify(f.results))
          //       this.expandedFolders.push(f.results[0].id);
          //       this.loadingFolders = false;  
          //       this.getAllplaylist(f.results[0].playlists[0].id);

          //     }else{
          //       alert('inelse if of'+this.expandedFolders)
          //       alert(this.temp_totalFolderscount +'this.temp_totalFolderscount == this.totalFolders'+ this.totalFolders)
          //       this.showNoData=false;
          //       this.loadingFolders = false; 
          //       this.getAllplaylist(this.currentPlaylist.id);


          //       // if(this.temp_totalFolderscount == this.totalFolders){
          //       //   this.temp_totalFolderscount=this.totalFolders;
          //       //   this.getAllplaylist(this.currentPlaylist.id);
          //       // } else{
          //       //   this.temp_totalFolderscount=this.totalFolders;
          //       //   this.getAllplaylist(this.currentPlaylist.id);

          //       // }
          //       // this.showNoData = false;

          //     }

          //   }

          // }else if (!this.loadingPlaylists && f.results.length == 0){
          //   this.loadingFolders = false;
          //   this.showNoData = true;
          // }else{
          //   this.loadingFolders = false;
          //   if(f.results.length == 0){
          //     this.showNoData = true;
          //   }else{
          //     this.showNoData = false;
          //     this.getAllplaylist(this.currentPlaylist.id);
          //   }
          // }


          ///---old
          // this.expandedFolders.push(f.results[0].id);
          // if (!this.loadingPlaylists && p.results.length > 0) {
          // if (!this.loadingPlaylists && f.results.length > 0) {
          //   alert("in if")
          //   this.loadingFolders = false;
          //   if (f.results[0].playlists.length == 0) {
          //     alert("in if 1")

          //     this.showNoData = true;
          //   } else {
          //     alert("in if 2")

          //     this.showNoData = false;
          //     this.toggleFolder(f.results[0].id,f.results[0].playlists)
          //   }
          // }else{
          //   alert('else 1')
          //   this.loadingFolders = false;
          //   if (f.results.length > 0) {            alert('else 12')

          //     this.getAllplaylist(this.currentPlaylist.id);
          //   } else {            alert('else 13')

          //     this.showNoData = true;
          //   }
          // }
          // if(this.loadingFolders){
          // }
          // this.isFolderOpen(f.results[0].id);
          // this.expandedFolders=f.results[0].id

          // alert(f.results[0].playlists.length == 0)
          // if (f.results[0].playlists.length == 0) {
          //   this.showNoData = true;
          // } else {
          //   this.showNoData = false;
          // }
          // this.loadingFolders = false;

          // alert(f.results.length>0)
          // if (f.results.length > 0) {
          //   this.getAllplaylist(this.currentPlaylist.id);
          // } else {
          //   this.showNoData = true;
        }
      }, (error) => {
        //alert("An error ocurred while getting the folders. Check your connection and try again");
      })
  }

  getFilterdata(folderIDs, id) {
    let findedData = folderIDs.find(i => i.id === id);
    if (typeof findedData === 'undefined') {
      return null;
    }
    this.loadingFolders = true;
    return findedData;
  }


  getAllplaylist(playlistId) {
    this._playlistService.getPlaylist(playlistId, true).subscribe(p => {
      // let newfoldername= localStorage.getItem('newFoldername');

      // if(this.temp_totalFolderscount != this.totalFolders && newfoldername != ''){
      //   //Delete handle/New plalist
      //  var folderval=this.folders.filter(  v => v.name === newfoldername); 
      //   this.temp_totalFolderscount=this.totalFolders;
      // if(folderval){
      //   this.expandedFolders=[];
      //   this.temp_totalFolderscount=this.totalFolders;
      //   this.expandedFolders.push(folderval[0].id);
      //   localStorage.setItem('newFoldername','')
      //   this.showNoData=true;
      // } 
      //   else{
      //     this.temp_totalFolderscount=this.totalFolders;
      //     this.currentPlaylist = p;
      //     this.trackCount = p.track_count;
      //     this.activeclientCount = p.active_clients;
      //     localStorage.setItem('newFoldername','')
      //     // alert(p.track_count)
      //     this.loadingCount = true;

      // }       
      // }else{
      this.temp_totalFolderscount = this.totalFolders;
     if( this._activatedRoute.snapshot.queryParams.playlist &&  this.currentUser === null){
      this.currentPlaylist = p
      this.currentValueItem = Number(this._activatedRoute.snapshot.queryParams.playlist)
     }
     else{
       if(this.currentValueItem === p.id){
       this._currentPlaylist = p;
       }
       if(this.currentValueItem === undefined){
        this._currentPlaylist = p;
        this.currentValueItem = p.id
       }
     }
    // this.currentValueItem =p.id
     // this.currentPlaylist = p;
      
     if(this.currentValueItem === p.id){
      this.trackCount = p.track_count;
      this.composerCount = p.composer_count;
      this.activeclientCount = p.active_clients;
     }
      // this._router.navigate([], {
      //   relativeTo: this._activatedRoute,
      //   queryParams: this._params,
      // });
      //this.totalPlaylists = this.totalFolders;
     
      if (!this.trackCount) {
        this.totalPlaylists = 0
        this.loadingTracks= false;
      }
      else {
        if(this.currentValueItem === p.id){
        this.totalPlaylists = this.playlistCount;
        this.loadingTracks= false;
        }
      }
      //   localStorage.setItem('newFoldername','')
      // alert(p.track_count)
      this.loadingCount = true;
    
      return;
      //  }

    }, (error) => {
      console.log(error);
      //alert("An error ocurred while getting the playlist. Check your connection and try again")
    })
  }

  loadTrackspagination(pageval) {

    this._getTracksSubscription = this._playlistTracksService
      .getPlaylistTrackspagination(
        pageval
      )
      .subscribe((t) => {
        // this.totalTracks = t.count;
        // this._tracksSubject.next(t.results);
        // this.trackresult = t.results;

        // this.tracks = this._tracksSubject.asObservable();

        // // this.tracks=t.results;
        // this.showNoData = false;
        // //  alert(this.currentPlaylist.id+"===37")
        // this.getAllplaylist(t.results[0].id)
        // if(t.results.length>0){
        //   this.showNoData=false;
        // }else{
        //   this.showNoData=true;

        // }
        this.loadingTracks = false;
      }, (error) => {
        console.log(error);
        //alert("An error ocurred while getting the tracks for the playlist. Check your connection and try again");
      });
  }
  loadTracks() {
    if (!this.currentPlaylist || !this.currentPlaylist.id) {
      return;
    }
    this.loadingTracks = true;
    if (this._getTracksSubscription && !this._getTracksSubscription.closed) {
      this._getTracksSubscription.unsubscribe();
    }
    let params = {
      playlistId: this.currentPlaylist.id,
      limit: tracksPageSize,
      offset: (this.currentTracksPage - 1) * tracksPageSize,
      filterval: 'getAlltrack'
    };
    if (this.ordering) {
      params['ordering_by'] = this.ordering
    }
    this._getTracksSubscription = this._playlistTracksService
      .getPlaylistTracks(
        params
      )
      .subscribe((t) => {
        // this.totalTracks = t.count;
        this._tracksSubject.next(t.results);
        this.trackresult = t.results;

        this.tracks = this._tracksSubject.asObservable();

        // this.tracks=t.results;
        this.showNoData = false;
        //this.getAllplaylist(this.currentPlaylist.id)

        if(this._activatedRoute.snapshot.queryParams.playlist){
          this.getAllplaylist(this._activatedRoute.snapshot.queryParams.playlist)
        }
        else{
          this.getAllplaylist(this.currentPlaylist.id)
        }
        // if(t.results.length>0){
        //   this.showNoData=false;
        // }else{
        //   this.showNoData=true;

        // }
        this.loadingTracks = false;
      }, (error) => {
        console.log(error);
      });

    // this._getTracksSubscription = this._playlistTracksService
    //   .getassignedTracks(
    //     this.currentPlaylist.id
    //   )
    //   .subscribe((t) => {
    //     // this.totalTracks = t.count;
    //     // this._tracksSubject.next(t.results);
    //     this.loadingTracks = false;
    //   }, (error) => {
    //     console.log(error);
    //     //alert("An error ocurred while getting the tracks for the playlist. Check your connection and try again");
    //   });
  }

  changeUrl(id:number){
    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: this._params,
    });
    this.currentValueItem =  id
    console.log(id)
    //  this._playerService.currentPlaylistItems = {};
    // this._playerService.playList(null);
    // this._playerService.setPlayerObjects(null, null);
   // this._playerService.nextPlayTab(true);
     //this.getAllplaylist(id)
  }
  sortChange(data: MatSortable) {
    this.ordering = (data.start === 'desc' ? '-' : '') + data.id;
  }

  submitSearch() {
    this.currentPlaylistsPage = 1;
    this.searchFilterFocused = false;
    this.loadPlaylistOrFolder();
    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: this._params,
    });
  }

  displayNewPlaylistFolderDialog() {
    if(this.currentUser !== null){
    this._dialogService.displayNewPlaylistFolderDialog().subscribe((folder: PlaylistFolder) => {
      if (folder) {
        this.newfolderval = folder;
        //   console.log(this.newfolderval)
        this.temp_totalFolderscount = this.totalFolders;
        this.folders.unshift(folder);
        this.totalFolders++;
        this.loadFolders('add');
        this.currentValueItem = null
        this.currentPlaylist = null
        this.loadPlaylists('pagination','new');
        this.getAllplaylist('')
        this.loadPlaylistOrFolder();
        this.loadPlaylistOrFolder();
        this.loadTracks()
      }
    });
  }
  else{
    this._router.navigate(['/login']);
  }
  }

  displayDuplicatePlaylistDialog() {
    if(this.currentUser !== null){
    this._dialogService.displayDuplicatePlaylistDialog(this.currentPlaylist).subscribe(p => {
      if(p){
        this.currentValueItem = null
        this.currentPlaylist = null
        this.loadPlaylists('pagination','new');
        this.getAllplaylist('')
        this.loadPlaylistOrFolder();
        this.loadPlaylistOrFolder();
        this.loadTracks()
      }
    });
  }
  else{
    this._router.navigate(['/login']);
  }
  }

  displayNewPlaylistDialog() {
    if(this.currentUser !== null){
    this._dialogService.displayNewPlaylistDialog().subscribe(p => {
      if(p){
        this.currentValueItem = null
        this.currentPlaylist = null
        this.loadPlaylists('pagination','new');
        this.getAllplaylist('')
        this.loadPlaylistOrFolder();
        this.loadPlaylistOrFolder();
        this.loadTracks()
        }
        this.loadPlaylists();
    });
  }
  else{
    this._router.navigate(['/login']);
  }
  }

  displayAddRemovePlaylistDialog() {
    if(this.currentUser !== null){
    this._dialogService.displayAddRemovePlaylistDialog(this.currentPlaylist, this.expandedFolders, (this.expandedFolders.map(id => this.folders.find(f => f.id == id))), this.activeTab).subscribe(changed => {
      // this.loadPlaylistOrFolder();
      if (changed) {
        this.loadingFolders = true;
        
        this.loadFolders();
        this.loadPlaylistOrFolder();
        this.loadPlaylists();
        this.getAllplaylist('')
        this.loadTracks()
        // this.loadPlaylistOrFolder();
      }
    });
  }
  else{
    this._router.navigate(['/login']);
  }
  }

  displayDeletePlaylistDialog() {
    if(this.currentUser !== null){
    if (this.activeTab == 'folders') {
      this._dialogService.displayDeletePlaylistFoldersDialog(this.expandedFolders.map(id => this.folders.find(f => f.id == id)).filter(f => !!f)).subscribe(changed => {
        if (changed) {
          this.loadFolders('delete');
          this.currentValueItem = null
          this.currentPlaylist = null
          this.loadPlaylistOrFolder();
          this.loadPlaylists();
          this.getAllplaylist('')
          this.loadTracks()
        }
      })
      return;
    } else {
      this._dialogService.displayDeletePlaylistDialog(this.currentPlaylist).subscribe(changed => {
        if (changed) {
          this.currentValueItem = null
          this.currentPlaylist = null
          this.loadPlaylistOrFolder();
          this.loadPlaylistOrFolder();
          this.loadPlaylists();
          this.getAllplaylist('')
          this.loadTracks();
        }
      });
    }
    return;
  }
  else{
    this._router.navigate(['/login']);
  }
  }

  displayManageTracksClientsDialog(val) {
    // if(val == 'tracks'){
    //   this._playlistTracksService.getPlaylistTracks({playlistId: this.currentPlaylist.id, limit: tracksLimit})
    //   .subscribe((res) => {
    //     this.allData = res.results;

    //     // this.tracks = t.results;
    //     // this.trackOptions.next(t.results);
    //     // this.loadingTracks = false;
    //   }, (error) => {
    //     console.log(error);
    //     //alert("An error ocurred while getting the tracks for the playlist. Check your connections and try again");
    //   });
    // }else{
    //   this._playlistService.getAllClients({'limit':`${clientLimit}`}).subscribe(res => {
    //      this.allData = res.results;
    //     // for(let client of this.checkedClients.reverse()) {
    //     //   if(!clients.some(c=>c.id == client.id)) {
    //     //     clients.unshift(client);
    //     //   }
    //     // }
    //     // this.clients = clients;
    //     // this.clientOptions.next(clients);
    //     // this.loadingClients = false;
    //   }, (error) => {
    //     console.log(error);
    //     //alert("An error ocurred while getting clients. Check your connections and try again");
    //   })
    // }
    if(this.currentUser !== null){
      this._dialogService.displayManageTracksClientsDialog(this.currentPlaylist, val).subscribe(changed => {
        if (changed) {
          // this.loadPlaylistOrFolder();
          // if(this._activatedRoute.snapshot.queryParams.playlist){
          //   this.currentPlaylist.id = this._activatedRoute.snapshot.queryParams.playlist
          // }
          // else{
          //   this.currentPlaylist.id =this.currentPlaylist.id
          // }
          this._playlistService.getPlaylist(this.currentPlaylist.id, true).subscribe(p => {
            this.temp_totalFolderscount = this.totalFolders;
            this.currentPlaylist = p;
            this.trackCount = p.track_count;
            this.composerCount = p.composer_count;
            this.activeclientCount = p.active_clients;
            this.loadingCount = true;
            this.loadTracks();
          }, (error) => {
            console.log(error);
            //alert("An error ocurred while getting the playlist. Check your connection and try again")
          })
        }
      });
    }
    else{
      this._router.navigate(['/login']);
    }
  }

  displayEditPlaylistDialog(playlist) {
    this._dialogService.displayEditPlaylistDialog(playlist).subscribe(changed => {
      if (changed) {
        this.loadPlaylistOrFolder();
        if( this._activatedRoute.snapshot.queryParams.playlist){
          this.getAllplaylist( this._activatedRoute.snapshot.queryParams.playlist)
          }
       // location.reload();
      }
    })
  }

  displayEditPlaylistFolderDialog(folder: PlaylistFolder) {
    this._dialogService.displayEditPlaylistFolderDialog(folder).subscribe(changed => {
      if (changed) {
        this.loadPlaylistOrFolder();
        if( this._activatedRoute.snapshot.queryParams.playlist){
          this.getAllplaylist( this._activatedRoute.snapshot.queryParams.playlist)
          }
       // location.reload();
      }
    });
  }

  switchTab(val, type:any='') {
    if(this.currentUser !== null)
    this.activeTab = val;
    this.currentPlaylistsPage = 1;
    this.startDate = null;
    this.endDate = null;
    if(type !== 'start' && this.activeTab == 'playlists' ){
      this.switchCase = true;
    }
 
    this.loadPlaylistOrFolder();
  }

  loadPlaylistOrFolder(pagination?) {
    if (this.activeTab == 'playlists') {
      this.showNoData = false;
      this.loadingPlaylists = true;
      this.loadPlaylists(pagination);
    } else if(this.activeTab == 'folders'){
      this.expandedFolders = [];
      this.loadingFolders = true;
      this.loadFolders(pagination);
    }
  }

  toggleFolder(id: number, playlist) {
    if (this.isFolderOpen(id)) {
      let i = this.expandedFolders.indexOf(id);
      this.expandedFolders.splice(i, 1);
      // return;
    }
    this.expandedFolders = [];
    this.expandedFolders.push(id);
    if (playlist.length > 0) {

      this.currentPlaylist = playlist[0];
      this.currentValueItem = playlist[0].id
      this.loadTracks();
    } else {
      // this.expandedFolders.push(id);
      this.showNoData = true;
    }
    // this.showNoData=false;

    //   if(playlist.length>0){
    //   if (this.isFolderOpen(id)) {
    //     let i = this.expandedFolders.indexOf(id);
    //     this.expandedFolders.splice(i, 1);
    //     return;
    //   } 
    //   this.expandedFolders.push(id);
    //   this.showNoData=false;

    // }else{
    //   console.log("id"+id+"this.expandedFolders"+this.expandedFolders)
    //   this.expandedFolders.push(id);
    //   this.showNoData=true;

    // }
  }

  isFolderOpen(id: number): boolean {
    return this.expandedFolders.includes(id);
  }

  playlistDisplayName(p: Playlist): string {
    if (!p || !p.id) return '';
    return `${this.formatDate(p.created_at)} - ${p.name}`;
  }
  datePickerEvent(event) {
    this.clear();
  }
  folderDisplayName(f: PlaylistFolder): string {
    return `${this.formatDate(f.created_at)} - ${f.name}`;
  }

  formatDate(d: Date): string {
    return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear().toString().substr(-2)}`;
  }

  playlistTrackBy(i: number, playlist: Playlist) {
    return playlist.id + playlist.name;
  }

  folderTrackBy(i: number, folder: PlaylistFolder) {
    return folder.id + folder.name;
  }

  toggleMusicPlayerCollapsed() {
    this.musicPlayerCollapsed = !this.musicPlayerCollapsed;
    this.enableplay = false;

    this._playerService.currentPlaylistItems.current=-1;
  }

  collapseMusicPlayer() {
    this.musicPlayerCollapsed = true;
  }
  data: any = []
  setPlayingTrack(pTrack: PlaylistTracks) {

    this.enableplay = false;
    this._playerService.currentPlaylistItems;
    this.data = pTrack;
    this.mp3_file = pTrack.mp3_file;
    this.trackName = pTrack.title
    this._playingTrack = pTrack;

    setTimeout(() => {
      this.enableplay = true;
      this.playTrack =true
      this._playerService.updatePlayListType(true);
      this._playerService.nextPlayTab(false);
      this.updateMediaSession();
    }, 500);

  }

  startChanged(value: Date) {
    this.startDate = value;
  }

  endChanged(value: Date) {
    this.endDate = value;
    if (this.startDate && this.endDate) {
      if (this.activeTab == 'playlists') {
        this.loadPlaylists();
      } else {
        this.loadFolders();
      }
    }
  }
  downloadcue() {

if(this.currentUser !== null){
    let requestURL = environment.apiURL + `/api/v1/admin/playlists/${this.currentPlaylist.id}/cuesheet/?ordering_by=id`
    let headers = new HttpHeaders();
    this.http.get(`${requestURL}`, {
      responseType: 'text', headers: headers
    },
    ).subscribe(response => {
      
      this.downLoadFile(this.currentPlaylist.name, response, "application/pdf")
    });
  }
  else{
    this._router.navigate(['/login']);
  }

  }
  private updateMediaSession() {
    if (!this._platform.isBrowser) { return; }
    if (!('mediaSession' in navigator)) { return; }
    if (!this.data) { return; }
    (<any>navigator).mediaSession.metadata = new MediaMetadata({
      title: this.data.title,
      // album: "Podcast Name",
      // artwork: [{src: "podcast.jpg"}]
    });
    (<any>navigator).mediaSession.setActionHandler('play', () => {
      this.play();
    });
    (<any>navigator).mediaSession.setActionHandler('pause', () => {
      this.pause();
    });
  }
  downLoadFile(playlistName:any, data: any, type: string) {
  //   let filename = playlistName.trim()+'MIBE Cuesheet';
  //   let blob = new Blob([data], { type: 'application/pdf' });
  //   let url = URL.createObjectURL(blob);
  //   let name = url.split('/')
  //  // let newurl = name[0]+'/'+name[1]+'/'+name[2]+'/'+filename
  //   let pwa = window.open(url);
  //   if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
  //     //alert( 'Please disable your Pop-up blocker and try again.');
  //   }.trim()
    let filename = `${playlistName.trim()}.pdf`;
    let blob = new Blob([data], { type: type});
    let url = window.URL.createObjectURL(blob);
    let result = filename.substring(0,4);
    let tr = filename.substring(4);
    if(result.toLowerCase() === 'mibe'){
        filename = result.toUpperCase() + tr
    }
    else{
      filename = 'MIBE ' + filename
    }
    saveAs(url, filename);
  }

  play() {
    this.playTrack =true
    this.isplay = true
    this.isPlaying = true;
    // if (this.isPlaying) { return; }
    this._playerService.nextPlayTab(false);
    this._playerService.updatePlayListType(true);
  }
  pause() {
    this.isPlaying = false;
    // if (!this.isPlaying) { return; }
    this._playerService.nextPlayTab(false);
    this._playerService.updatePlayListType(false);
  }
  private animationFrameCb() {
    if (!this._platform.isBrowser) { return; }
    if (this.currentTime) {
      this._ngZone.run(() => this.audioTimeUpdate());
    }
    this._cancelAnimationFrame = requestAnimationFrame(() => {
      this.animationFrameCb()
    })
  }
  private cancelAnimationFrame() {
    if (this._platform.isBrowser && this._cancelAnimationFrame) {
      cancelAnimationFrame(this._cancelAnimationFrame);
    }
  }
  audioTimeUpdate() {
    if (!this._platform.isBrowser) { return; }

    this._playbackProgress = this.currentTime / this.duration;
    this._playerService.updatePlaybackProgress(this._playbackProgress);
  }

}
