<div class="musicplayer-container" > 

    <!-- <div class="musicplayer-container" *ngIf="!isPlaying">  -->
    <div class="controls" [ngClass]="{'disabledElement': (loadingTracks === true )}">
        <div class="button-controls">
            <button type="button" (click)="skipBack()" [disabled]="!previousItem && currentTime < 2"
                class="previous-button">
                <svg width="13px" height="16px" viewBox="0 0 13 16" version="1.1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                    stroke-linejoin="round">
                    <g id="Play-Bar_Green" transform="translate(-389.000000, -48.000000)" stroke="#15151C"
                      class="inner-path">
                      <g id="Skip-Back">
                        <g transform="translate(389.000000, 49.000000)">
                          <polygon id="Path" points="11.75 14 3 7 11.75 0"></polygon>
                          <path d="M1,14 L1,0" id="Path"></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
            </button>
            <button type="button" class="play-button" *ngIf="!isPlaying" (click)="play()">
                <svg width="13px" height="17px" viewBox="0 0 13 17" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="Style-Guide" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="Components_Track" transform="translate(-426.000000, -388.000000)" class="inner-path"
                            stroke="#000000" stroke-width="0.95">
                            <g id="Inactive-Track" transform="translate(392.000000, 351.000000)">
                                <path
                                    d="M45.5758457,45.9952239 C45.6280256,45.9604373 45.6728005,45.9156624 45.707587,45.8634826 C45.8531045,45.6452065 45.7941219,45.3502935 45.5758457,45.2047761 L35.4134826,38.4298673 C35.3354555,38.3778493 35.2437769,38.3500912 35.15,38.3500912 C34.8876647,38.3500912 34.675,38.562756 34.675,38.8250912 L34.675,52.3749088 C34.675,52.4686856 34.7027581,52.5603643 34.7547761,52.6383914 C34.9002935,52.8566675 35.1952065,52.9156501 35.4134826,52.7701327 L45.5758457,45.9952239 Z"
                                    id="Play"></path>
                            </g>
                        </g>
                    </g>
                </svg>
            </button>
            <button type="button" class="pause-button" (click)="pause($event)" *ngIf="isPlaying">
                <svg width="10px" height="14px" viewBox="0 0 10 14" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="Style-Guide" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="Components_Track" transform="translate(-426.000000, -746.000000)" fill="#15151C"
                            class="inner-path">
                            <g id="Track-Playing" transform="translate(393.000000, 704.000000)">
                                <path
                                    d="M33.2499996,42.9499995 L36.4166662,42.9499995 L36.4166662,55.616666 L33.2499996,55.616666 L33.2499996,42.9499995 Z M39.5833328,42.9499995 L42.7499995,42.9499995 L42.7499995,55.616666 L39.5833328,55.616666 L39.5833328,42.9499995 Z"
                                    id="Pause"></path>
                            </g>
                        </g>
                    </g>
                </svg>
            </button>
            <button type="button" class="next-button" (click)="skipForward()" [disabled]="!nextItem">
                <svg width="13px" height="16px" viewBox="0 0 13 16" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"
                        stroke-linecap="round" stroke-linejoin="round">
                        <g id="Play-Bar_Green" transform="translate(-476.000000, -48.000000)" stroke="#15151C"
                            class="inner-path">
                            <g id="Skip-Forward">
                                <g transform="translate(477.000000, 49.000000)">
                                    <polygon id="Path" points="0 0 8.75 7 0 14"></polygon>
                                    <path d="M10.8333333,0.333333333 L10.8333333,14.3333333" id="Path"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </button>
        </div>
    </div>

    <div class="track-name"  *ngIf="track" [ngClass]="{'disabledElement': (loadingTracks === true )}">
        <!-- <div class="name" style="color: #000000;" *ngIf="version.is_main_track == true">{{ track.title+ "-"+version.title+ "- Main"}}</div>
        <div class="name"  style="color: #000000;"  *ngIf="version.is_main_track !== true && version.is_full_version !== true">{{ version.title ? track.title+ "-"+version.title :track.title}}</div>
        <div class="name"  style="color: #000000;"  *ngIf="version.is_main_track !== true && version.is_full_version == true">{{ version.title}}</div>  -->
        
        <div class="name"  style="color: #000000;" >{{ version?.title}}</div>  
        <!-- <div class="kind hide-xs">
            <span *ngIf="version.is_instrumental">Instrumental</span>
            <span *ngIf="!version.is_instrumental">Vocals</span>
        </div> -->
    </div>

    <div class="time"   [ngClass]="{'disabledElement': (loadingTracks === true )}">
        <div  style="color: #000000;" class="current-time">{{ currentTime | duration}}</div>
        <div style="color: #000000;"  class="separator">|</div>
        <div  style="color: #000000;"  class="duration">{{ duration | duration }}</div>
    </div>

    <div class="scrubber" >
        <app-loader *ngIf="loadingTracks" style="width: 100%;
        margin-top: 23px;
        position: absolute;
        margin-left: 50%;"></app-loader>
        <!-- <app-waveform [version]="version" [trackProgress]="playbackProgress"
            (trackProgressChange)="playbackProgress = $event"></app-waveform> -->
            <div style="width: 70%" (click)="getCurrentPreview()">
                <div  id="basetemplate"></div>
                <div id="wave-timeline"></div>
                <div *ngIf="wave" class="controls">
                </div>
    </div>

    <div >
        <button 
            type="button"
            matTooltip="Collapse Player"
            matTooltipClass="tooltip-white"
            class="action-button collapse"
            (click)="collapsePlayer()">
            <svg width="16px" height="15px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                    <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                        <g id="Genre-Options">
                            <g id="Close" transform="translate(1439.000000, 32.000000)">
                                <path d="M24,0 L0,24" id="Path"></path>
                                <path d="M0,0 L24,24" id="Path"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </button>
    </div>
    <audio id="aud" [src]="version ? version.mp3_file : ''" (play)="play()" (pause)="pause($event)" [title]="track ? track.title : ''" #audioEl></audio>
</div>

