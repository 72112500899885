import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MenuUser } from '../models/menu-user.model';
import { CONTAINER_DATA, DialogConfig } from '../services/slider-dialog.service';

@Component({
  selector: 'app-user-dialogue-form',
  templateUrl: './user-dialogue-form.component.html',
  styleUrls: ['./user-dialogue-form.component.scss']
})
export class UserDialogueFormComponent implements OnInit {

  primary:any;
  toppings = new FormControl();
  searchFilterFocused:any;
  searchString:any;
  primaryPhoneControl:FormControl = new FormControl();
  toppingList: string[] = ['Sub Admin Groups', 'Test1', 'Test2', 'Test3', 'Test4'];

  loading:boolean = false;

  user: MenuUser;
  password: string = "";
  isNewUser: boolean = false;
  errorMessage: string;

  username:string = "";

  phoneControllers:FormControl[] = [];
  constructor(@Inject(CONTAINER_DATA) public componentData: DialogConfig) { }

  ngOnInit(): void {
    this.user = new MenuUser(this.componentData.data.user.toJSON());
    console.log(this.user)
  }


}
