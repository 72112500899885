import { Component, OnInit } from '@angular/core';
import { UserMenuService } from '../shared/services/user-menu.service';
import { environment } from '../../environments/environment';
import { MenuConfigService } from '../shared/services/menu-config.service';
import { UserService } from '../shared/services/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  loading: boolean = false
  constructor(public api: UserMenuService, public config: MenuConfigService, public user: UserService) { }

  created_by: any;
  

  ngOnInit(): void {
    this.getAllCount();
    this.getAllAudioLevel();
    this.getAllPercentage();
    let us = localStorage.getItem("userdata")
    // this.user._currentUserSubject.next()
    this.user.currentUserStream.subscribe(x=>{
      this.created_by = us['id']
      console.log(this.created_by)
    })
    
  }
  public track_count: number = 0
  public artist_count: number = 0
  public client_count:number= 0
  public show_count: number = 0
  public genres_count: number = 0
  public sub_genres_count:number = 0
  public min_audio_level:number = 0
  public max_audio_level:number = 0
  public percentage: number = 0

getAllCount(){
this.loading = true;
  let url = environment.apiURL + this.config.doDashboard;
  this.api.doGET(url).subscribe((x:any)=>{
    this.loading = false;
      this.track_count = x.track_count || 0;
      this.artist_count = x.artist_count || 0;
      this.client_count = x.client_count || 0;
      this.show_count = x.show_count || 0;
      this.genres_count = x.genres_count || 0;
      this.sub_genres_count = x.sub_genres_count || 0;
    },
    (error)=>{
    this.loading = false
    }
  )
}

validateNumber(event) {
  const keyCode = event.keyCode;
  const excludedKeys = [8, 37, 39, 46, 190];
  if ((event.key === ' ' || isNaN(Number(event.key)))&&!((
    (keyCode >= 96 && keyCode <= 105) ||
    (excludedKeys.includes(keyCode))))) {
    event.preventDefault();
  }
  if(keyCode === 190 && event.target.value.split('.').length === 2){
    event.preventDefault();
  }
  let x = event.target.value.toString()
  var index = x.indexOf(".");
  if(keyCode !== 8 && (x.length - index - 1) > 1 && index >= 0){
    event.preventDefault();
  }else{
    return true;
  }
}
getInputValue(val){
  if(val==='.'){
    val= '0.'
  }
  this.max_audio_level = Number((Math.round(val * 100) / 100).toFixed(2));
}

getInputValueMin(val){
  if(val==='.'){
    val= '0.'
  }
  this.min_audio_level = Number((Math.round(val * 100) / 100).toFixed(2));
}
getAllAudioLevel(){
  this.loading = true;
    let url = environment.apiURL + this.config.doAudioLevel;
    this.api.doGET(url).subscribe((x:any)=>{
      this.loading = false;
          this.min_audio_level = Number(Number(x.min_audio_level).toFixed(2)) || 0;
          this.max_audio_level = Number(Number(x.max_audio_level).toFixed(2)) || 0;
      },
      (error)=>{
      this.loading = false
      }
    )
  }
  getAllPercentage(){
    let mibe_name = 'MIBE PUBLISHING'
    this.loading = true;
      let url = environment.apiURL + this.config.doPublisher;
      this.api.doGET(url).subscribe(x=>{
        this.loading = false;
           this.percentage = x['percentage'];
        },
        (error)=>{
        this.loading = false
        }
      )
    }
   
    plus(){
      if(this.percentage < 100){
        this.percentage =  Number(this.percentage) + 1
      }
      else{
        this.percentage = 100
      }
      
    }
    minus(){
      if(this.percentage > 0){
        this.percentage = Number(this.percentage) - 1
      }
      else{
        this.percentage =  0
      }
    }
    save(){
      let data = {
        "min_audio_level": this.min_audio_level,
        "max_audio_level": this.max_audio_level,
        "created_by": this.created_by
    }
      this.loading = true
      let url = environment.apiURL + this.config.postAudioLevel;
      this.api.doPOST(url,data).subscribe(x=>{
      this.loading = false
      }, (error)=>{
        this.loading = false
      })
      this.savePercentage();
    }
    savePercentage(){
     let data =  {
        "name":"MIBE PUBLISHING",
        "percentage":this.percentage
      }
      this.loading = true
      let url = environment.apiURL + this.config.postPublisher;
      this.api.doPUT(url,data).subscribe(x=>{
        this.loading = false
      },(error)=>{
        this.loading = false
      })
    }

  onBlur(value) {

    if (Number(value) < 100) {
      var num = Number(value)
      this.percentage = num
    }
    else {
      this.percentage = 100
    }
  }
  value(value) {

    if (Number(value) < 100) {
      var num = Number(value)
      this.percentage = num
    }
    else {
      this.percentage = 100

    }
  }
}
