<div class="track-details-card">
  <div class="title-card-left"><b>TRACK DETAILS <span class="allfields"><small>*All fields required </small></span></b></div>
  <div class="title-description">{{track.id ?  'Review track details to ensure all information was accurately inputted by artist': "Please enter the following information as completely and accurately as possible."}}</div>

  <mat-card class="grid-card">
    <div class="artist-title" *ngIf="artist"><b>ARTIST:</b> {{ artist }}</div>
    <div class="grid-container" *ngIf="track.id==undefined">
  
      <div class="grid-item">
          <mat-form-field appearance="fill" [ngClass]="(( track.title && track.title.length != 0 && track.title.length <= 0   ) || (trackTitle.touched && trackTitle.invalid)) ? 'addErrorValidation' :''">
              <mat-label>Track Name</mat-label>
              <input class="tracknamef" matInput [(ngModel)]="track.title"   [formControl]="trackTitle" required (input)="gettrackName($event.target.value)">
              <mat-error style="margin-top: 7%;" *ngIf="trackTitle.invalid">Track name cannot be empty</mat-error>
            </mat-form-field>
      </div>
      <div class="grid-item">
          <mat-form-field appearance="fill">
              <mat-label>Artist Name</mat-label>
              <input matInput [(ngModel)]="artistName" readonly>
          </mat-form-field>
      </div>
  </div>
    <div class="grid-container">
      <div class="grid-item">
        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>Genre</mat-label>
          <mat-select [panelClass]="'card-select'" placeholder="Select" [disableOptionCentering]="true" [ngModel]="currentGenre" [compareWith]="genreCompareWith" (selectionChange)="genreSelection($event)">
            <mat-option>
              <ngx-mat-select-search
                [searching]="searchingGenre"
                [formControl]="genreFilterCtrl"
                placeholderLabel="Select a Genre"
                noEntriesFoundLabel="No Options Found">
                <svg ngxMatSelectSearchClear class="filter-clear-icon" width="26px" height="24px" viewBox="0 0 26 24"
                  version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                    stroke-linejoin="round">
                    <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                      <g id="Genre-Options">
                        <g id="Close" transform="translate(1439.000000, 32.000000)">
                          <path d="M24,0 L0,24" id="Path"></path>
                          <path d="M0,0 L24,24" id="Path"></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let option of filteredGenreOptions | async" [value]="option">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="grid-item">
        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>SubGenre</mat-label>
          <!-- <mat-select [panelClass]="'card-select'" placeholder="Select" [disableOptionCentering]="true" [ngModel]="currentSubGenre" [compareWith]="genreCompareWith" (selectionChange)="subGenreSelection($event)" [disabled]="!currentGenre || !subGenres || subGenres.length <= 0"> -->
          <mat-select [panelClass]="'card-select'" placeholder="Select" [disableOptionCentering]="true" [ngModel]="currentSubGenre" [compareWith]="genreCompareWith" (selectionChange)="subGenreSelection($event)" >
            <mat-option>
              <ngx-mat-select-search
                [searching]="searchingSubGenre"
                [formControl]="subGenreFilterCtrl"
                placeholderLabel="Select a SubGenre"
                noEntriesFoundLabel="No Options Found">
                <svg ngxMatSelectSearchClear class="filter-clear-icon" width="26px" height="24px" viewBox="0 0 26 24"
                  version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                    stroke-linejoin="round">
                    <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                      <g id="Genre-Options">
                        <g id="Close" transform="translate(1439.000000, 32.000000)">
                          <path d="M24,0 L0,24" id="Path"></path>
                          <path d="M0,0 L24,24" id="Path"></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let option of filteredSubGenreOptions | async" [value]="option">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="grid-container">
      <div class="grid-item" style="position: relative;">
        <mat-form-field appearance="fill" class="" >
          <mat-label>Mood</mat-label>
          <mat-select panelClass="card-select1 card-select" placeholder="Select" [(value)]="selectedmood"  [disableOptionCentering]="true" [ngModel]="currentMoods[0]" [compareWith]="moodCompareWith" (selectionChange)="moodSelection($event)">
            <!-- <mat-option value="Aggresive">Aggresive</mat-option> -->
            <mat-option>
              <ngx-mat-select-search
                [searching]="searchingMood"
                [formControl]="moodFilterCtrl"
                placeholderLabel="Select a Mood"
                noEntriesFoundLabel="No Options Found">
                <svg ngxMatSelectSearchClear class="filter-clear-icon" width="26px" height="24px" viewBox="0 0 26 24"
                  version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                    stroke-linejoin="round">
                    <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                      <g id="Genre-Options">
                        <g id="Close" transform="translate(1439.000000, 32.000000)">
                          <path d="M24,0 L0,24" id="Path"></path>
                          <path d="M0,0 L24,24" id="Path"></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let option of filteredMoodOptions | async" [value]="option">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" style="position:absolute;
       top: 32%;
    width: 100%;
        margin-top: 10px;
       ">
          <mat-select panelClass="card-select1 card-select" placeholder="Select" [(value)]="selectedmood2"  [disableOptionCentering]="true" [ngModel]="currentMoods[1]" [compareWith]="moodCompareWith" (selectionChange)="mood2Selection($event)">
            <!-- <mat-option value="Angry">Angry</mat-option> -->
            <mat-option>
              <ngx-mat-select-search
                [searching]="searchingMood"
                [formControl]="moodFilterCtrl"
                placeholderLabel="Select a Mood"
                noEntriesFoundLabel="No Options Found">
                <svg ngxMatSelectSearchClear class="filter-clear-icon" width="26px" height="24px" viewBox="0 0 26 24"
                  version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                    stroke-linejoin="round">
                    <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                      <g id="Genre-Options">
                        <g id="Close" transform="translate(1439.000000, 32.000000)">
                          <path d="M24,0 L0,24" id="Path"></path>
                          <path d="M0,0 L24,24" id="Path"></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let option of filteredMoodOptions | async" [value]="option">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      <mat-form-field appearance="fill" class="mood-select1" style="position: absolute;
      top: 79%;
    width: 100%;
      ">
          <mat-select panelClass="card-select1 card-select" placeholder="Select" [(value)]="selectedmood3"  [disableOptionCentering]="true" [ngModel]="currentMoods[2]" [compareWith]="moodCompareWith" (selectionChange)="mood3Selection($event)">
            <!-- <mat-option value="Furious">Furious</mat-option> -->
            <mat-option>
              <ngx-mat-select-search
                [searching]="searchingMood"
                [formControl]="moodFilterCtrl"
                placeholderLabel="Select a Mood"
                noEntriesFoundLabel="No Options Found">
                <svg ngxMatSelectSearchClear class="filter-clear-icon" width="26px" height="24px" viewBox="0 0 26 24"
                  version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                    stroke-linejoin="round">
                    <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                      <g id="Genre-Options">
                        <g id="Close" transform="translate(1439.000000, 32.000000)">
                          <path d="M24,0 L0,24" id="Path"></path>
                          <path d="M0,0 L24,24" id="Path"></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let option of filteredMoodOptions | async" [value]="option">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="grid-item">
        <mat-form-field appearance="fill" floatLabel="always" style="margin-bottom:10%;">
          <mat-label>Tempo</mat-label>
          <mat-select [panelClass]="'card-select'" placeholder="Select" [disableOptionCentering]="true" [(ngModel)]="version.tempo" (selectionChange)="tempoSelection($event)">
            <mat-option *ngFor="let option of tempoList" [value]="option.id">
              {{ option.tempo_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <app-bpm-field  [(bpm)]="bpmValue"  [(version)]="version" [(track)]="track"></app-bpm-field>
      </div>
      <div class="grid-item bpm-item poistionbpm">
        
      </div>
    </div>
      <div class="grid-container" style="margin-top:5%;">
      <div class="grid-item">
        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>Track Ending</mat-label>
          <mat-select [panelClass]="'card-select'" placeholder="Select" [disableOptionCentering]="true" [ngModel]="version.track_ending" (selectionChange)="trackEndingSelection($event)">
            <mat-option *ngFor="let option of trackEndingList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- <mat-form-field appearance="fill">
          <mat-label>Track Ending</mat-label>
          <input matInput [(ngModel)]="trackEnding" placeholder="Track Ending" >
        </mat-form-field> -->
      </div>
      <div class="grid-item">
        <mat-form-field appearance="fill" floatLabel="always">
          <mat-label>Key</mat-label>
          <mat-select [panelClass]="'card-select'" placeholder="Select" [disableOptionCentering]="true" [ngModel]="version.calculated_key" (selectionChange)="keyChange($event)">
            <mat-option *ngFor="let option of keyList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    </div>
    <div class="meta-data">
      <mat-checkbox class="checkbox-margin" [(ngModel)]="isExclusive" [disabled]='track.id==undefined' (ngModelChange)="setExclusive($event)">Exclusive to Mibe</mat-checkbox>
      <mat-checkbox class="checkbox-margin" [(ngModel)]="isFullLength" (ngModelChange)="setFullLength($event)">Full-Length?</mat-checkbox>
      <div *ngIf="track.id">
        <mat-radio-group *ngIf="isInstrumental" [(ngModel)]="version.is_instrumental" (change)="radioChange($event)">
          <mat-radio-button name="instrumental" class="checkbox-margin" [value]="isInstrumental">Instrumental</mat-radio-button>
          <mat-radio-button name="vocals" class="checkbox-margin" [value]="isVocal">Vocals</mat-radio-button>
        </mat-radio-group>
        <mat-radio-group *ngIf="isVocal"  [(ngModel)]="version.is_vocals" (change)="radioChangeVocal($event)">
          <mat-radio-button name="instrumental" class="checkbox-margin" [value]="isInstrumental">Instrumental</mat-radio-button>
          <mat-radio-button name="vocals" class="checkbox-margin" [value]="isVocal">Vocals</mat-radio-button>
        </mat-radio-group>
      </div>
      
      <div *ngIf="track.id === undefined">
        <mat-radio-group [(ngModel)]="version.is_instrumental" (change)="radioChange($event)">
          <mat-radio-button name="instrumental" class="checkbox-margin" [value]="isInstrumental">Instrumental</mat-radio-button>
          <mat-radio-button name="vocals" class="checkbox-margin" [value]="isVocal">Vocals</mat-radio-button>
        </mat-radio-group>
      </div>
     
      <mat-checkbox *ngIf="track.id!=undefined" class="checkbox-margin" [(ngModel)]="isMibeOwned" (ngModelChange)="setMibeOwned($event)">MIBE Owned</mat-checkbox>
      <mat-checkbox *ngIf="track.id!=undefined" class="checkbox-margin" [(ngModel)]="isFiveStar" (ngModelChange)="setFiveStar($event)">5-Star</mat-checkbox>
    </div>

  </mat-card>

</div>
