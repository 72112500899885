import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  ViewChild,
  ElementRef,
  NgZone,
  Renderer2,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { Track } from '../models/track.model';
import { TrackVersion } from '../models/trackversion.model';
import { PlayerService, PlayingItem } from '../services/player.service';
import { UserService } from '../services/user.service';
import { TrackVersionService } from '../services/trackversion.service';
import { User } from '../models/user.model';
import { DialogService } from '../services/dialog.service';
import { Subscription, fromEvent } from 'rxjs';

declare var MediaMetadata: any;

@Component({
  selector: 'app-musicplayer',
  templateUrl: './musicplayer.component.html',
  styleUrls: ['./musicplayer.component.scss']
})
export class MusicplayerComponent implements OnInit, OnDestroy {

  @Input()
  set track(t: Track) {
    this._track = t;
    this.updateMediaSession();
  //  console.log(this._track.title)
     this.duration = 0;
     this.currentTime = 0;
     this.loadingTracks = true;
    // if(this.oldTrackTitle !== this._track.title ){
    //  // this.loadingTracks = false;
    //  this.duration = 0;
    //  this.currentTime = 0;
    // }
    // else{
    // //  this.loadingTracks = true;
    //   this.duration = 0;
    //   this.currentTime = 0;
    // }
  }
  get track(): Track {
    return this._track;
  }
  @Input()
  version: TrackVersion;
  @Input()
  set playUrl(p: string) {
    this._playUrl = this.version.wav_file
  }
  get playUrl(): string {
    return this._playUrl;
  }
  @Input()
  set playbackProgress(p: number) {
    this._playbackProgress = p;
  //  let duration = this.version.duration;
    if (!this._platform.isBrowser) { return; }
    if (!isNaN(this.audioEl.nativeElement.duration) && this.audioEl.nativeElement.duration != Infinity) {
      //duration = this.audioEl.nativeElement.duration;
    }
   // let newTime = duration * p;
   // this.audioEl.nativeElement.currentTime = newTime;
    //this.currentTime = this.audioEl.nativeElement.currentTime;
    this._playerService.updatePlaybackProgress(this._playbackProgress);
  }
  get playbackProgress(): number {
    return this._playbackProgress;
  }
  @Input()
  set getCurrentTime(p:number){
    this.currentTime = p;
    this.cdr.detectChanges();
    this.loadingTracks = false;
  }

  @Input()
  set getWaveDuration(p: number) {
    this.duration = p;
    this.cdr.detectChanges();
    this.loadingTracks = false;
  }

  //currentTime : number = 0
  
  @Output()
  collapse = new EventEmitter();


  @ViewChild('audioEl', {static: true})
  audioEl: ElementRef<HTMLAudioElement>;

  set isPlaying(p: boolean) {
    this._isPlaying = p;
    if (!this._platform.isBrowser) { return; }
    if (p && this.audioEl.nativeElement.paused) {
      setTimeout(() => {
       this.audioEl.nativeElement.play();
       //this._playerService.updateURL(p);
        this._cancelAnimationFrame = this._ngZone.runOutsideAngular(() => requestAnimationFrame(() => {
          this.animationFrameCb()
        }))
      });
    } else if (!p && !this.audioEl.nativeElement.paused) {
      this.audioEl.nativeElement.pause();
      this.cancelAnimationFrame();
    }
  }
  get isPlaying(): boolean {
    return this._isPlaying;
  }

  get previousItem(): PlayingItem {
    return this._playerService.previousItem;
  }
  get nextItem(): PlayingItem {
    return this._playerService.nextItem;
  }
  get isFavorited(): boolean {
    return this.currentUser && this.version && this.version.favorited_by_me;
  }

  currentTime: number = 0;
  currentUser: User;

  private _cancelAnimationFrame: number;
  private _track: Track;
  private _isPlaying = false;
  private _playbackProgress = 0;
  private _playUrl =''
  private _subscriptions: Subscription[] = [];
  
  isplay = false;
  duration: number= 0;
  loadingTracks: boolean = true;
  oldTrackTitle:string=''

  @Input() doPlay: boolean; // decorate the property with @Input()
  @Input() doPause: boolean; // decorate the property with @Input()

  constructor(
    private _playerService: PlayerService,
    private _ngZone: NgZone,
    private _platform: Platform,
    private _renderer: Renderer2,
    private _userService: UserService,
    private _trackVersionService: TrackVersionService,
    private _dialogService: DialogService, private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
   
  //  this.oldTrackTitle = this._track.title
    this._subscriptions.push(
      this._playerService.isPlaying.subscribe((p) => {
        this.isPlaying = p;
        if(p == false){
          this._playerService.updateCollapse(p);
          this.pause();
        }
        else{
          this._playerService.updateCollapse(p);   
          this.play();
        }
      })
    );
    this._subscriptions.push(
      this._playerService.playbackProgress.subscribe((p) => {
        if (this._playbackProgress != p) {
          this.playbackProgress = p;
        }
      })
    );
    this._subscriptions.push(this._userService.currentUserStream.subscribe(u => {
      this.currentUser = u;
    }));
    if (this._platform.isBrowser) {
      this._subscriptions.push(fromEvent(window.document, 'keydown').subscribe((e: KeyboardEvent) => {
        if (e.keyCode === 32 || e.code === 'Space') {
          let activeNodeName = window.document.activeElement.nodeName.toLowerCase();
          if (activeNodeName == 'input' || activeNodeName == 'textarea' || activeNodeName == 'button' || activeNodeName == 'select') {
            return;
          }
          if (!this.track || !this.version) { return; }
          e.preventDefault();
          if (this.isPlaying) {
            this.pause();
          } else {
            this.play();
            this._playerService.updateURL(this.version.wav_file);
          }
        }
      }));
    }
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => {
      s.unsubscribe();
    });
    this.cancelAnimationFrame();
  }

  play() {
    this.isplay = true
    if (this.isPlaying) { return; }
    this._playerService.updateIsPlaying(true);
  }
  pause() {
    if (!this.isPlaying) { return; }
    this._playerService.updateIsPlaying(false);
  }
  ended() {
    if (!this.nextItem) { return; }
    this._playerService.next();
    this._playerService.updateIsPlaying(true);
  }
  audioTimeUpdate() {
    if (!this._platform.isBrowser) { return; }
    // let duration = this.version.duration;
    // if (!isNaN(this.audioEl.nativeElement.duration) && this.audioEl.nativeElement.duration != Infinity) {
    //   duration = this.audioEl.nativeElement.duration;
    // }
  //  this.currentTime = this.audioEl.nativeElement.currentTime;
    this._playbackProgress = this.currentTime / this.duration;
    this._playerService.updatePlaybackProgress(this._playbackProgress);
  }
  audioCanPlay() {
    if (!this._platform.isBrowser) { return; }
    if (this.isPlaying && this.audioEl.nativeElement.paused) {
      this.audioEl.nativeElement.play();
     // this._playerService.updateURL(url);
    }
  }
  skipForward() {
    if (!this.nextItem) { return; }
    this._playerService.next();
  }
  skipBack() {
    if (this.currentTime > 2) {
      this.playbackProgress = 0;
      return;
    }
    if (!this.previousItem) { return; }
    this._playerService.previous();
  }

  collapsePlayer() {
    this.collapse.emit();
    //this._playerService.updateCollapse(false);
  }

  amOwner(track: Track): boolean {
    if (!this.currentUser || !track) return false;
    return track.owned_by == this.currentUser.id;
  }

  artistFeatured(track: Track): boolean {
    if (!track) return false;
    return track.artist && track.artist.is_featured;
  }

  displayTrackInfo(track: Track) {
    if (!track) return;
    // this._dialogService.displayTrackInfo(track);
  }

  editTrackDetail(track: Track) {
    if (!track) return;
    // this._dialogService.displayCreateTrack(track);
  }

  addTrackVersion(track: Track) {
    if (!track) return;
    // this._dialogService.displayUploadVersion(track);
  }

  editVersionDetail(track: Track, version: TrackVersion) {
    if (!track || !version) return;
    // this._dialogService.displayUploadVersion(track, version);
  }

  private animationFrameCb() {
  if (!this._platform.isBrowser) { return; }
    if (this.currentTime) {
      this._ngZone.run(() => this.audioTimeUpdate());
    }
    this._cancelAnimationFrame = requestAnimationFrame(() => {
      this.animationFrameCb()
    })
  }
  private cancelAnimationFrame() {
    if (this._platform.isBrowser && this._cancelAnimationFrame) {
      cancelAnimationFrame(this._cancelAnimationFrame);
    }
  }
  
  private updateMediaSession() {
    if (!this._platform.isBrowser) { return; }
    if (!('mediaSession' in navigator)) { return; }
    if (!this.track) { return; }
    (<any>navigator).mediaSession.metadata = new MediaMetadata({
      title: this.track.title,
      artist: this.track.artist ? this.track.artist.name : '',
      // album: "Podcast Name",
      // artwork: [{src: "podcast.jpg"}]
    });
    (<any>navigator).mediaSession.setActionHandler('play', () => {
      this.play();
    });
    (<any>navigator).mediaSession.setActionHandler('pause', () => {
      this.pause();
    });
    (<any>navigator).mediaSession.setActionHandler('previoustrack', () => {
      this.skipBack();
    });
    (<any>navigator).mediaSession.setActionHandler('nexttrack', () => {
      this.skipForward();
    });
  }
}
