import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DialogService } from '../../shared/services/dialog.service';
import { PuplishcmsComponent } from '../puplishcms/puplishcms.component';
import { AddplaylistcmsComponent } from '../addplaylistcms/addplaylistcms.component'
import { environment } from 'src/environments/environment';
import { MenuConfigService } from 'src/app/shared/services/menu-config.service';
import { UserMenuService } from 'src/app/shared/services/user-menu.service';
import { RemoveGenreDialogueComponent } from '../remove-genre-dialogue/remove-genre-dialogue.component';
import { MenuListService } from 'src/app/shared/services/menu-list.service';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { DataSource } from '@angular/cdk/collections';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CmsaddgenreComponent } from '../cmsaddgenre/cmsaddgenre.component';
import { Track } from 'src/app/shared/models/track.model';
import { TrackVersion } from 'src/app/shared/models/trackversion.model';
import { PlayerService, PlayingItem } from 'src/app/shared/services/player.service';
import { SliderDialogService } from 'src/app/shared/services/slider-dialog.service';
import { TrackService } from 'src/app/shared/services/track.service';
import { CmsaddplaylistfeatureComponent } from '../cmsaddplaylistfeature/cmsaddplaylistfeature.component';
import { UserService } from 'src/app/shared/services/user.service';
import { User } from 'src/app/shared/models/user.model';
const listPageSize = 6;
@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ],
})
export class CmsComponent implements OnInit {
  displayedColumns = ['name', 'title', 'tracks', 'created_at', 'updated_at', 'Active'];
  displayedColumnsTrack = ['name', 'title', 'tracks', 'created', 'action'];
  dataSource0 = ELEMENT_DATA;
  displayedColumns1 = ['genre', 'created', 'Updated', 'Active'];
  dataSource2 = ELEMENT_DATA3;
  dataSource3 = ELEMENT_DATA4;
  dataSource1 = ELEMENT_DATA2;
  dataSource = new ExampleDataSource();
  displayedColumnsPlaylist = ['name', 'title', 'tracks', 'created', 'Updated', 'Active'];

  tabFlag: string = "0"
  trackActiveSub: any = undefined;
  toggleOnSub: any = undefined;

  //Genre
  public activeGenre: any = [];
  public allGenre: any = [];
  public updatedGenre: any = [];
  public allnewGenre: any = []
  public newActiveGenre: any = [];
  public newInActiveGenre: any = [];
  public searchFilterFocused = false;
  public searchString: any;
  public count = 0
  public ordering: any = '';
  public genrePage: number = 1;
  public currentGenrePage: number = 1
  @ViewChild(MatSort) sort: MatSort;
  public currentPage: number = 1;
  public activeGenreCount: number = 0;
  public updateActiveGenre: any = [];
  public updateActiveGenre1: any = [];
  public activePublic: boolean = false;
  isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');
  public expandedElement: any;
  public statusOpen = false;
  public expandrowValue = false;
  public loadingGenre = true;
  public loadingAllGenre = true;

  // Trending playlist
  public playlistCount = 0;
  public allPlaylist: any = [];
  public updatedPlaylist: any = [];
  public allnewPlaylist: any = [];
  public currentPlaylistPage: number = 1;
  public activePlaylistCount: number = 0;
  public updateActivePlaylist: any = [];
  public updateActivePlaylist1: any = [];
  public activePlaylist: any = [];
  public newActivePlaylist: any = [];
  public newInActivePlaylist: any = [];
  public activePublicPlaylist: boolean = false;
  public searchStringPlaylist: any = '';
  public loadingPlaylist = true;
  public loadingAllPlaylist = true;
  public activeButton = 0;
  public featurePage: number = 1;

  // Trending Track
  public trackCount = 0;
  public allPlaylistTrack: any = [];
  public updatedPlaylistTrack: any = [];
  public allnewTrack: any = [];
  public currentTrackPage: number = 1;
  public activeTrackCount: number = 0;
  public updateActiveTrack: any = [];
  public updateActiveTrack1: any = [];
  public activeTrack: any = [];
  public newActiveTrack: any = [];
  public newInActiveTrack: any = [];
  public activePublicTrack: boolean = false;
  public searchStringTrack: any = '';
  public loadingTrack = true;
  public loadingAllTrack = true;
  public currentPlaylistId: number = 0
  public currentPlaylistName: string = '';
  isExpansionDetailRow1 = (i: number, row: Object) => row.hasOwnProperty('detailRow');
  public expandedElement1: any;
  public trackEnable = false;
  public activeFeaTrackList = [];
  public isFeatureTrack = 0;
  selectecTrack: any;
  selectecTracks: any[] = [];
  public _isPlaying: boolean = false;
  wavePlay: boolean = true;
  private _currentlyPlaying: PlayingItem;
  private _subscriptions: Subscription[] = [];
  currentUser: User;
  playingTrack: Track = null;
  playingTrackVersion: TrackVersion = null;
  musicPlayerCollapsed = false;

  // Feature Tab
  public allFeaturelist: any = [];
  public allFeaturelistup: any = [];
  public searchStringFeature: any = '';
  public activePublicFeaturelist: boolean = false;
  allGenreItemlist: string;
  showModal: boolean = false;
  temp_allFeaturelistup: any[] = [];
  temp_allFeaturelist: any[] = [];
  public FeatureCount = 0;

  @ViewChild('hBSort') hBSort: MatSort;
  @ViewChild('sBSort') sBSort: MatSort;
  expandedTrack: Observable<Track> = null;
  expandedTrackSubject = new BehaviorSubject<Track>(null);
  @Output()
  collapseMusicPlayer = new EventEmitter<null>();

  @Output()
  listChange: EventEmitter<void> = new EventEmitter<void>();
  isPlay: boolean;
  isPause: boolean;
  isPlaying: boolean=false;


  constructor(public dialog: DialogService, public list: MenuListService, public _DialogService: DialogService,
    private _dialogService: DialogService, private sortTb: ChangeDetectorRef, private _userService: UserService,
    public config: MenuConfigService, public api: UserMenuService, public _playerService: PlayerService,
    private _sliderDialogService: SliderDialogService, public trackServ: TrackService,) {
    this.expandedTrack = this.expandedTrackSubject.asObservable();
  }

  get genreNumberOfPages(): number {
    if (this.tabFlag === '1') {
      return Math.ceil(this.count / listPageSize);
    }
    // if (this.tabFlag === '3') {
    //   return Math.ceil(this.playlistCount / listPageSize);
    // }
    //return Math.ceil(this.count / listPageSize);
  }
  get playlistNumberOfPages(): number {
    if (this.tabFlag === '3') {
      return Math.ceil(this.playlistCount / listPageSize);
    }
    if (this.tabFlag === '2')
      return Math.ceil(this.trackCount / listPageSize);
  }
  get featureNumberOfPages(): number {
    return Math.ceil(this.FeatureCount / listPageSize);
  }

  isUpdateTable: any;


  ngOnInit() {
    this.getGenreActiveList();
    this.getAllGenreList();
    this.getAllTrendingPlaylist();
    this.getPlaylistActiveList();
    this.getAllTrendingTrack();
    this.getTrackActiveList(this.currentPlaylistId);
    this.getAllFeatureItem();
    this.getAllActiveFeatureItem();


    this.dialog.dialogRefresh.subscribe(newItem => {
      this.isUpdateTable = newItem;

      if (this.isUpdateTable) {
        this.getAllActiveFeatureItem();
        this.getAllFeatureItem();
        this.deleteFeatureItem;
      }
    });

    this._subscriptions.push(
      this._userService.currentUserStream.subscribe((u) => {
        this.currentUser = u;
      }),
      this._playerService.currentlyPlaying.subscribe(data => {
        this._currentlyPlaying = data;
      }),
      // this._playerService.isPlaying.subscribe(isPlaying => {
      //   this._isPlaying = isPlaying;
      // }),
      this._playerService.isWavePlay.subscribe((p) => {
        this.wavePlay = p;
        this._isPlaying =p;
        if(p === true){
        this._playerService.currentPlaylistItems.isPlay = true;
        this._playerService.currentPlaylistItems.isPause = false;
        }
        else{
          this._playerService.currentPlaylistItems.isPlay = false;
          this._playerService.currentPlaylistItems.isPause = true;
        }
        console.log(this.wavePlay)
      }),
      this._playerService.isPlaying.subscribe((p) => {
        this._isPlaying = p;
      })
    );

    this._playerService.currentlyPlaying.subscribe(playingItem => {
      if (!playingItem) {
        this.playingTrack = null;
        this.playingTrackVersion = null;
        return;
      }
      this.playingTrack = playingItem.track;
      this.playingTrackVersion = playingItem.version;
    }),
      this._playerService.showMusicPlayer.subscribe(show => {
        this.musicPlayerCollapsed = !show;
      })
  }
  FeaturedTab() {
    this.tabFlag = '0'
    this.ordering =''
    this.activePublicFeaturelist = false;
    this.getAllFeatureItem();
    this.getAllActiveFeatureItem();
  }
  GenresTab() {
    this.tabFlag = '1'
    this.searchString = ''
    this.ordering =''
    this.activePublic = false;
    this.getGenreActiveList();
    this.getAllGenreList();
  }
  TracksTab() {
    this.ordering =''
    this.tabFlag = '2'
    this.currentPlaylistId = 0;
    this.currentPlaylistName = '';
    this.updateActiveTrack = [];
    this.searchStringTrack = '';
    this.isFeatureTrack = 0;
    this.activeFeaTrackList = [];
    this.activeTrack = [];
    this.activePublicTrack = false;
    this.trackEnable = false;
    this.getAllTrendingTrack();
    this.getTrackActiveList(this.currentPlaylistId);
  }
  PlaylistsTab() {
    this.ordering =''
    this.tabFlag = '3'
    this.searchStringPlaylist = ''
    this.activePublicPlaylist = false;
    this.getAllTrendingPlaylist();
    this.getPlaylistActiveList();
  }
  publisherror() {
    this.dialog.displayMenuDialog(PuplishcmsComponent).subscribe(changed => {
      if (changed) {
        this.newActivePlaylist = []
        this.newInActiveGenre = []
        this.getAllTrendingPlaylist();
        this.getPlaylistActiveList();
      }
    })
  }
  addplaylist(event) {

    this.dialog.displayMenuDialog(AddplaylistcmsComponent, event).subscribe(changed => {
      if (changed) {
        if (this.tabFlag === '2') {
          this.activePublicTrack = false;
          this.searchStringTrack = ''
          this.isFeatureTrack =0
          this.getAllTrendingTrack();
          //this.getTrackActiveList(this.currentPlaylistId);
        }
        else {
          this.activePublicPlaylist = false;
          this.getAllTrendingPlaylist();
          this.getPlaylistActiveList();
        }
      }
    });
  }
  addgenreall() {
    this.dialog.displayMenuDialog(CmsaddgenreComponent);
  }
  addplaylistFeature() {
    this.dialog.displayMenuDialog(CmsaddplaylistfeatureComponent);
  }

  // setPlayingTrack(pTrack:any) {
  //   this.selectecTracks=pTrack;
  //   this.selectecTrack=pTrack[0];
  // }

  playButtonPressed(track: Track, version: TrackVersion) {

    // this.isPlay=true;
    // this.isPause=false;

    
    // this.expandTrack(track);

    // this._isPlaying = true;
    // this.playingTrack = track;
    // this.playingTrackVersion = version;

    // this._playerService.updateShowMusicPlayer(true);
    // this._playerService.updatePlaybackContextList(track.versions.map(v => new PlayingItem(track, v)));
    // this._playerService.play(track, version);
    // this._playerService.updateIsPlaying(true);
    // this._playerService.updateCurrentlyPlaying(track, version);
   
    this.isPlaying=true;
    this._playerService.updateIsPlaying(true);
    this._playerService.play(track, version);
    // this._playerService.updateCurrentlyPlaying(track, version);

    this._playerService.currentPlaylistItems.isPlay = true;
    this._playerService.currentPlaylistItems.isPause = false;


  }

  pauseButtonPressed(track: Track, version: TrackVersion) {
    

    this._playerService.updateIsPlaying(false);

    this._playerService.currentPlaylistItems.isPlay = false;
    this._playerService.currentPlaylistItems.isPause = true;


    // this.isPlay=false;
    // this.isPause=true;
    // this._isPlaying=false;

    // this.playingTrack = track;
    // this.playingTrackVersion=version;

    // this._playerService.updatePlaybackContextList(track.versions.map(v => new PlayingItem(track, v)));

    // this._playerService.updateCurrentlyPlaying(track, version);
    // this._playerService.updateWavePlay(false);
    // this._playerService.updateIsPlaying(false);
  }


  isPlaying1(version: TrackVersion) {

    // if (!this._isPlaying || !this.wavePlay) return false;
    // if (!this.playingTrackVersion) return false;

    // if ((this._playerService.currentPlaylistItems.isPlay || this._playerService.currentPlaylistItems.isPause) && this.playingTrackVersion.id == version.id) {
    //   return true;
    // }sss
    // else if (!(this._playerService.currentPlaylistItems.isPause && this._playerService.currentPlaylistItems.isPlay)) {
    //   return false;
    // }

    if (!this._isPlaying || !this.wavePlay) return false;
    if (!this.playingTrackVersion) return false;
    return this.playingTrackVersion.id == version.id;
  }

  toggleMusicPlayerCollapsed() {
    this.musicPlayerCollapsed = !this.musicPlayerCollapsed;
  }

  collapseMusicPlayer1() {
    this.musicPlayerCollapsed = true;
  }

  setPlayingTrack1(track: Track) {
    this.playingTrack = track;
  }

  setPlayingTrackVersion(trackVersion: TrackVersion) {
    this.playingTrackVersion = trackVersion;
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => {
      s.unsubscribe();
    });
    this._playerService.updateWavePlay(false);
    this._playerService.updateIsPlaying(false);
    this._playerService.updateShowMusicPlayer(false);
    this._playerService.play(null, null);
    this._isPlaying = false;
  }
  changeTrack(event) {
    // if(this.isFeatureTrack >= 10 && event?.is_featured == true){
    //   alert("Only 10 track should be active")
    //   return;
    // }
    if (event?.is_featured) {
      this.activeFeaTrackList.push(
        {
          "id": event.id,
          "is_featured": true
        })
      for (let i = 0; i < event.versions.length; i++) {
        this.isFeatureTrack++;
      }
    }
    else {
      this.activeFeaTrackList = this.activeFeaTrackList.filter(function (e) {
        if (e.id !== event.id) {
          return e;
        }
      })
      this.activeFeaTrackList.push(
        {
          "id": event.id,
          "is_featured": false
        })
      if (this.isFeatureTrack > 0) {
        for (let i = 0; i < event.versions.length; i++) {
          this.isFeatureTrack--;
        }
      }
    }
    this.activePublicTrack = true;
  }

  changeTrackPlaylist(event, type) {
    let val
    if (type === 'remove') {
      val = false
    }
    else {
      val = event?.active_playlist_track
    }
    if (val) {
      this.currentPlaylistId = event.id;
      this.currentPlaylistName = event.name;

      if (this.currentPlaylistId !== 0) {
        let value = this.activeFeaTrackList;
        let data = {
          "playlist_id": this.currentPlaylistId,
          "active_playlist_track": true,
        }
        console.log(value)
        let url = environment.apiURL + this.config.updateActivePlaylist
        this.toggleOnSub = this.api.doPUT(url, data).subscribe((result: any) => {
          this.getTrackActiveList(event.id)
          this.trackEnable = true;
          this.activePublicTrack = true;
        },
          (error) => {
            // alert(error.error.error)
            let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
            this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
            });
            location.reload();
          });

      }
    }
    else {
      if (this.toggleOnSub) {
        this.toggleOnSub.unsubscribe()
        this.loadingGenre = false;
      }
      if (this.trackActiveSub) {
        this.trackActiveSub.unsubscribe();
        this.loadingGenre = false;
      }
      if (this.currentPlaylistId !== 0) {
        this.activeTrack = [];
        this.updateActiveTrack = new MatTableDataSource(this.activeTrack);
        let data = {
          "playlist_id": this.currentPlaylistId,
          "active_playlist_track": false,
        }

        this.activeFeaTrackList = this.activeFeaTrackList.filter(function (e) {
          if (e.id !== event.id) {
            return e;
          }
        })
        let dataFeature =[]
        for(let x of this.activeFeaTrackList){
        dataFeature.push(
          {
            "id": event.id,
            "is_featured": false
          })
        }
        this.activeFeaTrackList = dataFeature;
        let url = environment.apiURL + this.config.updateActivePlaylist
        this.api.doPUT(url, data).subscribe((result: any) => {
          this.publishTrack()
          this._playerService.nextTab(true);
          this._playerService.updateWavePlay(false);
          this._playerService.updateIsPlaying(false);
          this._playerService.updateShowMusicPlayer(false);
          this._playerService.play(null, null);
          this._isPlaying = false;
          this.trackEnable = false;
          this.activePublicTrack = false;
          this.currentPlaylistId = 0;
          this.isFeatureTrack = 0;
          this.currentPlaylistName = '';
          this.activeTrack = [];
          this.updateActiveTrack = new MatTableDataSource(this.activeTrack);
          this.sortTb.detectChanges();
          this.updateActiveTrack.sort = this.hBSort;
          this.getAllTrendingTrack();
        },
          (error) => {
            // alert(error.error.error);
            let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
            this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
            });

            location.reload();
          });
      }
    }
  }

  publishTrack() {

    if (this.activeFeaTrackList.length > 0) {
      let value = this.activeFeaTrackList;
      let data = {
        "all_track": value
      }
      console.log(value)
      let url = environment.apiURL + this.config.updateActiveTarck
      this.api.doPUT(url, data).subscribe((result: any) => {
        this.activeFeaTrackList = []
        this.isFeatureTrack = 0
        this.searchString = ''
      },
        (error) => {
          // alert(error.error.error);
          let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
          this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
          });

          location.reload();
        });
    }

    this.activeFeaTrackList = [];
    // this.isFeatureTrack = 0;
    this.currentPlaylistId = 0;
    this.currentPlaylistName = '';
    this.searchString = '';
    this.trackEnable = true;
    this.activePublicTrack = false;
    this.getAllTrendingTrack();
  }

  getGenreActiveList() {
    this.loadingGenre = true;
    let rows = []
    let url = environment.apiURL + this.config.allGenre
    this.api.doGET(url).subscribe(x => {
      this.activeGenre = x || [];
      let j = 0, newActiveGenre = [];
      // console.log(x)
      this.activeGenre = this.activeGenre
      this.activeGenreCount = this.activeGenre.length;
      for (let i = 0; i < this.activeGenre.length; i++) {
        if (this.activeGenre[i].is_featured) {
          newActiveGenre[j] = this.activeGenre[i]
          let time = this.activeGenre[i].created_at
          let updateTime = this.activeGenre[i].updated_at
          newActiveGenre[j].updateTime = new Date(updateTime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
          newActiveGenre[j++].time = new Date(time).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
        }
      }
      this.activeGenre = newActiveGenre
      this.updateActiveGenre = newActiveGenre
      this.updateActiveGenre = newActiveGenre.sort((a, b) => a.order - b.order);
      if (this.updateActiveGenre.length < 20 && this.updateActiveGenre.length !== 0) {
        for (let i = this.updateActiveGenre.length; i < (20); i++) {
          this.updateActiveGenre[i] = {}
        }
      }
      this.updateActiveGenre.forEach(element => {
        if (element?.id) {
          rows.push(element, { detailRow: true, element })
        }
        else {
          rows.push({})
        }
      });
      this.loadingGenre = false;
      this.updateActiveGenre = rows || [];
      this.updateActiveGenre1 = [];
      for (let j = 0; j < this.activeGenreCount; j++) {
        this.updateActiveGenre1[j] = newActiveGenre[j]
      }
    },
      (error) => {
        //alert(error.message)
      }
    )
  }

  getAllGenreList(event: any = '') {
    this.loadingAllGenre = true;
    let url;
    if (event == '') {
      url = environment.apiURL + this.config.activeGenre;
    }
    else {
      url = event;
    }
    this.api.doGET(url).subscribe(x => {
      const rows = [];
      this.allGenre = x || [];

      let j = 0, newAllGenrere = [];
      // console.log(x)
      this.count = this.allGenre.count
      this.allGenre = this.allGenre.results
      //this.allnewGenre = this.allGenre
      for (let i = 0; i < this.allGenre.length; i++) {
        if (this.allGenre[i].is_featured === false) {
          newAllGenrere[j] = this.allGenre[i]
          let time = this.allGenre[i].created_at
          let updateTime = this.allGenre[i].updated_at
          newAllGenrere[j].updateTime = new Date(updateTime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
          newAllGenrere[j++].time = new Date(time).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
        }
      }
      this.allGenre = newAllGenrere
      this.updatedGenre = newAllGenrere
      this.allGenre.forEach(element => rows.push(element, { detailRow: true, element }));
      this.allGenre = rows || [];
      this.loadingAllGenre = false;
      this.allnewGenre = rows
      this.allGenre = new MatTableDataSource(rows);
      // console.log(this.allGenre)
    },
      (error) => {
        //alert(error.message)
      }
    )
  }

  getAllTrendingPlaylist(event: any = '') {
    this.loadingAllPlaylist = true;
    let url;
    if (event == '') {
      url = environment.apiURL + this.config.addPlaylist;
    }
    else {
      url = event;
    }
    this.api.doGET(url).subscribe(x => {
      const rows = [];
      this.allPlaylist = x || [];
      let j = 0, newAllPlaylist = [];
      this.playlistCount = this.allPlaylist.count
      this.allPlaylist = this.allPlaylist.results
      //  this.allnewPlaylist = this.allPlaylist
      for (let i = 0; i < this.allPlaylist.length; i++) {
        if (this.allPlaylist[i].is_featured === false) {
          newAllPlaylist[j] = this.allPlaylist[i]
          let time = this.allPlaylist[i].created_at
          let updateTime = this.allPlaylist[i].updated_at
          newAllPlaylist[j].updateTime = new Date(updateTime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
          newAllPlaylist[j++].time = new Date(time).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
        }
      }
      this.allPlaylist = newAllPlaylist
      this.updatedPlaylist = newAllPlaylist
      this.allnewPlaylist = newAllPlaylist
      this.loadingAllPlaylist = false;
      this.allPlaylist = new MatTableDataSource(newAllPlaylist);
    },
      (error) => {
        //alert(error.message)
      }
    )
  }



  announceSortChange(sortState: Sort) {
    this.ordering =''
    let url;
    if (this.featurePage != 1) {
      url = environment.apiURL + this.config.addGenreTable + '?page=' + this.featurePage;
    } else {
      url = environment.apiURL + this.config.addGenreTable;
    }

    if (sortState.direction) {
      console.log(sortState.direction);

      if (sortState.direction == 'asc') {
        if (url.includes('?')) {
          url += '&ordering_by=' + sortState.active;
        }
        else {
          url += '?ordering_by=' + sortState.active;
        }
        this.ordering = sortState.active
      }
      else {
        if (url.includes('?')) {
          url += '&ordering_by=-' + sortState.active;
        }
        else {
          url += '?ordering_by=-' + sortState.active;
        }
        this.ordering = '-'+sortState.active
      }
      
      // url += '&order_by=-' + sortState.direction;

    }

    this.getAllFeatureItem(url);

  }

  pageChangeAddedFeature(page: number) {
    let url;
    this.featurePage = page
    if (page != 1) {
      if(this.ordering !== ''){
      url = environment.apiURL + this.config.addGenreTable + '?page=' + page +'&ordering_by=' + this.ordering;
      }
      else{
        url = environment.apiURL + this.config.addGenreTable + '?page=' + page
      }
    } else {
      if(this.ordering !== ''){
        url = environment.apiURL + this.config.addGenreTable  +'?ordering_by=' + this.ordering;
        }
        else{
        url = environment.apiURL + this.config.addGenreTable;
        }
    }
    this.getAllFeatureItem(url);
  }

  getAllFeatureItem(dynamicURL?: any) {

    let url = '';
    if (dynamicURL)
      url = dynamicURL;
    else
      url = environment.apiURL + this.config.addGenreTable;

    this.api.doGET(url).subscribe((x: any) => {
      this.FeatureCount = x.count
      this.allFeaturelist = x || []
      console.log(this.allFeaturelist)

      if (this.allFeaturelist.results.length > 0) {
        this.allFeaturelist.results.forEach(element => {
          element.is_active = false;
        });
        this.temp_allFeaturelist = this.allFeaturelist.results || [];
      }
    },
      (error: any) => {
        console.log(error);
      })
  }

  getAllActiveFeatureItem() {
    let url = environment.apiURL + this.config.addGenreTableup;
    this.api.doGET(url).subscribe(x => {
      this.allFeaturelistup = x || []
      console.log(this.allFeaturelistup)

      if (this.allFeaturelistup.results.length > 0) {
        this.allFeaturelistup.results.forEach(element => {
          element.is_active = true;
        });

        this.temp_allFeaturelistup = this.allFeaturelistup.results || [];
      }
    },
      (error: any) => {
        console.log(error);
      })
  }


  deleteFeatureItem(element: any) {
    let apiURL = environment.apiURL + this.config.deleteFeatureItem + element.id;
    this.api.doDelete(apiURL).subscribe(
      (result: any) => {
        console.log(result);
        this.getAllFeatureItem();
      },
      (errors: any) => {
        console.log(errors);
      })
  }

  changeFeatureDown(event) {
    this.activePublicFeaturelist = true;
    return;
  }

  changeFeatureUP(event) {
    let apiURL = environment.apiURL + this.config.updateActiveFeatureDown
    let data = {
      "all_features": [event.id]
    }
    this.api.doPUT(apiURL, data).subscribe(
      (result: any) => {
        let indexVal = this.temp_allFeaturelist.findIndex(obj => obj.id == event.id);
        this.temp_allFeaturelistup.push(event);
        this.temp_allFeaturelist.splice(indexVal, 1);

        this.getAllActiveFeatureItem();

        this.allFeaturelist.results = new MatTableDataSource(this.temp_allFeaturelist);
        this.allFeaturelistup.results = new MatTableDataSource(this.temp_allFeaturelistup);
        this.activePublicFeaturelist = true;
      },
      (errors: any) => {
        console.log(errors);
      })
  }


  doFeaturePublish() {
    let apiURL = environment.apiURL + this.config.updateActiveFeatureDown
    let data = {
      "active_features": this.temp_allFeaturelistup.map((obj, index) => ({
        "id": obj.id,
        "order": (index + 1),
        "is_active": obj.is_active
      }))
    };

    this.api.doPUT(apiURL, data).subscribe(
      (result: any) => {
        this.getAllActiveFeatureItem();
        this.getAllFeatureItem();
        this.allFeaturelist.results = new MatTableDataSource(this.temp_allFeaturelist);
        this.allFeaturelistup.results = new MatTableDataSource(this.temp_allFeaturelistup);
      },
      (errors: any) => {
        console.log(errors);
      })
    this.activePublicFeaturelist = false;
  }

  submitFeatureSearch(value) {
    let url = environment.apiURL + this.config.addFeatureSearch + value;
    this.api.doGET(url).subscribe(x => {
      this.temp_allFeaturelist = x['results'] || [];
      this.allFeaturelist.results = new MatTableDataSource(this.temp_allFeaturelist);
    },
      (error) => {
      })
  }

  movedownFeature(current: any) {
    let index = this.temp_allFeaturelistup.findIndex(f => f.id == current.id);
    let newdata = [];
    if (index < this.temp_allFeaturelistup.length - 1) {
      let swappedItems = this.swap(this.temp_allFeaturelistup, index, index + 1);

      for (let i = 0; i < this.temp_allFeaturelistup.length; i++) {
        if (this.temp_allFeaturelistup[i]?.element?.id === current.id) {
          newdata.push(i)
        }
      }
      let swappedItems1 = this.swap(swappedItems, newdata[0], newdata[0] + 1);
      this.temp_allFeaturelistup = [...swappedItems1];
      this.allFeaturelistup.results = new MatTableDataSource(this.temp_allFeaturelistup);
    }
    this.activePublicFeaturelist = true;
  };

  moveupFeature(current: any) {
    let index = this.temp_allFeaturelistup.findIndex(f => f.id == current.id);
    let newdata = [];
    if (index >= 1) {
      let swappedItems = this.swap(this.temp_allFeaturelistup, index, index - 1);
      for (let i = 0; i < this.temp_allFeaturelistup.length; i++) {
        if (this.temp_allFeaturelistup[i]?.element?.id === current.id) {
          newdata.push(i)
        }
      }
      let swappedItems1 = this.swap(swappedItems, newdata[0], newdata[0] - 1);
      this.temp_allFeaturelistup = [...swappedItems1];
      this.allFeaturelistup.results = new MatTableDataSource(this.temp_allFeaturelistup);
    }
    this.activePublicFeaturelist = true;
  }

  getPlaylistActiveList() {
    this.loadingPlaylist = true;
    let rows = []
    let url = environment.apiURL + this.config.allPlaylist
    this.api.doGET(url).subscribe(x => {
      this.activePlaylist = x || [];
      let j = 0, newActivePlaylist = [];
      // console.log(x)
      this.activePlaylist = this.activePlaylist
      this.activePlaylistCount = this.activePlaylist.length;
      this.activeButton = this.activePlaylist.length;
      for (let i = 0; i < this.activePlaylist.length; i++) {
        if (this.activePlaylist[i].is_featured) {
          newActivePlaylist[j] = this.activePlaylist[i]
          let time = this.activePlaylist[i].created_at
          let updateTime = this.activePlaylist[i].updated_at
          newActivePlaylist[j].updateTime = new Date(updateTime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
          newActivePlaylist[j++].time = new Date(time).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
        }
      }
      this.activePlaylist = newActivePlaylist
      this.updateActivePlaylist = newActivePlaylist
      this.updateActivePlaylist = newActivePlaylist.sort((a, b) => a.order - b.order);
      if (this.updateActivePlaylist.length < 3 && this.updateActivePlaylist.length !== 0) {
        for (let i = this.updateActivePlaylist.length; i < (3); i++) {
          this.updateActivePlaylist[i] = {}
        }
      }
      this.loadingPlaylist = false;
      this.updateActivePlaylist1 = [];
      for (let j = 0; j < this.activePlaylistCount; j++) {
        this.updateActivePlaylist1[j] = newActivePlaylist[j]
      }
    },
      (error) => {
        //alert(error.message)
      }
    )
  }

  getAllTrendingTrack(event: any = '') {
    this.loadingAllTrack = true;
    let url;
    if (event == '') {
      url = environment.apiURL + this.config.addPlaylistTrack+'?page_size=6';
    }
    else {
      url = event;
    }
    this.api.doGET(url).subscribe(x => {
      const rows = [];
      this.allPlaylistTrack = x || [];
      let j = 0, newAllTrack = [];
      this.trackCount = this.allPlaylistTrack.count
      this.allPlaylistTrack = this.allPlaylistTrack.results
      //  this.allnewTrack = this.allPlaylistTrack
      for (let i = 0; i < this.allPlaylistTrack.length; i++) {
        if (this.allPlaylistTrack[i].active_playlist_track === true) {
          this.currentPlaylistId = this.allPlaylistTrack[i].id
          this.currentPlaylistName = this.allPlaylistTrack[i].name
        }
        newAllTrack[j] = this.allPlaylistTrack[i]
        let time = this.allPlaylistTrack[i].created_at
        let updateTime = this.allPlaylistTrack[i].updated_at
        newAllTrack[j].updateTime = new Date(updateTime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
        newAllTrack[j++].time = new Date(time).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
      }
      this.allPlaylistTrack = newAllTrack
      this.updatedPlaylist = newAllTrack
      this.allnewTrack = newAllTrack
      this.loadingAllTrack = false;
      this.allPlaylistTrack = new MatTableDataSource(newAllTrack);
      if (this.currentPlaylistId !== 0) {
        this.currentPlaylistId = this.currentPlaylistId;
        this.currentPlaylistName = this.currentPlaylistName;
        this.trackEnable = true;
        // this.activePublicTrack = true;
        this.activePublicTrack = false;
        this.getTrackActiveList(this.currentPlaylistId)
      }
    },
      (error) => {
        //alert(error.message)
      }
    )
  }


  getTrackActiveList(event, type: any = '') {
    this.isFeatureTrack = 0;
    if (event !== 0) {
      this.loadingTrack = true;
      let rows = [], url
      if (type === '') {
        url = environment.apiURL + this.config.allTrack + event + '/get-assigned-tracks-list/?ordering_by=title'
      }
      else {
        url = type;
      }
      this.loadingGenre = true;
      this.trackActiveSub = this.api.doGET(url).subscribe(x => {
        
        this.activeTrack = x || [];
        let j = 0, newActiveTrack = [];
        console.log(x)
        this.activeTrack = this.activeTrack.results
        this.activeTrackCount = this.activeTrack.count;
        for (let i = 0; i < this.activeTrack.length; i++) {
          if (this.activeTrack[i]) {
            newActiveTrack[j] = this.activeTrack[i]
            let time = this.activeTrack[i].created_at
            let updateTime = this.activeTrack[i].updated_at
            for (let i1 = 0; i1 < this.activeTrack[i].versions.length; i1++) {
              let time = this.activeTrack[i].versions[i1].created_at
              let updateTime = this.activeTrack[i].versions[i1].updated_at
              newActiveTrack[j].versions[i1].updateTime = new Date(updateTime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
              newActiveTrack[j].versions[i1].time = new Date(time).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
            }
            newActiveTrack[j].updateTime = new Date(updateTime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
            newActiveTrack[j++].time = new Date(time).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
          }
          if (this.activeTrack[i].is_featured) {
            for (let i1 = 0; i1 < this.activeTrack[i].versions.length; i1++) {
              this.isFeatureTrack++;
            }
            // this.isFeatureTrack++;
            this.activeFeaTrackList.push(
              {
                "id": this.activeTrack[i].id,
                "is_featured": this.activeTrack[i].is_featured
              })
          }
        }
        // if (this.isFeatureTrack == 0) {
        //   this._playerService.nextTab(true);
        //   this._playerService.updateWavePlay(false);
        //   this._playerService.updateIsPlaying(false);
        //   this._playerService.updateShowMusicPlayer(false);
        //   this._playerService.play(null, null);
        //   this._isPlaying = false;
        // }

        this.activeTrack = newActiveTrack
        this.updateActiveTrack = newActiveTrack
        this.updateActiveTrack = newActiveTrack.sort((a, b) => a.order - b.order);
        this.updateActiveTrack.forEach(element => rows.push(element, { detailRow: true, element }));
        this.updateActiveTrack = rows || [];
        //  this.allnewTrack = rows
        this.activeTrack = rows
        this.updateActiveTrack = new MatTableDataSource(rows);
        this.loadingGenre = false;

      },
        (error) => {
          //alert(error.message)
        }
      )
    }
  }

  displayDeleteTrackVersionDialog(track: Track, version: TrackVersion) {
    if (!track || !version) return;
    this._dialogService.displayDeleteTrackVersionDialog(track, version).subscribe(changed => {
      if (changed) {
        let index = track.versions.findIndex(v => v.id == version.id);
        track.versions.splice(index, 1);
      }
    });
  }

  ngAfterContentChecked(): void {
    if (this.tabFlag == '1') {
      this.allGenre = new MatTableDataSource(this.allnewGenre);
      this.sortTb.detectChanges();
      this.allGenre.sort = this.sort;
    }
    if (this.tabFlag == '3') {
      this.allPlaylist = new MatTableDataSource(this.updatedPlaylist);
      this.sortTb.detectChanges();
      this.allPlaylist.sort = this.sort;
    }
    if (this.tabFlag == '2') {
      // this.updatedPlaylistTrack = new MatTableDataSource(this.newActiveTrack);
      // this.sortTb.detectChanges();
      // this.updatedPlaylistTrack.sort = this.sort;
      this.allPlaylistTrack = new MatTableDataSource(this.allnewTrack);
      this.sortTb.detectChanges();
      this.allPlaylistTrack.sort = this.sBSort;

      this.updateActiveTrack = new MatTableDataSource(this.activeTrack)
      this.sortTb.detectChanges();
      this.updateActiveTrack.sort = this.hBSort;
    }
  }

  sortChange(data) {
    this.ordering =''
    // console.log(data)
    if (data.active !== undefined && this.expandrowValue !== true) {
      if (data.active === 'genre' && (data._direction === 'asc' || data._direction === '')) {
        this.ordering = 'name'
      } else if (data.active === 'genre' && data._direction === 'desc') {
        this.ordering = '-name'
      }
      if (data.active === 'created' &&( data._direction === 'asc' || data._direction === '')) {
        this.ordering = 'created_at'
      } else if (data.active === 'created' && data._direction === 'desc') {
        this.ordering = '-created_at'
      }
      if (data.active === 'Updated' && (data._direction === 'asc' || data._direction === '')) {
        this.ordering = 'updated_at'
      } else if (data.active === 'Updated' && data._direction === 'desc') {
        this.ordering = '-updated_at'
      }
      let url = environment.apiURL + this.config.activeGenre + '&ordering_by=' + this.ordering + '&page=' + this.genrePage;
      this.getAllGenreList(url)
    }
  }

  sortChangePlaylist(data, type) {
    console.log(data)
    this.ordering =''
    if (data.active !== undefined) {
      if (data.active === 'name' && (data._direction === 'asc' || data._direction === '')) {
        this.ordering = 'name'
      } else if (data.active === 'name' && data._direction === 'desc') {
        this.ordering = '-name'
      }
      if (data.active === 'created' && (data._direction === 'asc' || data._direction === '')) {
        this.ordering = 'created_at'
      } else if (data.active === 'created' && data._direction === 'desc') {
        this.ordering = '-created_at'
      }
      if (data.active === 'Updated' && (data._direction === 'asc' || data._direction === '')) {
        this.ordering = 'updated_at'
      } else if (data.active === 'Updated' && data._direction === 'desc') {
        this.ordering = '-updated_at'
      }
      if (type === 'playlist') {
        let url = environment.apiURL + this.config.addPlaylist + '&ordering_by=' + this.ordering + '&page=' + this.genrePage;
        this.getAllTrendingPlaylist(url)
      }
      else {
        let url = environment.apiURL + this.config.addPlaylistTrack + '?ordering_by=' + this.ordering + '&page=' + this.genrePage;
        this.getAllTrendingTrack(url)
      }
    }
  }
  sortChangeTrack(data) {
    this.ordering =''
    console.log(data)
    if (data.active !== undefined && this.expandrowValue !== true) {
      if (data.active === 'name' && (data._direction === 'asc' || data._direction === '')) {
        this.ordering = 'title'
      } else if (data.active === 'name' && data._direction === 'desc') {
        this.ordering = '-title'
      }
      if (data.active === 'title' && (data._direction === 'asc' || data._direction === '')) {
        this.ordering = 'genre__name'
      } else if (data.active === 'title' && data._direction === 'desc') {
        this.ordering = '-genre__name'
      }
      if (data.active === 'tracks' && (data._direction === 'asc' || data._direction === '')) {
        this.ordering = 'sub_genre__name'
      } else if (data.active === 'tracks' && data._direction === 'desc') {
        this.ordering = '-sub_genre__name'
      }
      if (data.active === 'created' && (data._direction === 'asc' || data._direction === '')) {
        this.ordering = 'created_at'
      } else if (data.active === 'created' && data._direction === 'desc') {
        this.ordering = '-created_at'
      }

      let url = environment.apiURL + this.config.allTrack + this.currentPlaylistId + '/get-assigned-tracks-list/?ordering_by=' + this.ordering;
      this.getTrackActiveList(this.currentPlaylistId, url)
    }
  }

  valueUpdate() {
    this.expandrowValue = false;
  }

  movedownGenre(current: any) {
    let index = this.updateActiveGenre.findIndex(f => f.id == current.id);
    let newdata = [];
    if (index < this.updateActiveGenre.length - 1) {
      let swappedItems = this.swap(this.updateActiveGenre, index, index + 2);

      for (let i = 0; i < this.updateActiveGenre.length; i++) {
        if (this.updateActiveGenre[i]?.element?.id === current.id) {
          newdata.push(i)
        }
      }
      let swappedItems1 = this.swap(swappedItems, newdata[0], newdata[0] + 2);
      this.updateActiveGenre = [...swappedItems1];
    }
    this.activePublic = true;
  };

  moveupGenre(current: any) {
    let index = this.updateActiveGenre.findIndex(f => f.id == current.id);
    let newdata = [];
    if (index >= 1) {
      let swappedItems = this.swap(this.updateActiveGenre, index, index - 2);
      for (let i = 0; i < this.updateActiveGenre.length; i++) {
        if (this.updateActiveGenre[i]?.element?.id === current.id) {
          newdata.push(i)
        }
      }
      let swappedItems1 = this.swap(swappedItems, newdata[0], newdata[0] - 2);
      this.updateActiveGenre = [...swappedItems1];
    }

    this.activePublic = true;
  }

  movedownPlaylist(current: any) {
    let index = this.updateActivePlaylist.findIndex(f => f.id == current.id);
    if (index < this.updateActivePlaylist.length - 1) {
      let swappedItems = this.swap(this.updateActivePlaylist, index, index + 1);
      this.updateActivePlaylist = [...swappedItems];
    }
    this.activePublicPlaylist = true;
  };

  moveupPlaylist(current: any) {
    let index = this.updateActivePlaylist.findIndex(f => f.id == current.id);
    if (index >= 1) {
      let swappedItems = this.swap(this.updateActivePlaylist, index, index - 1);
      this.updateActivePlaylist = [...swappedItems];
    }
    this.activePublicPlaylist = true;
  }

  changeCanActive(event) {
    let rows = [], rows1 = [];
    // console.log(event)
    let count = 0
    if (this.updateActiveGenre1.length >= 20 && event.is_featured === true) {
      // alert('Maximum 20 slots will be able to display as active genre')
      let data = 'Maximum 20 slots will be able to display as active genre'
      this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
      });
      // event.is_featured = false;
      // console.log(this.updatedGenre)
      // this.updatedGenre = this.allGenre
      // this.allGenre.forEach(element => rows1.push(element, { detailRow: true, element }));
      // this.allGenre = rows1 || [];
      // this.allnewGenre = rows1
      // this.allGenre = new MatTableDataSource(rows);
      if (this.newActiveGenre.length === 0) {
        this.getAllGenreList()
      }
    }
    else {
      if (event.is_featured) {
        this.newActiveGenre.push(event.id)
        this.updateActiveGenre1.push(event)
        this.activePublic = true;
        let data = {
          "all_genres": [event.id]
        }
        let url = environment.apiURL + this.config.updateCmsGenre
        this.api.doPUT(url, data).subscribe((result: any) => {
          this.searchString = ''
          url = environment.apiURL + this.config.activeGenre1 + '?page=' + this.currentGenrePage + '&page_size=6';
          this.newActiveGenre = []
          this.getAllGenreList(url)
          this.getGenreActiveList()
        },
          (error) => {
            if (error.error.error) {
              // alert(error.error.error)
              let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
              this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
              });

              // location.reload();
            };

          });
        this.allGenre = this.updatedGenre.filter(function (e) {
          if (e.id !== event.id) {
            return e;
          }
        })
        this.updatedGenre = this.allGenre
        this.allGenre.forEach(element => rows1.push(element, { detailRow: true, element }));
        this.allGenre = rows1 || [];
        this.allnewGenre = rows1
        this.allGenre = new MatTableDataSource(rows);
      }
      else {
        this.newActiveGenre = this.newActiveGenre.filter(function (e) {
          if (e !== event.id) {
            return e;
          }
        })
        this.updateActiveGenre1 = this.updateActiveGenre1.filter(function (e) {
          if (e.id !== event.id) {
            return e;
          }
        })
      }
      count = this.updateActiveGenre1.length
      this.updateActiveGenre = this.updateActiveGenre1;
      if (this.updateActiveGenre.length < 20 && this.updateActiveGenre.length !== 0) {
        for (let i = this.updateActiveGenre.length; i < (20); i++) {
          this.updateActiveGenre[i] = {}
        }
      }
      if (count > 20) {
        // alert('Maximum 20 slots will be able to display as active genre')

        return
      }
      this.updateActiveGenre1 = [];
      for (let index = 0; index < count; index++) {
        this.updateActiveGenre1[index] = this.updateActiveGenre[index]
      }
      if (this.newActiveGenre.length === 0) {
        this.activePublic = false;
      }

    }
    if (this.updateActiveGenre1.length < 20) {
      console.log(this.updateActiveGenre, this.updateActiveGenre1)
      this.updateActiveGenre.forEach(element => {
        if (element?.id) {
          rows.push(element, { detailRow: true, element })
        }
        else {
          rows.push({})
        }
      });
      this.updateActiveGenre = rows || [];
    }

  }

  changeInActive(event) {
    // console.log(event)
    if (event.is_featured === false) {
      let data = {
        "id": event.id, "order": event.order, "is_featured": event.is_featured
      }
      this.newInActiveGenre.push(data)
      this.activePublic = true;
      this.newActiveGenre = this.newActiveGenre.filter(function (e) {
        if (e !== event.id) {
          return e;
        }
      })
      this.updateActiveGenre1 = this.updateActiveGenre1.filter(function (e) {
        if (e.id !== event.id) {
          return e;
        }
      })
      let value = this.newInActiveGenre;
      let data1 = {
        "active_genres": value
      }
      // console.log(value)
      let url = environment.apiURL + this.config.updateCmsGenre
      this.api.doPUT(url, data1).subscribe((result: any) => {
        url = environment.apiURL + this.config.activeGenre1 + '?page=' + this.currentGenrePage + '&page_size=6';
        this.newInActiveGenre = []
        this.searchString = ''
        this.getAllGenreList(url)
        this.getGenreActiveList()
      },
        (error) => {
        });
    }
    else {
      this.newInActiveGenre = this.newInActiveGenre.filter(function (e) {
        if (e.id !== event.id) {
          return e;
        }
      })
      this.newActiveGenre.push(event.id)
      if (this.newInActiveGenre.length == 0) {
        this.activePublic = false;
      }
    }
  }

  changeCanActivePlaylist(event) {
    let count = 0, valCount = 0, valInCount = 0;
    if (event.is_featured) {
      this.newActivePlaylist.push(event.id)
      this.activePublicPlaylist = true;
      //  if (this.updateActivePlaylist1.length < 3) {
      this.updateActivePlaylist1.push(event)

      this.allPlaylist = this.updatedPlaylist.filter(function (e) {
        if (e.id !== event.id) {
          return e;
        }
      })
      if (this.newActivePlaylist.length > 0) {
        let value = this.newActivePlaylist;
        let data = {
          "all_playlists": [event.id]
        }
        // console.log(value)
        let url = environment.apiURL + this.config.updateActivePlaylist
        this.api.doPUT(url, data).subscribe((result: any) => {
          this.searchString = ''
          this.newActivePlaylist = []
        },
          (error) => {
            if (error.error.error) {
              // alert  (error.error.error)
              let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
              this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
              });

            };

          });
      }

      this.updatedPlaylist = this.allPlaylist
      this.allPlaylist = new MatTableDataSource(this.updatedPlaylist);
      this.newInActivePlaylist = this.newInActivePlaylist.filter(function (e) {
        if (e.id !== event.id) {
          return e;
        }
      })
      // }
    }
    else {
      this.newActivePlaylist = this.newActivePlaylist.filter(function (e) {
        if (e !== event.id) {
          return e;
        }
      })
      this.updateActivePlaylist1 = this.updateActivePlaylist1.filter(function (e) {
        if (e.id !== event.id) {
          return e;
        }
      })
    }
    let data = {
      "id": event.id, "order": event.order, "is_featured": event.is_featured
    }
    this.newInActivePlaylist.push(data)
    count = this.updateActivePlaylist1.length
    this.updateActivePlaylist = this.updateActivePlaylist1;
    if (this.updateActivePlaylist.length < 3 && this.updateActivePlaylist.length !== 0) {
      for (let i = this.updateActivePlaylist.length; i < (3); i++) {
        this.updateActivePlaylist[i] = {}
      }
    }

    this.updateActivePlaylist1 = [];
    for (let index = 0; index < count; index++) {
      this.updateActivePlaylist1[index] = this.updateActivePlaylist[index]
    }
    if (this.newActivePlaylist.length === 0) {
      this.activePublicPlaylist = false;
    }
    let ids = []
    for (let i = 0; i < this.updateActivePlaylist1.length; i++) {
      if (this.updateActivePlaylist1[i].is_featured) {
        valInCount++
        ids.push(this.updateActivePlaylist1[i].id)
      }
    }
    let activeArray = ids.concat(this.newActivePlaylist)
    activeArray = activeArray.filter(
      (element, i) => i === activeArray.indexOf(element)
    );
    this.activeButton = activeArray.length

  }



  changeInActivePlaylist(event) {
    // console.log(event)
    let valInCount = 0;
    if (event.is_featured === false) {
      let data = {
        "id": event.id, "order": event.order, "is_featured": event.is_featured
      }
      this.newInActivePlaylist.push(data)
      this.activePublicPlaylist = true;
      this.newActivePlaylist = this.newActivePlaylist.filter(function (e) {
        if (e !== event.id) {
          return e;
        }
      })
      this.updateActivePlaylist1 = this.updateActivePlaylist1.filter(function (e) {
        if (e.id !== event.id) {
          return e;
        }
      })
      let count = this.updateActivePlaylist1.length;
      this.updateActivePlaylist1 = this.updateActivePlaylist1
      this.updateActivePlaylist = this.updateActivePlaylist1;
      if (this.updateActivePlaylist.length < 3 && this.updateActivePlaylist.length !== 0) {
        for (let i = this.updateActivePlaylist.length; i < (3); i++) {
          this.updateActivePlaylist[i] = {}
        }
      }
      this.updateActivePlaylist1 = [];
      for (let index = 0; index < count; index++) {
        this.updateActivePlaylist1[index] = this.updateActivePlaylist[index]
      }
      this.updatedPlaylist[this.updatedPlaylist.length] = event
      this.allPlaylist = this.updatedPlaylist
      this.updatedPlaylist = this.allPlaylist
      this.allPlaylist = new MatTableDataSource(this.updatedPlaylist);
      if (this.newInActivePlaylist.length > 0) {
        let value = this.newInActivePlaylist;
        let data = {
          "active_playlist": value
        }
        console.log(value)
        let url = environment.apiURL + this.config.updateActivePlaylist
        this.api.doPUT(url, data).subscribe((result: any) => {
          this.newInActivePlaylist = []
          this.searchString = ''
        },
          (error) => {
          });
      }
    }
    else {
      this.newInActivePlaylist = this.newInActivePlaylist.filter(function (e) {
        if (e.id !== event.id) {
          return e;
        }
      })
      this.updateActivePlaylist1.push(event)
      this.newActivePlaylist.push(event.id)
      this.allPlaylist = this.updatedPlaylist.filter(function (e) {
        if (e.id !== event.id) {
          return e;
        }
      })
      this.updatedPlaylist = this.allPlaylist
      this.allPlaylist = new MatTableDataSource(this.updatedPlaylist);
      if (this.newInActivePlaylist.length == 0) {
        this.activePublicPlaylist = false;
      }
    }
    let ids = []
    for (let i = 0; i < this.updateActivePlaylist1.length; i++) {
      if (this.updateActivePlaylist1[i].is_featured) {
        valInCount++
        ids.push(this.updateActivePlaylist1[i].id)
      }
    }
    let activeArray = ids.concat(this.newActivePlaylist)
    activeArray = activeArray.filter(
      (element, i) => i === activeArray.indexOf(element)
    );
    this.activeButton = activeArray.length

  }

  rowClicked(row) {
    this.expandrowValue = true;
    if (this.statusOpen == false) {
      this.expandedElement = row;
      this.statusOpen = true;
    } else if (this.statusOpen = true) {
      this.expandedElement = null;
      this.statusOpen = false;
    }
  }

  publishGenre() {
    let newActiveCount = 0, alertMessage = 0, newInActiveCount = 0
    if (this.newInActiveGenre.length > 0 || this.newActiveGenre.length > 0) {
      if (this.newActiveGenre.length > 0) {
        newActiveCount = this.newActiveGenre.length + this.activeGenreCount
        if (newActiveCount > 20) {
          alertMessage = 1
        }
      }
      if (this.newInActiveGenre.length > 0) {
        newInActiveCount = Math.abs(this.newInActiveGenre.length - this.activeGenreCount)
        if (newActiveCount > 20) {
          alertMessage = 2
        }
      }
    }
    if (alertMessage !== 1) {
      // if (this.newInActiveGenre.length > 0) {
      //   let value = this.newInActiveGenre;
      //   let data = {
      //     "active_genres": value
      //   }
      //   // console.log(value)
      //   let url = environment.apiURL + this.config.updateCmsGenre
      //   this.api.doPUT(url, data).subscribe((result: any) => {
      //     this.newInActiveGenre = []
      //     this.searchString = ''
      //   },
      //     (error) => {
      //     });
      // }
      // if (this.newActiveGenre.length > 0) {
      //   let value = this.newActiveGenre;
      //   let data = {
      //     "all_genres": value
      //   }
      //   // console.log(value)
      //   let url = environment.apiURL + this.config.updateCmsGenre
      //   this.api.doPUT(url, data).subscribe((result: any) => {
      //     this.searchString = ''
      //     this.newActiveGenre = []
      //   },
      //     (error) => {
      //       if (error.error.error) {
      //         // alert(error.error.error)
      //         let data = error.error?.error ? error.error?.error : (error.error?.detail ?error.error?.detail : (error.error?.details ? error.error?.details:``) )
      //         this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
      //         });

      //         // location.reload();
      //       };

      //     });
      // }
    }
    else {
      // alert('Maximum 20 slots will be able to display as active genre')

    }
    this.getSwappingGenre('Genre')
  }

  publishPlaylist() {
    let Count = (this.activePlaylistCount + this.newActivePlaylist.length) - this.newInActivePlaylist.length
    if (this.activeButton < 3) {
      this.publisherror()
      this.newInActivePlaylist = [];
      this.newActivePlaylist = []
      return
    }
    else {
      if (this.activeButton >= 3) {
        if (this.newInActivePlaylist.length > 0) {
          let value = this.newInActivePlaylist;
          let data = {
            "active_playlist": value
          }
          console.log(value)
          let url = environment.apiURL + this.config.updateActivePlaylist
          this.api.doPUT(url, data).subscribe((result: any) => {
            this.newInActivePlaylist = []
            this.searchString = ''
          },
            (error) => {
            });
        }
        if (this.newActivePlaylist.length > 0) {
          let value = this.newActivePlaylist;
          let data = {
            "all_playlists": value
          }
          // console.log(value)
          let url = environment.apiURL + this.config.updateActivePlaylist
          this.api.doPUT(url, data).subscribe((result: any) => {
            this.searchString = ''
            this.newActivePlaylist = []
          },
            (error) => {
              if (error.error.error) {
                //  alert (error.error.error)
                let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
                this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
                });

              };

            });
        }

        this.newInActivePlaylist = []
        this.newActivePlaylist = []
        this.searchString = ''
      }
      this.getSwappingGenre('Playlist')
      this.newInActivePlaylist = []
      this.newActivePlaylist = []
      this.searchString = ''
    }
  }


  deleteGenre() {
    this.dialog.displayMenuDialog(RemoveGenreDialogueComponent).subscribe(res => {
      this.getAllGenreList()
      this.getGenreActiveList()
    })
  }


  displayTrackInfo(track: Track, version: TrackVersion) {
    this.collapseMusicPlayer.emit();
    this.trackServ.trackPopData = track.element
    this._dialogService.displayTrackInfo(track.element, version, 'cms');
  }

  rowClicked1(track: Track) {
    
    // return;
    if (this.trackIsExpanded(track)) {
      this._playerService.updateShowMusicPlayer(false);
      this._playerService.play(null, null);
      this.expandTrack(track);
      return;
    }
    this.expandTrack(track);
    if (!track.versions || track.versions.length <= 0) return;
    this.expandrowValue = true;
    if (this.statusOpen == false) {
      this.expandedElement = track;
      this.statusOpen = true;
    } else if (this.statusOpen = true) {
      this.expandedElement = null;
      this.statusOpen = false;
    }
    // let version = track.versions.find(v => v.is_full_version);
    let version = track.versions[0]
    if (!version) version = track.versions[0];
   // this.isPlaying=true;
   
    this._playerService.updateShowMusicPlayer(true);
    this._playerService.updatePlaybackContextList(track.versions.map(v => new PlayingItem(track, v)));
    this._playerService.play(track, version);
  }

  expandTrack(track: Track) {
    if (
      this.expandedTrackSubject.value &&
      this.expandedTrackSubject.value.id == track.id
    ) {
      this.expandedTrackSubject.next(null);
      return;
    }
    this.expandedTrackSubject.next(track);
  }

  trackIsExpanded(track: Track) {
    return (
      this.expandedTrackSubject.value &&
      this.expandedTrackSubject.value.id == track.id
    );
  }
  getSwappingGenre(event: any = '') {
    if (event == 'Genre') {
      let newOrder = [], newActiveGenre = [], newActiveGenre1 = [];
      this.searchString = ''
      for (let i = 0; i < this.updateActiveGenre.length; i++) {
        if (this.updateActiveGenre[i]?.element?.id) {
          newActiveGenre.push(i)
        }
        else {
          if (this.updateActiveGenre[i].id) {
            newActiveGenre1.push(this.updateActiveGenre[i])
          }
        }
      }
      for (let i = 0; i < this.activeGenreCount; i++) {
        let data = {
          id: newActiveGenre1[i].id,
          order: i,
          is_featured: newActiveGenre1[i].is_featured
        }
        newOrder.push(data)
      };
      let data = {
        "active_genres": newOrder
      }
      let url = environment.apiURL + this.config.updateCmsGenre
      this.api.doPUT(url, data).subscribe((result: any) => {
        this.activePublic = false;
        this.getAllGenreList()
        this.getGenreActiveList()
      },
        (error) => {
          if (error.error.error) {
            // alert(error.error.error)
            let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
            this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
            });

            location.reload();
          };

        });
    }
    else {
      let newOrder = [], newActivePlaylist = [], newActivePlaylist1 = [];
      this.searchStringPlaylist = ''
      for (let i = 0; i < this.updateActivePlaylist.length; i++) {
        if (this.updateActivePlaylist[i]?.element?.id) {
          newActivePlaylist.push(i)
        }
        else {
          if (this.updateActivePlaylist[i].id) {
            newActivePlaylist1.push(this.updateActivePlaylist[i])
          }
        }
      }
      for (let i = 0; i < this.updateActivePlaylist1.length; i++) {
        let data = {
          id: newActivePlaylist1[i].id,
          order: i,
          is_featured: newActivePlaylist1[i].is_featured
        }
        newOrder.push(data)
      };
      let data = {
        "active_playlist": newOrder
      }
      let url = environment.apiURL + this.config.updateActivePlaylist
      this.api.doPUT(url, data).subscribe((result: any) => {
        this.activePublicPlaylist = false;
        this.getAllTrendingPlaylist();
        this.getPlaylistActiveList();
      },
        (error) => {
          if (error.error.error) {
            // alert(error.error.error)
            let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
            this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
            });

            location.reload();
          };

        });
    }
  }

  pageChangeFeature(page: number) {
    let url;
    this.featurePage = page
    if (page != 1) {
      if (this.ordering !== '') {
        url = environment.apiURL + this.config.addGenreTable1 + '?page=' + page + '&ordering_by=' + this.ordering + '&page_size=6';
      }
      else {
        url = environment.apiURL + this.config.addGenreTable1 + '?page=' + page + '&page_size=6';
      }
    } else {
      if(this.ordering !== ''){
        url = environment.apiURL + this.config.addGenreTable  +'?ordering_by=' + this.ordering + '&page_size=6';
        }
        else{
      url = environment.apiURL + this.config.addGenreTable;
        }
    }
    this.getAllFeatureItem(url);
  }

  pageChange(page: number) {
    let url;
    this.genrePage = page
    this.currentGenrePage = page
    if (page != 1) {
      if (this.ordering !== '') {
        url = environment.apiURL + this.config.activeGenre1 + '?page=' + page + '&page_size=6' + '&ordering_by=' + this.ordering;
      }
      else {
        url = environment.apiURL + this.config.activeGenre1 + '?page=' + page + '&page_size=6';
      }
    } else {
      if (this.ordering !== '') {
        url = environment.apiURL + this.config.activeGenre + '&ordering_by=' + this.ordering;
      }
      else {
        url = environment.apiURL + this.config.activeGenre;
      }
    }
    this.getAllGenreList(url);
  }

  playlistPageChange(page: number) {
    let url;
    this.genrePage = page
    if (page != 1) {
      if (this.ordering !== '') {
        url = environment.apiURL + this.config.addPlaylist1 + '?page=' + page + '&page_size=6' + '&ordering_by=' + this.ordering;
      }
      else {
        url = environment.apiURL + this.config.addPlaylist1 + '?page=' + page + '&page_size=6';
      }
    } else {
      if (this.ordering !== '') {
        url = environment.apiURL + this.config.addPlaylist + '&ordering_by=' + this.ordering;
      }
      else {
        url = environment.apiURL + this.config.addPlaylist;
      }
    }
    this.getAllTrendingPlaylist(url);
  }


  trackPageChange(page: number) {
    let url;
    this.genrePage = page
    if (page != 1) {
      if (this.ordering !== '') {
        url = environment.apiURL + this.config.addPlaylistTrack1 + '?page=' + page + '&page_size=6' + '&ordering_by=' + this.ordering;
      }
      else{
      url = environment.apiURL + this.config.addPlaylistTrack1 + '?page=' + page + '&page_size=6';
      }
    } else {
      if (this.ordering !== '') {
        url = environment.apiURL + this.config.addPlaylistTrack + '?page=' + page + '&page_size=6' + '&ordering_by=' + this.ordering;
      }
      else{
      url = environment.apiURL + this.config.addPlaylistTrack + '?page=' + page + '&page_size=6';
      }
    }
    this.getAllTrendingTrack(url);
  }

  submitSearch() {
    if (this.searchString.trim() !== '' && this.searchString.length > 0) {
      let url = environment.apiURL + this.config.activeGenre1 + '?search=' + this.searchString;
      this.getAllGenreList(url)
    }
    else {
      this.getAllGenreList()
    }
  }

  submitPlaylistSearch() {
    if (this.searchStringPlaylist.trim() !== '' && this.searchStringPlaylist.length > 0) {
      let url = environment.apiURL + this.config.addPlaylist1 + '?search=' + this.searchStringPlaylist;
      this.getAllTrendingPlaylist(url)
    }
    else {
      this.getAllTrendingPlaylist()
    }
  }
  submitTrackSearch() {
    if (this.searchStringTrack.trim() !== '' && this.searchStringTrack.length > 0) {
      let url = environment.apiURL + this.config.addPlaylistTrack1 + '?search=' + this.searchStringTrack+'&page_size=6';
      this.getAllTrendingTrack(url)
    }
    else {
      this.getAllTrendingTrack()
    }
  }

  displayDeletePlaylistDialog(element: any, type: any) {
    let data, url, value
    if (type == 'playlist') {
      data = [{
        "playlist_id": element.id,
        "is_alive_cms": false,
      }]

      value = { "playlist_info": data }
      url = environment.apiURL + this.config.addPlaylistDropdown
      this.api.doPUT(url, value).subscribe(x => {
        this.activePublicPlaylist = false;
        this.getAllTrendingPlaylist();
        this.getPlaylistActiveList();
      },
        (error) => {
          if (error) {
            console.log(error)
            //  alert(error.error.error)
          };

        });
    }
    else {
      data = [{
        "playlist_id": element.id,
        "is_playlist_enable": false,
      }]

      value = { "playlist_info": data }
      url = environment.apiURL + this.config.addPlaylistTrackDropdown
      this.api.doPUT(url, value).subscribe(x => {
        this.activePublicPlaylist = false;
        this.getAllTrendingTrack();
        this.getTrackActiveList(this.currentPlaylistId);
      },
        (error) => {
          if (error) {
            console.log(error)
            //  alert(error.error.error)
          };

        });
    }


  }

  public swap(activeGenre: any[], x: any, y: any) {
    var b = activeGenre[x];
    activeGenre[x] = activeGenre[y];
    activeGenre[y] = b;
    return activeGenre;
  }
}
export interface PeriodicElement {
  Size: string;
  position: string;
  weight: string;
  symbol: string;
  Artistname: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { position: 'Featured Playlist', Size: 'lets go 2022 - party tracks', weight: '30', symbol: '11/12/21 . 12.23pm', Artistname: '11/12/21 . 12.23pm' },
  { position: 'Featured Album', Size: 'Dont drink the water', weight: '30', symbol: '11/12/21 . 12.23pm', Artistname: '11/12/21 . 12.23pm' },
];
export interface PeriodicElement1 {

  position: string;

  symbol: string;
  Artistname: string;
}

const ELEMENT_DATA2: PeriodicElement1[] = [
  { position: 'Latin', symbol: '11/12/21 . 12.23pm', Artistname: '11/12/21 . 12.23pm' },
  { position: 'Marching Band', symbol: '11/14/21 •  10:27pm', Artistname: '12/2/21  •  7:27pm' },



];

export interface PeriodicElement3 {

  Size: string;
  position: string;
  weight: string;
  symbol: string;
  Artistname: string;
}

const ELEMENT_DATA3: PeriodicElement3[] = [
  { position: 'New Opportunities For Living', Size: 'Steingrimur Thorhallsson', weight: 'Bumper & Stings', symbol: 'Hip-Hop Stings', Artistname: '11/8/20' },
  { position: 'New Opportunities For Living', Size: 'Steingrimur Thorhallsson', weight: 'Bumper & Stings', symbol: 'Hip-Hop Stings', Artistname: '11/8/20' },


];
export interface PeriodicElement4 {


  position: string;
  weight: string;
  symbol: string;
  Artistname: string;
}

const ELEMENT_DATA4: PeriodicElement4[] = [
  { position: 'Let’s go 2022 - Party Tracks', weight: '30', symbol: '11/14/21 •  10:27pm', Artistname: '12/2/21  •  7:27pm' },
  { position: 'Let’s go 2022 - Party Tracks', weight: '10', symbol: '11/14/21 •  10:27pm', Artistname: '12/2/21  •  7:27pm' },


];
export interface Element {
  genre: string;
  created: string;
  Updated: string;
  name: string;
}
const data: Element[] = [
  { genre: 'Blues', created: '8/26/21 . 10:08 AM', Updated: '8/26/21 . 10:08 AM', name: '5 Subgenres' },
  { genre: 'Bumpers and stings', created: '8/26/21 . 10:08 AM', Updated: '8/26/21 . 10:08 AM', name: '5 Subgenres' },

];
export class ExampleDataSource extends DataSource<any> {
  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<Element[]> {
    const rows = [];
    data.forEach(element => rows.push(element, { detailRow: true, element }));
    console.log(rows);
    return of(rows);
  }

  disconnect() { }



}