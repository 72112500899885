import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { MenuListService } from '../../shared/services/menu-list.service';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { environment } from '../../../environments/environment';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { DialogService } from 'src/app/shared/services/dialog.service';


@Component({
  selector: 'app-edit-subgenre-dialogue',
  templateUrl: './edit-subgenre-dialogue.component.html',
  styleUrls: ['./edit-subgenre-dialogue.component.scss']
})
export class EditSubgenreDialogueComponent implements OnInit {
    imageChangedEvent: any = '';
  imageUrl = '/assets/img/placeholder-image.png';
  croppedImage = '/assets/img/placeholder-image.png';
  finalImg = '/assets/img/placeholder-image.png';
  oldMainUrl ='/assets/img/placeholder-image.png';
  oldThumbUrl= '/assets/img/placeholder-image.png';
  show = false;
  newFinalImg:any ='';
  showCropper = false;
  currentCropImage = '/assets/img/placeholder-image.png';
  crop() {
    this.show = false;
    this.showCropper = false;
    this.finalImg = this.croppedImage;
  }
//  toggle(){
// this.show = !this.show;
//   }
toggleclose(){
  this.show = !this.show;

  this.imageUrl = this.oldMainUrl;
  if(this.oldThumbUrl){
    this.finalImg = this.oldThumbUrl
  }
}
  @ViewChild('myFileInput') myFileInput;

  onFileChange(event) {
    this.myFileInput.nativeElement.value = '';
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.newFinalImg = event;
    this.currentCropImage = this.croppedImage
    const fileToReturn = this.base64ToFile(
      event.base64,
      this.image,
    )
    console.log(fileToReturn)
    this.newFinalImg = fileToReturn;
  }
  base64ToFile(data, filename) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  imageLoaded() {
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }
  fileToUpload: any;
  
  image=''
  genreImg  =''
  
  handleFileInput(file: FileList) {
    this.show = true;
    this.fileToUpload = file.item(0);
    this.imageChangedEvent = event;
    this.finalImg = '/assets/img/placeholder-image.png';
    //Show image preview
    this.genreImg = this.fileToUpload;
    this.image = this.fileToUpload.name;
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
      // this.finalImg = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
    this.isEditImage = true
  
  }
  toppings = new FormControl();
  parent:string = '';
  subGnereName: string = ""
  isEditImage = false;
  toppingList: string[] = ['Sub Admin Groups', 'Test1', 'Test2', 'Test3', 'Test4'];
  loader: boolean = true;
  constructor(private dialogRef: MatDialogRef<EditSubgenreDialogueComponent>,
    public list: MenuListService,
    public config: MenuConfigService,
    public api: UserMenuService,
    public _DialogService:DialogService

  ) { 
    this.list.getAllParents()
  }

  ngOnInit(): void {
    if( this.list.currSubGnere?.marquee_image){
      let data ='https://s3.amazonaws.com/mibe-subscribe-dev/media/'
      let data1= this.list.currSubGnere.marquee_image.url
      console.log(data1)
     this.imageUrl = data+this.list.currSubGnere.marquee_image
     this.oldMainUrl =  data+this.list.currSubGnere.marquee_image

      var splitUrl =  this.imageUrl.split('/');
      this.image = splitUrl[splitUrl.length-1]
      this.finalImg = data+this.list.currSubGnere.thumb_image
      this.oldThumbUrl =  data+this.list.currSubGnere.thumb_image
      var splitUrl =  this.finalImg.split('/');
      this.image = splitUrl[splitUrl.length-1]
    }
    setTimeout(()=>{                         
      this.loader = false;
    }, 500);
  }
  close(event:any='') {
    this.dialogRef.close(event);
  }
  cropData() {
    this.showCropper = true;
    this.show = false;
    this.currentCropImage = this.currentCropImage
  }
  resetCrop() {
    this.showCropper = false;
    this.show = true;
  }
  create(){
    if(this.subGnereName === ''){
      this.subGnereName = this.list.currSubGnere.name
    }
    const formData:any = new FormData();
    formData.append("name", this.subGnereName);
    formData.append("parent",this.list.currSubGnere.parent);
    if(this.isEditImage === true){
      // formData.append("file", this.genreImg);
       formData.append("marquee_image", this.genreImg);
       formData.append("thumb_image", this.newFinalImg);
    }
    let data = { 
      "name": this.subGnereName,
      "parent": this.list.currSubGnere.parent
    }
      let requestUrl = environment.apiURL + this.config.doGenres + this.list.currSubGnere.id + '/'
      this.api.doPUT(requestUrl,formData).subscribe(x=>{
        this.close(true);
        this.list.getAllGenres();
      },
      error => {
      //  alert (error.error.error)
      let data = error.error?.error ? error.error?.error : (error.error?.detail ?error.error?.detail : (error.error?.details ? error.error?.details:``) )
        this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
        });
        this.close()
      }
    )
  }

}
