import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Instrument } from 'src/app/shared/models/instrument.model';
import { NetworkReference } from 'src/app/shared/models/networkreference.model';
import { SonicReference } from 'src/app/shared/models/sonicreference.model';
import { Track } from 'src/app/shared/models/track.model';
import { TrackVersion } from 'src/app/shared/models/trackversion.model';
import { TrackInfo } from 'src/app/shared/models/trackinfo.model';
import { DialogData } from 'src/app/shared/trackinfo/trackinfo.component';
import { SliderDialogService } from '../../shared/services/slider-dialog.service';
import { MenuListService } from '../../shared/services/menu-list.service';
import { EditTrackVersionComponent } from '../../shared/edit-track-version/edit-track-version.component';
import { TrackService } from '../../shared/services/track.service';
import { MatDialog } from '@angular/material/dialog';
import { PlayerService } from 'src/app/shared/services/player.service';
import { D } from '@angular/cdk/keycodes';
import { Mood } from 'src/app/shared/models/mood.model';
import { EditNewTrackVersionComponent } from 'src/app/shared/edit-new-track-version/edit-new-track-version.component';

@Component({
  selector: 'app-track-details-composer',
  templateUrl: './track-details-composer.component.html',
  styleUrls: ['./track-details-composer.component.scss']
})
export class TrackDetailsComposerComponent implements OnInit {

  composers: TrackInfo[] = [];
  instrumentationComposer = [];
  sonicComposer =[];
  nwComposer =[];
  dataTable = [];
  track: Track;
  trackversion: TrackVersion;
  displayUserInputs = false;
  panelOpenState = false;

  displayedColumns: string[] = [
    'composer',
    'composer_pro',
    'composer_percentage',
    'publisher_name',
    'publisher_pro',
    'publisher_percentage'
  ];
  rejectedReasons: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<TrackDetailsComposerComponent>,
    private _sliderDialogService: SliderDialogService,
    public list: MenuListService,
    public trackServ: TrackService,
    public dialog: MatDialog,
    private _playerService: PlayerService,
  ) { }

  ngOnInit(): void {
    this.dataTable = this.data.composers['composer_details'];
    this.dataTable  =  this.dataTable.filter(function(e) { 
      if(e.composerShare > 0){
        return e;
      }
    })
    this.dataTable  =  this.dataTable.filter(function(e) { 
      if(e.composerShare > 0){
       let float = isFloat(e.composerShare)
       let float1 = isFloat(e.publisherShare)
       if(float){
        e.composerShare = e.composerShare.toFixed(2);
       }
       if(float1){
        e.publisherShare = e.publisherShare.toFixed(2);
       }
        return e;
      }
    })
    
    this.composers = this.data.composers
    this.instrumentationComposer = ((this.composers['version_instruments'].length > 0) ? this.composers['version_instruments'] : this.composers['instruments'])
    let desc = ((this.composers['version_instruments_desc'].length > 0) ? this.composers['version_instruments_desc'] : this.composers['instruments_desc'])
    let inst = [...this.instrumentationComposer, ...desc]
    this.instrumentationComposer = inst.filter(function (e) {
      if (e.added_by_role === 0) {
        return e;
      }
    })
    let sonic = [...this.composers['version_sonic_references'],...this.composers['support_sonic_band_references']]
    this.sonicComposer = ((sonic.length > 0) ? sonic :this.composers['sonic_references'])
    this.sonicComposer  =  this.sonicComposer.filter(function(e) { 
      if(e.added_by_role === 0){
        return e;
      }
    })
   
    let tvData = [...this.composers['support_tv_nw_references'],...this.composers['version_tv_nw_references']]
    this.nwComposer = ((tvData.length > 0) ? tvData :this.composers['tv_nw_references'])
    this.nwComposer  =  this.nwComposer.filter(function(e) { 
      if(e.added_by_role === 0){
        return e;
      }
    })
    this.rejectedReasons = this.data.composers['rejected_status_reason'];
    if (this.rejectedReasons && (this.rejectedReasons != null || this.rejectedReasons != undefined || this.rejectedReasons != '')) {
      this.rejectedReasons = this.rejectedReasons.split(",");
      console.log(this.rejectedReasons.length)
    }
    this.track = this.data.track
    this.trackversion = this.data.version
    this.trackServ.versionPopData = this.data.version;
    this.trackServ.trackPopData = this.data.track
    console.log(this.data.track)
    console.log(this.data.version)
  }

  getUserInputsColor(userInput) {
    if (userInput && userInput.added_by_role == 0) {
      return 'composer';
    } else if (userInput && userInput.added_by_role == 1) {
      return 'approver';
    } else if (userInput && userInput.added_by_role == 2) {
      return 'meta-tagger';
    } else {
      return '';
    }
  }

  close() {
    this.dialogRef.close();
  }
  moodsTrackBy(i: number, mood: Mood) {
    return mood.id + mood.name;
  }
  instrumentsTrackBy(i: number, instrument: Instrument) {
    return instrument.name;
  }

  networkReferencesTrackBy(i: number, netRef: NetworkReference) {
    return netRef.id + netRef.name;
  }

  sonicReferencesTrackBy(i: number, sonicRef: SonicReference) {
    return sonicRef.id + sonicRef.name;
  }
  // Replace track version
  replaceTrackVersion() {
    this.dialog.closeAll()
    this._sliderDialogService.getTrackCreationTypeSubject.next("create");
    this._sliderDialogService.getReplaceTrackVersionSubject.next("replace");
    this.list.getTrackTypeSubject.next("trackversion");
    // this._playerService.updateWavePlay(false);
    // this._playerService.updateIsPlaying(false);
    // this._playerService.updateShowMusicPlayer(false);
    // this._playerService.play(null, null);
    this._playerService.nextTab(true);
    this._playerService.updateViewType(true);
    this._playerService.updatePreviewPlaying(true);
    this._playerService.updateShowMusicPlayer(false);
    this._playerService.play(null, null);
    this._sliderDialogService.open(EditNewTrackVersionComponent, {
      width: "600px",
      title: "REPLACE A TRACK VERSION",
      panelClass: "edit-track-dialog",
      data: { track: this.trackServ.trackPopData, version: this.trackServ.versionPopData }
    }, (user) => {

    });

  }

}
function isFloat(n){
  return Number(n) === n && n % 1 !== 0;
}
