import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from '../models/user.model';
import { UserService } from '../services/user.service';
import { EmailGroupService } from '../services/email-group.service';
import { EmailGroup } from '../models/email-group.model';
import { DialogService } from '../services/dialog.service';

export interface DialogData {
  users: [];
  groupId: number;
}
@Component({
  selector: 'app-remove-users-from-email-group',
  templateUrl: './remove-users-from-email-group.component.html',
  styleUrls: ['./remove-users-from-email-group.component.scss']
})
export class RemoveUsersFromEmailGroupComponent implements OnInit {

  loading: boolean = false;
  getUserIds: any = [];

  constructor(
    private dialogRef: MatDialogRef<RemoveUsersFromEmailGroupComponent>,
    private _emailGroupService: EmailGroupService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,public _dialogService: DialogService,
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close(false);
  }
  userTrackBy(i:number, user:User) {
    return user.id;
  }

  removeUsers() {
    //this.loading = true;
    // if (this.data.isArtist) {
    //   this.data.user.is_artist = false;
    //   if (!this.data.user.is_client) this.data.user.is_active = false;
    // } else {
    //   this.data.user.is_client = false
    //   if (!this.data.user.is_artist) this.data.user.is_active = false;
    // }
    this.data.users.forEach(element => this.getUserIds.push(element['id']));
    this._emailGroupService.removeUsersFromGroup(this.getUserIds, this.data.groupId).subscribe(()=> {
      this.loading = false;
      this.dialogRef.close(true);
    }, (error) => {
      console.log(error);
      let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details :(error?.error.name ? error?.error?.name : '') ))
      this._dialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
      });
      this.dialogRef.close(true);
    //  alert ("An error ocurred while removing the users. Check your connections and try again");
    });
  }

}
