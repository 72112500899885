<h4 *ngIf="successReset=== false">Set New Password</h4>

<div   *ngIf="successReset=== false" class="form-wrapper green-theme">
    <form  >
        <div class="error" *ngFor="let error of errors">{{ error }}</div>
        <!-- <div class="form-row">
            <mat-form-field>
                <input
                    placeholder="New Password"
                    type="New Password"
                    name="New Password"
                    [(ngModel)]="setPassword"
                    tabindex="1"
                    maxlength="16"
                    minlength="8"
                    required
                    matInput
                />
            </mat-form-field>
            <mat-hint class="hint-info">Use “8” characters or more</mat-hint>
        </div> -->
        <div class="form-row">
            <mat-form-field>
                <input name="New Password" type="New Password" tabindex="2" maxlength="16" minlength="8" required [type]="passwordInputType" placeholder="New Password" [(ngModel)]="setPassword" matInput>
                <button class="input-suffix" type="button" matSuffix (click)="togglePasswordInputType()">
                    <svg width="23px" height="18px" viewBox="0 0 23 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" *ngIf="passwordInputType == 'password'">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                            <g transform="translate(-906.000000, -381.000000)" stroke="#15151C">
                                <g transform="translate(512.000000, 160.000000)">
                                    <g transform="translate(49.000000, 191.000000)">
                                        <g transform="translate(346.000000, 31.000000)">
                                            <path d="M0,8 C0,8 3.74025974,0 10.2857143,0 C16.8311688,0 20.5714286,8 20.5714286,8 C20.5714286,8 16.8311688,16 10.2857143,16 C3.74025974,16 0,8 0,8 Z"></path>
                                            <ellipse cx="10.2857143" cy="8" rx="2.80519481" ry="3"></ellipse>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <svg width="23px" height="18px" viewBox="0 0 23 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" *ngIf="passwordInputType == 'text'">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                            <g transform="translate(-906.000000, -381.000000)">
                                <g transform="translate(512.000000, 160.000000)">
                                    <g transform="translate(49.000000, 191.000000)">
                                        <g transform="translate(346.000000, 31.000000)">
                                            <path d="M0,8 C0,8 3.74025974,0 10.2857143,0 C16.8311688,0 20.5714286,8 20.5714286,8 C20.5714286,8 16.8311688,16 10.2857143,16 C3.74025974,16 0,8 0,8 Z" stroke="#15151C" fill="#15151C"></path>
                                            <ellipse stroke="#FFFFFF" fill="#FFFFFF" cx="10.2857143" cy="8" rx="2.80519481" ry="3"></ellipse>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </button>
            </mat-form-field>
            </div>
            <div class="form-row">
            <mat-form-field>
                <input name="Confirm Password" type="Confirm Password" tabindex="2" maxlength="16" minlength="8" required [type]="passwordInputType1" placeholder="Confirm Password" [(ngModel)]="confirmPassword" matInput>
                <button class="input-suffix" type="button" matSuffix (click)="togglePasswordInputType('confirm')">
                    <svg width="23px" height="18px" viewBox="0 0 23 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" *ngIf="passwordInputType1 == 'password'">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                            <g transform="translate(-906.000000, -381.000000)" stroke="#15151C">
                                <g transform="translate(512.000000, 160.000000)">
                                    <g transform="translate(49.000000, 191.000000)">
                                        <g transform="translate(346.000000, 31.000000)">
                                            <path d="M0,8 C0,8 3.74025974,0 10.2857143,0 C16.8311688,0 20.5714286,8 20.5714286,8 C20.5714286,8 16.8311688,16 10.2857143,16 C3.74025974,16 0,8 0,8 Z"></path>
                                            <ellipse cx="10.2857143" cy="8" rx="2.80519481" ry="3"></ellipse>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <svg width="23px" height="18px" viewBox="0 0 23 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" *ngIf="passwordInputType1 == 'text'">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                            <g transform="translate(-906.000000, -381.000000)">
                                <g transform="translate(512.000000, 160.000000)">
                                    <g transform="translate(49.000000, 191.000000)">
                                        <g transform="translate(346.000000, 31.000000)">
                                            <path d="M0,8 C0,8 3.74025974,0 10.2857143,0 C16.8311688,0 20.5714286,8 20.5714286,8 C20.5714286,8 16.8311688,16 10.2857143,16 C3.74025974,16 0,8 0,8 Z" stroke="#15151C" fill="#15151C"></path>
                                            <ellipse stroke="#FFFFFF" fill="#FFFFFF" cx="10.2857143" cy="8" rx="2.80519481" ry="3"></ellipse>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </button>
            </mat-form-field>

            <mat-hint class="hint-info">Use “8” characters or more</mat-hint>
        </div>
        <div class="form-row">
            <button
                type="submit"
                class="green"
                [disabled]="setPassword.length < 8 || confirmPassword.length < 8 "
                (click) = resetPassword();
            >
            Reset Password
            </button>
           
        </div>
    </form>
</div> 


<!-- You’re all set! -->
  <h4 *ngIf="successReset">You’re all set!</h4>

<div *ngIf="successReset" class="form-wrapper green-theme">
    <form>
        <div class="form-row">
           <p class="succes-msg">Your password has been successfully updated.</p>
        </div>
        <div class="form-row">
            <button
                type="submit"
                class="green"
                (click)="login()"
            >
            Go to Login
            </button>
           
        </div>
    </form>
</div>
