import { Pipe, PipeTransform } from '@angular/core';
import { TrackStatusValue } from '../models/trackstatus-value.model';
/*
 * Formats number in seconds to a pretty duration.
 * Usage:
 *   value | duration
 * Example:
 *   {{ 96 |  duration }}
 *   formats to: 01:36
*/
@Pipe({name: 'track-status'})
export class TrackStatusPipe implements PipeTransform {
  transform(value: number): TrackStatusValue {
    // PROCESSING = 0
    // SYSTEM_REJECTED = 1
    // PENDING_REVIEW = 2
    // PENDING_REVIEW_APPROVAL = 3
    // REJECTED = 4
    // ACCEPTED = 5
    // DISABLED = 6
    if (value == null) return;
    let statuses: TrackStatusValue[] = [
      new TrackStatusValue({value: 0, color: '#e3b950', name: 'Processing'}),
      new TrackStatusValue({value: 1, color: '#d73939', name: 'System Rejected'}),
      new TrackStatusValue({value: 2, color: '#e3b950', name: 'Pending'}),
      new TrackStatusValue({value: 3, color: '#47cdff', name: 'Pending Approval'}),
      new TrackStatusValue({value: 4, color: '#d73939', name: 'Denied'}),
      new TrackStatusValue({value: 5, color: '#50e3c2', name: 'Approved'}),
      new TrackStatusValue({value: 6, color: '#8a8a8d', name: 'Disabled'})
    ];
    return statuses[value];
  }
}
