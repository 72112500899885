import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MenuListService } from '../../shared/services/menu-list.service';
import { environment } from '../../../environments/environment';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { UserUploadPopupComponent } from 'src/app/shared/user-upload-popup/user-upload-popup.component';


@Component({
  selector: 'app-remove-moods-dialogue',
  templateUrl: './remove-moods-dialogue.component.html',
  styleUrls: ['./remove-moods-dialogue.component.scss']
})
export class RemoveMoodsDialogueComponent implements OnInit {
  loaderContent: boolean = true;
  loader: boolean = false;
  constructor(private dialogRef: MatDialogRef<RemoveMoodsDialogueComponent>,  public _DialogService:DialogService,public list: MenuListService, public api: UserMenuService, public config: MenuConfigService) { }

  ngOnInit(): void {
    setTimeout(()=>{                         
      this.loaderContent = false;
    }, 500);
  }
  close() {
    this.dialogRef.close();
  }
  
  removeUser(){    
    this.loader = true; 
    let requestUrl = environment.apiURL + this.config.getAllMOODSlist + this.list.moodId + '/';
    let data = { 
      id: this.list.moodId
    }
    this.api.doDelete(requestUrl,data).subscribe(x=>{      
      this.loader = false;       
      this.list.currentLetter = ''
      this.list.getAllMOODS();
      this.close();
    }, error => {
      //alert(error.error.detail);
      // alert(`This Mood is associated with a track(s), hence can't be deleted.`)
      let data = error.error?.error ? error.error?.error : (error.error?.detail ?error.error?.detail : (error.error?.details ? error.error?.details:`This Moods is associated with a track(s), hence can't be deleted.`) )
      this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
      });
      this.close()
    })
  }
}
