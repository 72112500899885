import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { environment } from '../../../environments/environment';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { DialogService } from 'src/app/shared/services/dialog.service';
/* import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';
const moment = _rollupMoment || _moment; */

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MM/YY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class ProfileComponent implements OnInit {
  maxDate = new Date();
  primary:any;
  toppings = new FormControl();
  searchFilterFocused:any;
  searchString:any;
  primaryPhoneControl:FormControl = new FormControl();
  toppingList: string[] = ['Sub Admin Groups', 'Test1', 'Test2', 'Test3', 'Test4'];
  dob: any;
  loadingUserUpdate:boolean = false;
  errorMessage1 = 0;
  error =''
  public customPatterns = {'0': { pattern: new RegExp('\[a-zA-Z\]')}};  
  public numberPattern = {'0': { pattern: new RegExp('^[0-9]')}};
  profileForm: FormGroup;
  password: string = "";
  isNewUser: boolean = false;
  errorMessage: string;

  username:string = "";

  phoneControllers:FormControl[] = [];
  phoneNumberArray = [];
  user = {
    country: '',
    state: ''
  }
socialModal: boolean = false;
socialNetworkName: any;
addedSocialName: boolean = false;
socialControllers:FormControl[] = [];
socialArray = [ {
  social_type:"FACEBOOK",
  value:"",
},
{
  social_type:"TWITTER",
  value:"",
},
{
  social_type:"INSTAGRAM",
  value:"",
},
{
  social_type:"TIKTOK",
  value:"",
},
{
  social_type:"YOUTUBE",
  value:"",
},
{
  social_type:"CLUBHOUSE",
  value:"",
},
{
  social_type:"SNAPCHAT",
  value:"",
},
{
  social_type:"SOUNDCLOUD",
  value:"",
},
{
  social_type:"TWITCH",
  value:"",
}
];

phoneNumber: any = '';
currentValue: any;
submitted:boolean= false;
otherNetwork: boolean = false;
indexRemove : number;
deletedName = ''
dataRemove: boolean = false;
socialData: boolean = false;
value: any ='';
  constructor(public fb: FormBuilder, public api: UserMenuService, public config: MenuConfigService, private datePipe: DatePipe,public dialog: DialogService,) { }

  ngOnInit(): void {
    this.profileForm = this.fb.group({
      email: new FormControl(''),
      first_name: new FormControl(''),
      last_name: new FormControl(''),
      nick_name: new FormControl(''),
      birth_date: new FormControl(''),
      address1: new FormControl(''),
      address2: new FormControl(''),
      city: new FormControl(''),
      state: new FormControl(''),
      country: new FormControl(''),
      postal_code: new FormControl(''),
      phone_number: new FormControl(''),
      
    social_media_links: new FormControl(''),
    password: new FormControl(''),
    newPassword: new FormControl(''),
    confirmPassword: new FormControl(''),
    })
    this.getUserProfile()
  }
  formSave(){
    console.log(this.profileForm)
  }

  removeLink (index: number){
    this.indexRemove = index;
     this.deletedName = this.socialArray[index].social_type
     
  }
  removeNumber(index: number) {
    this.phoneNumberArray.splice(index, 1);
    this.phoneControllers.splice(index, 1);
    console.log(this.phoneNumberArray)
  }

  removeUser (event: string){
    this.dataRemove = false;
    let data =  this.socialArray[this.indexRemove].social_type;
    if(data == event){
    this.socialArray = this.socialArray.filter(function(e) { 
      if(e.social_type !== data){
        return e;
      }
    })
  }
  }
  addPhoneNumber(){
    this.phoneNumberArray.push({
      number_type:"",
      phone_number:""
    })
    }
    addsociallinks(){
      this.socialModal=false
      this.addedSocialName = true;
      this.socialArray.push({
        social_type:this.socialNetworkName,
        value: this.value
      })
      this.value="";
      }
validateNumber(event, i?:number) {  
  const keyCode = event.keyCode;
  const excludedKeys = [8, 37, 39, 46];
  if ((event.key === ' ' || isNaN(Number(event.key)))&&!((
    (keyCode >= 96 && keyCode <= 105) ||
    (excludedKeys.includes(keyCode))))) {
    event.preventDefault();
  }
}
getPhoneCode(e, i?:number){
  let l = e.value.length;
  this.currentValue = e.id;
  //this.phoneNumber = '';
  if(l == 1){
    if(e.value !== "+"){
      this.phoneNumber = '+1' + e.value;
      if(i!==undefined){
      this.phoneNumberArray[i].phone_number = this.phoneNumber
      }
    }
  }else{
    this.phoneNumber = e.value;
  }
}
getUserProfile(){
      let url = environment.apiURL + this.config.doProfile;
      this.api.doGET(url).subscribe(x=>{    
        //this.dob = this.datePipe.transform(x['birth_date'],'MM/yy');    
        this.profileForm.patchValue(x);
        /* this.phoneNumberArray = [ {
          number_type:"mobile",
          phone_number:""
          
      }]; */
      let phone = this.phoneNumberArray.filter(x => x.number_type == 'mobile' || x.number_type== 'office');
      if(x['additional_numbers'].length > 0 &&  x['additional_numbers'] !== null &&  x['additional_numbers'] !== undefined )
      {
        this.phoneNumberArray = x['additional_numbers'];

      }
      else{
        this.phoneNumberArray.push({
          number_type:"",
          phone_number:""
        })
       
      }
      console.log( this.phoneNumberArray)
        this.user.country = x['country'];
        this.user.state = x['state'];
      //   if (!this.phoneNumberArray) {
      //     this.phoneNumberArray = [{
      //       number_type: "mobile",
      //       phone_number: ""

      //     }];
      //  }
          for(let y of this.socialArray){
            if( x['social_media_links']){
           for (let i of x['social_media_links']) {
             if (y.social_type == i.social_type) {
               y.value = i.value
             }
           }
          }
          }
          if( x['social_media_links']){
          for (let i of x['social_media_links']) {
            if (i.social_type !== 'FACEBOOK' && i.social_type !== 'INSTAGRAM' && i.social_type !== 'TWITTER' && i.social_type !== 'TIKTOK' && i.social_type !== 'YOUTUBE' && i.social_type !== 'CLUBHOUSE' && i.social_type !== 'SNAPCHAT' && i.social_type !== 'SOUNDCLOUD' && i.social_type !== 'TWITCH') {
              if (i.social_type)
                this.socialArray.push({
                  social_type: i.social_type,
                  value: i.value
                })
            }
          }
          }
      },
      (error)=>{
        
        //alert(error.message)
      }
    )
}
getSocialName(val){
  this.socialNetworkName = val;
      if(val !== ''){
        this.socialData = true;
      }
      else{
        this.socialData = false;
      }
}
getSocialUrl(event){
  this.value = event;
   }
updateUserProfile(){
  this.submitted = true;
  this.loadingUserUpdate = true;
  let phone = this.phoneNumberArray.filter(x => x.number_type == 'mobile' || x.number_type== "Office");
  console.log(phone)
  let socialLinks = this.socialArray.filter(x => x.social_type == 'FACEBOOK' || x.social_type == 'INSTAGRAM')
  if(this.profileForm.invalid || this.phoneNumberArray.length === 0 || this.user.state == '' || this.user.country == ''){

    //alert('Enter required fields')
    if(this.profileForm.value.confirmPassword.length < 4 ||  this.profileForm.value.newPassword.length < 4){
      this.loadingUserUpdate = false;
      this.errorMessage1 = 1;
      this.error = 'Password must be 8 characters!'
      return ;
    }
    else{
    this.errorMessage1 = 1;
    this.error = 'Enter required fields'
    this.loadingUserUpdate = false;
    return;
    }
  }
 
  if(this.profileForm.value.confirmPassword !== this.profileForm.value.newPassword){
    this.loadingUserUpdate = false;
    this.error = 'New password and Confirm password must be same!'
    this.errorMessage1 = 1;
    return ;
  }
  let date = {
    newDate: this.profileForm.value.birth_date
   }
   let data=  this.datePipe.transform(date.newDate,'yyyy-MM-dd')
   
  let postUserBody = {
    "email": this.profileForm.value.email,
    "first_name": this.profileForm.value.first_name,
    "last_name": this.profileForm.value.last_name,
    "nick_name": this.profileForm.value.nick_name,
    "birth_date": data,
    "address1": this.profileForm.value.address1,
    "address2": this.profileForm.value.address2,
    "city": this.profileForm.value.city,
    "state": this.user.state,
    "country": this.user.country,
    "postal_code": Number(this.profileForm.value.postal_code.toString().replace(",", "")),
    "phone_number": this.phoneNumber,
    "additional_numbers": phone,
    "social_media_links": this.socialArray,
    "password": this.profileForm.value.confirmPassword ? this.profileForm.value.confirmPassword : null
  }
  console.log(postUserBody)
  this.socialArray 
  
  let requestURL = environment.apiURL + this.config.doProfile;
  this.api.doPUT(requestURL, postUserBody).subscribe((result: any) => {
    console.log(result)
    this.loadingUserUpdate = false;
    this.errorMessage1 = 0
  },
    (error) => {
      this.loadingUserUpdate = false;
      let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
      this.dialog.UserUploadPopupConfirmPopup(data).subscribe(changed => {
      });
      this.errorMessage1 = 0
      //alert(error.error.detail)
    });
}
/* birthdayChange(event) {
  this.profileForm.value.birth_date = this.datePipe.transform(event.value,'MM/yy');
  console.log(this.profileForm.value.birth_date)
} */


}
