import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  
  constructor(
    private _router: Router,public _userService: UserService,
  ) {}

  ngOnInit(): void {
  }

  isActive(tab:string) {
    return this._router.url.includes(tab);
  }

}
