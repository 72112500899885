<div class="user-mgmt">
    <div class="header">
        <ul class="navigation">
            <li class="nav-item" [ngClass]="{'nav-item-active': isActive('user-account')}">
                <a class="nav-link" [routerLink]="['/composer', 'user-account']">MY INFO</a></li>
            <li class="nav-item" [ngClass]="{'nav-item-active': isActive('pro')}">
                <a class="nav-link" [routerLink]="['/composer', 'pro']">P.R.O</a></li>
            <li class="nav-item" [ngClass]="{'nav-item-active': isActive('co-writer')}">
                <a class="nav-link" [routerLink]="['/composer', 'co-writer']">CO-WRITERS</a>
            </li>
            <li class="nav-item" [ngClass]="{'nav-item-active': isActive('payment')}">
                <a class="nav-link" [routerLink]="['/composer', 'payment']">PAYMENT</a>
            </li>
        </ul>
    </div>
    <div class="body">
        <!-- <app-data *ngIf="isActive('user-account')"></app-data>
        <app-pro *ngIf="isActive('user-account')"></app-pro>
        <app-reports *ngIf="isActive('co-writer')"></app-reports> -->
        <router-outlet></router-outlet>

    </div>
</div>