<div class="track-details-card">
    <div class="title-card-left">
      <b>TRACK DETAILS<span class="allfields"><small>*All fields required </small></span></b>
    </div>
    <div class="title-description" *ngIf="version.id==undefined">Please enter the details below to begin your submission
    </div>
    <div class="title-description" *ngIf="version.id!=undefined && !replaceTrackVersion">Review track details to ensure
      all information was accurately inputted by artist</div>
    <div class="title-description" *ngIf="version.id!=undefined && replaceTrackVersion">Please enter the details below to
      replace track version</div>
    <div class="check-titletrack" style="margin-bottom: 2%;">
      <div class="meta-data edit-track" *ngIf="version.id==undefined && !replaceTrackVersion">
        <mat-checkbox class="checkbox-margin" [(ngModel)]="isMainTrack" (ngModelChange)="setMainTrack($event)">Use version as main track</mat-checkbox>
      </div>
    </div>
    <mat-card class="grid-card">
      <div class="artist-title" *ngIf="track.artist && !replaceTrackVersion">
        <b>ARTIST:</b> {{track.artist.name}}
      </div>
  
      <div class="grid-container">
        <div class="grid-item" *ngIf="version.id==undefined">
          <mat-form-field appearance="fill">
            <mat-label>Parent Track</mat-label>
            <mat-select [(value)]="selectedparent" panelClass="exportdownloadpanel-parent" [disableOptionCentering]="true" placeholder="Select" [(ngModel)]="track.track.id" (selectionChange)="trackDetail($event)">
              <mat-option *ngFor="let track of allTracks" [value]="track.id">{{track.title}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="grid-item" *ngIf="version.id!=undefined" [ngClass]="{'disabledElement full-opacity': replaceTrackVersion}">
          <mat-form-field appearance="fill" [ngClass]="{'disabledElement':getParentVal}">
            <mat-label>Parent Track</mat-label>
            <!-- <mat-select [value]="track.track.title" panelClass="exportdownloadpanel-parent" [disableOptionCentering]="true" placeholder="Select" [(ngModel)]="track.track.id">
              <mat-option *ngFor="let track of allTracks" [value]="track.id">{{track.title}}</mat-option>
            </mat-select> -->
            <mat-select placeholder="Select parent track" [value]="track.track.title"  [ngModel]="trackId" (selectionChange)="trackDetail($event)">
              <mat-option [ngClass]="{'disabledElement':getParentVal}">
                <ngx-mat-select-search  [ngClass]="{'disabledElement':getParentVal}"
                  [searching]="searchingParent"
                  [formControl]="parentFilterCtrl"
                  placeholderLabel="Select parent"
                  noEntriesFoundLabel="No Options Found">
                  <svg ngxMatSelectSearchClear class="filter-clear-icon" width="26px" height="24px" viewBox="0 0 26 24"
                    version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                      stroke-linejoin="round">
                      <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                        <g id="Genre-Options">
                          <g id="Close" transform="translate(1439.000000, 32.000000)">
                            <path d="M24,0 L0,24" id="Path"></path>
                            <path d="M0,0 L24,24" id="Path"></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </ngx-mat-select-search>
              </mat-option>
              <!-- <mat-select-filter [placeholder]="'Filter'" [displayMember]="'name'" [array]="allTracks" (filteredReturn)="filteredList1 =$event"></mat-select-filter> -->
              <!-- <mat-option *ngFor="let item of filteredList1" [value]="item.id">
                {{item.name}}
              </mat-option>
              <mat-option [disabled]="true" style="text-align: center; background-color: #50e3c2; color: black!Important" (click)="load();$event.preventdefault()">
               Load More
              </mat-option> -->
              <mat-option *ngFor="let track of allTracks" [value]="track.id" >{{track.title}}</mat-option>
              <button [disabled]='nextList == null' (click)="loadNextOnScroll($event)" style="text-align: center;background-color: #50E3C2;width: 100%;">Load More</button>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="grid-item">
          <mat-form-field appearance="fill" [ngClass]="{'disabledElement':!track.id}">
            <mat-label>Version</mat-label>
            <!-- <mat-select [panelClass]="'card-select'" placeholder="Select" [disableOptionCentering]="true" [ngModel]="version.title" (selectionChange)="trackVersionTitle($event)">
              <mat-option *ngFor="let option of trackVersionList" [value]="option">
                {{ option }}
              </mat-option>
            </mat-select> -->
            <input matInput [(ngModel)]="versionTitle" placeholder="Version Title" (input)="titleChanged()"
            (blur)="titleChanged()">

          </mat-form-field>
          <div *ngIf="showVerNameErr" class="error">The track name and the version name shouldn't be same.</div>
        </div>

   
        <div class="grid-item grid-item-5">
          <mat-form-field appearance="fill" floatLabel="always">
            <mat-label>Track Ending</mat-label>
            <mat-select [panelClass]="'card-select'" placeholder="Select" [disableOptionCentering]="true" [ngModel]="version.track_ending" (selectionChange)="trackEnding($event)">
              <mat-option *ngFor="let option of trackEndingList" [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>

      </div>
      <div class="meta-data">
        <mat-checkbox *ngIf="version.id" [disabled]="version.title !== 'Narrative'" [(ngModel)]="version.is_full_version">Full-Length?</mat-checkbox>
        <div *ngIf="version.id">
          <mat-radio-group *ngIf="isInstrumental" [(ngModel)]="version.is_instrumental" (change)="radioChange($event)">
            <mat-radio-button class="checkbox-margin" name="instrumental" [value]="isInstrumental">Instrumental</mat-radio-button>
            <mat-radio-button class="checkbox-margin" name="vocals" [value]="isVocal">Vocals</mat-radio-button>
          </mat-radio-group>
          <mat-radio-group *ngIf="isVocal" [(ngModel)]="version.is_vocals" (change)="radioChangeVocal($event)">
            <mat-radio-button class="checkbox-margin" name="instrumental" [value]="isInstrumental">Instrumental</mat-radio-button>
            <mat-radio-button class="checkbox-margin" name="vocals" [value]="isVocal">Vocals</mat-radio-button>
          </mat-radio-group>
        </div>
        
        <div *ngIf="version.id === undefined">
          <mat-radio-group [(ngModel)]="version.is_instrumental" (change)="radioChange($event)">
            <mat-radio-button class="checkbox-margin" name="instrumental" [value]="isInstrumental">Instrumental</mat-radio-button>
            <mat-radio-button class="checkbox-margin" name="vocals" [value]="isVocal">Vocals</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="meta-data set2-btns" *ngIf="version.id!=undefined && !replaceTrackVersion">
        <mat-checkbox [(ngModel)]="version.is_mibe_owned" (ngModelChange)="setMibeOwned($event)">MIBE Owned</mat-checkbox>
        <mat-checkbox class="checkbox-margin" [(ngModel)]="version.is_five_star" (ngModelChange)="setFiveStar($event)">5-Star</mat-checkbox>
      </div>
    </mat-card>
  </div>
  <div class="create-track general track-details-card" *ngIf="replaceTrackVersion && (url ==='')">
    <div class="section">
      <mat-card class="grid-card">
        <div class="repace-player">
          <input id="replace_track" type="file" [(ngModel)]="File" [ngModelOptions]="{standalone: true}" (change)="selectFiles($event)" #fileInput accept=".wav" content-type="audio/wav">
          <label for="replace_track" *ngIf="selectedFiles && selectedFiles?.length==0"><svg xmlns="http://www.w3.org/2000/svg" height="15px" viewBox="0 0 24 24" width="15px" fill="#FFFFFF" style="fill: black;margin:-2px 9px;">
              <path d="M0 0h24v24H0V0z" fill="none"></path>
              <path d="M5 5c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H6c-.55 0-1 .45-1 1zm2.41 9H9v5c0 .55.45 1 1 1h4c.55 0 1-.45 1-1v-5h1.59c.89 0 1.34-1.08.71-1.71L12.71 7.7c-.39-.39-1.02-.39-1.41 0l-4.59 4.59c-.63.63-.19 1.71.7 1.71z"></path>
            </svg>
            Select a replacement track</label>
          <div class="mat-card-upload" *ngIf="selectedFiles && selectedFiles!=''">
            <div id="file" type="file">
              <div class="upload-left">
                <mat-progress-bar mode="determinate" [(value)]="progressInfos" *ngIf="isUploading && !isFailed">
                </mat-progress-bar>
  
                <div *ngIf="url ===''">
                  <div *ngIf="url ===''" [ngClass]="!isUploading ? 'disabledElement1' : ''">
                    <strong>{{ selectedFiles.name }}</strong>
                    <p>{{ sizeOf(selectedFiles.size) }}</p>
                  </div>
                  <app-loader *ngIf="!isUploading" style="width: 100%;
                  margin-top: -10%;
                  position: absolute;
                  margin-left: 40%;"></app-loader>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
  <div *ngIf="!isUploading" [ngClass]="url !==''? 'waveform' : 'loadwave'">
    <app-loader *ngIf="loadingTracks" style="width: 100%;
    margin-top: 30px;
    position: absolute;
    margin-left: 50%;"></app-loader>
    <div class="preview-track flexContainer" *ngIf="url !==''">
      <div class="controls flex-item inner-container">
        <div class="button-controls">
          <button class="play-button" type="button" *ngIf="!isPlaying" (click)="play()">
            <svg width="13px" height="17px" viewBox="0 0 13 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="Style-Guide" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g class="inner-path" id="Components_Track" transform="translate(-426.000000, -388.000000)" stroke="#000000" stroke-width="0.95">
                  <g id="Inactive-Track" transform="translate(392.000000, 351.000000)">
                    <path id="Play" d="M45.5758457,45.9952239 C45.6280256,45.9604373 45.6728005,45.9156624 45.707587,45.8634826 C45.8531045,45.6452065 45.7941219,45.3502935 45.5758457,45.2047761 L35.4134826,38.4298673 C35.3354555,38.3778493 35.2437769,38.3500912 35.15,38.3500912 C34.8876647,38.3500912 34.675,38.562756 34.675,38.8250912 L34.675,52.3749088 C34.675,52.4686856 34.7027581,52.5603643 34.7547761,52.6383914 C34.9002935,52.8566675 35.1952065,52.9156501 35.4134826,52.7701327 L45.5758457,45.9952239 Z"></path>
                  </g>
                </g>
              </g>
            </svg>
          </button>
          <button class="pause-button" type="button" (click)="pause()" *ngIf="isPlaying">
            <svg width="10px" height="14px" viewBox="0 0 10 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="Style-Guide" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g class="inner-path" id="Components_Track" transform="translate(-426.000000, -746.000000)" fill="#15151C">
                  <g id="Track-Playing" transform="translate(393.000000, 704.000000)">
                    <path id="Pause" d="M33.2499996,42.9499995 L36.4166662,42.9499995 L36.4166662,55.616666 L33.2499996,55.616666 L33.2499996,42.9499995 Z M39.5833328,42.9499995 L42.7499995,42.9499995 L42.7499995,55.616666 L39.5833328,55.616666 L39.5833328,42.9499995 Z"></path>
                  </g>
                </g>
              </g>
            </svg>
          </button>
        </div>
        <div class="track-name">{{title}}</div>
        <div class="time-replace">{{ current | minuteSeconds }} <span>|</span> {{ duration | minuteSeconds }}</div>
      </div>
  
        <div class="repace-player cancel" *ngIf="url !==''">
        <input id="replace_track" type="file" [(ngModel)]="File" [ngModelOptions]="{standalone: true}" (change)=" reset(); selectFiles($event)" #fileInput accept="audio/*">
        <label for="replace_track">Change</label>
      </div>
    </div>
    <div style="width: 100%" (click)="getCurrentPreview()">
      <div id="wave"></div>
      <div id="wave-timeline"></div>
      <div class="controls" *ngIf="wavesurfer">
      </div>
  
    </div>
  </div>