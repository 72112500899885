import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  AfterViewInit,
  ViewChild,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { MatSort, MatSortable } from '@angular/material/sort';
import {
  trigger,
  style,
  animate,
  transition,
  state,
} from '@angular/animations';
import { Observable, Subscription, BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { Track } from '../models/track.model';
import { TrackVersion } from '../models/trackversion.model';
import { User } from '../models/user.model';
import { PlayerService, PlayingItem } from '../services/player.service';
import { DialogService } from '../services/dialog.service';
import { SliderDialogService } from '../services/slider-dialog.service';
import { TrackStatusValue } from '../models/trackstatus-value.model';
import { TrackStatusPipe } from '../pipes/trackstatus.pipe';
import { EditTrackComponent } from '../edit-track/edit-track.component';
import { EditTrackVersionComponent } from '../edit-track-version/edit-track-version.component';
import { PlaylistTracks } from '../models/playlisttracks.model';
import { ManageClientAccountComponent } from '../manage-client-account/manage-client-account.component';
import { UserPRO } from '../models/user-pro.model';
import { ManageArtistAccountComponent } from '../manage-artist-account/manage-artist-account.component';
import { UserUploadPopupComponent } from '../user-upload-popup/user-upload-popup.component';


declare type UserArtistListDataSource = Observable<ReadonlyArray<{user:User,userPRO:UserPRO}>>;

export class UserArtistListRow {
  constructor(
    public user: User,
    public userPRO: UserPRO
  ) {}
}

@Component({
  selector: 'app-user-artist-list',
  templateUrl: './user-artist-list.component.html',
  styleUrls: ['./user-artist-list.component.scss'],
  animations: [
    trigger('rowInOut', [
      transition(':enter', [
        style({
          opacity: '0',
          height: '0',
          paddingTop: '0',
          overflow: 'hidden',
          paddingBottom: '0',
        }),
        animate(
          '0.7s cubic-bezier(0.19, 1, 0.22, 1)',
          style({
            opacity: '1',
            height: '*',
          })
        ),
      ]),
      transition(':leave', [
        style({
          opacity: '1',
          height: '*',
          overflow: 'hidden',
        }),
        animate(
          '0.7s cubic-bezier(0.19, 1, 0.22, 1)',
          style({
            opacity: '0',
            height: '0',
            paddingTop: '0',
            paddingBottom: '0',
          })
        ),
      ]),
    ]),
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', overflow: 'hidden' })
      ),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
  providers: [TrackStatusPipe],
})
export class UserArtistListComponent implements OnInit, OnDestroy, AfterViewInit {

  selectedTrack = null;
  selectedVersion = null;

  isOpen = false;
  loadingOverlay = false;

  triggerOrigin: any;

  toggle(trigger: any, track: any) {
    this.selectedTrack = track;

    this.triggerOrigin = trigger;
    this.isOpen = !this.isOpen
  }

  @Input()
  set dataSource(data: UserArtistListDataSource) {
    this._dataSource = data
      .pipe(
        map((users) => {
          let retval: UserArtistListRow[] = [];
          users.forEach((u) => {
            let row = new UserArtistListRow(u.user, u.userPRO);
            retval.push(row);
            console.log(retval)
          });
          return retval;
        })
      );
  }

  @Input()
  set checkedArtists(clients: User[]) {
    this._checkedArtists = clients;
    this.checkedArtistsChanged.emit(this._checkedArtists)
  }
  get checkedArtists() {
    return this._checkedArtists;
  }

  @Output()
  checkedArtistsChanged = new EventEmitter<User[]>()

  @Output()
  playingTrack = new EventEmitter<PlaylistTracks>();

  @Output()
  collapseMusicPlayer = new EventEmitter<null>();

  @Output()
  sortChange: EventEmitter<MatSortable> = new EventEmitter<MatSortable>();

  @Output()
  listChange: EventEmitter<null> = new EventEmitter<null>();

  @Input()
  set sortValue(data: string) {
    const start = data && data[0] === '-' ? 'desc' : 'asc';
    const id = data && data[0] === '-' ? data.substring(1) : data || 'id';
    this._sortValue = { id, start } as MatSortable;
  }

  get listDataSource() {
    return this._dataSource;
  }

  private _checkedArtists: User[] = [];

  private _dataSource: Observable<ReadonlyArray<UserArtistListRow>>;
  private _subscriptions: Subscription[] = [];
  private _dataSourceSubscription: Subscription = null;
  private _sortValue: MatSortable = null;
  user1 :User

  displayedColumns: string[] = [
    'name',
    'nick_name',
    'email',
    'phone_number',
    'pro',
    'date_joined',
    'last_login',
    'is_disable',
    'can_upload',
    'tos',
    'action',
  ];

  @ViewChild(MatSort) sort: MatSort;
  private _sortSubscription: Subscription = null;

  constructor(
    private _userService: UserService,
    private _playerService: PlayerService,
    private _dialogService: DialogService,
    public _DialogService:DialogService,
    private _sliderDialogService: SliderDialogService,
    private _trackStatusPipe: TrackStatusPipe,private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this._sortSubscription = this.sort.sortChange.subscribe(() => {
      let data =''
      if(this.sort.active === "is_active"){
        data = "is_disable"
      }
      else if(this.sort.active === "tos"){
        data = "tos_status"
      }
      else{
        data = this.sort.active
      }
      this.sortChange.emit({
        id: data,
        start: this.sort.direction,
      } as MatSortable);
    });
    if (!this._sortValue) return;
    this.sort.sort(this._sortValue);
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => {
      s.unsubscribe();
    });
    if (this._dataSourceSubscription) {
      this._dataSourceSubscription.unsubscribe();
    }
    if (this._sortSubscription) {
      this._sortSubscription.unsubscribe();
    }
  }

  listTrackBy(_, item: UserArtistListRow) {
    return item.user.id;
  }

  userIsChecked(user:User) {
    return this.checkedArtists.some(u=>u.id == user.id);
  }

  userChecked(checked:boolean, user:User) {
    if (checked) {
      this._checkedArtists.push(user);
      this.checkedArtistsChanged.emit(this.checkedArtists)
      return;
    }
    let i = this._checkedArtists.findIndex(u=>u.id==user.id);
    if (i >= 0) {
      this._checkedArtists.splice(i,1);
      this.checkedArtistsChanged.emit(this.checkedArtists)
    }
  }

  deselectAll() {
    this._checkedArtists.length = 0;
    this.checkedArtistsChanged.emit(this.checkedArtists)
  }

  addToGroupClick(user:User) {
    var from_page = "user_component";
    this._dialogService.displayAddToEmailGroupDialog([user], from_page).subscribe(changed=>{

    });
  }

  activityClick(user:User) {
    this._dialogService.displayArtistActivityDialog(user).subscribe(changed=> {
      
    })
  }

  editClick(user:User, userPRO:UserPRO) {
    user.password = user.password ? user.password : '12345678';
    this._sliderDialogService.open(ManageArtistAccountComponent, {
      width: "600px",
      title: "EDIT ARTIST ACCOUNT",
      panelClass: "edit-track-dialog",
      data: {user:user, userPRO:userPRO,isEdit:true}
    }, (val)=> {
      if (val != null) {
        this.listChange.emit();
      }
      this.listChange.emit();
    });
  }

  deleteClick(user:User) {
    this._dialogService.displayDeleteClientDialog(user, true).subscribe(changed=> {
      if (changed) this.listChange.emit();
    })
  }
  changeCanUpload(user:User) {
    this.loadingOverlay = true;
    let data =[]
    
   // user['allowed_cowriters'] = []
   // user['visible_genres'] = []
   if( user['allowed_cowriters'].length > 0){
    let newValues =[];
    for(let i=0;i< user['allowed_cowriters'].length;i++){
      if( user['allowed_cowriters'][i].id !==2 &&  user['allowed_cowriters'][i].id !== 1){
        newValues.push( user['allowed_cowriters'][i])
      }
    }
    user['allowed_cowriters'] = newValues
  } 
  //  if (user['visible_genres'] .length > 0) {
  //   for(let index=0;index< user['visible_genres'] .length; index++){
  //     if(user['visible_genres'][index]?.id){
  //       data.push(user['visible_genres'][index].id)
  //     }
  //     else{
  //       data.push(user['visible_genres'][index])
  //     }
  //   }
  //   user['visible_genres']  = data;
  // }
  //  if(user.pro){
  //   user['pro'] = user.pro['id'];
  //  }
    this._userService.updateUser(user).subscribe(u=>{
     this.updateUserData(u)
      
      this.loadingOverlay = false;
     // location.reload()
    }, (error) => {
      console.log(error);
      this.loadingOverlay = false;
      if(error.error.detail){
      // alert("An error ocurred while updating the \"Can Upload\" status. Check your connections and try again");
      // this._dialogService.UserUploadPopupConfirmPopup(UserUploadPopupComponent);
      let data = error.error?.error ? error.error?.error : (error.error?.detail ?error.error?.detail : (error.error?.details ? error.error?.details:``) )
      this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
      });
      }
      else{
        // alert("An error ocurred while updating the \"Can Upload\" status. Check your connections and try again");
        let data = error.error?.error ? error.error?.error : (error.error?.detail ?error.error?.detail : (error.error?.details ? error.error?.details:``) )
        this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
        });
      }
      user.can_upload = !user.can_upload;
    });
  }
  updateUserData(user:User){
    this.user1 = user;
    let newData =[]
    for(let index=0;index< user['visible_genres'].length; index++){
      newData.push( user['visible_genres'][index])
    }
    user['visible_genres'] = newData
    let value = this.listDataSource.source.source['_value']
    for(let i=0;i<value.length;i++){
      if(value[i]['user'].id === this.user1.id){
        value[i]['user'] = this.user1
      }
    }
    this.listDataSource.source.source['_value'] = value
  }
}
