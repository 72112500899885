import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { Track } from '../models/track.model';
import { Subscription, combineLatest } from 'rxjs';
import { TrackService, GetTracksOptions, Filters } from '../services/track.service';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../environments/environment';
import { MenuConfigService } from '../services/menu-config.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { DialogService } from '../services/dialog.service';
import { DownloadService } from '../services/download.service';

interface Genre {
  value: string;
  viewValue: string;
}

interface QueryType {
  value: string;
  viewValue: string;
}

interface DialogData {
  filters: Filters,
  trackCount: number
}

@Component({
  selector: 'app-download-results-dialog',
  templateUrl: './download-results-dialog.component.html',
  styleUrls: ['./download-results-dialog.component.scss']
})
export class DownloadResultsDialogComponent implements OnInit {

  loading = false;

  trackCount: number = 0;
  filters: Filters

  mediaQueryTypes: { name: string, slug: string }[] = [
    { name: 'MTV', slug: 'mtv' },
    { name: 'Sound Mouse', slug: 'sound_mouse' },
    { name: 'Sound MIBE Miner', slug: 'sound_mibe_miner' },
    { name: 'Source Audio', slug: 'source_audio' },
    { name: 'Turner', slug: 'turner' },
    { name: 'Sound Miner', slug: 'sound_miner' },
    { name: 'NetMix', slug: 'netmix' },
    { name: 'BMAT', slug: 'bmat' },
    { name: 'NBC', slug: 'nbc' },
    { name: 'Music Mark', slug: 'music_mark' },
    { name: 'Prometheus', slug: 'prometheus' },
    { name: 'NFL Synchtank', slug: 'nfl_synchtank' }

  ]

  selectedMediaQuery = '';
  selectMediaQuery = '';

  private _subscriptions: Subscription[] = [];

  constructor(
    private _platform: Platform,
    public dialogRef: MatDialogRef<DownloadResultsDialogComponent>,
    private _trackService: TrackService,
    private config: MenuConfigService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public _DialogService: DialogService,
    public http: HttpClient,
    private downloadService: DownloadService
  ) {
    this.filters = data.filters;
    this.trackCount = data.trackCount;
  }
  currentUser: any;
  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('userdata'))
    console.log(this.currentUser)
  }

  close() {
    this.dialogRef.close();
  }

  getCurrentTrackFilters(): GetTracksOptions {
    let options: GetTracksOptions = {
      limit: 0
    }
    options.filters = this.filters;
    return options;
  }



  downloadTracks() {
    console.log(this._trackService.currentFilter)
    if (this.trackCount > 0) {
      this.loading = true;
      let downloadUrl = environment.apiURL + this.config.downloadTracks;
      // window.open(requestURL)
      if(this._trackService.currentFilter.querytype){
        delete this._trackService.currentFilter['querytype']
      }
      let headers = new HttpHeaders();
     this.http.get(`${downloadUrl}`,{
      responseType: 'arraybuffer',
      headers:headers,
      params: this._trackService.currentFilter
    } 
     ).subscribe(user => 
      {
        //this.downLoadFile(response, "text/csv", 'export')
        let data = user['message'] ? user['message'] : (user['details'] ? user['details'] :'The report that you requested for has been sent to your email id')
        this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed=>{
          this.dialogRef.close()

        });
    });
    }
  }


  /**
   * Direct download, no email link sent (triggers download but does not lock up UI)
   */
  downloadTracksDirect(): void {
    if (this.trackCount > 0) {
      // Display a message to the user
      // You can show this message in a dialog or any other UI component
      // For example:
      this._DialogService.UserUploadPopup_NoConfirm("We are retrieving those tracks. Your download will begin once all tracks are ready.");
  
      //triggers the loader on the top right to display
      this.downloadService.startDownload();

      const downloadUrl = environment.apiURL + this.config.downloadTracksDirect;
      if (this._trackService.currentFilter.querytype) {
        delete this._trackService.currentFilter['querytype'];
      }
      let headers = new HttpHeaders();
      // Set response type to 'blob' to handle binary data
      this.http.get(`${downloadUrl}`, {
        responseType: 'blob',
        headers: headers,
        params: this._trackService.currentFilter
      }).subscribe((response: Blob) => {
        // Once the API response is received:
        // Create a Blob from the response
        const blob = new Blob([response], { type: 'application/zip' });
  
        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create a formatted timestamp for the filename
        const timestamp = this.getFormattedTimestamp();
        const filename = `Mibe Tracks_${timestamp}.zip`;
  
        // Create a link element to initiate the download
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
  
        // Cleanup: Remove the link after download is complete
        document.body.removeChild(link);
  
        // Close any dialogs or loading indicators
        this._DialogService.UserUploadPopupConfirmPopup("Download completed");

        //triggers spinner on top right to stop (unless other downloads are in progress)
        this.downloadService.endDownload();
      });
    }
  }

  private getFormattedTimestamp(): string {
    const date = new Date();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert hour to 12-hour format
    const formattedMinutes = this.padNumber(minutes);
    const formattedSeconds = this.padNumber(seconds);
    const formattedDate = `${date.getFullYear()}-${this.padNumber(date.getMonth() + 1)}-${this.padNumber(date.getDate())}`;
    return `${formattedDate} ${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
  }
  
  private padNumber(number: number): string {
    return number < 10 ? '0' + number : '' + number;
  }

  downloadMediaQuery() {
    let mediaQuery = this.mediaQueryTypes.find(i => i.slug.toLowerCase() == this.selectedMediaQuery);
    this.selectMediaQuery = mediaQuery.name;
    if (this.trackCount > 0 && this.selectedMediaQuery != '') {
      const downloadUrl = this._trackService.getMediaQueryDownloadLink(this.selectedMediaQuery, this._trackService.currentFilter);
      // window.location.href = downloadUrl;
      let headers = new HttpHeaders();
      this.http.get(`${downloadUrl}`, {
        responseType: 'arraybuffer',
        headers: headers
      }
      ).subscribe((user) => {
        //this.downLoadFile(response, "text/csv", this.selectMediaQuery)
        let data = user['message'] ? user['message'] : (user['details'] ? user['details'] : 'The report that you requested for has been sent to your email id')
        this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
          this.dialogRef.close()

        });
      });
    }

  }
  downloadAllReports() {


    const downloadUrl = this._trackService.getMediaQueryDownloadLink(this.selectedMediaQuery, this.getCurrentTrackFilters());

    let headers = new HttpHeaders();
    this.http.get(`${downloadUrl}`, {
      responseType: 'arraybuffer', headers: headers
    }
    ).subscribe((user) => {
      //this.downLoadFile(response, "text/csv", this.selectMediaQuery)
      let data = user['message'] ? user['message'] : (user['details'] ? user['details'] : 'The report that you requested for has been sent to your email id')
      this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
        this.dialogRef.close()

      });
    });
  }
  downLoadFile(data: any, type: string, filename: any) {
    // let fileName = `${filename}.xlsx`;
    let fileName = `Tracks.xlsx`;
    let blob = new Blob([data], { type: 'text/csv' });
    let url = window.URL.createObjectURL(blob);
    saveAs(url, fileName);
    this.loading = false;
    // let pwa = window.open(url);
    // if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
    //     //alert( 'Please disable your Pop-up blocker and try again.');
    // }
  }

}
