<div class="home" *ngIf="term">
    <div class="top-filters">
        <form
            class="search-filter"
            [ngClass]="{'search-filter-focused': searchFilterFocused}"
            #searchForm="ngForm"
            (ngSubmit)="$event.preventDefault();submitSearch()"
        >
            <label for="search-filter-field" class="icon" (click)="submitSearch()">
                <svg *ngIf="!searchFilterFocused" xmlns="http://www.w3.org/2000/svg" width="14" height="14"><path fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="20" d="M6 1a5 5 0 110 10A5 5 0 016 1zM13.002 13.002L9.535 9.535"/></svg>
                <svg *ngIf="searchFilterFocused" xmlns="http://www.w3.org/2000/svg" width="14" height="14"><path fill="none" stroke="#15151c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="20" d="M6 1a5 5 0 110 10A5 5 0 016 1zM13.002 13.002L9.535 9.535"/></svg>
            </label>
            <input
                [(ngModel)]="searchString"
                name="searchString"
                type="text"
                class="txt"
                id="search-filter-field"
                placeholder="{{searchFilterFocused ? 'Enter keyword(s) seperated by commas to begin' : 'Search'}}"
                (focus)="searchFilterFocused = true"
            >
            <input
                type="reset"
                value="Cancel"
                class="btn-reset"
                (click)="searchString = null;submitSearch();$event.stopPropagation()"
          style="padding-right:10%;"  >
            <input type="submit" value="Search" class="btn-submit">
        </form>
        <!-- <app-range-datepicker
            [(startDate)]="startDate"
            [(endDate)]="endDate"
        ></app-range-datepicker> -->
        <div class="date">
            <app-range-datepicker
                [enableSelect]="true"
                [rangeFilterList]="dateChoices"
                [selectedRangeFilter]="selectedDateType"
                [startDate]="startDate"
                [endDate]="endDate"
                (startDateChange)="changedate($event)"
                (endDateChange)="changedateend($event)"
                (dateTypeChange)="dateTypeChange($event)"
                (selectedRangeFilterChange)="dateType($event)"
               
            ></app-range-datepicker>
        </div>
        <div class="download-buttons">
            <button
                type="button"
                class="download-button"
                (click)="displayExportOptionsDialog()"
            >
                EXPORT OPTIONS
            </button>
            <button
                type="button"
                class="download-button"
                (click)="displayDownloadResultsDialog()"
            >
                DOWNLOAD RESULTS
            </button>
        </div>
    </div>
    <div class="main">
        <div class="filter-container">
        <app-filters
            [totalTracks]="totalTracks"
            [(filters)]="filters"
            (clearClick)="clear()"
            (filtersChange)=filterChange($event)
        ></app-filters>
        </div>

        <div class="track-list" [ngClass]="{'music-player-visible': playingTrack && playingTrackVersion, 'music-player-collapsed': musicPlayerCollapsed}">
            <app-loader *ngIf="loadingTracks"></app-loader>
            <app-track-new-list
                class="tracks"
                *ngIf="!loadingTracks && totalTracks > 0"
                [dataSource]="tracks"
                [sortValue]="ordering"
                (collapseMusicPlayer)="collapseMusicPlayer()"
                (sortChange)="sortChange($event)"
                (filtersChange)="filternew()"
                (listChange)="loadTracks()"
            ></app-track-new-list>
            <div *ngIf="!totalTracks && !loadingTracks" class="no-data">No data available</div>
            <app-pagination
                *ngIf="tracksNumberOfPages > 1 && !loadingTracks"
                [page]="currentTracksPage"
                (filtersChange)="pageTrigger($event)"
                [numberOfPages]="tracksNumberOfPages"
            ></app-pagination>

            <div class="music-player-wrapper"
            [ngClass]="{hide: !playingTrack || !playingTrackVersion, collapsed: musicPlayerCollapsed}">
                <button type="button"
                class="music-player-expand-button"
                (click)="toggleMusicPlayerCollapsed()">
                    <svg width="18px" height="9px" viewBox="0 0 18 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="player_close" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="closed-player" transform="translate(-730.000000, -169.000000)" class="inner-stroke" stroke="#50E3C2">
                                <polyline id="Path-2" points="730.682129 177.754395 738.843146 169.593378 747.004163 177.754395"></polyline>
                            </g>
                        </g>
                    </svg>
                </button>
                <app-new-musicplayer [track]="playingTrack"
                [version]="playingTrackVersion"
                [ngClass]="{hide: !playingTrack || !playingTrackVersion, collapsed: musicPlayerCollapsed}"
                (collapse)="toggleMusicPlayerCollapsed()"
            class="music-player"></app-new-musicplayer>
            </div>
        </div>
    </div>
</div>

