<div class="dialog-container">
    <div class="dialog-header">
      <h4 class="dialog-heading">{{!!data.isDuplicate ? 'Duplicate': 'New'}} Group</h4>
      <button type="button" class="close-button" (click)="close()">
        <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
            stroke-linejoin="round">
            <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
              <g id="Genre-Options">
                <g id="Close" transform="translate(1439.000000, 32.000000)">
                  <path d="M24,0 L0,24" id="Path"></path>
                  <path d="M0,0 L24,24" id="Path"></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </button>
    </div>

    <mat-dialog-content>
      <app-loader *ngIf="loading" color="#50e3c2"></app-loader>
      <div class="dialog-body edit-track" *ngIf="!loading">
        <div class="form-row green-theme">
            <mat-radio-group [(ngModel)]="_emailGroupService.checkedtype">
                <mat-radio-button name="type" [checked]="_emailGroupService.checkedtype=='CLIENT'" class="checkbox-margin" [value]="'CLIENT'">Client</mat-radio-button>
                <mat-radio-button name="type" [checked]="_emailGroupService.checkedtype=='ARTIST'" class="checkbox-margin" [value]="'ARTIST'">Artist</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="form-container">
            <mat-form-field class="dialog">
                <mat-label>Group Name</mat-label>
                <input [(ngModel)]="name" matInput>
            </mat-form-field>
        </div>
        <p class="note">Please make title as short and descriptive as possible</p>
        <p class="error" *ngIf="error">{{error}}</p>
        <div class="form-col">
          <button *ngIf="!data.isDuplicate" class="form-button" (click)="create()" [disabled]="!name || ( name && name?.trim() == '')">{{'Create'}}</button>
          <button class="form-button" *ngIf="data.isDuplicate" (click)="dupliacte()" [disabled]="!name || ( name && name?.trim() == '')">{{'Duplicate'}}</button>

          <button class="form-button form-button-cancel" (click)="close()">Cancel</button>
        </div>
      </div>
    </mat-dialog-content>
  </div>
