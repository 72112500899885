import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MenuListService } from '../../shared/services/menu-list.service';
import { environment } from '../../../environments/environment';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
  selector: 'app-duplicate-new-group-dialogue',
  templateUrl: './duplicate-new-group-dialogue.component.html',
  styleUrls: ['./duplicate-new-group-dialogue.component.scss']
})
export class DuplicateNewGroupDialogueComponent implements OnInit {
  loader: boolean = false;
  userArray:any[] = [];
  permissionArray:any[] = [];
  userGroupForm: FormGroup;
  constructor(public FormUserGroup: FormBuilder, private dialogRef: MatDialogRef<DuplicateNewGroupDialogueComponent>,public list: MenuListService, public api: UserMenuService, public config: MenuConfigService,public dialog: DialogService,) {
    this.list.getAllUser();
    this.list.getAllPermission();
    this.list.getAvailableUserDetails();
    this.list.getAvailableUserPermission();
    this.list.getAdminUserGroups().subscribe(x=>{
      if(x){
        this.list.isUserGroupsDuplicate = true;
        this.list.singleUserForGroup(x)
        this.list.getSingleUserGroups(x)
      }
     
     })
  }
  ngOnInit(): void {
    
    this.userGroupForm = this.FormUserGroup.group({
      group_name: new FormControl('',[Validators.required])
    })
  }
  close() {
    this.dialogRef.close();
  }

  duplicateUserGroup(){
    this.userArray = [];
    this.permissionArray = [];
    this.list.singleuserGroupArray.forEach(element => {
      this.userArray.push(element.id)
    });
    this.list.singleUserArray.forEach(element => {
      this.permissionArray.push(element.id)
    });
    let postUserBody = {
      "name": this.userGroupForm.value.group_name,
      "permissions": this.permissionArray,
      // "users_ids": this.userArray
      "users_ids": []
    }
    this.loader = true;
    let url = environment.apiURL + this.config.createUserGroup;
    this.api.doPOST(url,postUserBody).subscribe(x=>{
      this.loader = false;      
      this.dialogRef.close();
      this.list.getAllGroups(1);
    },
    (error) => {
      this.loader = false;
      let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
      this.dialog.UserUploadPopupConfirmPopup(data).subscribe(changed => {
      });
      this.dialogRef.close();
      this.list.getAllGroups(1);
      //alert(error.message)
    })
  }
}
