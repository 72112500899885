import { MibeModel } from './mibemodel.model';
import { Playlist } from './playlist.model';

export class PlaylistFolder implements MibeModel {
  id: number;
  name: string;
  playlists: Playlist[];
  created_at=new Date();

  private _original_data: any;

  /**
   * Create a Playlist.
   * @param {any} json - The JSON object with which to construct our Playlist.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a Playlist.
   * @param {any} json - The JSON object with which to load our Playlist.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this._original_data = json;
    this.id = json.id;
    this.name = json.name;
    if (json.playlists && json.playlists.length > 0) {
      this.playlists = json.playlists.map(p=>new Playlist(p)).sort((a:Playlist, b:Playlist) => {
        if (a.created_at < b.created_at) {
          return 1;
        } else if (a.created_at > b.created_at) {
          return -1;
        }
        return 0
      });
    } else {
      this.playlists = [];
    }
    this.created_at = json.created_at ? new Date(json.created_at):null;
  }
  /**
   * Returns a JSON representation of our Playlist
   * @return {any} - The JSON representation of our Playlist
   */
  toJSON() {
    var json = this._original_data;
    json['id'] = this.id;
    json['name'] = this.name;
    json['playlists'] = this.playlists.map(p=>p.toJSON());
    json['created_at'] = this.created_at ? this.created_at.toISOString():null;
    return json;
  }

  getDisplayName(): string {
    return `${this.formatDate(this.created_at)} - ${this.name}`;
  }

  formatDate(d:Date): string {
    return `${d.getMonth()+1}/${d.getDate()}/${d.getFullYear().toString().substr(-2)}`;
  }

}
