import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {FormGroup, FormControl} from '@angular/forms';
import { MenuListService } from '../../shared/services/menu-list.service';
import { environment } from '../../../environments/environment';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { DatePipe } from '@angular/common'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-reports-dialogue',
  templateUrl: './reports-dialogue.component.html',
  styleUrls: ['./reports-dialogue.component.scss']
})
export class ReportsDialogueComponent implements OnInit {
  maxDate = new Date();
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  loading: boolean = false;
  startDate: Date;
  endDate: Date;
  start_date: any =''
  end_date: any='';
  clearShow: boolean = false;
  constructor(private dialogRef: MatDialogRef<ReportsDialogueComponent>, public list: MenuListService, public config: MenuConfigService, public api: UserMenuService, public datepipe: DatePipe, public http: HttpClient ) { }

  ngOnInit(): void {
  }
  close() {
    this.dialogRef.close();
  }
  downloadAllReports(){
    this.loading = false;
    let postUserBody = {
      'from': this.start_date,
      'to': this.end_date
    }
    let requestURL = environment.apiURL + this.config.downloadReports;
    requestURL = requestURL.replace('{start}', this.start_date)
    requestURL = requestURL.replace('{end}', this.end_date)
    //window.open(requestURL)
    /* this.api.doGET(requestURL).subscribe((result: any) => {
    this.loading = false;
      this.dialogRef.close();
    },
    (error) => {
        this.loading = false;
       //alert(error.message)
    }); */
    let headers = new HttpHeaders();
    this.http.get(`${requestURL}`,{
      responseType: 'arraybuffer',headers:headers} 
     ).subscribe(response => this.downLoadFile(response, ""));
     this.dialogRef.close();
  }
  downLoadFile(data: any, type: string) {

    let filename = 'uploads_report.xlsx';
    let blob = new Blob([data], { type: type});
    let url = window.URL.createObjectURL(blob);
    saveAs(url, filename);
    // let blob = new Blob([data], { type: 'text/csv'});
    // let url = window.URL.createObjectURL(blob);
    // let pwa = window.open(url);
    // if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
    //     //alert( 'Please disable your Pop-up blocker and try again.');
    // }
}
startChanged(e){  
  this.start_date =this.datepipe.transform(e, 'yyyy-MM-dd');
}
endChanged(e){
  this.end_date =this.datepipe.transform(e, 'yyyy-MM-dd');
  this.clearShow = true;
}
clearDates(){
  this.startDate = null;
  this.endDate = null;
  this.start_date = null;
  this.end_date = null;
  this.range.reset({ start: '', end: '' });
  this.clearShow = false;
}
}
