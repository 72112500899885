import { Component, Inject, Input, OnInit } from '@angular/core';
import { User } from '../models/user.model';
import { CONTAINER_DATA, DialogConfig, SliderDialogService } from '../services/slider-dialog.service';
import { UserService } from '../services/user.service';
import { PhoneNumber, PhoneNumberTypes } from '../models/phonenumber.model';
import { FormControl } from '@angular/forms';
import { DialogService } from '../services/dialog.service';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserMenuService } from '../services/user-menu.service';
import { of, ReplaySubject, Subscription } from 'rxjs';
import { ComposerPROService } from '../services/composer-pro.service';
import { startWith, debounceTime, distinctUntilChanged, tap, switchMap } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';


@Component({
  selector: "app-manage-client-account",
  templateUrl: "./manage-client-account.component.html",
  styleUrls: ["./manage-client-account.component.scss"],
})
export class ManageClientAccountComponent implements OnInit {
  loading: boolean = false;
  username: any;
  user: User;
  password: string = "";
  isNewUser: boolean = false;
  typeClient :string =''
  errorMessage: string;
  today = new Date();
  public customPatterns = { "0": { pattern: new RegExp("[a-zA-Z]") } };
  public numberPattern = { "0": { pattern: new RegExp("^[0-9]") } };

  primaryPhoneControl: FormControl = new FormControl();
  phoneControllers: FormControl[] = [];

  get phoneNumberTypes(): string[] {
    return Object.keys(PhoneNumberTypes).map((key) => PhoneNumberTypes[key]);
  }

  phoneNumber: any = '';
  currentValue: any;
  isPassChange = false;
  isEmailExist: number = 0;
  oldUser:string =''
  isUserExist: number = 0;
  clientShows: any = [];
  searchingShow =false
  SelectsShow = "Select";
  showFilterCtrl = new FormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredShowOptions: ReplaySubject<any[]> = new ReplaySubject<any[]>();
  private _subscriptions: Subscription[] = [];

  constructor(
    private _sliderDialogService: SliderDialogService,
    private _userService: UserService,
    public _DialogService:DialogService, public api: UserMenuService,
    private _showService: ComposerPROService,

    @Inject(CONTAINER_DATA) public componentData: DialogConfig
  ) {}

  ngOnInit(): void {

    
    if (this.componentData.data) {
      this.user = new User(this.componentData.data.user.toJSON());
      if (this.componentData.data.isNewUser) {
        this.isNewUser = this.componentData.data.isNewUser;
        this.user.shows = [];
        this.typeClient = 'create'
        this.getShowList('create')
      }
      else{
        this.typeClient = 'edit'
        this.getShowList('edit')
      }
      //Set up a form control for each additional number
      this.user.additional_numbers.forEach(() => {
        this.phoneControllers.push(new FormControl());
      });
 
    }

    else{
      this.user.birth_date ='';
    
    }
    if(this.user.is_disable === true){
      this.user.can_download = false;
    }
    this.user.can_download = this.user.can_download==undefined ? true : this.user.can_download
    console.log(this.user);
  
    // this._subscriptions.push(
    //   this.showFilterCtrl.valueChanges
    //     .pipe(
    //       startWith(""),
    //       debounceTime(150),
    //       distinctUntilChanged(),
    //       tap(() => {
    //         this.searchingShow = true;
    //       }),
    //       switchMap((v) => {
    //         if (!v) {
    //           return of(this.clientShows);
    //         }
    //         return of(
    //           this.clientShows.filter((g) =>
    //             g.name.toLowerCase().includes(v.toLowerCase())
    //           )
    //         );
    //       }),
    //       tap(() => {
    //         this.searchingShow = false;
    //       })
    //     )
    //     .subscribe(
    //       (g) => {
    //         this.filteredShowOptions.next(g);
    //       },
    //       (error) => {
    //         console.log(error);
    //       }
    //     )
    // );
  }

  passwordInputType1 = 'password';
  togglePasswordInputType(type: any = "") {
    if (this.passwordInputType1 == 'password') {
      this.passwordInputType1 = 'text';
    } else {
      this.passwordInputType1 = 'password';
    }
  }

  showCompareWith(g1: any, g2: any) {
    if (!g1 || !g2) {
      return g1 == g2;
    }
    return g1.id == g2.id;
  }

  selectShow(event) {
    const index = this.user.shows.findIndex(i => i.id == event.option.value.id);
    if (index < 0) {
      this.user.shows.push(event.option.value);
    }
  }
  removeShow(event) {
    const index = this.user.shows.findIndex(i => i.id == event.option.value.id);
    if (index >= 0) {
      this.user.shows.splice(index, 1);
    }
  }

  selectNewShow(event){
    this.user.shows = event.value
  }
  getShowList(url:any, value:any=''){
    let id:any='' , search =''
    if(url == 'edit'){
      id = this.user.id;
    }
    if(value.trim() !==''){
      search = value
    }
    this._showService.getShows(url, id, search).subscribe(
      (data) => {
        this.clientShows= data
        this.filteredShowOptions.next(this.clientShows);
      },
      (error) => {
        console.log(error);
        //alert("An error ocurred while getting Composer PROs. Check your connections and try again");
      }
    );
  }

  close() {
    this._sliderDialogService.close(null);
  }

  removeNumber(index: number) {
    this.user.additional_numbers.splice(index, 1);
    this.phoneControllers.splice(index, 1);
  }

  passwordChange() {
    this.isPassChange = true;
  }

  addNumber() {
    this.phoneControllers.push(new FormControl());
    this.user.additional_numbers.push(
      new PhoneNumber({ number_type: "mobile" })
    );
  }

  shouldShowStateInput(): boolean {
    if (!this.user || !this.user.country) return false;
    return this.user.country.toLowerCase() != "us";
  }

  validateNumber(event, i?:number) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if ((event.key === ' ' || isNaN(Number(event.key)))&&!((
      (keyCode >= 96 && keyCode <= 105) ||
      (excludedKeys.includes(keyCode))))) {
      event.preventDefault();
    }
  }
  getPhoneCode(e, i?:number){
    let l = e.value.length;
    this.currentValue = e.id;
    //this.phoneNumber = '';
    if(l==1){
      if(e.value !== "+"){
        this.phoneNumber = '+1' + e.value;
        if(i!==undefined){
        this.user.additional_numbers[i].phone_number = this.phoneNumber
        } else {
          this.user.phone_number = this.phoneNumber;
        }
      }
    }else{
      this.phoneNumber = e.value;
    }
  }
  canSave(): boolean {
    if (this.user) {
      if (!this.user.email || !this.user.first_name || !this.user.last_name || !this.user.ms_username){
        return false;
      }
      if(this.user.first_name){
        if(this.user.first_name.trim() === ''){
          return false;
        }
      }
      if(this.user.last_name){
        if(this.user.last_name.trim() === ''){
          return false;
        }
      }
      if(this.user.ms_username){
        if(this.user.ms_username.trim() === ''){
          return false;
        }
      }
      // if (
      //   !this.user.address1 ||
      //   !this.user.state ||
      //   !this.user.city ||
      //   !this.user.postal_code ||
      //   !this.user.country
      // )
      //   return false;
      // if (this.user.postal_code.length <= 4) {
      //   return false
      // }
      // if (!this.user.phone_number) return false;
      // if(!this.user.birth_date){
      //   return false
      // }
      if (this.isNewUser || (!this.isNewUser && this.isPassChange)) {
        // if (this.password) {
        if (this.user.raw_password) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  save() {
    console.log(this.isPassChange, "PC")
    console.log(this.isNewUser, "NU")
    this.errorMessage = null;
    if (!this.user) {
      this.errorMessage =
        "Issue with user please close the panel, check you internet connection and try again";
      return;
    }
    if(this.user){
      this.checkUsername(this.user.ms_username, 'save')
    }
    if(this.isUserExist === 0){
      this.user['password'] = this.user['raw_password']
      if (this.user['discount_rate'].length == 3) {
        this.user['discount_rate'] = (this.user['discount_rate'].toString()).slice(0, -1)
      }
    if (!this.isNewUser && this.isPassChange===false) {
      this.user['password'] = this.user['raw_password']
      console.log("IN")
      // this.user.password = '';
      delete this.user['password'];
    }
    if (this.user.pro) {
      delete this.user['pro'];
    }
    if(!this.isNewUser && this.isPassChange && (!this.user.password || this.user.password == "")){
      this.errorMessage = "Password is required";
        return;
    }
    if (this.isNewUser) {
      if (!this.user.password || this.user.password == "") {
        this.errorMessage = "Password is required";
        return;
      }
      this.loading = true;
      this._userService.registerUser(this.user, this.user.password, true).subscribe(
        (user) => {
          this.componentData.data.user = user;
          this.loading = false;
          this._sliderDialogService.close(user);
          location.reload();
        },
        (error) => {
          console.log(error);
          //this.isEmailExist = 1;
          if(error.error.email){
            this.isEmailExist = 1
          }
          else{
            // alert(error.error.detail);
            let data = error.error?.error ? error.error?.error : (error.error?.detail ?error.error?.detail : (error.error?.details ? error.error?.details:``) )
            this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
            });
          }
         
          //alert("An error ocurred while registering the user. Check your input for errors, and then check connection and try again");
          this.loading = false;
        }
      );
      return;
    }
    this.loading = true;
    this._userService.updateUser(this.user).subscribe(
      (user) => {
        this.componentData.data.user = user;
        this.loading = false;
        this._sliderDialogService.close(user);
        location.reload();
      },
      (error) => {
        console.log(error);
        //this.isEmailExist = 1;
        if(error.error.email){
          this.isEmailExist = 1
        }
        else{
          // alert(error.error.detail);
          let data = error.error?.error ? error.error?.error : (error.error?.detail ?error.error?.detail : (error.error?.details ? error.error?.details:``) )
          this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
          });
        }
        //alert("An error ocurred while updating the user. Check your input for errors, and then check connection and try again");
        this.loading = false;
      }
    );
    }
  }

  birthdayChange(event) {
    this.user.birth_date = event.value;
  }

  checkNumber(e) {
    //Check Charater
    const reg = /^\d*\.?\d{0,1}$/g;
    if (!reg.test(e.target.value)) {
      e.preventDefault();
    }
    var unicode = e.charCode ? e.charCode : e.keyCode;
    if (e.target.value.indexOf(".") != -1) if (unicode == 46) return false;
    if (unicode != 8)
      if ((unicode < 48 || unicode > 57) && unicode != 46) return false;
  }
  checkLength() {
    var fieldVal: any = (<HTMLInputElement>document.getElementById("discount"))
      .value;
    //Suppose u want 3 number of character
    if (parseInt(fieldVal) <= 100) {
      return true;
    } else {
      var str = (<HTMLInputElement>document.getElementById("discount")).value;
      str = str.substring(0, str.length - 1);
      (<HTMLInputElement>document.getElementById("discount")).value = str;
    }
  }
  checkUsername(name, type: any = '') {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    let email, data: any;
    email = name;
    if (email && this.oldUser !== email) {
      if (this.user && this.user.id) {
        data = {
          "user_id": (this.user && this.user?.id.toString()) ? this.user?.id.toString() : '',
          "ms_username": email
        }
      }
      else {
        data = {
          "user_id": '',
          "ms_username": email
        }
      }
      let requestUrl = environment.apiURL + '/api/v1/admin/users/check-username/'
      this.api.doGET(requestUrl, { params: (data) }).subscribe(x => {
        this.isUserExist = 0;
      }, (error) => {
        if (type !== '') {
          let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
          this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
          });
        }
        else {
          if (error.error.error) {
            this.isUserExist = 1;
          }
        }
      });
    } else {
      this.isUserExist = 0;
    }
  }
}
