import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../environments/environment';
import { Genre } from '../models/genre.model';
import { APIListResponse } from '../models/apiresponse.model';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class GenreService {

  currentlyBrowsingGenre: Observable<Genre>;
  private _currentlyBrowsingGenreSubject: BehaviorSubject<Genre> = new BehaviorSubject<Genre>(null);

  constructor(
    private http: HttpClient,
    private _platform: Platform,
    private _transferState: TransferState
  ) {
    this.currentlyBrowsingGenre = this._currentlyBrowsingGenreSubject.asObservable();
  }

  updateCurrentlyBrowsingGenre(g: Genre) {
    this._currentlyBrowsingGenreSubject.next(g);
  }

  getFeaturedGenres(): Observable<Genre[]> {
    return this.getGenres({
      'is_featured': 'true'
    });
  }

  getAllGenres(): Observable<Genre[]> {
    return this.getGenres({'limit': '250'});
  }

  getParentGenres(): Observable<Genre[]> {
    return this.getGenres({'parent__isnull': 'true', 'limit': '250'});
  }

  getSubGenres(parentGenreId: number): Observable<Genre[]> {
    return this.getSubGenresList({'parent': `${parentGenreId}`, 'limit': '250'});
  }

  getSimilarGenres(similarToGenreId: number, limit: number = 250): Observable<Genre[]> {
    return this.getGenres({'similar_to': `${similarToGenreId}`, 'limit': `${limit}`});
  }

  getGenre(genreId: number): Observable<Genre> {
    // Check if genre are cached from server
    const GENRE_KEY = makeStateKey<Object>('genre-' + genreId);
    if (this._transferState.hasKey(GENRE_KEY)) {
      const genre = this._transferState.get<Object>(GENRE_KEY, null);
      this._transferState.remove(GENRE_KEY);
      return of(new Genre(genre));
    }
    let headers = new HttpHeaders();
    return this.http.get<Object>(
      environment.apiURL + '/api/v1/genres/' + genreId + '/',
      {
        headers: headers
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the genre
        if (!this._platform.isBrowser) {
          this._transferState.set(GENRE_KEY, res);
        }
      }),
      map((res) => new Genre(res))
    );
  }
  private getSubGenresList(params: {[param: string]: string | string[]}): Observable<Genre[]> {
    let p = new HttpParams({ fromObject: params });
    // Check if genres are cached from server
   
    let per = params.parent
    console.log(per)
    const GENRES_KEY = makeStateKey<APIListResponse<Object>>('genres-' + p.toString());
    if (this._transferState.hasKey(GENRES_KEY)) {
      const genres = this._transferState.get<APIListResponse<Object>>(GENRES_KEY, null);
      this._transferState.remove(GENRES_KEY);
      return of(genres.results.map((s) => new Genre(s)));
    }
    let headers = new HttpHeaders();
    return this.http.get<any>(
      environment.apiURL + `/api/v1/track/${per}/sub-genre-dropdown/`,
      {
        headers: headers,
       
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the genres
        if (!this._platform.isBrowser) {
          this._transferState.set(GENRES_KEY, res);
        }
      }),
      map((res) => res.map((s) => new Genre(s)))
    );
  }
  private getGenres(params: {[param: string]: string | string[]}): Observable<Genre[]> {
     console.log(params)
    let p = new HttpParams({ fromObject: params });
    // Check if genres are cached from server
    const GENRES_KEY = makeStateKey<APIListResponse<Object>>('genres-' + p.toString());
    if (this._transferState.hasKey(GENRES_KEY)) {
      const genres = this._transferState.get<APIListResponse<Object>>(GENRES_KEY, null);
      this._transferState.remove(GENRES_KEY);
      return of(genres.results.map((s) => new Genre(s)));
    }
    let headers = new HttpHeaders();
    return this.http.get<any>(
      environment.apiURL + '/api/v1/track/genre-dropdown/?exclude_track_count=true',
      {
        headers: headers,
        params: p
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the genres
        if (!this._platform.isBrowser) {
          this._transferState.set(GENRES_KEY, res);
        }
      }),
      map((res) => res.map((s) => new Genre(s)))
    );
  }
}
