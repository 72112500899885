import { version } from 'process';
import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  AfterViewInit,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { MatSort, MatSortable } from '@angular/material/sort';
import {
  trigger,
  style,
  animate,
  transition,
  state,
} from '@angular/animations';
import { Observable, Subscription, BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { Track } from '../models/track.model';
import { TrackVersion } from '../models/trackversion.model';
import { User } from '../models/user.model';
import { PlayerService, PlayingItem } from '../services/player.service';
import { DialogService } from '../services/dialog.service';
import { TrackStatusValue } from '../models/trackstatus-value.model';
import { TrackStatusPipe } from '../pipes/trackstatus.pipe';
import { PlaylistTracks } from '../models/playlisttracks.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatTable } from '@angular/material/table';
import { environment } from 'src/environments/environment';
import { UserMenuService } from '../services/user-menu.service';
import { Version } from '@angular/compiler';
import { ActivatedRoute } from '@angular/router';


declare type PlaylistTrackListDataSource = Observable<ReadonlyArray<PlaylistTracks>>;

export class PlaylistTracksListRow {
  id:any
  top_version:number
  admin_top_version: number
  constructor(
    public playlistTracks: PlaylistTracks,
  ) { }
}

@Component({
  selector: "app-playlist-track-list",
  templateUrl: "./playlist-track-list.component.html",
  styleUrls: ["./playlist-track-list.component.scss"],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition(":enter", [
        style({
          opacity: "0",
          height: "0",
          paddingTop: "0",
          overflow: "hidden",
          paddingBottom: "0",
        }),
        animate(
          "0.7s cubic-bezier(0.19, 1, 0.22, 1)",
          style({
            opacity: "1",
            height: "*",
          })
        ),
      ]),
      transition(":leave", [
        style({
          opacity: "1",
          height: "*",
          overflow: "hidden",
        }),
        animate(
          "0.7s cubic-bezier(0.19, 1, 0.22, 1)",
          style({
            opacity: "0",
            height: "0",
            paddingTop: "0",
            paddingBottom: "0",
          })
        ),
      ]),
    ]),
  ],
  // animations: [
  //   trigger("rowInOut", [
  //     transition(":enter", [
  //       style({
  //         opacity: "0",
  //         height: "0",
  //         paddingTop: "0",
  //         overflow: "hidden",
  //         paddingBottom: "0",
  //       }),
  //       animate(
  //         "0.7s cubic-bezier(0.19, 1, 0.22, 1)",
  //         style({
  //           opacity: "1",
  //           height: "*",
  //         })
  //       ),
  //     ]),
  //     transition(":leave", [
  //       style({
  //         opacity: "1",
  //         height: "*",
  //         overflow: "hidden",
  //       }),
  //       animate(
  //         "0.7s cubic-bezier(0.19, 1, 0.22, 1)",
  //         style({
  //           opacity: "0",
  //           height: "0",
  //           paddingTop: "0",
  //           paddingBottom: "0",
  //         })
  //       ),
  //     ]),
  //   ]),
  //   trigger("detailExpand", [
  //     state(
  //       "collapsed",
  //       style({ height: "0px", minHeight: "0", overflow: "hidden" })
  //     ),
  //     state("expanded", style({ height: "*" })),
  //     transition(
  //       "expanded <=> collapsed",
  //       animate("500ms cubic-bezier(0.4, 0.0, 0.2, 1)")
  //     ),
  //   ]),
  // ],
  providers: [TrackStatusPipe],
})
export class PlaylistTrackListComponent
  implements OnInit, OnDestroy, AfterViewInit {

    
    expandedElement: any;

  disableBtn: any = "Disabled";
  denieBtn: any = "Denied";
  approveBtn: any = "Approved";
  pendingApproveBtn: any = "Pending Approval";
  pendingBtn: any = "Pending";
  selectedTrack = null;
  selectedVersion = null;
  musicPlayerCollapsed = false;
  isOpen = false;
  showmusicplayer = false;
  triggerOrigin: any;
  newId:string =''

  toggle(trigger: any, track: any) {
    this.selectedTrack = track;

    this.triggerOrigin = trigger;
    this.isOpen = !this.isOpen;
  }


  @Input()
  // set dataSource(data: PlaylistTrackListDataSource) {
  //   this._dataSource = data.pipe(
  //     map((playlistTracks) => {
  //       let retval: PlaylistTracksListRow[] = [];
  //       let i=0;
  //       let tracks = playlistTracks.slice();
  //       tracks.sort((a, b) => {
  //         if (a.order == b.order) return 0;
  //         if (a.order > b.order) return 1;
  //         if (a.order < b.order) return -1;
  //         return 0;
  //       });
  //       tracks.forEach((t) => {
  //         let row = new PlaylistTracksListRow(t);
  //        // row.playlistTracks.position = i++;
  //         retval.push(row);
  //         this.playlistTrackList.push(t)
  //       });
  //       return retval;
  //     })
  //   );
  //   if (this._dataSourceSubscription) {
  //     this._dataSourceSubscription.unsubscribe();
  //   }
  //   this._dataSourceSubscription = data.subscribe((playlistTracks) => {
  //     let list: PlayingItem[] = [];
  //     let tracks = playlistTracks.slice();
  //     tracks.sort((a, b) => {
  //       if (a.order == b.order) return 0;
  //       if (a.order > b.order) return 1;
  //       if (a.order < b.order) return -1;
  //       return 0;
  //     });
  //     tracks.forEach((t) => {
  //       if (t && t.track && t.track.versions && t.track.versions.length > 0) {
  //         list.push(new PlayingItem(t.track, t.track.versions[0]));
  //       }
  //     });
  //     this._playerService.updatePlaybackContextList(list);
  //   });
  //  // console.log(data.source['value'])
  // }
  set dataSource(data: PlaylistTrackListDataSource){
   
  // this.newdataSource = data.source['value']
  // this.getPlaylistTrackDetail('')
  }

  @Output()
  playingTrack = new EventEmitter<PlaylistTracks>();

  @Output()
  collapseMusicPlayer = new EventEmitter<null>();

  @Output()
  sortChange: EventEmitter<MatSortable> = new EventEmitter<MatSortable>();

  @Output()
  trackChange: EventEmitter<null> = new EventEmitter<null>();

  @Input()
  set sortValue(data: string) {

    const start = data && data[0] === "-" ? "desc" : "asc";
    const id = data && data[0] === "-" ? data.substring(1) : data || "id";
    this._sortValue = { id, start } as MatSortable;
  }
  @Input()
  set playlist(t: any) {
    this.playlist_id = t;
    if(this.playlist_id !== '')
    this.getPlaylistTrackDetail(this.playlist_id )
  }
  @Input() isNotDraggable: boolean;

  get listDataSource() {
   // return this._dataSource.source['_value'];
  
   return this.newdataSource
  }
  
  expandedTrack: Observable<Track> = null;
  expandedTrackSubject = new BehaviorSubject<Track>(null);
  currentUser: User;
  playlistTrackList:any[]=[]
  public _isPlaying: boolean = false;
  public _currentlyPlaying: PlayingItem;
   public _dataSource: Observable<ReadonlyArray<PlaylistTracksListRow>>;
  public newdataSource: PlaylistTracksListRow[];
  @ViewChild('table') table: MatTable<Observable<ReadonlyArray<PlaylistTracksListRow>>>;
  public _subscriptions: Subscription[] = [];
  public _dataSourceSubscription: Subscription = null;
  public _sortValue: MatSortable;
  public playlist_id :any =''

  displayedColumns: string[] = [
     "position",
    "title",
    "track__artist",
    "track__genre",
    "track__sub_genre",
    "created_at",
    "approved_at",
  ];

  @ViewChild(MatSort) sort: MatSort;
  public _sortSubscription: Subscription = null;



  constructor(
    public _userService: UserService,
    public _playerService: PlayerService,
    public _dialogService: DialogService,
    public _trackStatusPipe: TrackStatusPipe,public api: UserMenuService,  private _activatedRoute: ActivatedRoute,
  ) {
    this.expandedTrack = this.expandedTrackSubject.asObservable();
  //  this.getPlaylistTrackDetail()
  }
  currentTrack: any;
  currIndex: any;
  ngOnInit(): void {
    this._playerService.currentPlaylistItems = {};

  }



  ngAfterViewInit() {
    this._sortSubscription = this.sort.sortChange.subscribe(() => {
      this.sortChange.emit({
        id: this.sort.active,
        start: this.sort.direction,
      } as MatSortable);
    });
    this.sort.sort(this._sortValue);
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => {
      s.unsubscribe();
    });
    if (this._dataSourceSubscription) {
      this._dataSourceSubscription.unsubscribe();
    }
    if (this._sortSubscription) {
      this._sortSubscription.unsubscribe();
    }
  }

  getPlaylistTrackDetail(id){
    if( this.newId !==  this._activatedRoute.snapshot.queryParams.playlist){
      if (this._activatedRoute.snapshot.queryParams.playlist == undefined) {
        this.newId = id
      }
      else {
        this.newId = this._activatedRoute.snapshot.queryParams.playlist
      }
      if (this.newId != "" || this.newId != undefined) {
        let url = environment.apiURL + `/api/v1/admin/playlists/` + this.newId.toString() + `/get-assigned-tracks-list/?top_version=true`
        this.api.doGET(url).subscribe(x => {
          // this.listDataSource = x["results"];
          this.newdataSource = x["results"];
          return this.newdataSource
          console.log(x)

        },
          (error) => {
            //alert(error.message)
            console.log(error);
          }
        )
      }
    }
  }

  listTrackBy(_, item: PlaylistTracksListRow) {
    return item.playlistTracks.id;
  }

  playButtonPressed(pTrack: PlaylistTracks, version:TrackVersion) {
 
    pTrack.mp3_file = version.mp3_file
    pTrack.id = version.id;
    pTrack.track_name = version.title;
    pTrack.title = version.title;
    pTrack.wav_file = version.wav_file; 
    pTrack.created_at = version.created_at;
    pTrack.approved_at = version.approved_at;
    pTrack.status = version.status;
   // let allPlaylistItems: any[] = this.listDataSource .source.source['_value'] || [];
   let allPlaylistItems: any[] = this.listDataSource || [];
   console.log(allPlaylistItems)
   let newData :any =[]
   for(let i = 0 ;i < pTrack.versions.length; i++){
    newData.push(pTrack.versions[i])
   }
   //newData = pTrack.versions
   console.log(newData)
    this._playerService.setPlayerObjects(newData, pTrack);
    this.playingTrack.emit(pTrack);
  }

  pauseButtonPressed(pTrack: PlaylistTracks, i, version:TrackVersion) {
    this._playerService.nextPlayTab(false);
    this._playerService.currentPlaylistItems.isPlay=false;
    this._playerService.currentPlaylistItems.isPause=true;
    this._playerService.updatePlayListType(false);
  }

  getTrackStatus(track: Track): TrackStatusValue {
    if (!track || !track.versions || track.versions.length <= 0) return null;
    let mainVersion = track.versions[0];
    return this._trackStatusPipe.transform(mainVersion.status);
  }

  getVersionStatus(version: TrackVersion): TrackStatusValue {
    if (!version || version.status == null) return null;
    return this._trackStatusPipe.transform(version.status);
  }

  getApprovedAt(track: Track): string {
    if (
      track &&
      track.versions &&
      track.versions.length > 0 &&
      track.versions[0].approved_at
    ) {
      return this.formatDate(track.versions[0].approved_at);
    }
    return "YES";
  }

  formatDate(d: Date): string {
    return `${d.getMonth() + 1}/${d.getDate()}/${d
      .getFullYear()
      .toString()
      .substr(-2)}`;
  }

  isPlaying(pTrack: PlaylistTracks) {
    if (this._playerService.currentPlaylistItems.currentItem) {
      if (this._playerService.currentPlaylistItems.currentItem.id == pTrack.id) {
        if (!this._playerService.currentPlaylistItems.isPlay && this._playerService.currentPlaylistItems.isPause)
          return false;
        else if (this._playerService.currentPlaylistItems.isPlay && !this._playerService.currentPlaylistItems.isPause)
          return true;
        else
          return true;
      }
      else
        return false;
    }
    else
      return false;

  }


  isPlaying_current(pTrack: PlaylistTracks) {
    if (this._playerService.currentPlaylistItems.currentItem) {
      if (this._playerService.currentPlaylistItems.currentItem.id == pTrack.id) {
          return true;
      }
      else
        return false;
    }
    else
      return false;

  }

  isPlayingPause(pTrack: PlaylistTracks) {
    if (this._playerService.currentPlaylistItems.currentItem) {
      if (this._playerService.currentPlaylistItems.currentItem.id == pTrack.id) {
        if (!this._playerService.currentPlaylistItems.isPlay && this._playerService.currentPlaylistItems.isPause)
          return true;
        else if (this._playerService.currentPlaylistItems.isPlay && !this._playerService.currentPlaylistItems.isPause)
          return false;
        else
          return false;
      }
      else
        return false;
    }
    else
      return false;

  }

  displayAddSongPlaylistDialog(pTrack: PlaylistTracks) {
    if (
      !pTrack.id || !pTrack.track || !pTrack.track.versions
    )
      //       !pTrack ||
      //||
      //   !pTrack.track.versions ||
      //   pTrack.track.versions.length <= 0
      return;
    this._dialogService
      .displayAddSongPlaylistDialog(pTrack.track, pTrack.track.versions[0], pTrack.id)
      .subscribe((changed) => {
        if (changed) {
          this.trackChange.emit();
        }
      });
  }
  sortTrack(trackval) {
    console.log(trackval + "playlist")
    // let params = {
    //   playlistId: this.currentPlaylist.id,
    //   limit: tracksPageSize,
    //   offset: (this.currentTracksPage - 1) * tracksPageSize,
    //   filterval:'getAlltrack'
    // };
    // if(this.ordering){
    //   params['ordering_by']= this.ordering
    // }
    // this._getTracksSubscription = this._playlistTracksService
    //   .getPlaylistTracks(
    //     params
    //   )
    //   .subscribe((t) => {
    //     this._tracksSubject.next(t.results);
    //     console.log((this.folders))
    //       this.showNoData=false;

    //     this.loadingTracks = false;
    //   }, (error) => {
    //     console.log(error);
    //     //alert("An error ocurred while getting the tracks for the playlist. Check your connection and try again");
    //   });

  }
  removeTrack(data) {
    this.newId = this._activatedRoute.snapshot.queryParams.playlist
    let value = {
      "track_id": data.id
    }
    this._dialogService.displayDeletePlaylistDialog(value, this.newId, 'version', data).subscribe(changed => {
      if (changed) {
        this.newId = null
        this.getPlaylistTrackDetail(this._activatedRoute.snapshot.queryParams.playlist)
        this.trackChange.emit();
       // location.reload()
      }
    });

  }
  dropTable(event: CdkDragDrop<Observable<ReadonlyArray<PlaylistTracksListRow>>>) {
    console.log(this.listDataSource)
    let newArray = [];
  //  const prevIndex = this.listDataSource.source.source['_value'].findIndex((d) =>{ 
    const prevIndex = this.listDataSource.findIndex((d) =>{ 
   //   console.log(d);
      d === event.item.data
    });
  //  moveItemInArray(this.listDataSource.source.source['_value'], prevIndex, event.currentIndex);
   moveItemInArray(this.listDataSource, event.previousIndex, event.currentIndex);
    this.table.renderRows();
    for(let i=0;i<this.listDataSource.length;i++){
      newArray.push({
        "top_version":this.listDataSource[i]?.admin_top_version,
        "order": i
      })
    }
    if(newArray.length > 0 && newArray.length === this.listDataSource.length){
      let data = {
        "reorder_info": newArray  
      }
      let pId = this._activatedRoute.snapshot.queryParams.playlist ? this._activatedRoute.snapshot.queryParams.playlist : this.playlist_id
      let url = environment.apiURL + `/api/v1/admin/playlists/`+ pId +`/update-reorder/`
      this.api.doPUT(url,  data).subscribe(x => {
       // this.listDataSource = x["results"];
       console.log(x)
      },
        (error) => {
          //alert(error.message)
          let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details :(error?.error.name ? error?.error?.name : '') ))
          this._dialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
          });
         // this.dialogRef.close();
          console.log(error);
        }
      )
    }

  }
}
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  description: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    position: 1,
    name: 'Hydrogen',
    weight: 1.0079,
    symbol: 'H',
    description: `Hydrogen is a chemical element with symbol H and atomic number 1. With a standard
        atomic weight of 1.008, hydrogen is the lightest element on the periodic table.`
  }, 
];
