export const MenuList = {
  admin: [
    { label: 'Data', path: '/data' },
    { label: 'Log', path: '/log' },
    { label: 'Reports', path: '/reports' },
    { label: 'Terms of Service', path: '/terms' },
    { label: 'Admin Groups', path: '/groups' },
    { label: 'Users', path: '/users' },
  ],
  user: [],
};
