import { MibeModel } from './mibemodel.model';
import { Album } from './album.model';

export class Artist implements MibeModel {
  id: number;
  name: string;
  bio: string;
  albums: Album[] = [];
  facebook_url: string;
  twitter_url: string;
  instagram_url: string;
  thumb_image_height: number;
  thumb_image_width: number;
  thumb_image: string;
  marquee_image_height: number;
  marquee_image_width: number;
  marquee_image: string;
  is_featured: boolean;
  favorited_by_me: boolean;
  user_id: any;

  /**
   * Create a Mood.
   * @param {any} json - The JSON object with which to construct our Artist.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a Mood.
   * @param {any} json - The JSON object with which to load our Artist.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this.id = json.id;
    this.name = json.name;
    this.bio = json.bio;
    if (json.albums) {
      this.albums = json.albums.map(a => new Album(a));
    }
    this.facebook_url = json.facebook_url;
    this.twitter_url = json.twitter_url;
    this.instagram_url = json.instagram_url;
    this.thumb_image_height = json.thumb_image_height;
    this.thumb_image_width = json.thumb_image_width;
    this.thumb_image = json.thumb_image;
    this.marquee_image_height = json.marquee_image_height;
    this.marquee_image_width = json.marquee_image_width;
    this.marquee_image = json.marquee_image;
    this.is_featured = !!json.is_featured;
    this.favorited_by_me = json.favorited_by_me;
  }
  /**
   * Returns a JSON representation of our Artist
   * @return {any} - The JSON representation of our Artist
   */
  toJSON() {
    var json: any = {
      'id': this.id,
      'name': this.name,
      'bio': this.bio,
      'facebook_url': this.facebook_url,
      'twitter_url': this.twitter_url,
      'instagram_url': this.instagram_url,
      'thumb_image_height': this.thumb_image_height,
      'thumb_image_width': this.thumb_image_width,
      'thumb_image': this.thumb_image,
      'marquee_image_height': this.marquee_image_height,
      'marquee_image_width': this.marquee_image_width,
      'marquee_image': this.marquee_image,
      'is_featured': this.is_featured,
      'favorited_by_me': this.favorited_by_me
    };
    if (this.albums) {
      json['albums'] = this.albums.map(a => a.toJSON());
    }
    return json;
  } 
}
