<div class="form-wrapper green-theme" >
    <form>
        <div class="form-row edit-track">
            <mat-radio-group (change)="mailCheckboxActivated($event)">
                <mat-radio-button class="checkbox-margin" name="Email" [value]="0" [checked]="true">Email</mat-radio-button>
                <mat-radio-button class="checkbox-margin" name="Track" [value]="1">Track</mat-radio-button>
                <mat-radio-button class="checkbox-margin" name="Version" [value]="2">Version</mat-radio-button>
              </mat-radio-group>
        </div>
        <div class="form-row">
            <mat-form-field appearance="none">
                <input type="email" matInput *ngIf="type === 0" [(ngModel)]="supportInput" [ngModelOptions]="{standalone: true}" placeholder="Please Enter User Input" required (input)="getsupportData($event.target.value)">
                <input type="text" matInput *ngIf="type !== 0" [(ngModel)]="supportInput" [ngModelOptions]="{standalone: true}" placeholder="Please Enter User Input" required (input)="getsupportData($event.target.value)">
                
            </mat-form-field>
            <!-- <p *ngIf="type === 0 && this.isValid === false && supportInput !=='' && this.count !== 1" style="
                color: red;
                font-size: small;
                margin-left: -10px;
                margin-top: 5%;">Please enter valid email.</p> -->
        </div>

        <div class="form-row meta-data edit-track" style="text-align: left;font-weight: 300;
        font-size: 12px;">
            <mat-checkbox  class="checkbox-margin" [(ngModel)]="supportPlaylist" [ngModelOptions]="{standalone: true}" [disabled]="mailCheckbox" name="Playlist" style="margin-right: 5%; margin-left: 5%;">Playlist</mat-checkbox>
            <mat-checkbox  class="checkbox-margin" [(ngModel)]="supportDownload" [ngModelOptions]="{standalone: true}"[disabled]="mailCheckbox" name="Downloads">Downloads</mat-checkbox>
        </div>

        <div class style="margin-top: 15%;width: 100%;padding-left: 24%;">
            <button class="form-button form-button-cancel" type="submit" style="margin-left: -7%;" (click)="close()">
                Cancel
            </button>
            <button class="green" type="submit" [disabled]="(supportInput === '' || supportInput.trim() === '' || loadingTracks=== true)" style="" (click)="preview()">
                Preview
            </button>
        </div>
    </form>
</div>



