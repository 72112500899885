<div class="dialog-container green-theme">
  <div class="dialog-header">
    <h4 class="dialog-heading">Manage - {{ activeTab == 'clients' ? 'Clients' : 'Tracks' }}</h4>
    <div>
      <div class="tabs">
        <span
          class="tab"
          [ngClass]="{'active': activeTab == 'clients'}"
          (click)="switchTab('clients')"
        >Clients</span>
        <span
          class="tab"
          
          (click)="switchTab('tracks')"
          [ngClass]="{'active': activeTab == 'tracks'}"
        >Tracks</span>
      </div>
      <button type="button" class="close-button" (click)="close()">
        <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
            stroke-linejoin="round">
            <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
              <g id="Genre-Options">
                <g id="Close" transform="translate(1439.000000, 32.000000)">
                  <path d="M24,0 L0,24" id="Path"></path>
                  <path d="M0,0 L24,24" id="Path"></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </button>
    </div>
  </div>

  <mat-dialog-content>
    <app-loader *ngIf="isLoading()" color="#50e3c2"></app-loader>
    <div class="dialog-body" *ngIf="!isLoading()">
      <div *ngIf="activeTab == 'clients'">
        <h3 class="title">{{data.playlist.getDisplayName()}}</h3>
        <div class="add-to-folder form-container">
          <p class="label">{{activeTab}}</p>
          <input class="search"
            placeholder="Find a client"
            [formControl]="clientSearchControl" [(ngModel)]="searchClientString">
          <ul class="list" *ngIf="!searchingClients" >
            <li *ngFor="let client of clientOptions | async ">
              <!-- <mat-checkbox [checked]="isClientChecked(client)" (change)="clientChecked($event.checked, client)" > -->
                <mat-checkbox [checked]="client.is_added === 'True'" (change)="clientChecked($event.checked, client)">
                <span class="check-text">{{client.full_name}}</span>
                <!-- //{{client.id == data.playlist.created_by ? '(Owner)':''}} | async; trackBy:clientTrackBy [disabled]="client.id == data.playlist.created_by"-->
              </mat-checkbox>
            </li>
            <button  *ngIf="!searchingClients && nextClient !== null && nextClient !== '' " class="btn save-btn" style="padding: 0px;
            width: 100px;
            height: 25px;" (click)="loadMore('clients')">Load More</button>
          </ul>
          <div class="no-groups" *ngIf="!searchingClients && clients.length <= 0">
            <p>There are currently no clients available.</p>
          </div>
          <app-loader *ngIf="searchingClients" color="#50e3c2"></app-loader>
        </div>
        <p class="note">Select or deselect {{ activeTab == 'clients' ? 'clients' : 'tracks' }} to add or remove them from this playlist</p>
      </div>
      <div *ngIf="activeTab == 'tracks'">
        <h3 class="title">{{data.playlist.getDisplayName()}}</h3>
        <div class="add-to-folder form-container">
          <p class="label">{{activeTab}}</p>
          <input class="search"
            placeholder="Find a track"
            [formControl]="trackSearchControl" [(ngModel)]="searchTrackString">
          <ul class="list" *ngIf="!searchingTracks">
            <li *ngFor="let track of trackOptions | async">
              <!-- <mat-checkbox [checked]="isTrackChecked(track)" (change)="trackChecked($event.checked, track)"> -->
              <mat-checkbox [checked]="!isTrackUnchecked(track) && track.is_added === 'True'|| isTrackChecked(track)" (change)="trackChecked($event.checked, track)">
                <span class="check-text">{{track?.title}}</span>
              </mat-checkbox>
            </li>
            <button  *ngIf="!searchingTracks && nextTrack !== null && nextTrack!== '' "  class="btn save-btn" style="padding: 0px;
            width: 100px;
            height: 25px;" (click)="loadMore('tracks')">Load More</button>
          </ul>
          <div class="no-groups" *ngIf="!searchingTracks && tracks.length <= 0">
            <p>There are currently no track versions available.</p>
          </div>
          <app-loader *ngIf="searchingTracks" color="#50e3c2"></app-loader>
        </div>
        <p class="note">Select or deselect tracks to add or remove them from this playlist</p>
      </div>
      <div class="form-col buttons">
        <button class="form-button" (click)="save( activeTab == 'clients' ? 'clients' : 'tracks' )" [disabled]="!canSubmit()">Save</button>
        <button class="form-button form-button-cancel" (click)="close()">Cancel</button>
      </div>
    </div>
  </mat-dialog-content>
</div>
