import { Directive, ElementRef, Input, OnInit, OnDestroy,Renderer2, Output, EventEmitter } from '@angular/core';
import { FormGroup,FormBuilder,AbstractControl,Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[phoneMask]'
})
export class PhoneMaskDirective implements OnInit, OnDestroy{

  private _phoneControl:AbstractControl;
  private _preValue:string;

  @Input() 
  set phoneControl(control:AbstractControl){
    this._phoneControl = control;
  }
  @Input() 
  set preValue(value:string){
    this._preValue = value == null ? '':value;
  }

  @Output()
  valueChange:EventEmitter<string> = new EventEmitter<string>();
  
  private sub:Subscription;

  constructor(private el: ElementRef,private renderer:Renderer2) {}

  ngOnInit(){
     this.phoneValidate();
     //If we start out with a value then parse it with timeout to beat change detection
     if (this._preValue && this._preValue != '') {
      setTimeout(()=>{
        this.onChange(this._preValue);
      })
       
     }
  }

  ngOnDestroy() {
    this.sub.unsubscribe(); 
  }

  phoneValidate(){
    this.sub = this._phoneControl.valueChanges.subscribe((data:string)=>this.onChange(data));
  }

  private onChange(data:string) {
    let preInputValue:string = this._preValue;
    preInputValue = preInputValue.replace('+','');
    var lastChar:string = preInputValue.substr(preInputValue.length - 1);
    // remove all mask characters (keep only numeric)
    var newVal = data.replace(/\D/g, '');

    let start = this.renderer.selectRootElement(this.el.nativeElement).selectionStart;
    let end = this.renderer.selectRootElement(this.el.nativeElement).selectionEnd;

    if (data.length < preInputValue.length) {
      if(preInputValue.length < start){
        if(lastChar == ')'){
          newVal = newVal.substr(0,newVal.length-1); 
        }
      }
      newVal = this.formatNumber(newVal, true);
      
    } else {
      
      newVal = this.formatNumber(newVal);

    }
    this.valueChange.emit(newVal);
    this._phoneControl.setValue(newVal,{emitEvent: false});

    let offset = this.calculateCursorOffset(start, data, newVal);
    this.renderer.selectRootElement(this.el.nativeElement).setSelectionRange(start+offset,end+offset);
  }

  private formatNumber(newVal:string, isDeletion:boolean = false): string {
    if (newVal.length == 0) {
      newVal = '';
    } else if (newVal.length <= 3) {
      newVal = newVal.replace(/^(\d{0,3})/, isDeletion ? '$1':'$1');
    } else if (newVal.length <= 6) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1 $2');
    } else if (newVal.length <= 9) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(.*)/, '$1 $2-$3');
    } else {
      newVal = newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})(.*)/, '+$1 $2-$3-$4');
      /* if (newVal.length == 10) {
        newVal = newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})(.*)/, '+1 $1$2-$3-$4');
      } else if (newVal.length == 12) {
        newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})(.*)/, '+$1 $2-$3-$4');
      } else {
        newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})(.*)/, '+$1 $2-$3-$4');
      }  */
    }
    return newVal;
  }

  private calculateCursorOffset(start:number, data:string, newVal:string): number {
    let pre = data.substring(0, start);
    let post = data.substring(start, data.length);
    let cleanNewVal = data.replace(/\D/g, '');
    pre = pre.replace(/\D/g, '');
    post = post.replace(/\D/g, '');
    if (post.length == 0) {
      return newVal.length - start;
    }
    if (pre.length == 0) {
      return 0;
    }
    let offset = 0;
    if (cleanNewVal.length < 11) { //no int code
      if (pre.length < 4) {
        offset = 1;
      } else if (pre.length < 7) {
        offset = 3
      } else {
        offset = 4;
      } 
    } else {
      let codeLength = cleanNewVal.length - 10;
      if (pre.length < codeLength+1) {
        offset = 1;
      } else if (pre.length < codeLength+4) {
        offset = 3;
      } else if (pre.length < codeLength+7) {
        offset = 5;
      } else {
        offset = 6;
      }
    }

    return (offset + pre.length) - start;
  }
}
