<div class="main">
    <div class="user-list">
        <div class="header">
            <div class="filters top-filters">
                <div class="tabs">
                    <span
                      class="tab" (click)="tbactive = true"
                      [ngClass]='{"tabsactive": tbactive}'
                      >Clients</span>
                    <span
                      class="tab" (click)="tbactive = false" [ngClass]='{"tabsactive": !tbactive}'
                     >Artists</span>
                </div>
            </div>
            <form action="#" class="search-filter" #searchForm="ngForm" [ngClass]="{'search-filter-focused': searchFilterFocused}">
                <label for="search-filter-field" class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14">
                        <path fill="none" stroke="#fff" stroke-linecap="round"
                            stroke-linejoin="round" stroke-miterlimit="20" d="M6 1a5 5 0 110 10A5 5 0 016 1zM13.002 13.002L9.535 9.535"
                        /></svg>
                </label>
                <input  *ngIf="!tbactive" name="searchString" type="text" class="txt" id="search-filter-field" (focus)="searchFilterFocused = true"
                (keyup.enter)="pageTosTrigger('',$event.target.value)" placeholder="Search" />
            </form>
            <div class="btns">
                <button  class="button" (click)="fileInput.click()">upload terms of service agreement
                <img class="uploadwidth" src="assets/img/publish_white.svg"> 
                </button>
            </div>
            <input style="display: none;"  accept="application/pdf, application/vnd.ms-excel" (change)="onChangeFile(fileInput.files[0])" type="file" enctype="multipart/form-data" #fileInput>
        </div>
        <div class="reports-info track-list" *ngIf="!tbactive">
                <app-loader *ngIf="list.tosLoader"></app-loader>
            <mat-table matSort  (matSortChange)="sortData(list.dataSourceForTOS.sort)" *ngIf="!list.tosLoader" [dataSource]="list.dataSourceForTOS" class="mat-elevation-z8">

               
                <!-- Position Column -->
                <!-- <ng-container matColumnDef="position">
                  <mat-header-cell *matHeaderCellDef> client </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.user_type}} </mat-cell>
                </ng-container> -->
              
                <!-- Name Column -->
                <ng-container matColumnDef="file_name">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Artist </mat-header-cell>
                  <mat-cell *matCellDef="let element" style="font-weight: 700;"><span class="email">{{element.user_name}}</span>  </mat-cell>
                </ng-container>

                 <!-- Name Column -->
                 <ng-container matColumnDef="version">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Version </mat-header-cell>
                  <mat-cell *matCellDef="let element" style="font-weight: 700;"><span class="email"> {{element.file_name}} </span></mat-cell>
                </ng-container>
              
                <!-- Weight Column -->
                <ng-container matColumnDef="created_at">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> created </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.created_at | date: 'M/d/yy'}} </mat-cell>
                </ng-container>
              
                <!-- Symbol Column -->
                <ng-container matColumnDef="shared_on">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> shared on </mat-header-cell>
                  <mat-cell *matCellDef="let element" > {{element.shared_on | date: 'M/d/yy'}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="View">
                    <mat-header-cell *matHeaderCellDef>  </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="justify-content:flex-end!important;
                    "> 
                    
                    <a [href]="element.file_path" target="_blank" class="action"><small>View</small></a>
                    </mat-cell>
                  </ng-container>
              
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" class="main-row"></mat-row>
            </mat-table>
              
              
            
        </div>
        <div *ngIf="!tbactive">
        <!-- <app-pagination></app-pagination> -->
        <div *ngIf="!list.tosCount && !list.tosLoader" class="no-data">No data available</div>
        <app-pagination
                *ngIf="tosNumberOfPages > 1"
                [page]="_currentTosPage"
                (filtersChange)="pageTosTrigger($event, _searchString)"
                [numberOfPages]="tosNumberOfPages"
            ></app-pagination>
          </div>
          <div *ngIf="tbactive">
            <!-- <app-pagination></app-pagination> -->
            <div  class="no-data mt-3">No data available</div>
            <!-- <app-pagination
                    *ngIf="tosNumberOfPages > 1"
                    [page]="_currentTosPage"
                    (filtersChange)="pageTosTrigger($event, _searchString)"
                    [numberOfPages]="tosNumberOfPages"
                ></app-pagination> -->
              </div>

    </div>
</div>

