import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MenuConfigService } from 'src/app/shared/services/menu-config.service';
import { UserMenuService } from 'src/app/shared/services/user-menu.service';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';
@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }
  transform(file) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(file);
  }

}
@Component({
  selector: 'composer-tos',
  templateUrl: './tos.component.html',
  styleUrls: ['./tos.component.scss']
})
export class TosCompComponent implements OnInit {

  loading: boolean = false;
  tosDetail: any = [];
  id: number;
  date: string
  file:string;
  reject = false;
  constructor( public api: UserMenuService, private _router: Router,  public config: MenuConfigService, private _userService: UserService) { }

  ngOnInit(): void {

    this.loading = true;
    console.log(this._userService.currentUser.tos_status, this._userService.currentUser.id)
    let url = environment.apiURL + `/api/v1/admin/users/` + `${this._userService.currentUser.id}` + `/get-tos/`;
    this.api.doGET(url).subscribe(x => {
      this.loading = false;
      this.tosDetail = x;
      this.date = this.tosDetail[0].last_sent
      this.id = this.tosDetail[0].id
      this.file = this.tosDetail[0].file
      console.log(this.id,  this.date, this.file)
    },
      (error) => {
        this.loading = false;
      })
  }
  tosRejStatus() {
    this.reject = true;
    //  this.dialog.displayDeleteUsergroupDialog(TosRejectPopupComponent)
  }
  rejected(){
    this.reject = false;
  }
  tosStatus() {
    let data = {
      "tos_id": this.id,
      "tos_status": "Accepted"
    }
    this.loading = true;
    console.log(this._userService.currentUser.tos_status, this._userService.currentUser.id)
    let url = environment.apiURL + `/api/v1/admin/users/tos-status/`;
    this.api.doPUT(url, data).subscribe(x => {
      this.loading = false;
      this._userService.logout().subscribe(()=> {
        this._router.navigate(['/login']);
      }, (error) => {
        console.log(error);
      });
    },
      (error) => {
        this.loading = false;
      })
  }
}
