import {Component, Input, OnInit, Output, EventEmitter, ViewChild} from '@angular/core';
import {TrackComposer} from '../models/trackcomposer.model';
import {Observable, ReplaySubject, Subscription, of} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {CoWriterService} from '../services/cowriter.service';
import { FormControl } from '@angular/forms';
import { tap, startWith, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { MatSelectChange, MatSelect } from '@angular/material/select';
import { environment } from '../../../environments/environment';
import { UserMenuService } from '../services/user-menu.service';
import { MatOption } from '@angular/material/core';
import { TrackService } from '../services/track.service';
import { UserService } from '../services/user.service';
import { Track } from '../models/track.model';
import { PlayerService } from '../services/player.service';

export interface CoWriter {
  composer_name: string;
  composer_percentage: number;
  new: boolean;
}
interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-composer-manage-cowriters',
  templateUrl: './composer-manage-cowriters.component.html',
  styleUrls: ['./composer-manage-cowriters.component.scss']
})
export class ComposerManageCowritersComponent implements OnInit {

  gridApi;
  frameworkComponents: any;
  percentage : number;
  panelOpenState: boolean;
  get noOfCoWriters(): number {
    if (!this.composers) return 0;
    return this.composers.length;
  }
@ViewChild('coWriter') coWriter:MatSelect
  pervalue : number = 0;
  currentlyOpen:number = null;


  // @ViewChild('agGrid') gridApi: AgGridAngular;

  coWriterFilterCtrl = new FormControl();
  coWriterForm = new FormControl();
  filteredCoWriterOptions: ReplaySubject<TrackComposer[]> = new ReplaySubject<TrackComposer[]>(1);
  allCoWritersOptions: ReplaySubject<TrackComposer[]> = new ReplaySubject<TrackComposer[]>(1);
  searchingCoWriter = false;

  @ViewChild("coWriterPanel") coWriterPanel: any;

  @Input() 
  set composers(c: TrackComposer[]) {
    this._composers = c;
    this.dataSource.data = this._composers;
    this.composersChange.emit(this._composers);
  };
  get composers():TrackComposer[] {
    return this._composers;
  }

  @Input()
  set isVersion(value: boolean){
    this.is_version = value;
  }
  get isVersion():boolean{
    return this.is_version;
  }
  @Input()
  set track1(t: Track) {
    this._track = t;
  }
  get track1(): Track {
    return this._track;
  }
  dataCowriter =[];
  newCowriter:boolean= true
  @Output()
  composersChange = new EventEmitter<TrackComposer[]>()

  allCoWriters: TrackComposer[] = [];
  dataSource = new MatTableDataSource(this.composers);
  is_version : boolean = false;
  _track:Track

  private _composers: TrackComposer[] = [];
  private _subscriptions: Subscription[] = [];
  defaultCount: number= 0
  constructor(
    private _coWriterService: CoWriterService,
    public api: UserMenuService,
    private _userService: UserService, public track: TrackService, private _playerService: PlayerService,
  ) {
    
    this.getcoWriters();
  }
lengthOfCo:any;
defaultValueCowriter:any=[]
  ngOnInit() {

    if(this.isVersion){
      this.newCowriter = false;
    this._subscriptions.push(
      this._playerService.trackId.subscribe((value) => {
        if (value !== 0) {
          this.newCowriter = true;
        }
      }),
  
       this._playerService.trackCowriter.subscribe((co) => {
        this.track.cowritten_by = []
        this.defaultValueCowriter = []
        this.defaultValueCowriter = co

        this._playerService.trackNumber.subscribe((p) => {
          console.log(p)
          this.dataCowriter = []
          this.track.cowritten_by = []
          this.defaultValueCowriter = []
          this.coWriters = []
          this.defaultValueCowriter = co

          for (let x of this.defaultValueCowriter) {
            if (x.disable === true) {
              this.track.cowritten_by.push(x)
              this.coWriters.push(x)
            }
          }
          // const uniqueIds = [];
          // const unique = this.coWriters.filter(element => {
          //   const isDuplicate = uniqueIds.includes(element.id);
          //   if (!isDuplicate) {
          //     uniqueIds.push(element.id);
          //     return true;
          //   }
          //   return false;
          // });
          // this.coWriters = unique
          if (p.length > 0 ) {
            for (let i = 0; i < p.length; i++) {
              this.dataCowriter.push({
                id: p[i]['id'],
                first_name: p[i]['name'],
                last_name: '',
                percent: p[i]['percent'],
                user_id: p[i]['user_id'],
              })
              this.coWriters.push({
                id: p[i]['id'],
                first_name: p[i]['name'],
                last_name: '',
                percent: p[i]['percent'],
                user_id: p[i]['user_id'],
                disable: true
              })
              this.track.cowritten_by.push({
                id: p[i]['id'],
                first_name: p[i]['name'],
                last_name: '',
                percent: p[i]['percent'],
                user_id: p[i]['user_id'],
                disable: true
              })

            }
            let data1 = this.track.cowritten_by.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
            let valueCo = []
            for (let i = 0; i < data1.length; i++) {
              if (data1[i].id) {
                valueCo.push(data1[i])
              }
            }
            this.track.cowritten_by = valueCo
            // for (let data of this.track.cowritten_by) {
            //   this.coWriter.options.map((x: MatOption) => {
            //     if (x.value.id == data.id) {
            //       x.select();
            //       x.value.percent = data.percent;
            //     }
            //   }
            //   )
            // }
            this.lengthOfCo = this.track.cowritten_by.length
            // this.coWriters = this.coWriters + this.dataCowriter;
            // this.track.cowritten_by = this.track.cowritten_by + this.dataCowriter
          }
          // setTimeout(() => {
          //   for (let data of this.track.cowritten_by) {
          //     this.coWriter.options.map((x: MatOption) => {
          //       if (x.value.id == data.id) {
          //         x.select();
          //         x.value.percent = data.percent;
          //       }
          //     }
          //     )
          //   }
          // }, 500)
          let uniqueChars = [...new Set(this.coWriters)];
          this.coWriters = uniqueChars
        }
        )
        let uniqueChars = [...new Set(this.coWriters)];
        this.coWriters = uniqueChars
      })
      
      )
      let uniqueChars = [...new Set(this.coWriters)];
      this.coWriters = uniqueChars
    }
    this._coWriterService.getCoWriters().subscribe(coWriters => {
      
      this.allCoWriters = coWriters;
      this.allCoWritersOptions.next(this.allCoWriters);
      this.filteredCoWriterOptions.next(this.allCoWriters);
      console.log(this.track)
    }, (error) => {
      console.log(error);
      //alert("An error ocurred while getting cowriters. Check your connections and try again");
    });
    
    this._subscriptions.push(this.coWriterFilterCtrl.valueChanges.pipe(
      startWith(''),
      distinctUntilChanged(),
      tap(() => {
        this.searchingCoWriter = true;
      }),
      switchMap((v) => {
        if (!v) {
          return of(this.allCoWriters)
        }
        return of(this.allCoWriters.filter(c=> {
          return c.composer_name.toLowerCase().includes(v.toLowerCase()) || c.publisher_name.toLowerCase().includes(v.toLowerCase());
        }))
      }),
      tap(() => {
        this.searchingCoWriter = false;
      })
    ).subscribe(c => {
      this.filteredCoWriterOptions.next(c);
    }));
    let uniqueChars = [...new Set(this.coWriters)];
    this.coWriters = uniqueChars
  }
  addco(i){
  
   
   }
  addElement() {
    this.composers.push(new TrackComposer(null));
    this.dataSource = new MatTableDataSource(this.composers);
  }
  removeAt(index: number) {
    
    const c = this.composers;
    c.splice(index, 1);
    this.composers = c
  }

  // Workaround for angular component issue #13870
  disableAnimation = true;
  displayedColumns: string[] = ['user', 'percent', 'actions'];
  ngAfterViewInit(): void {
    // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => this.disableAnimation = false);
    console.log(this.track.cowritten_by, "cwb")
    if(this.track.cowritten_by.length > 0 && !this.isVersion){
      let data = this.track.cowritten_by;
      for (let i = 0; i < data.length; i++) {
        this.coWriters.push({
          id: data[i].id,
          first_name: data[i].name,
          last_name: '',
          percent: data[i].percentage_allocated,
          user_id: data[i].id,
        }) 
        this.track.cowritten_by.push({
          id: data[i].id,
          first_name: data[i].name,
          last_name: '',
          percent: data[i].percentage_allocated,
          user_id: data[i].id,
          disable:true
        }) 
        this.defaultCount = this.defaultCount + data[i].percentage_allocated
        this.lengthOfCo = this.track.cowritten_by.length
      }
      this.coWriters = this.coWriters + this.dataCowriter;
      this.track.cowritten_by = this.track.cowritten_by + this.dataCowriter
      let data1 = this.track.cowritten_by.filter((v,i,a)=>a.findIndex(t=>(t.id===v.id))===i)
      let valueCo = []
      for(let i=0;i< data1.length;i++){
        if(data1[i].id){
          valueCo.push(data1[i])
        //  this.defaultCount = this.defaultCount + data1[i].percent;

        }
      }
      this.track.cowritten_by = valueCo
     
    for(let data  of this.track.cowritten_by){
      this.coWriter.options.map((x:MatOption) =>{
        if(x.value.id == data.id){
          x.select();
          // this.coWriterList.splice(i, 1)
        }
        // if(data.id === 1 || data.id === 2){
        //   x.select()
        // }
      }
      )
    }
    let uniqueChars = [...new Set(this.coWriters)];
    this.coWriters = uniqueChars
  }
    
  }
  ngAfterContentChecked(): void{
    
  }

  openChangedAt(open:boolean, i:number) {
    if (open) {
      this.currentlyOpen = i;
    } else {
      if (this.currentlyOpen === i) {
        this.currentlyOpen = null;
      }
    }
  }


  

  filteredCoWriterOptionsAt(index:number): ReplaySubject<TrackComposer[]> {
    if (this.currentlyOpen == index) {
      return this.filteredCoWriterOptions;
    }
    return this.allCoWritersOptions;
  }

  coWriterChangedAt(change:MatSelectChange, index:number) {
    let p = this.composers[index].composer_percentage;
    this.composers[index] = change.value;
    this.composers[index].composer_percentage = p;
    this.composersChange.emit(this._composers);
  }
  percentageChangedAt(value, index) {
    this.composers[index].composer_percentage = value;
    this.composersChange.emit(this._composers);
  }

  coWriterCompareWith(c1: TrackComposer, c2: TrackComposer) {
    if (!c1 || !c2) {
      return c1 == c2;
    }
    return c1.composer_id == c2.composer_id;
  }

  coWriterTrackBy(i: number, coWriter: TrackComposer) {
    if (!coWriter) {
      return coWriter;
    }
    return i + '-' + coWriter.composer_id;
  }
  coWriters:any=[];
  coWriterList:any;
  co_editDup: any;
  currentUser: any;
  cowriterValue:any=[]
  
  getcoWriters(){
    this.track.cowritten_by = [];
    let requestUrl = environment.apiURL + `/api/v1/track/artist-dropdown/`
    // this.api.doGET(requestUrl).subscribe(x =>{
    //   this.coWriters = x;
    //   this._userService.currentUserStream.subscribe((u) => {
    //     this.currentUser = u;
    //   });
    //  // let name =  this.currentUser.first_name +" "+ this.currentUser.last_name
    //  let email = this.currentUser.email
    //   if(this.currentUser.allowed_cowriters.length > 0){
    //     this.coWriters = this.currentUser.allowed_cowriters;
    //     this.coWriters = this.coWriters.filter(function(e) { 
    //         if(e.email !==  email ){
    //           return e;
    //         }
    //       })
    //     }
      
    
    //   // let id = this.currentUser.id ;
    //   // console.log(name)
    //   // this.coWriters = this.coWriters.filter(function(e) { 
    //   //   if(e.id !== id && e.name !== name ){
    //   //     return e;
    //   //   }
    //   // })
    //   // for(var co of this.coWriters){
    //   //       co['percent'] = 0;
    //   // }
    //   // setTimeout(() => {
    //   //   for(let data  of this.track.trackPopData.cowritten_by){
    //   //     this.coWriter.options.map((x:MatOption) =>{
    //   //       if(x.value.id == data.id){
    //   //         x.select();
    //   //         x.value.percent = data.percent;
    //   //       }
    //   //     }
    //   //     )
    //   //   }
    //   // },500)
    // })
    this._userService.currentUserStream.subscribe((u) => {
        this.currentUser = u;
      });

    let email = this.currentUser.email
    let url = environment.apiURL + `/api/v1/admin/users/${this.currentUser.id}/get-cowriter/`;
    this.api.doGET(url).subscribe(x=>{
      this.cowriterValue = x;
      if (!this.isVersion) {
        for (let i = 0; i < this.cowriterValue.length; i++) {
          this.cowriterValue[i].disbale = false;
          this.coWriters.push(this.cowriterValue[i]);
        }
        //this.coWriters = x;
        for (let i = 0; i < this.currentUser['default_co_writer'].length; i++) {
          this.coWriters.push({
            id: this.currentUser['default_co_writer'][i].id,
            first_name: this.currentUser['default_co_writer'][i].name,
            last_name: '',
            percent: this.currentUser['default_co_writer'][i].percentage_allocated,
            user_id: this.currentUser['default_co_writer'][i].id,
            disable: true
          })
          this.defaultValueCowriter.push({
            id: this.currentUser['default_co_writer'][i].id,
            first_name: this.currentUser['default_co_writer'][i].name,
            last_name: '',
            percent: this.currentUser['default_co_writer'][i].percentage_allocated,
            user_id: this.currentUser['default_co_writer'][i].id,
            disable: true
          })
          this.track.cowritten_by = this.defaultValueCowriter
          //  this.defaultCount = this.defaultCount + this.currentUser['default_co_writer'][i].percentage_allocated
          this.lengthOfCo = this.track.cowritten_by.length
        }
      }
      this.coWriters = this.coWriters.filter(function(e) { 
          if(e.email !==  email ){
            return e;
          }
      })
      setTimeout(() => {
        for (let data of this.track.cowritten_by) {
          this.coWriter.options.map((x: MatOption) => {
            if (x.value.id == data.id) {
              x.select();
              x.value.percent = data.percent;
            }
          }
          )
        }
      }, 500)

    },
    (error) => {
    })
  }
  Changeddesc(e){

  }
  remove(i){
    this.coWriter.options.map((x:MatOption) =>{
     
      if(x.value.id == i){
        
        x.deselect();
        // this.coWriterList.splice(i, 1)
      }
    
    }
    )
  }
  minus(e,i){
    if(e > 0){
      this.track.cowritten_by[i].percent = Number(e) - 1
      return Number(e) - 1
    }
    else{
      this.track.cowritten_by[i].percent = 0
    return 0
    }
   
  }
  plus(e,i){
    e = e != undefined ? e : 0;
    var totalPercentage = 0;
    if (e <= (100-this.defaultCount)) {
      this.track.cowritten_by[i].percent = Number(e) + 1
      if (this.track.cowritten_by.length > 0) {
        for (let value of this.track.cowritten_by) {
          totalPercentage += value.percent;
        }
      }
      if (totalPercentage > (99)) {
        return 0
      }
      else {
        if (e < (100-this.defaultCount)) {
          this.track.cowritten_by[i].percent = Number(e) + 1
          return Number(e) + 1
        }
      }
    }
    else {
      this.track.cowritten_by[i].percent = (100-this.defaultCount)
      if (this.track.cowritten_by.length > 0) {
        for (let value of this.track.cowritten_by) {
          totalPercentage += value.percent;
        }
      }
      if (totalPercentage > (99)) {
        return this.track.cowritten_by[i].percent
      }
      else {
        this.track.cowritten_by[i].percent = 0
        return this.track.cowritten_by[i].percent
      }
    }
  }
  selectOption(e,ind){
    if(e.target===undefined){
      //this.track.cowritten_by = this.track.cowritten_by.concat(this.track.trackPopData.cowritten_by)
    }
   // this.track.cowritten_by.concat(this.defaultValueCowriter)
    for(let i=0;i<this.defaultValueCowriter.length;i++){
      this.track.cowritten_by[ this.track.cowritten_by.length] = this.defaultValueCowriter[i]
    }
    let valueCo = []
    let data = this.track.cowritten_by.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
    for (let i = 0; i < data.length; i++) {
      if (data[i].id) {
        valueCo.push(data[i])
      }
    }
    this.track.cowritten_by = valueCo
    this.lengthOfCo = this.track.cowritten_by.length
  }


  onBlur(value, i) {
    let decimal = false;
    if(value. charAt(value. length - 1)  === '.'){
    value = (value)+ 0;
    decimal = true;
    }
    var totalPercentage = 0;

    if (Number(value) <= (100-this.defaultCount)) {
      var num = Number(value)
      this.track.cowritten_by[i].percent = Number(value)
      if (this.track.cowritten_by.length > 0) {
        for (let value of this.track.cowritten_by) {
          totalPercentage += value.percent;
        }
      }
      if (totalPercentage > (99)) {
        this.track.cowritten_by[i].percent =  0
      }
      else {
        if (num <= (100-this.defaultCount)) {
          if(decimal){
            this.track.cowritten_by[i].percent = num+'.'
            return  this.track.cowritten_by[i].percent
            }
            else{
              this.track.cowritten_by[i].percent = num
              return  this.track.cowritten_by[i].percent
            }
        }
      }

    }
    else {
      this.track.cowritten_by[i].percent = (100-this.defaultCount)
      if (this.track.cowritten_by.length > 0) {
        for (let value of this.track.cowritten_by) {
          totalPercentage += value.percent;
        }
      }
      if (totalPercentage > (99)) {
         this.track.cowritten_by[i].percent = 0
         return this.track.cowritten_by[i].percent
      }
      else {
        this.track.cowritten_by[i].percent = 0
        return this.track.cowritten_by[i].percent
      }
    }
  }
  value(value, i) {
    let decimal = false;
    if(value. charAt(value. length - 1)  === '.'){
    value = (value)+ 0;
    decimal = true;
    }
    var totalPercentage = 0;

    if (Number(value) <= (100-this.defaultCount)) {
      var num = Number(value)
      this.track.cowritten_by[i].percent = Number(num)
      if (this.track.cowritten_by.length > 0) {
        for (let value of this.track.cowritten_by) {
          totalPercentage += value.percent;
        }
      }
      if (totalPercentage > (99)) {
        this.track.cowritten_by[i].percent =0
        return this.track.cowritten_by[i].percent
      }
      else {
        if (num <= (100-this.defaultCount)) {
          if(decimal){
            this.track.cowritten_by[i].percent = num +'.';
            return num +'.'
          }
          else{
            this.track.cowritten_by[i].percent = num;
            return num
          }
        }
      }

    }
    else {
      this.track.cowritten_by[i].percent = (100-this.defaultCount)
      if (this.track.cowritten_by.length > 0) {
        for (let value of this.track.cowritten_by) {
          totalPercentage += value.percent;
        }
      }
      if (totalPercentage > (99)) {
         this.track.cowritten_by[i].percent = 0
         return this.track.cowritten_by[i].percent
      }
      else {
        this.track.cowritten_by[i].percent = 0
        return this.track.cowritten_by[i].percent
      }
    }
  }

  closethisPanel() {
      this.panelOpenState = false;
      this.coWriterPanel.close();
  }

}
