import { Track } from './../models/track.model';
import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../environments/environment';
import { Playlist } from '../models/playlist.model';
import { APIListResponse } from '../models/apiresponse.model';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { GetTracksOptions } from './track.service';
import { PlaylistClient } from '../models/playlistclient.model';
import { UserService } from './user.service';
import { SSL_OP_CISCO_ANYCONNECT } from 'constants';
import { formatDate } from "@angular/common";


export interface GetPlaylistsOptions {
  ordering?: string;
  search?: string;
  startDate?: Date;
  endDate?: Date;
  limit?: number;
  offset?: number;
  containsTrackVersionId?: number;
  includeComposerCount?: boolean;
  createdBy?: number;
  sharedWith?: number;
  page?:number;
  ordering_by?:string;
}

@Injectable({
  providedIn: 'root'
})
export class PlaylistService {
userData:any;
  constructor(
    private http: HttpClient,
    private _platform: Platform,
    private _transferState: TransferState,
    public user: UserService
  ) {
var retrievedObject = localStorage.getItem('userdata')
this.userData =  JSON.parse(retrievedObject);
   }

  getAllPlaylists(options: GetPlaylistsOptions): Observable<APIListResponse<Playlist>> {
    let params = this.optionsToParams(options);
    console.log(params)
    return this.getPlaylists(params);
  }

  getPlaylist(playlistId: number, includeComposerCount: boolean = false): Observable<Playlist> {
    // Check if playlist is cached from server
    let p = new HttpParams({ fromObject: {'include_composer_count': includeComposerCount ? 'true':'false'} });
    const PLAYLIST_KEY = makeStateKey<APIListResponse<Object>>('playlist-' + playlistId + '-' + p.toString());
    if (this._transferState.hasKey(PLAYLIST_KEY)) {
      const playlist = this._transferState.get<APIListResponse<Object>>(PLAYLIST_KEY, null);
      this._transferState.remove(PLAYLIST_KEY);
      return of(new Playlist(playlist));
    }
    let headers = new HttpHeaders();
    if (playlistId) {
      var url = environment.apiURL + '/api/v1/admin/playlists/' + playlistId + '/';
    } else {
      var url = environment.apiURL + '/api/v1/admin/playlists/'
    }
    return this.http.get<Object>(
      url,
      {
        headers: headers,
        params: p
      }
    ).pipe(
      tap(res => {
        // console.log('playlists================',res)

        // If we're on the server cache the moods
        if (!this._platform.isBrowser) {
          this._transferState.set(PLAYLIST_KEY, res);
        }
      }),
      map((res) => new Playlist(res))
    );
  }

  getFeaturedPlaylists(limit: number=null): Observable<APIListResponse<Playlist>> {

    return this.getPlaylists({
      'is_featured': 'true',
      'limit': (limit!==null? limit.toString():'3')
    });
  }

  getEditablePlaylists(check_for_track_version: number = null, options: GetPlaylistsOptions = null): Observable<APIListResponse<Playlist>> {
    let params: { [param: string]: string | string[]; } = {
      'is_editable': 'true'
    };
    if (options) {
      if (options.ordering) {
        params['ordering'] = options.ordering;
      }
      if (options.search) {
        params['search'] = options.search;
      }
    }
    if (check_for_track_version != null) {
      params['contains_track_version_id'] = `${check_for_track_version}`;
    }
    return this.getPlaylists(params);
  }

  getSharedPlaylists(options: GetPlaylistsOptions = null): Observable<APIListResponse<Playlist>> {
    const params = {
      'is_shared': 'true'
    };
    if (options) {
      if (options.ordering) {
        params['ordering'] = options.ordering;
      }
      if (options.search) {
        params['search'] = options.search;
      }
    }
    return this.getPlaylists(params);
  }

  sharePlaylistViaEmail(playlist: Playlist, emails: string[], message: string): Observable<Playlist> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiURL + '/api/v1/admin/playlists/' + playlist.id + '/shareemail/',
      {
        'emails': emails,
        'message': message
      },
      {
        headers: headers
      }
    ).pipe(map((res) => {
      playlist.loadWithJSON(res);
      return playlist;
    }));
  }

  duplicatePlaylist(playlist: Playlist, name: string): Observable<Playlist> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiURL + '/api/v1/admin/playlists/' + playlist.id + '/duplicate/',
      {
        'name': name
      },
      {
        headers: headers
      }
    ).pipe(map((res) => {
      let playlist = new Playlist(res);
      return playlist;
    }));
  }

  createPlaylist(name: string, description: string = '',created_by: number, id:number=0, type:string='', folder_ids:any, folder_name: string = '', is_public: boolean): Observable<Playlist> {
    let headers = new HttpHeaders();

    console.log(this.userData)
    headers = headers.append("Content-Type", "application/json");
    if(type === 'create'){
      return this.http.post<any>(
        environment.apiURL + '/api/v1/admin/playlists/',
        {
          name: name,
          description: description,
          created_by: this.userData.id,
          folder_ids: folder_ids,
          folder_name: folder_name,
          is_public: is_public,
          "track_to_be_mapped": [],
        },
        {
          headers: headers
        }
      ).pipe(map((res) => new Playlist(res)));
    }
    else{
      return this.http.post<any>(
        environment.apiURL + '/api/v1/admin/playlists/',
        {
          name: name,
          description: description,
          created_by: this.userData.id,
          folder_ids: folder_ids,
          folder_name: folder_name,
          is_public: is_public,
          "playlist_id": id,
          "track_to_be_mapped": [],
        },
        {
          headers: headers
        }
      ).pipe(map((res) => new Playlist(res)));
    }
  
  }
  createPlaylist1(formData: FormData): Observable<any> {
    let headers = new HttpHeaders();
    console.log(this.userData)
    headers = headers.append("Content-Type", "application/json");
      return this.http.post<any>(
        environment.apiURL +  '/api/v1/admin/playlists/',
        formData, { observe: 'events', reportProgress: true }
        
      ).pipe(map((res) => new Playlist(res)));  
  }
  updatePlaylist(formData: FormData, id: any): Observable<any> {
    
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.put<any>(
      environment.apiURL + '/api/v1/admin/playlists/' + id + '/',
      formData,
      {
        headers: headers
      }
    ).pipe(map((res) => new Playlist(res)));
  }

  deletePlaylist(playlistId: number): Observable<void> {
    let headers = new HttpHeaders();
    return this.http.delete<any>(
      environment.apiURL + '/api/v1/admin/playlists/' + playlistId + '/',
      {
        headers: headers
      }
    );
  }
  getAddsongtoplaylist(search:any='', limit:any='') : Observable<any>{
    let headers = new HttpHeaders();
    let url =''
    if(search !==''){
      url =  `/api/v1/admin/playlists/dropdown/?search=` +search
    }
    else{
      url =  `/api/v1/admin/playlists/dropdown/`
    }
    if (limit !== "") {
      url =  `/api/v1/admin/playlists/dropdown/?page=${limit}`
    }
    else{
      url =  `/api/v1/admin/playlists/dropdown/`
    }
    headers = headers.append("Content-Type", "application/json");
    return this.http.get<Object>(
      environment.apiURL +
        url,
      {
        headers: headers,
      }
    );
   
  }
  getAddsongtoplaylist1(search:any='', limit) : Observable<any>{
    let headers = new HttpHeaders();
    let url =''
    if(search !==''){
      url =  `/api/v1/admin/playlists/dropdown/?search=` +search
    }
    else{
      url =  `/api/v1/admin/playlists/dropdown/?is_public=true&page=${limit}`
    }
    headers = headers.append("Content-Type", "application/json");
    return this.http.get<Object>(
      environment.apiURL +
        url,
      {
        headers: headers,
      }
    );
   
  }
  getTopTrackPlaylist(id:any) {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.get<Object>(
      environment.apiURL +
        `/api/v1/track/`+ id +`/`,
      {
        headers: headers,
      }
    );
  }
  


  
  getassignedplaylistfortrack(trackversion_id:any) {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.get<Object>(
      environment.apiURL +
        `/api/v1/admin/playlists/`+
        
        trackversion_id
        +`/assigned-playlist-for-track/`,      
      {
        headers: headers,
      }
    );
  }


  addTrackVersionsToPlaylist(trackId: any, playlistIds: number[]): Observable<Playlist> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.put<any>(
      environment.apiURL + '/api/v1/admin/playlists/' + trackId + '/assign-playlist/',
      {
        playlist_ids: playlistIds
      },
      {
        headers: headers
      }
    ).pipe(map((res) => new Playlist(res)));
  }

  addMusicTrackVersionsToPlaylist(trackId: any, playlistIds: number[], trackVersion:number[], suggestion:string[], remove:number[]): Observable<Playlist> {
  let headers = new HttpHeaders();
  headers = headers.append("Content-Type", "application/json");
  return this.http.put<any>(
    environment.apiURL + '/api/v1/admin/playlists/' + trackId + '/assign-playlist-track/',
    {
      playlist_ids: playlistIds,
      track_version_ids:trackVersion,
      new_playlist_name: suggestion,
      is_public: true,
      remove_playlist:remove
    //  top_version:toptrack
    },
    {
      headers: headers
    }
  ).pipe(map((res) => new Playlist(res)));
}
  
  updateAssigntracksFromPlaylist(playlistId: number, trackVersionIds: any): Observable<Track> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiURL + '/api/v1/admin/playlists/' + playlistId + '/assign-tracks/',
      trackVersionIds,
      {
        headers: headers
      }
    ).pipe(map((res) => new Track(res)));
  }
  updateAssignClientsFromPlaylist(playlistId: number, addedIds: number[], removedIds: number[]): Observable<Track> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiURL + '/api/v1/admin/playlists/' + playlistId + '/assign-clients/',
      {
        remove_ids: removedIds,
        new_ids: addedIds
      },
      {
        headers: headers
      }
    ).pipe(map((res) => new Track(res)));
  }
  downloadPlaylist(playlistId: number): Observable<{download_url:string}> {
    let headers = new HttpHeaders();
    return this.http.get<any>(
      environment.apiURL + '/api/v1/admin/playlists/' + playlistId + '/download/',
      {
        headers:headers
      }
    ).pipe(map((res)=>{
      return res;
    }));
  }

  getAllClients(params: {[param: string]: string | string[]}): Observable<APIListResponse<PlaylistClient>> {
    var searchString = params['search'] ? params['search'] : '';
    let p = new HttpParams({ fromObject: params });
    // Check if playlists are cached from server
    const PLAYLIST_CLIENTS_KEY = makeStateKey<APIListResponse<Object>>('playlists-' + p.toString());
    if (this._transferState.hasKey(PLAYLIST_CLIENTS_KEY)) {
      const clients = this._transferState.get<APIListResponse<Object>>(PLAYLIST_CLIENTS_KEY, null);
      this._transferState.remove(PLAYLIST_CLIENTS_KEY);
      return of({
        next: clients.next,
        previous: clients.previous,
        count: clients.count,
        results: clients.results.map((c) => new PlaylistClient(c))
      });
    }
    let headers = new HttpHeaders();
    return this.http.get<APIListResponse>(
      environment.apiURL + '/api/v1/admin/playlists/available-clients/?search=' + searchString,
      {
        headers: headers,
     //   params: params
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the moods
        if (!this._platform.isBrowser) {
          this._transferState.set(PLAYLIST_CLIENTS_KEY, res);
        }
      }),
      map((res) => {
        return {
          next: res.next,
          previous: res.previous,
          count: res.count,
          results: res.results.map((c) => new PlaylistClient(c))
        };
      })
    );
  }

  optionsToParams(options: GetPlaylistsOptions) {
    let params: { [param: string]: string | string[]; } = {
    };
    console.log('print-parsm---',options)
    if (options) {
      // if (options.ordering_by) {
      //   params['ordering_by'] = options.ordering_by;
      // } else {
      //   params['ordering_by'] = '-created_at';
      // }
      if (options.search) {
        params['search'] = options.search;
      }
      if (options.startDate) {
        //alert("date before====="+options.startDate)
        const format = 'yyyy-MM-dd';
        const locale = 'en-US';
        const formattedDate = formatDate(options.startDate, format, locale);
        console.log(formattedDate)
        params['start_date'] = formattedDate;
      }
      if (options.endDate) {
        const format = 'yyyy-MM-dd';
    const locale = 'en-US';
    const formattedDate = formatDate(options.endDate, format, locale);
    console.log(formattedDate)
        params['end_date'] = formattedDate;
      }
      if (options.offset) {
        params['offset'] = `${options.offset}`;
        
      }
      if (options.limit) {
        params['limit'] = `${options.limit}`;
      }
      if (options.containsTrackVersionId) {
        params['contains_track_version_id'] = `${options.containsTrackVersionId}`;
      }
      if (options.includeComposerCount) {
      //  params['include_composer_count'] = `${options.includeComposerCount ? 'true':'false'}`;
      }
      if (options.createdBy) {
        params['created_by'] = `${options.createdBy}`;
      }
      if (options.sharedWith) {
        params['shared_with'] = `${options.sharedWith}`;
      }
      if(options.page){
        params['page']= `${options.page}` 
      }
    }
    return params
  }

  private getPlaylists(params: {[param: string]: string | string[]}): Observable<APIListResponse<Playlist>> {
    let p = new HttpParams({ fromObject: params });
    // Check if playlists are cached from server
    const PLAYLISTS_KEY = makeStateKey<APIListResponse<Object>>('playlists-' + p.toString());
    if (this._transferState.hasKey(PLAYLISTS_KEY)) {
      const playlists = this._transferState.get<APIListResponse<Object>>(PLAYLISTS_KEY, null);
      this._transferState.remove(PLAYLISTS_KEY);
      return of({
        next: playlists.next,
        previous: playlists.previous,
        count: playlists.count,
        results: playlists.results.map((p) => new Playlist(p))
      });
    }
    let headers = new HttpHeaders();
    let url;
   
    url = params.start_date ? environment.apiURL + '/api/v1/admin/playlists/date-filter/': environment.apiURL + '/api/v1/admin/playlists/'
       if(params.contains_track_version_id){
         url =
           environment.apiURL +
           `/api/v1/admin/playlists/${params.contains_track_version_id}/get-assigned-playlist/`;
         params = {}  

         return this.http
           .get<Object>(url, {
             headers: headers,
             params: params,
           })
           .pipe(
             tap((res) => {
               // If we're on the server cache the moods
               if (!this._platform.isBrowser) {
                 this._transferState.set(PLAYLISTS_KEY, res);
               }
             }),
             map((res :any) => {
               return {
                 next: res.next,
                 previous: res.previous,
                 count: res.count,
                 results: res.map((p) => new Playlist(p)),
               };
             })
           );

       }else{
           return this.http
             .get<APIListResponse>(url, {
               headers: headers,
               params: params,
             })
             .pipe(
               tap((res) => {
                 // If we're on the server cache the moods
                 if (!this._platform.isBrowser) {
                   this._transferState.set(PLAYLISTS_KEY, res);
                 }
               }),
               map((res) => {
                 return {
                   next: res.next,
                   previous: res.previous,
                   count: res.count,
                   results: res.results.map((p) => new Playlist(p)),
                 };
               })
             );
       }
       
     
    
  }
}
