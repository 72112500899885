import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { Observable, of } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DialogService } from '../../shared/services/dialog.service';
import { AddGenreDialogueComponent } from '../add-genre-dialogue/add-genre-dialogue.component';
import { EditGenreDialogueComponent } from '../edit-genre-dialogue/edit-genre-dialogue.component';
import { RemoveGenreDialogueComponent } from '../remove-genre-dialogue/remove-genre-dialogue.component';
import { AddSubgenreDialogueComponent } from '../add-subgenre-dialogue/add-subgenre-dialogue.component';
import { EditSubgenreDialogueComponent } from '../edit-subgenre-dialogue/edit-subgenre-dialogue.component';
import { RemoveSubgenreDialogueComponent } from '../remove-subgenre-dialogue/remove-subgenre-dialogue.component';
import { AddEditMoodsDialogueComponent } from '../add-edit-moods-dialogue/add-edit-moods-dialogue.component';
import { RemoveMoodsDialogueComponent } from '../remove-moods-dialogue/remove-moods-dialogue.component';
import { AddProDialogueComponent } from '../add-pro-dialogue/add-pro-dialogue.component';
import { EditProDialogueComponent } from '../edit-pro-dialogue/edit-pro-dialogue.component';
import { MenuListService } from '../../shared/services/menu-list.service';
import { ThesaurusComponent } from '../thesaurus/thesaurus.component';
import { ThesaurusDeleteDialogueComponent } from '../thesaurus-delete-dialogue/thesaurus-delete-dialogue.component';
import { ThesaurusEditDialogueComponent } from '../thesaurus-edit-dialogue/thesaurus-edit-dialogue.component';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { environment } from '../../../environments/environment';
import {MatSort} from '@angular/material/sort';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import {MatTableDataSource} from '@angular/material/table';
import { MatSortable } from '@angular/material/sort';
const listPageSize = 25;
@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', overflow: 'hidden' })),
      state('expanded', style({ height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DataComponent implements OnInit {
  displayedColumns = ['position', 'name', 'weight'];
  dataSource = new ExampleDataSource();
  
  displayedColumns1 = ['pro', 'name', 'weight'];
  dataSource1 = new ExampleDataSource1();

  displayedColumns2 = ['position'];
  displayedColumns3 = ['name', 'created_at', 'updated_at','action'];
  

  dataSource2 = ELEMENT_DATA2;
  loading: boolean = false;
  tabFlag:string = "0"
  genreCount: any = 0;
  currentPage: number = 1;
  isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');
  expandedElement: any;
  statusOpen = false;
  ordering: any = 'asc';
  prosPage: number = 1;
  constructor(private _router: Router, private _activatedRoute: ActivatedRoute,public dialog: DialogService, public list: MenuListService,  public api: UserMenuService, public config: MenuConfigService, private sortTb: ChangeDetectorRef) { 
    this.list.getAllGenres();
    this.list.getAllPROS();
    this.list.getAllMOODS();
    this.list.getAllthesaurus();
    this.genreCount = this.list.AllGenresList.count
    //console.log(this.genreCount)
  }

  @ViewChild(MatSort) sort:MatSort;
/*   @ViewChild(MatSort, { static: false })
  set sort(pros: MatSort) {
    if(this.tabFlag === '2'){
      this.list.dataSourceForPROs.sort = pros;  
    }else if(this.tabFlag === '3'){      
      this.list.dataSourceForThesaurus.sort = pros;
    }
    console.log(this.list.dataSourceForPROs)
  } */
  private _currentGenresPage = 1;
  set currentGenresPage(p: number) {
    if (!isNaN(p) && p != this._currentGenresPage) {
      this._currentGenresPage = p;
      if(this.tabFlag === '0'){
        this.list.getAllGenres();
      }else if(this.tabFlag === '2'){
        this.list.getAllPROS();
      }else if(this.tabFlag === '3'){
        this.list.getAllthesaurus();
      }
    }
  }
  get currentGenresPage(): number {
    return this._currentGenresPage;
  }
  get genreNumberOfPages(): number {
    if(this.tabFlag === '0'){
      return Math.ceil(this.list.AllGenresList.count / listPageSize);
    }else if(this.tabFlag === '2'){
      return Math.ceil(this.list.allPROSDetails.count / listPageSize);
    }else if(this.tabFlag === '3'){
      return Math.ceil(this.list.allthesaurusList.count / listPageSize);
    }
    
  }
  ngOnInit(): void {
    this.selectedLet('All')
  }
  
  ngAfterContentChecked(): void{
    this.list.dataSourceForPROs = new MatTableDataSource(this.list.allPROS);
    this.list.dataSourceForThesaurus = new MatTableDataSource(this.list.allthesaurus); 
    this.sortTb.detectChanges();
    if(this.tabFlag === '2'){
      this.list.dataSourceForPROs.sort = this.sort;  
    }else if(this.tabFlag === '3'){      
      this.list.dataSourceForThesaurus.sort = this.sort;
    }
  }
  pageChange(page:number) {
  let url;
  if(page!=1){
    url = environment.apiURL + this.config.doGenres+'?page='+page;
  }else{
    url = environment.apiURL + this.config.doGenres;
  }
  
    this.api.doGET(url).subscribe(x=>{
      const rows = [];
      this.list.AllGenresList = x; 
      x['results'].forEach(element => rows.push(element, { detailRow: true, element }));
        this.list.AllGenres = rows || [];
      },
      (error)=>{
      })
  }
  
  pageChangePROS(page:number) {
    let url;
    this.prosPage = page;
    if(page!=1){
      url = environment.apiURL + this.config.getAllPROSlist+'?page='+page;
    }else{
      url = environment.apiURL + this.config.getAllPROSlist;
    }
    
      this.api.doGET(url).subscribe(x=>{
        this.list.allPROSDetails = x;
         this.list.allPROS = x['results']; 
        /*  this.list.dataSourceForPROs = new MatTableDataSource(this.list.allPROS);
        setTimeout(()=>{
          this.list.dataSourceForPROs.sort = this.sort 
          console.log(this.list.dataSourceForPROs)
        }, 1000); */
        
        },
        (error)=>{
        })
    }
    pageChangeThesaurus(page:number) {
      let url;
      if(page!=1){
        url = environment.apiURL + this.config.doThesaurus+'?page='+page;
      }else{
        url = environment.apiURL + this.config.doThesaurus;
      }
      
        this.api.doGET(url).subscribe(x=>{
          this.list.allthesaurusList = x;
           this.list.allthesaurus = x['results']; 
         /*  this.list.dataSourceForThesaurus = new MatTableDataSource(this.list.allthesaurus); 
          setTimeout(()=>{
            this.list.dataSourceForThesaurus.sort = this.sort 
            console.log(this.list.dataSourceForThesaurus)
          }, 1000); */
          },
          (error)=>{
          })
      }
  selectedLet(letters){
    if(letters == 'All'){
      this.list.currentLetter = ''
    }else{
      this.list.currentLetter = letters;
    }
    this.loading = true;
      let getletterWise = {
        'search_letter': this.list.currentLetter
      }
      let requestURL = environment.apiURL + this.config.getAlbhaticalMoods;
      this.api.doPOST(requestURL, getletterWise).subscribe((result: any) => {
        this.loading = false;
        this.list.allMOODS.results = result;
      },
      (error) => {
        this.loading = false;
        //alert(error.message)
      });
  }
  rowClicked(row){
    if(this.statusOpen == false){
      this.expandedElement = row;
      this.statusOpen = true;
    }else if(this.statusOpen = true){
      this.expandedElement = null;
      this.statusOpen = false;
    }
  }
  genreTab(){
  this.tabFlag = '0'
  }
  moodsTab(){
    this.tabFlag = '1'
  }
prosTab(){
  this.tabFlag = '2'
}
thesaurusTab(){
  this.tabFlag = '3'
 
}

sortChange(data) {
  console.log(data)
  if(data.active === 'pro' && data._direction === 'asc' || data._direction === ''){
    this.ordering = 'pro'
  }else if(data.active === 'pro' && data._direction === 'desc'){
    this.ordering = '-pro'
  }
  if(data.active === 'name' && data._direction === 'asc' || (data.active === 'name'&& data._direction === '')){
    this.ordering = 'publisher__name'
  }else if(data.active === 'name' && data._direction === 'desc'){
    this.ordering = '-publisher__name'
  }
  this.loading = true;
    let requestUrl = environment.apiURL +  this.config.getAllPROSlist+'?ordering='+this.ordering+'&page='+this.prosPage;
    this.api.doGET(requestUrl).subscribe(x =>{
      this.list.allPROSDetails = x;
      this.list.allPROS = x['results']; 
      this.list.dataSourceForPROs = new MatTableDataSource(this.list.allPROS); 
      this.loading = false
    })
}

  addGenre(){
    
    this.dialog.displayMenuDialog(AddGenreDialogueComponent)
  }
  EditGenre(){
    
    this.dialog.displayMenuDialog(EditGenreDialogueComponent)
  }
  deleteGenre(){
    
    this.dialog.displayMenuDialog(RemoveGenreDialogueComponent)
  }

  addSubGenre(){
    
    this.dialog.displayMenuDialog(AddSubgenreDialogueComponent)
  }
  EditSubGenre(){
    
    this.dialog.displayMenuDialog(EditSubgenreDialogueComponent)
  }
  deleteSubGenre(){
    
    this.dialog.displayMenuDialog(RemoveSubgenreDialogueComponent)
  }

  addMoods(){
    this.dialog.displayMenuDialog(AddEditMoodsDialogueComponent)
  }
  EditMoods(){
    
    this.dialog.displayMenuDialog(AddEditMoodsDialogueComponent)
  }
  deleteMoods(){
    this.dialog.displayMenuDialog(RemoveMoodsDialogueComponent)
  }


  addPro(){
    
    this.dialog.displayMenuDialog(AddProDialogueComponent)
  }
  editPro(){
    
    this.dialog.displayMenuDialog(EditProDialogueComponent)
  }
  addThesaurus(){
    
    this.dialog.displayMenuDialog(ThesaurusComponent)
  }
  deleteThesaurus(){
    this.dialog.displayMenuDialog(ThesaurusDeleteDialogueComponent)
  }
  editThesaurus(){
    this.dialog.displayMenuDialog(ThesaurusEditDialogueComponent)
  }
}
export interface Element {
  name: string;
  position: string;
  weight: string;
  symbol: string;
}

const data: Element[] = [
  { position: 'Blues', name: '5 Subgenres', weight: 'abc', symbol: 'H' },
  { position: 'Bumpers and stings', name: '5 Subgenres', weight: 'abc', symbol: 'H' },
  
];
export class ExampleDataSource extends DataSource<any> {
  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<Element[]> {
    const rows = [];
    data.forEach(element => rows.push(element, { detailRow: true, element }));
    console.log(rows);
    return of(rows);
  }

  disconnect() { }
}

export class ExampleDataSource1 extends DataSource<any> {
  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<Element[]> {
    const rowsPROS = [];
    data.forEach(element => rowsPROS.push(element));
    console.log(rowsPROS);
    return of(rowsPROS);
  }

  disconnect() { }
}
export interface PeriodicElement {
  Size: string;
  position: string;
  weight: string;
  
}

const ELEMENT_DATA1: PeriodicElement[] = [
  {position: 'Throwback Fusion Stinger.wav', Size: '3124kb', weight: 'wav', },
  {position: 'Throwback Fusion Stinger.wav', Size: '3124kb', weight: 'wav', },
  {position: 'Throwback Fusion Stinger.wav', Size: '3124kb', weight: 'wav', },
  
 
];
export interface PeriodicElement {
  Size: string;
  position: string;
  weight: string;
  
}

const ELEMENT_DATA2: PeriodicElement[] = [
  {position: 'Throwback Fusion Stinger.wav', Size: '3124kb', weight: 'wav', },
  {position: 'Throwback Fusion Stinger.wav', Size: '3124kb', weight: 'wav', },
  {position: 'Throwback Fusion Stinger.wav', Size: '3124kb', weight: 'wav', },
  
 
];