<div class="dialog-container" >
  <div class="dialog-header" *ngIf="_emailTemplateService.templatetype != 'edit'">
    <h4 class="dialog-heading" *ngIf="_emailTemplateService.templatetype == 'new'">New Template</h4>
    <h4 class="dialog-heading" *ngIf="_emailTemplateService.templatetype == 'duplicate'">Duplicate Template</h4>
    <button type="button" class="close-button" (click)="close()">
      <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
          stroke-linejoin="round">
          <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
            <g id="Genre-Options">
              <g id="Close" transform="translate(1439.000000, 32.000000)">
                <path d="M24,0 L0,24" id="Path"></path>
                <path d="M0,0 L24,24" id="Path"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </button>
  </div>
<!-- duplicate -->
  <mat-dialog-content *ngIf="_emailTemplateService.templatetype != 'edit'" class="p-25">
    <app-loader *ngIf="loading" color="#50e3c2"></app-loader>
    <div class="dialog-body" *ngIf="!loading">
      <div class="form-row green-theme edit-track">
          <mat-radio-group [(ngModel)]="_emailTemplateService.usertype" (change)="radioChange($event)">
              <mat-radio-button name="type" class="checkbox-margin" [checked]="_emailTemplateService.usertype=='CLIENT'" [value]="'CLIENT'">Client</mat-radio-button>
              <mat-radio-button name="type" class="checkbox-margin" [checked]="_emailTemplateService.usertype=='ARTIST'" [value]="'ARTIST'">Artist</mat-radio-button>
          </mat-radio-group>
      </div>
      <div class="form-container new-email">
          <mat-form-field class="dialog">
              <mat-label>Template Name</mat-label>
              <input *ngIf="_emailTemplateService.templatetype == 'new'" matInput (input)="getmailValue($event.target.value)">
              <input *ngIf="_emailTemplateService.templatetype == 'duplicate'" [(ngModel)]="template.name" matInput>
          </mat-form-field>
      </div>
      <p class="note">Please make title as short and descriptive as possible</p>
      <p class="error" *ngIf="error">{{error}}</p>
      <div class="form-col">
        <button class="form-button" *ngIf="_emailTemplateService.templatetype == 'duplicate'" (click)="create()" [disabled]="!canCreate('duplicate')">Duplicate</button>
        <button class="form-button" (click)="create()" *ngIf="_emailTemplateService.templatetype == 'new'"[disabled]="!canCreate('new')" >Create</button>
        <button class="form-button form-button-cancel" (click)="close()">Cancel</button>
      </div>
    </div>
  </mat-dialog-content>
<!-- create -->
  <mat-dialog-content *ngIf="_emailTemplateService.templatetype == 'edit'" style="max-height:90vh;">
    <app-loader *ngIf="loading" color="#50e3c2"></app-loader>
    <div class="dialog-body" *ngIf="!loading">
      
      <div class="section general">

       <div class="grid-card">
            <div class="grid-item">
              <mat-form-field appearance="fill">
                <mat-label>New Template Name</mat-label>
                <input matInput *ngIf="_emailTemplateService.templatetype != 'edit'">
                <input matInput *ngIf="_emailTemplateService.templatetype == 'edit'" [(ngModel)]="template.name">
                <input matInput *ngIf="_emailTemplateService.templatetype == 'duplicate'" [(ngModel)]="template.name">
                <mat-checkbox   [ngClass]="{'disabledElement': (name?.trim() == template?.name?.trim())}" class="chk-position" [(ngModel)]="saveTemplate" (change)= changeTemplate(saveTemplate)>
                  <span class="check-text ">Save as new template</span>
              </mat-checkbox>
            </mat-form-field>

            
            </div>
        </div>
        <div class="grid-card">
            <div class="grid-item">
                <mat-form-field appearance="fill">
                  <mat-label>From</mat-label>
                    <mat-select  *ngIf="_emailTemplateService.templatetype != 'edit'" panelClass="templatefrompanel" [disableOptionCentering]="true">
                        
                    </mat-select>
                    <mat-select *ngIf="_emailTemplateService.templatetype == 'edit'" panelClass="templatefrompanel" [disableOptionCentering]="true" [(ngModel)]="formEmail" (selectionChange)="getFromEmail($event)">
                      <!-- <mat-option *ngFor="let user of userDetails" [disabled]="user.id == 2 ||  user.id == 3 || user.id == 4 " [value]="user.email">{{user.email}}</mat-option>  -->
                      <mat-option *ngFor="let user of userDetails"[value]="user.email">{{user.email}}</mat-option> 

                    </mat-select>
                    <!-- <input matInput  [(ngModel)]="formEmail" style="color: black;" [disabled]='disabled'> -->
                </mat-form-field>
            </div>
        </div>
        <div class="grid-container">

            <div class="grid-item">
              <mat-form-field appearance="fill" floatLabel="always">
                <mat-label>To(Group)</mat-label>                
                <mat-select  *ngIf="_emailTemplateService.templatetype != 'edit'" multiple placeholder="Select">
                  <mat-option></mat-option> 
                </mat-select>
                <mat-select *ngIf="_emailTemplateService.templatetype == 'edit'" panelClass="templatepanel" [disableOptionCentering]="true" placeholder="Select" [(ngModel)]="groupId"  multiple (selectionChange)="getGroupEmail($event.value,'select')">
                  <!-- <mat-option *ngFor="let groups of groupsToDetails" [value]="groups.id"> -->
                  <mat-option>
                    <ngx-mat-select-search
                    [searching]="searchingGroup"
                    [formControl]="toGroupCtrl"
                      placeholderLabel="Select Group"
                      noEntriesFoundLabel="No Options Found">
                      <svg ngxMatSelectSearchClear class="filter-clear-icon" width="26px" height="24px" viewBox="0 0 26 24"
                        version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                          stroke-linejoin="round">
                          <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                            <g id="Genre-Options">
                              <g id="Close" transform="translate(1439.000000, 32.000000)">
                                <path d="M24,0 L0,24" id="Path"></path>
                                <path d="M0,0 L24,24" id="Path"></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let groups of groupDetails | async" [value]="groups.id">
                    <span *ngIf="type === 'CLIENT'">{{groups.name}}</span>
                    <span *ngIf="type === 'ARTIST'">{{groups.created_at | date: 'shortDate'}} - {{groups.name}}</span>
                  </mat-option>
                </mat-select>
                <p *ngIf="errorValue !== ''" style="
                color: red;
    font-size: small;
    margin-left: 2px;
    margin-top: 1%;
    margin-bottom: -8%;">{{errorValue}}</p>
               
            </mat-form-field>
            </div>
            <div class="grid-item">
                <mat-form-field appearance="fill">
                    <mat-label>To(single  Individual)</mat-label>
                    <input matInput *ngIf="_emailTemplateService.templatetype != 'edit'">
                    <input type="email" matInput *ngIf="_emailTemplateService.templatetype == 'edit'"  [(ngModel)]="templatedetails.receiver"   (input)="getIndividual($event.target.value)" required>
                </mat-form-field>
            </div>
        </div>
        <div class="grid-container">

          <div class="grid-item">
            <mat-form-field appearance="fill">
              <mat-label>Add (cc)</mat-label>
              <input type="email" *ngIf="_emailTemplateService.templatetype != 'edit'">
              <input  type="email" matInput *ngIf="_emailTemplateService.templatetype == 'edit'" [(ngModel)]="templatedetails.cc_email"  (input)="getCCMail($event.target.value)">
          </mat-form-field>
          </div>
          <div class="grid-item">
              <mat-form-field appearance="fill">
                  <mat-label>Add (BCC)</mat-label>
                  <input  type="email" matInput *ngIf="_emailTemplateService.templatetype != 'edit'">
                  <input  type="email" matInput *ngIf="_emailTemplateService.templatetype == 'edit'" [(ngModel)]="templatedetails.bcc_email" (input)="getBCCMail($event.target.value)">
              </mat-form-field>
          </div>
      </div>
        
        <div class="grid-card">
            <div class="grid-item">
                <mat-form-field appearance="fill">
                    <mat-label>Subject</mat-label>
                    <input matInput *ngIf="_emailTemplateService.templatetype != 'edit'">
                    <input matInput *ngIf="_emailTemplateService.templatetype == 'edit'" [(ngModel)]="templatedetails.subject">
                </mat-form-field>
            </div>
        </div>
        <div class="title">
          <h6>Message</h6>
        </div>
        
        <div class="grid-card">
          <div class="grid-item">
            <!-- <textarea placeholder="Write a message" #commentInput *ngIf="_emailTemplateService.templatetype == 'edit'" [(ngModel)]="templatedetails.body" style="border: 1px solid #ccc; width:100%;border-radius: 5px;padding: 10px;
            margin-bottom: 20px; background: #fff; flex-grow: 1; resize: vertical;"  rows="5" [ngClass]="{'w-100' : text?.length > 25}"></textarea> -->
            <ckeditor [editor]="Editor" 
            [config]="editorConfig"
            #commentInput 
            *ngIf="_emailTemplateService.templatetype == 'edit'" 
            [(ngModel)]="templatedetails.body" 
            rows="5" 
            [ngClass]="{'w-100' : text?.length > 25}"
            (ready)="onReady($event)"></ckeditor>
                 
                  <!-- <textarea  class="temp-box" placeholder="Write a Message" *ngIf="_emailTemplateService.templatetype != 'edit'"></textarea>
                  <textarea  class="temp-box" *ngIf="_emailTemplateService.templatetype == 'edit'" [(ngModel)]="templatedetails.body"></textarea> -->
             
              <p class="note">Use {{NickName}} to insert clients nickname into message</p>
              <p class="note">Use {{FirstName}} to insert clients firstname into message</p>
          </div>
      </div>
      <div class="grid-card">
        <div class="grid-item grid-format">
          <p style="font-size:12px;"><strong style="text-transform: uppercase;font-weight: 800;
            font-size: 13px;">Email Formatting Controls: &nbsp;&nbsp;</strong>Add files (Wav, jpg, png, mp3, etc.) Text Style, Color, Alignment …</p>
          </div>
          </div>
          <div class="playlistorder">
            <mat-dialog-content style="max-height:100% !important">
                <div class="dialog-body">

                    <div class="add-to-folder form-container">
                        <h6 class="label">Add playlist<span class="deselect" ><small> {{this.playlistArray.length}} Playlists </small></span></h6>
                        <span></span>

                        <input class="search txt" placeholder="Find a playlist" [(ngModel)]="searchString" name="searchString" type="text"
                          id="search-filter-field" (focus)="searchFilterFocused = true"
                          (keyup)="$event.preventDefault();$event.stopPropagation();submitSearch($event.target.value)">

                        <div class="container-space">
                        <div class="container-playlist">
                        <ul #scrollMe id="checkBoxUl" (scroll)="scrollHandler($event)" class="list">
                          <li *ngFor="let playlist of playlistArray; let idx = index" style="display: flex;">
                             <div style="flex:0 0 58%;"><mat-checkbox  [checked]= "playlist.checked" (change)="mySelectOpt(playlist, $event, idx)">
                              <span class="check-text">{{playlist.created_at | date: 'shortDate'}} - {{playlist.name}}</span>
                          </mat-checkbox></div> 
                              
                            <div style="flex:0 0 30%;"> <mat-checkbox (change)="selectedRecipient(playlist.id, $event, idx)">
                              <span class="check-text">Add to recipients account</span>
                          </mat-checkbox></div> 
                            <span class="check-text recipent-list"  (click)="copyLink(playlist)"> <img class="uploadwidth" src="assets/img/link-solid.svg"> Copy link</span>
                          </li>
                          
                          <button *ngIf="!searchString || searchResultsCount > playlistArray.length" class="btn save-btn" style="padding: 0px;margin-top:10px;
                          width: 100px;
                          height: 25px;" (click)="loadMore()">Load More</button>
                      </ul></div>
                      <!-- <div class="container-recipient">
                        <ul class="list">
                          <li *ngFor="let playlist of playlistArray; let idx = index">
                            <mat-checkbox (change)="selectedRecipient(playlist.id, $event, idx)">
                              <span class="check-text">Add to recipients account</span>
                          </mat-checkbox>
                          <span class="check-text recipent-list"  (click)="copyLink(playlist)"> <img class="uploadwidth" src="assets/img/link-solid.svg"> Copy link</span>
                          </li>
                      </ul>
                      </div> -->
                    </div>
                    </div>
                   
                    <!-- <mat-checkbox >
                        <span  class="check-text">Select All</span>
                    </mat-checkbox> -->

                </div>
            </mat-dialog-content>
            <p class="error" style="color: red;text-align: center;" *ngIf="emailValidation">Please enter valid email</p>
        </div>
        <button *ngIf="!saveTemplate" class="btn save-btn" mat-button (click)="saveAndSend('send')" [disabled]="!canSave()">Send Email</button>
        <button *ngIf="saveTemplate" class="btn save-btn" mat-button (click)="saveAndSend(templatedetails)" [disabled]="!canSave()">Save & Send Email</button>
    </div>
    
    </div>
  </mat-dialog-content>
</div>

<div class="loading-overlay" *ngIf="loadingUserUpdate">
  <app-loader></app-loader>
</div>