import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  public activeDownloads = 0;

  get downloadInProgress(): boolean {
    return this.activeDownloads > 0;
  }

  constructor() { }

  startDownload(): void {
    this.activeDownloads++;
  }

  endDownload(): void {
    if (this.activeDownloads > 0) {
      this.activeDownloads--;
    }
  }
}
