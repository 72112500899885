import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpEventType, HttpResponse } from '@angular/common/http';
import { FormControl, FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SliderDialogService } from 'src/app/shared/services/slider-dialog.service';
import { MenuListService } from 'src/app/shared/services/menu-list.service';
import { Router } from '@angular/router';
import { ComposerAlertComponent } from 'src/app/composer-alert/composer-alert.component';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
  selector: 'app-upload-track-pop',
  templateUrl: './upload-track-pop.component.html',
  styleUrls: ['./upload-track-pop.component.scss']
})
export class UploadTrackPopComponent implements OnInit {
  uploadForm: FormGroup;
  percentCompleted: number = 0;
  isMultipleUploaded = false;
  isSingleUploaded = false;
  urlAfterUpload = '';
  percentUploaded = [];
  selectedFiles: any = [];
  progressInfos = [];
  message = '';
  isUploaded = false;
  isBrowser= false;
  isUploading = [];
  isFailed = [];
  File:any;
  isContinue:boolean= false;

  fileInfos: Observable<any>;
  acceptedExtensions = "wav, mp4";
  @ViewChild('fileInput')myInputVariable: ElementRef;

  constructor(
    public formBuilder: FormBuilder,
    private http: HttpClient,
    private _sliderDialogService: SliderDialogService,  private dialog: DialogService,public _DialogService:DialogService,
    public list: MenuListService,private _router: Router,
    ) { }

  ngOnInit(): void {
    this.uploadForm = this.formBuilder.group({
      name: ['', Validators.required],
      filesToUpload: this.formBuilder.array([
      this.formBuilder.control('', [Validators.required]),
    ])
   }); 
  }
  browseTrack(files: File, fileNum: number){
    const formData = new FormData();
    formData.append("file", files);
    this.uploadWithProgress(formData)
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.percentUploaded[fileNum] = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          console.log(files.name + ', Size: ' + files.size + ', Uploaded URL: ' + event.body.link);
          this.fileUploadSuccess();
        }
      });
    
  }

  selectFiles(event) {
 
    const files = event.target.files;
    let isImage = true;
    for (let i = 0; i < files.length; i++) {
      if (files.item(i).type && (files.item(i).type.match('audio/wav') || files.item(i).type.match('audio/x-wav'))) {
        this.selectedFiles.push(files[i]);
        console.log(this.selectedFiles)
       // this.myInputVariable.nativeElement.value = "";
        continue;
      } else {
        isImage = false;
        // alert('invalid format!');
        break;
      }
    }
  }

  removeTrack(index) {
    this.selectedFiles.splice(index, 1);
    this.isFailed.splice(index, 1);
    if (this.myInputVariable &&this.myInputVariable.nativeElement.value) {
      this.myInputVariable.nativeElement.value = ""
    }
    if (this.selectedFiles.length === 0) {
      this.isBrowser = false;
      this.isUploaded = false;
    }
    else{
      this.isUploaded = true;
      this.isContinue = false;
    }
  }

   async uploadFiles(cont=null) {
     var indexUpload 
    if (cont == 'continue') {
      this._sliderDialogService.close(true);
      this.selectedFiles = [];
      this.list.allTrackListEvent.next(true);
      this._router.navigate(['/composer/my-uploads'])
    } else {
      this.message = '';
      for (let i = 0; i < this.selectedFiles.length;i++) {
        var idx = i;
        indexUpload = i
        var file = this.selectedFiles[i];
        await this.upload(i, this.selectedFiles[i]);
       
      }
      if(indexUpload!== this.selectedFiles.length-1)
      {
        this.isUploaded = false;
      }
      else{
        this.isUploaded = true;
      }
    }
  }

   upload(idx, file) {
    this.isUploading[idx] = true;
    this.isBrowser = true;
    const formData = new FormData();
    formData.append("file", file);
    let promise = new Promise<any>((resolve, reject) => {
    this.uploadWithProgress(formData)
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progressInfos[idx] = Math.round(100 * event.loaded / event.total);
          if (this.progressInfos[idx] == 100) {
            setTimeout(()=>{                 
              this.isUploading[idx] = false;
            }, 2000);
          }
        } else if (event instanceof HttpResponse) {
          resolve(event); 
          console.log(file.name + ', Size: ' + file.size + ', Uploaded URL: ' + event.body.link);
        }
      },
      err => {
        this.message = 'Could not upload the file:' + file.name;
        this.isFailed[idx] = true;
        setTimeout(()=>{ 
          if(err.error.details) {
          // alert(err.error.details)
          let data = err.error?.error ? err.error?.error : (err.error?.detail ?err.error?.detail : (err.error?.details ? err.error?.details:``) )
          this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
          });
          }
          else if(err.error && err.error.is_logout){
            this._sliderDialogService.close(false);
            this.selectedFiles = [];
            this.list.allTrackListEvent.next(true);
            this._router.navigate(['/composer/my-uploads'])
            this.dialog.displayMenuDialogAlert(ComposerAlertComponent,false, err.error.error).subscribe(res => {
            })
          }
          else{
            // alert("Kindly check the audio level.  Please contact the MIBE administrator.")
            let data = err.error?.error ? err.error?.error : (err.error?.detail ?err.error?.detail : (err.error?.details ? err.error?.details:``) )
          this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
          });
         
            this.isContinue= true;
          }
        }, 50);
        resolve(true);
      });
    })
      return promise;
  }

  sizeOf = function (bytes) {
    if (bytes == 0) { return "0.00 B"; }
    var e = Math.floor(Math.log(bytes) / Math.log(1024));
    return (bytes/Math.pow(1024, e)).toFixed(2)+' '+' KMGTP'.charAt(e)+'B';
  }

  get filesToUpload(): FormArray {
    return this.uploadForm.get('filesToUpload') as FormArray;
  }
  fileUploadSuccess() {
    let flag = true;
    this.percentUploaded.forEach(n => {
      if (n !== 100) {
        flag = false;
      }
    });
    if (flag) {
      this.isMultipleUploaded = true;
    }
  }
  onFormSubmit() {
    console.log('---Uploading multiple file---');
    this.isMultipleUploaded = false;
    for (let i = 0; i < this.filesToUpload.length && this.uploadForm.valid; i++) {
      const selectedFileList = (<HTMLInputElement>document.getElementById('file' + i)).files;
      const file = selectedFileList.item(0);
      this.browseTrack(file, i);
    }
  }
  uploadWithProgress(formData: FormData): Observable<any> {
    return this.http.post(environment.apiURL + '/api/v1/upload/', formData, { observe: 'events',  reportProgress: true })
        .pipe(
            catchError(err => this.handleError(err))
        );
  }
  private handleError(error: any) {
      return throwError(error);
  }
}
