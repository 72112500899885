<form [formGroup]="addMoodForm">
<div class="dialog-container">
    <div class="dialog-header">
        <h4 *ngIf="list.moodsType === 'Add'" class="dialog-heading">ADD mood</h4>
        <h4 *ngIf="list.moodsType === 'Edit'" class="dialog-heading">EDIT mood</h4>
        <button type="button" class="close-button" (click)="close()">
        <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                    <g id="Genre-Options">
                        <g id="Close" transform="translate(1439.000000, 32.000000)">
                            <path d="M24,0 L0,24" id="Path"></path>
                            <path d="M0,0 L24,24" id="Path"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        </button>
    </div>
<!-- code for add genre -->
     <mat-dialog-content>
        <app-loader *ngIf="loaderContent" color="#50e3c2"></app-loader>
        <div class="dialog-body" *ngIf="!loaderContent">

            <div class="moodHeader" *ngIf="list.moodsType === 'Edit'">{{list.moodName}}</div>
              <div class="grid-card">
                <div class="grid-item">
                    <mat-form-field appearance="fill" class="group">
                        <mat-label>Mood Name</mat-label>
                        <input *ngIf="list.moodsType === 'Add'" matInput formControlName="mood_name">
                        <input *ngIf="list.moodsType === 'Edit'" matInput formControlName="mood_name" [(ngModel)]="list.moodName">
                    </mat-form-field>
                    <p class="note" *ngIf="list.moodsType === 'Add'">Please make title as short and descriptive as possible</p>
                    <mat-form-field appearance="fill" class="group" floatLabel="always">
                      <mat-label>Thesaurus</mat-label>
                      <span *ngIf="thesaurus.length>0" class="status-clear" (click)="moodNumber = false;thesaurus =[] ;moodChanged($event)"
                        class="status-clear">Clear </span>

                      <mat-select panelClass="addeditmoodspanel"  [disableOptionCentering]="true"
                         (selectionChange)="moodChanged($event);" 
                        #mood_select id="mood_select" name="mood" [(value)]="thesaurus" 
                        placeholder="All" multiple>
                    
                        <mat-option *ngFor="let topping of toppingList" [value]="topping.id">{{topping.name}}</mat-option>
                    
                      </mat-select>
                    </mat-form-field>


                      
                </div>
            </div> 
            
            <div class="form-col">
              <button class="form-button" *ngIf="list.moodsType === 'Add'" [disabled]="(addMoodForm.invalid || addMoodForm.value.mood_name.trim() === '')" (click)="createMoods()">create</button>
              <button class="form-button" *ngIf="list.moodsType === 'Edit'" [disabled]="(addMoodForm.invalid || addMoodForm.value.mood_name.trim() === '')" (click)="createMoods()">Save</button>
              <button class="form-button form-button-cancel" (click)="close()">Cancel</button>
            </div>
          </div>
    </mat-dialog-content> 

</div>
</form>
<div class="loading-overlay" *ngIf="loader">
    <app-loader></app-loader>
</div>

