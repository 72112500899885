import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Track } from '../models/track.model';
import { Observable, Subscription, of, Subject, ReplaySubject } from 'rxjs';
import { Mood } from '../models/mood.model';
import { Instrument } from '../models/instrument.model';
import { Vocals } from '../models/vocals.model';
import { SonicReferenceService } from '../services/sonicreference.service';
import { NetReferenceService } from '../services/netreference.service';
import { InstrumentService } from '../services/instrument.service';
import { VocalsService } from '../services/vocals.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { Genre } from '../models/genre.model';
import { startWith, switchMap } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { SonicReference } from '../models/sonicreference.model';
import { NetworkReference } from '../models/networkreference.model';
import { Artist } from '../models/artist.model';
import { BandReference } from '../models/bandreference.model';
import { TvReference } from '../models/tvreference.model';
import { environment } from '../../../environments/environment';
import { UserMenuService } from '../services/user-menu.service';
import { MenuListService } from '../services/menu-list.service';
import { TrackVersion } from '../models/trackversion.model';
import { PlayerService } from '../services/player.service';

@Component({
  selector: 'app-composer-new-version-description',
  templateUrl: './composer-new-version-description.component.html',
  styleUrls: ['./composer-new-version-description.component.scss']
})

export class ComposerNewVersionDescriptionComponent implements OnInit, OnDestroy {
  // set version(v: TrackVersion) {
  //   this._version = v;
  //   this.versionChange.emit(this._version);
  // }
  // get version(): TrackVersion {
  //   return this._version;
  // }
  // @Output()
  // versionChange = new EventEmitter<TrackVersion>();
  _version: any =[];
  enDescription: boolean = false;
  showMetaFor: number = -1;
  instrumentationPanelOpen: boolean;
  tvNetworkPanelOpen: boolean;
  sonicPanelOpen: boolean;
  lyricsPanelOpen: boolean;
  vocalsPanelOpen: boolean;
  sonicUpdate: boolean = false;
  instDesc: any;
  newValue: any =[];
  networkSuggestion : any=[];
  networkDesc: any = [];
  tvDesc: any = []
  tvSuggestion: any[]=[]
  instrumentData:any=[]

  separatorKeysCodes: number[] = [ENTER, COMMA];

  instrumentCtrl = new FormControl();
  filteredInstruments: ReplaySubject<Instrument[]> = new ReplaySubject<Instrument[]>(1);;
  allInstruments: Instrument[] = [];

  networkCtrl = new FormControl();
  filteredNetworks: ReplaySubject<NetworkReference[]> = new ReplaySubject<NetworkReference[]>(1);;
  allNetworks: NetworkReference[] = [];

  sonicCtrl = new FormControl();
  filteredSonics: ReplaySubject<SonicReference[]> = new ReplaySubject<SonicReference[]>(1);;
  allSonics: any[] = [];
  sonicNewCtrl = new FormControl();
  sonicSuggestion: any[]=[];

  vocalCtrl = new FormControl();
  filteredVocals: ReplaySubject<Vocals[]> = new ReplaySubject<Vocals[]>(1);;
  allVocal: Vocals[] = [];

  tvCtrl = new FormControl();
  filteredTV: ReplaySubject<TvReference[]> = new ReplaySubject<TvReference[]>(1);;
  allTV: TvReference[] = [];
  networkNewCtrl = new FormControl();
  tvNewCtrl = new FormControl();
  bandCtrl = new FormControl();
  filteredBands: ReplaySubject<BandReference[]> = new ReplaySubject<BandReference[]>(1);;
  allBands: BandReference[] = [];
  bandNewCtrl= new FormControl();
  bandSuggestion:any[]=[];

  artistCtrl = new FormControl();
  filteredArtists: ReplaySubject<Artist[]> = new ReplaySubject<Artist[]>(1);;
  allArtists: Artist[] = [];

  showVerseField: Boolean = false;
  showPreChorusField: Boolean = false;
  showChorusField: Boolean = false;
  showBridgeField: Boolean = false;

  @ViewChild('instrumentInput') instrumentInput: ElementRef<HTMLInputElement>;
  @ViewChild('networkInput') networkInput: ElementRef<HTMLInputElement>;
  @ViewChild('sonicInput') sonicInput: ElementRef<HTMLInputElement>;
  @ViewChild('vocalInput') vocalInput: ElementRef<HTMLInputElement>;
  @ViewChild('tvInput') tvInput: ElementRef<HTMLInputElement>;
  @ViewChild('artistInput') artistInput: ElementRef<HTMLInputElement>;
  @ViewChild('bandInput') bandInput: ElementRef<HTMLInputElement>;
  @ViewChild("instrumentsPanel") instrumentsPanel: any;
  @ViewChild("networkPanel") networkPanel: any;
  @ViewChild("sonicPanel") sonicPanel: any;
  @ViewChild("lyricsPanel") lyricsPanel: any;
  @ViewChild("vocalsPanel") vocalsPanel: any;
  @ViewChild('instrumentInput', { read: MatAutocompleteTrigger })
  autoComplete: MatAutocompleteTrigger;
  @ViewChild('networkInput', { read: MatAutocompleteTrigger })
  autoComplete1: any;
  @ViewChild('sonicInput', { read: MatAutocompleteTrigger })
  autoComplete2: MatAutocompleteTrigger;
  @ViewChild('vocalInput', { read: MatAutocompleteTrigger })
  autoComplete3: MatAutocompleteTrigger;
  @ViewChild('tvInput', { read: MatAutocompleteTrigger })
  autoComplete4: MatAutocompleteTrigger;
  @ViewChild('artistInput', { read: MatAutocompleteTrigger })
  autoComplete5: MatAutocompleteTrigger;
  @ViewChild('bandInput', { read: MatAutocompleteTrigger })
  autoComplete6: MatAutocompleteTrigger;

  allInstrumentationSubCategory: any = [];
  allVolDescList: any = [];
  artistReferences:any = [];
  bandReferences:any = [];
  allnetworkReferences:any = [];
  tvReferences:any = [];
  newInsSuggestion:any=[]
  @Input()
  set track(t: Track) {
   
    this._track = t;
    this.trackChange.emit(this._track);
  }
  get track(): Track {
    return this._track;
  }

  @Output()
  trackChange = new EventEmitter<Track>();

  @Input()
  set isVersion(value: boolean){
    this.is_version = value;
  }
  get isVersion():boolean{
    return this.is_version;
  }
  @Input()
  set version(v: TrackVersion) {
    this._version = v;
    this.versionChange.emit(this._version);
  };
  get version(): TrackVersion {
    return this._version;
  }

  @Output()
  versionChange = new EventEmitter<TrackVersion>();
  private _track: any =[];
  private _subscriptions: Subscription[] = []
  isInstrumental: Boolean;
  trackType: any;
  values = [];
  data =[];
  inDescription: any = [];
  nameSuggestion: any = [];
  vocalDescription: any = [];
  vocalSuggestion: any = [];
  preChrousValues = [];
  preChrousData = [];
  chrousValues = [];
  chrousData = [];
  bridgeValues = [];
  bridgeData = [];
  is_version : boolean = false;
  constructor(
    private sonicRefService: SonicReferenceService,
    private netRefService: NetReferenceService,
    private instrumentService: InstrumentService,
    private vocalsService: VocalsService,
    private api: UserMenuService,
    public list: MenuListService, private _playerService: PlayerService,
  ) {
    this._playerService.trackNum([]);
    this.resetValues()
    // this._version = TrackVersion;
    // this._track = Track;
    this._playerService.trackDetail([])
    this._playerService.trackVersionDetail([])
    this.versionChange.emit(this._version);
    this.trackChange.emit(this._track)
  }

  ngOnInit() {
    console.log(this._track)
    console.log(this._version)
    this.track.sonic_references =[];
    this.track.band_references =[];
    this.track.newNetwork_ref_rm_sug = [];
    this.track.newTv_ref_rm_sug = [];

    this.instrumentData =[], this.track.rm_ins =[], this.track.newAddedSuggestion =[], this.track.new_ins = []
    this.list.getInstrumentalTyep().subscribe((type) => {
      console.log(type)
      this.isInstrumental = type;
      this.setValidation()
    }, (error) => {
      console.log(error);
    })
    
    this._playerService.trackValues.subscribe((co) => {
      if(Object.keys(co).length > 0){
     this._track = co
     this.track.Instrumentnew_suggestion =[]
     this.inDescription =[]
     this.nameSuggestion =[]
     if(this.track.support_version_ins_desc?.length > 0){
     this.newInsSuggestion=[]
     for(let x of this.track.support_version_ins_desc){
      if(x.name !== ''){
        this.newInsSuggestion.push(x.name)
      }
     }
     }
     console.log(this._track['instruments_desc'].length)
     if(this._track['version_lyrics']){
        
      this._track['lyrics'] = this._track['lyrics']
    }
  this._track['lyrics'] = (this._track['version_lyrics'] ) ? this._track['version_lyrics']:this._track['lyrics']
  if(!this._track['lyrics']){
    var dat=''
      var lyrics = {
        verse:dat,
        pre_chorus:dat,
        chorus:dat,
        bridge:dat
      }
      this._track['lyrics'] = lyrics
    }
     this.track['new_ins'] = []
     this._track['rm_ins'] =[]
     this.track['new_ins'] =[]
     this._track['rm_ins'] =[]
  
     this.track.sonic_references = (this.track.version_sonic_reference?.length>0 ) ?this.track.version_sonic_reference:this.track.track_sonic_reference
     this.track.band_references = ( this.track.version_band_references?.length>0 ) ? this.track.version_band_references: this.track.band_references
     this._track.instruments = (this._track.version_instruments?.length>0 ) ?this._track.version_instruments:this._track.instruments
     this._track['instruments_desc'] = (this._track['version_instruments_desc']?.length>0 ) ? this._track['version_instruments_desc'] :this._track['instruments_desc']
     this._track['vocal'] = (this._track['version_vocal']?.length>0 ) ? this._track['version_vocal'] :this._track['vocal']
     this.track.tv_references = (this.track.version_tv_references?.length>0 ) ? this.track.version_tv_references :this.track.tv_references
     this.track.network_references = (this.track.version_network_references?.length>0 ) ? this.track.version_network_references :this.track.network_references
     this._track['vocal_desc'] = (this._track['version_vocal_desc']?.length>0 ) ? this._track['version_vocal_desc'] :this._track['vocal_desc']

     if(this._track.instruments?.length>0){
      for(let i of this._track.instruments){
        this.instrumentData.push(i.id)
      }
      this.track.Instrumentsub_category = this.instrumentData
      this.getArtist(this.instrumentData, 'ini')
    }
    if (this._track.Instrumentsub_category?.length > 0) {
      this.track.Instrumentsub_category = (this._track.Instrumentsub_category)
      this.getArtist(this._track.Instrumentsub_category, 'ini')
    }
     if (this._track['instruments_desc']?.length > 0) {
       let filData = []
       for (let x of this._track['instruments_desc']) {
         console.log(x)
         let data = {}
         if (x.id !== null && x.name !== null) {
           data['id'] = x.id
           filData.push(x.id)
           this.inDescription.push(x.id)
           this.nameSuggestion.push(x.name);
         }
       }
       this.track.Instrumentdescriptions = this.inDescription
       this.track.Instrumentnew_suggestion = this.nameSuggestion
       this.newValue = this.inDescription
       console.log("desc", filData)
 
     }
     // <!-- Add Newsuggestion -->
 
     let newData = [], tvData = []
     if (this._track['tv_desc']?.length > 0) {
       for (let x of this._track['tv_desc']) {
         if (x.name !== null) {
           let data = {
             id: '',
             name: x.name
           }
           tvData.push(data)
           this.networkDesc.push(x.name)
           newData.push(x)
         }
       }
       for (let val of tvData) {
         this.track.tv_references.push(val)
       }
 
       this.track.tv_references.push(tvData)
       this.track.tv_referencesDescriptions = newData
 
     }
     let networkData = [], valData = []
     if (this._track['network_desc']?.length > 0) {
       for (let x of this._track['network_desc']) {
         if (x.name !== null) {
           let data = {
             id: '',
             name: x.name
           }
           valData.push(data)
           this.networkDesc.push(x.name)
           networkData.push(x)
         }
       }
       for (let val of valData) {
         this.track.network_references.push(val)
       }
 
       this.track.network_referencesDescriptions = networkData
     }
     if (this._track['vocal']?.length > 0) {
       this.track.Vocalssub_category = this._track['vocal'][0].id
       this.getVocDesc(this.track.Vocalssub_category)
     }
     let filDataVoc = []
     this.vocalSuggestion =[]
     this.vocalDescription =[]
     if (this._track['vocal_desc']?.length > 0) {
       for (let x of this._track['vocal_desc']) {
         let data = {}
         data['id'] = x.thesaurus_id
         filDataVoc.push(x.thesaurus_id)
         this.vocalDescription.push(x.thesaurus_id)
         this.vocalSuggestion.push(x.thesaurus_name);
       }
       this.track.Vocalsdescriptions = this.vocalDescription;
       this.track.Vocalsnew_suggestion = this.vocalSuggestion;
 
     }

     if (this.isInstrumental !== false) {
       if (this._track.lyrics) {
         if (typeof (this._track.lyrics?.verse) !== 'string') {
           if (this._track.lyrics.verse?.length > 0) {
             for (let i = 0; i < this._track.lyrics.verse.length; i++) {
               this.showVerseField = true
               if (this._track.lyrics.verse[i] !== '')
                 this.values.push({ value: this._track.lyrics.verse[i] });
             }
           }
         }
         if (typeof (this._track.lyrics.pre_chorus) !== 'string') {
           if (this._track.lyrics.pre_chorus?.length > 0) {
             for (let i = 0; i < this._track.lyrics.pre_chorus.length; i++) {
               this.showPreChorusField = true
               if (this._track.lyrics.pre_chorus[i] !== '')
                 this.preChrousValues.push({ value: this._track.lyrics.pre_chorus[i] });
             }
           }
         }
         if (typeof (this._track.lyrics?.chorus) !== 'string') {
           if (this._track.lyrics.chorus?.length > 0) {
             for (let i = 0; i < this._track.lyrics.chorus.length; i++) {
               this.showChorusField = true
               if (this._track.lyrics.chorus[i] !== '')
                 this.chrousValues.push({ value: this._track.lyrics.chorus[i] });
             }
           }
         }
         if (typeof (this._track.lyrics?.bridge) !== 'string') {
           if (this._track.lyrics.bridge?.length > 0) {
             for (let i = 0; i < this._track.lyrics.bridge.length; i++) {
               this.showBridgeField = true
               if (this._track.lyrics.bridge[i] !== '')
                 this.bridgeValues.push({ value: this._track.lyrics.bridge[i] });
             }
           }
         }
       }
     }
 
      }
    }),
    this._playerService.versionValues.subscribe((co) => {
      if(Object.keys(co).length > 0){
      this._version = co
      }
     }),
     this._playerService.trackId.subscribe((value) => {
      if (value !== 0) {
        this.enDescription = true;
      }
      else{
        this.enDescription = false;
      }
    }),

    this.list.getTrackType().subscribe((type) => {
      console.log(type)
      this.trackType = type;
    }, (error) => {
      console.log(error);
    })
    this.getSubCategory();
    this.getAllvocalstype();
    this.getTVReferences();
    this.getnetworkReferences();
    this.getartistReferences();
   // this.getbandReferences();
   this.gecontextReferences();
    this.getSonicReferences();
    this.instrumentService.getAllReferences().subscribe((data) => {
      this.allInstruments = data;
      console.log(this.allInstruments)
      // this.updateFilteredInstruments()
    }, (error) => {
      console.log(error);
      //alert("An error ocurred while getting instruments. Check your connections and try again");
    });
    this.vocalsService.getAllReferences().subscribe((data) => {
      this.allVocal = data;
      console.log(this.allVocal)
      // this.updateFilteredVocals()
    }, (error) => {
      console.log(error);
      //alert("An error ocurred while getting instruments. Check your connections and try again");
    });

    this.netRefService.getAllReferences().subscribe((data) => {
      this.allNetworks = data;
      // this.updateFilteredNetworks();
    }, (error) => {
      console.log(error);
      //alert("An error ocurred while getting network references. Check your connections and try again");
    });

    // this.sonicRefService.getAllReferences().subscribe((data) => {
    //   this.allSonics = data;
    //   this.updateFilteredSonics();
    // }, (error) => {
    //   console.log(error);
    //   //alert("An error ocurred while getting sonic references. Check your connections and try again");
    // });

    // if(this._track.instruments?.length>0){
    //   for(let i of this._track.instruments){
    //     this.instrumentData.push(i.id)
    //   }
    //   this.track.Instrumentsub_category =this.instrumentData
    //   this.getArtist(this.instrumentData, 'ini')
    // }
    // if (this._track.Instrumentsub_category?.length > 0) {
    //   this.track.Instrumentsub_category = (this._track.Instrumentsub_category)
    //   this.getArtist(this._track.Instrumentsub_category, 'ini')
    // }
    console.log(this._track['instruments_desc'].length)
    if (this._track['instruments_desc']?.length > 0) {
      let filData = []
      for (let x of this._track['instruments_desc']) {
        console.log(x)
        let data = {}
        if (x.id !== null && x.name !== null) {
          data['id'] = x.id
          filData.push(x.id)
          this.inDescription.push(x.id)
          this.nameSuggestion.push(x.name);
        }
      }
      this.track.Instrumentdescriptions = this.inDescription
      this.track.Instrumentnew_suggestion = this.nameSuggestion
      this.newValue = this.inDescription
      console.log("desc", filData)

    }
    // <!-- Add Newsuggestion -->

    let newData = [], tvData = []
    if (this._track['tv_desc']?.length > 0) {
      for (let x of this._track['tv_desc']) {
        if (x.name !== null) {
          let data = {
            id: '',
            name: x.name
          }
          tvData.push(data)
          this.networkDesc.push(x.name)
          newData.push(x)
        }
      }
      for (let val of tvData) {
        this.track.tv_references.push(val)
      }

      this.track.tv_references.push(tvData)
      this.track.tv_referencesDescriptions = newData

    }
    let networkData = [], valData = []
    if (this._track['network_desc']?.length > 0) {
      for (let x of this._track['network_desc']) {
        if (x.name !== null) {
          let data = {
            id: '',
            name: x.name
          }
          valData.push(data)
          this.networkDesc.push(x.name)
          networkData.push(x)
        }
      }
      for (let val of valData) {
        this.track.network_references.push(val)
      }

      this.track.network_referencesDescriptions = networkData
    }
    if (this._track['vocal']?.length > 0) {
      this.track.Vocalssub_category = this._track['vocal'][0].id
      this.getVocDesc(this.track.Vocalssub_category)
    }
    let filDataVoc = []
    this.vocalSuggestion =[]
    this.vocalDescription =[]
    if (this._track['vocal_desc']?.length > 0) {
      for (let x of this._track['vocal_desc']) {
        let data = {}
        data['id'] = x.thesaurus_id
        filDataVoc.push(x.thesaurus_id)
        this.vocalDescription.push(x.thesaurus_id)
        this.vocalSuggestion.push(x.thesaurus_name);
      }
      this.track.Vocalsdescriptions = this.vocalDescription;
      this.track.Vocalsnew_suggestion = this.vocalSuggestion;

    }

    this.list.getTrackType().subscribe((type) => {
      console.log(type)
      this.trackType = type;
    }, (error) => {
      console.log(error);
    })
    if (this.isInstrumental !== false) {
      if (this._track.lyrics) {
        if (typeof (this._track.lyrics?.verse) !== 'string') {
          if (this._track.lyrics.verse?.length > 0) {
            for (let i = 0; i < this._track.lyrics.verse.length; i++) {
              this.showVerseField = true
              if (this._track.lyrics.verse[i] !== '')
                this.values.push({ value: this._track.lyrics.verse[i] });
            }
          }
        }
        if (typeof (this._track.lyrics.pre_chorus) !== 'string') {
          if (this._track.lyrics.pre_chorus?.length > 0) {
            for (let i = 0; i < this._track.lyrics.pre_chorus.length; i++) {
              this.showPreChorusField = true
              if (this._track.lyrics.pre_chorus[i] !== '')
                this.preChrousValues.push({ value: this._track.lyrics.pre_chorus[i] });
            }
          }
        }
        if (typeof (this._track.lyrics?.chorus) !== 'string') {
          if (this._track.lyrics.chorus?.length > 0) {
            for (let i = 0; i < this._track.lyrics.chorus.length; i++) {
              this.showChorusField = true
              if (this._track.lyrics.chorus[i] !== '')
                this.chrousValues.push({ value: this._track.lyrics.chorus[i] });
            }
          }
        }
        if (typeof (this._track.lyrics?.bridge) !== 'string') {
          if (this._track.lyrics.bridge?.length > 0) {
            for (let i = 0; i < this._track.lyrics.bridge.length; i++) {
              this.showBridgeField = true
              if (this._track.lyrics.bridge[i] !== '')
                this.bridgeValues.push({ value: this._track.lyrics.bridge[i] });
            }
          }
        }
      }
    }

    this._subscriptions.push(
      // this.instrumentCtrl.valueChanges.pipe(
      //   startWith(''),
      //   switchMap((v) => {
      //     let iIds = this.track.instruments.map(i => i.id);
      //     if (!v) {
      //       return of(this.allInstruments.filter(i => !iIds.includes(i.id)));
      //     }
      //     return of(this.allInstruments.filter(i => i.name.toLowerCase().includes(v) && !iIds.includes(i.id)));
      //   })
      // ).subscribe(i => {
      //   this.filteredInstruments.next(i);
      // }),
      // this.vocalCtrl.valueChanges.pipe(
      //   startWith(''),
      //   switchMap((v) => {
      //     let iIds = this.track.vocal.map(i => i.id);
      //     if (!v) {
      //       return of(this.allVocal.filter(i => !iIds.includes(i.id)));
      //     }
      //     return of(this.allVocal.filter(i => i.name.toLowerCase().includes(v) && !iIds.includes(i.id)));
      //   })
      // ).subscribe(i => {
      //   this.filteredVocals.next(i);
      // }),
      this.networkCtrl.valueChanges.pipe(
        startWith(''),
        switchMap((v) => {
          let iIds = this.track.network_references.map(i => i.id);
          if (!v || typeof v != 'string') {
            return of(this.allnetworkReferences.filter(i => !iIds.includes(i.id)));
          }
          return of(this.allnetworkReferences.filter(i => i.name.toLowerCase().includes(v.toLowerCase()) && !iIds.includes(i.id)));
        })
      ).subscribe(i => {
        this.filteredNetworks.next(i);
      }),
      
      // this.tvCtrl.valueChanges.pipe(
      //   startWith(''),
      //   switchMap((v) => {
      //     let iIds = this.track.tv_references.map(i => i.id);
      //     if (!v || typeof v != 'string') {
      //       return of(this.tvReferences.filter(i => !iIds.includes(i.id)));
      //     }
      //     return of(this.tvReferences.filter(i => i.name.toLowerCase().includes(v.toLowerCase()) && !iIds.includes(i.id)));
      //   })
      // ).subscribe(i => {
      //   this.filteredTV.next(i);
      // }),
      // this.sonicCtrl.valueChanges.pipe(
      //   startWith(''),
      //   switchMap((v) => {
      //     let iIds = this.track.sonic_references.map(i => i.id);
      //     if (!v || typeof v != 'string') {
      //       return of(this.allSonics.filter(i => !iIds.includes(i.id)));
      //     }
      //     return of(this.allSonics.filter(i => i.name.toLowerCase().includes(v.toLowerCase()) && !iIds.includes(i.id)));
      //   })
      // ).subscribe(i => {
      //   this.filteredSonics.next(i);
      // })
    )
    window.addEventListener('scroll', this.scrollEvent, true);
    this.setValidation()
  }


  scrollEvent = (event: any): void => {
    // if (this.autoComplete.panelOpen)
    //   // this.autoComplete.closePanel();
    //   this.autoComplete.updatePosition();

        if (this.autoComplete1 && this.autoComplete1.panelOpen){
         this.autoComplete1.updatePosition();
        }
        if (this.autoComplete2 && this.autoComplete2.panelOpen){
          this.autoComplete2.updatePosition();
         }
         if (this.autoComplete3 && this.autoComplete3.panelOpen){
          this.autoComplete3.updatePosition();
         }
         if (this.autoComplete4 && this.autoComplete4.panelOpen){
          this.autoComplete4.updatePosition();
         }
         if (this.autoComplete5 && this.autoComplete5.panelOpen){
          this.autoComplete5.updatePosition();
         }
         if (this.autoComplete6 && this.autoComplete6.panelOpen){
          this.autoComplete6.updatePosition();
         }
      
  };
  //Get sonic REferences
  dataSonic: any = []
  getSonicReferences(event: string = '') {
    let requestUrl
    if (event.trim() !== '') {
      requestUrl = environment.apiURL + `/api/v1/sonicreferences/sonic-reference-dropdown/?search=` + event
    }
    else {
      requestUrl = environment.apiURL + `/api/v1/sonicreferences/sonic-reference-dropdown/`
    }
    this.api.doGET(requestUrl).subscribe(x => {
      this.dataSonic = x['results']
      this.allSonics = this.dataSonic;
      this.updateFilteredSonics();
    })
    this.sonicCtrl.valueChanges.pipe(
      startWith(''),
      switchMap((v) => {
        let iIds = this.track && this.track['sonic_references'].map(i => i.id);
        if (!v || typeof v != 'string') {
          return of(this.allSonics.filter(i => !iIds.includes(i.id)));
        }
        return of(this.allSonics.filter(i => i.name.toLowerCase().includes(v.toLowerCase()) && !iIds.includes(i.id)));
      })
    ).subscribe(i => {
      this.filteredSonics.next(i);
    })
  }
  // Get band references
  gecontextReferences(event: any = '') {

    let requestUrl
    if (event.trim() !== '') {
      requestUrl = environment.apiURL + `/api/v1/sonicreferences/band-reference-dropdown/?search=` + event
    }
    else {
      requestUrl = environment.apiURL + `/api/v1/sonicreferences/band-reference-dropdown/`
    }
    this.api.doGET(requestUrl).subscribe(x => {
      this.bandReferences = x['results'];
      this.updateFilteredBands();
    })
    this.bandCtrl.valueChanges.pipe(
      startWith(''),
      switchMap((v) => {
        let iIds = this.track && this.track['band_references'].map(i => i.id);
        if (!v || typeof v != 'string') {
          return of(this.bandReferences.filter(i => !iIds.includes(i.id)));
        }
        return of(this.bandReferences.filter(i => i.name.toLowerCase().includes(v.toLowerCase()) && !iIds.includes(i.id)));
      })
    ).subscribe(i => {
      this.filteredBands.next(i);
    })
  }
  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._subscriptions = [];
  }

  updateFilteredInstruments(type:any='') {
    let iIds = this.track.instruments.map(i => i.id);
    this.filteredInstruments.next(this.allInstrumentationSubCategory.filter(i => !iIds.includes(i.id)));
    if(type !== ''){
      if(this.allInstrumentationSubCategory.length ==0){
       this.track.Instrumentnew_suggestion=[];
       this.track.Instrumentnew_suggestion = this.track.newAddedSuggestion;
       this.track.Instrumentdescriptions =[];
       this.inDescription =[];
       this.track.Instrumentnew_suggestion =  this.newInsSuggestion;

      }
      if(this.allInstrumentationSubCategory.length !==0){
       let dataInst:any=[]
       this.inDescription =[];
       for (let i = 0; i < this.allInstrumentationSubCategory.length; i++) {
         const index1 = this.track.Instrumentnew_suggestion.findIndex(ind => ind == this.allInstrumentationSubCategory[i].name);
         if (index1 >= 0) {
           dataInst.push(this.allInstrumentationSubCategory[i].name)
           this.inDescription.push(this.allInstrumentationSubCategory[i].id)
         }
       }
       this.track.Instrumentdescriptions = this.inDescription
       if(this.track.newAddedSuggestion?.length > 0){
       this.track.Instrumentnew_suggestion=[...dataInst,...this.track.newAddedSuggestion]
         let data = [ ...this.track.Instrumentnew_suggestion,... this.newInsSuggestion]
         this.track.Instrumentnew_suggestion = data;
       }
       else{
        this.track.Instrumentnew_suggestion=[...dataInst,... this.newInsSuggestion]
       }
      }
     }
  }

  updateFilteredNetworks() {
    let iIds = this.track.network_references.map(i => i.id);
    this.filteredNetworks.next(this.allnetworkReferences.filter(i => !iIds.includes(i.id)));
  }

  updateFilteredTVReferences() {
    let iIds = this.track.tv_references.map(i => i.id);
    this.filteredTV.next(this.tvReferences.filter(i => !iIds.includes(i.id)));
  }

  updateFilteredArtists() {
    let iIds = this.track.artist_references.map(i => i.id);
    this.filteredArtists.next(this.artistReferences.filter(i => !iIds.includes(i.id)));
  }

  updateFilteredBands() {
    let iIds = this.track.band_references.map(i => i.id);
    this.filteredBands.next(this.bandReferences.filter(i => !iIds.includes(i.id)));
  }

  updateFilteredSonics() {
    let iIds = this.track.sonic_references.map(i => i.id);
    this.filteredSonics.next(this.allSonics.filter(i => !iIds.includes(i.id)));
  }

  updateFilteredVocals() {
    let iIds = this.track.vocal.map(i => i.id);
    this.filteredVocals.next(this.allVolDescList.filter(i => !iIds.includes(i.id)));
  }

  addInstrument(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      let instrument = this.allInstruments.find(i => i.name.toLowerCase() == value.trim().toLowerCase());
      if (instrument) {
        this.track.instruments.push(instrument);
        this.trackChange.emit(this.track);
        this.updateFilteredInstruments();
      }
    }

    if (input) {
      input.value = '';
    }

    this.instrumentCtrl.setValue('');
  }

  removeInstrument(instrument: Instrument): void {
    const index = this.track.instruments.findIndex(i => i.id == instrument.id);

    if (index >= 0) {
      this.track.instruments.splice(index, 1);
      this.trackChange.emit(this.track);
      this.updateFilteredInstruments();
    }
    this.setValidation();
  }

  selectedInstrument(event: MatAutocompleteSelectedEvent): void {
    this.track.instruments.push(event.option.value);
    this.trackChange.emit(this.track);
    this.updateFilteredInstruments();
    this.instrumentInput.nativeElement.value = '';
    this.instrumentCtrl.setValue('');
  }
  addVocal(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      let vocal = this.allVocal.find(i => i.name.toLowerCase() == value.trim().toLowerCase());
      if (vocal) {
        this.track.vocal.push(vocal);
        this.trackChange.emit(this.track);
        this.updateFilteredVocals();
      }
    }

    if (input) {
      input.value = '';
    }

    this.vocalCtrl.setValue('');
  }

  removeVocal(vocal: Instrument): void {
    const index = this.track.vocal.findIndex(i => i.id == vocal.id);

    if (index >= 0) {
      this.track.vocal.splice(index, 1);
      this.trackChange.emit(this.track);
      this.updateFilteredVocals();
    }
    this.setValidation();
  }

  selectedVocal(event: MatAutocompleteSelectedEvent): void {
    this.track.vocal.push(event.option.value);
    this.trackChange.emit(this.track);
    this.updateFilteredVocals();
    this.vocalInput.nativeElement.value = '';
    this.vocalCtrl.setValue('');
  }

  addNetwork(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      let network = this.allNetworks.find(i => i.name.toLowerCase() == value.trim().toLowerCase());
      if (network) {
        this.track.network_references.push(network);
        this.trackChange.emit(this.track);
        this.updateFilteredNetworks();
      }
    }

    if (input) {
      input.value = '';
    }

    this.networkCtrl.setValue('');
  }

  removeNetwork(network: NetworkReference): void {
    const index = this.track.network_references.findIndex(i => i.name == network.name);
    const index2 = this.track['support_network_references'].findIndex(i => i.name == network.name);
    if (index >= 0) {
      this.track.network_ref_rm_sug.push(this.track.version_network_references[index].id)
      this.track.network_references.splice(index, 1);
      this.trackChange.emit(this.track);
      this.updateFilteredNetworks();
    }

    if (index2 >= 0) {
      if(this.track.newNetwork_ref_rm_sug === undefined || this.track.newNetwork_ref_rm_sug.length===0 ){
        this.track.newNetwork_ref_rm_sug =[]
        this.track.newNetwork_ref_rm_sug.push(network.id) 
      }
      this.track['support_network_references'].splice(index2, 1);
      this.trackChange.emit(this.track);
      this.updateFilteredNetworks();
    }

  // <!-- Add Newsuggestion -->
    let data = this.track.network_referencesNew_suggestion.filter(function (e) {
      if (e !== network.name) {
        return e;
      }
    })
    this.track.network_referencesNew_suggestion = data
    this.trackChange.emit(this.track);
    if(network)
    this.setValidation();
  }

  selectedNetwork(event: MatAutocompleteSelectedEvent): void {
    this.track.network_references.push(event.option.value);
    this.trackChange.emit(this.track);
    this.updateFilteredNetworks();
    this.networkInput.nativeElement.value = '';
    this.networkCtrl.setValue('');
  }
  // Add TV Reference
  addTV(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      let tv = this.allTV.find(i => i.name.toLowerCase() == value.trim().toLowerCase());
      if (tv) {
        this.track.tv_references.push(tv);
        this.trackChange.emit(this.track);
        this.updateFilteredTVReferences();
      }
    }

    if (input) {
      input.value = '';
    }

    this.tvCtrl.setValue('');
  }
// Remove TV Reference
removeTV(tv: TvReference): void {
  const index = this.track.tv_references.findIndex(i => i.name == tv.name);
  const index2 = this.track['support_tv_references'].findIndex(i => i.name == tv.name);
    const tvIndex =  this.track.tv_referencesDescriptions && this.track?.tv_referencesDescriptions.findIndex(i => i.name == tv.name);
 
  if (index >= 0) {
    this.track.tv_ref_rm_sug.push(this.track.tv_references[index].id)
    this.track.tv_references.splice(index, 1);
    this.trackChange.emit(this.track);
    this.updateFilteredTVReferences();
  }
   
    if (index2 >= 0) {
      if(this.track.newTv_ref_rm_sug === undefined || this.track.newTv_ref_rm_sug.length===0){
        this.track.newTv_ref_rm_sug =[]
        this.track.newTv_ref_rm_sug.push(tv.id) 
      }
      else{
        this.track.newTv_ref_rm_sug.push(tv.id)
      }
      this.track['support_tv_references'].splice(index2, 1);
      this.trackChange.emit(this.track);
      this.updateFilteredTVReferences();
    }
     // <!-- Add Newsuggestion -->

  let data = this.track.tv_referencesNew_suggestion.filter(function (e) {
    if (e !== tv.name) {
      return e;
    }
  })
  this.track.tv_referencesNew_suggestion = data
  this.trackChange.emit(this.track);
  this.setValidation();
}
// Selected TV Reference
  selectedTV(event: MatAutocompleteSelectedEvent): void {
    this.track.tv_references.push(event.option.value);
    this.trackChange.emit(this.track);
    this.updateFilteredTVReferences();
    this.tvInput.nativeElement.value = '';
    this.tvCtrl.setValue('');
    this.tvNewCtrl.setValue('');
  }
  // Add Artist Reference
  addArtist(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      let artist = this.allTV.find(i => i.name.toLowerCase() == value.trim().toLowerCase());
      if (artist) {
        this.track.artist_references.push(artist);
        this.trackChange.emit(this.track);
        this.updateFilteredArtists();
      }
    }

    if (input) {
      input.value = '';
    }

    this.artistCtrl.setValue('');
  }
// Remove Artist Reference
  removeArtist(artist: Artist): void {
    const index = this.track.artist_references.findIndex(i => i.id == artist.id);

    if (index >= 0) {
      this.track.artist_references.splice(index, 1);
      this.trackChange.emit(this.track);
      this.updateFilteredArtists();
    }
    this.setValidation();
  }
// Selected Artist Reference
  selectedArtist(event: MatAutocompleteSelectedEvent): void {
    this.track.artist_references.push(event.option.value);
    this.trackChange.emit(this.track);
    this.updateFilteredArtists();
    this.artistInput.nativeElement.value = '';
    this.artistCtrl.setValue('');
  }
  // Add Band Reference
  addBand(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      let band = this.allBands.find(i => i.name.toLowerCase() == value.trim().toLowerCase());
      if (band) {
        this.track.band_references.push(band);
        this.trackChange.emit(this.track);
        this.updateFilteredBands();
      }
    }

    if (input) {
      input.value = '';
    }

    this.bandCtrl.setValue('');
  }
// Remove Band Reference
  removeBand(artist: Artist): void {
    const index = this.track.band_references.findIndex(i => i.id == artist.id);

    if (index >= 0) {
      this.track.band_references.splice(index, 1);
      this.trackChange.emit(this.track);
      this.updateFilteredBands();
    }
    this.setValidation();
  }
// Selected Band Reference
  selectedBand(event: MatAutocompleteSelectedEvent): void {
    this.track.band_references.push(event.option.value);
    this.trackChange.emit(this.track);
    this.updateFilteredBands();
    this.bandInput.nativeElement.value = '';
    this.bandCtrl.setValue('');
  }

  addSonic(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      let sonic = this.allSonics.find(i => i.name.toLowerCase() == value.trim().toLowerCase());
      if (sonic) {
        this.track.sonic_references.push(sonic);
        this.trackChange.emit(this.track);
        this.updateFilteredSonics();
      }
    }

    if (input) {
      input.value = '';
    }

    this.sonicCtrl.setValue('');
  }

  removeSonic(sonic: SonicReference): void {
    const index = this.track.sonic_references.findIndex(i => i.id == sonic.id);

    if (index >= 0) {
      this.track.sonic_references.splice(index, 1);
      this.trackChange.emit(this.track);
      this.updateFilteredSonics();
    }
    this.setValidation();
  }

  selectedSonic(event: MatAutocompleteSelectedEvent): void {
    this.track.sonic_references.push(event.option.value);
    this.trackChange.emit(this.track);
    this.updateFilteredSonics();
    this.sonicInput.nativeElement.value = '';
    this.sonicCtrl.setValue('');
  }
  onLyricsChangeverse(data, i) {
    console.log(this.values)
    this.values[i].value = data;
    for(let index = 0; index < this.values.length ; index++){
      this.data[index] = this.values[index].value;
    }
    console.log(this.data)
    this.track.lyrics.verse =  this.data;
    this.trackChange.emit(this.track);
  }
  onLyricsChangepre_chorus(data,i) {
    console.log(this.values)
    this.preChrousValues[i].value = data;
    for(let index = 0; index < this.preChrousValues.length ; index++){
      this.preChrousData[index] = this.preChrousValues[index].value;
    }
    console.log(this.preChrousData)
    this.track.lyrics.pre_chorus = this.preChrousData;
    this.trackChange.emit(this.track);
  }
  onLyricsChangechorus(data, i) {
    console.log(this.chrousValues)
    this.chrousValues[i].value = data;
    for(let index = 0; index < this.chrousValues.length ; index++){
      this.chrousData[index] = this.chrousValues[index].value;
    }
    console.log(this.chrousData)
    this.track.lyrics.chorus = this.chrousData;
    this.trackChange.emit(this.track);
  }
  onLyricsChangebridge(data, i) {
    this.bridgeValues[i].value = data;
    for(let index = 0; index < this.bridgeValues.length ; index++){
      this.bridgeData[index] = this.bridgeValues[index].value;
    }
    console.log(this.bridgeData)
    this.track.lyrics.bridge = this.bridgeData;
    this.trackChange.emit(this.track);
  }

  onVocalsChange(data) {
    this.track.vocals = data;
    this.trackChange.emit(this.track);
  }

  instrumentTrackBy(instrument: Instrument, index) {
    return instrument.id;
  }

  networkTrackBy(network: NetworkReference, index) {
    return network.id;
  }

  sonicTrackBy(sonic: SonicReference, index) {
    return sonic.id;
  }

  vocalTrackBy(vocal: Vocals, index) {
    return vocal.id;
  }

  getInstrumentAddedByClass(instrument: Instrument): string {
    // let it = this.track.instruments.find(i=>i.id == instrument.id);
    // if (it) {
    //   return this.getClassForRole(it.added_by_role);
    // }
    return ''
  }

  getNetworkAddedByClass(network: NetworkReference): string {
    // let it = this.track.networkreferencetracks_set.find(i=>i.networkreference_id == network.id);
    // if (it) {
    //   return this.getClassForRole(it.added_by_role);
    // }
    return ''
  }

  getSonicAddedByClass(sonic: SonicReference): string {
    // let it = this.track.sonicreferencetracks_set.find(i=>i.sonicreference_id == sonic.id);
    // if (it) {
    //   return this.getClassForRole(it.added_by_role);
    // }
    return ''
  }

  getVocalAddedByClass(vocal: Vocals): string {
    // let it = this.track.instruments.find(i=>i.id == instrument.id);
    // if (it) {
    //   return this.getClassForRole(it.added_by_role);
    // }
    return ''
  }

  getClassForRole(role: number) {
    switch (role) {
      case -1:
        return 'all';
      case 0:
        return 'composer';
      case 1:
        return 'approver';
      case 2:
        return 'meta-tagger';
    }
  }

  
  addCustomTv(event: any): void {

    if (event.value !== null &&  event.value.trim() !== '') {
      const index = this.tvReferences.findIndex(i => i.name == event.value);
      const tvIndex = this.track.tv_references.findIndex(i => i.name == event.value)
      if (tvIndex > 0 && index > 0) {
        this.tvNewCtrl.setValue('');
      }
      else {
        if (index < 0) {
          let data = {
            id: '',
            name: event.value
          }
          this.track.tv_references.push(data);
          this.tvSuggestion.push(event.value)
          this.track.tv_referencesNew_suggestion = []
          this.track.tv_referencesNew_suggestion = this.tvSuggestion
          this.trackChange.emit(this.track);
          this.tvNewCtrl.setValue('');
        }
        if (tvIndex < 0 && index > 0) {

          this.track.tv_references.push(event.value);
          this.tvSuggestion.push(event.value.name)
          this.track.tv_referencesNew_suggestion = []
          this.track.tv_referencesNew_suggestion = this.tvSuggestion
          this.trackChange.emit(this.track);
          this.tvNewCtrl.setValue('');
        }
      }
    }
  }
  addCustomNetwork(event: any): void {
    if (event.value !== null &&  event.value.trim() !== '') {
      const index = this.allnetworkReferences.findIndex(i => i.name == event.value);
      const indexNetwork = this.track.network_references.findIndex(i => i.name == event.value)
      if (indexNetwork > 0 && index > 0) {
        this.networkNewCtrl.setValue('');
      }
      else {
        if (index < 0) {
          let data = {
            id: '',
            name: event.value
          }
          this.track.network_references.push(data);
          this.networkSuggestion.push(event.value)
          this.track.network_referencesNew_suggestion = []
          this.track.network_referencesNew_suggestion = this.networkSuggestion
          this.trackChange.emit(this.track);
          this.networkNewCtrl.setValue('');
        }
        if (indexNetwork < 0 && index > 0) {
          this.track.network_references.push(event.value);
          this.networkSuggestion.push(event.value.name)
          this.track.network_referencesNew_suggestion = []
          this.track.network_referencesNew_suggestion = this.networkSuggestion
          this.trackChange.emit(this.track);
          this.networkNewCtrl.setValue('');
        }
      }

    }
  }


  addCustomSonic(event){
    if (event.value !== null && event.value.trim() !== '') {
      const index = this.allSonics.findIndex(i => i.name == event.value);
      const tvIndex = this.track.sonic_references.findIndex(i => i.name == event.value)
      if (tvIndex > 0 && index > 0) {
        this.sonicNewCtrl.setValue('');
      }
      else {
        if (index < 0) {
          let data = {
            id: '',
            name: event.value
          }
          this.track.sonic_references.push(data);
          this.sonicSuggestion.push(event.value)
          this.track.sonic_referencesNew_suggestion = []
          this.track.sonic_referencesNew_suggestion = this.sonicSuggestion
          this.trackChange.emit(this.track);
          this.sonicNewCtrl.setValue('');
        }
        if (tvIndex < 0 && index > 0) {

          this.track.sonic_references.push(event.value);
          this.sonicSuggestion.push(event.value.name)
          this.track.sonic_referencesNew_suggestion = []
          this.track.sonic_referencesNew_suggestion = this.sonicSuggestion
          this.trackChange.emit(this.track);
          this.sonicNewCtrl.setValue('');
        }
      }
    }

  }

  addCustomBand(event){
    if (event.value !== null && event.value.trim() !== '') {
      const index = this.allBands.findIndex(i => i.name == event.value);
      const tvIndex = this.track.band_references.findIndex(i => i.name == event.value)
      if (tvIndex > 0 && index > 0) {
        this.bandNewCtrl.setValue('');
      }
      else {
        if (index < 0) {
          let data = {
            id: '',
            name: event.value
          }
          this.track.band_references.push(data);
          this.bandSuggestion.push(event.value)
          this.track.band_referencesNew_suggestion = []
          this.track.band_referencesNew_suggestion = this.bandSuggestion
          this.trackChange.emit(this.track);
          this.bandNewCtrl.setValue('');
        }
        if (tvIndex < 0 && index > 0) {

          this.track.band_references.push(event.value);
          this.bandSuggestion.push(event.value.name)
          this.track.band_referencesNew_suggestion = []
          this.track.band_referencesNew_suggestion = this.bandSuggestion
          this.trackChange.emit(this.track);
          this.bandNewCtrl.setValue('');
        }
      }
    }

  }
  
  // allInstrumentationSubCategory: any;
  allSubCategory: any;
  allVocals: any;
  instsub: any;
  vocalstype: any;
  vocalsDesc: any;
  // allVolDescList: any;
  getArtist(e , type:any='') {
    if(e !== undefined){
    let requestUrl = environment.apiURL + `/api/v1/track/ins-description-dropdown?ins_ids=`+e
    this.api.doGET(requestUrl).subscribe(x => {
      this.allInstrumentationSubCategory = x;
      if(type == ''){
        this.updateFilteredInstruments('val')
        }
        else{
          this.updateFilteredInstruments()
        }
    })
    this.instrumentCtrl.valueChanges.pipe(
      startWith(''),
      switchMap((v) => {
        let iIds = this.track.instruments.map(i => i.id);
        if (!v) {
          return of(this.allInstrumentationSubCategory.filter(i => !iIds.includes(i.id)));
        }
        if (this.allInstrumentationSubCategory && this.allInstrumentationSubCategory?.length > 0) {
          return of(this.allInstrumentationSubCategory.filter(i => i.name.toLowerCase().includes(v) && !iIds.includes(i.id)));
        }
      })
    ).subscribe(i => {
      this.filteredInstruments.next(i);
    })
  }
  }
  getSubCategory() {
    let requestUrl = environment.apiURL + `/api/v1/track/instrument-dropdown/`
    this.api.doGET(requestUrl).subscribe(x => {
      this.allSubCategory = x;
    })
  }
  getAllvocalstype() {
    let requestUrl = environment.apiURL + `/api/v1/track/vocals-dropdown/`
    this.api.doGET(requestUrl).subscribe(x => {
      this.allVocals = x;
    })
  }
  getVocDesc(e) {
    let requestUrl = environment.apiURL + `/api/v1/track/${e}/vocal-description-dropdown/`
    this.api.doGET(requestUrl).subscribe(x => {
      this.allVolDescList = x;
      this.updateFilteredVocals();
    })
    this.vocalCtrl.valueChanges.pipe(
      startWith(''),
      switchMap((v) => {
        let iIds = this.track.vocal.map(i => i.id);
        if (!v) {
          return of(this.allVolDescList.filter(i => !iIds.includes(i.id)));
        }
        if (this.allVolDescList && this.allVolDescList?.length > 0) {
          return of(this.allVolDescList.filter(i => i.name.toLowerCase().includes(v) && !iIds.includes(i.id)));
        }
      })
    ).subscribe(i => {
      this.filteredVocals.next(i);
    })
  }
  Changeddesc(e) {

  }
  instrumenrtationChange(e) {
    this.list.instDesc = this.instDesc
    this.list.instSubCategory = this.instsub
  }
  instrumenrtationChange2(e, name) {
    if(name === 'add'){
      let data: any = [];
      this.newValue = [];
      for (let index = 0; index < e.length; index++) {
        for (let i = 0; i < this.allInstrumentationSubCategory.length; i++) {
          if (this.allInstrumentationSubCategory[i].id === e[index]) {
            data.push(this.allInstrumentationSubCategory[i].name)
            this.newValue.push(this.allInstrumentationSubCategory[i].id)
          }
        }
      }
      for (let j = 0; j < this.track.Instrumentnew_suggestion.length; j++) {
        data.push(this.track.Instrumentnew_suggestion[j])
      }
      const dataInstrument = data => data.filter((item, index) => data.indexOf(item) === index)
      console.log(dataInstrument(data))
      this.track.Instrumentnew_suggestion = dataInstrument(data);
      this.track.Instrumentdescriptions = (e)
    }
    else{
      this.track.Instrumentdescriptions = e
    }
   
  }
  vocalsChange(e) {
    this.list.vocalsType = this.vocalstype
    this.list.vocalsdesc = this.vocalsDesc
  }

  closethisPanel(panel) {
    if (panel == 'instruments') {
      this.instrumentationPanelOpen = false;
      this.instrumentsPanel.close();
      this.instrumentInput.nativeElement.value = '';
      this.instrumentCtrl.setValue('');
    }
    if (panel == 'network') {
      this.tvNetworkPanelOpen = false;
      this.networkPanel.close();
    }
    if (panel == 'sonic') {
      this.sonicPanelOpen = false;
      this.sonicPanel.close();
      this.sonicUpdate = true;
    }
    if (panel == 'lyrics') {
      this.lyricsPanelOpen = false;
      this.lyricsPanel.close();
    }
    if (panel == 'vocals') {
      this.vocalsPanelOpen = false;
      this.vocalsPanel.close();
    }
   this.setValidation();
   
  }
  clearInstrumentInput() {
    this.instrumentInput.nativeElement.value = '';
    this.instrumentCtrl.setValue('');
  }
  clearTVNetworkRefInput() {
    this.tvInput.nativeElement.value='';
    this.tvCtrl.setValue('');
    this.networkInput.nativeElement.value = '';
    this.networkCtrl.setValue('');
    this.tvNewCtrl.setValue('');
  }
  clearSonicRefInput() {
    this.artistInput.nativeElement.value='';
    this.artistCtrl.setValue('');
    this.bandInput.nativeElement.value = '';
    this.bandCtrl.setValue('');
  }
  // Add custom instruments
  addCustomInstruments(): void {
    console.log(this.track)
    if (this.instrumentInput.nativeElement.value != undefined && this.instrumentInput.nativeElement.value != '') {
      this.track.Instrumentnew_suggestion.push(this.instrumentInput.nativeElement.value);
      if(this.track.newAddedSuggestion == undefined || this.track.newAddedSuggestion.length===0){
      this.track.newAddedSuggestion=[]
      this.track.newAddedSuggestion.push(this.instrumentInput.nativeElement.value)
      }
      else{
      this.track.newAddedSuggestion.push(this.instrumentInput.nativeElement.value)
      }
      this.trackChange.emit(this.track);
      this.instrumentInput.nativeElement.value = '';
      this.instrumentCtrl.setValue('');
    }
  }
  // Remove custom instruments
  removeCustomInstruments(instrument?: any) {
    console.log(instrument)
    console.log(this.track)
    const index = this.track.Instrumentnew_suggestion.findIndex(i => i == instrument);
    const indexData = this.allInstrumentationSubCategory.filter(function (e) {
      if (e.name === instrument) {
        return e.id;
      }
    })
    const valInd = this.track.newAddedSuggestion.findIndex(i => i == instrument);
    if(valInd >= 0){
      this.track.newAddedSuggestion.splice(valInd,1)
    }
    console.log(indexData);
    for (let val = 0; val < indexData.length; val++) {
      this.newValue = this.newValue.filter(function (e) {
        if (e !== indexData[val].id) {
          return e;
        }
      })
  }
  this.instrumenrtationChange2(this.newValue,"remove")
    if (index != -1) {
      this.track.Instrumentnew_suggestion.splice(index, 1);
      this.trackChange.emit(this.track);
      console.log(this.track)
    }
    this.setValidation();
  }
  // Get artist references
  getartistReferences(){
    let requestUrl = environment.apiURL + `/api/v1/track/all-artists`
    this.api.doGET(requestUrl).subscribe(x => {
      this.artistReferences = x;
      this.updateFilteredArtists()
    })
    this.artistCtrl.valueChanges.pipe(
      startWith(''),
      switchMap((v) => {
        let iIds = this.track.artist_references.map(i => i.id);
        if (!v || typeof v != 'string') {
          return of(this.artistReferences.filter(i => !iIds.includes(i.id)));
        }
        return of(this.artistReferences.filter(i => i.name.toLowerCase().includes(v.toLowerCase()) && !iIds.includes(i.id)));
      })
    ).subscribe(i => {
      this.filteredArtists.next(i);
    })
  }
  // // Get band references
  // getbandReferences(){
  //   let requestUrl = environment.apiURL + `/api/v1/track/all-bands`
  //   this.api.doGET(requestUrl).subscribe(x => {
  //     this.bandReferences = x;
  //     this.updateFilteredBands();
  //   })
  //   this.bandCtrl.valueChanges.pipe(
  //     startWith(''),
  //     switchMap((v) => {
  //       let iIds = this.track.band_references.map(i => i.id);
  //       if (!v || typeof v != 'string') {
  //         return of(this.bandReferences.filter(i => !iIds.includes(i.id)));
  //       }
  //       return of(this.bandReferences.filter(i => i.name.toLowerCase().includes(v.toLowerCase()) && !iIds.includes(i.id)));
  //     })
  //   ).subscribe(i => {
  //     this.filteredBands.next(i);
  //   })
  // }
  // Get network references
  getnetworkReferences(){
    let requestUrl = environment.apiURL + `/api/v1/track/all-network-ref`
    this.api.doGET(requestUrl).subscribe(x => {
      this.allnetworkReferences = x;
      this.updateFilteredNetworks();
    })
    this.networkCtrl.valueChanges.pipe(
      startWith(''),
      switchMap((v) => {
        let iIds = this.track.network_references.map(i => i.id);
        if (!v || typeof v != 'string') {
          return of(this.allnetworkReferences.filter(i => !iIds.includes(i.id)));
        }
        return of(this.allnetworkReferences.filter(i => i.name.toLowerCase().includes(v.toLowerCase()) && !iIds.includes(i.id)));
      })
    ).subscribe(i => {
      this.filteredNetworks.next(i);
    })
  }
  // Get TV references
  getTVReferences(){
    let requestUrl = environment.apiURL + `/api/v1/track/all-tv-ref`
    this.api.doGET(requestUrl).subscribe(x => {
      this.tvReferences = x;
      this.updateFilteredTVReferences();
    })
    this.tvCtrl.valueChanges.pipe(
        startWith(''),
        switchMap((v) => {
          let iIds = this.track.tv_references.map(i => i.id);
          if (!v || typeof v != 'string') {
            return of(this.tvReferences.filter(i => !iIds.includes(i.id)));
          }
          return of(this.tvReferences.filter(i => i.name.toLowerCase().includes(v.toLowerCase()) && !iIds.includes(i.id)));
        })
      ).subscribe(i => {
        this.filteredTV.next(i);
      })
  }
  setValidation(){
    if (!this.isVersion) {
      if (this.track?.title && this.track?.genre && (this.track?.moods[0] || this.track?.moods[1] || this.track?.moods[2])  && this.version?.tempo && this.version?.track_ending && this.version?.calculated_key) {
       // this.track.isValid = false;
      if (this.isInstrumental !== false) {
        if (this.track.network_references.length >= 2 && this.track.tv_references.length >= 2 && this.track.sonic_references?.length >= 2 &&
          this.track.band_references?.length >= 2 && (this.track.Vocalssub_category && (this.track.Vocalsdescriptions && this.track.Vocalsdescriptions?.length > 0))
          && (this.track.lyrics.verse != '' || this.track.lyrics.pre_chorus != '' || this.track.lyrics.chorus != '' || this.track.lyrics.bridge != '')
          && ((this.track.Instrumentsub_category && this.track.Instrumentsub_category.length > 0)  && ((this.track.Instrumentdescriptions 
            && this.track.Instrumentdescriptions?.length>0) ||
            (this.track.Instrumentnew_suggestion && this.track.Instrumentnew_suggestion?.length>0)))) {
          this.track.isValid = false;
        }
        else {
          this.track.isValid = true;
        }
      }
      if (this.isInstrumental === false) {
        if (this.track.network_references.length >= 2 && this.track.tv_references.length >= 2 && this.track.sonic_references?.length >= 2 &&
          this.track.band_references?.length >= 2 &&
          ((this.track.Instrumentsub_category && this.track.Instrumentsub_category.length > 0)  && ( (this.track.Instrumentdescriptions 
            && this.track.Instrumentdescriptions?.length>0) ||
            (this.track.Instrumentnew_suggestion && this.track.Instrumentnew_suggestion?.length>0)))) {

          this.track.isValid = false;
        }
        else {
          this.track.isValid = true;
        }
      }
      }
      else {
        this.track.isValid = true;
      }
    }
    if (this.isVersion) {
      if (this.version?.title && this.version?.track_ending) {
     //   this.track.isValid = false;
        if (this.isInstrumental !== false) {
          if (this.version.title !== '' && ([...this.track.version_network_references,...this.track['support_network_references']])?.length >= 2 && ([...this.track.version_tv_references,...this.track['support_tv_references']])?.length >= 2 
          && ([...this.track.version_sonic_reference,...this.track['support_sonic_references']])?.length >= 2 &&
          ([...this.track.version_band_references,...this.track['support_band_references']])?.length >= 2 && (this.track.Vocalssub_category && (this.track.Vocalsdescriptions && this.track.Vocalsdescriptions?.length > 0))
            && (this.track.lyrics.verse != '' || this.track.lyrics.pre_chorus != '' || this.track.lyrics.chorus != '' || this.track.lyrics.bridge != '')
            &&((this.track.Instrumentsub_category && this.track.Instrumentsub_category.length > 0)  && ((this.track.Instrumentdescriptions 
              && this.track.Instrumentdescriptions?.length>0) ||
              (this.track.Instrumentnew_suggestion && this.track.Instrumentnew_suggestion?.length>0)))) {
              this.track.isValid = false;
          }
          else {
            this.track.isValid = true;
          }
        }
        if (this.isInstrumental === false) {
          if (this.version.title !== '' && ([...this.track.version_network_references,...this.track['support_network_references']])?.length >= 2 && ([...this.track.version_tv_references,...this.track['support_tv_references']])?.length >= 2 
          && ([...this.track.version_sonic_reference,...this.track['support_sonic_references']])?.length >= 2 &&
          ([...this.track.version_band_references,...this.track['support_band_references']])?.length >= 2 &&
            ((this.track.Instrumentsub_category && this.track.Instrumentsub_category.length > 0)  && ( (this.track.Instrumentdescriptions 
              && this.track.Instrumentdescriptions?.length>0) ||
              (this.track.Instrumentnew_suggestion && this.track.Instrumentnew_suggestion?.length>0)))) {
              this.track.isValid = false;
          }
          else {
            this.track.isValid = true;
          }
        }
      }
      else {
        this.track.isValid = true;
      }
    }
   
  }
  remove(i){
    this.data =[];
    this.values.splice(i,1);
    for(let index = 0; index < this.values.length ; index++){
      this.data[index] = this.values[index].value;
    }
    this.track.lyrics.verse =  this.data;
    this.trackChange.emit(this.track);
  }

  setNewInstrument(e, id){
    if(e.isUserInput ){
    let instrument = this.instrumentData.findIndex(i => i == id);
    if (e.source.selected) {
      if (instrument < 0) {
      this.track['new_ins'].push(id)
      }
    }
    else{
      if (instrument >= 0) {
      this.track['rm_ins'].push(id)
      }
    }
    this.trackChange.emit(this.track);
  }
  }

  addVerse(){
    this.values.push({value: ""});
  }
  movedownVerse(current, next){
    let data = this.values[next];
    let oldDate = this.values[current];
    this.values[current]= data;
    this.values[next]= oldDate
    for(let index = 0; index < this.values.length ; index++){
      this.data[index] = this.values[index].value;
    }
    console.log(this.data)
    this.track.lyrics.verse =  this.data;
    this.trackChange.emit(this.track);
  }
  moveupVerse(current, next){
    let data = this.values[next];
    let oldDate = this.values[current];
    this.values[current]= data;
    this.values[next]= oldDate
    for(let index = 0; index < this.values.length ; index++){
      this.data[index] = this.values[index].value;
    }
    console.log(this.data)
    this.track.lyrics.verse =  this.data;
    this.trackChange.emit(this.track);
  }

  removePreChrous(i){
    this.preChrousData = []
    this.preChrousValues.splice(i,1);
    for(let index = 0; index < this.preChrousValues.length ; index++){
      this.preChrousData[index] = this.preChrousValues[index].value;
    }
    console.log(this.preChrousValues,this.preChrousData)
    this.track.lyrics.pre_chorus =  this.preChrousData;
    this.trackChange.emit(this.track);
  }

  addPreChrous(){
    this.preChrousValues.push({value: ""});
  }
  movedownPreChrous(current, next){
    let data = this.preChrousValues[next];
    let oldDate = this.preChrousValues[current];
    this.preChrousValues[current]= data;
    this.preChrousValues[next]= oldDate
    for(let index = 0; index < this.preChrousValues.length ; index++){
      this.preChrousData[index] = this.values[index].value;
    }
    console.log(this.preChrousData)
    this.track.lyrics.pre_chorus =  this.preChrousData;
    this.trackChange.emit(this.track);
  }
  moveupPreChrous(current, next){
    let data = this.preChrousValues[next];
    let oldDate = this.preChrousValues[current];
    this.preChrousValues[current]= data;
    this.preChrousValues[next]= oldDate
    for(let index = 0; index < this.preChrousValues.length ; index++){
      this.preChrousData[index] = this.preChrousValues[index].value;
    }
    console.log(this.preChrousData)
    this.track.lyrics.pre_chorus =  this.preChrousData;
    this.trackChange.emit(this.track);
  }

  removeChrous(i){
    this.chrousData = []
    this.chrousValues.splice(i,1);
    for(let index = 0; index < this.chrousValues.length ; index++){
      this.chrousData[index] = this.chrousValues[index].value;
    }
    console.log(this.chrousValues,this.chrousData)
    this.track.lyrics.chorus =  this.chrousData;
    this.trackChange.emit(this.track);
  }

  addChrous(){
    this.chrousValues.push({value: ""});
  }
  movedownChrous(current, next){
    let data = this.chrousValues[next];
    let oldDate = this.chrousValues[current];
    this.chrousValues[current]= data;
    this.chrousValues[next]= oldDate
    for(let index = 0; index < this.chrousValues.length ; index++){
      this.chrousData[index] = this.chrousValues[index].value;
    }
    console.log(this.chrousData)
    this.track.lyrics.chorus =  this.chrousData;
    this.trackChange.emit(this.track);
  }
  moveupChrous(current, next){
    let data = this.chrousValues[next];
    let oldDate = this.chrousValues[current];
    this.chrousValues[current]= data;
    this.chrousValues[next]= oldDate
    for(let index = 0; index < this.chrousValues.length ; index++){
      this.chrousData[index] = this.chrousValues[index].value;
    }
    console.log(this.chrousData)
    this.track.lyrics.chorus =  this.chrousData;
    this.trackChange.emit(this.track);
  }

  removeBridge(i){
    this.bridgeData = []
    this.bridgeValues.splice(i,1);
    for(let index = 0; index < this.bridgeValues.length ; index++){
      this.bridgeData[index] = this.bridgeValues[index].value;
    }
    console.log(this.bridgeValues,this.bridgeData)
    this.track.lyrics.bridge =  this.bridgeData;
    this.trackChange.emit(this.track);
  }

  addBridge(){
    this.bridgeValues.push({value: ""});
  }
  movedownBridge(current, next){
    let data = this.bridgeValues[next];
    let oldDate = this.bridgeValues[current];
    this.bridgeValues[current]= data;
    this.bridgeValues[next]= oldDate
    for(let index = 0; index < this.bridgeValues.length ; index++){
      this.bridgeData[index] = this.bridgeValues[index].value;
    }
    console.log(this.bridgeData)
    this.track.lyrics.bridge =  this.bridgeData;
    this.trackChange.emit(this.track);
  }
  moveupBridge(current, next){
    let data = this.bridgeValues[next];
    let oldDate = this.bridgeValues[current];
    this.bridgeValues[current]= data;
    this.bridgeValues[next]= oldDate
    for(let index = 0; index < this.bridgeValues.length ; index++){
      this.bridgeData[index] = this.bridgeValues[index].value;
    }
    console.log(this.bridgeData)
    this.track.lyrics.bridge =  this.bridgeData;
    this.trackChange.emit(this.track);
  }
  resetValues(){
    this.track.Instrumentdescriptions =[]
    this.track.instruments = [];
    this.track.version_instruments=[];
    this.track.instrumenttracks_set=[];
    this.track.lyrics ={}
    this.track.network_references = [];
    this.track.version_network_references=[];
    this.track.networkreferencetracks_set = []
    this.track.sonic_references = [];
    this.track.sonicreferencetracks_set = [];
    this.track.vocal = [];
    this.track.version_vocal = [];
    this.track.artist_references = []; 
    this.track.band_references = [];
    this.track.version_band_references = [];
    this.track.tv_references = [];
    this.track.version_tv_references=[];
    this.track.track_sonic_reference =[]
    this.track.version_sonic_reference=[]
    
    this.track.Instrumentsub_category =[];
    this.track.Instrumentdescriptions = [];
    this.track.Instrumentsuggesstion = [];
    this.track.Instrumentnew_suggestion = [];
  
    this.track.network_referencesSub_category=[];
    this.track.network_referencesDescriptions= [];
    this.track.network_referencesSuggesstion=[];
    this.track.network_referencesNew_suggestion=[];
  
    this.track.tv_referencesSub_category=[];
    this.track.tv_referencesDescriptions= [];
    this.track.tv_referencesSuggesstion=[];
    this.track.tv_referencesNew_suggestion=[];
 
    this.track.Vocalssub_category=[];
    this.track.Vocalsdescriptions = [];
    this.track.Vocalssuggesstion =[];
    this.track.Vocalsnew_suggestion =[];
    this.track.Instrumentsub_category =[]
    this.inDescription =[]
    this.nameSuggestion =[]
    this.track.Instrumentnew_suggestion =[]
    this.versionChange.emit(this.version);
    this.trackChange.emit(this.track)
  }
  networkRef(track){
    let net = track.support_network_references? track.support_network_references: []
    let data = [...track.network_references,...net]
   
    return data
  }
  getTvData(track){
    let tv = track.support_tv_references ? track.support_tv_references: []
    let data = [...track.tv_references,...tv]
  
    return data
  }
  getSonicData(track){
    let tv = track.support_sonic_references ? track.support_sonic_references: []
    let data = [...track.sonic_references,...tv]
   
    return data
  }
  getBandData(track){
    let tv = track.support_band_references ? track.support_band_references: []
    let data = [...track.band_references,...tv]
    return data
  }

  networkRefClick() {    
    if (!this.autoComplete1.panelOpen) {
      this.autoComplete1.openPanel()
    }
  }
  programRefClick() {
    if (!this.autoComplete4.panelOpen) {
      this.autoComplete4.openPanel()
    }
  }

  sonicRefClick() {
    if (!this.autoComplete2.panelOpen) {
      this.autoComplete2.openPanel()
    }
  }
  contextRefClick() {
    if (!this.autoComplete6.panelOpen) {
      this.autoComplete6.openPanel()
    }
  }
}


