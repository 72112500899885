import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  @Input()
  color: string = '#FFF';

  //provide optional text with loader
  @Input()
  textDisplay: string | null = null;

  //make loader smaller (half size)
  @Input()
  isHalfSize: boolean = false;

  constructor(private changeRef: ChangeDetectorRef) {}

  ngOnInit() {
    var test = this.textDisplay;
    // this.changeRef.detectChanges();
  }
}
