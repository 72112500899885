import {ComponentRef, Injectable, Injector, InjectionToken} from '@angular/core';
import {ComponentType, Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal, PortalInjector} from '@angular/cdk/portal';
import {SliderDialogContainerComponent} from '../slider-dialog-container/slider-dialog-container.component';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

import { filter, take } from 'rxjs/operators';

export const CONTAINER_DATA = new InjectionToken<DialogConfig>('CONTAINER_DATA');

@Injectable({
  providedIn: 'root'
})
export class SliderDialogService {

  componentRef: ComponentRef<any>;
  onClose: (val:any)=>any;
  currentFile:any;
  constructor(private overlay: Overlay, private injector: Injector) {}
  public getTrackCreationTypeSubject = new BehaviorSubject(null);
  public getReplaceTrackVersionSubject = new BehaviorSubject(null);
  public getReplaceTrackFileSubject = new BehaviorSubject(null);
  currentTrackVersions: any[] = [];
  getTrackType(): Observable<any> {
    return this.getTrackCreationTypeSubject.asObservable();
  }
  getReplaceTrackVersion(): Observable<any> {
    return this.getReplaceTrackVersionSubject.asObservable();
  }
  getReplaceTrackFile(): Observable<any> {
    return this.getReplaceTrackFileSubject.asObservable();
  }
  public open( component: ComponentType<any>,config?: DialogConfig, onClose: (val:any)=>any = null){
    config = config || { width: "auto", title: "" };
    const positionStrategy = this.overlay
      .position()
      .global()
      .top("0")
      .right("0");
    const overlay = this.overlay.create({
      hasBackdrop: true,
      positionStrategy,
      panelClass: config.panelClass || "dialog-container",
      width: config.width,
      maxWidth: "90vw",
      height: "100vh"
    });

    const dialogPreview = new ComponentPortal(SliderDialogContainerComponent);
    const dialogContainerRef = overlay.attach(dialogPreview);
    dialogContainerRef.instance.dialogTitle = config.title;
    const dialogRef = new DialogRef<any>(overlay);

    const injector = this.createInjector(config);
    const c = new ComponentPortal(component, null, injector);
    dialogContainerRef.instance.selectedPortal = c;
    dialogRef.componentInstance = dialogContainerRef.instance.attach();

    this.applyDialogProperties(dialogContainerRef, overlay, config);
    this.onClose = onClose;
    return dialogRef;
  }

  public close(val: any) {
    if (this.componentRef && this.componentRef.instance && this.componentRef.instance.startExitAnimation) {
      this.componentRef.instance.startExitAnimation()
      this.componentRef = null;
      if (this.onClose) {
        this.onClose(val);
      }
    }
  }

  private applyDialogProperties(
    componentRef: ComponentRef<any>,
    overlayRef: OverlayRef,
    config: DialogConfig
  ) {
    this.componentRef = componentRef;
    componentRef.instance.containerEvent.subscribe(e => {
      if (e.key === "CLOSE") {
        // componentRef.instance.startExitAnimation();
        overlayRef.dispose();
      }
    });
    if (!config.disableClose) {
      overlayRef.backdropClick().subscribe(() => componentRef.instance.startExitAnimation());
    }
  }

  private createInjector(dialogConfig: DialogConfig): PortalInjector {
    const injectorMap = new WeakMap();
    injectorMap.set(CONTAINER_DATA, dialogConfig);
    return new PortalInjector(this.injector, injectorMap);
  }
}

export interface DialogConfig {
  width?: string;
  panelClass?: string;
  disableClose?: boolean;
  autoFocus?: boolean;
  title: string;
  data?: {[key:string]:any}
}

export class DialogRef<T> {

  dialogComponentInstance: SliderDialogContainerComponent;


  private compInstance: ComponentRef<T>;

  constructor(private overlayRef: OverlayRef) {}

  get componentInstance() {
    return this.compInstance;
  }

  set componentInstance(c: ComponentRef<T>) {
    this.compInstance = c;
  }

  public close() {
    this.overlayRef.dispose();
  }


}
