import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})
export class TrackDetailsService {

  /**
   * Represents the available list of options for Keys when creating/editing a track
   * DO NOT CHANGE THE ORDER OF THIS LIST - IT IS NECESSARY FOR THE AUTO-KEY DETECTOR PROCESS
   */
  public keyList = [
    "C",
    "C#/Db",
    "D",
    "D#/Eb",
    "E",
    "F",
    "F#/Gb",
    "G",
    "G#/Ab",
    "A",
    "A#/Bb",
    "B",
    "Cm",
    "C#m/Dbm",
    "Dm",
    "D#m/Ebm",
    "Em",
    "Fm",
    "F#m/Gbm",
    "Gm",
    "G#m/Abm",
    "Am",
    "A#m/Bbm",
    "Bm"
  ]

  //every value in this object must have a lowercase property name
  private keyMapping: { [key: string]: string } = {
    // Minor keys
    "c minor": "cm",
    "c# minor": "c#m",
    "db minor": "c#m",
    "d minor": "dm",
    "d# minor": "d#m",
    "eb minor": "d#m",
    "e minor": "em",
    "f minor": "fm",
    "f# minor": "f#m",
    "gb minor": "f#m",
    "g minor": "gm",
    "g# minor": "g#m",
    "ab minor": "g#m",
    "a minor": "am",
    "a# minor": "a#m",
    "bb minor": "a#m",
    "b minor": "bm",

    // Major keys
    "c major": "c",
    "c# major": "c#/db",
    "db major": "c#/db",
    "d major": "d",
    "d# major": "d#/eb",
    "eb major": "d#/eb",
    "e major": "e",
    "f major": "f",
    "f# major": "f#/gb",
    "gb major": "f#/gb",
    "g major": "g",
    "g# major": "g#/ab",
    "ab major": "g#/ab",
    "a major": "a",
    "a# major": "a#/bb",
    "bb major": "a#/bb",
    "b major": "b"
  };

  constructor(private httpService: HttpClient) { }


  getGenresList(): Observable<any> {
    return this.httpService.get('../../assets/genre.json');
  }

  getKeyFromList(key: string): string {

    //we must convert the key to match the options available
    key = this.convertKeyToOptionFormat(key);

    var response = '';

    // first, look for exact match
    for (let i = 0; i < this.keyList.length; i++) {

      if (this.keyList[i].toLowerCase() === key.toLowerCase()) {
        response = this.keyList[i];
        break; // Exit the loop once we find a match
      }
    }

    if (response == '') {
      //next, check for key as a substring in values containing '/'
      //The ordering the keyList is important (ex. "F#" will be found from "F#/Gb" before "F#m/Gbm" is evaluated)
      for (let i = 0; i < this.keyList.length; i++) {
        if (this.keyList[i].toLowerCase().includes('/') && this.keyList[i].toLowerCase().includes(key.toLowerCase())) {
          response = this.keyList[i];
          break; // Exit the loop once we find a match
        }
      }
    }

    return response;
  }

  /**
   * Since the auto-key detector will return values like "C minor",
   * We need to convert this to match the available options from the keyList
   * Casing is not important
   * @param key 
   */
  convertKeyToOptionFormat(key: string): string {

    var response = '';

    //first - if we have a direct match, go ahead and return that value
    response = this.keyList.find(item => item.toLowerCase() === key.toLowerCase());

    if (response)
      return response;

    //no direct match, search using key mapping
    // Normalize the input string
    const normalizedKey = key.toLowerCase().replace(/-/g, ' ').replace(/[^a-z0-9 ]/g, '').replace(/\s+/g, ' ').trim();

    // Check if the key is in the mapping object
    if (this.keyMapping[normalizedKey]) {
      return this.keyMapping[normalizedKey];
    }
  }
}
