import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  AfterViewInit,
  ViewChild,
  EventEmitter,
  AfterContentChecked,
  ChangeDetectorRef,
} from '@angular/core';
import { MatSort, MatSortable } from '@angular/material/sort';
import {
  trigger,
  style,
  animate,
  transition,
  state,
} from '@angular/animations';
import { Observable, Subscription, BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { Track } from '../models/track.model';
import { TrackVersion } from '../models/trackversion.model';
import { User } from '../models/user.model';
import { PlayerService, PlayingItem } from '../services/player.service';
import { DialogService } from '../services/dialog.service';
import { SliderDialogService } from '../services/slider-dialog.service';
import { TrackStatusValue } from '../models/trackstatus-value.model';
import { TrackStatusPipe } from '../pipes/trackstatus.pipe';
import { EditTrackComponent } from '../edit-track/edit-track.component';
import { EditTrackVersionComponent } from '../edit-track-version/edit-track-version.component';
import { TrackService } from '../services/track.service';
import { EditNewTrackComponent } from '../edit-new-track/edit-new-track.component';
import { MenuListService } from '../services/menu-list.service';
import { EditNewTrackVersionComponent } from '../edit-new-track-version/edit-new-track-version.component';


declare type TrackListDataSource = Observable<ReadonlyArray<Track>>;

export class TracklistRow {
  constructor(
    public track: Track,
    public version: TrackVersion,
    public currentlyPlayingVersion: TrackVersion,
    public main: boolean,
    public playing: boolean = false,

  ) { }
}

@Component({
  selector: 'app-track-list',
  templateUrl: './track-list.component.html',
  styleUrls: ['./track-list.component.scss'],
  animations: [
    trigger('rowInOut', [
      transition(':enter', [
        style({
          opacity: '0',
          height: '0',
          paddingTop: '0',
          overflow: 'hidden',
          paddingBottom: '0',
        }),
        animate(
          '0.7s cubic-bezier(0.19, 1, 0.22, 1)',
          style({
            opacity: '1',
            height: '*',
          })
        ),
      ]),
      transition(':leave', [
        style({
          opacity: '1',
          height: '*',
          overflow: 'hidden',
        }),
        animate(
          '0.7s cubic-bezier(0.19, 1, 0.22, 1)',
          style({
            opacity: '0',
            height: '0',
            paddingTop: '0',
            paddingBottom: '0',
          })
        ),
      ]),
    ]),
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', overflow: 'hidden' })
      ),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
  providers: [TrackStatusPipe],
})
export class TrackListComponent implements OnInit, OnDestroy, AfterViewInit {
  disableBtn: any = 'Disabled';
  denieBtn: any = 'Denied';
  approveBtn: any = 'Approved';
  pendingApproveBtn: any = 'Pending Approval';
  opened:number=0;
  openedVersion: number = 0;
  pendingBtn: any = 'Pending';
  @Input()
  set dataSource(data: any[]) {

    this._dataSource = combineLatest([data, this.expandedTrack, this._playerService.isPlaying,
      this._playerService.currentlyPlaying]).pipe(
        map(([tracks, expandedTrack, isPlaying, currentlyPlaying]) => {
          let retval: TracklistRow[] = [];
          tracks.forEach((t) => {
            if (currentlyPlaying && currentlyPlaying.track && expandedTrack && currentlyPlaying.track.id !== expandedTrack?.id) {
              this.expandTrack(currentlyPlaying.track);
            }
            for(let j=0;j<t.versions.length;j++){
              t.versions[j].versionTitle = t.versions[j].title;
            }
            for (let i = 0, tot = t.versions.length; i < tot; i++) {
              let v = t.versions[i];
              let row = new TracklistRow(t, v, currentlyPlaying && currentlyPlaying.version, i == 0, currentlyPlaying && currentlyPlaying.track.id == t.id && isPlaying);
              retval.push(row);
              break;
            }
          });
          return retval;
        })
      );
    if (this._dataSourceSubscription) {
      this._dataSourceSubscription.unsubscribe();
    }
    this._dataSourceSubscription = combineLatest([
      this.expandedTrack,
      this._playerService.currentlyPlaying
    ]).subscribe(([expandedTrack, currentlyPlaying]) => {
      if (!currentlyPlaying || !currentlyPlaying.track || !currentlyPlaying.version) {
        this._playerService.updatePlaybackContextList([]);
        return;
      }
      if (currentlyPlaying.track.id != (expandedTrack && expandedTrack?.id) && expandedTrack?.versions && expandedTrack?.versions.length > 0) {
        this._playerService.play(expandedTrack, expandedTrack.versions[0]);
      }
      this._playerService.updatePlaybackContextList( expandedTrack?.versions && expandedTrack?.versions?.map(v => new PlayingItem(expandedTrack, v)));
    });
  }

  @Output()
  playingTrack = new EventEmitter<Track>();

  @Output()
  playingTrackVersion = new EventEmitter<TrackVersion>();

  @Output()
  collapseMusicPlayer = new EventEmitter<null>();

  @Output()
  sortChange: EventEmitter<MatSortable> = new EventEmitter<MatSortable>();

  @Output()
  listChange: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  set sortValue(data: string) {
    const start = data && data[0] === '-' ? 'desc' : 'asc';
    const id = data && data[0] === '-' ? data.substring(1) : data || 'id';
    // console.log('sortdata---',start,id)
    this._sortValue = { id, start } as MatSortable;
  }

  get listDataSource() {
    return this._dataSource;
  }

  expandedTrack: Observable<Track> = null;
  expandedTrackSubject = new BehaviorSubject<Track>(null);
  currentUser: User;

  private _isPlaying: boolean = false;
  wavePlay: boolean = true;
  private _currentlyPlaying: PlayingItem;
  private _dataSource: Observable<ReadonlyArray<TracklistRow>>;
  private _subscriptions: Subscription[] = [];
  private _dataSourceSubscription: Subscription = null;
  private _sortValue: MatSortable;

  displayedColumns: string[] = [
    'title',
    'artist__name',
    'genre',
    'sub_genre',
    'created_at',
    'approved_at',
    'status',
    'action',
  ];

  status = {
    ACCEPTED: {
      id: 5,
      label: 'Accepted',
      color: '#E3B950'
    }
  };

  @ViewChild(MatSort) sort: MatSort;
  private _sortSubscription: Subscription = null;

  constructor(
    private cdref: ChangeDetectorRef,
    private _userService: UserService,
    private _playerService: PlayerService,
    private _dialogService: DialogService,
    private _sliderDialogService: SliderDialogService,
    public trackServ: TrackService,public list: MenuListService,
    private _trackStatusPipe: TrackStatusPipe, private cdr: ChangeDetectorRef,) {
    this.expandedTrack = this.expandedTrackSubject.asObservable();


  }

  ngOnInit(): void {
    this._subscriptions.push(
      this._userService.currentUserStream.subscribe((u) => {
        this.currentUser = u;
      }),
      this._playerService.currentlyPlaying.subscribe(data => {
        this._currentlyPlaying = data;
        // if (data && data.track) {
        //   if (!this.expandedTrackSubject.value || (data.track.id !== this.expandedTrackSubject.value.id)) {
        //     this.expandTrack(data.track);
        //   }
        // }
      }),
      this._playerService.isPlaying.subscribe(isPlaying => {
        this._isPlaying = isPlaying;
      }),
      this._playerService.isWavePlay.subscribe((p) => {
          this.wavePlay = p;
          console.log(this.wavePlay)
        })
    );


  }

  ngAfterViewInit() {

    this._sortSubscription = this.sort.sortChange.subscribe(() => {
      this.sortChange.emit({
        id: this.sort.active,
        start: this.sort.direction,
      } as MatSortable);
    });

    this.sort.sort(this._sortValue);


  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => {
      s.unsubscribe();
    });
    if (this._dataSourceSubscription) {
      this._dataSourceSubscription.unsubscribe();
    }
    if (this._sortSubscription) {
      this._sortSubscription.unsubscribe();
    }
    this._playerService.nextTab(true);
    this._playerService.updateWavePlay(false);
    this._playerService.updateIsPlaying(false);
    this._playerService.updateShowMusicPlayer(false);
    this._playerService.play(null, null);
  }

  listTrackBy(_, item: TracklistRow) {
    return item.track.id + '-' + item.version.id;
  }

  displayTrackInfo(track: Track, version: TrackVersion) {
    this.collapseMusicPlayer.emit();
    this.trackServ.trackPopData = track
    this._dialogService.displayTrackInfo(track, version);
  }

  rowClicked(track: Track) {
    if (this.trackIsExpanded(track)) {
     // this._playerService.nextTab(true);
      this._playerService.updateShowMusicPlayer(false);
      this._playerService.play(null, null);
      this.expandTrack(track);
      return;
    }
    this.expandTrack(track);
    if (!track.versions || track.versions.length <= 0) return;
   
   // let version = track.versions.find(v => v.is_full_version);
     let version = track.versions[0]
    if (!version) version = track.versions[0];
    this._playerService.updateShowMusicPlayer(true);
    this._playerService.updatePlaybackContextList(track.versions.map(v => new PlayingItem(track, v)));
    this._playerService.play(track, version);
  }

  expandTrack(track: Track) {
    if (
      this.expandedTrackSubject.value &&
      this.expandedTrackSubject.value.id == track.id
    ) {
      this.expandedTrackSubject.next(null);
      return;
    }
    this.expandedTrackSubject.next(track);
  }

  trackIsExpanded(track: Track) {
    return (
      this.expandedTrackSubject.value &&
      this.expandedTrackSubject.value.id == track.id
    );
  }

  playButtonPressed(track: Track, version: TrackVersion) {
    this._isPlaying = true;
    this._playerService.updateWavePlay(true);
    this._playerService.play(track, version);
  }

  pauseButtonPressed(track: Track, version: TrackVersion) {
    this._isPlaying = false;
    this._playerService.updateWavePlay(false);
    this._playerService.updateIsPlaying(false);
  }

  getTrackStatus(track: Track): TrackStatusValue {
    if (!track || !track.versions || track.versions.length <= 0) return null;
    let mainVersion = track.versions.find(v => v.is_full_version);
    if (!mainVersion) {
      mainVersion = track.versions[0];
    }
    return this._trackStatusPipe.transform(mainVersion.status);
  }

  getVersionStatus(version: TrackVersion): TrackStatusValue {
    if (!version || version.status == null) return null;
    return this._trackStatusPipe.transform(version.status);
  }

  openEditTrack(track: Track, version: TrackVersion) {
  
    var checkbox = document.getElementById('checkbox1');
    function handleCheckboxEvent(e) {
      e.preventDefault();
      if (e.keyCode === 32) {  // If spacebar fired the event
        this.checked = !this.checked;
      }
    }
    checkbox.addEventListener("click", handleCheckboxEvent, true);
    checkbox.addEventListener("keyup", handleCheckboxEvent, true);
    this._playerService.nextTab(true);
    this._playerService.updateViewType(true);
    this._playerService.updatePreviewPlaying(true);
     this._playerService.updateShowMusicPlayer(false);
     this._playerService.play(null, null);
     this.expandTrack(track);
    this.rowClicked(track)
    // this._playerService.updateIsPlaying(false)

    console.log(track)
    console.log(version)

    this._sliderDialogService.getTrackCreationTypeSubject.next("edit");
    this.collapseMusicPlayer.emit();
    if (version.is_full_version) {
      this.trackServ.versionPopData = version;
      this.trackServ.trackPopData = track
      if(this.opened !== track.id){

      this._sliderDialogService.open(EditNewTrackComponent, {
        width: "600px",
        title: "EDIT TRACK",
        panelClass: "edit-track-dialog",
        data: { track: track, version: version }
      }, (val) => {
        if (val) {
          this.listChange.emit()
        }
      });
      this.opened = track.id;
    }
    } else {
      this.trackServ.versionPopData = version;
      this.trackServ.trackPopData = track
      if(this.openedVersion !== version.id){
      this._sliderDialogService.open(EditNewTrackVersionComponent, {

        width: "600px",
        title: "EDIT TRACK VERSION",
        panelClass: "edit-track-dialog",
        data: { track: track, version: version }
      }, (val) => {
        if (val) {
          this.listChange.emit()
        }
      });
      this.openedVersion = version.id
    }
    }
    this._playerService.nextTab(true);
    this._playerService.updateWavePlay(false);
    this._playerService.updateIsPlaying(false);
    this._playerService.updateShowMusicPlayer(false);
    this._playerService.play(null, null);
    this.opened = 0;
    this.openedVersion = 0;
  }

  displayAddSongPlaylistDialog(track: Track, version: TrackVersion) {
    if (!track || !version) return;
    // this._dialogService.displayAddSongPlaylistDialog(track, version, '').subscribe(changed => {
    //   if (changed) {
    //     // this.trackChange.emit();
    //   }
    // });
    this._dialogService.displayAddTrackPlaylistDialog(track, version, this._dataSource,'').subscribe(changed => {
      if (changed) {
        // this.trackChange.emit();
      }
    });
  }

  displayDeleteTrackVersionDialog(track: Track, version: TrackVersion) {
    if (!track || !version) return;
    this._dialogService.displayDeleteTrackVersionDialog(track, version).subscribe(changed => {
      if (changed) {
        let index = track.versions.findIndex(v => v.id == version.id);
        track.versions.splice(index, 1);
      }
    });
  }

  isPlaying(version: TrackVersion) {
    if (!this._isPlaying || !this.wavePlay) return false;
    if (!this._currentlyPlaying || !this._currentlyPlaying.track || !this._currentlyPlaying.version) return false;
    return this._currentlyPlaying.version.id == version.id;
  }
}
