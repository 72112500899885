<form [formGroup]="userGroupForm">
<div class="dialog-container">
    <div class="dialog-header">
        <h4 class="dialog-heading">create New Group</h4>
        <button type="button" class="close-button" (click)="close()">
        <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                    <g id="Genre-Options">
                        <g id="Close" transform="translate(1439.000000, 32.000000)">
                            <path d="M24,0 L0,24" id="Path"></path>
                            <path d="M0,0 L24,24" id="Path"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        </button>
    </div>
<mat-dialog-content>
   
    <div class="dialog-body">
         <div class="grid-card">
            <div class="grid-item">
                <mat-form-field appearance="fill" class="group">
                    <mat-label>Group Name</mat-label>
                    <input matInput formControlName="group_name" >
                </mat-form-field>
                <p class="note cretenote">Please make title as short and descriptive as possible</p>
            </div>
        </div> 
        <div class="add-to-folder">
            <h6 class="label">Add Users
                <!-- <button type="button" class="action" (click)="deselectAllUser()"><small>Deselect all ({{selectedUserCount}})</small></button> -->
            </h6>
            <span></span>
            <input class="search" [(ngModel)]="searchUsers" [ngModelOptions]="{standalone: true}" placeholder="Find a user">
            <ul class="list">
                <li *ngFor="let client of list.availableUserDetails | filter : searchUsers; let idx = index">
                    <mat-checkbox (change)="selectUser(client.id, $event, idx)" [checked]= "client.checked">
                        <span class="check-text">{{client.name}}</span>
                    </mat-checkbox>
                </li>
            </ul>
        </div>
        <p class="note">Select users to add to your group</p>
        <mat-checkbox (change)="selectAllUser($event)" [checked]="allselectUser">
            <span class="check-text">Select All</span>
        </mat-checkbox>
        
    </div>
    <div class="dialog-body ">
        
        <div class="add-to-folder">
            <h6 class="label">Available Permissions
                <!-- <button type="button" class="action" (click)="deselectAll()"><small>Deselect all ({{selectedPermissionCount}})</small></button> -->
            </h6>
            <span></span>
            <input class="search" [(ngModel)]="searchPermission" [ngModelOptions]="{standalone: true}" placeholder="Find a permission">
            <ul class="list">
                <li *ngFor="let client of list.availableUserPermission | filter : searchPermission; let i = index">
                    <mat-checkbox (change)="mySelectOpt(client.id, $event, i)" [checked]= "client.checked"> 
                        <span class="check-text">{{client.name}}</span>
                    </mat-checkbox>
                </li>
            </ul>
        </div>
        <p class="note">Select or deselect or applicable permissions</p>
        <mat-checkbox (change)="selectAll($event)" [checked]="allselect">
            <span  class="check-text">Select All</span>
        </mat-checkbox>
        
        <div  class="form-col">
                <app-loader *ngIf="loader" color="#50e3c2"></app-loader>
            <button *ngIf="!loader" class="form-button" (click)="createUserGroup()" [disabled]="(this.permissionArray.length === 0 || this.userGroupForm.value.group_name.trim() == '' || this.userArray.length === 0)">Create Group</button>
            <button  *ngIf="!loader"class="form-button form-button-cancel" (click)="close()">Cancel</button>
        </div>
    </div>
</mat-dialog-content>
</div>
</form>
