<div class="main">
  <div class="sidebar">
      <div class="top-holder">
          <p>MIBE Publishing %</p>
          <div class="container-box">
            <p>MIBE PUBLISHING</p>
            <div class="split-box">
            <!-- <div style="border-right: none; padding-right:21px;">{{percentage}}</div> -->
            <div class="percent-data" style="border-right: none; padding-right:0px;"><span>   <input class="data" matInput [(ngModel)]="percentage" (keyup)="$event.preventDefault();$event.stopPropagation();value($event.target.value)" type="text" matInput  maxlength="3" pattern ='^[1-9][0-9]{3}$'
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"   (blur)="$event.preventDefault();$event.stopPropagation();onBlur($event.target.value)" ></span></div>
            <div style="border-left: none;" >%</div>
            <div (click)="minus()">-</div>
            <div style="border-right: none;" (click)="plus()">+</div></div>
          </div>

      </div>
      <div class="top-holder">
        <p>Audio Level</p>
        <div class="container-box">
          <p style="margin-top: 0;">MAX - AUDIO LEVEL</p>
          <input type="text" class="max-level" [(ngModel)]="max_audio_level" maxlength="6" (keydown)="validateNumber($event)" (input)="getInputValue($event.target.value)">
         </div>
         <div class="container-box">
          <p style="margin-top: 0;">MIN - AUDIO LEVEL</p>
          <input class="max-level" type="text" [(ngModel)]="min_audio_level" maxlength="6" (keydown)="validateNumber($event)" (input)="getInputValueMin($event.target.value)">
         </div>
         <button class="btn save-btn" (click)="save()" mat-button >Save</button>
      </div>
      
      </div>

      <div class="dashboard-panel">
        <!-- <h2>Dashboard</h2> -->
        <div class="data-blocks">
          <div class="data-block">
                <app-loader *ngIf="loading"></app-loader>
            <strong *ngIf="!loading">{{track_count}}</strong>
            <span>Tracks</span>
            <button [routerLink]="['/']" class="button" >Manage</button>
          </div>
          <div class="data-block">
                <app-loader *ngIf="loading"></app-loader>
            <strong *ngIf="!loading">{{artist_count}}</strong>
            <span>Artists</span>
            <button [routerLink]="['/user-mgmt/users/artists']"class="button" >Manage</button>
          </div>
          <div class="data-block">
                <app-loader *ngIf="loading"></app-loader>
            <strong *ngIf="!loading">{{client_count}}</strong>
            <span> Clients</span>
            <button [routerLink]="['/user-mgmt/users/clients']"class="button" >Manage</button>
          </div>
        </div>
        <div class="data-blocks" style="margin-bottom: 6%;">
          <div class="data-block">
                <app-loader *ngIf="loading"></app-loader>
            <strong *ngIf="!loading">{{show_count}}</strong>
            <span>shows</span>
            <button class="button" >Manage</button>
          </div>
          <div class="data-block">
                <app-loader *ngIf="loading"></app-loader>
            <strong *ngIf="!loading">{{genres_count}}</strong>
            <span>genres</span>
            <button [routerLink]="['/menu/data']" class="button" >Manage</button>
          </div>
          <div class="data-block">
                <app-loader *ngIf="loading"></app-loader>
            <strong *ngIf="!loading">{{sub_genres_count}}</strong>
            <span>  subgenres</span>
            <button [routerLink]="['/menu/data']" class="button" >Manage</button>
          </div>
        </div>
      </div>
 </div>