import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormGroup, FormControl} from '@angular/forms';
import { Track } from '../models/track.model';
import { GenreService } from '../services/genre.service';
import { TrackTypeService } from '../services/tracktype.service';
import { Subscription, combineLatest } from 'rxjs';
import { TrackType } from '../models/tracktype.model';
import { TrackService, GetTracksOptions } from '../services/track.service';
import { Platform } from '@angular/cdk/platform';

import { Genre } from '../models/genre.model';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { UserMenuService } from '../services/user-menu.service';
import { environment } from '../../../environments/environment';
import { MenuConfigService } from '../services/menu-config.service';
import { saveAs } from 'file-saver';
import { DialogService } from '../services/dialog.service';



interface QueryType {
  value: string;
  viewValue: string;
}

interface DialogData {
  tracks: Track[];
}

@Component({
  selector: 'app-export-options-dialog',
  templateUrl: './export-options-dialog.component.html',
  styleUrls: ['./export-options-dialog.component.scss']
})
export class ExportOptionsDialogComponent implements OnInit {
  maxDate = new Date();
  typeSelected:any;
  constructor(
    private dialogRef: MatDialogRef<ExportOptionsDialogComponent>,
    private _genreService: GenreService,
    private _trackTypeService: TrackTypeService,
    private _trackService: TrackService,
    public http: HttpClient,
    public api: UserMenuService,
    public config: MenuConfigService,
    public _DialogService:DialogService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.getAllTracks();
    this.getAllGnere()
  }

  loading = false;
  download = false;
  trackCount = 0
  GenreData = ''

  genres: Genre[] = [];
  selectedGenre = -1;

  mediaQueryTypes: {name:string, slug:string}[] = [
    {name: 'MTV', slug: 'mtv'},
    {name: 'Sound Mouse', slug: 'sound_mouse'},
    {name: 'Sound MIBE Miner', slug: 'sound_mibe_miner'},
    {name: 'Source Audio', slug: 'source_audio'},
    {name: 'Turner', slug: 'turner'},
    {name: 'Sound Miner', slug: 'sound_miner'},
    {name: 'NetMix', slug: 'netmix'},
    {name: 'BMAT', slug: 'bmat'},
    {name: 'NBC', slug: 'nbc'},
    {name: 'Music Mark', slug: 'music_mark'},
    {name: 'Prometheus', slug:'prometheus'},
    {name: 'Artist User', slug:'artistuser'},
    {name: 'Client User', slug:'clientuser'}
  ];
  selectedMediaQuery = '';
  dateChoices: string[] = ['All Tracks', 'Date Created','Date Updated', 'Date Approved'];
  selectedDateType: string = "All Tracks";

  selectMediaQuery = '';

  // range = new FormGroup({
  //   start: new FormControl(),
  //   end: new FormControl()
  // });


  // startDate: Date = null;
  // endDate: Date = null;
  private _startDate:any= null;
  private _endDate: any=null;
  get startDate() {
    return this._startDate;
  }
  set startDate(d: Date) {
    this._startDate = d
  }
  get endDate() {
    return this._endDate;
  }
  set endDate(d: Date) {
    this._endDate = d
  }


  private _subscriptions: Subscription[] = [];
public currentUser:any;
  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('userdata'))
    this.loading = true;
    this._subscriptions.push(
      combineLatest([
       this.result,
        this._genreService.getAllGenres(),
      ]).subscribe(([tracks, g]) => {
        this.trackCount = this.result['count'];
        this.genres = g;
        this.loading = false;
      }, (error) => {
        console.log(error);
        //alert("An error ocurred while getting the data for export. Check your connections and try again");
      })
    );
  }
  result:any[]
getAllTracks(){
  this.loading = true;
  let perm;
  let headers = new HttpHeaders();
  this.api.doGET(environment.apiURL + '/api/v1/track/filter',
  {
    headers: headers,
  }
).subscribe(t=>{
  this.result = t['results'];
  this.loading = false;
  })
}
  close() {
    this.dialogRef.close();
  }

  onGenreChange(ob) {
    this.selectedGenre = ob.value;
     for(let x of this.genres){
      if(x.id == ob.value){
        this.GenreData = x.name;
      }
     }
    this.setDownloadTracks();
  }
  sDate: any;
  EDate: any;
  changedate(e) {
    if (e) {
      var date = new Date(e);

      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      this.sDate = year + "-" + month + "-" + day
    }
    else {
      this.sDate = null
    }
  }
  changedateend(e) {
    if (e) {
      var date = new Date(e);

      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      this.EDate = year + "-" + month + "-" + day
    }
    else {
      this.EDate = null
    }
    
    if (this.sDate && this.EDate) {
    //  this.downloadAllReports(data)
    this.setDownloadTracks();
    }

  }
  dateTypeSelectd: any;
  dateTypeChange(e) {
    console.log(e)
    if (e) {
      this.dateTypeSelectd = e

    }
    else {
      this.dateTypeSelectd = null;
    }
  }
  DType: string = 'All Tracks'
  dateType(e) {
    this.typeSelected = e;
    if (e == 'All Tracks') {
      this.DType ='All Tracks'
    }
    if (e == 'Date Created') {
      this.DType = 'Date Created'
    }
    else if (e == 'Date Approved') {
      this.DType = "Date Approved";
    }
  }
  getCurrentTrackFilters(): GetTracksOptions {
    let options: GetTracksOptions = {
    //  limit: 0
    }
    if (this.selectedGenre != -1) {
      options.genreId = this.selectedGenre;
    }
    if (this.EDate) {
      options['end_date'] = this.EDate
    }

    if (this.sDate) {
      options['start_date'] = this.sDate
    }

    if (this.DType == 'Date Created') {
      options['created_at'] = true
    }
    if (this.DType == 'Date Approved') {
      options['approved_at'] = true;
    }
    if (this.typeSelected == 'Date Updated') {
      options['updated_at'] = true;
    }
    return options;
  }

  setDownloadTracks(data:any='') {
    this.filtertracks(this.getCurrentTrackFilters())
  }

  inputStartDate(ob) {
    this._startDate = new Date(ob.value);
    this.setDownloadTracks();
  }

  changeStartDate(ob) {
    this._startDate = new Date(ob.value);
    this.setDownloadTracks();
  }

  inputEndDate(ob) {
    this.endDate = ob.value ? new Date(ob.value) : null;
    this.setDownloadTracks();
  }

  changeEndDate(ob) {
    this.endDate = ob.value ? new Date(ob.value) : null;
    this.setDownloadTracks();
  }

  downloadTracks() {
    if (this.trackCount > 0) {
      this.download = true
      const downloadUrl = this._trackService.getTracksDownloadLink(this.getCurrentTrackFilters());
      // window.location.href = downloadUrl;
      this.downloadAllReports(this.getCurrentTrackFilters())
    }
  }

  downloadMediaQuery() {
    let mediaQuery = this.mediaQueryTypes.find(i => i.slug.toLowerCase() == this.selectedMediaQuery);
    this.selectMediaQuery = mediaQuery.name;
    if (this.selectedMediaQuery != '') {
      let data = this.getCurrentTrackFilters()
      // const downloadUrl = this._trackService.getMediaQueryDownloadLink(this.selectedMediaQuery, this.getCurrentTrackFilters());
      let query = { querytype: this.selectedMediaQuery }
      let downloadUrl = environment.apiURL + '/api/v1/track/mediaquery/?export=true';
      // window.open(requestURL)
      let headers = new HttpHeaders();
      this.http.get(`${downloadUrl}`, {
        responseType: 'arraybuffer',
        headers: headers,
        params: query
      }
      ).subscribe((user) => {
        //this.downLoadFile(response, "text/csv", this.selectMediaQuery)
        let data = user['message'] ? user['message'] : (user['details'] ? user['details'] : 'The report that you requested for has been sent to your email id')
        this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
          this.dialogRef.close()
        });
      });
      this.loading = false;
    }
  }


  downloadAllReports(e){
    this.loading = true;
    this.download = true;
  let headers = new HttpHeaders();
  if(e.start_date){
    var date = new Date(e.start_date);
    
    let day = date.getDate();
    let month = date.getMonth()+1;
    let year = date.getFullYear();
    e.start_date = year+"-"+month+"-"+day
  }
  if(e.end_date){
    var date = new Date(e.end_date);
    
    let day = date.getDate();
    let month = date.getMonth()+1;
    let year = date.getFullYear();
    e.end_date = year+"-"+month+"-"+day
  }
  e.flag = 'Genre'
  let downloadUrl = environment.apiURL + this.config.downloadTracks;
  let genre
  if(this.selectedGenre === -1){
    this.GenreData ="Genre"
  }
  if (this.selectedGenre != -1) {
    e.genreId = this.selectedGenre;
  }
  // else{
  //   e.genreId = this.GenreData
  // }
  // window.open(requestURL)
this.http.get(`${downloadUrl}`,{
  responseType: 'arraybuffer',
  headers:headers,
  params: e
} 

//  ).subscribe(response => this.downLoadFile(response, "text/csv", this.GenreData));
).subscribe((user) => {
  //this.downLoadFile(response, "text/csv", this.selectMediaQuery)
  let data = user['message'] ? user['message'] : (user['details'] ? user['details'] : 'The report that you requested for has been sent to your email id')
  this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
    this.dialogRef.close()

  });
});
  
  //   let headers = new HttpHeaders();
  //   this.http.get(`${environment.apiURL}'/api/v1/track/`,
  //   // {
  //   //   responseType: 'arraybuffer',headers:headers,
  //   // } 
  //    {
  //   headers: headers,
  //   perm : e
  // }
  //    ).subscribe(response => this.downLoadFile(response, "text/csv"));
  }
  downLoadFile(data: any, type: string, filename: any) {
    //let fileName = filename;
    let fileName = `${filename}.xlsx`;
    let blob = new Blob([data], { type: type});
     let url = window.URL.createObjectURL(blob);
    saveAs(url, fileName);
    this.loading = false; 
     this.download = false;
    // let pwa = window.open(url);
    // if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
    // }
  }
getAllGnere(){
  let headers = new HttpHeaders();
    
    this.api.doGET(environment.apiURL + '/api/v1/track/genre-dropdown',
    {
      headers: headers,
    
    }
  ).subscribe(t=>{
    let res;
    res = t
    this.genres = res
    })
}
filtertracks(e){
  if(e.start_date){
    var date = new Date(e.start_date);
    
    let day = date.getDate();
    let month = date.getMonth()+1;
    let year = date.getFullYear();
    e.start_date = year+"-"+month+"-"+day
  }
  if(e.end_date){
    var date = new Date(e.end_date);
    
    let day = date.getDate();
    let month = date.getMonth()+1;
    let year = date.getFullYear();
    e.end_date = year+"-"+month+"-"+day
  }
  this.loading = true;
  let headers = new HttpHeaders();
  if (e.start_date && e.end_date) {
    this.api.doGET(environment.apiURL + '/api/v1/track/filter',
    {
      headers: headers,
      params : e
    }
  ).subscribe(t=>{
    let res;
    res = t
    this.trackCount = res.count;
    this.loading = false;
    })
  }
}
}


