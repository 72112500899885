import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../environments/environment';
import { ComposerPRO } from '../models/composer-pro.model';
import { APIListResponse } from '../models/apiresponse.model';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ComposerPROService {

  constructor(
    private http: HttpClient,
    private _platform: Platform,
    private _transferState: TransferState
  ) { }

  getComposerPROs(): Observable<ComposerPRO[]> {
    // Check if album is cached from server
    const COMPOSER_PROS_KEY = makeStateKey<APIListResponse<Object>>('composerpros');
    if (this._transferState.hasKey(COMPOSER_PROS_KEY)) {
      const composerPROs = this._transferState.get<APIListResponse<Object>>(COMPOSER_PROS_KEY, null);
      this._transferState.remove(COMPOSER_PROS_KEY);
      return of(composerPROs.results.map(p => new ComposerPRO(p)));
    }
    let headers = new HttpHeaders();
    return this.http.get<APIListResponse<Object>>(
      environment.apiURL + '/api/v1/composerpros/',
      {
        headers: headers
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the moods
        if (!this._platform.isBrowser) {
          this._transferState.set(COMPOSER_PROS_KEY, res);
        }
      }),
      map((res) => {
        return res.results.map(p => new ComposerPRO(p));
      })
    );
  }

  getPROs() {
    let headers = new HttpHeaders();
    return this.http.get<APIListResponse<Object>>(
      environment.apiURL + '/api/v1/admin/users/get-pro-dropdown/',
      {
        headers: headers
      }
    ).pipe(
      tap(res => { 
      }),
      map((res) => {
        return res;
      })
    );
  }

  getShows(url, id:any='', search:any='') {
    if(url == 'edit'){
      url = environment.apiURL + '/api/v1/admin/users/'+id+'/get-user-shows/'
    }
    else{
      url = environment.apiURL + '/api/v1/admin/users/get-all-shows/'
    }
    if(search != ''){
      url = url +'?search='+search
    }
    let headers = new HttpHeaders();
    return this.http.get<APIListResponse<Object>>(
      url,
      {
        headers: headers
      }
    ).pipe(
      tap(res => { 
      }),
      map((res) => {
        return res;
      })
    );
  }
}
