<form [formGroup]="uploadForm">
    <div class="browse-btn1">
        <!-- <input type="file" formControlName="File" (change)="browseTrack($event.target.files,$event.target.value)" #fileInput multiple accept="application/audio" /> -->
        <input type="file" [(ngModel)]="File" *ngIf="!isBrowser"  [ngModelOptions]="{standalone: true}" (change)="selectFiles($event)" #fileInput multiple  accept=".wav" content-type="audio/wav" />
        <label for="fileDropRef" class="mb-0" *ngIf="!isBrowser">BROWSE</label>
    </div>
    <div class="upload-general">
        
        <div class="uploadtrack section">
            <p class="section-uploads">You may select multiple files in a folder. All files must be <strong>.wav </strong>files or they will not be accepted. You may continue to click ‘browse’ and select files from different folders and Upload or drag files here . When all selections have been made, click “Start Uploading”</p>
            <div class="mat-card-upload" *ngFor="let file of selectedFiles;let index = index"  >
                <div  type="file" id="file" [ngClass]="(!isUploaded) ? 'disabledElement' : ''">
                    <div class="upload-left">
                        <mat-progress-bar mode="determinate" [(value)]="progressInfos[index]" *ngIf="isUploading[index] && !isFailed[index]"></mat-progress-bar>
                        <strong style="color:#000!important;">{{ file.name }}</strong>
                        <p>{{ sizeOf(file.size) }}</p>
                    </div>
                </div>
                <div class="upload-right" [ngClass]="{'disabledElement': (isBrowser)}" (click)="removeTrack(index)" *ngIf="progressInfos[index]!=100 && !isFailed[index]">
                    <span style="margin-right:20px;">Remove</span>
                </div>
                <div class="upload-right" *ngIf="progressInfos[index]==100">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg>
                </div>
                <div class="upload-right" *ngIf="isFailed[index]"  (click)="removeTrack(index)">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FF0000" style="fill:red;"><path d="M0 0h24v24H0V0z" fill="none" opacity=".87"/><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm4.3 14.3c-.39.39-1.02.39-1.41 0L12 13.41 9.11 16.3c-.39.39-1.02.39-1.41 0-.39-.39-.39-1.02 0-1.41L10.59 12 7.7 9.11c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L12 10.59l2.89-2.89c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41L13.41 12l2.89 2.89c.38.38.38 1.02 0 1.41z"/></svg>
                </div>
            
            </div>
        
    
        </div> 
        <div>
        <button class="btn save-btn1" [ngClass]="{'disabledElement': (isBrowser)}" mat-button *ngIf="selectedFiles.length>0 && !isUploaded" (click)="uploadFiles()">start uploading</button>
        <button class="btn save-btn1" [disabled]="isContinue === true"  mat-button *ngIf="selectedFiles.length>0 &&  isUploaded" (click)="uploadFiles('continue')">Continue</button>
    </div>
    </div> 
</form>
<!-- 
<div class="loading-overlay" *ngIf="isBrowser">
    <app-loader color="#50e3c2"></app-loader>
  </div> -->