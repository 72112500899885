<div class="dialog-container green-theme manage-playlists-wrapper">
    <div class="dialog-header">
      <div class="title">
        <h4 class="dialog-heading">Manage Playlists - {{activeTab}}</h4>
      </div>
      <div class="actions">
        <div class="tabs">
            <span
              class="tab"
              (click)="switchTab('shared')"
              [ngClass]="{'active': activeTab === 'shared'}">Shared</span>
            <span
              class="tab"
              [ngClass]="{'active': activeTab === 'personal'}"
              (click)="switchTab('personal')">Personal</span>
        </div>
        <button type="button" class="close-button" (click)="close()">
            <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                stroke-linejoin="round">
                <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                <g id="Genre-Options">
                    <g id="Close" transform="translate(1439.000000, 32.000000)">
                    <path d="M24,0 L0,24" id="Path"></path>
                    <path d="M0,0 L24,24" id="Path"></path>
                    </g>
                </g>
                </g>
            </g>
            </svg>
        </button>
      </div>
    </div>
  
    <mat-dialog-content>
      
      <div class="dialog-body" >
        <h3 class="title">{{data.user.getFullName()}}</h3>
        <div class="add-to-folder form-container">
          <p class="label">{{activeTab}} Playlists <span *ngIf="activeTab === 'shared' && getCount" class="deselect" (click)="deselectPlaylist()"><small>Deselect all ({{getCount}})</small></span></p>
          <input id="myInput" class="search" #search *ngIf="activeTab === 'shared'"
            placeholder="Find a playlist" [(ngModel)]="searchPeram" (keyup)="searchPlaylist()">
            <input id="myInput" class="search"  *ngIf="activeTab === 'personal'"
            placeholder="Find a playlist" (keyup)="getFindPersonalList()">
            <app-loader *ngIf="activeTab === 'personal' && personalPlaylists.length == 0" color="#50e3c2"></app-loader>
          <ul id="checkBoxU2"  class="list" *ngIf="activeTab === 'personal' && personalPlaylists != 0">
            <li class="item" *ngFor="let playlist of personalPlaylists">
              <a (click)="close()" target="_blank"  href= "https://mibemusic.com/playlist/{{playlist.id}}" [queryParams]="{playlist: playlist.id}">{{playlist.name}}</a>

              <!-- <a (click)="close()" [routerLink]="['/playlists']" [queryParams]="{playlist: playlist.id}">{{playlist.name}}</a> -->
            </li>
          </ul>
          <app-loader *ngIf="activeTab === 'shared' && allPlaylist.length == 0" color="#50e3c2"></app-loader>
          <ul #scrollMe id="checkBoxUl" (scroll)="scrollHandler($event)" class="list" *ngIf="activeTab === 'shared' && allPlaylist.length != 0"> 
            <li class="item" *ngFor="let playlist of allPlaylist; let i = index">
              <mat-checkbox [checked]="playlist.checked" (change)="playlistChecked($event.checked, playlist, i)">
                <span class="check-text">{{playlist.name}}</span>
              </mat-checkbox>
             
            </li>
            <!-- <button  class="btn save-btn" style="padding: 0px;
            width: 100px;
            height: 25px;" (click)="loadMore()">Load More</button> -->
          </ul>
          <div class="no-groups" *ngIf="!searchingPlaylists && ((activeTab === 'personal' && personalPlaylists.length <= 0))">
            <p>There are currently no playlists available. </p>
          </div>
          <app-loader *ngIf="searchingPlaylists" color="#50e3c2"></app-loader>
        </div>
        <p class="note" *ngIf="activeTab === 'shared'">Select or Deselect playlists to remove or add them from clients visibility</p>
        <div class="form-col buttons" *ngIf="activeTab === 'shared'">
          <button class="form-button" (click)="saveSharedPlayList()" [disabled]="!canSubmit()">Save</button>
          <button class="form-button form-button-cancel" (click)="close()">Cancel</button>
        </div>
      </div>
    </mat-dialog-content>
</div>

