import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { MenuListService } from '../../shared/services/menu-list.service';
import { environment } from '../../../environments/environment';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
  selector: 'app-thesaurus',
  templateUrl: './thesaurus.component.html',
  styleUrls: ['./thesaurus.component.scss']
})
export class ThesaurusComponent implements OnInit {

  ThesaurusName: string = '';
  constructor(private dialogRef: MatDialogRef<ThesaurusComponent>,
    public api: UserMenuService, 
    public config: MenuConfigService,
    public list: MenuListService,
    public _DialogService:DialogService
  ) { }

  ngOnInit(): void {
  }
  close() {
    this.dialogRef.close();
  }
  create(){
    if(this.ThesaurusName == ''){
      //alert('Enter Thesaurus Name')
      return
    }
    let data = {
      name: this.ThesaurusName
    }
    let requestUrl = environment.apiURL + this.config.doThesaurus;
    this.api.doPOST(requestUrl,data).subscribe(x=>{
      this.close()
      this.list.getAllthesaurus();
    },error =>{
      // alert('This Thesaurus term already exists.')
      let data = error.error?.error ? error.error?.error : (error.error?.detail ?error.error?.detail : (error.error?.details ? error.error?.details:``) )
        this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
        });
        this.close()
    }
  )
  }

}
