import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../environments/environment';
import { APIListResponse } from '../models/apiresponse.model';
import { Activity } from '../models/activity.model';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export interface GetActivitiesOptions {
  userId?:number;
  action?:string;
  limit?:number;
  offset?:number;
}

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  constructor(
    private http: HttpClient,
    private _platform: Platform,
    private _transferState: TransferState
  ) {
  }

  getActivities(options: GetActivitiesOptions): Observable<APIListResponse<Activity>> {
    let params = {};
    if (options.userId) {
      params['user'] = `${options.userId}`;
    }
    if (options.action) {
      params['action'] = options.action;
    }
    if (options.limit) {
      params['limit'] = `${options.limit}`;
    }
    if (options.offset) {
      params['offset'] = `${options.offset}`;
    }

    let p = new HttpParams({ fromObject: params });
    // Check if subscription is cached from server
    const ACTIVITIES_KEY = makeStateKey<APIListResponse<Object>>('activities-' + p.toString());
    if (this._transferState.hasKey(ACTIVITIES_KEY)) {
      const activities = this._transferState.get<APIListResponse<Object>>(ACTIVITIES_KEY, null);
      this._transferState.remove(ACTIVITIES_KEY);
      return of({
        next: activities.next,
        previous: activities.previous,
        count: activities.count,
        results: activities.results.map((a) => new Activity(a))
      });
    }
    let headers = new HttpHeaders();
    return this.http.get<APIListResponse>(
      environment.apiURL + '/api/v1/admin/useractivity/',
      {
        headers: headers,
        params: p
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the genre
        if (!this._platform.isBrowser) {
          this._transferState.set(ACTIVITIES_KEY, res);
        }
      }),
      map( (res) =>  {
          return {
            next: res.next,
            previous: res.previous,
            count: res.count,
            results: res.results.map((a) => new Activity(a))
          };

        }
      )
    );
  }

  recordActivity(action:string, content_id:number=null, additional_data:any=null): Observable<Activity> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<APIListResponse>(
      environment.apiURL + '/api/v1/useractivity/log/',
      {
        action: action,
        content_object: {id: content_id},
        additional_data: additional_data
      },
      {
        headers:headers
      }
    ).pipe(map((res)=> {
      if (res.results && res.results.length > 0) {
        return new Activity(res.results[0]);
      }
      return null;
    }));
  }

}
