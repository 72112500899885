import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserService } from '../shared/services/user.service';
import { Router } from "@angular/router";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  token: any;
  constructor(
    public inj: Injector,
    private router: Router,
  ) {


  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const auth = this.inj.get(UserService);
    const authToken: any = auth.token;
    console.log("JWT", authToken)

    //check to see if we're already on the login page (an api call is made to check the email address / username and we don't need authorization for this)
    //also check if logout call is being made, allow this to avoid infinite loop
    if (this.router.url.includes('/login') || request.url.includes('api/v1/user/logout/') || request.url.includes('api/v1/user/reset/')) {
      return next.handle(request.clone())
    }

    else if (auth.token && auth.token != 'null') {

      //check for timeout
      const expiry = (JSON.parse(atob(authToken.split('.')[1]))).exp;
      if ((expiry * 1000 < Date.now())) {
        auth.logout_V2().subscribe(res => {
          auth._currentUser = null;
          auth._currentUserSubject.next(auth._currentUser);

          auth.currentUser = null;
          localStorage.setItem("token", null);
          localStorage.setItem("userdata", null);
          this.router.navigate(['/login']);
          window.location.reload();
        }
        );
      }
      else {
        return next.handle(
          request.clone({
            setHeaders: {
              Authorization: `Bearer ${auth.token}`
            }
          })
        )
      }

    }
    else {
      //we don't have our token, we're not on the login page and we are not calling the logout api call, logout and route to login page
      // if (auth.getCurrentUser()) {
      auth.logout_V2().subscribe(res => {
        auth._currentUser = null;
        auth._currentUserSubject.next(auth._currentUser);

        auth.currentUser = null;
        localStorage.setItem("token", null);
        localStorage.setItem("userdata", null);
        this.router.navigate(['/login']);
        window.location.reload();
      });

    }

  }
}
