import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DeleteEditUserDialogueComponent } from '../delete-edit-user-dialogue/delete-edit-user-dialogue.component';
import { FormBuilder } from '@angular/forms';
import { MenuListService } from '../../shared/services/menu-list.service';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { environment } from '../../../environments/environment';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
  selector: "app-delete-edit-group-permission-dialogue",
  templateUrl: "./delete-edit-group-permission-dialogue.component.html",
  styleUrls: ["./delete-edit-group-permission-dialogue.component.scss"],
})
export class DeleteEditGroupPermissionDialogueComponent implements OnInit {
  currentUserGroups: any;
  selectedArray: any;
  selectedArrayList: any;
  userArray: any;
  usercount:any
  loaderContent: boolean = true;
  searchText = "";
  loadingPermissionUpdate: boolean = false;
  allselectPermission: boolean = false;
  userDetails: any;
  selectedPermissionCount: any = 0;
  constructor(
    private dialogRef: MatDialogRef<DeleteEditGroupPermissionDialogueComponent>,
    public FormUserGroup: FormBuilder,
    public list: MenuListService,
    public api: UserMenuService,
    public config: MenuConfigService,public dialog: DialogService,
  ) {
    
    this.list.userListPermission = false;
  }
  ngOnInit(): void {
    this.allselectPermission = false;
    this.list.getAdminUserGroups().subscribe((x) => {
      if (x) {
        this.list.getSingleUserGroups(x);
        this.list.getAvailableUserPermission();
        this.userDetails = x;
      }
    });
  }
  
countNumber = (array,value) => {
  this.selectedPermissionCount = array.filter(n => n.checked == value).length
}
  close() {
    this.dialogRef.close();
  }
  mySelectOpt(id, evt, index) {
    console.log(index)
    if (evt.checked) {
      this.list.availableUserPermission[index].checked = true;
    } else {
      this.list.availableUserPermission[index].checked = false;
    }
    for (var i = 0; i < this.list.availableUserPermission.length; i++) {
      if (this.list.availableUserPermission[index].checked && !this.list.managePermissionArrayGroups.includes(this.list.availableUserPermission[index].id)) {
        this.list.managePermissionArrayGroups.push(id);
        console.log(this.list.managePermissionArrayGroups)
        this.list.countOfUserGroups = this.list.managePermissionArrayGroups.length;
        if (
          this.list.managePermissionArrayGroups.length ==
          this.list.availableUserPermission.length
        ) {
          this.allselectPermission = true;
        }
        break;
      } else {
        this.list.managePermissionArrayGroups = this.list.managePermissionArrayGroups.filter(
          (x) => x !== id
        );
        this.list.countOfUserGroups = this.list.managePermissionArrayGroups.length;
        this.allselectPermission = false;
        break;
      }
    }
  }
  
  getFindPermissionList() {
    var input, filter, ul, li, span, i, txtValue;
    input = document.getElementById('myInput');
    filter = input.value.toUpperCase();
    ul = document.getElementById("checkBoxUl");
    li = ul.getElementsByTagName('li');
  
    for (i = 0; i < li.length; i++) {
      span = li[i].getElementsByTagName("span")[0];
      txtValue = span.textContent || span.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
}
  deselectAll() {
    this.list.countOfUserGroups = 0;

    for (let x of this.list.availableUserPermission) {
      x.checked = false;
      this.allselectPermission = false;
      this.list.managePermissionArrayGroups = [];
    }
    this.selectedArrayList = this.list.availableUserPermission;
  }

  selectAll(evt) {
    if (this.allselectPermission) {
      for (let x of this.list.availableUserPermission) {
        x.checked = false;
        this.allselectPermission = false;
        this.list.managePermissionArrayGroups = [];
        this.list.countOfUserGroups = 0;
      }
      this.selectedArrayList = this.list.availableUserPermission;
    } else {
      for (let x of this.list.availableUserPermission) {
        x.checked = true;
        this.allselectPermission = true;
        if (!this.list.managePermissionArrayGroups.includes(x.id)) {
          this.list.managePermissionArrayGroups.push(x.id);
          this.list.countOfUserGroups = this.list.managePermissionArrayGroups.length;
        }
      }
      this.selectedArrayList = this.list.availableUserPermission;
    }
  }


  updatePermission(user) {
    let postUserBody = {
      permissions: this.list.managePermissionArrayGroups,
    };
    let requestURL = environment.apiURL + this.config.managePermission;
    requestURL = requestURL.replace("{group_id}", user);
    this.api.doPUT(requestURL, postUserBody).subscribe(
      (result: any) => {
        this.close();
        this.list.getAllGroups(1)
        this.loadingPermissionUpdate = false;
      },
      (error) => {
        this.loadingPermissionUpdate = false;
        let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
        this.dialog.UserUploadPopupConfirmPopup(data).subscribe(changed => {
        });
        this.close();
        //alert(error.message)
      }
    );
  }
}
