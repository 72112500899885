import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { AllowedCoWriters } from '../models/allowedcowriters.model';
import { PhoneNumber, PhoneNumberTypes } from '../models/phonenumber.model';
import { UserPRO } from '../models/user-pro.model';
import { User } from '../models/user.model';
import {Tos} from '../models/tos.model'
import { CONTAINER_DATA, DialogConfig, SliderDialogService } from '../services/slider-dialog.service';
import { UserService } from '../services/user.service';
import { ComposerPROService } from '../services/composer-pro.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { FormControl, Validators } from '@angular/forms';
import { GenreService } from '../services/genre.service';
import { ComposerPRO } from '../models/composer-pro.model';
import { Genre } from '../models/genre.model';
import { MatSelectChange } from '@angular/material/select';
import { startWith, debounceTime, distinctUntilChanged, tap, switchMap } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserMenuService } from '../services/user-menu.service';
import { MenuConfigService } from '../services/menu-config.service';
import { DialogService } from '../services/dialog.service';


export class TOSListRow {
  constructor(
    public tos: string, //TODO: fill with real tos
  ) {}
}

export class PaymentListRow {
  constructor(
    public payment: string, //TODO: fill with real payment info
  ) {}
}

@Component({
  selector: "app-manage-artist-account",
  templateUrl: "./manage-artist-account.component.html",
  styleUrls: ["./manage-artist-account.component.scss"],
  animations: [
    trigger("rowInOut", [
      transition(":enter", [
        style({
          opacity: "0",
          height: "0",
          paddingTop: "0",
          overflow: "hidden",
          paddingBottom: "0",
        }),
        animate(
          "0.7s cubic-bezier(0.19, 1, 0.22, 1)",
          style({
            opacity: "1",
            height: "*",
          })
        ),
      ]),
      transition(":leave", [
        style({
          opacity: "1",
          height: "*",
          overflow: "hidden",
        }),
        animate(
          "0.7s cubic-bezier(0.19, 1, 0.22, 1)",
          style({
            opacity: "0",
            height: "0",
            paddingTop: "0",
            paddingBottom: "0",
          })
        ),
      ]),
    ]),
  ],
})
export class ManageArtistAccountComponent implements OnInit, OnDestroy {
  Selects = "Select";
  Selectsgeners = "Select";

  activeTab: "general" | "tos" | "payment" = "general";

  loading: boolean = false;

  user: User;
  userPRO: UserPRO;
  password: string = "";
  isNewUser: boolean = false;
  errorMessage: string;

  primaryPhoneControl: FormControl = new FormControl();
  phoneControllers: FormControl[] = [];

  coWriterFirstName: string = "";
  coWriterLastName: string = "";
  coWriterEmail: string = "";

  username: string = "";
  today = new Date();
  tosDataSource: MatTableDataSource<Tos>;
  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.tosDataSource.sort = sort;
  }
  tosDisplayedColumns = ["version", "last_sent", "status", "file"];
  loadingTos: boolean = false;

  paymentsDataSource: Observable<ReadonlyArray<PaymentListRow>>;
  paymentsDisplayedColumns = ["amount", "status", "document"];
  loadingPayments: boolean = false;

  composerPROs: ComposerPRO[] = [];
  artistPROs: any = [];
  genres: Genre[] = [];
  editGenres: any = [];
  addVisible:any=[];
  userproData: any;

  proFilterCtrl = new FormControl();
  filteredPROOptions: ReplaySubject<ComposerPRO[]> = new ReplaySubject<
    ComposerPRO[]
  >(1);
  searchingPRO = false;

  genreFilterCtrl = new FormControl();
  filteredGenreOptions: ReplaySubject<Genre[]> = new ReplaySubject<Genre[]>(1);
  searchingGenre = false;

  get phoneNumberTypes(): string[] {
    return Object.keys(PhoneNumberTypes).map((key) => PhoneNumberTypes[key]);
  }

  phoneNumber: any = '';
  currentValue: any;
  private _subscriptions: Subscription[] = [];
  isPassChange = false;
  socialSercurity: FormControl
  oldEmail:string= ''
  oldUser:string =''
  isUserExist: number = 0;
  isEmailExist: number = 0;
  public numberPattern = { "0": { pattern: new RegExp("^[0-9]") } };
  constructor(
    private _sliderDialogService: SliderDialogService,
    private _userService: UserService,
    private _composerPROService: ComposerPROService,
    private _genreService: GenreService,
    public _DialogService:DialogService,
    @Inject(CONTAINER_DATA) public componentData: DialogConfig,  public http: HttpClient,
    public api: UserMenuService,public config: MenuConfigService, 
  ) {}

  ngOnInit(): void {
    this.socialSercurity= new FormControl('', [Validators.required, Validators.maxLength(12)]);
    this.getAllPercentage()
    if (this.componentData.data) {
      this.user = new User(this.componentData.data.user.toJSON());
      this.oldEmail = this.user.email;
      this.oldUser = this.user.ms_username;
      console.log(this.user.id)
      let data1 =[]
      let data = (this.user && this.user?.default_co_writer) ? (this.user && this.user?.default_co_writer) : [];
      if(data.length > 0){
      for(let i=0; i< data.length;i++){
         data1.push({
          "email": "",
          "first_name": data[i].name,
          "id": data[i].id,
          "is_active": true,
          "last_name": "",
          "isdisable":true,
        })
      }
      this.user.allowed_cowriters = this.user.allowed_cowriters.concat(data1)
    }
   
     // this.user.can_upload = this.user.is_disable
      // this.userPRO = new UserPRO(this.componentData.data.userPRO.toJSON());
      this.userPRO = new UserPRO(this.componentData.data.user.toJSON());
      if (this.componentData.data.isNewUser) {
        this.isNewUser = this.componentData.data.isNewUser;
      }
      //Set up a form control for each additional number
      this.user.additional_numbers.forEach(() => {
        this.phoneControllers.push(new FormControl());
      });

      this._genreService.getParentGenres().subscribe(
        (data) => {
          this.genres = data;
          let newData=[]
          if(this.user['visible_genres'].length > 0 && this.user['visible_genres'][0]?.id){
            this.user['visible_genres'] = this.user['visible_genres'] 
          }
          else{
          for(let index=0;index< this.user['visible_genres'].length; index++){
            for(let i=0;i<this.genres.length;i++){
              if(this.user['visible_genres'][index] === this.genres[i].id){
                newData.push( this.genres[i])

              }
            }
          }
          this.user['visible_genres'] = newData
        }
          this.filteredGenreOptions.next(this.genres);
        },
        (error) => {
          console.log(error);
          //alert("An error ocurred while getting genres. Check your connections and try again");
        }
      );

      // this._composerPROService.getComposerPROs().subscribe(
      //   (data) => {
      //     this.composerPROs = data;
      //     this.filteredPROOptions.next(this.composerPROs);
      //   },
      //   (error) => {
      //     console.log(error);
      //     //alert("An error ocurred while getting Composer PROs. Check your connections and try again");
      //   }
      // );

      this._composerPROService.getPROs().subscribe(
        (data) => {
          this.artistPROs = data;
          this.filteredPROOptions.next(this.artistPROs);
        },
        (error) => {
          console.log(error);
          //alert("An error ocurred while getting Composer PROs. Check your connections and try again");
        }
      );

      this._subscriptions.push(
        this.proFilterCtrl.valueChanges
          .pipe(
            startWith(""),
            debounceTime(150),
            distinctUntilChanged(),
            tap(() => {
              this.searchingPRO = true;
            }),
            switchMap((v) => {
              if (!v) {
                return of(this.artistPROs);
              }
              return of(
                this.artistPROs.filter(
                  (pro) =>
                    pro.pro.toLowerCase().includes(v.toLowerCase()) 
                )
              );
            }),
            tap(() => {
              this.searchingPRO = false;
            })
          )
          .subscribe(
            (pros) => {
              this.filteredPROOptions.next(pros);
              this.filterPro();
            },
            (error) => {
              console.log(error);
              //alert("An error ocurred while getting the filtered PROs. Check your connections and try again");
            }
          ),
        this.genreFilterCtrl.valueChanges
          .pipe(
            startWith(""),
            debounceTime(150),
            distinctUntilChanged(),
            tap(() => {
              this.searchingGenre = true;
            }),
            switchMap((v) => {
              if (!v) {
                return of(this.genres);
              }
              return of(
                this.genres.filter((g) =>
                  g.name.toLowerCase().includes(v.toLowerCase())
                )
              );
            }),
            tap(() => {
              this.searchingGenre = false;
            })
          )
          .subscribe(
            (g) => {
              this.filteredGenreOptions.next(g);
            },
            (error) => {
              console.log(error);
              //alert("An error ocurred while getting the filtered genres. Check your connections and try again");
            }
          )
      );

      // this.tosDataSource = of([
      //   new TOSListRow("1"),
      //   new TOSListRow("2"),
      //   new TOSListRow("3"),
      //   new TOSListRow("4"),
      //   new TOSListRow("5"),
      // ]);
      // this.paymentsDataSource = of([
      //   new PaymentListRow("1"),
      //   new PaymentListRow("2"),
      // ]);
      if(this.componentData.data.isEdit){
          this.tosDataSource = new MatTableDataSource<Tos>();
          // this.tosDataSource.sort = this.sort;
          this._userService
            .getTosStatusList(this.componentData.data.user)
            .subscribe((res) => {
              this.tosDataSource.data = res as Tos[];
            });
      }
    
    }
    if(this.user.is_disable === true){
      this.user.can_upload = false;
    }
    this.user.can_upload = this.user.can_upload==undefined ? true : this.user.can_upload
    this.user.birth_date = this.user ? this.user.birth_date : ''
   // this.user.is_disable = this.user.is_disable==undefined ? true : this.user.is_disable;
    this.userproData = this.user ? this.user.pro?.id : '';
  }
  passwordInputType = 'password';
  passwordInputType1 = 'password';
  togglePasswordInputType(type: any = "") {
    if (type === 'confirm') {
      if (this.passwordInputType1 == 'password') {
        this.passwordInputType1 = 'text';
      } else {
        this.passwordInputType1 = 'password';
      }
    }
    else {
      if (this.passwordInputType == 'password') {
        this.passwordInputType = 'text';
      } else {
        this.passwordInputType = 'password';
      }
    }
  }

  uploadChecked(event){
    this.user.can_upload= event
  }
  disableChecked(event){
    this.user.is_disable= event
  
  }
  getAllPercentage() {
    let mibe_name = 'MIBE PUBLISHING'
    let url = environment.apiURL + this.config.doPublisher;
    this.api.doGET(url).subscribe(x => {
      this.user.publisher_percentage = x['percentage'];
    },
      (error) => {
        this.loading = false
      }
    )
  }
  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
    this._subscriptions = [];
  }

  close() {
    this._sliderDialogService.close(null);
  }

  removeNumber(index: number) {
    this.user.additional_numbers.splice(index, 1);
    this.phoneControllers.splice(index, 1);
  }

  passwordChange() {
    this.isPassChange = true;
  }

  addNumber() {
    this.phoneControllers.push(new FormControl());
    this.user.additional_numbers.push(
      new PhoneNumber({ number_type: "mobile" })
    );
  }

  canAddCoWriter(): boolean {
    if (!this.user.unlimited_cowriters) {
      if (!(this.user.cowriter_quanity_limit)) return false;
      if (
        (this.user.cowriter_quanity_limit) <
        (this.user.allowed_cowriters.length-2) + 1
      )
        return false;
    }
    if (
      !this.coWriterEmail ||
      !this.coWriterFirstName ||
      !this.coWriterLastName
    )
      return false;
    if (
      this.user.allowed_cowriters.some(
        (cowriter) => cowriter.email == this.coWriterEmail
      )
    )
      return false;
    return true;
  }

  addCoWriter() {
    this.user.allowed_cowriters.push(
      new AllowedCoWriters({
        email: this.coWriterEmail,
        first_name: this.coWriterFirstName,
        last_name: this.coWriterLastName,
      })
    );
    this.coWriterEmail = null;
    this.coWriterFirstName = null;
    this.coWriterLastName = null;
  }

  removeCowriter(index: number) {
    this.user.allowed_cowriters.splice(index, 1);
  }

  shouldShowStateInput(): boolean {
    if (!this.user || !this.user.country) return false;
    return this.user.country.toLowerCase() != "us";
  }

  validateNumber(event, i?:number) {  
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if ((event.key === ' ' || isNaN(Number(event.key)))&&!((
      (keyCode >= 96 && keyCode <= 105) ||
      (excludedKeys.includes(keyCode))))) {
      event.preventDefault();
    }
  }
  getPhoneCode(e, i?:number){
    let l = e.value.length;
    this.currentValue = e.id;
    //this.phoneNumber = '';
    if(l==1){
      if(e.value !== "+"){
        this.phoneNumber = '+1' + e.value;
        if(i!==undefined){
        this.user.additional_numbers[i].phone_number = this.phoneNumber;
        } else {
          this.user.phone_number = this.phoneNumber;
        }
      }
    }else{
      this.phoneNumber = e.value;
    }
  }
  canSave(): boolean {
    if (this.user) {
      if (!this.user.email || !this.user.first_name || !this.user.last_name)
        return false;
        if(this.user.first_name){
          if(this.user.first_name.trim() === ''){
            return false;
          }
        }
        if(this.user.ms_username){
          if(this.user.ms_username.trim() === ''){
            return false;
          }
        }
        if(this.user.last_name){
          if(this.user.last_name.trim() === ''){
            return false;
          }
        }
        if(this.user.visible_genres.length === 0){
          return false;
        }
      // if (
      //   !this.user.address1 ||
      //   !this.user.state ||
      //   !this.user.city ||
      //   // !this.user.postal_code ||
      //   !this.user.country
      // )
      //   return false;
     // if (!this.user.phone_number) return false;
      // if(!this.user.birth_date){
      //   return false
      // }
      // if(this.user.postal_code.length <= 4){
      //   return false
      //   }
      // if (this.user.cae_ipi) {
      //   if (this.user.cae_ipi.length < 9) 
      //   return false;
      // } else {
      //   return false;
      // }
      // if (this.user.ssn) {
      //   if (this.user.ssn.length < 9) 
      //   return false;
      // } else {
      //   return false;
      // }
      if (!this.user.unlimited_cowriters) {
        if (
          this.user.cowriter_quanity_limit < (this.user.allowed_cowriters.length-2)
        )
          return false;
      }
      if (this.isNewUser || (!this.isNewUser && this.isPassChange)) {
        if (this.user.raw_password) {
          return true;
        } else {
          return false;
        }
      }
      if(this.user.visible_genres.length === 0){
        return false;
      }
      return true;
    }

    return false;
  }

  save() {
    this.errorMessage = null;
    if (!this.user) {
      this.errorMessage =
        "Issue with user please close the panel, check you internet connection and try again";
      return;
    }
    if(this.user){
      this.checkUsername(this.user.ms_username, 'save')
    }
    if (this.isUserExist === 0) {
      this.user['password'] = this.user['raw_password']
      if (this.user['discount_rate'].length == 3) {
        this.user['discount_rate'] = (this.user['discount_rate'].toString()).slice(0, -1)
      }
      if (!this.isNewUser && this.isPassChange === false) {

        console.log("IN")
        // this.user.password = '';
        delete this.user['password'];
      }
      if (this.userproData) {
        this.user['pro'] = this.userproData;
      }
      if (!this.isNewUser && this.isPassChange && (!this.user.password || this.user.password == "")) {
        this.errorMessage = "Password is required";
        return;
      }
      if (this.user.visible_genres.length > 0) {
        for (let index = 0; index < this.user.visible_genres.length; index++) {
          this.addVisible.push(this.user.visible_genres[index].id)
        }
        this.user.visible_genres = this.addVisible;
      }
      if (this.isNewUser) {
        // if (!this.user.password || this.user.password == "") {
        //   this.errorMessage = "Password is required";
        //   return;
        // }
        this.loading = true;
        this.user.is_active = true;

        if (this.user.allowed_cowriters.length > 0) {
          let newValues = [];
          for (let i = 0; i < this.user.allowed_cowriters.length; i++) {
            if (this.user.allowed_cowriters[i]?.disable === false) {
              newValues.push(this.user.allowed_cowriters[i])
            }
          }
          this.user.allowed_cowriters = newValues
        }
        this._userService.registerUser(this.user, this.user.password).subscribe(
          (user) => {
            this.componentData.data.user = user;
            this.loading = false;
            this._sliderDialogService.close(user);
            location.reload();
          },
          (error) => {
            console.log(error);
            // this.isEmailExist = 1;
            if (error.error.email) {
              this.isEmailExist = 1
            }
            else {
              // alert(error.error.detail);
              let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
              this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
              });

            }

            //alert("An error ocurred while registering the user. Check your input for errors, and then check connection and try again");
            this.loading = false;
          }
        );
        return;
      }
      this.loading = true;
      if (!this.isNewUser) {
        if (this.editGenres.length > 0) {
          this.user.visible_genres = this.editGenres;
        }
        else {
          this.user.visible_genres = this.addVisible
        }
      }

      if (this.user.allowed_cowriters.length > 0) {
        let newValues = [];
        for (let i = 0; i < this.user.allowed_cowriters.length; i++) {
          if (this.user.allowed_cowriters[i]?.disable === false) {
            newValues.push(this.user.allowed_cowriters[i])
          }
        }
        this.user.allowed_cowriters = newValues
      }
      this._userService.updateUser(this.user).subscribe(
        (user) => {
          console.log(user, "User after update")
          this.componentData.data.user = user;
          this.loading = false;
          this._sliderDialogService.close(user);
          location.reload();
        },
        (error) => {
          console.log(error);
          //this.isEmailExist = 1;
          if (error.error.email) {
            this.isEmailExist = 1
          }
          else {
            // alert(error.error.detail);
            let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
            this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
            });
          }
          //alert("An error ocurred while updating the user. Check your input for errors, and then check connection and try again");
          this.loading = false;
        }
      );
    }
  }

  changeTab(tab: "general" | "tos" | "payment") {
    if (this.activeTab != tab) {
      this.activeTab = tab;
    }
  }

  birthdayChange(event) {
    this.user.birth_date = event.value;
  }

  tosListTrackBy(index: number, tos: string) {
    return `${index}-${tos}`;
  }

  paymentsListTrackBy(index: number, payment: string) {
    return `${index}-${payment}`;
  }

  composerPROSelection(change: MatSelectChange) {
    this.userPRO.pro = change.value;
  }

  genreSelection(change: MatSelectChange) {
    //console.log(change)
    for (let i = 0; i < change.value.length; i++) {
      if (!this.user.visible_genres.includes(change.value[i].id)) {
        this.editGenres.push(change.value[i].id);
      }else{
        this.user.visible_genres.splice(i, 1);
      }
      console.log(this.user.visible_genres, this.editGenres)
    }
  }

  composerPROCompareWith(p1: ComposerPRO, p2: ComposerPRO) {
    if (!p1 || !p2) {
      return p1 == p2;
    }
    return p1.id == p2.id;
  }

  genreCompareWith(g1: Genre, g2: Genre) {
    if (!g1 || !g2) {
      return g1 == g2;
    }
    return g1.id == g2.id;
  }

  checkNumber(e) {
    //Check Charater
    const reg = /^\d*\.?\d{0,1}$/g;
    if (!reg.test(e.target.value)) {
      e.preventDefault();
    }
    var unicode = e.charCode ? e.charCode : e.keyCode;
    if (e.target.value.indexOf(".") != -1) if (unicode == 46) return false;
    if (unicode != 8)
      if ((unicode < 48 || unicode > 57) && unicode != 46) return false;
  }
  checkLength() {
    var fieldVal: any = (<HTMLInputElement>document.getElementById("discount"))
      .value;
    //Suppose u want 3 number of character
    if (parseInt(fieldVal) <= 100) {
      return true;
    } else {
      var str = (<HTMLInputElement>document.getElementById("discount")).value;
      str = str.substring(0, str.length - 1);
      (<HTMLInputElement>document.getElementById("discount")).value = str;
    }
  }
  filterPro() {
    if (!this.proFilterCtrl.value) {
     return;
    }
    let search = this.proFilterCtrl.value;
    if (!search) {
      this.filteredPROOptions.next(this.artistPROs.value.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredPROOptions.next(
      this.artistPROs.filter(name=> name.pro.toLowerCase().indexOf(search)>-1)
    );
  }

  checkEmaile(name){
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    let email, data: any;
    email = name.target.value;
    if (email && this.oldEmail !== email) {
      if (this.user && this.user.id) {
        data = {
          "user_id": (this.user && this.user?.id.toString()) ? this.user?.id.toString() : '',
          "email": email
        }
      }
      else {
        data = {
          "user_id": '',
          "email": email
        }
      }
      let requestUrl = environment.apiURL + '/api/v1/admin/users/check-existing-email/'

      // let value =  this.http.get<Object>(
      //   environment.apiURL + '/api/v1/admin/users/check-existing-email/',
      //   {
      //     params: data,
      //     headers: headers
      //   }
      // )
     // console.log(value)
     this.api.doGET(requestUrl,{ params :(data)}).subscribe(x =>{
      this.isEmailExist = 0;
      }, (error) => {
        if(error.error.details)
        {
        this.isEmailExist = 1;
        }
      });
    } else {
      this.isEmailExist = 0;
    }
     
  }

  checkUsername(name, type:any=''){
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    let email, data: any;
    email = name;
    if (email && this.oldUser !== email) {
      if (this.user && this.user.id) {
        data = {
          "user_id": (this.user && this.user?.id.toString()) ? this.user?.id.toString() : '',
          "ms_username": email
        }
      }
      else {
        data = {
          "user_id": '',
          "ms_username": email
        }
      }
      let requestUrl = environment.apiURL + '/api/v1/admin/users/check-username/'
     this.api.doGET(requestUrl,{ params :(data)}).subscribe(x =>{
      this.isUserExist = 0;
      }, (error) => {
        if(type !== ''){
          let data = error.error?.error ? error.error?.error : (error.error?.detail ?error.error?.detail : (error.error?.details ? error.error?.details:``) )
          this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
          });
        }
        else{
        if(error.error.error)
        {
        this.isUserExist = 1;
        }
      }
      });
    } else {
      this.isUserExist = 0;
    }
  }

}
