import { MibeModel } from './mibemodel.model';

export class SonicReferenceTrack implements MibeModel {
  id: number;
  sonicreference_id: number;
  track_id: number;
  added_by_id: number;
  added_by_role: number

  /**
   * Create a Instrument.
   * @param {any} json - The JSON object with which to construct our Instrument.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a Instrument.
   * @param {any} json - The JSON object with which to load our Instrument.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this.id = json.id;
    this.sonicreference_id = json.sonicreference_id;
    this.track_id = json.track_id;
    this.added_by_id = json.added_by_id;
    this.added_by_role = json.added_by_role;
  }
  /**
   * Returns a JSON representation of our Instrument
   * @return {any} - The JSON representation of our Instrument
   */
  toJSON() {
    var json: any = {
      'id': this.id,
      'sonicreference_id': this.sonicreference_id,
      'track_id': this.track_id,
      'added_by_id': this.added_by_id,
      'added_by_role': this.added_by_role,
    };
    return json;
  }
}
