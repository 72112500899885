import { Injectable, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { UserMenuService } from './user-menu.service';
import { MenuConfigService } from './menu-config.service';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { UserGroups } from '../../interfaces/user-groups';
import sortKeys from 'sort-keys';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
interface SelectString {
  value: string;
  label: string;
}
@Injectable({
  providedIn: 'root'
})
export class MenuListService {
  currentUserGroupName: any;
  menuheader: boolean = true;
  AllusersDetails: any[] = []
  AllGroups: any = []
  AllGroupList:any=[]
  count: number = 0
  page: number=1;
  AllPermission = [];
  availableUserDetails: any = [];
  availableUserPermission: any = [];
  editAvailableUserPermission: any = [];
  singleUserGroups:any;
  currentUserGroup:any;
  currentUserGroupEditDelete: any;
  currentDeteuserUserGroups:any;
  singleUserArray: UserGroups[];
  dataSourceForUserGroups = new MatTableDataSource<any>();
  dataSourceForReports = new MatTableDataSource<any>();
  dataSourceForLogs = new MatTableDataSource<any>();
  dataSourceForPROs = new MatTableDataSource<any>();
  dataSourceForThesaurus = new MatTableDataSource<any>();
  dataSourceForTOS = new MatTableDataSource<any>();
  dataClientSourceForTOS = new MatTableDataSource<any>();
  loadingUser:boolean = false;
  loadingUserGroups: boolean = false;
  usergroupTable:boolean =false;
  singleuserGroupArray: any;
  constructor(public config: MenuConfigService, public api: UserMenuService) { }
  public currentAdminUserSubject = new BehaviorSubject(null);
  public currentAdminUserGroupsSubject = new BehaviorSubject(null);
  public currentUploadedTrackSubject = new BehaviorSubject(null);
  public currentAssignedGroupSubject = new BehaviorSubject(null);
  public allTrackListEvent = new BehaviorSubject(null);
  public currentIntrumentaltypeSubject = new BehaviorSubject(true);
  public getTrackFileSubject = new BehaviorSubject(null);
  public getTrackTypeSubject = new BehaviorSubject(null);
  singleuserGroupCount: any;
  getAllUserArray: any;
  moodsType:string = 'Add';
  moodId: number;
  moodName: any;
  allLogs: any[] = []

  instSubCategory:any;
  instDesc:any;
  vocalsdesc:any;
  vocalsType:any;
  
  lettersOfMoods = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  
  uploadedTracks: any = [];
  uploadedTracksDetails: any = [];
  isUserGroupsDuplicate: boolean = false;
  userListPermission: boolean = false;
  supportDataList=[];
  MissingData:any=[];
  errorMessage:string='';
  supportType = 0;
  getAdminUser(): Observable<any> {
    return this.currentAdminUserSubject.asObservable();
  }
  getInstrumentalTyep(): Observable<any> {
    return this.currentIntrumentaltypeSubject.asObservable();
  }
  getTrackFile(): Observable<any> {
    return this.getTrackFileSubject.asObservable();
  }
  getTrackType(): Observable<any> {
    return this.getTrackTypeSubject.asObservable();
  }
  getAdminUserGroups(): Observable<any> {
    return this.currentAdminUserGroupsSubject.asObservable();
  }
  getSingleUploadedTrack(): Observable<any> {
    return this.currentUploadedTrackSubject.asObservable();
  }
  getSingleAssignedGroup(): Observable<any> {
    return this.currentAssignedGroupSubject.asObservable();
  }
  getContinueTrack(): Observable<any> {
    return this.allTrackListEvent.asObservable();
  }

  getAllUser(){
    this.loadingUser = true;
    // let url = environment.apiURL + this.config.doMenuUser + 'get-staffs/';
    let url = environment.apiURL + this.config.doMenuUser 
    this.api.doGET(url).subscribe(x=>{
      this.getAllUserArray = x;
      this.AllusersDetails = x['results'] || []
      // this.currentAdminUserSubject.next(x['results'][0])
      // this.currentUserGroup = x['results'][0];
      this.loadingUser = false;
    },
    (error)=>{
      this.loadingUser = false;
      //alert(error.message)
    }
  )
  }
  allMenuUser
  getAllMenuUser(user=null){
    this.loadingUser = true;
    let url = environment.apiURL + this.config.menuUser;
    this.api.doGET(url).subscribe(x=>{
      this.allMenuUser = x['results'] || []
      if (user) {
        this.currentAdminUserSubject.next(user);
      } else {
        this.currentAdminUserSubject.next(this.allMenuUser[0])
      }
      // this.currentAdminUserSubject.next(x['results'][0])
      // this.currentUserGroup = x['results'][0];
      this.loadingUser = false;
    },
    (error)=>{
      this.loadingUser = false;
      //alert(error.message)
    }
  )
  }
  
  getAllPermission(){
    this.loadingUser = true;
    this.AllPermission = []
    let url = environment.apiURL + this.config.listOfPermission;
    this.api.doGET(url).subscribe(( x : any)=>{

      for(let res of x ){
        res['checked'] = false;
        this.AllPermission.push(res)
      }
      this.loadingUser = false;
    },
    (error)=>{
     
      this.loadingUser = false;
      //alert(error.message)
    }
  )
  }
  getAllGroups(page: any, type:any=''){
    let url = environment.apiURL + this.config.doGroups + '?page='+page;
    this.api.doGET(url).subscribe(x=>{
      this.AllGroups = x['results'] || []
      this.count = x['count'] || 0
      if(type === ''){
      this.singleUserForGroup(x['results'][0].id)
      }
      this.currentUserGroup = x['results'][0]
      this.currentAdminUserGroupsSubject.next(x['results'][0].id)
      this.page = page
      
    },
    (error)=>{
     
      //alert(error.message)
    }
  )
  }

  getAllGroupsList(type:any=''){
    let url = environment.apiURL + this.config.listOfPermission;
    this.api.doGET(url).subscribe(x=>{
      this.AllGroups = x || []
     // this.count = x['count'] || 0
     if(type == ''){
      this.singleUserForGroup(x[0].id)}
      this.currentUserGroup = x[0]
      this.currentAdminUserGroupsSubject.next(x[0].id)
    },
    (error)=>{
     
      //alert(error.message)
    }
  )
  }
  
  
  getSingleUserGroups(perms: any){
    this.loadingUserGroups = true;
    this.singleUserGroups = null;
    this.singleUserArray = [];
    let url = environment.apiURL + this.config.doGroups + perms+'/';
    this.api.doGET(url).subscribe(x=>{
      
      this.singleUserGroups = x;
      this.singleUserArray = x['permissions'];
      this.currentUserGroupName = this.isUserGroupsDuplicate?this.singleUserGroups.name+' '+'copy':this.singleUserGroups.name;
      this.currentAdminUserSubject.next(x)
      this.loadingUserGroups = false;
    },
    (error)=>{
     
      //alert(error.message)
    }
  )
  }
  getAvailableUserDetails(){
     let url = environment.apiURL + this.config.availableUser;
    // let url = environment.apiURL + '/api/v1/admin/users/get-staffs/'
        this.api.doGET(url).subscribe(x=>{
        this.availableUserDetails = x;
        this.availableUserDetails = this.availableUserDetails
        this.availableUserDetails.forEach(x=>{
          x['name'] = x.first_name+ " "+x.last_name;
          return x;
        })
      },
      (error)=>{     
        //alert(error.message)
      }
    )
  }
  managePermissionArrayGroups: any[] = []
  countOfUserGroups: any;
  getAvailableUserPermission(){
    this.managePermissionArrayGroups = []
    this.loadingUser = true;
    let url = environment.apiURL + this.config.availablePermission;
    this.api.doGET(url).subscribe(x=>{
        this.availableUserPermission = x;
        this.availableUserPermission.forEach(x=>{
          if(this.userListPermission){
            for(var i=0; i<this.currentUserGroupEditDelete.user_permissions.length; i++){
              if(x.id === this.currentUserGroupEditDelete.user_permissions[i].id){
                x['checked'] = true;
                break
              }else{
                x['checked'] = false;
              }
            }
            return x;
          }else{
            for(var j=0; j<this.singleUserArray?.length; j++){
              if(x.id === this.singleUserArray[j].id){
                x['checked'] = true;
                this.managePermissionArrayGroups.push(x.id)
                break
              }else{
                x['checked'] = false;
              }
            }
            return x;
          }
        })
        this.countOfUserGroups = this.singleUserArray?.length;
        this.loadingUser = false;
      },
      (error)=>{  
        this.loadingUser = false;   
        //alert(error.message)
      }
    )
  }
  getEditAvailableUserPermission(){
    this.loadingUser = true;
    let url = environment.apiURL + this.config.availablePermission;
    this.api.doGET(url).subscribe(x=>{
        this.editAvailableUserPermission = x;
        this.editAvailableUserPermission.forEach(x=>{
          x['checked'] = false;
          return x;
        })
        this.loadingUser = false;
      },
      (error)=>{  
        this.loadingUser = false;   
        //alert(error.message)
      }
    )
  }
  singleUserForGroup(e){
    this.usergroupTable = true;
    let url = environment.apiURL + this.config.doGroups + e + '/get-group-user/';
    this.api.doGET(url).subscribe(x=>{
        this.singleuserGroupArray = x || [];
        this.dataSourceForUserGroups = new MatTableDataSource(this.singleuserGroupArray); 
        this.singleuserGroupCount = this.singleuserGroupArray.length;
        this.usergroupTable =  false;
      },
      (error)=>{     
        this.usergroupTable =  false
        //alert(error.message)
      }
    )
  }

  // data services starts

  AllGenresList:any = []
  AllGenres:any = []
  AllParents:any = []
  loadingGnere:boolean = false;
  currGnere:any;
  currSubGnere:any;

  getAllGenres(){
    let url = environment.apiURL + this.config.doGenres;
    this.loadingGnere = true
    this.api.doGET(url).subscribe(x=>{
      this.AllGenresList = x;
      const rows = [];
      this.loadingGnere = false
      x['results'].forEach(element => rows.push(element, { detailRow: true, element }));
        this.AllGenres = rows || [];
      },
      (error)=>{  
        this.loadingGnere = false   
        //alert(error.message)
      }
    )
  }

  getAllParents(){
    let url = environment.apiURL + this.config.doGenres + 'dropdown/'
    this.api.doGET(url).subscribe(x=>{
        this.AllParents = x || [];
      },
      (error)=>{     
        //alert(error.message)
      }
    )
  }
  allPublisher: any = [];
  getAllPublisher(){
    let requestUrl = environment.apiURL +  this.config.publisherList;
    this.api.doGET(requestUrl).subscribe(x =>{
      this.allPublisher = x['results'];
    })
  }
  allPROSDetails: any = [];
  allPROS: any = [];
  currPros:any;
  prosLoading: boolean = false;
  getAllPROS(){
    this.prosLoading = true
    let requestUrl = environment.apiURL +  this.config.getAllPROSlist;
    this.api.doGET(requestUrl).subscribe(x =>{
      this.allPROSDetails = x;
      this.allPROS = x['results']; 
      this.dataSourceForPROs = new MatTableDataSource(this.allPROS); 
      this.prosLoading = false
    })
  }
  singlePROS: any = [];
  publisher: any;
  publisherid: any;
  getSignlePROS(id){
    let requestUrl = environment.apiURL +  this.config.getAllPROSlist+ id + '/';
    this.api.doGET(requestUrl).subscribe(x =>{
      this.singlePROS = x;
      this.publisherid = this.singlePROS.publisher.id;
      this.publisher = this.singlePROS.publisher.name;
    })
  }
  
  currentLetter = '';
  allMOODS: any = [];
  availableLettersOfMoods: any = [];
  getAllMOODS(){   
    this.currentLetter = '';
    this.availableLettersOfMoods = []
    let requestUrl = environment.apiURL +  this.config.getAllMOODSlist;
    this.api.doGET(requestUrl).subscribe(x =>{
      
      this.allMOODS = x; 
      var len = this.allMOODS.count;
      for (var i = 0; i < len; i++) {
        for(var l = 0; l < len; l++){
          if (this.allMOODS.results[i].name.indexOf(this.lettersOfMoods[l]) == 0){   
            this.availableLettersOfMoods.push(this.lettersOfMoods[l])
          }
        }
      }

    })
  }
  
// thesaurus block
allthesaurusList: any = []
allthesaurus : any = [];
thesaurusLoading: boolean = false;
currThesaurus:any;
getAllthesaurus(){
  this.thesaurusLoading = true;
  let url = environment.apiURL + this.config.doThesaurus;
  this.api.doGET(url).subscribe(x=>{
    this.allthesaurusList = x; 
this.thesaurusLoading = false;
      this.allthesaurus = x['results'] || [];      
      this.dataSourceForThesaurus = new MatTableDataSource(this.allthesaurus); 
    },
    (error)=>{   
      this.thesaurusLoading = false;  
      //alert(error.message)
    }
  )
}

// Reports Services
allReportsDetails:any = [];
allReports:any = [];
reportsLoader:boolean = false;
newReports: any = []
/* @ViewChild(MatSort, {static: false})
set sort(log: MatSort) {
  this.dataSourceForReports.sort = log;
} */
getAllReports(){
  this.reportsLoader = true;
  let url = environment.apiURL + this.config.getAllReports;
  this.api.doGET(url).subscribe(x=>{
    this.reportsLoader = false;
    this.allReportsDetails = x;
    this.allReports = x['results'] || [];
    for(var i=0;i<this.allReports.length;i++){
      this.newReports.push(this.allReports[i].uploads)
    }
    this.dataSourceForReports = new MatTableDataSource(this.allReports);
  },
    (error)=>{   
      this.reportsLoader = false;  
      //alert(error.message)
    }
  )
}
logCount:any;
logLoader:boolean = false;
getAllLogs(){
  this.logLoader = true;
  let url = environment.apiURL + this.config.doLogs;
  this.api.doGET(url).subscribe(x=>{
    this.logLoader = false;
      this.allLogs = x['results'] || [];      
      this.dataSourceForLogs = new MatTableDataSource(this.allLogs);
      this.logCount = x['count'];
      
    },
    (error)=>{
      this.logLoader = false;     
      //alert(error.message)
    }
  )
}

// Tos Services
allTos:any = [];
tosLoader:boolean = false;
ordering: any = 'asc';
allClientTos:any=[]
tosCount:any;
getAllTos(){
  this.tosLoader = true;
  let url = environment.apiURL + this.config.doTos;
  this.api.doGET(url).subscribe(x=>{
  this.tosLoader = false;
      this.allTos = x['results'] || [];
      this.dataSourceForTOS = new MatTableDataSource(this.allTos);
      this.tosCount = x['count'];
    },
    (error)=>{   
      this.tosLoader = false;  
      //alert(error.message)
    }
  )
  let url1 = environment.apiURL + this.config.doTosClient;
  this.api.doGET(url1).subscribe(x => {
    this.tosLoader = false;
    this.allClientTos = x['results'] || [];
    this.dataClientSourceForTOS = new MatTableDataSource(this.allClientTos);
  },
    (error) => {
      this.tosLoader = false;
      //alert(error.message)
    }
  )
}

// Tos Services
allPlaylist:any = [];
getAllAvailablePlayList(){
  let url = environment.apiURL + '/api/v1/admin/users/get-available-playlist/';
  this.api.doGET(url).subscribe(x=>{
      this.allPlaylist = x || [];
      this.allPlaylist.forEach(x=>{
        x['checked'] = false;
        return x;
      })
    },
    (error)=>{   
      //alert(error.message)
    }
  )
}

tempo:any=[]
filteredTempoOptions: ReplaySubject<SelectString[]> = new ReplaySubject<SelectString[]>(1);
getTempo(){
  let requestUrl = environment.apiURL + `/api/v1/track/tempo-dropdown/`
  this.api.doGET(requestUrl).subscribe(x =>{
    let arr;
    arr = x;
    this.tempo = x
    this.filteredTempoOptions.next(arr);
    
  })
  }
  trackDetail: any = []
  getTrackDetail(id) {
    let url = environment.apiURL +`/api/v1/track/${id}/get-track-info/`;
    this.api.doGET(url).subscribe(x => {
      this.trackDetail = x
      return x
    })
  }
  userDetail: any = []
  getUserDetail(id) {
    let url = environment.apiURL + `/api/v1/admin/users/${id}/`;
    this.api.doGET(url).subscribe(x => {
      this.userDetail = x
      return x
    })
  }
}

