import { MibeModel } from './mibemodel.model';

export class TrackStatusValue implements MibeModel {
  value: number;
  color: string;
  name: string;

  /**
   * Create a TrackStatusValue.
   * @param {any} json - The JSON object with which to construct our TrackStatusValue.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a TrackStatusValue.
   * @param {any} json - The JSON object with which to load our TrackStatusValue.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this.value = json.value;
    this.color = json.color;
    this.name = json.name;
  }
  /**
   * Returns a JSON representation of our TrackStatusValue
   * @return {any} - The JSON representation of our TrackStatusValue
   */
  toJSON() {
    var json: any = {
      'value': this.value,
      'color': this.color,
      'name': this.name,
    };
    return json;
  }
}
