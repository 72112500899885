import { Component, OnInit, OnDestroy, Renderer2, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from '../services/user.service';
import { User } from '../models/user.model';
import { MenuList } from './menu';
import { Router } from '@angular/router';
import { MenuListService } from '../services/menu-list.service';
import { ThemeService } from '../../theme/theme.service';
import { SliderDialogService } from '../services/slider-dialog.service';
import { UploadTrackPopComponent } from 'src/app/composer/upload-track-pop/upload-track-pop.component';
import { PlayerService } from '../services/player.service';
import { ComposerAlertComponent } from 'src/app/composer-alert/composer-alert.component';
import { DialogService } from '../services/dialog.service';
import { DownloadService } from '../services/download.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  loadingCurrentUser = true;
  currentUser: User;
  menuList = [];
  term = true;

  get route():string {
    return this._router.url.split('?')[0];
  }

  private _subscriptions: Subscription[] = [];

  @Output()
  listChange: EventEmitter<void> = new EventEmitter<void>();
  
  constructor(
    public _userService: UserService,
    private _ren: Renderer2,
    private _router: Router,
    public list: MenuListService,
    public themeService: ThemeService,
    private _sliderDialogService: SliderDialogService,
    private _playerService: PlayerService,
    private dialog: DialogService,
    public _downloadService: DownloadService
  ) {
  this.themeClick();

  }

  ngOnInit(): void {

    this._router.url
    this._subscriptions.push(
      this._userService.currentUserStream.subscribe((u) => {
        console.log(u)
        this.currentUser = u;
        if((this._userService.currentUser && this._userService.currentUser.is_artist ===  true && this._userService.currentUser.tos_status === 'Pending') ){
          this.term = false;
         }
         else if((this._userService.currentUser === null) ){
          this.term = false;
          console.log(this._userService.currentUser)
         }
        else{
          this.term = true;
        }
        this.loadingCurrentUser = false;
        this.menuList = u && u.role ? MenuList[u.role] : [];
      }),
    );
   
  }

  changeTab(){
    this._playerService.nextTab(true);
    this._playerService.updateWavePlay(false);
    this._playerService.updateIsPlaying(false);
    this._playerService.updateShowMusicPlayer(false);
    this._playerService.play(null, null);
    this._sliderDialogService.getReplaceTrackVersionSubject.next("");
  }
  ngOnDestroy(): void {
    this._subscriptions.forEach((s) => {
      s.unsubscribe();
    });
    this._subscriptions = [];
  }

  openMenu(trigger) {
    trigger.openMenu();
  }

  openUploadTrack() {
      this._sliderDialogService.open(UploadTrackPopComponent, {
        width: "600px",
        title: "UPLOAD TRACKS",
        panelClass: "edit-track-dialog",  
      }, (val)=> {
        if (val) {
          this.listChange.emit()
        }
      });
  }

  logout() {
    this._userService.logout().subscribe(()=> {
      this._router.navigate(['/login']);
    }, (error) => {
      console.log(error);
      //alert("An error ocurred while logging out. Check your connections and try again");
    });
  }
  data(){
    console.log("click")
  }
 themeClick(){
  this.themeService.toggleDark();
 }
 uploadPop(){
    this.dialog.displayMenuDialogAlert(ComposerAlertComponent,true,'').subscribe(res => {
    })
 }
}
