<div class="track-info-header">
  <!-- <div class="track-title" style="width:67%;" *ngIf="version.is_main_track">
    {{ version.title }} - Main
  </div>
  <div class="track-title" style="width:67%;" *ngIf="!version.is_main_track && !version.is_full_version">
    {{ version.title }}
</div>
<div class="track-title" style="width:67%;" *ngIf="!version.is_main_track && version.is_full_version">
  {{ track.title }}
</div> -->
<div class="track-title" style="width:67%;">
  {{ version.title }}
</div>
  <div class="track-user-input" style="width:28%;">
    <mat-slide-toggle class="user-input" 
      [(ngModel)]="displayUserInputs">
      Display User Inputs
    </mat-slide-toggle>
    <button type="button" class="close-button" (click)="close()">
      <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
              <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                  <g id="Genre-Options">
                      <g id="Close" transform="translate(1439.000000, 32.000000)">
                          <path d="M24,0 L0,24" id="Path"></path>
                          <path d="M0,0 L24,24" id="Path"></path>
                      </g>
                  </g>
              </g>
          </g>
      </svg>
    </button>
  </div>
</div>

<mat-dialog-content>
  <div class="track-info-wrapper">
    <div class="track-info-version">
      <div class="label"  style="text-transform: uppercase;">Version</div>
      <span class="title">{{ version.title }} - {{ version?.duration | minuteSeconds }}</span>
    </div>
    <div class="track-meta track-meta-split" *ngIf="track.genre">
      <div class="track-meta-item">
        <span class="track-meta-label">Genre:</span>
        <span class="track-meta-value">
          <ng-container *ngIf="!track.track.genre">
            {{ track.genre }}
          </ng-container>
          <ng-container *ngIf="track.track.genre">
            {{ track.track.genre.name }}
          </ng-container>
        </span>
      </div>
      <div class="track-meta-item" >
        <span class="track-meta-label">Key:</span>
        <span class="track-meta-value">
          {{ version.calculated_key }}
        </span>
      </div>
      <div class="track-meta-item">
        <span class="track-meta-label">Sub-Genre:</span>
        <span class="track-meta-value">
          {{ track.track.sub_genre.name }}
        </span>
      </div>
      <div class="track-meta-item" >
        <span class="track-meta-label">Ending:</span>
        <span class="track-meta-value">
          {{ version.track_ending }}
        </span>
      </div>
      <div class="track-meta-item" >
        <span class="track-meta-label">Moods:</span>
        <span class="track-meta-value">
          <ng-container *ngFor="let mood of track.moods; last as isLast; trackBy: moodsTrackBy">
            {{ mood.name }}<ng-container *ngIf="!isLast">, </ng-container>
          </ng-container>
        </span>
      </div>
      <div class="track-meta-item" *ngIf="version.calculated_bpm">
        <span class="track-meta-label">BPM:</span>
        <span class="track-meta-value">
          {{ version.calculated_bpm }}
        </span>
      </div>
     
      <div class="track-meta-item" >
        <span class="track-meta-label">Tempo:</span>
        <span class="track-meta-value">
          {{ version?.tempo_value }}
        </span>
      </div>
    </div>
    <div class="track-meta">
      <div class="track-meta-full">
        <mat-table [dataSource]="dataTable" class="track-composer-table" multiTemplateDataRows >
          <ng-container matColumnDef="composer">
            <mat-header-cell *matHeaderCellDef class="track-meta-label">COMPOSER</mat-header-cell>
            <mat-cell *matCellDef="let row" class="track-meta-value" style="font-size: 12px;">{{row.composerFirstName}} {{row.composerLastName}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="composer_pro">
            <mat-header-cell *matHeaderCellDef class="track-meta-label">COMPOSER PRO</mat-header-cell>
            <mat-cell *matCellDef="let row" class="track-meta-value" style="margin-left:3px;">{{ row.composerSociety }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="composer_percentage">
            <mat-header-cell *matHeaderCellDef class="track-meta-label">COMPOSER %</mat-header-cell>
            <mat-cell *matCellDef="let row" class="track-meta-value"><span style="margin-left: 22px;">{{ row.composerShare }}</span></mat-cell>
          </ng-container>
          <ng-container matColumnDef="publisher_name">
            <mat-header-cell *matHeaderCellDef class="track-meta-label"><span style="margin-left: 35px;">PUB</span></mat-header-cell>
            <mat-cell *matCellDef="let row" class="track-meta-value" style="text-transform: none;">{{ row.publisherName | titlecase}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="publisher_pro">
            <mat-header-cell *matHeaderCellDef class="track-meta-label">PUB PRO</mat-header-cell>
            <mat-cell *matCellDef="let row" class="track-meta-value">{{ row.publisherSociety }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="publisher_percentage">
            <mat-header-cell *matHeaderCellDef class="track-meta-label" style="padding-right:0;">PUB %</mat-header-cell>
            <mat-cell *matCellDef="let row" class="track-meta-value">{{ row.publisherShare }}</mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns" class="main-row"></mat-row>
        </mat-table>
      </div>
    </div>
    <div class="track-meta" *ngIf="track.metadata && !!track.metadata.length">
      <div class="track-meta-item">
        <span class="track-meta-label">Tags:</span>
        <span class="track-meta-value">
          <ng-container *ngFor="let m of track.metadata; last as isLast; trackBy: metadataTrackBy">
            <a [routerLink]="['/browse']"
              [queryParams]="{search: m.keyword}"
              (click)="close()">
              {{ m.keyword }}
            </a><ng-container *ngIf="!isLast">, </ng-container>
          </ng-container>
        </span>
      </div>
    </div>
    <div class="track-meta" [ngClass]="{'display-user-inputs': displayUserInputs}" *ngIf="track">
      <div class="track-meta-item">
        <span class="track-meta-label">Instrumentation:</span>
        <span class="track-meta-value">
          <ng-container *ngFor="let instrument of ((composers['version_instruments'].length > 0) ? composers['version_instruments'] : []) ; last as isLast; trackBy: instrumentsTrackBy">
            <span [class]="getUserInputsColor(instrument)">{{ instrument.name }}</span><ng-container *ngIf="!isLast || composers['version_instruments_desc'].length !==0 || composers['instruments_desc'].length !== 0  ">, </ng-container>
          </ng-container>
          <ng-container *ngFor="let netRef of ((composers['version_instruments_desc'].length > 0) ? composers['version_instruments_desc'] : []);last as isLast">
            <span [class]="getUserInputsColor(netRef)">{{ netRef.name }}</span><ng-container *ngIf="!isLast">, </ng-container>
          </ng-container>
        </span>
      </div>
      <div class="track-meta-item">
        <span class="track-meta-label">TV / Network References:</span>
        <span class="track-meta-value">
          <ng-container *ngFor="let netRef of ((composers['version_tv_nw_references'].length > 0) ? composers['version_tv_nw_references'] : []); last as isLast; trackBy: networkReferencesTrackBy">
            <span [class]="getUserInputsColor(netRef)">{{ netRef.name }}</span><ng-container *ngIf="!isLast || composers['support_tv_nw_references'] !==0">, </ng-container>
          </ng-container>
          <ng-container *ngFor="let netRef of composers['support_tv_nw_references'];last as isLast">
            <span [class]="getUserInputsColor(netRef)">{{ netRef.name }}</span><ng-container *ngIf="!isLast">, </ng-container>
          </ng-container>
        </span>
      </div>
      <div class="track-meta-item">
        <span class="track-meta-label">Sonic References:</span>
        <span class="track-meta-value">
          <ng-container *ngFor="let sonicRef of ((composers['version_sonic_references'].length > 0) ? composers['version_sonic_references'] : []); last as isLast; trackBy: sonicReferencesTrackBy">
            <span [class]="getUserInputsColor(sonicRef)">{{ sonicRef.name }}</span><ng-container *ngIf="!isLast || composers['support_sonic_band_references'] !==0">, </ng-container>
          </ng-container>
          <ng-container *ngFor="let sonicRef of composers['support_sonic_band_references'];last as isLast">
            <span [class]="getUserInputsColor(sonicRef)">{{ sonicRef.name }}</span><ng-container *ngIf="!isLast">, </ng-container>
          </ng-container>
        </span>
      </div>
    </div>
    <button *ngIf="version.is_full_version && data.type ===''" class="edit-track-button" (click)="editTrackPressed()">EDIT TRACK</button>
    <button *ngIf="!version.is_full_version && data.type ===''" class="edit-track-button" (click)="editTrackPressed()">EDIT TRACK VERSION</button>

  </div>
</mat-dialog-content>
