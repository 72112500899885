<div class="dialog-container">
    <div class="dialog-header">
        <h4 class="dialog-heading">edit genre</h4>
        <button type="button" class="close-button" (click)="close()">
        <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                    <g id="Genre-Options">
                        <g id="Close" transform="translate(1439.000000, 32.000000)">
                            <path d="M24,0 L0,24" id="Path"></path>
                            <path d="M0,0 L24,24" id="Path"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        </button>
    </div>
<!-- code for add genre -->
     <mat-dialog-content>
        
        <app-loader *ngIf="loader" color="#50e3c2"></app-loader>
        <div class="dialog-body" *ngIf="!loader">

            <p><strong>{{list.currGnere.name}}</strong></p>
              <div class="grid-card">
                <div class="grid-item">
                    <mat-form-field appearance="fill" class="group">
                        <mat-label>rename Genre to</mat-label>
                        <input matInput [(ngModel)]="gnereName">
                    </mat-form-field>
                   
                </div>
            </div> 
            <div class="imgfile-container"  style="margin-bottom: 30px;">
                <mat-form-field appearance="fill" class="group image-dialog" >
                    <mat-label>Image</mat-label>
                    <input class="wrap-textfilename" matInput placeholder="File name"  [(ngModel)]="image" readonly>
                    <div class="file-input " style="position: absolute;
           right: -1%;
    top: -68%;">
              <input #Image 
                type="file"  (change)="handleFileInput($event.target.files,$event)" 
                name="file-input"
                id="file-input"
                class="file-input__input" accept="image/*"  #myFileInput
                (click)="onFileChange($event)"  readonly>
              <label class="file-input__label" for="file-input">
                <span>choose file</span></label>
            </div>
                </mat-form-field>
             </div>
        
             <p class="note" style="position: relative;margin-bottom: 35px;margin-top: -7%;" >Aspect Ratio – 16:9  (jpeg)
                <span style="position: absolute;right:40%;">Max file size: 500KB</span>
                </p>  
                <!-- <div *ngIf="maximumError" class="container-image" style="margin-bottom: 15px;">
                    <p>Max file size: 500KB, Please upload less then 500KB</p>
                  </div>  -->
                  <div *ngIf="maximumDimention" class="container-image" style="margin-bottom: 15px;">
                    <p>Please upload the correct valid Dimension image</p>
                  </div>
                <div *ngIf="show" class="crop-style" style=" box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);" >
                    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="false" [containWithinAspectRatio]= 'false' [aspectRatio]="4 / 3" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
                       <div *ngIf="show" style="margin-bottom: 0%; margin-left: 34%; margin-top: 2%;">
                          <button class="crop-btn ok-btn" mat-button color="primary" (click)="cropData()">ok</button>
                          <button class="crop-btn" mat-button color="primary" (click)="toggleclose()">cancel</button>
                          <!-- <button mat-button color="primary" class="crop-btn"  >Redo</button>
                          <button mat-button color="primary" class="crop-btn" >Undo</button> -->
                       </div>
                </div>

         <div class="crop-style" *ngIf="showCropper" style=" width: 66% !important;position: inherit; box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);">
             <img *ngIf="currentCropImage" [src]="currentCropImage" style="height: 160px;width: 249px; margin-left: 17%">
                 <div *ngIf="showCropper" style="margin-bottom: -4%; margin-left: 30%; margin-top: 10px;">
                   <button class="crop-btn  ok-btn" mat-button color="primary" (click)="crop()">save</button>
                   <button class="crop-btn" mat-button color="primary" (click)="resetCrop()">Reset</button>
                 </div>
         </div>
                          
          <div class="imgstore-container" style="display: inline-flex;width: 250px; height: 160px;margin-bottom:20px;" >
            <img style="object-fit: cover;" *ngIf="imageUrl" [src]="imageUrl">
              <div class="content">
                <img   *ngIf="finalImg" [src]="finalImg" style="height: 160px; width: 140px;">
                <br/>
              </div>
          </div>
            
            <div class="form-col">
              <button class="form-button"  [disabled]="(gnereName == '' && isEditImage === false)" (click)="save()">save</button>
              <button class="form-button form-button-cancel" (click)="close()">Cancel</button>
            </div>
          </div>
    </mat-dialog-content> 

</div>

