import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, of } from 'rxjs';
import { Track } from '../models/track.model';
import { User } from '../models/user.model';
import { ActivityService } from '../services/activity.service';

const activityLimit = 30;

export interface DialogData {
  user: User;
}

declare type ClientActivityListDataSource = Observable<ReadonlyArray<User>>;

export class ClientActivityListRow {
  constructor(
    public track: Track,
    public downloaded: Date
  ) {}
}
@Component({
  selector: 'app-client-activity-dialog',
  templateUrl: './client-activity-dialog.component.html',
  styleUrls: ['./client-activity-dialog.component.scss'],
  animations: [
    trigger('rowInOut', [
      transition(':enter', [
        style({
          opacity: '0',
          height: '0',
          paddingTop: '0',
          overflow: 'hidden',
          paddingBottom: '0',
        }),
        animate(
          '0.7s cubic-bezier(0.19, 1, 0.22, 1)',
          style({
            opacity: '1',
            height: '*',
          })
        ),
      ]),
      transition(':leave', [
        style({
          opacity: '1',
          height: '*',
          overflow: 'hidden',
        }),
        animate(
          '0.7s cubic-bezier(0.19, 1, 0.22, 1)',
          style({
            opacity: '0',
            height: '0',
            paddingTop: '0',
            paddingBottom: '0',
          })
        ),
      ]),
    ]),
  ],
})
export class ClientActivityDialogComponent implements OnInit {

  loading:boolean = false;
  totalActivities:number = 0;
  currentPage:number = 1;

  displayedColumns: string[] = [
    'title',
    'artist',
    'downloaded',
  ];

  get listDataSource() {
    return this._dataSource;
  }

  get numberOfPages(): number {
    return Math.ceil(this.totalActivities/activityLimit);
  }

  // private _dataSource: Observable<ReadonlyArray<ClientActivityListRow>>;
  _dataSource: MatTableDataSource<[]>;

  constructor(
    private dialogRef: MatDialogRef<ClientActivityDialogComponent>,
    private _activityService: ActivityService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
    this.loadActivities();
  }

  close() {
    this.dialogRef.close(false);
  }

  listTrackBy(_, item: ClientActivityListRow) {
    return item.track.id + item.downloaded.toISOString();
  }

  pageChange(page:number) {
    if (page != this.currentPage) {
      this.currentPage = page;
      this.loadActivities();
    }
  }

  loadActivities() {
    this.loading = true;
    this._dataSource = new MatTableDataSource<[]>();
    this._activityService.getActivities({
      limit: activityLimit,
      offset: activityLimit * (this.currentPage-1),
      userId: this.data.user.id,
      action: 'downloaded'
    }).subscribe(activities => {
      this.loading = false;
      this.totalActivities = activities.count;
    //   this._dataSource = of(activities.results.map(activity => {
    //     return new ClientActivityListRow(activity.content_object as Track, activity.created_at)
    //   }))
    // }, (error) => {
    //   console.log(error);
    //   //alert("An error ocurred while getting the activity for this client. Check your connections and try again");
    // });
      this._dataSource.data = activities.results as [];
      console.log(this._dataSource.data )
      // this._dataSource = of(activities.results.map(activity => {
      //   return new ClientActivityListRow(activity.content_object as Track, activity.created_at)
      // })
    })
  }

}
