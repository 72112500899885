import { Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CdkPortalOutlet, ComponentPortal, Portal } from '@angular/cdk/portal';
import { animate, group, query, state, style, transition, trigger } from '@angular/animations';
import { PlayerService } from '../services/player.service';
import { SliderDialogService } from '../services/slider-dialog.service';

const ANIMATION_TIMINGS = '300ms cubic-bezier(0.25, 0.8, 0.25, 1)';

@Component({
  selector: 'app-slider-dialog-container',
  templateUrl: './slider-dialog-container.component.html',
  styleUrls: ['./slider-dialog-container.component.scss'],
  animations: [
    trigger('slideContent', [
      state('void', style({ transform: 'translate3d(100%, 0, 0)', opacity: 0 })),
      state('enter', style({ transform: 'none', opacity: 1 })),
      state('leave', style({ transform: 'translate3d(100%, 0, 0)', opacity: 0 })),
      transition('* => *', animate(ANIMATION_TIMINGS)),
    ])
  ]

})
export class SliderDialogContainerComponent implements OnInit {

  @Input()
  dialogTitle = 'EDIT TRACK';

  @Input()
  componentPortal: ComponentPortal<any>;

  @Output()
  containerEvent = new EventEmitter<{ key: 'CLOSE' }>();

  @ViewChild("portal", { read: CdkPortalOutlet, static: true })
  portal: CdkPortalOutlet;

  @Input()
  selectedPortal: Portal<any>;

  animationState: 'void' | 'enter' | 'leave' = 'enter';
  animationStateChanged = new EventEmitter<AnimationEvent>();

  trackCreationType:any;

  constructor(private _playerService: PlayerService, private _sliderDialogService: SliderDialogService) {
    this._sliderDialogService.getTrackCreationTypeSubject.subscribe((u) => {
      this.trackCreationType = u;
      console.log(this.trackCreationType)
    });
   }

  ngOnInit(): void {

  }

  attach() {
    const c = this.portal.attach(this.selectedPortal);
    return c.instance;
  }

  closeDialog() {
    this._sliderDialogService.getTrackCreationTypeSubject.next(null);
    this.startExitAnimation();
    this._playerService.updatePreviewPlaying(false);
    this._playerService.updateViewType(false);
    // this.containerEvent.emit({key: 'CLOSE'});
  }

  onAnimationStart(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }

  onAnimationDone(event: AnimationEvent) {
    this.animationStateChanged.emit(event);

    if (this.animationState == 'leave') {
      this.containerEvent.emit({ key: 'CLOSE' })
      this._playerService.updatePreviewPlaying(false);
      this._playerService.updateViewType(false);

    }

  }

  startExitAnimation() {
    this.animationState = 'leave';
    this._playerService.updatePreviewPlaying(false);
    this._playerService.updateViewType(false);

  }

}
