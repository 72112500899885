import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject, Subscription } from 'rxjs';
import { Playlist } from '../models/playlist.model';
import { Track } from '../models/track.model';
import { TrackVersion } from '../models/trackversion.model';
import { DialogService } from '../services/dialog.service';
import { PlayerService } from '../services/player.service';
import { PlaylistService } from '../services/playlist.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SessionStorageService } from '../services/session-storage.service';


const playlistLimit = 30;

export interface DialogData {
  track: Track;
  version: TrackVersion;
  list: any;
  trackid: any;
}

@Component({
  selector: 'app-add-track-playlist-dialog',
  templateUrl: './add-track-playlist-dialog.component.html',
  styleUrls: ['./add-track-playlist-dialog.component.scss']
})

export class AddTrackPlaylistDialogComponent implements OnInit, OnDestroy {
  constructor(
    private sessionStorageService: SessionStorageService,
    private dialogRef: MatDialogRef<AddTrackPlaylistDialogComponent>,
    private _playlistService: PlaylistService,
    public _DialogService: DialogService,
    private _playerService: PlayerService,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this._playerService.nextTab(true);
    this._playerService.updateViewType(true);
    this._playerService.updatePreviewPlaying(true);
    this._playerService.updateShowMusicPlayer(false);
    this._playerService.play(null, null);
  }

  disableBtn: any = 'Disabled';
  denieBtn: any = 'Denied';
  approveBtn: any = 'Approved';
  pendingApproveBtn: any = 'Pending Approval';
  pendingBtn: any = 'Pending';
  newPlaylistFolder = true;
  loading = true;
  playlistList: any[] = [];
  playlistOptions: ReplaySubject<any> = new ReplaySubject<any>(1);;
  playlistcheckbox: any = [];
  oldPlaylistData: any = [];

  // carries the ID(s) of active playlist(s) to determine whether set active visually or not (i.e. in 'Add to Playlist' menu)
  // GW 4/3/24 renamed 'newPlaylistChecked' to activePlaylistsIds for clarity
  activePlaylistsIds: any = []
  activePlaylistId: any;
  activePlaylistName: any;
  // activePlaylistSessionKey: string;
  trackCheckBox: any = [];
  private _subscriptions: Subscription[] = [];
  trackId: any;
  playlist: any = [];

  // array of playlists which contain a given track (i.e. in 'Add to Playlist' menu)
  // GW 4/3/24 renamed 'playlistName' to playlistsContainingTrack for clarity
  playlistsContainingTrack: any = [];
  newSuggestion: any = [];
  newplaylistSuggestion: any = []
  versionData: any = [];
  versionList: any = [];
  playlistCtrl = new FormControl();
  duplicate: boolean = false;
  topTrackValue: number = 0;
  enableTop: boolean = false;
  onetrack: boolean = false;
  allVersion: boolean = false;
  enableButton: boolean = false;
  topTrackList: boolean = false;
  removePlaylistList = []
  @ViewChild('allSelected') private allSelected: MatOption;
  tempoFilterCtrl = new FormControl();

  ngOnInit(): void {

    if (!this.data) return;
    this.versionData = this.data.track.versions
    this.getTopTrackDetail(this.data.track.id)
    this.versionData.forEach(element => {
      if (element.status == 'APPROVED') {
        element.topTrack = false;
        element.checked = false;
        this.versionList.push(element)
      }
    })
    this.getPlaylist()
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
    this._subscriptions = [];
  }

  close() {
    this.dialogRef.close(false);
  }

  isPlaylistChecked(playlist: Playlist) {
    return this.playlistcheckbox.some((p) => p == playlist.id);
  }

  playlistChecked(checked: boolean, playlist: Playlist) {
    if (checked) {
      this.playlistcheckbox.push(playlist);
      let i = this.removePlaylistList.findIndex((p) => p == playlist.id);

      if (i >= 0) {
        this.removePlaylistList.splice(i, 1);
      }
    } else {
      let i = this.playlistcheckbox.findIndex((p) => p == playlist.id);
      this.removePlaylistList.push(playlist.id)
      if (i >= 0) {
        this.playlistcheckbox.splice(i, 1);
      }
    }

  }
  playlistIndex = 1
  loadMore() {
    this.getPlaylist();
  }
  getPlaylist(value: any = '') {
    let data = ''
    if (value.trim() !== '') {
      data = value
    }
    this._playlistService.getAddsongtoplaylist1(data, this.playlistIndex).subscribe(res => {
      if (data !== '') {
        this.playlistList = res.results;
        this.playlistIndex = 0
      }
      else {
        for (let play of res.results) {
          this.playlistList.push(play)
          this.playlistIndex++
        }
      }


      this.playlistOptions.next(this.playlistList);
      this.loading = false;
      if (this.data.trackid) {
        this.trackId = this.data.trackid;

      } else {
        this.trackId = this.data.version.id;
      }
      //   if(this.versionList.length > 0){
      //   this.versionList.forEach(element => {
      //     if(this.trackId === element.id){
      //       console.log("id ", this.data.track.id, this.trackId)
      //      // element.checked = true;
      //       //this.trackCheckBox.push(this.trackId)
      //     }
      //     else{
      //     element.checked = false;
      //     }

      //   });
      // }
      // if(this.versionList.length === 1){
      //   this.versionList.forEach(element => {
      //     if(this.topTrackValue == 0){
      //     element.topTrack = true;
      //     this.topTrackValue = element.id;
      //     this.enableTop = true
      //     this.onetrack = true;
      //     }
      //     this.enableTop = true
      //     element.topTrack = true;
      //     this.onetrack = true;
      //   });

      // } 
      if (data === '') {
        this._playlistService.getassignedplaylistfortrack(this.trackId).subscribe(res => {
          // this.sessionStorageService.activePlaylistSessionName = res['current'][res['current'].length - 1].name;
          this.playlistcheckbox = res['current']
          let versionData = res['versions_ids']
          if (res['existing'].length > 0) {
            for (let i = 0; i < res['existing'].length; i++) {
              this.playlistcheckbox.push(res['existing'][i])
            }
          }
          const ids = this.playlistcheckbox.map(o => o.id)
          const filtered = this.playlistcheckbox.filter(({ id }, index) => !ids.includes(id, index + 1))
          this.playlistcheckbox = filtered
          this.playlistcheckbox.forEach(element => {
            let data = {
              id: element.id,
              value: element.name,
              isNew: false
            }
            let isRepeat = this.playlistsContainingTrack.find((ele: any) => (ele.id == data.id))
            if (!isRepeat) {
              this.playlist.push(element.id)
              this.playlistsContainingTrack.push(data);
            }
          });
          let dataVersion = 0
          this.versionList.forEach(element => {
            versionData.forEach(element1 => {
              if (element.id === element1) {
                element.checked = true;
                dataVersion++;
                this.trackCheckBox.push(element1)
              }
            })
          });
          if (this.versionList.length === dataVersion) {
            this.allVersion = true;
          }
          this.playlistsContainingTrack.filter((v, i, a) => a.findIndex(t => (t.value === v.value)) === i)
          this.playlistcheckbox = this.playlist
          this.oldPlaylistData = this.playlist
          console.log(this.playlist)


          this.loadSuggestion(res['current'])
        })
      }
    })

  }
  // function called when a playlist is selected off the searchable list
  // GW 4/3/24 changed occurrences of 'value' to 'playlistId'
  playlistAllSelection(event, playlistId, name) {
    console.log(event.source.selected)
    console.log(playlistId)
    console.log(name)
    this.setSessionActivePlaylist(playlistId, name);
    let newValue = '';
    if (event.source.selected) {
      newValue = playlistId;
      this.playlistList.forEach(element => {
        if (playlistId === element.id) {
          const index1 = this.playlistsContainingTrack.findIndex(i => i.value == element.name);
          if (index1 >= 0) {
            this.playlistsContainingTrack.splice(index1, 1);
          }
          let data = {
            id: element.id,
            value: element.name,
            isNew: false
          }
          this.playlistsContainingTrack.push(data)
        }
      });
      const index1 = this.oldPlaylistData.findIndex(i => i == playlistId);
      if (index1 >= 0) {
        const index = this.removePlaylistList.findIndex(i => i == playlistId);
        if (index >= 0) {
          this.removePlaylistList.splice(index, 1);
        }
      }
      else {
        this.trackCheckBox = [];
        this.versionList.forEach(element => {
          element.checked = false;
        });
        this.allVersion = false
        this.activePlaylistsIds.push(playlistId)
        // this.setSessionActivePlaylist(playlist.id, playlist);
        // this.playlistcheckbox.push(value)
      }
    }
    if (event.source.selected === false) {
      newValue = playlistId;
      const index1 = this.playlistsContainingTrack.findIndex(i => i.value == name);
      if (index1 >= 0) {
        let newList = this.playlistsContainingTrack[index1].id
        this.playlistsContainingTrack.splice(index1, 1);
        const index2 = this.oldPlaylistData.findIndex(i => i == playlistId);
        if (index2 >= 0) {
          this.removePlaylistList.push(newList)
        }
      }
      let newList;
      const index = this.activePlaylistsIds.findIndex(i => i == playlistId);
      if (index >= 0) {
        newList = this.activePlaylistsIds[index]
        this.activePlaylistsIds.splice(index, 1);
        this.trackCheckBox = [];
        this.versionList.forEach(element => {
          element.checked = false;
        });
        this.allVersion = false
      }

    }
    this.playlistsContainingTrack.filter((v, i, a) => a.findIndex(t => (t.value === v.value)) === i)
    for (let j = 0; j < this.activePlaylistsIds.length; j++) {
      for (let i = 0; i < this.playlistsContainingTrack.length; i++) {
        if (this.playlistsContainingTrack[i].id === this.activePlaylistsIds[j]) {
          this.playlistsContainingTrack[i].newPlay = true;
        }
      }
    }

    if (this.activePlaylistsIds.length > 0) {
      this.enableButton = true;
    }
    else {
      this.enableButton = false;
    }
  }

  getTopTrackDetail(id) {
    this._playlistService.getTopTrackPlaylist(id).subscribe(res => {
      let data = res || []
      if (data['top_version'] !== null) {
        this.topTrackValue = data['top_version'];
        // if(this.versionList.length > 0){
        //   this.versionList.forEach(element => {
        //     if( this.topTrackValue !== 0  && this.topTrackValue ===  element.id){
        //       element.topTrack = true;
        //       this.enableTop = true;
        //       this.topTrackValue = element.id
        //     }
        //     else{
        //       element.topTrack = false;
        //       this.enableTop = false;
        //       //this.topTrackValue = 0
        //     }
        //   });
        // }
        // if(this.versionList.length === 1){
        //   this.versionList.forEach(element => {
        //     if(this.topTrackValue == 0){
        //     element.topTrack = true;
        //     this.topTrackValue = element.id;
        //     this.enableTop = true
        //     this.onetrack = true;
        //     }
        //     this.enableTop = true
        //     element.topTrack = true;
        //     this.onetrack = true;
        //   });
        // }
      }
    })
  }
  topTrackChecked(event, playlist) {
    if (event) {
      this.enableTop = true;
      this.topTrackValue = playlist.id
    }
    else {
      this.enableTop = false;
      this.topTrackValue = 0
    }
  }
  clearPlaylistInput() {
    this.playlistCtrl.setValue('');
  }

  trackChecked(check: boolean, value: any, select: any) {

    if (check === true && select === 'All') {
      for (let i = 0; i < value.length; i++) {
        this.trackCheckBox.push(value[i].id)
      }
      this.allVersion = true
      this.versionList.forEach(element => {
        element.checked = true;
      });
    }
    else if (check === false && select === 'All') {
      this.trackCheckBox = [];
      this.versionList.forEach(element => {
        element.checked = false;
      });
      this.allVersion = false
    }
    else if (check === true && select === '') {
      this.trackCheckBox.push(value.id)
    }
    else {
      let i = this.trackCheckBox.findIndex((p) => p == value.id);
      if (i >= 0) {
        this.trackCheckBox.splice(i, 1);
      }
    }
    console.log(this.trackCheckBox)
  }

  save() {
    this.loading = true;
    let newData = this.oldPlaylistData.filter(val => !this.playlistcheckbox.includes(val));
    newData = newData.filter(val => !this.removePlaylistList.includes(val));
    // set active playlist here
    let active = this.getActivePlaylistFromSession();
    if (this.removePlaylistList.includes(active.id)) {
      this.removeActivePlaylistFromSession();
    } else {
      this.setSessionActivePlaylist(this.sessionStorageService.activePlaylistSessionKey, this.sessionStorageService.activePlaylistSessionName);
    }
    // this.setSessionActivePlaylist(this.activePlaylistId, this.activePlaylistName);
    this._playlistService.addMusicTrackVersionsToPlaylist(this.trackId, this.activePlaylistsIds, this.trackCheckBox, this.newSuggestion, this.removePlaylistList).subscribe(f => {
      this.closeIfDone();
      this.loading = false;

    }, (error) => {
      console.log(error);
      // alert(error.error.error)
      let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
      this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
      });
      this.close()
      // alert(`An error ocurred while removing versions ${removed.toString()} from the playlist. Check connection and try again`);
    })


  }


  // Add custom instruments
  addCustomPlaylist(): void {
    let id, duplicateData = false, newDate = [];
    if (this.playlistCtrl.value.trim() !== '') {
      this.playlistList.forEach(ele => {
        if (this.playlistCtrl.value.trim() !== '') {
          if (ele.name.trim() === this.playlistCtrl.value.trim()) {
            id = ele.id
          }
        }
      })
      if (id) {
        this.duplicate = true;
      }
      else {
        this.duplicate = false;
        this.playlistsContainingTrack.forEach(element => {
          if (element.value.trim() === this.playlistCtrl.value.trim()) {
            duplicateData = true;
          }
        });
        if (!duplicateData) {
          let newChanges = {
            value: this.playlistCtrl.value,
            isNew: true
          }
          this.playlistsContainingTrack.forEach(element => {
            if (element.value.trim() !== this.playlistCtrl.value.trim()) {
              newDate.push(element);
            }
          })
          this.playlistsContainingTrack[this.playlistsContainingTrack.length] = (newChanges);
          this.newSuggestion[this.newSuggestion.length] = (this.playlistCtrl.value);
          this.newplaylistSuggestion.forEach(element => {
            if (element.value.trim() !== this.playlistCtrl.value.trim()) {
              newDate.push(element);
            }
          })
          newDate.filter((v, i, a) => a.findIndex(t => (t.value === v.value)) === i)
          this.newplaylistSuggestion = (newDate);
          this.playlistCtrl.setValue('');
          this.trackCheckBox = [];
          this.versionList.forEach(element => {
            element.checked = false;
          });
          this.allVersion = false
          // console.log(this.playlistName)
        }
      }
    }
    if (this.newSuggestion.length > 0) {
      this.enableButton = true;
    }
    else {
      this.enableButton = false;
    }
  }

  removeCustom(event) {
    let data = [], dataId = [], id, value = [], newDataId = [], value1 = [];
    this.playlistsContainingTrack.forEach(element => {
      if (element.value !== event) {
        data.push(element)
      }
    });
    this.playlistsContainingTrack.forEach(element => {
      if (element.value == event) {
        if (element?.id) {
          this.removePlaylistList.push(element.id)
        }
      }
    });
    this.playlistList.forEach(ele => {
      if (ele.name === event) {
        id = ele.id
      }
    })
    this.playlistcheckbox.forEach(element => {
      if (element !== id) {
        dataId.push(element)
      }
    });
    this.activePlaylistsIds.forEach(element => {
      if (element !== id) {
        newDataId.push(element)
      }
    });

    this.newSuggestion.forEach(element => {
      if (element !== event) {
        value.push(element)
      }
    });

    // this.oldPlaylistData.forEach(element => {
    //   if(element !== event){
    //     value1.push(element)
    //   }
    // });
    this.newSuggestion = value;

    this.playlistsContainingTrack = data;
    this.newplaylistSuggestion = data;
    this.playlistcheckbox = dataId
    // this.oldPlaylistData = value1;
    this.activePlaylistsIds = newDataId
    if (this.activePlaylistsIds.length > 0) {
      this.enableButton = true;
    }
    else {
      this.enableButton = false;
    }
    this.removePlaylistList && this.removePlaylistList.filter((v, i, a) => a.findIndex(t => (t === v)) === i)
  }
  private closeIfDone() {
    this.dialogRef.close(true);
  }

  // Responsible for the "selected / recently edited playlist"
  // Calls get-latest-playlist
  // parameters:
  //   res - contains array of playlists which the selected track is previously found in
  loadSuggestion(res) {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    this.http.get<any>(environment.apiURL + '/api/v1/admin/playlists/get-latest-playlist/', { headers: headers }).subscribe(response => {

      let activePlaylist = this.getActivePlaylistFromSession();
      // if session has playlist, use it as active; else pull latest playlist from server & make it default session playlist
      if (activePlaylist) {
        activePlaylist = { id: activePlaylist.id, value: activePlaylist.value, isNew: false, newPlay: true }
        this.enableButton = true;
        let containsActivePlaylist = false;
        res.forEach(existingPlaylist => {
          if (activePlaylist.id === existingPlaylist.id) {
            containsActivePlaylist = true;
          }
        })
        if (!containsActivePlaylist) {
          if (activePlaylist.id && activePlaylist.value) {
            this.playlistsContainingTrack.push(activePlaylist);
          }
          this.activePlaylistsIds.push(activePlaylist.id);
          console.log('hello');
        }
      } else {
        response.forEach(x => {
          let play = { "id": x.id, "value": x.name, "isNew": false, "newPlay": true }
          this.enableButton = true
          // state -> when true, existing playlists does not contain the latest ("active") playlist
          let state = true
          res.forEach(exist => {
  
            if (play.id === exist.id) {
              state = false
            }
          })
          if (state) {
            if (play.id && play.value) {
              this.playlistsContainingTrack.push(play)
            }
            this.activePlaylistsIds.push(play.id);
          }
          this.setSessionActivePlaylist(play.id, play.value);
          // this.setSessionActivePlaylist(play.value);
          // this.activePlaylistId = play.id;
          // this.activePlaylistName = play.value;
        })
      }
    })
  }

  setSessionActivePlaylist(playlistId: any, playlistName: any) {
    let playlist = {id : playlistId, value: playlistName}
    this.sessionStorageService.activePlaylistSessionKey = playlistId;
    this.sessionStorageService.activePlaylistSessionName = playlistName;
    this.sessionStorageService.setItem('activePlaylist', playlist);
  }

  // Get this without key?
  getActivePlaylistFromSession() {
    return this.sessionStorageService.getItem('activePlaylist');
  }

  removeActivePlaylistFromSession() {
    this.sessionStorageService.removeItem('activePlaylist');
  }
}
