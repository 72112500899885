<form [formGroup]="proForm" *ngIf="!loading">
  <div class="profile-info">
      <div class="menu-user-info">
      <div class="section general">
              <div class="title">
                  <h3>P.R.O. Details</h3>
              </div>
              
            
              <div class="grid-card">
                  <div class="grid-item">
                      <mat-form-field appearance="fill" floatLabel="always" [ngClass]="{'disabledElement': proFieldDisable}">
                          <mat-label>P.R.O</mat-label>
                          <!-- <mat-label>Artist Royalties Information</mat-label> -->
                          <!-- <input matInput [(ngModel)]="myProDetails.artist_royalities" formControlName="artist_royalities"> -->
                          <mat-select [(value)]="Selects" placeholder="Select" [panelClass]="'card-select'" [(ngModel)]="myProDetails.pro" formControlName="pro">
                            <mat-option value="Select">Select</mat-option>
                            <mat-option>
                            <ngx-mat-select-search
                              [searching]="searchingPRO"
                              [formControl]="proFilterCtrl"
                              placeholderLabel="Select a PRO"
                              noEntriesFoundLabel="No Options Found">
                              <svg ngxMatSelectSearchClear class="filter-clear-icon" width="26px" height="24px" viewBox="0 0 26 24"
                                version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                                  stroke-linejoin="round">
                                  <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                                    <g id="Genre-Options">
                                      <g id="Close" transform="translate(1439.000000, 32.000000)">
                                        <path d="M24,0 L0,24" id="Path"></path>
                                        <path d="M0,0 L24,24" id="Path"></path>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </ngx-mat-select-search>
                          </mat-option>
                          <mat-option *ngFor="let option of filteredPROOptions | async" [value]="option.id">
                            {{ option?.pro }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                  </div>
              </div>
              <div class="grid-card">
                  <div class="grid-item">
                      <mat-form-field appearance="fill" [ngClass]="{'disabledElement': caeipiFieldDisable}">
                          <mat-label>CAEIPI #</mat-label>
                          <input matInput [(ngModel)]="myProDetails.cae_ipi" formControlName="cae_ipi" mask="000-00-000000">
                      </mat-form-field>
                  </div>
              </div>
              <div class="grid-card">
                  <div class="grid-item">
                      <mat-form-field appearance="fill" [ngClass]="{'disabledElement': companyFieldDisable}">
                          <mat-label>Legal Name / Company Name</mat-label>
                          <input matInput [(ngModel)]="myProDetails.company_name" formControlName="company_name">
                      </mat-form-field>
                  </div>
              </div>
              <div class="grid-container">
      
                      <div class="grid-item">
                          <mat-form-field appearance="fill" >
                              <mat-label>Social Security</mat-label>
                              <input matInput [(ngModel)]="myProDetails.ssn" [type]="passwordInputType" readonly formControlName="ssn" mask="000-00-0000">
                              <button class="input-suffix" type="button" matSuffix (click)="togglePasswordInputType()">
                                <svg width="23px" height="18px" viewBox="0 0 23 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" *ngIf="passwordInputType == 'password'">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                                        <g transform="translate(-906.000000, -381.000000)" stroke="#15151C">
                                            <g transform="translate(512.000000, 160.000000)">
                                                <g transform="translate(49.000000, 191.000000)">
                                                    <g transform="translate(346.000000, 31.000000)">
                                                        <path d="M0,8 C0,8 3.74025974,0 10.2857143,0 C16.8311688,0 20.5714286,8 20.5714286,8 C20.5714286,8 16.8311688,16 10.2857143,16 C3.74025974,16 0,8 0,8 Z"></path>
                                                        <ellipse cx="10.2857143" cy="8" rx="2.80519481" ry="3"></ellipse>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                <svg width="23px" height="18px" viewBox="0 0 23 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" *ngIf="passwordInputType == 'text'">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                                        <g transform="translate(-906.000000, -381.000000)">
                                            <g transform="translate(512.000000, 160.000000)">
                                                <g transform="translate(49.000000, 191.000000)">
                                                    <g transform="translate(346.000000, 31.000000)">
                                                        <path d="M0,8 C0,8 3.74025974,0 10.2857143,0 C16.8311688,0 20.5714286,8 20.5714286,8 C20.5714286,8 16.8311688,16 10.2857143,16 C3.74025974,16 0,8 0,8 Z" stroke="#15151C" fill="#15151C"></path>
                                                        <ellipse stroke="#FFFFFF" fill="#FFFFFF" cx="10.2857143" cy="8" rx="2.80519481" ry="3"></ellipse>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                          </mat-form-field>
                      </div>
                      <div class="grid-item">
                          <mat-form-field appearance="fill" [ngClass]="{'disabledElement': einFieldDisable}">
                              <mat-label>Federal EIN (If Company)</mat-label>
                              <input  matInput [(ngModel)]="myProDetails.ein" formControlName="ein" mask="00-0000000">
                          </mat-form-field>
                      </div>
                  </div>
          </div>
          
          <div class="section contact">
              <button class="btn save-btn"  mat-button (click)="updatePRO()" [disabled]="proFieldDisable && caeipiFieldDisable && companyFieldDisable && ssnFieldDisable && einFieldDisable">Save</button>
          </div>
          </div>
  </div>
</form>  
<div  *ngIf="loading">
    <app-loader color="#50e3c2"></app-loader>
</div>




