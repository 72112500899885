import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


export interface DialogData {
  title: any,
  val: any
}
@Component({
  selector: 'app-duplicatetemplate-popup',
  templateUrl: './duplicatetemplate-popup.component.html',
  styleUrls: ['./duplicatetemplate-popup.component.scss']
})
export class DuplicatetemplatePopupComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<DuplicatetemplatePopupComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
  }
  close() {
    this.dialogRef.close();
  }

}
