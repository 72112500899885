import { Directive, ElementRef } from '@angular/core';
import { MatSelect } from '@angular/material/select';

@Directive({
  selector: 'mat-select'
})
export class SelectBackdropClassDirective {

  constructor(private el: MatSelect, private elRef: ElementRef) {
  }

  ngAfterViewInit() {
    if (this.elRef.nativeElement.classList.contains('custom-backdrop-class')){
      this.el.overlayDir.backdropClass = "myCustomBackdropClass";
    }
  }
}
