import { Injectable } from '@angular/core';
import { MenuConfigService } from './menu-config.service';
import { UserMenuService } from './user-menu.service';
// import { environment } from '../../../environments/environment.prod';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ComposerListService {
  loadingUser:boolean = false
  constructor(public config: MenuConfigService, public api: UserMenuService) { }



  getAllUser(){
    this.loadingUser = true;
    let url = environment.apiURL + this.config.doMenuUser;
    this.api.doGET(url).subscribe(x=>{
      // this.getAllUserArray = x;
      // this.AllusersDetails = x['results'] || []
      // this.currentAdminUserSubject.next(x['results'][0])
      // this.currentUserGroup = x['results'][0];
      this.loadingUser = false;
    },
    (error)=>{
      this.loadingUser = false;
      //alert(error.message)
    }
  )
  }
}
