<mat-table [dataSource]="listDataSource" matSort class="track-list reorder-play" 
cdkDropList
[cdkDropListData]="listDataSource"
(cdkDropListDropped)="dropTable($event)"  #table multiTemplateDataRows >
<ng-container matColumnDef="position">
  <th mat-header-cell *matHeaderCellDef> </th>
  <td mat-cell *matCellDef="let element" style="padding:11px;">
    <mat-icon *ngIf="!isNotDraggable" cdkDragHandle>reorder</mat-icon>
  </td>
</ng-container>
  <ng-container matColumnDef="title">
    <mat-header-cell *matHeaderCellDef>Track Name</mat-header-cell>
    <mat-cell   *matCellDef="let row;let i = index" style="color:#fff !important;">
      <!-- <button type="button" class="play-button" (click)="playButtonPressed(row)"
        *ngIf="!isPlaying(row)" [ngClass]="{'active_play': isPlaying_current(row)}">
        <svg width="8px" height="10px" viewBox="0 0 8 10" version="1.1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-25.000000, -23.000000)">
              <g transform="translate(16.000000, 16.000000)">
                <path class="inner-path"
                  d="M16.0796687,12.8192319 L11.3234623,16.1485764 C10.871013,16.4652909 10.2474826,16.3552561 9.93076808,15.9028068 C9.8131096,15.7347232 9.75,15.5345165 9.75,15.3293444 L9.75,8.67065556 C9.75,8.11837081 10.1977153,7.67065556 10.75,7.67065556 C10.9551721,7.67065556 11.1553788,7.73376517 11.3234623,7.85142364 L16.0796687,11.1807681 C16.532118,11.4974826 16.6421528,12.121013 16.3254383,12.5734623 C16.2584893,12.6691037 16.17531,12.752283 16.0796687,12.8192319 Z"
                  fill="#FFFFFF"></path>
              </g>
            </g>
          </g>
        </svg>
      </button>
      <button type="button" (click)="pauseButtonPressed(row,i)" class="pause-button"
        *ngIf="isPlaying(row)" [ngClass]="{'active_play': isPlayingPause(row)}">
        <svg width="10px" height="14px" viewBox="0 0 10 14" version="1.1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-426.000000, -746.000000)" fill="#15151C" class="inner-path">
              <g transform="translate(393.000000, 704.000000)">
                <path
                  d="M33.2499996,42.9499995 L36.4166662,42.9499995 L36.4166662,55.616666 L33.2499996,55.616666 L33.2499996,42.9499995 Z M39.5833328,42.9499995 L42.7499995,42.9499995 L42.7499995,55.616666 L39.5833328,55.616666 L39.5833328,42.9499995 Z">
                </path>
              </g>
            </g>
          </g>
        </svg>
      </button> -->
      <span  class="email" >
        <!-- {{row.playlistTracks ?row.playlistTracks.track_name:'' }} -->
        {{row?.title|| ''}}
      </span>
      <!-- <span>{{ row && row.playlistTracks  && row.playlistTracks.track_name ? row.playlistTracks.track_name:'' }}</span> -->

      <!-- <span>{{ row && row.playlistTracks && row.playlistTracks.track && row.playlistTracks.track.versions && row.playlistTracks.track.versions.length > 0 && row.playlistTracks.track.versions[0].title ? row.playlistTracks.track.versions[0].title:row.playlistTracks.track_name }}</span> -->
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="track__artist">
    <mat-header-cell *matHeaderCellDef>Artist</mat-header-cell>
    <mat-cell *matCellDef="let row" >
      <span  class="email" > {{
          row.artist?.name || ''
      }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="track__genre">
    <mat-header-cell *matHeaderCellDef>Genre</mat-header-cell>
    <mat-cell *matCellDef="let row">
   <span  class="email" >  {{ row && row.genre ? row.genre.name : "" }} </span>  
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="track__sub_genre">
    <mat-header-cell *matHeaderCellDef>Subgenre</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span  class="email" >  {{ row && row.sub_genre ? row.sub_genre.name : "" }} </span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="created_at">
    <mat-header-cell *matHeaderCellDef>Created</mat-header-cell>
    <mat-cell *matCellDef="let row">
      {{ row.created_at | date: 'shortDate' }}
    </mat-cell>
  </ng-container>

<ng-container matColumnDef="approved_at">
    <mat-header-cell *matHeaderCellDef>Approved</mat-header-cell>
    <mat-cell *matCellDef="let row">
      {{ row.versions[0].approved_at | date: 'shortDate' }}
     <!-- <ng-container *ngIf="row.playlistTracks.approved_at != 'APPROVED'">N/A</ng-container>
      <ng-container *ngIf="row.playlistTracks.approved_at == 'APPROVED'">YES</ng-container> -->
      <svg  class="arr-svg" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#cccc"><path d="M24 24H0V0h24v24z" fill="none" opacity=".87"/><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"/></svg>
    </mat-cell>

  </ng-container>

<!-- <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
    <mat-cell *matCellDef="let row">
   <ng-container *ngIf="!getTrackStatus(row.playlistTracks.track)">N/A</ng-container> 
      <span  *ngIf="row.playlistTracks.status == 'PENDING'" class="status-bullet" style="background-color: #E3B950;" [matTooltip]="pendingBtn" matTooltipPosition="left" matTooltipClass="tooltip-white"></span>
      <span  *ngIf="row.playlistTracks.status == 'PENDING_APPROVAL'" class="status-bullet" style="background-color: #47CDEF;" [matTooltip]="pendingApproveBtn" matTooltipPosition="left" matTooltipClass="tooltip-white"></span>
      <span  *ngIf="row.playlistTracks.status == 'APPROVED'" class="status-bullet" style="background-color: #50E3C2;" [matTooltip]="approveBtn" matTooltipPosition="left" matTooltipClass="tooltip-white"></span>
      <span  *ngIf="row.playlistTracks.status == 'DENIED'" class="status-bullet" style="background-color: #D73939;" [matTooltip]="denieBtn" matTooltipPosition="left" matTooltipClass="tooltip-white"></span>
      <span  *ngIf="row.playlistTracks.status == 'DISABLE'" class="status-bullet" style="background-color: #8A8A8D;" [matTooltip]="disableBtn" matTooltipPosition="left" matTooltipClass="tooltip-white"></span>
    </mat-cell>
  </ng-container> -->

  <!-- <ng-container matColumnDef="action">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let row">
      <button type="button" cdkOverlayOrigin #trigger="cdkOverlayOrigin" (click)="displayAddSongPlaylistDialog(row.playlistTracks)"
            matTooltip="Add to Playlist" matTooltipClass="tooltip-white" class="action-button">
        <svg class="add-playlist" width="17px" height="17px" viewBox="0 0 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
            stroke-linejoin="round">
            <g class="inner-stroke" transform="translate(0.000000, 1.000000)">
              <line x1="0.533333333" y1="2.66666667" x2="6.93333333" y2="2.66666667"></line>
              <line x1="0.533333333" y1="9.06666667" x2="13.3333333" y2="9.06666667"></line>
              <line x1="0.533333333" y1="15.4666667" x2="13.3333333" y2="15.4666667"></line>
              <g transform="translate(10.666667, 0.000000)">
                <line x1="2.66666667" y1="0" x2="2.66666667" y2="5.33333333"></line>
                <line x1="0" y1="2.66666667" x2="5.33333333" y2="2.66666667"></line>
              </g>
            </g>
          </g>
        </svg>
      </button>
    </mat-cell>

  </ng-container> -->
  <!-- Expand detail row  -->
  <ng-container matColumnDef="expandedDetail">
    <mat-cell *matCellDef="let row" style="width: 100%;">

      <div   style="width: 100%;"[@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'">
           <div class="version-row"  *ngFor="let version of row.versions;let i = index">
            <div class="mat-cell title" style="flex: 0 0 72%;">
             <button type="button" class="play-button" (click)="playButtonPressed(row, version)"
        *ngIf="!isPlaying(version)" [ngClass]="{'active_play': isPlaying_current(version)}">
        <svg width="8px" height="10px" viewBox="0 0 8 10" version="1.1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-25.000000, -23.000000)">
              <g transform="translate(16.000000, 16.000000)">
                <path class="inner-path"
                  d="M16.0796687,12.8192319 L11.3234623,16.1485764 C10.871013,16.4652909 10.2474826,16.3552561 9.93076808,15.9028068 C9.8131096,15.7347232 9.75,15.5345165 9.75,15.3293444 L9.75,8.67065556 C9.75,8.11837081 10.1977153,7.67065556 10.75,7.67065556 C10.9551721,7.67065556 11.1553788,7.73376517 11.3234623,7.85142364 L16.0796687,11.1807681 C16.532118,11.4974826 16.6421528,12.121013 16.3254383,12.5734623 C16.2584893,12.6691037 16.17531,12.752283 16.0796687,12.8192319 Z"
                  fill="#FFFFFF"></path>
              </g>
            </g>
          </g>
        </svg>
      </button>
      <button type="button" (click)="pauseButtonPressed(row,version,i)" class="pause-button"
        *ngIf="isPlaying(version)" [ngClass]="{'active_play': isPlayingPause(version)}">
        <svg width="10px" height="14px" viewBox="0 0 10 14" version="1.1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-426.000000, -746.000000)" fill="#15151C" class="inner-path">
              <g transform="translate(393.000000, 704.000000)">
                <path
                  d="M33.2499996,42.9499995 L36.4166662,42.9499995 L36.4166662,55.616666 L33.2499996,55.616666 L33.2499996,42.9499995 Z M39.5833328,42.9499995 L42.7499995,42.9499995 L42.7499995,55.616666 L39.5833328,55.616666 L39.5833328,42.9499995 Z">
                </path>
              </g>
            </g>
          </g>
        </svg>
      </button>
              <span class="version-title" style="margin-left:0;color:#fff;">
              
            {{version?.title || ''}}
            
              </span>
            </div>
            <!-- <div class="mat-cell actions">
              <button  type="button" matTooltip="Info"
                matTooltipClass="tooltip-white" class="action-button">
                <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(-292.000000, -20.000000)">
                      <g transform="translate(0.000000, -4.000000)">
                        <g transform="translate(292.000000, 24.000000)" class="inner-stroke">
                          <circle cx="8" cy="8" r="7.5"></circle>
                          <circle fill-rule="nonzero" cx="8" cy="4.75" r="1"></circle>
                          <line x1="8" y1="11" x2="8" y2="8" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round"></line>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </button>
              <button type="submit" matTooltip="Edit" matTooltipClass="tooltip-white" class="action-button">
              
                <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                    stroke-linejoin="round">
                    <g transform="translate(1.000000, 1.000000)" class="inner-stroke">
                      <path
                        d="M14.4,10.128 L14.4,14.4 C14.4,15.2836556 13.6836556,16 12.8,16 L1.6,16 C0.7163444,16 0,15.2836556 0,14.4 L0,3.2 C0,2.3163444 0.7163444,1.6 1.6,1.6 L5.872,1.6">
                      </path>
                      <polygon points="12.8 0 16 3.2 8 11.2 4.8 11.2 4.8 8"></polygon>
                    </g>
                  </g>
                </svg>
              </button>
              <button type="button"  matTooltip="Add to playlist" matTooltipClass="tooltip-white" 
                matTooltip="Add to Playlist" matTooltipClass="tooltip-white" class="action-button">
                <svg width="17px" height="17px" viewBox="0 0 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                    stroke-linejoin="round">
                    <g class="inner-stroke" transform="translate(0.000000, 1.000000)">
                      <line x1="0.533333333" y1="2.66666667" x2="6.93333333" y2="2.66666667"></line>
                      <line x1="0.533333333" y1="9.06666667" x2="13.3333333" y2="9.06666667"></line>
                      <line x1="0.533333333" y1="15.4666667" x2="13.3333333" y2="15.4666667"></line>
                      <g transform="translate(10.666667, 0.000000)">
                        <line x1="2.66666667" y1="0" x2="2.66666667" y2="5.33333333"></line>
                        <line x1="0" y1="2.66666667" x2="5.33333333" y2="2.66666667"></line>
                      </g>
                    </g>
                  </g>
                </svg>
              </button>
    
              <button type="button" matTooltip="Delete" matTooltipClass="tooltip-white" class="action-button" >
                <svg width="14px" height="16px" viewBox="0 0 14 16" version="1.1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                    stroke-linejoin="round">
                    <g transform="translate(-1.000000, 0.000000)" class="inner-stroke">
                      <g transform="translate(2.000000, 1.000000)">
                        <polyline points="0 2.8 1.33333333 2.8 12 2.8"></polyline>
                        <path
                          d="M10.6666667,2.8 L10.6666667,12.6 C10.6666667,13.3731986 10.069713,14 9.33333333,14 L2.66666667,14 C1.930287,14 1.33333333,13.3731986 1.33333333,12.6 L1.33333333,2.8 M3.33333333,2.8 L3.33333333,1.4 C3.33333333,0.62680135 3.930287,0 4.66666667,0 L7.33333333,0 C8.069713,0 8.66666667,0.62680135 8.66666667,1.4 L8.66666667,2.8">
                        </path>
                        <line x1="4.66666667" y1="6.3" x2="4.66666667" y2="10.5"></line>
                        <line x1="7.33333333" y1="6.3" x2="7.33333333" y2="10.5"></line>
                      </g>
                    </g>
                  </g>
                </svg>
              </button>
            </div> -->
            
            <div class="mat-cell dats" style="flex:0 0 12%;">
              <span>  {{ version.created_at | date: 'shortDate' }}</span>
            </div>
            <div class="mat-cell dats-approved" style="display: contents;">
              <span style="margin-left: -12px;">   {{ version.approved_at | date: 'shortDate' }}</span>
              <button type="button"  matTooltip="Delete" matTooltipClass="tooltip-white" class="del-action action-button action" (click)="removeTrack(version)">
                <svg width="15px" height="15px" viewBox="0 0 12 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <!-- <title>Delete</title> -->
                  <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                      <g id="Email-Mgmt-Desk-User-Client-Card" class="inner-stroke" transform="translate(-1288.000000, -16.000000)">
                          <g id="Actions" transform="translate(1016.000000, 0.000000)">
                              <g id="Group" transform="translate(270.000000, 16.000000)">
                                  <g id="trash-2" transform="translate(2.000000, 1.000000)">
                                      <polyline id="Path" points="0 2.8 1.33333333 2.8 12 2.8"></polyline>
                                      <path d="M10.6666667,2.8 L10.6666667,12.6 C10.6666667,13.3731986 10.069713,14 9.33333333,14 L2.66666667,14 C1.930287,14 1.33333333,13.3731986 1.33333333,12.6 L1.33333333,2.8 M3.33333333,2.8 L3.33333333,1.4 C3.33333333,0.62680135 3.930287,0 4.66666667,0 L7.33333333,0 C8.069713,0 8.66666667,0.62680135 8.66666667,1.4 L8.66666667,2.8" id="Shape"></path>
                                      <line x1="4.66666667" y1="6.3" x2="4.66666667" y2="10.5" id="Path"></line>
                                      <line x1="7.33333333" y1="6.3" x2="7.33333333" y2="10.5" id="Path"></line>
                                  </g>
                              </g>
                          </g>
                      </g>
                  </g>
              </svg>
              </button>
            </div>
          </div>
      </div>
    </mat-cell>

     
  </ng-container>

 
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns" class="element-row main-row" [@detailExpand] cdkDrag [cdkDragData]="row" [cdkDragDisabled]="isNotDraggable"
      [class.expanded]="expandedElement === row"
      (click)="expandedElement = expandedElement === row ? null : row">
  </mat-row>
  <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="element-row1"></mat-row>
</mat-table>


