import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { MenuConfigService } from 'src/app/shared/services/menu-config.service';
import { UserMenuService } from 'src/app/shared/services/user-menu.service';
import { environment } from 'src/environments/environment';
import { MatOption } from '@angular/material/core';

@Component({
  selector: 'app-cmsaddplaylistfeature',
  templateUrl: './cmsaddplaylistfeature.component.html',
  styleUrls: ['./cmsaddplaylistfeature.component.scss']
})
export class CmsaddplaylistfeatureComponent implements OnInit {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  public allPlaylistItemlist: any = [];
  public selectedPlaylist: any = [];
  public selectedPlaylistName:  any = [];
  public loadingUser: boolean = true;
  public listSelectedPlaylist: any =[];
  public loadingallPlaylistItemlist = true;
  @Input() showModal:boolean;

  constructor(private dialogRef: MatDialogRef<CmsaddplaylistfeatureComponent>,public api: UserMenuService,public config: MenuConfigService,public dialog: DialogService) { 
    if(this.showModal){
    this.dialog.displayMenuDialog(CmsaddplaylistfeatureComponent);
    } }
pageIndex:number = 1
    ngOnInit() {
      this.getallPlaylistItemList();
    }
    close() {
      this.dialogRef.close();
      this.selectedPlaylist = [];
      this.selectedPlaylistName =[];
    }
  
    getallPlaylistItemList(event: any = '') {
      let newPlaylist = [];
      let url = environment.apiURL + this.config.addPlaylistFeatureDropdown + '?page=' + this.pageIndex;
      this.api.doGET(url).subscribe(x => {
       for(let play of x['results']){
        this.allPlaylistItemlist.push(play)
       }
        x['results'].forEach(x => {
          if (x.is_home_slide) {
            x['checked'] = true;
            newPlaylist.push(x)
            let data = {
              "id": x.id,
              "is_home_slide": true,
              "list":true,
            }
            this.selectedPlaylist.push(data)
            this.listSelectedPlaylist.push(data)
            this.selectedPlaylistName.push({ "id": x.id,  "list":true, "name": x.name })
          }
          else {
            x['checked'] = false;
            newPlaylist.push(x)
          }
        })
        this.loadingUser = false;
        // this.allPlaylistItemlist = [...newPlaylist]
      })
    }
  
    mySelectOpt(id, evt, index) {
      if (evt.checked) {
        let data = {
          "id": id,
          "is_home_slide": true,
        }
        this.selectedPlaylist.push(data)
        this.listSelectedPlaylist.push(data)
        this.allPlaylistItemlist[index].checked = true;
        this.selectedPlaylistName.push({ "id": id, "name": this.allPlaylistItemlist[index].name })
      }
      else {
        this.allPlaylistItemlist[index].checked = false;
        const index1 =  this.listSelectedPlaylist.findIndex(i => i.id == id);
        if( this.listSelectedPlaylist[index1]?.list){
        this.listSelectedPlaylist[index1] =  {
          "id": id,
          "is_home_slide": false,
        }
        }
        else{
          this.listSelectedPlaylist = this.listSelectedPlaylist.filter((x) => x.id !== id);
        }
          this.selectedPlaylist = this.selectedPlaylist.filter((x) => x.id !== id);
          this.selectedPlaylistName = this.selectedPlaylistName.filter((x) => x.id !== id);    
      }
    }
  
    removeSelectPlaylist(Playlist) {
      const index = this.allPlaylistItemlist.findIndex(i => i.id == Playlist.id);
      this.allPlaylistItemlist[index].checked = false;
      this.selectedPlaylist = this.selectedPlaylist.filter((x) => x.id !== Playlist.id);
      this.selectedPlaylistName = this.selectedPlaylistName.filter((x) => x.id !== Playlist.id);
      const index1 =  this.listSelectedPlaylist.findIndex(i => i.id == Playlist.id);
      if( this.listSelectedPlaylist[index1]?.list){
      this.listSelectedPlaylist[index1] =  {
        "id": Playlist.id,
        "is_home_slide": false,
      }
      }
      else{
        this.listSelectedPlaylist = this.listSelectedPlaylist.filter((x) => x.id !== Playlist.id);
      }
    }
  
    addSelectPlaylist(){
      let value ={"playlist": this.listSelectedPlaylist}
      let url = environment.apiURL + this.config.addFeaturePlaylist
      this.api.doPOST(url, value ).subscribe(x => {
        this.dialogRef.close(true);
        this.getallPlaylistItemList();
        this.selectedPlaylist = [];
        this.selectedPlaylistName =[];
  
        this.dialog.dialogRefresh.next('true');
      },
      (error) => { 
        if (error) {
          console.log(error)
        };
  
      });
    }
  
    deselectAll() {
      for (let x of this.allPlaylistItemlist) {
        x.checked = false;
      }
      this.selectedPlaylist = [];
      this.selectedPlaylistName =[];
      let newData =[];
      for(let i=0;i<this.listSelectedPlaylist.length;i++){
        newData[i] = {
          "id": this.listSelectedPlaylist[i].id,
          "is_home_slide": false,
        }
      }
      this.listSelectedPlaylist = newData;
    }
  
    getFindPlaylistList() {
      var input, filter, ul, li, span, i, txtValue;
      input = document.getElementById('myInput');
      filter = input.value.toUpperCase();
      ul = document.getElementById("checkBoxUl");
      li = ul.getElementsByTagName('li');
  
      for (i = 0; i < li.length; i++) {
        span = li[i].getElementsByTagName("span")[0];
        txtValue = span.textContent || span.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = "";
        } else {
          li[i].style.display = "none";
        }
      }
    }
    loadMore(){
      this.pageIndex++
this.getallPlaylistItemList()
    }
    scrollToBottom(): void {
      try {
       let pos = (this.myScrollContainer.nativeElement.scrollTop || this.myScrollContainer.nativeElement.body.scrollTop) + this.myScrollContainer.nativeElement.offsetHeight;
       let scro = this.myScrollContainer.nativeElement.scrollHeight;
       console.log(Math.round(pos))
       console.log(scro)
      if((scro-5)< Math.round(pos)){    
        this.loadMore();
      }
      } catch(err) { }                 
  }
  ngAfterViewChecked() {        
    this.scrollToBottom();        
  }

scrollHandler(event){

  console.log(this.myScrollContainer.nativeElement.scrollHeight)

}
}
