<div class="manage-artist-account">
    <div class="body">
        <div class="tabs">
            <span
            class="tab"
            (click)="changeTab('general')"
            [ngClass]="{'active': activeTab === 'general'}">General Info</span>
            <span
            class="tab"
            (click)="changeTab('tos')"
            [ngClass]="{'active': activeTab === 'tos'}">Terms of Service</span>
            <span
            class="tab"
            (click)="changeTab('payment')"
            [ngClass]="{'active': activeTab === 'payment'}">Payment Info</span>
        </div>
        <ng-container *ngIf="activeTab === 'general'">
         
            <mat-card class="grid-card">
                <div class="section general">
                    <div class="title">
                        <h3>General<span class="allfields"><small>*All fields required </small></span></h3>
                    </div>
                    <div class="grid-container">
                        <div class="grid-item">
                            <!-- <mat-form-field appearance="fill">
                                <mat-label>Username</mat-label>
                                <input matInput [(ngModel)]="user.ms_username"> -->
                                <mat-form-field appearance="fill"  [ngClass]="(isUserExist) ? 'addErrorValidation' :''">
                                    <mat-label>Username</mat-label>
                                    <input type='text' matInput  autocomplete="off" [(ngModel)]="user.ms_username" (change)="checkUsername($event.target.value)">
                                </mat-form-field>
                                <div *ngIf="isUserExist" class="textDanger">
                                    This Username already exists.
                                  </div>
                        </div>
                        <div class="grid-item">
                            <mat-form-field appearance="fill">
                                <mat-label>Password</mat-label>
                                <input type="password" matInput  [type]="passwordInputType1" [(ngModel)]="user.raw_password" (keyup)="passwordChange()">
                                <button class="input-suffix" type="button" matSuffix (click)="togglePasswordInputType('confirm')">
                                    <svg width="23px" height="18px" viewBox="0 0 23 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" *ngIf="passwordInputType1 == 'password'">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                                            <g transform="translate(-906.000000, -381.000000)" stroke="#15151C">
                                                <g transform="translate(512.000000, 160.000000)">
                                                    <g transform="translate(49.000000, 191.000000)">
                                                        <g transform="translate(346.000000, 31.000000)">
                                                            <path d="M0,8 C0,8 3.74025974,0 10.2857143,0 C16.8311688,0 20.5714286,8 20.5714286,8 C20.5714286,8 16.8311688,16 10.2857143,16 C3.74025974,16 0,8 0,8 Z"></path>
                                                            <ellipse cx="10.2857143" cy="8" rx="2.80519481" ry="3"></ellipse>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    <svg width="23px" height="18px" viewBox="0 0 23 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" *ngIf="passwordInputType1 == 'text'">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                                            <g transform="translate(-906.000000, -381.000000)">
                                                <g transform="translate(512.000000, 160.000000)">
                                                    <g transform="translate(49.000000, 191.000000)">
                                                        <g transform="translate(346.000000, 31.000000)">
                                                            <path d="M0,8 C0,8 3.74025974,0 10.2857143,0 C16.8311688,0 20.5714286,8 20.5714286,8 C20.5714286,8 16.8311688,16 10.2857143,16 C3.74025974,16 0,8 0,8 Z" stroke="#15151C" fill="#15151C"></path>
                                                            <ellipse stroke="#FFFFFF" fill="#FFFFFF" cx="10.2857143" cy="8" rx="2.80519481" ry="3"></ellipse>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </button>
                            </mat-form-field>
                        </div>
                        <div class="grid-item">
                            <mat-form-field appearance="fill">
                                <mat-label>First Name</mat-label>
                                <input matInput [(ngModel)]="user.first_name">
                            </mat-form-field>
                        </div>
                        <div class="grid-item">
                            <mat-form-field appearance="fill">
                                <mat-label>Last Name</mat-label>
                                <input matInput [(ngModel)]="user.last_name">
                            </mat-form-field>
                        </div>
                        <div class="grid-item">
                            <mat-form-field appearance="fill"  [ngClass]="(isEmailExist) ? 'addErrorValidation' :''">
                                <mat-label>Email Address</mat-label>
                                <input type='email' matInput [(ngModel)]="user.email" (change)="checkEmaile($event)">
                            </mat-form-field>
                            <div *ngIf="isEmailExist" class="textDanger">
                                This Email ID already exists.
                              </div>
                        </div>
                        <div class="grid-item">
                            <mat-form-field appearance="fill">
                                <mat-label>Nickname</mat-label>
                                <input matInput [(ngModel)]="user.nick_name">
                            </mat-form-field>
                        </div>
                        <div class="grid-item">
                            <mat-form-field appearance="fill">
                                <mat-label>Discount Rate (0-100%)</mat-label>
                                <input id="discount" matInput [(ngModel)]="user.discount_rate" type="text"  maxlength="3"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  (keypress)="checkNumber($event)" (keyup)="checkLength()" minlength='0' maxlength='100' required >
                            </mat-form-field>
                        </div> 
                        <div class="grid-item">
                            <mat-form-field appearance="fill">
                                <mat-label>D.O.B </mat-label>
                                <!-- <input *ngIf="!user.birth_date" [patterns]="numberPattern" mask="00/00/0000" matInput [matDatepicker]="picker" [max]='today' [(value)]="user.birth_date" (dateChange)="birthdayChange($event)" required> -->
                                <input [patterns]="numberPattern"   matInput [matDatepicker]="picker" [max]='today' [(value)]="user.birth_date" (dateChange)="birthdayChange($event)" required>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="section contact">
                    <div class="title">
                        <h3>Contact<span class="allfields"><small>*All fields required </small></span></h3>
                    </div>
                    <div class="grid-container">
                        <div class="grid-item">
                        <mat-form-field appearance="fill">
                            <mat-label>Address Line 1</mat-label>
                            <input matInput [(ngModel)]="user.address1">
                        </mat-form-field>
                        </div>
                        <div class="grid-item">
                            <mat-form-field appearance="fill">
                            <mat-label>Address Line 2</mat-label>
                            <input matInput [(ngModel)]="user.address2">
                            </mat-form-field>
                        </div>
                        <div class="grid-item">
                            <app-country-select [(currentSelection)]="user.country"></app-country-select>
                        </div>
                        <div class="grid-item">
                            <mat-form-field appearance="fill">
                                <mat-label>City</mat-label>
                                <input matInput [(ngModel)]="user.city">
                            </mat-form-field>
                        </div>
                        <div class="grid-item">
                            <mat-form-field appearance="fill" *ngIf="shouldShowStateInput()">
                                <mat-label>State or Territory</mat-label>
                                <input matInput [(ngModel)]="user.state">
                            </mat-form-field>
                            <app-state-select [(currentSelection)]="user.state" *ngIf="!shouldShowStateInput()"></app-state-select>
                        </div>
                        <div class="grid-item">
                            <mat-form-field appearance="fill">
                                <mat-label>Zip</mat-label>
                                <input matInput [patterns]="numberPattern" mask="00000" [(ngModel)]="user.postal_code" minlength="5">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="rows">
                        <div class="phone">
                                <mat-form-field class="type" appearance="fill">
                                  <mat-select value='mobile' style="text-transform: capitalize;">
                                <mat-option *ngFor="let type of phoneNumberTypes" [value]="type" style="text-transform: capitalize;">
                                     {{type}}
                                </mat-option>
                                </mat-select>
                                </mat-form-field>
                                <mat-form-field class="number" appearance="fill">
                                    <input type="tel" matInput class="form-control" [(ngModel)]="user.phone_number" [formControl]="primaryPhoneControl" maxlength="12" (keydown)="validateNumber($event)" (input)="getPhoneCode($event.target)">	
                                </mat-form-field>
                        </div>
                        <div class="phone" *ngFor="let number of user.additional_numbers; let i = index;">
                            <mat-form-field class="type" appearance="fill">
                                <mat-select [(ngModel)]="number.number_type" style="text-transform: capitalize;">
                                    <mat-option *ngFor="let type of phoneNumberTypes" [value]="type" style="text-transform: capitalize;">
                                        {{type}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="number" appearance="fill">
                                <input type="tel" id={{i}} matInput class="form-control" [(ngModel)]="number.phone_number" [formControl]="phoneControllers[i]" maxlength="12" (keydown)="validateNumber($event, i)" (input)="getPhoneCode($event.target, i)">
                            </mat-form-field>
                            <button class="remove" (click)="removeNumber(i)">
                                <svg width="13px" height="12px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                                    stroke-linejoin="round">
                                    <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                                        <g id="Genre-Options">
                                        <g id="Close" transform="translate(1439.000000, 32.000000)">
                                            <path d="M24,0 L0,24" id="Path"></path>
                                            <path d="M0,0 L24,24" id="Path"></path>
                                        </g>
                                        </g>
                                    </g>
                                    </g>
                                </svg>
                            </button>
                        </div>
                        <div class="add-row "><button (click)="addNumber()" class="addnum">+ <u>Add</u></button></div>
                    </div>
                </div>
                <div class="section details">
                    <div class="title">
                        <h3>Details</h3>
                        <!-- <h3>Details<span class="allfields"><small>*All fields required </small></span></h3> -->
                    </div>
                    <div class="checkboxes">
                        <mat-checkbox [(ngModel)]="user.can_upload" (change)="uploadChecked($event.checked)">Allow Uploading</mat-checkbox>
                        <mat-checkbox [(ngModel)]="user.is_disable" (change)="disableChecked($event.checked)">Disable Artist</mat-checkbox>
                    </div>
                    <div class="grid-container">
                        <div class="grid-item">
                            <mat-form-field appearance="fill">
                                <!-- <mat-label>Artist Royalties</mat-label> -->
                                <mat-label>P.R.O.</mat-label>
                                <mat-select [(value)]="Selects" placeholder="Select" [panelClass]="'card-select '" [disableOptionCentering]="true" [(ngModel)]="userproData" (selectionChange)="composerPROSelection($event)" required>
                                    <mat-option value="Select">Select</mat-option>
                                    <mat-option>
                                    <ngx-mat-select-search
                                      [searching]="searchingPRO"
                                      [formControl]="proFilterCtrl"
                                      placeholderLabel="Select a PRO"
                                      noEntriesFoundLabel="No Options Found">
                                      <svg ngxMatSelectSearchClear class="filter-clear-icon" width="26px" height="24px" viewBox="0 0 26 24"
                                        version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                                          stroke-linejoin="round">
                                          <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                                            <g id="Genre-Options">
                                              <g id="Close" transform="translate(1439.000000, 32.000000)">
                                                <path d="M24,0 L0,24" id="Path"></path>
                                                <path d="M0,0 L24,24" id="Path"></path>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </svg>
                                    </ngx-mat-select-search>
                                  </mat-option>
                                  <mat-option *ngFor="let option of filteredPROOptions | async" [value]="option.id">
                                    {{ option?.pro }}
                                  </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="grid-item">
                            <mat-form-field appearance="fill">
                                <mat-label>Visible Genres</mat-label>
                                <mat-select [(value)]="Selectsgeners" placeholder="Select" panelClass="visiblegrnre-panel" [disableOptionCentering]="true" [(ngModel)]="user.visible_genres" [compareWith]="genreCompareWith" (selectionChange)="genreSelection($event)" [multiple]="true" required>
                                    <mat-option>
                                    <ngx-mat-select-search
                                      [searching]="searchingGenre"
                                      [formControl]="genreFilterCtrl"
                                      placeholderLabel="Select Genres"
                                      noEntriesFoundLabel="No Options Found">
                                      <svg ngxMatSelectSearchClear class="filter-clear-icon" width="26px" height="24px" viewBox="0 0 26 24"
                                        version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                                          stroke-linejoin="round">
                                          <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                                            <g id="Genre-Options">
                                              <g id="Close" transform="translate(1439.000000, 32.000000)">
                                                <path d="M24,0 L0,24" id="Path"></path>
                                                <path d="M0,0 L24,24" id="Path"></path>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </svg>
                                    </ngx-mat-select-search>
                                  </mat-option>
                                  <mat-option *ngFor="let option of filteredGenreOptions | async" [value]="option">
                                    {{ option.name }}
                                  </mat-option>
                                </mat-select>
                                <p *ngIf="user.visible_genres.length === 0" style="
                                color: black;
                                font-size: 10px;
                                margin-left: -10px;
                                margin-top: 5%;" >Select at least 1 Genre</p>
                              </mat-form-field>
                              
                        </div>
                        <div class="grid-item">
                        <mat-form-field appearance="fill">
                            <mat-label>Legal Name / Company Name</mat-label>
                            <input matInput [(ngModel)]="user.company_name" >
                        </mat-form-field>
                        </div>
                        <div class="grid-item">
                            <!-- <mat-form-field appearance="fill" [ngClass]="(( user.ssn && user.ssn.length != 0 && user.ssn.length < 9 ) || (socialSercurity.touched && socialSercurity.invalid)) ? 'addErrorValidation' :''"> -->
                                <mat-form-field appearance="fill" >

                                <mat-label>Social Security</mat-label>
                                <input matInput [(ngModel)]="user.ssn" mask="000-00-0000"  [type]="passwordInputType" >
                                <!-- <input
                                [type]="passwordInputType"
                                mask="000-00-0000"
                                placeholder="Social Security"
                                [(ngModel)]="user.ssn"   [formControl]="socialSercurity"
                                tabindex="2"
                                maxlength="12"
                                required
                                matInput/>
                                <p *ngIf=" user.ssn && user.ssn.length != 0 && user.ssn.length < 9" style="
                                color: red;
                                font-size: small;
                                margin-left: -10px;
                                margin-top: 5%;" >SSN should be 9 digits</p>
                            <mat-error style="margin-top: 7%;" *ngIf="socialSercurity.invalid">SSN cannot be empty</mat-error> -->
                            <button
                                matSuffix
                                type="button"
                                class="input-suffix"
                                (click)="togglePasswordInputType()"
                            >
                                <svg
                                    width="23px"
                                    height="18px"
                                    viewBox="0 0 23 18"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    *ngIf="passwordInputType == 'password'"
                                >
                                    <g
                                        stroke="none"
                                        stroke-width="1"
                                        fill="none"
                                        fill-rule="evenodd"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <g
                                            transform="translate(-906.000000, -381.000000)"
                                            stroke="#15151C"
                                        >
                                            <g
                                                transform="translate(512.000000, 160.000000)"
                                            >
                                                <g
                                                    transform="translate(49.000000, 191.000000)"
                                                >
                                                    <g
                                                        transform="translate(346.000000, 31.000000)"
                                                    >
                                                        <path
                                                            d="M0,8 C0,8 3.74025974,0 10.2857143,0 C16.8311688,0 20.5714286,8 20.5714286,8 C20.5714286,8 16.8311688,16 10.2857143,16 C3.74025974,16 0,8 0,8 Z"
                                                        ></path>
                                                        <ellipse
                                                            cx="10.2857143"
                                                            cy="8"
                                                            rx="2.80519481"
                                                            ry="3"
                                                        ></ellipse>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                <svg
                                    width="23px"
                                    height="18px"
                                    viewBox="0 0 23 18"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    *ngIf="passwordInputType == 'text'"
                                >
                                    <g
                                        stroke="none"
                                        stroke-width="1"
                                        fill="none"
                                        fill-rule="evenodd"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <g transform="translate(-906.000000, -381.000000)">
                                            <g
                                                transform="translate(512.000000, 160.000000)"
                                            >
                                                <g
                                                    transform="translate(49.000000, 191.000000)"
                                                >
                                                    <g
                                                        transform="translate(346.000000, 31.000000)"
                                                    >
                                                        <path
                                                            d="M0,8 C0,8 3.74025974,0 10.2857143,0 C16.8311688,0 20.5714286,8 20.5714286,8 C20.5714286,8 16.8311688,16 10.2857143,16 C3.74025974,16 0,8 0,8 Z"
                                                            stroke="#15151C"
                                                            fill="#15151C"
                                                        ></path>
                                                        <ellipse
                                                            stroke="#FFFFFF"
                                                            fill="#FFFFFF"
                                                            cx="10.2857143"
                                                            cy="8"
                                                            rx="2.80519481"
                                                            ry="3"
                                                        ></ellipse>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                            </mat-form-field>
                        </div>
                        <div class="grid-item">
                            <mat-form-field appearance="fill" >
                                <mat-label>Federal EIN (If Company)</mat-label>
                                <input matInput [(ngModel)]="user.ein" mask="00-0000000">
                            </mat-form-field>
                        </div>
                        <div class="grid-item">
                            <mat-form-field appearance="fill">
                                <mat-label>CAEIPI#</mat-label>
                                <input matInput [(ngModel)]="user.cae_ipi" mask="000000000000000" required>

                                <!-- <input matInput [(ngModel)]="user.cae_ipi" mask="000-00-0000000000"[min]=11 [max]=15 required> -->
                                <!-- <p *ngIf="(user.cae_ipi && user.cae_ipi.length != 0 )&&( user.cae_ipi && user.cae_ipi.length < 11)" style="
                                color: red;
                                font-size: small;
                                margin-left: -10px;
                                margin-top: 5%;" >CAEIPI# should be 11 -15 digits.</p> -->
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </mat-card>

            <mat-card class="grid-card">
                <div class="section manage">
                    <div class="header">
                        <div class="title">
                            <h3>Manage Co-Writers</h3>
                        </div>
                        <div class="actions">
                            <div class="quantity">
                                <label for="cowriter_quanity_limit">Quantity Limit</label>
                                <input type=number   [ngClass]="{'disabledElement': (user.unlimited_cowriters === true)}" name="cowriter_quanity_limit" max="90" [(ngModel)]="user.cowriter_quanity_limit">
                            </div>
                            <div class="allow-unlimited">
                                <mat-checkbox [ngClass]="{'disabledElement': (user.cowriter_quanity_limit > 0)}"  [(ngModel)]="user.unlimited_cowriters">Allow Unlimited</mat-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="grid-container">
                        <div class="grid-item">
                            <mat-form-field appearance="fill">
                                <mat-label>First Name</mat-label>
                                <input matInput [(ngModel)]="coWriterFirstName">
                            </mat-form-field>
                        </div>
                        <div class="grid-item">
                            <mat-form-field appearance="fill">
                                <mat-label>Last Name</mat-label>
                                <input matInput [(ngModel)]="coWriterLastName">
                            </mat-form-field>
                        </div>
                        <div class="grid-item full-width">
                            <mat-form-field appearance="fill">
                                <mat-label>Email Address</mat-label>
                                <input matInput [(ngModel)]="coWriterEmail">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="section-actions">
                        <button class="green" (click)="addCoWriter()" [disabled]="!canAddCoWriter()">Add Co-Writer</button>
                    </div>
                </div>
                <div class="section artist-cowriter">
                    <div class="title">
                        <h3>Artists Co-Writers</h3>
                    </div>
                    <div class="rows">
                        <div class="row">
                            <span class="name">
                                MIBE Publishing
                            </span>
                            <div class="data">
                                <div class="number-input">
                                    <input type="number" [(ngModel)]="user.publisher_percentage"/>
                                    <span>%</span>
                                </div>
                                <button class="action" (click)="user.publisher_percentage = user.publisher_percentage - 1" [disabled]="user.publisher_percentage == 0" >
                                    <svg width="10px" height="2px" viewBox="0 0 10 2" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <title>Plus_8px_Light</title>
                                        <defs>
                                            <rect id="path-1" x="0" y="0" width="583" height="2018"></rect>
                                            <filter x="-3.9%" y="-1.1%" width="107.7%" height="102.2%" filterUnits="objectBoundingBox" id="filter-2">
                                                <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                                                <feGaussianBlur stdDeviation="7.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                                                <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.15 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                                            </filter>
                                            <rect id="path-3" x="0" y="0" width="536" height="492" rx="4"></rect>
                                            <filter x="-0.7%" y="-0.5%" width="101.3%" height="101.4%" filterUnits="objectBoundingBox" id="filter-4">
                                                <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                                                <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                                                <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.11 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                                            </filter>
                                        </defs>
                                        <g id="Admin" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g id="Admin_Desk_Users_Artist_New-Artist" transform="translate(-1262.000000, -1789.000000)">
                                                <rect fill="#FFFFFF" x="0" y="0" width="1366" height="4372"></rect>
                                                <rect id="BG" fill="#15151C" x="-9.09494702e-13" y="0" width="1366" height="2018"></rect>
                                                <rect id="Overlay" fill="#0C0C12" opacity="0.649999976" x="-9.09494702e-13" y="0" width="1366" height="2018"></rect>
                                                <g id="Manage-Artist" transform="translate(783.000000, 0.000000)">
                                                    <g id="Container">
                                                        <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
                                                        <use fill="#F8F8F8" fill-rule="evenodd" xlink:href="#path-1"></use>
                                                    </g>
                                                    <g id="Manage-Co-Writers" transform="translate(23.000000, 1406.000000)">
                                                        <g id="Container">
                                                            <use fill="black" fill-opacity="1" filter="url(#filter-4)" xlink:href="#path-3"></use>
                                                            <use fill="#FFFFFF" fill-rule="evenodd" xlink:href="#path-3"></use>
                                                        </g>
                                                        <g id="MIBE-PUBLISHING-%" transform="translate(16.000000, 364.000000)">
                                                            <rect id="Container" stroke="#D6D6D6" fill="#FFFFFF" x="0.5" y="0.5" width="503" height="39" rx="4"></rect>
                                                            <g id="Percentage" transform="translate(362.000000, 0.000000)">
                                                                <rect id="Box" stroke="#D6D6D6" fill="#FFFFFF" x="63.5" y="0.5" width="39" height="39"></rect>
                                                                <g id="Group" transform="translate(79.000000, 16.000000)" stroke="#202027" stroke-linecap="round" stroke-linejoin="round">
                                                                    <g id="Plus_8px_Light" transform="translate(0.000000, 3.000000)">
                                                                        <line x1="0" y1="1" x2="8" y2="1" id="Path"></line>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                                <g id="Annotations" transform="translate(80.000000, 26.000000)"></g>
                                            </g>
                                        </g>
                                    </svg>
                                </button>
                                <button class="action" (click)="user.publisher_percentage = user.publisher_percentage + 1" [disabled]="user.publisher_percentage == 100">
                                    <svg width="10px" height="10px" viewBox="0 0 10 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <title>Plus_8px_Light</title>
                                        <defs>
                                            <rect id="path-1" x="0" y="0" width="583" height="2018"></rect>
                                            <filter x="-3.9%" y="-1.1%" width="107.7%" height="102.2%" filterUnits="objectBoundingBox" id="filter-2">
                                                <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                                                <feGaussianBlur stdDeviation="7.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                                                <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.15 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                                            </filter>
                                            <rect id="path-3" x="0" y="0" width="536" height="492" rx="4"></rect>
                                            <filter x="-0.7%" y="-0.5%" width="101.3%" height="101.4%" filterUnits="objectBoundingBox" id="filter-4">
                                                <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                                                <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                                                <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.11 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                                            </filter>
                                        </defs>
                                        <g id="Admin" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g id="Admin_Desk_Users_Artist_New-Artist" transform="translate(-1301.000000, -1785.000000)">
                                                <rect fill="#FFFFFF" x="0" y="0" width="1366" height="4372"></rect>
                                                <rect id="BG" fill="#15151C" x="-9.09494702e-13" y="0" width="1366" height="2018"></rect>
                                                <rect id="Overlay" fill="#0C0C12" opacity="0.649999976" x="-9.09494702e-13" y="0" width="1366" height="2018"></rect>
                                                <g id="Manage-Artist" transform="translate(783.000000, 0.000000)">
                                                    <g id="Container">
                                                        <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
                                                        <use fill="#F8F8F8" fill-rule="evenodd" xlink:href="#path-1"></use>
                                                    </g>
                                                    <g id="Manage-Co-Writers" transform="translate(23.000000, 1406.000000)">
                                                        <g id="Container">
                                                            <use fill="black" fill-opacity="1" filter="url(#filter-4)" xlink:href="#path-3"></use>
                                                            <use fill="#FFFFFF" fill-rule="evenodd" xlink:href="#path-3"></use>
                                                        </g>
                                                        <g id="MIBE-PUBLISHING-%" transform="translate(16.000000, 364.000000)">
                                                            <rect id="Container" stroke="#D6D6D6" fill="#FFFFFF" x="0.5" y="0.5" width="503" height="39" rx="4"></rect>
                                                            <g id="Percentage" transform="translate(362.000000, 0.000000)">
                                                                <path d="M138,0.5 C138.966498,0.5 139.841498,0.891750844 140.474874,1.52512627 C141.108249,2.15850169 141.5,3.03350169 141.5,4 L141.5,4 L141.5,36 C141.5,36.9664983 141.108249,37.8414983 140.474874,38.4748737 C139.841498,39.1082492 138.966498,39.5 138,39.5 L138,39.5 L102.5,39.5 L102.5,0.5 Z" id="Box" stroke="#D6D6D6" fill="#FFFFFF"></path>
                                                                <g id="Group" transform="translate(118.000000, 16.000000)" stroke="#202027" stroke-linecap="round" stroke-linejoin="round">
                                                                    <g id="Plus_8px_Light">
                                                                        <line x1="4" y1="0" x2="4" y2="8" id="Path"></line>
                                                                        <line x1="0" y1="4" x2="8" y2="4" id="Path"></line>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section cowriters" style="font-size:12px;">
                    <p *ngIf="!user.allowed_cowriters || user.allowed_cowriters.length <= 0">Your Co-writers will appear here</p>
                    <div class="rows" *ngIf="user.allowed_cowriters && user.allowed_cowriters.length > 0">
                        <div class="row" *ngFor="let cowriter of user.allowed_cowriters; let i = index">
                            <span class="name">{{cowriter.first_name}} {{cowriter.last_name}}</span>
                            <a [href]="'mailto:' + cowriter.email" class="email">{{cowriter.email}}</a>
                            <button class="remove" (click)="removeCowriter(i)" [disabled]="(cowriter.isdisable === true ||  cowriter.isdisable === true)" >
                                <svg width="13px" height="12px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                                stroke-linejoin="round">
                                <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                                    <g id="Genre-Options">
                                    <g id="Close" transform="translate(1439.000000, 32.000000)">
                                        <path d="M24,0 L0,24" id="Path"></path>
                                        <path d="M0,0 L24,24" id="Path"></path>
                                    </g>
                                    </g>
                                </g>
                                </g>
                            </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </mat-card>
       
        </ng-container>
        <ng-container *ngIf="activeTab === 'tos'">
            <mat-card class="grid-card">
                <div class="section title-section">
                    <div class="title">
                        <h3>Terms of Service - History</h3>
                    </div>
                </div>
                <div class="section member" style="border-bottom: none !important;">
                    <div class="title">
                        <h4>{{user.getFullName()}}</h4>
                    </div>
                    <div class="subtitle">
                        <p>Member since: <span>{{componentData.data.user.date_joined | date: 'mediumDate'}}</span></p>
                    </div>
                </div>
                <div class="table track-list-sort" style="border:1px solid #ccc; border-left:none;border-right: none;">
                    <app-loader *ngIf="loadingTos" color="#50e3c2"></app-loader>
                    <mat-table [dataSource]="tosDataSource"  class="green-theme" multiTemplateDataRows >          
                        <ng-container matColumnDef="version">
                        <mat-header-cell *matHeaderCellDef  style="font-weight: 700;font-size:12px;text-transform: uppercase;color:#333;">Version</mat-header-cell>
                        <mat-cell *matCellDef="let row" style="font-size:12px;">{{row.version}}</mat-cell>
                        </ng-container>
                    
                        <ng-container matColumnDef="last_sent">
                        <mat-header-cell *matHeaderCellDef  style="font-weight: 700;font-size:12px;text-transform: uppercase;color:#333;">Last Sent</mat-header-cell>
                        <mat-cell *matCellDef="let row" style="font-size:12px;">{{row.last_sent | date: 'shortDate'}}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <mat-header-cell *matHeaderCellDef  style="font-weight: 700;font-size:12px;text-transform: uppercase;color:#333;">Status</mat-header-cell>
                            <mat-cell *matCellDef="let row" style="font-size:12px;">{{row.status}}</mat-cell>
                            </ng-container>
                    
                        <ng-container matColumnDef="file">
                        <mat-header-cell *matHeaderCellDef  style="font-weight: 700;font-size:12px;text-transform: uppercase;color:#333;">File</mat-header-cell>
                        <mat-cell *matCellDef="let row" style="font-size:12px;"><a target="_blank" class="dark" href="{{row.file}}">View</a></mat-cell>
                        </ng-container>
                    
                        <mat-header-row *matHeaderRowDef="tosDisplayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: tosDisplayedColumns" class="main-row" [@rowInOut]></mat-row>
                    </mat-table>
                </div>
            </mat-card>
        </ng-container>
        <ng-container *ngIf="activeTab === 'payment'">
            <mat-card class="grid-card">
                <div class="section title-section">
                    <div class="title">
                        <h3>Preferred Payment Method</h3>
                    </div>
                </div>
                <div class="section member">
                    <div class="title">
                        <h4>{{user.getFullName()}}</h4>
                    </div>
                    <div class="subtitle">
                        <p>Check</p>
                    </div>
                    <div class="data">
                        <h5>Mailing Address</h5>
                        <p>{{user.address1}}</p>
                        <p *ngIf="user.address2">{{user.address2}}</p>
                        <p>{{user.city}}, {{user.state}} {{user.postal_code}}</p>
                    </div>
                </div>
            </mat-card>
            <mat-card class="grid-card">
                <div class="section title-section">
                    <div class="title">
                        <h3>Payment History</h3>
                    </div>
                </div>
                <div class="section table">
                    <app-loader *ngIf="loadingPayments" color="#50e3c2"></app-loader>
                    <mat-table *ngIf="!loadingPayments" [dataSource]="paymentsDataSource"  class="track-list green-theme" multiTemplateDataRows [trackBy]="paymentsListTrackBy">          
                        <ng-container matColumnDef="amount" >
                        <mat-header-cell *matHeaderCellDef style="font-weight: 900;font-size: 14px;">Amount</mat-header-cell>
                        <mat-cell *matCellDef="let row">$563.00</mat-cell>
                        </ng-container>
                    
                        <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef style="font-weight: 900;font-size: 14px;">Status</mat-header-cell>
                        <mat-cell *matCellDef="let row">Paid</mat-cell>
                        </ng-container>
                    
                        <ng-container matColumnDef="document">
                        <mat-header-cell *matHeaderCellDef style="font-weight: 900;font-size: 14px;">Document</mat-header-cell>
                        <mat-cell *matCellDef="let row"><a class="dark" href="#">Statement</a></mat-cell>
                        </ng-container>
                    
                        <mat-header-row *matHeaderRowDef="paymentsDisplayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: paymentsDisplayedColumns" class="main-row" [@rowInOut]></mat-row>
                    </mat-table>
                </div>
            </mat-card>
        </ng-container>
    </div>

    <button class="btn save-btn" mat-button (click)="save()" [disabled]="!canSave()">SAVE</button>
</div>
<div class="loading-overlay" *ngIf="loading">
    <app-loader></app-loader>
</div>