import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { of, ReplaySubject, Subscription } from 'rxjs';
import { startWith, debounceTime, distinctUntilChanged, tap, switchMap, map } from 'rxjs/operators';
import { Playlist } from '../models/playlist.model';
import { User } from '../models/user.model';
import { PlaylistService } from '../services/playlist.service';
import { EmailGroupService, GetEmailGroupOptions } from '../../shared/services/email-group.service';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { environment } from '../../../environments/environment';
import { DialogService } from '../services/dialog.service';

const usersPageSize = 30;
const groupPageSize = 30;
const groupLimit = 30;

export interface DialogData {
  users: User[];
  userType: string
  from_page: any
}
@Component({
  selector: 'app-add-to-email-group',
  templateUrl: './add-to-email-group.component.html',
  styleUrls: ['./add-to-email-group.component.scss']
})
export class AddToEmailGroupComponent implements OnInit, OnDestroy {
  selectedUsersGroup: any;
  getUserIds: any = [];
  isChecked = false;

  constructor(
    private dialogRef: MatDialogRef<AddToEmailGroupComponent>,
    private _groupService: PlaylistService,
    public _emailGroupService: EmailGroupService,
     public api: UserMenuService,
      public config: MenuConfigService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,public dialog: DialogService,
  ) {

  }

  loading = false;
  groupids:any
  groups: any;
  totalGroups: any;
  modifiedGroups: number[] = [];
  checkedGroups: number[] = [];
  checkedGroupsIds: number[] = [];
  userArray: any = [];
  searchControl = new FormControl();
  groupOptions: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  searchingGroups = false;
  searchString = ''
  private _subscriptions:Subscription[] = [];

  selectedCount = 0;
  groupsArray: any = []
  ngOnInit(): void {
    this.loadGroups()
    this._subscriptions.push(this.searchControl.valueChanges.pipe(
      startWith(''),
      debounceTime(150),
      distinctUntilChanged(),
      tap(() => {
        this.searchingGroups = true;
      }),
      switchMap((v) => {
        return of(this.groups);
      }),
      tap(() => {
        this.searchingGroups = false;
      })
    ).subscribe(f => {
      this.groupOptions.next(f);
    }));
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s=>s.unsubscribe())
    this._subscriptions = [];
  }

  close() {
    this.dialogRef.close(false);
  }

  isGroupChecked(index:number) {
    return this.checkedGroups.includes(index);
  }
  loadGroups() {
    this.loading = true;
    let params:GetEmailGroupOptions = {
      type: this.data.users[0].is_client?'CLIENT':'ARTIST',
      limit: groupPageSize,
      offset: (2 - 1) * groupPageSize,
    };
    if (this.searchString && this.searchString != '') {
      params.searchString = this.searchString;
    }

    this._emailGroupService
      .getAllGroups(params, this.data.from_page)
      .subscribe((p) => {
        let len = p;
        this.loading = false;
        this.totalGroups = p.count ? p.count : len.results.length;
        this.groups = p.results;
        this.groups.forEach(element => {
          element.checked = false
        });
        this.data.users.forEach(element => this.getUserIds.push(element['id']));
        this.groups.forEach((elem, index) => {
          this.selectedUsersGroup = elem.users.filter(element => this.getUserIds.includes(element));
          if (this.selectedUsersGroup.length > 0) { 
            this.checkedGroups.push(index);
            this.checkedGroupsIds.push(elem.id);
            this.selectedCount++;
            elem['isChecked'] = true; 
          }
        });    
      }, (error) => {
        console.log(error);
        //alert("An error ocurred while getting the groups. Check your connection and try again");
      });
  }
  groupChecked(checked:boolean, index:number, id: number) {
    // this.toggleModified(group);
    if (checked) {
      this.checkedGroups.push(index);
      this.checkedGroupsIds.push(id);
      this.selectedCount++;
      // this.selectedCount = this.checkedGroups.length
      return;
    }
    let i = this.checkedGroups.findIndex(i=> i==index);
    if (i >= 0) {
      this.checkedGroups.splice(i, 1);
      this.checkedGroupsIds.splice(i, 1);
      this.selectedCount--;
      // this.selectedCount = this.checkedGroups.length
    }
  }
  deselectGroups(){ 
    this.selectedCount = 0;
    for(let x of this.groups){
      x.checked = false;
      if (x.isChecked) { x.isChecked = false; }
      this.checkedGroups=[];
      this.checkedGroupsIds=[];
    }
  }
  // toggleModified(group:Playlist) {
  //   let i = this.modifiedGroups.findIndex(p=>p.id == group.id)
  //   if (i >= 0) {
  //     this.modifiedGroups.splice(i, 1);
  //   } else {
  //     this.modifiedGroups.push(group);
  //   }
  // }

  userTrackBy(i:number, user:User) {
    return user.id;
  }
  groupTrackBy(i:number, group:Playlist) {
    return group.id;
  }

  canSubmit():boolean {
    return this.modifiedGroups && this.modifiedGroups.length > 0;
  }

  save() {
    //if (!this.canSubmit()) return;
    // this.loading = true;
    // this.groupHandled = [];
    // this.modifiedGroups.forEach(group => {
    //   if (this.isGroupChecked(group)) {
    //     this._groupService.addTrackVersionsToGroup(group.id, [this.data.version.id]).subscribe(p => {
    //       this.groupHandled.push(p.id);
    //       this.closeIfDone();
    //     });
    //   } else {
    //     this._groupService.removeTrackVersionsFromGroup(group.id, [this.data.version.id]).subscribe(p => {
    //       this.groupHandled.push(p.id);
    //       this.closeIfDone();
    //     });
    //   }
    // });
    if(this.data.users.length>1){
      this.userArray = []
      this.data.users.forEach(element => {
        this.userArray.push(element.id)
      });
    }else{
      this.userArray = []
      this.userArray.push(this.data.users[0].id)
    }
    if(this.selectedCount!=0){
      
      let postbody = {
        "groups": this.checkedGroupsIds,
        "users": this.userArray
      }
      let requestURL = environment.apiURL + '/api/v1/admin/emailgroup/add-multi-users/';
      this.api.doPUT(requestURL, postbody).subscribe((result: any) => {
        this.loading = false;
        this.dialogRef.close(false);
      },
      (error) => {
        this.loading = false;
        let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
        this.dialog.UserUploadPopupConfirmPopup(data).subscribe(changed => {
        });
        //alert(error.message)
      });
    }
  }

  private groupHandled = [];
  private closeIfDone() {
    if (this.groupHandled.length != this.modifiedGroups.length) return;
    this.dialogRef.close(true);
  }

  getGroups() {
    var input, filter, ul, li, span, i, txtValue;
    input = document.getElementById('searchGroup');
    filter = input.value.toUpperCase();
    ul = document.getElementById("availableGroups");
    li = ul.getElementsByTagName('li');
  
    for (i = 0; i < li.length; i++) {
      span = li[i].getElementsByTagName("span")[0];
      txtValue = span.textContent || span.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
}

}
