<div class="left-side">
  <a class="logo-link">
    <img src="/assets/img/logo.png" title="Mibe Admin" alt="Mibe Admin" class="logo" />
    <span class="site">Composer</span>
  </a>
</div>
<div class="tos-list" *ngIf="!reject">
  <div class="tos-title">
    <h3>Terms of Service</h3>
    <!-- <p>Please review and accept our updated terms of service to continue</p> -->
    <p>Please review and accept our updated terms of service to continue. Once accepted, you will be prompted to login again.</p>
  </div>
  <div class="mat-card tos-card">
    <strong>MIBE - TERMS OF SERVICE AGREEMENT {{date| date: 'shortDate' }}</strong>
    <div class="tos-card-data">
      <!-- <div>      -->
        <iframe [src] ='file | safe' allowfullscreen  width="640" height="360" >
      </iframe> 
    </div>
    <div class="form-col">
      <button class="form-button form-button-cancel" (click)="tosRejStatus()">Reject</button>
      <button class="form-button" (click)="tosStatus()">Accept</button>
    </div>
  </div>
</div>
<div class="tos-list" *ngIf="reject">
  <div class="tos-title">
    <h3>Terms of Service</h3>
    <p>Please review and accept our updated terms of service to continue</p>
  </div>
  <div class="mat-card tos-card">

    <div class="tos-card-data tos-review">
      <strong>YOU HAVE REJECTED THE MIBE TERMS OF SERVICE</strong>
      <!-- <p>If you are having trouble with some of the stipulations in our agreement, please contact us at
        <a href="mailto:customersupport@mibe.com?Subject=Hello&" target="_blank" class="action">customersupport@mibe.com </a>, or by phone at (123) 456-7890</p> -->
        <p>Please contact your MIBE Representative.</p>
  
    </div>
    <div class="" style="text-align: center;">
      <button class="form-button" (click)="rejected()">Review terms</button>
    </div>
  </div>
</div>