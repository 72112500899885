import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../environments/environment';
import { PlaylistTracks } from '../models/playlisttracks.model';
import { APIListResponse } from '../models/apiresponse.model';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Filters, filtersToParams } from './track.service';

export interface GetPlaylistTracksOptions {
  playlistId: number;
  limit?: number;
  offset?: number;
  filters?: Filters;
  search?: string;
  ordering_by?:string;
  filterval?:string;
}

@Injectable({
  providedIn: 'root'
})
export class PlaylistTracksService {

  constructor(
    private http: HttpClient,
    private _platform: Platform,
    private _transferState: TransferState
  ) { }

  getPlaylistTracks(options: GetPlaylistTracksOptions): Observable<APIListResponse<PlaylistTracks>> {
    let endpoint:string;
    let params: any = {};
    if (options.filters) {
      params = filtersToParams(options.filters);
    }
    params['playlist_id'] = `${options.playlistId}`;
    if (options.limit) {
      params['limit'] = `${options.limit}`;
    }
    if (options.offset) {
      params['offset'] = `${options.offset}`;
    }
    if(options.ordering_by){
      params['ordering_by']=options.ordering_by
      if(options.ordering_by =='approved_at'){
        params['ordering_by']='status'
      }
      else if(options.ordering_by =='-approved_at'){
         params["ordering_by"] = "-status";
      }
    }
    if (options.search) {
      params=[]
      endpoint = `/api/v1/admin/playlists/available-tracks/?search=` + options.search
      params['search'] = options.search;
    } else if(options.filterval){
      if(options.ordering_by){
        // endpoint= `/api/v1/admin/playlists/`+      
        // `${options.playlistId}`
        // +`/get-assigned-tracks-list/?ordering_by=`+ options.ordering_by;
        //endpoint = `/api/v1/admin/playlisttracks/?playlist_id=`
        endpoint = `/api/v1/admin/playlists/` +`${options.playlistId}`+`/get-assigned-tracks-list/?top_version=true`;
      }else{
        // endpoint= `/api/v1/admin/playlists/`+      
        // `${options.playlistId}`
        // +`/get-assigned-tracks-list/`;
        // endpoint = `/api/v1/admin/playlisttracks/?playlist_id=`
        // +`${options.playlistId}`
        endpoint = `/api/v1/admin/playlists/`
        +`${options.playlistId}`+`/get-assigned-tracks-list/?top_version=true`
      }
    }
    else{
      endpoint = `/api/v1/admin/playlists/available-tracks/`;
     // endpoint = `/api/v1/admin/playlists/available-clients/`;
    }
    // Check if playlist is cached from server
    const PLAYLIST_TRACKS_KEY = makeStateKey<APIListResponse<Object>>('playlisttracks-' + options.playlistId);
    if (this._transferState.hasKey(PLAYLIST_TRACKS_KEY)) {
      const playlistTracks = this._transferState.get<APIListResponse<Object>>(PLAYLIST_TRACKS_KEY, null);
      this._transferState.remove(PLAYLIST_TRACKS_KEY);
      return of({
        next: playlistTracks.next,
        previous: playlistTracks.previous,
        count: playlistTracks.count,
        results: playlistTracks.results.map(pt => new PlaylistTracks(pt))
      });
    }
  //  if(options.filterval){
    let headers = new HttpHeaders();
    return this.http.get<APIListResponse<Object>>(
      environment.apiURL + `${endpoint}`,
      {
        headers: headers,
      //  params: params
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the moods
        console.log('assignedtrack',res)
        if (!this._platform.isBrowser) {
          this._transferState.set(PLAYLIST_TRACKS_KEY, res);
        }
      }),
      map((res) => {
        return {
          
          next: res.next,
          previous: res.previous,
          count: res.count,
          results: res.results.map(pt => 
            new PlaylistTracks(pt)
          )
        };
      })
    );
    // }else{
    //     let headers = new HttpHeaders();
    // return this.http.get<Object>(
    //   environment.apiURL + `${endpoint}`,
    //   {
    //     headers: headers,
    //    // params: params
    //   }
    // );
    // }
  }
  getPlaylistTrackspagination(pageval) {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.get<Object>(
      environment.apiURL +
      `/api/v1/admin/playlists/?page=`+pageval  ,
      {
        headers: headers,
        
      }
    );
   
  }
  // getPlaylistTrackspagination(pageval): Observable<APIListResponse<PlaylistTracks>> {
  //   let endpoint:string;

  //       endpoint= `/api/v1/admin/playlists/?page=`+pageval      
        
       
  //   // Check if playlist is cached from server
  //   const PLAYLIST_TRACKS_KEY = makeStateKey<APIListResponse<Object>>('playlisttracks-' + options.playlistId);
  //   if (this._transferState.hasKey(PLAYLIST_TRACKS_KEY)) {
  //     const playlistTracks = this._transferState.get<APIListResponse<Object>>(PLAYLIST_TRACKS_KEY, null);
  //     this._transferState.remove(PLAYLIST_TRACKS_KEY);
  //     return of({
  //       next: playlistTracks.next,
  //       previous: playlistTracks.previous,
  //       count: playlistTracks.count,
  //       results: playlistTracks.results.map(pt => new PlaylistTracks(pt))
  //     });
  //   }
  // //  if(options.filterval){
  //   let headers = new HttpHeaders();
  //   return this.http.get<APIListResponse<Object>>(
  //     environment.apiURL + `${endpoint}`,
  //     {
  //       headers: headers,
  //     //  params: params
  //     }
  //   ).pipe(
  //     tap(res => {
  //       // If we're on the server cache the moods
  //       console.log('assignedtrack',res)
  //       if (!this._platform.isBrowser) {
  //         this._transferState.set(PLAYLIST_TRACKS_KEY, res);
  //       }
  //     }),
  //     map((res) => {
  //       return {
          
  //         next: res.next,
  //         previous: res.previous,
  //         count: res.count,
  //         results: res.results.map(pt => 
  //           new PlaylistTracks(pt)
  //         )
  //       };
  //     })
  //   );
  //   // }else{
  //   //     let headers = new HttpHeaders();
  //   // return this.http.get<Object>(
  //   //   environment.apiURL + `${endpoint}`,
  //   //   {
  //   //     headers: headers,
  //   //    // params: params
  //   //   }
  //   // );
  //   // }
  // }
  getassignedClients(playlist:any, index:number) {

    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    if(playlist.search)
    {
    return this.http.get<APIListResponse<Object>>(
      environment.apiURL +
        `/api/v1/admin/playlists/`+
        
        playlist.playlistId
        +`/get-assigned-clients/?search=${playlist.search}&page=${index}`,
      {
        headers: headers,
      }
    );
    }
    else{
      return this.http.get<APIListResponse<Object>>(
        environment.apiURL +
          `/api/v1/admin/playlists/`+
          
          playlist.playlistId
          +`/get-assigned-clients/?page=${index}`,
        {
          headers: headers,
        }
      );
    }
  }
  

  getassignedTracks(playlist:any,index:any=''): Observable<APIListResponse<PlaylistTracks>> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    if(playlist.search)
    {
    return this.http.get<APIListResponse<PlaylistTracks>>(
      environment.apiURL +
        `/api/v1/admin/playlists/`+
        
        playlist.playlistId
        +`/get-assigned-tracks/?search=${playlist.search}&page=${index}`,
      {
        headers: headers,
      }
    );
    }
    else{
      return this.http.get<APIListResponse<PlaylistTracks>>(
        environment.apiURL +
          `/api/v1/admin/playlists/`+
          
          playlist.playlistId
          +`/get-assigned-tracks/?page=${index}`,
        {
          headers: headers,
        }
      );
    }
  }
  reorderTracks(playlistTracks: PlaylistTracks[]): Observable<Object> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<Object>(
      environment.apiURL + '/api/v1/admin/playlisttracks/reorder/',
      {
        playlisttracks: playlistTracks.map(pt => { return {id: pt.id, order: pt.order}; })
      },
      {
        headers: headers
      }
    );
  }
}
