import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import {Track} from '../models/track.model';
import {TrackService} from '../services/track.service';
import {TrackComposer} from '../models/trackcomposer.model';
import {TrackVersion} from '../models/trackversion.model';
import { CONTAINER_DATA, DialogConfig, SliderDialogService } from '../services/slider-dialog.service';
import { TrackVersionService } from '../services/trackversion.service';
import { forkJoin } from 'rxjs';
import { MenuListService } from '../services/menu-list.service';
import { PlayerService } from '../services/player.service';
import { DialogService } from '../services/dialog.service';

@Component({
  selector: 'app-edit-track-version',
  templateUrl: './edit-track-version.component.html',
  styleUrls: ['./edit-track-version.component.scss','../edit-track/edit-track.component.scss']
})
export class EditTrackVersionComponent implements OnInit {

  track: Track;
  set version(v: TrackVersion) {
    this._version = v;
  }
  get version():TrackVersion {
    return this._version;
  }
  loading:boolean = false;
  composers: TrackComposer[];
  bpmValue: number = 0;
  replaceTrackVersion:Boolean = false;
  uploadFile: boolean = false;
  confirmData: boolean = false;
  @Output()
  versionChange = new EventEmitter<TrackVersion>();

  private _version: TrackVersion;
  oldVersionTitle =''
  oldVersionStatus=''

  constructor(
    private _sliderDialogService: SliderDialogService,
    private _trackService: TrackService,
    public _DialogService:DialogService,
    private _trackVersionService: TrackVersionService, private _dialogService: DialogService,
    @Inject(CONTAINER_DATA) public componentData: DialogConfig, private _playerService: PlayerService,
    public list: MenuListService
  ) { }


  ngOnInit(): void {
    this._sliderDialogService.getReplaceTrackVersion().subscribe((val)=>{
      if(val && val=='replace'){
        console.log(val);
        this.replaceTrackVersion = true;
      }
    });
console.log(this._trackService.trackPopData)
this.track = this._trackService.trackPopData
if(this._trackService.versionPopData)
{
this.version = this._trackService.versionPopData
}
else{
  if(this.componentData.data){
    this.version = (this.componentData.data.version);
    this._trackService.versionPopData = this.componentData.data.version
  }
}
this.oldVersionTitle = this.version.title
this.oldVersionStatus = this.version.status
    // if (this.componentData.data) {
    //   this.track = new Track(this.componentData.data.track.toJSON());
    //   this.version = new TrackVersion(this.componentData.data.version.toJSON());
    //   this.composers = this.track.composers;
    // }
    if(this.replaceTrackVersion){
      this._sliderDialogService.getReplaceTrackFile().subscribe((val)=>{
        if(val){
          this.uploadFile = true;
        }
      });
    }
    if(this.track){
      if(this.version.title &&  this.version.track_ending && this.track.title){
       this.track.isValid = false;
      }
      else{
       this.track.isValid = true;
      }
     }
    if(this.version.is_instrumental){
      if(this.track.instruments ===  undefined || this.track['instruments_desc'].length === 0 || 
       this.track.tv_references.length < 2 || this.track.network_references.length < 2 || this.track.band_references.length < 2  || 
       this.track.sonic_references.length < 2){
         this.track.isValid = true;
      }
      else{
       this.track.isValid = false;
      }
    }
    if(this.version.is_vocals){
     if(this.track.instruments ===  undefined || this.track['instruments_desc'].length === 0  || 
       this.track.tv_references.length < 2 || this.track.network_references.length < 2 || this.track.band_references.length < 2  || 
       this.track.sonic_references.length < 2 || (this.track.lyrics.verse == '' || this.track.lyrics.bridge == '' || this.track.lyrics.chorus == '' || this.track.lyrics.pre_chorus == '') || this.track.vocal ===  undefined || this.track['vocal_desc'].length === 0 ){
        this.track.isValid = true;
     }
     else{
      this.track.isValid = false;
     }
   }
  }

  toggleMusicPlayerCollapsed() {

  }

  bpmChange(val: any) {
    this.bpmValue = Math.round(val);
    this.version.calculated_bpm = Math.round(val);
    this.versionChange.emit(this._version);
  }
  
  saveVersion() {
    if(this._trackService.cowritten_by.length > 0){
      let newValues =[];
      // for(let i=0;i<this._trackService.cowritten_by.length;i++){
      //   // if (this._trackService.cowritten_by[i].id !== 2 && this._trackService.cowritten_by[i].id !== 1 && this._trackService.cowritten_by[i].id !== 10000 && this._trackService.cowritten_by[i].id !== 10001 && this._trackService.cowritten_by[i].id !== 10002) {
      //   //   newValues.push(this._trackService.cowritten_by[i])
      //   // }
      //   for(let j=0;j<this.track.default_cowritten_by.length;j++){
      //     if(this._trackService.cowritten_by[i].id !== this.track.default_cowritten_by[j].id){
      //       newValues.push(this._trackService.cowritten_by[i])

      //     }
      //   }
      // }
     // this._trackService.cowritten_by = newValues
     for (let j = 0; j < this.track.default_cowritten_by.length; j++) {
      let data = this.track.default_cowritten_by[j].id
      this._trackService.cowritten_by = this._trackService.cowritten_by.filter(function (e) {
        if (e.id !== data) {
          return e;
        }
      })
      
    }
    }
    console.log(this.track, this.track.Instrumentsub_category)
    let id:any=[]
    id = this.track.Instrumentsub_category;
    if (id) {
      if(this.track &&this.track.Instrumentdescriptions){
      this.track.Instrumentdescriptions = this.track.Instrumentdescriptions.filter(function (e) {
        if (e !== id) {
          return e;
        }
      })

    }
      this.track.instruments = this.track.instruments.filter(function (e) {
        if (e.id !== id) {
          return e;
        }
      })
    }
       // <!-- Add Newsuggestion -->
    if(this.track.tv_references){
      this.track.tv_references = this.track.tv_references.filter(function(e) { 
        if(e.id !== ''){
          return e;
        }
      })
     }
     if(this.track.network_references){
      this.track.network_references = this.track.network_references.filter(function(e) { 
        if(e.id !== ''){
          return e;
        }
      })
     }
    let data = {
      "track":this.track.id,
      "title":this.version.title,
      "is_instrumental":this.version.is_instrumental,
      "is_full_version":this.version.is_full_version,
      "is_main_track": this.version.is_main_track,
      "status": this.version.status,
      "cowritten_by":this.filter(this._trackService.cowritten_by) || [],
      "id": this.track.id,
      "instruments":{
          "sub_category":this.track.Instrumentsub_category || "",
          "descriptions":this.track.Instrumentdescriptions || [],
        //  "suggesstion":this.track.instruments.map(x=> x.id) || [],
          "suggesstion": [],
          "new_suggestion":this.track.Instrumentnew_suggestion || []
      },
     // "tv_references": this.track.tv_references.map(x=> x.id),
      //"network_references": this.track.network_references.map(x=> x.id),
      // <!-- Add Newsuggestion -->

        "tv_references": {
        "sub_category": this.track.tv_references.map(x=> x.id),
      //  "descriptions": this.track?.tv_referencesDescriptions && this.track.tv_referencesDescriptions.map(x=> x.id) || [],
      //  "suggesstion":[],
        "new_suggestion": this.track.tv_referencesNew_suggestion || []
    },
    "network_references":{
        "sub_category": this.track.network_references.map(x=> x.id),
       //  "descriptions": this.track?.network_referencesDescriptions && this.track.network_referencesDescriptions.map(x=> x.id)|| [],
       // "suggesstion":[],
        "new_suggestion": this.track.network_referencesNew_suggestion|| []
    },
      // "artist_references":this.track.artist_references.map(x=> x.id),
      "artist_references":[],
      "sonic_references": this.track.sonic_references.map(x=> x.id),
      //"sonic_references":[],
      "band_references": this.track.band_references.map(x=> x.id),
      "vocal": {
        "sub_category":this.track.Vocalssub_category || "",
        "descriptions":this.track.Vocalsdescriptions || [],
        "suggesstion":this.track.vocals || [],
        "new_suggestion":[]
    },
    "lyrics_data":{
      "verse":this.track.lyrics.verse ?this.track.lyrics.verse:[''],
      "pre_chorus":this.track.lyrics.pre_chorus ? this.track.lyrics.pre_chorus :[''],
      "chorus":this.track.lyrics.chorus?this.track.lyrics.chorus:[''],
      "bridge":this.track.lyrics.bridge?this.track.lyrics.bridge:['']
  },
      "is_vocals": this.version.is_vocals,
      "is_mibe_owned": this.version.is_mibe_owned,
      "is_five_star": this.version.is_five_star,
      "versionId" : this.version.id,
      "calculated_bpm": this.version.calculated_bpm,
      "track_ending": this.version.track_ending,
      "calculated_key": this.version.calculated_key,
      "tempo": this.version.tempo,
      "rejected_status_reason":this.version.rejected_status_reason,
      'added_by_role': this.track.added_by_role,
      'notify_composer': this.track.notify_composer,
      'internal_status_notes': this.version.internal_status_notes,
      'partial': this.track.partial
  }
  if(this.replaceTrackVersion){
    this._sliderDialogService.getReplaceTrackFile().subscribe((val)=>{
      data['upload_file_id'] = val
        console.log(val);
        data['status'] = 'PENDING'
    });
  }
  console.log(data)
    console.log(this.track)
    this.track.composers = this.composers;
    this.loading = true;
    if (this.replaceTrackVersion && this.confirmData) {
      forkJoin([
        this._trackVersionService.updateReplaceTrackVersion(data)
      ]).subscribe(([version]) => {
        this.componentData.data.version = version;
        this.loading = false;
        this._playerService.nextTab(true)
        this._sliderDialogService.close(true);
        this._playerService.updateWavePlay(false);
        this._playerService.updateIsPlaying(false);
        this._playerService.updateShowMusicPlayer(false);
        this._playerService.play(null, null);
        location.reload();
      }, (error) => {
        console.log(error);
        // alert(error.error.error)
        let data = error.error?.error ? error.error?.error : (error.error?.detail ?error.error?.detail : (error.error?.details ? error.error?.details:``) )
        this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
        });
        //alert("An error ocurred while updating the track version. Check your input for errors and then check your internet connections and try again");
        this.loading = false;
      });
    }
    else {
      forkJoin([
        // this._trackService.updateTrack(data,'version'),
        this._trackVersionService.updateTrackVersion(data)
      ]).subscribe(([version]) => {
        // this.componentData.data.track = track;
        this.componentData.data.version = version;
        this.loading = false;
        this._playerService.nextTab(true)
        this._playerService.updateWavePlay(false);
        this._playerService.updateIsPlaying(false);
        this._playerService.updateShowMusicPlayer(false);
        this._playerService.play(null, null);
        this._sliderDialogService.close(true);
        location.reload();
      }, (error) => {
        console.log(error);
        //alert("An error ocurred while updating the track version. Check your input for errors and then check your internet connections and try again");
        this.loading = false;
      });
    }
  

    // this._trackVersionService.updateTrackVersion(this.version).subscribe(v=> {
    //   this.componentData.data.track = this.track
    //   this.componentData.data.version = v;
    //   this._sliderDialogService.close(null);
    // });
  }

  toggleTrackDescriptionCollapsed(event: any): void {
    this.track[event.type] = event.data;
  }
  filter(e){
    let filData = []
    for(let x of e){
      console.log(e)
      let data = {}
      if(x.user_id)
      {
        data['user_id'] = x.user_id
        data['percent'] = x.percent
      }
      else{
        data['user_id'] = x.id
        data['percent'] = x.percent
      }
     
      filData.push(data)
    }
    return filData
  }
  replaceTrack(){
    this._dialogService.displayConfirmPopup(this.track.title,"replace").subscribe(changed => {
      if (changed) {
        this.confirmData= true
      }
      if(this.confirmData){
        this.saveVersion()
      }
    })
  }
  ngOnDestroy() {
    this._version .title = this.oldVersionTitle;
    this._version.status = this.oldVersionStatus;
    this.versionChange.emit(this._version );
  }
}
