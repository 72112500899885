import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SliderDialogService } from '../../shared/services/slider-dialog.service';
import { CreateTracksComponent } from '../create-tracks/create-tracks.component';
import { CreateTrackComponent } from '../../shared/create-track/create-track.component';
import { UploadTrackPopComponent } from '../upload-track-pop/upload-track-pop.component';
import { User } from '../../shared/models/user.model';
import { CreateTrackVersionComponent } from '../../shared/create-track-version/create-track-version.component';
import { environment } from '../../../environments/environment';
import { MenuListService } from '../../shared/services/menu-list.service';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { DialogService } from '../../shared/services/dialog.service';
import { RemoveTrackDialogueComponent } from '../remove-track/remove-track-dialogue.component';
import { UserService } from 'src/app/shared/services/user.service';
import { Router } from '@angular/router';
import { ComposerAlertComponent } from 'src/app/composer-alert/composer-alert.component';
import { PlayerService } from 'src/app/shared/services/player.service';
const listPageSize = 30;

interface sortFile {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-my-upload',
  templateUrl: './my-upload.component.html',
  styleUrls: ['./my-upload.component.scss']
})
export class MyUploadComponent implements OnInit {
  searchPerm:string = '';
  selectedsort = 'All';
  currentPage: number = 1;
  sorting: sortFile[] = [
    {value: 'file_name', viewValue: 'File Name'}
  ];
  displayedColumns: string[] = ['position', 'name', 'weight'];
  dataSource = ELEMENT_DATA;
  loading: boolean = false;
  logout: boolean = false;
  opened:number = 0;
  openedVersion: number = 0;
  currentUser: User;
  constructor( 
    private _sliderDialogService:SliderDialogService,private _router: Router,
    public api: UserMenuService, 
    public config: MenuConfigService,
    private dialog: DialogService,
    public list: MenuListService,   public _userService: UserService, private _playerService: PlayerService
    ) { }
    get genreNumberOfPages(): number {
      return Math.ceil(this.list.uploadedTracks.count / listPageSize);
  }
  ngOnInit(): void {

    this._userService.currentUserStream.subscribe((u) => {
      this.currentUser = u;
    })
    this.list.getContinueTrack().subscribe(res => {
      this.getUploadedTracks();
    })
    this.getUploadedTracks()
  }

  uploadTrack() {
    
    this._sliderDialogService.open(UploadTrackPopComponent, {
      width: "600px",
      title: "UPLOAD TRACKS",
      panelClass: "edit-track-dialog",
      data: {user: new User({is_client:true}), isNewUser: true}
    }, (user) => {
    });
  
}
ngOnDestroy(): void {
  this._playerService.updateShowMusicPlayer(true);
  this._playerService.play(null, null);
  this.list.getTrackFileSubject.next(null);
  this.list.getTrackTypeSubject.next("track");
  this.list.getTrackFileSubject.next(null);
  this.list.getTrackTypeSubject.next("trackversion");
}

pageChange(page:number) {
  let url;
  if(page!=1){
    url = environment.apiURL + this.config.uploadedTracksapi+'?page='+page;
  }else{
    url = environment.apiURL + this.config.uploadedTracksapi;
  }    
    this.api.doGET(url).subscribe(x=>{
      const rows = [];
      this.loading = false;
      this.list.uploadedTracks = x || [];
      this.list.uploadedTracksDetails = this.list.uploadedTracks.results;
      //  x['results'].forEach(element => rows.push(element, { detailRow: true, element }));
      // this.parentTracksDetails = rows || [];
      },
      (error)=>{
      })
  }
  newTrack(ele?:any) {
    if(this._userService?.currentUser?.can_upload === false){
      this.uploadpop()
    }
    else {
      var checkbox = document.getElementById('checkbox1');

      function handleCheckboxEvent(e) {
        e.preventDefault();

        if (e.keyCode === 32) {  // If spacebar fired the event
          this.checked = !this.checked;
        }
      }

      checkbox.addEventListener("click", handleCheckboxEvent, true);
      checkbox.addEventListener("keyup", handleCheckboxEvent, true);
    this._sliderDialogService.getTrackCreationTypeSubject.next("create");
    console.log(ele, "Element");
    this.list.getTrackFileSubject.next(ele);
    this.list.getTrackTypeSubject.next("track");
   // if(this.opened !== ele.id){
      this._sliderDialogService.open(CreateTrackComponent, {
        width: "600px",
        title: "CREATE A TRACK",
        panelClass: "edit-track-dialog",
        data: {user: new User({is_client:true}), isNewUser: true, mytrack:ele}
     
      }, (user) => {
        if (user[1] === false) {
          this.logout =true;
          this.dialog.displayMenuDialogAlert(ComposerAlertComponent,false,user[0]).subscribe(res => {
          })
        }
      });
      this.opened =  ele.id;
   // }
  }
  
}
newTrackVersion(ele?:any) {
  if(this._userService?.currentUser?.can_upload === false){
    this.uploadpop()
  }

  else {
    this._playerService.trackCowriter1([])
    var checkbox = document.getElementById('checkbox');

    function handleCheckboxEvent(e) {
        e.preventDefault();
  
        if (e.keyCode === 32) {  // If spacebar fired the event
            this.checked = !this.checked;
        }
    }
  
    checkbox.addEventListener("click", handleCheckboxEvent, true);
    checkbox.addEventListener("keyup", handleCheckboxEvent, true);

    this._sliderDialogService.getTrackCreationTypeSubject.next("create");
    this.list.getTrackFileSubject.next(ele);
    this.list.getTrackTypeSubject.next("trackversion");
   // if (this.openedVersion !== ele.id) {
      this._sliderDialogService.open(CreateTrackVersionComponent, {
        width: "600px",
        title: "CREATE A TRACK VERSION",
        panelClass: "edit-track-dialog",
        data: { user: new User({ is_client: true }), isNewUser: true, mytrack: ele }
      }, (user) => {
        if (user[1] === false) {
          this.logout =true;
          this.dialog.displayMenuDialogAlert(ComposerAlertComponent,false,user[0]).subscribe(res => {
          })
        }
      });
      this.openedVersion = ele.id;
    //}
  }
}
searchkey(keyword){
  if(keyword!=''){
    this.searchTracks(keyword)
  }else{
    this.getUploadedTracks()
  }
}
searchTracks(value){
  this.loading = true;
  let url = environment.apiURL + this.config.searchUploadedTrack + value;
    this.api.doGET(url).subscribe(x=>{
      this.loading = false;
      this.list.uploadedTracksDetails = x['results'] || [];
    },
    (error) => {
      this.loading = false;
    })
}
refreshTracks(evt){
  if(evt){
    this.getUploadedTracks()
  }
}
getUploadedTracks(){
  this.loading = true;
    let url = environment.apiURL + this.config.uploadedTracksapi;
    this.api.doGET(url).subscribe(x=>{
      this.loading = false;
      this.list.uploadedTracks = x || [];
      this.list.uploadedTracksDetails = this.list.uploadedTracks.results;
      console.log(this.list.uploadedTracksDetails)
    },
    (error) => {
      this.loading = false;
    })
    

}
sortingFilename(evt){
  let opt = evt.value
  console.log(evt.value)
  this.loading = true;
  let url = environment.apiURL + this.config.sortuploadedTrack + opt;
    this.api.doGET(url).subscribe(x=>{
      this.loading = false;
      this.list.uploadedTracksDetails = x['results'] || [];
    },
    (error) => {
      this.loading = false;
    })
}

removeTrack(){
  this.dialog.displayMenuDialog(RemoveTrackDialogueComponent).subscribe(res => {
    this.searchPerm = ""
    this.getUploadedTracks();
  })
}

uploadpop(){
  this.dialog.displayMenuDialogAlert(ComposerAlertComponent,true,"").subscribe(res => {
    this.getUploadedTracks();
  })
}
}
export interface PeriodicElement {
  name: string;
  position: string;
  weight: number;
  
}

const ELEMENT_DATA: PeriodicElement[] = [
  {position: 'file name_example_song version.wav', name: '10.6 mb', weight: 1.0079},
  {position: 'file name_example_song version.wav', name: '10.6 mb', weight: 1.0079},
 
];