import { Component, OnInit, ViewChild, ViewChildren, QueryList, ChangeDetectorRef, OnDestroy, PipeTransform, Pipe } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { environment } from '../../../environments/environment';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { Track } from '../../shared/models/track.model';
import { TrackVersion } from '../../shared/models/trackversion.model';
import { PlayerService, PlayingItem } from '../../shared/services/player.service';
import { DialogService } from '../../shared/services/dialog.service';
import { TrackDetailsComponent } from '../../shared/track-details/track-details.component';
import { TrackDetailsComposerComponent } from '../track-details-composer/track-details-composer.component';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { UserService } from 'src/app/shared/services/user.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { TrackService } from 'src/app/shared/services/track.service';
import { MatDialog } from '@angular/material/dialog';
import { SliderDialogService } from '../../shared/services/slider-dialog.service';
import { MenuListService } from '../../shared/services/menu-list.service';
import { ReplaceTrackVersionComponent } from '../replace-track-version/replace-track-version.component'
const listPageSize = 30;

interface Food {
  value: string;
  viewValue: string;
}
@Pipe({
  name: 'minuteSeconds'
})
export class MinuteSecondPipe implements PipeTransform {
  transform(value: number): string {
    let numhours = Math.floor(value / 3600);
    let numminutes = Math.floor((value % 3600) / 60);
    let numseconds = Math.round((value % 3600) % 60);
    let str = '';
    if (numhours) {
      str = this.zeroPad(numhours, 2) + ':' + this.zeroPad(numminutes, 2) + ':' + this.zeroPad(numseconds, 2);
    } else {
      str = this.zeroPad(numminutes, 2) + ':' + this.zeroPad(numseconds, 2);
    }
    return str;
  }
  private zeroPad(num, minLength): string {
    let numStr = '' + num;
    while (numStr.length < minLength) {
      numStr = '0' + numStr;
    }
    return numStr;
  }
}

@Component({
  selector: 'app-my-tracks',
  templateUrl: './my-tracks.component.html',
  styleUrls: ['./my-tracks.component.scss'],
  animations: [
    trigger('rowInOut', [
      transition(':enter', [
        style({
          opacity: '0',
          height: '0',
          paddingTop: '0',
          overflow: 'hidden',
          paddingBottom: '0',
        }),
        animate(
          '0.7s cubic-bezier(0.19, 1, 0.22, 1)',
          style({
            opacity: '1',
            height: '*',
          })
        ),
      ]),
      transition(':leave', [
        style({
          opacity: '1',
          height: '*',
          overflow: 'hidden',
        }),
        animate(
          '0.7s cubic-bezier(0.19, 1, 0.22, 1)',
          style({
            opacity: '0',
            height: '0',
            paddingTop: '0',
            paddingBottom: '0',
          })
        ),
      ]),
    ]),
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', overflow: 'hidden' })
      ),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})



export class MyTracksComponent implements OnInit, OnDestroy {
  searchFilterFocused = false;
  selectedgenre :any = false; 
  selectedsubg  :any = false; 
  selectedmood  :any = false;
  selectedtempo  :any = false;
  food = 'All';
  clearBtnFlag:boolean =  false;
  cleargenre=false;
  clearsubgenre=false;
  clearmood=false;
  cleartempo=false;
  foods: Food[] = [
    {value: 'steak-0', viewValue: 'Pending'},
    {value: 'pizza-1', viewValue: 'Pending Approval'},
    {value: 'tacos-2', viewValue: 'Approved'},
    {value: 'app-3', viewValue: 'Denied'},
    {value: 'dis-4', viewValue: 'Disabled'},
    {value: 'All', viewValue:"All"},
  ];
  loading:boolean = false;
  parentTracksDetails: Track ;
  versions:any=[]
  dataTab: any[] = [];

  @ViewChild('outerSort', { static: true }) sort: MatSort;
  @ViewChildren('innerSort') innerSort: QueryList<MatSort>;
  @ViewChildren('innerTables') innerTables: QueryList<any>;

  isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('versions');
  dataSource1: any;
  usersData: User[] = [];
  columnsToDisplay = ['name', 'email', 'adstatus', 'phone'  , 'symbol'];
  innerDisplayedColumns = ['street', 'zipCode', 'adstatus1','city', 'artistn'];
  expandedElement: any | null;
  keyValue: any='';
  filteredGenreOptions: any = [];
  filteredSubGenreOptions: any = [];
  filteredMoodsOptions: any = [];
  filteredTempoOptions: any = [];
  currentPage: number = 1;

  genreId: any ='';
  subGenreId: any ='';
  moodId: any = '';
  playingTrack: Track = null;
  playingTrackVersion: TrackVersion = null;
  tempoId:any ='';
  tabFlag:string = "0"
  isExclusive: any = 'False';
  isInstrumental: any = 'False';
  statusVal: any ='';
  private _isPlaying: boolean = false;
  loadingTracks = true
  wavePlay: boolean = true;
  private _currentlyPlaying: PlayingItem;
  expandedTrack: Observable<Track> = null;
  Instrumentalsflag: boolean = true;
  Vocalsflag: boolean = true;
  data:any=[]
  currentUser: any=[];
  visibleGenre : any=[];
  count= 0
  expandedTrackSubject = new BehaviorSubject<Track>(null);
  private _subscriptions: Subscription[] = [];
  get genreNumberOfPages(): number {
      return Math.ceil(this.count / listPageSize);
  }
  constructor(
    private cd: ChangeDetectorRef, 
    public api: UserMenuService, 
    public config: MenuConfigService,
    public _playerService: PlayerService,
    public dialog: MatDialog,
    private _sliderDialogService:SliderDialogService,
    private _userService: UserService,
    private _trackService: TrackService,
    public list: MenuListService,
    private _dialogService: DialogService,
    ) { }

  ngOnInit(): void {
    this.getInitData();

    this._subscriptions.push(
      this._playerService.currentlyPlaying.subscribe(playingItem => {
        if (!playingItem) {
          this.playingTrack = null;
          this.playingTrackVersion = null;
          return;
        }
        this.playingTrack = playingItem.track;
        this.playingTrackVersion = playingItem.version;
      }),

      this._playerService.currentlyPlaying.subscribe(data => {
        this._currentlyPlaying = data;
      }),
      this._playerService.isPlaying.subscribe(isPlaying => {
        this._isPlaying = isPlaying;
      }),
      this._playerService.isWavePlay.subscribe((p) => {
        this.wavePlay = p;
        this._isPlaying = p
        console.log(this.wavePlay)
      }),
      this._playerService.isLoader.subscribe((p) => {
        this.loadingTracks = p;
        console.log(this.loadingTracks)
      })
    )
  }
  getInitData() {
    let data = {}

    if(this.Instrumentalsflag !== null){
      data['instrumental'] = this.Instrumentalsflag

    }
    if(this.Vocalsflag !== null){
      data['is_vocal'] = this.Vocalsflag

    }
    let url = environment.apiURL + '/api/v1/track/track_list/';
    this.api.doGET(url, { params: data }).subscribe(x => {
      this.loading = false;
      console.log(x);
      this.parentTracksDetails = x['results'] || [];
      this.count =  x['count']
      console.log(this.parentTracksDetails)
      this.parentTracksDetails.versions =[]
      // this.dataSource1 = new MatTableDataSource(this.parentTracksDetails.versions)
      // this.dataSource1.sort = this.sort;
      /*  USERS.forEach(user => {
         if (this.parentTracksDetails.results && Array.isArray(user.addresses) && user.addresses.length) {
           this.usersData = [...this.usersData, {...user, addresses: new MatTableDataSource(user.addresses)}];
         } else {
           this.usersData = [...this.usersData, user];
         }
       }); */
    },
      (error) => {
        this.loading = false;
      })
    this.getAllGenreTracks()
    this.getAllMoodsTracks();
    this.getAllTempoTracks();
  }
  toggleRow(element) {
    element ? (this.expandedElement = this.expandedElement === element ? null : element) : null;
  //   this.playingTrack = element;
  //   this.musicPlayerCollapsed = true;
  //   this.playingTrackVersion = element.versions;
  //   this._playerService.play(element, element.versions);
  //   this.musicPlayerCollapsed = !this.musicPlayerCollapsed;
  //   this.cd.detectChanges();
  //   this.innerTables.forEach((table, index) => 
  //   (table.dataSource as MatTableDataSource<any>).sort = this.innerSort.toArray()[index]
  //   );
  //  console.log(this.playingTrack,   this.playingTrackVersion)
  //  console.log("element",element,   element.versions)
   if (this.trackIsExpanded(element)) {
    this._playerService.updateShowMusicPlayer(false);
    this._playerService.play(null, null);
    this.expandTrack(element);
    return;
  }
  this.expandTrack(element);
  if (!element.versions || element.versions.length <= 0) return;
 // let version = element.versions.find(v => v.is_full_version);
  let version =  element.versions[0]
  if (!version) version = element.versions[0];
  this._playerService.updateShowMusicPlayer(true);
  this._playerService.updatePlaybackContextList(element.versions.map(v => new PlayingItem(element, v)));
  this._playerService.play(element, version);
  this.innerTables.forEach((table, index) => 
    (table.dataSource as MatTableDataSource<any>).sort = this.innerSort.toArray()[index]
    );
  }

  toggleRow1(element){
    element ? (this.expandedElement = this.expandedElement === element ? null : element) : null;
    if (this.trackIsExpanded(element)) {
      this._playerService.updateShowMusicPlayer(false);
      this._playerService.play(null, null);
      this.expandTrack(element);
      return;
    }
    let headers = new HttpHeaders();
    this.api.doGET(environment.apiURL + '/api/v1/track/'+element.id+'/get-version/',
    {
      headers: headers,
      params: element.id
    }
  ).subscribe(t => {
    console.log(t)
    if (t) {
      this.versions = t
      element.versions = this.versions
      this.expandTrack(element);
    
      if (!element.versions || element.versions.length <= 0) return;
      let version = element.versions[0]
      if (!version) version = element.versions[0];
      this._playerService.nextTab(false);
      this._playerService.updateWavePlay(true);
      this._playerService.updateShowMusicPlayer(true);
      this._playerService.updateIsPlaying(true);
      this._playerService.updatePlaybackContextList(element.versions.map(v => new PlayingItem(element, v)));
      this._playerService.play(element, version);
      this.innerTables.forEach((table, index) =>
        (table.dataSource as MatTableDataSource<any>).sort = this.innerSort.toArray()[index]
      );
      
    }
  })
  }

  expandTrack(track: Track) {
    if (
      this.expandedTrackSubject.value &&
      this.expandedTrackSubject.value.id == track.id
    ) {
      this.expandedTrackSubject.next(null);
      return;
    }
    this.expandedTrackSubject.next(track);
  }

  trackIsExpanded(track: Track) {
    return (
      this.expandedTrackSubject.value &&
      this.expandedTrackSubject.value.id == track.id
    );
  }

  setPlayingTrack(track:Track) {
    this.playingTrack = track;
  }

  setPlayingTrackVersion(trackVersion:TrackVersion) {
    this.playingTrackVersion = trackVersion;
  }
  clearFilter() {
    this.selectedgenre = false; 
    this.selectedsubg = false; 
    this.selectedmood = false; 
    this.selectedtempo = false;
    this.clearsubgenre=false;
    this.cleargenre=false;
    this.clearmood=false;
    this.cleartempo=false;
    this.genreId='';
    this.subGenreId='';
    this.moodId='';
    this.tempoId='';
    this.clearBtnFlag = false;
    if( this.keyValue ==''){
    this.getInitData();
    }
    else{
      this.searchTracks()
    }
   
  }
  getAllParentTracks(page:any='') {
    this.loading = true;
    let data = {}
    if (this.genreId != null ) {
      data['genreId'] = this.genreId
    }
    if (this.subGenreId != null) {
      data['sub_genre'] = this.subGenreId
    }

    if (this.moodId != null) {
      data['moodId'] = this.moodId
    }
    if (this.tempoId != null) {
      data['tempo'] = this.tempoId
    }
    if(this.statusVal !=null && this.statusVal!=='ALL'){
      if(this.statusVal === 'PENDING APPROVAL'){
        this.statusVal='PENDING_APPROVAL'
      }
      data['versionStatus'] = this.statusVal
    }
    if(this.keyValue.trim() !==''){
      data['search'] = this.keyValue;
    }

    if (this.Instrumentalsflag !== null) {
      data['instrumental'] = this.Instrumentalsflag

    }
    if (this.Vocalsflag !== null) {
      data['is_vocal'] = this.Vocalsflag
    }
    let URlDetail
    if(page !==''){
      URlDetail = '/api/v1/track/filter/' + '?page=' + page;
    }
    else{
      URlDetail = '/api/v1/track/filter/'
    }

    this.api.doGET(environment.apiURL + URlDetail, {
     
      params: data }).subscribe(x => {
      this.loading = false;
      this.parentTracksDetails = x['results'] || [];
      this.count =  x['count']
      console.log(this.parentTracksDetails)
      new MatTableDataSource(this.parentTracksDetails.versions)
    },
      (error) => {
        this.loading = false;
      })
  }
  pageChange(page:number) {
   
    let data =  this.getAllParentTracks(page), url;
    if(this.keyValue !== ''){
      this.searchTracks(page)
    }
    if(this.clearBtnFlag && this.keyValue !==''){
      this.searchTracks(page) 
    }
    if(this.clearBtnFlag && this.keyValue ==''){
      this.getAllParentTracks(page)
    }
    else{
    
    if(this.Instrumentalsflag !== null){
      data['is_instrumental'] = this.Instrumentalsflag

    }
    if(this.Vocalsflag !== null){
      data['is_vocals'] = this.Vocalsflag

    }
    if (page != 1) {
      url = environment.apiURL + '/api/v1/track/track_list/' + '?page=' + page;
    } else {
      url = environment.apiURL + '/api/v1/track/track_list/';
    }
    this.api.doGET(url, { params: data }).subscribe(x => {
      const rows = [];
      this.loading = false;
      this.parentTracksDetails = x['results'] || [];
      this.count = x['count']
      // this.dataSource1 = new MatTableDataSource(this.parentTracksDetails.versions)
      //this.dataSource1.sort = this.sort;
      //  x['results'].forEach(element => rows.push(element, { detailRow: true, element }));
      // this.parentTracksDetails = rows || [];
    },
      (error) => {
      })
    }
    }
  getAllGenreTracks(){
    this._userService.currentUserStream.subscribe((u) => {
      this.currentUser = u;
    });
    let url = environment.apiURL + `/api/v1/admin/users/${this.currentUser.id}/`;
    this.api.doGET(url).subscribe(x=>{
      this.data = x || [];
      this.visibleGenre = this.data.visible_genres
      this.filteredGenreOptions = this.visibleGenre;
    },
    (error) => {
      this.loading = false;
    })
    // let url = environment.apiURL + '/api/v1/track/genre-dropdown';
    //   this.api.doGET(url).subscribe(x=>{
    //     this.loading = false;
    //     this.filteredGenreOptions = x || [];
    //   },
    //   (error) => {
    //     this.loading = false;
    //   })
  }
  getAllTempoTracks(){
    this.loading = true;
    let url = environment.apiURL + '/api/v1/track/tempo-dropdown/';
      this.api.doGET(url).subscribe(x=>{
        this.loading = false;
        this.filteredTempoOptions = x || [];
      },
      (error) => {
        this.loading = false;
      })
  }
  genreChanged(data, value:any=''){
    if(this.selectedgenre == false){
      this.genreId = ''
    }
    else{
      this.genreId = this.selectedgenre;
    }
   
    this.getAllParentTracks();
  }
  subgenreChanged(id, value:any=''){
    if(this.selectedsubg == false){
      this.subGenreId = ''
    }
    else{
      this.subGenreId = this.selectedsubg;
    }
   
      this.getAllParentTracks();
  }
  moodsChanged(id, value:any=''){
    
    if(this.selectedmood == false){
      this.moodId = ''
    }
    else{
      this.moodId = this.selectedmood;
    }
    
    this.getAllParentTracks();
  }
  tempoChanged(id, value:any=''){
    if(this.selectedtempo == false){
      this.tempoId = ''
    }
    else{
      this.tempoId = this.selectedtempo;
    }
    this.getAllParentTracks();
  }
  statusFilter(sta){
    if(sta == 'All'){
      this.statusVal = ''
    }else{
      this.statusVal=sta.viewValue.toUpperCase();
    }
    this.getAllParentTracks();
    // else if(sta.viewValue == 'Approved'){
    //   this.statusVal = 'APPROVAL'
    // }else{
    //   this.statusVal = sta.viewValue.toUpperCase()
    // }
    // console.log(this.statusVal)
    // this.loading = true;
    // let url = environment.apiURL + '/api/v1/track/?versionStatus=' + this.statusVal
    
    //   this.api.doGET(url).subscribe(x=>{
    //     this.loading = false;
    //     this.parentTracksDetails = x || [];
    //     console.log(this.parentTracksDetails)
    //     new MatTableDataSource(this.parentTracksDetails.versions)
        
    //   },
    //   (error) => {
    //     this.loading = false;
    //   })
  }

  getAllSubGenreTracks(e){
    this.loading = true;
    let url = environment.apiURL + `/api/v1/track/${e.value}/sub-genre-dropdown`;
      this.api.doGET(url).subscribe(x=>{
        this.loading = false;
        this.filteredSubGenreOptions = x || [];
      },
      (error) => {
        this.loading = false;
      })
  }
  getAllMoodsTracks(){
    this.loading = true;
    let url = environment.apiURL + '/api/v1/track/moods-dropdown/';
      this.api.doGET(url).subscribe(x=>{
        this.loading = false;
        this.filteredMoodsOptions = x || [];
      },
      (error) => {
        this.loading = false;
      })
  }
  
  insChecked(evt){
    // if(evt){
    //   this.isInstrumental = 'True'
    // }else{
    //   this.isInstrumental = 'False'
    // }  
    this.isInstrumental = this.Instrumentalsflag
    this.getAllParentTracks();

  }
  exclusiveChecked(evt){    
    this.isExclusive =this.Vocalsflag;
    this.getAllParentTracks();
    // if(evt){
    //   this.isExclusive = 'True'
    //   console.log(this.isExclusive)
    // }else{
    //   this.isExclusive = 'False'
    //   console.log(this.isExclusive)
    // }
  }
  getSearchVal(evt){
    this.keyValue = evt.trim();
  }
  searchTracks(page:any=''){
    this.loading = true;
    let url;
    let URlDetail
    if(this.statusVal !=null && this.statusVal!=='ALL'){
      if(this.statusVal === 'PENDING APPROVAL'){
        this.statusVal='PENDING_APPROVAL'
      }
    }
    if ((this.keyValue.trim() !=='')||(this.genreId != undefined || this.genreId != '') || (this.subGenreId != undefined || this.subGenreId != '') || (this.moodId != undefined || this.moodId != '')|| (this.tempoId != undefined || this.tempoId != '')) {
      url = environment.apiURL + '/api/v1/track/filter/?search=' + this.keyValue  +'&versionStatus='+this.statusVal+'&genreId=' + this.genreId + '&sub_genre=' + this.subGenreId + '&moodId=' + this.moodId + '&tempo=' + this.tempoId + '&is_vocal=' + this.Vocalsflag + '&instrumental=' + this.Instrumentalsflag;
    } else {
      url = environment.apiURL + '/api/v1/track/track_list/'
    }
    if (page !== '') {
      URlDetail = url + '?page=' + page;
    }
    else {
      URlDetail = url
    }

      this.api.doGET(URlDetail).subscribe(x=>{
        this.loading = false;
        this.parentTracksDetails = x['results'] || [];
        this.count =  x['count']
        console.log(this.parentTracksDetails)
      //  new MatTableDataSource(this.parentTracksDetails.versions)
      },
      (error) => {
        this.loading = false;
      })
  }
  playButtonPressed(track: Track, version: TrackVersion) {
    this._isPlaying = true;
    this._playerService.updateWavePlay(true);
    this._playerService.play(track, version);
  }

  pauseButtonPressed(track: Track, version: TrackVersion) {
    this._isPlaying = false;
    this._playerService.updateWavePlay(false);
    this._playerService.updateIsPlaying(false);
  }

  openDetails(track: Track,version: TrackVersion){
    this._playerService.nextTab(true);
    this._playerService.updateViewType(true);
    this._playerService.updatePreviewPlaying(true);
    this._playerService.updateShowMusicPlayer(false);
    this._playerService.play(null, null);
    this._trackService.getComposerDetails(version.id).subscribe(opt => {
      this.dataTab = opt['composer_details'];
      if (this.dataTab.length > 0) { 
        // const dialog = this.dialog.open(TrackDetailsComposerComponent, {
        //   autoFocus: true,
        //   closeOnNavigation: true,
        //   width: '708px',
        //   maxHeight: '100%',
        //   panelClass: 'no-padding-dialog',
        //   data: {track: track, version:version, response: this.dataTab, composers: opt}
        // });
        // return dialog.afterClosed();
        this._dialogService.displayDetailTrackVersionDialog(track, version,opt).subscribe(changed => {
          if (changed) {
            this.getInitData()
          }
        });
      }
    }, (error) => {
      console.log(error);
    });
    return ;
    // this.dialog.displayMenuDialog(TrackDetailsComposerComponent)

   
  }

  isPlaying(version: TrackVersion) {
    if (!this._isPlaying || !this.wavePlay) return false;
    if (!this._currentlyPlaying || !this._currentlyPlaying.track || !this._currentlyPlaying.version) return false;
    return this._currentlyPlaying.version.id == version.id;
  }

  
  ngOnDestroy() {
    this._subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  
  }

}


export interface User {
  name: string;
  email: string;
  phone: string;
  
  addresses?: Address[] | MatTableDataSource<Address>;
}

export interface Address {
  street: string;
  zipCode: string;
  city: string;
}

export interface UserDataSource {
  name: string;
  email: string;
  phone: string;
 
  addresses?: MatTableDataSource<Address>;
}

const USERS: User[] = [
  {
    name: "Parent Track Name",
    email: "ACTION REQUIRED",
    phone: "In-progress",
    
    addresses: [
      {
        street: "Main",
        zipCode: "05.36",
        city: "Pending Approved"
      },
      {
        street: "Narrative",
        zipCode: "05.36",
        city: "Pending"
      }
    ]
  },
  {
    name: "Parent Track Name",
    email: "ACTION REQUIRED",
    phone: "In-progress",
    
    addresses: [
      {
        street: "Main",
        zipCode: "05.36",
        city: "In-progress"
      },
      {
        street: "Narrative",
        zipCode: "05.36",
        city: "Denied"
      }
    ]
  },
  
];

