import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmailGroupService } from '../services/email-group.service';
import { UserService } from '../../shared/services/user.service';
import { DialogService } from '../services/dialog.service';

export interface DialogData {
  name: string;
  isDuplicate: boolean;
  id:number
}
@Component({
  selector: 'app-new-user-email-group',
  templateUrl: './new-user-email-group.component.html',
  styleUrls: ['./new-user-email-group.component.scss']
})
export class NewUserEmailGroupComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<NewUserEmailGroupComponent>,
    public _emailGroupService: EmailGroupService,
    private _userService: UserService,
    public _DialogService:DialogService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  loading = false;
  type=  this._emailGroupService.checkedtype;
  name: string;
  error: string;
  id:number;


  ngOnInit(): void {
    this.error = null;
    if (this.data && this.data.name) {
      this.name = this.data.name;
    }
    if (this.data && this.data.id) {
      this.id = this.data.id;
    }
  }

  create() {
    this.type = this._emailGroupService.checkedtype;
    if (!this.name) {
      this.error = "Name is required";
      return;
    }
    if (!this.type) {
      this.error = "Type is required";
      return;
    }
    this.error = null;
    this.loading = true;
    this._emailGroupService.createGroup(this.name, this.type).subscribe(group => {
     this.loading = false;
     this.dialogRef.close(true);
    }, err => {
      let data = err.error?.error ? err.error?.error : (err.error?.detail ?err.error?.detail : (err.error?.details ? err.error?.details:``) )
          this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
          });
      this.loading = false;
      // this.error = "There was an error trying to create your email group. Please check your internet connection and try again";
    })
  }
  dupliacte(){
    this.type = this._emailGroupService.checkedtype;
    if (!this.name) {
      this.error = "Name is required";
      return;
    }
    if (!this.type) {
      this.error = "Type is required";
      return;
    }
    if (!this.id) {
      this.error = "Name is required";
      return;
    }
    this.error = null;
    this.loading = true;
    this._emailGroupService.duplicateGroup(this.name, this.type, this.id).subscribe(group => {
     this.loading = false;
     this.dialogRef.close(true);
    }, err => {
      // alert(err.error.details)
      let data = err.error?.error ? err.error?.error : (err.error?.detail ?err.error?.detail : (err.error?.details ? err.error?.details:``) )
      this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
      });
      this.loading = false;
      // this.error = "There was an error trying to create your email group. Please check your internet connection and try again";
    })
  }

  close() {
    this.dialogRef.close(false);
  }

}
