import { MibeModel } from './mibemodel.model';
import { PlaylistClient } from './playlistclient.model';

export class Playlist implements MibeModel {
  id: number;
  name: string;
  description: string;
  thumb_image: string;
  marquee_image: string;
  is_featured: boolean;
  is_public: boolean;
  is_editable: boolean;
  track_count: number;
  composer_count: number;
  active_clients: PlaylistClient[];
  created_by: number;
  created_at: Date;
  is_cms:boolean;
  assigned_folders: any[] =[]

  private _original_data: any;

  /**
   * Create a Playlist.
   * @param {any} json - The JSON object with which to construct our Playlist.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a Playlist.
   * @param {any} json - The JSON object with which to load our Playlist.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this._original_data = json;
    this.id = json.id;
    this.name = json.name;
    this.description = json.description;
    this.thumb_image = json.thumb_image;
    this.marquee_image = json.marquee_image;
    this.is_featured = !!json.is_featured;
    this.is_public = !!json.is_public;
    this.is_editable = !!json.is_editable;
    this.track_count = json.track_count;
    this.composer_count = json.composer_count
    this.active_clients = json.active_clients && json.active_clients.length > 0 ? json.active_clients.map(c => new PlaylistClient(c)):[];
    this.created_by = json.created_by;
    this.created_at = json.created_at ? new Date(json.created_at):null
    this.is_cms = json.is_cms;
    this.assigned_folders = json.assigned_folders;
  }
  /**
   * Returns a JSON representation of our Playlist
   * @return {any} - The JSON representation of our Playlist
   */
  toJSON() {
    var json = this._original_data;
    json['id'] = this.id;
    json['name'] = this.name;
    json['description'] = this.description;
    json['thumb_image'] = this.thumb_image;
    json['marquee_image'] = this.marquee_image;
    json['is_featured'] = this.is_featured;
    json['is_public'] = this.is_public;
    json['is_editable'] = this.is_editable;
    json['track_count'] = this.track_count;
    json['composer_count'] = this.composer_count;
    json['active_clients'] = this.active_clients.map(c=>c.toJSON());
    json['created_at'] = this.created_at;
    json['is_cms'] = this.is_cms;
    json['assigned_folders'] = this.assigned_folders
    return json;
  }

  containsTrackVersion(id: number): boolean {
    return this._original_data['contains_track_version_' + id];
  }

  getDisplayName(): string {
    return `${this.name}`;
  }

  formatDate(d:Date): string {
    return `${d.getMonth()+1}/${d.getDate()}/${d.getFullYear().toString().substr(-2)}`;
  }
}
