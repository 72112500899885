import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ChangeDetectorRef

} from '@angular/core';

import { FormControl } from '@angular/forms';
import { ArtistService, FilterableArtist } from '../services/artist.service';
import { Filters, minMaxToString, stringToMinMax } from '../services/track.service';
import { ReplaySubject, Subscription, of, Subject } from 'rxjs';
import { startWith, debounceTime, distinctUntilChanged, map, tap, switchMap, take, takeUntil } from 'rxjs/operators';
import { TrackStatusValue } from '../models/trackstatus-value.model';
import { Genre } from '../models/genre.model';
import { Mood } from '../models/mood.model';
import { GenreService } from '../services/genre.service';
import { MoodService } from '../services/mood.service';
import { version } from 'process';
import { UserMenuService } from '../services/user-menu.service';
import { environment } from '../../../environments/environment';
import { MenuListService } from '../services/menu-list.service';

interface SelectString {
  value: string;
  label: string;
}
@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {

  statusnumber: any = false
  artistNumber: any = false
  gnereNumber: any = false
  subgnereNumber: any = false
  moodNumber: any = false
  tempoNumber: any = false
  bpm: any = ''
  versionNumber: any = false
  Instrumentalsflag: boolean = true;
  Vocalsflag: boolean = true;
  ExclusiveTracksflag: boolean = true;
  isSelected: boolean = false;
  currentMoods: Mood[] = []
  searchingMoods = false;
  MibeOwnedflag: boolean = false;

  // selectArtistType = ['artist', 'cowritter', 'both']
  selectedArtsit = 'None'
  selectArtistType = [
    { value: 'artist', label: 'Artist Only' },
    { value: 'cowritter', label: 'Co-writer Only' },
    { value: 'both', label: 'Both Artist and Cowriter' },
  ];


  clearBtnFlag: boolean = false;
  @Input() totalTracks: string;
  _onDestroy = new Subject<void>();
  artistFilterCtrl = new FormControl();
  filteredArtistOptions: ReplaySubject<FilterableArtist[]> = new ReplaySubject<FilterableArtist[]>(1);
  searchingArtist = false;

  genreFilterCtrl = new FormControl();
  filteredGenreOptions: ReplaySubject<Genre[]> = new ReplaySubject<Genre[]>(1);
  searchingGenre = false;

  subGenreFilterCtrl = new FormControl('');
  filteredSubGenreOptions: ReplaySubject<Genre[]> = new ReplaySubject<Genre[]>(1);
  searchingSubgenre:any=''

  moodFilterCtrl = new FormControl();
  filteredMoodOptions: ReplaySubject<Mood[]> = new ReplaySubject<Mood[]>(1);

  tempoFilterCtrl = new FormControl();
  filteredTempoOptions: ReplaySubject<SelectString[]> = new ReplaySubject<SelectString[]>(1);
  versionFilterCtrl = new FormControl();
  filteredVersionOptions: ReplaySubject<SelectString[]> = new ReplaySubject<SelectString[]>(1);

  public banks: Genre[] = [];
  tmpBanks: Genre[];
  onSearchBank(value) {
    this.banks = this.searchBank(value);
  }

  searchBank(value: string) {
    let filter = value.toLowerCase();
    return this.tmpBanks.filter(option => option.name.toLowerCase().includes(filter));
  }

  loading = false;
  version: any;
  isinstrumentalChecked = true;
  vocalsChecked = true;
  isExclusiveChecked = true;
  searchArtist = '';
  searchGenre: any = null;
  searchMood: any = null;
  searchSubgnere: any = null;
  @Input()
  set filters(f: Filters) {

    this._filters = f;
    // if (f.artistType !== 'None') {
    //   this.clearBtnFlag = true;
    //   this.selectedArtsit = f.artistType ? f.artistType : 'None'
    // }
    // // this.selectedArtsit = f.artistType? f.artistType:'None'

    // if (f.versionStatus && f.versionStatus.length > 0) {
    //   this.statusnumber = f.versionStatus;
    // } else {
    //   this.statusnumber = false;
    // }
    // if (!(f.artistId || f.composerId) && this.selectedArtist) {
    //   this.selectedArtist = null;
    // } else if ((f.artistId || f.composerId) && (!this.selectedArtist || (this.selectedArtist.id != f.artistId && this.selectedArtist.id != f.composerId))) {
    //   this.artistNumber = f.artistId;
    //   let selectedArtistOption = null;
    //   selectedArtistOption = this.allTrackArtist ? this.allTrackArtist.find(i => i.id == f.artistId):null;
    //   this.selectedArtist = selectedArtistOption;
    //   this.filteredArtistOptions.next(this.allTrackArtist);
    //   this.loading = false;
    //   this._cdr.detectChanges();
    //   this.loading = true;
    //   // this._artistService.getAllArtistsForFilter(f.artistId ? {artist:f.artistId}:{composer:f.composerId} )
    //   //   .subscribe((artists:FilterableArtist[]) => {
    //   //     if (artists.length <= 0)  {
    //   //       this.loading = false;
    //   //       return;
    //   //     }
    //   //     let a = artists[0]
    //   //     this.selectedArtist = {id:a.id, name:a.name, type:a.type};
    //   //     this.filteredArtistOptions.next([{id:a.id, name:selectedArtistOption.name, type:a.type}]);
    //   //     this.loading = false;
    //   //     this._cdr.detectChanges();
    //   //   }, (error) => {
    //   //     console.log(error);
    //   //     //alert("An error ocurred while getting the artists for filtering. Check your connections and try again");
    //   //   });
    // }

    // if (!f.genreId && this.selectedGenre) {
    //   this.selectedGenre = null;
    // } else if (f.genreId && (!this.selectedGenre || this.selectedGenre.id != f.genreId)) {
    //   if (this._allGenres && this._allGenres.length > 0) {
    //     this.selectedGenre = this._allGenres?.find(g => g.id == f.genreId);
    //     this.gnereNumber = f.genreId;
    //   } else {
    //     this._genreService.getGenre(f.genreId).subscribe(g => {
    //       this.selectedGenre = g;
    //     }, (error) => {
    //       console.log(error);
    //       //alert("An error ocurred while getting the genre. Check your connections and try again");
    //     })
    //   }
    // }
    // let moodList: any = []
    // if (!f.moodId && this.selectedMood) {
    //   this.selectedMood = null;
    // } else if ((f.moodId !== false || f.moodId.length > 0) && (!this.selectedMood)) {
    //   if (f.moodId.length > 0 && f.moodId !== undefined) {
    //     if (f.moodId.length === 1) {
    //       moodList = f.moodId
    //     }
    //     else {
    //       moodList = f.moodId.split(",");
    //     }
    //     if (this._allMood && this._allMood.length > 0) {
    //       if (moodList.length >= 1) {
    //         for (let moodIndex = 0; moodIndex < moodList.length; moodIndex++) {
    //           if (moodList[moodIndex] !== false) {
    //             this.selectedMood = this._allMood.find(g => g.id == moodList[moodIndex]);
    //           }
    //         }
    //       }
    //     } else {
    //       if (moodList.length >= 1) {
    //         for (let moodIndex = 0; moodIndex < moodList.length; moodIndex++) {
    //           if (moodList[moodIndex] !== false) {
    //             this._moodService.getMood(moodList[moodIndex]).subscribe(m => {
    //               this.selectedMood = m;
    //             },
    //               (error) => {
    //                 console.log(error);
    //                 //alert("An error ocurred while getting the mood. Check your connections and try again");
    //               });
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    this.statusnumber = f.versionStatus ? f.versionStatus : false
    this.artistNumber = f.artistId ? f.artistId : false
    this.gnereNumber = f.genreId ? f.genreId : false
    if (this.gnereNumber) {
      this.getSubgnere(this.gnereNumber)
    }
    this.subgnereNumber = f.sub_genre ? f.sub_genre : false;
    if (f.moodId !== null && f.moodId.length > 0) {
      let data:any = []
      this.currentMoods =[]
      if(typeof (f.moodId) !== 'string'){
      data = f.moodId[0]?.split(',') ? f.moodId[0]?.split(',') : f.moodId
      }
      else{
        data = f.moodId?.split(',') ? f.moodId?.split(',') : f.moodId
      }
      if(this._allMood.length == 0){
        this._moodService.getAllMoods().subscribe(m => {
          this._allMood = m;
          this.filteredMoodOptions.next(m);
          for (let x of this._allMood) {
            if (data.length) {
              for (let y of data) {
                if (x.id === Number(y)) {
                  this.currentMoods.push(x)
                }
              }
            }
          }
        }, (error) => {
          console.log(error);
        })
      }
    else{
      for (let x of this._allMood) {
        if (data.length) {
          for (let y of data) {
            if (x.id === Number(y)) {
              this.currentMoods.push(x)
            }
          }
        }
      }
    }
   
    }
    else {
      this.currentMoods = []
    }
    if (f.tempo) {
      if (this.list.tempo.length == 0) {
        this.list.getTempo();
        for (let x of this.list.tempo) {
          if (x === f.tempo) {
            this.tempoNumber = f.tempo
          }
        }
      }
      else {
        this.tempoNumber = f.tempo ? f.tempo : false
      }
    }
    else {
      this.tempoNumber = false
    }

    this.bpm = f.bpm ? f.bpm : ''
    this.versionNumber = f.version ? f.version : false
    // this.Instrumentalsflag = f.is_instrumental ? f.is_instrumental : true
    // this.ExclusiveTracksflag = f.isExclusive ? f.isExclusive : true
    this.subgnereNumber = f.sub_genre ? f.sub_genre : false
    //this.Vocalsflag = f.is_vocals ? f.is_vocals : false
    this.selectedArtsit = f.artistType ? f.artistType : false
    this.moodFilterCtrl.valueChanges
      .pipe(
        startWith(""),
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.searchingMoods = true;
        }),
        switchMap((v) => {
          if (!v) {
            return of(this._allMood);
          }
          return of(
            this._allMood.filter((g) =>
              g.name.trim().toLowerCase().includes(v.toLowerCase())
            )
          );
        }),
        tap(() => {
          this.searchingMoods = false;
        })
      )
      .subscribe(
        (g) => {
          this.filteredMoodOptions.next(g);
        },
        (error) => {
          console.log(error);
          //alert("An error ocurred while getting the filtered genres. Check your connections and try again");
        }
      )
    this.artistFilterCtrl.valueChanges
      .pipe(
        startWith(""),
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.searchingArtist = true;
        }),
        switchMap((v) => {
          if (!v) {
            return of(this.allTrackArtist);
          }
          return of(
            this.allTrackArtist.filter((g) =>
              g.name.trim().toLowerCase().includes(v.toLowerCase())
            )
          );
        }),
        tap(() => {
          this.searchingArtist = false;
        })
      )
      .subscribe(
        (g) => {
          this.filteredArtistOptions.next(g);
        },
        (error) => {
          console.log(error);
          //alert("An error ocurred while getting the filtered genres. Check your connections and try again");
        }
      )
    this.genreFilterCtrl.valueChanges
      .pipe(
        startWith(""),
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.searchingGenre = true;
        }),
        switchMap((v) => {
          if (!v) {
            return of(this._allGenres);
          }
          return of(
            this._allGenres.filter((g) =>
              g.name.trim().toLowerCase().includes(v.toLowerCase())
            )
          );
        }),
        tap(() => {
          this.searchingGenre = false;
        })
      )
      .subscribe(
        (g) => {
          this.filteredGenreOptions.next(g);
        },
        (error) => {
          console.log(error);
        }
      )
    if (this.subGnereArr.length > 0 && this.subgnereNumber) {
      this.subGenreFilterCtrl.valueChanges
        .pipe(
          startWith(""),
          debounceTime(150),
          distinctUntilChanged(),
          tap(() => {
            this.searchingSubgenre = true;
          }),
          switchMap((v) => {
            if (!v) {
              return of(this.subGnereArr);
            }
            return of(
              this.subGnereArr.filter((g) =>
                g.name.trim().toLowerCase().includes(v.toLowerCase())
              )
            );
          }),
          tap(() => {
            this.searchingSubgenre = false;
          })
        )
        .subscribe(
          (g) => {
            this.filteredSubGenreOptions.next(g);
          },
          (error) => {
            console.log(error);
          }
        )
    }
  }
  get filters(): Filters {
    return this._filters;
  }
  get tempo(): any | false {
    if (!this.filters.tempo) return false;
    return this.filters.tempo;
  }

  @Output()
  filtersChange = new EventEmitter<Filters>();

  @Output()
  clearClick = new EventEmitter<void>();

  get selectedArtist(): FilterableArtist | false {
    if (!this._selectedArtist) {
      return false;
    }
    return this._selectedArtist;
  }
  set selectedArtist(a: FilterableArtist | false) {
    this._selectedArtist = a ? a : null;
    this.filters = {
      versionStatus: this.statusnumber,
      artistId: this.artistNumber,
      genreId: this.gnereNumber,
      moodId: this.currentMoods,
      tempo: this.tempoNumber,
      bpm: this.bpm,
      version: this.versionNumber,
      is_instrumental: this.Instrumentalsflag,
      isExclusive: this.ExclusiveTracksflag,
      sub_genre: this.subgnereNumber,
      is_vocals: this.Vocalsflag,
      composerId: null,
      duration: null,
      artistType: this.selectedArtsit,
      clear: false

    };
    // this.filtersChange.emit(this.filters);
  }

  get genre(): Genre | false {
    if (this.selectedGenre === false) return false;
    return this.selectedGenre && this.selectedGenre.parent ? this.selectedGenre.parent : this.selectedGenre;
  }
  get subGenre(): Genre | false {
    if (this.selectedGenre === false) return false;
    return this.selectedGenre && this.selectedGenre.parent ? this.selectedGenre : false;
  }
  get selectedGenre(): Genre | false {
    if (!this._selectedGenre) return false;
    return this._selectedGenre;
  }
  set selectedGenre(g: Genre | false) {
    this._selectedGenre = g ? g : null;
    if (g !== false && g && !g.parent) {
      this.filteredSubGenreOptions.next(this._allGenres?.filter((genre) => !!genre.parent && genre.parent.id == g.id));
    }
    this.filters = {
      versionStatus: this.statusnumber,
      artistId: this.artistNumber,
      genreId: this.gnereNumber,
      moodId: this.currentMoods,
      tempo: this.tempoNumber,
      bpm: this.bpm,
      version: this.versionNumber,
      is_instrumental: this.Instrumentalsflag,
      isExclusive: this.ExclusiveTracksflag,
      sub_genre: this.subgnereNumber,
      is_vocals: this.Vocalsflag,
      composerId: null,
      duration: null,
      artistType: this.selectedArtsit,
      clear: false

    };
    //  this.filtersChange.emit(this.filters);
  }

  get selectedMood(): Mood | false {
    if (!this._selectedMood) return false;
    return this._selectedMood;
  }
  set selectedMood(m: Mood | false) {
    let array;
    if (this.currentMoods.length === 0) {
      array = false;
    }
    else {
      if (this.currentMoods.length > 0) {
        array = ""
        for (let i = 0; i < this.currentMoods.length; i++) {
          array += (this.currentMoods[i].id).toString() + ","
        }
        array = array.slice(0, -1)
        this.moodNumber = array.slice(0, -1)
      }
    }
    this._selectedMood = array ? array : null;

    this.filters = {
      versionStatus: this.statusnumber,
      artistId: this.artistNumber,
      genreId: this.gnereNumber,
      moodId: array,
      tempo: this.tempoNumber,
      bpm: this.bpm,
      version: this.versionNumber,
      is_instrumental: this.Instrumentalsflag,
      isExclusive: this.ExclusiveTracksflag,
      sub_genre: this.subgnereNumber,
      is_vocals: this.Vocalsflag,
      composerId: null,
      duration: null,
      artistType: this.selectedArtsit,
      clear: false
    };
    //  this.filtersChange.emit(this.filters);
  }

  get instrumental(): boolean {
    return this._instrumental
  }
  set instrumental(i: boolean) {
    this._instrumental = i;
    // if (this._instrumental && this._vocals || (!this._instrumental && !this._vocals)) {
    //   this.instrumentalChanged(null);
    // } else if (this._instrumental) {
    //   this.instrumentalChanged(true);
    // } else if (this._vocals) {
    //   this.instrumentalChanged(false);
    // }
  }

  get vocals(): boolean {
    return this._vocals;
  }
  set vocals(v: boolean) {
    this._vocals = v;
    // if (this._instrumental && this._vocals || (!this._instrumental && !this._vocals)) {
    //   this.instrumentalChanged(null);
    // } else if (this._vocals) {
    //   this.instrumentalChanged(false);
    // } else if (this._instrumental) {
    //   this.instrumentalChanged(true);
    // }
  }

  statuses: TrackStatusValue[] = [
    new TrackStatusValue({ value: 'PENDING', color: '#e3b950', name: 'Pending' }),
    new TrackStatusValue({ value: 'PENDING_APPROVAL', color: '#47cdff', name: 'Pending Approval' }),
    new TrackStatusValue({ value: 'APPROVED', color: '#50e3c2', name: 'Approved' }),
    new TrackStatusValue({ value: 'DENIED', color: '#d73939', name: 'Denied' }),
    new TrackStatusValue({ value: 'DISABLED', color: '#8a8a8d', name: 'Disabled' })
  ];
  selectedStatus: any;

  private _filters: Filters;
  private _selectedArtist: FilterableArtist;
  private _selectedGenre: Genre;
  private _selectedMood: Mood;
  private _allGenres: Genre[];
  private _allMood: Mood[] =[];
  private _instrumental: boolean = false;
  private _vocals: boolean = false;
  baseTempoOptions: any = [];
  baseVersions;
  private _subscriptions: Subscription[] = [];

  constructor(
    private _artistService: ArtistService,
    private _genreService: GenreService,
    private _moodService: MoodService,
    private _cdr: ChangeDetectorRef,
    public api: UserMenuService,public list: MenuListService, 
  ) { 
    this.getArtist();
    if (this.filters === undefined && !this.filters?.tempo) {
      this.list.getTempo();
    }
  }

  ngOnInit() {
    this.filteredTempoOptions = this.list.filteredTempoOptions
    // this.getArtist()
    // this._subscriptions.push(this.artistFilterCtrl.valueChanges.pipe(
    //   startWith(''),
    //   debounceTime(150),
    //   distinctUntilChanged(),
    //   tap(() => {
    //     this.searchingArtist = true;
    //   }),
    //   switchMap((v) => {
    //     if (!v || v.length <= 2) {
    //       if (this.selectedArtist) {
    //         return of([this.selectedArtist]);
    //       } else {
    //         return of([]);
    //       }
    //     }
    //     return this._artistService.getAllArtistsForFilter({search:v}).pipe(
    //       map(a => {

    //         let contains_selected = a.findIndex(artist => {
    //           return !this.selectedArtist || artist.id == this.selectedArtist.id;
    //         }) != -1;
    //         if (!contains_selected && this.selectedArtist) {
    //           a.push(this.selectedArtist);
    //         }
    //         return a;
    //       })
    //     );
    //   }),
    //   tap(() => {
    //     this.searchingArtist = false;
    //   })
    // ).subscribe(a => { 
    //   this.filteredArtistOptions.next(a);
    // }));
    this._subscriptions.push(this._genreService.getAllGenres().subscribe(genres => {
      this._allGenres = genres;
      this.filteredGenreOptions.next(genres.filter(g => !g.parent));
      if (this.selectedGenre) {
        let id = this.selectedGenre.parent ? this.selectedGenre.parent.id : this.selectedGenre.id;
        this.filteredSubGenreOptions.next(genres.filter(g => !!g.parent && g.parent.id == id))
      }
    }, (error) => {
      console.log(error);
      //alert("An error ocurred while getting the genres for filtering. Check your connections and try again");
    }));
    this._subscriptions.push(this._moodService.getAllMoods().subscribe(m => {
      this._allMood = m;
      this.filteredMoodOptions.next(m);
    }, (error) => {
      console.log(error);
      //alert("An error ocurred while getting the moods for filtering. Check your connections and try again");
    }))
    // this.baseTempoOptions = [
    //   {value: '160:', label: 'Very Fast: 160-400 bpm'},
    //   {value: '140:159', label: 'Fast: 140-159 bpm'},
    //   {value: '120:139', label: 'Lively: 120-139 bpm'},
    //   {value: '100:119', label: 'Moderate: 100-119 bpm'},
    //   {value: '60:99', label: 'Slow: 60-99 bpm'},
    //   {value: ':59', label: 'Very Slow: 0-59 bpm'}
    // ];
    this._subscriptions.push(this.tempoFilterCtrl.valueChanges.pipe(
      startWith(''),
      debounceTime(150),
      distinctUntilChanged(),
      map((v) => {
        let addedTempoOptions: SelectString[] = [];
        if (this.filters.tempo) {
          let tempoStr = (this.filters.tempo);
          if (this.baseTempoOptions.findIndex(o => o.value == tempoStr) == -1) {
            addedTempoOptions.push({ value: tempoStr, label: `${this.filters.tempo.min} bpm` });
          }
        }
        if (!!v && !isNaN(parseInt(v))) {
          addedTempoOptions.push({ value: v + ':' + `${parseInt(v) + 1}`, label: `${v} bpm` });
        }
        return addedTempoOptions.concat(this.baseTempoOptions);
      })
    ).subscribe(o => {
      this.filteredTempoOptions.next(o);
      this.filterTempos();
    }));

    // this._subscriptions.push(this.artistFilterCtrl.valueChanges.pipe(
    //   startWith(''),
    //   debounceTime(150),
    //   distinctUntilChanged(),
    //   map((v) => {
    //     let addedArtist: FilterableArtist[] = [];
    //     if (this.filters.artistId && v.trim() !== '') {
    //       let selectedArtistOption = null;
    //       selectedArtistOption = this.allTrackArtist.find(i => i.id == this.filters.artistId);
    //       let artistStr = this.filters.artistId;
    //       if (this.allTrackArtist.findIndex(o => o.value === artistStr) == -1) {
    //         addedArtist.push({ id: artistStr, name: selectedArtistOption.name, type: selectedArtistOption.type });
    //       }
    //     }
    //     return addedArtist.concat(this.allTrackArtist);
    //   })
    // ).subscribe(o => {
    //   this.filteredArtistOptions.next(o);
    //   this.filterArtists();
    //   //this.filters.artistId=0
    // }));

    this.baseVersions = [
      // {value: '30', label: '30'},
      // {value: 'Bumper', label: 'Bumper'},
      // {value: 'Narrative', label: 'Narrative'},
      // {value: 'Sting', label: 'Sting'},
      // {value: '30 Vox', label: '30 Vox'},
      // {value: 'Bumper Vox', label: 'Bumper Vox'},
      // {value: 'Sting Vox', label: 'Sting Vox'}
      { value: 'Narrative', label: 'Narrative' },
      { value: '30', label: '30 Sec' },
      { value: 'Bumper', label: 'Bumper' },
      { value: 'Sting', label: 'Sting' },
      { value: 'Vox', label: 'Vox' },
      { value: 'Bed', label: 'Bed' },
      { value: 'Dnb', label: 'Drum & Bass (Dnb)' },
    ];
    this._subscriptions.push(this.versionFilterCtrl.valueChanges.pipe(
      startWith(''),
      debounceTime(150),
      distinctUntilChanged(),
      map((v) => {
        let addedVersionOptions: any[] = [];
        if (this.filters.version) {
          if (this.baseVersions.findIndex(o => o.value == this.filters.version) == -1) {
            addedVersionOptions.push({ value: this.filters.version, label: `${this.filters.version}` });
          }
        }
        if (!!v && !isNaN(parseInt(v))) {
          addedVersionOptions.push({ value: v, label: `${v}` });
        }
        return addedVersionOptions.concat(this.baseVersions);
      })
    ).subscribe(o => {
      this.filteredVersionOptions.next(o);
      this.filterVersions();
    }));
    if (this.filters.is_instrumental !== null) {
      if (this.filters.is_instrumental) {
        this.instrumental = true;
      } else {
        this.vocals = true;
      }
    }
    if (this.filters.is_vocals !== null) {
      if (this.filters.is_vocals) {
        this.vocals = true;
      } else {
        this.instrumental = true;
      }
    }
  }

  clearFilter() {
    this.clearBtnFlag = false;
    this.clearClick.emit();

    this.isSelected = false;
    this.vocals = false;
    this.instrumental = false;
    this.statusnumber = false

    this.artistNumber = false,
      this.gnereNumber = false,
      this.moodNumber = false,
      this.tempoNumber = false,
      this.versionNumber = false,
      this.bpm = ''
      this.currentMoods = []
    this.selectedArtsit = 'None'
    // this.Instrumentalsflag= false,
    // this.ExclusiveTracksflag= false,
    this.subgnereNumber = false,
      //this.Vocalsflag= false,
      this.filters = {
        versionStatus: this.statusnumber,
        artistId: this.artistNumber,
        genreId: this.gnereNumber,
        moodId: this.currentMoods,
        tempo: this.tempoNumber,
        bpm: this.bpm,
        version: this.versionNumber,
        is_instrumental: this.Instrumentalsflag,
        isExclusive: this.ExclusiveTracksflag,
        sub_genre: this.subgnereNumber,
        is_vocals: this.Vocalsflag,
        composerId: null,
        duration: null,
        artistType: 'None',
        clear: true
      };
    this.filteredSubGenreOptions.next([]);
    this.filtersChange.emit(this.filters);
  }

  statusChanged(status: number[] | false) {
    this.selectedStatus = status;
    let array;
    if (this.currentMoods.length === 0) {
      array = false;
    }
    else {
      if (this.currentMoods.length > 0) {
        array = ""
        for (let i = 0; i < this.currentMoods.length; i++) {
          array += (this.currentMoods[i].id).toString() + ","
        }
        array = array.slice(0, -1)
        this.moodNumber = array
      }
    }
    let filters = {
      versionStatus: this.statusnumber,
      artistId: this.artistNumber,
      genreId: this.gnereNumber,
      moodId:  this.moodNumber,
      tempo: this.tempoNumber,
      bpm: this.bpm,
      version: this.versionNumber,
      is_instrumental: this.Instrumentalsflag,
      isExclusive: this.ExclusiveTracksflag,
      sub_genre: this.subgnereNumber,
      is_vocals: this.Vocalsflag,
      composerId: null,
      duration: null,
      artistType: this.selectedArtsit,
      clear: false
    };
    this.filtersChange.emit(filters);
  }
  genreChanged(genre: Genre | false) {
    this.selectedGenre = genre;
  }
  tempoChanged(tempo: string | false) {
    let array;
    if (this.currentMoods.length === 0) {
      array = false;
    }
    else {
      if (this.currentMoods.length > 0) {
        array = ""
        for (let i = 0; i < this.currentMoods.length; i++) {
          array += (this.currentMoods[i].id).toString() + ","
        }
        array = array.slice(0, -1)
        this.moodNumber = array
      }
    }
    let filters = {
      versionStatus: this.statusnumber,
      artistId: this.artistNumber,
      genreId: this.gnereNumber,
      moodId: this.moodNumber,
      tempo: this.tempoNumber,
      bpm: this.bpm,
      version: this.versionNumber,
      is_instrumental: this.Instrumentalsflag,
      isExclusive: this.ExclusiveTracksflag,
      sub_genre: this.subgnereNumber,
      is_vocals: this.Vocalsflag,
      composerId: null,
      duration: null,
      artistType: this.selectedArtsit,
      clear: false
    };
    this.filtersChange.emit(filters);
  }
  filterTempos() {
    if (!this.tempoFilterCtrl.value) {
      return;
    }
    let search = this.tempoFilterCtrl.value;
    if (!search) {
      this.filteredTempoOptions.next(this.baseTempoOptions.value.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredTempoOptions.next(
      this.baseTempoOptions.filter(tempo => tempo.tempo_name.toLowerCase().indexOf(search) > -1)
    );
  }

  filterArtists() {
    if (!this.artistFilterCtrl.value) {
      return;
    }
    let search = this.artistFilterCtrl.value;
    if (!search) {
      this.filteredArtistOptions.next(this.allTrackArtist.value.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredArtistOptions.next(
      this.allTrackArtist.filter(artist => artist.name.toLowerCase().indexOf(search) > -1)
    );
  }

  versionChanged(version: string | false) {
    let array;
    if (this.currentMoods.length === 0) {
      array = false;
    }
    else {
      if (this.currentMoods.length > 0) {
        array = ""
        for (let i = 0; i < this.currentMoods.length; i++) {
          array += (this.currentMoods[i].id).toString() + ","
        }
        array = array.slice(0, -1)
        this.moodNumber = array
      }
    }
    let filters = {
      versionStatus: this.statusnumber,
      artistId: this.artistNumber,
      genreId: this.gnereNumber,
      moodId: this.moodNumber,
      tempo: this.tempoNumber,
      bpm: this.bpm,
      version: this.versionNumber,
      is_instrumental: this.Instrumentalsflag,
      isExclusive: this.ExclusiveTracksflag,
      sub_genre: this.subgnereNumber,
      is_vocals: this.Vocalsflag,
      composerId: null,
      duration: null,
      artistType: this.selectedArtsit,
      clear: false

    };
    this.filtersChange.emit(filters);
  }
  omitSpclChar(event, type) {
    var k;
    k = event.charCode;  //         k = event.keyCode;
    if (type == "version") {
      return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
    } else {
      if (k != 58 && k != 45) {
        return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
      }
    }
  }
  filterVersions() {
    if (!this.versionFilterCtrl.value) {
      return;
    }
    let search = this.versionFilterCtrl.value;
    if (!search) {
      this.filteredVersionOptions.next(this.baseVersions.value.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredVersionOptions.next(
      this.baseVersions.filter(version => version.label.toLowerCase().indexOf(search) > -1)
    );
  }

  changeValue(value) {
    this.ExclusiveTracksflag = !value;
  }

  mibeOwnedChanged(){
    this.MibeOwnedflag = !this.MibeOwnedflag;
  }

  instrumentalChanged(instrumental: boolean) {
    let array;
    if (this.currentMoods.length === 0) {
      array = false;
    }
    else {
      if (this.currentMoods.length > 0) {
        array = ""
        for (let i = 0; i < this.currentMoods.length; i++) {
          array += (this.currentMoods[i].id).toString() + ","
        }
        array = array.slice(0, -1)
        this.moodNumber = array
      }
    }
    let filters = {
      versionStatus: this.statusnumber,
      artistId: this.artistNumber,
      genreId: this.gnereNumber,
      moodId:  this.moodNumber,
      tempo: this.tempoNumber,
      bpm: this.bpm,
      version: this.versionNumber,
      is_instrumental: this.Instrumentalsflag,
      isExclusive: this.ExclusiveTracksflag,
      sub_genre: this.subgnereNumber,
      is_vocals: this.Vocalsflag,
      composerId: null,
      duration: null,
      artistType: this.selectedArtsit,
      clear: false

    };
    this.filtersChange.emit(filters);
  }

  exclusiveChanged(exclusive: any) {

    //Robert: 5/16/24 - Prevent last saved track from auto playing
    localStorage.setItem('editData','no')

    this.filteredArtistOptions.next(this.allTrackArtist);
    this.searchArtist = '';
    this.searchGenre = null;
    this.searchMood = null;
    this.searchSubgnere = null;
    let array;
    if (this.currentMoods.length === 0) {
      array = false;
    }
    else {
      if (this.currentMoods.length > 0) {
        array = ""
        for (let i = 0; i < this.currentMoods.length; i++) {
          array += (this.currentMoods[i].id).toString() + ","
        }
        array = array.slice(0, -1)
        this.moodNumber = array
      }
    }
    let filters = {
      versionStatus: this.statusnumber,
      artistId: this.artistNumber,
      genreId: this.gnereNumber,
      moodId:  this.moodNumber,
      tempo: this.tempoNumber,
      bpm: this.bpm,
      version: this.versionNumber,
      is_instrumental: this.Instrumentalsflag,
      isExclusive: this.ExclusiveTracksflag,
      is_mibe_owned: this.MibeOwnedflag,
      sub_genre: this.subgnereNumber,
      is_vocals: this.Vocalsflag,
      composerId: null,
      duration: null,
      artistType: this.selectedArtsit,
      clear: false
    };
    if (this.gnereNumber == false) {
      this.filteredSubGenreOptions.next([]);
    }
    this.filtersChange.emit(filters);
    this._cdr.detectChanges();
  }

  typeChanged(event: any = '') {
    let array;
    if (this.currentMoods.length === 0) {
      array = false;
    }
    else {
      if (this.currentMoods.length > 0) {
        array = ""
        for (let i = 0; i < this.currentMoods.length; i++) {
          array += (this.currentMoods[i].id).toString() + ","
        }
        array = array.slice(0, -1)
        this.moodNumber = array
      }
    }
   let filters = {
      versionStatus: this.statusnumber,
      artistId: this.artistNumber,
      genreId: this.gnereNumber,
      moodId:  this.moodNumber,
      tempo: this.tempoNumber,
      bpm: this.bpm,
      version: this.versionNumber,
      is_instrumental: this.Instrumentalsflag,
      isExclusive: this.ExclusiveTracksflag,
      sub_genre: this.subgnereNumber,
      is_vocals: this.Vocalsflag,
      composerId: null,
      duration: null,
      artistType: this.selectedArtsit,
      clear: false
    };
    if (this.gnereNumber == false) {
      this.filteredSubGenreOptions.next([]);
    }
    this.filtersChange.emit(filters);
    this._cdr.detectChanges();
  }
  data(value) {
    var pattern = /^[0-9]*$/;
    if (!pattern.test(value)) {
      value = value.slice(0, -1);
      this.bpm = value
    }
  }
  moodChanged(exclusive: any) {
    console.log(exclusive)
    console.log(this.currentMoods);
    let array;
    if (this.currentMoods.length === 0) {
      array = false;
    }
    else {
      if (this.currentMoods.length > 0) {
        array = ""
        for (let i = 0; i < this.currentMoods.length; i++) {
          array += (this.currentMoods[i].id).toString() + ","
        }
        array = array.slice(0, -1)
        this.moodNumber = array.slice(0, -1)
      }
    }
    console.log(array.toString())

    // if(this.moodNumber.length > 1){
    //   this.isSelected = false;
    //   this.moodNumber = (this.moodNumber.slice(1)).toString()
    // }
    // else if((this.moodNumber.length === 1 && this.moodNumber[0] === false) ||  this.moodNumber === false ){
    //   this.isSelected = true;
    // }
    // else if(this.moodNumber[0] !== false &&  this.moodNumber !== false){
    //   this.moodNumber = (this.moodNumber).toString()
    //   this.isSelected = false;
    // }
    if (this.moodNumber === false || (this.currentMoods.length === 1 && this.moodNumber[0] === false)) {
      this.moodNumber = this.moodNumber;
    }
    else if (this.moodNumber.length > 1) {
      if (this.moodNumber[0] === false) {
        this.moodNumber = (this.moodNumber.slice(1)).toString()
      }
      else {
        this.moodNumber = (this.moodNumber).toString()
      }
    }
   let filters = {
      versionStatus: this.statusnumber,
      artistId: this.artistNumber,
      genreId: this.gnereNumber,
      moodId: array,
      tempo: this.tempoNumber,
      bpm: this.bpm,
      version: this.versionNumber,
      is_instrumental: this.Instrumentalsflag,
      isExclusive: this.ExclusiveTracksflag,
      sub_genre: this.subgnereNumber,
      is_vocals: this.Vocalsflag,
      composerId: null,
      duration: null,
      artistType: this.selectedArtsit,
      clear: false
    };
    if (this.gnereNumber == false) {
      this.filteredSubGenreOptions.next([]);
    }
    this.filtersChange.emit(filters);
  }

  artistChanged(exclusive: any) {
    this.artistNumber = exclusive.value
    this.selectedArtsit = 'both'
    this.typeChanged()
  }

  artistChange(exclusive: any) {
    this.artistNumber = exclusive.value
    let array;
    if (this.currentMoods.length === 0) {
      array = false;
    }
    else {
      if (this.currentMoods.length > 0) {
        array = ""
        for (let i = 0; i < this.currentMoods.length; i++) {
          array += (this.currentMoods[i].id).toString() + ","
        }
        array = array.slice(0, -1)
        this.moodNumber = array.slice(0, -1)
      }
    }
    let filters = {
      versionStatus: this.statusnumber,
      artistId: exclusive.value,
      genreId: this.gnereNumber,
      moodId: array,
      tempo: this.tempoNumber,
      bpm: this.bpm,
      version: this.versionNumber,
      is_instrumental: this.Instrumentalsflag,
      isExclusive: this.ExclusiveTracksflag,
      sub_genre: this.subgnereNumber,
      is_vocals: this.Vocalsflag,
      composerId: null,
      duration: null,
      artistType: this.selectedArtsit,
      clear: false
    };
    this.filtersChange.emit(filters);
    console.log(this.filters)
  }
  tempoSearch(exclusive: any) {
    this.tempoNumber = exclusive.value
    let array;
    if (this.currentMoods.length === 0) {
      array = false;
    }
    else {
      if (this.currentMoods.length > 0) {
        array = ""
        for (let i = 0; i < this.currentMoods.length; i++) {
          array += (this.currentMoods[i].id).toString() + ","
        }
        array = array.slice(0, -1)
        this.moodNumber = array.slice(0, -1)
      }
    }
    let filters = {
      versionStatus: this.statusnumber,
      artistId: this.artistNumber,
      genreId: this.gnereNumber,
      moodId: array,
      tempo: exclusive.value,
      bpm: this.bpm,
      version: this.versionNumber,
      is_instrumental: this.Instrumentalsflag,
      isExclusive: this.ExclusiveTracksflag,
      sub_genre: this.subgnereNumber,
      is_vocals: this.Vocalsflag,
      composerId: null,
      duration: null,
      artistType: this.selectedArtsit,
      clear: false
    };

    this.filtersChange.emit(filters);
  }
  versionSearch(exclusive: any) {
    this.versionNumber = exclusive.value
    let array;
    if (this.currentMoods.length === 0) {
      array = false;
    }
    else {
      if (this.currentMoods.length > 0) {
        array = ""
        for (let i = 0; i < this.currentMoods.length; i++) {
          array += (this.currentMoods[i].id).toString() + ","
        }
        array = array.slice(0, -1)
        this.moodNumber = array.slice(0, -1)
      }
    }
    let filters = {
      versionStatus: this.statusnumber,
      artistId: this.artistNumber,
      genreId: this.gnereNumber,
      moodId: array,
      tempo: this.tempoNumber,
      bpm: this.bpm,
      version: exclusive.value,
      is_instrumental: this.Instrumentalsflag,
      isExclusive: this.ExclusiveTracksflag,
      sub_genre: this.subgnereNumber,
      is_vocals: this.Vocalsflag,
      composerId: null,
      duration: null,
      artistType: this.selectedArtsit,
      clear: false
    };
    console.log(this.filters)
    this.filtersChange.emit(filters);
  }

  artistCompareWith(a1: FilterableArtist, a2: FilterableArtist) {
    if (!a1 || !a2) {
      return a1 == a2;
    }
    return a1.id == a2.id;
  }

  genreCompareWith(g1: Genre, g2: Genre) {
    if (!g1 || !g2) {
      return g1 == g2;
    }
    return g1.id == g2.id;
  }

  moodCompareWith(m1: Mood, m2: Mood) {
    if (!m1 || !m2) {
      return m1 == m2;
    }
    return m1.id == m2.id;
  }

  selectStringCompareWith(o1: SelectString, o2: SelectString) {
    if (!o1 || !o2) {
      return o1 == o2;
    }
    return o1.value == o2.value;
  }

  artistsTrackBy(i: number, artist: FilterableArtist) {
    if (!artist) {
      return artist;
    }
    return i + '-' + artist.id + '-' + artist.type;
  }

  genreTrackBy(i: number, g: Genre) {
    if (!g) {
      return g;
    }
    return i + '-' + g.id;
  }

  moodTrackBy(i: number, m: Mood) {
    if (!m) {
      return m;
    }
    return i + '-' + m.id;
  }

  selectStringTrackBy(i: number, s: SelectString) {
    if (!s) {
      return s;
    }
    return s.value + s.label;
  }


  subGnereArr:any = [];
getSubgnere(e){
  let requestUrl = environment.apiURL + `/api/v1/track/${e}/sub-genre-dropdown/`
  this.api.doGET(requestUrl).subscribe(x =>{
    let arr;
    arr = x;
    this.subGnereArr = x
    this.filteredSubGenreOptions.next(arr);
  })
}
allTrackArtist: any=[];
getArtist(){
  let requestUrl = environment.apiURL + `/api/v1/track/music-artist-dropdown/`
  this.api.doGET(requestUrl).subscribe(x =>{
    let arr;
    arr = x;
    this.allTrackArtist = x;
    this.filteredArtistOptions.next(arr);
  })
}
// all
// getTempo(){
//   let requestUrl = environment.apiURL + `/api/v1/track/tempo-dropdown/`
//   this.api.doGET(requestUrl).subscribe(x =>{
//     let arr;
//     arr = x;
//     this.subGnereArr = x
//     this.baseTempoOptions = x;
//     this.filteredTempoOptions.next(arr);
    
//   })
// }

  onSearchGenre(searcVal: any) {
    let search = searcVal;

    if (!searcVal) {
      this.filteredGenreOptions.next(
        this._allGenres
      );
      this.subGnereArr =[]
      this.filteredSubGenreOptions.next([])
      return;
    }
    else {
      search = search.toLowerCase();
    }

    this.filteredGenreOptions.next(
      this._allGenres.filter(item => item.name.toLowerCase().indexOf(search) > -1)
    );
  }

  onSearchSubGenre(searcVal: any) {
    let search = searcVal;

    if (!searcVal) {
      this.filteredSubGenreOptions.next(
        this.subGnereArr
      );
      return;
    }
    else {
      search = search.toLowerCase();
    }
    +
      this.filteredSubGenreOptions.next(
        this.subGnereArr.filter(item => item.name.toLowerCase().indexOf(search) > -1)
      );
  }

  onSearchMood(searcVal: any) {
    let search = searcVal;
 //   this.searchMood = this.currentMoods
let data = this.currentMoods
console.log(data)
    if (!searcVal) {
      this.filteredMoodOptions.next(
        this._allMood
      );
      for (let x of this._allMood) {
        if (data.length) {
          for (let y of data) {
            if (x.id === Number(y)) {
              this.currentMoods.push(x)
            }
          }
        }
      }
      return;
    }
    else {
      search = search.toLowerCase();
    }

    this.filteredMoodOptions.next(
      this._allMood.filter(item => item.name.toLowerCase().indexOf(search) > -1))
      for (let x of this._allMood) {
        if (data.length) {
          for (let y of data) {
            if (x.id === Number(y)) {
              this.currentMoods.push(x)
            }
          }
        }
      }
    
  }
}
