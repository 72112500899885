import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio/radio';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { MenuListService } from 'src/app/shared/services/menu-list.service';
import { UserMenuService } from 'src/app/shared/services/user-menu.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-support-user-input',
  templateUrl: './support-user-input.component.html',
  styleUrls: ['./support-user-input.component.scss']
})
export class SupportUserInputComponent implements OnInit {

  public mailCheckbox: boolean;
  public type: number = 0;
  public supportInput: string = '';
  public dataList: any;
  public count: number = 0;
  public isValid: boolean = false;
  public supportDownload: boolean = true;
  public supportPlaylist: boolean = true;
  public loadingTracks: boolean = false;

  constructor(private dialogRef: MatDialogRef<SupportUserInputComponent>, public dialog: DialogService, private api: UserMenuService, public list: MenuListService,) { }

  ngOnInit() {
  }

  mailCheckboxActivated(radioButton: MatRadioChange) {
    if (radioButton.value === 0) {
      this.mailCheckbox = false;
    } else {
      this.mailCheckbox = true;
      this.supportDownload = false;
      this.supportPlaylist = false;
    }
    this.supportInput = ''
    this.type = radioButton.value
  
  }
  getsupportData(event) {
    if (this.type === 0) {
      if (event.trim() !== '') {
        let value, valid
        let latest = event.trim().split(',')
        let count = event.trim().split(",").length - 1;
        if (latest.length === 1) {
          value = latest[0]
        }
        else {
          if (latest[latest.length - 1] !== '') {
            value = latest[latest.length - 1]
          }
          else {
            value = latest[latest.length - 2]
          }
        }
        if (value && this.count !== count) {
          valid = this.validateEmail(value, count)
          this.isValid = valid

        }
        console.log(this.isValid)
      }
    }
    else {
      this.isValid = false;
    }
    this.supportInput = event.trim()
  }
  close() {
    this.dialogRef.close();
  }
  preview() {
    this.loadingTracks = true;
    this.list.supportDataList =[]
    let data = {}
    // var lastChar = this.supportInput[this.supportInput.length - 1];
    // if (lastChar === ',') {
    //   this.supportInput = this.supportInput
    // }
    // else {
    //   this.supportInput = this.supportInput + ','
    // }
    if (this.type === 0) {
      data = {
        'email': this.supportInput
      }
    }
    if (this.type === 1) {
      data = {
        'track_title': this.supportInput
      }
    }
    if (this.type === 2) {
      data = {
        'version_title': this.supportInput
      }
    }
    this.api.doPOST(environment.apiURL + '/api/v1/support/page/', data).subscribe(t => {
      this.dataList = t['result']?t['result']:[];
      this.list.supportType = this.type;
      this.list.supportDataList = this.dataList;
      this.list.MissingData = t['missing_data']?t['missing_data']:[]
      this.list.errorMessage = t['error_message']?t['error_message']:''
      this.loadingTracks = false;
      this.dialogRef.close(true);
    },
      (error) => { 
        let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
        this.dialog.UserUploadPopupConfirmPopup(data).subscribe(changed => {
          this.list.supportDataList = [];
          this.list.MissingData = [];
          this.list.supportType = 0;
          this.loadingTracks = false;
          this.dialogRef.close(true);
        });

      })
  }
  validateEmail(email, count) {
    this.count = count;
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }
}
