import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserMenuService {
  public endPoint:string;
  constructor(public _http: HttpClient) { 
   
  }

  /************** Base HTTP Request Methods *****************/

  /**
   * To Get Http Request for REST API
   * @param URL 
   * @param Options  
   */
  doGET(URL?: string, Options?: any) {
    
    return this._http.get(URL, Options);
  }

  /**
   * To Post Http Request for REST API
   * @param URL   // The Endpoint URL
   * @param Body    //Post Input Body
   * @param Options   //Headers or Other Optional Params
   */
  doPOST(URL?: string, Body?: any, Options?: any) {
    
    return this._http.post(URL, Body, Options);
  }

  /**
   * To Update Http Request for REST API
   * @param URL 
   * @param Body 
   * @param Options 
   */
  doPUT(URL?: string, Body?: any, Options?: any) {
   
    return this._http.put(URL, Body, Options);
  }


  /**
   * To Delete Http Request for REST API
   * @param URL 
   * @param Options 
   *  @param Body 
   */
  doDelete(URL?: string, Body?:any, Options?: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: Body
    }
    
    return this._http.delete(URL,options);
  }

  /**
   * If Patch Request for Http Restfull API
   * @param URL 
   * @param Body 
   * @param Options 
   */
  doPatch(URL?: string, Body?: any, Options?: any) {
    
    return this._http.patch(URL, Body, Options);
  }
}
