import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { MenuListService } from '../../shared/services/menu-list.service';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { environment } from '../../../environments/environment';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
  selector: 'app-delete-manage-user-dialogue',
  templateUrl: './delete-manage-user-dialogue.component.html',
  styleUrls: ['./delete-manage-user-dialogue.component.scss']
})
export class DeleteManageUserDialogueComponent implements OnInit {
  userArray:any;
  currentUserGroups:any;
  selectedArray:any;
  selectedArrayList:any;
  selectedArrayOfUsers: any;
  manageUserArray:any[] = [];
  allselectUser:boolean = false;
  loadingUserUpdate:boolean = false;
  userDetails: any;
  selectedUserCount: any = 0;
  searchUsers = "";
  constructor( private dialogRef: MatDialogRef<DeleteManageUserDialogueComponent>,public FormUserGroup: FormBuilder,public list: MenuListService, public api: UserMenuService, public config: MenuConfigService,public dialog: DialogService,) { 
    
    this.list.getAllUser();
    setTimeout(()=>{                           // <<<---using ()=> syntax
      this.list.getAdminUserGroups().subscribe(x=>{
        this.currentUserGroups = x;
        this.updateManageUsers(x)
      })
  }, 500);
    
  }
  ngOnInit(): void {
   /*  this.list.AllusersDetails.forEach(x=>{
      
      x['checked'] = false;
      return x;
    }) */
    /* this.list.getAdminUserGroups().subscribe(x=>{
      if(x){
        this.userDetails = x;
        this.updateManageUsers(this.userDetails)
      }
     
     }) */
     
   
  }
  ngAfterContentChecked(): void{
    
  }

  close() {
    this.dialogRef.close();
  }

  mySelectOpt(id, evt, index){
    if(evt.checked){
      this.selectedArray[index].checked = true;   
    }else{
      this.selectedArray[index].checked = false;  
    }  
    for (var i = 0; i < this.selectedArray.length; i++) {
      if (this.selectedArray[index].checked) {
        this.manageUserArray.push(id);
        this.selectedUserCount = this.manageUserArray.length;
        if(this.manageUserArray.length==this.selectedArray.length){
          this.allselectUser = true;
        } 
        break
      }else{
        this.manageUserArray = this.manageUserArray.filter(x => x !== id);
        this.selectedUserCount = this.manageUserArray.length;
        this.allselectUser = false;
        break
      }
    }
  }
  deselectAll(){  
    this.selectedUserCount = 0
    for(let x of this.selectedArray){
      x.checked = false;
      this.allselectUser = false;
      this.manageUserArray=[];
    }
  }
  selectAll(evt){
    if(evt.checked){
      for(let x of this.selectedArray){
        x.checked = true;        
        this.allselectUser = true;
        if(!this.manageUserArray.includes(x.id)){
          this.manageUserArray.push(x.id);
          this.selectedUserCount = this.manageUserArray.length;
        }
      }
    }else{
      for(let x of this.selectedArray){
        x.checked = false;
        this.allselectUser = false;
        this.manageUserArray=[];
        this.selectedUserCount = 0
      }
    }
    
  }
  updateUser(user){
    let postUserBody = {
      'users_ids': this.manageUserArray
    }
    let requestURL = environment.apiURL + this.config.manageUser + this.currentUserGroups + '/update-group-user/'
    requestURL = requestURL.replace('{group_id}', user)
    this.api.doPUT(requestURL, postUserBody).subscribe((result: any) => {
      this.close();
      this.list.singleUserForGroup(user);
      this.loadingUserUpdate = false;
    },
      (error) => {
        this.loadingUserUpdate = false;
        let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
        this.dialog.UserUploadPopupConfirmPopup(data).subscribe(changed => {
        });
        this.close();
        //alert(error.message)
      });
  }
  updateManageUsers(id){
    let render1 = false;
    let render2 = false;
  let requestUrl2 = environment.apiURL +  this.config.doGroups + id + '/get-group-user/'
  this.api.doGET(requestUrl2).subscribe(x =>{
    this.selectedArrayOfUsers = x;
    render1 = true;
    if(render1 && render2){
      this.renderArrayList(this.selectedArrayOfUsers,this.selectedArray)
    }
  })
  
  let requestUrl1 = environment.apiURL +  this.config.doGroups + id + '/get-available-group-user/'
 //let requestUrl1 = environment.apiURL + '/api/v1/admin/users/get-staffs/'
  this.api.doGET(requestUrl1).subscribe(x =>{
    this.selectedArray = x;
    render2 = true;
    if(render1 && render2){
      this.renderArrayList(this.selectedArrayOfUsers,this.selectedArray)
    }
  })
  }
  renderArrayList(groupArray,allArray){
    for(let users of allArray){
      for(var idx=0; idx<groupArray.length; idx++){
        if(groupArray[idx].id == users.id){
          users.checked = true;
          this.manageUserArray.push(users.id);
          this.selectedUserCount = this.manageUserArray.length;
        }   
        
      }      
          
      this.selectedArrayList = allArray;
      this.selectedArrayList.forEach(x=>{
        x['name'] = x.first_name+ " "+x.last_name;
        return x;
      })
    }
  }
}
