
<div class="main">
    <div class="sidebar">
        <div class="top-holder">
            <strong>3 Categories</strong>
                <div class="btns-cate">
                    <button (click)="genreTab()" [ngClass]="{'btnActive': tabFlag === '0'}">Genres <p>{{list.AllGenresList.count}}</p></button>
                    <button (click)="moodsTab()" [ngClass]="{'btnActive': tabFlag === '1'}">Moods <p>{{list.allMOODS.count}}</p></button>
                    <button (click)="prosTab()"  [ngClass]="{'btnActive': tabFlag === '2'}">pros <p>{{list.allPROSDetails.count}}</p></button>
                    <button (click)="thesaurusTab()"  [ngClass]="{'btnActive': tabFlag === '3'}">thesaurus <p>{{list.allthesaurusList.count}} Cross References</p></button>

            </div>
        </div>
    </div>
    <div class="user-list">
        <div class="header" *ngIf="tabFlag == '0'">
            <h3>genres  - {{list.AllGenresList.count}}</h3>
            <div class="btns">
                <button class="button" (click)="addGenre()">Add Genre </button>
                
            </div>
        </div>
        <div class="usergroups-info" *ngIf="tabFlag == '0'">
                <app-loader *ngIf="this.list.loadingGnere"></app-loader>
            <mat-table  [dataSource]="list.AllGenres" *ngIf="!this.list.loadingGnere" class="mat-elevation-z8">
              <!-- Position Column -->
              <ng-container matColumnDef="position">
                <mat-header-cell *matHeaderCellDef>  </mat-header-cell>
                <mat-cell *matCellDef="let element" style="text-transform: uppercase;"> {{element?.name}} </mat-cell>
              </ng-container>
          
              <!-- Name Column -->
              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef>  </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element?.sub_genre?.length}} subgenres </mat-cell>
              </ng-container>
          
              <!-- Weight Column -->
              <ng-container matColumnDef="weight">
                <mat-header-cell *matHeaderCellDef>  </mat-header-cell>
                <mat-cell *matCellDef="let element" style="justify-content:flex-end!important;padding-right: 0;
                "> 
                  <button type="button" (click)="EditGenre();list.currGnere = element" class="action"><small>Edit </small></button>
                  <button type="button" (click)="addSubGenre();list.currGnere = element" class="action"><small>Add Subgenre </small></button>
                  <button type="button" (click)="deleteGenre();list.currGnere = element" class="action"><small>Delete </small></button>
                        <button type="button"  class="versions-toggle" class="expand-button expan-btn1">
                            <svg width="10px" height="5px" viewBox="0 0 10 5" class="carrot"
                              >
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                                stroke-linejoin="round">
                                <g transform="translate(-1392.000000, -396.000000)" class="inner-stroke" stroke-width="0.95">
                                  <g transform="translate(392.000000, 351.000000)">
                                    <g transform="translate(920.550000, 36.100000)">
                                      <polyline points="80.75 9.5 84.55 13.3 88.35 9.5"></polyline>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </button>
              </mat-cell>
              </ng-container>
          
              <!-- Expanded Content Column - The detail row is made up of this one column -->
              <ng-container matColumnDef="expandedDetail">
                <mat-cell *matCellDef="let detail" style="display: block;border-top: 1px solid #202027;padding-top:10px;"> 
                  <div >
                  <div class="expand-order expand-margin" *ngFor="let subGnere of detail.element.sub_genre" style="display: inline-block;">
                   <p> <button style="padding:1rem 20px;width:auto;" (click)="EditSubGenre();list.currSubGnere = subGnere">{{subGnere?.name}} </button>
                    <button type="button" class="close-button" (click)="deleteSubGenre();list.currSubGnere = subGnere" >
                      <svg width="10px" height="10px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                          <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                              <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                                  <g id="Genre-Options">
                                      <g id="Close" transform="translate(1439.000000, 32.000000)">
                                          <path d="M24,0 L0,24" id="Path"></path>
                                          <path d="M0,0 L24,24" id="Path"></path>
                                      </g>
                                  </g>
                              </g>
                          </g>
                      </svg>
                      </button></p>
                   
                  </div>
                </div>
                </mat-cell>
              </ng-container>
          
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"
                      matRipple 
                      class="element-row" 
                      [class.expanded]="expandedElement == row" [class.collapsed]="expandedElement == null"
                      (click)="rowClicked(row)"></mat-row>
              <mat-row  class="element-row1"  *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow"
               [@detailExpand]="row.element == expandedElement ? 'expanded' : 'collapsed'"
> 
              </mat-row>
            </mat-table>
            <app-pagination
          *ngIf="genreNumberOfPages > 1 && !list.loadingGnere"
          [page]="currentPage"
          (pageChange)="pageChange($event)"
          [numberOfPages]="genreNumberOfPages"
          ></app-pagination>
        </div>
        
    <!-- thrusurs -->
    <div class="user-list" *ngIf="tabFlag == '3'">
            <div class="header">
                <h3>Thesaurus  - {{list.allthesaurusList.count}}</h3>
                <div class="btns">
                    <button class="button" (click)="addThesaurus()" >Add Thesaurus </button>
                    
                </div>
                
            </div>
            <div class="usergroups-info track-list">
                    <app-loader *ngIf="this.list.thesaurusLoading"></app-loader>
                    <mat-table  *ngIf="!this.list.thesaurusLoading" matSort [dataSource]="list.dataSourceForThesaurus" class="mat-elevation-z8">
              
                            <!-- Position Column -->
                            <ng-container matColumnDef="name">
                              <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
                              <mat-cell *matCellDef="let element" style="font-weight: 700;"> {{element.name}} </mat-cell>
                            </ng-container>
                          
                            <!-- Name Column -->
                            <ng-container matColumnDef="created_at">
                              <mat-header-cell *matHeaderCellDef mat-sort-header> Created At </mat-header-cell>
                              <mat-cell *matCellDef="let element" > {{element.created_at | date: 'M/d/yy'}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="updated_at">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> Updated At </mat-header-cell>
                                <mat-cell *matCellDef="let element" > {{element.updated_at | date: 'M/d/yy'}} </mat-cell>
                                
                              </ng-container>
                          
                            <!-- Weight Column -->
                            <ng-container matColumnDef="action">
                              <mat-header-cell *matHeaderCellDef></mat-header-cell>
                              <mat-cell *matCellDef="let element" style="justify-content:flex-end!important;
                              "> 
                                 <button type="button" (click)="editThesaurus();list.currThesaurus = element" class="action"><small>Edit </small></button> 
                                 <button type="button" (click)="deleteThesaurus();list.currThesaurus = element" class="action"><small>Delete </small></button>
                                </mat-cell>
                            </ng-container>
                          
                            <mat-header-row *matHeaderRowDef="displayedColumns3"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns3;" class="main-row"></mat-row>
                        </mat-table>
              
            
        </div>
         
        <app-pagination
        *ngIf="genreNumberOfPages > 1 && !list.thesaurusLoading"
        [page]="currentPage"
        (pageChange)="pageChangeThesaurus($event)"
        [numberOfPages]="genreNumberOfPages"
        ></app-pagination>
          </div>

<!-- data-pros code strats-->

        <div class="user-list" *ngIf="tabFlag == '2'">
          <div class="header">
              <h3>PROS  - {{list.allPROSDetails.count}}</h3>
              <div class="btns">
                  <button class="button" (click)="addPro()" >Add Pro </button>
                  
              </div>
              
          </div>
          <div class="usergroups-info track-list">
            <mat-table (click)="sortChange(list.dataSourceForPROs.sort)" [dataSource]="list.dataSourceForPROs" matSort class="mat-elevation-z8">
              
              <!-- Position Column -->
              <ng-container matColumnDef="pro">
                <mat-header-cell *matHeaderCellDef mat-sort-header> performing rights organization </mat-header-cell>
                <mat-cell *matCellDef="let element" style="font-weight: 700;text-transform: uppercase;font-size: 10px;"> {{element.pro}} </mat-cell>
              </ng-container>
            
              <!-- Name Column -->
              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header> associated publisher </mat-header-cell>
                <mat-cell *matCellDef="let element" > {{element.publisher.name}} </mat-cell>
              </ng-container>
            
              <!-- Weight Column -->
              <ng-container matColumnDef="weight">
                <mat-header-cell *matHeaderCellDef> </mat-header-cell>
                <mat-cell *matCellDef="let element" style="justify-content:flex-end!important;
                "> 
                   <button type="button" (click)="editPro();list.getSignlePROS(element.id);list.currPros = element" class="action"><small>Edit </small></button> </mat-cell>
              </ng-container>
            
              <mat-header-row *matHeaderRowDef="displayedColumns1"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns1;" class="main-row"></mat-row>
          </mat-table>
            
          
      </div>
      <app-pagination
      *ngIf="genreNumberOfPages > 1 && !list.prosLoading"
      [page]="currentPage"
      (pageChange)="pageChangePROS($event)"
      [numberOfPages]="genreNumberOfPages"
      ></app-pagination>
        </div>
        
<!-- data- moods code  -->
        <div class="user-list" *ngIf="tabFlag == '1'">
          <div class="header">
              <h3>Moods  - {{list.allMOODS.count}}</h3>
              <div class="btns">
                <button class="button1" [ngClass]="{'activeLetter':list.currentLetter==''}" (click)="selectedLet('All')">All</button><span class="m-05">-</span>
                <button class="button1" [ngClass]="{'activeLetter':list.currentLetter==letters, 'greyOut':!list.availableLettersOfMoods.includes(letters)}" *ngFor="let letters of list.lettersOfMoods; let i = index" (click)="selectedLet(letters)">{{letters}}</button>
                  <button (click)="addMoods(); list.moodsType='Add'" class="button" >Add Mood </button>
              </div>
              
          </div>
          <div class="usergroups-info">
              <!-- <mat-table [dataSource]="list.allMOODS.results" class="mat-elevation-z8">
              
                <ng-container matColumnDef="position">
                  <mat-header-cell *matHeaderCellDef></mat-header-cell>
                  <mat-cell *matCellDef="let element"> 
                    <div class="expand-order">
                      <button (click)="EditMoods(); list.moodsType='Edit'; list.moodId = element.id;  list.moodName = element.name">{{element.name}}</button>
                      <button (click)="deleteMoods();list.moodId = element.id;" type="button" class="close-button" >
                        <svg width="10px" height="10px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                                <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                                    <g id="Genre-Options">
                                        <g id="Close" transform="translate(1439.000000, 32.000000)">
                                            <path d="M24,0 L0,24" id="Path"></path>
                                            <path d="M0,0 L24,24" id="Path"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        </button>
                     
                    </div>
                    
                  </mat-cell>
                </ng-container>
              
              
                <mat-header-row *matHeaderRowDef="displayedColumns2"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns2;" class="main-row"></mat-row>
            </mat-table> -->
            <div class="mat-table" class="mat-elevation-z8">
              <div class="mat-row">
                <div class="mat-cell moodItems">
                  <div class="expand-order expand-margin" *ngFor="let items of list.allMOODS.results">                    
                    <p> <button (click)="EditMoods(); list.moodsType='Edit'; list.moodId = items.id;  list.moodName = items.name">{{items.name|titlecase}}</button>
                    <button (click)="deleteMoods();list.moodId = items.id;list.moodName = items.name" type="button" class="close-button" ><svg width="10px" height="10px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                          <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                              <g id="Genre-Options">
                                  <g id="Close" transform="translate(1439.000000, 32.000000)">
                                      <path d="M24,0 L0,24" id="Path"></path>
                                      <path d="M0,0 L24,24" id="Path"></path>
                                  </g>
                              </g>
                          </g>
                      </g>
                  </svg></button></p>
                  </div>
                </div>
              </div>
            </div>
            
          
      </div>
       
        </div>
    </div>
</div>
<!-- <div class="loading-overlay" *ngIf="loading">
  <app-loader></app-loader>
  
</div> -->







