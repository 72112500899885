import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmailTemplate } from '../models/email-template.model';
import { EmailTemplateService } from '../services/email-template.service';
import { environment } from '../../../environments/environment';
import { UserMenuService } from '../services/user-menu.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../services/user.service';
import { DialogService } from '../../shared/services/dialog.service';
import { DuplicatetemplatePopupComponent } from '../duplicatetemplate-popup/duplicatetemplate-popup.component';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { BehaviorSubject, of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { startWith, debounceTime, distinctUntilChanged, tap, switchMap } from 'rxjs/operators';

export interface DialogData {
  template: EmailTemplate;
  isDuplicate: boolean;
  type: string
}

export interface toGroupData {
  created_at: string;
  id: number;
  name: string;
  type: string;
  users: number[];
}

@Component({
  selector: 'app-new-email-template-dialog',
  templateUrl: './new-email-template-dialog.component.html',
  styleUrls: ['./new-email-template-dialog.component.scss']
})
export class NewEmailTemplateDialogComponent implements OnInit {
  // public Editor = ClassicEditor;

  public Editor = DecoupledEditor;

  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }
  //   public onChange( { editor }: ChangeEvent ) {
  //     const data = editor.getData();

  //     // console.log("changed data", data );
  // }
  public editorConfig = {
    placeholder: 'Write a Message',
    highlight: {
      options: [
        {
          model: 'greenMarker',
          class: 'marker-green',
          title: 'Green marker',
          color: 'var(--ck-highlight-marker-green)',
          type: 'marker'
        },
        {
          model: 'redPen',
          class: 'pen-red',
          title: 'Red pen',
          color: 'var(--ck-highlight-pen-red)',
          type: 'pen'
        }
      ]
    },
    fontSize: {
      options: [
        9,
        11,
        13,
        'default',
        17,
        19,
        21,
        28,
        30,
        41
      ]
    },
    toolbar: ['heading', 'fontColor', 'fontFamily', 'fontSize', 'Highlight', '|', 'bold', 'italic', 'Underline', 'strikeThrough', '|', 'alignment', 'bulletedList', 'numberedList', 'blockQuote', 'link'], // ['undo', 'redo', 'link',]
    simpleUpload: {
      // The URL that the images are uploaded to.
      uploadUrl: "http://example.com",

      // Headers sent along with the XMLHttpRequest to the upload server.
      // headers: {
      //   'X-CSRF-TOKEN': 'CSFR-Token',
      //   Authorization: 'Bearer <JSON Web Token>'
      // }
    },
  }

  text: string;
  textarea: HTMLTextAreaElement;
  messageLastScrollHeight;
  loadingUserUpdate: boolean = false;
  emailValidation: boolean = false;
  @ViewChild('commentInput', { static: false }) commentInput: ElementRef;

  ngAfterViewInit() {
    this.textarea = this.commentInput?.nativeElement;
    this.textarea?.addEventListener('input', () => this.adjust());
    this.messageLastScrollHeight = this.textarea?.scrollHeight;
  }

  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  playlistVal: any[] = [];
  template: EmailTemplate;
  loading = false;
  formEmail = "Admin@mibemusic.com"
  error: string;
  disabled = true;
  templatedetails: any;
  valToEmail: any = ''
  // userDetails: any = [{email:"admin@mibemusic.com", id:0},
  // {email:"peteyz@mibemusic.com", id:1},
  // {email:"laura.berger@mibemusic.com", id:2},
  // {email: "Robert.Rudkin@mibemusic.com", id:3},
  // {email: "kyle.lucianin@mibemusic.com", id:4}];
  playlistArray: any = [];
  groupsToDetails: any = [];
  userDetails: any = []
  tempName: any;
  fromMail = "Admin@mibemusic.com";
  getToMailId: any;
  getCCMailId: any;
  getBCCMailId: any;
  groupList: any = [];
  groupMails: any = [];
  errorValue: any = '';
  groupId: any = [];
  toMail: any = [];
  toMailSent: string = ''
  recipientsArrayId: any = [];
  playListArrayNames: any = [];
  copyPlaylist: any = []
  playlistIds: any = [];
  searchFilterFocused = false;
  searchString = ''
  type = ''
  name: any = ''
  NickName = "{{Nickname}}"
  FirstName = "{{Firstname}}"
  tempBody = ''
  saveTemplate = false;
  temptoGroup: any
  tempto: any
  tempcc: any
  tempBcc: any
  tempSub: any
  tempPlay: any
  toGroupCtrl = new FormControl();
  searchingGroup = false;
  groupDetails: BehaviorSubject<toGroupData[] | [] | ArrayBuffer> = new BehaviorSubject<toGroupData[] | [] | ArrayBuffer>([]);

  // Robert: 5/15/24 - tracking search results count to enable "Load More" button
  searchResultsCount = 0;

  constructor(
    private dialogRef: MatDialogRef<NewEmailTemplateDialogComponent>,
    public _emailTemplateService: EmailTemplateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public api: UserMenuService,
    private clipboard: Clipboard,
    private _snackBar: MatSnackBar, public _userService: UserService, public _DialogService: DialogService,
  ) { }

  usertype = this._emailTemplateService.usertype;
  ngOnInit(): void {
    this.error = null;
    this.template = new EmailTemplate(this.data && this.data.template ? this.data.template.toJSON() : {
      type: 'client',
      name: '',
      subject: 'Enter Subject',
      body: 'Write a message'
    });
    this.type = this.data.type
    this.template.type = this.usertype;
    this.getPlaylistForTemplate()
    this.getUserDetails()
    this.getToGroups()
    this.getFromEmailData()
    if (this._emailTemplateService.templatetype == 'duplicate') {
      if (this.data.isDuplicate) {
        this.template.name = `${this.data.template.name} Copy`;
      }
    }
    if (this._emailTemplateService.templatetype == 'edit') {
      this.data.isDuplicate = false;
      if (this.data.template.body) {

        if (this.data.template.groups) {
          this.getGroupEmail(this.data.template.groups, 'patch')
        }
        this.valToEmail = this.data.template.receiver
        this.templatedetails = this.data.template;
        this.name = this.data.template.name
        this.templatedetails.body = this.templatedetails.message
        //  this.saveTemplate =true;
        if (this.templatedetails.sender === 'admin@mibemusic.com') {
          this.formEmail = 'Admin@mibemusic.com'
        }
        else {
          this.formEmail = this.templatedetails.sender
        }
        //  this.templatedetails.body = "Hey" + " " + "MIBE User," +"\n\n"+ this.templatedetails.body
        this.tempBody = this.templatedetails.body
        this.templatedetails = this.data.template;
        this.name = this.data.template.name
        // this.temptoGroup = this.templatedetails.groups
        // this.tempto = this.templatedetails.receiver
        // this.tempcc = this.templatedetails.cc_email[0]
        // this.tempBcc = this.templatedetails.bcc_email[0]
        // this.tempSub = this.templatedetails.subject
        // this.tempPlay = this.templatedetails.playlists
      }
      else {
        this.templatedetails = EmailTemplate
        this.templatedetails = this.data.template;
        this.name = this.data.template.name
        this.templatedetails.id = this.data.template.id

        // Robert: 5/15/24 - Setting default body per Kyle's request - MMP-28 
        this.templatedetails.body = 'Hi {{Nickname}},'
      }
      // else{
      //   this.name = this.data.template.name
      //   this.templatedetails = EmailTemplate;
      //   this.templatedetails.id= this.data.template.id
      //  // this.templatedetails.body = "Hey" + " " + "MIBE User,"
      //   this.saveTemplate = false;
      // }
    }
    this.textarea = this.commentInput?.nativeElement;
    this.textarea?.addEventListener('input', () => this.adjust());
    this.messageLastScrollHeight = this.textarea?.scrollHeight;

    this.toGroupCtrl.valueChanges
      .pipe(
        startWith(""),
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.searchingGroup = true;
        }),
        switchMap((v) => {
          if (!v) {
            return of(this.groupsToDetails);
          }
          return of(
            this.groupsToDetails.filter((g) =>
              g.name.trim().toLowerCase().includes(v.toLowerCase())
            )
          );
        }),
        tap(() => {
          this.searchingGroup = false;
        })
      )
      .subscribe(
        (g) => {
          this.groupDetails.next(g);
        },
        (error) => {
          console.log(error);
        }
      )
  }
  adjust(): void {
    let rows = parseInt(this.textarea.getAttribute('rows'));
    this.textarea.setAttribute('rows', '1');

    if (this.textarea.scrollHeight > (this.messageLastScrollHeight + 1)) {
      rows++;
    } else if (rows > 1 && this.textarea.scrollHeight < this.messageLastScrollHeight) {
      rows--;
    }

    this.messageLastScrollHeight = this.textarea.scrollHeight;
    this.textarea.setAttribute("rows", rows.toString());
    // this.textarea.style.overflow = 'hidden';
    // this.textarea.style.height = 'auto';
    // this.textarea.style.height = this.textarea.scrollHeight + 'px';
  }
  scrollToBottom(): void {
    try {

      let pos = (this.myScrollContainer.nativeElement.scrollTop || this.myScrollContainer.nativeElement.body.scrollTop) + this.myScrollContainer.nativeElement.offsetHeight;
      let scro = this.myScrollContainer.nativeElement.scrollHeight;
      console.log(Math.round(pos))
      console.log(scro)
      if ((scro - 5) < Math.round(pos)) {

        this.loadMore();
      }
    } catch (err) { }
  }
  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  scrollHandler(event) {

    console.log(this.myScrollContainer.nativeElement.scrollHeight)

  }
  getmailValue(evt) {
    this.tempName = evt.trim()
    this.template.name = evt.trim()
    console.log(evt)
  }
  getFromEmail(evt) {
    this.fromMail = evt.value;
    this.formEmail = evt.value
  }
  getIndividual(evt) {
    this.toMail = []
    this.toMail.push(evt)
    this.toMailSent = evt
    this.valToEmail = evt.trim()
  }
  getCCMail(evt) {
    if (evt && evt.trim() !== '') {
      this.getCCMailId = [evt]
    }
    else {
      this.getCCMailId = []
    }
  }
  getBCCMail(evt) {
    if (evt && evt.trim() !== '') {
      this.getBCCMailId = [evt]
    }
    else {
      this.getBCCMailId = []
    }
  }
  getGroupEmail(evt, type) {
    this.groupMails = [], this.groupId = []
    evt.forEach(element => {
      let val
      if (type === 'select') {
        val = element
      }
      else {
        val = element.id
      }
      let requestUrl = environment.apiURL + '/api/v1/admin/emailgroup/' + val + '/assigned-users/'
      this.api.doGET(requestUrl).subscribe(x => {
        this.groupList = x['results'];
        for (var i = 0; i < this.groupList.length; i++) {
          this.groupMails.push(this.groupList[i].email)
          this.valToEmail = this.groupList[i].email
        }
        if (this.groupMails.length === 0) {
          this.errorValue = `This group doesn't have any email Id(s)`
        }
        else {
          this.errorValue = ''
        }
      },
        (error) => {
          //alert(error.message)
        }
      )
      this.groupId.push(val)
    });

  }

  radioChange(event) {
    this.template.type = event.value
  }

  saveAndSend(val: any) {
    this.loadingUserUpdate = true;
    let postBodymail, requestURL = '', allToMails, htmlBody = ''

    this.toMail = this.toMail.filter(x => x !== '')
    for (let em of this.toMail) {

      allToMails = this.groupMails.concat(em);


    }
    if (this.toMail.length > 0) {


    }
    else {

      allToMails = this.templatedetails.receiver && this.templatedetails.receiver !== '' ? this.groupMails.concat(this.templatedetails.receiver) : this.groupMails;
    }
    if (this.getCCMailId === undefined) {
      if (typeof (this.templatedetails.cc_email) === 'string') {
        if (this.templatedetails.cc_email && this.templatedetails.cc_email.trim() === "") {
          this.getCCMailId = []
        }
        else {
          this.getCCMailId = [this.templatedetails.cc_email]
        }
      }
      else {
        if (this.templatedetails.cc_email && this.templatedetails.cc_email[0]?.trim() === "") {
          this.getCCMailId = []
        }
        else {
          this.getCCMailId = this.templatedetails.cc_email
        }
      }
    }
    if (this.getBCCMailId === undefined) {
      if (typeof (this.templatedetails.bcc_email) === 'string') {
        if (this.templatedetails.bcc_email && this.templatedetails.bcc_email.trim() === "") {
          this.getBCCMailId = []
        }
        else {
          this.getBCCMailId = [this.templatedetails.bcc_email]
        }
      }
      else {
        if (this.templatedetails.bcc_email && this.templatedetails.bcc_email[0]?.trim() === "") {
          this.getBCCMailId = []
        }
        else {
          this.getBCCMailId = this.templatedetails.bcc_email
        }
      }
    }
    if (this.playListArrayNames) {
      this.playListArrayNames.forEach(element => {
        this.playlistIds.push(element.id)
      });
    }
    if (this.playListArrayNames.length > 0) {
      if (this.type === 'CLIENT') {
        htmlBody = "<html><body><h3>" + this.templatedetails.body + "</h3><br><p>" + "Playlist shared by " + "<strong>" + this._userService.currentUser.first_name + " " + this._userService.currentUser.last_name + "</strong>" + "</p><ul>" + this.playListArrayNames.map(user => `<li><a target='_blank' href="https://mibemusic.com/playlist/${user.id}" class="action" >MIBE ${user.name}</a></li>`).join("") + "</ul></body></html>"
        // htmlBody = "<html><body>" + this.templatedetails.body + "<br><p>"+"Playlist shared by "+"<strong>"+this._userService.currentUser.first_name + " "+ this._userService.currentUser.last_name+"</strong>" +"</p><ul>" + this.playListArrayNames.map(user => `<li><a target='_blank' href="https://democlient.mibemusic.xyz/playlist/${user.id}" class="action" >MIBE ${user.name}</a></li>`).join("")  + "</ul></body></html>"
      }
      else {
        htmlBody = "<html><body><h3>" + this.templatedetails.body + "</h3><br><p>" + "Playlist shared by " + "<strong>" + this._userService.currentUser.first_name + " " + this._userService.currentUser.last_name + "</strong>" + "</p><ul>" + this.playListArrayNames.map(user => `<li><a target='_blank' href="https://mibemusic.com/playlist/${user.id}" class="action" >MIBE ${user.name}</a></li>`).join("") + "</ul></body></html>"
        // htmlBody ="<html><body>" + this.templatedetails.body + "<br><p>"+"Playlist shared by "+"<strong>"+this._userService.currentUser.first_name + " "+ this._userService.currentUser.last_name+"</strong>" +"</p><ul>" + this.playListArrayNames.map(user => `<li><a target='_blank' href="https://democlient.mibemusic.xyz/playlist/${user.id}" class="action" >MIBE ${user.name}</a></li>`).join("")  + "</ul></body></html>"
      }
    }
    else {
      // htmlBody = "<html><body><h1>" + this.templatedetails.body + "</h1></body></html>"
      htmlBody = "<html><body>" + this.templatedetails.body + "</body></html>"
    }

    for (let data of allToMails) {
      var re = /\S+@\S+\.\S+/;
      if (!re.test(data)) {
        this.emailValidation = true
        this.loadingUserUpdate = false;
        return
      }
    }
    if (val === 'send') {
      // postBodymail = {
      //   "from_email": this.fromMail,
      //   "subject": this.templatedetails.subject,
      //   "html_body":htmlBody ,
      //   "to_email": allToMails,
      //   "cc_email": this.getCCMailId,
      //   "bcc_email": this.getBCCMailId,
      //   "recipient_playlist_ids": this.recipientsArrayId
      // }
      postBodymail = {
        "subject": this.templatedetails.subject,
        "body": htmlBody,
        "name": this.name,
        "type": this.type,
        "sender": this.fromMail,
        "receiver": this.toMailSent ? this.toMailSent : this.templatedetails.receiver,
        "cc_email": this.getCCMailId ? this.getCCMailId : this.templatedetails.cc_email,
        "bcc_email": this.getBCCMailId ? this.getBCCMailId : this.templatedetails.bcc_email,
        "groups": this.groupId,
        "playlists": this.playlistIds,
        "to_email": allToMails,
        "message": this.templatedetails.body
      }
    }
    else {
      if (val !== 'send')
        postBodymail = {
          "subject": this.templatedetails.subject,
          "body": htmlBody,
          "name": this.name,
          "type": this.type,
          "sender": this.fromMail,
          "receiver": this.toMailSent ? this.toMailSent : this.templatedetails.receiver,
          "cc_email": this.getCCMailId ? this.getCCMailId : this.templatedetails.cc_email,
          "bcc_email": this.getBCCMailId ? this.getBCCMailId : this.templatedetails.bcc_email,
          "groups": this.groupId,
          "playlists": this.playlistIds,
          "to_email": allToMails,
          "message": this.templatedetails.body,
          "save_template": true
        }
    }

    if (postBodymail.bcc_email && typeof postBodymail.cc_email != 'string') {
      postBodymail.cc_email = postBodymail.cc_email.filter(x => x !== '')
    }
    if (postBodymail.bcc_email && typeof postBodymail.bcc_email != 'string') {
      postBodymail.bcc_email = postBodymail.bcc_email.filter(x => x !== '')
    }


    if (val === 'send') {
      //    requestURL = environment.apiURL + '/api/v1/admin/emailtemp/send-email/';
      requestURL = environment.apiURL + '/api/v1/admin/emailtemp/' + this.templatedetails.id + '/';
      this.api.doPUT(requestURL, postBodymail).subscribe((result: any) => {
        this.dialogRef.close(true);
        this.loadingUserUpdate = false;
        // location.reload()
      },
        (error) => {
          let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : (error?.error.name ? error?.error?.name : '')))
          this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
          });
          this.loadingUserUpdate = false;
          this.dialogRef.close();

        });
    }
    else {
      requestURL = environment.apiURL + '/api/v1/admin/emailtemp/';
      this.api.doPOST(requestURL, postBodymail).subscribe((result: any) => {
        this.dialogRef.close(true);
        this.loadingUserUpdate = false;
        location.reload()
      },
        (error) => {
          let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : (error?.error.name ? error?.error?.name : '')))
          this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
          });
          this.dialogRef.close();
          this.loadingUserUpdate = false;

        });
    }

    console.log(postBodymail)
  }
  create() {
    if (this._emailTemplateService.templatetype == 'duplicate' || this._emailTemplateService.templatetype == 'new') {
      this.template.groups.filter((el: any) =>
        this.template.groups = [el.id]
      );
      if (this._emailTemplateService.templatetype == 'new') {
        if (this.template.playlists.length > 0) {
          this.template.playlists.filter((el: any) =>
            this.template.playlists = [el.id]
          );
        } else {
          this.template.playlists = []
        }
      }
      else {
        if (this.template.playlists.length > 0) {
          this.template.playlists.forEach((val) => {
            this.playlistVal.push(val.id)
          })
        }
      }
      this.template.playlists = this.playlistVal

    }
    if (!this.template.name) {
      this.error = "Name is required";
      return;
    }
    if (!this.template.type) {
      this.error = "Type is required";
      return;
    }
    if (this._emailTemplateService.templatetype == 'duplicate') {
      this.template.name = this.template.name;
    } else {
      this.template.name = this.tempName;
      this.template.body = "";
      this.template.receiver = null;
      this.template.sender = null;
      this.template.subject = "";
      this.template.cc_email = [],
        this.template.bcc_email = [];
      this.template.groups = []
    }
    // this.template.body = " ";
    // this.template.subject = " ";


    this.template.created_at = new Date()
    this.error = null;
    this.loading = true;
    if (this._emailTemplateService.templatetype == 'duplicate') {
      this._emailTemplateService.duplicateTemplate(this.template).subscribe(template => {
        this.loading = false;
        this.dialogRef.close(true);
      }, err => {
        this.loading = false;
        if (err.error.details) {
          // alert(err.error.details)
          this._DialogService.DuplicateConfirmPopup(DuplicatetemplatePopupComponent)
        }
        else {
          // alert(err.error.detail)
          let data = err.error?.error ? err.error?.error : (err.error?.detail ? err.error?.detail : (err.error?.details ? err.error?.details : ``))
          this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
          });
          this.close();
        }
        // this.error = "There was an error trying to create your email template. Please check your internet connection and try again";
      })
    }
    else {
      this._emailTemplateService.createTemplate(this.template).subscribe(template => {
        this.loading = false;
        this.dialogRef.close(true);
      }, err => {
        this.loading = false;
        // alert(err.error.details)
        let data = err.error?.error ? err.error?.error : (err.error?.detail ? err.error?.detail : (err.error?.details ? err.error?.details : ``))
        this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
        });
        this.close();
        // this.error = "There was an error trying to create your email template. Please check your internet connection and try again";
      })
    }

  }

  close() {
    this.dialogRef.close(false);
  }
  mySelectOpt(lsit, evt, index) {
    let dataNew = false, count = 0;
    if (evt.checked) {
      this.playListArrayNames.push(lsit)
      this.copyPlaylist.push(lsit)
      this.copyPlaylist.forEach(element => {
        if (element.id === lsit.id) {
          count++;
        }
      });
      if (count > 1) {
        dataNew = true
      }
      let text = lsit.name;
      if (dataNew === false) {
        //let result = text.link(copiedURL);
        if (this.templatedetails.body) {
          let data = "* " + "MIBE " + lsit.name
          var removeData = this.templatedetails.body.replace(data, "");
          this.templatedetails.body = removeData
          this.templatedetails.body = this.templatedetails.body.replace(/^(?=\n)$|^\s*|\s*$|\n\n+/gm, "")
          this.templatedetails.body += "\n" + "* " + "MIBE " + text + ""
        }
        else {
          let data = "* " + "MIBE " + lsit.name
          var removeData = this.templatedetails.body.replace(data, "");
          this.templatedetails.body = removeData
          this.templatedetails.body = this.templatedetails.body.replace(/^(?=\n)$|^\s*|\s*$|\n\n+/gm, "")
          this.templatedetails.body = "* " + "MIBE " + text + ""
        }
      }
    } else {
      this.playListArrayNames = this.playListArrayNames.filter(x => x.name !== lsit.name);
      this.copyPlaylist = this.copyPlaylist.filter(x => x.name !== lsit.name);
      let data = "* " + "MIBE " + lsit.name
      var removeData = this.templatedetails.body.replace(data, "");
      this.templatedetails.body = removeData
      this.templatedetails.body = this.templatedetails.body.replace(/^(?=\n)$|^\s*|\s*$|\n\n+/gm, "")
    }
    /* if(evt.checked){
      this.list.availableUserPermission[index].checked = true;   
    }else{
      this.list.availableUserPermission[index].checked = false;  
    }  
    for (var i = 0; i < this.list.availableUserPermission.length; i++) {
      if (this.list.availableUserPermission[index].checked) {
        this.managePermissionArray.push(id);            
        this.selectedPermissionCount = this.managePermissionArray.length;
        if(this.managePermissionArray.length==this.list.availableUserPermission.length){
          this.allselectPermission = true;
        } 
        break
      }else{
        this.managePermissionArray = this.managePermissionArray.filter(x => x !== id);            
        this.selectedPermissionCount = this.managePermissionArray.length;
        this.allselectPermission = false;
        break
      }
    } */
  }
  canSave() {
    if (!this.templatedetails.subject) {
      return false
    }
    if (this.templatedetails.subject.trim() === '') {
      return false
    }
    if (!this.templatedetails.body) {
      return false
    }
    if (this.templatedetails.body.trim() === '') {
      return false
    }
    if (!this.formEmail) {
      return false
    }


    if (this.groupId.length === 0 && (!this.templatedetails.receiver || this.templatedetails.receiver === '')) {
      return false
    }

    return true
  }
  canCreate(event) {
    if (event === 'duplicate') {
      if (!this.template.name) {
        return false
      }
      if (this.template.name.trim() === '') {
        return false
      }
      return true
    }
    else {
      if (!this.tempName) {
        return false;
      }
      if (this.tempName?.trim() === '') {
        return false
      }
      return true
    }
  }

  selectedRecipient(id, evt, index) {
    if (evt.checked) {
      this.recipientsArrayId.push(id)
    } else {
      this.recipientsArrayId = this.recipientsArrayId.filter(x => x !== id);
    }
  }
  deselectAll() {
    /* this.selectedPermissionCount = 0
    for(let x of this.list.availableUserPermission){
      x.checked = false;
      this.allselectPermission = false;
      this.managePermissionArray=[];
    } */
  }
  playlistIndex = 1;
  getPlaylistForTemplate() {
    let requestUrl = `${environment.apiURL}/api/v1/admin/playlists/dropdown/?search=${this.searchString}&page=${this.playlistIndex}`;

    this.api.doGET(requestUrl).subscribe(x => {
      this.searchResultsCount = x['count'];
      if (this.playlistIndex == 1) {
        //clear playlist, new searchword being used
        this.playlistArray = [];
      }
      for (let play of x['results']) {
        this.playlistArray.push(play)
      }

      if (this._emailTemplateService.templatetype == 'edit') {
        if (this.data.template.playlists && this.data.template.body) {
          this.data.template.playlists.forEach(data => {
            for (let i = 0; i < this.playlistArray.length; i++) {
              if (data.id === this.playlistArray[i].id) {
                this.playlistArray[i].checked = true
                this.playListArrayNames.push(this.playlistArray[i])
                this.copyPlaylist.push(this.playlistArray[i])
                // let text = this.palylistArray[i].name;
                // //let result = text.link(copiedURL);
                // this.templatedetails.body+=text
              }
            }
          })
        }
      }
    },
      (error) => {
        //alert(error.message)
      }
    )
  }
  loadMore() {
    this.playlistIndex++
    this.getPlaylistForTemplate()
  }
  getUserDetails() {
    let requestUrl = environment.apiURL + '/api/v1/admin/users/get-artists/'
    this.api.doGET(requestUrl).subscribe(x => {
      // this.userDetails = x['results'] || [];
    },
      (error) => {
        //alert(error.message)
      }
    )
  }
  getToGroups() {
    // let requestUrl = environment.apiURL + '/api/v1/admin/emailgroup/'
    let requestUrl = environment.apiURL + `/api/v1/admin/emailgroup/email-group-dropdown/?type=${this.type}`
    this.api.doGET(requestUrl).subscribe(x => {
      this.groupsToDetails = x || [];
      this.groupDetails.next(x)
    },
      (error) => {
        console.log("Error in GetToGroups", error.message)
      }
    )
  }
  // get From Email
  getFromEmailData() {
    let data: any = []
    let requestUrl = environment.apiURL + `/api/v1/admin/emailtemp/get_from_emails/`
    this.api.doGET(requestUrl).subscribe(x => {
      console.log(x)

      data = x['from_emails'] || [];
      if (data.length > 0) {
        // for(let i=0;i< (data.length);i++){
        //   this.userDetails.push({email:data[i].sendAsEmail, id:i})
        // }
        for (let key in data) {
          let value = data[key];
          this.userDetails.push({ email: value.sendAsEmail, id: Number(key) })
          console.log(value)
          // JSON.stringify(this.userDetails);
          // Use `key` and `value`
        }
      }
    },
      (error) => {
        //alert(error.message)
      }
    )
  }
  // Copy link
  copyLink(id) {
    let value = this.playlistArray.findIndex(f => f.id == id.id);
    let copiedURL = ''
    let totalPage = (this.playlistArray.length + 25 - 1) / 25;
    // for(let i=2; i< Math.round(totalPage); i++){
    //   if(value<=(25)){
    //     copiedURL = 'https://qaweb.mibemusic.ml/playlists?playlist='+id.id;
    //     this.clipboard.copy(copiedURL);
    //     this._snackBar.open("Link Copied", "", {duration: 3000});
    //     return;
    //   }
    //   else if(value >(25*i)){
    //     copiedURL = 'https://qaweb.mibemusic.ml/playlists?playlist='+id.id+'&ordering_by=id&page='+i;
    //     this.clipboard.copy(copiedURL);
    //     this._snackBar.open("Link Copied", "", {duration: 3000});
    //     return;
    //   }
    // }

    copiedURL = 'https://democlient.mibemusic.xyz/playlists?playlist=' + id.id + '&ordering_by=id';
    this.clipboard.copy(copiedURL);
    this._snackBar.open("Link Copied", "", { duration: 3000 });
  }
  submitSearch(value) {
    this.playlistIndex = 1;
    this.searchFilterFocused = false;
    // if (value.length > 0) {
    //   let url = environment.apiURL + `/api/v1/admin/playlists/dropdown/?search=${value}&page=${this.playlistIndex}`
    //   this.api.doGET(url).subscribe(x => {
    //     this.playlistArray = x["results"];
    //     if (this._emailTemplateService.templatetype == 'edit') {
    //       if (this.data.template.playlists && this.data.template.body) {
    //         this.data.template.playlists.forEach(data => {
    //           for (let i = 0; i < this.playlistArray.length; i++) {
    //             if (data.id === this.playlistArray[i].id) {
    //               this.playlistArray[i].checked = true
    //               this.playListArrayNames.push(this.playlistArray[i])
    //               this.copyPlaylist.push(this.playlistArray[i])
    //               // let text = this.palylistArray[i].name;
    //               // //let result = text.link(copiedURL);
    //               // this.templatedetails.body+=text
    //             }
    //           }
    //         })
    //       }
    //     }
    //   },
    //     (error) => {
    //       //alert(error.message)
    //     }
    //   )
    // }
    // else {

    this.getPlaylistForTemplate();

    //}
  }
  changeTemplate(event) {
    if (event === true) {
      this.saveTemplate = true;
    }
    // if (event === true) {
    //   if (this.data.template.body) {
    //     this.templatedetails = this.data.template;
    //     this.saveTemplate = true;
    //     if (this.data.template.groups) {
    //       this.getGroupEmail(this.data.template.groups,'patch')
    //     }
    //     this.palylistArray.forEach(element => {
    //       if(element.checked == false){
    //         element.checked = true
    //       }
    //     });
    //     this.data.template.body =  this.tempBody;
    //     this.data.template.groups =  this.temptoGroup
    //     this.data.template.receiver =  this.tempto 
    //     this.data.template.cc_email = this.tempcc 
    //     this.data.template.bcc_email = this.tempBcc
    //     this.data.template.subject = this.tempSub
    //     this.data.template.playlists = this.tempPlay 
    //     this.data.template.sender = this.formEmail
    //   }
    // }
    // else {
    //   this.palylistArray.forEach(element => {
    //     if(element.checked){
    //       element.checked = false
    //     }
    //   });
    //   this.copyPlaylist =[]
    //   this.name = this.data.template.name
    //   this.templatedetails = EmailTemplate;
    //   this.templatedetails.id= this.data.template.id
    //   this.saveTemplate = false;
    //   this.groupId = []
    //   this.formEmail ="admin@mibemusic.com"
    //  // this.templatedetails.body = "Hey" + " " + "MIBE User,"
    // }
  }
}
