import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Platform } from '@angular/cdk/platform';
import {
  HttpClient,
  HttpParams,
  HttpHeaders
} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Track } from '../models/track.model';
import { APIListResponse } from '../models/apiresponse.model';
import { Observable, of } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import { TrackComposer } from '../models/trackcomposer.model';
import { TrackInfo } from '../models/trackinfo.model';


export interface MinMax {
  min?: number;
  max?: number;
}
export function minMaxToString(minMax: MinMax): string {
  let s = undefined;
  if (minMax) {
    s = '';
    if (minMax.min) {
      s = `${minMax.min}`;
    }
    s = s + ':';
    if (minMax.max) {
      s = s + `${minMax.max}`;
    }
  }
  return s;
}
export function stringToMinMax(s: string): MinMax {
  let mm = null;
  if (s) {
    mm = {};
    let splitS = s.split(':');
    if (splitS[0]) {
      mm['min'] = parseFloat(splitS[0]);
    }
    if (splitS[1]) {
      mm['max'] = parseFloat(splitS[1]);
    }
  }
  return mm;
}
export interface Filters {
  versionStatus: number[];
  artistId: number;
  composerId: number;
  genreId: number;
  moodId: any;
  duration: MinMax;
  tempo: any;
  bpm: any;
  version: string;
  is_instrumental: boolean;
  isExclusive: boolean;
  sub_genre: number;
  is_vocals: boolean;
  page?: any;
  date_filter_type?: any;
  artistType?: any
  clear?: boolean;
  is_mibe_owned?: boolean; //Robert: made this optional for now to avoid errors elsewhere in app

}
export enum versionStatus {
  PROCESSING = 0,
  SYSTEM_REJECTED = 1,
  PENDING_REVIEW = 2,
  PENDING_REVIEW_APPROVAL = 3,
  REJECTED = 4,
  ACCEPTED = 5,
  DISABLED = 6
}
export interface GetTracksOptions {
  search?: string;
  startDate?: Date;
  endDate?: Date;
  trackIds?: number[];
  ownedBy?: number;
  playlistId?: number;
  genreId?: number;
  moodId?: any;
  artistId?: number;
  albumId?: number;
  filters?: Filters;
  favorite?: boolean;
  downloadable?: boolean;
  ordering?: string;
  offset?: number;
  limit?: number;
  searchString?: string;
  version?: string;
  versionStatus?: number[];
  page?: any;
  activity_type?: string;
  genre?: any;
  start_date?: any;
  end_date?: any
  artistType?: any
  tempo?: any;
  DType?: any
}


export function filtersToParams(filters: Filters): { [param: string]: string | string[]; } {
  let params = {};
  // if (filters.versionStatus) {
  //   params['versions__status'] = filters.versionStatus.map((status) =>
  //     status.toString()
  //   );
  // }
  if (filters.versionStatus) {
    params['versionStatus'] = `${filters.versionStatus}`;
  }
  if (filters.artistId) {
    params['artist'] = `${filters.artistId}`;
  }
  if (filters.artistId) {
    params['artist'] = `${filters.artistId}`;
  }
  if (filters.composerId) {
    params['composer'] = `${filters.composerId}`;
  }
  if (filters.genreId) {
    params['genre'] = `${filters.genreId}`;
  }
  if (filters.sub_genre) {
    params['sub_genre'] = `${filters.sub_genre}`;
  }
  if (filters.moodId) {
    params['mood'] = `${filters.moodId}`;
  }
  if (filters.version) {
    params['version'] = `${filters.version}`;
  }
  if (filters.duration) {
    if (filters.duration.max) {
      params['duration__lte'] = `${filters.duration.max}`;
    }
    if (filters.duration.min) {
      params['duration__gte'] = `${filters.duration.min}`;
    }
  }
  if (filters.bpm) {
    params['bpm'] = `${filters.bpm}`;
  }
  if (filters.tempo) {
    params['tempo'] = `${filters.tempo}`;
  }
  // if (filters.tempo) {
  //   if (filters.tempo.max) {
  //     params['bpm__lte'] = `${filters.tempo.max}`;
  //   }
  //   if (filters.tempo.min) {
  //     params['bpm__gte'] = `${filters.tempo.min}`;
  //   }
  // }
  if (filters.is_instrumental !== null) {
    params['instrumental'] = `${filters.is_instrumental}`;
  }
  if (filters.is_vocals !== null) {
    params['is_vocal'] = `${filters.is_vocals}`;
  }
  if (filters.isExclusive === true || filters.isExclusive === false) {
    params['is_exclusive'] = `${filters.isExclusive}`;
  }
  if (filters.is_mibe_owned === true || filters.is_mibe_owned === false) {
    params['is_mibe_owned'] = `${filters.is_mibe_owned}`;
  }
  if (filters.artistType !== '') {
    params['artistType'] = `${filters.artistType}`;
  }

  return params;
}

export function areMinMaxEqual(mm1: MinMax, mm2: MinMax): boolean {
  if ((mm1 == null && mm2 != null) || (mm2 == null && mm1 != null)) {
    return false;
  }
  if (mm1 != null && mm2 != null) {
    if (mm1.max != mm2.max || mm1.min != mm2.min) {
      return false;
    }
  }
  return true;
}

// export interface Filters {
//   versionStatus: number[];
//   artistId: number;
//   composerId: number;
//   genreId: number;
//   moodId: number;
//   duration: MinMax;
//   tempo: MinMax;
//   instrumental: boolean;
//   vocals: boolean;
//   isExclusive: boolean;
// }
export function areFiltersEqual(f1: Filters, f2: Filters): boolean {
  if (f1.versionStatus && !f2.versionStatus || !f1.versionStatus && f2.versionStatus) {
    return false;
  }
  if (f1.versionStatus && f2.versionStatus) {
    if (f1.versionStatus.length !== f2.versionStatus.length) {
      return false;
    }
    if (f1.versionStatus.toString() != f2.versionStatus.toString()) {
      return false;
    }
  }
  if (f1.artistId !== f2.artistId) {
    return false;
  }
  if (f1.artistId !== f2.artistId) {
    return false;
  }
  if (f1.composerId !== f2.composerId) {
    return false;
  }
  if (f1.genreId !== f2.genreId) {
    return false;
  }
  if (f1.moodId !== f2.moodId) {
    return false;
  }
  if (!areMinMaxEqual(f1.duration, f2.duration)) {
    return false;
  }
  if (f1.tempo !== f2.tempo) {
    return false;
  }
  if (f1.bpm !== f2.bpm) {
    return false;
  }
  if (f1.version !== f2.version) {
    return false;
  }
  if (f1.is_instrumental !== f2.is_instrumental) {
    return false
  }
  if (f1.is_vocals !== f2.is_vocals) {
    return false
  }
  if (f1.isExclusive !== f2.isExclusive) {
    return false;
  }
  if (f1.artistType !== f2.artistType) {
    return false;
  }
  return true;
}

@Injectable({
  providedIn: "root",
})
export class TrackService {
  trackExpend: string = localStorage.getItem('editData')
  trackdata: any;
  cowritten_by: any = [];
  trackPopData: any;
  versionPopData: any;
  currentFilter: any;

  //Robert: 5/21/24 - used to determine if track should be played when selected (if just edited, don't play)
  lastUpdatedTrackId: number | null = null;
  
  constructor(
    private http: HttpClient,
    private _platform: Platform,
    private _transferState: TransferState
  ) { }

  getFeaturedTracks(limit: number = 20): Observable<Track[]> {
    return this._getTracks({
      is_featured: "true",
      limit: limit.toString(),
    }).pipe(map((res) => res.results));
  }

  getTracks(options: GetTracksOptions): Observable<APIListResponse<Track>> {
    console.log(options);
    return this._getTracks(this._getTracksOptionsToParams(options));
  }

  getTrack(trackId: number): Observable<Track> {
    return this.http
      .get<Object>(environment.apiURL + "/api/v1/track/" + trackId + "/")
      .pipe(map((res) => new Track(res)));
  }

  updateTrack(track: any, type): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    if (type == 'track') {
      return this.http.patch<any>(environment.apiURL + "/api/v1/track/" + track.id + "/", track, {
        headers: headers,
      })
        .pipe(
          map((res) => {
            return new Track(res);
          })
        );
    }
    if (type == 'version') {
      return this.http.patch<any>(environment.apiURL + "/api/v1/trackversion/" + track.id + "/", track, {
        headers: headers,
      })
        .pipe(
          map((res) => {
            return new Track(res);
          })
        );
    }

  }

  createTrack(track: Track): Observable<Track> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http
      .post<any>(environment.apiURL + "/api/v1/track/", track.toJSON(), {
        headers: headers,
      })
      .pipe(
        map((res) => {
          return new Track(res);
        })
      );
  }

  getTracksDownloadLink(options: GetTracksOptions): string {
    let params = this._getTracksOptionsToParams(options);
    let p = new HttpParams({ fromObject: params });
    const downloadUrl =
      environment.apiURL + `/api/v1/track/downloadfile/?${p.toString()}`;
    return downloadUrl;
  }
  getCurrentTrackFilters(): GetTracksOptions {
    let options: GetTracksOptions = {
      //  limit: 0
    }

    return options;
  }
  getMediaQueryDownloadLink(queryType: string, options: any) {
    let params = options;
    params["querytype"] = queryType;
    params["download"] = true;
    let p = new HttpParams({ fromObject: params });
    const downloadUrl = environment.apiURL + `/api/v1/track/mediaquery/?${p.toString()}`;
    return downloadUrl;
  }

  private _getTracks(params: {
    [param: string]: string | string[];
  }): Observable<APIListResponse<Track>> {
    console.log("params", params);
    let p = new HttpParams({ fromObject: params });
    // Check if tracks are cached from server
    const TRACKS_KEY = makeStateKey<APIListResponse<Object>>(
      "tracks-" + p.toString()
    );
    if (this._transferState.hasKey(TRACKS_KEY)) {
      const tracks = this._transferState.get<APIListResponse<Object>>(
        TRACKS_KEY,
        null
      );
      this._transferState.remove(TRACKS_KEY);
      return of({
        next: tracks.next,
        previous: tracks.previous,
        count: tracks.count,
        results: tracks.results.map((s) => new Track(s)),
      });
    }

    let headers = new HttpHeaders();
    let endpoint = `/api/v1/track/`;

    return this.http
      .get<APIListResponse>(environment.apiURL + `${endpoint}`, {
        headers: headers,
        params: p,
      })
      .pipe(
        tap((res) => {
          // If we're on the server cache the tracks
          if (!this._platform.isBrowser) {
            this._transferState.set(TRACKS_KEY, res);
          }
        }),
        map((res) => {
          return {
            next: res.next,
            previous: res.previous,
            count: res.count,
            results: res.results.map((s) => new Track(s)),
          };
        })
      );
  }

  private _getTracksOptionsToParams(options: GetTracksOptions) {
    console.log(options);
    let params: { [param: string]: string | string[] } = {};
    if (options) {
      if (options.filters) {
        params = filtersToParams(options.filters);
      }
      params["page"] = `${options.offset}`;
      if (options.search) {
        params["search"] = options.search;
      }
      if (options.startDate) {
        params["created_at__gte"] = options.startDate
          .toISOString()
          .split("T")[0];
      }
      if (options.endDate) {
        params["created_at__lte"] = options.endDate.toISOString().split("T")[0];
      }
      if (options.trackIds) {
        params["id"] = options.trackIds.map((id) => id.toString());
      }
      if (options.ownedBy) {
        params["owned_by"] = `${options.ownedBy}`;
      }
      if (options.genreId) {
        params["genre"] = `${options.genreId}`;
      }
      if (options.moodId) {
        params["moods"] = `${options.moodId}`;
      }
      if (options.playlistId) {
        params["playlists"] = `${options.playlistId}`;
      }
      if (options.artistId) {
        params["artist"] = `${options.artistId}`;
      }
      if (options.albumId) {
        params["albums"] = `${options.albumId}`;
      }
      if (options.offset) {
        params["offset"] = `${options.offset}`;
      }
      if (options.limit) {
        params["limit"] = `${options.limit}`;
      }
      if (options.ordering) {
        params["ordering"] = options.ordering;
      }

      if (options.searchString) {
        params["search"] = options.searchString;
      }
      if (options.favorite === true || options.favorite === false) {
        params["is_favorite"] = `${options.favorite}`;
      }
      if (options.downloadable === true || options.downloadable === false) {
        params["is_downloadable"] = `${options.downloadable}`;
      }

      // if (options.versionStatus) {
      //   params['versions__status'] = options.versionStatus.map((status) =>
      //     status.toString()
      //   );
      // }
    }
    return params;
  }

  getTrackActivity(options: GetTracksOptions) {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.get<Object>(
      environment.apiURL +
      `/api/v1/admin/users/` +
      `${options.ownedBy}` +
      `/get-artist-activity-tracks/`,
      {
        headers: headers,
      }
    );
  }

  getUploadActivity(options: GetTracksOptions) {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.get<Object>(
      environment.apiURL +
      `/api/v1/admin/users/` +
      `${options.ownedBy}` +
      `/get-artist-activity-uploads/`,
      {
        headers: headers,
      }
    );
  }
  trackDetail: any = []
  getComposerDetails(trackId: number): Observable<any[]> {
    let headers = new HttpHeaders();
    return this.http.get<any[]>(
      environment.apiURL + '/api/v1/track/' + trackId + '/get-track-info/',
      {
        headers: headers
      }
    ).pipe(
      tap((res) => {
        this.trackDetail = res;
        // if (!this._platform.isBrowser) {
        //   this._transferState.set(COWRITER_KEY, res);
        // }
      }),
      map((res) => {
        this.trackDetail = res;
        return res;
      }));
  }


}
