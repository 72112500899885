<mat-form-field [ngClass]="{'no-select': !enableSelect, 'dark-theme': isDark}" appearance="fill" id="musicApp">
  <div class="select-holder" *ngIf="enableSelect">
    
    <svg  *ngIf="type===''" xmlns="http://www.w3.org/2000/svg" width="12" height="13"><path class="ico" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="20" d="M2.111 2H9.89c.612 0 1.11.498 1.11 1.111v7.778C11 11.502 10.502 12 9.889 12H2.11A1.112 1.112 0 011 10.889V3.11C1 2.498 1.498 2 2.111 2zM8 1v2M4 1v2M1 5h10"/></svg>
    <svg  *ngIf="type !==''" xmlns="http://www.w3.org/2000/svg" width="12" height="13"><path class="ico1" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="20" d="M2.111 2H9.89c.612 0 1.11.498 1.11 1.111v7.778C11 11.502 10.502 12 9.889 12H2.11A1.112 1.112 0 011 10.889V3.11C1 2.498 1.498 2 2.111 2zM8 1v2M4 1v2M1 5h10"/></svg>

    <mat-select panelClass="daterange-select"  (selectionChange)="exclusiveChanged($event)" [(value)]="selectedRangeFilter" class="renge-select" [disableOptionCentering]="true">
      <mat-option class="text-uppercase" *ngFor="let rangeFilter of rangeFilterList" [value]="rangeFilter">
        {{rangeFilter}}
      </mat-option>
    </mat-select>
  </div>
  <div class="rangepicker-holder">
    <svg *ngIf="!enableSelect" xmlns="http://www.w3.org/2000/svg" width="12" height="13"><path class="ico" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="20" d="M2.111 2H9.89c.612 0 1.11.498 1.11 1.111v7.778C11 11.502 10.502 12 9.889 12H2.11A1.112 1.112 0 011 10.889V3.11C1 2.498 1.498 2 2.111 2zM8 1v2M4 1v2M1 5h10"/></svg>
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [max]="maxDate">
      <input matStartDate formControlName="start" placeholder="From" (dateChange)="startChanged($event.value)" [ngModel]="startDate">
      <input matEndDate formControlName="end" placeholder="To" (dateChange)="endChanged($event.value)" [ngModel]="endDate">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </div>
</mat-form-field>
<span
  class="clear-date"
  *ngIf="range.value.start || range.value.end"
  (click)="clearDateFilter()"
>Clear</span>
