<div class="dialog-container green-theme">
  <div class="dialog-header">
    <h4 class="dialog-heading">Add {{data.users && data.users.length > 1 ? 'selected':'user'}} to group</h4>
    <button type="button" class="close-button" (click)="close()">
      <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
          stroke-linejoin="round">
          <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
            <g id="Genre-Options">
              <g id="Close" transform="translate(1439.000000, 32.000000)">
                <path d="M24,0 L0,24" id="Path"></path>
                <path d="M0,0 L24,24" id="Path"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </button>
  </div>

  <mat-dialog-content>
    <app-loader *ngIf="loading" color="#50e3c2"></app-loader>
    <div class="dialog-body" *ngIf="!loading">
      <h3 class="title"><ng-container *ngFor="let user of data.users.slice(0,5); let i = index; trackBy:userTrackBy">{{user.getFullName()}}{{data.users.length > i+1 ? ', ':''}}</ng-container>{{data.users.length > 5 ? '+' + (data.users.length - 5) :''}}</h3>
      <div class="add-to-folder form-container"> 
        <p class="label">Available Groups<span class="deselect" (click)="deselectGroups()"><small>Deselect all ({{selectedCount}})</small></span></p>
        <input class="search"  id="searchGroup" (keyup)="getGroups()"
          placeholder="Find a group"
          [formControl]="searchControl">
        <ul class="list" *ngIf="!searchingGroups" id="availableGroups">
          <li *ngFor="let group of groups; let i = index">
            <mat-checkbox [class]="group.isChecked ? 'dark-theme' : ''" [checked]="isGroupChecked(i)" (change)="groupChecked($event.checked, i, group.id)" [(ngModel)]="group.isChecked">
              <span class="check-text">{{group.name}}</span>
            </mat-checkbox>
          </li>
        </ul>
        <div class="no-groups" *ngIf="!searchingGroups && groups.length <= 0">
          <p>There are currently no groups available. Create a new group to add your track version</p>
        </div>
        <app-loader *ngIf="searchingGroups" color="#50e3c2"></app-loader>
      </div>
      <p class="note">Select or deselect groups to add {{data.users[0].is_client ? 'client(s)':'artist(s)'}} to group</p>
      <div class="form-col buttons">
        <button class="form-button" (click)="save()" [disabled]="selectedCount==0">Save</button>
        <button class="form-button form-button-cancel" (click)="close()">Cancel</button>
      </div>
    </div>
  </mat-dialog-content>
</div>
