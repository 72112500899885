import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, of, ReplaySubject, throwError } from 'rxjs';
import { Track } from '../models/track.model';
import { TrackVersion } from '../models/trackversion.model';
import { UserMenuService } from '../services/user-menu.service';
import { PlaylistService } from '../services/playlist.service';
import { UserService } from '../services/user.service';
import { PlayerService } from '../services/player.service';
import { DialogService } from '../services/dialog.service';
import { MenuListService } from '../services/menu-list.service';
import { SliderDialogService } from '../services/slider-dialog.service';
import { TrackService } from '../services/track.service';
import { environment } from 'src/environments/environment';
import { catchError, debounceTime, distinctUntilChanged, startWith, switchMap, tap } from 'rxjs/operators';
import { HttpClient, HttpEventType, HttpResponse, HttpHeaders } from '@angular/common/http';
import { WaveService } from 'angular-wavesurfer-service';
import { FormControl } from '@angular/forms';



@Component({
  selector: 'app-track-version-new-detail',
  templateUrl: './track-version-new-detail.component.html',
  styleUrls: ['./track-version-new-detail.component.scss'],
  providers: [WaveService]
})

export class TrackVersionNewDetailComponent implements OnInit {
  filteredList1:any;
  page = 1
  @Input()
  set track(t: Track) {
    this._track = t;
    this.trackChange.emit(this._track);
  }
  get track(): Track {
    return this._track;
  }

  @Output()
  trackChange = new EventEmitter<Track>();

  @Input()
  set version(v: TrackVersion) {
    this._version = v;
    this.versionChange.emit(this._version);
  }
  get version(): TrackVersion {
    return this._version;
  }
  loadingTracks: boolean = false;
  @Output()
  versionChange = new EventEmitter<TrackVersion>();
  File: any;
  acceptedExtensions = "wav, mp4";
  @ViewChild('fileInput') myInputVariable: ElementRef;
  selectedFiles: any = [];
  message = '';
  versionTitle: string = "";
  bpmValue: number = 0;
  keyValue: string = 'C';
  isMainTrack = false;
  tempoList: string[] = [];
  trackEndingList: string[] = ['sting ending','sting ending decay','cold ending','cold ending decay']
  star = false;
  isMibeOwned = false;
  private _track: Track;
  private _version: TrackVersion;
  public trackEnd = "Quater-note";
  public tempo = "Sting";
  allTracks: any=[];
  allTracksList: any;
  NarrativeCount: number =0;
  selectedparent = 'Select';
  trackVersionList =[]
  replaceTrackVersion: Boolean = false;
  progressInfos: any;
  isUploaded = false;
  isUploading: any;
  isFailed: any;
  url: string =''
  trackId:any=''
  title ='';
  @Input() wavesurfer: WaveSurfer = null;
  isPlaying =false;
  current =0;
  duration = 0;
  isInstrumental = false;
  isVocal = false;
  searchingParent = false;
  parentFilterCtrl  = new FormControl();
  userId:any=''
  parentArray:any=[]
  nextList:any =''
  getParentVal = true
  parentTrackTitle = "";
  showVerNameErr: boolean = false;


  filteredParentOptions: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  nextTab = false;
  constructor(public api: UserMenuService,private cdr: ChangeDetectorRef,
    private _playlistService: PlaylistService,private _userService: UserService, 
    private _playerService: PlayerService, public _DialogService:DialogService,
    private _listService: MenuListService, private http: HttpClient, 
    private _sliderDialogService: SliderDialogService,public trackDetail1: TrackService, public waveService: WaveService) {  }

  ngOnInit() {
    this._sliderDialogService.getReplaceTrackVersion().subscribe((val) => {
      if (val && val == 'replace') {
        console.log(val);
        this.replaceTrackVersion = true;
       
      }
    });
   
    if(this.version.id !== undefined && this.track.id !== undefined){
      this.version.title = this.track['title']
    }
    if (this.version == null || this.version.id == undefined || this.version.id == null) {
      this._userService.currentUserStream.subscribe((u) => {
        this.userId = u.id
      });
    }
    this.getAllTracks(); 
    this.isInstrumental = this.version.is_instrumental;
    this.isVocal = this.version.is_vocals;
    this.versionTitle = this.version.title;
    this.parentTrackTitle = this.track.track.title;
    this.bpmValue = Math.round(this.version.calculated_bpm);
    this.keyValue = this.version.calculated_key;
    this.isMainTrack = this.version.is_main_track;
    this.trackEnd = this.version.track_ending ? this.version.track_ending : "";
    this.tempo = this.version.tempo ? this.version.tempo : this.tempoList[0];
    this._listService.currentIntrumentaltypeSubject.next(!this.version.is_instrumental);
    this.setValidation()
    this.trackId= this.track.track.id
    this.parentFilterCtrl.valueChanges
    .pipe(
      startWith(""),
      debounceTime(150),
      distinctUntilChanged(),
      tap(() => {
        this.searchingParent= true;
      }),
      switchMap((v) => {
        if (!v) {
          return of(this.allTracks);
        }
        return of(
          // this.allTracks.filter((g) =>
          //   g.name.trim().toLowerCase().includes(v.toLowerCase())
          // )
          this.getAllTracks('','',v)

        );
      }),
      tap(() => {
        this.searchingParent = false;
      })
    )
    .subscribe(
      (g) => {

        if(this.parentFilterCtrl.value == ''){
          this.allTracks = this.parentArray
          this.nextList = environment.apiURL + `/api/v1/trackversion/${this.track.track.owned_by}/get-all-tracks/?track_id=${this.track.id}&page=2`

        }
        this.filteredParentOptions.next(g);
      },
      (error) => {
        console.log(error);
        //alert("An error ocurred while getting the filtered genres. Check your connections and try again");
      }
    )
  }

  titleChanged() {
    if (this.version.title != this.versionTitle) {
      this.version.title = this.versionTitle;
      this.version.is_full_version = false;
      if (this.versionTitle.toLowerCase().includes("narrative")) {
        this.version.is_full_version = true;
        this.version.title = this.versionTitle.toLowerCase().includes(this.parentTrackTitle.toLowerCase())? this.versionTitle: `${this.parentTrackTitle} Narrative`;
      }
      this.versionChange.emit(this.version);
      this.setValidation();
    }
  }

reset(){
  this.selectedFiles = [];
  this.url = "";
  this.current = 0
  this.duration = 0
  this.wavesurfer.stop();
  this.wavesurfer = null;
  this._playerService.updateIsPlaying(false);
}

  trackEnding(val: any) {
    if (this.trackEnd != val.value) {
      this.trackEnd = val.value;
    }
    if (this.trackEnd != this.version.track_ending) {
      this.version.track_ending = this.trackEnd;
      this.versionChange.emit(this.version);
      this.setValidation()
    }
  }
  setMibeOwned(val: any) {
    console.log(val)
    this.version.is_mibe_owned = val;
    this.versionChange.emit(this.version);
  }

  setFiveStar(val: any) {
    console.log(val)
    this.version.is_five_star = val;
    this.versionChange.emit(this.version);
  }
  trackDetail(event:any){
    this.trackId = event.value
    const index = this.allTracks.findIndex(i => i.id == this.trackId);
    const preIndex= this.parentArray.findIndex(i => i.id == this.trackId)
    this.parentTrackTitle = this.allTracks[index]?.title;
    if(preIndex<0){
      if(index >= 0){
        this.parentArray.push(this.allTracks[index])
      }
    }
    for(let x of this.allTracks){
      if(x.id == event.value){
        this._playerService.trackNum(x.cowritten_by);
        this._playerService.trackIdDetail(x.id)
      }
    }
  }

  load(){
    
    this.page = this.page + 1;
    this.getAllTracks()

  }
  getAllTracks(val:any='', value:any='',search:any='', load:any='') {
    let id: any, trackId: any
    this.getParentVal = true
    if (this.version == null || this.version.id == undefined || this.version.id == null) {
      this._userService.currentUserStream.subscribe((u) => {
        id = u.id
      });
    }
    else {
      console.log(this.track)
      id = this.track.track.owned_by ? this.track.track.owned_by : this.track.track.owned_by;
      trackId =this.track.track.id ? this.track.track.id : this.track.track.id;
    }
    let requestUrl = ''
    if(load === ''){
    if(search.trim() == ''){
     requestUrl = environment.apiURL + `/api/v1/trackversion/${id}/get-all-tracks/?track_id=${trackId}&page=${this.page}`
    }
    else{
      requestUrl = environment.apiURL + `/api/v1/trackversion/${id}/get-all-tracks/?track_id=${trackId}&search=${search}`
    }
  }
  else{
    requestUrl = load
  }
    this.api.doGET(requestUrl).subscribe(x => {
      let arr;
      arr = x;
      this.allTracks = arr['results'];
      if (search.trim() === ''){
        this.parentArray = x['results']
      }
      if(search.trim() !== '' && load == ''){
        this.allTracks=(arr['results']);

      }
      else{
        this.allTracks.push(...arr['results']);

      }
      this.nextList = arr['next']
      this.filteredParentOptions.next(this.allTracks);
      // for(let data of this.allTracks){
      //   data['name'] = data.title
      // }
      // this.filteredList1 = this.allTracks.slice();
        for(let x of this.allTracks){
          if(x.id === this.version['track'].id){
            let data:any =[]
            let versions:any=[], versionId = 0;
            this.NarrativeCount = 0
            for(let val of x.versions){
              if(val.is_full_version){
                this.NarrativeCount++;
              }
            }
            if (this.NarrativeCount >= 2) {
              this.trackVersionList = ['30 Sec', 'Bumper', 'Sting', 'Vox', 'Bed', 'Drum & Bass (Dnb)']
            }
            else {
              this.trackVersionList = ['Narrative', '30 Sec', 'Bumper', 'Sting', 'Vox', 'Bed', 'Drum & Bass (Dnb)']
            }
          }
        }
        this.getParentVal = false

    })
  }

  loadNextOnScroll(event) {
    if(this.nextList !== null)
    this.getAllTracks('','','',this.nextList);
  }
  

  radioChange(e) {
    console.log(e)
    if (this.version.id === undefined) {
      if (e.value === false) {
        this.version.is_vocals = true;
        this.version.is_instrumental = false;
        this.versionChange.emit(this._version);
        this._listService.currentIntrumentaltypeSubject.next(!this.version.is_instrumental);
        this.setValidation()
      }
      else {
        this.version.is_vocals = false;
        this.version.is_instrumental = true;
        this.versionChange.emit(this._version);
        this._listService.currentIntrumentaltypeSubject.next(!this.version.is_instrumental);
        this.setValidation()
      }
    }
    else if (this.replaceTrackVersion === true && this.version.id !== null) {
      if (e.value === false) {
        this.version.is_vocals = true;
        this.version.is_instrumental = false;
        this.isInstrumental = false;
          this.isVocal = true;
        this.versionChange.emit(this._version);
        this._listService.currentIntrumentaltypeSubject.next(!this.version.is_instrumental);
        this.setValidation()
      }
      else {
        this.version.is_vocals = false;
        this.version.is_instrumental = true;
        this.isInstrumental = true;
          this.isVocal = false;
        this.versionChange.emit(this._version);
        this._listService.currentIntrumentaltypeSubject.next(!this.version.is_instrumental);
        this.setValidation()
      }
    }
    else {
      if (e.value === false) {
        this.version.is_vocals = true;
        this.version.is_instrumental = false;
        this.isInstrumental = false;
        this.isVocal = true;
        this.versionChange.emit(this._version);
        this._listService.currentIntrumentaltypeSubject.next(!this.version.is_instrumental);
        this.setValidation()
      }
      else {
        this.version.is_vocals = false;
        this.version.is_instrumental = true;
        this.isInstrumental = true;
        this.isVocal = false;
        this.versionChange.emit(this._version);
        this._listService.currentIntrumentaltypeSubject.next(!this.version.is_instrumental);
        this.setValidation()
      }
    }
  }
  radioChangeVocal(e){
       if (this.replaceTrackVersion === true && this.version.id !== null) {
        if (e.value === false) {
          this.version.is_vocals = false;
          this.version.is_instrumental = true;
          this.isInstrumental = true;
          this.isVocal = false;
          this.versionChange.emit(this._version);
          this._listService.currentIntrumentaltypeSubject.next(!this.version.is_instrumental);
          this.setValidation()
        }
        else {
          this.version.is_vocals = true;
          this.version.is_instrumental = false;
          this.isInstrumental = false;
          this.isVocal = true;
          this.versionChange.emit(this._version);
          this._listService.currentIntrumentaltypeSubject.next(!this.version.is_instrumental);
          this.setValidation()
        }
      }
      else {
        if (e.value === false) {
          this.version.is_vocals = false;
          this.version.is_instrumental = true;
          this.isInstrumental = true;
          this.isVocal = false;
          this.versionChange.emit(this._version);
          this._listService.currentIntrumentaltypeSubject.next(!this.version.is_instrumental);
          this.setValidation()
        }
        else {
          this.version.is_vocals = true;
          this.version.is_instrumental = false;
          this.isInstrumental = false;
          this.isVocal = true;
          this.versionChange.emit(this._version);
          this._listService.currentIntrumentaltypeSubject.next(!this.version.is_instrumental);
          this.setValidation()
        }
      }
    
  }

  selectFiles(event) {
    const files = event.target.files;
    let isImage = true;
    this.isFailed = false;
      if (files.item(0).type && files.item(0).type.match('audio/wav')) {
        this.selectedFiles = files[0];
        this.upload(0, this.selectedFiles);
        console.log(this.selectedFiles)
        this.myInputVariable.nativeElement.value = "";
      } else {
        isImage = false;
        // alert('invalid format!');
      }
    
  }

  upload(idx, file) {
    this.isUploading = true;
    const formData = new FormData();
    formData.append("file", file);
    let promise = new Promise<any>((resolve, reject) => {
      this.uploadWithProgress(formData)
        .subscribe(event => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progressInfos = Math.round(100 * event.loaded / event.total);
            console.log(this.progressInfos);
            if (this.progressInfos == 100) {
              setTimeout(() => {
                this.isUploaded = true;
                this.isUploading = false;
                this.loadingTracks = true;
              }, 200);
            }
          } else if (event instanceof HttpResponse) {
            resolve(event);
            // console.log(file.name + ', Size: ' + file.size + ', Uploaded URL: ' + event.body.link);
          }
          // resolve(event); 
          console.log(event)
        
          if (event.status == 200 && event.body) {
           this.url = event.body.file
           this.title = event.body.file_name;
           this._sliderDialogService.getReplaceTrackFileSubject.next(event.body.id);
         
           this._sliderDialogService.currentFile = event.body.file
           this.cdr.detectChanges();
          }
          console.log(event)
          if(this.url !== ''){
            this.generate(event.body.id);
          }
        },
          err => {
            this.message = 'Could not upload the file:' + file.name;
            this.isFailed = true;
            if(err.error.details){
              // alert(err.error.details)
              let data = err.error?.error ? err.error?.error : (err.error?.detail ?err.error?.detail : (err.error?.details ? err.error?.details:``) )
          this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
          });
            }
            else{
              // alert("Kindly check the audio level.  Please contact the MIBE administrator.")
              let data = err.error?.error ? err.error?.error : (err.error?.detail ?err.error?.detail : (err.error?.details ? err.error?.details:``) )
          this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
          });
          
            }
            this.selectedFiles = [];
            this.url = "";
            this.current = 0
            this.duration = 0
            this.wavesurfer = null;
            resolve(true);
          });
    })
    return promise;
  }

  uploadWithProgress(formData: FormData): Observable<any> {
    return this.http.post(environment.apiURL + '/api/v1/upload/', formData, { observe: 'events', reportProgress: true })
      .pipe(
        catchError(err => this.handleError(err))
      );
  }
  private handleError(error: any) {
    return throwError(error);
  }
  trackVersionTitle(val: any) {
   
    if(val.value === 'Narrative'){
      this.version.is_full_version = true
    }
    else{
      this.version.is_full_version = false
    }
    this.version.title = val.value;
    if(this.version.title === 'Narrative'){
      this.version.is_full_version = true
     }
    this.versionChange.emit(this.version);
    this.setValidation();
  }
  sizeOf = function (bytes) {
    if (bytes == 0) { return "0.00 B"; }
    var e = Math.floor(Math.log(bytes) / Math.log(1024));
    return (bytes / Math.pow(1024, e)).toFixed(2) + ' ' + ' KMGTP'.charAt(e) + 'B';
  }

  generate(id:any){

    if( this.url !== '' && this.url !== null){
      if (this.wavesurfer === null) {
        this.wavesurfer = this.waveService.create({
          container: '#wave',
          waveColor: 'white',
          height: 30,
          progressColor: 'black',
          backend: 'MediaElement',
          hideScrollbar: true,
          barGap: 3,
          barWidth: 1,
          responsive: true,
          fillParent: false,
          scrollParent: false,
          minPxPerSec: 30,
          cursorColor: "#fff",
          cursorWidth: 0,
        });
      }
        this.generateWaveform();
        this.cdr.detectChanges();
  
        Promise.resolve().then(() => {
          // this.currentPlayingItem.waveform_file_data ? this.wave.load(this.currentPlayingItem.mp3_file,this.currentPlayingItem.waveform_file_data) : this.wave.load(this.currentPlayingItem.mp3_file)
          let url:any = `${environment.apiURL}/api/v1/trackversions/get-version-waveform/?version_id=${id}`
      this.http.get(url).subscribe((y:any)=>{
        let hostUrl = environment.awsHost + y.mp3_file
        let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if(isSafari){
       let  result = hostUrl.replace(/.mp4/g, ".mp3");
         y['waveform_file_data'] ? this.wavesurfer.load(result,y.waveform_file_data) : this.wavesurfer.load(y.mp3_file);
        }
        else{
         y['waveform_file_data'] ? this.wavesurfer.load(hostUrl,y.waveform_file_data) : this.wavesurfer.load(y.mp3_file);
        }

      })
        });
      

      
    }
  }
  generateWaveform(): void {
    Promise.resolve(null).then(() => {
      this.wavesurfer.on('ready', () => {
        this.loadingTracks = false;
       // this.wavesurfer.play();
        this.duration =this.wavesurfer.getDuration();
        this.cdr.detectChanges();
        this.wavesurfer.isPlaying()
       // this.isPlaying =true;
      });

        this.wavesurfer.on('audioprocess', () => {
          this.current = this.wavesurfer.getCurrentTime()
          this.cdr.detectChanges();
          this.wavesurfer.on('finish', () => {
            this.current = 0
            this.wavesurfer.stop()
            this.isPlaying = false;
            this.cdr.detectChanges();

          })
        })
      

    });
  }
  getCurrentPreview() {
    setTimeout(()=>{  
      this.current = this.wavesurfer.getCurrentTime()
      this.cdr.detectChanges();
    }, 100);
  }

  play() {
    this.isPlaying =true;
    this.wavesurfer.play();
  }
  pause() {
    this.isPlaying = false;
    this.wavesurfer.playPause();
  }
  setMainTrack(event){
    this.version.is_main_track = event;
    this.versionChange.emit(this._version);
  }
  setValidation(){
    if(this.track){
      if(this.version.title &&  this.version.track_ending && this.track.title){
       this.track.isValid = false;
      }
      else{
       this.track.isValid = true;
      }
     }
    if (this.version.is_instrumental === false) {
      if (this.versionTitle.trim() !== "" && ([...this.track.version_network_references,...this.track['support_network_references']])?.length >= 2 && ([...this.track.version_tv_references,...this.track['support_tv_references']])?.length >= 2 
      && ([...this.track.version_sonic_reference,...this.track['support_sonic_references']])?.length >= 2 &&
      ([...this.track.version_band_references,...this.track['support_band_references']])?.length >= 2 && (this.track.Vocalssub_category && (this.track.Vocalsdescriptions && this.track.Vocalsdescriptions?.length > 0))
        && (this.track.lyrics.verse != '' || this.track.lyrics.pre_chorus != '' || this.track.lyrics.chorus != '' || this.track.lyrics.bridge != '')
        &&  ((this.track.Instrumentsub_category && this.track.Instrumentsub_category.length > 0)  && ((this.track.Instrumentdescriptions 
          && this.track.Instrumentdescriptions?.length>0) ||
          (this.track.Instrumentnew_suggestion && this.track.Instrumentnew_suggestion?.length>0)))) {
        this.track.isValid = false;
      }
      else {
        this.track.isValid = true;
      }
    }
    else {
      if (this.versionTitle.trim() !== "" && ([...this.track.version_network_references,...this.track['support_network_references']])?.length >= 2 && ([...this.track.version_tv_references,...this.track['support_tv_references']])?.length >= 2 
      && ([...this.track.version_sonic_reference,...this.track['support_sonic_references']])?.length >= 2 &&
      ([...this.track.version_band_references,...this.track['support_band_references']])?.length >= 2 &&  ((this.track.Instrumentsub_category && this.track.Instrumentsub_category.length > 0)  && ( (this.track.Instrumentdescriptions 
          && this.track.Instrumentdescriptions?.length>0) ||
          (this.track.Instrumentnew_suggestion && this.track.Instrumentnew_suggestion?.length>0)))) {
        this.track.isValid = false;
      }
      else {
        this.track.isValid = true;
      }
    }
    if (this.parentTrackTitle === this.versionTitle.trim()) {
        this.track.isValid = true;
        if (this.parentTrackTitle !== "" && this.versionTitle !== "") {
          this.showVerNameErr = true;
        }
      } else {
        this.showVerNameErr = false;
      }
  }
  ngOnDestroy() {
    if(this.wavesurfer !== null){
      this.wavesurfer.stop()
      }
    this._playerService.nextTab(false);
    this._playerService.updateViewType(false);
    this._playerService.updateShowMusicPlayer(false);
    this._playerService.play(null, null);
    // this.wave.destory()
  }
  
}
