import { Component, OnInit, Inject, Input, ViewChild, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { MenuListService } from '../../shared/services/menu-list.service';
import { SliderDialogService } from '../../shared/services/slider-dialog.service';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { X } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-user-edit-form',
  templateUrl: './user-edit-form.component.html',
  styleUrls: ['./user-edit-form.component.scss']
})
export class UserEditFormComponent implements OnInit {
  @ViewChild('matRef') matRef: MatSelect;
  @Input('currentuser') currentUser:any;
  @Input('userDetail') userObj:any;
  primary:any;
  formDisable: boolean = false
  toppings = new FormControl();
  searchFilterFocused:any;
  loading: boolean = false;
  searchString:any;
  groupVal:any = [];
  editPermissions:any;
  primaryPhoneControl:FormControl = new FormControl();
  toppingList: string[] = ['Sub Admin Groups', 'Test1', 'Test2', 'Test3', 'Test4'];
  managePermissionArray:any[] = [];
  permission:any[] = [];
  userDetails:any;
  userEditForm: FormGroup;
  password: string = "";
  isNewUser: boolean = false;
  searchPermission:string ="";
  cueerntDetails:any;
  errorMessage: string;
  user = {
    country: '',
    state:''
  }
  username:string = "";
  phoneControllers:FormControl[] = [];
  phoneNumberArray:any = [ {
    number_type:"mobile",
    phone_number:""
    
}];
groupInitial: any = '';
groupEdit: boolean = false;
selectedCount: any = 0;
selectedCountAdd: any = 0;
defaultCount: any;
phoneNumber: any = '';
currentValue: any;
finalArray:any = []
currentUserPermission:any=[]
currentUserGroup :any=[]
  allselectUser:boolean = false;
  public numberPattern = {'0': { pattern: new RegExp('^[0-9]')}};
  constructor(public fb: FormBuilder,
     public api: UserMenuService, 
     public config: MenuConfigService, 
     public list: MenuListService,
     private _sliderDialogService:SliderDialogService,public dialog: DialogService,
    ) { 
      this.list.getAllGroupsList('edit');
      this.list.getEditAvailableUserPermission()
    }
    ngOnChanges(changes: SimpleChanges) {
    //  this.setformValue(changes.userObj.currentValue)
      
  }
  ngOnInit(): void {
   
    this.userEditForm = this.fb.group({
      first_name: new FormControl('',[Validators.required]),
      last_name: new FormControl('',[Validators.required]),
      email: new FormControl('',[Validators.required,Validators.email]),
      address1: new FormControl(''),
      address2: new FormControl(''),
      city: new FormControl(''),
      state: new FormControl(''),
      country: new FormControl(''),
      postal_code: new FormControl(''),
      phone_number: new FormControl(''),
      additional_numbers: new FormControl([]),
      shows: new FormControl([]),
      allowed_cowriters: new FormControl([]),
      visible_genres: new FormControl([]),
      username: new FormControl(''),
      groups : new FormControl(''),
      ms_username: new FormControl('')
    })
    
    //if(this.currentUser == 'edit'){
     this.list.getAdminUser().subscribe(usersub=>{
       this.cueerntDetails = usersub
       if (usersub?.groups?.length > 0) {
        this.formDisable = false
         this.getGroupPermission(usersub.groups[0], usersub)
       }
       else {
         this.currentUserGroup = []
       }
       this.currentUserPermission = usersub?.user_permissions
       this.groupEdit = true
      this.finalArray = [...[]]
      setTimeout(() => {

        for (let x of this.list.editAvailableUserPermission) {
          x.checked = false
          x.disabled = false
          if (usersub && usersub?.user_permissions?.length > 0) {
            for (let user of usersub && usersub?.user_permissions) {
              if (user == x.id) {
                x.checked = true
                // x.disabled = true
              }
            }
            // for (let i of this.currentUserGroup) {
            //   if (x.id == i.id) {
            //     x.checked = true
            //     x.disabled = true
            //     usersub.user_permissions.push(i.id)
            //   }
            // }
            let data =  usersub?.user_permissions?.filter((item,index) =>  usersub?.user_permissions?.indexOf(item) === index)
            this.selectedCount = data?.length | 0;
           // this.selectedCount = usersub?.user_permissions?.length | 0
          }
         
         
        }
      }, 3000);
      if(usersub){
       
        this.finalArray = this.list.editAvailableUserPermission;

        this.userDetails = usersub;
        
        // for (let x of this.list.editAvailableUserPermission) {
        //   for (let i of this.currentUserGroup) {
        //     if (x.id == i.id) {
        //       x.checked = true
        //       x.disabled = true
        //       usersub.user_permissions.push(i.id)
        //     }
        //   }
        // }
        let data =  usersub?.user_permissions?.filter((item,index) =>  usersub?.user_permissions?.indexOf(item) === index)
        this.selectedCount = data?.length | 0;
        //this.selectedCount = usersub?.user_permissions?.length | 0
        this.setformValue(usersub);    
      }
     
     })
    //}
   
  
  }
  
setformValue(user){
  let value = [];
  if (user.visible_genres) {
    user.visible_genres.forEach(element => {
      value.push(element.id)
    });
  }
  let data =  {
    first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      address1: user.address1,
      address2: user.address2,
      city: user.city,
      state: user.state,
      country: user.country,
      postal_code: user.postal_code,
      phone_number: user.phone_number==null?this.phoneNumber:this.phoneNumber,
      additional_numbers: user.additional_numbers,
      shows: user.shows,
      allowed_cowriters: user.allowed_cowriters,
      visible_genres: value,
      username: user.email,
      ms_username: user.ms_username,
      group : user?.groups[0]
  }
  this.user.country = user.country
  this.user.state = user.state
  this.phoneNumberArray = user.additional_numbers;
  this.groupInitial = user.groups[0]
  this.groupVal = [user.groups[0]]
  // this.groupChange(gr)
 /*  if(this.phoneNumberArray){
    this.phoneNumberArray.push({ 
      number_type:"mobile",
    phone_number:user.phone_number,
    id : ''})
  } */
  //this.groupVal = user.groups[0]
  this.managePermissionArray = user.user_permissions
  //this.selectedCount = user.user_permissions.length;
  //this.editPermissionSet();
  console.log(this.list.editAvailableUserPermission)
  
  this.userEditForm.patchValue(data);
}
  getGroupPermission(event, usersub) {
    let data = null, permission = []
    let url = environment.apiURL + this.config.doGroups + event + '/';
    this.api.doGET(url).subscribe(x => {
      data = x;

      if (data !== null) {
        usersub.groups[0] = x["id"]
        this.currentUserGroup = x['permissions']
        for (let x of this.list.editAvailableUserPermission) {
          for (let i of this.currentUserGroup) {
            if (x.id == i.id) {
              x.checked = true
              x.disabled = true
              usersub.user_permissions.push(i.id)
            }
          }
        }
      }

    })
  }
getPhoneCode(e, i?:number){
  let l = e.value.length;
  this.currentValue = e.id;
  //this.phoneNumber = '';
  if(l==1){
    if(e.value !== "+"){
      this.phoneNumber = '+1' + e.value;
      if(i!==undefined){
      this.phoneNumberArray[i].phone_number = this.phoneNumber
      }
    }
  }else{
    this.phoneNumber = e.value;
  }
}
resetGroup(){   
  this.matRef.options.forEach((data: MatOption) => data.deselect());
}
formSave(){
  let data = this.userEditForm.value;
  
  //let group = []
  //group.push(this.groupVal)
  /* let phone = this.phoneNumberArray.filter(x => x.number_type == 'mobile');
  this.phoneNumberArray.forEach(element => {
    element.phone_number = this.phoneNumber;
  }); */
  //data['phone_number'] = phone.length !== 0 ?  this.phoneNumber: this.phoneNumber
  //data['additional_numbers'] = this.phoneNumberArray
  data['first_name'] = this.userEditForm.value.first_name,
  data['last_name'] = this.userEditForm.value.last_name,
  data['email'] = this.userEditForm.value.email,
  data['address1'] = this.userEditForm.value.address1,
  data['address2'] = this.userEditForm.value.address2,
  data['city'] = this.userEditForm.value.city,
  data['state'] = this.user.state;
  data['country'] = this.user.country;
  data['postal_code'] = this.userEditForm.value.postal_code,
  data['groups'] = this.groupVal;
  data['ms_username'] = this.userEditForm.value.ms_username,
  data['user_permissions'] = this.managePermissionArray;/* this.permission.map(x => x.id) */
  console.log(data)
  
 /*  if(this.userEditForm.invalid || this.phoneNumberArray.length === 0 || this.user.state == '' || this.user.country == ''){

    //alert('Enter required fields')
    return;
  } */
  
  this.loading = true;
    let url = environment.apiURL + this.config.doMenuUser + this.userDetails.id + '/';
    this.api.doPUT(url,data).subscribe(x=>{
      this.loading = false
      this.list.getAllMenuUser(x);
      this.formDisable = false
      this.list.currentAdminUserSubject.next(x)
    },
    (error) => {
      this.loading = false;
      let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
      this.dialog.UserUploadPopupConfirmPopup(data).subscribe(changed => {
      });
      //alert(error.message)
    })
  // }
  // else{
  //   let url = environment.apiURL + this.config.doMenuUser;
  //   this.api.doPOST(url,data).subscribe(x=>{
  //     this.loading = false
  //     this.list.getAllUser();
  //     this._sliderDialogService.close(true);
  //   },
  //   (error) => {
  //     this.loading = false;
  //     //alert(error.message)
  //   })
  // }

}
removeNumber(index: number) {
  this.phoneNumberArray.splice(index, 1);
  this.phoneControllers.splice(index, 1);
  console.log(this.phoneNumberArray)
}
addPhoneNumber(){
    this.phoneNumberArray.push({
      number_type:"",
      phone_number:""
    })
}
validateNumber(event, i?:number) {
  const keyCode = event.keyCode;
  const excludedKeys = [8, 37, 39, 46];
  if ((event.key === ' ' || isNaN(Number(event.key)))&&!((
    (keyCode >= 96 && keyCode <= 105) ||
    (excludedKeys.includes(keyCode))))) {
    event.preventDefault();
  }
}
mySelectOpt(id, evt, index){  /* 
  console.log(this.selectedCount)
  console.log(this.selectedCountAdd) */
  this.formDisable = true
  let data = 0;
  if(evt.checked){
    this.list.editAvailableUserPermission[index].checked = true;   
  }else{
    this.list.editAvailableUserPermission[index].checked = false;  
  }  
  for (var i = 0; i < this.list.editAvailableUserPermission.length; i++) {
    if (this.list.editAvailableUserPermission[index].checked) {
      this.managePermissionArray.push(id);
      let data =  this.managePermissionArray.filter((item,index) =>  this.managePermissionArray.indexOf(item) === index)
      this.selectedCount = data.length;
      if(this.managePermissionArray.length==this.list.editAvailableUserPermission.length){
        this.allselectUser = true;
      } 
      break
    }else{
      this.managePermissionArray = this.managePermissionArray.filter(x => x !== id);
      let data =  this.managePermissionArray.filter((item,index) =>  this.managePermissionArray.indexOf(item) === index)
      this.selectedCount = data.length;
      this.allselectUser = false;
      break
    }
  }
  for (let x of this.list.editAvailableUserPermission) {
    if (x.checked) {
      data++;
    }
  }
  this.selectedCount = data;
}
deselectPermission(){ 
  this.selectedCount=this.defaultCount;
  this.selectedCountAdd = this.defaultCount;
  let data = 0
  for (let x of this.list.editAvailableUserPermission) {
    if (!x.disabled) {
      x.checked = false;
    }
    if (x.disabled) {
      data++;
    }
    this.selectedCount = data;
    this.allselectUser = false;
    this.managePermissionArray = [];
  }
}
selectAllPermission(evt){
  if(evt.checked){
    for(let x of this.list.editAvailableUserPermission){
      x.checked = true;        
      this.allselectUser = true;
      if(!this.managePermissionArray.includes(x.id)){
        this.managePermissionArray.push(x.id);
        let data =  this.managePermissionArray.filter((item,index) =>  this.managePermissionArray.indexOf(item) === index)
        this.selectedCount = data.length;
      }
    }
  }else{
    for(let x of this.list.editAvailableUserPermission){
      if(!x.disabled){
        x.checked = false;
      } 
      
      this.allselectUser = false;
      this.managePermissionArray=[];
      this.selectedCount = this.selectedCountAdd;
    }
  }
}
get selectallStatus(){
var status = true;
  for(let x of this.permission){
   if(!x.checked){
    status = false
   }
  }
  return status
}

groupChange(e){
  let userList = []
  let url = environment.apiURL + this.config.menuUser;
  this.api.doGET(url).subscribe(x => {
    userList = x['results'] || []
    for (let val of userList) {
      if (val.id === this.userDetails.id) {
        this.managePermissionArray = val.user_permissions
      }
    }
  },
    (error) => {
      //alert(error.message)
    }
  )
  
  console.log(e,this.managePermissionArray)
  this.groupEdit = true;
  this.selectedCountAdd = 0;
  this.groupVal = [];
  this.groupVal.push(e.value)
  for(let x of this.list.AllGroups){
      if(x.id === e.value){
        for (var i = 0; i < this.list.editAvailableUserPermission.length; i++) {
          this.list.editAvailableUserPermission[i].checked = false;
          this.list.editAvailableUserPermission[i].disabled = false;
          if(this.managePermissionArray.includes(this.list.editAvailableUserPermission[i].id)){
            this.list.editAvailableUserPermission[i].checked = true;
            this.list.editAvailableUserPermission[i].disabled = true;   
          }
          for(var j = 0; j < x?.permissions.length; j++){
            if (this.list.editAvailableUserPermission[i].id == x.permissions[j].id) {   
              this.list.editAvailableUserPermission[i].checked = true;
              this.list.editAvailableUserPermission[i].disabled = true;        
              let data =  this.managePermissionArray.filter((item,index) =>  this.managePermissionArray.indexOf(item) === index)
              this.selectedCount = data.length;
              this.selectedCountAdd = this.selectedCount;
              this.defaultCount = x.permissions.length
              if(this.list.editAvailableUserPermission[i].checked){
                if(!this.managePermissionArray.includes(this.list.editAvailableUserPermission[i].id)){
                  this.managePermissionArray.push(this.list.editAvailableUserPermission[i].id)           
                  let data =  this.managePermissionArray.filter((item,index) =>  this.managePermissionArray.indexOf(item) === index)
                  this.selectedCount = data.length;
                  this.selectedCountAdd = this.selectedCount;
                  console.log(this.managePermissionArray)
                }
              }
            }
          }
        }
      }
  }
}
/* editPermissionSet(){
  for(let x of this.list.availableUserPermission){
       
       for(let y of  x.permissions){
        for(let group of this.editPermissions){
          if(group === y.id){
            y['checked'] = true;
          }
          else{
            y['checked'] = false;
          }
        }
        
       }
       this.permission = x.permissions
       console.log
  }
} */
changeEvent(e){
  this.managePermissionArray = this.currentUserPermission
  console.log(e)
  this.groupEdit = true;
  this.selectedCountAdd = 0;
  this.groupVal = [];
  this.groupVal.push(e)
  for(let x of this.list.AllGroups){
      if(x.id === e){
        for (var i = 0; i < this.list.editAvailableUserPermission.length; i++) {
          this.list.editAvailableUserPermission[i].checked = false;
          this.list.editAvailableUserPermission[i].disabled = false;
          if(this.managePermissionArray.includes(this.list.editAvailableUserPermission[i].id)){
            this.list.editAvailableUserPermission[i].checked = true;
          }
          for(var j = 0; j < x.permissions.length; j++){
            if (this.list.editAvailableUserPermission[i].id == x.permissions[j].id) {   
              this.list.editAvailableUserPermission[i].checked = true;
              this.list.editAvailableUserPermission[i].disabled = true;        
              let data =  this.managePermissionArray.filter((item,index) =>  this.managePermissionArray.indexOf(item) === index)
              this.selectedCount = data.length;
              this.selectedCountAdd = this.selectedCount;
              this.defaultCount = x.permissions.length
              if(this.list.editAvailableUserPermission[i].checked){
                if(!this.managePermissionArray.includes(this.list.editAvailableUserPermission[i].id)){
                  this.managePermissionArray.push(this.list.editAvailableUserPermission[i].id)           
                  let data =  this.managePermissionArray.filter((item,index) =>  this.managePermissionArray.indexOf(item) === index)
                  this.selectedCount = data.length;
                  this.selectedCountAdd = this.selectedCount;
                  console.log(this.managePermissionArray)
                }
              }
            }
          }
        }
      }
  }
}
}


