<mat-card class="customCard">
  <mat-expansion-panel hideToggle (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title class="titleFlexContainer">
        <div class="title titleFlexItem">
          <b>TRACK STATUS - {{(statuses[statusSelected] ? statuses[statusSelected].name:'Pending') | uppercase}} </b>
        </div>
        <span  class="dot titleFlexItem" [ngStyle]=  "{'background-color': statuses[statusSelected] ? statuses[statusSelected].color:'#e3b950'}">
            <!-- "{'background-color': 'orange': selectedReason === 'PendingApproval',
            'green': selectedReason === 'Approve','red':  selectedReason === 'Reject'}" -->

      </span>
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
      <div *ngIf="panelOpenState">
        <div class="btn btn-link headerFlexItem" style="margin-left:auto;" ><u>Close</u>
        </div>
      </div>
      <div *ngIf="!panelOpenState">
        <div class="btn btn-link headerFlexItem" style="margin-left:auto;" ><u>Update</u>
        </div>
      </div>
    </mat-expansion-panel-header>
    <div *ngIf="panelOpenState">
      <div fxLayout="column" fxLayoutGap="32px">
        <!-- <form [formGroup]="trackStatusForm" (ngSubmit)="onSubmit()"> -->
        <div *ngIf="panelOpenState">
          <mat-radio-group class="smallRadio radioFlexContainer" aria-label="Select an option"
                           [(ngModel)]="statusSelected"
                           (change)="onStatusSelection()">
            <mat-radio-button [value]="'PENDING'" class="radioFlexItem">Pending</mat-radio-button>
            <mat-radio-button [value]="'PENDING_APPROVAL'" class="radioFlexItem">Pending Approval</mat-radio-button>
            <mat-radio-button [value]="'APPROVED'" class="radioFlexItem">Approve</mat-radio-button>
            <mat-radio-button [value]="'DENIED'" class="radioFlexItem">Reject</mat-radio-button>
            <mat-radio-button [value]="'DISABLED'" class="radioFlexItem">Disable</mat-radio-button>
          </mat-radio-group>
        </div>
        <div  class="form-field title-description" *ngIf="!isApproved">
          You don't have permission to perform this action. Please contact your MIBE representative.
        </div>
        <mat-form-field class="form-field rejection-form-field" *ngIf="rejectionReason" appearance="fill" floatLabel="always">
          <mat-label style="font-size: 12px;">
            <b>REASON FOR REJECTION</b>
          </mat-label>
          <mat-select class="custom-backdrop-class"  [multiple]="true" panelClass="filter-select filter-rejection" [disableOptionCentering]="true"  placeholder="Select" [ngModel]="selectedReasons" (selectionChange)="setRejectionReasons($event)">
           
            <mat-option  *ngFor="let reason of rejectionReasons"
              [value]="reason">
              {{ reason }}
            </mat-option>
          </mat-select>
         
        </mat-form-field>
        <div class="form-field rejection-reason" appearance="outline" *ngIf="noteReject">
        <!-- <div class="form-field rejection-reason" appearance="outline" *ngIf="rejectionReason && reasonDetails"> -->
          <div>
            <label>
              <textarea  placeholder="Add reason for rejection ..." [ngModel]="otherReason" (ngModelChange)="setOtherReason($event)"></textarea>
            </label>
          </div>
        </div>

        <div class="form-field" appearance="outline" *ngIf="allowNotify">
          <div>
            <!-- <input type="checkbox" value="notify"> Notify Composer -->
            <mat-checkbox class="notify-margin" [(ngModel)]="notify"  (ngModelChange)="notifyComposer($event);">Notify Composer</mat-checkbox>
          </div>
        </div>
        <div class="form-field">
          <mat-divider></mat-divider>
        </div>
        
        <div class="grid-card" *ngIf="statusSelected !== 'APPROVED'"> 
          <div class="grid-item">
             <h4 class="internal-note">Internal Notes</h4>
                  <textarea  class="notes-box" placeholder="{{rejectionReason ? 'Write here... ' : 'Add notes'}}" [ngModel]="version.internal_status_notes" (ngModelChange)="setInternalNote($event)"></textarea>
                
          </div>
        </div>
       
        <!-- </form> -->

      </div>
    </div>
  </mat-expansion-panel>
</mat-card>
