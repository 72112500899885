import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { Track } from '../models/track.model';
import { TrackService } from '../services/track.service';
import { TrackComposer } from '../models/trackcomposer.model';
import { TrackVersion } from '../models/trackversion.model';
import { CONTAINER_DATA, DialogConfig, SliderDialogService } from '../services/slider-dialog.service';
import { TrackVersionService } from '../services/trackversion.service';
import { forkJoin } from 'rxjs';
import { MenuListService } from '../services/menu-list.service';
import { environment } from '../../../environments/environment';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { PlayerService } from '../services/player.service';

@Component({
  selector: 'app-create-track-version',
  templateUrl: './create-track-version.component.html',
  styleUrls: ['./create-track-version.component.scss']
})
export class CreateTrackVersionComponent implements OnInit {

  track: Track;
  set version(v: TrackVersion) {
    this._version = v;
    if(this.version.title &&  this.version.track_ending && this.track.title && (this.version.title.trim() !== this.track.title)){
      this.track.isValid = false;
     }
     else{
      this.track.isValid = true;
     }
  }
  get version(): TrackVersion {
    return this._version;
  }
  is_version: boolean = true;
  loading: boolean = false;
  composers: TrackComposer[];

  bpmValue: number = 0;
  @Output()
  versionChange = new EventEmitter<TrackVersion>();

  private _version: TrackVersion;
  trackInfo: any;
  vocalDesc:any

  constructor(
    private _sliderDialogService: SliderDialogService,
    private _trackService: TrackService,
    private _trackVersionService: TrackVersionService,
    public api: UserMenuService,
    public config: MenuConfigService,
    @Inject(CONTAINER_DATA) public componentData: DialogConfig,   private _playerService: PlayerService, private _userService: UserService,private _router: Router,
    public list: MenuListService
  ) {
    if (componentData.data?.mytrack.file) {
      this.getTrackInfo();
    }
  }

  newTrack: any = {
    "title": "",
    "description": null,
    "is_exclusive": false,
    "is_featured": false,
    "artist": null,
    "genre": "",
    "sub_genre": "",
    "lyrics": {
      "verse": "",
      "pre_chorus": "",
      "chorus": "",
      "bridge": ""
    },
    "owned_by": "",
    "moods": [],
    "albums": [],
    "metadata": [],
    "instruments": [],
    "vocal": [],
    "themes": [],
    "cowritten_by": [],
    "network_references": [],
    "tv_references": [],
    "artist_references": [],
    "band_references": [],
    "sonic_references": [],
    "vocal_desc": [],
    "instruments_desc": [],
    "versions": [],
    'isValid': true
  }
  trackVersion: any = {
    "calculated_bpm": "",
    "calculated_key": "",
    "duration": 0,
    "tempo": "",
    "is_instrumental": true,
    "is_vocals": false,
    "is_full_version": false,
    "is_main_track":false
  }
  ngOnInit(): void {
    console.log(this.componentData)
    this.track = new Track(this.newTrack)
    this.version = this.trackVersion
    // if (this.componentData.data) {
    //   this.track = new Track(this.componentData.data.track.toJSON());
    //   this.version = new TrackVersion(this.componentData.data.version.toJSON());
    //   this.composers = this.track.composers;
    // }
    this.version.mp3_file =  this.componentData.data.mytrack.file;
    this.version.title = this.componentData.data?.mytrack.file_name ;
    this.versionChange.emit(this._version);
    if(this.track){
    
      if(this.version.title &&  this.version.track_ending && this.track.title){
       this.track.isValid = false;
      }
      else{
       this.track.isValid = true;
      }
     }
  }

  toggleMusicPlayerCollapsed() {

  }

  bpmChange(val: any) {
    this.bpmValue = Math.round(val);
    this.version.calculated_bpm = Math.round(val);
    this.versionChange.emit(this._version);
  }

  saveVersion() {
    if (this.version.title.includes(this.version?.track?.title) && (this.version.title.replace(this.version?.track?.title, "").trim().toLowerCase() == 'narrative')) {
      this.version.is_full_version = true;
    }
    else if (this.version.title.trim().toLowerCase() == 'narrative') {
      this.version.is_full_version = true;
    }
    else {
      this.version.is_full_version = false;
    }

    let id :any= []
    id = this.track.Instrumentsub_category;
    if(this._trackService.cowritten_by.length > 0){
      let newValues =[];
      for(let i=0;i<this._trackService.cowritten_by.length;i++){
        if(this._trackService.cowritten_by[i]?.disable !== true && this._trackService.cowritten_by[i]?.percent > 0){
          newValues.push(this._trackService.cowritten_by[i])
        }
      }
      this._trackService.cowritten_by = newValues
    }
    if (id) {
      this.track.instruments = this.track.instruments.filter(function (e) {
        if (e.id !== id) {
          return e;
        }
      })
      if(this.track &&this.track.Instrumentdescriptions){
      this.track.Instrumentdescriptions = this.track.Instrumentdescriptions.filter(function (e) {
        if (e !== id) {
          return e;
        }
      })
    }
    }
       // <!-- Add Newsuggestion -->

       let tv;
       let network, sonic, band;
      // <!-- Add Newsuggestion -->
       if (this.track.tv_references) {
         tv = this.track.tv_references.filter(function (e) {
           if (e.id !== '') {
             return e;
           }
         })
       }
       if (this.track.network_references) {
         network = this.track.network_references.filter(function (e) {
           if (e.id !== '') {
             return e;
           }
         })
       }

       if (this.track.sonic_references) {
        sonic = this.track.sonic_references.filter(function (e) {
          if (e.id !== '') {
            return e;
          }
        })
      }
      if (this.track.band_references) {
        band = this.track.band_references.filter(function (e) {
          if (e.id !== '') {
            return e;
          }
        })
      }
      if(this.track.support_band_references.length > 0){
        let data=[]
        for(let x of this.track.support_band_references){
          data.push(x.name)
      
        }
        if(this.track?.band_referencesNew_suggestion?.length > 0){
        this.track.band_referencesNew_suggestion =  this.track.band_referencesNew_suggestion.concat(data)
        }
        else{
          this.track.band_referencesNew_suggestion =  data
        }
      }
      if(this.track.support_network_references.length > 0){
        let data =[]
        for(let x of this.track.support_network_references){
          data.push(x.name)
        
        }
        if(this.track?.network_referencesNew_suggestion?.length > 0){
        this.track.network_referencesNew_suggestion = this.track.network_referencesNew_suggestion.concat(data)
        }
        else{
          this.track.network_referencesNew_suggestion = data
        }
      }
      if(this.track.support_tv_references.length > 0){
        let data =[]
        for(let x of this.track.support_tv_references){
          data.push(x.name)
       
        }
        if(this.track?.tv_referencesNew_suggestion?.length > 0){
        this.track.tv_referencesNew_suggestion = this.track.tv_referencesNew_suggestion.concat(data)
        }
        else{
          this.track.tv_referencesNew_suggestion = data
        }
      }
      if(this.track.support_sonic_references.length > 0){
        let data=[]
        for(let x of this.track.support_sonic_references){
          
          data.push(x.name)
       
        }
        if (this.track?.sonic_referencesNew_suggestion?.length > 0){
        this.track.sonic_referencesNew_suggestion = this.track.sonic_referencesNew_suggestion.concat(data)
        }
        else{
          this.track.sonic_referencesNew_suggestion = data
        }
      }
     let Id =0
     this._playerService.trackId.subscribe((value) => {
      if (value !== 0) {
      Id = value
      }
      this.vocalDesc = this.track?.Vocalsdescriptions?.filter((c, index) => {
        return this.track?.Vocalsdescriptions?.indexOf(c) === index;
       });
    })
    if (this.track.Instrumentdescriptions?.length > 0) {
      if (this.track.support_version_ins_desc.length > 0) {
        for (let y of this.track.Instrumentdescriptions) {
          for (let x of this.track.support_version_ins_desc) {
            if (x.id === y) {
              if(this.track.newAddedSuggestion == undefined || this.track.newAddedSuggestion.length == 0){
                this.track.newAddedSuggestion=[]
                this.track.newAddedSuggestion.push(x.name)
                }
                else{
                this.track.newAddedSuggestion.push(x.name)
                }
            }
          }
        }
      }
    }
    let data = {
      "track": Id,
      "title": this.version.title,
      "is_instrumental": (this.version.is_instrumental===false && this.version.is_vocals===false) ? true : this.version.is_instrumental,
      "is_full_version": this.version.is_full_version,
      "is_main_track": this.version.is_main_track,
      "status": this.version.status,
      "cowritten_by": this.filter(this._trackService.cowritten_by) || [],
      "instruments": {
        "sub_category": this.track.Instrumentsub_category || "",
        "descriptions": this.track.Instrumentdescriptions || [],
        // "suggesstion": this.track.instruments.map(x => x.id) || [],
        "suggesstion":[],
        "new_suggestion": this.track.newAddedSuggestion || [],
        'new_ins':this.track.new_ins || [],
        'rm_ins': this.track.rm_ins || [],
        'new_ins_decs': this.track.Instrumentdescriptions || []

      },
     // "tv_references": this.track.tv_references.map(x => x.id),
     // "network_references": this.track.network_references.map(x => x.id),
     // <!-- Add Newsuggestion -->

        "tv_references": {
        "sub_category": tv.map(x=> x.id),
       //"descriptions": this.track.tv_referencesDescriptions || [],
       // "suggesstion":[],
        "new_suggestion": []
    },
    "network_references":{
        "sub_category": network.map(x=> x.id),
        //"descriptions": this.track.network_referencesDescriptions|| [],
        //"suggesstion":[],
        "new_suggestion":  []
    },
       "artist_references": this.track.artist_references.map(x => x.id),
      //"artist_references": [],
     // "sonic_references": [],
      "sonic_references": sonic.map(x=> x.id),
      "band_references": band.map(x => x.id),
      "vocal": {
        "sub_category": this.track.Vocalssub_category || "",
        "descriptions": this.vocalDesc || [],
        // "suggesstion": this.track.Vocalssuggesstion || [],
        "suggesstion": [],
        "new_suggestion": []
      },
      "lyrics_data": {
        "verse": this.track.lyrics.verse?this.track.lyrics.verse:[],
        "pre_chorus": this.track.lyrics.pre_chorus?this.track.lyrics.pre_chorus:[],
        "chorus": this.track.lyrics.chorus?this.track.lyrics.chorus:[],
        "bridge": this.track.lyrics.bridge?this.track.lyrics.bridge:[]
      },
      "is_vocals": this.version.is_vocals,
      "calculated_bpm": Math.round(this.trackInfo? this.trackInfo.bpm: ''),
      "track_ending": this.version.track_ending,
      "calculated_key": this.trackInfo? this.trackInfo.key: '',
      "duration": this.trackInfo ? this.trackInfo.duration ?parseFloat(this.trackInfo.duration).toFixed(2):0 : 0,
      "tempo": this.trackInfo ? this.trackInfo.tempo.id: '',
      "upload_file_id": this.componentData.data.mytrack.id,
      "support_tv_ref":{
        "new_sug":this.track.tv_referencesNew_suggestion || [],
        "rm_sug":this.track.newTv_ref_rm_sug || [],
    },
    
    "support_nw_ref":{
      "new_sug":this.track.network_referencesNew_suggestion || [],
      "rm_sug":this.track.newNetwork_ref_rm_sug || [],
  },
  "support_sonic_ref":{
    "new_sug":this.track.sonic_referencesNew_suggestion || [],
    "rm_sug":this.track.newSonic_ref_rm_sug || [],
},
"support_band_ref":{
  "new_sug":this.track.band_referencesNew_suggestion || [],
  "rm_sug":this.track.newBand_ref_rm_sug || [],
},
    }
    console.log(data);
    this.track.composers = this.composers;
    this.loading = true;
    let url = environment.apiURL + this.config.createTrackVersionAPI;
    this.api.doPOST(url, data).subscribe(x => {
      this.loading = false
      this._playerService.nextTab(true)
      this._playerService.updateWavePlay(false);
      this._playerService.updateIsPlaying(false);
      this._playerService.updateShowMusicPlayer(false);
      this._playerService.play(null, null);
      this._playerService.updatePreviewPlaying(false);
      this._sliderDialogService.close(true);
      localStorage.setItem('editData','yes')
       location.reload()
      
    },
      (error) => {
        this.loading = false;
         if (error.error && error.error.is_logout) {
        //  alert(error.error.error)
           let data = [];
           data.push(error.error.error)
           data.push(false);
           this._playerService.nextTab(true)
           this._playerService.updateWavePlay(false);
           this._playerService.updateIsPlaying(false);
           this._playerService.updateShowMusicPlayer(false);
           this._playerService.play(null, null);
           this._playerService.updatePreviewPlaying(false);
           this._sliderDialogService.close(data);
        }
        //alert(error.message)
      })
    // this._trackVersionService.updateTrackVersion(this.version).subscribe(v=> {
    //   this.componentData.data.track = this.track
    //   this.componentData.data.version = v;
    //   this._sliderDialogService.close(null);
    // });
  }

  toggleTrackDescriptionCollapsed(event: any): void {
    this.track[event.type] = event.data;
  }
  filter(e) {
    let filData = []
    for (let x of e) {
      console.log(e)
      let data = {}
      data['user_id'] = x.id
      data['percent'] = x.percent ?x.percent:0
      filData.push(data)
    }
    return filData
  }

  getTrackInfo() {
    let trackData = {
      "file_path": this.componentData.data.mytrack.file
    }
    let requestUrl = environment.apiURL + `/api/v1/track/process-files/`
    this.api.doPOST(requestUrl, trackData).subscribe(x => {
      let arr;
      arr = x;
      this.trackInfo = arr;
    })
  }

}
