import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PhoneNumber, PhoneNumberTypes } from 'src/app/shared/models/phonenumber.model';
import { User } from 'src/app/shared/models/user.model';
import { environment } from '../../../environments/environment';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { UserMenuService } from '../../shared/services/user-menu.service';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { DatePipe } from '@angular/common';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class CreateAccountComponent implements OnInit {
  loading: boolean = false;
  createAccountForm: FormGroup;  
  errorMessage =0;
  error = ''
  //phoneNumber: any = '';
  currentValue: any = '';
  public customPatterns = {'0': { pattern: new RegExp('\[a-zA-Z\]')}};  
  public numberPattern = {'0': { pattern: new RegExp('^[0-9]')}};
//   phoneControllers:FormControl[] = [];
//   phoneNumberArray = [ {
//     number_type:"mobile",
//     phone_number:""
    
// }];
primaryPhoneControl: FormControl = new FormControl();
phoneControllers: FormControl[] = [];

get phoneNumberTypes(): string[] {
  return Object.keys(PhoneNumberTypes).map((key) => PhoneNumberTypes[key]);
}
value: any ='';
phoneNumber: any = '';
socialControllers:FormControl[] = [];
socialArray = [ {
  social_type:"FACEBOOK",
  value:"",
},
{
  social_type:"TWITTER",
  value:"",
},
{
  social_type:"INSTAGRAM",
  value:"",
},
{
  social_type:"TIKTOK",
  value:"",
},
{
  social_type:"YOUTUBE",
  value:"",
},
{
  social_type:"CLUBHOUSE",
  value:"",
},
{
  social_type:"SNAPCHAT",
  value:"",
},
{
  social_type:"SOUNDCLOUD",
  value:"",
},
{
  social_type:"TWITCH",
  value:"",
}
];
newArray = [ {
  social_type:"FACEBOOK",
  value:"",
},
{
  social_type:"TWITTER",
  value:"",
},
{
  social_type:"INSTAGRAM",
  value:"",
},
{
  social_type:"TIKTOK",
  value:"",
},
{
  social_type:"YOUTUBE",
  value:"",
},
{
  social_type:"CLUBHOUSE",
  value:"",
},
{
  social_type:"SNAPCHAT",
  value:"",
},
{
  social_type:"SOUNDCLOUD",
  value:"",
},
{
  social_type:"TWITCH",
  value:"",
}
];
user = {
  country: '',
  state:''
}
user1: User;
myInfoDetails: any = [];
maxDate = new Date();
socialModal: boolean = false;
dataRemove: boolean = false;
socialNetworkName: any;
socialData: boolean = false;
addedSocialName: boolean = false;
deletedName = ''
indexRemove : number;

date = '';
constructor(
  public accountInfo: FormBuilder,
  public api: UserMenuService, 
  public config: MenuConfigService,  private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.createAccountForm = this.accountInfo.group({      
      email: new FormControl('',[Validators.required,Validators.email]),
      first_name: new FormControl('',[Validators.required]),
      last_name: new FormControl('',[Validators.required]),
      nick_name: new FormControl('',[Validators.required]),
      birth_date: new FormControl('',[Validators.required]),
      address1: new FormControl('',[Validators.required]),
      address2: new FormControl('',[Validators.required]),
      city: new FormControl('',[Validators.required]),
      state: new FormControl(''),
      country: new FormControl(''),
      postal_code: new FormControl('',[Validators.required]),
      phone_number: new FormControl(''),
    //  additional_numbers: new FormControl([]),
      social_media_links: new FormControl(''),
      password: new FormControl(''),
      confirmPassword: new FormControl(''),
      newPassword: new FormControl(''),
    })

    this.getprofile();
  }
  getprofile(){
    this.loading = true;
    let data: any=[];

    let url = environment.apiURL + this.config.doProfile;
      this.api.doGET(url).subscribe(x=>{
        this.loading = false;
        this.myInfoDetails = x || [];
       
        if( this.myInfoDetails && this.myInfoDetails?.social_media_links !== null){
          for (let y of this.socialArray) {
            for (let i of x['social_media_links']) {
              if (y.social_type == i.social_type) {
                y.value = i.value
              }
            }
          }
          for (let i of x['social_media_links']) {
            if (i.social_type !== 'FACEBOOK' && i.social_type !== 'INSTAGRAM' && i.social_type !== 'TWITTER' && i.social_type !== 'TIKTOK' && i.social_type !== 'YOUTUBE' && i.social_type !== 'CLUBHOUSE' && i.social_type !== 'SNAPCHAT' && i.social_type !== 'SOUNDCLOUD' && i.social_type !== 'TWITCH') {
              if (i.social_type)
                this.socialArray.push({
                  social_type: i.social_type,
                  value: i.value
                })
            }
          }
        }
        else{
          this.socialArray = this.socialArray
        }

        this.user = {
          country:  this.myInfoDetails.country,
          state: this.myInfoDetails.state,
        }
        this.user1 = new User(this.myInfoDetails);
        //Set up a form control for each additional number
        this.user1.additional_numbers.forEach(() => {
          this.phoneControllers.push(new FormControl());
        });
      
      },
      (error) => {
        this.loading = false;
      })
  }
  addPhoneNumber(){
     this.phoneControllers.push(new FormControl());
    this.user1.additional_numbers.push(
      new PhoneNumber({ number_type: "mobile" })
    );
    }
    addsociallinks(){
      this.socialModal=false
      this.addedSocialName = true;
      this.socialArray.push({
        social_type:this.socialNetworkName,
        value: this.value
      })
      this.value="";
      // this.myInfoDetails.social_media_links.push({
      //   social_type:this.socialNetworkName,
      //   value: ''
      // })
    }
    getSocialName(val){
      this.socialNetworkName = val;
      if(val !== ''){
        this.socialData = true;
      }
      else{
        this.socialData = false;
      }
    } 
    getSocialUrl(event){
      this.value = event;
       }    
    validateNumber(event, i?:number) {
      const keyCode = event.keyCode;
      const excludedKeys = [8, 37, 39, 46];
      if ((event.key === ' ' || isNaN(Number(event.key)))&&!((
        (keyCode >= 96 && keyCode <= 105) ||
        (excludedKeys.includes(keyCode))))) {
        event.preventDefault();
      }
    }
    getPhoneCode(e, i?:number){
      let l = e.value.length;
      this.currentValue = e.id;
      //this.phoneNumber = '';
      if(l==1){
        if(e.value !== "+"){
          this.phoneNumber = '+1' + e.value;
          if(i!==undefined){
          this.user1.additional_numbers[i].phone_number = this.phoneNumber
          } else {
            this.user1.phone_number = this.phoneNumber;
          }
        }
      }
      else if(this.user1.phone_number == ''){
        this.user1.phone_number =  e.value
      }
    }
      removeNumber(index: number) {
        this.user1.additional_numbers.splice(index, 1);
        this.phoneControllers.splice(index, 1);
      }
      removeLink (index: number){
        this.indexRemove = index;
         this.deletedName = this.socialArray[index].social_type
         
      }
      removeUser (event: string){
        this.dataRemove = false;
        let data =  this.socialArray[this.indexRemove].social_type;
        if(data == event){
        this.socialArray = this.socialArray.filter(function(e) { 
          if(e.social_type !== data){
            return e;
          }
        })
      }
      }
   
  createAccount(){
   if(this.createAccountForm.invalid) {
     //alert('Enter Required Fields')
   }
   if((this.createAccountForm.value.confirmPassword && this.createAccountForm.value.confirmPassword.length < 4) ||  (this.createAccountForm.value.newPassword && this.createAccountForm.value.newPassword.length < 4)){
    this.loading = false;
    this.errorMessage = 1;
    this.error = 'Password must be 8 characters!'
    return ;
  }
   
  if(this.createAccountForm.value.confirmPassword !== this.createAccountForm.value.newPassword){
    this.loading = false;
    this.error = 'New password and Confirm password must be same!'
    this.errorMessage = 1;
    return ;
  }
   let date = {
    newDate: this.createAccountForm.value.birth_date
   }
   let data=  this.datePipe.transform(date.newDate,'yyyy-MM-dd')
   
    let postbody = {
      "email": this.createAccountForm.value.email,
      "first_name": this.createAccountForm.value.first_name,
      "last_name": this.createAccountForm.value.last_name,
      "nick_name": this.createAccountForm.value.nick_name,
      "birth_date": data,
      "address1": this.createAccountForm.value.address1,
      "address2": this.createAccountForm.value.address2,
      "city": this.createAccountForm.value.city,
      "state": this.user.state,
      "country": this.user.country,
      "postal_code": this.createAccountForm.value.postal_code,
      "phone_number": this.user1.phone_number,
      "additional_numbers": this.user1.additional_numbers,
      "social_media_links": this.socialArray,
      "password": this.createAccountForm.value.confirmPassword ? this.createAccountForm.value.confirmPassword : null
    }
    this.loading = true;
    this.socialArray 
    let url = environment.apiURL + this.config.doProfile;
      this.api.doPUT(url,postbody).subscribe(x=>{
        this.loading = false;
        this.errorMessage = 0;
        // this.socialArray = this.newArray
        //this.getprofile()
      },
      (error) => {
        this.loading = false;
        this.errorMessage = 0
        //alert(error.message)
      })
  }
}
