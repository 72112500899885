import { MibeModel } from './../models/mibemodel.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../environments/environment';
import { User } from '../models/user.model';
import { UserPRO } from '../models/user-pro.model';
import { TrackComposer } from '../models/trackcomposer.model';
import { Observable, ReplaySubject, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { APIListResponse } from '../models/apiresponse.model';
import { CSRFInterceptor } from '../interceptors/csrf.interceptor';
import { Token } from '../models/token.model';
import { group } from '@angular/animations';
import { Router } from '@angular/router';

export interface GetUsersOptions {
  isClient?: boolean;
  isArtist?: boolean;
  groupId?: number;
  limit?: number;
  offset?: number;
  page?: number;
  searchString?: string;
  startDate?: Date;
  endDate?: Date;
  // dateType?: 'date_joined'|'last_login',
  dateType?: 'with_upload' | 'last_visited' | 'with_download',
  type?: string;
  ordering_by?: string;
}


@Injectable({
  providedIn: 'root'
})
export class UserService {
  public tokenSubject = new BehaviorSubject(null);
  get isLoggedIn(): boolean {
    return !!this._currentUser;
  }
  public currentUserStream: Observable<User>;
  public _currentUserSubject: ReplaySubject<User> = new ReplaySubject<User>(1);
  public _currentUser: User;
  private _refreshCurrentUser: Observable<User>;

  constructor(
    public http: HttpClient,
    private _transferState: TransferState,
    private _platform: Platform,
    private _router: Router
  ) {
    this.currentUserStream = this._currentUserSubject.asObservable();

    // Try refreshing the current user, or fail if we're not logged in
    this.refreshCurrentUser().subscribe(
      _ => { /* We need to subscribe to this event, to cause it to happen */ },
      err => {
        // User isn't logged in
      }
    );
    this.currentUserStream.subscribe(u => {

    });
  }

  getCurrentUserPRO(): Observable<UserPRO> {
    if (!this._currentUser) { return; }
    // Check if moods are cached from server
    const PRO_KEY = makeStateKey<Object>('currentuserpro');
    if (this._transferState.hasKey(PRO_KEY)) {
      const pro = this._transferState.get<Object>(PRO_KEY, null);
      this._transferState.remove(PRO_KEY);
      return of(new UserPRO(pro));
    }
    let headers = new HttpHeaders();
    return this.http.get<Object>(
      environment.apiURL + '/api/v1/user/pros/' + this._currentUser.id + '/',
      {
        headers: headers
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the mood
        if (!this._platform.isBrowser) {
          this._transferState.set(PRO_KEY, res);
        }
      }),
      map((res) => new UserPRO(res))
    );
  }
  getTosStatusList(u: User) {

    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http
      .get<Object>(
        environment.apiURL + `/api/v1/admin/users/${u.id}/get-tos/`,
        {
          headers: headers,
        }
      )
  }


  updateCurrentUserPRO(pro: UserPRO): Observable<UserPRO> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.put<any>(
      environment.apiURL + '/api/v1/user/pros/' + this._currentUser.id + '/',
      pro.toJSON(),
      {
        headers: headers
      }
    ).pipe(map((res) => new UserPRO(res)));
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
  updateCurrentUser(u: User, password: string = null): Observable<User> {
    let payload = u.toJSON();

    payload.birth_date = this.formatDate(payload.birth_date);
    payload['shows'] = [];
    payload['groups'] = [];
    payload['user_permissions'] = [];
    if (password) {
      payload['password'] = password;
    }
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.put<Object>(
      environment.apiURL + '/api/v1/user/current/',
      payload,
      {
        headers: headers
      }
    ).pipe(map((res) => {
      this._currentUser = new User(res);
      this._currentUserSubject.next(this._currentUser);
      return this._currentUser;
    }));
  }

  updateUser(u: User): Observable<User> {
    let headers = new HttpHeaders();
    let payload = u.toJSON()
    let data = []
    if (payload.birth_date) {
      payload.birth_date = this.formatDate(payload.birth_date);
    }
    else {
      payload.birth_date = null
    }
    payload['is_active'] = true;
    payload['groups'] = [];
    payload['user_permissions'] = [];
    if (payload['pro']) {
      payload['pro'] = payload.pro['id'] ? payload.pro['id'] : payload.pro;
    }
    if (payload['visible_genres'].length > 0) {
      for (let index = 0; index < payload['visible_genres'].length; index++) {
        if (payload['visible_genres'][index]?.id) {
          data.push(payload['visible_genres'][index].id)
        }
        else {
          data.push(payload['visible_genres'][index])
        }
      }
      payload['visible_genres'] = data;
    }
    if (!u.password || u.password == '') {
      delete payload["password"]
    }
    headers = headers.append("Content-Type", "application/json");
    return this.http.put<Object>(
      environment.apiURL + `/api/v1/admin/users/${u.id}/`,
      payload,
      {
        headers: headers
      }
    ).pipe(map((res) => {
      return new User(res);
    }));
  }

  getCurrentUser(): Observable<User> {
    if (this._currentUser) {
      return of(this._currentUser);
    }
    return this.refreshCurrentUser();
  }

  refreshCurrentUser(): Observable<User> {
    // Check if user is cached from server
    const USER_KEY = makeStateKey<Object>('current-user');
    if (this._transferState.hasKey(USER_KEY)) {
      const user = this._transferState.get<Object>(USER_KEY, null);
      this._transferState.remove(USER_KEY);
      this._currentUser = new User(user);
      this._currentUserSubject.next(this._currentUser);

      this.currentUser = new User(user);
      localStorage.setItem("userdata", JSON.stringify(this._currentUser));
      return of(this._currentUser);
    }
    if (this._refreshCurrentUser) {
      return this._refreshCurrentUser;
    }
    let headers = new HttpHeaders();
    this._refreshCurrentUser = this.http.get<Object>(
      environment.apiURL + '/api/v1/user/current/',
      {
        headers: headers
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the genre
        if (!this._platform.isBrowser) {
          this._transferState.set(USER_KEY, res);
        }
        this._refreshCurrentUser = null;
      }, _ => {
        this._currentUserSubject.next(null);
        this._refreshCurrentUser = null;
      }),
      map((res) => {
        this._currentUser = new User(res);
        this._currentUserSubject.next(this._currentUser);
        this.currentUser = new User(res)
        localStorage.setItem("userdata", JSON.stringify(this._currentUser));
        return this._currentUser;
      })
    );
    return this._refreshCurrentUser;
  }

  forgotPassword(email: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<Object>(
      environment.apiURL + '/api/v1/user/forgot/',
      {
        email: email
      },
      {
        headers: headers
      }
    ).pipe(map((res) => {
      let data = res;
      return data;

    }));
  }
  resetNewPassword(email: string, uidb64: string, token: string, password: string): Observable<User> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<Object>(
      environment.apiURL + '/api/v1/user/reset/',
      {
        email: email,
        uidb64: uidb64,
        token: token,
        password: password,
      },
      {
        headers: headers
      }
    ).pipe(map((res) => {
      this.currentUser = new User(res);
      return this.currentUser;
    }));
  }

  resetPassword(uidb64: string, token: string, password: string): Observable<User> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<Object>(
      environment.apiURL + '/api/v1/user/reset/',
      {
        password: password,
        token: token,
        uidb64: uidb64
      },
      {
        headers: headers
      }
    ).pipe(map((res) => {
      this._currentUser = new User(res);
      this._currentUserSubject.next(this._currentUser);
      return this._currentUser;
    }));
  }
  getAuthorizationToken() {
    return this.tokenSubject.asObservable()
  }
  us = localStorage.getItem("userdata")
  currentUser: any = JSON.parse(this.us);
  public loginUser(email: string, password: string, rememberMe: boolean, type: boolean): Observable<User> {
    let headers = new HttpHeaders();
    let data
    if (type === true) {
      data = {
        email: email,
        password: password,
        rememberMe: rememberMe
      }
    }
    else {
      data = {
        username: email,
        password: password,
        rememberMe: rememberMe
      }
    }
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<Object>(
      environment.apiURL + '/api/v1/user/login/',
      data,
      {
        headers: headers
      }
    ).pipe(map((res) => {
      this._currentUser = new User(res);
      if (this._currentUser.is_client === false) {
        this._currentUser = new User(res);
        this.currentUser = new User(res);
        localStorage.setItem("userdata", JSON.stringify(this._currentUser));
        this._currentUserSubject.next(this._currentUser);
        return this._currentUser;
      }
      else {
        let data: any = []
        return data
      }

    }));
  }

  registerUser(u: User, password: string, subscribe: boolean = true, message: string = '', portfolio_link: string = '', groups: any = [], user_permissions: any = []): Observable<User> {
    let data = u.toJSON();
    if (data.birth_date) {
      data.birth_date = this.formatDate(data.birth_date);
    }
    else {
      data.birth_date = null
    }
    data['groups'] = groups;
    data['user_permissions'] = user_permissions;
    data['subscribe'] = subscribe;
    if (password) { data['password'] = password; }
    data['message'] = message;
    data['portfolio_link'] = portfolio_link;
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<Object>(
      environment.apiURL + '/api/v1/admin/users/',
      data,
      {
        headers: headers
      }
    ).pipe(map((res) => {
      this._currentUser = new User(res);
      this._currentUserSubject.next(this._currentUser);
      return this._currentUser;
    }));
  }

  logout(): Observable<boolean> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<Object>(
      environment.apiURL + '/api/v1/user/logout/',
      {
        headers: headers
      }
    ).pipe(
      map(() => {
        this._currentUser = null;
        this._currentUserSubject.next(this._currentUser);

        this.currentUser = null;
        localStorage.setItem("userdata", null);
        location.reload();
        return true;
      })
    );
  }

  logout_V2() {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<Object>(
      environment.apiURL + '/api/v1/user/logout/',
      {
        headers: headers
      }
    );
    // .subscribe(res => {
    //   this._currentUser = null;
    //   this._currentUserSubject.next(this._currentUser);

    //   this.currentUser = null;
    //   localStorage.setItem("token", null);
    //   localStorage.setItem("userdata", null);
    //   this._router.navigate(['/login']);
    // }
    // );
  }

  getCoWriter(email: string): Observable<TrackComposer> {
    let headers = new HttpHeaders();
    return this.http.get<Object>(
      environment.apiURL + '/api/v1/user/cowriters/',
      {
        params: { 'email': email },
        headers: headers
      }
    ).pipe(
      map((res) => new TrackComposer(res))
    );
  }

  getAllUsers(options: GetUsersOptions): Observable<APIListResponse<User>> {
    console.log(options)
    let params = {};
    if (options.isClient != null) {
      params['is_client'] = options.isClient;
    }
    if (options.isArtist != null) {
      params['is_artist'] = options.isArtist;
    }
    if (options.groupId != null) {
      params['group'] = `${options.groupId}`;
    }
    if (options.limit) {
      params['limit'] = options.limit;
    }
    if (options.offset) {
      params['offset'] = options.offset;
    }
    if (options.searchString) {
      params['search'] = options.searchString;
    }
    if (options.type) {
      // params['type'] = options.type;
    }
    if (options.page) {
      params['page'] = options.page;
    }
    if (options.ordering_by && options.type) {
      if (options.ordering_by == "title") {
        params['ordering_by'] = 'first_name,last_name'
      }
      else if (options.ordering_by == "-title") {
        params["ordering_by"] = "-first_name,-last_name";
      } else if (options.ordering_by == "nickname") {
        params["ordering_by"] = "nick_name";
      } else if (options.ordering_by == "-nickname") {
        params["ordering_by"] = "-nick_name";
      } else if (options.ordering_by == "phone") {
        params["ordering_by"] = "phone_number";
      } else if (options.ordering_by == "-phone") {
        params["ordering_by"] = "-phone_number";
      } else if (options.ordering_by == "created_at") {
        params["ordering_by"] = "date_joined";
      } else if (options.ordering_by == "-created_at") {
        params["ordering_by"] = "-date_joined";
      } else if (options.ordering_by == "visited") {
        params["ordering_by"] = "last_login";
      } else if (options.ordering_by == "-visited") {
        params["ordering_by"] = "-last_login";
      } else {
        params["ordering_by"] = options.ordering_by;
      }
    } else if (options.ordering_by) {
      if (options.ordering_by == 'name') {
        params['ordering_by'] = 'first_name,last_name'
      }
      else if (options.ordering_by == "-name") {
        params["ordering_by"] = "-first_name,last_name";
      } else {
        params["ordering_by"] = options.ordering_by;
      }

    }
    // let type = 'date_joined';
    let type = 'with_upload';
    if (options.dateType) {
      type = options.dateType;
    }
    // params[type] = options.type;
    params[type] = true;
    if (options.startDate) {
      // params[`${type}__gte`] = options.startDate.toISOString().split('T')[0];
      params["start_date"] = options.startDate.toISOString().split('T')[0];
    }
    if (options.endDate) {
      // params[`${type}__lte`] = options.endDate.toISOString().split('T')[0];
      params["end_date"] = options.endDate.toISOString().split('T')[0];
    }
    //To speed up the request exclude track counting on genres
    // params['exclude_track_count'] = 'true';
    return this.getUsers(params, options);
  }

  getUserPROs(ids: number[]): Observable<APIListResponse<UserPRO>> {
    let p = new HttpParams({ fromObject: { users: ids.map(id => id.toString()) } });
    // Check if moods are cached from server
    const PROS_KEY = makeStateKey<APIListResponse>('userpros-' + p.toString());
    if (this._transferState.hasKey(PROS_KEY)) {
      const pros = this._transferState.get<APIListResponse>(PROS_KEY, null);
      this._transferState.remove(PROS_KEY);
      return of({
        next: pros.next,
        previous: pros.previous,
        count: pros.count,
        results: pros.results.map((u) => new UserPRO(u))
      });
    }
    let headers = new HttpHeaders();
    return this.http.get<APIListResponse>(
      environment.apiURL + '/api/v1/admin/user/pros/',
      {
        headers: headers,
        params: p
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the mood
        if (!this._platform.isBrowser) {
          this._transferState.set(PROS_KEY, res);
        }
      }),
      map((res) => {
        return {
          next: res.next,
          previous: res.previous,
          count: res.count,
          results: res.results.map((u) => new UserPRO(u))
        }
      })
    );
  }

  updateUserPRO(userId: number, pro: UserPRO): Observable<UserPRO> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.put<any>(
      environment.apiURL + '/api/v1/admin/user/pros/' + userId + '/?users=' + userId,
      pro.toJSON(),
      {
        headers: headers
      }
    ).pipe(map((res) => new UserPRO(res)));
  }

  deleteUser(user: User): Observable<void> {
    let headers = new HttpHeaders();
    return this.http.delete<any>(
      environment.apiURL + '/api/v1/admin/users/' + user.id + '/',
      {
        headers: headers
      }
    );
  }


  private getUsers(params: { [param: string]: string | string[] }, options): Observable<APIListResponse<User>> {
    let p = new HttpParams({ fromObject: params });
    // Check if playlists are cached from server
    const USERS_KEY = makeStateKey<APIListResponse<Object>>('users-' + p.toString());
    if (this._transferState.hasKey(USERS_KEY)) {
      const users = this._transferState.get<APIListResponse<Object>>(USERS_KEY, null);
      this._transferState.remove(USERS_KEY);
      return of({
        next: users.next,
        previous: users.previous,
        count: users.count,
        results: users.results.map((p) => new User(p))
      });
    }
    let url;
    if (options.isClient != null && options.type != 'group') {
      url = environment.apiURL + '/api/v1/admin/users/get-clients/'
    } else if (options.isArtist != null && options.type != 'group') {
      url = environment.apiURL + '/api/v1/admin/users/get-artists/'
    } else {
      url = environment.apiURL + '/api/v1/admin/emailgroup/' + options.groupId + '/assigned-users/';
      // p = new HttpParams({ });
    }
    let headers = new HttpHeaders();
    return this.http.get<APIListResponse>(
      url,
      {
        params: p,
        headers: headers
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the moods
        if (!this._platform.isBrowser) {
          this._transferState.set(USERS_KEY, res);
        }
      }),
      map((res) => {
        return {
          next: res.next,
          previous: res.previous,
          count: res.count,
          results: res.results.map((u) => new User(u))
        };
      })
    );
  }
  // token:any = localStorage.getItem("token");

  //Robert: 6/5/24 - Keep token value current with localStorage at all times
  get token(): string | null {
    return localStorage.getItem("token");
  }

  set token(value: string | null) {
    if (value === null) {
      localStorage.removeItem("token");
    } else {
      localStorage.setItem("token", value);
    }
  }


  getAuthToken(email: string, password: string, rememberMe: boolean): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<Object>(
      environment.apiURL + '/api/v1/token/',
      {
        email: email,
        password: password,
        rememberMe: rememberMe
      },
      {
        headers: headers
      }
    ).pipe(map((res: Token) => {
      this.tokenSubject.next(res.access)
      this.token = res.access
      localStorage.setItem("token", res.access);

      return res;
    }));
  }
}
