<div class="track-info-header">
  <!-- <div class="track-title" style="width:65%;" *ngIf="!displayUserInputs && !data.version.is_main_track && !data.version.is_full_version">
    {{track.title}} - {{composers['title']}} 
  </div>
  <div class="track-title" style="width:65%;" *ngIf="!displayUserInputs && !data.version.is_main_track && data.version.is_full_version">
    {{composers['title']}} 
  </div>
  <div class="track-title" style="width:65%;" *ngIf="!displayUserInputs && data.version.is_main_track">
    {{track.title}} - {{composers['title']}}  - Main
  </div> -->

  <div class="track-title" style="width:65%;" *ngIf="!displayUserInputs">
   {{composers['title']}}
  </div>
  <div class="track-title" style="width:65%;" *ngIf="displayUserInputs">
    {{composers['file_name']}}
  </div>
  <div class="track-user-input" style="width:40%;">
    <mat-slide-toggle class="user-input" [(ngModel)]="displayUserInputs">
      Display Original Track Name
    </mat-slide-toggle>
    <button type="button" class="close-button" (click)="close()">
      <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
          stroke-linejoin="round">
          <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
            <g id="Genre-Options">
              <g id="Close" transform="translate(1439.000000, 32.000000)">
                <path d="M24,0 L0,24" id="Path"></path>
                <path d="M0,0 L24,24" id="Path"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </button>
  </div>
</div>

<mat-dialog-content>
  <div class="track-info-wrapper">
    <div class="track-info-version">
      <div class="label">Version</div>
      <span class="title">{{ composers['title'] }} - {{ composers['duration'] | duration}}</span>
    </div>
    <div class="track-denied-block" *ngIf="composers['status']=='DENIED'">
      <mat-accordion>
        <mat-expansion-panel [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Denied
              <div class="btn  headerFlexItem panel-expansion-indication">
                <span *ngIf="panelOpenState">-</span>
                <span *ngIf="!panelOpenState">+</span>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div *ngIf="rejectedReasons!=null || rejectedReasons!=undefined">
            <strong>Reason for rejection</strong>
            <ul>
              <li *ngFor="let reason of rejectedReasons">{{reason}}</li>
            </ul>
          </div>
          <button *ngIf="!trackversion.is_full_version" mat-flat-button color="primary" class="action-btn" (click)="replaceTrackVersion()">Replace Version</button>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="track-meta track-meta-split" *ngIf="composers['status']!='DENIED'">
      <div class="track-meta-item">
        <span class="track-meta-label">Genre:</span>
        <span class="track-meta-value">
          <ng-container>
            {{ composers['genre']}}
          </ng-container>
          <ng-container>

          </ng-container>
        </span>
      </div>
      <div class="track-meta-item">
        <span class="track-meta-label">Key:</span>
        <span class="track-meta-value">
          {{ composers['calculated_key']}}
        </span>
      </div>
      <div class="track-meta-item">
        <span class="track-meta-label">Sub-Genre:</span>
        <span class="track-meta-value">
          {{ composers['sub_genre']}}
        </span>
      </div>
      <div class="track-meta-item">
        <span class="track-meta-label">Ending:</span>
        <span class="track-meta-value">
          {{ composers['track_ending']}}
        </span>
      </div>
      <div class="track-meta-item">
        <span class="track-meta-label">Moods:</span>
        <span class="track-meta-value">
          <ng-container *ngFor="let mood of composers['moods']; last as isLast; trackBy: moodsTrackBy">
            {{ mood.name }}<ng-container *ngIf="!isLast">, </ng-container>
          </ng-container>
        </span>
      </div>
      <div class="track-meta-item" >
          <span class="track-meta-label">BPM:</span>
          <span class="track-meta-value">
            {{ composers['calculated_bpm']}}
          </span>
        </div>
        <div class="track-meta-item" >
          <span class="track-meta-label">Tempo:</span>
          <span class="track-meta-value">
            {{ composers['tempo_value']}}
          </span>
        </div>
    </div>
    <div class="track-meta" *ngIf="composers['status']!='DENIED'">
      <div class="track-meta-full">
        <mat-table [dataSource]="dataTable" class="track-composer-table" multiTemplateDataRows>
          <ng-container matColumnDef="composer">
            <mat-header-cell *matHeaderCellDef class="track-meta-label">COMPOSER</mat-header-cell>
            <mat-cell *matCellDef="let row" class="track-meta-value" style="font-size: 12px;">{{row.composerFirstName}}
              {{row.composerLastName}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="composer_pro">
            <mat-header-cell *matHeaderCellDef class="track-meta-label">COMPOSER PRO</mat-header-cell>
            <mat-cell *matCellDef="let row" class="track-meta-value" style="margin-left:3px;">{{ row.composerSociety }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="composer_percentage">
            <mat-header-cell *matHeaderCellDef class="track-meta-label">COMPOSER %</mat-header-cell>
            <mat-cell *matCellDef="let row" class="track-meta-value">{{ row.composerShare }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="publisher_name">
            <mat-header-cell *matHeaderCellDef class="track-meta-label">PUB</mat-header-cell>
            <mat-cell *matCellDef="let row" class="track-meta-value" style="text-transform: none;">{{ row.publisherName
              | titlecase}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="publisher_pro">
            <mat-header-cell *matHeaderCellDef class="track-meta-label">PUB PRO</mat-header-cell>
            <mat-cell *matCellDef="let row" class="track-meta-value">{{ row.publisherSociety }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="publisher_percentage">
            <mat-header-cell *matHeaderCellDef class="track-meta-label">PUB %</mat-header-cell>
            <mat-cell *matCellDef="let row" class="track-meta-value">{{ row.publisherShare }}</mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns" class="main-row"></mat-row>
        </mat-table>
      </div>
    </div>
    <!-- <div class="track-meta" >
        <div class="track-meta-item">
          <span class="track-meta-label">Tags:</span>
          <span class="track-meta-value">
            <ng-container >
              <a >
              </a><ng-container > </ng-container>
            </ng-container>
          </span>
        </div>
      </div> -->
      <!-- <div class="track-meta" [ngClass]="{'display-user-inputs': displayUserInputs}"> -->
    <div class="track-meta">
      <div class="track-meta-item">
        <span class="track-meta-label">Instrumentation:</span>
        <span class="track-meta-value">
          <ng-container
            *ngFor="let instrument of instrumentationComposer; last as isLast; trackBy: instrumentsTrackBy">
            <span [class]="getUserInputsColor(instrument)">{{ instrument.name }}</span>
            <ng-container *ngIf="!isLast">, </ng-container>
          </ng-container>
        </span>
      </div>
      <div class="track-meta-item">
        <span class="track-meta-label">TV / Network References:</span>
        <span class="track-meta-value">
          <ng-container
            *ngFor="let netRef of nwComposer; last as isLast; trackBy: networkReferencesTrackBy">
            <span [class]="getUserInputsColor(netRef)">{{ netRef.name }}</span>
            <ng-container *ngIf="!isLast">, </ng-container>
          </ng-container>
        </span>
      </div>
      <div class="track-meta-item">
        <span class="track-meta-label">Sonic References:</span>
        <span class="track-meta-value">
          <ng-container
            *ngFor="let sonicRef of sonicComposer; last as isLast; trackBy: sonicReferencesTrackBy">
            <span [class]="getUserInputsColor(sonicRef)">{{ sonicRef.name }}</span>
            <ng-container *ngIf="!isLast">, </ng-container>
          </ng-container>
        </span>
      </div>
    </div>
    <!-- <button class="edit-track-button" >EDIT TRACK VERSION</button> -->
  </div>
</mat-dialog-content>