import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { UserService } from '../../shared/services/user.service';
@Component({
  selector: 'app-co-writers',
  templateUrl: './co-writers.component.html',
  styleUrls: ['./co-writers.component.scss']
})
export class CoWritersComponent implements OnInit {

  coWriterForm: FormGroup;
  constructor(public fb: FormBuilder,
    private cd: ChangeDetectorRef, 
    public proInfo: FormBuilder,
    public api: UserMenuService, 
    public config: MenuConfigService, 
    public user: UserService
  ) { }
  loading: boolean = false;
  allCoWriter:any = [];
  userDetails:any;
  error:string =''
  ngOnInit(): void {
    this.user.currentUserStream.subscribe(x=>{
      console.log(x)
      this.userDetails = x;
    })
    this.coWriterForm = this.fb.group({
      first_name: new FormControl('',[Validators.required]),
      last_name: new FormControl('',[Validators.required]),
      email: new FormControl('',[Validators.required,Validators.email])
    })
    this.getCoWriterDetails()
  }
  canAddCoWriter(): boolean {
    if (!this.userDetails.unlimited_cowriters) {
      if (!this.userDetails.cowriter_quanity_limit) return false;
      if (
        this.userDetails.cowriter_quanity_limit <
        this.userDetails.allowed_cowriters.length + 1
      )
        return false;
    }
    if (this.allCoWriter?.length === this.userDetails?.cowriter_quanity_limit && this.userDetails.unlimited_cowriters === false) {
      return false
    }

    if (
      !this.coWriterForm.value.first_name ||
      !this.coWriterForm.value.last_name ||
      !this.coWriterForm.value.email
    )
      return false;
    if (
      this.userDetails.allowed_cowriters.some(
        (cowriter) => cowriter.email == this.coWriterForm.value.email
      )
    )
      return false;
      if(this.userDetails.email === this.coWriterForm.value.email){
        this.error =`Same user can't be allowed as a co-writer`
        return false;
      }
      else{
        this.error =''
      }
     
    return true;
  }
  addCoWriter(){    
   
    let postbody = {
      'first_name': this.coWriterForm.value.first_name,
      'last_name': this.coWriterForm.value.last_name,
      'email':  this.coWriterForm.value.email
    };
    
    this.loading = true;
    let url = environment.apiURL + `/api/v1/admin/users/${this.userDetails.id}/get-cowriter/`;
      this.api.doPUT(url,postbody).subscribe(x=>{
        this.loading = false;
        this.coWriterForm.reset();
        this.getCoWriterDetails()
      },
      (error) => {
        this.loading = false;
        //alert(error.message)
      })
  
  }
  checkEmail(value){
    this.error =''
    if(this.userDetails.email === this.coWriterForm.value.email){
      this.error =`Same user can't be allowed as a co-writer`
      return false;
    }
    else{
      this.error =''
    }
  }
  getCoWriterDetails(){
    this.loading = true;
    let url = environment.apiURL + `/api/v1/admin/users/${this.userDetails.id}/get-cowriter-for-track/`;
      this.api.doGET(url).subscribe(x=>{
        this.loading = false;
        this.allCoWriter = x || [];
        this.allCoWriter =  this.allCoWriter.results[0].allowed_cowriters
        console.log(x)
      },
      (error) => {
        this.loading = false;
      })
  }

  deleteCoWriter(coWriterId) {
    this.loading = true;
    let url = environment.apiURL + `/api/v1/admin/users/${coWriterId}/`;
    this.api.doDelete(url).subscribe(x => {
      this.loading = false;
      this.getCoWriterDetails();
    },
    (error) => {
      this.loading = false;
    })
  }
}
