import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserService } from '../shared/services/user.service';

export interface Data {
disable: boolean;
error:string;
}
@Component({
  selector: 'app-composer-alert',
  templateUrl: './composer-alert.component.html',
  styleUrls: ['./composer-alert.component.scss']
})
export class ComposerAlertComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ComposerAlertComponent>
    ,@Inject(MAT_DIALOG_DATA) public data: Data, public _userService: UserService,
    private _router: Router,) { }

  ngOnInit() {

  }

  close() {
    this.dialogRef.close();
  }
  logout(){
    this._userService.logout().subscribe(()=> {
      this._router.navigate(['/login']);
    }, (error) => {
      console.log(error);
      //alert("An error ocurred while logging out. Check your connections and try again");
    });
  }
}
