   <div class="create-track general">
    <div class="section">
      
    <div class="title">
        <h3>Track Details<span class="allfields"><small>*All fields required </small></span></h3>
        <p>Please enter the details below to begin your submission</p>
    </div>
    <div  class="check-titletrack" style="margin-bottom: 2%;">
      <div class="meta-data edit-track">
        <mat-checkbox class="checkbox-margin" >Use version as main track</mat-checkbox>
    </div>
    </div>
    <div class="mat-card card-cratetrack">
   
    
    <div class="grid-container">
  
        <div class="grid-item">
            <mat-form-field appearance="fill">
                <mat-label>Parent Track</mat-label>
                <mat-select  [(value)]="selectedparent"  [disableOptionCentering]="true"  placeholder="Select">
                  <mat-option value="Select">select</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="grid-item">
            <mat-form-field appearance="fill">
                <mat-label>Version</mat-label>
                <mat-select [(value)]="selectedversion"  [disableOptionCentering]="true"  placeholder="Select" >
                  <mat-option value="Select">select</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        
    </div>
    <div class="grid-card">
        <div class="grid-item">
            <mat-form-field appearance="fill" class="trackend-group">
                <mat-label>Track Ending</mat-label>
                <mat-select  [(value)]="selectedtrackend"  [disableOptionCentering]="true"  placeholder="Select">
                  <mat-option value="Select">select</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="meta-data edit-track">
       
        <mat-checkbox class="checkbox-margin" >Full length?</mat-checkbox>
        <mat-radio-group >
          <mat-radio-button name="instrumental" class="checkbox-margin " [value]="true">Instrumental</mat-radio-button>
          <mat-radio-button name="vocals" class="checkbox-margin " [value]="false">Vocals</mat-radio-button>
        </mat-radio-group>
       
    </div>
    
  </div>
  <div class="co-writer" style="margin-bottom: 9%;">
    <mat-expansion-panel hideToggle (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title class="panel-title">
            CO-WRITERS - TRACK VERSION
            <div *ngIf="panelOpenState">
              <div class="btn btn-link headerFlexItem" style="position: absolute;
              right: 38px;font-weight: 100;
              text-transform: capitalize;">
                <u>Edit</u>
               
              </div>
            </div>
            <div *ngIf="!panelOpenState">
              <div class="btn btn-link headerFlexItem" style="position: absolute;
              right: 38px;font-weight: 100;
              text-transform: capitalize;">
                <u>Add</u>
                <u class="svg-text"><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg></u>
              </div>
            </div>
          </mat-panel-title>
          <mat-panel-description>
            </mat-panel-description>
        </mat-expansion-panel-header>
        
        <div class="grid-card card-data">
          <div class="grid-item">
              <mat-form-field appearance="fill">
                  <mat-label>Co-writers</mat-label>
                  <mat-select  [(value)]="selectedtrackend"  [disableOptionCentering]="true"  placeholder="Select">
                    <mat-option value="Select">select</mat-option>
                  </mat-select>
              </mat-form-field>
          </div>
      </div>
      <div class="grid-card card-data1">
        <div class="grid-item">
          <p>Joe Mcinrish</p>
         <p class="percent-data"><span>100%</span></p> 
         <p class="percent-data1"><span>-</span></p> 
         <p class="percent-data2"><span>+</span></p> 
         <p class="percent-data3"><span>Remove</span></p>
         
        </div>
        
        </div>
        <div class="grid-card card-data1">
          <div class="grid-item">
            <p>Joe Mcinrish</p>
           <p class="percent-data"><span>100%</span></p> 
           <p class="percent-data1"><span>-</span></p> 
           <p class="percent-data2"><span>+</span></p> 
           <p class="percent-data3"><span>Remove</span></p>
           
          </div>
          
          </div>
     
      <p class="card-portion">The co-writer’s share is based on a portion of 100% of a songwriter’s share.</p>
      </mat-expansion-panel>
  </div><hr>
  <div class="title" style="margin-top: 5%;">
    <h3>Track Description<span class="allfields"><small>*All categories required </small></span></h3>
    <p>Please review and confirm the following tags assigned to the parent track for accuracy</p>
</div>
  <div class="co-writer">
    <mat-accordion>
    <mat-expansion-panel hideToggle style="margin-bottom: 4%;" (opened)="inspanelOpenState = true" (closed)="inspanelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title class="panel-title">
            <svg xmlns="http://www.w3.org/2000/svg"  matTooltipPosition="above" matTooltip=" Please be sure to select and input all the instruments used in this track" matTooltipClass="tooltip-white custom-tooltip " height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg>
           <p>INSTRUMENTATION </p>
            <div *ngIf="inspanelOpenState">
              <div class="btn btn-link headerFlexItem" style="position: absolute;top:25px;
              right: 38px;font-weight: 100;
              text-transform: capitalize;">
                 <u><svg xmlns="http://www.w3.org/2000/svg" style="margin-top: -4px;
                  margin-right: -7px;" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 13H5v-2h14v2z"/></svg></u>
               
              </div>
            </div>
            <div *ngIf="!inspanelOpenState">
              <div class="btn btn-link headerFlexItem" style="position: absolute;top:18px;
              right: 38px;font-weight: 100;
              text-transform: capitalize;">
               <u>Edit</u>
               <u class="svg-text"><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg></u>
              </div>
            </div>
          </mat-panel-title>
          <mat-panel-description>
            </mat-panel-description>
        </mat-expansion-panel-header>
        
        <div class="grid-container card-data">
  
            <div class="grid-item">
                <mat-form-field appearance="fill">
                    <mat-label>Subcategory</mat-label>
                    <mat-select  >
                        <mat-option ></mat-option>
                    </mat-select>
                   
                </mat-form-field>
            </div>
            <div class="grid-item">
                <mat-form-field appearance="fill">
                    <mat-label>Description</mat-label>
                    <mat-select  >
                        <mat-option ></mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            
        </div>
        <div class="grid-card">
            <div class="grid-item">
                <mat-form-field appearance="fill">
                    
                    <input matInput class="search-text" placeholder="Type a single suggestion or seperate mutiple entries with commas ie. tag1 , tag2" >
                </mat-form-field>
            </div>
        </div>
        <div class="tagnames">
            <div class="tagnames-data">
                <p>Tag name 2 <span class="close-btn1">&times;</span></p>
            </div>
            <div class="tagnames-data">
                <p>Tag name 2 <span class="close-btn1">&times;</span></p>
            </div>
        </div>
        <button class="btn save-btn" style="margin-top:5%;" mat-button>continue & save</button>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle style="margin-bottom: 4%;" (opened)="tvnpanelOpenState = true" (closed)="tvnpanelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title class="panel-title">
            <svg xmlns="http://www.w3.org/2000/svg" matTooltip="Please input all networks and programs you feel reference this piece of music. i.e Bravo,  VH1, ABC, The Real Housewives, Love & Hip Hop, Shark Tank" matTooltipPosition="above" matTooltipClass="tooltip-white custom-tooltip " height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg>
           <p>TV / NETWORK REFRENCES  </p>
            <div *ngIf="tvnpanelOpenState">
              <div class="btn btn-link headerFlexItem" style="position: absolute;top:25px;
              right: 38px;font-weight: 100;
              text-transform: capitalize;">
                <u><svg xmlns="http://www.w3.org/2000/svg" style="margin-top: -4px;
                  margin-right: -7px;" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 13H5v-2h14v2z"/></svg></u>
               
              </div>
            </div>
            <div *ngIf="!tvnpanelOpenState">
              <div class="btn btn-link headerFlexItem" style="position: absolute;top:18px;
              right: 38px;font-weight: 100;
              text-transform: capitalize;">
               <u>Edit</u>
               <u class="svg-text"><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg></u>
              </div>
            </div>
          </mat-panel-title>
          <mat-panel-description>
            </mat-panel-description>
        </mat-expansion-panel-header>
        
        <div class="card-data tv-ref">
            <p>Please enter as accurately as possible any relevant TV and/or Network references</p>
            <div class="grid-card"> 
            <div class="grid-item">
                <h4>TV References</h4>
                <mat-form-field  appearance="fill">
                    <textarea matInput placeholder="Enter your tag suggestions ..."></textarea>
                  </mat-form-field>
                  <p  class="text-content">Seperate suggestions with commas</p>
            </div>
            </div>
            <div class="grid-card">
            <div class="grid-item">
                <h4 style="margin-top: 8%;">Network References</h4>
                <mat-form-field  appearance="fill">
                    <textarea matInput placeholder="Enter your tag suggestions ..."></textarea>
                  </mat-form-field>
                <p class="text-content">Seperate suggestions with commas</p>
            </div>
        </div>
        </div>
        
       
       
        <button class="btn save-btn" style="margin-top:5%;"  mat-button>continue & save</button>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle style="margin-bottom: 4%;" (opened)="sonpanelOpenState = true" (closed)="sonpanelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title class="panel-title">
            <svg xmlns="http://www.w3.org/2000/svg" matTooltip="Please add in all information referencing any artists or composers that create this style  of music. i.e Drake, Hans Zimmer, Foo Fighters" matTooltipPosition="above" matTooltipClass="tooltip-white custom-tooltip " height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg>
           <p>SONIC REFRENCES  </p>
            <div *ngIf="sonpanelOpenState">
              <div class="btn btn-link headerFlexItem" style="position: absolute;top:25px;
              right: 38px;font-weight: 100;
              text-transform: capitalize;">
                  <u><svg xmlns="http://www.w3.org/2000/svg" style="margin-top: -4px;
                    margin-right: -7px;" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 13H5v-2h14v2z"/></svg></u>
               
              </div>
            </div>
            <div *ngIf="!sonpanelOpenState">
              <div class="btn btn-link headerFlexItem" style="position: absolute;top:18px;
              right: 38px;font-weight: 100;
              text-transform: capitalize;">
               <u>Edit</u>
               <u class="svg-text"><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg></u>
              </div>
            </div>
          </mat-panel-title>
          <mat-panel-description>
            </mat-panel-description>
        </mat-expansion-panel-header>
        
        <div class="card-data tv-ref">
            <p>Please enter as accurately as possible any relevant TV and/or Network references</p>
            <div class="grid-card"> 
            <div class="grid-item">
                <h4>ARTIST References</h4>
                <mat-form-field  appearance="fill">
                    <textarea matInput placeholder="Enter your tag suggestions ..."></textarea>
                  </mat-form-field>
                  <p  class="text-content">Seperate suggestions with commas</p>
            </div>
            </div>
            <div class="grid-card">
            <div class="grid-item">
                <h4 style="margin-top: 8%;">Band References</h4>
                <mat-form-field  appearance="fill">
                    <textarea matInput placeholder="Enter your tag suggestions ..."></textarea>
                  </mat-form-field>
                <p class="text-content">Seperate suggestions with commas</p>
            </div>
        </div>
        </div>
        
       
       
        <button class="btn save-btn" style="margin-top:5%;" mat-button>continue & save</button>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle style="margin-bottom: 4%;" (opened)="lyricpanelOpenState = true" (closed)="lyricpanelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title class="panel-title">
            <svg xmlns="http://www.w3.f/2000/svg" matTooltip="Please list out the lyrics to this song if applicable.

              " matTooltipPosition="above" matTooltipClass="tooltip-white custom-tooltip " height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg>
           <p>LYRICS  </p>
            <div *ngIf="lyricpanelOpenState">
              <div class="btn btn-link headerFlexItem" style="position: absolute;top:25px;
              right: 38px;font-weight: 100;
              text-transform: capitalize;">
                  <u><svg xmlns="http://www.w3.org/2000/svg" style="margin-top: -4px;
                    margin-right: -7px;" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 13H5v-2h14v2z"/></svg></u>
               
              </div>
            </div>
            <div *ngIf="!lyricpanelOpenState">
              <div class="btn btn-link headerFlexItem" style="position: absolute;top:18px;
              right: 38px;font-weight: 100;
              text-transform: capitalize;">
               <u>Edit</u>
               <u class="svg-text"><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg></u>
              </div>
            </div>
          </mat-panel-title>
          <mat-panel-description>
            </mat-panel-description>
        </mat-expansion-panel-header>
        
        <div class="card-data tv-ref">
            <p>Please input lyrics in the order they are found within the track</p>
            <div class="tagnames" style="margin-bottom: 8%;">
                <div class="tagnames-data">
                    <p><span class="close-btn1" style="margin-right:19px;">&#43;</span>Verse </p>
                </div>
                <div class="tagnames-data">
                    <p><span class="close-btn1" style="margin-right:19px;">&#43;</span>Pre-Chorus </p>
                </div>
                <div class="tagnames-data">
                    <p><span class="close-btn1" style="margin-right:19px;">&#43;</span>Chorus </p>
                </div>
                <div class="tagnames-data">
                    <p><span class="close-btn1" style="margin-right:19px;">&#43;</span>Bridge </p>
                </div>
            </div><hr>
            <div class="grid-card"> 
            <div class="grid-item">
                <h4 style="margin-top: 4%;"><strong>Verse 1</strong><span class="close-btn1" style="float: right;">&times;</span></h4>
                <mat-form-field  appearance="fill">
                    <textarea matInput placeholder="Writing arranged with a metrical rhythm, typically having a rhyme."></textarea>
                  </mat-form-field>
                
            </div>
            </div>
            <div class="grid-card">
            <div class="grid-item">
                <h4 style="margin-top: 2%;"><strong>Pre-Chorus</strong><span class="close-btn1" style="float: right;">&times;</span></h4>
                <mat-form-field  appearance="fill">
                    <textarea matInput placeholder="Suggestion 1, Suggestion 2, ..."></textarea>
                  </mat-form-field>
               
            </div>
        </div>
        <div class="grid-card">
            <div class="grid-item">
                <h4 style="margin-top: 2%;"><strong>Chorus</strong><span class="close-btn1" style="float: right;">&times;</span></h4>
                <mat-form-field  appearance="fill">
                    <textarea matInput placeholder="Suggestion 1, Suggestion 2, ..."></textarea>
                  </mat-form-field>
               
            </div>
        </div>
        <div class="grid-card">
            <div class="grid-item">
                <h4 style="margin-top: 2%;"><strong>Bridge</strong><span class="close-btn1" style="float: right;">&times;</span></h4>
                <mat-form-field  appearance="fill">
                    <textarea matInput placeholder="Suggestion 1, Suggestion 2, ..."></textarea>
                  </mat-form-field>
               
            </div>
        </div>
        </div>
        
       
       
        <button class="btn save-btn" style="margin-top:5%;" mat-button>continue & save</button>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle style="margin-bottom: 4%;" (opened)="vocpanelOpenState = true" (closed)="vocpanelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title class="panel-title">
            <svg xmlns="http://www.w3.org/2000/svg" matTooltip="Please note if any vocals are used in this song. Vocals can be male/female vocals, choirs,  and chants" matTooltipPosition="above" matTooltipClass="tooltip-white custom-tooltip " height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg>
           <p>VOCALS </p>
            <div *ngIf="vocpanelOpenState">
              <div class="btn btn-link headerFlexItem" style="position: absolute;top:25px;
              right: 38px;font-weight: 100;
              text-transform: capitalize;">
                 <u><svg xmlns="http://www.w3.org/2000/svg" style="margin-top: -4px;
                  margin-right: -7px;" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 13H5v-2h14v2z"/></svg></u>
               
              </div>
            </div>
            <div *ngIf="!vocpanelOpenState">
              <div class="btn btn-link headerFlexItem" style="position: absolute;top:18px;
              right: 38px;font-weight: 100;
              text-transform: capitalize;">
               <u>Edit</u>
               <u class="svg-text"><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg></u>

              </div>
            </div>
          </mat-panel-title>
          <mat-panel-description>
            </mat-panel-description>
        </mat-expansion-panel-header>
        
        <div class="grid-container card-data">
  
            <div class="grid-item">
                <mat-form-field appearance="fill">
                    <mat-label>Type</mat-label>
                    <mat-select  >
                        <mat-option ></mat-option>
                    </mat-select>
                   
                </mat-form-field>
            </div>
            <div class="grid-item">
                <mat-form-field appearance="fill">
                    <mat-label>Description</mat-label>
                    <mat-select  >
                        <mat-option ></mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            
        </div>
        <div class="grid-card">
            <div class="grid-item">
                <mat-form-field appearance="fill">
                    
                    <input matInput class="search-text" placeholder="Typing a name to search" >
                </mat-form-field>
            </div>
        </div>
        <div class="tagnames">
            <div class="tagnames-data">
                <p>Tag name 2 <span class="close-btn1">&times;</span></p>
            </div>
            <div class="tagnames-data">
                <p>Tag name 2 <span class="close-btn1">&times;</span></p>
            </div>
        </div>
        <button class="btn save-btn" style="margin-top:5%;" mat-button>continue & save</button>
      </mat-expansion-panel>
     </mat-accordion>
  </div>
  
</div>
  <button class="btn save-btn1"  mat-button>Save track version</button>
  </div>
