import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpHeaders } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort, MatSortable } from '@angular/material/sort';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EditNewTrackVersionComponent } from '../edit-new-track-version/edit-new-track-version.component';
import { EditNewTrackComponent } from '../edit-new-track/edit-new-track.component';
import { Track } from '../models/track.model';
import { TrackStatusValue } from '../models/trackstatus-value.model';
import { TrackVersion } from '../models/trackversion.model';
import { User } from '../models/user.model';
import { TrackStatusPipe } from '../pipes/trackstatus.pipe';
import { DialogService } from '../services/dialog.service';
import { MenuListService } from '../services/menu-list.service';
import { PlayerService, PlayingItem } from '../services/player.service';
import { SliderDialogService } from '../services/slider-dialog.service';
import { Filters, TrackService } from '../services/track.service';
import { UserMenuService } from '../services/user-menu.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-track-new-list',
  templateUrl: './track-new-list.component.html',
  styleUrls: ['./track-new-list.component.scss'],
  animations: [
    trigger('rowInOut', [
      transition(':enter', [
        style({
          opacity: '0',
          height: '0',
          paddingTop: '0',
          overflow: 'hidden',
          paddingBottom: '0',
        }),
        animate(
          '0.7s cubic-bezier(0.19, 1, 0.22, 1)',
          style({
            opacity: '1',
            height: '*',
          })
        ),
      ]),
      transition(':leave', [
        style({
          opacity: '1',
          height: '*',
          overflow: 'hidden',
        }),
        animate(
          '0.7s cubic-bezier(0.19, 1, 0.22, 1)',
          style({
            opacity: '0',
            height: '0',
            paddingTop: '0',
            paddingBottom: '0',
          })
        ),
      ]),
    ]),
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', overflow: 'hidden' })
      ),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
  providers: [TrackStatusPipe],
})

export class TrackNewListComponent implements OnInit, OnDestroy, AfterViewInit {
  sample: any = { "id": 90848, "title": "Testsong Track", "status": "PENDING", "artist": { "id": 9120, "artist_name": "song list", "name": "song list", "user_id": 15588 }, "genre": { "id": 1363, "name": "Blues" }, "sub_genre": { "id": 1365, "name": "Blues Rock" }, "created_at": "2022-07-26T22:05:15.401663-07:00", "updated_at": "2022-07-26T22:16:37.837331-07:00", "is_active": true, "top_version": null, "track_approved_at": null, "versions": [{ "id": 337142, "title": "Testsong Track", "created_at": "2022-07-27T05:05:15.493771Z", "status": "PENDING", "approved_at": null, "is_full_version": true, "is_main_track": false, "is_instrumental": true, "is_vocals": false, "duration": 33.53, "wav_file": "https://d1fy9cxdgutbzc.cloudfront.net/media/uploads/original_audio_files/2022/07/26/File1_1.wav", "mp3_file": "https://d1fy9cxdgutbzc.cloudfront.net/media/uploads/original_audio_files/2022/07/26/File1_1.wav" }, { "id": 337143, "title": "Sting", "created_at": "2022-07-27T05:06:05.105313Z", "status": "PENDING", "approved_at": null, "is_full_version": false, "is_main_track": false, "is_instrumental": true, "is_vocals": false, "duration": 95.17138671875, "wav_file": "https://d1fy9cxdgutbzc.cloudfront.net/media/uploads/original_audio_files/2022/07/26/MIBE_on_the_judgement_day_vox.wav", "mp3_file": "https://d1fy9cxdgutbzc.cloudfront.net/media/uploads/original_audio_files/2022/07/26/MIBE_on_the_judgement_day_vox.wav" }] }
  @Input()
  set dataSource(data: any[]) {
    this._dataSource = data;
  }

  get listDataSource() {
    let data = (this._dataSource && this._dataSource['source'] && this._dataSource['source']['_value']) ? this._dataSource['source']['_value'] : this._dataSource
    return data;
  }

  disableBtn: any = 'Disabled';
  denieBtn: any = 'Denied';
  approveBtn: any = 'Approved';
  pendingApproveBtn: any = 'Pending Approval';
  opened: number = 0;
  openedVersion: number = 0;
  pendingBtn: any = 'Pending';
  versions: any = []
  expandedTrack: Observable<Track> = null;
  expandedTrackSubject = new BehaviorSubject<Track>(null);
  currentUser: User;
  loadingTracks = true
  private _isPlaying: boolean = false;
  wavePlay: boolean = true;
  private _currentlyPlaying: PlayingItem;
  private _dataSource: any
  private _subscriptions: Subscription[] = [];
  private _dataSourceSubscription: Subscription = null;
  private _sortValue: MatSortable;
  @Output()
  sortChange: EventEmitter<MatSortable> = new EventEmitter<MatSortable>();
  @Input()
  set sortValue(data: string) {
    const start = data && data[0] === '-' ? 'desc' : 'asc';
    const id = data && data[0] === '-' ? data.substring(1) : data || 'id';
    this._sortValue = { id, start } as MatSortable;
  }
  @Output()
  filtersChange = new EventEmitter<Filters>();
  currFilt: Filters;

  displayedColumns: string[] = [
    'title',
    'artist__user__first_name',
    'genre',
    'sub_genre',
    'created_at',
    'updated_at',
    'approved_at',
    'status',
    'action',
  ];

  status = {
    ACCEPTED: {
      id: 5,
      label: 'Accepted',
      color: '#E3B950'
    }
  };

  @ViewChild(MatSort) sort: MatSort;
  private _sortSubscription: Subscription = null;
  isEdit = false


  constructor(
    private _userService: UserService,
    private _playerService: PlayerService,
    private _dialogService: DialogService,
    private _sliderDialogService: SliderDialogService,
    public trackServ: TrackService, public list: MenuListService,
    private _trackStatusPipe: TrackStatusPipe, private api: UserMenuService, private changeDetector: ChangeDetectorRef) {
    this.expandedTrack = this.expandedTrackSubject.asObservable();
  }

  ngOnInit(): void {
    this._subscriptions.push(
      this._userService.currentUserStream.subscribe((u) => {
        this.currentUser = u;
      }),
      this._playerService.currentlyPlaying.subscribe(data => {
        this._currentlyPlaying = data;
      }),
      this._playerService.isPlaying.subscribe(isPlaying => {
        this._isPlaying = isPlaying;
      }),
      this._playerService.isWavePlay.subscribe((p) => {
        this.wavePlay = p;
        console.log(this.wavePlay)
      }),
      this._playerService.isLoader.subscribe((p) => {
        this.loadingTracks = p;
        console.log(this.loadingTracks)
      })
    );

    if (localStorage.getItem('editData') == 'yes') {
      this.rowClicked1(JSON.parse(localStorage.getItem('expand')))
    }


  }

  ngAfterViewInit() {

    this._sortSubscription = this.sort.sortChange.subscribe(() => {
      this.sortChange.emit({
        id: this.sort.active,
        start: this.sort.direction,
      } as MatSortable);
    });

    this.sort.sort(this._sortValue);
    if (this.isEdit == true) {
      this.filtersChange.emit()
    }
  }
  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => {
      s.unsubscribe();
    });
    if (this._dataSourceSubscription) {
      this._dataSourceSubscription.unsubscribe();
    }
    if (this._sortSubscription) {
      this._sortSubscription.unsubscribe();
    }
    this._playerService.nextTab(true);
    this._playerService.updateWavePlay(false);
    this._playerService.updateIsPlaying(false);
    this._playerService.updateShowMusicPlayer(false);
    this._playerService.play(null, null);
    this._sliderDialogService.currentTrackVersions = [];
  }

  displayTrackInfo(track: Track, version: TrackVersion) {
    this.trackServ.trackPopData = track
    this._dialogService.displayTrackInfo(track, version);
  }

  rowClicked(track: Track) {
    if (this.trackIsExpanded(track)) {
      this._playerService.updateShowMusicPlayer(false);
      this._playerService.play(null, null);
      this.expandTrack(track);
      return;
    }
    this.expandTrack(track);
    if (!track.versions || track.versions.length <= 0) return;

    let version = track.versions[0]
    if (!version) version = track.versions[0];
    // this._playerService.updateShowMusicPlayer(true);
    // this._playerService.updatePlaybackContextList(track.versions.map(v => new PlayingItem(track, v)));
    // this._playerService.play(track, version);
  }

  rowClicked1(track: Track) {
    this.sample = track;
    console.log(track)
    if (this.trackIsExpanded(track)) {
      this._playerService.updateShowMusicPlayer(false);
      this._playerService.updateWavePlay(false);
      this._playerService.updateIsPlaying(false);
      this._playerService.play(null, null);
      this.expandTrack(track);
      return;
    }
    let headers = new HttpHeaders();
    this.api.doGET(environment.apiURL + '/api/v1/track/' + track.id + '/get-version/',
      {
        headers: headers,
        params: track.id
      }
    ).subscribe(t => {
      console.log(t)
      if (t) {
        this.versions = t;
        this._sliderDialogService.currentTrackVersions = this.versions;
        this.versions.trackId = track.id
        track.trackId = track.id
        track.versions = this.versions
        this.expandTrack(track);
        if (!track.versions || track.versions.length <= 0) return;
        let version = track.versions[0]
        if (!version) version = track.versions[0];
        this.loadingTracks = true;
        this._playerService.updateLoader(true);
        this._playerService.nextTab(false);
        this._playerService.updateWavePlay(true);
        this._playerService.updateShowMusicPlayer(true);
        this._playerService.updateIsPlaying(true);
        this._playerService.updatePlaybackContextList(track.versions.map(v => new PlayingItem(track, v)));

        //Robert: 5/21/24 - Fix for Kyle's request.  Track should expand but NOT play after editing
        if (this.trackServ.lastUpdatedTrackId != track.id) {
          this._playerService.play(track, version);
        } else {
          //reset lastUpdatedTrackId so it can be played if clicked          
          this.trackServ.lastUpdatedTrackId = null;

          //set loadingTrack to false so the play button is not disabled on the row
          this.loadingTracks = false;
        }

      }
    })
  }

  expandTrack(track: Track) {
    if (
      this.expandedTrackSubject.value &&
      this.expandedTrackSubject.value.id == track.id
    ) {
      this.expandedTrackSubject.next(null);
      return;
    }
    this.expandedTrackSubject.next(track);
  }

  trackIsExpanded(track: Track) {
    return (
      this.expandedTrackSubject.value &&
      this.expandedTrackSubject.value.id == track.id
    );
  }

  playButtonPressed(track: Track, version: TrackVersion) {
    track.versions = this.versions
    this._isPlaying = true;
    this.loadingTracks = true;
    this._playerService.updateLoader(true);
    this._playerService.nextTab(false);
    this._playerService.updateWavePlay(true);
    this._playerService.updateIsPlaying(true);
    this._playerService.play(track, version);
  }

  pauseButtonPressed(track: Track, version: TrackVersion) {
    track.versions = this.versions
    this._isPlaying = false;
    this._playerService.updateWavePlay(false);
    this._playerService.updateIsPlaying(false);
  }

  getTrackStatus(track: Track): TrackStatusValue {
    if (!track || !track.versions || track.versions.length <= 0) return null;
    let mainVersion = track.versions.find(v => v.is_full_version);
    if (!mainVersion) {
      mainVersion = track.versions[0];
    }
    return this._trackStatusPipe.transform(mainVersion.status);
  }

  getVersionStatus(version: TrackVersion): TrackStatusValue {
    if (!version || version.status == null) return null;
    return this._trackStatusPipe.transform(version.status);
  }

  openEditTrack(track: Track, version: TrackVersion) {

    this.trackServ.lastUpdatedTrackId = track.id;

    localStorage.setItem('editData', 'no')
    localStorage.setItem('expand', JSON.stringify(track))
    var checkbox = document.getElementById('checkbox1');
    function handleCheckboxEvent(e) {
      e.preventDefault();
      if (e.keyCode === 32) {  // If spacebar fired the event
        this.checked = !this.checked;
      }
    }
    checkbox.addEventListener("click", handleCheckboxEvent, true);
    checkbox.addEventListener("keyup", handleCheckboxEvent, true);
    this._playerService.nextTab(true);
    this._playerService.updateViewType(true);
    this._playerService.updatePreviewPlaying(true);
    this._playerService.updateShowMusicPlayer(false);
    this._playerService.play(null, null);
    this.expandTrack(track);
    this.rowClicked(track)
    // this._playerService.updateIsPlaying(false)

    console.log(track)
    console.log(version)

    this._sliderDialogService.getTrackCreationTypeSubject.next("edit");
    if (version.is_full_version) {
      this.trackServ.versionPopData = version;
      this.trackServ.trackPopData = track
      if (this.opened !== track.id) {

        this._sliderDialogService.open(EditNewTrackComponent, {
          width: "100px",
          title: "EDIT TRACK",
          panelClass: "edit-track-dialog",
          data: { track: track, version: version }
        }, (val) => {
          if (val) {
            this.isEdit = true
            this.filtersChange.emit()
          }
        });
        this.opened = track.id;
      }
    } else {
      this.trackServ.versionPopData = version;
      this.trackServ.trackPopData = track
      if (this.openedVersion !== version.id) {
        this._sliderDialogService.open(EditNewTrackVersionComponent, {

          width: "100px",
          title: "EDIT TRACK VERSION",
          panelClass: "edit-track-dialog",
          data: { track: track, version: version }
        }, (val) => {
          if (val) {
            this.isEdit = true
            this.filtersChange.emit()
          }
        });
        this.openedVersion = version.id

      }
    }
    this._playerService.nextTab(true);
    this._playerService.updateWavePlay(false);
    this._playerService.updateIsPlaying(false);
    this._playerService.updateShowMusicPlayer(false);
    this._playerService.play(null, null);
    this.opened = 0;
    this.openedVersion = 0;
  }

  displayAddSongPlaylistDialog(track: Track, version: TrackVersion) {
    track.versions = this.versions
    this._playerService.nextTab(true);
    this._playerService.updateViewType(true);
    this._playerService.updatePreviewPlaying(true);
    this._playerService.updateShowMusicPlayer(false);
    this._playerService.play(null, null);
    if (!track || !version) return;
    this._dialogService.displayAddTrackPlaylistDialog(track, version, this._dataSource, '').subscribe(changed => {
      if (changed) {
      }
    });
  }

  displayDeleteTrackVersionDialog(track: Track, version: TrackVersion) {
    if (!track || !version) return;
    this._dialogService.displayDeleteTrackVersionDialog(track, version).subscribe(changed => {
      if (changed) {
        let index = track.versions.findIndex(v => v.id == version.id);
        track.versions.splice(index, 1);
      }
      this.filtersChange.emit(this.currFilt);
    });
  }

  isPlaying(version: TrackVersion) {
    if (!this._isPlaying || !this.wavePlay) return false;
    if (!this._currentlyPlaying || !this._currentlyPlaying.track || !this._currentlyPlaying.version) return false;
    return this._currentlyPlaying.version.id == version.id;
  }
}
