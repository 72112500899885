import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { MenuConfigService } from 'src/app/shared/services/menu-config.service';
import { UserMenuService } from 'src/app/shared/services/user-menu.service';
import { environment } from 'src/environments/environment';
import { threadId } from 'worker_threads';

@Component({
  selector: 'app-cmsaddgenre',
  templateUrl: './cmsaddgenre.component.html',
  styleUrls: ['./cmsaddgenre.component.scss']
})
export class CmsaddgenreComponent implements OnInit {

  public allGenreItemlist: any = [];
  public selectedGenre: any = [];
  public selectedGenreName:  any = [];
  public loadingUser: boolean = true;
  public listSelectedGenre: any =[];
  public loadingallGenreItemlist = true;

  @Input() showModal:boolean;
  @Output() messageEvent = new EventEmitter<any>();

  constructor(private dialogRef: MatDialogRef<CmsaddgenreComponent>,public api: UserMenuService,public config: MenuConfigService,public dialog: DialogService,) { 
    if(this.showModal){
    this.dialog.displayMenuDialog(CmsaddgenreComponent);
    }
  }

  ngOnInit() {
    this.getallGenreItemList();
  }
  close() {
    this.dialogRef.close();
    this.selectedGenre = [];
    this.selectedGenreName =[];
  }

  getallGenreItemList(event: any = '') {
    let newGenre = [];
    let url = environment.apiURL + this.config.addGenreDropdown
    this.api.doGET(url).subscribe(x => {
      this.allGenreItemlist = x || []
      this.allGenreItemlist.forEach(x => {
        if (x.is_home_slide) {
          x['checked'] = true;
          newGenre.push(x)
          let data = {
            "id": x.id,
            "is_home_slide": true,
            "list":true,
          }
          this.selectedGenre.push(data)
          this.listSelectedGenre.push(data)
          this.selectedGenreName.push({ "id": x.id,  "list":true, "name": x.name })
        }
        else {
          x['checked'] = false;
          newGenre.push(x)
        }
      })
      this.loadingUser = false;
      this.allGenreItemlist = newGenre
    })
     this.dialog.dialogRefresh.next('true');
  }

  mySelectOpt(id, evt, index) {
    if (evt.checked) {
      let data = {
        "id": id,
        "is_home_slide": true,
      }
      this.selectedGenre.push(data)
      this.listSelectedGenre = this.listSelectedGenre.filter((x) => x.id !== id);
      this.listSelectedGenre.push(data)
      this.allGenreItemlist[index].checked = true;
      this.selectedGenreName.push({ "id": id, "name": this.allGenreItemlist[index].name })
    }
    else {
      this.allGenreItemlist[index].checked = false;
      const index1 =  this.listSelectedGenre.findIndex(i => i.id == id);
      if( this.listSelectedGenre[index1]?.list){
      this.listSelectedGenre[index1] =  {
        "id": id,
        "is_home_slide": false,
      }
      }
      else{
        this.listSelectedGenre = this.listSelectedGenre.filter((x) => x.id !== id);
      }
        this.selectedGenre = this.selectedGenre.filter((x) => x.id !== id);
        this.selectedGenreName = this.selectedGenreName.filter((x) => x.id !== id);    
    }
  }

  removeSelectGenre(Genre) {
    const index = this.allGenreItemlist.findIndex(i => i.id == Genre.id);
    this.allGenreItemlist[index].checked = false;
    this.selectedGenre = this.selectedGenre.filter((x) => x.id !== Genre.id);
    this.selectedGenreName = this.selectedGenreName.filter((x) => x.id !== Genre.id);
    const index1 =  this.listSelectedGenre.findIndex(i => i.id == Genre.id);
    if( this.listSelectedGenre[index1]?.list){
    this.listSelectedGenre[index1] =  {
      "id": Genre.id,
      "is_home_slide": false,
    }
    }
    else{
      this.listSelectedGenre = this.listSelectedGenre.filter((x) => x.id !== Genre.id);
    }
  }

  addSelectGenre(){
    let value ={"genre": this.listSelectedGenre}
    let url = environment.apiURL + this.config.addFeatureDropdown
    this.api.doPOST(url, value ).subscribe(x => {
      this.dialogRef.close(true);
      this.getallGenreItemList();
      this.selectedGenre = [];
      this.selectedGenreName =[];

      this.dialog.dialogRefresh.next('true');
    },
    (error) => { 
      if (error) {
            console.log(error)
            let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
            this.dialog.UserUploadPopupConfirmPopup(data).subscribe(changed => {
            });
            this.dialogRef.close(true);
          //  alert(error.error.error)
          };
    });
  }

  deselectAll() {
    for (let x of this.allGenreItemlist) {
      x.checked = false;
    }
    this.selectedGenre = [];
    this.selectedGenreName =[];
    let newData =[];
    for(let i=0;i<this.listSelectedGenre.length;i++){
      newData[i] = {
        "id": this.listSelectedGenre[i].id,
        "is_home_slide": false,
      }
    }
    this.listSelectedGenre = newData;
  }

  getFindGenreList() {
    var input, filter, ul, li, span, i, txtValue;
    input = document.getElementById('myInput');
    filter = input.value.toUpperCase();
    ul = document.getElementById("checkBoxUl");
    li = ul.getElementsByTagName('li');

    for (i = 0; i < li.length; i++) {
      span = li[i].getElementsByTagName("span")[0];
      txtValue = span.textContent || span.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  }
}
