<div class="dialog-container">
    <div class="dialog-header">
        <h4 class="dialog-heading">EXPORT OPTIONS</h4>
        <button type="button" class="close-button" (click)="close()">
        <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                    <g id="Genre-Options">
                        <g id="Close" transform="translate(1439.000000, 32.000000)">
                            <path d="M24,0 L0,24" id="Path"></path>
                            <path d="M0,0 L24,24" id="Path"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        </button>
    </div>

    <mat-dialog-content>
       
        <div class="dialog-body" >
            <div class="form-container">
                <div class="form-row">
                    <div class="form-col">
                        <h5 class="form-heading">
                            GENRE DOWNLOAD
                        </h5>
                        <p>
                            Please specify the genre and time frame to begin downloading
                        </p>
                    </div>
                </div>
                <div class="form-row filters top-filters-export">
                    <div class="form-col-6">
                        <mat-form-field appearance="fill">
                            <mat-label>Genre</mat-label>
                            <mat-select (selectionChange)="onGenreChange($event)" panelClass="exportdownloadpanel" [disableOptionCentering]="true" [(value)]="selectedGenre">
                                <mat-option [value]="-1">
                                    All
                                </mat-option>
                                <mat-option *ngFor="let genre of genres" [value]="genre.id">
                                    {{genre.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div  class="date top-filters">
                        <app-range-datepicker [enableSelect]="true" [type]="'export'" [rangeFilterList]="dateChoices" [selectedRangeFilter]="selectedDateType" [startDate]="startDate" [endDate]="endDate" (startDateChange)="changedate($event)" (endDateChange)="changedateend($event)" (dateTypeChange)="dateTypeChange($event)" (selectedRangeFilterChange)="dateType($event)"></app-range-datepicker>  

                        <!-- <mat-form-field class="exportdialog" appearance="fill">
                            
                            <mat-date-range-input [formGroup]="range" [rangePicker]="picker"[max]="maxDate" >
                            
                              <input matStartDate (dateInput)="inputStartDate($event)" (dateChange)="changeStartDate($event)" placeholder="From" formControlName="start" >
                              <input matEndDate (dateInput)="inputEndDate($event)" (dateChange)="changeEndDate($event)"  placeholder="To" formControlName="end" >
                            </mat-date-range-input>
                            <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
                            <span class="range-date1">DATE RANGE</span>
                            <mat-date-range-picker #picker></mat-date-range-picker>

                            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                        </mat-form-field> -->
                    </div>
                </div>
                <div class="form-row mb-0">
                    <div class="form-col">
                            <div class="loader" *ngIf="loading">
                                    <app-loader color="#50e3c2"></app-loader>
                                </div>
                        <button *ngIf="(!loading)" (click)="downloadTracks()" class="form-button" [disabled]="trackCount <= 0">
                            Send Email ({{ trackCount }} Track{{trackCount !== 1 ? 's':''}})
                        </button>
                    </div>
                </div>
            </div>
            <div class="form-container mb-0" *ngIf="currentUser?.is_superuser">
                <div class="form-row">
                    <div class="form-col">
                        <h5 class="form-heading">
                            Media Query Download
                        </h5>
                        <p>
                            Please select a query type to begin downloading
                        </p>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-col ">
                        <mat-form-field appearance="fill" floatLabel="always" class="quertype-fill">
                            <mat-label>QUERY TYPE</mat-label>
                            <mat-select [(value)]="selectedMediaQuery" panelClass="exportdownloadpanel queerytypeheight" [disableOptionCentering]="true" >
                                <mat-option [value]="''">
                                    Select 
                                </mat-option>
                                <mat-option *ngFor="let mediaQueryType of mediaQueryTypes" [value]="mediaQueryType.slug">
                                    {{mediaQueryType.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="form-row mb-0">
                    <div class="form-col">
                        <button class="form-button" [disabled]="selectedMediaQuery == ''" (click)="downloadMediaQuery()">
                           Send Email
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</div>
