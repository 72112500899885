import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../environments/environment';
import { Playlist } from '../models/playlist.model';
import { APIListResponse } from '../models/apiresponse.model';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PlaylistFolder } from '../models/playlistfolder.model';


export interface GetPlaylistFoldersOptions {
  ordering?: string;
  search?: string;
  startDate?: Date;
  endDate?: Date;
  limit?: number;
  offset?: number;
  playlistId?: number;
  page?:string;//number
  includeComposerCount?: boolean
}
export interface UpdatePlaylistFolders {
  folder_ids?:any;
  folder_name?: string;

}
@Injectable({
  providedIn: 'root'
})
export class PlaylistFolderService {

  constructor(
    private http: HttpClient,
    private _platform: Platform,
    private _transferState: TransferState
  ) { }

  getAllPlaylistFolders(options: GetPlaylistFoldersOptions): Observable<APIListResponse<PlaylistFolder>> {
    let params = this.optionsToParams(options);
    return this.getPlaylistFolders(params);
  }
  getAllPlaylistFolderspopup() {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.get<Object>(
      environment.apiURL +
        `/api/v1/admin/playlist-folder/dropdown/ `,
      {
        headers: headers,
      }
    );  
  }
  getAllPlaylistFolderspopupcheckbox(playlistId) {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.get<Object>(
      environment.apiURL +
        `/api/v1/admin/playlists/`+playlistId+`/get-folders/ `,
      {
        headers: headers,
      }
    );  
  }
  getAllFoldersPlaylistpopupcheckbox(folderId) {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.get<Object>(
      environment.apiURL +
        `/api/v1/admin/playlists/`+folderId+`/assigned-playlist-for-folder/ `,
      {
        headers: headers,
      }
    );  
  }
  getPlaylistFolder(playlistfolderId: number, includeComposerCount: true): Observable<PlaylistFolder> {
    // Check if playlist is cached from server
    let p = new HttpParams({ fromObject: {'include_composer_count': includeComposerCount ? 'true':'false'} });
    const PLAYLIST_KEY = makeStateKey<APIListResponse<Object>>('playlistfolder-' + playlistfolderId + '-' + p.toString());
    if (this._transferState.hasKey(PLAYLIST_KEY)) {
      const playlist = this._transferState.get<APIListResponse<Object>>(PLAYLIST_KEY, null);
      this._transferState.remove(PLAYLIST_KEY);
      return of(new PlaylistFolder(playlist));
    }
    let headers = new HttpHeaders();
    return this.http.get<Object>(
      environment.apiURL + '/api/v1/playlistfolders/' + playlistfolderId + '/',
      {
        headers: headers
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the moods
        if (!this._platform.isBrowser) {
          this._transferState.set(PLAYLIST_KEY, res);
        }
      }),
      map((res) => new PlaylistFolder(res))
    );
  }


  createPlaylistFolder(name: string): Observable<PlaylistFolder> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiURL + '/api/v1/playlistfolders/',
      {
        name: name
      },
      {
        headers: headers
      }
    ).pipe(map((res) => new PlaylistFolder(res)));
  }

  updatePlaylistFolder(playlistFolder: PlaylistFolder): Observable<PlaylistFolder> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.put<any>(
      environment.apiURL + '/api/v1/playlistfolders/' + playlistFolder.id + '/',
      playlistFolder.toJSON(),
      {
        headers: headers
      }
    ).pipe(map((res) => new PlaylistFolder(res)));
  }
  // updatePlaylisttoFolder(folderId,paramsval:UpdatePlaylistFolders): Observable<any> {
  //   let params = this.optionsToParamsupdate(paramsval);
  //   let headers = new HttpHeaders();
  //   headers = headers.append("Content-Type", "application/json");
  //   return this.http.post<any>(
  //     environment.apiURL + '/api/v1/admin/playlists/' + folderId + '/add-folders/',
      
  //     {
  //       headers: headers,
  //      params:params
  //     }
  //   ).pipe(map((res) => new PlaylistFolder(res)));
  // }

  updatePlaylisttoFolder(playlistId: number, folderIds: number[],foldername:string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiURL + '/api/v1/admin/playlists/' + playlistId + '/add-folders/',
      {      
        folder_ids:folderIds,
        folder_name:foldername  
         },
      {
        headers: headers
      }
    );
  }

  updateFoldertoPlaylist(folderId: number, playlistIds: number[]): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.put<any>(
      environment.apiURL + '/api/v1/admin/playlists/' + folderId + '/assign-playlist-folder/',
      {      
        playlist_ids:playlistIds
         },
      {
        headers: headers
      }
    );
  }
  deletePlaylist(playlistId: number): Observable<void> {
    let headers = new HttpHeaders();
    return this.http.delete<any>(
      environment.apiURL + '/api/v1/admin/playlist-folder/' + playlistId + '/',
      {
        headers: headers
      }
    );
  }
  optionsToParamsupdate(options: UpdatePlaylistFolders) {
    let params: { [param: string]: string | string[]; } = {
    };
   
      if (options.folder_ids) {
        params['folder_ids'] = options.folder_ids;
      }
      if (options.folder_name) {
        params['folder_name'] = options.folder_name;
      }     
    return params
  }
  optionsToParams(options: GetPlaylistFoldersOptions) {
    let params: { [param: string]: string | string[]; } = {
    };
    if (options) {
      // if (options.ordering) {
      //   params['ordering'] = options.ordering;
      // } else {
      //   params['ordering'] = '-created_at';
      // }
      if (options.search) {
        params['search'] = options.search;
      }
      if (options.startDate) {
        params['start_date'] = options.startDate.toISOString().split('T')[0];
      }
      if (options.endDate) {
        params['end_date'] = options.endDate.toISOString().split('T')[0];
      }
      if (options.offset) {
        params['offset'] = `${options.offset}`;
      
      }
      if(options.page){
        params['page'] = `${options.page}`; //page as number type
        // params['page'] = options.page;
      }
      if (options.limit) {
        params['limit'] = `${options.limit}`;
      }
      if (options.playlistId) {
        params['playlist'] = `${options.playlistId}`
      }
      if (options.includeComposerCount) {
       // params['include_composer_count'] = `${options.includeComposerCount ? 'true':'false'}`;
      }
    }
    return params
  }

  private getPlaylistFolders(params: {[param: string]: string | string[]}): Observable<APIListResponse<PlaylistFolder>> {
    let p = new HttpParams({ fromObject: params });
    console.log("p===================="+p)
    // Check if playlists are cached from server
    const PLAYLISTS_KEY = makeStateKey<APIListResponse<Object>>('playlistfolders-' + p.toString());
    if (this._transferState.hasKey(PLAYLISTS_KEY)) {
      const playlists = this._transferState.get<APIListResponse<Object>>(PLAYLISTS_KEY, null);
      this._transferState.remove(PLAYLISTS_KEY);
      return of({
        next: playlists.next,
        previous: playlists.previous,
        count: playlists.count,
        results: playlists.results.map((p) => new PlaylistFolder(p))
      });
    }
    let headers = new HttpHeaders();
    let url;

    return this.http.get<APIListResponse>(
   
      url = params.start_date ? environment.apiURL + '/api/v1/admin/playlist-folder/date-filter/': environment.apiURL + '/api/v1/admin/playlist-folder/',
      // environment.apiURL + '/api/v1/admin/playlist-folder/',
      {
        headers: headers,
       params: params
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the moods
        if (!this._platform.isBrowser) {
          this._transferState.set(PLAYLISTS_KEY, res);
        }
      }),
      map((res) => {
        return {
          next: res.next,
          previous: res.previous,
          count: res.count,
          results: res.results.map((p) => new PlaylistFolder(p))
        };
      })
    );
  }
}
