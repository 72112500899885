<div class="main">
    <div class="sidebar">
        <div class="top-holder">

            <form action="#" class="search-filter" #searchForm="ngForm" [ngClass]="{'search-filter-focused': searchFilterFocused}">
                <label for="search-filter-field" class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14">
                        <path fill="none" stroke="#fff" stroke-linecap="round"
                            stroke-linejoin="round" stroke-miterlimit="20" d="M6 1a5 5 0 110 10A5 5 0 016 1zM13.002 13.002L9.535 9.535"
                        /></svg>
                </label>
                <input [(ngModel)]="searchString" (keyup)="getFindUserGroups()"  name="searchString" type="text" class="txt" id="search-filter-field" (focus)="searchFilterFocused = true"
                placeholder="Search" />
            </form>
        </div>
        <div class="top-holder">
            <div class="templates-info">
                <strong> {{list.count}} Admin Groups</strong>
                <button  (click)="newGroups()" class="button">+ New Group</button>
            </div>
        </div>
        <div class="playlists-holder">
                <app-loader *ngIf="this.list.loadingUserGroups"></app-loader>
            <ul class="playlists" *ngIf="!this.list.loadingUserGroups">
              <li class="playlist"  *ngFor="let user of list.AllGroups" (click)="list.singleUserForGroup(user.id);list.currentAdminUserGroupsSubject.next(user.id);list.currentUserGroup = user;" [ngClass]="{'active': list.currentUserGroup?.id === user?.id}">{{user.name}}</li>
            </ul>
          </div>
          <div class="groups-pagination" *ngIf="!this.list.loadingUserGroups && groupsNumberOfPages > 1">
            <button class="button" (click)="currentGroupsPage = currentGroupsPage-1" [disabled]="currentGroupsPage <= 1">
              <svg width="10px" height="16px" viewBox="0 0 10 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                  <g id="Number-Pagination" transform="translate(1.000000, -9.000000)" class="inner-stroke" stroke="#FFFFFF">
                    <g id="Stroke-1">
                      <polyline points="8 25 0 17 8 9"></polyline>
                    </g>
                  </g>
                </g>
              </svg>
              Prev
            </button>
            <input type="text" class="txt" [(ngModel)]="currentGroupsPage">
            <button class="button btn-next" (click)="currentGroupsPage = currentGroupsPage+1" [disabled]="groupsNumberOfPages <= currentGroupsPage">
              Next
              <svg width="10px" height="16px" viewBox="0 0 10 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                  <g id="Number-Pagination" transform="translate(-343.000000, -9.000000)" class="inner-stroke" stroke="#FFFFFF">
                    <g id="Stroke-1">
                      <polyline transform="translate(348.000000, 17.000000) scale(-1, 1) translate(-348.000000, -17.000000) " points="352 25 344 17 352 9"></polyline>
                    </g>
                  </g>
                </g>
              </svg>
            </button>
          </div>
        <div class="templates-holder">
            <ul class="templates">
                <!-- <li class="template" [ngClass]="{'active': currentTemplate && template && currentTemplate.id == template.id}" *ngFor="let template of templates; trackBy:templateTrackBy" (click)="currentTemplate = template">
                      {{template.getDisplayName()}}
                    </li> -->
            </ul>
        </div>
    </div>
    <div class="user-list">
        <div class="header" *ngIf="list.currentUserGroup">
            <h3>{{list.currentUserGroup?.name}} - {{list.singleuserGroupCount}}</h3>
            <div class="btns" >
                <button (click)="modelclick()" class="button">Delete </button>
                <button  class="button" (click)="duplicateclick()">Duplicate </button>
                <button (click)="manageUser()" class="button">Manage Users</button>
                <button (click)="editGroupPermission()" class="button">Manage Permissions </button>
            </div>
        </div>
        <div class="usergroups-info track-list">
                <app-loader *ngIf="this.list.usergroupTable"></app-loader>
            <mat-table  *ngIf="!this.list.usergroupTable" [dataSource]="list.dataSourceForUserGroups" matSort class="mat-elevation-z8">

                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->
              
                <!-- Position Column -->
                <ng-container matColumnDef="first_name">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> User </mat-header-cell>
                  <mat-cell *matCellDef="let element" style="text-transform: uppercase;font-size:10px;"> {{element?.first_name}}  {{element?.last_name}}</mat-cell>
                </ng-container>
                
              
                <!-- Name Column -->
                <ng-container matColumnDef="date_joined">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Date Created </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.date_joined | date: 'M/d/yy'}} </mat-cell>
                  <mat-cell *matCellDef="let element" > {{element.id}} </mat-cell>
                </ng-container>
              
                <!-- Weight Column -->
                <ng-container matColumnDef="weight">
                  <mat-header-cell *matHeaderCellDef>  </mat-header-cell>
                  <mat-cell *matCellDef > 
                  
                 
                  </mat-cell>
                </ng-container>
              
                <!-- Symbol Column -->
                <ng-container matColumnDef="symbol">
                  <mat-header-cell *matHeaderCellDef>  </mat-header-cell>
                  <mat-cell *matCellDef="let element" style="justify-content:flex-end!important;font-size:10px;
                  ">
                   <button type="button" (click)="list.currentUserGroupEditDelete = element;editUser();" class="action"><small>Edit Permissions</small></button>
                    <div class="separated-button" *ngIf="list.singleuserGroupCount > 1">
                        <button type="button" matTooltip="Delete" matTooltipClass="tooltip-white" class="action-button action" (click)="list.currentUserGroupEditDelete = element;removeGroups(element.id);">
                          <svg width="18px" height="18px" viewBox="0 0 12 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <title>Trash</title>
                            <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                                <g id="Email-Mgmt-Desk-User-Client-Card" class="inner-stroke" transform="translate(-1288.000000, -16.000000)">
                                    <g id="Actions" transform="translate(1016.000000, 0.000000)">
                                        <g id="Group" transform="translate(270.000000, 16.000000)">
                                            <g id="trash-2" transform="translate(2.000000, 1.000000)">
                                                <polyline id="Path" points="0 2.8 1.33333333 2.8 12 2.8"></polyline>
                                                <path d="M10.6666667,2.8 L10.6666667,12.6 C10.6666667,13.3731986 10.069713,14 9.33333333,14 L2.66666667,14 C1.930287,14 1.33333333,13.3731986 1.33333333,12.6 L1.33333333,2.8 M3.33333333,2.8 L3.33333333,1.4 C3.33333333,0.62680135 3.930287,0 4.66666667,0 L7.33333333,0 C8.069713,0 8.66666667,0.62680135 8.66666667,1.4 L8.66666667,2.8" id="Shape"></path>
                                                <line x1="4.66666667" y1="6.3" x2="4.66666667" y2="10.5" id="Path"></line>
                                                <line x1="7.33333333" y1="6.3" x2="7.33333333" y2="10.5" id="Path"></line>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        </button>
                      </div>
                </mat-cell>
                </ng-container>
              
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" class="main-row"></mat-row>
               
            </mat-table>
              

              
            
        </div>
    </div>
</div>
