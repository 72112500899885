import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { Track } from '../models/track.model';
import { TrackVersion } from '../models/trackversion.model';
import { TrackInfoComponent } from '../trackinfo/trackinfo.component';
import { Observable } from 'rxjs';
import { ExportOptionsDialogComponent } from '../export-options-dialog/export-options-dialog.component';
import { DownloadResultsDialogComponent } from '../download-results-dialog/download-results-dialog.component';
import { NewPlaylistFolderDialogComponent } from '../new-playlist-folder-dialog/new-playlist-folder-dialog.component';
import { DuplicatePlaylistDialogComponent } from '../duplicate-playlist-dialog/duplicate-playlist-dialog.component';
import { AddRemovePlaylistDialogComponent } from '../add-remove-playlist-dialog/add-remove-playlist-dialog.component';

import { DeletePlaylistDialogComponent } from '../delete-playlist-dialog/delete-playlist-dialog.component';
import { RemoveTrackDialogComponent } from '../remove-track-dialog/remove-track-dialog.component';
import { ManageTracksClientsDialogComponent } from '../manage-tracks-clients-dialog/manage-tracks-clients-dialog.component';
import { AddSongPlaylistDialogComponent } from '../add-song-playlist-dialog/add-song-playlist-dialog.component';
import { Filters, TrackService } from './track.service';
import { PlaylistFolder } from '../models/playlistfolder.model';
import { Playlist } from '../models/playlist.model';
import { User } from '../models/user.model';
import { AddToEmailGroupComponent } from '../add-to-email-group/add-to-email-group.component';
import { ManageUserPlaylistsComponent } from '../manage-user-playlists/manage-user-playlists.component';
import { ClientActivityDialogComponent } from '../client-activity-dialog/client-activity-dialog.component';
import { DeleteClientDialogComponent } from '../delete-client-dialog/delete-client-dialog.component';
import { ArtistActivityDialogComponent } from '../artist-activity-dialog/artist-activity-dialog.component';
import { DeleteTrackVersionDialogComponent } from '../delete-track-version-dialog/delete-track-version-dialog.component';
import { NewUserEmailGroupComponent } from '../new-user-email-group/new-user-email-group.component';
import { RemoveUsersFromEmailGroupComponent } from '../remove-users-from-email-group/remove-users-from-email-group.component';
import { EmailGroup } from '../models/email-group.model';
import { DeleteUserEmailGroupComponent } from '../delete-user-email-group/delete-user-email-group.component';
import { EmailTemplate } from '../models/email-template.model';
import { NewEmailTemplateDialogComponent } from '../new-email-template-dialog/new-email-template-dialog.component';
import { DeleteEmailTemplateDialogComponent } from '../delete-email-template-dialog/delete-email-template-dialog.component';
import { DeletePlaylistFoldersDialogComponent } from '../delete-playlist-folders-dialog/delete-playlist-folders-dialog.component';
import { DeleteUsergroupsDialogueComponent } from '../../menu_mgt/delete-usergroups-dialogue/delete-usergroups-dialogue.component';
import { TrackInfo } from '../models/trackinfo.model';
import { environment } from 'src/environments/environment';
import { map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AddTrackPlaylistDialogComponent } from '../add-track-playlist-dialog/add-track-playlist-dialog.component';
import { ConfirmPopupComponent } from 'src/app/composer/confirm-popup/confirm-popup.component';

import {BehaviorSubject}  from 'rxjs'
import { DuplicatetemplatePopupComponent } from '../duplicatetemplate-popup/duplicatetemplate-popup.component';
import { UserUploadPopupComponent } from '../user-upload-popup/user-upload-popup.component';
import { SupportUserInputComponent } from 'src/app/menu_mgt/support-user-input/support-user-input.component';
import { TrackDetailsComposerComponent } from 'src/app/composer/track-details-composer/track-details-composer.component';


@Injectable({
  providedIn: 'root'
})
export class DialogService {
  dataTab: any[] = [];

  public dialogRefresh: BehaviorSubject<string> = new BehaviorSubject('');


  constructor(
    public dialog: MatDialog, private http: HttpClient, private _trackService: TrackService
  ) {
  }

  displayTrackInfo(track: any, version: any, type:any=''): Observable<void> {
      this._trackService.getComposerDetails(version.id).subscribe(opt => {
        track = opt
        version = opt
        this.dataTab = opt['composer_details'];
        if (this.dataTab.length > 0) { 
          const dialog = this.dialog.open(TrackInfoComponent, {
            autoFocus: true,
            closeOnNavigation: true,
            width: '708px',
            maxHeight: '100%',
            panelClass: 'no-padding-dialog',
            data: {track: track, version: version, response: this.dataTab, composers: opt,type:type}
          });
          return dialog.afterClosed();
        }
      }, (error) => {
        console.log(error);
      });
      return ;
  }

  displayDeleteTrackVersionDialog(track: Track, version: TrackVersion): Observable<boolean> {

    const dialog = this.dialog.open(DeleteTrackVersionDialogComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: {
        track: track,
        version: version
      }
    });
    return dialog.afterClosed();

  }
  displayDetailTrackVersionDialog(track: Track, version: TrackVersion,opt: any): Observable<boolean> {
  const dialog = this.dialog.open(TrackDetailsComposerComponent, {
    autoFocus: true,
    closeOnNavigation: true,
    width: '708px',
    maxHeight: '100%',
    panelClass: 'no-padding-dialog',
    data: {track: track, version:version, response: this.dataTab, composers: opt}
  });
  return dialog.afterClosed();
}

  displayExportOptionsDialog(): Observable<void> {
    const dialog = this.dialog.open(ExportOptionsDialogComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: { }
    });
    return dialog.afterClosed();
  }

  displayNewPlaylistDialog(): Observable<Playlist> {
    const dialog = this.dialog.open(DuplicatePlaylistDialogComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: { playlist: null, isDuplicate: false, isEdit: false }
    });
    return dialog.afterClosed();
  }
  

  displayDuplicatePlaylistDialog(playlist: Playlist): Observable<Playlist> {
    const dialog = this.dialog.open(DuplicatePlaylistDialogComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: { playlist: playlist, isDuplicate: true, isEdit: false }
    });
    return dialog.afterClosed();
  }

  displayEditPlaylistDialog(playlist: Playlist): Observable<Playlist> {
    const dialog = this.dialog.open(DuplicatePlaylistDialogComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: { playlist: playlist, isDuplicate: false, isEdit: true }
    });
    return dialog.afterClosed();
  }

  displayNewPlaylistFolderDialog(): Observable<PlaylistFolder> {
    const dialog = this.dialog.open(NewPlaylistFolderDialogComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: { folder: null, isEdit:false }
    });
    return dialog.afterClosed();
  }
  displayConfirmPopup(title:any, val:any): Observable<any> {
    const dialog = this.dialog.open(ConfirmPopupComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: {title:title, val:val }
    });
    return dialog.afterClosed();
  }
  DuplicateConfirmPopup(title:any): Observable<any> {
    const dialog = this.dialog.open(DuplicatetemplatePopupComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '508px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: {title:title}
    });
    return dialog.afterClosed();
  }
  SupportUserPopup(title:any): Observable<any> {
    const dialog = this.dialog.open(SupportUserInputComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '508px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: {title:title}
    });
    return dialog.afterClosed();
  }
  UserUploadPopupConfirmPopup(title:any, value:any=''): Observable<any> {
    const dialog = this.dialog.open(UserUploadPopupComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: {title:title, value:value}
    });
    return dialog.afterClosed();
  }

  UserUploadPopup_NoConfirm(title:any, value:any=''){
    const dialog = this.dialog.open(UserUploadPopupComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: {title:title, value:value}
    });
  
    //closes after 3 seconds
    setTimeout(() => {
      this.dialog.closeAll();
    }, 3000);

  }

  displayEditPlaylistFolderDialog(folder: PlaylistFolder): Observable<PlaylistFolder> {
    const dialog = this.dialog.open(NewPlaylistFolderDialogComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: { folder: folder, isEdit:true }
    });
    return dialog.afterClosed();
  }

  displayAddRemovePlaylistDialog(playlist: Playlist,folderId:number[],playListfolder:PlaylistFolder[],tabval:string): Observable<boolean> {
    const dialog = this.dialog.open(AddRemovePlaylistDialogComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: { playlist: playlist,folderId:folderId,playListfolder:playListfolder ,tabval:tabval}
    });
    return dialog.afterClosed();
  }

  displayDownloadResultsDialog(filters: Filters, trackCount: number): Observable<void> {
    const dialog = this.dialog.open(DownloadResultsDialogComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: { filters:filters, trackCount:trackCount }
    });
    return dialog.afterClosed();
  }

  displayDeletePlaylistDialog(playlist: any, id:any='', type:any='', data:any=[]): Observable<boolean> {
    const dialog = this.dialog.open(DeletePlaylistDialogComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: { playlist: playlist , id:id, type:type,data:data}
    });
    return dialog.afterClosed();
  }

  displayDeletePlaylistFoldersDialog(folders: PlaylistFolder[]): Observable<boolean> {
    const dialog = this.dialog.open(DeletePlaylistFoldersDialogComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: { folders: folders }
    });
    return dialog.afterClosed();
  }

  displayRemoveTrackDialog(track: Track): Observable<void> {
    const dialog = this.dialog.open(RemoveTrackDialogComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: { track: track }
    });
    return dialog.afterClosed();
  }

  displayManageTracksClientsDialog(playlist: any, val: String): Observable<boolean> {
    const dialog = this.dialog.open(ManageTracksClientsDialogComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: { playlist: playlist, val:val }
    });
    return dialog.afterClosed();
  }

  displayAddSongPlaylistDialog(track: Track, version: TrackVersion,trackid:any): Observable<boolean> {
    var matDialogConfig: MatDialogConfig = {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
    };
   
    const dialogConfig = matDialogConfig;
    dialogConfig. data= {
      track: track,
      version: version,
      trackid:trackid
    }
    const dialog = this.dialog.open(AddSongPlaylistDialogComponent, dialogConfig);
    return dialog.afterClosed();
  }
  displayAddTrackPlaylistDialog(track: Track, version: TrackVersion,list:any, trackid:any): Observable<boolean> {
    var matDialogConfig: MatDialogConfig = {
      position: {
        right: '2px',
        top: '0px',
      },
      width: '600px',
      height:'100%',
      autoFocus: true,
      closeOnNavigation: true,
    };
    const dialogConfig = matDialogConfig;
    dialogConfig. data= {
      track: track,
      version: version,
      list:list,
      trackid:trackid
    }
    const dialog = this.dialog.open(AddTrackPlaylistDialogComponent, dialogConfig);
    return dialog.afterClosed();
  }

  displayAddToEmailGroupDialog(users: User[], from_page: any): Observable<boolean> {

    const dialog = this.dialog.open(AddToEmailGroupComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: {
        users: users,
        from_page: from_page
      }
    });
    return dialog.afterClosed();
  }

  displayManageUserPlaylistsDialog(user: User): Observable<boolean> {

    const dialog = this.dialog.open(ManageUserPlaylistsComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: {
        user: user,
      }
    });
    return dialog.afterClosed();
  }

  displayClientActivityDialog(user: User): Observable<boolean> {

    const dialog = this.dialog.open(ClientActivityDialogComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: {
        user: user,
      }
    });
    return dialog.afterClosed();
  }

  displayDeleteClientDialog(user: User, isArtist: boolean = false): Observable<boolean> {

    const dialog = this.dialog.open(DeleteClientDialogComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: {
        user: user,
        isArtist: isArtist
      }
    });
    return dialog.afterClosed();

  }

  displayArtistActivityDialog(user: User): Observable<boolean> {

    const dialog = this.dialog.open(ArtistActivityDialogComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: {
        user: user,
      }
    });
    return dialog.afterClosed();
  }


  displayNewUserEmailGroupDialog(name:string = '', isDuplicate:boolean = false, id:number=0): Observable<boolean> {

    const dialog = this.dialog.open(NewUserEmailGroupComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: {
        name: name,
        isDuplicate: isDuplicate,
        id:id
      }
    });
    return dialog.afterClosed();
  }

  displayRemoveUsersFromEmailGroupDialog(users:User[] = [], groupId:number): Observable<boolean> {

    const dialog = this.dialog.open(RemoveUsersFromEmailGroupComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: {
        users: users,
        groupId: groupId
      }
    });
    return dialog.afterClosed();

  }

  displayDeleteUserEmailGroupDialog(group:EmailGroup): Observable<boolean>{
  
    const dialog = this.dialog.open(DeleteUserEmailGroupComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: {
        group: group
      }
    });
    return dialog.afterClosed();

  }

  displayNewEmailTemplateDialog(template:EmailTemplate =null, isDuplicate: boolean = false, type:string): Observable<boolean> {
    
    const dialog = this.dialog.open(NewEmailTemplateDialogComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: {
        template: template,
        isDuplicate: isDuplicate,
        type:type
      }
    });
    return dialog.afterClosed();
  }

  displayDeleteEmailTemplateDialog(template:EmailTemplate): Observable<boolean>{

    const dialog = this.dialog.open(DeleteEmailTemplateDialogComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: {
        template: template
      }
    });
    return dialog.afterClosed();
  }

  displayDeleteUsergroupDialog(data): Observable<boolean>{

    const dialog = this.dialog.open(data, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: { }
    });
    return dialog.afterClosed();
  }
  displayMenuDialog(data, type:any=''): Observable<boolean>{

    const dialog = this.dialog.open(data, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: {type:type}
    });
    return dialog.afterClosed();
  }
  displayMenuDialogAlert(data, disable, val): Observable<boolean>{

    const dialog = this.dialog.open(data, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '500px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: { disable: disable , error:val}
    });
    return dialog.afterClosed();
  }
}
