<div class="track-list">
<mat-table [dataSource]="listDataSource" matSort class="track-list green-theme" multiTemplateDataRows [trackBy]="listTrackBy">
    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef mat-sort-header>User Name</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <mat-checkbox class="dark-theme" [checked]="userIsChecked(row.user)" (change)="userChecked($event.checked, row.user)"></mat-checkbox>
        <span class="email">{{ row.user.getFullName() }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <span class="email">{{row.user.user_type}}</span></mat-cell>
    </ng-container>
  
    <ng-container matColumnDef="nickname">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Nickname</mat-header-cell>
      <mat-cell *matCellDef="let row"> <span class="email">{{row.user.nick_name}}</span></mat-cell>
    </ng-container>
  
    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
      <mat-cell *matCellDef="let row"><span class="email">{{row.user.email}}</span></mat-cell>
    </ng-container>
  
    <ng-container matColumnDef="phone">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Phone</mat-header-cell>
      <mat-cell *matCellDef="let row"><span class="email">{{row.user.phone_number}}</span></mat-cell>
    </ng-container>
  
    <ng-container matColumnDef="created_at">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Created</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.user.date_joined | date: 'shortDate'}}</mat-cell>
    </ng-container>
  
    <ng-container matColumnDef="visited">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Visited</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.user.last_login | date: 'shortDate'}}</mat-cell>
    </ng-container>
  
    <ng-container matColumnDef="tos">
      <mat-header-cell *matHeaderCellDef mat-sort-header>TOS</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span *ngIf="row.user.tos_status=='Accepted'"
          class="status-bullet"
          [ngStyle]="{'background-color': '#50e3c2'}"
          [matTooltip]="'Accepted'"
          matTooltipPosition="left"
          matTooltipClass="tooltip-white"
        ></span>
        <span *ngIf="row.user.tos_status=='Rejected'"
          class="status-bullet"
          [ngStyle]="{'background-color': '#D73939'}"
          [matTooltip]="'Rejected'"
          matTooltipPosition="left"
          matTooltipClass="tooltip-white"
        ></span>
        <span *ngIf="row.user.tos_status=='Pending'"
        class="status-bullet"
        [ngStyle]="{'background-color': '#E3B950'}"
        [matTooltip]="'Pending'"
        matTooltipPosition="left"
        matTooltipClass="tooltip-white"
      ></span>
      </mat-cell>
    </ng-container>
  
    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef>
        <div class="header-action">
          <button class="action" *ngIf="checkedUsers && checkedUsers.length > 0" (click)="deselectAll()">Deselect all ({{checkedUsers.length}})</button>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="actions">
          <div class="separated-button">
            <button type="button" matTooltip="Delete" matTooltipClass="tooltip-white" class="action-button action" (click)="deleteClick(row.user)">
              <svg width="15px" height="20px" viewBox="0 0 12 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>Trash</title>
                <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                    <g id="Email-Mgmt-Desk-User-Client-Card" class="inner-stroke" transform="translate(-1288.000000, -16.000000)">
                        <g id="Actions" transform="translate(1016.000000, 0.000000)">
                            <g id="Group" transform="translate(270.000000, 16.000000)">
                                <g id="trash-2" transform="translate(2.000000, 1.000000)">
                                    <polyline id="Path" points="0 2.8 1.33333333 2.8 12 2.8"></polyline>
                                    <path d="M10.6666667,2.8 L10.6666667,12.6 C10.6666667,13.3731986 10.069713,14 9.33333333,14 L2.66666667,14 C1.930287,14 1.33333333,13.3731986 1.33333333,12.6 L1.33333333,2.8 M3.33333333,2.8 L3.33333333,1.4 C3.33333333,0.62680135 3.930287,0 4.66666667,0 L7.33333333,0 C8.069713,0 8.66666667,0.62680135 8.66666667,1.4 L8.66666667,2.8" id="Shape"></path>
                                    <line x1="4.66666667" y1="6.3" x2="4.66666667" y2="10.5" id="Path"></line>
                                    <line x1="7.33333333" y1="6.3" x2="7.33333333" y2="10.5" id="Path"></line>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
            </button>
          </div>
        </div>
      </mat-cell>
    </ng-container>
  
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns" class="main-row" [@rowInOut]></mat-row>
</mat-table>

</div>
  <div class="overlay" *ngIf="loadingOverlay">
    <app-loader></app-loader>
  </div>