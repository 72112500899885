            <form [formGroup]="userEditForm">
            <div class="section general">
                <div class="title">
                    <h3>{{currentUser == 'edit' ? 'User Info': 'User Info'}}</h3>
                </div>
                <div class="grid-card">
                    <div class="grid-item">
                        <mat-form-field appearance="fill">
                            <mat-label>Permission Group</mat-label>
                            <mat-select  #matRef (selectionChange)="formDisable = true;groupChange($event)" panelClass="permission-grp" [disableOptionCentering]="true" [(ngModel)]="groupInitial"[ngModelOptions]="{standalone: true}">
                                <mat-option *ngFor="let groups of list.AllGroups" [value]="groups.id">{{groups.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="grid-container">

                    <div class="grid-item">
                        <mat-form-field appearance="fill">
                            <mat-label>First Name</mat-label>
                            <input (keyup)="formDisable = true" matInput formControlName="first_name" >
                        </mat-form-field>
                    </div>
                    <div class="grid-item">
                        <mat-form-field appearance="fill">
                            <mat-label>Last Name</mat-label>
                            <input (keyup)="formDisable = true" matInput  formControlName="last_name">
                        </mat-form-field>
                    </div>
                </div>
                
                <div class="grid-card">
                    <div class="grid-item">
                        <mat-form-field appearance="fill">
                            <mat-label>Email Address</mat-label>
                            <input (keyup)="formDisable = true" matInput formControlName="email" #email>
                        </mat-form-field>
                    </div>
                </div>
                <div class="grid-card">
                    <div class="grid-item">
                        <mat-form-field appearance="fill">
                            <mat-label>User Name</mat-label>
                            <input (keyup)="formDisable = true" matInput formControlName="ms_username">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <hr>






            <div class="section general">
                <div class="title">
                    <h3>Permissions</h3>
                </div>
                <div>
                    <mat-dialog-content>
                        <div class="dialog-body">

                            <div class="add-to-folder form-container">
                                <h6 class="label">Available Permissions<span class="deselect" (click)="deselectPermission()"><small>Deselect all ({{selectedCount}})</small></span></h6>
                                <span></span>

                                <input class="search" [(ngModel)]="searchPermission" [ngModelOptions]="{standalone: true}" placeholder="Find a permission">
                                <ul class="list">
                                    <li (click)="formDisable = true" *ngFor="let client of this.list.editAvailableUserPermission | filter : searchPermission; let idx = index">
                                        <mat-checkbox [checked]= "client.checked" [ngClass]="{'permission': (!groupEdit || client.disabled)}" [disabled]="!groupEdit || client.disabled"  (change)="mySelectOpt(client.id, $event, idx)"> 
                                            <span  class="check-text">{{client.name}}</span>
                                        </mat-checkbox>
                                    </li>
                                </ul>
                                
                            </div>
                            <p class="note">Select or deselect or applicable permissions</p>
                            <mat-checkbox (change)="selectAllPermission($event)" [checked]="allselectUser">
                                <span  class="check-text">Select All</span>
                            </mat-checkbox>

                        </div>
                    </mat-dialog-content>
                </div>
            </div>
            <hr>






            <div class="section contact">
                <div class="title">
                    <h3>Contact<span class="allfields"><small>*All fields required </small></span></h3>
                </div>
                <div class="grid-card">
                    <div class="grid-item">
                        <mat-form-field appearance="fill">
                            <mat-label>Address </mat-label>
                            <input (keyup)="formDisable = true" matInput formControlName="address1">
                        </mat-form-field>
                    </div>
                </div>
                <div class="grid-card">
                    <div class="grid-item">
                        <mat-form-field appearance="fill">
                            <mat-label>Address Line 2</mat-label>
                            <input (keyup)="formDisable = true" matInput formControlName="address2">
                        </mat-form-field>
                    </div>
                </div>
                <div class="grid-container">

                    <div class="grid-item">
                        <!-- <mat-form-field appearance="fill">
                            <mat-label>Country</mat-label>
                            <input matInput formControlName="country">
                        </mat-form-field> -->
                        <app-country-select (currentSelectionChange)="formDisable = true" [(currentSelection)]="user.country"></app-country-select>
                    </div>

                    <div class="grid-item">
                        <mat-form-field appearance="fill">
                            <mat-label>City</mat-label>
                            <input matInput formControlName="city">
                        </mat-form-field>
                    </div>
                    <div class="grid-item">
                        <!-- <mat-form-field appearance="fill">
                            <mat-label>State</mat-label>
                            <input matInput formControlName="state">
                        </mat-form-field> -->
                        <app-state-select (currentSelectionChange)="formDisable = true" [(currentSelection)]="user.state"></app-state-select>                    </div>
                    <div class="grid-item">
                        <mat-form-field appearance="fill">
                            <mat-label>Zip</mat-label>
                            <input  (keyup)="formDisable = true" matInput [patterns]="numberPattern" mask="00000" formControlName="postal_code">
                        </mat-form-field>
                    </div>
                </div>
                <div class="grid-card">
                   
                    <div class="grid-item">

                        <div class="rows">
                            <h6 class="phonecls">PHONE</h6>
                            <div class="phone" *ngFor="let phones of phoneNumberArray; let idx = index">
                               
                                <mat-form-field class="type" appearance="fill">
                                    <mat-select (change)="formDisable = true" [(ngModel)]="phones.number_type"  [ngModelOptions]="{standalone: true}">
                                        <mat-option [value]="'mobile'">
                                            Mobile
                                        </mat-option>
                                        <mat-option [value]="'office'">
                                            Office
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="number" appearance="fill">
                                    <input type="tel" id={{idx}} [value]="currentValue == idx?phoneNumber:phones.phone_number" [(ngModel)]="phones.phone_number"  [ngModelOptions]="{standalone: true}" matInput class="form-control" maxlength="12" (keydown)="validateNumber($event)" (input)="getPhoneCode($event.target, idx)">
                                </mat-form-field>
                                <button class="remove" (click)="removeNumber(idx)">
                                    <svg width="13px" height="12px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                                            <g id="Genre-Options">
                                            <g id="Close" transform="translate(1439.000000, 32.000000)">
                                                <path d="M24,0 L0,24" id="Path"></path>
                                                <path d="M0,0 L24,24" id="Path"></path>
                                            </g>
                                            </g>
                                        </g>
                                        </g>
                                    </svg>
                                </button>
                            </div>

                            <div class="add-row"><button class="btn-row" (click)="addPhoneNumber()">+ <u>Add</u></button></div>
                        </div> 
                        <!-- <div class="title">
                            <h3>UserName</h3>
                        </div>
                        <div class="grid-card">
                            <div class="grid-item">
                                <mat-form-field appearance="fill">
                                    <mat-label>Users Name</mat-label>
                                    <input readonly matInput [(ngModel)]="email.value" [ngModelOptions]="{standalone: true}">
                                </mat-form-field>
                            </div>
                        </div> -->
                        <button class="btn save-btn" [disabled]="userEditForm.invalid || !formDisable" (click)="formSave()" mat-button>{{currentUser == 'edit' ? 'Update' : 'Update'}}</button>
                    </div>
                </div>
</div>
</form>
<div class="loading-overlay" *ngIf="loading">
    <app-loader></app-loader>
</div>