import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  Pipe,
  PipeTransform
} from '@angular/core';
import {Platform} from '@angular/cdk/platform';
import {Track} from '../models/track.model';
import {TrackVersion} from '../models/trackversion.model';
import {PlayerService, PlayingItem} from '../services/player.service';
import {UserService} from '../services/user.service';
import {User} from '../models/user.model';
import {fromEvent, Subscription} from 'rxjs';

declare var MediaMetadata: any;

@Pipe({
  name: 'minuteSeconds'
})
export class MinuteSecondsPipe implements PipeTransform {
  transform(value: number): string {
    let numhours = Math.floor(value / 3600);
    let numminutes = Math.floor((value % 3600) / 60);
    let numseconds = Math.round((value % 3600) % 60);
    let str = '';
    if (numhours) {
      str = this.zeroPad(numhours, 2) + ':' + this.zeroPad(numminutes, 2) + ':' + this.zeroPad(numseconds, 2);
    } else {
      str = this.zeroPad(numminutes, 2) + ':' + this.zeroPad(numseconds, 2);
    }
    return str;
  }
  private zeroPad(num, minLength): string {
    let numStr = '' + num;
    while (numStr.length < minLength) {
      numStr = '0' + numStr;
    }
    return numStr;
  }
}


@Component({
  selector: 'app-track-preview',
  templateUrl: './track-preview.component.html',
  styleUrls: ['./track-preview.component.scss']
})
export class TrackPreviewComponent implements OnInit {

  @Input()
  set track(t: Track) {
    this._track = t;
  }

  get track(): Track {
    return this._track;
  }

  @Input()
  version: TrackVersion;

  @Input()
  interactionEnabled:boolean = true;

  trackTitle: string;
  @Input()
  set getCurrentPreview(p:number){
    this.currentTime = p;
  }

  @Input()
  set getWaveDuration(p: number) {
    this.duration = p;
  }

  @Input()
  set playbackProgress(p: number) {
    this._playbackProgress = p;
  //  this.currentTime = this.version.duration * p;
    if (!this._platform.isBrowser) { return; }
  }
  get playbackProgress(): number {
    return this._playbackProgress;
  }

  wave = null;

  @Output()
  collapse = new EventEmitter();

  set previewPlaying(p: boolean) {
    this._previewPlaying = p;
  }
  get previewPlaying(): boolean {
    return this._previewPlaying;
  }

  get previousItem(): PlayingItem {
    return this._playerService.previousItem;
  }
  get nextItem(): PlayingItem {
    return this._playerService.nextItem;
  }

  get progressPercent(): number {
    return this._playbackProgress * 100
  }

  get hoveringPercent(): string {
    return (this.hoveringProgress * 100) + '%';
  }
  get hoveringTimestamp(): number {
    return this.hoveringProgress * this.duration;
  }

  @ViewChild('waveformEl', {static: true})
  waveformEl: ElementRef;

  hovering = false;
  panning = false;
  hoveringProgress = 0;

  currentTime: number = 0;
  duration :number =0

  private _cancelAnimationFrame: number;
  private _track: Track;
  private _previewPlaying = false;
  private _playbackProgress = 0;
  private _subscriptions: Subscription[] = [];

  constructor(
    private _playerService: PlayerService,
    private _ngZone: NgZone,
    private _platform: Platform,
    private _renderer: Renderer2,
    private _userService: UserService,
  ) { }

  ngOnInit() {
    // if (this.version.is_main_track && this.version.id ) {
    //   this.trackTitle = this.track.track.title + "-" + this.version.title + "- Main";
    // }
    // if (this.version.is_full_version && this.version.id ) {
    //   this.trackTitle = (this.track && this.track?.track ? this.track?.track?.title : (this.track?.title?this.track?.title:''));
    // }
    // if (!this.version.is_main_track && !this.version.is_full_version && this.version.id ) {
    //   this.trackTitle = (this.track && this.track?.track ? this.track?.track?.title : (this.track?.title?this.track?.title:'')) + "-" + this.version.title;
    // }

    // if(this.version.id == undefined ){
    //   this.trackTitle =  this.version.title ?  this.version.title:''
    // }
    if (this.version.id) {
      this.trackTitle = this.track.title;
    }
    if (this.version.id == undefined) {
      this.trackTitle = this.version.title ? this.version.title : ''
    }

    this._subscriptions.push(
      this._playerService.previewPlaying.subscribe((p) => {
        this.previewPlaying = p;
      })
    );
    this._subscriptions.push(
      this._playerService.playbackProgress.subscribe((p) => {
        if (this._playbackProgress != p) {
          this.playbackProgress = p;
        }
      })
    );
    if (this.track && this.version) {
      this._playerService.updatePlaybackContextList([new PlayingItem(this.track, this.version)]);
      this._playerService.play(this.track, this.version);
      this._playerService.updateShowMusicPlayer(false);
      this._playerService.updateViewType(true);
    }

  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => {
      s.unsubscribe();
    });
    // this.cancelAnimationFrame();
  }

  play() {
    if (this.previewPlaying) { return; }
    this._playerService.updatePreviewPlaying(true);
  }
  pause() {
    if (!this.previewPlaying) { return; }
    this._playerService.updatePreviewPlaying(false);
  }

  updatePlaybackProgress(progress:number) {
    this._playerService.updatePlaybackProgress(progress);
  }

  mouseenter(e) {
    this.hovering = true;
    let bb = this.waveformEl.nativeElement.getBoundingClientRect();
    this.hoveringProgress = Math.max(Math.min((e.clientX - bb.left) / bb.width, 1), 0);
  }
  mousemove(e) {
    let bb = this.waveformEl.nativeElement.getBoundingClientRect();
    this.hoveringProgress = Math.max(Math.min((e.clientX - bb.left) / bb.width, 1), 0);
  }
  mouseleave(e) {
    this.hovering = false;
  }
  mousedown(e) {
    e.preventDefault();
  }
  tapped(e) {
    if (!this.interactionEnabled) { return; }
    let bb = this.waveformEl.nativeElement.getBoundingClientRect();
    this._playerService.updatePlaybackProgress((e.center.x - bb.left) / bb.width);
  }
  panstart(e) {
    if (!this.interactionEnabled) { return; }
    e.preventDefault();
    if (e.srcEvent.stopPropagation) { e.srcEvent.stopPropagation(); }
    this.panning = true;
    let bb = this.waveformEl.nativeElement.getBoundingClientRect();
    this.hoveringProgress = Math.max(Math.min((e.center.x - bb.left) / bb.width, 1), 0);
    this._playerService.updatePlaybackProgress(this.hoveringProgress);
  }
  pan(e) {
    if (!this.interactionEnabled) { return; }
    e.preventDefault();
    if (e.srcEvent.stopPropagation) { e.srcEvent.stopPropagation(); }
    let bb = this.waveformEl.nativeElement.getBoundingClientRect();
    this.hoveringProgress = Math.max(Math.min((e.center.x - bb.left) / bb.width, 1), 0);
    this._playerService.updatePlaybackProgress(this.hoveringProgress);
  }
  panend(e) {
    if (!this.interactionEnabled) { return; }
    e.preventDefault();
    if (e.srcEvent.stopPropagation) { e.srcEvent.stopPropagation(); }
    this.panning = false;
  }
}
