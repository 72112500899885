

<div class="btns">
    <button class="button" style="border: 1px solid #50e3c2;" (click)="addUserSupport();">Add User Input</button>
    <button class="button" [disabled]="list.supportDataList.length === 0" style="border: 1px solid #50e3c2;" (click)="migrateEmail();">Migrate</button>
</div>

<table class="mat-elevation-z8" *ngIf="(list.supportType === 0)" mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="emailId">
      <th mat-header-cell *matHeaderCellDef> Email Id </th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>
  
    <ng-container matColumnDef="trackCount">
      <th mat-header-cell *matHeaderCellDef> Track Count </th>
      <td mat-cell *matCellDef="let element"> {{element.tracks_count ? element.tracks_count:0}} </td>
    </ng-container>
  
    <ng-container matColumnDef="trackVersionCount">
      <th mat-header-cell *matHeaderCellDef> Track Version Count </th>
      <td mat-cell *matCellDef="let element"> {{element.track_version_count ?element.track_version_count :0}} </td>
    </ng-container>
  
    <ng-container matColumnDef="playlistCount">
      <th mat-header-cell *matHeaderCellDef> Playlist Count </th>
      <td mat-cell *matCellDef="let element"> {{element.playlist_count ? element.playlist_count : 0}} </td>
    </ng-container>

    <ng-container matColumnDef="dowloadCount">
        <th mat-header-cell *matHeaderCellDef> Dowload Count </th>
        <td mat-cell *matCellDef="let element"> {{element?.downloads_count ? element.downloads_count : 0}} </td>
      </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<table class="mat-elevation-z8" *ngIf="(list.supportType == 1)" mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="emailId">
    <th mat-header-cell *matHeaderCellDef> Title </th>
    <td mat-cell *matCellDef="let element"> {{element?.track_title ? element?.track_title :''}} </td>
  </ng-container>

  <ng-container matColumnDef="trackVersionCount">
    <th mat-header-cell *matHeaderCellDef> Track Version Count </th>
    <td mat-cell *matCellDef="let element"> {{element.track_version_count ?element.track_version_count :0}} </td>
  </ng-container>
  
  <ng-container matColumnDef="trackCount">
    <th mat-header-cell *matHeaderCellDef> Dowload Count </th>
    <td mat-cell *matCellDef="let element"> {{element.track_download_count ? element.track_download_count:0}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
</table>

<table class="mat-elevation-z8" *ngIf="(list.supportType == 2)" mat-table [dataSource]="dataSource">
  
  <ng-container matColumnDef="emailId">
    <th mat-header-cell *matHeaderCellDef> Title </th>
    <td mat-cell *matCellDef="let element"> {{element?.version_title ? element?.version_title :''}} </td>
  </ng-container>

  <ng-container matColumnDef="trackVersionCount">
    <th mat-header-cell *matHeaderCellDef> Track Version Count </th>
    <td mat-cell *matCellDef="let element"> {{element.track_count ?element.track_count :0}} </td>
  </ng-container>

  <ng-container matColumnDef="playlistCount">
    <th mat-header-cell *matHeaderCellDef> Playlist Count </th>
    <td mat-cell *matCellDef="let element"> {{element.playlist_count ? element.playlist_count : 0}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
</table>

<div class="no-data" style="margin-top: 12%;" *ngIf="list.supportDataList.length === 0">No data found</div>

