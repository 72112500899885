import { Component, Input, OnInit, OnDestroy, Version, Output, EventEmitter, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { map, startWith, debounceTime, distinctUntilChanged, tap, switchMap } from 'rxjs/operators';
import { Observable, Subscription, of, Subject, observable, ReplaySubject } from 'rxjs';

import { Genre } from '../models/genre.model';
import { GenreService } from '../services/genre.service';
import { MoodService } from '../services/mood.service';
import { SonicReferenceService } from '../services/sonicreference.service';
import { Track } from '../models/track.model';
import { Mood } from '../models/mood.model';
import { TrackVersion } from '../models/trackversion.model';
import { MatSelectChange } from '@angular/material/select';
import { UserMenuService } from '../services/user-menu.service';
import { environment } from 'src/environments/environment';
import { UserService } from '../services/user.service';
import { MenuListService } from '../services/menu-list.service';
import { CONTAINER_DATA, DialogConfig, SliderDialogService } from '../services/slider-dialog.service';
import { CreateTrackVersionComponent } from '../../shared/create-track-version/create-track-version.component';
import { EditTrackVersionComponent } from '../edit-track-version/edit-track-version.component';
import { TrackService } from '../services/track.service';
import { TrackDetailsService } from '../services/track-details.service';

@Component({
  selector: 'app-track-details',
  templateUrl: './track-details.component.html',
  styleUrls: ['./track-details.component.scss']
})

export class TrackDetailsComponent implements OnInit, OnDestroy {

  @Input()
  set track(t: Track) {
    this._track = t;
    this.trackChange.emit(this._track);
    this.selectedmood = t && t.moods && t.moods[0]?.name;
    this.selectedmood2 = t && t.moods && t.moods[1]?.name;
    this.selectedmood3 = t && t.moods && t.moods[2]?.name;
  }
  get track(): Track {
    return this._track;
  }

  @Output()
  trackChange = new EventEmitter<Track>();

  @Input()
  set version(v: TrackVersion) {
    this._version = v;
    this.versionChange.emit(this._version);
  };
  get version(): TrackVersion {
    return this._version;
  }

  @Output()
  versionChange = new EventEmitter<TrackVersion>();

  artist: string;

  selectedGenre: string = null;
  selectedmood: string;
  selectedmood2: string;
  selectedmood3: string;
  bpmValue: number = 0;
  bpmNumber: number = 10;
  checked = false;
  isExclusive = false;
  isFullLength = false;
  isInstrumental = false;
  isVocal = false;
  isMibeOwned = false;
  isFiveStar = false;
  versionNumber: any;
  genres: any[] = [];
  subGenres: Genre[] = [];
  moods: Mood[] = [];

  tempoNumber: any;
  trackEnding: any;
  key: any
  data: any;
  visibleGenre: any;

  genreFilterCtrl = new FormControl();
  filteredGenreOptions: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  searchingGenre = false;

  subGenreFilterCtrl = new FormControl();
  filteredSubGenreOptions: ReplaySubject<Genre[]> = new ReplaySubject<Genre[]>(1);
  searchingSubGenre = false;

  moodFilterCtrl = new FormControl();
  filteredMoodOptions: ReplaySubject<Mood[]> = new ReplaySubject<Mood[]>(1);
  searchingMood = false;

  // tempoList: any[] = [{name: 'Quater-note',id: 1}, {name:'Half-note',id: 2}, {name: 'Whole-note',id: 3}];
  tempoList: string[] = [];
  // trackEndingList: string[] = ['Sting', 'Beep', 'Drums'];
  trackEndingList: string[] = ['sting ending', 'sting ending decay', 'cold ending', 'cold ending decay']

  keyList: string[] = [];
  // keyList: string[] = ['C', 'Db/C#', 'D', 'Eb', 'E', 'F', 'Gb/F#', 'G', 'Ab', 'A', 'Bb', 'B/Cb','C#'];
  // keyList: string[] = ['C', 'Db/C#', 'D', 'Eb', 'E', 'F', 'Gb/F#', 'G', 'Ab', 'A', 'Bb', 'B/Cb', 'C#', "Cmin", "D#", "E min", "Fmin", "gminor", "A", "b", "Fsharp", "minor", "C Major key", "Cm", "Bb", "Am", "D major", "C", "C Sharp", "m", "D", "Dm", "Em", "Gm", "A minor", "B minor", "Bm", "D Minor", "Db/C#", "Eb", "E", "F"
  //   , "Gb/F#", "G", "Ab", "B/Cb", "C#", "Dmin", "D minor", "C minor", "Ebm", "gmin", "g sharp minor #", "g sharp major", "f#", "f sharp minor", "f sharp #", "f sharp", "F#", "e major", "C#m"
  //   , "A major", "A sharp", "Ab", "Ab minor", "db", "a#", "Emin", "G# minor", "G minor", "G Major", "Fm", "F# minor", "F minor", "F min", "F Major", "Eb minor", "Eb Major", "E Minor", "Dmi", "Db major"
  //   , "D min", "D major", " D Sharp", "C# minor", "C# major", " C major", "Bmin", "Bb min", "Bb major", "Bb Minor", " B minor", "B Major", "Amin", "Am", "c minor", "emin", "b min", "a min"
  //   , "D sharp", "minor", "bbm", " G sharp", "Gminor", "Csharp", "minor", "key of D minor", 'key of A minor']
  get currentGenre(): Genre {
    if (!this.track || !this.track.genre) return null;
    return this.track.genre && this.track.genre.parent ? this.track.genre.parent : this.track.genre;
  }

  get currentSubGenre(): Genre {
    if (!this.track || !this.track.sub_genre) return null;
    return this.track.sub_genre;
  }

  get currentMoods(): Mood[] {
    return this.track.moods;
  }

  private _track: Track;
  private _version: TrackVersion;
  private _subscriptions: Subscription[] = [];
  artistName: any;
  currentUser: any;
  trackCreationType: any;
  trackTitle: FormControl
  constructor(private genreService: GenreService, private moodsService: MoodService, public api: UserMenuService, private _userService: UserService, private _listService: MenuListService, private _sliderDialogService: SliderDialogService, @Inject(CONTAINER_DATA) public componentData: DialogConfig, public trackServ: TrackService, public trackDetailsService: TrackDetailsService) {
    this.getTempo();
    //Robert: 5/17/24 - set keyList options
    this.keyList = this.trackDetailsService.keyList;
  }

  ngOnInit() {
    this.trackTitle = new FormControl('', [Validators.required]);
    console.log(this.track, "TRACK")
    this._userService.currentUserStream.subscribe((u) => {
      this.currentUser = u;
    });
    if (this.track.id === undefined) {
      let url = environment.apiURL + `/api/v1/admin/users/${this.currentUser.id}/`;
      this.api.doGET(url).subscribe(x => {
        this.data = x || [];
        this.visibleGenre = this.data.visible_genres
        this.genres = this.visibleGenre;
        this.filteredGenreOptions.next(this.genres);
        this.getTrackInfo();
      })
    }
    else {
      let id = this.track.artist.user_id

      let url = environment.apiURL + `/api/v1/admin/users/${id}/`;
      this.api.doGET(url).subscribe(x => {
        this.data = x || [];
        this.visibleGenre = this.data.visible_genres
        this.genres = this.visibleGenre;
        this.filteredGenreOptions.next(this.genres);
      })
    }

    this._sliderDialogService.getTrackType().subscribe((type) => {
      this.trackCreationType = type
    });

    if (this.track.id == undefined || this.track.id == null) {
      this.artistName = this.currentUser.first_name + ' ' + this.currentUser.last_name;
    }
    this.artist = this.track.artist ? this.artist = this.track.artist.name : ''



    if (this.track.genre) this.updateSubGenres(this.track.genre)
    this.moodsService.getAllMoods().subscribe(moods => {
      this.moods = moods;
      this.filteredMoodOptions.next(moods);
    }, (error) => {
      console.log(error);
      //alert("An error ocurred while getting moods. Check your connections and try again");
    })

    this.isExclusive = this.track.is_exclusive;
    this.isFullLength = this.version.is_full_version;
    this.isInstrumental = this.version.is_instrumental;
    this.isVocal = this.version.is_vocals;
    this.isMibeOwned = this.version.is_mibe_owned
    this.isFiveStar = this.version.is_five_star
    this.tempoNumber = this.version.tempo
    this.key = this.version.calculated_key
    this.bpmValue = Math.round(this.version.calculated_bpm);
    this.trackEnding = this.track.track_ending;
    // this.genreService.getParentGenres().subscribe((data) => {
    //   this.genres = data;
    //   this.filteredGenreOptions.next(this.genres);
    // }, (error) => {
    //   console.log(error);
    //   //alert("An error ocurred while getting genres. Check your connections and try again");
    // });

    this._subscriptions.push(
      this.genreFilterCtrl.valueChanges.pipe(
        startWith(''),
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.searchingGenre = true;
        }),
        switchMap((v) => {
          if (!v) {
            return of(this.genres);
          }
          return of(this.genres.filter(g => g.name.toLowerCase().includes(v.toLowerCase())));
        }),
        tap(() => {
          this.searchingGenre = false;
        })
      ).subscribe(g => {
        this.filteredGenreOptions.next(g);
      }, (error) => {
        console.log(error);
        //alert("An error ocurred while getting the filtered genres. Check your connections and try again");
      }),
      this.subGenreFilterCtrl.valueChanges.pipe(
        startWith(''),
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.searchingSubGenre = true;
        }),
        switchMap((v) => {
          if (!v) {
            return of(this.subGenres);
          }
          return of(this.subGenres.filter(g => g.name.toLowerCase().includes(v.toLowerCase())));
        }),
        tap(() => {
          this.searchingSubGenre = false;
        })
      ).subscribe(g => {
        this.filteredSubGenreOptions.next(g);
      }, (error) => {
        console.log(error);
        //alert("An error ocurred while getting the filtered subgeneres. Check your connections and try again");
      }),
      this.moodFilterCtrl.valueChanges.pipe(
        startWith(''),
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.searchingMood = true;
        }),
        switchMap((v) => {
          if (!v) {
            return of(this.moods);
          }
          return of(this.moods.filter(g => g.name.toLowerCase().includes(v.toLowerCase())));
        }),
        tap(() => {
          this.searchingMood = false;
        })
      ).subscribe(m => {
        this.filteredMoodOptions.next(m);
      }, (error) => {
        console.log(error);
        //alert("An error ocurred while getting the filtered moods. Check your connections and try again");
      }),
    );
    console.log(this.version.is_instrumental);
    this._listService.currentIntrumentaltypeSubject.next(!this.version.is_instrumental);
    this.setValidation()
  }

  getTempo() {
    let requestUrl = environment.apiURL + `/api/v1/track/tempo-dropdown/`
    this.api.doGET(requestUrl).subscribe(x => {
      let arr;
      arr = x;
      this.tempoList = arr;
    })
  }
  gettrackName(event) {
    if (event.trim() === '') {
      this.setValidation()
    }
    else {
      this.setValidation()
    }
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._subscriptions = [];
  }

  bpmclick() {
    if (this.bpmValue != undefined) {
      var oldbpmvalue = this.bpmValue;
      var num = 10;

      this.bpmValue = +oldbpmvalue + this.bpmNumber;
      this.version.calculated_bpm = Math.round(this.bpmValue);
    } else {
      this.bpmValue = 10;
    }
  }

  bpmChange(val: any) {
    this.bpmValue = Math.round(val);
    this.version.calculated_bpm = Math.round(val);
    if (this.version.calculated_bpm === 0) {
      this.track.isValid = true;
    }
    else {
      this.track.isValid = false;
    }
    this.versionChange.emit(this._version);
  }

  genreSelection(change: MatSelectChange) {
    this.track.genre = change.value;
    this.trackChange.emit(this._track);
    this.updateSubGenres(change.value);
    this.setValidation()
  }

  updateSubGenres(genre: Genre): void {
    this.genreService.getSubGenres(genre && genre.parent ? genre.parent.id : genre.id).subscribe((data) => {
      if (data.length === 0) {
        this.track.sub_genre = "";
        this.trackChange.emit(this._track);
      }
      this.subGenres = data;
      this.filteredSubGenreOptions.next(data);
    }, (error) => {
      console.log(error);
      //alert("An error ocurred while getting sub genres. Check your connections and try again");
    });
  }

  subGenreSelection(change: MatSelectChange) {
    this.track.sub_genre = change.value;
    this.trackChange.emit(this._track);
    // this.setValidation()
  }

  moodSelection(change: MatSelectChange) {
    this.track.moods[0] = change.value;
    this.track.moods1 = change.value
    this.trackChange.emit(this._track);
    this.setValidation()
  }
  mood2Selection(change: MatSelectChange) {
    this.track.moods[1] = change.value;
    this.track.moods2 = change.value
    this.trackChange.emit(this._track);
    this.setValidation()
  }
  mood3Selection(change: MatSelectChange) {
    this.track.moods[2] = change.value;
    this.track.moods3 = change.value
    this.trackChange.emit(this._track);
    this.setValidation()
  }
  tempoSelection(change: MatSelectChange) {
    this.version.tempo = change.value
    this.versionChange.emit(this._version);
    this.setValidation()
  }

  trackEndingSelection(change: MatSelectChange) {
    this.version.track_ending = change.value
    this.trackChange.emit(this._track);
    this.setValidation()
  }

  keyChange(change: MatSelectChange) {
    this.version.calculated_key = change.value;
    this.versionChange.emit(this._version);
    this.setValidation()
  }


  setExclusive(val: any) {
    this.track.is_exclusive = val;
    this.trackChange.emit(this._track);
  }

  setFullLength(val: any) {
    if (val === false && (this.currentUser && this.currentUser?.is_artist === true)) {
      this._sliderDialogService.close(true);
      this._sliderDialogService.open(CreateTrackVersionComponent, {
        width: "600px",
        title: "CREATE A TRACK VERSION",
        panelClass: "edit-track-dialog",
        data: { mytrack: this._listService.getTrackFileSubject.value }
      }, (user) => {

      });
      this._listService.getTrackTypeSubject.next("trackversion");
    }
    else if (val === false && (this.currentUser && this.currentUser?.is_artist === false)) {
      this._sliderDialogService.getTrackCreationTypeSubject.next("edit");
      this.trackServ.versionPopData = this.version;
      this.trackServ.trackPopData = this.track
      this._sliderDialogService.close(true);
      this._sliderDialogService.open(EditTrackVersionComponent, {
        width: "600px",
        title: "EDIT TRACK VERSION",
        panelClass: "edit-track-dialog",
        data: { track: this.track, version: this.version }
      }, (user) => {

      });

    }
    this.version.is_full_version = val;
    this.versionChange.emit(this._version);
  }
  radioChange(e) {
    console.log(e)
    if (this.track.id === undefined) {
      if (e.value === false) {
        this.version.is_vocals = true;
        this.version.is_instrumental = false;
        this.versionChange.emit(this._version);
        this._listService.currentIntrumentaltypeSubject.next(!this.version.is_instrumental);
        this.setValidation()

      }
      else {
        this.version.is_vocals = false;
        this.version.is_instrumental = true;
        this.versionChange.emit(this._version);
        this._listService.currentIntrumentaltypeSubject.next(!this.version.is_instrumental);
        this.setValidation()

      }
    }
    else {
      if (e.value === false) {
        this.version.is_vocals = true;
        this.version.is_instrumental = false;
        this.isInstrumental = false;
        this.isVocal = true;
        this.versionChange.emit(this._version);
        this._listService.currentIntrumentaltypeSubject.next(!this.version.is_instrumental);
        this.setValidation()


      }
      else {
        this.version.is_vocals = false;
        this.version.is_instrumental = true;
        this.isInstrumental = true;
        this.isVocal = false;
        this.versionChange.emit(this._version);
        this._listService.currentIntrumentaltypeSubject.next(!this.version.is_instrumental);
        this.setValidation()
      }
    }
  }
  radioChangeVocal(e) {

    if (e.value === false) {
      this.version.is_vocals = false;
      this.version.is_instrumental = true;
      this.isInstrumental = true;
      this.isVocal = false;
      this.versionChange.emit(this._version);
      this._listService.currentIntrumentaltypeSubject.next(!this.version.is_instrumental);
      this.setValidation()


    }
    else {
      this.version.is_vocals = true;
      this.version.is_instrumental = false;
      this.isInstrumental = false;
      this.isVocal = true;
      this.versionChange.emit(this._version);
      this._listService.currentIntrumentaltypeSubject.next(!this.version.is_instrumental);
      this.setValidation()

    }

  }
  setInstrumental(val: any) {
    this.version.is_instrumental = true;
    this.version.is_vocals = false;
    this.versionChange.emit(this.version);
    this._listService.currentIntrumentaltypeSubject.next(false);
  }

  setValidation() {
    if (this.track) {
      if (this.track.title && this.track.genre && (this.track.moods[0] || this.track.moods[1] || this.track.moods[2]) && this.version.tempo && this.version.track_ending && this.version.calculated_key) {
        //   this.track.isValid = false;
        if (this.version.is_instrumental === false) {
          if (this.track.network_references.length >= 2 && this.track.tv_references.length >= 2 && this.track.sonic_references?.length >= 2 &&
            this.track.band_references?.length >= 2 && (this.track.Vocalssub_category && (this.track.Vocalsdescriptions && this.track.Vocalsdescriptions?.length > 0))
            && (this.track.lyrics.verse != '' || this.track.lyrics.pre_chorus != '' || this.track.lyrics.chorus != '' || this.track.lyrics.bridge != '')
            && (this.track.Instrumentsub_category && (this.track.Instrumentdescriptions
              && this.track.Instrumentdescriptions?.length > 0) ||
              (this.track.Instrumentnew_suggestion && this.track.Instrumentnew_suggestion?.length > 0))) {
            this.track.isValid = false;
          }
          else {
            this.track.isValid = true;
          }
        }
        if (this.version.is_instrumental === true) {
          if (this.track.network_references.length >= 2 && this.track.tv_references.length >= 2 && this.track.sonic_references?.length >= 2 &&
            this.track.band_references?.length >= 2 &&
            (this.track.Instrumentsub_category && (this.track.Instrumentdescriptions
              && this.track.Instrumentdescriptions?.length > 0) ||
              (this.track.Instrumentnew_suggestion && this.track.Instrumentnew_suggestion?.length > 0))) {
            this.track.isValid = false;
          }
          else {
            this.track.isValid = true;
          }
        }
      }
      else {
        this.track.isValid = true;
      }
    }

  }

  setVocal(val: any) {
    this.version.is_vocals = true;
    this.version.is_instrumental = false;
    this.versionChange.emit(this.version);
    this._listService.currentIntrumentaltypeSubject.next(true);
  }

  setMibeOwned(val: any) {
    console.log(val)
    this.version.is_mibe_owned = val;
    this.versionChange.emit(this._version);
  }

  setFiveStar(val: any) {
    console.log(val)
    this.version.is_five_star = val;
    this.versionChange.emit(this._version);
  }

  genreCompareWith(g1: Genre, g2: Genre) {
    if (!g1 || !g2) {
      return g1 == g2;
    }
    return g1.id == g2.id;
  }

  moodCompareWith(m1: Mood, m2: Mood) {
    if (!m1 || !m2) {
      return m1 == m2;
    }
    return m1.id == m2.id;
  }
  getTrackInfo() {
    let trackData = {
      "file_path": this.version.mp3_file
    }
    let requestUrl = environment.apiURL + `/api/v1/track/process-files/`
    this.api.doPOST(requestUrl, trackData).subscribe(x => {
      let arr;
      arr = x;
      this.tempoNumber = arr.tempo.id
      this.bpmValue = Math.round(arr.bpm)
      this.key = arr.key_with_scale
      if (this.trackCreationType != 'edit') {
        this.version.tempo = (this.version.tempo != '' || this.version.tempo != undefined || this.version.tempo != null) ? this.tempoNumber : this.version.tempo
        this.version.calculated_key = (this.version.calculated_key != '' || this.version.calculated_key != undefined || this.version.calculated_key != null) ? this.trackDetailsService.getKeyFromList(this.key) : this.version.calculated_key
        this.version.calculated_bpm = (this.version.calculated_bpm != undefined || this.version.calculated_bpm != null) ? Math.round(arr.bpm) : this.version.calculated_bpm
      }
      else {
        this.version.tempo = this.tempoNumber;
        this.version.calculated_key = this.trackDetailsService.getKeyFromList(this.key)
        this.version.calculated_bpm = (Math.round(arr.bpm))
      }

      console.log(arr)
    })
  }

}
