import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MenuListService } from '../../shared/services/menu-list.service';
import { environment } from '../../../environments/environment';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
  selector: 'app-delete-new-group-dialogue',
  templateUrl: './delete-new-group-dialogue.component.html',
  styleUrls: ['./delete-new-group-dialogue.component.scss']
})
export class DeleteNewGroupDialogueComponent implements OnInit {
  loader: boolean = false;
  userArray:any[] = [];
  permissionArray:any[] = [];
  permission:any[] = [];
  allselectedGruopIds: any[] = [];
  allselectedUserIds: any[] = [1];
  allselect:any;
  allselectUser:any;
  userGroupForm: FormGroup;
  selectedUserCount: any = 0;
  selectedPermissionCount: any = 0;
  searchUsers = "";
  searchPermission = "";
  constructor(public FormUserGroup: FormBuilder, private dialogRef: MatDialogRef<DeleteNewGroupDialogueComponent>,public list: MenuListService, public api: UserMenuService, public config: MenuConfigService,public dialog: DialogService,) {
    this.list.getAllUser();
    this.list.getAllPermission();
    this.list.getAvailableUserDetails();
    this.list.getAvailableUserPermission();
  }
  ngOnInit(): void {
    this.permission = this.list.AllPermission;
    this.userGroupForm = this.FormUserGroup.group({
      group_name: new FormControl('',[Validators.required])
    })
  }
  close() {
    this.dialogRef.close();
  }
  selectUser(id, evt, index){
    if(evt.checked){
      this.list.availableUserDetails[index].checked = true;   
    }else{
      this.list.availableUserDetails[index].checked = false;  
    }  
    for (var i = 0; i < this.list.availableUserDetails.length; i++) {
      if (this.list.availableUserDetails[index].checked) {
        this.userArray.push(id);
        this.selectedUserCount = this.userArray.length;
        if(this.userArray.length==this.list.availableUserDetails.length){
          this.allselectUser = true;
        } 
        break
      }else{
        this.userArray = this.userArray.filter(x => x !== id);
        this.selectedUserCount = this.userArray.length;
        this.allselectUser = false;
        break
      }
    }
  }
  mySelectOpt(id, evt, index){
    if(evt.checked){
      this.list.availableUserPermission[index].checked = true;   
    }else{
      this.list.availableUserPermission[index].checked = false;  
    }  
    for (var i = 0; i < this.list.availableUserPermission.length; i++) {
      if (this.list.availableUserPermission[index].checked) {
        this.permissionArray.push(id);
        this.selectedPermissionCount = this.permissionArray.length;
        if(this.permissionArray.length==this.list.availableUserPermission.length){
          this.allselect = true;
        } 
        break
      }else{
        this.permissionArray = this.permissionArray.filter(x => x !== id);
        this.selectedPermissionCount = this.permissionArray.length;
        this.allselect = false;
        break
      }
    }
  }
  deselectAll(){  
    this.selectedPermissionCount = 0;
    for(let x of this.list.availableUserPermission){
      x.checked = false;
      this.allselect = false;
      this.permissionArray=[];
    }
  }
  selectAll(evt){
    if(evt.checked){
      for(let x of this.list.availableUserPermission){
        x.checked = true;        
        this.allselect = true;
        if(!this.permissionArray.includes(x.id)){
          this.permissionArray.push(x.id);
          this.selectedPermissionCount = this.permissionArray.length;
        }
      }
    }else{
      for(let x of this.list.availableUserPermission){
        x.checked = false;
        this.allselect = false;
        this.permissionArray=[];
        this.selectedPermissionCount = this.permissionArray.length;
      }
    }
    
  }
  
  deselectAllUser(){  
    this.selectedUserCount = 0;
    for(let x of this.list.availableUserDetails){
      x.checked = false;
      this.allselectUser = false;
      this.userArray=[];
    }
  }
  selectAllUser(evt){
    if(evt.checked){
      for(let x of this.list.availableUserDetails){
        x.checked = true;        
        this.allselectUser = true;
        if(!this.userArray.includes(x.id)){
          this.userArray.push(x.id);
          this.selectedUserCount = this.userArray.length;
        }
      }
    }else{
      for(let x of this.list.availableUserDetails){
        x.checked = false;
        this.allselectUser = false;
        this.userArray=[];
        this.selectedUserCount = this.userArray.length;
      }
    }
    
  }
  get selectallStatus(){
  var status = true;
    for(let x of this.permission){
     if(!x.checked){
      status = false
     }
    }
    return status
  }

  createUserGroup(){
    if(this.permissionArray.length === 0 || this.userGroupForm.value.group_name == '' || this.userArray.length === 0){
      //alert('Kindly Select All fiends')
      return
    }
    let postUserBody = {
      "name": this.userGroupForm.value.group_name,
      "permissions": this.permissionArray,
      "users_ids": this.userArray
    }
    this.loader = true;
    let url = environment.apiURL + this.config.createUserGroup;
    this.api.doPOST(url,postUserBody).subscribe(x=>{
      this.loader = false;      
      this.dialogRef.close();
      this.list.getAllGroups(1);
    },
    (error) => {
      this.loader = false;
      let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
      this.dialog.UserUploadPopupConfirmPopup(data).subscribe(changed => {
      });
      // this.dialogRef.close();
      // //alert(error.message)
      // this.list.getAllGroups(1);
    })
  }
}
