import { MibeModel } from './mibemodel.model';
import { TrackVersion } from './trackversion.model';
import { TrackType } from './tracktype.model';
import { TrackComposer } from './trackcomposer.model';
import { Mood } from './mood.model';
import { MetaData } from './metadata.model';
import { Genre } from './genre.model';
import { Artist } from './artist.model';
import { Album } from './album.model';
import { Instrument } from './instrument.model';
import { InstrumentTrack } from './instrumenttrack.model';
import { NetworkReference } from './networkreference.model';
import { NetworkReferenceTrack } from './networkreferencetrack.model';
import { SonicReference } from './sonicreference.model';
import { SonicReferenceTrack } from './sonicreferncetrack.model';
import { Theme } from './theme.model';
import { Vocals } from './vocals.model';

export class Track implements MibeModel {
  id: number;
  track:any=[]
  trackId:number;
  albums: Album[];
  artist: Artist;
  composers: TrackComposer[];
  default_co_writer:any[];
  default_cowritten_by:any[];
  created_at: Date;
  description: string;
  genre: Genre;
  sub_genre: any;
  version: string;
  status: string;
  instruments: Instrument[] =[];
  version_instruments: Instrument[];
  instrumenttracks_set: InstrumentTrack[];
  is_exclusive: boolean;
  is_featured: boolean;
  sub_genre_info:any
  lyrics: { 
    bridge?:any;
    chorus?:any;
    pre_chorus?:any;
    verse?:any;
    id?:number
  }
    ;
    version_lyrics: { 
      bridge?:any;
      chorus?:any;
      pre_chorus?:any;
      verse?:any;
      id?:number
    }
      ;
    
  metadata: MetaData[];
  moods: Mood[];
  moods1: Mood[];
  moods2: Mood[];
  moods3: Mood[];
  network_references: any[];
  version_network_references: any=[];
  networkreferencetracks_set: NetworkReferenceTrack[];
  owned_by: number;
  added_by_role: number;
  sonic_references: any[];
  sonic_referencesNew =[];
  version_band_referencesNew =[];
  sonicreferencetracks_set: SonicReferenceTrack[];
  themes: Theme[];
  title: string;
  type: TrackType;
  updated_at: Date;
  versions: TrackVersion[];
  vocals: string;
  vocal:Vocals[];
  version_vocal: Vocals[];
  artist_references?:any[];
  band_references?:any[];
  version_band_references?: any[];
  tv_references?:any[];
  version_tv_references?:any=[];
  track_ending?:any;
  cowritten_by?:any;
  notify_composer: boolean;
  partial:boolean= true;
  track_sonic_reference:any
  version_sonic_reference:any=[]
  
  Instrumentsub_category?:any[]=[];
  Instrumentdescriptions:any[]= [];
  Instrumentsuggesstion:any[];
  Instrumentnew_suggestion:any[];
  support_version_ins_desc:any[]=[];
  Instrumentrm_desc_sug: any[] =[];
  Instrumentnew_desc_sug: any[] =[];
  new_ins: any[] =[]
  rm_ins: any[] =[]
  new_ins_decs: any[] =[]
  newAddedSuggestion:any[]=[]

  network_referencesSub_category?:any;
  network_referencesDescriptions:any[]= [];
  network_referencesSuggesstion:any[] =[];
  network_referencesNew_suggestion=[];
  network_ref_new_sug:any[]=[];
  network_ref_rm_sug:any[]=[];

  tv_referencesSub_category?:any;
  tv_referencesDescriptions:any[]= [];
  tv_referencesSuggesstion:any[];
  tv_referencesNew_suggestion=[];
  tv_ref_new_sug:any[]=[];
  tv_ref_rm_sug:any[]=[];
  newTv_ref_rm_sug:any[]=[];
  newNetwork_ref_rm_sug:any[]=[]
  isValid = true
  detailRow:boolean
  element:any

  //band reference
  vers_new_sonic_band:any[]=[]
  vers_rm_sonic_band:any[]=[]
  band_referencesNew_suggestion=[]
  newBand_ref_rm_sug:any[]=[];


  //sonic reference
  vers_new_sonic_ref: any[] =[]
  vers_rm_sonic_ref: any[]=[]
  sonic_referencesNew_suggestion=[];
  newSonic_ref_rm_sug:any[]=[];

  sonicRemove: any[] =[]
  bandRemove: any[]=[]

  Vocalssub_category?:any;
  Vocalsdescriptions?:any[];
  Vocalssuggesstion?:any;
  Vocalsnew_suggestion?:any;
  Vocalrm_desc_sug?:any=[]
  Vocalnew_desc_sug?:any=[]

  support_network_references = [];
  support_tv_references = [];
  support_band_references = [];
  support_sonic_references = [];
  /**
   * Create a Track.
   * @param {any} json - The JSON object with which to construct our Track.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a Track.
   * @param {any} json - The JSON object with which to load our Track.
   */
  loadWithJSON(json: any) {
    if (!json) {
      this.moods = [];
      this.versions = [];
      return;
    }
    this.id = json.id;
    this.trackId = json.id;
    if (json.albums) {
      this.albums = json.albums.map((a) => new Album(a));
    } else {
      this.albums = [];
    }
    if (json.artist) {
      this.artist = new Artist(json.artist);
    } else {
      this.artist = null;
    }
    if (json.composers) {
      this.composers = json.composers.map((c) => new TrackComposer(c));
    } else {
      this.composers = [];
    }
    if (json.created_at) {
      
      this.created_at = new Date(json.created_at);
       console.log(this.created_at);
    } else {
      this.created_at = null;
    }
    this.description = json.description;
    this.status = json.status;
    this.sub_genre = json.sub_genre;
    this.version = json.version;
    this.notify_composer = json.notify_composer;
    this.partial = json.partial;
    this.support_network_references = json.support_network_references
    this.support_tv_references = json.support_tv_references
    this.sonic_referencesNew = json.sonic_referencesNew
    this.version_band_referencesNew = json.version_band_referencesNew
    if (json.genre) {
      this.genre = new Genre(json.genre);
    } else {
      this.genre = null;
    }
    if (json.instruments) {
      this.instruments = json.instruments.map((i) => new Instrument(i));
    } else {
      this.instruments = [];
    }
    if (json.version_instruments) {
      this.version_instruments = json.version_instruments.map((i) => new Instrument(i));
    } else {
      this.version_instruments = [];
    }
    if (json.instrumenttracks_set) {
      this.instrumenttracks_set = json.instrumenttracks_set.map((i)=> new InstrumentTrack(i));
    } else {
      this.instrumenttracks_set = [];
    }
    this.is_exclusive = !!json.is_exclusive;
    this.is_featured = !!json.is_featured;
    if(json.lyrics){
      this.lyrics = json.lyrics;
    }
    else {
      this.lyrics ={}

    }
    if(json.version_lyrics){
      this.version_lyrics = json.version_lyrics;
    }
    else {
      this.version_lyrics ={}

    }
    if (json.metadata) {
      this.metadata = json.metadata.map((m) => new MetaData(m));
    } else {
      this.metadata = [];
    }
    if (json.moods) {
      this.moods = json.moods.map((m) => new Mood(m));
    } else {
      this.moods = [];
    }
    if (json.network_references) {
      this.network_references = json.network_references.map((r) => new NetworkReference(r));
    } else {
      this.network_references = [];
    }
    if (json.version_network_references) {
      this.version_network_references = json.version_network_references.map((r) => new NetworkReference(r));
    } else {
      this.version_network_references = [];
    }
    if (json.networkreferencetracks_set) {
      this.networkreferencetracks_set = json.networkreferencetracks_set.map((r) => new NetworkReferenceTrack(r));
    } else {
      this.networkreferencetracks_set = []
    }
    this.owned_by = json.owned_by;
    this.added_by_role = json.added_by_role;
    if (json.sonic_references) {
      this.sonic_references = json.sonic_references.map((r) => new SonicReference(r));
    } else {
      this.sonic_references = [];
    }
    if (json.track_sonic_reference) {
      this.track_sonic_reference = json.track_sonic_reference.map((r) => new SonicReference(r));
    } else {
      this.track_sonic_reference = [];
    }
    if (json.version_sonic_reference) {
      this.version_sonic_reference = json.version_sonic_reference.map((r) => new SonicReference(r));
    } else {
      this.version_sonic_reference = [];
    }
    if (json.sonicreferencetracks_set) {
      this.sonicreferencetracks_set = json.sonicreferencetracks_set.map((r) => new SonicReferenceTrack(r));
    } else {
      this.sonicreferencetracks_set = [];
    }
    if (json.themes) {
      this.themes = json.themes.map((t) => new Theme(t));
    } else {
      this.themes = [];
    }
    this.title = json.title;
    if (json.type) {
      this.type = new TrackType(json.type);
    } else {
      this.type = null;
    }
    if (json.updated_at) {
      this.updated_at = new Date(json.updated_at);
    } else {
      this.updated_at = null;
    }
    if (json.versions) {
      this.versions = json.versions.map((v) => new TrackVersion(v));
      this.versions.sort((a, b) => {
        if (a.is_full_version) {
          return -1;
        }
        if (b.is_full_version) {
          return 1;
        }
        if (a.is_main_track) {
          return -1;
        }
        if (b.is_main_track) {
          return 1;
        }
        if (a.created_at < b.created_at) {
          return -1;
        }
        if (b.created_at < a.created_at) {
          return 1;
        }
        return 0;

      })
    } else {
      this.versions = [];
    }
    this.vocals = json.vocals;
    this.version_vocal = json.version_vocal;
    if(json.Instrumentnew_suggestion){
      this.Instrumentnew_suggestion = json.Instrumentnew_suggestion
    } else {
      this.Instrumentnew_suggestion = [];
    }
    if(json.support_version_ins_desc){
      this.support_version_ins_desc = json.support_version_ins_desc
    }
    else{
      this.support_version_ins_desc =[]
    }
    if(json.artist_references){
      this.artist_references = json.artist_references
    } else {
      this.artist_references = [];
    }
    if(json.band_references){
      this.band_references = json.band_references
    } else {
      this.band_references = [];
    }
    if(json.version_band_references){
      this.version_band_references = json.version_band_references
    } else {
      this.version_band_references = [];
    }
    if(json.tv_references){
      this.tv_references = json.tv_references
    } else {
      this.tv_references = [];
    }
    if(json.version_tv_references){
      this.version_tv_references = json.version_tv_references
    } else {
      this.version_tv_references = [];
    }
   // <!-- Add Newsuggestion -->

    if(json.tv_referencesNew_suggestion){
      this.tv_referencesNew_suggestion = json.tv_referencesNew_suggestion
    } else {
      this.tv_referencesNew_suggestion = [];
    }
    
    if(json.sonic_referencesNew_suggestion){
      this.sonic_referencesNew_suggestion = json.sonic_referencesNew_suggestion
    } else {
      this.sonic_referencesNew_suggestion = [];
    }

    if(json.network_referencesNew_suggestion){
      this.network_referencesNew_suggestion = json.network_referencesNew_suggestion
    } else {
      this.network_referencesNew_suggestion = [];
    }
 
    // this.Instrumentnew_suggestion = json.Instrumentnew_suggestion;
    if (json.vocal) {
      this.vocal = json.vocal.map((i) => new Vocals(i));
    } else {
      this.vocal = [];
    }
    if (json.version_vocal) {
      this.version_vocal = json.version_vocal.map((i) => new Vocals(i));
    } else {
      this.version_vocal = [];
    }


  }
  /**
   * Returns a JSON representation of our Track
   * @return {any} - The JSON representation of our Track
   */
  toJSON() {
    var json: any = {
      'id': this.id,
      'trackId':this.trackId,
      'albums': this.albums.map((a)=>a.toJSON()),
      'artist': this.artist ? this.artist.toJSON():null,
      'composers': this.composers.map((c)=>c.toJSON()),
      'created_at': this.created_at ? this.created_at.toJSON():null,
      'description': this.description,
      'status': this.status,
      'notify_composer': this.notify_composer,
      'partial':this.partial,
      'sub_genre': this.sub_genre,
      'version': this.version,
      'genre': this.genre ? this.genre:null,
      'instruments': this.instruments.map((i)=> i.toJSON()),
      'version_instruments': this.version_instruments.map((i)=> i.toJSON()),
      'instrumenttracks_set': this.instrumenttracks_set.map((i) => i.toJSON()),
      'is_exclusive': this.is_exclusive,
      'is_featured': this.is_featured,
      'lyrics': this.lyrics,
      'version_lyrics':this.version_lyrics,
      'metadata': this.metadata.map((m)=>m.toJSON()),
      'moods': this.moods.map((m)=>m.toJSON()),
      'network_references': this.network_references.map((r)=> r.toJSON()),
      'version_network_references': this.version_network_references.map((r)=> r.toJSON()),

      'networkreferencetracks_set': this.networkreferencetracks_set.map((r) => r.toJSON()),
      'owned_by': this.owned_by,
      'added_by_role':this.added_by_role,
      'sonic_references': this.sonic_references.map((r)=> r.toJSON()),
      'version_sonic_reference': this.version_sonic_reference.map((r)=> r.toJSON()),

      'sonicreferencetracks_set': this.sonicreferencetracks_set.map((r) => r.toJSON()),
      'themes': this.themes.map((t)=>t.toJSON()),
      'title': this.title,
      'type': this.type ? this.type.toJSON():null,
      'updated_at': this.updated_at ? this.updated_at.toJSON():null,
      'versions': this.versions.map((v)=>v.toJSON()),
      'vocals': this.vocals,
      'vocal':this.vocal.map((i)=>i.toJSON()),
      'version_vocal':this.version_vocal.map((i)=>i.toJSON()),
      'Instrumentnew_suggestion':this.Instrumentnew_suggestion && this.Instrumentnew_suggestion?.length>0?this.Instrumentnew_suggestion.map((i)=>i.toJSON()):[],
      'artist_references':this.artist_references && this.artist_references?.length>0?this.artist_references.map((i)=>i.toJSON()):[],
      'band_references':this.band_references && this.band_references?.length>0?this.band_references.map((i)=>i.toJSON()):[],

      'tv_references':this.tv_references && this.tv_references?.length>0?this.tv_references.map((i)=>i.toJSON()):[],
      'version_tv_references':this.version_tv_references && this.version_tv_references?.length>0?this.version_tv_references.map((i)=>i.toJSON()):[],

        // <!-- Add Newsuggestion -->
        'version_band_references':this.version_band_references && this.version_band_references?.length>0?this.version_band_references.map((i)=>i.toJSON()):[],

       'tv_referencesNew_suggestion':this.tv_referencesNew_suggestion && this.tv_referencesNew_suggestion?.length>0?this.tv_referencesNew_suggestion.map((i)=>i.toJSON()):[],
       'network_referencesNew_suggestion':this.network_referencesNew_suggestion && this.network_referencesNew_suggestion?.length>0?this.network_referencesNew_suggestion.map((i)=>i.toJSON()):[],
        'support_tv_references': this.support_tv_references,
        'support_network_references': this.support_network_references,
        'sonic_referencesNew_suggestion':this.sonic_referencesNew_suggestion && this.sonic_referencesNew_suggestion?.length>0?this.sonic_referencesNew_suggestion.map((i)=>i.toJSON()):[],
        'version_band_referencesNew': this.version_band_referencesNew,
        'sonic_referencesNew': this.sonic_referencesNew,
        'support_version_ins_desc':this.support_version_ins_desc

    };
    return json;
  }
}
