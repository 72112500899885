<mat-table [dataSource]="listDataSource" matSort class="track-list" multiTemplateDataRows >
    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Track Name</mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.title }}</mat-cell>
    </ng-container>
  
    <ng-container matColumnDef="artist__user__first_name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Artist</mat-header-cell>
      <mat-cell *matCellDef="let row">{{
            row?.artist?.name || ''
        }}</mat-cell>
    </ng-container>
  
    <ng-container matColumnDef="genre">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Genre</mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row.genre && row.genre.parent ? row.genre.parent.name : row.genre ? row.genre.name : "" }}
      </mat-cell>
    </ng-container>
  
    <ng-container matColumnDef="sub_genre">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Subgenre</mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row.sub_genre ? row.sub_genre.name : "" }}
      </mat-cell>
    </ng-container>
  
    <ng-container matColumnDef="created_at">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Created</mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row.created_at | date: 'shortDate' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="updated_at">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Updated</mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row.updated_at | date: 'shortDate' }}
      </mat-cell>
    </ng-container>
  
    <ng-container matColumnDef="approved_at">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Approved</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <ng-container *ngIf="row.status != 'APPROVED'">N/A</ng-container>
        <ng-container *ngIf="row.status == 'APPROVED'">{{ row.track_approved_at | date: 'shortDate' }}</ng-container>
      </mat-cell>
    </ng-container>
  
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef >Status</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span  *ngIf="row.status == 'PENDING'" class="status-bullet" style="background-color: #E3B950;" [matTooltip]="pendingBtn" matTooltipPosition="left" matTooltipClass="tooltip-white"></span>
        <span  *ngIf="row.status == 'PENDING_APPROVAL'" class="status-bullet" style="background-color: #47CDEF;" [matTooltip]="pendingApproveBtn" matTooltipPosition="left" matTooltipClass="tooltip-white"></span>
        <span  *ngIf="row.status == 'APPROVED'" class="status-bullet" style="background-color: #50E3C2;" [matTooltip]="approveBtn" matTooltipPosition="left" matTooltipClass="tooltip-white"></span>
        <span  *ngIf="row.status == 'DENIED'" class="status-bullet" style="background-color: #D73939;" [matTooltip]="denieBtn" matTooltipPosition="left" matTooltipClass="tooltip-white"></span>
        <span  *ngIf="row.status == 'DISABLED'" class="status-bullet" style="background-color: #8A8A8D;" [matTooltip]="disableBtn" matTooltipPosition="left" matTooltipClass="tooltip-white"></span>
      </mat-cell>
    </ng-container>
  
    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button type="button" (click)="$event.preventDefault();$event.stopPropagation();rowClicked1(row)" class="versions-toggle" class="expand-button">
          <svg width="10px" height="5px" viewBox="0 0 10 5" class="carrot"
            [ngClass]="{ flip: trackIsExpanded(row) }">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
              stroke-linejoin="round">
              <g transform="translate(-1392.000000, -396.000000)" class="inner-stroke" stroke-width="0.95">
                <g transform="translate(392.000000, 351.000000)">
                  <g transform="translate(920.550000, 36.100000)">
                    <polyline points="80.75 9.5 84.55 13.3 88.35 9.5"></polyline>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </button>
      </mat-cell>
    </ng-container>
  
    <ng-container matColumnDef="expandedDetail">
      <mat-cell *matCellDef="let row" class="expanded-cell">
        <div class="version-row" *ngFor="let version of versions">
          <div class="mat-cell title">
            <button type="button"   [ngClass]="{'disabledElement': (loadingTracks)}" class="play-button" (click)="playButtonPressed(row, version)"
              *ngIf="!isPlaying(version)">
              <svg width="8px" height="10px" viewBox="0 0 8 10" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(-25.000000, -23.000000)">
                    <g transform="translate(16.000000, 16.000000)">
                      <path class="inner-path"
                        d="M16.0796687,12.8192319 L11.3234623,16.1485764 C10.871013,16.4652909 10.2474826,16.3552561 9.93076808,15.9028068 C9.8131096,15.7347232 9.75,15.5345165 9.75,15.3293444 L9.75,8.67065556 C9.75,8.11837081 10.1977153,7.67065556 10.75,7.67065556 C10.9551721,7.67065556 11.1553788,7.73376517 11.3234623,7.85142364 L16.0796687,11.1807681 C16.532118,11.4974826 16.6421528,12.121013 16.3254383,12.5734623 C16.2584893,12.6691037 16.17531,12.752283 16.0796687,12.8192319 Z"
                        fill="#FFFFFF"></path>
                    </g>
                  </g>
                </g>
              </svg>
            </button>
            <button type="button" [ngClass]="{'disabledElement': (loadingTracks)}"  (click)="pauseButtonPressed(row, version)" class="pause-button"
              *ngIf="isPlaying(version)">
              <svg width="10px" height="14px" viewBox="0 0 10 14" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(-426.000000, -746.000000)" fill="#15151C" class="inner-path">
                    <g transform="translate(393.000000, 704.000000)">
                      <path
                        d="M33.2499996,42.9499995 L36.4166662,42.9499995 L36.4166662,55.616666 L33.2499996,55.616666 L33.2499996,42.9499995 Z M39.5833328,42.9499995 L42.7499995,42.9499995 L42.7499995,55.616666 L39.5833328,55.616666 L39.5833328,42.9499995 Z">
                      </path>
                    </g>
                  </g>
                </g>
              </svg>
            </button>
              <span class="version-title" (click)="isPlaying(version) ? pauseButtonPressed(row, version) : playButtonPressed(row, version)">
                <!-- <span *ngIf="version.is_main_track "> {{row.title}} - {{ version.title  || '' }} - Main</span>
                <span  *ngIf="!version.is_main_track && !version.is_full_version">{{row.title}} - {{ version.title || '' }}</span>
                <span  *ngIf="!version.is_main_track && version.is_full_version && version.title === 'Narrative'">{{row.title}} - {{ version.title || '' }}</span>
                <span  *ngIf="!version.is_main_track && version.is_full_version  && version.title !== 'Narrative' ">{{ version.title || '' }}</span> -->
                <span> {{ version.title  || '' }} </span>
              </span>
          </div>
          <div class="mat-cell actions">
            <button (click)="displayTrackInfo(row, version)" type="button" matTooltip="Info"
              matTooltipClass="tooltip-white" class="action-button">
              <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(-292.000000, -20.000000)">
                    <g transform="translate(0.000000, -4.000000)">
                      <g transform="translate(292.000000, 24.000000)" class="inner-stroke">
                        <circle cx="8" cy="8" r="7.5"></circle>
                        <circle fill-rule="nonzero" cx="8" cy="4.75" r="1"></circle>
                        <line x1="8" y1="11" x2="8" y2="8" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"></line>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </button>
            <button type="submit" id="checkbox1" matTooltip="Edit" matTooltipClass="tooltip-white" class="action-button"
              (click)="$event.stopPropagation();$event.preventDefault();openEditTrack(row, version);">
              <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                  stroke-linejoin="round">
                  <g transform="translate(1.000000, 1.000000)" class="inner-stroke">
                    <path
                      d="M14.4,10.128 L14.4,14.4 C14.4,15.2836556 13.6836556,16 12.8,16 L1.6,16 C0.7163444,16 0,15.2836556 0,14.4 L0,3.2 C0,2.3163444 0.7163444,1.6 1.6,1.6 L5.872,1.6">
                    </path>
                    <polygon points="12.8 0 16 3.2 8 11.2 4.8 11.2 4.8 8"></polygon>
                  </g>
                </g>
              </svg>
            </button>
            <button type="button" [disabled]="version.status  !== 'APPROVED'" matTooltip="Add to playlist" matTooltipClass="tooltip-white" (click)="displayAddSongPlaylistDialog(row, version)"
              matTooltip="Add to Playlist" matTooltipClass="tooltip-white" class="action-button">
              <svg width="17px" height="17px" viewBox="0 0 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                  stroke-linejoin="round">
                  <g class="inner-stroke" transform="translate(0.000000, 1.000000)">
                    <line x1="0.533333333" y1="2.66666667" x2="6.93333333" y2="2.66666667"></line>
                    <line x1="0.533333333" y1="9.06666667" x2="13.3333333" y2="9.06666667"></line>
                    <line x1="0.533333333" y1="15.4666667" x2="13.3333333" y2="15.4666667"></line>
                    <g transform="translate(10.666667, 0.000000)">
                      <line x1="2.66666667" y1="0" x2="2.66666667" y2="5.33333333"></line>
                      <line x1="0" y1="2.66666667" x2="5.33333333" y2="2.66666667"></line>
                    </g>
                  </g>
                </g>
              </svg>
            </button>
  
            <button type="button" matTooltip="Delete" matTooltipClass="tooltip-white" class="action-button" (click)="displayDeleteTrackVersionDialog(row, version)">
              <svg width="14px" height="16px" viewBox="0 0 14 16" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                  stroke-linejoin="round">
                  <g transform="translate(-1.000000, 0.000000)" class="inner-stroke">
                    <g transform="translate(2.000000, 1.000000)">
                      <polyline points="0 2.8 1.33333333 2.8 12 2.8"></polyline>
                      <path
                        d="M10.6666667,2.8 L10.6666667,12.6 C10.6666667,13.3731986 10.069713,14 9.33333333,14 L2.66666667,14 C1.930287,14 1.33333333,13.3731986 1.33333333,12.6 L1.33333333,2.8 M3.33333333,2.8 L3.33333333,1.4 C3.33333333,0.62680135 3.930287,0 4.66666667,0 L7.33333333,0 C8.069713,0 8.66666667,0.62680135 8.66666667,1.4 L8.66666667,2.8">
                      </path>
                      <line x1="4.66666667" y1="6.3" x2="4.66666667" y2="10.5"></line>
                      <line x1="7.33333333" y1="6.3" x2="7.33333333" y2="10.5"></line>
                    </g>
                  </g>
                </g>
              </svg>
            </button>
          </div>
          <div class="mat-cell" style="margin-left: 13%;">
            {{ version.created_at | date: 'shortDate' }}
          </div>
          <div class="mat-cell">
            {{ version.updated_at | date: 'shortDate' }}
          </div>
          <div *ngIf="version.status != 'APPROVED'" class="mat-cell">N/A</div>
          <div *ngIf="version.status == 'APPROVED'" class="mat-cell">{{ version.approved_at | date: 'shortDate' }}</div>
          <div class="mat-cell">
            <ng-container *ngIf="!version.status">N/A</ng-container>
            <span  *ngIf="version.status == 'PENDING'" class="status-bullet" style="background-color: #E3B950;" [matTooltip]="pendingBtn" matTooltipPosition="left" matTooltipClass="tooltip-white"></span>
        <span  *ngIf="version.status == 'PENDING_APPROVAL'" class="status-bullet" style="background-color: #47CDEF;" [matTooltip]="pendingApproveBtn" matTooltipPosition="left" matTooltipClass="tooltip-white"></span>
        <span  *ngIf="version.status == 'APPROVED'" class="status-bullet" style="background-color: #50E3C2;" [matTooltip]="approveBtn" matTooltipPosition="left" matTooltipClass="tooltip-white"></span>
        <span  *ngIf="version.status == 'DENIED'" class="status-bullet" style="background-color: #D73939;" [matTooltip]="denieBtn" matTooltipPosition="left" matTooltipClass="tooltip-white"></span>
        <span  *ngIf="version.status == 'DISABLED'" class="status-bullet" style="background-color: #8A8A8D;" [matTooltip]="disableBtn" matTooltipPosition="left" matTooltipClass="tooltip-white"></span>
            <span
              class="status-bullet"
              *ngIf="getVersionStatus(version)"
              [matTooltip]="getVersionStatus(version).name"
              matTooltipPosition="left"
              matTooltipClass="tooltip-white"
              [ngStyle]="{'background-color': getVersionStatus(version).color}"
            ></span>
          </div>
          <div class="mat-cell"></div>
        </div>
        <div class="version-row" *ngIf="! versions || versions.length === 0">
          There is no version for this track
        </div>
      </mat-cell>
    </ng-container>
  
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns" class="main-row"
      [ngClass]="{ expanded: trackIsExpanded(row) }" (click)="rowClicked1(row)"></mat-row>
    <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"
      [@detailExpand]="trackIsExpanded(row) ? 'expanded' : 'collapsed'"></mat-row>
  </mat-table>
  