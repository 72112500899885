import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-mgmt',
  templateUrl: './user-mgmt.component.html',
  styleUrls: ['./user-mgmt.component.scss']
})
export class UserMgmtComponent implements OnInit {

  constructor(
    private _router: Router
  ) {}

  ngOnInit(): void {
  }

  isActive(tab:string) {
    return this._router.url.includes(tab);
  }

}
