<div class="main" >
  <div class="sidebar">
    <div class="top-holder">
      <div class="tabs">
        <span class="tab" [ngClass]="{'active': activeTab === 'playlists'}" (click)="switchTab('playlists');">All
          Playlists</span>
        <span class="tab" (click)="switchTab('folders');" [ngClass]="{'active': activeTab === 'folders'}">Folders</span>
      </div>
      <form action="#" class="search-filter" #searchForm="ngForm" (ngSubmit)="$event.preventDefault()"
        [ngClass]="{'search-filter-focused': searchFilterFocused}">
        <label for="search-filter-field" class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14">
            <path fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="20"
              d="M6 1a5 5 0 110 10A5 5 0 016 1zM13.002 13.002L9.535 9.535" /></svg>
        </label>
        <input [ngClass]="{'disabledElement':(this.currentUser == null)}" [(ngModel)]="searchString" name="searchString" type="text" class="txt" id="search-filter-field"
          (focus)="searchFilterFocused = true" (keyup)="$event.preventDefault();$event.stopPropagation();submitSearch()"
          placeholder="Search" />
      </form>
      <!-- (keyup.enter)="$event.target.blur();" (blur)="submitSearch()" -->
      <div class="date-picker dark-theme" >
        <mat-form-field appearance="fill"  [ngClass]="{'disabledElement':(this.currentUser == null)}">
          <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-input [rangePicker]="picker" disabled [max]="maxDate">
            <input matStartDate placeholder="From" (dateChange)="startChanged($event.value)" [ngModel]="startDate">
            <input matEndDate placeholder="To" (dateChange)="endChanged($event.value)" [ngModel]="endDate">
          </mat-date-range-input>
          <button class="remove" (click)="clear()">
            <svg width="10px" height="12px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                stroke-linejoin="round">
                <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                  <g id="Genre-Options">
                    <g id="Close" transform="translate(1439.000000, 32.000000)">
                      <path d="M24,0 L0,24" id="Path"></path>
                      <path d="M0,0 L24,24" id="Path"></path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </button>
          <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>



        </mat-form-field>

      </div>
      <div class="playlists-info" *ngIf="activeTab === 'playlists'">
        <strong>{{playlistCount}} Playlists</strong>
        <button class="button" (click)="displayNewPlaylistDialog()">+ New Playlist</button>
      </div>
      <div class="playlists-info" *ngIf="activeTab === 'folders'">
        <strong>{{totalFolders}} Groups</strong>
        <button class="button" (click)="displayNewPlaylistFolderDialog()">+ New Playlist Folder</button>
      </div>
    </div>
    <div class="playlists-holder" *ngIf="activeTab === 'playlists'">
      <app-loader *ngIf="loadingPlaylists"></app-loader>
      <ul class="playlists" *ngIf="!loadingPlaylists &&  currentUser !== null " style="padding-left:10px;">
        <!-- [ngClass]="{'active': list.currentUserGroup?.id === user?.id}" --> 
        <li class="playlist" style="display: inline-flex;padding-bottom: 2px;" [ngClass]="{'active': currentPlaylist && playlist && currentValueItem == playlist.id}"
          *ngFor="let playlist of playlists; trackBy:playlistTrackBy" (click)="currentPlaylist = playlist; changeUrl(playlist.id)">
          <button type="submit" matTooltip="Edit" matTooltipClass="tooltip-white" class="action-button"
          (click)="displayEditPlaylistDialog(playlist)" *ngIf="this.currentPlaylist">
           <svg width="15px" height="15px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink">
             <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
               stroke-linejoin="round">
               <g transform="translate(1.000000, 1.000000)" class="inner-stroke">
                 <path
                   d="M14.4,10.128 L14.4,14.4 C14.4,15.2836556 13.6836556,16 12.8,16 L1.6,16 C0.7163444,16 0,15.2836556 0,14.4 L0,3.2 C0,2.3163444 0.7163444,1.6 1.6,1.6 L5.872,1.6">
                 </path>
                 <polygon points="12.8 0 16 3.2 8 11.2 4.8 11.2 4.8 8"></polygon>
               </g>
             </g>
           </svg>
         </button>
         <p>{{playlistDisplayName(playlist)}}</p> 
        </li>
      </ul>
      <ul class="playlists" *ngIf="!loadingPlaylists &&  currentUser == null ">
        <!-- [ngClass]="{'active': list.currentUserGroup?.id === user?.id}" --> 
        <li class="playlist" [ngClass]="{'active': currentPlaylist && playlist && currentPlaylist.id == playlist.id}"
          *ngFor="let playlist of playlists; trackBy:playlistTrackBy" (click)="currentPlaylist = playlist; changeUrl(playlist.id)">
          {{playlistDisplayName(playlist)}}
        </li>
      </ul>
    </div>
    <div class="folders-holder" *ngIf="activeTab === 'folders'">
      <app-loader *ngIf="loadingFolders"></app-loader>
      <ul class="folders" *ngIf="!loadingFolders ">
        <li class="folder" [ngClass]="{'active': isFolderOpen(folder.id)}"
          *ngFor="let folder of folders; trackBy:folderTrackBy" (click)="toggleFolder(folder.id,folder.playlists)"
          (mouseenter)="hoveringFolder = folder.id" (mouseleave)="hoveringFolder = null;">
          <ng-container *ngIf="hoveringFolder == folder.id">
            <svg width="8" height="8" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              (click)="$event.preventDefault();$event.stopPropagation();displayEditPlaylistFolderDialog(folder)"
              class="ico edit-folder">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                stroke-linejoin="round">
                <g transform="translate(1.000000, 1.000000)" class="inner-stroke">
                  <path
                    d="M14.4,10.128 L14.4,14.4 C14.4,15.2836556 13.6836556,16 12.8,16 L1.6,16 C0.7163444,16 0,15.2836556 0,14.4 L0,3.2 C0,2.3163444 0.7163444,1.6 1.6,1.6 L5.872,1.6">
                  </path>
                  <polygon points="12.8 0 16 3.2 8 11.2 4.8 11.2 4.8 8"></polygon>
                </g>
              </g>
            </svg>
          </ng-container>
          <ng-container *ngIf="hoveringFolder != folder.id">
            <svg class="ico" xmlns="http://www.w3.org/2000/svg" width="8" height="8">
              <path fill="#fff"
                d="M8 6.442a.79.79 0 01-.8.778H.8a.79.79 0 01-.8-.778V.998A.79.79 0 01.8.22h2l.8 1.167h3.6c.442 0 .8.348.8.777z" />
              </svg>
          </ng-container>
          {{folderDisplayName(folder)}}
          <svg class="bullet" xmlns="http://www.w3.org/2000/svg" width="10" height="6">
            <path fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="20"
              d="M1 1h0l4 4h0l4-4h0" /></svg>
          <ul class="playlists" *ngIf="isFolderOpen(folder.id)" style="padding-left:0;">
            <li class="playlist"
              [ngClass]="{'active': currentPlaylist && playlist && currentPlaylist.id == playlist.id}"
              *ngFor="let playlist of folder.playlists; trackBy:playlistTrackBy"
              (click)="currentPlaylist = playlist;$event.stopPropagation();setcurrentplaylist(playlist); changeUrl(playlist.id)">
            <button class="action-button" style="padding-left:0;" type="submit" matTooltip="Edit" matTooltipClass="tooltip-white" (click)="displayEditPlaylistDialog(playlist)" *ngIf="this.currentPlaylist">
              <svg width="15px" height="15px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
               <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
               stroke-linejoin="round">
               <g transform="translate(1.000000, 1.000000)" class="inner-stroke">
                 <path
                   d="M14.4,10.128 L14.4,14.4 C14.4,15.2836556 13.6836556,16 12.8,16 L1.6,16 C0.7163444,16 0,15.2836556 0,14.4 L0,3.2 C0,2.3163444 0.7163444,1.6 1.6,1.6 L5.872,1.6">
                 </path>
                 <polygon points="12.8 0 16 3.2 8 11.2 4.8 11.2 4.8 8"></polygon>
               </g>
             </g>
           </svg>
         </button>
            <span style="padding-top: 11px;">{{playlistDisplayName(playlist)}}</span>  
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="playlists-pagination"
      *ngIf="((activeTab === 'playlists' && !loadingPlaylists) || (activeTab === 'folders' && !loadingFolders)) && playlistsNumberOfPages > 1">
      <button class="button" (click)="currentPlaylistsPage = currentPlaylistsPage-1;"
        [disabled]="(this.currentUser == null) || currentPlaylistsPage <= 1">
        <svg width="10px" height="16px" viewBox="0 0 10 16" version="1.1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
            stroke-linejoin="round">
            <g id="Number-Pagination" transform="translate(1.000000, -9.000000)" class="inner-stroke" stroke="#FFFFFF">
              <g id="Stroke-1">
                <polyline points="8 25 0 17 8 9"></polyline>
              </g>
            </g>
          </g>
        </svg>
        Prev
      </button>
      <input type="text" class="txt" readonly [(ngModel)]="currentPlaylistsPage"  [disabled]="(this.currentUser == null)">
      <button class="button btn-next" (click)="currentPlaylistsPage = currentPlaylistsPage+1;"
        [disabled]="(this.currentUser == null) || playlistsNumberOfPages <= currentPlaylistsPage">
        Next
        <svg width="10px" height="16px" viewBox="0 0 10 16" version="1.1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
            stroke-linejoin="round">
            <g id="Number-Pagination" transform="translate(-343.000000, -9.000000)" class="inner-stroke"
              stroke="#FFFFFF">
              <g id="Stroke-1">
                <polyline transform="translate(348.000000, 17.000000) scale(-1, 1) translate(-348.000000, -17.000000) "
                  points="352 25 344 17 352 9"></polyline>
              </g>
            </g>
          </g>
        </svg>
      </button>
    </div>
  </div>

  <div class="track-list" *ngIf="!showNoData">
    <div class="header">
      <div class="title">
        <h3>{{playlistDisplayName(this.currentPlaylist)}}</h3>
        <!-- <button type="button"
          matTooltip="Edit Playlist"
          matTooltipClass="tooltip-white"
          class="action-button"
          (click)="displayEditPlaylistDialog()"
          *ngIf="this.currentPlaylist">
            <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                stroke-linejoin="round">
                <g transform="translate(1.000000, 1.000000)" class="inner-stroke">
                  <path
                    d="M14.4,10.128 L14.4,14.4 C14.4,15.2836556 13.6836556,16 12.8,16 L1.6,16 C0.7163444,16 0,15.2836556 0,14.4 L0,3.2 C0,2.3163444 0.7163444,1.6 1.6,1.6 L5.872,1.6">
                  </path>
                  <polygon points="12.8 0 16 3.2 8 11.2 4.8 11.2 4.8 8"></polygon>
                </g>
              </g>
            </svg>
        </button> -->
      </div>
      <div class="btns">
        <button class="button" (click)="displayDuplicatePlaylistDialog()">Duplicate</button>
        <button class="button" (click)="displayAddRemovePlaylistDialog()">Add/remove</button>
        <button class="button" (click)="displayDeletePlaylistDialog()">Delete</button>
        <a class="button hoverbutton" (click)="downloadcue()">Download Cuesheet
          <img class="shareicon-width" src="assets/img/shareicon.svg">
        </a>
      </div>
    </div>
    <div class="data-blocks" *ngIf="loadingCount && currentPlaylist">
      <div class="data-block">
        <!-- <strong>{{iscurrentPlaylist(currentPlaylist.track_count)}}</strong> -->
        <strong>{{this.trackCount ? this.trackCount : 0}}</strong>
        <span>Tracks</span>
        <button class="button" *ngIf="currentPlaylist.is_public"
          (click)="displayManageTracksClientsDialog('tracks')">Manage</button>
      </div>
      <div class="data-block">
        <strong>{{(currentPlaylist && currentPlaylist.composer_count) ? (composerCount ? composerCount : 0): (currentPlaylist.composer_count ? currentPlaylist.composer_count:0) }}</strong>
        <span>Composers</span>
      </div>
      <div class="data-block">
        <strong>{{ this.activeclientCount.length}}</strong>
        <span>Active Clients</span>
        <button class="button" *ngIf="currentPlaylist.is_public"
          (click)="displayManageTracksClientsDialog('clients')">Manage</button>
      </div>
    </div>
    <app-loader *ngIf="loadingTracks"></app-loader>
    <div class="track-list"
      [ngClass]="{'music-player-visible': playingTrack && playingTrackVersion, 'music-player-collapsed': musicPlayerCollapsed}">
      <app-playlist-track-list
       class="tracks" *ngIf="!loadingTracks && trackCount" [dataSource]="tracks" [playlist]="currentValueItem"
        [sortValue]="ordering" (trackChange)="loadTracks();loadPlaylistOrFolder()" (sortChange)="sortChange($event)"
        (playingTrack)="setPlayingTrack($event)" [isNotDraggable]="!currentPlaylist.is_public"></app-playlist-track-list>
      <div class="no-data"style="margin-top:12%;" *ngIf="!loadingTracks && !trackCount">No data found</div>

      <!-- //   ---playlist waveform-----// -->

      <div class="scrubber" >
        <app-playlistwaveform-wave  [trackProgress]="playbackProgress" *ngIf="playTrack"
          (trackProgressChange)="playbackProgress = $event" (Current)="getCurrent= $event"
          (Duration)="getDuration = $event" [url]=mp3_file [name]="trackName" (tracks)="trackresult"
          (collapsePlay)="toggleMusicPlayerCollapsed()"></app-playlistwaveform-wave>
      </div>

      <!-- <app-track-list
      class="tracks"
      *ngIf="!loadingTracks"
      [dataSource]="tracks"
      [sortValue]="ordering"
      (collapseMusicPlayer)="collapseMusicPlayer()"
      (sortChange)="sortChange($event)"
      (listChange)="loadTracks()"
  ></app-track-list> -->

      <app-pagination *ngIf="tracksNumberOfPages > 1" [page]="currentTracksPage"
        (pageChange)="currentTracksPage = $event" [numberOfPages]="tracksNumberOfPages"></app-pagination>
      <div class="music-player-wrapper"
        [ngClass]="{hide: !playingTrack || !playingTrackVersion, collapsed: musicPlayerCollapsed}">
        <button type="button" class="music-player-expand-button" (click)="toggleMusicPlayerCollapsed()">
          <svg width="18px" height="9px" viewBox="0 0 18 9" version="1.1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="player_close" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="closed-player" transform="translate(-730.000000, -169.000000)" class="inner-stroke"
                stroke="#50E3C2">
                <polyline id="Path-2" points="730.682129 177.754395 738.843146 169.593378 747.004163 177.754395">
                </polyline>
              </g>
            </g>
          </svg>
        </button>
        <!-- <app-musicplayer [track]="playingTrack"
          [version]="playingTrack"
          [ngClass]="{hide: !playingTrack || !playingTrackVersion, collapsed: musicPlayerCollapsed}"
          (collapse)="toggleMusicPlayerCollapsed()"
          class="music-player"></app-musicplayer> -->
      </div>
    </div>
  </div>
  <div class="track-list" *ngIf="showNoData">
    <div class="header">

      <div class="btns">
        <button class="button" (click)="displayAddRemovePlaylistDialog()">Add/remove</button>
        <button class="button" (click)="displayDeletePlaylistDialog()">Delete</button>

      </div>
    </div>
    <div class="no-track-list"> No Playlist data.
    </div>
  </div>
</div>
<!-- <div class="no-playlist"  *ngIf="_userService?.currentUser?.is_artist === true">Please contact MIBE representative.</div> -->