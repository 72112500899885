import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { UserMenuService } from '../../shared/services/user-menu.service';

@Component({
  selector: 'app-create-tracks',
  templateUrl: './create-tracks.component.html',
  styleUrls: ['./create-tracks.component.scss']
})
export class CreateTracksComponent implements OnInit {
  selectedgenre = 'Select'; 
  selectedsubg = 'Select'; 
  selectedmood = 'Select'; 
  selectedtempo = 'Select'; 
  selectedkey = 'Select'; 
  selectedtrackend = 'Select'; 
  createTrackForm: FormGroup;
  loading: boolean = false;
  @Output() getNewTrack: EventEmitter<any> = new EventEmitter();
  panelOpenState = false;
  inspanelOpenState = false;
  tvnpanelOpenState = false;
  sonpanelOpenState = false;
  vocpanelOpenState = false;
  lyricpanelOpenState = false;
  getGenreList: any = [];
  getSubGenreList: any = [];
  getMoodsList: any = [];
  getInstrumentList: any = [];
  getInsDescriptionList: any = [];
  getVocalList: any = [];
  getVocalDescList: any = [];
  genreId: any;
  subgenreId: any;
  instrumentation_id: any;
  insDesc_id: any;
  vocal_id: any;
  moodsArray: any = [];
  insDescArray: any = [];
  vocalDescArray: any = [];
  tempoId: any = 1;
  trackEndingId: any = 1;
  constructor(
    public ctf: FormBuilder,
    public api: UserMenuService, 
    public config: MenuConfigService
    ) { }

  ngOnInit(): void {
    this.createTrackForm = this.ctf.group({
      title: new FormControl('',[Validators.required]),
      artist: new FormControl('',[Validators.required]),
      genre: new FormControl('',[Validators.required,Validators.email]),
      sub_genre: new FormControl('',[Validators.required]),
      moods: new FormControl('',[Validators.required]),
      tempo: new FormControl('',[Validators.required]),
      track_ending: new FormControl(''),
      versions: new FormControl(''),
      is_exclusive: new FormControl('',[Validators.required]),
      composers: new FormControl(''),
      instruments: new FormControl([]),
      tv_references: new FormControl([]),
      network_references: new FormControl([]),
      artist_references: new FormControl([]),
      band_references: new FormControl(''),
      lyrics_data : new FormControl(''),
      vocal: new FormControl([]),
      owned_by: new FormControl([]),
      albums: new FormControl(''),
      upload_file_id : new FormControl(''),
      cowritten_by: new FormControl(''),
      insSuggesstion: new FormControl(''),
      vocalSuggesstion: new FormControl(''),
      verse: new FormControl(''),
      pre_chorus: new FormControl(''),
      chorus: new FormControl(''),
      bridge: new FormControl(''),
    })
    this.getGenres()
    this.getMood()
    this.getInstrument()
    this.getVocals()
  }

  saveTrack(){

    let postbody = {
      "title": this.createTrackForm.value.title,
      "artist": 1,
      "genre": this.genreId,
      "sub_genre": this.subgenreId,
      "moods": [this.genreId],
      "tempo": this.tempoId,
      "track_ending": this.trackEndingId,
      "versions": {
          "calculated_bpm": 1.2,
          "calculated_key": 3.0,
          "duration": 2.5,
          "is_instrumental": true
      },
      "is_exclusive": true,
      "composers": [],
      "instruments":{
          "sub_category":this.instrumentation_id,
          "descriptions":this.insDescArray,
          "suggesstion":[this.createTrackForm.value.insSuggesstion]
      },
      "tv_references": [1],
      "network_references": [1],
      "artist_references": [1],
      "band_references": [1],
      "lyrics_data":{
          "verse": this.createTrackForm.value.verse,
          "pre_chorus": this.createTrackForm.value.pre_chorus,
          "chorus": this.createTrackForm.value.chorus,
          "bridge": this.createTrackForm.value.bridge
      },
      "vocal": {
          "sub_category":this.vocal_id,
          "descriptions":this.vocalDescArray,
          "suggesstion":[this.createTrackForm.value.vocalSuggesstion]
      },
      "owned_by":36,
      "albums":"",
      "upload_file_id": 6,
      "cowritten_by": [
          { 
              "id": 1, 
              "percent": 2.2
          }
          ]
    }
    
    let url = environment.apiURL + this.config.createTrackAPI;
    this.api.doPOST(url,postbody).subscribe(x=>{
      this.loading = false
      this.getNewTrack.emit(true)
    },
    (error) => {
      this.loading = false;
      //alert(error.message)
    })
  }
  myMoodspt(id, evt, index){  
    if(evt.checked){
      this.getGenreList[index].checked = true;   
    }else{
      this.getGenreList[index].checked = false;  
    }  
    for (var i = 0; i < this.getGenreList.length; i++) {
      if (this.getGenreList[index].checked) {
        this.moodsArray.push(id);
        break
      }else{
        this.moodsArray = this.moodsArray.filter(x => x !== id);
        this.moodsArray.pop(id);
        break
      }
    }
  }
  myInsDescpt(id, evt, index){  
    if(evt.checked){
      this.getInsDescriptionList[index].checked = true;   
    }else{
      this.getInsDescriptionList[index].checked = false;  
    }  
    for (var i = 0; i < this.getInsDescriptionList.length; i++) {
      if (this.getInsDescriptionList[index].checked) {
        this.insDescArray.push(id);
        break
      }else{
        this.insDescArray = this.insDescArray.filter(x => x !== id);
        this.insDescArray.pop(id);
        break
      }
    }
  }
  myVocalsDescpt(id, evt, index){  
    if(evt.checked){
      this.getVocalDescList[index].checked = true;   
    }else{
      this.getVocalDescList[index].checked = false;  
    }  
    for (var i = 0; i < this.getVocalDescList.length; i++) {
      if (this.getVocalDescList[index].checked) {
        this.vocalDescArray.push(id);
        break
      }else{
        this.vocalDescArray = this.vocalDescArray.filter(x => x !== id);
        this.vocalDescArray.pop(id);
        break
      }
    }
  }
  getGenres(){
    let url = environment.apiURL + this.config.getTracksGenres;
      this.api.doGET(url).subscribe(x=>{
        this.getGenreList = x || [];
        this.getGenreList.forEach(x=>{
          x['checked'] = false;
          return x;
        })
      },
      (error) => {
      })
  }
  getGenreID(evt){
    this.genreId = evt.value;
    this.getSubGenres();
  }
  getSubGenreID(evt){
    this.subgenreId = evt.value;
  }
  getSubGenres(){
    let url = environment.apiURL + '/api/v1/track/'+ this.genreId +'/sub-genre-dropdown';
      this.api.doGET(url).subscribe(x=>{
        this.getSubGenreList = x || [];
      },
      (error) => {
      })
  }
  getMood(){
    let url = environment.apiURL + this.config.getTracksMoods;
      this.api.doGET(url).subscribe(x=>{
        this.getMoodsList = x || [];
      },
      (error) => {
      })
  }
  getInstrument(){
    let url = environment.apiURL + this.config.getTracksInstrument;
      this.api.doGET(url).subscribe(x=>{
        this.getInstrumentList = x || [];
      },
      (error) => {
      })
  }
  getinstrumentID(evt){
    this.instrumentation_id = evt.value;
    this.getDescription();
  }
  getDescID(evt){
    this.insDesc_id = evt.value;
  }
  getDescription(){
    let url = environment.apiURL + '/api/v1/track/'+ this.instrumentation_id +'/ins-description-dropdown/';
      this.api.doGET(url).subscribe(x=>{
        this.getInsDescriptionList = x || [];
      },
      (error) => {
      })
  }
  getVocals(){
    let url = environment.apiURL + this.config.getTracksVocals;
      this.api.doGET(url).subscribe(x=>{
        this.getVocalList = x || [];
      },
      (error) => {
      })
  }
  getVocalID(evt){
    this.vocal_id = evt.value
    this.getVocalDescription()
  }
  getVocalDescription(){
    let url = environment.apiURL + '/api/v1/track/'+ this.vocal_id +'/vocal-description-dropdown';
      this.api.doGET(url).subscribe(x=>{
        this.getVocalDescList = x || [];
      },
      (error) => {
      })
  }
}
