<div class="dialog-container">
    <div class="dialog-header">
        <h4 class="dialog-heading">Download Current Results</h4>
        <button type="button" class="close-button" (click)="close()">
            <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"
                    stroke-linecap="round" stroke-linejoin="round">
                    <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                        <g id="Genre-Options">
                            <g id="Close" transform="translate(1439.000000, 32.000000)">
                                <path d="M24,0 L0,24" id="Path"></path>
                                <path d="M0,0 L24,24" id="Path"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </button>
    </div>

    <mat-dialog-content>
        <app-loader *ngIf="loading" style="left: 47% !important;
        position: relative;" color="#50e3c2"></app-loader>
        <div class="dialog-body" *ngIf="!loading">
            <div class="form-container">
                <div class="form-row mb-0">
                    <div class="form-col">
                        <button class="form-button" (click)="downloadTracks()" [disabled]="trackCount <= 0">
                            Send Email ({{ trackCount }} tracks)
                        </button>

                        <!-- Robert: 5/23/24 - Option for direct download -->

                        <p style="text-align: center;">or</p>
                        <button class="form-button" (click)="downloadTracksDirect()" [disabled]="trackCount <= 0">
                            Direct Download ({{ trackCount }} tracks)
                        </button>
                        
                    </div>
                </div>
            </div>
            <div class="form-container mb-0" *ngIf="currentUser?.is_superuser">
                <div class="form-row">
                    <div class="form-col">
                        <h5 class="form-heading">
                            Media Query for Current Results
                        </h5>
                        <p>
                            Please select a query type to begin downloading
                        </p>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-col">
                        <mat-form-field appearance="fill" floatLabel="always" class="quertype-fill">
                            <mat-label>QUERY TYPE</mat-label>
                            <mat-select [(value)]="selectedMediaQuery" panelClass="exportdownloadpanel queerytypeheight"
                                [disableOptionCentering]="true">
                                <mat-option [value]="''">
                                    Select
                                </mat-option>
                                <mat-option *ngFor="let mediaQueryType of mediaQueryTypes"
                                    [value]="mediaQueryType.slug">
                                    {{mediaQueryType.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="form-row mb-0">
                    <div class="form-col">
                        <button class="form-button download-btn" [disabled]="selectedMediaQuery == ''"
                            (click)="downloadMediaQuery()">
                            <!-- Download Query -->
                            Send Email
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</div>