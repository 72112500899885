import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  AfterViewInit,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { MatSort, MatSortable } from '@angular/material/sort';
import {
  trigger,
  style,
  animate,
  transition,
  state,
} from '@angular/animations';
import { Observable, Subscription, BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { User } from '../models/user.model';
import { DialogService } from '../services/dialog.service';
import { SliderDialogService } from '../services/slider-dialog.service';
import { UserPRO } from '../models/user-pro.model';
// import { environment } from '../../../environments/environment.prod';
import { environment } from '../../../environments/environment';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { UserMenuService } from '../../shared/services/user-menu.service';
import {MatTableDataSource} from '@angular/material/table';
import { MenuListService } from '../../shared/services/menu-list.service';


declare type UserEmailGroupListDataSource = Observable<ReadonlyArray<User>>;

export class UserEmailGroupListRow {
  constructor(
    public user: User,
  ) {}
}

@Component({
  selector: 'app-user-email-group-list',
  templateUrl: './user-email-group-list.component.html',
  styleUrls: ['./user-email-group-list.component.scss'],
  animations: [
    trigger('rowInOut', [
      transition(':enter', [
        style({
          opacity: '0',
          height: '0',
          paddingTop: '0',
          overflow: 'hidden',
          paddingBottom: '0',
        }),
        animate(
          '0.7s cubic-bezier(0.19, 1, 0.22, 1)',
          style({
            opacity: '1',
            height: '*',
          })
        ),
      ]),
      transition(':leave', [
        style({
          opacity: '1',
          height: '*',
          overflow: 'hidden',
        }),
        animate(
          '0.7s cubic-bezier(0.19, 1, 0.22, 1)',
          style({
            opacity: '0',
            height: '0',
            paddingTop: '0',
            paddingBottom: '0',
          })
        ),
      ]),
    ]),
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', overflow: 'hidden' })
      ),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class UserEmailGroupListComponent implements OnInit, OnDestroy, AfterViewInit {

  selectedTrack = null;
  selectedVersion = null;

  isOpen = false;
  loadingOverlay = false;

  triggerOrigin: any;

  @Input()
  set dataSource(data: UserEmailGroupListDataSource) {
    this._dataSource = data
      .pipe(
        map((users) => {
          let retval: UserEmailGroupListRow[] = [];
          users.forEach((u) => {
            let row = new UserEmailGroupListRow(u);
            retval.push(row);
          });
          return retval;
        })
      );
  }

  @Input()
  set checkedUsers(clients: User[]) {
    this._checkedUsers = clients;
    this.checkedUsersChanged.emit(this._checkedUsers)
  }
  get checkedUsers() {
    return this._checkedUsers;
  }

  @Output()
  checkedUsersChanged = new EventEmitter<User[]>()

  @Output()
  collapseMusicPlayer = new EventEmitter<null>();

  @Output()
  sortChange: EventEmitter<MatSortable> = new EventEmitter<MatSortable>();

  @Output()
  listChange: EventEmitter<null> = new EventEmitter<null>();

  @Input()
  set sortValue(data: string) {
    const start = data && data[0] === '-' ? 'desc' : 'asc';
    const id = data && data[0] === '-' ? data.substring(1) : data || 'id';
    this._sortValue = { id, start } as MatSortable;
  }

  @Input()
  groupId: number;
  count = 0

  /* listDataSource = new MatTableDataSource<any>(); */
  get listDataSource() {
    this.count = this._dataSource['source']['source']['_value'].length
    return this._dataSource;
  }

  private _checkedUsers: User[] = [];

  private _dataSource: Observable<ReadonlyArray<UserEmailGroupListRow>>;
  private _subscriptions: Subscription[] = [];
  private _dataSourceSubscription: Subscription = null;
  private _sortValue: MatSortable = null;
  allAssignedUsers: any = [];
  displayedColumns: string[] = [
    'title',
    'type',
    'nickname',
    'email',
    'phone',
    'created_at',
    'visited',
    'tos',
    'action',
  ];

  @ViewChild(MatSort) sort: MatSort;
  private _sortSubscription: Subscription = null;

  constructor(
    private _userService: UserService,
    private _dialogService: DialogService,
    private _sliderDialogService: SliderDialogService,
    public config: MenuConfigService, 
    public api: UserMenuService,
    public list: MenuListService
  ) {}

  ngOnInit(): void {
    
    /* this.list.getSingleAssignedGroup().subscribe(x=>{
      if(x){
        this.currentGroupInfo(x);
      }
     
     }) */
  }

  ngAfterViewInit() {
    this._sortSubscription = this.sort.sortChange.subscribe(() => {
      this.sortChange.emit({
        id: this.sort.active,
        start: this.sort.direction,
      } as MatSortable);
    });
    if (!this._sortValue) return;
    this.sort.sort(this._sortValue);
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => {
      s.unsubscribe();
    });
    if (this._dataSourceSubscription) {
      this._dataSourceSubscription.unsubscribe();
    }
    if (this._sortSubscription) {
      this._sortSubscription.unsubscribe();
    }
  }

  listTrackBy(_, item: UserEmailGroupListRow) {
    return item.user.id;
  }

  userIsChecked(user) {
    return this.checkedUsers.some(u=>u.id == user.id);
  }

  userChecked(checked:boolean, user) {
    if (checked) {
      this._checkedUsers.push(user);
      this.checkedUsersChanged.emit(this.checkedUsers)
      return;
    }
    let i = this._checkedUsers.findIndex(u=>u.id==user.id);
    if (i >= 0) {
      this._checkedUsers.splice(i,1);
      this.checkedUsersChanged.emit(this.checkedUsers)
    }
  }

  deselectAll() {
    this._checkedUsers.length = 0
    this.checkedUsersChanged.emit( this.checkedUsers)
  }
/* 
  currentGroupInfo(Id){
    let url = environment.apiURL + '/api/v1/admin/emailgroup/'+Id+'/assigned-users/';
    this.api.doGET(url).subscribe(x=>{
      this.allAssignedUsers = x['results'] || []
      this.listDataSource = new MatTableDataSource(this.allAssignedUsers); 
    },
    (error)=>{
      //alert(error.message)
    }
  )
  } */


  deleteClick(user) {
    if (!this.groupId && this.groupId !== 0) {
      //alert("There is an issue removing the user. please reload the page and try again");
      return;
    }
    this._dialogService.displayRemoveUsersFromEmailGroupDialog([user], this.groupId).subscribe(changed => {
      if (changed) {
        if (this.userIsChecked(user)) {
          this.userChecked(false, user);
        }
        this.listChange.emit();
      }
    })
  }

}
