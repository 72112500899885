import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MenuListService } from '../../shared/services/menu-list.service';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { environment } from '../../../environments/environment';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
  selector: "app-delete-edit-user-dialogue",
  templateUrl: "./delete-edit-user-dialogue.component.html",
  styleUrls: ["./delete-edit-user-dialogue.component.scss"],
})
export class DeleteEditUserDialogueComponent implements OnInit {
  loaderContent: boolean = true;
  userDetails: any;
  managePermissionArray: any[] = [];
  allselectPermission: boolean = false;
  selectedArray: any = [];
  loadingPermissionUpdate: boolean = false;
  selectedPermissionCount: any = 0;
  groupEdit: boolean = false;
  searchText = "";
  constructor(
    private dialogRef: MatDialogRef<DeleteEditUserDialogueComponent>,
    public list: MenuListService,
    public api: UserMenuService,
    public config: MenuConfigService,public dialog: DialogService,
  ) {
    this.list.userListPermission = true;
    
    this.list.getAvailableUserPermission();
    setTimeout(() => {
      this.list.getAdminUserGroups().subscribe((x) => {
        this.userDetails = x;
        this.groupEdit = true
         for (let user of this.list.availableUserPermission) {
           user.checked = false
           user.disabled = false
           for (let cuser of this.list.currentUserGroup.permissions) {
             if (user.id === cuser.id) {
               user.checked = true;
               user.disabled = true
             }
            if(user.checked && !this.managePermissionArray.includes(user.id)){
               this.managePermissionArray.push(user.id);
            }
           }
         }
         
       let requestUrl =
       environment.apiURL +
       this.config.doGroups +
    //    this.userDetails +
    //    "/permissions-dropdown/";
    //  this.api.doGET(requestUrl).subscribe((x) => {
    //    this.selectedArray = x;
       this.list.currentUserGroupEditDelete.id +
       "/get-user-permissions/";
     this.api.doGET(requestUrl).subscribe((x) => {
       this.selectedArray = x[0].user_permissions;
 
       for (let users of this.selectedArray) {
         for (let alluser of this.list.availableUserPermission) {
           if (users.id === alluser.id) {
             alluser.checked = true;
             //alluser.disabled = true;
             if (!this.managePermissionArray.includes(users.id)) {
               this.managePermissionArray.push(users.id);
             }
           }
         }

       }
       for (let cuser of this.list.currentUserGroup.permissions) {
         for (let alluser of this.list.availableUserPermission) {
           if (alluser.id === cuser.id) {
             alluser.checked = true;
             alluser.disabled = true
           }
         }
       }
       this.selectedPermissionCount = this.countNumber(
         this.list.availableUserPermission,
         true
       );
       
      
     });
      
      });
  
    }, 1000);
  }
  ngOnInit(): void {
    setTimeout(() => {
      this.loaderContent = false;
    }, 500);
   
     
  }


  close() {
    this.dialogRef.close();
  }
countNumber = (array,value) => {
    return array.filter(n => n.checked == value).length
}

  mySelectOpt(id, evt, index) {
    let data = 0;
    if (evt.checked) {
      this.list.availableUserPermission[index].checked = true;
     // this.list.availableUserPermission[index].disabled = true;
    } else {
      this.list.availableUserPermission[index].checked = false;
      this.list.availableUserPermission[index].disabled = false;
    }
    for (var i = 0; i < this.list.availableUserPermission.length; i++) {
      if (this.list.availableUserPermission[index].checked && !this.managePermissionArray.includes(this.list.availableUserPermission[index].id)) {
          this.managePermissionArray.push(id);
        this.selectedPermissionCount = this.managePermissionArray.length;
        if (
          this.managePermissionArray.length ==
          this.list.availableUserPermission.length
        ) {
          this.allselectPermission = true;
        }
        break;
      } else {
        this.managePermissionArray = this.managePermissionArray.filter(
          (x) => x !== id
        );
        this.selectedPermissionCount = this.managePermissionArray.length;
        this.allselectPermission = false;
        break;
      }
    }
    for (var i = 0; i < this.list.availableUserPermission.length; i++) {
      if (this.list.availableUserPermission[i].checked) {
        data++
      }
    }
    this.selectedPermissionCount = data
    
  }
  getFindPermissionList() {
    var input, filter, ul, li, span, i, txtValue;
    input = document.getElementById('myInput');
    filter = input.value.toUpperCase();
    ul = document.getElementById("checkBoxUl");
    li = ul.getElementsByTagName('li');
  
    for (i = 0; i < li.length; i++) {
      span = li[i].getElementsByTagName("span")[0];
      txtValue = span.textContent || span.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
}
  deselectAll() {
    this.selectedPermissionCount = 0;
    for (let x of this.list.availableUserPermission) {
      if (!x.disabled) {
        x.checked = false;
      }
      if (x.disabled) {
        this.selectedPermissionCount++;
      }
      this.allselectPermission = false;
      this.managePermissionArray = [];
    }
  }
  selectAll(evt) {
    if (evt.checked) {
      for (let x of this.list.availableUserPermission) {
        x.checked = true;
       // x.disabled = true;
        this.allselectPermission = true;
        if (!this.managePermissionArray.includes(x.id)) {
          this.managePermissionArray.push(x.id);
          this.selectedPermissionCount = this.managePermissionArray.length;
        }
      }
    } else {
      for (let x of this.list.availableUserPermission) {
        if (!x.disabled) {
          x.checked = false;
        }   
        this.allselectPermission = false;
        this.managePermissionArray = [];
        this.selectedPermissionCount = 0;
      }
    }
  }
  updatePermission(user) {
    let postUserBody = {
      permission_ids: this.managePermissionArray,
    };
    let requestURL =
      environment.apiURL +
      "/api/v1/admin/groups/{user_id}/update-user-permission/";
    requestURL = requestURL.replace(
      "{user_id}",
      this.list.currentUserGroupEditDelete.id
    );
    this.api.doPUT(requestURL, postUserBody).subscribe(
      (result: any) => {
        this.close();
        this.loadingPermissionUpdate = false;
        this.list.singleUserForGroup(user);
      },
      (error) => {
        this.loadingPermissionUpdate = false;
        let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
        this.dialog.UserUploadPopupConfirmPopup(data).subscribe(changed => {
        });
        this.close();
      }
    );
  }
  deletePermission() {}
}
