import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { PlaylistComponent } from './playlist/playlist.component';

import { AuthGuard } from './shared/guards/auth.guard';
import { GuestGuard } from './shared/guards/guest.guard';
import { UserMgmtComponent } from './user-mgmt/user-mgmt.component';
import { MenuComponent } from './menu_mgt/menu/menu.component';
import { DataComponent } from './menu_mgt/data/data.component';
import { LogComponent } from './menu_mgt/log/log.component';
import { ReportsComponent } from './menu_mgt/reports/reports.component';
import { TosComponent } from './menu_mgt/tos/tos.component';
import { UserGroupsComponent } from './menu_mgt/user-groups/user-groups.component';
import { ProfileComponent } from './menu_mgt/profile/profile.component';
import { MenuUserComponent } from './menu_mgt/menu-user/menu-user.component';
import { ComposerRootComponent } from './composer/composer-root/composer-root.component';
import { ProComponent } from './composer/pro/pro.component';
import { CreateAccountComponent } from './composer/create-account/create-account.component';
import { CoWritersComponent } from './composer/co-writers/co-writers.component';
import { MyUploadComponent } from './composer/my-upload/my-upload.component';
import { PaymentComponent } from './composer/payment/payment.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TosCompComponent } from './composer/tos/tos.component';
import { HomeComposerComponent } from './composer/home-composer/home-composer.component'
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CmsComponent } from './menu_mgt/cms/cms.component';
import { SupportUserInputComponent } from './menu_mgt/support-user-input/support-user-input.component';
import { SupportComponent } from './menu_mgt/support/support.component';


const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent, canActivate: [GuestGuard] },
  { path: 'playlists', component: PlaylistComponent },
  { path: 'user-mgmt', redirectTo: 'user-mgmt/users', pathMatch: 'full'},
  { path: 'user-mgmt/users', redirectTo: 'user-mgmt/users/clients', pathMatch: 'full'},
  { path: 'user-mgmt/users/clients', component: UserMgmtComponent, canActivate: [AuthGuard]},
  { path: 'user-mgmt/users/artists', component: UserMgmtComponent, canActivate: [AuthGuard]},
  { path: 'user-mgmt/groups', component: UserMgmtComponent, canActivate: [AuthGuard]},
  { path: 'user-mgmt/templates', component: UserMgmtComponent, canActivate: [AuthGuard]},

  { path: 'menu', component: MenuComponent, children : [
    {
      path: 'menu', 
      redirectTo: 'data', 
      pathMatch: 'full'
   },
    { path: 'users', component: MenuUserComponent},
    { path: 'data', component: DataComponent},
    { path: 'log', component: LogComponent},
    { path: 'reports', component: ReportsComponent},
    { path: 'tos', component: TosComponent},
    { path: 'user-groups', component: UserGroupsComponent},
    { path: 'profile', component: ProfileComponent},
    { path: 'cms', component: CmsComponent},
    { path: 'supportUser', component: SupportComponent},
  ]},
  { path: 'dashboard', component: DashboardComponent},
  { path: 'composer', component: ComposerRootComponent, children : [
    {
      path: 'composer', 
      redirectTo: 'user-account', 
      pathMatch: 'full'
   },
    { path: 'pro', component: ProComponent},
    { path: 'user-account', component: CreateAccountComponent},
    { path: 'co-writer', component: CoWritersComponent},
    { path: 'payment', component: PaymentComponent},
  ]},
  { path: 'composer/term-condition', component: TosCompComponent ,canActivate: [AuthGuard] },

  { path: 'composer/my-tracks', component: HomeComposerComponent},
  
  { path: 'composer/my-uploads', component: MyUploadComponent},
  
  { path: 'reset-password', component: ResetPasswordComponent},
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
