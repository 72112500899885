<div class="dialog-container">
        <div class="dialog-header">
            <h4 class="dialog-heading">edit Thesaurus</h4>
            <button type="button" class="close-button" (click)="close()">
            <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                    <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                        <g id="Genre-Options">
                            <g id="Close" transform="translate(1439.000000, 32.000000)">
                                <path d="M24,0 L0,24" id="Path"></path>
                                <path d="M0,0 L24,24" id="Path"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
            </button>
        </div>
    <!-- code for add genre -->
         <mat-dialog-content>
            <div class="dialog-body">
                <p><strong>{{list.currThesaurus.name}}</strong></p>
                  <div class="grid-card">
                    <div class="grid-item">
                        <mat-form-field appearance="fill" class="group">
                            <mat-label>rename Thesaurus to</mat-label>
                            <input matInput [(ngModel)]="Thesaurus">
                        </mat-form-field>
                       
                    </div>
                </div> 
                
                <div class="form-col">
                  <button class="form-button"  [disabled]="Thesaurus.trim() === '' " (click)="save()">save</button>
                  <button class="form-button form-button-cancel" (click)="close()">Cancel</button>
                </div>
              </div>
        </mat-dialog-content> 
    
    </div>
    
    