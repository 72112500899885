import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSortable } from '@angular/material/sort';
import { User } from '../../shared/models/user.model';
import { DialogService } from '../../shared/services/dialog.service';

import { environment } from '../../../environments/environment';


import { EmailTemplate } from '../../shared/models/email-template.model';
import { UserService } from '../../shared/services/user.service';
import { EmailTemplateService, GetEmailTemplateOptions } from '../../shared/services/email-template.service';

const templatePageSize = 25;

@Component({
  selector: 'app-user-mgmt-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent implements OnInit {

  loadingUsers = true;
  loadingTemplates = false;
  loadingFolders = false;
  searchFilterFocused = false;
  musicPlayerCollapsed = false;
  newCurrenttemplate : number;

  get apiURL(): string {
    return environment.apiURL;
  }

  set activeTab(t:'clients'|'artists') {
    this._currentTab = t;
    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: this._params,
    });
  }
  get activeTab() {
    return this._currentTab;
  }

  set currentTemplate(p:EmailTemplate) {
    if (this._currentTemplate && p && this._currentTemplate.id == p.id) {
      return;
    }
    this._currentTemplate = p;
   // this.loadTemplates();
    // this._router.navigate([], {
    //   relativeTo: this._activatedRoute,
    //   queryParams: this._params,
    // });
  }
  get currentTemplate(): EmailTemplate {
    return this._currentTemplate;
  }

  get startDate() {
    return this._startDate;
  }
  set startDate(d: Date) {
    this._startDate = d
 //   this.loadTemplates();
    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: this._params,
    });
  }
  get endDate() {
    return this._endDate;
  }
  set endDate(d: Date) {
    this._endDate = d
   // this.loadTemplates();
    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: this._params,
    });
  }

  get totalTemplates():number {
    if (this.activeTab == 'clients') {
      return this._totalClientTemplates;
    }
    return this._totalArtistTemplates;
  }
  set totalTemplates(total:number) {
    if (this.activeTab == 'clients') {
      this._totalClientTemplates = total;
    } else {
      this._totalArtistTemplates = total;
    }
  }

  set templates(p: EmailTemplate[]) {
    this._templates = p;
    // if (!this.currentTemplate && p && p.length > 0) {
    if (p && p.length > 0) {
      this.currentTemplate = p[0];
    }
  }
  get templates(): EmailTemplate[] {
    return this._templates;
  }
  set currentTemplatesPage(p: number) {
    if (!isNaN(p) && p != this._currentTemplatesPage) {
      this._currentTemplatesPage = p;
      this.loadTemplates();
      this._router.navigate([], {
        relativeTo: this._activatedRoute,
        queryParams: this._params,
      });
    }
  }
  get currentTemplatesPage(): number {
    return this._currentTemplatesPage;
  }
  get templatesNumberOfPages(): number {
    return Math.ceil(this.totalTemplates / templatePageSize);
  }

  get searchString(): string {
    return this._searchString;
  }
  set searchString(s: string) {
    if (this._searchString != s) {
      this._searchString = s;
      this.loadTemplates();
      // this._router.navigate([], {
      //   relativeTo: this._activatedRoute,
      //   queryParams: this._params,
      // });
    }
  }

  private get _params() {
    let params = {};
    if (this.activeTab && this.activeTab !== 'clients') {
      params['tab'] = this.activeTab;
    }
    if (this.currentTemplate) {
      params['template'] = this.currentTemplate.id;
    }
    if (this.currentTemplatesPage && this.currentTemplatesPage != 1) {
      params['templatepage'] = `${this.currentTemplatesPage}`;
    }
    if (this.searchString) {
      params['search'] = this.searchString;
    }
    return params;
  }

  private _totalClientTemplates = 0;
  private _totalArtistTemplates = 0;
  private _currentTemplatesPage = 1;
  private _currentTab:'clients'|'artists' = 'clients';
  private _currentTemplate: EmailTemplate = null;
  private _searchString = '';
  private _startDate: Date;
  private _endDate: Date;
  private _templates: EmailTemplate[];

  private _subscriptions: Subscription[] = [];

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    public _emailTemplateService: EmailTemplateService,
    public cdr: ChangeDetectorRef,
    private _dialogService: DialogService,
  ) {}

  ngOnInit(): void {
    this._subscriptions.push(
      this._activatedRoute.queryParamMap.subscribe((params) => {
        let tab = params.get('tab');
        if (tab) {
          this.activeTab = tab as ('clients'|'artists');
        }
        let template = params.get('template');
        let templateId: number = null;
        if (template !== null) {
          templateId = parseInt(template);
          if (!isNaN(templateId)) {
            this._emailTemplateService.getTemplate(templateId).subscribe(p => {
              this.currentTemplate = p;
            }, (error) => {
              console.log(error);
              //alert("An error ocurred while getting the template. Check your connection and try again")
            })
          }
        }

        let templatepage = params.get('templatepage');
        if (templatepage !== null) {
          this.currentTemplatesPage = parseInt(templatepage);
        } else {
          this.currentTemplatesPage = 1;
        }

        let searchString = params.get('search');
        if (searchString !== null) {
          this.searchString = searchString;
        } else {
          this.searchString = '';
        }
      })
    );
    this.switchTab(this.activeTab);
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((s) => s.unsubscribe());
    this._subscriptions = [];
  }

  loadTemplates() {
    this.loadingTemplates = true;
    let params:GetEmailTemplateOptions = {
      type: this.activeTab == 'clients' ? 'CLIENT':'ARTIST',
      limit: templatePageSize,
      offset: (this.currentTemplatesPage - 1) * templatePageSize,
      page: this.currentTemplatesPage
    };
    if (this.searchString && this.searchString != '') {
      params.searchString = this.searchString;
    }

    this._emailTemplateService
      .getAllTemplates(params)
      .subscribe((p) => {
        this.loadingTemplates = false;
        this.totalTemplates = p.count;
        this.templates = p.results;
        this.newCurrenttemplate = p.results[0].id
        this.currentTemplate = p.results[0]
        this.cdr.detectChanges();
      }, (error) => {
        console.log(error);
        //alert("An error ocurred while getting the templates. Check your connection and try again");
      });
  }

  submitSearch() {
    this.searchFilterFocused = false;
    this.loadTemplates();
    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: this._params,
    });
  }

  displayDuplicateTemplateDialog() {
    let type = ''
    if(this.activeTab === 'artists'){
      type = 'ARTIST'
    }
    else{
      type = 'CLIENT'
    }
    this._dialogService.displayNewEmailTemplateDialog(this.currentTemplate, true, type).subscribe(changed => {
      if (changed) {
        this.loadTemplates();
      }
      this.loadTemplates();
    })
  }

  displayNewTemplateDialog() {
    let type = ''
    if(this.activeTab === 'artists'){
      type = 'ARTIST'
    }
    else{
      type = 'CLIENT'
    }
    for(let y of this.templates){
      if(y.id == this.currentTemplate.id){
        this.currentTemplate = y
        this._emailTemplateService.usertype = type;
        console.log("this.currentTemplate",this.currentTemplate, y)
        this._dialogService.displayNewEmailTemplateDialog(y, true,type ).subscribe(changed => {
          if (changed) {
            this.loadTemplates();
            // this._router.navigate([], {
            //   relativeTo: this._activatedRoute,
            //   queryParams: this._params,
            // });
          }
        //  this.loadTemplates();
        })
      }
    }
  
  }

  displayDeleteTemplateDialog() {
    this._dialogService.displayDeleteEmailTemplateDialog(this.currentTemplate).subscribe(changed => {
      if (changed) {
        this.currentTemplate = null;
        this.loadTemplates();
      }
      this.loadTemplates();
    });
  }

  switchTab(val){
    this.searchString =""
    this.activeTab = val;
    this.currentTemplatesPage = 1;
    this.loadTemplates();
  }

  templateDisplayName(p:EmailTemplate): string {
    if (!p || !p.id) return '';
    return `${this.formatDate(p.created_at)} - ${p.name}`;
  }

  formatDate(d:Date): string {
    return `${d.getMonth()+1}/${d.getDate()}/${d.getFullYear().toString().substr(-2)}`;
  }

  templateTrackBy(i: number, template: EmailTemplate) {
    return template.id + template.name;
  }

}
