import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { MenuListService } from '../../shared/services/menu-list.service';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { environment } from '../../../environments/environment';
import { DialogService } from 'src/app/shared/services/dialog.service';


@Component({
  selector: 'app-add-pro-dialogue',
  templateUrl: './add-pro-dialogue.component.html',
  styleUrls: ['./add-pro-dialogue.component.scss']
})
export class AddProDialogueComponent implements OnInit {
  //toppings = new FormControl();
  items = 'Select';
  loader: boolean = true;
  publisherForm: FormGroup;
  loadingProsAdd: boolean = false;
  publisherArray: any = {
    
  }
  toppingList: string[] = ['Sub Admin Groups', 'Test1', 'Test2', 'Test3', 'Test4'];
  constructor(private dialogRef: MatDialogRef<AddProDialogueComponent>, public FormPub: FormBuilder, public list: MenuListService, public api: UserMenuService, public config: MenuConfigService,public _DialogService:DialogService) { }

  ngOnInit(): void {
    setTimeout(()=>{                         
      this.loader = false;
    }, 500);
    this.publisherForm = this.FormPub.group({
      organization_name: new FormControl('')
    })
    this.list.getAllPublisher();
  }
  close() {
    this.dialogRef.close();
  }
  setPublisher(evt, id){
    
    this.publisherArray = {}; 
    this.publisherArray.id = Number(id);
    //this.publisherArray.name = evt.source.value;   
  }
  addPros(){
      this.loadingProsAdd = true;
      /* if(this.publisherForm.invalid){
    
        //alert('Enter required fields')
        this.loadingProsAdd = false;
        return;
        
      } */
      let postUserBody = {
        "id": this.list.allPROSDetails.count + 1,
        "pro": this.publisherForm.value.organization_name,
        "publisher": Number(this.publisherArray.id)
      }
      let requestURL = environment.apiURL + this.config.createPROS;
      this.api.doPOST(requestURL, postUserBody).subscribe((result: any) => {
        
        this.loadingProsAdd = false;
        this.dialogRef.close();
        this.list.getAllPROS();
        this.publisherArray = {};
      },
        (error) => {
          this.loadingProsAdd = false;
          let data = error.error?.error ? error.error?.error : (error.error?.detail ?error.error?.detail : (error.error?.details ? error.error?.details:``) )
          this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
          });
          this.dialogRef.close();
          //alert(error.message)
        });
  }
}
