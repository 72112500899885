import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Track } from '../models/track.model';
import { TrackVersion } from '../models/trackversion.model';
import { UserMenuService } from '../services/user-menu.service';
import { BpmService } from '../services/bpm.service';


@Component({
  selector: 'app-bpm-field',
  templateUrl: './bpm-field.component.html',
  styleUrls: ['./bpm-field.component.scss']
})
export class BpmFieldComponent implements OnInit {

  @Input()
  bpm: number;

  @Output()
  bpmChange: EventEmitter<number> = new EventEmitter<number>();

  bpmNumber: FormControl
  bpmValue: any
  oldValue: any

  @Input()
  set track(t: Track) {
    this._track = t;
    this.trackChange.emit(this._track);
  }
  get track(): Track {
    return this._track;
  }

  @Input()
  set version(v: TrackVersion) {
    this._version = v;
    this.bpmValue = this._version.calculated_bpm
    this.oldValue = this.bpmValue;
    this.versionChange.emit(this._version);
  };
  get version(): TrackVersion {
    return this._version;
  }

  @Output()
  versionChange = new EventEmitter<TrackVersion>();

  @Output()
  trackChange = new EventEmitter<Track>();

  private _calculatingBPM: boolean = false;
  private _startTimeStamp: Date;
  private _mostRecentClick: Date;
  private _clicks: number = 0;
  private _version: TrackVersion;
  private _track: Track;
  max = 99
  min = 10

  constructor(private cdr: ChangeDetectorRef, public api: UserMenuService, public bpmService: BpmService) { }

  ngOnInit(): void {
    this.bpmNumber = new FormControl('', [Validators.required, Validators.maxLength(12)]);
    //   this.bpmNumber = Number(this._version.calculated_bpm)
    if (this._version.id === undefined) {
      this.getTrackInfo()
    }
  }

  bpmclick() {

    //First click, start timer
    if (!this.bpmService.timerRunning) {
      this.version.calculated_bpm = this.bpmService.startTimer();
    }
    else {
      //count click
      this.version.calculated_bpm = this.bpmService.countClick();
    }

    this.bpmValue = Math.round(this.version.calculated_bpm);
    console.log(this.version, this._version)
    this.version.calculated_bpm = Math.round(this.version.calculated_bpm);
    this._version.calculated_bpm = Math.round(this.version.calculated_bpm);
    this.bpmValue = this._version.calculated_bpm
    if (Number(this.bpmValue) === 0) {
      this.track.isValid = true;
    }
    else {
      if (this.track) {
        if (this.track.title && this.track.genre && this.track.moods[0] && this.track.moods[1] && this.track.moods[2] && this.version.tempo && this.version.track_ending && this.version.calculated_key) {
          this.track.isValid = false;
        }
        else {
          this.track.isValid = true;
        }
      }
      if (this.version.is_vocals) {
        if (this.track.network_references.length >= 2 && this.track.tv_references.length >= 2 && this.track.track_sonic_reference?.length >= 2 &&
          this.track.band_references?.length >= 2 && (this.track.Vocalssub_category && (this.track.Vocalsdescriptions && this.track.Vocalsdescriptions?.length > 0))
          && (this.track.lyrics.verse != '' || this.track.lyrics.pre_chorus != '' || this.track.lyrics.chorus != '' || this.track.lyrics.bridge != '')
          && (this.track.Instrumentsub_category && (this.track.Instrumentdescriptions && this.track.Instrumentdescriptions?.length > 0))) {
          this.track.isValid = false;
        }
        else {
          this.track.isValid = true;
        }
      }
      else {
        if (this.track.network_references.length >= 2 && this.track.tv_references.length >= 2 && this.track.track_sonic_reference?.length >= 2 &&
          this.track.band_references?.length >= 2 && (this.track.Instrumentsub_category && (this.track.Instrumentdescriptions && this.track.Instrumentdescriptions?.length > 0))) {
          this.track.isValid = false;
        }
        else {
          this.track.isValid = true;
        }
      }
    }
    this.versionChange.emit(this._version);
    this.bpmChange.emit(this._version.calculated_bpm);
    this.setTimeoutBpmCalc();
    this.cdr.detectChanges();

  }



  // bpmclick() {
  //   // let click = document.getElementById('button')
  //   // console.log(click)
  //   // let now = new Date();
  //   // let secondsSinceLastClick = this._mostRecentClick ? (now.getTime() - this._mostRecentClick.getTime())/1000:0; //if the date isn't set then set this number to be very large to cancel any previous calculations
  //   // this._startTimeStamp = new Date();
  //   // if (!this._calculatingBPM || secondsSinceLastClick > 2 || !this._startTimeStamp) {
  //   //   this._calculatingBPM = true;
  //   //   this._startTimeStamp = new Date();
  //   //   console.log("starting time here:", this._startTimeStamp.toTimeString());
  //   //   this._clicks = 1;
  //   //   this.setTimeoutBpmCalc()
  //   //   return;
  //   // }
  //   // this._mostRecentClick = new Date();
  //   // this._clicks += 1;
  //   // console.log("you've clicked:", this._clicks);
  //   // let totalSeconds = (now.getTime() - this._startTimeStamp.getTime()) / 1000;
  //   // let totalMinutes = totalSeconds / 60;
  //   // console.log("it has been seconds:", totalSeconds);
  //   // console.log("it has been minutes:", totalMinutes);
  //   // let bpm = Math.round(this._clicks / totalMinutes);
  //  // this.version.calculated_bpm =  Number(bpm);
  //   // this.version.calculated_bpm = Math.abs(Number(Math.floor(Math.random() * 100) + 1))
  //   var val;
  //   this.max++, this.min++;
  //   this.min = Math.ceil(this.min);
  //   this.max = Math.floor(this.max);
  //   val = Math.floor(Math.random() * (this.max - this.min + 1)) + this.min;
  //   if( this.version.calculated_bpm === this.oldValue && this.version.calculated_bpm !== 0){
  //   this.version.calculated_bpm = 0
  //   }
  //   else{
  //     this.version.calculated_bpm = Math.abs(val)

  //   }

  //   if (this.version.calculated_bpm > 999) {
  //     this.version.calculated_bpm = 999;
  //   }
  //   this.bpmValue = Math.round(this.version.calculated_bpm);
  //   console.log(this.version,this._version )
  //   this.version.calculated_bpm = Math.round(this.version.calculated_bpm);
  //   this._version.calculated_bpm = Math.round(this.version.calculated_bpm);
  //   this.bpmValue = this._version.calculated_bpm
  //   if(Number(this.bpmValue)  === 0){
  //     this.track.isValid =true;
  //   }
  //   else{
  //     if(this.track){
  //       if(this.track.title && this.track.genre &&  this.track.moods[0] &&  this.track.moods[1] && this.track.moods[2] && this.version.tempo &&  this.version.track_ending && this.version.calculated_key){
  //         this.track.isValid = false;
  //        }
  //      else{
  //       this.track.isValid = true;
  //      }
  //     }
  //     if (this.version.is_vocals) {
  //       if (this.track.network_references.length >= 2 && this.track.tv_references.length >= 2 && this.track.track_sonic_reference?.length >= 2 &&
  //         this.track.band_references?.length >= 2 && (this.track.Vocalssub_category && (this.track.Vocalsdescriptions && this.track.Vocalsdescriptions?.length > 0))
  //         && (this.track.lyrics.verse != '' || this.track.lyrics.pre_chorus != '' || this.track.lyrics.chorus != '' || this.track.lyrics.bridge != '')
  //         && (this.track.Instrumentsub_category && (this.track.Instrumentdescriptions && this.track.Instrumentdescriptions?.length > 0))) {
  //         this.track.isValid = false;
  //       }
  //       else {
  //         this.track.isValid = true;
  //       }
  //     }
  //     else {
  //       if (this.track.network_references.length >= 2 && this.track.tv_references.length >= 2 && this.track.track_sonic_reference?.length >= 2 &&
  //         this.track.band_references?.length >= 2 && (this.track.Instrumentsub_category && (this.track.Instrumentdescriptions && this.track.Instrumentdescriptions?.length > 0))) {
  //         this.track.isValid = false;
  //       }
  //       else {
  //         this.track.isValid = true;
  //       }
  //     }
  //   }
  //   this.versionChange.emit(this._version);
  //   this.bpmChange.emit(this._version.calculated_bpm);
  //   this.setTimeoutBpmCalc();
  //   this.cdr.detectChanges();
  // }

  onBlur(value) {
    var num = Number(value)
    if (num > 999) {
      num = 999
    }
    this.version.calculated_bpm = Math.round(num);
    this._version.calculated_bpm = Math.round(num);
    this.bpmValue = this._version.calculated_bpm
    if (Number(this.bpmValue) === 0) {
      this.track.isValid = true;
    }
    else {
      if (this.version.is_vocals) {
        if (this.track.network_references.length >= 2 && this.track.tv_references.length >= 2 && this.track.track_sonic_reference?.length >= 2 &&
          this.track.band_references?.length >= 2 && (this.track.Vocalssub_category && (this.track.Vocalsdescriptions && this.track.Vocalsdescriptions?.length > 0))
          && (this.track.lyrics.verse != '' || this.track.lyrics.pre_chorus != '' || this.track.lyrics.chorus != '' || this.track.lyrics.bridge != '')
          && (this.track.Instrumentsub_category && (this.track.Instrumentdescriptions && this.track.Instrumentdescriptions?.length > 0))) {
          this.track.isValid = false;
        }
        else {
          this.track.isValid = true;
        }
      }
      else {
        if (this.track.network_references.length >= 2 && this.track.tv_references.length >= 2 && this.track.track_sonic_reference?.length >= 2 &&
          this.track.band_references?.length >= 2 && (this.track.Instrumentsub_category && (this.track.Instrumentdescriptions && this.track.Instrumentdescriptions?.length > 0))) {
          this.track.isValid = false;
        }
        else {
          this.track.isValid = true;
        }
      }
    }
    this.versionChange.emit(this._version);
    this.bpmChange.emit(num);
    this.cdr.detectChanges();
    setTimeout(() => {
      // this.bpm = num
    }, 2000)

  }

  preventDot(event) {
    var key = event.charCode ? event.charCode : event.keyCode;
    if (key == 46) {
      event.preventDefault();
      return false;
    }
  }
  private setTimeoutBpmCalc() {
    setTimeout(() => {
      if (this._mostRecentClick && this._startTimeStamp) {
        let now = new Date();
        let seconds = (now.getTime() - this._mostRecentClick.getTime()) / 1000;

        if (seconds < 2) {
          return;
        }
      }
      console.log("Resetting to to timeout");
      // this._calculatingBPM = false;
      //  this._startTimeStamp = null;
      // this._mostRecentClick = null;
      // this._clicks = 0;
    }, 100)
  }
  getTrackInfo() {
    let trackData = {
      "file_path": this.version.mp3_file
    }
    let requestUrl = environment.apiURL + `/api/v1/track/process-files/`
    this.api.doPOST(requestUrl, trackData).subscribe(x => {
      let arr;
      arr = x;
      this.bpmValue = Math.round(arr.bpm)
      this.oldValue = this.bpmValue;
      //  if (this.trackCreationType != 'edit') {
      this.version.calculated_bpm = this.version.calculated_bpm != undefined || this.version.calculated_bpm != null ? Math.round(arr.bpm) : this.version.calculated_bpm
      // }
      console.log(arr)
    })
  }
}
