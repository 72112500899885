import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { MenuListService } from '../../shared/services/menu-list.service';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-edit-pro-dialogue',
  templateUrl: './edit-pro-dialogue.component.html',
  styleUrls: ['./edit-pro-dialogue.component.scss']
})
export class EditProDialogueComponent implements OnInit {
  loader: boolean = true;
  toppings = new FormControl();
  editUserForm: FormGroup;
  loadingProsUpdate: boolean = false;
  currentName: any;
  publisherArray: any = {
    
  }
  toppingList: string[] = ['Sub Admin Groups', 'Test1', 'Test2', 'Test3', 'Test4'];
  constructor(private dialogRef: MatDialogRef<EditProDialogueComponent>, public FormPub: FormBuilder, public list: MenuListService, public api: UserMenuService, public config: MenuConfigService) {this.list.getAllPublisher() }

  ngOnInit(): void {
    setTimeout(()=>{                         
      this.loader = false;
    }, 500);
    this.editUserForm = this.FormPub.group({
      organization_name: new FormControl('')
    })    
    this.publisherArray.id = this.list.publisherid;
    /* this.list.getAdminUserGroups().subscribe(x=>{
      if(x){
        this.list.getSignlePROS(x)
      }
     
     })  */
    
  }
  close() {
    this.dialogRef.close();
  }
  setPublisher(evt,id){
    if (evt.source.selected) {
      this.publisherArray = {}; 
      this.publisherArray.id = Number(id);
      //this.publisherArray.name = evt.source.value;   
    }
  }
  updatePros(){
    //this.loadingProsUpdate = true;
    if(this.editUserForm.invalid){
  
      //alert('Enter required fields')
      this.loadingProsUpdate = false;
      return;
      
    }
    let postUserBody = {
      "id": this.list.singlePROS.id,
      "pro": this.editUserForm.value.organization_name,
      "publisher": Number(this.publisherArray.id)
    }
    let requestURL = environment.apiURL + this.config.getAllPROSlist + this.list.singlePROS.id + '/';
    this.api.doPUT(requestURL, postUserBody).subscribe((result: any) => {
      
      this.loadingProsUpdate = false;
      this.dialogRef.close();
      this.list.getAllPROS();
      this.publisherArray = {};
    },
      (error) => {
        this.loadingProsUpdate = false;
      });
      console.log(postUserBody)
}
}
