<div class="track-description-container">
  <div class="title-card-left">
    <b>TRACK DESCRIPTION <span class="allfields"><small>*All categories required </small></span></b>
  </div>
  <div class="title-description" *ngIf="trackType=='trackversion'">Please specify the following information to proceed
    to the next step</div>
  <div class="title-description" *ngIf="trackType!=='trackversion'">Review description categories to ensure all tags and
    suggestions are accurate</div>
  <div class="meta-radios" *ngIf="!replaceTrackVersion">
    <h4>
      View Meta Descriptions Implemented By:
    </h4>
    <mat-radio-group [(ngModel)]="showMetaFor" (change)="radioChange($event)">
      <mat-radio-button class="checkbox-margin" name="all" [value]="-1">All</mat-radio-button>
      <mat-radio-button class="checkbox-margin" name="all" [value]="0">Composer</mat-radio-button>
      <mat-radio-button class="checkbox-margin" name="all" [value]="2">Meta-tagger</mat-radio-button>
      <mat-radio-button class="checkbox-margin" name="all" [value]="1">Approver</mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="title-description" style="color: red;" *ngIf="currentUser.is_meta_tagger === false && showMetaFor !== -1">
    You don't have permission to perform this action. Please contact your MIBE representative.</div>
  <div [class]="'items-container ' + getClassForRole(showMetaFor)">
    <div class="custom-card">
      <mat-accordion>
        <mat-expansion-panel hideToggle #instrumentsPanel (opened)="instrumentationPanelOpen = true"
          (closed)="instrumentationPanelOpen = false; clearInstrumentInput()">
          <mat-expansion-panel-header>
            <mat-panel-title class=" card-title panel-title">


              <svg xmlns="http://www.w3.org/2000/svg" matTooltipPosition="above"
                matTooltip=" Please be sure to select and input all the instruments used in this track"
                matTooltipClass="tooltip-white custom-tooltip " height="24px" viewBox="0 0 24 24" width="24px">
                <path d="M0 0h24v24H0V0z" fill="none"></path>
                <path
                  d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z">
                </path>
              </svg>

              <b>INSTRUMENTATION</b>
              <div *ngIf="instrumentationPanelOpen">
                <div class="btn  headerFlexItem">
                  <u style="position: absolute;top:14px; font-size: 12px; right:14px;">Close</u>
                  <svg style="position: absolute;left:3%;" xmlns="http://www.w3.org/2000/svg" matTooltipPosition="above"
                    matTooltip=" Please be sure to select and input all the instruments used in this track"
                    matTooltipClass="tooltip-white custom-tooltip " height="24px" viewBox="0 0 24 24" width="24px"
                    fill="#fffff">
                    <path d="M0 0h24v24H0V0z" fill="none"></path>
                    <path
                      d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z">
                    </path>
                  </svg>
                </div>
              </div>
              <div *ngIf="!instrumentationPanelOpen">
                <div class="btn  headerFlexItem" style="position: absolute;top:14px; font-size: 12px; right:14px;">
                  <u *ngIf="((track?.Instrumentsub_category && track.Instrumentsub_category?.length === 0)  && (!track.Instrumentdescriptions 
                    || track.Instrumentdescriptions?.length==0) ||
                    (!track.Instrumentnew_suggestion || track.Instrumentnew_suggestion?.length==0))">Add</u>
                  <u *ngIf="((track.Instrumentsub_category && track.Instrumentsub_category.length > 0) && ((track.Instrumentdescriptions 
                    && track.Instrumentdescriptions?.length>0) ||
                    (track.Instrumentnew_suggestion && track.Instrumentnew_suggestion?.length>0)))">Review</u>
                  <u class="svg-text" *ngIf="(track.Instrumentsub_category && (track.Instrumentdescriptions 
                    && track.Instrumentdescriptions?.length>0) ||
                    (track.Instrumentnew_suggestion && track.Instrumentnew_suggestion?.length>0))"><img
                      class="uploadwidth" src="assets/img/check-circle.png"></u>
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="grid-container card-data" style="margin-top: 4%;">

            <div class="grid-item">
              <mat-form-field appearance="fill" style="width:97%;" floatLabel="always"
                [ngClass]="{'disabledElement': (showMetaFor === -1) ,'disabledElement1': (currentUser.is_meta_tagger === false)}">
                <mat-label>Instrument</mat-label>
                <mat-select class="custom-backdrop-class" panelClass="trackinstrument"
                  (selectionChange)="getArtist($event.value)" [multiple]="true" [disableOptionCentering]="true"
                  [(ngModel)]="track.Instrumentsub_category" placeholder="Select">
                  <span class="select-title">Select a Instrument</span>
                  <mat-option *ngFor="let status of allSubCategory"
                    (onSelectionChange)="setNewInstrument($event, status.id);" [value]="status.id">
                    {{status.name}}
                  </mat-option>
                </mat-select>

              </mat-form-field>
            </div>
            <div class="grid-item">
              <mat-form-field appearance="fill" style="width:97%;" floatLabel="always"
                [ngClass]="{'disabledElement':(!track.Instrumentsub_category) ||  (showMetaFor === -1 ),'disabledElement1': (currentUser.is_meta_tagger === false)}">
                <mat-label>Description</mat-label>
                <mat-select class="custom-backdrop-class" placeholder="Select all that apply" [multiple]="true"
                  panelClass="permission-grp trackdesc" [disableOptionCentering]="true"
                  (selectionChange)="instrumenrtationChange2($event.value,'add');" [(ngModel)]="inDescription">
                  <span class="select-title">Select a Description</span>
                  <mat-option *ngFor="let status of allInstrumentationSubCategory"
                    (onSelectionChange)="setInstrument($event, status.id, status.name)" [value]="status.id">

                    {{status.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

          </div>
          <mat-form-field appearance="fill" style="width:98%;"
            [ngClass]="{'disabledElement':(!track.Instrumentsub_category) ||  (showMetaFor === -1) ,'disabledElement1':  (currentUser.is_meta_tagger === false)}">
            <input placeholder="Type a suggestion" [formControl]="instrumentCtrl" [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addInstrument($event)"
              #instrumentInput>
            <span class="add-data"><span
                (click)="$event.preventDefault();$event.stopPropagation();clearInstrumentInput()"><strong style="right: 49px;
                  position: absolute;">&times;</strong></span><span
                (click)="$event.preventDefault();$event.stopPropagation();addCustomInstruments()">ADD</span></span>

            <mat-chip-list class="mat-chip-list" #chipList>
              <mat-chip style="margin: 5px 9px 7px 2px; height: 100%"
                *ngFor="let custom_instrument of track.Instrumentnew_suggestion;" [selectable]="false"
                [removable]="true" (removed)="removeCustomInstruments(custom_instrument)">
                {{custom_instrument}}
                <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
              </mat-chip>
            </mat-chip-list>
          </mat-form-field>
          <button class="btn save-btn" *ngIf="((track.Instrumentsub_category && track.Instrumentsub_category?.length === 0) &&(!track.Instrumentdescriptions 
            || track.Instrumentdescriptions?.length==0) ||
            (!track.Instrumentnew_suggestion || track.Instrumentnew_suggestion?.length==0))" mat-button disabled>Add
            atleast Two Instruments to continue</button>
          <button class="btn save-btn" *ngIf="  ((track.Instrumentsub_category && track.Instrumentsub_category?.length > 0) && ((track.Instrumentdescriptions 
             && track.Instrumentdescriptions?.length>0) ||
             (track.Instrumentnew_suggestion && track.Instrumentnew_suggestion?.length>0)))"
            [ngClass]="{'disabledElement':(!track.Instrumentsub_category) ||  (showMetaFor === -1) ,'disabledElement1':  (currentUser.is_meta_tagger === false)}"
            mat-button (click)="$event.preventDefault();$event.stopPropagation();closethisPanel('instruments')">continue
            & save</button>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel hideToggle #networkPanel (opened)="tvNetworkPanelOpen = true"
          (closed)="tvNetworkPanelOpen = false; clearTVNetworkRefInput()" style="margin-top: 4%;margin-bottom: 4%;">
          <mat-expansion-panel-header>
            <mat-panel-title class="card-title panel-title">

              <svg xmlns="http://www.w3.org/2000/svg"
                matTooltip="Please input all networks and programs you feel reference this piece of music. i.e Bravo,  VH1, ABC, The Real Housewives, Love & Hip Hop, Shark Tank"
                matTooltipPosition="above" matTooltipClass="tooltip-white custom-tooltip " height="24px"
                viewBox="0 0 24 24" width="24px" fill="#000000">
                <path d="M0 0h24v24H0V0z" fill="none"></path>
                <path
                  d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z">
                </path>
              </svg>
              <b>TV / NETWORK REFERENCES</b>


              <mat-panel-description>
              </mat-panel-description>
              <div *ngIf="tvNetworkPanelOpen">
                <div class="btn  headerFlexItem">
                  <u style="margin-left:auto;position: absolute;top:14px; font-size: 12px; right:14px;">Close</u>
                  <svg style="position:absolute;left:3%;" xmlns="http://www.w3.org/2000/svg"
                    matTooltip="Please input all networks and programs you feel reference this piece of music. i.e Bravo,  VH1, ABC, The Real Housewives, Love & Hip Hop, Shark Tank"
                    matTooltipPosition="above" matTooltipClass="tooltip-white custom-tooltip " height="24px"
                    viewBox="0 0 24 24" width="24px" fill="#fffff">
                    <path d="M0 0h24v24H0V0z" fill="none"></path>
                    <path
                      d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z">
                    </path>
                  </svg>

                </div>
              </div>
              <div *ngIf="!tvNetworkPanelOpen">
                <div class="btn  headerFlexItem"
                  style="margin-left:auto;position: absolute;top:14px; font-size: 12px; right:14px;">
                  <u *ngIf="(networkRef(track).length>=2 && getTvData(track).length>=2)">Review</u>
                  <u *ngIf="(networkRef(track).length<2) || (getTvData(track).length<2)">Add</u>
                  <u class="svg-text" *ngIf="(networkRef(track).length>=2 && getTvData(track).length>=2)"><img
                      class="uploadwidth" src="assets/img/check-circle.png"></u>
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="card-data tv-ref">
            <p>Please enter as accurately as possible any relevant TV and/or Network references</p>
            <h4 style="margin-top: 8%;">Network References</h4>
            <mat-form-field class="tv-network-ref" appearance="fill"
              [ngClass]="{'disabledElement': (showMetaFor === -1) ,'disabledElement1': (currentUser.is_meta_tagger === false)}"
              style="height:200px; border:none;">
              <input style="border: 1px solid #d6d6d6;
              border-radius: 1%;
              margin-top: 3px;
              margin-bottom: 2%;" placeholder=" Search Suggestion..." [formControl]="network1Ctrl"
                [matAutocomplete]="autoNetwork" [matChipInputFor]="networkChipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addNetwork($event)"
                #networkInput (click)="$event.preventDefault();$event.stopPropagation();networkRefClick();">
              <mat-form-field appearance="fill" class="tv-network-ref" style="height:125px;overflow-y: scroll;">
                <mat-chip-list #networkChipList="">
                  <mat-chip style="margin:5px 18px 12px 0;height: auto;" *ngFor="let tv of getTvData(track);"
                    [selectable]="false" [removable]="true" (removed)="removeNetwork(tv)">
                    {{tv.name}}
                    <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
                  </mat-chip>
                </mat-chip-list>
              </mat-form-field>
              <mat-autocomplete #autoNetwork="matAutocomplete" (optionSelected)="selectedNetwork($event)">
                <mat-option *ngFor="let tvref of filteredNetworks | async;"
                  (onSelectionChange)="setNetwork($event, tvref.id)" [value]="tvref">
                  {{tvref.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <!-- Add Newsuggestion -->
            <div class="music-input12"
              [ngClass]="{'disabledElement': (showMetaFor === -1) ,'disabledElement1':  (currentUser.is_meta_tagger === false)}">
              <input matInput placeholder="Type a suggestion" style="margin-top: 9px; width:81%; padding-left: 10px"
                [formControl]="networkNewCtrl">
              <span><span (click)=" clearTVNetworkRefInput()"><strong class="m-style">&times;</strong></span><span
                  class="m-style1" (click)="addCustomNetwork(networkNewCtrl)"><strong>ADD</strong></span></span>
            </div>


            <h4>Program/Show References</h4>


            <mat-form-field class="tv-network-ref" appearance="fill"
              [ngClass]="{'disabledElement': (showMetaFor === -1) ,'disabledElement1': (currentUser.is_meta_tagger === false)}"
              style="height:200px; border:none;">
              <input style="border: 1px solid #d6d6d6;
              border-radius: 1%;
              margin-top: 3px;
              margin-bottom: 2%;" placeholder=" Search Suggestion..." [formControl]="tvCtrl" [matAutocomplete]="autoTV"
                [matChipInputFor]="tvChipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="addTV($event)" #tvInput (click)="programRefClick()">
              <mat-form-field appearance="fill" class="tv-network-ref" style="height:125px;overflow-y: scroll;">
                <mat-chip-list #tvChipList="">
                  <mat-chip style="margin:5px 18px 12px 0;height: auto;" *ngFor="let tv of networkRef(track);"
                    [selectable]="false" [removable]="true" (removed)="removeTV(tv)">
                    {{tv.name}}
                    <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
                  </mat-chip>
                </mat-chip-list>
              </mat-form-field>
              <mat-autocomplete #autoTV="matAutocomplete" (optionSelected)="selectedTV($event)">
                <mat-option *ngFor="let tvref of filteredTV | async;" (onSelectionChange)="setTv($event, tvref.id)"
                  [value]="tvref">
                  {{tvref.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <!-- Add Newsuggestion -->
            <div class="music-input12"
              [ngClass]="{'disabledElement': (showMetaFor === -1 ) ,'disabledElement1':  (currentUser.is_meta_tagger === false)}">
              <input matInput placeholder="Type a suggestion" style="margin-top: 9px; width:81%;padding-left: 10px"
                [formControl]="tvNewCtrl">
              <span><span (click)=" clearTVNetworkRefInput()"><strong class="m-style">&times;</strong></span><span
                  class="m-style1" (click)="addCustomTv(tvNewCtrl)"><strong>ADD</strong></span></span>
            </div>

          </div>
          <button class="btn save-btn" mat-button (click)="closethisPanel('network')"
            [ngClass]="{'disabledElement':(!track.Instrumentsub_category) ||  (showMetaFor === -1) ,'disabledElement1':  (currentUser.is_meta_tagger === false)}"
            *ngIf="networkRef(track).length >=2 &&  getTvData(track).length>=2">continue
            & save</button>
          <button class="btn save-btn" mat-button disabled
            *ngIf="(networkRef(track).length==0 || networkRef(track).length<2) || ( getTvData(track).length==0 || getTvData(track).length<2)">Add
            two plus
            references for each category to continue</button>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel hideToggle #sonicPanel style="margin-bottom: 4%;" (opened)="sonicPanelOpen = true"
          (closed)="sonicPanelOpen = false; clearSonicRefInput()">
          <mat-expansion-panel-header>
            <mat-panel-title class="card-title panel-title">

              <svg xmlns="http://www.w3.org/2000/svg"
                matTooltip="Please add in all information referencing any artists or composers that create this style  of music. i.e Drake, Hans Zimmer, Foo Fighters"
                matTooltipPosition="above" matTooltipClass="tooltip-white custom-tooltip " height="24px"
                viewBox="0 0 24 24" width="24px" fill="#000000">
                <path d="M0 0h24v24H0V0z" fill="none"></path>
                <path
                  d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z">
                </path>
              </svg>
              <b>OTHERS</b>


              <mat-panel-description>
              </mat-panel-description>
              <div *ngIf="sonicPanelOpen">
                <div class="btn headerFlexItem">
                  <u style="margin-left:auto;position: absolute;top:14px; font-size: 12px; right:14px;">Close</u>
                  <svg style="position: absolute;left:3%;" xmlns="http://www.w3.org/2000/svg"
                    matTooltip="Please add in all information referencing any artists or composers that create this style  of music. i.e Drake, Hans Zimmer, Foo Fighters"
                    matTooltipPosition="above" matTooltipClass="tooltip-white custom-tooltip " height="24px"
                    viewBox="0 0 24 24" width="24px" fill="#fffff">
                    <path d="M0 0h24v24H0V0z" fill="none"></path>
                    <path
                      d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z">
                    </path>
                  </svg>
                </div>
              </div>
              <div *ngIf="!sonicPanelOpen">
                <div class="btn  headerFlexItem"
                  style="margin-left:auto;position: absolute;top:14px; font-size: 12px; right:14px;">
                  <u
                    *ngIf="( getSonicBand(track)?.length==0 ||  getSonicBand(track)?.length<2) || (getSonic(track)?.length==0 || getSonic(track)?.length<2)">Add</u>
                  <u *ngIf="( getSonicBand(track)?.length>=2 && getSonic(track)?.length>=2)">Review</u>
                  <u class="svg-text" *ngIf="( getSonicBand(track)?.length>=2 && getSonic(track)?.length>=2)"><img
                      class="uploadwidth" src="assets/img/check-circle.png"></u>
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="card-data tv-ref">
            <p>Please enter as accurately as possible any relevant artist and/or band references</p>
            <h4>Sonic References</h4>
            <mat-form-field class="tv-network-ref" appearance="fill"
              [ngClass]="{'disabledElement': (showMetaFor === -1) ,'disabledElement1': (currentUser.is_meta_tagger === false)}"
              style="height:200px; border:none;">
              <input style="border: 1px solid #d6d6d6;
                  border-radius: 1%;
                  margin-top: 3px;
                  margin-bottom: 2%;" placeholder="Search a suggestion"
                (keyup)="$event.preventDefault();$event.stopPropagation();getSonicReferences($event.target.value)"
                [formControl]="sonicCtrl" [matAutocomplete]="autoSonic" [matChipInputFor]="sonicChipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addSonic($event)"
                #sonicInput (click)="sonicRefClick()">
              <mat-form-field appearance="fill" class="tv-network-ref" style="height:125px;overflow-y: scroll;">
                <mat-chip-list #sonicChipList="">


                  <mat-chip style="margin: 5px 18px 0px 0;height: auto;"
                    *ngFor="let sonic of getSonic(track); trackBy: sonicTrackBy" [selectable]="false" [removable]="true"
                    (removed)="removeSonic(sonic)">
                    {{sonic.name}}
                    <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
                  </mat-chip>

                </mat-chip-list>
              </mat-form-field>
              <mat-autocomplete #autoSonic="matAutocomplete" (optionSelected)="selectedSonic($event)">
                <mat-option *ngFor="let sonic of filteredSonics | async"
                  (onSelectionChange)="setSonic($event, sonic.id)" [value]="sonic">
                  {{sonic.name}}
                </mat-option>
              </mat-autocomplete>

            </mat-form-field>
            <div class="music-input12"
              [ngClass]="{'disabledElement': (showMetaFor === -1) ,'disabledElement1':  (currentUser.is_meta_tagger === false)}">
              <input matInput placeholder="Type a suggestion" style="margin-top: 9px; width:81%; padding-left: 10px"
                [(ngModel)]="sonicSugg" [matChipInputFor]="networkChipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addNetwork($event)">
              <span><span (click)=" clearsonic()"><strong class="m-style">&times;</strong></span><span class="m-style1"
                  (click)="addsonicSugg()"><strong>ADD</strong></span></span>
            </div>
            <h4 style="margin-top: 8%;">Context Use</h4>
            <mat-form-field class="tv-network-ref" appearance="fill"
              [ngClass]="{'disabledElement': (showMetaFor === -1) ,'disabledElement1':  (currentUser.is_meta_tagger === false)}"
              style="height:200px; border:none;">
              <input style="border: 1px solid #d6d6d6;
                  border-radius: 1%;
                  margin-top: 3px;
                  margin-bottom: 2%;" placeholder="Search Suggestion..."
                (keyup)="$event.preventDefault();$event.stopPropagation();gecontextReferences($event.target.value)"
                [formControl]="bandCtrl" [matAutocomplete]="autoBand" [matChipInputFor]="bandChipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addBand($event)" #bandInput
                (click)="contextRefClick()">
              <mat-form-field appearance="fill" class="tv-network-ref" style="height:125px;overflow-y: scroll;">
                <mat-chip-list #bandChipList="">
                  <mat-chip style="margin: 5px 18px 12px 0;height: auto;" *ngFor="let band of getSonicBand(track);"
                    [selectable]="false" [removable]="true" (removed)="removeBand(band)">
                    {{band.name}}
                    <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
                  </mat-chip>
                </mat-chip-list>
              </mat-form-field>
              <mat-autocomplete #autoBand="matAutocomplete" (optionSelected)="selectedBand($event)">
                <mat-option *ngFor="let band_ref of filteredBands | async;"
                  (onSelectionChange)="setBand($event, band_ref.id)" [value]="band_ref">
                  {{band_ref.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div class="music-input12"
              [ngClass]="{'disabledElement': (showMetaFor === -1) ,'disabledElement1':  (currentUser.is_meta_tagger === false)}">
              <input matInput placeholder="Type a suggestion" style="margin-top: 9px; width:81%; padding-left: 10px"
                [(ngModel)]="bandSugg">
              <span><span (click)=" clearsonic()"><strong class="m-style">&times;</strong></span><span class="m-style1"
                  (click)="addBandSugg()"><strong>ADD</strong></span></span>
            </div>

          </div>

          <button class="btn save-btn"
            [ngClass]="{'disabledElement':(!track.Instrumentsub_category) ||  (showMetaFor === -1) ,'disabledElement1':  (currentUser.is_meta_tagger === false)}"
            mat-button (click)="closethisPanel('sonic')"
            *ngIf="getSonic(track)?.length>=2 && getSonicBand(track)?.length>=2">continue &
            save</button>
          <button class="btn save-btn" mat-button
            *ngIf="(getSonic(track)?.length==0 || getSonic(track)?.length<2) || (getSonicBand(track)?.length==0 || getSonicBand(track)?.length<2)"
            disabled>Add two plus
            references for each category to continue</button>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel *ngIf="isInstrumental!==false" hideToggle style="margin-bottom: 4%;" #vocalsPanel
          (opened)="vocalsPanelOpen = true" (closed)="vocalsPanelOpen = false">
          <mat-expansion-panel-header>
            <mat-panel-title class="card-title panel-title">

              <svg xmlns="http://www.w3.org/2000/svg"
                matTooltip="Please note if any vocals are used in this song. Vocals can be male/female vocals, choirs,  and chants"
                matTooltipPosition="above" matTooltipClass="tooltip-white custom-tooltip " height="24px"
                viewBox="0 0 24 24" width="24px" fill="#000000">
                <path d="M0 0h24v24H0V0z" fill="none"></path>
                <path
                  d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z">
                </path>
              </svg>
              <b>VOCALS</b>


              <mat-panel-description>
              </mat-panel-description>
              <div *ngIf="vocalsPanelOpen">
                <div class="btn headerFlexItem">
                  <u style="margin-left:auto;position: absolute;top:14px; font-size: 12px; right:14px;">Close</u>
                  <svg style="position: absolute;left:3%;" xmlns="http://www.w3.org/2000/svg"
                    matTooltip="Please note if any vocals are used in this song. Vocals can be male/female vocals, choirs,  and chants"
                    matTooltipPosition="above" matTooltipClass="tooltip-white custom-tooltip " height="24px"
                    viewBox="0 0 24 24" width="24px" fill="#fffff">
                    <path d="M0 0h24v24H0V0z" fill="none"></path>
                    <path
                      d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z">
                    </path>
                  </svg>
                </div>
              </div>
              <div *ngIf="!vocalsPanelOpen">
                <div class="btn  headerFlexItem"
                  style="margin-left:auto;position: absolute;top:14px; font-size: 12px; right:14px;">
                  <u
                    *ngIf="!track.Vocalssub_category || (!track.Vocalsdescriptions || track.Vocalsdescriptions?.length==0)">Add</u>
                  <u
                    *ngIf="track.Vocalssub_category && (track.Vocalsdescriptions && track.Vocalsdescriptions?.length>0)">Review</u>
                  <u class="svg-text"
                    *ngIf="track.Vocalssub_category && (track.Vocalsdescriptions && track.Vocalsdescriptions?.length>0)"><img
                      class="uploadwidth" src="assets/img/check-circle.png"></u>
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="grid-container card-data" style="margin-top: 4%;">

            <div class="grid-item">
              <mat-form-field appearance="fill" style="width: 97%;" floatLabel="always"
                [ngClass]="{'disabledElement': (showMetaFor === -1) ,'disabledElement1':(currentUser.is_meta_tagger === false)}">
                <mat-label>Type</mat-label>
                <mat-select class="custom-backdrop-class" panelClass=" trackinstrument"
                  (selectionChange)="getVocDesc($event.value)" [disableOptionCentering]="true"
                  [(ngModel)]="track.Vocalssub_category" placeholder="Select">
                  <span class="select-title">Select a type</span>
                  <mat-option *ngFor="let status of allVocals" [value]="status.id">

                    {{status.name}}
                  </mat-option>
                </mat-select>

              </mat-form-field>
            </div>
            <div class="grid-item">
              <mat-form-field appearance="fill" floatLabel="always" style="width: 97%;"
                [ngClass]="{'disabledElement':!track.Vocalssub_category || (showMetaFor === -1) ,'disabledElement1':(currentUser.is_meta_tagger === false)}">
                <mat-label>Description</mat-label>
                <mat-select class="custom-backdrop-class" [multiple]="true" panelClass="permission-grp trackdesc"
                  [disableOptionCentering]="true" (selectionChange)="instrumenrtationChange($event);"
                  [(ngModel)]="vocalDescription" placeholder="Select all that apply">
                  <span class="select-title">Select a Description</span>
                  <mat-option *ngFor="let status of allVolDescList" (onSelectionChange)="setVocal($event, status.id)"
                    [value]="status.id">

                    {{status.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

            </div>

          </div>
          <mat-form-field appearance="fill" style="width:98%;"
            [ngClass]="{'disabledElement': (showMetaFor === -1) ,'disabledElement1': (currentUser.is_meta_tagger === false)}">
            <!-- <input placeholder="Typing a name to search" [formControl]="vocalCtrl" [matChipInputFor]="vocalChipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addVocal($event)" #vocalInput> -->

            <mat-chip-list #vocalChipList="">
              <mat-chip style="margin: 18px 12px 0px 0;"
                *ngFor="let vocal of track.Vocalsnew_suggestion; trackBy: vocalTrackBy" [selectable]="false"
                [removable]="true" (removed)="removeVocal(vocal)" [class]="getVocalAddedByClass(vocal)">
                {{vocal}}
                <!-- <mat-icon matChipRemove *ngIf="true">cancel</mat-icon> -->
              </mat-chip>
            </mat-chip-list>
          </mat-form-field>
          <button class="btn save-btn" style="margin-top:5%;" mat-button disabled
            *ngIf="!track.Vocalssub_category || (!track.Vocalsdescriptions || track.Vocalsdescriptions?.length==0)">Add
            one or more vocal type to continue</button>
          <button class="btn save-btn" style="margin-top:5%;" mat-button (click)="closethisPanel('vocals')"
            [ngClass]="{'disabledElement':(!track.Instrumentsub_category) ||  (showMetaFor === -1) ,'disabledElement1':  (currentUser.is_meta_tagger === false)}"
            *ngIf="track.Vocalssub_category && (track.Vocalsdescriptions && track.Vocalsdescriptions?.length>0)">continue
            &
            save</button>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel *ngIf="isInstrumental!==false" hideToggle #lyricsPanel style="margin-bottom: 4%;"
          (opened)="lyricsPanelOpen = true" (closed)="lyricsPanelOpen = false">
          <mat-expansion-panel-header>
            <mat-panel-title class="card-title panel-title">

              <svg xmlns="http://www.w3.f/2000/svg" matTooltip="Please list out the lyrics to this song if applicable.
  
                " matTooltipPosition="above" matTooltipClass="tooltip-white custom-tooltip " height="24px"
                viewBox="0 0 24 24" width="24px" fill="#000000">
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                  d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
              </svg>
              <b>LYRICS</b>


              <mat-panel-description>
              </mat-panel-description>
              <div *ngIf="lyricsPanelOpen">
                <div class="btn headerFlexItem">
                  <u style="margin-left:auto;position: absolute;top:14px; font-size: 12px; right:14px;">Close</u>
                  <svg style="position: absolute;left:3%;" xmlns="http://www.w3.f/2000/svg" matTooltip="Please list out the lyrics to this song if applicable.
  
                  " matTooltipPosition="above" matTooltipClass="tooltip-white custom-tooltip " height="24px"
                    viewBox="0 0 24 24" width="24px" fill="#fffff">
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path
                      d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                  </svg>
                </div>
              </div>
              <div *ngIf="!lyricsPanelOpen">
                <div class="btn  headerFlexItem"
                  style="margin-left:auto;position: absolute;top:14px; font-size: 12px; right:14px;">
                  <u
                    *ngIf="(track.version_lyrics.verse=='' || track.version_lyrics.verse==undefined || track.version_lyrics.verse==null  ) && 
                    (track.version_lyrics.pre_chorus==''|| track.version_lyrics.pre_chorus==undefined || track.version_lyrics.pre_chorus== null ) && 
                    (track.version_lyrics.chorus==''|| track.version_lyrics.chorus==undefined || track.version_lyrics.chorus== null) &&
                     (track.version_lyrics.bridge==''|| track.version_lyrics.bridge==undefined || track.version_lyrics.bridge==null)">Add</u>
                  <u
                    *ngIf="track.version_lyrics.verse!='' || track.version_lyrics.pre_chorus!='' || track.version_lyrics.chorus!='' || track.version_lyrics.bridge!=''">Review</u>
                  <u *ngIf="track.version_lyrics.verse!='' || track.version_lyrics.pre_chorus!='' || track.version_lyrics.chorus!='' || track.version_lyrics.bridge!=''"
                    class="svg-text"><img class="uploadwidth" src="assets/img/check-circle.png"></u>
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>


          <div class="card-data tv-ref">
            <p>Please input lyrics in the order they are found within the track</p>
            <div class="tagnames" style="margin-bottom: 8%;"
              [ngClass]="{'disabledElement': (showMetaFor === -1) ,'disabledElement1': (currentUser.is_meta_tagger === false)}">
              <div class="tagnames-data1" (click)="showVerseField=true;addVerse()"
                [ngClass]="{'selected_lyric_box':((values.length === 0) ? false : true)}">
                <p><span class="close-btn1" style="margin-right:13px;">&#43;</span>Verse </p>
              </div>
              <div class="tagnames-data1" (click)="showPreChorusField=true; addPreChrous()"
                [ngClass]="{'selected_lyric_box':((preChrousValues.length === 0) ? false : true)}">
                <p><span class="close-btn1" style="margin-right:10px;">&#43;</span>Pre-Chorus </p>
              </div>
              <div class="tagnames-data1" (click)="showChorusField=true; addChrous()"
                [ngClass]="{'selected_lyric_box':((chrousValues.length === 0) ? false : true)}">
                <p><span class="close-btn1" style="margin-right:13px;">&#43;</span>Chorus </p>
              </div>
              <div class="tagnames-data1" (click)="showBridgeField=true; addBridge()"
                [ngClass]="{'selected_lyric_box': ((bridgeValues.length === 0) ? false : true)}">
                <p><span class="close-btn1" style="margin-right:13px;">&#43;</span>Bridge </p>
              </div>
            </div>
            <hr>
            <div class="grid-card"
              *ngIf="(!showVerseField && !showPreChorusField && !showChorusField && !showBridgeField)">
              <p class="lyrics-info">Please select from the above options to get started</p>
            </div>
            <div class="grid-card" style="margin-top:30px;" *ngIf="showVerseField"
              [ngClass]="{'disabledElement': (showMetaFor === -1),'disabledElement1':(currentUser.is_meta_tagger === false)}">
              <div class="mat-card grid-item" style="padding:0 !important;">


                <div *ngFor="let value of values; let i = index" style="padding:12px;">
                  <h4 style="margin-top: 4%;"><strong *ngIf="i === 0">Verse</strong><strong
                      *ngIf="i !== 0">Verse{{i}}</strong>
                    <span (click)="remove(i);  setValidation()" class="close-btn1" style="float: right;
                        margin-right: 3px;
                        margin-top: 4px;
                        font-size: 16px;">&times;</span>
                    <strong style="float: right;cursor: pointer;" (click)="movedownVerse(i,i+1)"
                      *ngIf="i+1 !== values.length">
                      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                        fill="#000000">
                        <path d="M24 24H0V0h24v24z" fill="none" opacity=".87" />
                        <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" />
                      </svg>
                    </strong>
                    <strong style="float: right; cursor: pointer;" (click)="moveupVerse(i,i-1)" *ngIf="i !== 0">
                      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                        fill="#000000">
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z" />
                      </svg>
                    </strong>
                  </h4>
                  <textarea type="text" (ngModelChange)="onLyricsChangeverse($event, i)" [(ngModel)]="value.value"
                    #name="ngModel" name="value{{i}}"></textarea>
                  <br>
                </div>

              </div>
            </div>
            <div class="grid-card" style="margin-top:30px;" *ngIf="showPreChorusField"
              [ngClass]="{'disabledElement': (showMetaFor === -1) ,'disabledElement1':(currentUser.is_meta_tagger === false)}">
              <div class="mat-card grid-item" style="padding:0 !important;">
                <div *ngFor="let value of preChrousValues; let i = index" style="padding:12px;">
                  <h4 style="margin-top: 2%;"><strong *ngIf="i === 0">Pre-Chorus</strong><strong
                      *ngIf="i !== 0">Pre-Chorus{{i}}</strong>
                    <span (click)="removePreChrous(i);setValidation()" class="close-btn1" style="float: right;
                                  margin-right: 3px;
                                  margin-top: 4px;
                                  font-size: 16px;">&times;</span>
                    <strong style="float: right; cursor: pointer;" (click)="movedownPreChrous(i,i+1)"
                      *ngIf="i+1 !== preChrousValues.length">
                      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                        fill="#000000">
                        <path d="M24 24H0V0h24v24z" fill="none" opacity=".87" />
                        <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" />
                      </svg>
                    </strong>
                    <strong style="float: right; cursor: pointer;" (click)="moveupPreChrous(i,i-1)" *ngIf="i !== 0">
                      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                        fill="#000000">
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z" />
                      </svg>
                    </strong>
                  </h4>

                  <textarea type="text" (ngModelChange)="onLyricsChangepre_chorus($event, i)" [(ngModel)]="value.value"
                    #name="ngModel" name="value{{i}}"></textarea>
                  <br>
                </div>


              </div>
            </div>
            <div class="grid-card" style="margin-top:30px;" *ngIf="showChorusField"
              [ngClass]="{'disabledElement': (showMetaFor === -1) ,'disabledElement1':(currentUser.is_meta_tagger === false)}">
              <div class="mat-card grid-item" style="padding:0 !important;">
                <div *ngFor="let value of chrousValues; let i = index" style="padding:12px;">

                  <h4 style="margin-top: 2%;"><strong *ngIf="i === 0">Chorus</strong><strong
                      *ngIf="i !== 0">Chorus{{i}}</strong>
                    <span (click)="removeChrous(i);setValidation()" class="close-btn1" style="float: right;
                              margin-right: 3px;
                              margin-top: 4px;
                              font-size: 16px;">&times;</span>
                    <strong style="float: right; cursor: pointer;" (click)="movedownChrous(i,i+1)"
                      *ngIf="i+1 !== chrousValues.length">
                      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                        fill="#000000">
                        <path d="M24 24H0V0h24v24z" fill="none" opacity=".87" />
                        <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" />
                      </svg>
                    </strong>
                    <strong style="float: right; cursor: pointer;" (click)="moveupChrous(i,i-1)" *ngIf="i !== 0">
                      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                        fill="#000000">
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z" />
                      </svg>
                    </strong>
                  </h4>
                  <textarea type="text" (ngModelChange)="onLyricsChangechorus($event, i)" [(ngModel)]="value.value"
                    #name="ngModel" name="value{{i}}"></textarea>

                  <br>
                </div>
              </div>
            </div>
            <div class="grid-card" style="margin-top:30px;" *ngIf="showBridgeField"
              [ngClass]="{'disabledElement': (showMetaFor === -1),'disabledElement1':(currentUser.is_meta_tagger === false)}">
              <div class="mat-card grid-item" style="padding:0 !important;">
                <div *ngFor="let value of bridgeValues; let i = index" style="padding:12px;">

                  <h4 style="margin-top: 2%;"><strong *ngIf="i === 0">Bridge</strong><strong
                      *ngIf="i !== 0">Bridge{{i}}</strong>
                    <span (click)="removeBridge(i);setValidation()" class="close-btn1" style="float: right;
                  margin-right: 3px;
                  margin-top: 4px;
                  font-size: 16px;">&times;</span>
                    <strong style="float: right; cursor: pointer;" (click)="movedownBridge(i,i+1)"
                      *ngIf="i+1 !== bridgeValues.length">
                      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                        fill="#000000">
                        <path d="M24 24H0V0h24v24z" fill="none" opacity=".87" />
                        <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" />
                      </svg>
                    </strong>
                    <strong style="float: right; cursor: pointer;" (click)="moveupBridge(i,i-1)" *ngIf="i !== 0">
                      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                        fill="#000000">
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z" />
                      </svg>
                    </strong>
                  </h4>
                  <textarea type="text" (ngModelChange)="onLyricsChangebridge($event, i)" [(ngModel)]="value.value"
                    #name="ngModel" name="value{{i}}"></textarea>
                  <br>
                </div>
              </div>
            </div>
          </div>
          <button class="btn save-btn" style="margin-top:5%;" mat-button disabled
            *ngIf="(track.version_lyrics.verse=='' || track.version_lyrics.verse==undefined ) && (track.version_lyrics.pre_chorus==''|| track.version_lyrics.pre_chorus==undefined ) && (track.version_lyrics.chorus==''|| track.version_lyrics.chorus==undefined) && (track.version_lyrics.bridge==''|| track.version_lyrics.bridge==undefined)">Add
            lyrics to continue</button>
          <button class="btn save-btn" style="margin-top:5%;" mat-button (click)="closethisPanel('lyrics')"
            [ngClass]="{'disabledElement':(!track.Instrumentsub_category) ||  (showMetaFor === -1) ,'disabledElement1':  (currentUser.is_meta_tagger === false)}"
            *ngIf="track.version_lyrics.verse!='' || track.version_lyrics.pre_chorus!='' || track.version_lyrics.chorus!='' || track.version_lyrics.bridge!=''">continue
            &
            save</button>

        </mat-expansion-panel>
      </mat-accordion>
    </div>

  </div>
</div>