import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { of, ReplaySubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { countries } from '../../constants/countries';

export interface Country {
  name:string;
  code:string;
}

@Component({
  selector: 'app-country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.scss']
})
export class CountrySelectComponent implements OnInit, OnDestroy {
  option = 'select';

  @Input()
  currentSelection: string;

  @Output()
  currentSelectionChange:EventEmitter<string> = new EventEmitter<string>();

  searching:boolean = false;
  filterCtrl = new FormControl();
  filteredOptions: ReplaySubject<Country[]> = new ReplaySubject<Country[]>(1);

  get current():Country {
    if (!this.currentSelection) return null;
    return countries.find(c => c.code.toLowerCase() == this.currentSelection.toLowerCase());
  }

  private _subscriptions: Subscription[] = [];

  constructor() { }

  ngOnInit(): void {

    this.filteredOptions.next(countries);

    this._subscriptions.push(
      this.filterCtrl.valueChanges.pipe(
        debounceTime(250),
        distinctUntilChanged(),
        tap(()=> {
          this.searching = true;
        }),
        switchMap(val => {
          if (!val || val == '') {
            return of(countries);
          }
          return of(countries.filter(c=>c.name.toLowerCase().includes(val.toLowerCase())||c.code.toLowerCase().includes(val.toLowerCase())));
        }),
        tap(()=> {
          this.searching = false;
        })
      ).subscribe((options:Country[]) => {
        this.filteredOptions.next(options);
      })
    );

  }

  ngOnDestroy() {
    this._subscriptions.forEach(s=>s.unsubscribe());
    this._subscriptions = [];
  }

  selectionMade(change:MatSelectChange) {
    this.filterCtrl.reset();
    this.currentSelection = change.value.code;
    this.currentSelectionChange.emit(change.value.code);
  }

  compareWith(c1: Country, c2: Country) {
    if (!c1 || !c2) {
      return c1 == c2;
    }
    return c1.code == c2.code;
  }

}
