import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MenuListService } from '../../shared/services/menu-list.service';
import { environment } from '../../../environments/environment';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { DialogService } from 'src/app/shared/services/dialog.service';


@Component({
  selector: 'app-edit-genre-dialogue',
  templateUrl: './edit-genre-dialogue.component.html',
  styleUrls: ['./edit-genre-dialogue.component.scss']
})
export class EditGenreDialogueComponent implements OnInit {
  imageChangedEvent: any = '';
  imageUrl = '/assets/img/placeholder-image.png';
  croppedImage = '/assets/img/placeholder-image.png';
  finalImg = '/assets/img/placeholder-image.png';
  oldMainUrl ='/assets/img/placeholder-image.png';
  oldThumbUrl= '/assets/img/placeholder-image.png';
  show = false;
  newFinalImg:any ='';
  showCropper = false;
  currentCropImage = '/assets/img/placeholder-image.png';
  maximumError:boolean = false;
  maximumDimention: boolean = false;
  crop() {
    this.show = false;
    this.showCropper = false;
    this.finalImg = this.croppedImage;
  }
  //  toggle(){
  // this.show = !this.show;
  //   }
  toggleclose() {
    this.show = !this.show;

    this.imageUrl = this.oldMainUrl
    this.finalImg = this.oldThumbUrl
  }
  @ViewChild('myFileInput') myFileInput;

onFileChange(event) { 
  this.myFileInput.nativeElement.value = '';
}
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.newFinalImg = event;
    this.currentCropImage = this.croppedImage
    const fileToReturn = this.base64ToFile(
      event.base64,
      this.image,
    )
    console.log(fileToReturn)
    this.newFinalImg = fileToReturn;
  }
  base64ToFile(data, filename) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  fileToUpload: any;
  image = ''
  genreImg = ''

  handleFileInput(file: FileList,e) {
   
    this.maximumDimention = false;
    this.maximumError = false
    // const URL = window.URL || window['webkitURL'];
    // const Img = new Image();

    const filesToUpload = (e.target.files);
//    Img.src = URL.createObjectURL(filesToUpload[0]);

  //   Img.onload = (e: any) => {
  //     const height = e.path[0].height;
  //     const width = e.path[0].width;
  
  //     if(width < 900 || height < 540){
  //       this.isEditImage = false
  //       this.maximumDimention = true;
  //     this.show = false;
  //     this.genreImg = '';
  //     this.image = '';
  //     this.finalImg = '';
  //     this.imageUrl = ''
  //     this.fileToUpload = null;
  //     this.imageChangedEvent = null
  //     return
  //     }
  // }
  
    if(e.srcElement.files[0].size  > 1 * 1000000){
      this.isEditImage = false
      this.maximumError = true;
      this.show = false;
      this.genreImg = '';
      this.image = '';
      this.finalImg = '';
      this.imageUrl = ''
      this.fileToUpload = null;
      this.imageChangedEvent = null
      return
     }
     this.isEditImage = true
    this.show = true;
    this.fileToUpload = file.item(0);
    this.imageChangedEvent = event;
    this.finalImg = '/assets/img/placeholder-image.png';
    //Show image preview
    this.image = this.fileToUpload.name;
    this.genreImg = this.fileToUpload;
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
      // this.finalImg = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
    this.isEditImage = true
    // if(this.gnereName === ''){
    //   this.gnereName = this.list.currGnere.name
    // }
  }
  gnereName: string = '';
  loader: boolean = true;
  isEditImage = false;
  constructor(private dialogRef: MatDialogRef<EditGenreDialogueComponent>,
    public list: MenuListService,
    private config: MenuConfigService,
    public api: UserMenuService,
    public _DialogService:DialogService
  ) {
    console.log(list.currGnere)
  }

  ngOnInit(): void {
    setTimeout(() => {                           //<<<---using ()=> syntax
      this.loader = false;
    }, 500);
    if (this.list.currGnere?.marquee_image) {
      this.imageUrl = this.list.currGnere.marquee_image
      var splitUrl = this.list.currGnere.marquee_image.split('/');
      this.image = splitUrl[splitUrl.length - 1]
      this.finalImg = this.list.currGnere.thumb_image
      var splitUrl = this.list.currGnere.thumb_image.split('/');
      this.image = splitUrl[splitUrl.length - 1]
      this.oldMainUrl = this.list.currGnere.marquee_image
      this.oldThumbUrl = this.list.currGnere.thumb_image
    }

  }
  close(event:any='') {
    this.dialogRef.close(event);
  }
  cropData() {
    this.showCropper = true;
    this.show = false;
    this.currentCropImage = this.currentCropImage
  }
  resetCrop() {
    this.showCropper = false;
    this.show = true;
  }
  save() {
    if (this.gnereName === '') {
      this.gnereName = this.list.currGnere.name
    }
    const formData: any = new FormData();
    formData.append("name", this.gnereName);
    if (this.isEditImage === true) {
    
      // formData.append("file", this.genreImg);
      formData.append("marquee_image", this.genreImg);
      formData.append("thumb_image", this.newFinalImg);
    }
    let data = {
      name: this.gnereName
    }
    let requestUrl = environment.apiURL + this.config.doGenres + this.list.currGnere.id + '/'
    this.api.doPUT(requestUrl, formData).subscribe(x => {
      this.close(true)
      this.list.getAllGenres();

    },
      error => {
        // alert(error.error.error)
        let data = error.error?.error ? error.error?.error : (error.error?.detail ?error.error?.detail : (error.error?.details ? error.error?.details:``) )
        this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
        });
        this.close()
      }
    )
  }
}