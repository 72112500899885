<div class="dialog-container">
    <div class="dialog-header">
        <h4 class="dialog-heading">Add Genres to cms</h4>
        <button type="button" class="close-button" (click)="close()">
        <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                    <g id="Genre-Options">
                        <g id="Close" transform="translate(1439.000000, 32.000000)">
                            <path d="M24,0 L0,24" id="Path"></path>
                            <path d="M0,0 L24,24" id="Path"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        </button>
    </div>

     <mat-dialog-content>        
        <div class="dialog-body">
            <div class="add-to-folder form-container">
                <h6 class="label">Available Genres<span  *ngIf="selectedGenre.length !== 0" class="deselect" (click)="deselectAll()">
                    <small>Deselect all({{selectedGenre.length}}) </small></span></h6>
                <span></span>
                <input class="search" id="myInput" type="text" (keyup)="getFindGenreList()"  placeholder="Find a Genre">
                <ul class="list" id="checkBoxUl">
                    <li *ngFor="let Genre of allGenreItemlist; let idx = index">
                        <mat-checkbox [checked]="Genre.checked" (change)="mySelectOpt(Genre.id, $event, idx)">
                            <span class="check-text">{{Genre.name}}</span> 
                        </mat-checkbox>
                    </li>
                </ul>
            </div>
            <p class="note">Select genres to add</p>
            <div class="folder-add" *ngFor="let Genre of selectedGenreName; let idx = index" style="display: inline-block;margin-bottom: 2%; margin-right: 2%;">
                <span class="playlistName" style="padding: 1px 5px; border: 1px solid #50e3c2; border-radius: 3px;">{{Genre.name}}
                   <span class="removePlaylist" (click)="removeSelectGenre(Genre)"><svg style="top:0px;left:25%;vertical-align: middle;" xmlns="http://www.w3.org/2000/svg" height="15px" viewBox="0 0 24 24" width="15px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path></svg></span> 
                </span>
            </div>
            <div class="form-col">
              <button class="form-button" [disabled]="selectedGenre.length === 0" (click)="addSelectGenre()">Add</button>
              <button class="form-button form-button-cancel" (click)="close()">Cancel</button>
              </div>

          </div>
    </mat-dialog-content> 

</div>


