import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import {Track} from '../models/track.model';
import {TrackService} from '../services/track.service';
import {TrackComposer} from '../models/trackcomposer.model';
import {TrackVersion} from '../models/trackversion.model';
import { CONTAINER_DATA, DialogConfig, SliderDialogService } from '../services/slider-dialog.service';
import { TrackVersionService } from '../services/trackversion.service';
import { forkJoin } from 'rxjs';
import { MenuListService } from '../services/menu-list.service';
import { PlayerService } from '../services/player.service';
import { DialogService } from '../services/dialog.service';

@Component({
  selector: 'app-edit-track',
  templateUrl: './edit-track.component.html',
  styleUrls: ['./edit-track.component.scss']
})
export class EditTrackComponent implements OnInit {

  track: Track;
  set version(v: TrackVersion) {
    this._version = v;
  }
  get version():TrackVersion {
    return this._version;
  }
  bpmValue: number = 0;
  @Output()
  versionChange = new EventEmitter<TrackVersion>();
  _version: TrackVersion;
  composers: TrackComposer[];
  loading:boolean = false;
  oldVersionTitle =''
  oldVersionStatus =''


  constructor(
    private _sliderDialogService: SliderDialogService,
    private trackService: TrackService,
    public _DialogService:DialogService,
    private trackVersionService: TrackVersionService,
    @Inject(CONTAINER_DATA) public componentData: DialogConfig,  private _playerService: PlayerService,
    public list: MenuListService
    ) {
    }

  ngOnInit(): void {
    this.track = this.trackService.trackPopData
  
// this.version = this.trackService.versionPopData
   // this.track = new Track(this.componentData.data.track.toJSON());
    this.version = new TrackVersion(this.componentData.data.version);
    this.oldVersionTitle = this.componentData.data.version?.version_title;
    this.track.version['title'] = this.oldVersionTitle
    this.oldVersionStatus = this.version.status;

    this.composers = this.track.composers;
    if (this.track) {
      if (this.track.genre && (this.track.moods[0] || this.track.moods[1] || this.track.moods[2]) && this.version.tempo && this.version.track_ending && this.version.calculated_key) {
        this.track.isValid = false;
      }
      else {
        this.track.isValid = true;
      }
    }
    if(this.version.is_instrumental){
      if(this.track.instruments ===  undefined || this.track['instruments_desc'].length === 0 || 
       this.track.tv_references.length < 2 || this.track.network_references.length < 2 || this.track.band_references.length < 2  || 
       this.track.sonic_references.length < 2){
         this.track.isValid = true;
      }
      else{
        this.track.isValid  = false;
      }
    }
    if(this.version.is_vocals){
     if(this.track.instruments ===  undefined || this.track['instruments_desc'].length === 0  || 
       this.track.tv_references.length < 2 || this.track.network_references.length < 2 || this.track.band_references.length < 2  || 
       this.track.sonic_references.length < 2 || (this.track.lyrics.verse == '' || this.track.lyrics.bridge == '' || this.track.lyrics.chorus == '' || this.track.lyrics.pre_chorus == '') || this.track.vocal ===  undefined || this.track['vocal_desc'].length === 0 ){
        this.track.isValid  = true;
     }
     else{
      this.track.isValid  = false;
     }
   }
  }

  bpmChange(val: any) {
    this.bpmValue = Math.round(val);
    this.version.calculated_bpm = Math.round(val);
    this.versionChange.emit(this._version);
  }
  onSave() {

    if(this.trackService.cowritten_by.length > 0){
      let newValues =[];
    
        // if (this.trackService.cowritten_by[i].id !== 2 && this.trackService.cowritten_by[i].id !== 1 && this.trackService.cowritten_by[i].id !== 10000 && this.trackService.cowritten_by[i].id !== 10001 && this.trackService.cowritten_by[i].id !== 10002) {
        //   newValues.push(this.trackService.cowritten_by[i])
        // }
      for (let j = 0; j < this.track.default_cowritten_by.length; j++) {
        let data = this.track.default_cowritten_by[j].id
        this.trackService.cowritten_by = this.trackService.cowritten_by.filter(function (e) {
          if (e.id !== data) {
            return e;
          }
        })
        
      }
     // this.trackService.cowritten_by = newValues
    }
    
   console.log(this.track, this.track.Instrumentsub_category)
   let id:any=[]
   id= this.track.Instrumentsub_category;
   if(id)
   {
    this.track.instruments = this.track.instruments.filter(function(e) { 
      if(e.id !== id){
        return e;
      }
    })
    if( this.track && this.track.Instrumentdescriptions){
    this.track.Instrumentdescriptions = this.track.Instrumentdescriptions.filter(function (e) {
      console.log(e)

      if (e !== id) {
        return e;
      }
    })
  }
   }
      // <!-- Add Newsuggestion -->

   if(this.track.tv_references){
    this.track.tv_references = this.track.tv_references.filter(function(e) { 
      if(e.id !== ''){
        return e;
      }
    })
   }
   if(this.track.network_references){
    this.track.network_references = this.track.network_references.filter(function(e) { 
      if(e.id !== ''){
        return e;
      }
    })
   }

    let data = {
      "title": this.track.title,
      "artist": this.track.artist.id,
      "id": this.track.id,
      "genre": this.track.genre.id,
      "sub_genre": (this.track.sub_genre && this.track.sub_genre.id)? this.track.sub_genre.id : "",
      "moods": this.track.moods.map(x => x.id),
      // "track_ending": this.version.track_ending,
      "versions": this.version,
      "is_exclusive": true,
      "composers": [],
      "instruments":{
          "sub_category":this.track.Instrumentsub_category || "",
          "descriptions":this.track.Instrumentdescriptions || [],
          "suggesstion": [],
          // "suggesstion":this.track.instruments.map(x=> x.id) || [],
          "new_suggestion":this.track.Instrumentnew_suggestion || []
      },
    // "tv_references": this.track.tv_references.map(x=> x.id),
   //  "network_references": this.track.network_references.map(x=> x.id),
       //"artist_references":this.track.artist_references.map(x=> x.id),
   // <!-- Add Newsuggestion -->
       "tv_references": {
        "sub_category": this.track.tv_references.map(x=> x.id),
//"descriptions": this.track.tv_referencesDescriptions &&this.track?.tv_referencesDescriptions.map(x=> x.id) || [],
        //"suggesstion":[],
        "new_suggestion": this.track.tv_referencesNew_suggestion || []
    },
    "network_references":{
        "sub_category": this.track.network_references.map(x=> x.id),
       // "descriptions": this.track.network_referencesDescriptions && this.track?.network_referencesDescriptions.map(x=> x.id)|| [],
        //"suggesstion":[],
        "new_suggestion": this.track.network_referencesNew_suggestion|| []
    },
     "artist_references":[],
      "band_references": this.track.band_references.map(x=> x.id),
      // "sonic_references": [],
      "sonic_references": this.track.sonic_references.map(x=> x.id),
      "lyrics_data":{
        "verse":this.track.lyrics.verse ?this.track.lyrics.verse:[''],
        "pre_chorus":this.track.lyrics.pre_chorus ? this.track.lyrics.pre_chorus :[''],
        "chorus":this.track.lyrics.chorus?this.track.lyrics.chorus:[''],
        "bridge":this.track.lyrics.bridge?this.track.lyrics.bridge:['']
      },
      "vocal": {
          "sub_category":this.track.Vocalssub_category || "",
          "descriptions":this.track.Vocalsdescriptions || [],
          // "suggesstion":this.track.vocals || [],
          "suggesstion": [],
          "new_suggestion":[]
      },
      // "owned_by":1,
      "albums":"",
      "cowritten_by": this.filter(this.trackService.cowritten_by) || [],
      'added_by_role': this.track.added_by_role,
      'notify_composer': this.track.notify_composer,
      'partial': true
      // "calculated_bpm": this.version.calculated_bpm,
      // "calculated_key": this.version.calculated_key,
      // "tempo": this.version.tempo
      
  }

  console.log(data)
    this.track.composers = this.composers;
    this.loading = true;
    forkJoin([
      this.trackService.updateTrack(data,'track'),
     // this.trackVersionService.updateTrackVersion(this.version)
    ]).subscribe(([track]) => {
      this.componentData.data.track = track;
      // this.componentData.data.version = version;
      this.loading = false;
      this._playerService.nextTab(true)
      this._playerService.updateWavePlay(false);
      this._playerService.updateIsPlaying(false);
      this._playerService.updateShowMusicPlayer(false);
      this._playerService.play(null, null);
      this._sliderDialogService.close(true);
      location.reload();
    }, (error) => {
      console.log(error);
      if(error.error.error){
        // alert(error.error.error)
        let data = error.error?.error ? error.error?.error : (error.error?.detail ?error.error?.detail : (error.error?.details ? error.error?.details:``) )
        this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
        });
      }
      else{
        // alert(error.error.detail)
        let data = error.error?.error ? error.error?.error : (error.error?.detail ?error.error?.detail : (error.error?.details ? error.error?.details:``) )
        this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
        });
        
      }
      //alert("An error ocurred while updating the track. Check your input for errors and then check your internet connections and try again");
      this.loading = false;
    });
  
  }
  filter(e){
    let filData = []
    for(let x of e){
      console.log(e)
      let data = {}
      if(x.user_id){
        data['user_id'] = x.user_id
        data['percent'] = x.percent
      }
      else{
        data['user_id'] = x.id
        data['percent'] = x.percent
      }
     
      filData.push(data)
    }
    return filData
  }
  ngOnDestroy() {
    this._version .title = this.oldVersionTitle;
    this._version.status = this.oldVersionStatus;
    this.versionChange.emit(this._version );
  }
}
