import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { TrackVersion } from '../models/trackversion.model';
import { TrackStatusValue } from '../models/trackstatus-value.model';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatSelectChange } from '@angular/material/select';
import { Track } from '../models/track.model';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-track-status',
  templateUrl: './track-status.component.html',
  styleUrls: ['./track-status.component.scss']
})
export class TrackStatusComponent implements OnInit {

  @Input()
  set track(t: Track) {
    this._track = t;
    this.trackChange.emit(this._track);
  }
  get track(): Track {
    return this._track;
  }
 
  @Input()
  set version(v: TrackVersion) {
    this._version = v;
    this.versionChange.emit(this._version);
  }
  get version():TrackVersion {
    return this._version;
  }

  @Output()
  versionChange = new EventEmitter<TrackVersion>();
  @Output()
  trackChange = new EventEmitter<Track>();

  panelOpenState: boolean;
  notify = false;
  statusSelected: string = 'PENDING';
  selectedReasons: string[];
  otherReason: string;
  noteReject = false;
  isApproved = true;

  private _version: TrackVersion;
  private _track: Track

  constructor(private formBuilder: FormBuilder, public _userService: UserService,) { }

  rejectionReasons = [
    'Missing Version',
    'Production Value',
    'Edited Incorrectly',
    'Other'
  ];

  get rejectionReason(): boolean {
    return this.version.status == 'DENIED';
  }
  get allowNotify(): boolean {
    return this.version.status == 'DENIED';
  }

  get reasonDetails(): boolean {
    if (!this.version || !this.version.rejected_status_reason || !this.version.rejected_status_reason.toLowerCase().includes('other'))
    { 
      this. noteReject = false;
      return false; }
    else{
     // this.noteReject = true
    return true;
    }
  }

  statuses: {[key:string]:TrackStatusValue} = {
    'PENDING': new TrackStatusValue({value: ['PENDING'], color: '#e3b950', name: 'Pending'}),
    'PENDING_APPROVAL': new TrackStatusValue({value: ['PENDING_APPROVAL'], color: '#47cdff', name: 'Pending Approval'}),
    'APPROVED': new TrackStatusValue({value: ['APPROVED'], color: '#50e3c2', name: 'Approved'}),
    'DENIED': new TrackStatusValue({value: ['DENIED'], color: '#d73939', name: 'Reject'}),
    'DISABLED': new TrackStatusValue({value: ['DISABLED'], color: '#8a8a8d', name: 'Disabled'})
  };

  ngOnInit() {
    
    this.statusSelected = this.version.status;
    if (this.version && this.version.rejected_status_reason) {
      if(this.version.status === 'DENIED'){
      let reasons = this.version.rejected_status_reason.split(', ');
      this.selectedReasons = reasons.map(r=> r.toLowerCase().includes('other') ? r.split(':')[0]:r );
      let other = this.version.rejected_status_reason.split(':');
      other.shift();
      this.otherReason = other.join(':');
      }
    }
    this.notify = this.track.notify_composer
  }

  setRejectionReasons(event: MatSelectChange) {
    this.selectedReasons = event.value;
    let reason = event.value.join(', ');
    if (reason.includes('Other')) {
      reason = reason.replace('Other', `Other:${this.otherReason}`)
      this.noteReject = true
    }
    this.version.rejected_status_reason = reason;
    this.versionChange.emit(this.version);
  }
  notifyComposer(event){
    console.log(event)
    this.track.notify_composer = event;
    this.notify = this.track.notify_composer
    this.trackChange.emit(this.track);
  }

  setOtherReason(reason:string) {
    this.otherReason = reason;
    if (!this.version || !this.version.rejected_status_reason || !this.version.rejected_status_reason.toLowerCase().includes('other')) return;
    let reasons = this.version.rejected_status_reason.split(', ');
    reasons = reasons.map(r=>r.toLowerCase().includes('other') ? `${r.split(':')[0]}: ${reason}`:r);
    this.version.rejected_status_reason = reasons.join(', ');
    this.versionChange.emit(this.version);
  }

  onStatusSelection() {
   // this.isApproved = false;
    if(this.statusSelected === 'APPROVED'){
      if( this._userService.currentUser.is_approver === true){
        this.isApproved = true
      }
      else{
        this.isApproved = false
      }
    }
    else{
      this.isApproved = true
    }

    if(this.isApproved === true){
    if (this.version.status != this.statusSelected) {
      this.version.status = this.statusSelected;
      this.versionChange.emit(this.version);
    }
    this.track.isValid = false
    this.trackChange.emit(this.track);
   
  }
   else{
    this.version.status = this.statusSelected;
    this.track.isValid = true
    this.trackChange.emit(this.track);
   // alert(`Don't have permission`)
  }
    this.noteReject = false;
    if (this.statusSelected !== 'DENIED') {
      this.noteReject = false;
      this.version.rejected_status_reason =''
      this.selectedReasons = null
    }
    this.version.internal_status_notes = ''
    this.versionChange.emit(this.version);
  }

  setInternalNote(note:string) {
    if (this.version.internal_status_notes != note) {
      this.version.internal_status_notes = note;
      this.versionChange.emit(this.version);
    }
  }



}
