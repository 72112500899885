import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuListService } from '../shared/services/menu-list.service';
import { UserService } from '../shared/services/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})

export class ResetPasswordComponent implements OnInit {
  loading = false;
  errors: string[] = [];
  forgotEmail = '';
  setPassword = '';
  successReset = false;
  uid:string ='';
  token :string = "";
  confirmPassword = '';

  constructor(private _userService: UserService, private _router: Router,private route: ActivatedRoute, public list: MenuListService) {
    this.list.menuheader = false;
    this._userService.token = null
      localStorage.setItem("token", null);
  }

  ngOnInit(): void {
        this._userService.currentUser = null;
        localStorage.setItem("userdata", null);
        this.uid = this.route.snapshot.queryParams.uidb64
        this.token = this.route.snapshot.queryParams.token
        this.forgotEmail =  localStorage.getItem("forgotEmail")

  }
  passwordInputType = 'password';
  passwordInputType1 = 'password';

  togglePasswordInputType(type: any = "") {
    if (type === 'confirm') {
      if (this.passwordInputType1 == 'password') {
        this.passwordInputType1 = 'text';
      } else {
        this.passwordInputType1 = 'password';
      }
    }
    else {
      if (this.passwordInputType == 'password') {
        this.passwordInputType = 'text';
      } else {
        this.passwordInputType = 'password';
      }
    }
  }

  resetPassword(){
    if (this.setPassword !== this.confirmPassword) {
      this.errors = [];
      this.errors.push("New password and Confirm password must be same!")
    }
    else {
    this.errors = [];
    this.loading = true;
    this._userService.resetNewPassword(
      this.forgotEmail,
      this.uid,
      this.token,
      this.setPassword,
    )
    .subscribe(
      () => {
        this.loading = false;
        this.successReset = true;
        localStorage.setItem("forgotEmail", null);
      },
      (err) => {
        this.loading = false;
        this.successReset = false;
        this.errors.push('Unable to update password');
        console.error(err);
      }
    );
    }
  }
  login(){
    localStorage.setItem("forgotEmail", null);
    this._router.navigate(['/login']);
  }
}
