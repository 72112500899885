import { MibeModel } from './mibemodel.model';
import { Publisher } from './publisher.model';

export class ComposerPRO implements MibeModel {
  id: number;
  pro: string;
  publisher: Publisher;

  /**
   * Create a Mood.
   * @param {any} json - The JSON object with which to construct our Mood.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a Mood.
   * @param {any} json - The JSON object with which to load our Mood.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this.id = json.id;
    this.pro = json.pro;
    if (json.publisher) {
      this.publisher = new Publisher(json.publisher);
    }
  }
  /**
   * Returns a JSON representation of our Mood
   * @return {any} - The JSON representation of our Mood
   */
  toJSON() {
    var json: any = {
      'id': this.id,
      'pro': this.pro
    };
    if (this.publisher) {
      json['publisher'] = this.publisher.toJSON();
    }
    return json;
  }
}
