<div class="main">
    <div class="user-list">
        <div class="header">
            <h3>Logs  - {{list.logCount}}</h3>
            <div class="filters top-filters btns">
                <div class="search">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"><path fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="20" d="M6 1a5 5 0 110 10A5 5 0 016 1zM13.002 13.002L9.535 9.535"/></svg>
                    <input [value]="searchString" [(ngModel)]="searchString" (keyup)="getFindUserLogs()"  placeholder="Search"/>
                </div>
                <div class="date">
                    <app-range-datepicker
                        [enableSelect]="true"
                        [rangeFilterList]="dateChoices"
                        [selectedRangeFilter]="selectedDateType"
                        [startDate]="startDate"
                        [endDate]="endDate"
                        (startDateChange)="getStartDate($event)"
                        (endDateChange)="getEndDate($event)"
                        (selectedRangeFilterChange)="getfilterType($event);"
                    ></app-range-datepicker>
                </div>
            </div>
            
        </div>
        <div class="reports-info track-list">
            <app-loader *ngIf="list.logLoader"></app-loader>
            <mat-table matSort *ngIf="!list.logLoader" [dataSource]="list.dataSourceForLogs" class="mat-elevation-z8">
              
                <!-- Position Column -->
                <ng-container matColumnDef="_log">
                  <mat-header-cell *matHeaderCellDef class="cellfirstelement" > Message </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="cellfirstelement" style="font-weight: 300; font-size:10px;"> {{element._log}} </mat-cell>
                </ng-container>
              
                <!-- Name Column -->
                <ng-container matColumnDef="level">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Log type </mat-header-cell>
                  <mat-cell *matCellDef="let element" style="font-weight: 300; font-size:10px;"> {{element.level}} </mat-cell>
                </ng-container>
              
                <!-- Weight Column -->
                <ng-container matColumnDef="logged_at">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Log time </mat-header-cell>
                  <mat-cell *matCellDef="let element" style="font-weight: 300; font-size:10px;"> {{element.logged_at | date: 'M/d/yy h:mm:ss a'}} </mat-cell>
                </ng-container>
              
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" class="main-row"></mat-row>
            </mat-table>
              
              
            
        </div>
        <!-- <app-pagination></app-pagination> -->
        <app-pagination
                *ngIf="logsNumberOfPages > 1 && !list.logLoader"
                [page]="currentLogsPage"
                (filtersChange)="pageLogsTrigger($event)"
                [numberOfPages]="logsNumberOfPages"
            ></app-pagination>
    </div>
</div>


