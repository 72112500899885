import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { environment } from '../../../environments/environment';
import { MenuListService } from '../../shared/services/menu-list.service';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
  selector: 'app-delete-remove-user-dialogue',
  templateUrl: './delete-remove-user-dialogue.component.html',
  styleUrls: ['./delete-remove-user-dialogue.component.scss']
})
export class DeleteRemoveUserDialogueComponent implements OnInit {
  loaderContent:boolean = true;
  currentUserGroups:any;
  constructor( private dialogRef: MatDialogRef<DeleteRemoveUserDialogueComponent>,public api: UserMenuService, public config: MenuConfigService,public list: MenuListService,public dialog: DialogService,) {
    this.list.getAdminUserGroups().subscribe(x=>{
      this.currentUserGroups = x;
    })
   }
  ngOnInit(): void {
    setTimeout(()=>{                 
      this.loaderContent = false;
    }, 500);
    console.log(this.list.currentUserGroupEditDelete)
  }
  close() {
    this.dialogRef.close();
  }
  removeUser(){
    let requestUrl = environment.apiURL + this.config.doGroups + this.currentUserGroups + '/remove-user-from-group/';
    let data = { 
      users_id: [this.list.currentUserGroupEditDelete.id]
    }
    this.api.doPUT(requestUrl,data).subscribe(x=>{
       this.list.getSingleUserGroups(this.list.currentUserGroup.id);
       this.list.singleUserForGroup(this.list.currentUserGroup.id);
      // this.list.getAllGroups(1);
      this.dialogRef.close();
    },
    (error) => {
      this.loaderContent = false;
      let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
      this.dialog.UserUploadPopupConfirmPopup(data).subscribe(changed => {
      });
      this.dialogRef.close();
      //alert(error.message)
    })
  }
  
}
