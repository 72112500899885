import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { MenuListService } from 'src/app/shared/services/menu-list.service';
import { UserMenuService } from 'src/app/shared/services/user-menu.service';
import { environment } from 'src/environments/environment';
import { SupportUserInputComponent } from '../support-user-input/support-user-input.component';

export interface PeriodicElement {
  emailId: string;
  trackCount: number;
  trackVersionCount: number;
  playlistCount: number;
  dowloadCount: number;
}

// const ELEMENT_DATA: PeriodicElement[] = [
//   {emailId: "mibe@gmail.com", trackCount: 10, trackVersionCount: 55, playlistCount: 23, dowloadCount: 100},
// ];


@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  public dataSource: any = [];
  public valueData: any = '';
  public loadingTracks: boolean = false;
 

  constructor(public dialog: DialogService, public list: MenuListService, private api: UserMenuService,) { }

  ngOnInit() {
    this. list.supportDataList =[]
  }


  addUserSupport() {
    this.dataSource = []
  
    // this.dialog.SupportUserPopup(SupportUserInputComponent);
    this.dialog.SupportUserPopup('').subscribe(changed => {
      this.loadingTracks = true;
      if (changed) {
        if (this.list.MissingData.length > 0) {
          let data = this.list.errorMessage;
          this.dialog.UserUploadPopupConfirmPopup(data).subscribe(changed => {
            if (changed) {
              this.dataSource = this.list.supportDataList;
            }
          })
        }
        else {
          this.dataSource = this.list.supportDataList;
        }
        if (this.list.supportDataList.length > 0) {
          for (let x of this.list.supportDataList) {
            if (this.list.supportType === 0) {
              if (this.valueData == '') {
                this.valueData = (x.email)
              }
              else {
                this.valueData = this.valueData  + ','+ (x.email)
              }
            }
            if (this.list.supportType === 1) {
              if (this.valueData == '') {
                this.valueData = (x.track_title)
              }
              else {
                this.valueData = this.valueData + ','+ (x.track_title)
              }
            }
            if (this.list.supportType === 2) {
              if (this.valueData == '') {
                this.valueData = (x.version_title)
              }
              else {
                this.valueData = this.valueData  + ','+ (x.version_title)
              }
            }
          }
        }
        this.loadingTracks = false;

      }
    });
  }

  migrateEmail() {
    this.loadingTracks = true;
    let dataValue
    let data = "Migration started in the backend. The status of migration will be sent to the respective user's email";
    this.dialog.UserUploadPopupConfirmPopup(data).subscribe(changed => {
      if (changed) {
        // var lastChar = this.valueData[this.valueData.length - 1];
        // if (lastChar === ',') {
        //   this.valueData = this.valueData
        // }
        // else {
        //   this.valueData = this.valueData + ','
        // }
        if (this.list.supportType === 0) {
          dataValue = {
            'email': this.valueData
          }
        }
        if (this.list.supportType === 1) {
          dataValue = {
            'track_title': this.valueData
          }
        }
        if (this.list.supportType === 2) {
          dataValue = {
            'version_title': this.valueData
          }
        }
        this.api.doPUT(environment.apiURL + '/api/v1/support/page/', dataValue).subscribe(t => {
          if (t['message']) {
            this.loadingTracks = false;
            let data = 'Migration completed successfully!!';
            this.dialog.UserUploadPopupConfirmPopup(data).subscribe(changed => {
            })
          }
        },
          (error) => {
            let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
            this.dialog.UserUploadPopupConfirmPopup(data).subscribe(changed => {
              this.loadingTracks = false;
              // this.list.supportDataList = [];
              // this.list.MissingData = [];
              // this.list.supportType = 0;
            });

          })
      }
    })
  }

  displayedColumns: any[] = ['emailId', 'trackCount', 'trackVersionCount', 'playlistCount', 'dowloadCount'];
  displayedColumns1: any[] = ['emailId', 'trackCount', 'trackVersionCount'];
  displayedColumns2: any[] = ['emailId', 'trackVersionCount', 'playlistCount'];
}
