import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../environments/environment';
import { Mood } from '../models/mood.model';
import { APIListResponse } from '../models/apiresponse.model';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { EmailTemplate } from '../models/email-template.model';


export interface GetEmailTemplateOptions {
  type?: 'CLIENT'|'ARTIST'|'BOTH';
  limit?: number;
  offset?: number;
  searchString?: string;
  page?: number;
}

@Injectable({
  providedIn: 'root'
})
export class EmailTemplateService {

  templatetype: any = 'new';
  usertype = 'CLIENT';
  
  constructor(
    private http: HttpClient,
    private _platform: Platform,
    private _transferState: TransferState
  ) {
  }


  getAllTemplates(options:GetEmailTemplateOptions): Observable<APIListResponse<EmailTemplate>> {
    let params = {};
    if (options.type != null) {
      params['type'] = options.type;
    }
    if (options.limit) {
      params['limit'] = options.limit;
    }
    if (options.offset) {
      params['offset'] = options.offset;
    }
    if (options.searchString) {
      params['search'] = options.searchString;
    }
    if (options.page) {
      params['page'] = options.page;
    }
    return this.getTemplates(params);
  }

  getTemplate(templateId: number): Observable<EmailTemplate> {
    // Check if moods are cached from server
    const TEMPLATE_KEY = makeStateKey<Object>('template-' + templateId);
    if (this._transferState.hasKey(TEMPLATE_KEY)) {
      const template = this._transferState.get<Object>(TEMPLATE_KEY, null);
      this._transferState.remove(TEMPLATE_KEY);
      return of(new EmailTemplate(template));
    }
    let headers = new HttpHeaders();
    return this.http.get<Object>(
      environment.apiURL + '/api/v1/admin/emailtemp/' + templateId + '/',
      {
        headers: headers
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the mood
        if (!this._platform.isBrowser) {
          this._transferState.set(TEMPLATE_KEY, res);
        }
      }),
      map((res) => new EmailTemplate(res))
    );
  }

  createTemplate(template: EmailTemplate): Observable<EmailTemplate> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiURL + '/api/v1/admin/emailtemp/',
      template.toJSON(),
      {
        headers: headers
      }
    ).pipe(map((res) => new EmailTemplate(res)));
  }
  duplicateTemplate(template: EmailTemplate): Observable<EmailTemplate> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiURL + '/api/v1/admin/emailtemp/'+template.id+'/duplicate/',
      template.toJSON(),
      {
        headers: headers
      }
    ).pipe(map((res) => new EmailTemplate(res)));
  }

  updateTemplate(template: EmailTemplate): Observable<EmailTemplate> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.put<any>(
      environment.apiURL + '/api/v1/admin/emailtemp/',
      template.toJSON(),
      {
        headers: headers
      }
    ).pipe(map((res) => new EmailTemplate(res)));
  }

  deleteTemplate(template:number): Observable<void> {
    let headers = new HttpHeaders();
    return this.http.delete<any>(
      `${environment.apiURL}/api/v1/admin/emailtemp/${template}/`,
      {
        headers: headers
      }
    );
  }

  private getTemplates(params: {[param: string]: string | string[]}): Observable<APIListResponse<EmailTemplate>> {
    let p = new HttpParams({ fromObject: params });
    // Check if moods are cached from server
    const TEMPLATES_KEY = makeStateKey<APIListResponse<Object>>('templates-' + p.toString());
    if (this._transferState.hasKey(TEMPLATES_KEY)) {
      const templates = this._transferState.get<APIListResponse<Object>>(TEMPLATES_KEY, null);
      this._transferState.remove(TEMPLATES_KEY);
      return of({
        next: templates.next,
        previous: templates.previous,
        count: templates.count,
        results: templates.results.map((g) => new EmailTemplate(g))
      });
    }
    let headers = new HttpHeaders();
    return this.http.get<APIListResponse>(
      environment.apiURL + '/api/v1/admin/emailtemp/',
      {
        headers: headers,
        params: p
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the moods
        if (!this._platform.isBrowser) {
          this._transferState.set(TEMPLATES_KEY, res);
        }
      }),
      map((res) => {
        return {
          next: res.next,
          previous: res.previous,
          count: res.count,
          results: res.results.map((g) => new EmailTemplate(g))
        }
      })
    );
  }
}
