import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MenuListService } from '../../shared/services/menu-list.service';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { UserMenuService } from '../../shared/services/user-menu.service';
// import { environment } from '../../../environments/environment.prod';
import { environment } from '../../../environments/environment';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
  selector: 'app-thesaurus-edit-dialogue',
  templateUrl: './thesaurus-edit-dialogue.component.html',
  styleUrls: ['./thesaurus-edit-dialogue.component.scss']
})
export class ThesaurusEditDialogueComponent implements OnInit {
  Thesaurus: string = '';
  
 
  constructor(private dialogRef: MatDialogRef<ThesaurusEditDialogueComponent>,
    public list: MenuListService,
    private config: MenuConfigService,
    public api: UserMenuService,
    public _DialogService:DialogService
  ) { }

  ngOnInit(): void {
  }
  close() {
    this.dialogRef.close();
  }
  save(){
    let data ={
      name: this.Thesaurus
    }
    let requestUrl = environment.apiURL + this.config.doThesaurus + this.list.currThesaurus.id + '/'
    this.api.doPUT(requestUrl,data).subscribe(x=>{
      this.close()
      this.list.getAllthesaurus();
    
    },
    error =>{
      //alert(error.message)
      // alert('This Thesaurus term already exists.')
      let data = error.error?.error ? error.error?.error : (error.error?.detail ?error.error?.detail : (error.error?.details ? error.error?.details:``) )
        this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
        });
        this.close()
    }
  )
  }
}
