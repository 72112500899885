<div class="user-mgmt">
    <div class="header">
        <ul class="navigation">
            <li *ngIf="_userService?.currentUser?.is_cms_enabled === true"  class="nav-item" [ngClass]="{'nav-item-active': isActive('cms')}">
                <a class="nav-link" [routerLink]="['/menu', 'cms']">cms</a></li>
            <li class="nav-item" [ngClass]="{'nav-item-active': isActive('data')}">
                <a class="nav-link" [routerLink]="['/menu', 'data']">Data</a></li>
            <li class="nav-item" [ngClass]="{'nav-item-active': isActive('log')}">
                <a class="nav-link" [routerLink]="['/menu', 'log']">LOG</a></li>
            <li class="nav-item" [ngClass]="{'nav-item-active': isActive('reports')}">
                <a class="nav-link" [routerLink]="['/menu', 'reports']">REPORTS</a>
            </li>
            <li class="nav-item" [ngClass]="{'nav-item-active': isActive('tos')}">
                <a class="nav-link" [routerLink]="['/menu', 'tos']">TOS</a></li>
            <li class="nav-item" [ngClass]="{'nav-item-active': isActive('user-groups')}">
                <a class="nav-link" [routerLink]="['/menu', 'user-groups']">ADMIN GROUPS</a></li>
            <li class="nav-item" [ngClass]="{'nav-item-active': isActive('users')}">
                <a class="nav-link" [routerLink]="['/menu', 'users']">USERS</a></li>
            <li class="nav-item" [ngClass]="{'nav-item-active': isActive('profile')}">
                <a class="nav-link" [routerLink]="['/menu', 'profile']">PROFILE</a>
            </li>
            <li class="nav-item" [ngClass]="{'nav-item-active': isActive('supportUser')}" *ngIf="false">
                <a class="nav-link" [routerLink]="['/menu', 'supportUser']">SUPPORT</a>
            </li>
        </ul>
    </div>
    <div class="body">
        <app-cms *ngIf="isActive('cms')"></app-cms>
        <app-data *ngIf="isActive('data')"></app-data>
        <app-log *ngIf="isActive('log')"></app-log>
        <app-reports *ngIf="isActive('reports')"></app-reports>
        <app-tos *ngIf="isActive('tos')"></app-tos>
        <app-user-groups *ngIf="isActive('user-groups')"></app-user-groups>
        <app-menu-user *ngIf="isActive('users')"></app-menu-user>
        <app-profile *ngIf="isActive('profile')"></app-profile>
        <app-support *ngIf="isActive('supportUser')"></app-support>
    </div>
</div>