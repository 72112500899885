import {
  BrowserModule,
  BrowserTransferStateModule,
  HammerModule,
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG
} from '@angular/platform-browser';
import { NgModule, Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from "@angular/common";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { SharedModule } from './shared/shared.module';

import { environment } from '../environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { HomeComponent } from './home/home.component';
import { PlaylistComponent } from './playlist/playlist.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { UserMgmtComponent } from './user-mgmt/user-mgmt.component';
import { UsersComponent } from './user-mgmt/users/users.component';
import { GroupsComponent } from './user-mgmt/groups/groups.component';
import { TemplatesComponent } from './user-mgmt/templates/templates.component';
import { MenuComponent } from './menu_mgt/menu/menu.component';
import { DataComponent } from './menu_mgt/data/data.component';
import { LogComponent } from './menu_mgt/log/log.component';
import { ReportsComponent } from './menu_mgt/reports/reports.component';
import { TosComponent } from './menu_mgt/tos/tos.component';
import { UserGroupsComponent } from './menu_mgt/user-groups/user-groups.component';
import { ProfileComponent } from './menu_mgt/profile/profile.component';
import { MenuUserComponent } from './menu_mgt/menu-user/menu-user.component';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CdkTableModule } from '@angular/cdk/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatSliderModule } from '@angular/material/slider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { UserFormComponent } from './menu_mgt/user-form/user-form.component';
import { UserEditFormComponent } from './menu_mgt/user-edit-form/user-edit-form.component';
import { DeleteUsergroupsDialogueComponent } from './menu_mgt/delete-usergroups-dialogue/delete-usergroups-dialogue.component';
import { DeleteRemoveUserDialogueComponent } from './menu_mgt/delete-remove-user-dialogue/delete-remove-user-dialogue.component';
import { DeleteEditUserDialogueComponent } from './menu_mgt/delete-edit-user-dialogue/delete-edit-user-dialogue.component';
import { DeleteEditGroupPermissionDialogueComponent } from './menu_mgt/delete-edit-group-permission-dialogue/delete-edit-group-permission-dialogue.component';
import { DeleteManageUserDialogueComponent } from './menu_mgt/delete-manage-user-dialogue/delete-manage-user-dialogue.component';
import { DeleteNewGroupDialogueComponent } from './menu_mgt/delete-new-group-dialogue/delete-new-group-dialogue.component';
import { DuplicateNewGroupDialogueComponent } from './menu_mgt/duplicate-new-group-dialogue/duplicate-new-group-dialogue.component';
import { ReportsDialogueComponent } from './menu_mgt/reports-dialogue/reports-dialogue.component';
import { EditGenreDialogueComponent } from './menu_mgt/edit-genre-dialogue/edit-genre-dialogue.component';
import { EditSubgenreDialogueComponent } from './menu_mgt/edit-subgenre-dialogue/edit-subgenre-dialogue.component';
import { AddGenreDialogueComponent } from './menu_mgt/add-genre-dialogue/add-genre-dialogue.component';
import { AddSubgenreDialogueComponent } from './menu_mgt/add-subgenre-dialogue/add-subgenre-dialogue.component';
import { RemoveSubgenreDialogueComponent } from './menu_mgt/remove-subgenre-dialogue/remove-subgenre-dialogue.component';
import { RemoveGenreDialogueComponent } from './menu_mgt/remove-genre-dialogue/remove-genre-dialogue.component';
import { RemoveMoodsDialogueComponent } from './menu_mgt/remove-moods-dialogue/remove-moods-dialogue.component';
import { AddEditMoodsDialogueComponent } from './menu_mgt/add-edit-moods-dialogue/add-edit-moods-dialogue.component';
import { AddProDialogueComponent } from './menu_mgt/add-pro-dialogue/add-pro-dialogue.component';
import { EditProDialogueComponent } from './menu_mgt/edit-pro-dialogue/edit-pro-dialogue.component';
import { CONTAINER_DATA } from './shared/services/slider-dialog.service';
import { DeleteUserDialogueComponent } from './menu_mgt/delete-user-dialogue/delete-user-dialogue.component';
import { ThesaurusComponent } from './menu_mgt/thesaurus/thesaurus.component';
import { ThesaurusDeleteDialogueComponent } from './menu_mgt/thesaurus-delete-dialogue/thesaurus-delete-dialogue.component';
import { ThesaurusEditDialogueComponent } from './menu_mgt/thesaurus-edit-dialogue/thesaurus-edit-dialogue.component';
import { CreateAccountComponent } from './composer/create-account/create-account.component';
import { ProComponent } from './composer/pro/pro.component';
import { CoWritersComponent } from './composer/co-writers/co-writers.component';
import { ComposerRootComponent } from './composer/composer-root/composer-root.component';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { ErrorInterceptor } from '../app/interceptors/error.interceptor';
import { MyUploadComponent } from './composer/my-upload/my-upload.component';
import { UploadTrackComponent } from './composer/upload-track/upload-track.component';
import { UploadTrackPopComponent } from './composer/upload-track-pop/upload-track-pop.component';
import { MyTracksComponent } from './composer/my-tracks/my-tracks.component';
import { CreateTracksComponent } from './composer/create-tracks/create-tracks.component';
import { CreateTrackVersionComponent } from './composer/create-track-version/create-track-version.component';
import { ReplaceTrackVersionComponent } from './composer/replace-track-version/replace-track-version.component';
import { RemoveTrackDialogueComponent } from './composer/remove-track/remove-track-dialogue.component';
import { PaymentComponent } from './composer/payment/payment.component';
import { TrackDetailsComponent } from './composer/track-details/track-details.component';
import { TrackDetailsComposerComponent } from './composer/track-details-composer/track-details-composer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import {FilterPipe} from './shared/pipes/filter.pipe'
import { NgWaveformModule } from 'ng-waveform';
import { MinuteSecondsPipe, PlaylistwaveformWaveComponent } from './playlistwaveform-wave/playlistwaveform-wave.component';
import { SafePipe, TosCompComponent } from './composer/tos/tos.component';
import { HomeComposerComponent } from './composer/home-composer/home-composer.component';
import { ComposerAlertComponent } from './composer-alert/composer-alert.component';
import { CmsComponent } from './menu_mgt/cms/cms.component';
import { PuplishcmsComponent } from './menu_mgt/puplishcms/puplishcms.component';
import { AddplaylistcmsComponent } from './menu_mgt/addplaylistcms/addplaylistcms.component';
import { CmsaddplaylistfeatureComponent } from './menu_mgt/cmsaddplaylistfeature/cmsaddplaylistfeature.component';
import { ConfirmPopupComponent } from './composer/confirm-popup/confirm-popup.component';
import { CmsaddgenreComponent } from './menu_mgt/cmsaddgenre/cmsaddgenre.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SupportUserInputComponent } from './menu_mgt/support-user-input/support-user-input.component';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { SupportComponent } from './menu_mgt/support/support.component';
import { MatSelectFilterModule } from 'mat-select-filter';
import {MatSelectInfiniteScrollModule} from 'ng-mat-select-infinite-scroll';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

/** Import Alyle UI */
import {
  LyTheme2,
  StyleRenderer,
  LY_THEME,
  LY_THEME_NAME,
  LyHammerGestureConfig
} from '@alyle/ui';

/** Import the component modules */
import { LyButtonModule } from '@alyle/ui/button';
import { LyToolbarModule } from '@alyle/ui/toolbar';
import { LyImageCropperModule } from '@alyle/ui/image-cropper';

/** Import themes */
import { MinimaLight, MinimaDark } from '@alyle/ui/themes/minima';

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  overrides = <any> {
      'pinch': { enable: false },
      'rotate': { enable: false },
      'tap': { enable: true }
  }
}
const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResetPasswordComponent,
    HomeComponent,
    PlaylistComponent,
    UserMgmtComponent,
    UsersComponent,
    GroupsComponent,
    TemplatesComponent,
    MenuComponent,
    DataComponent,
    LogComponent,
    ReportsComponent,
    TosComponent,
    UserGroupsComponent,
    ProfileComponent,
    MenuUserComponent,
    UserFormComponent,
    UserEditFormComponent,
    DeleteUsergroupsDialogueComponent,
    DeleteRemoveUserDialogueComponent,
    DeleteEditUserDialogueComponent,
    DeleteEditGroupPermissionDialogueComponent,
    DeleteManageUserDialogueComponent,
    DeleteNewGroupDialogueComponent,
    DuplicateNewGroupDialogueComponent,
    ReportsDialogueComponent,
    EditGenreDialogueComponent,
    EditSubgenreDialogueComponent,
    AddGenreDialogueComponent,
    AddSubgenreDialogueComponent,
    RemoveSubgenreDialogueComponent,
    RemoveGenreDialogueComponent,
    RemoveMoodsDialogueComponent,
    AddEditMoodsDialogueComponent,
    AddProDialogueComponent,
    EditProDialogueComponent,
    DeleteUserDialogueComponent,
    ThesaurusComponent,
    ThesaurusDeleteDialogueComponent,
    ThesaurusEditDialogueComponent,
    CreateAccountComponent,
    ProComponent,
    CoWritersComponent,
    ComposerRootComponent,
    MyUploadComponent,
    UploadTrackComponent,
    UploadTrackPopComponent,
    MyTracksComponent,
    CreateTracksComponent,
    CreateTrackVersionComponent,
    ReplaceTrackVersionComponent,
    RemoveTrackDialogueComponent,
    PaymentComponent,
    TrackDetailsComponent,
    TrackDetailsComposerComponent,
    DashboardComponent,
    FilterPipe,
    PlaylistwaveformWaveComponent,
    MinuteSecondsPipe,
    TosCompComponent,
    HomeComposerComponent,
    SafePipe,
    ComposerAlertComponent,
    CmsComponent,
    PuplishcmsComponent,
    AddplaylistcmsComponent,
    CmsaddplaylistfeatureComponent,
    ConfirmPopupComponent,
    CmsaddgenreComponent,
    SupportUserInputComponent,
    SupportComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    BrowserTransferStateModule,
    AppRoutingModule,
    MatFormFieldModule ,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    SharedModule,
    NgWaveformModule,
    HttpClientModule,
    DragDropModule,
    HammerModule,
    CommonModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSliderModule,
    MatCardModule,
    MatOptionModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDividerModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatExpansionModule,
    MatButtonModule,
    RouterModule,
    MatMenuModule,
    NgxMatSelectSearchModule,
    MatProgressBarModule,
    MatTableModule,
    MatSortModule,
    MatTooltipModule,
    DragDropModule,
    CdkTableModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    OverlayModule,
    NgxMaskModule.forRoot(maskConfig),
    ImageCropperModule,
    MatSelectFilterModule,
    MatSelectInfiniteScrollModule,
    CKEditorModule,
    LyButtonModule,
    LyToolbarModule,
    LyImageCropperModule,

  ],
  exports: [SharedModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig,
    },
    { provide: CONTAINER_DATA, useValue: {} },
    DatePipe,PlaylistComponent,
    [ LyTheme2 ],
    [ StyleRenderer ],
    // Theme that will be applied to this module
    { provide: LY_THEME_NAME, useValue: 'minima-light' },
    { provide: LY_THEME, useClass: MinimaLight, multi: true }, // name: `minima-light`
    { provide: LY_THEME, useClass: MinimaDark, multi: true }, // name: `minima-dark`
    // Gestures
    { provide: HAMMER_GESTURE_CONFIG, useClass: LyHammerGestureConfig } // Required for <ly-carousel>
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA
  ]
})
export class AppModule {}
