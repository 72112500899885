import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { MenuListService } from '../../shared/services/menu-list.service';
import { environment } from '../../../environments/environment';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
  selector: 'app-add-subgenre-dialogue',
  templateUrl: './add-subgenre-dialogue.component.html',
  styleUrls: ['./add-subgenre-dialogue.component.scss']
})
export class AddSubgenreDialogueComponent implements OnInit {
  imageChangedEvent: any = '';
  imageUrl = '/assets/img/placeholder-image.png';
  croppedImage = '/assets/img/placeholder-image.png';
  finalImg = '/assets/img/placeholder-image.png';
  show = false;
  newFinalImg:any ='';
  showCropper = false;
  currentCropImage = '/assets/img/placeholder-image.png';
  crop() {
    this.show = false;
    this.showCropper = false;
    this.finalImg = this.croppedImage;
  }
//  toggle(){
// this.show = !this.show;
//   }
toggleclose(){
  this.show = !this.show;

  this.imageUrl = '/assets/img/placeholder-image.png';
  this.finalImg = '/assets/img/placeholder-image.png';
}
imageCropped(event: ImageCroppedEvent) {
  this.croppedImage = event.base64;
  this.newFinalImg = event;
  this.currentCropImage = this.croppedImage
  const fileToReturn = this.base64ToFile(
    event.base64,
    this.image,
  )
  console.log(fileToReturn)
  this.newFinalImg = fileToReturn;
}
base64ToFile(data, filename) {
const arr = data.split(',');
const mime = arr[0].match(/:(.*?);/)[1];
const bstr = atob(arr[1]);
let n = bstr.length;
let u8arr = new Uint8Array(n);
while (n--) {
  u8arr[n] = bstr.charCodeAt(n);
}
return new File([u8arr], filename, { type: mime });
}
@ViewChild('myFileInput') myFileInput;

onFileChange(event) { 
  this.myFileInput.nativeElement.value = '';
}
  imageLoaded() {
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }
  cropData() {
    this.showCropper = true;
    this.show = false;
    this.currentCropImage = this.currentCropImage
  }
  resetCrop() {
    this.showCropper = false;
    this.show = true;
  }
fileToUpload:any
  genreImg = ''
  image = ''

handleFileInput(file: FileList) {
  this.show = true;
  this.fileToUpload = file.item(0);
  this.imageChangedEvent = event;
  this.finalImg = '/assets/img/placeholder-image.png';
  //Show image preview
  this.genreImg = this.fileToUpload;
  this.image = this.fileToUpload.name
  let reader = new FileReader();
  reader.onload = (event: any) => {
    this.imageUrl = event.target.result;
  }
  reader.readAsDataURL(this.fileToUpload);
}
  toppings = new FormControl();
  parent:string = ''
  subGnereName: string = ""
  toppingList: string[] = ['Sub Admin Groups', 'Test1', 'Test2', 'Test3', 'Test4'];
  loader: boolean = true;
  gnereName: string = '';
  
  gnereCheck:any='3'
  constructor(private dialogRef: MatDialogRef<AddSubgenreDialogueComponent>,
    public list: MenuListService,
    public config: MenuConfigService,
    public api: UserMenuService,public _DialogService:DialogService
  ) { 
    this.list.getAllParents()
  }

  ngOnInit(): void {
    setTimeout(()=>{  
      this.loader = false;
      this.parent  = this.list.currGnere.id
    }, 500);
  }
  close() {
    this.dialogRef.close();
  }
  create(){
    
    if(this.gnereCheck == ''){
      //alert('Enter Gnere Type')
      return
    } 
    const formData:any = new FormData();
    if(this.gnereCheck== '2'){
      console.log(this.gnereCheck)
      formData.append("name", this.gnereName);
     // formData.append("file", this.genreImg);
      formData.append("marquee_image", this.genreImg);
      formData.append("thumb_image", this.newFinalImg);
      let data = {
        name: this.gnereName
      }
      let requestUrl = environment.apiURL + this.config.doGenres;

      this.api.doPOST(requestUrl,formData).subscribe(x=>{
        this.close()
        this.list.getAllGenres();
      },error =>{
        let data = error.error?.error ? error.error?.error : (error.error?.detail ?error.error?.detail : (error.error?.details ? error.error?.details:``) )
        this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
        });
        this.close()
        //alert(error.message)
      }
    )
  }
  if(this.gnereCheck== '3'){
    if(this.parent == ''){
      //alert('Enter Select parent')
      return
    } 
    console.log(this.gnereCheck)
    formData.append("name", this.gnereName);
    formData.append("parent",  this.parent);
   // formData.append("file", this.genreImg);
    formData.append("marquee_image", this.genreImg);
    formData.append("thumb_image", this.genreImg);
    // let data = {
    //   "name": this.gnereName,
    //   "parent": this.parent

    // }
    let requestUrl = environment.apiURL + this.config.doGenres;

    this.api.doPOST(requestUrl,formData).subscribe(x=>{
      this.close();
      this.list.getAllGenres();
    },error =>{
      let data = error.error?.error ? error.error?.error : (error.error?.detail ?error.error?.detail : (error.error?.details ? error.error?.details:``) )
      this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
      });
      this.close()
      //alert(error.message)
    })
  
  }
  }

}
