import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from '../models/user.model';
import { DialogService } from '../services/dialog.service';
import { UserService } from '../services/user.service';

export interface DialogData {
  user: User;
  isArtist: boolean
}
@Component({
  selector: 'app-delete-client-dialog',
  templateUrl: './delete-client-dialog.component.html',
  styleUrls: ['./delete-client-dialog.component.scss']
})
export class DeleteClientDialogComponent implements OnInit {

  loading: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<DeleteClientDialogComponent>,
    private _userService: UserService,
    public _DialogService:DialogService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close(false);
  }

  /**
   * Don't actually delete users
   * just remove them from the list they were "deleted" from
   * and if they are no longer a part of either list deactivate them
   * this will allow us to "recover" users deleted by mistake
   * */
  deleteUser() {
    this.loading = true;
    if (this.data.isArtist) {
      this.data.user.is_artist = false;
      if (!this.data.user.is_client) this.data.user.is_active = false;
    } else {
      this.data.user.is_client = false
      if (!this.data.user.is_artist) this.data.user.is_active = false;
    }
    this._userService.deleteUser(this.data.user).subscribe(()=> {
      this.loading = false;
      this.dialogRef.close(true);
      location.reload();
    }, (error) => {
      // alert(error.error.message)
      let data = error.error?.error ? error.error?.error : (error.error?.detail ?error.error?.detail : (error.error?.details ? error.error?.details:(error?.error.message ? error?.error.message:'')) )
      this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
      });
      // this.close()
      this.dialogRef.close(true);
      //alert("An error ocurred while deleting the user. Check your connections and try again");
    });
  }

}
