import { Component, OnInit } from '@angular/core';

interface Food {
  value: string;
  viewValue: string;
}


@Component({
  selector: 'app-upload-track',
  templateUrl: './upload-track.component.html',
  styleUrls: ['./upload-track.component.scss']
})
export class UploadTrackComponent implements OnInit {
  foods: Food[] = [
    {value: 'steak-0', viewValue: 'Pending'},
    {value: 'pizza-1', viewValue: 'Pending Approval'},
    {value: 'tacos-2', viewValue: 'Denied'},
    {value: 'app-3', viewValue: 'Approved'},
    {value: 'dis-4', viewValue: 'Disabled'}
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
