import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { UserService } from '../shared/services/user.service';
import { Router } from "@angular/router";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(public inj: Injector, private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const userService = this.inj.get(UserService);
        return next.handle(request).pipe(catchError(err => {

            //if unathorized, logout and redirect to login page
            if ([401, 403].includes(err.status)) {
                if (userService.getCurrentUser()) {
                    userService.logout_V2().subscribe(res => {
                        userService._currentUser = null;
                        userService._currentUserSubject.next(userService._currentUser);
                  
                        userService.currentUser = null;
                        localStorage.setItem("token", null);
                        localStorage.setItem("userdata", null);
                        this.router.navigate(['/login']);
                        window.location.reload();
                      }
                      );
                }
            }
            else{
                return next.handle(request);
            }
        }))
    }
}