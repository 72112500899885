import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../environments/environment';
import { Mood } from '../models/mood.model';
import { APIListResponse } from '../models/apiresponse.model';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class MoodService {

  currentlyBrowsingMood: Observable<Mood>;
  moodsList:any=[]
  private _currentlyBrowsingMoodSubject: BehaviorSubject<Mood> = new BehaviorSubject<Mood>(null);

  constructor(
    private http: HttpClient,
    private _platform: Platform,
    private _transferState: TransferState
  ) {
    this.currentlyBrowsingMood = this._currentlyBrowsingMoodSubject.asObservable();
  }

  updateCurrentlyBrowsingMood(m: Mood) {
    this._currentlyBrowsingMoodSubject.next(m);
  }

  getAvailableMoodsForGenre(genreId: number): Observable<Mood[]> {
    return this.getMoods({'limit': '250', 'genre': `${genreId}`});
  }

  getAllMoods(): Observable<Mood[]> {
    return this.getMoods({'limit': '250'});
  }

  getMood(moodId: number): Observable<Mood> {
    // Check if moods are cached from server
    const MOOD_KEY = makeStateKey<Object>('mood-' + moodId);
    if (this._transferState.hasKey(MOOD_KEY)) {
      const mood = this._transferState.get<Object>(MOOD_KEY, null);
      this._transferState.remove(MOOD_KEY);
      return of(new Mood(mood));
    }
    let headers = new HttpHeaders();
    return this.http.get<Object>(
      environment.apiURL + '/api/v1/admin/moods/',
      {
        headers: headers
      }
    ).pipe(
      tap(res => {
        this.moodsList = res;
        // If we're on the server cache the mood
        if (!this._platform.isBrowser) {
          this._transferState.set(MOOD_KEY, res);
        }
      }),
      map((res) => new Mood(res))
    );
  }

  private getMoods(params: {[param: string]: string | string[]}): Observable<Mood[]> {
    let p = new HttpParams({ fromObject: params });
    // Check if moods are cached from server
    const MOODS_KEY = makeStateKey<APIListResponse<Object>>('moods-' + p.toString());
    if (this._transferState.hasKey(MOODS_KEY)) {
      const moods = this._transferState.get<APIListResponse<Object>>(MOODS_KEY, null);
      this._transferState.remove(MOODS_KEY);
      return of(moods.results.map((s) => new Mood(s)));
    }
    let headers = new HttpHeaders();
    return this.http.get<APIListResponse>(
      environment.apiURL + '/api/v1/track/moods-dropdown/',
      {
        headers: headers,
        params: p
      }
    ).pipe(
      tap(res => {
        this.moodsList = res;
        // If we're on the server cache the moods
        if (!this._platform.isBrowser) {
          this._transferState.set(MOODS_KEY, res);
        }
      }),
      map((res: any) => res.map((s) => new Mood(s)))
    );
  }
}
