import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-replace-track-version',
  templateUrl: './replace-track-version.component.html',
  styleUrls: ['./replace-track-version.component.scss']
})
export class ReplaceTrackVersionComponent implements OnInit {
  panelOpenState = false;
  inspanelOpenState = false;
  tvnpanelOpenState = false;
  sonpanelOpenState = false;
  vocpanelOpenState = false;
  lyricpanelOpenState = false;

  constructor() { }

  ngOnInit(): void {
  }

}
