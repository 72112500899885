<div class="edit-track " style="width: 100%;"  *ngIf="!loading">

<app-track-preview  *ngIf="userDetail > 0 && detail > 0" class="music-player" [track]="track" [version]="version" [ngClass]="{hide: !track || !version}"></app-track-preview>

<!--Track detail-->
<app-track-new-detail  *ngIf="userDetail > 0 && detail > 0" [(track)]="track" [(version)]="version"></app-track-new-detail>

<!--Manage co writer-->
<app-manage-cowriters *ngIf="userDetail > 0 && detail > 0" [(composers)]="composers"></app-manage-cowriters>

<!--Track status-->
<app-track-status *ngIf="userDetail > 0 && detail > 0" [(track)]="track" [(version)]="version"></app-track-status>

<div class="separator-line"></div>

 <!--Track description-->
<app-new-track-description *ngIf="userDetail > 0 && detail > 0" [(track)]="track" [(version)]="version"></app-new-track-description>

<button *ngIf="userDetail > 0 && detail > 0"  [disabled]="track.isValid === true" class ="save-version-btn" mat-button (click)="onSave()"> SAVE TRACK</button>
</div>
<div class="loading-overlay" *ngIf="userDetail == 0 && detail == 0 && loading">
  <app-loader></app-loader>
</div>