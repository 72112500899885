// Robert: 5/16/24
// This service was created for the bpm tracking tool

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BpmService {

  //Determines if the timer is running or not
  public timerRunning = false;

  //Tracks the duration set for the timer
  private timerDuration = 300;

  //Tracks how many seconds are left on the timer
  private secondsLeft = this.timerDuration - 1; //subtract 1 to avoid returning 'infinity'

  //Count clicks
  private clickCounter = 0;

  //Used to determine if the user has stopped clicking
  private lastClickCount = 0;
  private intervalsWithoutClick = 0;

  constructor() { }


  /**
   * Called on first click of BPM button - initiates the BPM detecting process
   * @param duration 
   */
  public startTimer(duration: number = this.timerDuration /**300 seconds default */) {

    //reset clickCounter just in case
    this.clickCounter = 0;

    this.timerRunning = true;

    this.timerDuration = duration;

    let timeLeft = duration; // Use let instead of var to keep variable within block scope
    const that = this; // Store reference to outer this context

    //This is the timer which is used to determine how many clicks per minute are being made
    const timerId = setInterval(() => {
      if (timeLeft == -1) {
        clearTimeout(timerId);
        that.timerRunning = false; // Use that to reference outer this context
        that.secondsLeft = this.timerDuration - 1; //subtract 1 to avoid returning 'infinity'
        that.clickCounter = 0; // Use that to reference outer this context

      } else {
        timeLeft--;
        if (timeLeft > 0) {
          that.secondsLeft = timeLeft; // Use that to reference outer this context
        }
      }
    }, 1000);

    //This timer is used to determine if the user has stopped clicking
    //If more than 3 seconds have passed since a click, we'll kill the process
    const timer2Id = setInterval(() => {
      if(this.lastClickCount == this.clickCounter){
        this.intervalsWithoutClick++;

        if(this.intervalsWithoutClick >= 3){
          //it's been too long since a click, kill the process

          //kill duration timer
          clearTimeout(timerId)
          that.timerRunning = false; // Use that to reference outer this context
          that.secondsLeft = this.timerDuration - 1; //subtract 1 to avoid returning 'infinity'
          that.clickCounter = 0; // Use that to reference outer this context
          that.lastClickCount = 0;
          that.intervalsWithoutClick = 0;
          //now kill this interval
          clearTimeout(timer2Id)
        }
      }
      else{
        this.lastClickCount = this.clickCounter;
        this.intervalsWithoutClick = 0;
      }
    }, 1000)

    return this.countClick();
  }

  /**
   * Called when user clicks BPM button everytime after first click
   * @returns 
   */
  public countClick() {
    this.clickCounter++;

    if (this.timerRunning) {
      var secondsPassed = this.timerDuration - this.secondsLeft;

      console.log(`click count ${this.clickCounter}`);
      console.log(`seconds passed ${secondsPassed}`);
      console.log(`timer duration ${this.timerDuration}`);
      console.log(`seconds left ${this.secondsLeft}`);

      return (this.clickCounter / (secondsPassed / 60));
    }
  }

  // public getBPM() {

  //   if (this.timerRunning) {
  //     var secondsPassed = this.timerDuration - this.secondsLeft;

  //     var bpm = (this.clickCounter / (secondsPassed / 60));

  //     this.secondsLeft = 0; // Use that to reference outer this context
  //     this.clickCounter = 0; // Use that to reference outer this context

  //     return bpm.toFixed(2);
  //   }

  // }
}
