<div class="main">
  <div class="sidebar">
    <div class="top-holder">
      <div style="padding: 13% 20px;"> <strong>Manage Content</strong></div>

      <div class="btns-cate filter-container">
        <mat-form-field appearance="fill" floatLabel="always" style="padding: 9% 20px;">
          <mat-label>Section</mat-label>

          <mat-select class="custom-backdrop-class" panelClass="filter-select filter-genre"
            [disableOptionCentering]="true" name="genreFilter" placeholder="Homepage">
            <span class="select-title">Select a Genre</span>
            <mat-option [value]="false">Homepage</mat-option>

          </mat-select>
        </mat-form-field>
        <div class="tabfield">
          <button style="border-top: 1px solid #333;" (click)="FeaturedTab()"
            [ngClass]="{'btnActive': tabFlag === '0'}">Featured </button>
          <button (click)="GenresTab()" [ngClass]="{'btnActive': tabFlag === '1'}">Popular Genres </button>
          <button (click)="TracksTab()" [ngClass]="{'btnActive': tabFlag === '2'}">Featured Tracks </button>
          <button (click)="PlaylistsTab()" [ngClass]="{'btnActive': tabFlag === '3'}">Trending Playlists </button>
        </div>
      </div>
    </div>
  </div>
  <!-- featured tab -->
  <div class="user-list" *ngIf="tabFlag == '0'">
    <div class="header">
      <h3>
        <svg xmlns="http://www.w3.org/2000/svg" height="10px" viewBox="0 0 24 24" width="10px" fill="#50e3c2">
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z" />
        </svg>
        Active Features ({{allFeaturelistup.count?allFeaturelistup.count:0}})
      </h3>
      <div class="btns">
        <button class="button" [disabled]="activePublicFeaturelist === false" (click)="doFeaturePublish();"
          style="border: 1px solid #50e3c2;">publish</button>

      </div>
    </div>
    <div class="usergroups-info playlist-cell">
      <mat-table [dataSource]="allFeaturelistup.results" class="mat-elevation-z8"
        *ngIf="allFeaturelistup.results?.length !== 0">


        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef> label </mat-header-cell>
          <mat-cell *matCellDef="let element" style="font-size:12px;color:#fff !important"> {{element?.name}}
          </mat-cell>
        </ng-container>


        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef> title </mat-header-cell>
          <mat-cell *matCellDef="let element" style="font-size:12px;color:#fff !important"> {{element?.title}}
          </mat-cell>
        </ng-container>


        <ng-container matColumnDef="tracks">
          <mat-header-cell *matHeaderCellDef> tracks </mat-header-cell>
          <mat-cell *matCellDef="let element"> <span style="padding-left: 20px;"> {{element?.tracks}}</span> </mat-cell>
        </ng-container>


        <ng-container matColumnDef="created_at">
          <mat-header-cell *matHeaderCellDef> created </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element?.created_at | date: 'M/d/yy'}}
            <span style="font-size: 35px;margin-top:-17px;">&nbsp;.&nbsp;</span> {{element?.created_at | date: 'h:mm a'}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="updated_at">
          <mat-header-cell *matHeaderCellDef> Updated </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.updated_at | date: 'M/d/yy'}} 
            <span style="font-size: 35px;margin-top:-17px;">&nbsp;.&nbsp;</span> {{element?.updated_at | date: 'h:mm a'}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Active">
          <mat-header-cell *matHeaderCellDef> Active </mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index">
            <mat-slide-toggle class="dark-theme" [(ngModel)]="element.is_active" (change)="changeFeatureDown(element)">
            </mat-slide-toggle>
            <div class="spin-arrows">
              <strong style="float: right;cursor: pointer;" (click)="movedownFeature(element)"
                *ngIf="i-1 !== (temp_allFeaturelistup.length)-2">
                <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#cccc">
                  <path d="M24 24H0V0h24v24z" fill="none" opacity=".87" />
                  <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" />
                </svg>

              </strong>
              <strong style="float: right;cursor: pointer;" (click)="moveupFeature(element)" *ngIf="i !== 0">
                <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#cccc">
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z" />
                </svg>

              </strong>
            </div>
          </mat-cell>
        </ng-container>


        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" class="main-row"></mat-row>
      </mat-table>
    </div>
    <div class="usergroups-info track-list playlist-cell newGenre" *ngIf="allFeaturelistup.results?.length === 0">
      No Active Features Available
    </div>
    <div class="header">
      <h3>Added Features </h3>
      <div class="btns">
        <button class="button" *ngIf="false">Add Artist</button>
        <button class="button" *ngIf="false">Add Album</button>
        <button class="button" (click)="addgenreall();">Add Genre</button>
        <button class="button" (click)="addplaylistFeature();">Add Playlist</button>

      </div>
      <div class="search">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14">
          <path fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="20"
            d="M6 1a5 5 0 110 10A5 5 0 016 1zM13.002 13.002L9.535 9.535" />
        </svg>
        <input name="searchString" type="text" [(ngModel)]="searchStringFeature"
          (keyup)="submitFeatureSearch($event.target.value)" placeholder="Search">
      </div>
    </div>


    <div class="usergroups-info track-list playlist-cell" *ngIf="allFeaturelist.results?.length !== 0">
      <mat-table matSort [dataSource]="allFeaturelist.results" class="mat-elevation-z8"
        (matSortChange)="announceSortChange($event)">


        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef> label </mat-header-cell>
          <mat-cell *matCellDef="let element" style="font-size:12px;color:#fff !important;"> {{element?.name}}
          </mat-cell>
        </ng-container>


        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef mat-sort-header> title </mat-header-cell>
          <mat-cell *matCellDef="let element" style="font-size:12px;color:#fff !important;"> {{element?.title}}
          </mat-cell>
        </ng-container>


        <ng-container matColumnDef="tracks">
          <mat-header-cell *matHeaderCellDef mat-sort-header> tracks </mat-header-cell>
          <mat-cell *matCellDef="let element"> <span style="padding-left: 20px;"> {{element?.tracks}}</span> </mat-cell>
        </ng-container>


        <ng-container matColumnDef="created_at">
          <mat-header-cell *matHeaderCellDef mat-sort-header> created </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element?.created_at | date: 'M/d/yy'}} <span
              style="font-size: 35px;margin-top:-17px;">&nbsp;.&nbsp;</span> {{element?.created_at | date: 'h:mm a'}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="updated_at">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Updated </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.updated_at | date: 'M/d/yy'}} <span
              style="font-size: 35px;margin-top:-17px;">&nbsp;.&nbsp;</span> {{element?.updated_at | date: 'h:mm a'}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Active">
          <mat-header-cell *matHeaderCellDef> Active </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-slide-toggle class="dark-theme" [(ngModel)]="element.is_active" (change)="changeFeatureUP(element)">
            </mat-slide-toggle>
            <div class="spin-arrows">
              <button type="button" matTooltip="Delete" (click)="deleteFeatureItem(element)"
                matTooltipClass="tooltip-white" class="action-button action">
                <svg width="18px" height="18px" viewBox="0 0 12 16" version="1.1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink">
                  <title>Delete</title>
                  <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                    stroke-linejoin="round">
                    <g id="Email-Mgmt-Desk-User-Client-Card" class="inner-stroke"
                      transform="translate(-1288.000000, -16.000000)">
                      <g id="Actions" transform="translate(1016.000000, 0.000000)">
                        <g id="Group" transform="translate(270.000000, 16.000000)">
                          <g id="trash-2" transform="translate(2.000000, 1.000000)">
                            <polyline id="Path" points="0 2.8 1.33333333 2.8 12 2.8"></polyline>
                            <path
                              d="M10.6666667,2.8 L10.6666667,12.6 C10.6666667,13.3731986 10.069713,14 9.33333333,14 L2.66666667,14 C1.930287,14 1.33333333,13.3731986 1.33333333,12.6 L1.33333333,2.8 M3.33333333,2.8 L3.33333333,1.4 C3.33333333,0.62680135 3.930287,0 4.66666667,0 L7.33333333,0 C8.069713,0 8.66666667,0.62680135 8.66666667,1.4 L8.66666667,2.8"
                              id="Shape"></path>
                            <line x1="4.66666667" y1="6.3" x2="4.66666667" y2="10.5" id="Path"></line>
                            <line x1="7.33333333" y1="6.3" x2="7.33333333" y2="10.5" id="Path"></line>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </button>
            </div>
          </mat-cell>
        </ng-container>


        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" class="main-row"></mat-row>
      </mat-table>

      <app-pagination *ngIf="featureNumberOfPages > 1" [page]="currentPage"
        (pageChange)="pageChangeAddedFeature($event)" [numberOfPages]="featureNumberOfPages"></app-pagination>
    </div>

    <div class="usergroups-info track-list playlist-cell newGenre" *ngIf="allFeaturelist.results?.length === 0">
      No Added Feature Available
    </div>

  </div>
  <!-- genres tab -->
  <div class="user-list" *ngIf="tabFlag == '1'">
    <div class="header">
      <h3>
        <svg xmlns="http://www.w3.org/2000/svg" height="10px" viewBox="0 0 24 24" width="10px" fill="#50e3c2">
          <path d="M0 0h24v24H0z" fill="none"></path>
          <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z"></path>
        </svg>
        Active Genres ({{updateActiveGenre1.length}})
      </h3>
      <div class="btns">
        <button class="button" style="border: 1px solid #50e3c2;" [disabled]="activePublic === false"
          (click)="publishGenre()">publish</button>
      </div>
    </div>
    <div *ngIf="updateActiveGenre.length !==0 " class="usergroups-info playlist-cell">
      <!-- <app-loader *ngIf="loadingGenre" color="#50e3c2"></app-loader> -->
      <mat-table class="mat-elevation-z8" [dataSource]="updateActiveGenre">

        <ng-container matColumnDef="genre">
          <mat-header-cell *matHeaderCellDef=""> Genre </mat-header-cell>
          <mat-cell *matCellDef="let element" style="font-size:12px;text-transform: uppercase;color:#fff !important;">
            <span  
            [class.expanded]="expandedElement == element"
            (click)="expandedElement = element">{{element?.name}}</span> </mat-cell>
        </ng-container>

        <ng-container matColumnDef="created">
          <mat-header-cell *matHeaderCellDef=""> created </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <p *ngIf="element?.created_at">{{element?.created_at | date: 'M/d/yy'}} <span
                style="font-size: 35px;">.</span> {{element?.time}}</p>
            <p class="emptySlot" *ngIf="element.created_at == undefined">- - - Empty Slot - - -</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Updated">
          <mat-header-cell *matHeaderCellDef=""> Updated </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <p *ngIf="element?.updated_at">{{element?.updated_at | date: 'M/d/yy'}} <span
                style="font-size: 35px;">.</span> {{element?.updateTime}}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Active">
          <mat-header-cell *matHeaderCellDef=""> Active </mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index">
            <mat-slide-toggle class="dark-theme" *ngIf="element?.updated_at" [(ngModel)]="element.is_featured"
              (change)="changeInActive(element)"></mat-slide-toggle>
            <div class="spin-arrows" *ngIf="element?.updated_at">
              <strong style="float: right;cursor: pointer;"  [class.collapsed]="expandedElement == element"
              (click)="expandedElement = element ; movedownGenre(element)"
                *ngIf="i-1 !== (2*updateActiveGenre1.length)-3">
                <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#cccc">
                  <path d="M24 24H0V0h24v24z" fill="none" opacity=".87"></path>
                  <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"></path>
                </svg>
              </strong>
              <strong style="float: right;cursor: pointer;" [class.collapsed]="expandedElement == element"
              (click)="expandedElement = element ; moveupGenre(element)" *ngIf="i !== 0">
                <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#cccc">
                  <path d="M0 0h24v24H0V0z" fill="none"></path>
                  <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z"></path>
                </svg>
              </strong>
            </div>
          </mat-cell>
        </ng-container>
        <!-- Expanded Content Column - The detail row is made up of this one column -->
        <ng-container matColumnDef="expandedDetail">
          <mat-cell *matCellDef="let detail" style="display: block;border-top: 1px solid #202027;padding-top:10px;">
            <div>
              <div class="expand-order expand-margin" *ngFor="let subGnere of detail.element.sub_genre"
                style="display: inline-block;">
                <p> <button style="padding:1rem 20px;width:auto;">{{subGnere?.name}} </button>
                  <!-- <button type="button" class="close-button" >
                      <svg width="10px" height="10px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                          <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                              <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                                  <g id="Genre-Options">
                                      <g id="Close" transform="translate(1439.000000, 32.000000)">
                                          <path d="M24,0 L0,24" id="Path"></path>
                                          <path d="M0,0 L24,24" id="Path"></path>
                                      </g>
                                  </g>
                              </g>
                          </g>
                      </svg>
                      </button>-->
                </p>

              </div>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns1"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns1;"
            matRipple 
            class="element-row" 
            [class.expanded]="expandedElement == row"
            (click)="expandedElement === row? expandedElement = null : expandedElement = row "></mat-row>
    <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow"
            [@detailExpand]="row.element == expandedElement ? 'expanded' : 'collapsed'"
            style="overflow: hidden"> 
    </mat-row>
      </mat-table>
    </div>
    <div *ngIf="updateActiveGenre.length == 0 " class="usergroups-info playlist-cell newGenre">
      <!-- <app-loader *ngIf="loadingGenre" color="#50e3c2"></app-loader> -->

      No Active Genres Available
    </div>
    <div class="header">
      <h3>All Genres and Subgenres </h3>
      <div class="btns">


      </div>
      <div class="search">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14">
          <path fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="20"
            d="M6 1a5 5 0 110 10A5 5 0 016 1zM13.002 13.002L9.535 9.535"></path>
        </svg>
        <input class="txt" id="search-filter-field" name="searchString" type="text" [(ngModel)]="searchString"
          (keyup)="submitSearch()" (focus)="searchFilterFocused = true" placeholder="Search">
      </div>
    </div>
    <div class="usergroups-info track-list playlist-cell">
      <!-- <app-loader *ngIf="loadingAllGenre" color="#50e3c2"></app-loader> -->
      <mat-table class="mat-elevation-z8" (click)="sortChange(allGenre.sort)" matSort [dataSource]="allGenre">

        <ng-container matColumnDef="genre">
          <mat-header-cell *matHeaderCellDef mat-sort-header (click)="valueUpdate()"> Genre </mat-header-cell>
          <mat-cell *matCellDef="let element" style="font-size:12px;text-transform: uppercase;color:#fff !important;">
            {{element?.name}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="created">
          <mat-header-cell *matHeaderCellDef mat-sort-header (click)="valueUpdate()"> created </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element?.created_at | date: 'M/d/yy'}} <span
              style="font-size: 35px;margin-top: -17px;">.</span> {{element?.time}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Updated">
          <mat-header-cell *matHeaderCellDef mat-sort-header (click)="valueUpdate()"> Updated </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.updated_at | date: 'M/d/yy'}} <span
              style="font-size: 35px;margin-top: -17px;">.</span> {{element?.updateTime}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Active">
          <mat-header-cell *matHeaderCellDef=""> Active </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-slide-toggle class="dark-theme" [(ngModel)]="element.is_featured" (change)="changeCanActive(element)">
            </mat-slide-toggle>
            <div class="spin-arrows">
              <button class="action-button action" type="button" (click)="deleteGenre();list.currGnere = element"
                matTooltip="Delete" matTooltipClass="tooltip-white">
                <svg width="18px" height="18px" viewBox="0 0 12 16" version="1.1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink">
                  <!-- <title>Delete</title> -->
                  <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                    stroke-linejoin="round">
                    <g class="inner-stroke" id="Email-Mgmt-Desk-User-Client-Card"
                      transform="translate(-1288.000000, -16.000000)">
                      <g id="Actions" transform="translate(1016.000000, 0.000000)">
                        <g id="Group" transform="translate(270.000000, 16.000000)">
                          <g id="trash-2" transform="translate(2.000000, 1.000000)">
                            <polyline id="Path" points="0 2.8 1.33333333 2.8 12 2.8"></polyline>
                            <path id="Shape"
                              d="M10.6666667,2.8 L10.6666667,12.6 C10.6666667,13.3731986 10.069713,14 9.33333333,14 L2.66666667,14 C1.930287,14 1.33333333,13.3731986 1.33333333,12.6 L1.33333333,2.8 M3.33333333,2.8 L3.33333333,1.4 C3.33333333,0.62680135 3.930287,0 4.66666667,0 L7.33333333,0 C8.069713,0 8.66666667,0.62680135 8.66666667,1.4 L8.66666667,2.8">
                            </path>
                            <line id="Path" x1="4.66666667" y1="6.3" x2="4.66666667" y2="10.5"></line>
                            <line id="Path" x1="7.33333333" y1="6.3" x2="7.33333333" y2="10.5"></line>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </button>

            </div>
          </mat-cell>
        </ng-container>
        <!-- Expanded Content Column - The detail row is made up of this one column -->
        <ng-container matColumnDef="expandedDetail">
          <mat-cell *matCellDef="let detail" style="display: block;border-top: 1px solid #202027;padding-top:10px;">
            <div>
              <div class="expand-order expand-margin" *ngFor="let subGnere of detail?.element?.sub_genre"
                style="display: inline-block;">
                <p> <button style="padding:1rem 20px;width:auto;">{{subGnere?.name}} </button>
                  <!-- <button type="button" class="close-button" >
                    <svg width="10px" height="10px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                            <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                                <g id="Genre-Options">
                                    <g id="Close" transform="translate(1439.000000, 32.000000)">
                                        <path d="M24,0 L0,24" id="Path"></path>
                                        <path d="M0,0 L24,24" id="Path"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                    </button> -->
                </p>

              </div>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns1"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns1;" matRipple class="element-row"
          [class.expanded]="expandedElement == row" [class.collapsed]="expandedElement == null"
          (click)="rowClicked(row)"></mat-row>
        <mat-row class="element-row1" *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow"
          [@detailExpand]="row.element == expandedElement ? 'expanded' : 'collapsed'">
        </mat-row>
      </mat-table>
      <app-pagination *ngIf="genreNumberOfPages > 1" [page]="currentPage" (pageChange)="pageChange($event)"
        [numberOfPages]="genreNumberOfPages"></app-pagination>
    </div>
  </div>
  <!-- feautured tracks -->
  <div class="user-list" *ngIf="tabFlag == '2'">
    <div class="header">
      <h3 style="color:#50e3c2;text-transform: capitalize;">
        {{currentPlaylistName}} <p *ngIf="currentPlaylistName !== ''" style="color: darkgoldenrod;
        margin-left: 104%;
        margin-top: -23px;
        width: 39%;">(10 min)</p></h3>
      <div class="btns">
        <button class="button active-btns">{{isFeatureTrack}} Active</button>
        <button class="button" style="border: 1px solid #50e3c2;"
          [disabled]="(isFeatureTrack < 10 || activePublicTrack === false)" (click)="publishTrack()">publish</button>
        <button class="button remove-btn" [disabled]="(isFeatureTrack < 10 || activePublicTrack === false)"
          (click)="changeTrackPlaylist(false,'remove')">Remove</button>

      </div>
    </div>
    <div class="usergroups-info track-list playlist-cell">
      <!-- #hBSort="matSort" (click)="sortChangeTrack(updateActiveTrack.sort)" -->
      <app-loader *ngIf="loadingGenre" color="#50e3c2"></app-loader>
      <mat-table *ngIf="!loadingGenre" #hBSort="matSort" (click)="sortChangeTrack(updateActiveTrack.sort)" [dataSource]="updateActiveTrack"
        matSort class="mat-elevation-z8">
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header (click)="valueUpdate()">Track Name</mat-header-cell>
          <mat-cell *matCellDef="let row" style="color:#fff !important;">{{ row.title }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef mat-sort-header (click)="valueUpdate()">Genre</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.genre && row.genre.parent ? row.genre.parent.name : row.genre ? row.genre.name : "" }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tracks">
          <mat-header-cell *matHeaderCellDef mat-sort-header (click)="valueUpdate()">Subgenre</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.sub_genre ? row.sub_genre.name : "" }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="created">
          <mat-header-cell *matHeaderCellDef="" mat-sort-header (click)="valueUpdate()"> created </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <p *ngIf="row?.created_at">{{row?.created_at | date: 'shortDate'}}</p>
          </mat-cell>
        </ng-container>

        <!--         
      <ng-container matColumnDef="Updated">
        <mat-header-cell *matHeaderCellDef="" mat-sort-header (click)="valueUpdate()"> Updated </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.updated_at | date: 'M/d/yy'}} . {{row?.updateTime}} </mat-cell>
      </ng-container> -->
        <!--               [ngClass]="{'disabled-btn': (isFeatureTrack >= 10 && row.is_featured === false )}"
 -->
        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef>Active</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-slide-toggle class="dark-theme" [(ngModel)]="row.is_featured" (change)="changeTrack(row)">
            </mat-slide-toggle>
            <button type="button" (click)="$event.preventDefault();$event.stopPropagation();rowClicked1(row)"
              class="versions-toggle" class="expand-button">
              <svg width="10px" height="5px" viewBox="0 0 10 5" class="carrot"
                [ngClass]="{ flip: trackIsExpanded(row) }">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                  stroke-linejoin="round">
                  <g transform="translate(-1392.000000, -396.000000)" class="inner-stroke" stroke-width="0.95">
                    <g transform="translate(392.000000, 351.000000)">
                      <g transform="translate(920.550000, 36.100000)">
                        <polyline points="80.75 9.5 84.55 13.3 88.35 9.5"></polyline>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </button>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="expandedDetail1">
          <mat-cell *matCellDef="let row" style="width: 100%;">

            <!-- sub Track -->
            <div style="width: 100%;">
              <div class="version-row" *ngFor="let version of row?.element?.versions">
                <!-- <div class="version-row" *ngFor="let version of row?.element?.versions" (click)="setPlayingTrack(row?.element?.versions)"> -->
                <!-- <pre>
                  {{version | json}}
                </pre> -->
                <button type="button" class="play-button" (click)="playButtonPressed(row?.element,version)"
                  *ngIf="!isPlaying1(version)">
                  <svg width="8px" height="10px" viewBox="0 0 8 10" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g transform="translate(-25.000000, -23.000000)">
                        <g transform="translate(16.000000, 16.000000)">
                          <path class="inner-path"
                            d="M16.0796687,12.8192319 L11.3234623,16.1485764 C10.871013,16.4652909 10.2474826,16.3552561 9.93076808,15.9028068 C9.8131096,15.7347232 9.75,15.5345165 9.75,15.3293444 L9.75,8.67065556 C9.75,8.11837081 10.1977153,7.67065556 10.75,7.67065556 C10.9551721,7.67065556 11.1553788,7.73376517 11.3234623,7.85142364 L16.0796687,11.1807681 C16.532118,11.4974826 16.6421528,12.121013 16.3254383,12.5734623 C16.2584893,12.6691037 16.17531,12.752283 16.0796687,12.8192319 Z"
                            fill="#FFFFFF"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                </button>
                <button type="button" (click)="pauseButtonPressed(row?.element,version)" class="pause-button"
                  *ngIf="isPlaying1(version)">


                  <!-- <pre>
                    {{_isPlaying | json}}
                    {{_playerService.currentPlaylistItems | json}}
                  </pre> -->

                 

                <ng-container *ngIf="(_isPlaying && _playerService.currentPlaylistItems?.isPlay); else elseTemplate11">
                 

                    <svg width="10px" height="14px" viewBox="0 0 10 14" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g transform="translate(-426.000000, -746.000000)" fill="#15151C" class="inner-path">
                        <g transform="translate(393.000000, 704.000000)">
                          <path
                            d="M33.2499996,42.9499995 L36.4166662,42.9499995 L36.4166662,55.616666 L33.2499996,55.616666 L33.2499996,42.9499995 Z M39.5833328,42.9499995 L42.7499995,42.9499995 L42.7499995,55.616666 L39.5833328,55.616666 L39.5833328,42.9499995 Z">
                          </path>
                        </g>
                      </g>
                    </g>
                  </svg>

                </ng-container>
                <ng-template #elseTemplate11>
                   
                  <svg width="8px" height="10px" viewBox="0 0 8 10" version="1.1" xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink">
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g transform="translate(-25.000000, -23.000000)">
                          <g transform="translate(16.000000, 16.000000)">
                            <path class="inner-path"
                              d="M16.0796687,12.8192319 L11.3234623,16.1485764 C10.871013,16.4652909 10.2474826,16.3552561 9.93076808,15.9028068 C9.8131096,15.7347232 9.75,15.5345165 9.75,15.3293444 L9.75,8.67065556 C9.75,8.11837081 10.1977153,7.67065556 10.75,7.67065556 C10.9551721,7.67065556 11.1553788,7.73376517 11.3234623,7.85142364 L16.0796687,11.1807681 C16.532118,11.4974826 16.6421528,12.121013 16.3254383,12.5734623 C16.2584893,12.6691037 16.17531,12.752283 16.0796687,12.8192319 Z"
                              fill="#FFFFFF"></path>
                          </g>
                        </g>
                      </g>
                    </svg>
                    
                </ng-template>
                




                  <!-- <svg width="8px" height="10px" viewBox="0 0 8 10" version="1.1" xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink">
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g transform="translate(-25.000000, -23.000000)">
                          <g transform="translate(16.000000, 16.000000)">
                            <path class="inner-path"
                              d="M16.0796687,12.8192319 L11.3234623,16.1485764 C10.871013,16.4652909 10.2474826,16.3552561 9.93076808,15.9028068 C9.8131096,15.7347232 9.75,15.5345165 9.75,15.3293444 L9.75,8.67065556 C9.75,8.11837081 10.1977153,7.67065556 10.75,7.67065556 C10.9551721,7.67065556 11.1553788,7.73376517 11.3234623,7.85142364 L16.0796687,11.1807681 C16.532118,11.4974826 16.6421528,12.121013 16.3254383,12.5734623 C16.2584893,12.6691037 16.17531,12.752283 16.0796687,12.8192319 Z"
                              fill="#FFFFFF"></path>
                          </g>
                        </g>
                      </g>
                    </svg> -->
                  <!-- <ng-container
                    *ngIf="!(_playerService.currentPlaylistItems.isPlay && _playerService.currentPlaylistItems.isPause)">
                    <svg width="10px" height="14px" viewBox="0 0 10 14" version="1.1" xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink">
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g transform="translate(-426.000000, -746.000000)" fill="#15151C" class="inner-path">
                          <g transform="translate(393.000000, 704.000000)">
                            <path
                              d="M33.2499996,42.9499995 L36.4166662,42.9499995 L36.4166662,55.616666 L33.2499996,55.616666 L33.2499996,42.9499995 Z M39.5833328,42.9499995 L42.7499995,42.9499995 L42.7499995,55.616666 L39.5833328,55.616666 L39.5833328,42.9499995 Z">
                            </path>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </ng-container> -->




                </button>


                <div class="mat-cell title" style="margin-bottom: 0;flex-grow:1;">
                  <span class="version-title" style="margin-left:0;color:#fff !important;">
                    <!-- <span style="margin-left:0;"
                      *ngIf="version.is_main_track"> {{ row.title}} {{ version.title || row.title || '' }} -
                      Main</span>
                      <span style="margin-left:0;" *ngIf="!version.is_main_track">{{ row.title}} {{
                      version.title || row.title || '' }}</span> -->
                      <span style="margin-left:0;"> {{ version.title  || '' }} </span>
                    </span>
                </div>
                <div class="mat-cell actions" style="flex-grow: 1;">
                  <button (click)="displayTrackInfo(row, version)" style="margin-left:35px;" type="button"
                    matTooltip="Info" matTooltipClass="tooltip-white" class="action-button">
                    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink">
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g transform="translate(-292.000000, -20.000000)">
                          <g transform="translate(0.000000, -4.000000)">
                            <g transform="translate(292.000000, 24.000000)" class="inner-stroke">
                              <circle cx="8" cy="8" r="7.5"></circle>
                              <circle fill-rule="nonzero" cx="8" cy="4.75" r="1"></circle>
                              <line x1="8" y1="11" x2="8" y2="8" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round"></line>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </button>
                </div>
                <div class="mat-cell" style="text-align: center;">
                  {{ version.created_at | date: 'shortDate' }}
                </div>
                <!-- <div class="mat-cell" style="margin-left: 12px;">
              {{ version.updated_at | date: 'shortDate' }}
            </div> -->
                <div class="mat-cell"></div>
              </div>
            </div>
            <div class="version-row" *ngIf="!row?.element?.versions || row?.element?.versions.length === 0">
              There is no version for this track
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsTrack"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsTrack;" matRipple class="element-row"
          [class.expanded]="expandedElement == row" [class.collapsed]="expandedElement == null"
          (click)="rowClicked1(row)"></mat-row>
        <mat-row class="element-row1" *matRowDef="let row; columns: ['expandedDetail1']; when: isExpansionDetailRow1"
          [@detailExpand]="row.element == expandedElement ? 'expanded' : 'collapsed'">
        </mat-row>
      </mat-table>

      <div class="music-player-wrapper" *ngIf="this.currentPlaylistId !== 0"
        [ngClass]="{hide: !playingTrack || !playingTrackVersion, collapsed: musicPlayerCollapsed}">
        <button type="button" class="music-player-expand-button" (click)="toggleMusicPlayerCollapsed()">
          <svg width="18px" height="9px" viewBox="0 0 18 9" version="1.1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="player_close" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="closed-player" transform="translate(-730.000000, -169.000000)" class="inner-stroke"
                stroke="#50E3C2">
                <polyline id="Path-2" points="730.682129 177.754395 738.843146 169.593378 747.004163 177.754395">
                </polyline>
              </g>
            </g>
          </svg>
        </button>
        <app-musicplayer [track]="playingTrack" [version]="playingTrackVersion" [doPlay]="isPlay" [doPause]="isPause" [(isPlaying)]="_isPlaying"
          [ngClass]="{hide: !playingTrack || !playingTrackVersion, collapsed: musicPlayerCollapsed}"
          (collapse)="toggleMusicPlayerCollapsed()" class="music-player"></app-musicplayer>
          <!-- <app-new-musicplayer [track]="playingTrack"
          [version]="playingTrackVersion"
          [ngClass]="{hide: !playingTrack || !playingTrackVersion, collapsed: musicPlayerCollapsed}"
          (collapse)="toggleMusicPlayerCollapsed()"
      class="music-player"></app-new-musicplayer> -->
      </div>
    </div>
    <div class="header">
      <h3>Added Playlists <span class="hint-content">(Only one playlist can be active at a time)</span></h3>
      <div class="btns">

        <button class="button" (click)="addplaylist('Track');">Add Playlist</button>

      </div>
      <div class="search">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14">
          <path fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="20"
            d="M6 1a5 5 0 110 10A5 5 0 016 1zM13.002 13.002L9.535 9.535"></path>
        </svg>
        <input name="searchString" type="text" [(ngModel)]="searchStringTrack" (keyup)="submitTrackSearch()"
          (focus)="searchFilterFocused = true" placeholder="Search">
      </div>
    </div>
    <div class="usergroups-info track-list playlist-cell" *ngIf="allnewTrack.length !== 0">
      <!-- <app-loader *ngIf="loadingAllPlaylist" color="#50e3c2"></app-loader> -->

      <mat-table #sBSort="matSort" class="mat-elevation-z8" (click)="sortChangePlaylist(allPlaylistTrack.sort,'track')"
        matSort [dataSource]="allPlaylistTrack">

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
          <mat-cell *matCellDef="let element" style="font-size:12px;color:#fff !important;"> {{element?.name}}
          </mat-cell>
        </ng-container>


        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef=""> </mat-header-cell>
          <mat-cell *matCellDef="let element"> </mat-cell>
        </ng-container>


        <ng-container matColumnDef="tracks">
          <mat-header-cell *matHeaderCellDef mat-sort-header> tracks </mat-header-cell>
          <mat-cell *matCellDef="let element"><span style="padding-left: 20px;"> {{element?.track_count}}</span>
          </mat-cell>
        </ng-container>


        <ng-container matColumnDef="created">
          <mat-header-cell *matHeaderCellDef mat-sort-header> created </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element?.created_at | date: 'M/d/yy'}} <span
              style="font-size: 35px;margin-top:-17px;">.</span> {{element?.time}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Updated">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Updated </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.updated_at | date: 'M/d/yy'}} <span
              style="font-size: 35px;margin-top:-17px;">.</span> {{element?.updateTime}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Active">
          <mat-header-cell *matHeaderCellDef=""> Active </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-slide-toggle class="dark-theme"
              [ngClass]="{'disabled-btn': ( (trackEnable == true && element.active_playlist_track === false) || loadingGenre == true )}"
              [(ngModel)]="element.active_playlist_track" (change)="changeTrackPlaylist(element,'list')">
            </mat-slide-toggle>
            <div class="spin-arrows">
              <button class="action-button action" type="button" matTooltip="Delete"
                (click)="displayDeletePlaylistDialog(element,'track')" matTooltipClass="tooltip-white">
                <svg width="18px" height="18px" viewBox="0 0 12 16" version="1.1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                    stroke-linejoin="round">
                    <g class="inner-stroke" id="Email-Mgmt-Desk-User-Client-Card"
                      transform="translate(-1288.000000, -16.000000)">
                      <g id="Actions" transform="translate(1016.000000, 0.000000)">
                        <g id="Group" transform="translate(270.000000, 16.000000)">
                          <g id="trash-2" transform="translate(2.000000, 1.000000)">
                            <polyline id="Path" points="0 2.8 1.33333333 2.8 12 2.8"></polyline>
                            <path id="Shape"
                              d="M10.6666667,2.8 L10.6666667,12.6 C10.6666667,13.3731986 10.069713,14 9.33333333,14 L2.66666667,14 C1.930287,14 1.33333333,13.3731986 1.33333333,12.6 L1.33333333,2.8 M3.33333333,2.8 L3.33333333,1.4 C3.33333333,0.62680135 3.930287,0 4.66666667,0 L7.33333333,0 C8.069713,0 8.66666667,0.62680135 8.66666667,1.4 L8.66666667,2.8">
                            </path>
                            <line id="Path" x1="4.66666667" y1="6.3" x2="4.66666667" y2="10.5"></line>
                            <line id="Path" x1="7.33333333" y1="6.3" x2="7.33333333" y2="10.5"></line>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </button>
            </div>
          </mat-cell>
        </ng-container>


        <mat-header-row *matHeaderRowDef="displayedColumnsPlaylist"></mat-header-row>
        <mat-row class="main-row" *matRowDef="let row; columns: displayedColumnsPlaylist;"></mat-row>
      </mat-table>

      <app-pagination *ngIf="playlistNumberOfPages > 1" [page]="currentTrackPage" (pageChange)="trackPageChange($event)"
        [numberOfPages]="playlistNumberOfPages"></app-pagination>

    </div>
    <div class="usergroups-info track-list playlist-cell newGenre" *ngIf="allnewTrack.length === 0">
      <!-- <app-loader *ngIf="loadingAllPlaylist" color="#50e3c2"></app-loader> -->
      No Added Playlists Available
    </div>

  </div>
  <!-- trending Playlists -->
  <div class="user-list" *ngIf="tabFlag == '3'">
    <div class="header">
      <h3>
        <svg xmlns="http://www.w3.org/2000/svg" height="10px" viewBox="0 0 24 24" width="10px" fill="#50e3c2">
          <path d="M0 0h24v24H0z" fill="none"></path>
          <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z"></path>
        </svg>
        Active Playlists ({{updateActivePlaylist1.length}})
      </h3>
      <div class="btns">
        <button class="button" style="border: 1px solid #50e3c2;" [disabled]="activePublicPlaylist === false"
          (click)="publishPlaylist()">publish</button>

      </div>
    </div>
    <div class="usergroups-info playlist-cell" *ngIf="updateActivePlaylist.length !==0 ">
      <!-- <app-loader *ngIf="loadingPlaylist" color="#50e3c2"></app-loader> -->

      <mat-table class="mat-elevation-z8" [dataSource]="updateActivePlaylist">

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef=""> Name </mat-header-cell>
          <mat-cell *matCellDef="let element" style="font-size:12px;color:#fff !important;"> {{element?.name}}
          </mat-cell>
        </ng-container>


        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef=""> </mat-header-cell>
          <mat-cell *matCellDef="let element"> </mat-cell>
        </ng-container>


        <ng-container matColumnDef="tracks">
          <mat-header-cell *matHeaderCellDef=""> tracks </mat-header-cell>
          <mat-cell *matCellDef="let element"><span style="padding-left: 20px;"> {{element?.track_count}}</span></mat-cell>
        </ng-container>

        <ng-container matColumnDef="created">
          <mat-header-cell *matHeaderCellDef=""> created </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <p *ngIf="element?.created_at">{{element?.created_at | date: 'M/d/yy'}} <span
                style="font-size: 35px;margin-top:-17px;">.</span> {{element?.time}}</p>
            <p class="emptySlot" *ngIf="element.created_at == undefined">- - - Empty Slot - - -</p>
          </mat-cell>
        </ng-container>


        <ng-container matColumnDef="Updated">
          <mat-header-cell *matHeaderCellDef=""> Updated </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.updated_at | date: 'M/d/yy'}} <span
              style="font-size: 35px;margin-top:-17px;">.</span> {{element?.updateTime}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Active">
          <mat-header-cell *matHeaderCellDef=""> Active </mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index">
            <mat-slide-toggle class="dark-theme" *ngIf="element?.created_at" [(ngModel)]="element.is_featured"
              (change)="changeInActivePlaylist(element)"></mat-slide-toggle>
            <div class="spin-arrows" *ngIf="element?.created_at && updateActivePlaylist1.length !==1">
              <strong style="float: right;cursor: pointer;" (click)="movedownPlaylist(element)"
                *ngIf="i-1 !== (updateActivePlaylist1.length)-2">
                <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#cccc">
                  <path d="M24 24H0V0h24v24z" fill="none" opacity=".87"></path>
                  <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"></path>
                </svg>
              </strong>
              <strong style="float: right;cursor: pointer;" (click)="moveupPlaylist(element)" *ngIf="i !== 0">
                <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#cccc">
                  <path d="M0 0h24v24H0V0z" fill="none"></path>
                  <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z"></path>
                </svg>
              </strong>
            </div>
          </mat-cell>
        </ng-container>


        <mat-header-row *matHeaderRowDef="displayedColumnsPlaylist"></mat-header-row>
        <mat-row class="main-row" *matRowDef="let row; columns: displayedColumnsPlaylist;"></mat-row>
      </mat-table>


    </div>
    <div class="usergroups-info playlist-cell newGenre" *ngIf="updateActivePlaylist.length ==0 ">
      <!-- <app-loader *ngIf="loadingPlaylist" color="#50e3c2"></app-loader> -->
      No Active Playlists Available
    </div>
    <div class="header">
      <h3>Added Playlists </h3>
      <div class="btns">

        <button class="button" (click)="addplaylist('playlist');">Add Playlist</button>

      </div>
      <div class="search">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14">
          <path fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="20"
            d="M6 1a5 5 0 110 10A5 5 0 016 1zM13.002 13.002L9.535 9.535"></path>
        </svg>
        <input name="searchString" type="text" [(ngModel)]="searchStringPlaylist" (keyup)="submitPlaylistSearch()"
          (focus)="searchFilterFocused = true" placeholder="Search">
      </div>
    </div>
    <div class="usergroups-info track-list playlist-cell" *ngIf="allnewPlaylist.length !== 0">
      <!-- <app-loader *ngIf="loadingAllPlaylist" color="#50e3c2"></app-loader> -->

      <mat-table class="mat-elevation-z8" (click)="sortChangePlaylist(allPlaylist.sort,'playlist')" matSort
        [dataSource]="allPlaylist">

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
          <mat-cell *matCellDef="let element" style="font-size:12px;color:#fff !important;"> {{element?.name}}
          </mat-cell>
        </ng-container>


        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef=""> </mat-header-cell>
          <mat-cell *matCellDef="let element"> </mat-cell>
        </ng-container>


        <ng-container matColumnDef="tracks">
          <mat-header-cell *matHeaderCellDef mat-sort-header> tracks </mat-header-cell>
          <mat-cell *matCellDef="let element"><span style="padding-left: 20px;"> {{element?.track_count}}</span></mat-cell>
        </ng-container>
        <ng-container matColumnDef="created">
          <mat-header-cell *matHeaderCellDef mat-sort-header> created </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element?.created_at | date: 'M/d/yy'}} <span
              style="font-size: 35px;margin-top:-17px;">.</span> {{element?.time}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Updated">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Updated </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.updated_at | date: 'M/d/yy'}} <span
              style="font-size: 35px;margin-top:-17px;">&nbsp;.&nbsp;</span> {{element?.updateTime}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Active">
          <mat-header-cell *matHeaderCellDef> Active </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-slide-toggle class="dark-theme" [(ngModel)]="element.is_featured"
              (change)="changeCanActivePlaylist(element)"></mat-slide-toggle>
            <div class="spin-arrows">
              <button class="action-button action" type="button" matTooltip="Delete"
                (click)="displayDeletePlaylistDialog(element,'playlist')" matTooltipClass="tooltip-white">
                <svg width="18px" height="18px" viewBox="0 0 12 16" version="1.1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                    stroke-linejoin="round">
                    <g class="inner-stroke" id="Email-Mgmt-Desk-User-Client-Card"
                      transform="translate(-1288.000000, -16.000000)">
                      <g id="Actions" transform="translate(1016.000000, 0.000000)">
                        <g id="Group" transform="translate(270.000000, 16.000000)">
                          <g id="trash-2" transform="translate(2.000000, 1.000000)">
                            <polyline id="Path" points="0 2.8 1.33333333 2.8 12 2.8"></polyline>
                            <path id="Shape"
                              d="M10.6666667,2.8 L10.6666667,12.6 C10.6666667,13.3731986 10.069713,14 9.33333333,14 L2.66666667,14 C1.930287,14 1.33333333,13.3731986 1.33333333,12.6 L1.33333333,2.8 M3.33333333,2.8 L3.33333333,1.4 C3.33333333,0.62680135 3.930287,0 4.66666667,0 L7.33333333,0 C8.069713,0 8.66666667,0.62680135 8.66666667,1.4 L8.66666667,2.8">
                            </path>
                            <line id="Path" x1="4.66666667" y1="6.3" x2="4.66666667" y2="10.5"></line>
                            <line id="Path" x1="7.33333333" y1="6.3" x2="7.33333333" y2="10.5"></line>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </button>
            </div>
          </mat-cell>
        </ng-container>


        <mat-header-row *matHeaderRowDef="displayedColumnsPlaylist"></mat-header-row>
        <mat-row class="main-row" *matRowDef="let row; columns: displayedColumnsPlaylist;"></mat-row>
      </mat-table>

      <app-pagination *ngIf="playlistNumberOfPages > 1" [page]="currentPlaylistPage"
        (pageChange)="playlistPageChange($event)" [numberOfPages]="playlistNumberOfPages"></app-pagination>

    </div>
    <div class="usergroups-info track-list playlist-cell newGenre" *ngIf="allnewPlaylist.length === 0">
      <!-- <app-loader *ngIf="loadingAllPlaylist" color="#50e3c2"></app-loader> -->
      No Added Playlists Available
    </div>
  </div>

</div>