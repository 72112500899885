<div class="main">
    <div class="user-list">
        <div class="header">
            <h3>Reports  - {{list.allReportsDetails.count}}</h3>
            <div class="btns">
                <button (click)="downloadreports()" class="button">Download Report </button>
               
            </div>
        </div>
        <div class="reports-info track-list">
            <mat-table matSort [dataSource]="list.dataSourceForReports" class="mat-elevation-z8">

                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->
              
                <!-- Position Column -->
                <ng-container matColumnDef="file_name">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> File name </mat-header-cell>
                  <mat-cell *matCellDef="let element" style="text-transform: uppercase;font-size:10px;"> {{element?.file_name}} </mat-cell>
                </ng-container>
              
                <!-- Name Column -->
                <ng-container matColumnDef="file_size">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Size </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element?.file_size}} </mat-cell>
                </ng-container>
              
                <!-- Weight Column -->
                <ng-container matColumnDef="file_format">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Format </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element?.file_format | titlecase}} </mat-cell>
                </ng-container>
              
                <!-- Symbol Column -->
                <ng-container matColumnDef="created_at">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Uploaded </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element?.created_at|date:"M/d/yy"}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="artist">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Artist name </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.artist}} </mat-cell>
                  </ng-container>
              
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" class="main-row"></mat-row>
            </mat-table>
              
              
              
              
            
        </div>
        
        <app-pagination
                *ngIf="reportsNumberOfPages > 1 && !list.reportsLoader"
                [page]="currentReportsPage"
                (filtersChange)="pageReportsTrigger($event)"
                [numberOfPages]="reportsNumberOfPages"
            ></app-pagination>
    </div>
</div>

