<h4 *ngIf="!forgotPassword && !successReset && !setPass">log in</h4>

<div class="form-wrapper green-theme" *ngIf="!forgotPassword && !successReset && !setPass">
        <app-loader *ngIf="pageLoader"></app-loader>
    <form *ngIf="!pageLoader" (ngSubmit)="login()" #loginForm="ngForm" autocomplete="off">
        <div class="error" *ngFor="let error of errors">{{ error }}</div>

        <div class="form-row">
            <mat-form-field>
                <input name="email Address" type="email Address" placeholder="email&nbsp; Address / Username" [(ngModel)]="loginModel.email"   (blur)="$event.preventDefault();$event.stopPropagation();checkEmail($event.target.value)"  tabindex="1" autocomplete="off" required matInput>
            </mat-form-field>
        </div>

        <div class="form-row">
            <mat-form-field>
                <input id="checkbox1" name="password" [type]="passwordInputType" value autocomplete="new-password" placeholder="Password" [(ngModel)]="loginModel.password" tabindex="2" maxlength="16" minlength="4" required matInput >
                <button class="input-suffix" type="button" matSuffix (click)="togglePasswordInputType()">
                    <svg width="23px" height="18px" viewBox="0 0 23 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" *ngIf="passwordInputType == 'password'">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                            <g transform="translate(-906.000000, -381.000000)" stroke="#15151C">
                                <g transform="translate(512.000000, 160.000000)">
                                    <g transform="translate(49.000000, 191.000000)">
                                        <g transform="translate(346.000000, 31.000000)">
                                            <path d="M0,8 C0,8 3.74025974,0 10.2857143,0 C16.8311688,0 20.5714286,8 20.5714286,8 C20.5714286,8 16.8311688,16 10.2857143,16 C3.74025974,16 0,8 0,8 Z"></path>
                                            <ellipse cx="10.2857143" cy="8" rx="2.80519481" ry="3"></ellipse>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <svg width="23px" height="18px" viewBox="0 0 23 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" *ngIf="passwordInputType == 'text'">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                            <g transform="translate(-906.000000, -381.000000)">
                                <g transform="translate(512.000000, 160.000000)">
                                    <g transform="translate(49.000000, 191.000000)">
                                        <g transform="translate(346.000000, 31.000000)">
                                            <path d="M0,8 C0,8 3.74025974,0 10.2857143,0 C16.8311688,0 20.5714286,8 20.5714286,8 C20.5714286,8 16.8311688,16 10.2857143,16 C3.74025974,16 0,8 0,8 Z" stroke="#15151C" fill="#15151C"></path>
                                            <ellipse stroke="#FFFFFF" fill="#FFFFFF" cx="10.2857143" cy="8" rx="2.80519481" ry="3"></ellipse>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </button>
            </mat-form-field>
        </div>

        <div class="form-row" style="text-align: left;font-weight: 300;
        font-size: 12px;">
            <mat-checkbox name="rememberMe" [(ngModel)]="loginModel.rememberMe">Stay logged in</mat-checkbox>
            <a class="forgot-password" (click)="forgotPassword = true">Forgot Password?</a>
        </div>

        <div class="form-row">
            <button class="green" type="submit" [disabled]="(!loginForm.form.valid || enableLogin === false)" *ngIf="!loading">
                Log In
            </button>
            <app-loader *ngIf="loading" color="#50e3c2"></app-loader>
        </div>
    </form>
</div>




<!-- forgot password UI -->
<h4 *ngIf="forgotPassword && !successReset && !setPass">Forgot Password</h4>

<div class="form-wrapper green-theme" *ngIf="forgotPassword && !successReset && !setPass">
    <form>
        <div class="error" *ngFor="let error of errors">{{ error }}</div>
        <div class="form-row">
            <mat-form-field>
                <input name="email Address" type="email Address" placeholder="email Address" [(ngModel)]="forgotEmail" tabindex="1" required matInput>
            </mat-form-field>
        </div>
        <div class="form-row">
            <button class="green" type="submit" [disabled]="forgotEmail  === '' " (click)="forgotPasswordLogin();">
            Send Reset Instructions
            </button>
            <app-loader *ngIf="loading" color="#50e3c2"></app-loader>
        </div>
    </form>
</div>

<!-- email send successfully -->
<!-- <h4 *ngIf="resetPass && !forgotPassword">Reset password </h4>
<div *ngIf="!forgotPassword && resetPass" class="form-wrapper green-theme">
    <form>
        <div class="form-row">
            <p class="succes-msg">Password reset instructions sent , please check your E-mail.
            </p>
        </div>
        <div class="form-row">
            <button type="submit" class="green" (click)="resetPass = false" ;>
                ok
            </button>
        </div>
    </form>
</div> -->




<!-- set new password UI -->
<h4 *ngIf="setPass && !forgotPassword && !successReset ">Set New Password</h4>

<!-- <div class="form-wrapper green-theme" *ngIf="setPass && !forgotPassword && !successReset ">
    <form>
        <div class="error" *ngFor="let error of errors">{{ error }}</div>
        <div class="form-row">
            <mat-form-field>
                <input name="New Password" type="New Password" tabindex="2" maxlength="16" minlength="8" required [type]="passwordInputType" placeholder="New Password" [(ngModel)]="setPassword" matInput>
                <button class="input-suffix" type="button" matSuffix (click)="togglePasswordInputType()">
                    <svg width="23px" height="18px" viewBox="0 0 23 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" *ngIf="passwordInputType == 'password'">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                            <g transform="translate(-906.000000, -381.000000)" stroke="#15151C">
                                <g transform="translate(512.000000, 160.000000)">
                                    <g transform="translate(49.000000, 191.000000)">
                                        <g transform="translate(346.000000, 31.000000)">
                                            <path d="M0,8 C0,8 3.74025974,0 10.2857143,0 C16.8311688,0 20.5714286,8 20.5714286,8 C20.5714286,8 16.8311688,16 10.2857143,16 C3.74025974,16 0,8 0,8 Z"></path>
                                            <ellipse cx="10.2857143" cy="8" rx="2.80519481" ry="3"></ellipse>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <svg width="23px" height="18px" viewBox="0 0 23 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" *ngIf="passwordInputType == 'text'">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                            <g transform="translate(-906.000000, -381.000000)">
                                <g transform="translate(512.000000, 160.000000)">
                                    <g transform="translate(49.000000, 191.000000)">
                                        <g transform="translate(346.000000, 31.000000)">
                                            <path d="M0,8 C0,8 3.74025974,0 10.2857143,0 C16.8311688,0 20.5714286,8 20.5714286,8 C20.5714286,8 16.8311688,16 10.2857143,16 C3.74025974,16 0,8 0,8 Z" stroke="#15151C" fill="#15151C"></path>
                                            <ellipse stroke="#FFFFFF" fill="#FFFFFF" cx="10.2857143" cy="8" rx="2.80519481" ry="3"></ellipse>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </button>
            </mat-form-field>
            </div>
            <div class="form-row">
            <mat-form-field>
                <input name="Confirm Password" type="Confirm Password" tabindex="2" maxlength="16" minlength="8" required [type]="passwordInputType1" placeholder="Confirm Password" [(ngModel)]="confirmPassword" matInput>
                <button class="input-suffix" type="button" matSuffix (click)="togglePasswordInputType('confirm')">
                    <svg width="23px" height="18px" viewBox="0 0 23 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" *ngIf="passwordInputType1 == 'password'">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                            <g transform="translate(-906.000000, -381.000000)" stroke="#15151C">
                                <g transform="translate(512.000000, 160.000000)">
                                    <g transform="translate(49.000000, 191.000000)">
                                        <g transform="translate(346.000000, 31.000000)">
                                            <path d="M0,8 C0,8 3.74025974,0 10.2857143,0 C16.8311688,0 20.5714286,8 20.5714286,8 C20.5714286,8 16.8311688,16 10.2857143,16 C3.74025974,16 0,8 0,8 Z"></path>
                                            <ellipse cx="10.2857143" cy="8" rx="2.80519481" ry="3"></ellipse>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <svg width="23px" height="18px" viewBox="0 0 23 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" *ngIf="passwordInputType1 == 'text'">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                            <g transform="translate(-906.000000, -381.000000)">
                                <g transform="translate(512.000000, 160.000000)">
                                    <g transform="translate(49.000000, 191.000000)">
                                        <g transform="translate(346.000000, 31.000000)">
                                            <path d="M0,8 C0,8 3.74025974,0 10.2857143,0 C16.8311688,0 20.5714286,8 20.5714286,8 C20.5714286,8 16.8311688,16 10.2857143,16 C3.74025974,16 0,8 0,8 Z" stroke="#15151C" fill="#15151C"></path>
                                            <ellipse stroke="#FFFFFF" fill="#FFFFFF" cx="10.2857143" cy="8" rx="2.80519481" ry="3"></ellipse>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </button>
            </mat-form-field>
        </div>
        <div class="form-row">
            <button class="green" type="submit" [disabled]="((setPassword.trim()  === ''  || setPassword.length < 8) || (confirmPassword.trim() === '' || confirmPassword.length < 8) )" (click)="setNewPassword();">
            Set Password
            </button>
            <app-loader *ngIf="loading" color="#50e3c2"></app-loader>
        </div>
    </form>
</div> -->
<div class="form-wrapper green-theme"  *ngIf="setPass && !forgotPassword && !successReset ">
    <form>
        <div class="form-row">
           <p class="succes-msg">{{value}}</p>
        </div>
        <div class="form-row">
            <button class="green" type="submit" (click)="setPass = false" [disabled]="loading === true">
            Ok
            </button>
           <!-- <app-loader *ngIf="loading" color="#50e3c2"></app-loader> -->
        </div>
    </form>
</div> 

<!-- You’re all set! -->
 <h4 *ngIf="successReset && !setPass">Reset password</h4>

<div class="form-wrapper green-theme" *ngIf="successReset && !setPass">
    <form>
        <div class="form-row">
           <p class="succes-msg">Password reset instructions sent , please check your E-mail.</p>
        </div>
        <div class="form-row">
            <button class="green" type="submit" (click)="successReset = false" *ngIf="!loading">
            Ok
            </button>
            <app-loader *ngIf="loading" color="#50e3c2"></app-loader>
        </div>
    </form>
</div> 



