<div class="manage-client-account">
    <mat-card class="grid-card">
        <div class="section general">
            <div class="title">
                <h3>General<span class="allfields"><small>*All fields required </small></span></h3>
            </div>
            <div class="grid-container">
                <div class="grid-item">
                    <mat-form-field appearance="fill"  [ngClass]="(isUserExist) ? 'addErrorValidation' :''">
                        <mat-label>Username</mat-label>
                        <input type='text' matInput  autocomplete="off" [(ngModel)]="user.ms_username" (change)="checkUsername($event.target.value)">
                    </mat-form-field>
                    <div *ngIf="isUserExist" class="textDanger">
                        This Username already exists.
                      </div>
                </div>
                <div class="grid-item">
                    <mat-form-field appearance="fill">
                      <mat-label>Password</mat-label>
                      <input type="password" matInput  [type]="passwordInputType1" [(ngModel)]="user.raw_password" (change)="passwordChange()">
                      <button class="input-suffix" type="button" matSuffix (click)="togglePasswordInputType()">
                          <svg width="23px" height="18px" viewBox="0 0 23 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" *ngIf="passwordInputType1 == 'password'">
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                                  <g transform="translate(-906.000000, -381.000000)" stroke="#15151C">
                                      <g transform="translate(512.000000, 160.000000)">
                                          <g transform="translate(49.000000, 191.000000)">
                                              <g transform="translate(346.000000, 31.000000)">
                                                  <path d="M0,8 C0,8 3.74025974,0 10.2857143,0 C16.8311688,0 20.5714286,8 20.5714286,8 C20.5714286,8 16.8311688,16 10.2857143,16 C3.74025974,16 0,8 0,8 Z"></path>
                                                  <ellipse cx="10.2857143" cy="8" rx="2.80519481" ry="3"></ellipse>
                                              </g>
                                          </g>
                                      </g>
                                  </g>
                              </g>
                          </svg>
                          <svg width="23px" height="18px" viewBox="0 0 23 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" *ngIf="passwordInputType1 == 'text'">
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                                  <g transform="translate(-906.000000, -381.000000)">
                                      <g transform="translate(512.000000, 160.000000)">
                                          <g transform="translate(49.000000, 191.000000)">
                                              <g transform="translate(346.000000, 31.000000)">
                                                  <path d="M0,8 C0,8 3.74025974,0 10.2857143,0 C16.8311688,0 20.5714286,8 20.5714286,8 C20.5714286,8 16.8311688,16 10.2857143,16 C3.74025974,16 0,8 0,8 Z" stroke="#15151C" fill="#15151C"></path>
                                                  <ellipse stroke="#FFFFFF" fill="#FFFFFF" cx="10.2857143" cy="8" rx="2.80519481" ry="3"></ellipse>
                                              </g>
                                          </g>
                                      </g>
                                  </g>
                              </g>
                          </svg>
                      </button>
                    </mat-form-field>
                </div>
                <div class="grid-item">
                    <mat-form-field appearance="fill">
                        <mat-label>First Name</mat-label>
                        <input matInput [(ngModel)]="user.first_name">
                    </mat-form-field>
                </div>
                <div class="grid-item">
                    <mat-form-field appearance="fill">
                        <mat-label>Last Name</mat-label>
                        <input matInput [(ngModel)]="user.last_name">
                    </mat-form-field>
                </div>
                <div class="grid-item">
                    <mat-form-field appearance="fill"  [ngClass]="(isEmailExist) ? 'addErrorValidation' :''">
                        <mat-label>Email Address</mat-label>
                        <input type='email' matInput [(ngModel)]="user.email" >
                    </mat-form-field>
                    <div *ngIf="isEmailExist" class="textDanger">
                        This Email ID already exists.
                      </div>
                  </div>
                <div class="grid-item">
                    <mat-form-field appearance="fill">
                        <mat-label>Nickname</mat-label>
                        <input matInput [(ngModel)]="user.nick_name">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="section contact">
            <div class="title">
                <h3>Contact<span class="allfields"><small>*All fields required </small></span></h3>
            </div>
            <div class="grid-container">
                <div class="grid-item">
                  <mat-form-field appearance="fill">
                    <mat-label>Address Line 1</mat-label>
                    <input matInput [(ngModel)]="user.address1">
                  </mat-form-field>
                </div>
                <div class="grid-item">
                    <mat-form-field appearance="fill">
                      <mat-label>Address Line 2</mat-label>
                      <input matInput [(ngModel)]="user.address2">
                    </mat-form-field>
                </div>
                <div class="grid-item">
                    <app-country-select [(currentSelection)]="user.country"></app-country-select>
                </div>
                <div class="grid-item">
                    <mat-form-field appearance="fill">
                        <mat-label>City</mat-label>
                        <input matInput [(ngModel)]="user.city">
                    </mat-form-field>
                </div>
                <div class="grid-item">
                    <mat-form-field appearance="fill" *ngIf="shouldShowStateInput()">
                        <mat-label>State or Territory</mat-label>
                        <input matInput [(ngModel)]="user.state">
                    </mat-form-field>
                    <app-state-select [(currentSelection)]="user.state" *ngIf="!shouldShowStateInput()"></app-state-select>
                </div>
                <div class="grid-item">
                    <mat-form-field appearance="fill">
                        <mat-label>Zip</mat-label>
                        <input matInput [patterns]="numberPattern" mask="00000" [(ngModel)]="user.postal_code" minlength="5">
                    </mat-form-field>
                </div>
            </div>
            <div class="rows">
                <div class="phone">
                        <mat-form-field class="type" appearance="fill">
                            <mat-select value='mobile' style="text-transform: capitalize;">
                                <mat-option *ngFor="let type of phoneNumberTypes" [value]="type" style="text-transform: capitalize;">
                                     {{type}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="number" appearance="fill">
                            <input type="tel" matInput class="form-control" [(ngModel)]="user.phone_number" [formControl]="primaryPhoneControl" maxlength="12" (keydown)="validateNumber($event)" (input)="getPhoneCode($event.target)"> 
                        </mat-form-field>
                </div>
                <div class="phone" *ngFor="let number of user.additional_numbers; let i = index;">
                    <mat-form-field class="type" appearance="fill">
                        <mat-select [(ngModel)]="number.number_type"style="text-transform: capitalize;" >
                            <mat-option *ngFor="let type of phoneNumberTypes" [value]="type" style="text-transform: capitalize;">
                                {{type}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="number" appearance="fill">
                        <input type="tel" id={{i}} matInput class="form-control" [(ngModel)]="number.phone_number" [formControl]="phoneControllers[i]" maxlength="12" (keydown)="validateNumber($event, i)" (input)="getPhoneCode($event.target, i)">
                    </mat-form-field>
                    <button class="remove" (click)="removeNumber(i)">
                        <svg width="13px" height="12px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                            stroke-linejoin="round">
                            <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                                <g id="Genre-Options">
                                <g id="Close" transform="translate(1439.000000, 32.000000)">
                                    <path d="M24,0 L0,24" id="Path"></path>
                                    <path d="M0,0 L24,24" id="Path"></path>
                                </g>
                                </g>
                            </g>
                            </g>
                        </svg>
                    </button>
                </div>
                <div class="add-row"><button (click)="addNumber()" class="addnum">+ <u>Add</u></button></div>
            </div>
        </div>
        <div class="section details">
            <div class="title">
                <h3>Details</h3>
                <!-- <h3>Details<span class="allfields"><small>*All fields required </small></span></h3> -->
            </div>
            <div class="checkboxes">
                <mat-checkbox [(ngModel)]="user.can_download">Allow Downloading</mat-checkbox>
                <mat-checkbox [(ngModel)]="user.is_disable">Disable Client</mat-checkbox>
                <mat-checkbox [(ngModel)]="user.can_view_mibe">MIBE Owned Tracks Only</mat-checkbox>
            </div>
            <div class="grid-container">
                <div class="grid-item">
                  <mat-form-field appearance="fill">
                    <mat-label>Company Name</mat-label>
                    <input matInput [(ngModel)]="user.company_name">
                  </mat-form-field>
                </div>
                <div class="grid-item">
                    <mat-form-field appearance="fill">
                      <mat-label>Project Name</mat-label>
                      <input matInput [(ngModel)]="user.project_name">
                    </mat-form-field>
                </div>
                <div class="grid-item">
                    <mat-form-field appearance="fill">
                        <mat-label>Discount Rate (0-100%)</mat-label>
                        <input id="discount" matInput [(ngModel)]="user.discount_rate" type="text"  maxlength="3"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  (keypress)="checkNumber($event)" (keyup)="checkLength()" minlength='0' maxlength='100' required >
                    </mat-form-field>
                </div>
                <div class="grid-item">
                    <mat-form-field appearance="fill">
                        <mat-label>Birthday</mat-label>
                        <input *ngIf="!user.birth_date" [patterns]="numberPattern" mask="00/00/0000" matInput [matDatepicker]="picker" [max]='today' [(value)]="user.birth_date" (dateChange)="birthdayChange($event)" required>
                        <input *ngIf="user.birth_date" [patterns]="numberPattern"  matInput [matDatepicker]="picker" [max]='today' [(value)]="user.birth_date" (dateChange)="birthdayChange($event)" required>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="grid-item">
                    <mat-form-field appearance="fill">
                        <mat-label>Shows</mat-label>
                        <mat-select class ="show-panel" [(value)]="SelectsShow" placeholder="Select" panelClass="show-panel" [disableOptionCentering]="true" [(ngModel)]="user.shows" [compareWith]="showCompareWith" (selectionChange)="selectNewShow($event)" [multiple]="true">
                            <mat-option>
                            <ngx-mat-select-search
                              [searching]="searchingShow"
                              [formControl]="showFilterCtrl"
                              placeholderLabel="Select shows"
                              (keyup)="$event.preventDefault();$event.stopPropagation();getShowList(typeClient,$event.target.value)"
                              noEntriesFoundLabel="No Options Found">
                              <svg ngxMatSelectSearchClear class="filter-clear-icon" width="26px" height="24px" viewBox="0 0 26 24"
                                version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                                  stroke-linejoin="round">
                                  <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                                    <g id="Genre-Options">
                                      <g id="Close" transform="translate(1439.000000, 32.000000)">
                                        <path d="M24,0 L0,24" id="Path"></path>
                                        <path d="M0,0 L24,24" id="Path"></path>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </ngx-mat-select-search>
                          </mat-option>
                          <mat-option *ngFor="let option of filteredShowOptions | async" [value]="option">
                            {{ option.name }}
                          </mat-option>
                        </mat-select>
                      
                      </mat-form-field>
                      
                </div>
            </div>
        </div>
    </mat-card>

    <button class="btn save-btn" mat-button (click)="save()" [disabled]="!canSave()">SAVE</button>
</div>
<div class="loading-overlay" *ngIf="loading">
    <app-loader></app-loader>
</div>