import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../shared/services/user.service';
import { MenuListService } from '../shared/services/menu-list.service';
import { DialogService } from '../shared/services/dialog.service';
import { TosCompComponent } from '../composer/tos/tos.component';
import { User } from '../shared/models/user.model';
import { environment } from '../../environments/environment';
import { UserMenuService } from '../shared/services/user-menu.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loading = false;
  pageLoader = false;
  valid = false;
  enableLogin = false;
  value =''
  passwordInputType = 'password';
  passwordInputType1 = 'password';
  loginModel = {
    email: '',
    password: '',
    rememberMe: false,
  };
  errors: string[] = [];
  forgotPassword: boolean = false;
  forgotEmail = '';
  successReset: boolean = false;
  setPass: boolean = false;
  resultData: boolean = false;
  setPassword = '';
  confirmPassword = '';


  constructor(private _userService: UserService, public api: UserMenuService, public _DialogService: DialogService, private _router: Router, public list: MenuListService, private dialog: DialogService, public activateRouter: ActivatedRoute) {
    this.list.menuheader = false;
    this._userService.token = null
    localStorage.setItem("token", null);

  }

  ngOnInit(): void {

    this._userService.currentUser = null;
    localStorage.setItem("userdata", null);

    this.activateRouter.queryParams.subscribe(x => {
      if (x.client) {
        
        this.pageLoader = true
        this._userService.tokenSubject.next(x.client)
        this._userService.token = x.client
        localStorage.setItem("token", x.client);
        this._userService.refreshCurrentUser().subscribe(y => {
          this.list.menuheader = true;
          this.pageLoader = false
          // if(x.playlist){
          //   window.location.href = `${environment.host}/playlists?playlist=${691}&ordering_by=id`
          // }
          if (this._userService.currentUser.is_artist === true && this._userService.currentUser.tos_status !== 'Accepted') {
            //  this.dialog.displayDeleteUsergroupDialog(TosCompComponent)
            window.location.href = `${environment.host}/composer/term-condition`
          }
          else if (this._userService.currentUser.is_artist === true && this._userService.currentUser.tos_status === 'Accepted') {
            window.location.href = `${environment.host}/composer/my-tracks`
          }
          else if (this._userService?.currentUser?.is_superuser == true) {
            //this._router.navigate(['/dashboard']);
            window.location.href = `${environment.host}/playlists?playlist=${x.playlist}&ordering_by=id`
          }
          else {
            if (this._userService.currentUser.is_artist === false && this._userService.currentUser.is_client === false)
              window.location.href = `${environment.host}/`
          }
        })
      }

    })
  }

  togglePasswordInputType(type: any = "") {
    if (type === 'confirm') {
      if (this.passwordInputType1 == 'password') {
        this.passwordInputType1 = 'text';
      } else {
        this.passwordInputType1 = 'password';
      }
    }
    else {
      if (this.passwordInputType == 'password') {
        this.passwordInputType = 'text';
      } else {
        this.passwordInputType = 'password';
      }
    }
  }

  login(type:any='') {
    this.errors = [];
    this.loading = true;
    this.loginModel.email = this.loginModel.email.replace(/^(?=\n)$|^\s*|\s*$|\n\n+/gm, "");
    if(this.loginModel.email.trim() !== ''){
    this.valid = this.validateEmail(this.loginModel.email.trim());
    }
    if (this.loginModel.email.trim() !== '' && this.resultData === false) {
      if(this.valid && type == ''){
      this.checkEmail(this.loginModel.email, 'submit','email')
      }
      if(!this.valid && type == ''){
        this.checkEmail(this.loginModel.email, 'submit' , 'user')
      }
     
    }
    // if(type === 'set'){
    //   this.valid = true
    // }
    if (this.resultData === true && this.loginModel.email.trim() !== '' && this.loginModel.password.trim() !== '') {
      this._userService
        .loginUser(
          this.loginModel.email,
          this.loginModel.password,
          this.loginModel.rememberMe,
          this.valid,
        )
        .subscribe(
          () => {
            if (this._userService.currentUser) {
              this._userService.getAuthToken(
                this._userService.currentUser.email.trim(),
                this.loginModel.password.trim(),
                this.loginModel.rememberMe,
              
              )
                .subscribe(
                  () => {
                    this.loading = false;
                    this.list.menuheader = true;
                    if (this._userService.currentUser.is_artist === true && this._userService.currentUser.tos_status !== 'Accepted') {
                      //  this.dialog.displayDeleteUsergroupDialog(TosCompComponent)
                      this._router.navigate(['/composer/term-condition']);
                    }
                    else if (this._userService.currentUser.is_artist === true && this._userService.currentUser.tos_status === 'Accepted') {
                      this._router.navigate(['/composer/my-tracks']);
                    }
                    else if (this._userService?.currentUser?.is_superuser == true) {
                      this._router.navigate(['/dashboard']);
                    }
                    else {
                      if (this._userService.currentUser.is_artist === false && this._userService.currentUser.is_client === false)
                        this._router.navigate(['/']);
                    }

                  },
                  (err) => {
                    this.loading = false;
                    if (err.error.detail) {
                      // alert('User account is inactive or disabled. Please contact the MIBE representative.')
                      let data = err.error?.error ? err.error?.error : (err.error?.detail ? err.error?.detail : (err.error?.details ? err.error?.details : ``))
                      this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
                      });

                    }
                    if (err.error.error) {
                      this.errors.push(err.error.error)
                    }
                    else {
                      this.errors.push('Incorrect Email or Password');
                    }

                    console.error(err);
                  }
                );
            }
            else {
              this.loading = false;
              this.errors.push('Please enter a valid username and password');
            }
          },
          (err) => {
            this.loading = false;
            this.resultData = false;
            // this.errors.push('Incorrect Email or Password');
            if (err.error.detail) {
              // alert(err.error.detail)
              let data = err.error?.error ? err.error?.error : (err.error?.detail ? err.error?.detail : (err.error?.details ? err.error?.details : ``))
              this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
              });
            }
            if (err.error.error) {
              if (err.error?.is_available) {
                let data = err.error?.error ? err.error?.error : (err.error?.detail ? err.error?.detail : (err.error?.details ? err.error?.details : ``))
                this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
               });
              }
              else {
                // let data = err.error?.error ? err.error?.error : (err.error?.detail ? err.error?.detail : (err.error?.details ? err.error?.details : ``))
                // this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
                // //  this.checkEmail(this.loginModel.email)
                // });
                this.errors.push(err.error.error)
              }
            }
            else {
              this.errors.push('Incorrect Email or Password');
            }

          }

        );
    }
    else {
      this.loading = false;
    }
  }

  forgotPasswordLogin(value:any='') {
    this.errors = [];
    this.loading = true;
    this._userService.forgotPassword(
      this.forgotEmail,
    )
      .subscribe(
        (data) => {
          if(value === 'check'){
            localStorage.setItem("forgotEmail", this.forgotEmail);
            this.loading = false;
          }
          else{
            console.log(data)
            localStorage.setItem("forgotEmail", this.forgotEmail);
            this.forgotPassword = false;
            this.successReset = true;
            this.loading = false;
          }
        },
        (err) => {
          this.loading = false;
          this.errors.push('Incorrect Email');
          console.error(err);
        }
      );
  }

  checkEmail(event, type: string = '', type1: string = '') {
    this.errors = [];
    if(type1 ===''){
     let valid = this.validateEmail(this.loginModel.email.trim());
     if(valid === true){
       type1 = 'email'
     }
     else{
       type1='user'
     }
    }
    var checkbox = document.getElementById('checkbox1');
    function handleCheckboxEvent(e) {
      e.preventDefault();
      if (e.keyCode === 32) {
        this.checked = !this.checked;
      }
    }
    checkbox.addEventListener("click", handleCheckboxEvent, true);
    checkbox.addEventListener("keyup", handleCheckboxEvent, true);

    if (event.trim() !== '') {
      //    this.loading = true;
      let url = environment.apiURL + `/api/v1/admin/users/check-last-login/`
      let data
      if (type1 === 'email') {
        data = { 'email': event.trim() }
      }
      else {
        data = { 'username': event.trim() }
      }
      this.api.doPOST(url, data).subscribe((result: any) => {
        this.setPass = false;
        this.enableLogin = true;
        this.resultData = true;
        if (this.loginModel.password !== '' && type === 'submit') {
          this.login()
        }
      },
        (error) => {
          this.enableLogin = false;
          this.loading = false;
          if (error.error?.is_available) {
            // this.errors.push(error.error.error)
            let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
            this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
            });
          }
          else {
            if(error?.error?.email){
              this.setPass = true
              this.forgotEmail =  error?.error?.email;
              this.value = error?.error?.error
              this.forgotPasswordLogin('check')
            }
            else{
            let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
            this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
              if(error?.error?.email){
              this.setPass = true;
              this.forgotEmail =  error?.error?.email;
              this.value = error?.error?.error
              this.forgotPasswordLogin('check')
              }
              else{
                this.setPass = false;
              }
             
            });
            if (type1 !== 'email') {
              this.loginModel.email = error?.error?.email
            }
           // this.setPass = true
          }
          }
        });
    }
  }

  setNewPassword() {
    if (this.setPassword !== this.confirmPassword) {
      this.errors = [];
      this.errors.push("Password and Confirm password must be same!")
    }
    else {

      this.loading = true;
      if (this.loginModel.email.trim() !== '' && this.setPassword.trim() !== '') {
        let url = environment.apiURL + `/api/v1/admin/users/set-new-password/`
        this.api.doPUT(url, { 'email': this.loginModel.email.trim(), 'password': this.setPassword.trim() }).subscribe((result: any) => {
          this.loginModel.password = this.setPassword
          this.loginModel.email = result.email
          this.resultData = true
          this.login('set')
        },
          (error) => {
            let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
            this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
            });
            this.resultData = false;
            this.loading = false;
          });
      }
    }
  }
  validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }
}
