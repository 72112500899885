import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Pipe, PipeTransform } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { WaveService } from 'angular-wavesurfer-service';
import { TrackVersion } from '../models/trackversion.model';
import { PlayerService } from '../services/player.service';
import { SliderDialogService } from '../services/slider-dialog.service';

@Pipe({
  name: 'minuteSeconds'
})
export class MinuteSecondsPipe implements PipeTransform {
  transform(value: number): string {
    let numhours = Math.floor(value / 3600);
    let numminutes = Math.floor((value % 3600) / 60);
    let numseconds = Math.round((value % 3600) % 60);
    let str = '';
    if (numhours) {
      str = this.zeroPad(numhours, 2) + ':' + this.zeroPad(numminutes, 2) + ':' + this.zeroPad(numseconds, 2);
    } else {
      str = this.zeroPad(numminutes, 2) + ':' + this.zeroPad(numseconds, 2);
    }
    return str;
  }
  private zeroPad(num, minLength): string {
    let numStr = '' + num;
    while (numStr.length < minLength) {
      numStr = '0' + numStr;
    }
    return numStr;
  }
}
@Component({
  selector: 'app-preview-waveform',
  templateUrl: './preview-waveform.component.html',
  styleUrls: ['./preview-waveform.component.scss'],
  providers: [WaveService]
})
export class PreviewWaveformComponent implements OnInit {

  @Input()
  set version(v: TrackVersion) {
    this._version = v;
    this.url = this._version.mp3_file
    if (!v) { return; }
  }
  get version(): TrackVersion {
    return this._version;
  }

  replace: boolean = false
  @Input()
  trackProgress: number;
  // @Input()
  url: string;
  nexturl: string;
  @Input()
  wavesurfer: WaveSurfer = null;

  set isViewType(p: boolean) {
    this.preview = p;
  }
  get isViewType(): boolean {
    return this.preview;
  }
  loading= false;

  // set playUrl(p: string){
  //   if(p !== null || p !== ''){
  //     this.url = p
  //   }
  // }
  // get playUrl(): string {
  //   return this.url;
  // }


  @Output()
  trackProgressChange = new EventEmitter();
  private _version: TrackVersion;

  @Output() previewWaveCurrent = new EventEmitter();
  @Output() waveDuration = new EventEmitter();



  //wave: WaveSurfer = null;
  private _subscriptions: Subscription[] = [];
  preview = false;
  isPlaying =false;
  current =0;
  duration = 0;

  constructor(private cdr: ChangeDetectorRef, private _playerService: PlayerService, private _sliderDialogService: SliderDialogService, public waveService: WaveService) { }
  ngOnInit() {
    this._sliderDialogService.getReplaceTrackVersion().subscribe((val) => {
      if (val && val == 'replace') {
        this.replace = true;
      }
    });
    if(this.replace === false){
   

    this._subscriptions.push(
      this._playerService.isViewType.subscribe((p) => {
        this.preview = p;
      })
    );
    if( this.url !== null && this.wavesurfer !== null){
    this._subscriptions.push(
      this._playerService.previewPlaying.subscribe((p) => {
        if (p === false && this.preview === false ) {
          this.wavesurfer.stop()
        }
        else {
          if (p == false && this.preview === true) {
            this.wavesurfer.stop();
          }
          else {
            this.wavesurfer.play()
            this.wavesurfer.on('finish', () => {
              if (this.wavesurfer.isPlaying() === false) {
                this.isPlaying =false;
                this.wavesurfer.stop()
                this.cdr.detectChanges();
              }
            });
          }

        }

        //    this._subscriptions.push(
        //    this._playerService.playUrl.subscribe((p) => {
        //     if( p !== null || p !== ""){
        //     this.url = p;
        //     }
        //   })
        // );
      })
    )
    }
    if (this.wavesurfer === null && this.url !== null) {
      this.wavesurfer = this.waveService.create({
        container: '#previewWaveform',
        waveColor: 'white',
        height: 50,
        hideScrollbar: true,
        barGap: 3,
        barWidth: 1,
        progressColor: 'gray',
        backend: 'MediaElement',
        responsive: true
      });
      this.generateWaveform();
      this.cdr.detectChanges();

      Promise.resolve().then(() => this.wavesurfer.load(this.url));
    }
    }



  }
  generateWaveform(): void {
    Promise.resolve(null).then(() => {
      this.wavesurfer.on('ready', () => {
        this.loading = true
       // this.wavesurfer.play();
        this.duration =this.wavesurfer.getDuration();
        this.waveDuration.next(this.wavesurfer.getDuration())
        this.cdr.detectChanges();
        //this.wavesurfer.isPlaying()
        this.isPlaying = false;
      });
      if (this.preview === false) {
        this.wavesurfer.on('audioprocess', () => {
          this.current = this.wavesurfer.getCurrentTime()
          this.previewWaveCurrent.next(this.wavesurfer.getCurrentTime());
          this.cdr.detectChanges();
          this.wavesurfer.on('finish', () => {
            if (this.wavesurfer.isPlaying() === false) {
              this.isPlaying = false;
              this.current = 0
              this.wavesurfer.stop()
              this.cdr.detectChanges();
            }
           
          });
        });
      }
      else {
        this.wavesurfer.on('audioprocess', () => {
          this.current = this.wavesurfer.getCurrentTime()
          this.previewWaveCurrent.next(this.wavesurfer.getCurrentTime());
          this.cdr.detectChanges();
          this.wavesurfer.on('finish', () => {
            this.isPlaying = false;
            this.current = 0
            this.wavesurfer.stop()
            this.cdr.detectChanges();

            //  this._playerService.updateIsPlaying(false);
          })
        })
      }

    });
  }
  ngOnDestroy() {
    this._subscriptions.forEach((s) => {
      s.unsubscribe();
    });
    if(this.wavesurfer !== null){
    this.wavesurfer.stop()
    }
    this._playerService.nextTab(false);
    this._playerService.updateViewType(false);
    this._playerService.updateShowMusicPlayer(false);
    this._playerService.play(null, null);
    // this.wave.destory()
  }
  getCurrentPreview() {
    setTimeout(()=>{  
      this.current = this.wavesurfer.getCurrentTime()
      this.cdr.detectChanges();
    }, 100);
    this.previewWaveCurrent.next(this.wavesurfer.getCurrentTime());
  }

  play() {
    this.isPlaying =true;
    this.wavesurfer.play();
    //this._playerService.updatePreviewPlaying(true);
  }
  pause() {
    this.isPlaying = false;
    this.wavesurfer.playPause();
  //  this._playerService.updatePlayListType(false);
  }
}
