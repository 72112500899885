import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MenuListService } from '../../shared/services/menu-list.service';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-remove-track-dialogue',
  templateUrl: './remove-track-dialogue.component.html',
  styleUrls: ['./remove-track-dialogue.component.scss']
})
export class RemoveTrackDialogueComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<RemoveTrackDialogueComponent>,public list: MenuListService, private config: MenuConfigService, private api: UserMenuService) { }
  currentTrack:any;
  loading: boolean = false;

  ngOnInit(): void {
    this.list.getSingleUploadedTrack().subscribe(x=>{
      if(x){
        this.currentTrack = x;
        console.log(this.currentTrack)
      }
    })
  }
  close() {
    this.dialogRef.close();
  }
  delete(){
    this.loading = true;
    let requestUrl = environment.apiURL + this.config.uploadedTracksapi + this.currentTrack.id + '/'
    this.api.doDelete(requestUrl).subscribe(x=>{
      this.loading = false;
      this.dialogRef.close();
      this.list.uploadedTracksDetails = x && x['results'] || [];
    },
    (error) =>{
     
      this.loading = false;
      //alert(error.message)
    }
  )
  }
  
}
