import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { environment } from 'src/environments/environment';
import { Playlist } from '../models/playlist.model';
import { DialogService } from '../services/dialog.service';
import { PlaylistService } from '../services/playlist.service';
import { UserMenuService } from '../services/user-menu.service';

export interface DialogData {
  playlist?: Playlist;
  id:any;
  type:any;
  data:any
}

@Component({
  selector: 'app-delete-playlist-dialog',
  templateUrl: './delete-playlist-dialog.component.html',
  styleUrls: ['./delete-playlist-dialog.component.scss']
})
export class DeletePlaylistDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<DeletePlaylistDialogComponent>,
    private _playlistService: PlaylistService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,public api: UserMenuService,public dialog: DialogService,
  ) {}

  loading = false;

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close(false);
  }

  delete(type:any='') {
    if(type === ''){
    if (!this.data.playlist) return;
    this._playlistService.deletePlaylist(this.data.playlist.id).subscribe(()=> {
      this.dialogRef.close(true);
    }, (error) => {
      console.log(error);
      let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
      this.dialog.UserUploadPopupConfirmPopup(data).subscribe(changed => {
      });
      this.dialogRef.close(true);
      //alert("An error ocurred while deleting the playlist. Check your connections and try again");
    });
  }
    else {
      let url = environment.apiURL + `/api/v1/admin/playlists/` + this.data.id + `/remove-track/`
      this.api.doPUT(url, this.data.playlist).subscribe(x => {
        this.dialogRef.close(true);
      },
        (error) => {
          console.log(error);
          let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
          this.dialog.UserUploadPopupConfirmPopup(data).subscribe(changed => {
          });
          this.dialogRef.close(true);
        }
      )
    }
  }

}
