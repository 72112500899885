<div class="main">
    <div class="sidebar">
        <div class="top-holder">

            <form action="#" class="search-filter" #searchForm="ngForm" [ngClass]="{'search-filter-focused': searchFilterFocused}">
                <label for="search-filter-field" class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14">
                        <path fill="none" stroke="#fff" stroke-linecap="round"
                            stroke-linejoin="round" stroke-miterlimit="20" d="M6 1a5 5 0 110 10A5 5 0 016 1zM13.002 13.002L9.535 9.535"
                        /></svg>
                </label>
                <input [(ngModel)]="_searchString" name="searchString" type="text" class="txt" id="search-filter-field" (focus)="searchFilterFocused = true"
                    (keyup)="getFindUser()" placeholder="Find a User" />
            </form>
        </div>
        <div class="top-holder">
            <div class="templates-info">
                <strong> {{this.list?.allMenuUser?.length}} Profiles</strong>
                <button class="button" (click)="newUser()">+ New User</button>
            </div>
        </div>
        <div class="playlists-holder">
                <app-loader *ngIf="this.list.loadingUser"></app-loader>
            <ul class="playlists" *ngIf="!this.list.loadingUser">
              <li class="playlist"  *ngFor="let user of list.allMenuUser" (click)="list.currentAdminUserSubject.next(user)" [ngClass]="{'active': currentSelect?.id === user?.id}">{{user.first_name}} {{user.last_name}}</li>
            </ul>
          </div>
        <div class="templates-holder">
            <ul class="templates">
                <!-- <li class="template" [ngClass]="{'active': currentTemplate && template && currentTemplate.id == template.id}" *ngFor="let template of templates; trackBy:templateTrackBy" (click)="currentTemplate = template">
                      {{template.getDisplayName()}}
                    </li> -->
            </ul>
        </div>
    </div>
    <div class="user-list">
        <div class="header">
            <h3>{{currentSelect?.first_name}}</h3>
            <div class="btns">
                <button class="button" (click)="deleteUser()">Delete User</button>
            </div>
        </div>
        <div class="menu-user-info">
        <app-user-edit-form ></app-user-edit-form>
        </div>
    </div>
</div>