import {Component, Input, OnInit, Output, EventEmitter, ViewChild} from '@angular/core';
import {TrackComposer} from '../models/trackcomposer.model';
import {Observable, ReplaySubject, Subscription, of} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {CoWriterService} from '../services/cowriter.service';
import { FormControl } from '@angular/forms';
import { tap, startWith, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { MatSelectChange, MatSelect } from '@angular/material/select';
import { environment } from '../../../environments/environment';
import { UserMenuService } from '../services/user-menu.service';
import { MatOption } from '@angular/material/core';
import { TrackService } from '../services/track.service';
import { PlaylistService } from '../services/playlist.service';
import { MenuListService } from '../services/menu-list.service';

export interface CoWriter {
  composer_name: string;
  composer_percentage: number;
  new: boolean;
}
interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-manage-cowriters',
  templateUrl: './manage-cowriters.component.html',
  styleUrls: ['./manage-cowriters.component.scss']
})



export class ManageCowritersComponent implements OnInit {

  gridApi;
  frameworkComponents: any;
  panelOpenState: boolean;
  get noOfCoWriters(): number {
    if (!this.composers) return 0;
    return this.composers.length;
  }
@ViewChild('coWriter') coWriter:MatSelect
  pervalue : number = 0;
  currentlyOpen:number = null;


  // @ViewChild('agGrid') gridApi: AgGridAngular;

  coWriterFilterCtrl = new FormControl();
  coWriterForm = new FormControl();
  filteredCoWriterOptions: ReplaySubject<TrackComposer[]> = new ReplaySubject<TrackComposer[]>(1);
  allCoWritersOptions: ReplaySubject<TrackComposer[]> = new ReplaySubject<TrackComposer[]>(1);
  searchingCoWriter = false;
  coWriterDetail: any;
  limit: number = 0;
  unlimit: boolean = false;
  defaultCount = 0;
  type:any = 1

  @Input() 
  set composers(c: TrackComposer[]) {
    this._composers = c;
    this.dataSource.data = this._composers;
    this.composersChange.emit(this._composers);
  };
  get composers():TrackComposer[] {
    return this._composers;
  }

  @Output()
  composersChange = new EventEmitter<TrackComposer[]>()

  allCoWriters: TrackComposer[] = [];
  dataSource = new MatTableDataSource(this.composers);

  private _composers: TrackComposer[] = [];
  private _subscriptions: Subscription[] = [];

  constructor(
    private _coWriterService: CoWriterService,
    public api: UserMenuService, public list: MenuListService,
    public track: TrackService,private _playlistService: PlaylistService,
  ) {
  //  this.getcoWriters();
  }
lengthOfCo:any;
  ngOnInit() {
    let newCowriterValue=[]
    this.getTrackDetail(this.list.trackDetail.id)
    this.defaultCount = 0;
    this.track.cowritten_by = this.list.trackDetail.track.cowritten_by;
    let newData = this.list.trackDetail.track.default_cowritten_by
    for(let j=0;j<newData.length;j++){
      this.track.cowritten_by.push({
        id: newData[j].id,
        name: newData[j].name,
        percent: newData[j].percentage_allocated,
        user_id:(newData[j].id+9999),
        disable:true
      })
      newCowriterValue.push({
        id: newData[j].id,
        name: newData[j].name,
        percent: newData[j].percentage_allocated,
        user_id:(newData[j].id+9999),
        disable:true
      })
      this.defaultCount = this.defaultCount + newData[j].percentage_allocated

    }
    let data = this.track.cowritten_by.filter((v,i,a)=>a.findIndex(t=>(t.id===v.id))===i)
    let valueCo = []
    for(let i=0;i< data.length;i++){
      if(data[i].id){
        valueCo.push(data[i])
      }
    }
    
    this.track.cowritten_by = valueCo
    this.getcoWritersDetail(this.list.trackDetail.artist.user_id)
 
    this._subscriptions.push(this.coWriterFilterCtrl.valueChanges.pipe(
      startWith(''),
      distinctUntilChanged(),
      tap(() => {
        this.searchingCoWriter = true;
      }),
      switchMap((v) => {
        if (!v) {
          return of(this.allCoWriters)
        }
        return of(this.allCoWriters.filter(c=> {
          return c.composer_name.toLowerCase().includes(v.toLowerCase()) || c.publisher_name.toLowerCase().includes(v.toLowerCase());
        }))
      }),
      tap(() => {
        this.searchingCoWriter = false;
      })
    ).subscribe(c => {
      this.filteredCoWriterOptions.next(c);
    }));
   // this.cowriterData = this.cowriterData.concat(newCowriterValue)
  }
  addco(i){
  
   
   }
   getTrackDetail(id){
     let newCowriterValue = [];
     this.track.cowritten_by=[]
    // this.defaultCount = 0;
   //  this._playlistService.getTopTrackPlaylist(id).subscribe(res => {
      let data = this.list.trackDetail || []
      this.track.cowritten_by = data.track['cowritten_by'];
      let newData = data.track['default_cowritten_by']
      for(let j=0;j<newData.length;j++){
        this.track.cowritten_by.push({
          id: newData[j].id,
          name: newData[j].name,
          percent: newData[j].percentage_allocated,
          user_id:(newData[j].id+9999),
          disable:true
        })
        newCowriterValue.push({
          id: newData[j].id,
          name: newData[j].name,
          percent: newData[j].percentage_allocated,
          user_id:(newData[j].id+9999),
          disable:true
        })
      }
      let data1 = this.track.cowritten_by.filter((v,i,a)=>a.findIndex(t=>(t.id===v.id))===i)
      let valueCo = []
      for(let i=0;i< data1.length;i++){
        if(data1[i].id){
          valueCo.push(data1[i])
        //  this.defaultCount = this.defaultCount + data1[i].percent;

        }
      }
      this.track.cowritten_by = valueCo
   // })
  }
  addElement() {
    this.composers.push(new TrackComposer(null));
    this.dataSource = new MatTableDataSource(this.composers);
  }
  removeAt(index: number) {
    
    const c = this.composers;
    c.splice(index, 1);
    this.composers = c
  }
  getcoWritersDetail(id){
    let data: any
    let url = environment.apiURL + `/api/v1/admin/users/${id}/`;
   // this.api.doGET(url).subscribe(x=>{
      data = this.list.userDetail || [];
      let newCowriter = []
      for (let j = 0; j < data.allowed_cowriters.length; j++) {
        data.allowed_cowriters[j].disable = false;
      }
      for (let i = 0; i < this.list.trackDetail.track.default_cowritten_by.length; i++) {
        newCowriter.push({
          email: "",
          first_name: this.list.trackDetail.track.default_cowritten_by[i].name,
          id: this.list.trackDetail.track.default_cowritten_by[i].id,
          is_active: true,
          last_name: "",
          percent: this.list.trackDetail.track.default_cowritten_by[i].percentage_allocated,
          user_id: (this.list.trackDetail.track.default_cowritten_by[i].id + 9999),
          disable: true
        })
      }
      this.coWriterDetail = data.allowed_cowriters.concat(newCowriter)
      this.limit = data.cowriter_quanity_limit + newCowriter.length
    //  this.unlimit = data.unlimited_cowriters
    this.unlimit = false;
      if(this.unlimit){
        this.getcoWriters();
        this._coWriterService.getCoWriters().subscribe(coWriters => {
          this.allCoWriters = coWriters;
          this.allCoWritersOptions.next(this.allCoWriters);
          this.filteredCoWriterOptions.next(this.allCoWriters);         
        }, (error) => {
          console.log(error);
          //alert("An error ocurred while getting cowriters. Check your connections and try again");
        });
       
      }
      else{
        this.allCoWriters = this.coWriterDetail;
        this.allCoWritersOptions.next(this.allCoWriters);
        this.filteredCoWriterOptions.next(this.allCoWriters);
        this.coWriters= this.coWriterDetail;
        for(var co of this.coWriters){
          co['percent'] = 0;
          co['name'] = co.first_name+co.last_name
         }
      }
      for (let data of this.list.trackDetail.track.cowritten_by) {
        if(this.coWriters && this.coWriters.length> 0){
        for (let val of this.coWriters) {
          if (this.unlimit) {
            if (data.user_id === val.user_id) {
              val.percent = data.percent;
              this.cowriterData.push(val)
            }
          }
          else {
            if (data.user_id === val.id) {
              val.percent = data.percent;
              this.cowriterData.push(val)
            }
            // if(val.id === 2){
            //   this.cowriterData.push(val)
            // }
            // if(val.id ===1){
            //   this.cowriterData.push(val)
            // }
          
          }
        }
      }
      }
      this.cowriterData = this.cowriterData.concat(newCowriter)
      console.log(this.cowriterData)
  //   },
  //   (error) => {
  //  // })
  }

  // Workaround for angular component issue #13870
  disableAnimation = true;
  displayedColumns: string[] = ['user', 'percent', 'actions'];
  ngAfterViewInit(): void {
    // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => this.disableAnimation = false);
       let valueCo = []
        let data = this.track.cowritten_by.filter((v,i,a)=>a.findIndex(t=>(t.id===v.id))===i)
        for(let i=0;i< data.length;i++){
          if(data[i].id){
            valueCo.push(data[i])
          }
        }
        this.track.cowritten_by = valueCo
    for(let data  of this.track.cowritten_by){
      this.coWriter.options.map((x:MatOption) =>{
        if(x.value.id == data.user_id){
          x.select();
          x.value.percent = data.percent;
          // this.coWriterList.splice(i, 1)
        }
        // if(data.id === 2){
        //   x.select();
        //   x.value.percent = data.percent;
        // }
        // if(data.id === 1){
        //   x.select();
        //   x.value.percent = data.percent;
        // }
      }
      )
  
    }

  }
  ngAfterContentChecked(): void{
    
  }

  openChangedAt(open:boolean, i:number) {
    if (open) {
      this.currentlyOpen = i;
    } else {
      if (this.currentlyOpen === i) {
        this.currentlyOpen = null;
      }
    }
  }


  

  filteredCoWriterOptionsAt(index:number): ReplaySubject<TrackComposer[]> {
    if (this.currentlyOpen == index) {
      return this.filteredCoWriterOptions;
    }
    return this.allCoWritersOptions;
  }

  coWriterChangedAt(change:MatSelectChange, index:number) {
    let p = this.composers[index].composer_percentage;
    this.composers[index] = change.value;
    this.composers[index].composer_percentage = p;
    this.composersChange.emit(this._composers);
  }
  percentageChangedAt(value, index) {
    this.composers[index].composer_percentage = value;
    this.composersChange.emit(this._composers);
  }

  coWriterCompareWith(c1: TrackComposer, c2: TrackComposer) {
    if (!c1 || !c2) {
      return c1 == c2;
    }
    return c1.composer_id == c2.composer_id;
  }

  coWriterTrackBy(i: number, coWriter: TrackComposer) {
    if (!coWriter) {
      return coWriter;
    }
    return i + '-' + coWriter.composer_id;
  }
  coWriters:any=[];
  coWriterList:any;
  co_editDup: any;
  cowriterData: any =[];
  coWriterData1:any=[]
  getcoWriters(){
    let requestUrl = environment.apiURL + `/api/v1/track/artist-dropdown/`
    this.api.doGET(requestUrl).subscribe(x =>{
      this.coWriterData1 = x;
      if(this.coWriterData1.length > 0){
      for (let i = 0; i < this.coWriterData1.length; i++) {
        if(this.coWriterData1[i]){
          this.coWriterData1[i].disable = false;
        this.coWriters.push(this.coWriterData1[i])
        }
      }
    }
      let id = this.list.trackDetail.artist.id ;
      let newValue = [];
      for(let i=0;i< this.list.trackDetail.track.default_cowritten_by.length;i++){
        this.coWriters.push({
          id: this.list.trackDetail.track.default_cowritten_by[i].id,
          name: this.list.trackDetail.track.default_cowritten_by[i].name,
          percent: this.list.trackDetail.track.default_cowritten_by[i].percentage_allocated,
          user_id:(this.list.trackDetail.track.default_cowritten_by[i].id+9999),
          disable: true
        })
        newValue.push({
          id: this.list.trackDetail.track.default_cowritten_by[i].id,
          name: this.list.trackDetail.track.default_cowritten_by[i].name,
          percent: this.list.trackDetail.track.default_cowritten_by[i].percentage_allocated,
          user_id:(this.list.trackDetail.track.default_cowritten_by[i].id+9999),
          disable: true
        })
      }

      this.coWriters = this.coWriters.filter(function(e) { 
        if(e.id !== id){
          return e;
        }
      })
      for(var co of this.coWriters){
            co['percent'] = 0;
            co['disable'] = false;
      }
      if(this.unlimit){
        for(let data  of this.list.trackDetail.track.cowritten_by){
          for (let val of this.coWriters) {
            if (data.user_id === val.user_id) {
              val.percent = data.percent;
              val.disable = true;
              this.cowriterData.push(val)
            }
          }
        }
      }
      else{
        for(let data  of this.list.trackDetail.track.cowritten_by){
          for (let val of this.coWriters) {
            if (data.user_id === val.id) {
              val.percent = data.percent;
              this.cowriterData.push(val)
            }
          }
        } 
      }

      setTimeout(() => {
        for(let data  of this.list.trackDetail.track.cowritten_by){
          this.coWriter && this.coWriter.options.map((x:MatOption) =>{
            if (!this.unlimit) {
              if (x.value.id == data.user_id) {
                x.select();
                x.value.percent = data.percent;
              }
              // if(x.value.id == 1 ){
              //   x.select();
              //   x.value.percent = data.percent;
              // }
              // if( x.value.id === 2){
              //   x.select();
              //   x.value.percent = data.percent;
              // }
            }
            else{
              if (x.value.user_id == data.user_id) {
                x.select();
                x.value.percent = data.percent;
              }
              // if(x.value.id == 1 ){
              //   x.select();
              //   x.value.percent = data.percent;
              // }
              // if( x.value.id === 2){
              //   x.select();
              //   x.value.percent = data.percent;
              // }
            }
          }
          )
          for (let val of this.coWriters) {
            if(this.unlimit){
              if (data.user_id === val.user_id) {
                val.percent = data.percent;
                this.cowriterData.push(val)
              }
              // if(val.id == 1 && val.id === 2){
              //   val.percent = data.percent;
              //   this.cowriterData.push(val)
              // }
            }
            else{
              if (data.user_id === val.id) {
                val.percent = data.percent;
                this.cowriterData.push(val)
              }
              // if(val.id == 1 && val.id === 2){
              //   val.percent = data.percent;
              //   this.cowriterData.push(val)
              // }
            }
         
          }
        }
      },500)
      let newCoWriterVal=[]
      if(this.unlimit){
        // for(let i=0;i< this.coWriter.value.length ;i++){
        //   if(this.coWriter.value[i].id !== 1  && this.coWriter.value[i].id !== 2 ){
        //   newCoWriterVal.push(this.coWriter.value[i])
        //   }
        // }
        this.coWriter = this.coWriter && this.coWriter.value.filter(function (e) {
          if (e.id !== 1 ) {
            return e;
          }
        })
        this.coWriter = this.coWriter && this.coWriter.value.filter(function (e) {
          if (e.id !== 2 ) {
            return e;
          }
        })
        this.cowriterData = this.coWriter;
      }
      else{
        this.cowriterData = this.coWriter;
      }
      
    })
  }
  Changeddesc(e){

  }
  remove(i){
    // this.coWriter.options.map((x:MatOption) =>{
     
    //   if(x.value.id == i){
        
    //     x.deselect();
    //     // this.coWriterList.splice(i, 1)
    //   }
    // }
    // )
    console.log(this.cowriterData,  this.track.cowritten_by)
    this.track.cowritten_by  = this.track.cowritten_by.filter(function(e) { 
      if(e.id !== i.id){
        return e;
      }
    })
    let valueCo = []
    let data = this.track.cowritten_by.filter((v,i,a)=>a.findIndex(t=>(t.id===v.id))===i)
    for(let i=0;i< data.length;i++){
      if(data[i].id){
        valueCo.push(data[i])
      }
    }

    this.track.cowritten_by = valueCo
    this.cowriterData =  this.cowriterData.filter(function (e) {
      if (e.id !== i.id ) {
        return e;
      }
    })
    this.cowriterData =  this.cowriterData.filter(function (e) {
      if (e.id !== i.user_id ) {
        return e;
      }
    })
   // this.cowriterData = this.coWriter;
   // this.cowriterData = this.track.cowritten_by 
  }
  minus(e,i){
    if(e > 0){
      this.track.cowritten_by[i].percent = Number(e) - 1
      return Number(e) - 1
    }
    else{
      this.track.cowritten_by[i].percent = 0
    return 0
    }
   
  }
  plus(e,i){
    var totalPercentage = 0;
    if(e <= (100 - (this.defaultCount))){
      this.track.cowritten_by[i].percent = Number(e) + 1
      if(this.track.cowritten_by.length > 0){
        for(let value of this.track.cowritten_by)
        {
          totalPercentage += value.percent;
        }
      }
      if(totalPercentage > 99){
        return 0
      }
      else{
        if(e < 100- (this.defaultCount)){
          this.track.cowritten_by[i].percent = Number(e) + 1
          return Number(e) + 1
        }
      }
    }
    else{
      this.track.cowritten_by[i].percent = (100- (this.defaultCount) )
      if(this.track.cowritten_by.length > 0){
        for(let value of this.track.cowritten_by)
        {
          totalPercentage += value.percent;
        }
      }
      if(totalPercentage > (99)){
        return this.track.cowritten_by[i].percent
      }
      else{
          this.track.cowritten_by[i].percent = 0
          return this.track.cowritten_by[i].percent
      }
    }
   
  }
  selectOption(e,ind){
    if (e.target === undefined) {
      //this.track.cowritten_by = this.track.cowritten_by.concat(this.list.trackDetail.cowritten_by)
    }
    if (this.track.cowritten_by.length > 0) {
      for (let ind = 0; ind < e.value?.length; ind++) {
        if (this.unlimit) {
          e.value[ind].id= e.value[ind].user_id
        }
        this.track.cowritten_by = this.track.cowritten_by.filter(function (en) {
          if (en.user_id) {

              if (en.user_id !== e.value[ind].id) {
                return en;
              }
          }
          else {
            if (en.id !== e.value[ind].id) {
              return en;
            }
          }

        })
        for (let val of this.list.trackDetail.track.cowritten_by) {
          if (this.unlimit) {
            if (val.user_id === e.value[ind].id) {
              e.value[ind].percent = val.percent;
            }
          }
          else {
            if (val.user_id === e.value[ind].user_id) {
              e.value[ind].percent = val.percent;
            }
          }
        }
        this.track.cowritten_by.push(e.value[ind])
        let valueCo = []
        let data = this.track.cowritten_by.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
        for (let i = 0; i < data.length; i++) {
          if (data[i].id) {
            valueCo.push(data[i])
          }
        }
        this.track.cowritten_by = valueCo
      }
    }
    else {
      if (e.value) {
        this.track.cowritten_by = e.value;
        let data = this.track.cowritten_by.filter((v,i,a)=>a.findIndex(t=>(t.id===v.id))===i)
        let valueCo = []
        for(let i=0;i< data.length;i++){
          if(data[i].id){
            valueCo.push(data[i])
          }
        }
        this.track.cowritten_by = valueCo
      }
    }

  }
  isNumberKey(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }

  onBlur(value, i) {
    let decimal = false;
    if(value. charAt(value. length - 1)  === '.'){
    value = (value)+ 0;
    decimal = true;
    }
    var totalPercentage = 0;

    if (Number(value) < (100- (this.defaultCount) )) {
      var num = Number(value)
      this.track.cowritten_by[i].percent = Number(value)
      if (this.track.cowritten_by.length > 0) {
        for (let value of this.track.cowritten_by) {
          totalPercentage += value.percent;
        }
      }
      if (totalPercentage > (99)) {
        this.track.cowritten_by[i].percent =  0
        return 0 
      }
      else {
        if (num <= (99)) {
          if(decimal){
          this.track.cowritten_by[i].percent = num+'.'
          return  this.track.cowritten_by[i].percent
          }
          else{
            this.track.cowritten_by[i].percent = num
            return  this.track.cowritten_by[i].percent
          }
        }
      }

    }
    else {
      this.track.cowritten_by[i].percent = (100- (this.defaultCount) )
      if (this.track.cowritten_by.length > 0) {
        for (let value of this.track.cowritten_by) {
          totalPercentage += value.percent;
        }
      }
      if (totalPercentage > (99)) {
         this.track.cowritten_by[i].percent = 0
         return  0;
      }
      else {
        this.track.cowritten_by[i].percent = 0
        return  0;
      }
    }
  }
  value(value, i) {
    let decimal = false;
    if(value. charAt(value. length - 1)  === '.'){
    value = (value)+ 0;
    decimal = true;
    }
    var totalPercentage = 0;

    if (Number(value) < (100- (this.defaultCount) )) {
      var num = Number(value)
      this.track.cowritten_by[i].percent = Number(num)
      if (this.track.cowritten_by.length > 0) {
        for (let value of this.track.cowritten_by) {
          totalPercentage += value.percent;
        }
      }
      if (totalPercentage > (99)) {
        this.track.cowritten_by[i].percent =0
        return 0;
      }
      else {
        if (num <= (100- (this.defaultCount) )) {
          if(decimal){
            this.track.cowritten_by[i].percent = num +'.';
            return num +'.'
          }
          else{
            this.track.cowritten_by[i].percent = num;
            return num
          }
          
        }
      }

    }
    else {
      this.track.cowritten_by[i].percent = (100- (this.defaultCount) )
      if (this.track.cowritten_by.length > 0) {
        for (let value of this.track.cowritten_by) {
          totalPercentage += value.percent;
        }
      }
      if (totalPercentage > (99)) {
         this.track.cowritten_by[i].percent = 0
         return 0
      }
      else {
        this.track.cowritten_by[i].percent = 0
        return 0
      }
    }
  }
  closethisPanel() {
    this.panelOpenState = false;
  }
}
