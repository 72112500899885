<div class="dialog-container green-theme">
  <div class="dialog-header">
    <h4 class="dialog-heading">Add Song to playlist</h4>
    <button type="button" class="close-button" (click)="close()">
      <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
          stroke-linejoin="round">
          <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
            <g id="Genre-Options">
              <g id="Close" transform="translate(1439.000000, 32.000000)">
                <path d="M24,0 L0,24" id="Path"></path>
                <path d="M0,0 L24,24" id="Path"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </button>
  </div>

  <mat-dialog-content>
    <app-loader *ngIf="loading" color="#50e3c2"></app-loader>
    <div class="dialog-body playlist-container" *ngIf="!loading">
      <h3 class="title">{{ data && data.track ?data.track.title:''}}</h3>
      <div class="add-to-folder form-container">
        <p class="label" style="padding-bottom: 2%;">Available Playlists</p>
        <input class="search"
          placeholder="Find a playlist"
          [formControl]="searchControl" [(ngModel)]="searchPlaylist">
        <ul class="list" *ngIf="!searchingPlaylists">
          <li *ngFor="let playlist of playlistOptions | async; trackBy:playlistTrackBy">
            <mat-checkbox [checked]="isPlaylistChecked(playlist)" (change)="playlistChecked($event.checked, playlist)">
              <span class="check-text">{{playlist.name}}</span>
            </mat-checkbox>
          </li>
        </ul>
        <div class="no-groups" *ngIf="!searchingPlaylists && playlistList.length <= 0">
          <p>There are currently no playlists available. Create a new playlist to add your track version</p>
        </div>
        <app-loader *ngIf="searchingPlaylists" color="#50e3c2"></app-loader>
      </div>
      <p class="note">Deselect tracks to remove them from this playlist</p>
      <div class="form-col buttons">
        <button class="form-button" (click)="save()" [disabled]="!canSubmit()">Save</button>
        <button class="form-button form-button-cancel" (click)="close()">Cancel</button>
        <!-- <button class="btn save-btn1"  (click)="save()" [disabled]="!canSubmit()" mat-button>Save to playlist</button>  -->
      </div>
    </div>

    <!-- NEW UI FOR ADD TO PLAYLIST -->
    <!-- <div class="dialog-body playlist-container">
      <h4>playlists</h4>
      <p>Select a playlist(s) to add tracks</p>
      <div class="playbox-container">
        
      <div class="grid-card">
          <div class="grid-item">
              <mat-form-field appearance="fill" class="trackend-group">
                  <mat-label>Available Playlists</mat-label>
                  <mat-select   placeholder="Select">
                    <mat-option >select</mat-option>
                  </mat-select>
              </mat-form-field>
          </div>
      </div>
      <div class="grid-card">
        <div class="grid-item">
           
            <mat-form-field  appearance="fill" class="trackend-group">
                <textarea matInput placeholder="New playlist name"></textarea>
                <span class="close-btn1" style="float: right;">&times;<strong style="padding-left: 28px;cursor: pointer;text-decoration: underline;">ADD</strong></span>
              </mat-form-field>
           
        </div>
    </div>
    <div class="tagnames">
      <div class="tagnames-data">
          <p>Tag name 2 goes here <span class="close-btn2">&times;</span></p>
      </div>
      <div class="tagnames-data">
          <p>Tag name 2 <span class="close-btn2">&times;</span></p>
      </div>
  </div>
      </div>
      <h4>Add Versions</h4>
      <p>Select all track versions you’d like to add to the playlist</p>
      <div class="playbox-container add-versionbox" style="margin-top: 2%;">
        <div class="meta-data">
          <mat-checkbox>ADD ALL VERSIONS</mat-checkbox>
          </div>
      </div>
      <div class="playbox-container add-versionbox">
        <div class="sub-checkbox">
          <mat-checkbox style="width: 80%;">Main</mat-checkbox>
           <svg matTooltipPosition="left" matTooltip="Pending" matTooltipClass="tooltip-white" class="circle-dot circle-pending" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z"/></svg>
          Pending
          </div>
      </div>
      <div class="playbox-container add-versionbox">
        <div class="sub-checkbox">
          <mat-checkbox style="width: 80%;">Narraive</mat-checkbox>
          <svg  matTooltipPosition="left" matTooltip="Pending Approval" matTooltipClass="tooltip-white" class="circle-dot circle-pendingapproval" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z"/></svg>
          Pending Approval
        </div>
      </div>
    </div>
    <button class="btn save-btn1"  mat-button>Save to playlist</button> -->



    
  </mat-dialog-content>
</div>
