<form [formGroup]="createAccountForm" *ngIf="!loading">
    <div class="profile-info">
        <div class="menu-user-info">
        <div class="section general">
                <div class="title">
                    <h3>User DETAILS<span class="allfields"><small>*All fields required </small></span></h3>
                </div>
                
                <div class="grid-container">
        
                    <div class="grid-item">
                        <mat-form-field appearance="fill">
                            <mat-label>First Name</mat-label>
                            <input matInput [(ngModel)]="myInfoDetails.first_name" formControlName="first_name">
                        </mat-form-field>
                    </div>
                    <div class="grid-item">
                        <mat-form-field appearance="fill">
                            <mat-label>Last Name</mat-label>
                            <input matInput [(ngModel)]="myInfoDetails.last_name" formControlName="last_name">
                        </mat-form-field>
                    </div>
                </div>
                <div class="grid-card">
                    <div class="grid-item">
                        <mat-form-field appearance="fill">
                            <mat-label>Email Address</mat-label>
                            <input matInput [(ngModel)]="myInfoDetails.email" formControlName="email">
                        </mat-form-field>
                    </div>
                </div>
                <div class="grid-container">
        
                        <div class="grid-item">
                            <mat-form-field appearance="fill">
                                <mat-label>Nick Name</mat-label>
                                <input matInput [(ngModel)]="myInfoDetails.nick_name" formControlName="nick_name">
                            </mat-form-field>
                        </div>
                        <div class="grid-item">
                            <mat-form-field appearance="fill">
                                <mat-label>Birthday</mat-label>
                                <input matInput [(ngModel)]="myInfoDetails.birth_date" formControlName="birth_date" [matDatepicker]="picker" [max]="maxDate">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
            </div>
            <hr>
        
        
            <div class="section contact">
                <div class="title">
                    <h3>Contact<span class="allfields"><small>*All fields required </small></span></h3>
                </div>
                <div class="grid-card">
                    <div class="grid-item">
                        <mat-form-field appearance="fill">
                            <mat-label>Address </mat-label>
                            <input matInput [(ngModel)]="myInfoDetails.address1" formControlName="address1">
                        </mat-form-field>
                    </div>
                </div>
                <div class="grid-card">
                    <div class="grid-item">
                        <mat-form-field appearance="fill">
                            <mat-label>Address Line 2</mat-label>
                            <input matInput [(ngModel)]="myInfoDetails.address2" formControlName="address2">
                        </mat-form-field>
                    </div>
                </div>
                <div class="grid-container">
        
                    <div class="grid-item">
                        <!-- <mat-form-field appearance="fill">
                            <mat-label>Country</mat-label>
                            <input matInput >
                        </mat-form-field>  -->
                        <app-country-select [(currentSelection)]="user.country"></app-country-select>
                    </div>
        
                    <div class="grid-item">
                        <mat-form-field appearance="fill">
                            <mat-label>City</mat-label>
                            <input matInput [(ngModel)]="myInfoDetails.city" [patterns]="customPatterns"  formControlName="city">
                        </mat-form-field>
                    </div>
                    <div class="grid-item">
                            <app-state-select [(currentSelection)]="user.state"></app-state-select>    
                            <!-- <mat-form-field appearance="fill">
                                <mat-label>State</mat-label>
                                <input matInput >
                            </mat-form-field> -->
                    </div>
                    <div class="grid-item">
                        <mat-form-field appearance="fill">
                            <mat-label>Zip</mat-label>
                            <input matInput [(ngModel)]="myInfoDetails.postal_code" [patterns]="numberPattern" mask="00000" formControlName="postal_code">
                        </mat-form-field>
                    </div>
                </div>
                <div class="grid-card">
                    <div class="grid-item">
                        <h6 class="phonetype" >PHONE</h6>
                        <div class="rows">
                            <div class="phone">
                                    <mat-form-field class="type" appearance="fill">
                                        <mat-select value='mobile'>
                                            <mat-option style="text-transform: capitalize;"  [value]="type" *ngFor="let type of phoneNumberTypes" >
                                                <span style="text-transform: capitalize;">{{type}}</span> 
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field class="number" appearance="fill">
                                        <input type="tel" matInput class="form-control" [(ngModel)]="myInfoDetails.phone_number" [formControl]="primaryPhoneControl" maxlength="12" (keydown)="validateNumber($event)" (input)="getPhoneCode($event.target)"> 
                                    </mat-form-field>
                            </div>
                            <div class="phone" *ngFor="let number of user1.additional_numbers; let i = index;">
                                <mat-form-field class="type" appearance="fill">
                                    <mat-select style="text-transform: capitalize;"  [(ngModel)]="number.number_type" [ngModelOptions]="{standalone: true}">
                                        <mat-option *ngFor="let type of phoneNumberTypes" [value]="type">
                                            <span style="text-transform: capitalize;">{{type}}</span> 
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="number" appearance="fill">
                                    <input type="tel" id={{i}} matInput class="form-control" [(ngModel)]="number.phone_number"  [formControl]="phoneControllers[i]" maxlength="12" (keydown)="validateNumber($event, i)"  (input)="getPhoneCode($event.target, i)">
                                </mat-form-field>
                                <button class="remove" (click)="removeNumber(i)">
                                    <svg width="13px" height="12px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                                            <g id="Genre-Options">
                                            <g id="Close" transform="translate(1439.000000, 32.000000)">
                                                <path d="M24,0 L0,24" id="Path"></path>
                                                <path d="M0,0 L24,24" id="Path"></path>
                                            </g>
                                            </g>
                                        </g>
                                        </g>
                                    </svg>
                                </button>
                            </div>
                            <div class="add-row"><button (click)="addPhoneNumber()" class="addnum">+ <u>Add</u></button></div>
                        </div>
                    </div>
                </div>
                
        </div><hr>
        <div class="section contact">
            <div class="title">
                <h3>SOCIAL<span class="allfields"><small>*Optional </small></span></h3>
            </div>
            
            <div class="grid-card" *ngFor="let media of socialArray; let i = index;">
                <div class="grid-item">
                    <mat-form-field appearance="fill">
                        <mat-label>{{media.social_type}}</mat-label>
                        <input class="url" type = url pattern="https?:\/\/*" placeholder="http://www.example.com"  matInput [(ngModel)]="media.value" [ngModelOptions]="{standalone: true}">
                    </mat-form-field>
                    <div *ngIf="media.social_type !== 'FACEBOOK' && media.social_type !== 'INSTAGRAM' && media.social_type !== 'TWITTER' && media.social_type !== 'TIKTOK' && media.social_type !== 'YOUTUBE' && media.social_type !== 'CLUBHOUSE' && media.social_type !== 'SNAPCHAT' && media.social_type !== 'SOUNDCLOUD' && media.social_type !== 'TWITCH'">
                    <button class="remove-link"  (click)="removeLink(i); dataRemove=true">
                        <svg width="13px" height="12px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                            stroke-linejoin="round">
                            <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                                <g id="Genre-Options">
                                <g id="Close" transform="translate(1439.000000, 32.000000)">
                                    <path d="M24,0 L0,24" id="Path"></path>
                                    <path d="M0,0 L24,24" id="Path"></path>
                                </g>
                                </g>
                            </g>
                            </g>
                        </svg>
                    </button>
                </div>
                </div>
            </div>
            
                    <div class="add-row"><button class="btn-row" (click)="socialModal=true">+ <u>Other</u></button></div>
                    
        </div><hr>
        <div class="section contact">
            <div class="title">
                <h3>UPDATE PASSWORD</h3>
            </div>
            
            <div class="grid-card">
                <div class="grid-item">
                    <mat-form-field appearance="fill">
                        <mat-label>CURRENT PASSWORD</mat-label>
                        <input type="password" matInput formControlName="password" maxlength="16" minlength="4" >
                    </mat-form-field>
                </div>
            </div>
            <div class="grid-card">
                    <div class="grid-item">
                        <mat-form-field appearance="fill">
                            <mat-label>NEW PASSWORD</mat-label>
                            <input type="password" formControlName="newPassword" matInput maxlength="16" minlength="4"  >
                        </mat-form-field>
                    </div>
                </div>
                <div class="grid-card">
                        <div class="grid-item">
                            <mat-form-field appearance="fill">
                                <mat-label>CONFIRM NEW PASSWORD</mat-label>
                                <input type="password" matInput  formControlName="confirmPassword" maxlength="16" minlength="4" >
                            </mat-form-field>
                        </div>
                    </div>
                </div><hr>
                <p *ngIf="errorMessage == 1" style="
                color: red;
                font-size: small;
                margin-left: 18px; margin-top: -9%;">{{error}}</p>
                <div class="section contact">
                    <button class="btn save-btn" mat-button (click)="createAccount()">Save</button>
                </div>
        
    
        </div>
    </div>
</form>
<div id="myModal" class="modal" *ngIf="socialModal">
    <!-- Modal content -->
    <div class="modal-content">
        <span class="close" (click)="socialModal=false">&times;</span>
        <span class="sclSpan dialog-header">Add Social Channel</span>
        <div class="inputSocial">
            <div class="grid-card">
                <div class="grid-item">
                    <mat-form-field appearance="fill">
                        <mat-label> Social Channel Name</mat-label>
                        <input matInput (input)="getSocialName($event.target.value)" />
                    </mat-form-field>
                </div>
            </div>
            <div class="grid-card">
                <div class="grid-item">
                    <mat-form-field appearance="fill">
                        <mat-label>Social Channel Url </mat-label>
                        <input matInput type= "url" (input)="getSocialUrl($event.target.value)" />
                    </mat-form-field>
                </div>
            </div>
        </div>
      <button class="btnAdd form-button"  [disabled]="socialData === false" mat-button  (click)="addsociallinks()">Add</button>
    </div>  
  </div>
  <div id="myModal" class="modal" *ngIf="dataRemove">
    <!-- Modal content -->
    <div class="modal-content-remove">
        <span class="close" (click)="dataRemove=false">&times;</span>
        <span class="sclSpan dialog-header">REMOVE SOCIAL CHANNEL</span>
        <div class="inputSocial-info">
            <p>Are you sure you want to remove the following social channel ?</p>
            <p><strong>{{deletedName |titlecase}}</strong></p>
            <div class="form-col">
              <button class="form-button" (click)="removeUser(deletedName)">Delete</button>
              <button class="form-button form-button-cancel" (click)="dataRemove=false">Cancel</button>
            </div>
        </div>
    </div>  
  </div>

<div *ngIf="loading">
    <app-loader color="#50e3c2"></app-loader>
</div>