<div class="dialog-container green-theme">
  <div class="dialog-header">
    <!-- {{data.isDuplicate ? 'Duplicate': data.isEdit ? 'Edit': 'New'}} Playlist -->
    <!-- <h4 class="dialog-heading">Add Playlist to Folder</h4> -->
    <h4 class="dialog-heading">{{showDataPlaylist ? 'Add Playlist to Folder' :'Add Folder to Playlist'}} </h4>

    <!-- <h4 class="dialog-heading">{{data.tabval ? 'playlist' ? 'Add Playlist to Folder': 'Add Folder to Playlist  '}} </h4> -->

    <button type="button" class="close-button" (click)="close()">
      <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
          stroke-linejoin="round">
          <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
            <g id="Genre-Options">
              <g id="Close" transform="translate(1439.000000, 32.000000)">
                <path d="M24,0 L0,24" id="Path"></path>
                <path d="M0,0 L24,24" id="Path"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </button>
  </div>

  <mat-dialog-content>
    <app-loader *ngIf="loading" color="#50e3c2"></app-loader>
    <div class="dialog-body" *ngIf="!loading">
      <h3 class="title" *ngIf="showDataPlaylist">{{ data.playlist.name }}</h3>
      <h3 class="title" *ngIf="!showDataPlaylist">{{ data.playListfolder[0].name }}</h3>

      <!-- <h3 *ngIf="data.playListfolder">'data.playListfolder</h3> -->
      <!-- <h3 class="title" *ngIf="data.tabval ==  'playlist'">{{ data.playlist.name }}</h3>
      <h3 class="title" *ngIf="data.tabval ==  'folders'">'foldername'</h3> -->

                            <!-- {{data.tabval ==  'playlist' ? 'Add Playlist to Folder' :'Add   Folder to Playlist'}}
      <h3 class="title" > {{data.tabval ==  'playlist' ? data.playlist.name  :data.playListfolder[0].name }}</h3> -->
      <div class="add-to-folder form-container">
        <p class="label">{{showDataPlaylist ? 'Add to Available Folder'  :'Add to Available Playlist' }}</p>
        <input *ngIf ="!showDataPlaylist" class="search"
          placeholder="Find a playlist"
          [formControl]="searchControl">
          <input *ngIf ="showDataPlaylist" class="search"
          placeholder="Find a playlist folder"
          [formControl]="searchControl">
        <ul class="list" *ngIf="!searchingFolders">
          <li *ngFor="let folder of folderOptions | async; trackBy:folderTrackBy">
            <mat-checkbox [checked]="isFolderChecked(folder)" (change)="folderChecked($event.checked, folder)">
              <!-- <span class="check-text">{{folder.getDisplayName()}}</span> -->
              <span class="check-text">{{folder.name}}</span>
            </mat-checkbox>
          </li>
          <li *ngIf="isNext">
            <mat-option style="text-align: center; background-color: #50E3C2; height: 30px;" id="loadMore" (click)="loadMore()" >Load More</mat-option>
          </li>
        </ul>
        <div class="no-groups" *ngIf="!searchingFolders && folderList.length <= 0">
          <p>There are currently no groups available. Create a new playlist group to add your playlist</p>
        </div>
        <app-loader *ngIf="searchingFolders" color="#50e3c2"></app-loader>
      </div>
      <p class="note">Select a playlist group to add, or deselect a group to remove playlist from that group</p>
      <!-- *ngIf="data.tabval ==  'playlist'" -->
      <div class="form-row" *ngIf="showDataPlaylist">
        <mat-checkbox
          [(ngModel)]="newPlaylistFolder">
          <span class="check-text">Create New Playlist Folder</span>
        </mat-checkbox>
      </div>
      <div class="form-container" *ngIf="newPlaylistFolder">
        <mat-form-field class="dialog">
          <mat-label>New Playlist Folder Name</mat-label>
          <input [(ngModel)]="folderName" matInput>
        </mat-form-field>
      </div>
      <div class="form-col buttons">
        <button class="form-button" (click)="getConfirmData()" [disabled]="!canSubmit()">Save</button>
        <button class="form-button form-button-cancel" (click)="close()">Cancel</button>
      </div>
    </div>
  </mat-dialog-content>
</div>
