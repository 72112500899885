import { Component, OnInit, OnDestroy, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

import { Subscription } from 'rxjs';

import { TrackService } from '../services/track.service';
import { Track } from '../models/track.model';
import { TrackComposer } from '../models/trackcomposer.model';
import { Mood } from '../models/mood.model';
import { MetaData } from '../models/metadata.model';
import { TrackVersion } from '../models/trackversion.model';
import { TrackInfo } from '../models/trackinfo.model';
import { Instrument } from '../models/instrument.model';
import { NetworkReference } from '../models/networkreference.model';
import { SonicReference } from '../models/sonicreference.model';
import { SliderDialogService } from '../services/slider-dialog.service';
import { EditNewTrackComponent } from '../edit-new-track/edit-new-track.component';
import { EditNewTrackVersionComponent } from '../edit-new-track-version/edit-new-track-version.component';


export interface DialogData {
  composers: TrackInfo[];
  response: any[];
  track: Track;
  version: TrackVersion;
  type:any
}

@Component({
  selector: 'app-trackinfo',
  templateUrl: './trackinfo.component.html',
  styleUrls: ['./trackinfo.component.scss']
})
export class TrackInfoComponent implements OnInit, OnDestroy {

  loading = false;
  displayUserInputs = false;
  track: Track;
  version: TrackVersion;
  composers: TrackInfo[] = [];
  // dataTable: any[] = [];
  instruments: any[] = [];
  dataTable = [];

  private _subscriptions: Subscription[] = [];

  displayedColumns: string[] = [
    'composer',
    'composer_pro',
    'composer_percentage',
    'publisher_name',
    'publisher_pro',
    'publisher_percentage'
  ];

  constructor(
    private _trackService: TrackService,
    private _sliderDialogService: SliderDialogService,
    public dialogRef: MatDialogRef<TrackInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
    this.track = this.data.track
    this.version = this.data.version
    this.dataTable = this.data.response

    this.dataTable  =  this.dataTable.filter(function(e) { 
      if(e.composerShare > 0){
       let float = isFloat(e.composerShare)
       let float1 = isFloat(e.publisherShare)
       if(float){
        e.composerShare = e.composerShare.toFixed(2);
       }
       if(float1){
        e.publisherShare = e.publisherShare.toFixed(2);
       }
        return e;
      }
    })
    
    this.composers = this.data.composers
  }


  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._subscriptions = [];
  }

  close() {
    this.dialogRef.close();
  }
  

  editTrackPressed() {
    this.close()
    if (this.version.is_full_version) {
      this._sliderDialogService.open(EditNewTrackComponent, {
        width: "600px",
        title: "EDIT TRACK",
        panelClass: "edit-track-dialog",
        data: {track:this.track, version:this.version}
      });
    } else {
      this._sliderDialogService.open(EditNewTrackVersionComponent, {
        width: "600px",
        title: "EDIT TRACK VERSION",
        panelClass: "edit-track-dialog",
        data: {track:this.track, version:this.version}
      });
    }
  }

  getUserInputsColor(userInput) {
    if (userInput && userInput.added_by_role == 0 ) {
      return 'composer';
    } else if (userInput && userInput.added_by_role == 1) {
      return 'approver';
    } else if (userInput && userInput.added_by_role == 2) {
      return 'meta-tagger';
    } else {
      return '';
    }
  }
  getInstrumentAddedByClass(instrument:Instrument): string {
    let it = this.track.instrumenttracks_set && this.track.instrumenttracks_set.find(i=>i.instrument_id == instrument.id);
    if (it) {
      return this.getClassForRole(it.added_by_role);
    }
    return ''  
  }

  getNetworkAddedByClass(network:NetworkReference): string {
    let it = this.track.networkreferencetracks_set && this.track.networkreferencetracks_set.find(i=>i.networkreference_id == network.id);
    if (it) {
      return this.getClassForRole(it.added_by_role);
    }
    return ''
  }

  getSonicAddedByClass(sonic:SonicReference): string {
    let it = this.track.sonicreferencetracks_set && this.track.sonicreferencetracks_set.find(i=>i.sonicreference_id == sonic.id);
    if (it) {
      return this.getClassForRole(it.added_by_role);
    }
    return ''
  }

  getClassForRole(role:number) {
    switch (role) {
      case -1:
        return 'all';
      case 0:
        return 'composer';
      case 1:
        return 'approver';
      case 2:
        return 'meta-tagger';
    }
  }

  moodsTrackBy(i: number, mood: Mood) {
    return mood.id + mood.name;
  }

  metadataTrackBy(_: number, metaData: MetaData) {
    return metaData.id + metaData.keyword;
  }

  composerTrackBy(i: number, composer: TrackComposer) {
    return composer.composer_name + composer.composer_percentage;
  }

  instrumentsTrackBy(i: number, instrument: Instrument) {
    return instrument.name;
  }

  networkReferencesTrackBy(i: number, netRef: NetworkReference) {
    return netRef.id + netRef.name;
  }

  sonicReferencesTrackBy(i: number, sonicRef: SonicReference) {
    return sonicRef.id + sonicRef.name;
  }

}

function isFloat(n){
  return Number(n) === n && n % 1 !== 0;
}

