<div class="edit-track create-new-track">

  <app-track-preview [track]="track" [version]="version" [ngClass]="{hide: !track || !version}" class="music-player">
  </app-track-preview>

    <app-track-details
      [(track)]="track"
      [(version)]="version"
    ></app-track-details>
  
    <app-composer-manage-cowriters [(composers)]="composers" [(version)]="version"></app-composer-manage-cowriters>
    <!-- <app-manage-cowriters [(composers)]="composers"></app-manage-cowriters> -->
  
    <div class="separator-line"></div>
  
    <app-composer-track-description
      [(track)]="track" [(version)]="version"></app-composer-track-description>
  
    <button  [disabled]="track.isValid  === true" class ="save-version-btn" mat-button (click)="onSave()"> SAVE TRACK</button>
  </div>

  <div id="myModal" class="modal" *ngIf="autoBpmValuepopup">
    <!-- Modal content -->
    <div class="modal-content-remove">
        <span class="close" (click)="autoBpmValuepopup=false">&times;</span>
        <span class="sclSpan dialog-header">BPM CONFIRMATION</span>
        <div class="inputSocial-info">
            <p>Please confirm auto detected BPM</p>
            <div class="form-col">
              <button class="form-button" (click)="saveData()">Yes</button>
              <button class="form-button form-button-cancel" (click)="autoBpmValuepopup=false">No</button>
            </div>
        </div>
    </div>  
  </div>
  <div class="loading-overlay" *ngIf="loading">
    <app-loader></app-loader>
  </div>
  
  
  