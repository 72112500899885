import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MenuListService } from '../../shared/services/menu-list.service';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { environment } from '../../../environments/environment';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
  selector: 'app-delete-user-dialogue',
  templateUrl: './delete-user-dialogue.component.html',
  styleUrls: ['./delete-user-dialogue.component.scss']
})
export class DeleteUserDialogueComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<DeleteUserDialogueComponent>,public list: MenuListService, private config: MenuConfigService, private api: UserMenuService,public dialog: DialogService,) { }
  currentUser:any;
  loading: boolean = false;

  ngOnInit(): void {
    this.list.getAdminUser().subscribe(x=>{
      if(x){
        this.currentUser = x;
      }
    })
  }
  close() {
    this.dialogRef.close();
  }
  delete(){
    this.list.loadingUser = true;
    let requestUrl = environment.apiURL + this.config.doMenuUser + this.currentUser.id + '/'
    this.api.doDelete(requestUrl).subscribe(x=>{
      this.list.loadingUser = false;
      this.dialogRef.close();
      this.list.getAllUser();
      location.reload();
    },
    (error) =>{
     
      this.list.loadingUser = false;
      let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
      this.dialog.UserUploadPopupConfirmPopup(data).subscribe(changed => {
      });
      this.dialogRef.close();
      //alert(error.message)
    }
  )
  }
  
}
