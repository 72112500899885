<form [formGroup]="editUserForm">
<div class="dialog-container">
    <div class="dialog-header">
        <h4 class="dialog-heading">edit P.R.O</h4>
        <button type="button" class="close-button" (click)="close()">
        <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                    <g id="Genre-Options">
                        <g id="Close" transform="translate(1439.000000, 32.000000)">
                            <path d="M24,0 L0,24" id="Path"></path>
                            <path d="M0,0 L24,24" id="Path"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        </button>
    </div>
<!-- code for add genre -->
     <mat-dialog-content>
        
        <app-loader *ngIf="loader" color="#50e3c2"></app-loader>
        <div class="dialog-body" *ngIf="!loader">
           
            <p><strong>{{list.currPros?.pro}}</strong></p>
              <div class="grid-card">
                <div class="grid-item">
                    <mat-form-field appearance="fill" class="group">
                        <mat-label>P.R.O Name</mat-label>
                        <input matInput formControlName="organization_name" [(ngModel)]="list.singlePROS.pro">
                    </mat-form-field>
                   
                </div>
            </div> 
            <div class="grid-card">
                <div class="grid-item">
                    <mat-form-field appearance="fill" class="group">
                        <mat-label>associated publisher</mat-label>
                        <mat-select [(ngModel)]="list.publisher" panelClass="templatefrompanel templatepub" [disableOptionCentering]="true" [ngModelOptions]="{standalone: true}">
                            <mat-option *ngFor="let items of list.allPublisher" [value]="items.name" (onSelectionChange)="setPublisher($event, items.id)">{{items.name | titlecase}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            
            <div class="form-col">
              <button class="form-button" (click)="updatePros()">save</button>
              <button class="form-button form-button-cancel" (click)="close()">Cancel</button>
            </div>
          </div>
    </mat-dialog-content> 

</div>
</form>
<div class="loading-overlay" *ngIf="loadingProsUpdate">
    <app-loader></app-loader>
</div>


