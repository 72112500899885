import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { Track } from 'src/app/shared/models/track.model';
import { TrackVersion } from 'src/app/shared/models/trackversion.model';
import { GetTracksOptions } from 'src/app/shared/services/track.service';
import { UserService } from 'src/app/shared/services/user.service';
import { PlayerService } from 'src/app/shared/services/player.service';


@Component({
  selector: 'app-home-composer',
  templateUrl: './home-composer.component.html',
  styleUrls: ['./home-composer.component.scss']
})
export class HomeComposerComponent implements OnInit, OnDestroy {
  loadingTracks = true;
  searchFilterFocused = false;

  playingTrack: Track = null;
  playingTrackVersion: TrackVersion = null;
  musicPlayerCollapsed = false;
  

  @Input()
  set baseTrackOptions(o: GetTracksOptions) {
    this._baseTrackOptions = o;
    this._currentTracksPage = 1;
  
  }
  get baseTrackOptions(): GetTracksOptions {
    return this._baseTrackOptions;
  }
  totalTracks = 0;
  set currentTracksPage(p: number) {
    if (!isNaN(p) && p != this._currentTracksPage) {
      this._currentTracksPage = p;
      
    }
  }
  get currentTracksPage(): number {
    return this._currentTracksPage;
  }
  tracks: Observable<ReadonlyArray<Track>>;

  private _currentTracksPage = 1;
  private _subscriptions: Subscription[] = [];
  private _tracksSubject: BehaviorSubject<
    ReadonlyArray<Track>
  > = new BehaviorSubject<ReadonlyArray<Track>>([]);
  private _baseTrackOptions: GetTracksOptions = {};
  constructor(
    public _userService: UserService,
    private _playerService: PlayerService,
  ) {
    this.tracks = this._tracksSubject.asObservable();
  }

  ngOnInit(): void {

    this._subscriptions.push(
      this._playerService.currentlyPlaying.subscribe(playingItem => {
        if (!playingItem) {
          this.playingTrack = null;
          this.playingTrackVersion = null;
          return;
        }
        this.playingTrack = playingItem.track;
        this.playingTrackVersion = playingItem.version;
      }),
      this._playerService.showMusicPlayer.subscribe(show => {
        this.musicPlayerCollapsed = !show;
       
      })
    );
    
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((s) => s.unsubscribe());
    this._subscriptions = [];
    this._playerService.updateShowMusicPlayer(true)
    this.musicPlayerCollapsed = false;
    this._playerService.play(null, null);
    this.playingTrackVersion = null;
    this.playingTrack = null;

  }

  toggleMusicPlayerCollapsed() {
    this.musicPlayerCollapsed = !this.musicPlayerCollapsed;
    // this._playerService.updateIsPlaying(false);
    // this._playerService.updateCollapse(!this.musicPlayerCollapsed);
  }

  collapseMusicPlayer() {
    this.musicPlayerCollapsed = true;
  }

  setPlayingTrack(track:Track) {
    this.playingTrack = track;
  }

  setPlayingTrackVersion(trackVersion:TrackVersion) {
    this.playingTrackVersion = trackVersion;
  }

}
