<mat-form-field appearance="fill">
   
    <span class="range-date1">COUNTRY</span>
    <mat-select [(value)]="option" placeholder="Select" [panelClass]="'card-select'" [disableOptionCentering]="true" [ngModel]="current" [compareWith]="compareWith" (selectionChange)="selectionMade($event)">
      <mat-option value="select">select</mat-option>
      <mat-option>
        <ngx-mat-select-search
          [searching]="searching"
          [formControl]="filterCtrl"
          placeholderLabel="Select a Country"
          noEntriesFoundLabel="No Options Found">
          <svg ngxMatSelectSearchClear class="filter-clear-icon" width="26px" height="24px" viewBox="0 0 26 24"
            version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
              stroke-linejoin="round">
              <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                <g id="Genre-Options">
                  <g id="Close" transform="translate(1439.000000, 32.000000)">
                    <path d="M24,0 L0,24" id="Path"></path>
                    <path d="M0,0 L24,24" id="Path"></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{ option.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>