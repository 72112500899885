import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { MenuListService } from '../../shared/services/menu-list.service';
import { environment } from '../../../environments/environment';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { UserUploadPopupComponent } from 'src/app/shared/user-upload-popup/user-upload-popup.component';

@Component({
  selector: 'app-remove-subgenre-dialogue',
  templateUrl: './remove-subgenre-dialogue.component.html',
  styleUrls: ['./remove-subgenre-dialogue.component.scss']
})
export class RemoveSubgenreDialogueComponent implements OnInit {

  loader: boolean = true;
  constructor(private dialogRef: MatDialogRef<RemoveSubgenreDialogueComponent>,
  public api: UserMenuService,
  public config: MenuConfigService,
  public list: MenuListService,
  public _DialogService:DialogService
  ) { }

  ngOnInit(): void {
    setTimeout(()=>{                         
      this.loader = false;
    }, 500);
  }
  close() {
    this.dialogRef.close();
  }
  delete(){
    let requestUrl = environment.apiURL + this.config.doGenres + this.list.currSubGnere.id + '/'
    this.api.doDelete(requestUrl).subscribe(x=>{
      this.close();
      this.list.getAllGenres();
    },
    error=>{
      //alert(error.message)
      let data = error.error?.error ? error.error?.error : (error.error?.detail ?error.error?.detail : (error.error?.details ? error.error?.details:`This Sub-genre is associated with a track(s), hence can't be deleted.`) )
      this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
      });
      this.close()
    }
  )
  }
}
