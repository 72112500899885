import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable, of, ReplaySubject, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Track } from '../models/track.model';
import { TrackVersion } from '../models/trackversion.model';
import { UserMenuService } from '../services/user-menu.service';
import { MenuListService } from '../services/menu-list.service'
import { SliderDialogService } from '../services/slider-dialog.service';
import { catchError, debounceTime, distinctUntilChanged, startWith, switchMap, tap } from 'rxjs/operators';
import { PlayerService } from '../services/player.service';
import { UserService } from '../services/user.service';
import { PlaylistService } from '../services/playlist.service';
import { DialogService } from '../services/dialog.service';
import { TrackService } from '../services/track.service';
import { WaveService } from 'angular-wavesurfer-service';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { TrackDetailsService } from '../services/track-details.service';

@Component({
  selector: 'app-track-details-for-version',
  templateUrl: './track-details-for-version.component.html',
  styleUrls: ['./track-details-for-version.component.scss'],
  providers: [WaveService]
})
export class TrackDetailsForVersionComponent implements OnInit {
  page = 1;
  @Input()
  set track(t: Track) {
    this._track = t;
    this.trackChange.emit(this._track);
  }
  get track(): Track {
    return this._track;
  }

  @Output()
  trackChange = new EventEmitter<Track>();

  @Input()
  set version(v: TrackVersion) {
    this._version = v;
    this.versionChange.emit(this._version);
  }
  get version(): TrackVersion {
    return this._version;
  }
  loadingTracks: boolean = false;
  @Output()
  versionChange = new EventEmitter<TrackVersion>();
  File: any;
  acceptedExtensions = "wav, mp4";
  @ViewChild('fileInput') myInputVariable: ElementRef;
  selectedFiles: any = [];
  message = '';


  versionTitle: string = "";
  bpmValue: number = 0;
  keyValue: string = 'C';
  isMainTrack = false;
  nextList: any = ''

  keysIList: Observable<any[]>;

  bpmNumber: number = 10;

  keyList: string[] = [];
  // keyList: string[] = ['C', 'Db/C#', 'D', 'Eb', 'E', 'F', 'Gb/F#', 'G', 'Ab', 'A', 'Bb', 'B/Cb','C#'];
  // keyList: string[] = ['C', 'Db/C#', 'D', 'Eb', 'E', 'F', 'Gb/F#', 'G', 'Ab', 'A', 'Bb', 'B/Cb', 'C#', "Cmin", "D#", "E min", "Fmin", "gminor", "A", "b", "Fsharp", "minor", "C Major key", "Cm", "Bb", "Am", "D major", "C", "C Sharp", "m", "D", "Dm", "Em", "Gm", "A minor", "B minor", "Bm", "D Minor", "Db/C#", "Eb", "E", "F"
  //   , "Gb/F#", "G", "Ab", "B/Cb", "C#", "Dmin", "D minor", "C minor", "Ebm", "gmin", "g sharp minor #", "g sharp major", "f#", "f sharp minor", "f sharp #", "f sharp", "e major", "C#m"
  //   , "A major", "A sharp", "Ab", "Ab minor", "db", "a#", "Emin", "G# minor", "G minor", "G Major", "Fm", "F# minor", "F minor", "F min", "F Major", "Eb minor", "Eb Major", "E Minor", "Dmi", "Db major"
  //   , "D min", "D major", " D Sharp", "C# minor", "C# major", " C major", "Bmin", "Bb min", "Bb major", "Bb Minor", " B minor", "B Major", "Amin", "Am", "c minor", "emin", "b min", "a min"
  //   , "D sharp", "minor", "bbm", " G sharp", "Gminor", "Csharp", "minor", "key of D minor", 'key of A minor']
  tempoList: string[] = [];
  // trackEndingList: string[] = ['Sting', 'Beep', 'Drums'];
  trackEndingList: string[] = ['sting ending', 'sting ending decay', 'cold ending', 'cold ending decay']
  // trackVersionList: string[] = ['Narrative','30 Sec','Bumper','Sting','Vox','Bed','Drum & Bass (Dnb)']
  trackVersionList: string[] = []
  star = false;
  isMibeOwned = false;
  searchingParent = false;
  parentFilterCtrl = new FormControl();
  userId: any = ''

  filteredParentOptions: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);


  private _track: any;
  private _version: TrackVersion;
  public trackEnd = "Quater-note";
  public tempo = "Sting";
  allTracks: any = [];
  parentArray: any = []
  NarrativeCount = 0
  allTracksList: any;
  selectedparent = 'Select';
  replaceTrackVersion: Boolean = false;
  progressInfos: any;
  isUploaded = false;
  isUploading: any;
  isFailed: any;
  url: string = ''
  title = '';
  parentTrackTitle: string = '';
  showVerNameErr: boolean = false;
  @Input() wavesurfer: WaveSurfer = null;
  isPlaying = false;
  getParentVal = true;
  current = 0;
  duration = 0;
  isInstrumental = false;
  isVocal = false;
  trackId = 0
  public variables = ['One', 'Two', 'County', 'Three', 'Zebra', 'XiOn'];
  filteredList1 = []
  constructor(public api: UserMenuService, private cdr: ChangeDetectorRef,
    private _playlistService: PlaylistService, private _userService: UserService,
    private _playerService: PlayerService, public _DialogService: DialogService,
    private _listService: MenuListService, private http: HttpClient,
    private _sliderDialogService: SliderDialogService, public trackDetail1: TrackService, public waveService: WaveService,
    private trackdetailsService: TrackDetailsService) {
    this.getTempo();
    //Robert: 5/17/24 - set keyList options
    this.keyList = this.trackdetailsService.keyList;
  }

  ngOnInit() {
    this.track.support_tv_references = [];
    console.log(this.version, "V");
    this._sliderDialogService.getReplaceTrackVersion().subscribe((val) => {
      if (val && val == 'replace') {
        console.log(val);
        this.replaceTrackVersion = true;

      }
    });
    if (this.version == null || this.version.id == undefined || this.version.id == null) {
      this._userService.currentUserStream.subscribe((u) => {
        this.userId = u.id
      });
    }
    if (this.version.id !== undefined && this.track.id !== undefined) {
      this.getTrackDetail(this.track.id)
    }
    // if(this.version.id==undefined){
    //   this.getAllTracks();
    // }
    this.getAllTracks();
    this.isInstrumental = this.version.is_instrumental;
    this.isVocal = this.version.is_vocals;
    this.versionTitle = "";
    this.bpmValue = Math.round(this.version.calculated_bpm);
    this.keyValue = this.version.calculated_key;
    this.isMainTrack = this.version.is_main_track;
    this.trackEnd = this.version.track_ending ? this.version.track_ending : "";
    // this.trackEnd = this.version.track_ending ?  this.version.track_ending : this.trackEndingList[0];
    this.tempo = this.version.tempo ? this.version.tempo : this.tempoList[0];
    this.keysIList = new Observable((observer) => {
      const gnList: any[] = new Array<any[]>();
      this.keyList.forEach((key, index) => {
        gnList.push({ id: index, name: key });
      })
      observer.next(gnList);
      observer.complete();
    });
    this._listService.currentIntrumentaltypeSubject.next(!this.version.is_instrumental);
    this.setValidation()

    this.parentFilterCtrl.valueChanges
      .pipe(
        startWith(""),
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.searchingParent = true;
        }),
        switchMap((v) => {
          if (!v) {
            return of(this.allTracks);
          }
          return of(
            // this.allTracks.filter((g) =>
            //   g.name.trim().toLowerCase().includes(v.toLowerCase())
            // )
            this.getAllTracks('', '', v)

          );
        }),
        tap(() => {
          this.searchingParent = false;
        })
      )
      .subscribe(
        (g) => {

          if (this.parentFilterCtrl.value == '') {
            this.allTracks = this.parentArray
            this.nextList = environment.apiURL + `/api/v1/trackversion/${this.userId}/get-all-tracks/?page=2`

          }
          this.filteredParentOptions.next(g);
        },
        (error) => {
          console.log(error);
          //alert("An error ocurred while getting the filtered genres. Check your connections and try again");
        }
      )

  }

  titleChanged() {
    console.log(this.track.support_network_references, "track references");
    if (this.version.title != this.versionTitle) {
      this.version.title = this.versionTitle;
      this.versionChange.emit(this.version);
      this.setValidation()
    }
  }
  getTempo() {
    let requestUrl = environment.apiURL + `/api/v1/track/tempo-dropdown/`
    this.api.doGET(requestUrl).subscribe(x => {
      let arr;
      arr = x;
      this.tempoList = arr;
    })
  }

  bpmclick() {
    if (this.bpmValue != undefined) {
      var oldbpmvalue = this.bpmValue;
      var num = 10;
      this.bpmValue = Math.round(+oldbpmvalue + this.bpmNumber);
      this.version.calculated_bpm = this.bpmValue;
    } else {
      this.bpmValue = 10;
    }
  }
  reset() {
    this.selectedFiles = [];
    this.url = "";
    this.current = 0
    this.duration = 0
    this.wavesurfer.stop();
    this.wavesurfer = null;
    this._playerService.updateIsPlaying(false);
  }


  loadNextOnScroll(event) {
    if (this.nextList !== null)
      this.getAllTracks('', '', '', this.nextList);
  }


  bpmChange(val: any) {
    if (this.bpmValue != val) {
      this.bpmValue = Math.round(val);
    }
    if (this.bpmValue != this.version.calculated_bpm) {
      this.version.calculated_bpm = this.bpmValue;
      this.versionChange.emit(this.version);
    }
  }

  keyChange(val: any) {
    if (this.keyValue != val.value) {
      this.keyValue = val.value;
    }
    if (this.keyValue != this.version.calculated_key) {
      this.version.calculated_key = this.keyValue;
      this.versionChange.emit(this.version);
    }
  }
  trackEnding(val: any) {
    if (this.trackEnd != val.value) {
      this.trackEnd = val.value;
    }
    if (this.trackEnd != this.version.track_ending) {
      this.version.track_ending = this.trackEnd;
      this.versionChange.emit(this.version);
      this.setValidation()
    }
  }
  setMibeOwned(val: any) {
    console.log(val)
    this.version.is_mibe_owned = val;
    this.versionChange.emit(this.version);
  }

  setFiveStar(val: any) {
    console.log(val)
    this.version.is_five_star = val;
    this.versionChange.emit(this.version);
  }
  trackDetail(event: any) {
    console.log(event);
    this.trackId = event.value
    const index = this.allTracks.findIndex(i => i.id == this.trackId);
    const preIndex = this.parentArray.findIndex(i => i.id == this.trackId)
    if (preIndex < 0) {
      if (index >= 0) {
        this.parentArray.push(this.allTracks[index])
      }
    }
    this._playerService.trackDetail([])
    this._playerService.trackVersionDetail([])
    this.getAllTracks('track', event.value)
    if (index >= 0) {
      if (this.track.support_network_references == undefined) { this.track.support_network_references = []; }
      this.versionTitle = this.allTracks[index].title;
      this.parentTrackTitle = this.allTracks[index].title;
      this.titleChanged()
    }

  }
  trackValue(event: any, data: any) {
    // this.track.title = data.title
  }

  load() {
    console.log(this.trackId)
    this.page = this.page + 1;
    this.getAllTracks(this.trackId)
  }


  getAllTracks(val: any = '', value: any = '', search: any = '', load: any = '') {
    let id: any
    this.getParentVal = true
    if (this.version == null || this.version.id == undefined || this.version.id == null) {
      this._userService.currentUserStream.subscribe((u) => {
        id = u.id
      });
    }
    else {
      console.log(this.track)
      id = this.track.owned_by ? this.track.owned_by : this.track.track.owned_by;
    }
    let requestUrl = ''
    if (load === '') {
      if (search.trim() == '') {
        requestUrl = environment.apiURL + `/api/v1/trackversion/${id}/get-all-tracks/?page=${this.page}`
      }
      else {
        requestUrl = environment.apiURL + `/api/v1/trackversion/${id}/get-all-tracks/?search=${search}`
      }
    }
    else {
      requestUrl = load
    }
    this.api.doGET(requestUrl).subscribe(x => {
      let arr;
      arr = x;
      if (search.trim() === '') {
        this.parentArray = x['results']
      }
      if (search.trim() !== '' && load == '') {
        this.allTracks = (arr['results']);

      }
      else {
        this.allTracks.push(...arr['results']);

      }
      this.nextList = arr['next']
      this.filteredParentOptions.next(this.allTracks);

      // for(let data of this.allTracks){
      //   data['name'] = data.title
      // }
      this.filteredList1 = this.allTracks.slice();
      if (val === 'track') {
        for (let x of this.allTracks) {
          if (x.id === value) {
            let data: any = []

            for (let x1 of x.default_cowritten_by) {
              data.push({
                id: x1.id,
                first_name: x1.name,
                last_name: '',
                percent: x1.percentage_allocated,
                user_id: x1.id,
                disable: true
              })
            }
            let versions: any = [], versionId = 0;
            this.NarrativeCount = 0
            for (let val of x.versions) {
              if ((val.title.trim()) == (x.title.trim()) && val.is_full_version == true) {
                versions = val
                versionId = val.id
                let Url = environment.apiURL + `/api/v1/track/${versionId}/get-track-info/`
                this.api.doGET(Url).subscribe(data => {
                  this._track = data;
                  versions = data
                  if (versions.is_instrumental) {
                    this.radioChange(versions.is_instrumental)
                  }
                  if (versions.is_vocals) {
                    this.radioChangeVocal(versions.is_vocals)
                  }
                  this.version = versions;
                  this.version.title = this.version?.title ? this.version.title : '';
                  this.track = this._track
                  this._playerService.trackDetail(this._track)
                  this._playerService.trackVersionDetail(this.version)
                  // this.versionChange.emit(this.version);
                  // this.trackChange.emit(this.track)

                })
              }
              if (val.is_full_version) {
                this.NarrativeCount++;
              }
            }
            if (this.NarrativeCount >= 2) {
              this.trackVersionList = ['30 Sec', 'Bumper', 'Sting', 'Vox', 'Bed', 'Drum & Bass (Dnb)']
            }
            else {
              this.trackVersionList = ['Narrative', '30 Sec', 'Bumper', 'Sting', 'Vox', 'Bed', 'Drum & Bass (Dnb)']
            }
            this.trackId = x.id
            this._playerService.trackNum(x.cowritten_by);
            this._playerService.trackCowriter1(data)
            this._playerService.trackIdDetail(x.id)
          }
        }
      }
      this.getParentVal = false

    })
    console.log(this.allTracks)
  }
  newdata: any = []
  getTrackDetail(id) {
    this._playlistService.getTopTrackPlaylist(id).subscribe(res => {
      let data = res || []
      this.newdata = data['versions']
      for (let i = 0; i < this.newdata.length; i++) {
        if (this.version.id === this.newdata[i].id) {
          this.version.title = this.newdata[i].title
        }
      }
    })
  }
  radioChange(e) {
    console.log(e)
    if (this.version.id === undefined) {
      if (e.value === false) {
        this.version.is_vocals = true;
        this.version.is_instrumental = false;
        this.versionChange.emit(this._version);
        this._listService.currentIntrumentaltypeSubject.next(!this.version.is_instrumental);
        this.setValidation()
      }
      else {
        this.version.is_vocals = false;
        this.version.is_instrumental = true;
        this.versionChange.emit(this._version);
        this._listService.currentIntrumentaltypeSubject.next(!this.version.is_instrumental);
        this.setValidation()
      }
    }
    else if (this.replaceTrackVersion === true && this.version.id !== null) {
      if (e.value === false) {
        this.version.is_vocals = true;
        this.version.is_instrumental = false;
        this.isInstrumental = false;
        this.isVocal = true;
        this.versionChange.emit(this._version);
        this._listService.currentIntrumentaltypeSubject.next(!this.version.is_instrumental);
        this.setValidation()
      }
      else {
        this.version.is_vocals = false;
        this.version.is_instrumental = true;
        this.isInstrumental = true;
        this.isVocal = false;
        this.versionChange.emit(this._version);
        this._listService.currentIntrumentaltypeSubject.next(!this.version.is_instrumental);
        this.setValidation()
      }
    }
    else {
      if (e.value === false) {
        this.version.is_vocals = true;
        this.version.is_instrumental = false;
        this.isInstrumental = false;
        this.isVocal = true;
        this.versionChange.emit(this._version);
        this._listService.currentIntrumentaltypeSubject.next(!this.version.is_instrumental);
        this.setValidation()
      }
      else {
        this.version.is_vocals = false;
        this.version.is_instrumental = true;
        this.isInstrumental = true;
        this.isVocal = false;
        this.versionChange.emit(this._version);
        this._listService.currentIntrumentaltypeSubject.next(!this.version.is_instrumental);
        this.setValidation()
      }
    }
  }
  radioChangeVocal(e) {
    if (this.replaceTrackVersion === true && this.version.id !== null) {
      if (e.value === false) {
        this.version.is_vocals = false;
        this.version.is_instrumental = true;
        this.isInstrumental = true;
        this.isVocal = false;
        this.versionChange.emit(this._version);
        this._listService.currentIntrumentaltypeSubject.next(!this.version.is_instrumental);
        this.setValidation()
      }
      else {
        this.version.is_vocals = true;
        this.version.is_instrumental = false;
        this.isInstrumental = false;
        this.isVocal = true;
        this.versionChange.emit(this._version);
        this._listService.currentIntrumentaltypeSubject.next(!this.version.is_instrumental);
        this.setValidation()
      }
    }
    else {
      if (e.value === false) {
        this.version.is_vocals = false;
        this.version.is_instrumental = true;
        this.isInstrumental = true;
        this.isVocal = false;
        this.versionChange.emit(this._version);
        this._listService.currentIntrumentaltypeSubject.next(!this.version.is_instrumental);
        this.setValidation()
      }
      else {
        this.version.is_vocals = true;
        this.version.is_instrumental = false;
        this.isInstrumental = false;
        this.isVocal = true;
        this.versionChange.emit(this._version);
        this._listService.currentIntrumentaltypeSubject.next(!this.version.is_instrumental);
        this.setValidation()
      }
    }

  }

  selectFiles(event) {
    const files = event.target.files;
    let isImage = true;
    this.isFailed = false;
    if (files.item(0).type && files.item(0).type.match('audio/wav')) {
      this.selectedFiles = files[0];
      this.upload(0, this.selectedFiles);
      console.log(this.selectedFiles)
      this.myInputVariable.nativeElement.value = "";
    } else {
      isImage = false;
      // alert('invalid format!');
    }

  }

  upload(idx, file) {
    this.isUploading = true;
    const formData = new FormData();
    formData.append("file", file);
    let promise = new Promise<any>((resolve, reject) => {
      this.uploadWithProgress(formData)
        .subscribe(event => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progressInfos = Math.round(100 * event.loaded / event.total);
            console.log(this.progressInfos);
            if (this.progressInfos == 100) {
              setTimeout(() => {
                this.isUploaded = true;
                this.isUploading = false;
                this.loadingTracks = true;
              }, 200);
            }
          } else if (event instanceof HttpResponse) {
            resolve(event);
            // console.log(file.name + ', Size: ' + file.size + ', Uploaded URL: ' + event.body.link);
          }
          // resolve(event); 
          console.log(event)
          if (event.status == 200 && event.body) {
            this.url = event.body.file
            this.title = event.body.file_name;
            this._sliderDialogService.getReplaceTrackFileSubject.next(event.body.id);
            this.cdr.detectChanges();
          }
          if (this.url !== '') {
            this.generate();
          }
        },
          err => {
            this.message = 'Could not upload the file:' + file.name;
            this.isFailed = true;
            if (err.error.details) {
              // alert(err.error.details)
              let data = err.error?.error ? err.error?.error : (err.error?.detail ? err.error?.detail : (err.error?.details ? err.error?.details : ``))
              this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
              });
            }
            else {
              // alert("Kindly check the audio level.  Please contact the MIBE administrator.")
              let data = err.error?.error ? err.error?.error : (err.error?.detail ? err.error?.detail : (err.error?.details ? err.error?.details : ``))
              this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
              });

            }
            this.selectedFiles = [];
            this.url = "";
            this.current = 0
            this.duration = 0
            this.wavesurfer = null;
            resolve(true);
          });
    })
    return promise;
  }

  uploadWithProgress(formData: FormData): Observable<any> {
    return this.http.post(environment.apiURL + '/api/v1/upload/', formData, { observe: 'events', reportProgress: true })
      .pipe(
        catchError(err => this.handleError(err))
      );
  }
  private handleError(error: any) {
    return throwError(error);
  }
  trackVersionTitle(val: any) {
    this.version.title = val.value;
    if (this.version.title === 'Narrative') {
      this.version.is_full_version = true
    }
    this.versionChange.emit(this.version);
    this.setValidation();
  }
  sizeOf = function (bytes) {
    if (bytes == 0) { return "0.00 B"; }
    var e = Math.floor(Math.log(bytes) / Math.log(1024));
    return (bytes / Math.pow(1024, e)).toFixed(2) + ' ' + ' KMGTP'.charAt(e) + 'B';
  }

  generate() {
    if (this.url !== '' && this.url !== null) {
      if (this.wavesurfer === null) {
        this.wavesurfer = this.waveService.create({
          container: '#wave',
          waveColor: 'white',
          height: 30,
          progressColor: 'black',
          backend: 'MediaElement',
          responsive: true,
          fillParent: false,
          scrollParent: false,
          minPxPerSec: 30,
          cursorColor: "#fff",
          cursorWidth: 0,
        });
      }
      this.generateWaveform();
      this.cdr.detectChanges();

      Promise.resolve().then(() => {
        let url: any = `${environment.apiURL}/api/v1/trackversions/get-version-waveform/?version_id=${this.version.id}`
        this.http.get(url).subscribe((y: any) => {

          let hostUrl = environment.awsHost + y.mp3_file
          let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
          if (isSafari) {
            let result = hostUrl.replace(/.mp4/g, ".mp3");
            y['waveform_file_data'] ? this.wavesurfer.load(result, y.waveform_file_data) : this.wavesurfer.load(y.mp3_file);
          }
          else {
            y['waveform_file_data'] ? this.wavesurfer.load(hostUrl, y.waveform_file_data) : this.wavesurfer.load(y.mp3_file);
          }

        })
      });



    }
  }
  generateWaveform(): void {
    Promise.resolve(null).then(() => {
      this.wavesurfer.on('ready', () => {
        this.loadingTracks = false;
        // this.wavesurfer.play();
        this.duration = this.wavesurfer.getDuration();
        this.cdr.detectChanges();
        this.wavesurfer.isPlaying()
        // this.isPlaying =true;
      });

      this.wavesurfer.on('audioprocess', () => {
        this.current = this.wavesurfer.getCurrentTime()
        this.cdr.detectChanges();
        this.wavesurfer.on('finish', () => {
          this.current = 0
          this.wavesurfer.stop()
          this.isPlaying = false;
          this.cdr.detectChanges();

        })
      })


    });
  }
  getCurrentPreview() {
    setTimeout(() => {
      this.current = this.wavesurfer.getCurrentTime()
      this.cdr.detectChanges();
    }, 100);
  }

  play() {
    this.isPlaying = true;
    this.wavesurfer.play();
  }
  pause() {
    this.isPlaying = false;
    this.wavesurfer.playPause();
  }
  setMainTrack(event) {
    this.version.is_main_track = event;
    this.version.title = this.version.title + " " + "Main"
    this.versionChange.emit(this._version);
  }
  setValidation() {
    if (this.track) {

      if (this.versionTitle && this.version.track_ending && this.track.title) {
        this.track.isValid = false;
      }
      else {
        this.track.isValid = true;
      }
    }
    if (this.version.is_instrumental === false) {

      if (this.versionTitle.trim() !== '' && ([...this.track.version_network_references, ...this.track['support_network_references']])?.length >= 2 && ([...this.track.version_tv_references, ...this.track['support_tv_references']])?.length >= 2
        && ([...this.track.version_sonic_reference, ...this.track['support_sonic_references']])?.length >= 2 &&
        ([...this.track.version_band_references, ...this.track['support_band_references']])?.length >= 2 && (this.track.Vocalssub_category && (this.track.Vocalsdescriptions && this.track.Vocalsdescriptions?.length > 0))
        && (this.track.lyrics.verse != '' || this.track.lyrics.pre_chorus != '' || this.track.lyrics.chorus != '' || this.track.lyrics.bridge != '')
        && (this.track.Instrumentsub_category && (this.track.Instrumentdescriptions
          && this.track.Instrumentdescriptions?.length > 0) ||
          (this.track.Instrumentnew_suggestion && this.track.Instrumentnew_suggestion?.length > 0))) {
        this.track.isValid = false;
      }
      else {
        this.track.isValid = true;
      }
    }
    else {

      if (this.versionTitle.trim() !== '' && ([...this.track.network_references, ...this.track.version_network_references, ...this.track['support_network_references']])?.length >= 2 && ([...this.track.tv_references, ...this.track.version_tv_references, ...this.track['support_tv_references']])?.length >= 2
        && ([...this.track.sonic_references, ...this.track.version_sonic_reference, ...this.track['support_sonic_references']])?.length >= 2 &&
        ([...this.track.band_references, ...this.track.version_band_references, ...this.track['support_band_references']])?.length >= 2 && (this.track.Instrumentsub_category && (this.track.Instrumentdescriptions
          && this.track.Instrumentdescriptions?.length > 0) ||
          (this.track.Instrumentnew_suggestion && this.track.Instrumentnew_suggestion?.length > 0))) {
        this.track.isValid = false;
      }
      else {
        this.track.isValid = true;
      }
    }

    if (this.parentTrackTitle === this.versionTitle.trim()) {
      this.track.isValid = true;
      if (this.parentTrackTitle !== "" && this.versionTitle !== "") {
        this.showVerNameErr = true;
      }
    } else {
      this.showVerNameErr = false;
    }
  }

}
