<mat-form-field appearance="fill" [ngClass]="((bpmValue  === 0  ) ) ? 'addErrorValidation' :''">
    <mat-label>BPM</mat-label>
    <input  id="counter" type="text" matInput [(ngModel)]="bpmValue" pattern ='^[1-9][0-9]{3}$' (blur)="$event.preventDefault();$event.stopPropagation();onBlur($event.target.value)" maxlength="3" (keypress)="preventDot($event)" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
    <button [ngStyle]="{'background-color': bpmService.timerRunning ? '#50E3C2' : ''}" id='button' type="button" class="bpm-btn bpmDivFlexItem" (click)="$event.preventDefault();$event.stopPropagation();bpmclick()" mat-stroked-button>
        <b>BPM</b>
      </button>
      <p *ngIf="(bpmValue)  === 0" style="
      color: red;
      font-size: small;
      margin-left: -10px;
      margin-top: 5%;position: absolute;" >BPM cannot be empty or 0</p>
</mat-form-field>