import { Component, OnInit, ViewChild, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { environment } from '../../../environments/environment';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { ComposerPRO } from '../../shared/models/composer-pro.model';
import { ComposerPROService } from '../../shared/services/composer-pro.service';
import { startWith, debounceTime, distinctUntilChanged, tap, switchMap } from 'rxjs/operators';


@Component({
  selector: 'app-pro',
  templateUrl: './pro.component.html',
  styleUrls: ['./pro.component.scss']
})



export class ProComponent implements OnInit {
  loading: boolean = false;
  proForm: FormGroup;
  myProDetails: any = [];
  myProDetailss: any = [];

  composerPROs: ComposerPRO[] = [];
  artistPROs: any = [];
  proFilterCtrl = new FormControl();
  filteredPROOptions: ReplaySubject<ComposerPRO[]> = new ReplaySubject<
    ComposerPRO[]
  >(1);
  searchingPRO = false;
  Selects = "Select";
  proFieldDisable: Boolean = false;
  caeipiFieldDisable: Boolean = false;
  companyFieldDisable: Boolean = false;
  ssnFieldDisable: Boolean = false;
  einFieldDisable: Boolean = false;
  private _subscriptions: Subscription[] = [];
  constructor(
    private cd: ChangeDetectorRef,
    public proInfo: FormBuilder,
    public api: UserMenuService,
    public config: MenuConfigService,
    private _composerPROService: ComposerPROService,
  ) { }

  ngOnInit(): void {
    this.proForm = this.proInfo.group({
      cae_ipi: new FormControl('', [Validators.required]),
      company_name: new FormControl('', [Validators.required]),
      ein: new FormControl('', [Validators.required]),
      ssn: new FormControl('', [Validators.required]),
      artist_royalities: new FormControl(''),
      pro: new FormControl('', [Validators.required])
    })
    this.getProDetails();
    this._composerPROService.getPROs().subscribe(
      (data) => {
        this.artistPROs = data;
        this.filteredPROOptions.next(this.artistPROs);
      },
      (error) => {
        console.log(error);
        //alert("An error ocurred while getting Composer PROs. Check your connections and try again");
      }
    );
    this._subscriptions.push(
      this.proFilterCtrl.valueChanges
        .pipe(
          startWith(""),
          debounceTime(150),
          distinctUntilChanged(),
          tap(() => {
            this.searchingPRO = true;
          }),
          switchMap((v) => {
            if (!v) {
              return of(this.artistPROs);
            }
            return of(
              this.artistPROs.filter(
                (pro) =>
                  pro.pro.toLowerCase().includes(v.toLowerCase()) ||
                  pro.publisher.name.toLowerCase().includes(v.toLowerCase())
              )
            );
          }),
          tap(() => {
            this.searchingPRO = false;
          })
        )
        .subscribe(
          (pros) => {
            this.filteredPROOptions.next(pros);
          },
          (error) => {
            console.log(error);
            //alert("An error ocurred while getting the filtered PROs. Check your connections and try again");
          }
        )
    );
  }

  getProDetails() {
    this.loading = true;
    let url = environment.apiURL + this.config.userPro;
    this.api.doGET(url).subscribe(x => {
      this.loading = false;
      this.myProDetails = x || [];
      this.myProDetailss = x || [];
      if (this.myProDetails) {
        this.proFieldDisable = this.myProDetailss.pro && this.myProDetailss.pro != '' ? true : false;
        this.caeipiFieldDisable = this.myProDetailss.cae_ipi && this.myProDetailss.cae_ipi != '' ? true : false;
        this.companyFieldDisable = this.myProDetailss.company_name && this.myProDetailss.company_name != '' ? true : false;
        this.ssnFieldDisable = this.myProDetailss.ssn && this.myProDetailss.ssn != '' ? true : false;
        this.einFieldDisable = this.myProDetailss.ein && this.myProDetailss.ein != '' ? true : false;
      }
      console.log(x)
    },
      (error) => {
        this.loading = false;
      })
  }

  updatePRO() {
    let postbody = {
      "cae_ipi": this.proForm.value.cae_ipi,
      "company_name": this.proForm.value.company_name,
      "ein": this.proForm.value.ein,
      "ssn": this.proForm.value.ssn,
      "artist_royalities": this.proForm.value.artist_royalities,
      "pro": this.proForm.value.pro
    }
    this.loading = true;
    let url = environment.apiURL + this.config.userPro;
    this.api.doPUT(url, postbody).subscribe(x => {
      this.loading = false;
      this.getProDetails()
    },
      (error) => {
        this.loading = false;
        //alert(error.message)
      })
  }

  passwordInputType = 'password';
  togglePasswordInputType(type: any = "") {

      if (this.passwordInputType == 'password') {
        this.passwordInputType = 'text';
      } else {
        this.passwordInputType = 'password';
      }

  }

  disableSave(): Boolean {
    if (this.myProDetailss) {
      if (!this.myProDetailss.pro || !this.myProDetailss.cae_ipi || !this.myProDetailss.company_name || !this.myProDetailss.ssn || !this.myProDetailss.ein) {
        return false;
      }
      return true;
    }
    return false;
  }

}


