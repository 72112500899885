import { Component, ViewChild, OnInit } from '@angular/core';
import { SliderDialogService } from '../../shared/services/slider-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteUsergroupsDialogueComponent } from '../delete-usergroups-dialogue/delete-usergroups-dialogue.component';
import { EmailTemplate } from '../../shared/models/email-template.model';
import { Observable } from 'rxjs';
import { DialogService } from '../../shared/services/dialog.service';
import { NgModel } from '@angular/forms';
import { DeleteEditGroupPermissionDialogueComponent } from '../delete-edit-group-permission-dialogue/delete-edit-group-permission-dialogue.component';
import { DeleteEditUserDialogueComponent } from '../delete-edit-user-dialogue/delete-edit-user-dialogue.component';
import { DeleteManageUserDialogueComponent } from '../delete-manage-user-dialogue/delete-manage-user-dialogue.component';
import { DeleteNewGroupDialogueComponent } from '../delete-new-group-dialogue/delete-new-group-dialogue.component';
import { DuplicateNewGroupDialogueComponent } from '../duplicate-new-group-dialogue/duplicate-new-group-dialogue.component';
import { DeleteRemoveUserDialogueComponent } from '../delete-remove-user-dialogue/delete-remove-user-dialogue.component';
import { MenuListService } from '../../shared/services/menu-list.service';
import { UserGroups } from '../../interfaces/user-groups';
import { environment } from '../../../environments/environment';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { MenuUser } from '../../shared/models/menu-user.model';
import { UserMenuService } from '../../shared/services/user-menu.service';
import {MatSort} from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
const groupPageSize = 25;

@Component({
  selector: 'app-user-groups',
  templateUrl: './user-groups.component.html',
  styleUrls: ['./user-groups.component.scss']
})
export class UserGroupsComponent implements OnInit  {
  displayedColumns = ['first_name', 'date_joined', 'weight', 'symbol'];
  constructor(private _sliderDialogService:SliderDialogService,    private _activatedRoute: ActivatedRoute,
    private _router: Router,private dialog: DialogService, public list: MenuListService, public config : MenuConfigService, public api: UserMenuService) { 
   
  }
  primary:any;
  
  searchFilterFocused:any;
  searchString:any;
  count: number;
  private _currentGroupsPage = 1;

  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    this.list.dataSourceForUserGroups.sort = value;  
  }
  set currentGroupsPage(p: number) {
    if (!isNaN(p) && p != this._currentGroupsPage) {
      this._currentGroupsPage = p;
      this.list.getAllGroups(this._currentGroupsPage);     
    }
  }
  get currentGroupsPage(): number {
    return this._currentGroupsPage;
  }
  get groupsNumberOfPages(): number {
    return Math.ceil(this.list.count / groupPageSize);
  }
  
  ngOnInit(): void {
    this.list.getAllGroups(this._currentGroupsPage);
    this.currentGroupsPage = this.list.page
  }
  
  modelclick(){
    this.dialog.displayDeleteUsergroupDialog(DeleteUsergroupsDialogueComponent)
  }
  duplicateclick(){
    this.dialog.displayDeleteUsergroupDialog(DuplicateNewGroupDialogueComponent)
  }
  editGroupPermission(){
    this.dialog.displayDeleteUsergroupDialog(DeleteEditGroupPermissionDialogueComponent)
  }
  editUser(){
    this.dialog.displayDeleteUsergroupDialog(DeleteEditUserDialogueComponent)
  }
  manageUser(){
    this.dialog.displayDeleteUsergroupDialog(DeleteManageUserDialogueComponent)
  }
  newGroups(){
    this.dialog.displayDeleteUsergroupDialog(DeleteNewGroupDialogueComponent)
  }
  removeGroups(params){
    this.dialog.displayDeleteUsergroupDialog(DeleteRemoveUserDialogueComponent)
  }
  getFindUserGroups(){

    if(this.searchString.length > 2){
      this.list.loadingUser = true;
       let url = environment.apiURL + this.config.doGroups + '?search=' + this.searchString;
    this.api.doGET(url).subscribe(x=>{
      this.list.loadingUser = false;
      this.count = x['count'] || 0
      this.list.count= x['count'] || 0
      this.list.AllGroups = x['results'] || []
      this.list.getSingleUserGroups(x['results'][0].id)
      this.list.currentAdminUserGroupsSubject.next(x['results'][0].id)
    },
    (error)=>{
      this.list.loadingUser = false;
      //alert(error.message)
    }
  )
    }
    else{
      this.list.getAllGroups(this._currentGroupsPage);
    }
  }
}
