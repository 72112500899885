import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpParams, HttpEventType } from '@angular/common/http';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../environments/environment';
import { User } from '../models/user.model'
import { SonicReference } from '../models/sonicreference.model';
import { APIListResponse } from '../models/apiresponse.model';
import { UserService } from './user.service';
import { Observable, BehaviorSubject, ReplaySubject, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SonicReferenceService {

    constructor(
        private http: HttpClient,
        private _platform: Platform,
        private _transferState: TransferState
    ) {
    }

    getAllReferences(): Observable<SonicReference[]> {
        return this.getReferences({});
    }

    private getReferences(params: {[param: string]: string | string[]}): Observable<SonicReference[]> {
        let p = new HttpParams({ fromObject: params });
        // Check if genres are cached from server
        const SONIF_KEY = makeStateKey<APIListResponse<Object>>('sonic');
        if (this._transferState.hasKey(SONIF_KEY)) {
            const references = this._transferState.get<APIListResponse<Object>>(SONIF_KEY, null);
            this._transferState.remove(SONIF_KEY);
            return of(references.results.map((s) => new SonicReference(s)));
        }

        let headers = new HttpHeaders();
        return this.http.get<APIListResponse>(
           // environment.apiURL + '/api/v1/sonicreferences/',
            environment.apiURL + '/api/v1/sonicreferences/sonic-and-band-reference-dropdown/',
            {
                headers: headers
            }
            ).pipe(
            tap(res => {
                // If we're on the server cache the genres
                if (!this._platform.isBrowser) {
                this._transferState.set(SONIF_KEY, res);
                }
            }),
            map((res) => res.results.map((s) => new SonicReference(s)))
            );
    }
}