
<div class="dialog-container">
    <div class="dialog-header">
        <h4 class="dialog-heading">Download Options</h4>
        <button type="button" class="close-button" (click)="close()">
        <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Nav-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                <g id="Genre" transform="translate(-1438.000000, -32.000000)" class="inner-stroke" stroke="#FFFFFF">
                    <g id="Genre-Options">
                        <g id="Close" transform="translate(1439.000000, 32.000000)">
                            <path d="M24,0 L0,24" id="Path"></path>
                            <path d="M0,0 L24,24" id="Path"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        </button>
    </div>
<mat-dialog-content>
        <div class="dialog-body">
            
            <p><strong>Reports Date Range for Download</strong></p>
            <!-- <div>
             <app-range-datepicker></app-range-datepicker>
                
            </div> -->
            <mat-form-field appearance="fill">
                
                <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [max]="maxDate">
                  <input matStartDate formControlName="start" [ngModel]="startDate" (dateChange)="startChanged($event.value)" placeholder="From">
                  <input matEndDate formControlName="end" [ngModel]="endDate" (dateChange)="endChanged($event.value)" placeholder="To">
                </mat-date-range-input>
                <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
                <span class="range-date1">DATE RANGE</span>
                <mat-date-range-picker #picker></mat-date-range-picker>
              <span class="clear-option" *ngIf="clearShow" (click)="clearDates()">Clear</span>
                <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
              </mat-form-field>
            
            <div class="form-col">
              <button class="form-button" [disabled]="start_date=='' || end_date==''" (click)="downloadAllReports()">Download</button>
              <button class="form-button form-button-cancel" (click)="close()">Cancel</button>
            </div>
          </div>
    </mat-dialog-content>
    </div>