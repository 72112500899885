<div class="card container">
  <div class="flex-item">
    <div class="title"><b>{{ title }}</b></div>
    <mat-chip-list #chipList aria-label="Mood selection">
      <mat-chip
        *ngFor="let mood of moods"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="remove(mood)">
        {{mood.name}}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>
  <div class="flex-item">
    <label>
      <input
        placeholder="New ..."
        #moodInput
        [formControl]="moodCtrl"
        [matChipInputFor]="chipList"
        [matAutocomplete]="auto"
        (matChipInputTokenEnd)="add($event)">
    </label>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let mood of filteredMoods | async" [value]="mood">
        {{mood.name}}
      </mat-option>
    </mat-autocomplete>
  </div>
</div>