import { Component, Inject, OnDestroy, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { of, ReplaySubject, Subscription } from 'rxjs';
import { startWith, debounceTime, distinctUntilChanged, tap, switchMap, map,filter } from 'rxjs/operators';
import { Playlist } from '../models/playlist.model';
import { PlaylistClient } from '../models/playlistclient.model';
import { User } from '../models/user.model';
import { GetPlaylistsOptions, PlaylistService } from '../services/playlist.service';

import { MenuListService } from '../../shared/services/menu-list.service';
import { environment } from '../../../environments/environment';
import { UserMenuService } from '../services/user-menu.service';
const playlistLimit = 30; 
export interface DialogData {
  user: User;
}
@Component({
  selector: 'app-manage-user-playlists',
  templateUrl: './manage-user-playlists.component.html',
  styleUrls: ['./manage-user-playlists.component.scss']
})
export class ManageUserPlaylistsComponent implements OnInit {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
searchPeram:string = ''
  activeTab: 'personal'|'shared' = 'shared';
  searchString = document.getElementById('myInput');
  loading = false;
  personalPlaylists: any = [];
  sharedPlaylists: any = [];
  allPlaylist: any = [];
  modifiedPlaylists: Playlist[] = [];
  checkedPlaylists: any = [];
  uncheckedPlaylist:any = []
  searchControl = new FormControl();
  playlistOptions: ReplaySubject<Playlist[]> = new ReplaySubject<Playlist[]>(1);
  searchingPlaylists = false;
  selectedCount: any = 0;
  private _subscriptions:Subscription[] = [];

  constructor(
    private dialogRef: MatDialogRef<ManageUserPlaylistsComponent>,
    private _playlistService: PlaylistService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public api: UserMenuService,
    public list: MenuListService
  ) {
    // this.list.getAllAvailablePlayList()
    // getAllAvailablePlayList(){
      // let url = environment.apiURL + '/api/v1/admin/users/get-available-playlist/';
      // this.api.doGET(url).subscribe(x=>{
      //     this.allPlaylist = x['results'] || [];
      //     this.allPlaylist.forEach(x=>{
      //       x['checked'] = false;
      //       return x;
      //     })
      //     if( this.allPlaylist.length > 0){
          let url2 = environment.apiURL + '/api/v1/admin/users/'+this.data.user.id+'/get-shared-playlist/';
          this.api.doGET(url2).subscribe(x=>{
            this.allPlaylist = x['results'] || [];
            this.allPlaylist.forEach(x=>{
                x['checked'] = x['is_shared'];
                return x;
              })
              // if(this.sharedPlaylists.length > 0){
              // this.getSharedItems(this.allPlaylist,this.sharedPlaylists)
              // }
              // else{
              //   this.allPlaylist = this.allPlaylist;
              // }
            },
            (error)=>{     
              //alert(error.message)
            }
          )
    }
get getCount(){
  let data = this.allPlaylist.filter(x=> x.checked) || []
  return data.length
}
  ngOnInit(): void {
    console.log(this.data.user.id)
    if (!this.data.user) return;
    // let url1 = environment.apiURL + '/api/v1/admin/users/'+this.data.user.id+'/get-shared-playlist/';
    let url1 = environment.apiURL + '/api/v1/admin/users/'+this.data.user.id+'/get-personal-playlist/';
    this.api.doGET(url1).subscribe(x=>{
        this.personalPlaylists = x['results'] || [];
        // this.sharedPlaylists = x || [];
        console.log(this.personalPlaylists)
      },
      (error)=>{     
        //alert(error.message)
      }
      
    )
   
    // let url2 = environment.apiURL + '/api/v1/admin/users/'+this.data.user.id+'/get-shared-playlist/';
    // this.api.doGET(url2).subscribe(x=>{
    //     this.sharedPlaylists = x || [];
    //     this.sharedPlaylists.forEach(x=>{
    //       x['checked'] = true;
    //       return x;
    //     })
    //     if(this.sharedPlaylists.length > 0){
    //     this.getSharedItems(this.list.allPlaylist,this.sharedPlaylists)
    //     }
    //     else{
    //       this.allPlaylist = this.list.allPlaylist;
    //     }
    //   },
    //   (error)=>{     
    //     //alert(error.message)
    //   }
    // )
    /* this._playlistService.getAllPlaylists({createdBy: this.data.user.id, limit:playlistLimit}).subscribe(res => {
      this.personalPlaylists = res.results;
      this.playlistOptions.next(res.results);
      this.loading = false;
    }, (error) => {
      console.log(error);
      alert("An error ocurred while getting the playlists created by the user. Check your connections and try again");
    });
    this._playlistService.getAllPlaylists({sharedWith: this.data.user.id}).subscribe(res => {
      this.sharedPlaylists = res.results;
      this.checkedPlaylists = res.results;
    }, (error) => {
      console.log(error);
      alert("An error ocurred while getting the playlists shared with the user. Check your connections and try again");
    })
    this._subscriptions.push(this.searchControl.valueChanges.pipe(
      startWith(''),
      debounceTime(150),
      distinctUntilChanged(),
      tap(() => {
        this.searchingPlaylists = true;
      }),
      switchMap((v) => {
        if (!v || v.length <= 2) {
          return of(this.activeTab == 'personal' ? this.personalPlaylists:this.sharedPlaylists);
        }
        let params:GetPlaylistsOptions = {search:v, limit:playlistLimit}
        if (this.activeTab == 'personal') {
          params.createdBy = this.data.user.id;
        }
        return this._playlistService.getAllPlaylists(params).pipe(
          map(res => {
            let ret = res.results;
            if (this.activeTab == 'shared') {
              for(let playlist of this.modifiedPlaylists) {
                if(!ret.some(p=>p.id == playlist.id)) {
                  ret.push(playlist);
                }
              }
            }
            return ret;
          })
        );
      }),
      tap(() => {
        this.searchingPlaylists = false;
      })
    ).subscribe(p => {
      this.playlistOptions.next(p);
    }, (error) => {
      console.log(error);
      alert("An error ocurred while getting the filtered playlists. Check your connections and try again");
    })); */
    
  }
  close() {
    this.dialogRef.close(false);
  }
  scrollToBottom(): void {
    try {
     let pos = (this.myScrollContainer.nativeElement.scrollTop || this.myScrollContainer.nativeElement.body.scrollTop) + this.myScrollContainer.nativeElement.offsetHeight;
     let scro = this.myScrollContainer.nativeElement.scrollHeight;
     console.log(Math.round(pos))
     console.log(scro)
    if((scro-5)< Math.round(pos)){
    
      this.loadMore();
    }
    } catch(err) { }                 
}
ngAfterViewChecked() {        
  this.scrollToBottom();        
}

scrollHandler(event){

  console.log(this.myScrollContainer.nativeElement.scrollHeight)

}
  getSharedItems(allList,sharedList) {
    allList.forEach(item =>{
      sharedList.forEach(shared =>{
       if(item.id===shared.id) {
        item.checked = true;
        console.log(item.id)
        this.checkedPlaylists.push(item.id);
        this.selectedCount = this.checkedPlaylists.length;
       }   
      });     
    });
    
    this.allPlaylist = allList;
    console.log(this.allPlaylist)
  }
  switchTab(tab) {
    if (this.activeTab != tab) {
      this.activeTab = tab;
      this.playlistOptions.next(this.activeTab == 'shared' ? this.sharedPlaylists:this.personalPlaylists);
    }
  }

  isOwner(playlist:Playlist) {
    return playlist.created_by == this.data.user.id;
  }

  isPlaylistChecked(playlist:Playlist) {
    return this.checkedPlaylists.some(p=>p.id == playlist.id);
  }

  playlistChecked(checked:boolean, playlist, index) {
    
    this.toggleModified(playlist);
    if(checked){
      this.allPlaylist[index].checked = true;   
    }else{
      for(let perm of this.allPlaylist){
        if(playlist.id == perm.id && perm.is_shared == true){
          this.uncheckedPlaylist.push(playlist.id)
        }
      }
      this.allPlaylist[index].checked = false;  
    }  
    if (checked && !this.checkedPlaylists.includes(playlist.id)) {
      this.checkedPlaylists.push(playlist.id);
      this.selectedCount = this.checkedPlaylists.length;
      return;
    }else{
     /*  let i = this.checkedPlaylists.findIndex(p=>p.id == playlist.id);
      if (i >= 0) {
        this.checkedPlaylists.splice(i, 1);
      } */
      this.checkedPlaylists = this.checkedPlaylists.filter(x => x !== playlist.id);
      this.selectedCount = this.checkedPlaylists.length;
    }
  }

  toggleModified(playlist:Playlist) {
    let i = this.modifiedPlaylists.findIndex(p=>p.id == playlist.id)
    if (i >= 0) {
      this.modifiedPlaylists.splice(i, 1);
    } else {
      this.modifiedPlaylists.push(playlist);
    }
  }

  playlistTrackBy(i:number, playlist:Playlist) {
    return playlist.id;
  }

  canSubmit():boolean {
    return this.checkedPlaylists && this.checkedPlaylists.length >= 0;
  }
  
  getFindSharedList() {
    var input, filter, ul, li, span, i, txtValue ,activeTab;
    input = document.getElementById('myInput');
    filter = input.value.toUpperCase();
    ul = document.getElementById("checkBoxUl"); 
    li = ul.getElementsByTagName('li');

    for (i = 0; i < li.length; i++) {
      span = li[i].getElementsByTagName("span")[0];
      txtValue = span.textContent || span.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
}
 
getFindPersonalList() {
  var input, filter, ul, li, span, i, txtValue;
    input = document.getElementById('myInput');
    filter = input.value.toUpperCase();   
    ul = document.getElementById("checkBoxU2");  
    li = ul.getElementsByTagName('li');

    for (i = 0; i < li.length; i++) {
      span = li[i].getElementsByTagName("a")[0];
      txtValue = span.textContent || span.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
}
deselectPlaylist(){ 
  for(let x of this.allPlaylist){
        if(x.checked){
          x.checked = false;
          this.uncheckedPlaylist.push(x.id)
        }
  }
  this.checkedPlaylists=[];
  this.selectedCount = this.checkedPlaylists.length;
}
/*   save() {
    if (!this.canSubmit()) return;
    this.loading = true;
    this.playlistsHandled = [];
    this.modifiedPlaylists.forEach(playlist => {
      if (this.isPlaylistChecked(playlist)) {
        if (playlist.active_clients.some(client=>client.id==this.data.user.id)) return;
        playlist.active_clients.push(new PlaylistClient({id: this.data.user.id}));
        this._playlistService.updatePlaylist(playlist).subscribe(p => {
          this.playlistsHandled.push(playlist.id);
          this.closeIfDone();
        }, (error) => {
          console.log(error);
          alert(`An error ocurred while updating the playlist ${playlist.getDisplayName()}. Check your input for errors, and then check connection and try again`);
        })
      } else {
        let i = playlist.active_clients.findIndex(client=>this.data.user.id==client.id);
        if (i < 0) return;
        playlist.active_clients.splice(i, 1);
        this._playlistService.updatePlaylist(playlist).subscribe(p => {
          this.playlistsHandled.push(playlist.id);
          this.closeIfDone();
        }, (error) => {
          console.log(error);
          alert(`An error ocurred while updating the playlist ${playlist.getDisplayName()}. Check your input for errors, and then check connection and try again`);
        })
      }
    });
    this.closeIfDone();
  } */
  saveSharedPlayList(){
    let index1 =0
    for(let data of this.checkedPlaylists){
      let index2 = 0
      for(let data2 of this.uncheckedPlaylist){
        if(data == data2){
          this.checkedPlaylists.splice(index1, 1); 
          this.uncheckedPlaylist.splice(index2, 1); 
        }
        index2++
      }
      index1++
    }
    let posSharedBody = {
      'new_playlist_ids': this.checkedPlaylists,
      'remove_playlist_ids': this.uncheckedPlaylist
    }
    let requestURL = environment.apiURL + '/api/v1/admin/users/'+this.data.user.id+'/update-shared-playlist/';
    this.api.doPUT(requestURL, posSharedBody).subscribe((result: any) => {
      this.dialogRef.close();
    },
      (error) => {
        this.dialogRef.close();
      });
      console.log(posSharedBody)
  }

  private playlistsHandled = [];
  private closeIfDone() {
    if (this.playlistsHandled.length != this.modifiedPlaylists.length) return;
    this.dialogRef.close(true);
  }
  pageIndex = 1
  searchIndex = 1;
  loadMore(){
    if(this.searchPeram === ''){
      this.pageIndex ++;
      this.searchIndex = 1
      // let url = environment.apiURL + `/api/v1/admin/users/get-available-playlist/?page=${this.pageIndex}`;
      // this.api.doGET(url).subscribe(x=>{
      //     // this.allPlaylist = x['results'] || [];
      //     x['results'].forEach(x=>{
      //       x['checked'] = false;
      //       this.allPlaylist.push(x)
      //       return x;
      //     })
         
          let url2 = environment.apiURL + `/api/v1/admin/users/${this.data.user.id}/get-shared-playlist/?page=${this.pageIndex}`;
          this.api.doGET(url2).subscribe(x=>{
              // this.sharedPlaylists = x['results'] || [];
              x['results'].forEach(x=>{
                x['checked'] = x['is_shared'];
                this.allPlaylist.push(x)
                return x;
              })
              // if(this.sharedPlaylists.length > 0){
              // this.getSharedItems(this.allPlaylist,this.sharedPlaylists)
              // }
              // else{
              //   this.allPlaylist = this.allPlaylist;
              // }
            },
            (error)=>{     
              //alert(error.message)
            }
          )
      //   },
      //   (error)=>{   
      //   }
      // )
    }
    else{
    
      this.searchIndex++
      // let url = environment.apiURL + `/api/v1/admin/users/get-available-playlist/?page=${this.searchIndex}&search=${this.searchPeram}`;
      // this.api.doGET(url).subscribe(x=>{
          // this.allPlaylist = x['results'] || [];
          // x['results'].forEach(x=>{
          //   x['checked'] = false;
          //   this.allPlaylist.push(x)
          //   return x;
          // })
         
          let url2 = environment.apiURL + `/api/v1/admin/users/${this.data.user.id}/get-shared-playlist/?page=${this.searchIndex}&search=${this.searchPeram}`;
          this.api.doGET(url2).subscribe(x=>{
              // this.sharedPlaylists = x['results'] || [];
              x['results'].forEach(x=>{
                x['checked'] = x['is_shared'];
                this.allPlaylist.push(x)
                return x;
              })
              // if(this.sharedPlaylists.length > 0){
              // this.getSharedItems(this.allPlaylist,this.sharedPlaylists)
              // }
              // else{
              //   this.allPlaylist = this.allPlaylist;
              // }
            },
            (error)=>{     
              //alert(error.message)
            }
          )
          
      //   },
      //   (error)=>{   
      //     //alert(error.message)
      //   }
      // )
    }
   
  }
  searchPlaylist(){
    this.searchIndex = 1
    
   
    if(this.searchPeram === ''){
      this.allPlaylist = []
      this.pageIndex = 0;
      let url2 = environment.apiURL + '/api/v1/admin/users/'+this.data.user.id+'/get-shared-playlist/';
      this.api.doGET(url2).subscribe(x=>{
        this.allPlaylist = x['results'] || [];
        this.allPlaylist.forEach(x=>{
            x['checked'] = x['is_shared'];
            return x;
          })
          // if(this.sharedPlaylists.length > 0){
          // this.getSharedItems(this.allPlaylist,this.sharedPlaylists)
          // }
          // else{
          //   this.allPlaylist = this.allPlaylist;
          // }
        },
        (error)=>{     
          //alert(error.message)
        }
      )
    }
    if(this.searchPeram.length < 2){
return
    }
   
    // let url = environment.apiURL + `/api/v1/admin/users/get-available-playlist/?search=${this.searchPeram}`;
    // this.api.doGET(url).subscribe(x=>{
    //     // this.allPlaylist = x['results'] || [];
    //     x['results'].forEach(x=>{
    //       x['checked'] = false;
    //       return x;
    //     })
       
    //     this.allPlaylist = x['results']
        // if( this.allPlaylist.length > 0){
        let url2 = environment.apiURL + `/api/v1/admin/users/${this.data.user.id}/get-shared-playlist/?search=${this.searchPeram}`;
        this.api.doGET(url2).subscribe(x=>{
          this.allPlaylist = []
            // this.sharedPlaylists = x['results'] || [];
            x['results'].forEach(x=>{
              x['checked'] = x['is_shared'];
              this.allPlaylist.push(x)
              return x;
            })
            // if(this.sharedPlaylists.length > 0){
            // this.getSharedItems(this.allPlaylist,this.sharedPlaylists)
            // }
            // else{
            //   this.allPlaylist = this.allPlaylist;
            // }
          },
          (error)=>{     
            //alert(error.message)
          }
        )
        // }
    //   },
    //   (error)=>{   
        
    //   }
    // )
    
    console.log(this.allPlaylist)
  }
}
