<div class="header-wrapper" *ngIf="term">
    <div class="left-side" *ngIf="_userService?.currentUser?.is_artist === false">
        <a [routerLink]="_userService?.currentUser?.is_superuser == true ? ['/dashboard'] : ['/']" class="logo-link">
            <img src="/assets/img/logo.png" title="MIBE Admin" alt="MIBE Admin" class="logo" />
            <span *ngIf="_userService?.currentUser?.is_artist === false" class="site">Admin</span>
        </a>
    </div>
    <div class="left-side" *ngIf="_userService.currentUser && _userService?.currentUser?.is_artist === true">
        <a [routerLink]="['/composer', 'my-tracks']" class="logo-link">
            <img src="/assets/img/logo.png" title="MIBE Composer" alt="MIBE Composer" class="logo" />
            <span *ngIf="_userService.currentUser && _userService?.currentUser?.is_artist === true"
                class="site">Composer</span>
        </a>
    </div>
    <!-- <div class="middle-side" *ngIf="!loadingCurrentUser && currentUser"> -->
    <div class="middle-side" *ngIf="list.menuheader">
        <ul class="navigation">

            <!-- Robert: 5/24/24 - TODO - Need to restrict access to users with appropriate permissions -->
            <!-- <li class="nav-item" *ngIf="_userService?.currentUser?.is_artist === false"
                [ngClass]="{'nav-item-active': route == '/data'}">
                <a [routerLink]="['/data']" class="nav-link" (click)="changeTab()">Data</a>
            </li> -->

            <li class="nav-item" *ngIf="_userService?.currentUser?.is_artist === false"
                [ngClass]="{'nav-item-active': route == '/'}">
                <a [routerLink]="['/']" class="nav-link" (click)="data();changeTab()">Music</a>
            </li>
            <li class="nav-item" *ngIf="route == '/playlists' && _userService?.currentUser?.is_artist === true"
                [ngClass]="{'nav-item-active': route == '/playlists'}">
                <a [routerLink]="['/playlists']" (click)="changeTab()" class="nav-link">Playlists</a>
            </li>
            <li class="nav-item" *ngIf="_userService?.currentUser?.is_artist === false"
                [ngClass]="{'nav-item-active': route == '/playlists'}">
                <a [routerLink]="['/playlists']" (click)="changeTab()" class="nav-link">Playlists</a>
            </li>
            <li class="nav-item" *ngIf="_userService?.currentUser?.is_artist === false"
                [ngClass]="{'nav-item-active': route.includes('/user-mgmt')}">
                <a [routerLink]="['/user-mgmt', 'users']" (click)="changeTab()" class="nav-link">User mgmt</a>
            </li>
            <li class="nav-item" style="margin-left: 7.75rem;margin-right: 4.75rem;"
                *ngIf="_userService?.currentUser?.is_artist === true"
                [ngClass]="{'nav-item-active': route.includes('/my-tracks')}">
                <a [routerLink]="['/composer', 'my-tracks']" (click)="changeTab()" class="nav-link">my tracks</a>
            </li>
            <li class="nav-item" *ngIf="_userService?.currentUser?.is_artist === true"
                [ngClass]="{'nav-item-active': route.includes('/my-uploads')}">
                <a [routerLink]="['/composer', 'my-uploads']" (click)="changeTab()" class="nav-link">my uploads</a>
            </li>
            <!-- <li class="nav-item"  *ngIf="_userService?.currentUser?.is_artist === true && _userService?.currentUser?.can_upload === false">
                <a (click)="uploadPop()" class="nav-link">my uploads</a>
            </li> -->


        </ul>
    </div>

    <app-loader *ngIf="_downloadService.downloadInProgress" [textDisplay]="'Downloading Tracks'"
        [isHalfSize]="true" style="margin-right: 20px;"></app-loader>

    <!-- <div class="right-side" *ngIf="!loadingCurrentUser && currentUser"> -->
    <div class="right-side" *ngIf="_userService?.currentUser">
        <button class="btn save-btn"
            *ngIf="_userService?.currentUser?.is_artist === true && _userService?.currentUser?.can_upload === true"
            mat-button (click)="openUploadTrack()">Upload tracks</button>
        <button class="btn upload-btn"
            *ngIf="_userService?.currentUser?.is_artist === true && _userService?.currentUser?.can_upload === false"
            mat-button (click)="uploadPop()">Upload tracks</button>

        <!-- <button class="btn save-btn"  mat-button >My Uploads</button> -->

        <a *ngIf="_userService?.currentUser?.is_artist === false" class="btn-notification">
            <svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g>
                        <rect class="border" x="0.5" y="0.5" width="23" height="23" rx="2"></rect>
                        <g class="icon" transform="translate(6.000000, 6.000000)">
                            <path
                                d="M7.18366447,10.2349119 L4.62596193,10.2349119 C4.41253115,10.2349119 4.23949547,10.0590396 4.23949547,9.84210997 C4.23949547,9.42740055 3.99079436,9.12038996 3.67592368,8.73157116 C3.2284124,8.17882954 2.67147263,7.49096649 2.67147263,6.2522002 C2.67147263,4.00002321 4.60516147,3.1824007 5.90488856,3.1824007 C7.20461565,3.1824007 9.13830449,4.00002321 9.13830449,6.2522002 C9.13830449,7.49096649 8.581214,8.17882954 8.13385345,8.73157116 C7.81883204,9.12038996 7.57013093,9.42740055 7.57013093,9.84210997 C7.57013093,10.0590396 7.39709524,10.2349119 7.18366447,10.2349119 Z M4.97022456,9.44930799 L6.83940184,9.44930799 C6.94747377,8.95999072 7.24606584,8.59124097 7.53682005,8.23229595 C7.94408696,7.72934397 8.36522085,7.20923372 8.36522085,6.2522002 C8.36522085,4.44537241 6.75574783,3.96800464 5.90473783,3.96800464 C5.05372784,3.96800464 3.44425482,4.44537241 3.44425482,6.2522002 C3.44425482,7.20923372 3.8653887,7.72949717 4.27265562,8.23229595 C4.56340983,8.59108777 4.86215262,8.95999072 4.97022456,9.44930799 Z">
                            </path>
                            <path
                                d="M8.52589684,3.32334368 C8.46078237,3.32334368 8.39476353,3.3064918 8.33402016,3.27110286 C8.14892624,3.16325083 8.08486687,2.92318816 8.19097934,2.73505992 L9.1043907,1.11605397 C9.21065391,0.927925722 9.44609096,0.862356594 9.63208925,0.970668214 C9.81718317,1.07852024 9.88124254,1.31858291 9.77513007,1.50671116 L8.86171871,3.1257171 C8.79012293,3.25241259 8.65989399,3.32334368 8.52589684,3.32334368 Z">
                            </path>
                            <path
                                d="M9.96791111,5.07701146 C9.83662707,5.07701146 9.70865904,5.00899115 9.6361589,4.88612563 C9.52627823,4.70014217 9.58551431,4.45885391 9.76864877,4.34732511 L11.2911518,3.41893979 C11.4741355,3.307411 11.711532,3.36746496 11.8214127,3.55375482 C11.9312934,3.73973829 11.8720573,3.98102655 11.6889228,4.09255535 L10.1664198,5.02094066 C10.1041692,5.05893399 10.035588,5.07701146 9.96791111,5.07701146 Z">
                            </path>
                            <path
                                d="M5.94679093,2.51368751 C5.73336016,2.51368751 5.56032447,2.33781517 5.56032447,2.12088554 L5.56032447,0.392801973 C5.56032447,0.175872334 5.73336016,6.16173779e-15 5.94679093,6.16173779e-15 C6.16037243,6.16173779e-15 6.33340812,0.175872334 6.33340812,0.392801973 L6.33340812,2.12088554 C6.33340812,2.33781517 6.16037243,2.51368751 5.94679093,2.51368751 Z">
                            </path>
                            <path
                                d="M3.39104785,3.32334368 C3.26323055,3.32334368 3.13812636,3.25884695 3.06442039,3.14103699 L2.11031248,1.61425678 C1.99590997,1.4311841 2.04911694,1.18836385 2.22923684,1.07208588 C2.40935673,0.955501523 2.6481098,1.00973393 2.76266304,1.19295981 L3.71677095,2.71974003 C3.83117346,2.90281271 3.7779665,3.14563296 3.5978466,3.26191092 C3.53363649,3.30342783 3.46204072,3.32334368 3.39104785,3.32334368 Z">
                            </path>
                            <path
                                d="M1.92883604,5.07701146 C1.86191283,5.07701146 1.79408525,5.05924039 1.73228679,5.02216626 L0.18958625,4.09378094 C0.00599960993,3.98317133 -0.0547437529,3.74234267 0.0539310972,3.55559321 C0.162756675,3.36899695 0.399851737,3.307411 0.583438377,3.4177142 L2.12613892,4.34609952 C2.30972556,4.45670913 2.37046892,4.69753779 2.26179407,4.88428725 C2.18959538,5.00822516 2.06087372,5.07701146 1.92883604,5.07701146 Z">
                            </path>
                            <path
                                d="M7.23310324,11.8025963 L4.6603279,11.8025963 C4.44689713,11.8025963 4.27386144,11.6267239 4.27386144,11.4097943 C4.27386144,11.1928646 4.44689713,11.0169923 4.6603279,11.0169923 L7.23310324,11.0169923 C7.44653401,11.0169923 7.61956969,11.1928646 7.61956969,11.4097943 C7.61956969,11.6267239 7.44653401,11.8025963 7.23310324,11.8025963 Z">
                            </path>
                        </g>
                    </g>
                </g>
            </svg>
        </a>

        <button *ngIf="_userService?.currentUser?.is_artist === false" class="btn-overflow-menu"
            [matMenuTriggerFor]="overflowMenu" #menuTrigger="matMenuTrigger" (click)="openMenu(menuTrigger)">
            <svg viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(1.000000, 1.000000)">
                        <circle class="border" stroke-linecap="round" stroke-linejoin="round" cx="20" cy="20" r="20">
                        </circle>
                        <g transform="translate(18.000000, 10.000000)" class="icon">
                            <circle cx="2" cy="10" r="2"></circle>
                            <circle cx="2" cy="18" r="2"></circle>
                            <circle cx="2" cy="2" r="2"></circle>
                        </g>
                    </g>
                </g>
            </svg>
        </button>

        <img *ngIf="_userService?.currentUser?.is_artist === true" class="btn-overflow-menu uploadwidth"
            [matMenuTriggerFor]="overflowMenu" #menuTrigger="matMenuTrigger" (click)="openMenu(menuTrigger)"
            (mouseenter)="menuTrigger.openMenu()" src="../../../assets/img/profile.png">

        <mat-menu #overflowMenu="matMenu" yPosition="below" class="header-overflow-menu">
            <div class="menu-item-title">Menu</div>
            <a mat-menu-item *ngFor="let menu of menuList" [routerLink]="[menu.path]">{{ menu.label }}</a>
            <a *ngIf="list.menuheader && _userService?.currentUser?.is_artist === false  && _userService?.currentUser?.is_cms_enabled === true"
                mat-menu-item [routerLink]="['/menu/cms']">CMS</a>
            <a *ngIf="list.menuheader && _userService?.currentUser?.is_artist === false" mat-menu-item
                [routerLink]="['/menu/data']">Data</a>
            <a *ngIf="list.menuheader && _userService?.currentUser?.is_artist === false" mat-menu-item
                [routerLink]="['/menu/log']">Log</a>
            <a *ngIf="list.menuheader && _userService?.currentUser?.is_artist === false" mat-menu-item
                [routerLink]="['/menu/reports']">Reports</a>
            <a *ngIf="list.menuheader && _userService?.currentUser?.is_artist === false" mat-menu-item
                [routerLink]="['/menu/tos']">Terms of Service</a>
            <a *ngIf="list.menuheader && _userService?.currentUser?.is_artist === false" mat-menu-item
                [routerLink]="['/menu/user-groups']">Admin Groups</a>
            <a *ngIf="list.menuheader && _userService?.currentUser?.is_artist === false" mat-menu-item
                [routerLink]="['/menu/users']">Users</a>
            <a *ngIf="list.menuheader && _userService?.currentUser?.is_artist === false" mat-menu-item
                [routerLink]="['/menu/profile']">My profile</a>
            <a *ngIf="list.menuheader && _userService?.currentUser?.is_artist === false" mat-menu-item
                [routerLink]="['/menu/supportUser']">Support</a>
            <a *ngIf="list.menuheader && _userService?.currentUser?.is_artist === true" mat-menu-item
                [routerLink]="['/composer/user-account']">My Info</a>

            <button mat-menu-item class="menu-item-logout" (click)="logout()" [routerLink]="['/login']">
                Log out
                <svg width="8px" height="8px" viewBox="0 0 10 10" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                        stroke-linejoin="round">
                        <g transform="translate(1.000000, 1.000000)" stroke="#202027">
                            <g transform="translate(0.500000, 0.000000)">
                                <path
                                    d="M2.72222222,8 L0.777777778,8 C0.348222972,8 0,7.6418278 0,7.2 L0,0.8 C0,0.3581722 0.348222972,0 0.777777778,0 L2.72222222,0">
                                </path>
                                <polyline points="5.44444444 5.6 7 4 5.44444444 2.4"></polyline>
                                <line x1="7" y1="4" x2="2.33333333" y2="4"></line>
                            </g>
                        </g>
                    </g>
                </svg>
            </button>
        </mat-menu>
    </div>
</div>
<div class="header-wrapper"
    *ngIf="!term && route !== '/login' && route !=='/composer/term-condition' && route !=='/reset-password'">
    <div class="left-side">
        <a class="logo-link">
            <img class="logo" src="/assets/img/logo.png" title="MIBE Admin" alt="MIBE Admin">
            <span class="site">Admin</span>
        </a>
    </div>
    <div class="middle-side">
        <ul class="navigation">
            <li class="nav-item" [ngClass]="{'nav-item-active': route == '/'}">
                <a class="nav-link">Music</a>
            </li>
            <li class="nav-item" [ngClass]="{'nav-item-active': route == '/playlists'}">
                <a class="nav-link" [routerLink]="['/playlists']">Playlists</a>
            </li>
            <li class="nav-item" [ngClass]="{'nav-item-active': route.includes('/user-mgmt')}">
                <a class="nav-link">User mgmt</a>
            </li>

        </ul>
    </div>
    <div class="right-side">


        <a class="btn-notification">
            <svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g>
                        <rect class="border" x="0.5" y="0.5" width="23" height="23" rx="2"></rect>
                        <g class="icon" transform="translate(6.000000, 6.000000)">
                            <path
                                d="M7.18366447,10.2349119 L4.62596193,10.2349119 C4.41253115,10.2349119 4.23949547,10.0590396 4.23949547,9.84210997 C4.23949547,9.42740055 3.99079436,9.12038996 3.67592368,8.73157116 C3.2284124,8.17882954 2.67147263,7.49096649 2.67147263,6.2522002 C2.67147263,4.00002321 4.60516147,3.1824007 5.90488856,3.1824007 C7.20461565,3.1824007 9.13830449,4.00002321 9.13830449,6.2522002 C9.13830449,7.49096649 8.581214,8.17882954 8.13385345,8.73157116 C7.81883204,9.12038996 7.57013093,9.42740055 7.57013093,9.84210997 C7.57013093,10.0590396 7.39709524,10.2349119 7.18366447,10.2349119 Z M4.97022456,9.44930799 L6.83940184,9.44930799 C6.94747377,8.95999072 7.24606584,8.59124097 7.53682005,8.23229595 C7.94408696,7.72934397 8.36522085,7.20923372 8.36522085,6.2522002 C8.36522085,4.44537241 6.75574783,3.96800464 5.90473783,3.96800464 C5.05372784,3.96800464 3.44425482,4.44537241 3.44425482,6.2522002 C3.44425482,7.20923372 3.8653887,7.72949717 4.27265562,8.23229595 C4.56340983,8.59108777 4.86215262,8.95999072 4.97022456,9.44930799 Z">
                            </path>
                            <path
                                d="M8.52589684,3.32334368 C8.46078237,3.32334368 8.39476353,3.3064918 8.33402016,3.27110286 C8.14892624,3.16325083 8.08486687,2.92318816 8.19097934,2.73505992 L9.1043907,1.11605397 C9.21065391,0.927925722 9.44609096,0.862356594 9.63208925,0.970668214 C9.81718317,1.07852024 9.88124254,1.31858291 9.77513007,1.50671116 L8.86171871,3.1257171 C8.79012293,3.25241259 8.65989399,3.32334368 8.52589684,3.32334368 Z">
                            </path>
                            <path
                                d="M9.96791111,5.07701146 C9.83662707,5.07701146 9.70865904,5.00899115 9.6361589,4.88612563 C9.52627823,4.70014217 9.58551431,4.45885391 9.76864877,4.34732511 L11.2911518,3.41893979 C11.4741355,3.307411 11.711532,3.36746496 11.8214127,3.55375482 C11.9312934,3.73973829 11.8720573,3.98102655 11.6889228,4.09255535 L10.1664198,5.02094066 C10.1041692,5.05893399 10.035588,5.07701146 9.96791111,5.07701146 Z">
                            </path>
                            <path
                                d="M5.94679093,2.51368751 C5.73336016,2.51368751 5.56032447,2.33781517 5.56032447,2.12088554 L5.56032447,0.392801973 C5.56032447,0.175872334 5.73336016,6.16173779e-15 5.94679093,6.16173779e-15 C6.16037243,6.16173779e-15 6.33340812,0.175872334 6.33340812,0.392801973 L6.33340812,2.12088554 C6.33340812,2.33781517 6.16037243,2.51368751 5.94679093,2.51368751 Z">
                            </path>
                            <path
                                d="M3.39104785,3.32334368 C3.26323055,3.32334368 3.13812636,3.25884695 3.06442039,3.14103699 L2.11031248,1.61425678 C1.99590997,1.4311841 2.04911694,1.18836385 2.22923684,1.07208588 C2.40935673,0.955501523 2.6481098,1.00973393 2.76266304,1.19295981 L3.71677095,2.71974003 C3.83117346,2.90281271 3.7779665,3.14563296 3.5978466,3.26191092 C3.53363649,3.30342783 3.46204072,3.32334368 3.39104785,3.32334368 Z">
                            </path>
                            <path
                                d="M1.92883604,5.07701146 C1.86191283,5.07701146 1.79408525,5.05924039 1.73228679,5.02216626 L0.18958625,4.09378094 C0.00599960993,3.98317133 -0.0547437529,3.74234267 0.0539310972,3.55559321 C0.162756675,3.36899695 0.399851737,3.307411 0.583438377,3.4177142 L2.12613892,4.34609952 C2.30972556,4.45670913 2.37046892,4.69753779 2.26179407,4.88428725 C2.18959538,5.00822516 2.06087372,5.07701146 1.92883604,5.07701146 Z">
                            </path>
                            <path
                                d="M7.23310324,11.8025963 L4.6603279,11.8025963 C4.44689713,11.8025963 4.27386144,11.6267239 4.27386144,11.4097943 C4.27386144,11.1928646 4.44689713,11.0169923 4.6603279,11.0169923 L7.23310324,11.0169923 C7.44653401,11.0169923 7.61956969,11.1928646 7.61956969,11.4097943 C7.61956969,11.6267239 7.44653401,11.8025963 7.23310324,11.8025963 Z">
                            </path>
                        </g>
                    </g>
                </g>
            </svg>
        </a>

        <button class="btn-overflow-menu">
            <svg viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(1.000000, 1.000000)">
                        <circle class="border" stroke-linecap="round" stroke-linejoin="round" cx="20" cy="20" r="20">
                        </circle>
                        <g class="icon" transform="translate(18.000000, 10.000000)">
                            <circle cx="2" cy="10" r="2"></circle>
                            <circle cx="2" cy="18" r="2"></circle>
                            <circle cx="2" cy="2" r="2"></circle>
                        </g>
                    </g>
                </g>
            </svg>
        </button>




    </div>
</div>

<div class="header-wrapper" *ngIf="!term && route =='/reset-password'">
    <div class="left-side">
        <a class="logo-link">
            <img class="logo" src="/assets/img/logo.png" title="MIBE Admin" alt="MIBE Admin">
            <span class="site">Admin</span>
        </a>
    </div>
</div>