<div class="pagination-container" [ngClass]="{'dark': darkText}">
  <button type="button"
    [@navButtonInOut]
    class="previous-button"
    (click)="page = page - 1;pageEmit()"
    *ngIf="page > 1">
    <svg width="10px" height="16px" viewBox="0 0 10 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
            <g id="Number-Pagination" transform="translate(1.000000, -9.000000)" class="inner-stroke" stroke="#FFFFFF">
                <g id="Stroke-1">
                    <polyline points="8 25 0 17 8 9"></polyline>
                </g>
            </g>
        </g>
    </svg>
  </button>
  <div class="pages">
    <div class="page"
      [@navButtonInOut]
      *ngFor="let p of displayPages">
      <span class="ellipsis" *ngIf="p == '...'">{{ p }}</span>
      <button
        type="button"
        *ngIf="p != '...'"
        (click)="page = p;pageEmit()"
        [ngClass]="{'selected': p == page}">
        {{ p }}
    </button>
    </div>
  </div>
  <button type="button"
    [@navButtonInOut]
    class="next-button"
    (click)="page = page + 1;pageEmit()"
    *ngIf="page < numberOfPages">
    <svg width="10px" height="16px" viewBox="0 0 10 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
            <g id="Number-Pagination" transform="translate(-343.000000, -9.000000)" class="inner-stroke" stroke="#FFFFFF">
                <g id="Stroke-1">
                    <polyline transform="translate(348.000000, 17.000000) scale(-1, 1) translate(-348.000000, -17.000000) " points="352 25 344 17 352 9"></polyline>
                </g>
            </g>
        </g>
    </svg>
  </button>
</div>
