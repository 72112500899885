import { MibeModel } from './mibemodel.model';

export class AllowedCoWriters implements MibeModel {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  disable:boolean = false;

  /**
   * Create a Mood.
   * @param {any} json - The JSON object with which to construct our Mood.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a Mood.
   * @param {any} json - The JSON object with which to load our Mood.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this.id = json.id;
    this.first_name = json.first_name;
    this.last_name = json.last_name;
    this.email = json.email;
  }
  /**
   * Returns a JSON representation of our Mood
   * @return {any} - The JSON representation of our Mood
   */
  toJSON() {
    var json: any = {
      'id': this.id,
      'first_name': this.first_name,
      'last_name': this.last_name,
      'email': this.email,
      'disable':this.disable,
    };
    return json;
  }
}
