import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-range-datepicker',
  templateUrl: './range-datepicker.component.html',
  styleUrls: ['./range-datepicker.component.scss']
})
export class RangeDatepickerComponent implements OnInit {

  @Input()
  rangeFilterList: string[] = ['All Tracks', 'Date Created', 'Date Approved'];
  maxDate = new Date();
  get selectedRangeFilter() {
    return this._selectedRangeFilter;
  }

  @Input()
  set selectedRangeFilter(filter) {
    if (this._selectedRangeFilter == filter) return;
    this._selectedRangeFilter = filter;
  //  this.selectedRangeFilterChange.emit(this._selectedRangeFilter);
  }

  @Output()
  selectedRangeFilterChange = new EventEmitter<string>();

  @Input()
  isDark: boolean = false;

  @Input()
  startDate: Date;

  @Input()
  public enableSelect: boolean = false;

  @Input()
  public type: string = '';
  @Output()
  startDateChange = new EventEmitter<Date>();

  @Input()
  endDate: Date;

  @Output()
  endDateChange = new EventEmitter<Date>();

  @Output()
  dateTypeChange = new EventEmitter<any>();

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  private _selectedRangeFilter: string;

  ngOnInit(): void {
    if (!this.selectedRangeFilter && this.rangeFilterList && this.rangeFilterList.length > 0 && this.enableSelect) {
      this.selectedRangeFilter = this.rangeFilterList[0];
    }
  }

  clearDateFilter(){
    this.range.reset({ start: '', end: '' });
    this.startDateChange.emit(null);
    this.endDateChange.emit(null);
    this.selectedRangeFilter = this.rangeFilterList[0]
    this.selectedRangeFilterChange.emit(this.rangeFilterList[0])
  }
  
  exclusiveChanged(event){
    this.range.reset({ start: '', end: '' });
    this.startDateChange.emit(null);
    this.endDateChange.emit(null);
    this.selectedRangeFilterChange.emit(event.value)
  }

  startChanged(value:Date) {
    this.startDateChange.emit(value)
  }

  endChanged(value:Date) {
    this.endDateChange.emit(value)
  }
}
