import { Component, OnInit, Inject, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, of, ReplaySubject, Subscription, BehaviorSubject } from 'rxjs';
import { startWith, debounceTime, distinctUntilChanged, tap, switchMap, map, filter } from 'rxjs/operators';
import { Playlist } from '../models/playlist.model';
import { PlaylistClient } from '../models/playlistclient.model';
import { PlaylistTracks } from '../models/playlisttracks.model';
import { TrackVersion } from '../models/trackversion.model';
import { PlaylistService } from '../services/playlist.service';
import { PlaylistTracksService } from '../services/playlisttracks.service';
import { UserService } from '../services/user.service';
import { PlaylistComponent } from '../../playlist/playlist.component'
import { DialogService } from '../services/dialog.service';
const clientLimit = 30;
const tracksLimit = 30;

interface DialogData {
  playlist: Playlist;
  tracks: Playlist
  val: string;
}

@Component({
  selector: 'app-manage-tracks-clients-dialog',
  templateUrl: './manage-tracks-clients-dialog.component.html',
  styleUrls: ['./manage-tracks-clients-dialog.component.scss']
})
export class ManageTracksClientsDialogComponent implements OnInit, OnDestroy {
  trackPageIndex: number = 1;
  clientPageIndex: number = 1;
  constructor(
    private dialogRef: MatDialogRef<ManageTracksClientsDialogComponent>,
    private _playlistService: PlaylistService,
    private _playlistTracksService: PlaylistTracksService, public _DialogService: DialogService,
    private _userService: UserService, private playlistComponent: PlaylistComponent,

    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    this.activeTab = data.val;
  }

  activeTab: string = 'clients';

  loadingClients = true;
  loadingTracks = true;
  loadingCount = true;
  searchTrackString = '';
  searchClientString = '';
  // @Input() update: EventEmitter<string>;

  @Output()
  update: EventEmitter<string> = new EventEmitter<string>();
  clients: any[] = [];
  modifiedClients: any[] = [];
  checkedClients: any = [];
  unCheckedClients: any = [];
  editedval: any;
  tracks: any = [];
  modifiedTracks: any = [];
  unCheckedTracks: any = [];
  rmchecked: any = [];
  currentPlaylist: any;

  clientSearchControl = new FormControl();
  trackSearchControl = new FormControl();
  clientOptions: BehaviorSubject<PlaylistClient[]> = new BehaviorSubject<PlaylistClient[]>([]);
  trackOptions: BehaviorSubject<PlaylistTracks[]> = new BehaviorSubject<PlaylistTracks[]>([]);
  allTracks: any = [];
  searchingClients = false;
  searchingTracks = false;
  datanew: any = [];
  allData: any[] = [];
  private _subscriptions: Subscription[] = [];
  public nextClient: any = '';
  public nextTrack: any = '';
  nextOldClient: any = '';
  nextOldTrack: any = '';
  private clientsHandled = [];
  private tracksHandled = [];
  // allassignedTracks:any;

  ngOnInit(): void {

    // alert("this.data.playlist.active_clients"+JSON.stringify(this.data.playlist.active_clients))
    // this.clients = this.data.playlist.active_clients;
    // this.checkedClients = this.data.playlist.active_clients;
    // this.clientOptions.next(this.clients);
    // this.getallData(this.data.val);

    // if (this.clients.length == 1) {
    this._playlistTracksService.getassignedClients({ playlistId: this.data.playlist.id, search: this.searchClientString }, this.clientPageIndex).subscribe((res: any) => {
      let clients = res.results
      this.nextClient = res?.next ? Number(res?.next.split('')[(res?.next?.length) - 1]) : ''
      for (let client of this.checkedClients.reverse()) {
        if (!clients.some(c => c.id == client.id)) {
          clients.unshift(client);
        }
      }
      this.clients = clients || [];
      this.clientOptions.next(res.results);
      this.loadingClients = false;
    }, (error) => {
      console.log(error);
      //alert("An error ocurred while getting clients. Check your connections and try again");
    })
    // } else {
    //   this.loadingClients = false;
    // }

    //this._playlistTracksService.getPlaylistTracks({playlistId: this.data.playlist.id, limit: tracksLimit,})
    this._playlistTracksService.getassignedTracks({ playlistId: this.data.playlist.id, search: this.searchTrackString }, this.trackPageIndex)
      .subscribe((t) => {
        this.tracks = t.results;
        this.allTracks = t.results;
        this.nextTrack = t.next ? Number(t?.next.split('')[(t?.next.length) - 1]) : '';
        this.trackOptions.next(this.allTracks);
        //this.allTracks.next(this.tracks)
        // this._playlistTracksService.getassignedTracks({playlistId:this.data.playlist.id}, this.pageIndex).subscribe((res) => {
        //   this.unCheckedTracks=res?.results;
        //   // this.allassignedTracks=res;

        // });
        this.loadingTracks = false;
      }, (error) => {
        console.log(error);
        //alert("An error ocurred while getting the tracks for the playlist. Check your connections and try again");
      });
    //Client search controller

    this._subscriptions.push(this.clientSearchControl.valueChanges.pipe(
      startWith(''),
      debounceTime(150),
      distinctUntilChanged(),
      tap(() => {
        this.searchingClients = true;
      }),
      switchMap((v) => {
        if (!v || v.length < 1) {
          return of(this.clients);
        }
        if (v.trim() === '') {
          this.searchClientString = this.searchClientString.trim()
          return of(this.clients);
        }
        let page = 1
        return this._playlistTracksService.getassignedClients({ playlistId: this.data.playlist.id, search: this.searchClientString }, page).pipe(
          map((res: any) => {
            let ret = res.results;
            this.nextClient = res?.next ? Number(res?.next.split('')[(res?.next.length) - 1]) : ''

            for (let client of this.modifiedClients) {
              if (!ret.some(c => c.id == client.id)) {
                ret.push(client);
              }
            }
            this.clients = ret;
            return ret;
          })
        );
      }),
      tap(() => {
        this.searchingClients = false;
      })
    ).subscribe(c => {
      this.clientOptions.next(c);
      if (this.searchClientString && this.searchClientString.length == 0) {
        this.clientOptions.next(this.allData);
        this.clients = this.allData
        this.nextClient = this.nextOldClient
      }
    }, (error) => {
      console.log(error);
      //alert("An error ocurred while getting the filtered clients. Check your connections and try again");
    }));


    //Track search controller
    this._subscriptions.push(this.trackSearchControl.valueChanges.pipe(
      startWith(''),
      debounceTime(150),
      distinctUntilChanged(),
      tap(() => {
        this.searchingTracks = true;
      }),
      switchMap((v) => {
        if (!v || v.length < 1) {
          this.trackOptions.next([]);
          this.trackPageIndex = 0;
          this.searchloadMore()
          return v
        }
        if (v.trim() === '') {
          this.searchTrackString = this.searchTrackString.trim()
          this.trackPageIndex = 0;
          this.trackOptions.next([]);
          this.searchloadMore()
          return
        }
        this.trackPageIndex = 0;
        this.trackOptions.next([]);
        this.searchloadMore()
      }),
      tap(() => {
        this.searchingTracks = false;
      })
    ).subscribe(t => {
      this.nextTrack = this.nextOldTrack;
      this.trackOptions.next(t['results']);
      //this.allTracks.next(t);
    }, (error) => {
      console.log(error);
      //alert("An error ocurred while getting the filtered tracks. Check your connections and try again");
    }));
  }

  /**
   * A method that Gets all Data of Tracks and Clients for the specified PLaylist.
   * @param val to specify which data to fetch whether clients or tracks.
   */
  getallData(val: string) {
    this.loadingClients = false;

    if (val === 'tracks') {
      this.loadingTracks = true;
      // getAllPlaylists
      //   this._playlistTracksService.getPlaylistTracks({playlistId: this.data.playlist.id,search: this.searchTrackString, filterval:'', limit: tracksLimit})
      this._playlistTracksService.getassignedTracks({ playlistId: this.data.playlist.id, search: this.searchTrackString }, this.trackPageIndex)
        .subscribe((res) => {
          this.allData = res?.results;
          this.nextTrack = res?.next ? Number(res?.next.split('')[(res?.next.length) - 1]) : ''
          this.nextOldTrack = res?.next ? Number(res?.next.split('')[(res?.next.length) - 1]) : ''
          this.trackOptions.next(this.allData);
          //this.allTracks.next(this.allData);
          this.loadingTracks = false;
          // this._playlistTracksService.getassignedTracks({playlistId:this.data.playlist.id}, this.pageIndex).subscribe((res) => {
          //   this.unCheckedTracks=res?.results;
          //   // this.allassignedTracks=res;

          // });
        }, (error) => {
          console.log(error);
          //alert("An error ocurred while getting the tracks for the playlist. Check your connections and try again");
        });
    } else {
      this.loadingClients = true;
      this._playlistTracksService.getassignedClients({ playlistId: this.data.playlist.id, search: this.searchClientString }, this.clientPageIndex).subscribe((res: any) => {
        this.allData = res.results;
        this.nextOldClient = res?.next ? Number(res?.next.split('')[(res?.next.length) - 1]) : ''
        this.clients = res.results || [];
        this.nextClient = res?.next ? Number(res?.next.split('')[(res?.next.length) - 1]) : ''
        this.clientOptions.next(res.results);

        this.loadingClients = false;
        //  this._playlistTracksService.getassignedClients({playlistId: this.data.playlist.id, search: this.searchClientString}).subscribe((res: any) => {
        //    this.checkedClients = res.results ? res.results: [];  
        //    // alert("len"+this.checkedClients.length+"====clients====="+this.clients.length)
        //    // if(this.checkedClients.length != this.clients.length){
        //    //   alert('in')
        //    //   if (!this.clients.some(c=>c.id == this.checkedClients.id)) {
        //    //     this.clients.push(this.checkedClients);
        //    //   }
        //    //   return;
        //    // }
        //  });
      }, (error) => {
        console.log(error);
        //alert("An error ocurred while getting clients. Check your connections and try again");
      })
    }
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe())
    this._subscriptions = [];
  }

  /**
   * A method that switches the Tab in the UI between Tracks and Clients
   * @param val to specify which tab to switch either tracks or clients 
   */
  switchTab(val: string) {
    this.activeTab = val;
    this.nextClient = ''
    this.nextTrack = ''
    this.searchClientString = '';
    this.clientPageIndex = 1;
    this.trackPageIndex = 1;
    this.searchTrackString = ''
    this.getallData(val);
  }

  /**
   * A method that closes the modal in the UI
   */
  close() {
    // this._playlistService.getPlaylist(this.data.playlist.id, true).subscribe(p => {
    //   this.update.emit('true');

    // }, (error) => {
    //   console.log(error);
    //   //alert("An error ocurred while getting the playlist. Check your connection and try again")
    // })
    this.dialogRef.close(false);
  }

  /**
   * A Method that tells whether the given playlistClient is checked or not
   * @param client playlistClient to be verfied
   * @returns true if the given playlistClient is checked by user else false
   */
  isClientChecked(client: PlaylistClient) {
    return this.checkedClients.some(c => c.id == client.id);
  }

  /**
   * A Method that handles the click event on the checkbox of playlistClient
   * @param checked boolean that specifies whether the given playlistClient is checked or unchecked by the user
   * @param client playlistClient to handle
   */
  clientChecked(checked: boolean, client: PlaylistClient) {
    if (checked) {
      this.checkedClients.push(client);
      let j = this.unCheckedClients.findIndex(f => f.id == client.id);
      if (j >= 0) {
        this.unCheckedClients.splice(j, 1)[0];
      }
      // if (!this.clients.some(c=>c.id == client.id)) {
      //   this.clients.push(client);
      // }
      // return;
    } else {
      let j = this.checkedClients.findIndex(f => f.id == client.id);
      if (j >= 0) {
        this.checkedClients.splice(j, 1)[0];
      }
      this.unCheckedClients.push(client);
      //   let i = this.clients.findIndex(f=>f.id == client.id);
      // if (i >= 0) {
      //   this.clients.splice(i, 1);
      // }
      // return;
    }

  }


  // toggleModifiedClient(client: PlaylistClient) {

  //   let i = this.modifiedClients.findIndex(c => c.id == client.id)
  //   if (i >= 0) {
  //     this.modifiedClients.splice(i, 1);
  //   } else {
  //     this.modifiedClients.push(client);
  //   }
  // }

   /**
   * A Method that tells whether the given PlaylistTrack is checked or not
   * @param track PlaylistTrack to be verfied
   * @returns true if the given PlaylistTrack is checked by user else false
   */
  isTrackChecked(track: PlaylistTracks) {
    return this.unCheckedTracks.some(t => t.id == track.id);
  }

  /**
   * A Method that tells whether the given PlaylistTrack is unchecked or not
   * @param track PlaylistTrack to be verfied
   * @returns true if the given PlaylistTrack is unchecked by user else false 
   */
  isTrackUnchecked(track: PlaylistTracks) {
    return this.rmchecked.some(t => t.id == track.id);
  }

   /**
   * A Method that handles the click event on the checkbox of PlaylistTrack
   * @param checked boolean that specifies whether the given PlaylistTrack is checked or unchecked by the user
   * @param track PlaylistTrack to handle
   */
  trackChecked(checked: boolean, track: any) {


    if (checked) {
      if (track.is_added === 'False') {
        this.unCheckedTracks.push(track);
      }

      if (!this.tracks.some(t => t.id == track.id)) {
        this.tracks.push(track);
      }
      let i = this.rmchecked.findIndex(t=>t.id == track.id);
      if (i >= 0) {
        this.rmchecked.splice(i, 1);
      }
      return;
      // if (!this.clients.some(c=>c.id == client.id)) {
      //   this.clients.push(client);
      // }
      // return;
    } else {

      let i = this.unCheckedTracks.findIndex(t => t.id == track.id);
      if (i >= 0) {
        this.unCheckedTracks.splice(i, 1);
      }
      else {
        this.rmchecked.push(track)
      }

      //   let j = this.tracks.findIndex(t=>t.id == track.id);
      // if (j >= 0) {
      //   this.tracks.splice(j, 1);
      // }
      return;
    }
  }

  // toggleModifiedTrack(track: PlaylistTracks) {
  //   let i = this.modifiedTracks.findIndex(c => c.id == track.id)
  //   if (i >= 0) {
  //     this.modifiedTracks.splice(i, 1);
  //   } else {
  //     this.modifiedTracks.push(track);
  //   }
  // }

  /**
   * TrackBy method for the ngFor
   * @param i index of the PlaylistClient
   * @param client PlaylistClient to handle
   * @returns unique Id of that PlaylistClient
   */
  clientTrackBy(i: number, client: PlaylistClient) {
    return client.id;
  }

  /**
   * TrackBy method for the ngFor
   * @param i index of the PlaylistTrack
   * @param track PlaylistTrack to handle
   * @returns unique Id of that PlaylistTrack
   */
  trackTrackBy(i: number, track: PlaylistTracks) {
    return track.id;
  }

  /**
   * A Method that disables the save button when there is no Changes made by the User
   * @returns true if there is any changes made else false;
   */
  canSubmit(): boolean {

    return (this.checkedClients && this.checkedClients.length > 0) || (this.unCheckedTracks && this.unCheckedTracks.length > 0) || (this.rmchecked && this.rmchecked.length > 0) || (this.unCheckedClients && this.unCheckedClients.length > 0);
  }

  /**
   * A Method that is called when save btn is pressed and it handles the saving of changes made.
   * @param val string to specify which changed data should be saved whether PlayListTrack or PlayListclient.
   * @returns 
   */
  save(val: string) {

    if (!this.canSubmit()) return;
    this.clientsHandled = [];
    this.tracksHandled = [];
    this.loadingClients = true;
    this.loadingTracks = true;

    // if (this.modifiedClients && this.modifiedClients.length > 0 && val == 'tracks') {

    //  this.data.playlist.active_clients = this.checkedClients;
    //   console.log((this.data.playlist.active_clients))
    //   this._playlistService.updatePlaylist(this.data.playlist).subscribe(p => {
    //     this.clientsHandled = this.modifiedClients.map(c=>c.id);
    //     this.closeIfDone();
    //   }, (error) => {
    //     console.log(error);
    //     //alert("An error ocurred while updating the playlist. Check your input for errors, and then check connection and try again");
    //   }),
    //   this._playlistService.getPlaylist(this.data.playlist.id, true).subscribe(p => {
    //     this.loadingCount = true;

    //   // this.update.emit('true');
    // }, (error) => {
    //   console.log(error);
    //   //alert("An error ocurred while getting the playlist. Check your connection and try again")
    // })
    // this.playlistComponent.getAllplaylist(this.data.playlist.id);

    // }
    if (val != 'tracks') {
      let added: number[] = [];
      let removed: number[] = [];
      this.checkedClients.forEach(track => {
        // if (!track || !track.track || !track.track.versions || track.track.versions.length <= 0 ||track.id) return;
        // if (this.isClientChecked(track)) {
        //   if(track.id){
        //     added.push(track.id)
        //   }
        //   added.push(track.id);
        // } else {
        //   console.log("track.id"+track.id)
        //   if (track.id) {
        //     removed.push(track.id)
        //   }
        //   removed.push(track.id);
        // }
        added.push(track.id)
      });
      this.unCheckedClients.forEach(track => {
        if (track.is_added === 'True') {
          removed.push(track.id)
        }
      })

      if (removed.length > 0 || added.length > 0) {

        if (removed.length > 0) {
          this.editedval = removed;
        } else {
          this.editedval = added;
        }


        // alert(this.editedval)
        // console.log('removed'+removed +"this.data.playlist.id"+this.data.playlist.id)
        this._playlistService.updateAssignClientsFromPlaylist(this.data.playlist.id, added, removed).subscribe(allClients => {
          this.tracksHandled.push(...removed);

          // this.getallData('clients');
          this.dialogRef.close(true);

        }, (error) => {
          console.log(error);
          // alert(`An error ocurred while removing versions ${removed.toString()} from the playlist. Check connection and try again`);
          let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
          this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
          });
        })
      }
    }
    let added: number[] = [];
    let removed: number[] = [];
    // this.unCheckedTracks.forEach(track => {
    //   // if (!track || !track.track || !track.track.versions || track.track.versions.length <= 0 ||track.id) return;
    //   if (this.isTrackChecked(track)) {
    //     if(track.id){
    //       added.push(track.id)
    //     }
    //     added.push(track.id);
    //   } else {
    //     console.log("track.id"+track.id)
    //     if (track.id) {
    //       removed.push(track.id)
    //     }
    //     removed.push(track.id);
    //   }
    // });
    // if (added.length > 0) {
    //   console.log('added',added)
    //   this._playlistService.addTrackVersionsToPlaylist(this.data.playlist.id, added).subscribe(p=> {
    //     this.tracksHandled.push(...added);
    //     this.closeIfDone();
    //   }, (error) => {
    //     console.log(error);
    //     //alert(`An error ocurred while adding versions ${added.toString()} to the playlist. Check connection and try again`);
    //   })
    // }
    if (val == 'tracks') {


      let data = {
        remove_ids: this.rmchecked.map(x => x.id),
        new_ids: this.unCheckedTracks.map(x => x.id)
      }

      this._playlistService.updateAssigntracksFromPlaylist(this.data.playlist.id, data).subscribe(p => {
        this.tracksHandled.push(...removed);
        this.getallData('tracks');
        this.dialogRef.close(true);
      }, (error) => {
        console.log(error);
        // alert(`An error ocurred while removing versions ${removed.toString()} from the playlist. Check connection and try again`);
        let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
        this._DialogService.UserUploadPopupConfirmPopup(data).subscribe(changed => {
        });
      })

    }

  }

  // closeIfDone() {
  //   if (this.modifiedClients && this.modifiedClients.length > 0) {
  //     if (this.clientsHandled.length != this.modifiedClients.length) return;
  //   }
  //   if (this.modifiedTracks && this.modifiedTracks.length > 0) {
  //     if (this.tracksHandled.length != this.modifiedTracks.length) return;
  //   }
  //   this.dialogRef.close(true);
  // }

  /**
   * A Method that handles the Loader in UI.
   * @returns a Boolean
   */
  isLoading() {
    return (this.activeTab == 'clients' && this.loadingClients) || (this.activeTab == 'tracks' && this.loadingTracks);
  }

  /**
   * A Method that Loads more data in the UI.
   * @param val string to specify for which the Data should be loaded either for PlayListTrack or PlayListclient
   */
  loadMore(val: string) {
    if (val === 'tracks') {
      this.trackPageIndex++
      this._playlistTracksService.getassignedTracks({ playlistId: this.data.playlist.id, search: this.searchTrackString }, this.trackPageIndex)
        .subscribe((t) => {
          this.tracks = t['results'];
          this.allTracks = t['results']
          this.nextTrack = t?.next ? Number(t?.next.split('')[(t?.next.length) - 1]) : ''
          let data = [...this.trackOptions['_value'], ...t['results']]
          this.trackOptions.next(data);
          //this.allTracks.next(this.tracks)
          this.loadingTracks = false;
          this.searchingTracks = false

        }, (error) => {
          console.log(error);
          this.searchingTracks = false
          this.loadingTracks = false;
          //alert("An error ocurred while getting the tracks for the playlist. Check your connections and try again");
        });
    } else {
      this.clientPageIndex++
      this._playlistTracksService.getassignedClients({ playlistId: this.data.playlist.id, search: this.searchClientString }, this.clientPageIndex)
        .subscribe((t) => {
          this.clients = t['results'] || [];
          this.nextClient = t?.next ? Number(t?.next.split('')[(t?.next.length) - 1]) : ''
          let data = [...this.clientOptions['_value'], ...t['results']]
          this.clientOptions.next(data);
          //this.allTracks.next(this.tracks)
          this.loadingClients = false;
          this.searchingClients = false

        }, (error) => {
          console.log(error);
          this.loadingClients = false;
          this.searchingClients = false
          //alert("An error ocurred while getting the tracks for the playlist. Check your connections and try again");
        });
    }
  }

  /**
   * A method that loads Data For the value in the Search bar.
   */
  searchloadMore() {
    this.rmchecked = this.rmchecked || [];
    this.unCheckedTracks = this.unCheckedTracks || [];
    this.trackPageIndex = 1
    this._playlistTracksService.getassignedTracks({ playlistId: this.data.playlist.id, search: this.searchTrackString }, this.trackPageIndex)
      .subscribe((t) => {
        this.tracks = t['results'];
        this.allTracks = t['results'];
        this.nextTrack = t?.next ? Number(t?.next.split('')[(t?.next.length) - 1]) : ''
        this.trackOptions.next(t['results']);
        //this.allTracks.next(this.tracks)
        this.loadingTracks = false;
        this.searchingTracks = false

      }, (error) => {
        console.log(error);
        this.searchingTracks = false
        this.loadingTracks = false;
        //alert("An error ocurred while getting the tracks for the playlist. Check your connections and try again");
      });
  }
}
