<div class="main">
    <div class="sidebar">
        <div class="top-holder">
            <strong>FILTER</strong>
            <p>{{count}} Tracks
              <button *ngIf="clearBtnFlag"
              type="button"
              (click)="clearFilter()" class="clear"
            >
              Clear all
            </button>
            </p>
          
        </div>
        <div class="top-holder filter-container">
            <mat-form-field appearance="fill">
                <mat-label>Genre</mat-label>
                <span *ngIf="selectedgenre" class="status-clear" (click)="selectedgenre=false;genreChanged($event,false);">Clear </span>
                <mat-select class="custom-backdrop-class"  panelClass="filter-select filter-genre setfiltercomposer" [disableOptionCentering]="true"  name="genreFilter"  placeholder="All" [(ngModel)]="selectedgenre" (selectionChange)="genreChanged($event);clearBtnFlag = true; getAllSubGenreTracks($event)"  #genreSelect>
                  <span class="select-title">Select a Genre</span>
                  <mat-option [value]="false" style="display: none;">All</mat-option>
                  <mat-option *ngFor="let option of filteredGenreOptions" [value]="option.id">
                    {{ option.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>subGenre</mat-label>
                <span *ngIf="selectedsubg" class="status-clear" (click)="selectedsubg=false;subgenreChanged($event,false);">Clear </span>
                <mat-select class="custom-backdrop-class" [(ngModel)]="selectedsubg" panelClass="setfiltercomposer filter-select filter-subgenre" [disableOptionCentering]="true"  name="genreFilter"  placeholder="All" (selectionChange)="subgenreChanged($event);clearBtnFlag = true">
                  <span class="select-title">Select a Subgenre</span>
                  <mat-option [value]="false" style="display: none;">All</mat-option>
                  <mat-option *ngFor="let option of filteredSubGenreOptions" [value]="option.id">
                    {{ option.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Mood</mat-label>
                <span *ngIf="selectedmood" class="status-clear" (click)="selectedmood=false;moodsChanged($event,false);">Clear </span>
                <mat-select class="custom-backdrop-class" [(ngModel)]="selectedmood" panelClass="setfiltercomposer filter-select filter-mood" [disableOptionCentering]="true"  name="genreFilter"  placeholder="All" (selectionChange)="moodsChanged($event);clearBtnFlag = true">
                  <span class="select-title">Select a Mood</span>
                  <mat-option [value]="false" style="display: none;">All</mat-option>
                  <mat-option *ngFor="let option of filteredMoodsOptions" [value]="option.id">
                    {{ option.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Tempo</mat-label>
                <span *ngIf="selectedtempo" class="status-clear" (click)="selectedtempo=false;tempoChanged($event,false);">Clear </span>
                <mat-select class="custom-backdrop-class" [(ngModel)]="selectedtempo" panelClass="setfiltercomposer filter-select filter-tempo" [disableOptionCentering]="true"  name="genreFilter"  placeholder="All" (selectionChange)="tempoChanged($event);clearBtnFlag = true">
                  <span class="select-title">Select a Tempo</span>
                  <mat-option [value]="false" style="display: none;">All</mat-option>
                  <mat-option *ngFor="let option of filteredTempoOptions" [value]="option.id">
                    {{ option.tempo_name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="additional-filter">
                <mat-checkbox  [(ngModel)]="Instrumentalsflag" (change)="insChecked($event.checked)">
                  Instrumentals
                </mat-checkbox>
              </div>
              
              <div class="additional-filter">
                <mat-checkbox [(ngModel)]="Vocalsflag" (change)="exclusiveChecked($event.checked)">
                  Vocals
                </mat-checkbox>
              </div>
        </div>
    </div>
    <div class="user-list">
        <div class="header">
           
            <form
            class="search-filter"
            [ngClass]="{'search-filter-focused': searchFilterFocused}"
            #searchForm="ngForm"
            
        >
            <label for="search-filter-field" class="icon" >
                <svg *ngIf="!searchFilterFocused" xmlns="http://www.w3.org/2000/svg" width="14" height="14"><path fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="20" d="M6 1a5 5 0 110 10A5 5 0 016 1zM13.002 13.002L9.535 9.535"/></svg>
                <svg *ngIf="searchFilterFocused" xmlns="http://www.w3.org/2000/svg" width="14" height="14"><path fill="none" stroke="#15151c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="20" d="M6 1a5 5 0 110 10A5 5 0 016 1zM13.002 13.002L9.535 9.535"/></svg>
            </label>
            <input
                
                name="searchString"
                type="text"
                class="txt"
                id="search-filter-field"
                placeholder="{{searchFilterFocused ? 'Enter keyword(s) seperated by commas to begin' : 'Search'}}"
                (focus)="searchFilterFocused = true"
                (input)="getSearchVal($event.target.value)"
            >
            <input
                type="reset"
                value="Cancel"
                class="btn-reset"
                (click)="searchFilterFocused = false;keyValue = '';searchTracks()"
            >
            <input type="submit" value="Search" class="btn-submit" 
            (click)="searchTracks()">
        </form>
            
            
            <div class="btns">
              <span class="btn-span">STATUS:</span>
                <div class="status-values">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#fff"><path d="M24 24H0V0h24v24z" fill="none" opacity=".87"/><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"/></svg>
                <mat-select [(value)]="food" panelClass="filter-select filter-mood" [disableOptionCentering]="true" placeholder="All" (selectionChange)="statusFilter($event.value)">
                  
                  <mat-option value="All" style="display: none;">All</mat-option>
                  <mat-option *ngFor="let food of foods" [value]="food" style="height:3em !important;">
                  <span> <svg class="" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z"/></svg></span>  {{food.viewValue}}
                  </mat-option>
                </mat-select>
              </div>
              
              
            </div>
        </div>
        <div class="reports-info track-list mytrack" *ngIf="count !==0">
            <app-loader *ngIf="loading"></app-loader>
            <mat-table *ngIf="!loading" [dataSource]="parentTracksDetails" multiTemplateDataRows class="mat-elevation-z8">
              
                <ng-container matColumnDef="name">
                  <mat-header-cell *matHeaderCellDef > </mat-header-cell>
                  <mat-cell *matCellDef="let row">{{ row.title }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="email">
                  <mat-header-cell *matHeaderCellDef > </mat-header-cell>
                  <mat-cell *matCellDef="let row"><span>{{row.status == 'DENIED' ? 'ACTION REQUIRED' : ''}}</span></mat-cell>
                </ng-container>

                <ng-container matColumnDef="adstatus" >
                  <mat-header-cell *matHeaderCellDef > </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <svg *ngIf="row.status == 'PENDING'" matTooltipPosition="left" matTooltip="Pending" matTooltipClass="tooltip-white" class="circle-dot circle-pending" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z"/></svg>
                    <svg *ngIf="row.status == 'PENDING_APPROVAL'"   matTooltipPosition="left" matTooltip="Pending Approval" matTooltipClass="tooltip-white" class="circle-dot circle-pendingapproval" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z"/></svg>
                    <svg *ngIf="row.status == 'APPROVED'" matTooltipPosition="left" matTooltip="Approved" matTooltipClass="tooltip-white" class="circle-dot circle-approved" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z"/></svg>
                    <svg *ngIf="row.status == 'DENIED'"   matTooltipPosition="left" matTooltip="Denied" matTooltipClass="tooltip-white" class="circle-dot circle-Denied" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z"/></svg>
                    <svg *ngIf="row.status == 'DISABLED'" matTooltipPosition="left" matTooltip="Disable" matTooltipClass="tooltip-white" class="circle-dot circle-disabled" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z"/></svg>
                    </mat-cell>
                    </ng-container>

                <ng-container matColumnDef="phone">
                  <mat-header-cell *matHeaderCellDef > </mat-header-cell>
                  <mat-cell   *matCellDef="let row" style="text-transform: none; margin-left: 0%;
                  margin-top: 3px;">
                    <span *ngIf="row.status !== 'PENDING_APPROVAL'">
                      {{ row.status | titlecase }}
                    </span>
                  <span *ngIf="row.status === 'PENDING_APPROVAL'" >
                    Pending Approval
                  </span></mat-cell>
                </ng-container>
               

                <ng-container matColumnDef="symbol" >
                  <mat-header-cell *matHeaderCellDef > </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                 
                    <button type="button"  class="versions-toggle" class="expand-button expan-btn1" >
                        <svg width="10px" height="5px" viewBox="0 0 10 5" class="carrot"
                          >
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                            stroke-linejoin="round">
                            <g transform="translate(-1392.000000, -396.000000)" class="inner-stroke" stroke-width="0.95">
                              <g transform="translate(392.000000, 351.000000)">
                                <g transform="translate(920.550000, 36.100000)">
                                  <polyline points="80.75 9.5 84.55 13.3 88.35 9.5"></polyline>
                                </g>
                              </g> 
                            </g>
                          </g>
                        </svg>
                      </button></mat-cell>
                </ng-container>
                
            
              
                <ng-container style="width:100%" matColumnDef="expandedDetail">
                  <mat-cell *matCellDef="let row"  style="width:100%;flex-direction: column;">
                    <div class="version-row" *ngFor="let version of versions">
                      <div class="mat-cell title">
                        <button type="button"  [ngClass]="{'disabledElement': (loadingTracks)}" class="play-button" (click)="playButtonPressed(row, version)"
                          *ngIf="!isPlaying(version)">
                          <svg width="8px" height="10px" viewBox="0 0 8 10" version="1.1" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <g transform="translate(-25.000000, -23.000000)">
                                <g transform="translate(16.000000, 16.000000)">
                                  <path class="inner-path"
                                    d="M16.0796687,12.8192319 L11.3234623,16.1485764 C10.871013,16.4652909 10.2474826,16.3552561 9.93076808,15.9028068 C9.8131096,15.7347232 9.75,15.5345165 9.75,15.3293444 L9.75,8.67065556 C9.75,8.11837081 10.1977153,7.67065556 10.75,7.67065556 C10.9551721,7.67065556 11.1553788,7.73376517 11.3234623,7.85142364 L16.0796687,11.1807681 C16.532118,11.4974826 16.6421528,12.121013 16.3254383,12.5734623 C16.2584893,12.6691037 16.17531,12.752283 16.0796687,12.8192319 Z"
                                    fill="#FFFFFF"></path>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </button>
                        <button type="button"  [ngClass]="{'disabledElement': (loadingTracks)}" (click)="pauseButtonPressed(row, version)" class="pause-button"
                          *ngIf="isPlaying(version)">
                          <svg width="10px" height="14px" viewBox="0 0 10 14" version="1.1" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <g transform="translate(-426.000000, -746.000000)" fill="#15151C" class="inner-path">
                                <g transform="translate(393.000000, 704.000000)">
                                  <path
                                    d="M33.2499996,42.9499995 L36.4166662,42.9499995 L36.4166662,55.616666 L33.2499996,55.616666 L33.2499996,42.9499995 Z M39.5833328,42.9499995 L42.7499995,42.9499995 L42.7499995,55.616666 L39.5833328,55.616666 L39.5833328,42.9499995 Z">
                                  </path>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </button>
                          <span class="version-title" (click)="isPlaying(version) ? pauseButtonPressed(row, version) : playButtonPressed(row, version)">
                            <!-- <span style="text-transform: none;" *ngIf="version.is_main_track "> {{row.title}} - {{ version.title  || '' }} - Main</span>
                            <span  style="text-transform: none;"  *ngIf="!version.is_main_track && !version.is_full_version">{{row.title}} - {{ version.title || '' }}</span>
                            <span  style="text-transform: none;"  *ngIf="!version.is_main_track && version.is_full_version">{{ version.title || '' }}</span> -->
                            <span> {{ version.title  || '' }} </span>
                          </span>
                      </div>
                      <div class="mat-cell">
                        <ng-container >
                          <span >{{version.duration | minuteSeconds }}</span>
                        </ng-container>
                      </div>
                    
                      <div class="mat-cell"style="text-align:center;flex: 0 0 3%;">
                        <ng-container *ngIf="!version.status">N/A</ng-container>
                        <svg style="margin-left:-7px;" *ngIf="version.status == 'PENDING'" matTooltipPosition="left" matTooltip="Pending" matTooltipClass="tooltip-white" class="circle-dot circle-pending" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z"/></svg>
                            <svg style="margin-left:-7px;" *ngIf="version.status == 'PENDING_APPROVAL'"   matTooltipPosition="left" matTooltip="Pending Approval" matTooltipClass="tooltip-white" class="circle-dot circle-pendingapproval" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z"/></svg>
                            <svg style="margin-left:-7px;" *ngIf="version.status == 'APPROVED'" matTooltipPosition="left" matTooltip="Approved" matTooltipClass="tooltip-white" class="circle-dot circle-approved" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z"/></svg>
                            <svg style="margin-left:-7px;" *ngIf="version.status == 'DENIED'"   matTooltipPosition="left" matTooltip="Denied" matTooltipClass="tooltip-white" class="circle-dot circle-Denied" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z"/></svg>
                            <svg style="margin-left:-7px;" *ngIf="version.status == 'DISABLED'" matTooltipPosition="left" matTooltip="Disable" matTooltipClass="tooltip-white" class="circle-dot circle-disabled" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z"/></svg>
                      </div>
                      <div class="mat-cell">
                      <ng-container  >
                          <span style="margin-left:-4px;" *ngIf="version.status !== 'PENDING_APPROVAL'">
                            {{ version.status | titlecase }}
                          </span>
                          <span style="margin-left:-4px;" *ngIf="version.status === 'PENDING_APPROVAL'">
                            Pending Approval
                          </span>
                      </ng-container></div>
                      <div class="mat-cell"style="text-align:end;">
                        <ng-container>
                          <span><button type="button" (click)="openDetails(row,version)" class="action"><small>Details </small></button></span>
                        </ng-container>
                      </div>
                    </div>
                    <div class="version-row" *ngIf="! versions || versions.length === 0">
                      There is no version for this track
                    </div>
                  </mat-cell>
                </ng-container>
            
                <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
                <mat-row *matRowDef="let element; columns: columnsToDisplay;" 
                   [ngClass]="{ expanded: trackIsExpanded(element) }"
                 [class.example-expanded-row]="expandedElement === element"
                  (click)="$event.preventDefault();$event.stopPropagation();toggleRow1(element)"  
                  class="element-row">
                </mat-row>
                <mat-row *matRowDef="let row; columns: ['expandedDetail']" 
                [@detailExpand]="trackIsExpanded(row) ? 'expanded' : 'collapsed'" class="example-detail-row" class="element-row1" ></mat-row>

              </mat-table>
            
              <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
            
        </div>
        <div *ngIf="count === 0" class="no-data"> <app-loader *ngIf="loading"></app-loader>
          <span *ngIf="!loading" >No data available</span></div>
        <app-pagination
        [ngStyle]="{'visibility': count === 0 ? 'hidden' : 'visible' }"
        [page]="currentPage"
        (pageChange)="pageChange($event)"
        [numberOfPages]="genreNumberOfPages"
        ></app-pagination>

    </div>
    
</div>

