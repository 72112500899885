import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Track } from '../models/track.model';
import { TrackVersion } from '../models/trackversion.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { PlaylistTracks } from '../models/playlisttracks.model';

export class PlayingItem {
  constructor(public track: Track, public version: TrackVersion) { }
}
export class PlaylistTrack {
  constructor(public track: PlaylistTracks) { }
}

@Injectable({
  providedIn: 'root'
})
export class PlayerService {
  public nextPressSubject = new BehaviorSubject(null)
  public nextButton = this.nextPressSubject.asObservable()

  public nextIndexSubject = new BehaviorSubject(null)
  public nextIndex = this.nextPressSubject.asObservable()

  public currTrackSubject = new BehaviorSubject(null)
  public currTrack = this.currTrackSubject.asObservable();

  currentlyPlaying: Observable<PlayingItem>;
  private _currentlyPlayingSubject: BehaviorSubject<PlayingItem> = new BehaviorSubject<PlayingItem>(null);
  isPlaying: Observable<boolean>;
  private _isPlayingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  previewPlaying: Observable<boolean>;
  isPlayType: Observable<boolean>;
  private _isPlayTypeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _previewPlayingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  playbackProgress: Observable<number>;
  private _playbackProgressSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private _playUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  playUrl: Observable<string>;

  playbackContextList: Observable<PlayingItem[]>;
  private _playbackContextListSubject: BehaviorSubject<PlayingItem[]> = new BehaviorSubject<PlayingItem[]>([]);
  showMusicPlayer: Observable<boolean>;
  private _showMusicPlayer: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _isViewType: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isViewType: Observable<boolean>;
  enablePlay: Observable<boolean>;
  private _enableplay: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isWavePlay: Observable<boolean>;
  private _isWavePlaySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoader: Observable<boolean>;
  private _isLoaderPlaySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isNextTab: Observable<boolean>;
  private _nextTabSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isPlayNextTab: Observable<boolean>;
  private _playNextTabSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isCollapse: Observable<boolean>;
  private _isCollapse: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  currentlyPlayingList: Observable<PlaylistTrack>;
  private _currentlyPlayingListSubject: BehaviorSubject<PlaylistTrack> = new BehaviorSubject<PlaylistTrack>(null);

  isPlayingList: Observable<boolean>;
  private _isPlayingListSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  playbackProgressList: Observable<number>;
  private _playbackProgressListSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    
  trackNumber: Observable<[]>;
  private _trackNumberSubject: BehaviorSubject<[]> = new BehaviorSubject<[]>([]);

  trackValues: Observable<[]>;
  private _trackValueSubject: BehaviorSubject<[]> = new BehaviorSubject<[]>([]);

  versionValues: Observable<[]>;
  private _trackVersionValueSubject: BehaviorSubject<[]> = new BehaviorSubject<[]>([]);

  trackCowriter: Observable<[]>;
  private _trackCowriterSubject: BehaviorSubject<[]> = new BehaviorSubject<[]>([]);

  trackId: Observable<number>;
  private _trackIdSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  playbackContextPlayList: Observable<PlaylistTrack[]>;
  private _playbackContextPlayListSubject: BehaviorSubject<PlaylistTrack[]> = new BehaviorSubject<PlaylistTrack[]>([]);

  clearPlaying: Observable<any>;
  public clearPlayingSubject: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  playTrack: Observable<any>;
  public playTrackSubject: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  get nextItem(): PlayingItem {
    return this._nextItem;
  }
  private _nextItem: PlayingItem;
  get previousItem(): PlayingItem {
    return this._previousItem;
  }
  private _previousItem: PlayingItem;


  //playlist music 
  get playNextItem(): PlaylistTrack {
    return this._playNextItem;
  }
  private _playNextItem: PlaylistTrack;
  get playPreviousItem(): PlaylistTrack {
    return this._playPreviousItem;
  }
  private _playPreviousItem: PlaylistTrack;


  currentPlaylistItems: any = {};


  constructor(
    private http: HttpClient,
    // private _activityService: ActivityService
  ) {
    this.currentlyPlaying = this._currentlyPlayingSubject.asObservable();
    this.isPlaying = this._isPlayingSubject.asObservable();
    this.previewPlaying = this._previewPlayingSubject.asObservable()
    this.playbackProgress = this._playbackProgressSubject.asObservable();
    this.playbackContextList = this._playbackContextListSubject.asObservable();
    this.showMusicPlayer = this._showMusicPlayer.asObservable();
    this.isViewType = this._isViewType.asObservable();
    this.playUrl = this._playUrl.asObservable();
    this.isPlayType = this._isPlayTypeSubject.asObservable();
    this.enablePlay = this._enableplay.asObservable();
    this.isCollapse = this._isCollapse.asObservable();
    this.isWavePlay = this._isWavePlaySubject.asObservable();
    this.isLoader = this._isLoaderPlaySubject.asObservable();
    this.isNextTab = this._nextTabSubject.asObservable();
    this.isPlayNextTab = this._playNextTabSubject.asObservable();
    this.trackNumber = this._trackNumberSubject.asObservable();
    this.trackValues = this._trackValueSubject.asObservable();
    this.versionValues = this._trackVersionValueSubject.asObservable();
    this.trackCowriter = this._trackCowriterSubject.asObservable();
    this.trackId = this._trackIdSubject.asObservable();
    this.currentlyPlayingList = this._currentlyPlayingListSubject.asObservable();
    this.isPlayingList = this._isPlayingListSubject.asObservable();
    this.playbackProgressList = this._playbackProgressListSubject.asObservable();
    this.playbackContextPlayList = this._playbackContextPlayListSubject.asObservable();
    this.nextButton.subscribe(x => {
    })
    this.clearPlaying = this.clearPlayingSubject.asObservable();
    this.playTrack = this.playTrackSubject.asObservable();
  }

  updateShowMusicPlayer(show: boolean) {
    this._showMusicPlayer.next(show);
  }
  updateViewType(show: boolean) {
    this._isViewType.next(show);
  }
  updateEnablePlay(show: boolean) {
    this._enableplay.next(show);
  }

  updatePlayListType(show: boolean) {
    this._isPlayTypeSubject.next(show);
  }
  updateCollapse(show: boolean) {
    this._isCollapse.next(show);

  }


  previous() {
    this.clearPlayingSubject.next(true)
    if (!this._previousItem) { return; }
    let currentlyPlaying = this._isPlayingSubject.getValue();
    if (currentlyPlaying) {
      this.play(this._previousItem.track, this._previousItem.version);
    } else {
      this.updateCurrentlyPlaying(this._previousItem.track, this._previousItem.version);
    }
  }

  playPrevious() {
    if (!this._previousItem) { return; }
    let currentlyPlaying = this._isPlayingSubject.getValue();
    if (currentlyPlaying) {
      this.play(this._previousItem.track, this._previousItem.version);
    } else {
      this.updateCurrentlyPlaying(this._previousItem.track, this._previousItem.version);
    }
  }

  play(track: Track, version: TrackVersion) {
    //alert(track)
    console.log(version)
    this.updateIsPlaying(false);
    if (this.updateCurrentlyPlaying(track, version)) {
      this.updateIsPlaying(true);
    }

    this.updateNextAndPrev();
  }
  playList(track: PlaylistTracks) {
    this.updateIsPlayingList(false);
    if (this.updateCurrentlyPlayingList(track)) {
      this.updateIsPlayingList(true);
    }

    this.updateNextAndPrevPlaylist();
  }

  updateCurrentlyPlaying(track: Track, version: TrackVersion): boolean {
    if (!track || !version || (!version.mp3_file && !version.wav_file)) {
      this._currentlyPlayingSubject.next(null);
      this._isPlayingSubject.next(false);
      this._playbackProgressSubject.next(0);
      return false;
    }
    // this._activityService.recordActivity('played_track', track.id).subscribe();
    let currentlyPlaying = new PlayingItem(track, version);
    this._currentlyPlayingSubject.next(currentlyPlaying);
    this._playbackProgressSubject.next(0);
    return true;
  }

  updateCurrentlyPlayingList(track: PlaylistTracks): boolean {
    if (!track || (!track.mp3_file && !track.wav_file)) {
      this._currentlyPlayingListSubject.next(null);
      this._isPlayingListSubject.next(false);
      this._playbackProgressListSubject.next(0);
      return false;
    }
    // this._activityService.recordActivity('played_track', track.id).subscribe();
    let currentlyPlayingList = new PlaylistTrack(track);
    this._currentlyPlayingListSubject.next(currentlyPlayingList);
    this._playbackProgressListSubject.next(0);
    return true;
  }
  next() {
    this.clearPlayingSubject.next(true)
    if (!this._nextItem) { return; }
    let currentlyPlaying = this._isPlayingSubject.getValue();
    if (currentlyPlaying) {
      this.play(this._nextItem.track, this._nextItem.version);
      this._playUrl.next(this._nextItem.version.mp3_file);
    } else {
      this._playUrl.next(this._nextItem.version.mp3_file);
      this.updateCurrentlyPlaying(this._nextItem.track, this._nextItem.version);
    }
  }

  playNext() {
    //alert("hi")
    if (!this._playNextItem) { return; }
    let currentlyPlaying = this._isPlayingListSubject.getValue();
    if (currentlyPlaying) {
      this.playList(this._playNextItem.track.mp3_file);
    } else {
      this.updateCurrentlyPlayingList(this._playNextItem.track);
    }
  }

  updateIsPlaying(playing: boolean) {
    this._isPlayingSubject.next(playing);
  }

  updatePreviewPlaying(playing: boolean) {
    this._previewPlayingSubject.next(playing);
  }

  updateWavePlay(playing: boolean) {
    this._isWavePlaySubject.next(playing);
  }
  updateLoader(playing: boolean) {
    this._isLoaderPlaySubject.next(playing);
  }
  nextTab(value:boolean){
    this._nextTabSubject.next(value);
  }
  nextPlayTab(value:boolean){
    this._playNextTabSubject.next(value);
  }
  

  updatePlaybackProgress(progress: number) {
    this._playbackProgressSubject.next(progress);
  }

  updateURL(progress: string) {
    this._playUrl.next(progress);
  }
  trackNum(progress: []) {
    this._trackNumberSubject.next(progress);
  }
  trackDetail(track:any){
    this._trackValueSubject.next(track)
  }
  trackVersionDetail(version: any){
    this._trackVersionValueSubject.next(version)
  }
  trackCowriter1(progress: []) {
    this._trackCowriterSubject.next(progress);
  }
  trackIdDetail(value:number){
    this._trackIdSubject.next(value);
  }

  updatePlaybackContextList(list: PlayingItem[]) {
    this._playbackContextListSubject.next(list);
    this.updateNextAndPrev();
  }
  updateIsPlayingList(playing: boolean) {
    this._isPlayingListSubject.next(playing);
  }

  private updateNextAndPrev() {
    let list = this._playbackContextListSubject.getValue();
    let currentlyPlaying = this._currentlyPlayingSubject.getValue();
    let prev: PlayingItem = null;
    let next: PlayingItem = null;
    if (!currentlyPlaying) {
      this._nextItem = next;
      this._previousItem = prev;
      return;
    }
    let i = list?.findIndex(item => {
      return item.track.id == currentlyPlaying.track.id && item.version.id == currentlyPlaying.version.id;
    });
    if (i != -1) {
      let nextI = (i + 1) % list?.length;
      let prevI = i > 0 ? i - 1 : list?.length - 1;
      next = list && list[nextI];
      next = list && list[nextI];
      prev = list && list[prevI];
    }
    this._nextItem = next;
    this._previousItem = prev;
  }

  private updateNextAndPrevPlaylist() {
    let list = this._playbackContextPlayListSubject.getValue();
    let currentlyPlayingList = this._currentlyPlayingListSubject.getValue();
    let prev: PlaylistTrack = null;
    let next: PlaylistTrack = null;
    if (!currentlyPlayingList) {
      this._playNextItem = next;
      this._playPreviousItem = prev;
      return;
    }
    let i = list.findIndex(item => {
      console.log(item)
      // return item.id == currentlyPlayingList.track.id && item.version.id == currentlyPlaying.version.id;
    });
    if (i != -1) {
      let nextI = (i + 1) % list.length;
      let prevI = i > 0 ? i - 1 : list.length - 1;
      next = list[nextI];
      prev = list[prevI];
    }
    this._playNextItem = next;
    this._playPreviousItem = prev;
  }



  setPlayerObjects(Playlists, currentTrack) {
   // this.currentPlaylistItems = {};
    let allPlaylistItems: any[] = Playlists || [];
    let currentIndex = allPlaylistItems.findIndex(obj => obj.id == currentTrack.id);
    let nextIndex = (allPlaylistItems.length - 1 == currentIndex) ? -1 : (currentIndex + 1);
    let prevIndex = (currentIndex == 0) ? -1 : (currentIndex - 1);

    let newPlaylistObj = {
      current: currentIndex,
      next: nextIndex,
      prev: prevIndex,
      playlists: allPlaylistItems,
      currentItem: currentTrack,
      isPlay:true,
      isPause:false
    }

    this.currentPlaylistItems = newPlaylistObj;


  }
}
