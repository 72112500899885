import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Track } from '../models/track.model';
import { TrackVersion } from '../models/trackversion.model';
import { DialogService } from '../services/dialog.service';
import { TrackVersionService } from '../services/trackversion.service';

export interface DialogData {
  track: Track;
  version: TrackVersion;
}

@Component({
  selector: 'app-delete-track-version-dialog',
  templateUrl: './delete-track-version-dialog.component.html',
  styleUrls: ['./delete-track-version-dialog.component.scss']
})
export class DeleteTrackVersionDialogComponent implements OnInit {

  loading: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<DeleteTrackVersionDialogComponent>,
    private _trackVersionSerivce: TrackVersionService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,public dialog: DialogService,
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close(false);
  }

  /**
   * Don't actually delete users
   * just remove them from the list they were "deleted" from
   * and if they are no longer a part of either list deactivate them
   * this will allow us to "recover" users deleted by mistake
   * */
  deleteTrackVersion() {
    this.loading = true;
    
    this._trackVersionSerivce.deleteTrackVersion(this.data.version).subscribe(()=> {
      this.loading = false;
      this.dialogRef.close(true);
      location.reload();
    }, (error) => {
      console.log(error);
      let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
      this.dialog.UserUploadPopupConfirmPopup(data).subscribe(changed => {
      });
      this.dialogRef.close(true);
      //alert("An error ocurred while deleting the track version. Check your connections and try again");
    });
  }

}
