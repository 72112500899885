import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


export interface DialogData {
  title: any,
  value: any
}

@Component({
  selector: 'app-user-upload-popup',
  templateUrl: './user-upload-popup.component.html',
  styleUrls: ['./user-upload-popup.component.scss']
})
export class UserUploadPopupComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<UserUploadPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
  }
  close() {
    this.dialogRef.close(true);
  }
  Cancel(){
    this.dialogRef.close();
  }

}
