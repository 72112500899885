import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MenuListService } from '../../shared/services/menu-list.service';
import { environment } from '../../../environments/environment';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
@Component({
  selector: 'app-delete-usergroups-dialogue',
  templateUrl: './delete-usergroups-dialogue.component.html',
  styleUrls: ['./delete-usergroups-dialogue.component.scss']
})
export class DeleteUsergroupsDialogueComponent implements OnInit {
  loaderContent:boolean = true;
  loader:boolean = false;
  groupDetails:any;
  constructor( private dialogRef: MatDialogRef<DeleteUsergroupsDialogueComponent>,public list: MenuListService, public api: UserMenuService, public config: MenuConfigService,public dialog: DialogService,) { }
  ngOnInit(): void {
    setTimeout(()=>{                 
      this.loaderContent = false;
    }, 500);
    this.list.getAdminUser().subscribe(x=>{
      if(x){
        this.groupDetails = x;
        console.log(x)
      }
     
     })
  }
  close() {
    this.dialogRef.close();
  }
  deleteUserGroup(groupDetails:any){
    let url = environment.apiURL + this.config.removeUserGroup;    
    url = url.replace('{group_id}', this.list.currentUserGroup.id);
    this.api.doDelete(url).subscribe(x=>{
      this.loader = false;
      this.dialogRef.close();
      this.list.getAllGroups(1)
    },
    (error) => {
      this.loader = false;
      let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : ``))
      this.dialog.UserUploadPopupConfirmPopup(data).subscribe(changed => {
      });
      this.dialogRef.close();
      //alert(error.message)
    })
  }
}