import { MibeModel } from './mibemodel.model';
import { Playlist } from './playlist.model';
import { EmailTemplateService } from '../../shared/services/email-template.service';
export class EmailTemplate implements MibeModel {
  id: number;
  name: string;
  type: string;
  sender: string;
  cc_email: any;
  bcc_email: any;
  receiver: string;
  groups: any[] = [];
  subject: string;
  body: string;
  playlists: Playlist[] = [];
  is_active: boolean;
  created_at: Date;
  updated_at: Date;
  message:string;
  /**
   * Create a Mood.
   * @param {any} json - The JSON object with which to construct our Mood.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a Mood.
   * @param {any} json - The JSON object with which to load our Mood.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this.id = json.id;
    this.name = json.name;
    this.type = json.type;
    this.sender = json.sender;
    this.bcc_email = json.bcc_email;
    this.cc_email = json.cc_email;
    this.receiver = json.receiver;
    this.groups = json.groups && json.groups.length > 0 ? json.groups:[];
    this.subject = json.subject;
    this.body = json.body;
    this.playlists = json.playlists && json.playlists.length > 0 ? json.playlists.map(p=>new Playlist(p)):[];
    this.is_active = json.is_active;
    this.created_at = json.created_at ? new Date(json.created_at):null;
    this.updated_at = json.updated_at ? new Date(json.updated_at):null;
    this.message = json.message ;
  }
  /**
   * Returns a JSON representation of our Mood
   * @return {any} - The JSON representation of our Mood
   */
  toJSON() {
    var json: any = {
      'id': this.id,
      'name': this.name,
      'type': this.type,
      'sender': this.sender,
      'bcc_email' : this.bcc_email,
      'cc_email' : this.cc_email,
      'receiver' : this.receiver,
      'groups' : this.groups,
      'subject': this.subject,
      'body': this.body,
      'playlists': this.playlists.map(p=>p),
      'is_active' : this.is_active,
    };
    if (this.created_at) {
      json['created_at'] = this.created_at.toJSON();
    }
    if (this.updated_at) {
      json['updated_at'] = this.updated_at.toJSON();
    }
    return json;
  }

  getDisplayName(): string {
    if (!this || !this.id) return '';
    return `${this.formatDate(this.created_at)} - ${this.name}`;
  }
  // Get the display name without date 
  getDisplayNameOnly(): string {
    if (!this || !this.id) return '';
    return `${this.name}`;
  }

  formatDate(d:Date): string {
    return `${d.getMonth()+1}/${d.getDate()}/${d.getFullYear().toString().substr(-2)}`;
  }
}
