<div class="main">
    <div class="user-list">
        <div class="header">
            <form action="#" class="search-filter" #searchForm="ngForm" >
                <label for="search-filter-field" class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14">
                        <path fill="none" stroke="#fff" stroke-linecap="round"
                            stroke-linejoin="round" stroke-miterlimit="20" d="M6 1a5 5 0 110 10A5 5 0 016 1zM13.002 13.002L9.535 9.535"
                        /></svg>
                </label>
                <input  name="searchString" type="text" class="txt" id="search-filter-field" 
                 placeholder="Search" />
            </form>
            <div class="btns">
                <span class="btn-span">STATUS:</span>
                  <div class="status-values">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#fff"><path d="M24 24H0V0h24v24z" fill="none" opacity=".87"/><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"/></svg>
                  <mat-select>
                    
                    <mat-option [value]="false">All</mat-option>
                    <mat-option *ngFor="let food of foods" [value]="food.value">
                      {{food.viewValue}}
                      
                    </mat-option>
                  </mat-select>
                </div>
                
              </div>
        </div>
        <div class="upload-card">
            <p>Please upload tracks to get started.</p>
            <button  type="button" class="download-button-upload"> upload tracks </button>
        </div>
    </div>
 </div> 
