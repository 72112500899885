import { MibeModel } from './mibemodel.model';

export class Instrument implements MibeModel {
  id: number;
  name: string;
  added_by_role: number;

  /**
   * Create a Instrument.
   * @param {any} json - The JSON object with which to construct our Instrument.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a Instrument.
   * @param {any} json - The JSON object with which to load our Instrument.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this.id = json.id;
    this.name = json.name;
    this.added_by_role = json.added_by_role
  }
  /**
   * Returns a JSON representation of our Instrument
   * @return {any} - The JSON representation of our Instrument
   */
  toJSON() {
    var json: any = {
      'id': this.id,
      'name': this.name,
      'added_by_role': this.added_by_role
    };
    return json;
  }
}
