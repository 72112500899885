<div class="main">
    <div class="user-list">
        <div class="header">
            <form action="#" class="search-filter" #searchForm="ngForm" >
                <label for="search-filter-field" class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14">
                        <path fill="none" stroke="#fff" stroke-linecap="round"
                            stroke-linejoin="round" stroke-miterlimit="20" d="M6 1a5 5 0 110 10A5 5 0 016 1zM13.002 13.002L9.535 9.535"
                        /></svg>
                </label>
                <input  name="searchString" type="text" class="txt" id="search-filter-field" 
                 placeholder="Search" [(ngModel)]="searchPerm" (input)="searchkey($event.target.value)"/>
            </form>
            <div class="btns">
             <span class="btn-span">SORT:</span> 
                <div class="status-values">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#fff"><path d="M24 24H0V0h24v24z" fill="none" opacity=".87"/><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"/></svg>
                <mat-select [(value)]="selectedsort" panelClass="filter-select filter-mood" [disableOptionCentering]="true" placeholder="All" (selectionChange)="sortingFilename($event)">
                  <mat-option value="All" >All</mat-option>
                  <mat-option *ngFor="let file of sorting" [value]="file.value">
                    {{file.viewValue}}
                    
                  </mat-option>
                </mat-select>
              </div>
              
            </div>
        </div>
        <div class="reports-info track-list" *ngIf="list.uploadedTracks.count != 0" (getNewTrack)="refreshTracks($event)">
            <app-loader *ngIf="loading"></app-loader>
            <mat-table  *ngIf="!loading" [dataSource]="list.uploadedTracksDetails" class="mat-elevation-z8">
                <ng-container matColumnDef="position">
                  <mat-header-cell *matHeaderCellDef> </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <button type="button" class="play-button" 
                   >
                    <svg width="8px" height="10px" viewBox="0 0 8 10" version="1.1" xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink">
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g transform="translate(-25.000000, -23.000000)">
                          <g transform="translate(16.000000, 16.000000)">
                            <path class="inner-path"
                              d="M16.0796687,12.8192319 L11.3234623,16.1485764 C10.871013,16.4652909 10.2474826,16.3552561 9.93076808,15.9028068 C9.8131096,15.7347232 9.75,15.5345165 9.75,15.3293444 L9.75,8.67065556 C9.75,8.11837081 10.1977153,7.67065556 10.75,7.67065556 C10.9551721,7.67065556 11.1553788,7.73376517 11.3234623,7.85142364 L16.0796687,11.1807681 C16.532118,11.4974826 16.6421528,12.121013 16.3254383,12.5734623 C16.2584893,12.6691037 16.17531,12.752283 16.0796687,12.8192319 Z"
                              fill="#FFFFFF"></path>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </button>  
                    {{element.file_name}} </mat-cell>
                </ng-container>
              
               
                <ng-container matColumnDef="name">
                  <mat-header-cell *matHeaderCellDef> </mat-header-cell>
                  <mat-cell *matCellDef="let element" style="font-size: 11px;"> {{element.file_size}} </mat-cell>
                </ng-container>
              
               
                <ng-container matColumnDef="weight">
                  <mat-header-cell *matHeaderCellDef>  </mat-header-cell>
                  <mat-cell *matCellDef="let element" style="justify-content:flex-end!important;font-size:10px;
                  ">
                   <button type="button"   id="checkbox1"  (click)="newTrack(element)" class="action"><small>Create a track</small></button>
                   <button type="button" id="checkbox"  (click)="newTrackVersion(element)" class="action"><small>Create a track version</small></button>
                   <button type="button" class="action" (click)="list.currentUploadedTrackSubject.next(element);removeTrack()">
                    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#FFFFFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/></svg>
                    </button>
                      
                </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" class="main-row"></mat-row>
            </mat-table>
        </div>
        <app-pagination
        *ngIf="genreNumberOfPages > 1 && !loading"
        [page]="currentPage"
        (pageChange)="pageChange($event)"
        [numberOfPages]="genreNumberOfPages"
        ></app-pagination>
        <div class="reports-info track-list" *ngIf="list.uploadedTracks.count == 0">
          <div class="upload-card">
            <p>Please upload tracks to get started.</p>
            <button type="button" class="download-button-upload" *ngIf=" _userService?.currentUser?.can_upload === true" mat-button (click)="uploadTrack()">Upload tracks</button>
            <button type="button" class="button-upload" *ngIf=" _userService?.currentUser?.can_upload === false" (click)="uploadpop()" mat-button >Upload tracks</button>
          </div>
        </div>
    </div>
    
    
</div> 
