<div class="section general">
    <div class="title">
        <h3>User Info</h3>
    </div>
    <div class="grid-card">
        <div class="grid-item">
            <mat-form-field appearance="fill">
                <mat-label>Permission Groups</mat-label>
                <mat-select [formControl]="toppings" [value]="'primary'">
                    <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="grid-container">

        <div class="grid-item">
            <mat-form-field appearance="fill">
                <mat-label>First Name</mat-label>
                <input matInput>
            </mat-form-field>
        </div>
        <div class="grid-item">
            <mat-form-field appearance="fill">
                <mat-label>Last Name</mat-label>
                <input matInput>
            </mat-form-field>
        </div>
    </div>
    
    <div class="grid-card">
        <div class="grid-item">
            <mat-form-field appearance="fill">
                <mat-label>Email Address</mat-label>
                <input matInput>
            </mat-form-field>
        </div>
    </div>
</div>
<hr>






<div class="section general">
    <div class="title">
        <h3>Permissions</h3>
    </div>
    <div>
        <mat-dialog-content>

            <div class="dialog-body">

                <div class="add-to-folder form-container">
                    <h6 class="label">Available Permission<span class="deselect"><small>Deselect all</small></span></h6>
                    <span></span>

                    <input class="search" placeholder="Find a Permission">
                    <ul class="list">
                        <li *ngFor="let client of ['permission 1','permission 2','permission 1','permission 2','permission 3','permission 4','permission 3','permission 4']">
                            <mat-checkbox>
                                <span class="check-text">{{client}}</span>
                            </mat-checkbox>
                        </li>
                    </ul>

                </div>
                <p class="note">Select or deselect application permission</p>
                <mat-checkbox>
                    <span class="check-text">Select All</span>
                </mat-checkbox>

            </div>
        </mat-dialog-content>
    </div>
</div>
<hr>






<div class="section contact">
    <div class="title">
        <h3>Contact<span class="allfields"><small>*All fields required </small></span></h3>
    </div>
    <div class="grid-card">
        <div class="grid-item">
            <mat-form-field appearance="fill">
                <mat-label>Address Line 1</mat-label>
                <input matInput>
            </mat-form-field>
        </div>
    </div>
    <div class="grid-card">
        <div class="grid-item">
            <mat-form-field appearance="fill">
                <mat-label>Address Line 2</mat-label>
                <input matInput>
            </mat-form-field>
        </div>
    </div>
    <div class="grid-container">

        <div class="grid-item">
            <mat-form-field appearance="fill">
                <mat-label>Country</mat-label>
                <input matInput>
            </mat-form-field>
        </div>

        <div class="grid-item">
            <mat-form-field appearance="fill">
                <mat-label>City</mat-label>
                <input matInput>
            </mat-form-field>
        </div>
        <div class="grid-item">
            <mat-form-field appearance="fill">
                <mat-label>State</mat-label>
                <input matInput>
            </mat-form-field>
            <!-- <app-state-select ></app-state-select> -->
        </div>
        <div class="grid-item">
            <mat-form-field appearance="fill">
                <mat-label>Zip</mat-label>
                <input matInput>
            </mat-form-field>
        </div>
    </div>
    <div class="grid-card">
        <div class="grid-item">

            <div class="rows">
                <div class="phone">
                    <mat-form-field class="type" appearance="fill">
                        <mat-select>
                            <mat-option [value]="'Mobile'">
                                Mobile
                            </mat-option>
                            <mat-option [value]="'Office'">
                                Office
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="number" appearance="fill">
                        <input type="tel" matInput class="form-control" phoneMask maxlength="19">
                    </mat-form-field>
                </div>
                <div class="phone">
                    <mat-form-field class="type" appearance="fill">
                        <mat-select>
                            <mat-option [value]="'Mobile'">
                                Mobile
                            </mat-option>
                            <mat-option [value]="'Office'">
                                Office
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="number" appearance="fill">
                        <input type="tel" matInput class="form-control" phoneMask maxlength="19">
                    </mat-form-field>
                </div>

                <div class="add-row"><button class="btn-row">+ <u>Add</u></button></div>
            </div> 
            <div class="title">
                <h3>User Name</h3>
            </div>
            <div class="grid-card">
                <div class="grid-item">
                    <mat-form-field appearance="fill">
                        <mat-label>User Name</mat-label>
                        <input matInput>
                    </mat-form-field>
                </div>
            </div>
            <button class="btn save-btn" mat-button>Update</button>
        </div>
    </div>
