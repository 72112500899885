import { Playlist } from '../models/playlist.model';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { PlaylistFolder } from '../models/playlistfolder.model';
import { PlaylistFolderService } from '../services/playlistfolder.service';
import {PlaylistComponent} from '../../playlist/playlist.component'
import { DialogService } from '../services/dialog.service';
export interface DialogData {
  folders?: PlaylistFolder[];
}

@Component({
  selector: 'app-delete-playlist-folders-dialog',
  templateUrl: './delete-playlist-folders-dialog.component.html',
  styleUrls: ['./delete-playlist-folders-dialog.component.scss']
})
export class DeletePlaylistFoldersDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<DeletePlaylistFoldersDialogComponent>,
    private _playlistFolderService: PlaylistFolderService, private playlistComponent: PlaylistComponent,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,public dialog: DialogService,
  ) {}

  loading = false;
  loadingUserUpdate:boolean = false;

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close(false);
  }

  delete() {
    if (!this.data.folders || this.data.folders.length <= 0) return;
    for (let folder of this.data.folders) {
      this.loadingUserUpdate = true;
      this._playlistFolderService.deletePlaylist(folder.id).subscribe(()=> {
        this.loadingUserUpdate = false;
        this.playlistComponent.loadFolders();
        this.dialogRef.close(true);
      }, (error) => {
        console.log(error);
        let data = error.error?.error ? error.error?.error : (error.error?.detail ? error.error?.detail : (error.error?.details ? error.error?.details : `Something went wrong`))
        this.dialog.UserUploadPopupConfirmPopup(data).subscribe(changed => {
        });
        this.loadingUserUpdate = false;
        this.dialogRef.close(true);
        //alert(`An error ocurred while deleting the playlist folder ${folder.name}. Check your connections and try again`);
      });
    }
    this.dialogRef.close(true);
  }
    

}
