import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { trigger, style, animate, transition } from '@angular/animations';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  animations: [
    trigger('navButtonInOut', [
      transition(':enter', [
        style({
          width: '0px',
          overflow: 'hidden',
          opacity: '0',
        }),
        animate(
          '0.4s ease-in-out',
          style({
            opacity: '1',
            overflow: 'hidden',
            width: '*',
          })
        ),
      ]),
      transition(':leave', [
        style({
          opacity: '1',
          overflow: 'hidden',
          width: '*',
        }),
        animate(
          '0.4s ease-in-out',
          style({
            opacity: '0',
            width: '0px',
          })
        ),
      ]),
    ]),
  ],
})
export class PaginationComponent implements OnInit, OnDestroy {
  @Input()
  set page(p: number) {
    if (this._page != p) {
      this._page = p;
      this.pageChange.emit(p);
    }
  }
  get page(): number {
    return this._page;
  }
  @Input()
  numberOfPages: number;

  @Input()
  darkText: boolean = false;

  @Output()
  pageChange: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  filtersChange = new EventEmitter<any>();

  @ViewChild('pageField', { static: true })
  set pageField(e: ElementRef) {
    this._pageField = e;
  }
  get pageField(): ElementRef {
    return this._pageField;
  }

  get displayPages(): any[] {
    return this._generatePageRange(this.page, this.numberOfPages);
  }

  private _page = 1;
  private _pageRangeDelta = 3;
  private _pageField: ElementRef;
  private _subscriptions: Subscription[] = [];
  constructor(private _breakpointObserver: BreakpointObserver) {}

  ngOnInit() {
    this._subscriptions.push(
      this._breakpointObserver
        .observe(['(max-width: 767px)'])
        .subscribe((result) => {
          if (result.matches) {
            this._pageRangeDelta = 1;
          } else {
            this._pageRangeDelta = 3;
          }
        })
    );
  }
  pageEmit(){
    this.filtersChange.emit(this.page);
  }
  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
    this._subscriptions = [];
  }

  private _generatePageRange(currentPage: number, lastPage: number): any[] {
    const delta = this._pageRangeDelta;

    const range = [];
    for (
      let i = Math.max(2, currentPage - delta);
      i <= Math.min(lastPage - 1, currentPage + delta);
      i += 1
    ) {
      range.push(i);
    }

    if (currentPage - delta > 2) {
      range.unshift('...');
    }
    if (currentPage + delta < lastPage - 1) {
      range.push('...');
    }

    range.unshift(1);
    if (lastPage !== 1) range.push(lastPage);

    return range;
  }
}
