import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ThemeService } from './theme/theme.service';
import { UserService } from './shared/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {
  title = 'mibeadmin';
  darkTheme = new FormControl(false);
  private _subscriptions: Subscription[] = [];
  term = true;
  constructor(public themeService: ThemeService, public user: UserService, public activateRouter: ActivatedRoute) {
      // this.themeService.toggleDark();
   this.activateRouter.queryParams.subscribe(x=>{
   })
  }

  ngOnInit() {
   this.user._currentUserSubject.next(JSON.parse(localStorage.getItem('userdata')))
   console.log(JSON.parse(localStorage.getItem('userdata')))
   if(this.user && this.user.currentUser && this.user.currentUser.is_artist ===  true && this.user.currentUser.tos_status === 'Pending' ){
    this.term = false;
   }
  else{
    this.term = true;
  }
  }
  ngOnDestroy() {
    this._subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }
}
