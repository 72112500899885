import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { Track } from '../models/track.model';
import { TrackVersion } from '../models/trackversion.model';
import { User } from '../models/user.model';
import { ActivityService } from '../services/activity.service';
import { TrackService } from '../services/track.service';
import { TrackVersionService } from '../services/trackversion.service';
import { MatTableDataSource } from '@angular/material/table';


const activityLimit = 30;

export interface DialogData {
  user: User;
}

export class TrackActivityListRow {
  constructor(
    public track: Track,
  ) {}
}

export class TrackVersionActivityListRow {
  constructor(
    public version: TrackVersion,
  ) {}
}

@Component({
  selector: "app-artist-activity-dialog",
  templateUrl: "./artist-activity-dialog.component.html",
  styleUrls: ["./artist-activity-dialog.component.scss"],
  animations: [
    trigger("rowInOut", [
      transition(":enter", [
        style({
          opacity: "0",
          height: "0",
          paddingTop: "0",
          overflow: "hidden",
          paddingBottom: "0",
        }),
        animate(
          "0.7s cubic-bezier(0.19, 1, 0.22, 1)",
          style({
            opacity: "1",
            height: "*",
          })
        ),
      ]),
      transition(":leave", [
        style({
          opacity: "1",
          height: "*",
          overflow: "hidden",
        }),
        animate(
          "0.7s cubic-bezier(0.19, 1, 0.22, 1)",
          style({
            opacity: "0",
            height: "0",
            paddingTop: "0",
            paddingBottom: "0",
          })
        ),
      ]),
    ]),
  ],
})
export class ArtistActivityDialogComponent implements OnInit {
  loading: boolean = false;
  activeTab: "tracks" | "uploads" = "tracks";
  notShowTrack:boolean = false
  notShowUpload:boolean = false
  tracksDataSource: MatTableDataSource<[]>;
  uploadsDataSource: MatTableDataSource<[]>;
  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.tracksDataSource.sort = sort;
    this.uploadsDataSource.sort = sort;
  }
  displayedColumns: string[] = ["title", "number", "created_at"];

  get currentPage(): number {
    return this.activeTab == "tracks"
      ? this._trackPage
      : this._trackVersionPage;
  }

  get totalActivities(): number {
    return this.activeTab == "tracks"
      ? this._trackActivityTotal
      : this._trackVersionActivityTotal;
  }

  get numberOfPages(): number {
    return Math.ceil(this.totalActivities / activityLimit);
  }

  get listDataSource() {
    return this.activeTab == "tracks"
      ? this.tracksDataSource
      : this.uploadsDataSource;
  }

  private _trackPage: number = 1;
  private _trackVersionPage: number = 1;
  private _trackActivityTotal: number = 0;
  private _trackVersionActivityTotal: number = 0;
  private _trackDataSource: Observable<ReadonlyArray<TrackActivityListRow>>;
  private _trackVersionDataSource: Observable<
    ReadonlyArray<TrackVersionActivityListRow>
  >;
  private options = {
    limit: activityLimit,
    offset: activityLimit * (this._trackPage - 1),
    ownedBy: this.data.user.id,
   
  };
  constructor(
    private dialogRef: MatDialogRef<ArtistActivityDialogComponent>,
    private _trackService: TrackService,
    private _trackVersionService: TrackVersionService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    this.options.offset = 1;
    this.loadActivities();
  }

  close() {
    this.dialogRef.close(false);
  }

  listTrackBy(_, item: TrackActivityListRow | TrackVersionActivityListRow) {
    if (item instanceof TrackActivityListRow) {
      return item.track.id;
    } else {
      return item.version.id;
    }
  }

  pageChange(page: number) {
    if (page != this.currentPage) {
      if (this.activeTab == "tracks") {
        this._trackPage = page;
      } else {
        this._trackVersionPage = page;
      }
      this.loadActivities();
    }
  }

  changeTab(tab: "tracks" | "uploads") {
    if (tab != this.activeTab) {
      this.activeTab = tab;
      this.options.offset = 1;
      this.loadActivities();
    }
  }

  // loadActivities() {
  //   this.loading = true;
  //   if (this.activeTab == "tracks") {
  //     this._trackService.getTracks(this.options).subscribe(
  //       (res) => {
  //         this._trackActivityTotal = res.count;
  //         this._trackDataSource = of(
  //           res.results.map((track) => {
  //             return new TrackActivityListRow(track);
  //           })
  //         );
  //         this.loading = false;
  //       },
  //       (error) => {
  //         console.log(error);
  //         //alert("An error ocurred while getting the tracks for this artist. Check your connections and try again");
  //       }
  //     );
  //   } else {
  //     this._trackVersionService.getAllTrackVersions(this.options).subscribe(
  //       (res) => {
  //         this._trackVersionActivityTotal = res.count;
  //         this._trackVersionDataSource = of(
  //           res.results.map((version) => {
  //             return new TrackVersionActivityListRow(version);
  //           })
  //         );
  //         this.loading = false;
  //       },
  //       (error) => {
  //         console.log(error);
  //         //alert("An error ocurred while getting the track uploads for this artist. Check your connections and try again");
  //       }
  //     );
  //   }
  // }

  getProperty = (obj, path) => path.split(".").reduce((o, p) => o && o[p], obj);

  loadActivities() {
    this.loading = true;
    if (this.activeTab == "tracks") {
      this.displayedColumns = ["title", "number", "created_at"];
      this.tracksDataSource = new MatTableDataSource<[]>();

      this._trackService.getTrackActivity(this.options).subscribe((res) => {
        this.tracksDataSource.data = res as [];
        this.loading = false;
        if(this.tracksDataSource.data.length == 0){
          this.notShowTrack = true
          this.notShowUpload = false
        }
      });
     
    } else {
      this.displayedColumns = ['file_name', 'file_size', 'created_at']
      this.uploadsDataSource = new MatTableDataSource<[]>();
      this._trackService.getUploadActivity(this.options).subscribe((res) => {
        this.uploadsDataSource.data = res as [];
        this.uploadsDataSource.sortingDataAccessor = (obj, property) =>
          this.getProperty(obj, property);
        this.loading = false;
        if(this.uploadsDataSource.data.length == 0){
          this.notShowUpload = true
          this.notShowTrack = false
        }
      });
      
    }
  }
}
