import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserMenuService } from '../../shared/services/user-menu.service';
import { MenuConfigService } from '../../shared/services/menu-config.service';
import { environment } from '../../../environments/environment';
import { MenuListService } from '../../shared/services/menu-list.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { DialogService } from 'src/app/shared/services/dialog.service';


@Component({
  selector: 'app-add-genre-dialogue',
  templateUrl: './add-genre-dialogue.component.html',
  styleUrls: ['./add-genre-dialogue.component.scss']
})
export class AddGenreDialogueComponent implements OnInit {
  imageChangedEvent: any = '';
  imageUrl = '/assets/img/placeholder-image.png';
  croppedImage = '/assets/img/placeholder-image.png';
  finalImg = '/assets/img/placeholder-image.png';
  show = false;
  newFinalImg:any ='';
  showCropper = false;
  currentCropImage = '/assets/img/placeholder-image.png';
  crop() {
    this.show = false;
    this.showCropper = false;
    this.finalImg = this.croppedImage;
  }
//  toggle(){
// this.show = !this.show;
//   }
toggleclose(){
  this.show = !this.show;

  this.imageUrl = '/assets/img/placeholder-image.png';
  this.finalImg = '/assets/img/placeholder-image.png';
}
@ViewChild('myFileInput') myFileInput;

onFileChange(event) { 
  this.myFileInput.nativeElement.value = '';
}
 imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
      this.currentCropImage = this.croppedImage
      this.newFinalImg = event;
      const fileToReturn = this.base64ToFile(
        event.base64,
        this.image,
      )
      console.log(fileToReturn)
      this.newFinalImg = fileToReturn;
  }
  base64ToFile(data, filename) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}
  imageLoaded() {
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }
  cropData() {
    this.showCropper = true;
    this.show = false;
    this.currentCropImage = this.currentCropImage
  }
  resetCrop() {
    this.showCropper = false;
    this.show = true;
  }
  fileToUpload: any;
  genreImg =''
  image=''
  maximumError:boolean = false;
  maximumDimention: boolean = false;
  handleFileInput(file: FileList,e:any) {
  //  const URL = window.URL || window['webkitURL'];
  //  const Img = new Image();

    const filesToUpload = (e.target.files);
   // Img.src = URL.createObjectURL(filesToUpload[0]);

  //   Img.onload = (e: any) => {
  //     const height = e.path[0].height;
  //     const width = e.path[0].width;
  
  //     if(width < 900 || height < 540){
  //       this.maximumDimention = true;
  //     this.show = false;
  //     this.genreImg = '';
  //     this.image = '';
  //     this.finalImg = '';
  //     this.imageUrl = ''
  //     this.fileToUpload = null;
  //     this.imageChangedEvent = null
  //     return
  //     }

  // }

    this.maximumError = false
    if(e.srcElement.files[0].size  > 1 * 1000000){
      this.maximumError = true;
      this.show = false;
      this.genreImg = '';
      this.image = '';
      this.finalImg = '';
      this.imageUrl = ''
      this.fileToUpload = null;
      this.imageChangedEvent = null
      return
     }
    this.show = true;
    this.fileToUpload = file.item(0);
    this.imageChangedEvent = event;
    this.finalImg = '/assets/img/placeholder-image.png';
    //Show image preview
    this.genreImg = this.fileToUpload;
    this.image = this.fileToUpload.name
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result ;
    }
    reader.readAsDataURL(this.fileToUpload);
  }
  gnereName: string = '';
  subgnereName: string = '';
  loader: boolean = true;
  parent:string =''
  gnereCheck:any='2'
  constructor(private dialogRef: MatDialogRef<AddGenreDialogueComponent>,
    public api: UserMenuService, 
    public config: MenuConfigService,
    public list: MenuListService,public _DialogService:DialogService
  ) { 
    this.list.getAllParents();
  }

  ngOnInit(): void {
    setTimeout(()=>{                 
      this.loader = false;
    }, 500);
  }
  close() {
    this.genreImg =''
    this.dialogRef.close(true);
  }
  create(){
    if(this.gnereCheck == ''){
      //alert('Enter Gnere Type')
      return
    } 
    
    const formData:any = new FormData();

   

    if(this.gnereCheck== '2'){
      console.log(this.gnereCheck)
      formData.append("name", this.gnereName);
     // formData.append("file", this.genreImg);
      formData.append("marquee_image", this.genreImg);
      formData.append("thumb_image", this.newFinalImg);
      let data = {
        name: this.gnereName
      }
      let requestUrl = environment.apiURL + this.config.doGenres;
      this.api.doPOST(requestUrl,formData).subscribe(x=>{
        this.close()
        this.list.getAllGenres();
      },error =>{
        // if(error.error.error)
        // alert(error.error.error)
        // else{
        //   alert(error.error.detail)
        //  }
        let data = error.error?.error ? error.error?.error : (error.error?.detail ?error.error?.detail : (error.error?.details ? error.error?.details:``) )
        this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
        });
        this.close()
        // alert(this.gnereName+ " - is already exist. Please add unique Genre name")
      }
    )
  }
  if(this.gnereCheck== '3'){
    if(this.parent == ''){
      //alert('Enter Select parent')
      return
    } 
    console.log(this.gnereCheck)
    formData.append("name", this.subgnereName);
    formData.append("parent",  this.parent);
   // formData.append("file", this.genreImg);
    formData.append("marquee_image", this.genreImg);
    formData.append("thumb_image", this.genreImg);
    let data = {
      "name": this.subgnereName,
      "parent": this.parent

    }
    let requestUrl = environment.apiURL + this.config.doGenres;
    this.api.doPOST(requestUrl,formData).subscribe(x=>{
      this.close();
      this.list.getAllGenres();
    },error =>{
      let data = error.error?.error ? error.error?.error : (error.error?.detail ?error.error?.detail : (error.error?.details ? error.error?.details:``) )
      this._DialogService.UserUploadPopupConfirmPopup(data ).subscribe(changed=>{
      });
      this.close()
      // alert(this.gnereName+ " - is already exist. Please add unique Genre name")
    })
  
  }
  }
}
